import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Radio,
  RadioGroup,
  useToast,
  ChakraProvider,
  Tooltip,
} from "@chakra-ui/react";
import CloseIcon from "@mui/icons-material/Close";

const url = {
  Classify:
    "https://intelliverse-demo-new.s3.ap-south-1.amazonaws.com/Self+service.mp4",
  Detect:
    "https://intelliverse-demo-new.s3.ap-south-1.amazonaws.com/Data+detection.mp4",
  Segment: "",
};

const HelpModal = ({ openModal, closeModal, type }) => {
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={openModal}
      onClose={closeModal}
      isCentered="true"
    >
      <ModalOverlay />
      <ModalContent style={{ borderRadius: "8px" }} maxW="360px">
        <ModalHeader
          py={"8px"}
          px={"16px"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          borderBottom={"1px solid #EBE2F0"}
        >
          <p className="text-[#605D64] text-sm font-medium">How to {type}</p>
          <CloseIcon
            className="cursor-pointer hover:scale-105"
            onClick={closeModal}
            sx={{ color: "#605D64" }}
          />
        </ModalHeader>
        <ModalBody p={"16px"}>
          <div className="w-full h-full flex justify-center items-center bg-black rounded-lg">
            <video
              controls
              src={url[type]}
              alt="tutorial"
              className="max-w-full h-auto rounded-lg"
            />
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default HelpModal;
