import { useCallback, useContext, useEffect, useState } from "react";
import {
  getParamterChartDataApi,
  getroZLdClarifierDataApi,
  postRoZldClarifierDataApi,
} from "../services/sodaashandlime.api";
import NavContext from "../../NavContext";
import {
  FormControl,
  FormErrorMessage,
  Input,
  NumberInput,
  NumberInputField,
  Skeleton,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { NoData, TableSkeleton } from "../components/Loading";
import { CommanSingleSelect } from "../components/Comman";
import { ParameterLineChart } from "../components/Charts";
import { convertToCamelCase } from "../utilis/utilityFunctions";

const ChartSection = ({ parameters }) => {
  const { auth } = useContext(NavContext);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState(parameters?.[0] || "");
  const [data, setData] = useState(null);

  const fetchData = useCallback(
    async (selectedParameter) => {
      try {
        const requestData = {
          parameter: selectedParameter,
        };
        const response = await getParamterChartDataApi(
          auth,
          requestData,
          setLoading
        );
        setData(response);
      } catch (error) {
        console.log("Error while fetching data!");
      }
    },
    [auth]
  );

  useEffect(() => {
    setFilters(parameters?.[0] || "");
  }, [parameters]);

  useEffect(() => {
    fetchData(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col gap-6 bg-white p-3 md:p-6 rounded-xl w-full h-full">
      <div className="flex xl:flex-row flex-col justify-between items-start xl:items-center gap-5">
        <p className="min-w-fit font-bold text-[#3E3C42] text-left text-sm md:text-base lg:text-lg">
          {filters}
        </p>
        <div className="w-[250px] min-w-[250px]">
          <CommanSingleSelect
            label={"Selected Graph"}
            options={parameters?.map((el) => ({ name: el, value: el }))}
            value={filters}
            handleChange={(event) => {
              const {
                target: { value },
              } = event;
              setFilters((prev) => value);
              fetchData(value);
            }}
          />
        </div>
      </div>
      {loading ? (
        <Skeleton
          width={"100%"}
          height={"400px"}
        />
      ) : (
        <div className="w-full h-full">
          {data?.data && data?.times && (
            <ParameterLineChart
              times={data?.times}
              data={data?.data}
              title={filters}
            />
          )}
        </div>
      )}
    </div>
  );
};

const InputFormSection = ({ setParameters }) => {
  const [data, setData] = useState([]);
  const [errors, setErrors] = useState({});
  const [result, setResult] = useState(0);
  const [loading, setLoading] = useState({ table: false, result: false });
  const [columns, setColumns] = useState([]);
  const { auth } = useContext(NavContext);

  const handleChange = useCallback(
    (value, index) => {
      if (isNaN(value)) return;
      setData((prev) => {
        const temp = [...prev];
        temp[index].value = value;
        return temp;
      });

      setErrors((prev) => {
        const tempErrors = { ...prev };
        const min = data[index]?.min || 0;
        const max = data[index]?.max || 100;
        let errorMessage = "";

        if (value < min || value > max) {
          errorMessage = `Please enter between ${min} to ${max}`;
        }

        if (data[index].id === 2) {
          // calcium
          const totalHardnessInlet = data.find((item) => item.id === 1)?.value;
          if (totalHardnessInlet !== undefined && value >= totalHardnessInlet) {
            errorMessage =
              "Calcium hardness inlet should be less than total hardness inlet";
          } else {
            if (tempErrors["1"]) {
              delete tempErrors["1"];
            }
          }
        } else if (data[index].id === 1) {
          const calciumHardnessInlet = data.find(
            (item) => item.id === 2
          )?.value;
          if (
            calciumHardnessInlet !== undefined &&
            value <= calciumHardnessInlet
          ) {
            errorMessage =
              "Total hardness inlet should be greater than calcium hardness inlet";
          } else {
            if (tempErrors["2"]) {
              delete tempErrors["2"];
            }
          }
        }

        if (errorMessage) {
          tempErrors[data[index].id] = errorMessage;
        } else {
          delete tempErrors[data[index].id];
        }
        return tempErrors;
      });
    },
    [data]
  );

  const fetchData = useCallback(async () => {
    try {
      setLoading((prev) => ({ ...prev, table: true }));
      const requestData = {
        clientId: "hindustanzinc",
        useCase: "sodaash",
      };
      const response = await getroZLdClarifierDataApi(
        auth,
        requestData,
        setLoading
      );
      // const tempParameters = response?.data?.map((el) => el.parameter);
      // setParameters((prev) => tempParameters);
      let tempErrors = {};
      response?.data?.forEach((el) => {
        if (el?.value < el?.min || el?.value > el?.max) {
          tempErrors[el?.id] = `Please enter between ${el?.min} to ${el?.max}`;
        }
      });
      setErrors((prev) => tempErrors);
      setColumns(response.columns);
      setData(response.data);
    } catch (error) {
      console.log("Error while fetching data!");
      setColumns(null);
      setData(null);
      setErrors(null);
    } finally {
      setLoading((prev) => ({ ...prev, table: false }));
    }
  }, [auth]);
  const handleClickCalculate = useCallback(async () => {
    try {
      const requestData = {
        clientId: "hindustanzinc",
        useCase: "sodaash",
      };
      data?.forEach((el) => {
        const tempParameterName =
          el?.parameter &&
          convertToCamelCase(el.parameter) + `(${el?.unit?.toLowerCase()})`;
        requestData[tempParameterName] = !isNaN(el?.value) && Number(el.value);
      });

      const response = await postRoZldClarifierDataApi(
        auth,
        requestData,
        setLoading
      );
      setResult((prev) => response);
    } catch (error) {
      console.log("Error while calculating!");
      setResult((prev) => 0);
    }
  }, [auth, data]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="flex flex-col gap-5 bg-white p-3 md:p-6 rounded-xl w-full h-full">
      <div className="flex flex-col gap-2 bg-white w-full">
        <p className="font-bold text-[#3E3C42] text-left text-sm md:text-base lg:text-lg">
          {"For RO ZLD Clarifier"}
        </p>
        <div className="rounded-md w-full h-full">
          {loading.table ? (
            <TableSkeleton
              headers={2}
              rows={5}
              cellsPerRow={2}
            />
          ) : columns && data ? (
            <TableContainer className="border rounded-md text-sm">
              <Table variant="simple">
                <Thead className="bg-[#CCEAFF]">
                  <Tr>
                    {columns?.map((columnName, columnIndex) => (
                      <Th
                        color="#3E3C42"
                        fontWeight={"semibold"}
                        key={`${columnName}`}
                        textAlign={"left"}
                        textTransform={"capitalize"}
                        fontSize={"inherit"}
                        py={5}
                      >
                        {columnName?.split("_").join(" ")}
                      </Th>
                    ))}
                  </Tr>
                </Thead>
                <Tbody>
                  {data?.map((rowData, rowIndex) => {
                    return (
                      <Tr
                        key={`row-${rowData?.id || rowIndex}`}
                        className="odd:bg-white even:bg-[#FAFAFA]"
                        textAlign={"left"}
                        fontWeight={"medium"}
                        color={"#3E3C42"}
                        fontSize={"inherit"}
                      >
                        {columns?.map((columnName) => {
                          if (columnName === "value") {
                            return (
                              <Td
                                key={`row-cell-${rowData?.id}-value`}
                                display={"flex"}
                                flexDirection={"row"}
                                alignItems={"center"}
                                gap={4}
                              >
                                <FormControl
                                  width={"240px"}
                                  isInvalid={errors?.[rowData?.id]}
                                  isRequired
                                >
                                  <NumberInput
                                    value={rowData?.[columnName]}
                                    rounded={"5px"}
                                    onChange={(value) =>
                                      handleChange(value, rowIndex)
                                    }
                                  >
                                    <NumberInputField
                                      px={3}
                                      fontSize={"inherit"}
                                    />
                                  </NumberInput>
                                  <FormErrorMessage>
                                    {errors?.[rowData?.id]}
                                  </FormErrorMessage>
                                </FormControl>
                                <p className="font-semibold text-inherit">
                                  {rowData?.unit}
                                </p>
                              </Td>
                            );
                          }
                          return (
                            <Td
                              key={`row-cell-${rowData?.id}-${rowData?.[columnName]}`}
                            >
                              {rowData?.[columnName]}
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })}
                  <Tr
                    className="odd:bg-white even:bg-[#FAFAFA]"
                    textAlign={"left"}
                    fontWeight={"medium"}
                    color={"#3E3C42"}
                  >
                    <Td>{"Optimal Soda Ash Dosing Rate "}</Td>
                    <Td
                      display={"flex"}
                      flexDirection={"row"}
                      gap={4}
                      alignItems={"end"}
                    >
                      <PrimaryButton
                        text={loading.result ? <Spinner /> : "Calculate"}
                        width={"125px"}
                        height={"40px"}
                        disable={
                          loading.result || Object.keys(errors)?.length > 0
                        }
                        onClick={handleClickCalculate}
                      />
                      <Input
                        readOnly
                        value={result}
                        border={"1px solid #CAC5CD"}
                        width={"100px"}
                        height={10}
                        placeholder="0"
                        backgroundColor={"#FFFFED"}
                      />
                      <p className="font-semibold text-inherit">{"m3/hr"}</p>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          ) : (
            <div className="w-full h-[400px]">
              <NoData />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const SodaAshOptimizer = () => {
  const [parameters, setParameters] = useState(null);
  return (
    <div className="flex xl:flex-row flex-col gap-4 w-full h-full">
      <InputFormSection setParameters={setParameters} />
      {parameters && <ChartSection parameters={parameters} />}
    </div>
  );
};
