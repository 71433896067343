import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import NavContext from "../NavContext";
import { baseURL } from "../..";
import mixpanel from "mixpanel-browser";
import { mixpanelTrackClientSelect } from "../../util/mixpanelFunctions";

const ClientSelect = () => {
  let param = useParams();

  console.log(param.material.toLowerCase());
  const [clients, setClients] = useState([]);

  const { auth } = useContext(NavContext);

  useEffect(() => {
    getClients();
  }, []);

  const getClients = async () => {
    const data = await fetch(
      `${baseURL}vision/v2/product/getClientIdsByUseCase/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
        body: JSON.stringify({
          usecase: param.material.toLowerCase(),
        }),
      }
    );
    const res = await data.json();
    const sortedArray = res?.sort((a, b) => {
      const aContainsTest = a.includes("test");
      const bContainsTest = b.includes("test");

      if (aContainsTest === bContainsTest) {
        return a.localeCompare(b);
      } else if (aContainsTest) {
        return 1;
      } else {
        return -1;
      }
    });
    setClients(sortedArray);
  };

  return (
    <div className="h-full">
      <div className="bg-white rounded-xl shadow-md p-4 mt-5 border">
        <div className="flex flex-col gap-5">
          <div className="flex justify-start mt-3 ml-3 mr-3 mb-3 text-semibold text-2xl gap-2 items-center">
            <Link
              to={`/vision/${param.category}`}
              style={{
                textDecoration: "none",
              }}
            >
              <img src="/backtick.svg" />
            </Link>
            Please choose a client
          </div>
          <div className="flex w-full flex-wrap gap-4 ml-3 mb-5">
            {clients?.map((item, index) => {
              return (
                <Link
                  to={`./${item}`}
                  onClick={() => {
                    mixpanelTrackClientSelect(item, param.material);
                  }}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    key={index}
                    className={
                      item.includes("test")
                        ? "p-1 h-40 w-40 border  border-dashed border-[#CAC5CD] items-center uppercase text-[#024D87] font-bold rounded-md flex justify-center shadow-md bg-[#F5F5F5] hover:bg-gray-100 hover:transition duration-200 cursor-pointer text-center"
                        : "p-1 h-40 w-40 border items-center uppercase text-[#024D87] font-bold rounded-md flex justify-center shadow-md hover:bg-gray-100 hover:transition duration-200 cursor-pointer text-center"
                    }
                  >
                    <p className="w-full">{item}</p>
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientSelect;
