import { useContext, useEffect, useState } from "react";
import { CustomStyledDataGrid } from "../../../util/MaterialDataGrid/CustomStyledDatagrid";
import { GridActionsCellItem, GridRowEditStopReasons, GridRowModes } from "@mui/x-data-grid";
import { CancelOutlined, EditNote, Save } from "@mui/icons-material";
import NavContext from "../../NavContext";
import { deleteParameterWeightApi, updateParameterWeightApi } from "../services/advancedServices";
import { Toast, useToast } from "@chakra-ui/react";
import { postCommentApi } from "../services/biasRecommenderServices";

export const ReportsTable = ({
  columns,
  rowData,
  handleCellClick,
  rowSelectionModel,
  columnGroupingModel,
  hideFooter = true,
  pagination = false,
  initialState,
  slots
}) => {

  console.log(rowData);

  const { auth } = useContext(NavContext);
  const toast = useToast();

  const [rowModesModel, setRowModesModel] = useState({});
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState(rowData);

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => async () => {
    try {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const processRowUpdate = async (newRow, originalRow) => {

    console.log(newRow, originalRow);
    try {
      let flag = true;
      for (let key in newRow) {
        if (key !== "id" && key !== "isNew") {
          if (newRow[key]) {
            flag = false;
            break;
          }
        }
      }
      if (flag === true) {
        throw new Error("Invalid data")
      }
      const requestData = {
        plate_id: newRow.primary_data_plate_id,
        comment_1: newRow?.comment_1,
        comment_2: newRow?.comment_2,
        comment_3: newRow?.comment_3,
      };
      console.log(requestData);
      const updatedRow = { ...newRow, isNew: false };
      if (requestData?.isNew) {
        delete requestData?.isNew;
      }
      const apiResponse = await postCommentApi(
        requestData,
      );
      if (apiResponse?.success) {
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        toast({
          title: "Parameter updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        return updatedRow;
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Invalid data",
        description: "Please ensure that data entered is valid",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      // return originalRow;
    }
  };

  const updatedColumnsWithActionCell = [
    ...columns,
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Save sx={{ color: "#3A74CA", fontSize: "24px" }} />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={
                <CancelOutlined sx={{ color: "#616161", fontSize: "24px" }} />
              }
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditNote sx={{ color: "#3A74CA", fontSize: "28px" }} />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];


  useEffect(() => {setRows((prev)=>rowData)},[rowData])
  return (
    <CustomStyledDataGrid
      rows={rows || []}
      columns={updatedColumnsWithActionCell || []}
      rowModesModel={rowModesModel}
      onRowModesModelChange={handleRowModesModelChange}
      onRowEditStop={handleRowEditStop}
      processRowUpdate={processRowUpdate}
      hideFooter={hideFooter}
      rowHeight={40}
      editMode={"row"}
      loading={loading}
      columnHeaderHeight={35}
      showColumnVerticalBorder
      disableColumnSelector={true}
      disableRowSelectionOnClick={true}
      experimentalFeatures={{ columnGrouping: true }}
      {...(columnGroupingModel && {
        columnGroupingModel: columnGroupingModel,
      })}
      {...(handleCellClick && {
        onCellClick: handleCellClick,
      })}
      pagination={pagination}
      initialState={initialState}
      slots={slots}
      // checkbox selection
      {...(rowSelectionModel && {
        checkboxSelection: true,
      })}
      {...(rowSelectionModel && {
        rowSelectionModel: rowSelectionModel,
      })}
      getRowClassName={(params) => {
        // console.log(params);
        return (params?.row?.measured_data_FCT_QC != "pass" && rowSelectionModel[0] === params?.id ? '!shadow-inner !border !border-red-200 !border-1 !bg-[#fffefb]' : (params?.row?.measured_data_FCT_QC != "pass" ? '!bg-red-50' : ''))
      }}
      sx={{
        // "& .MuiDataGrid-columnHeaderTitle": {
        //   whiteSpace: "normal",
        //   lineHeight: "normal",
        //   paddingTop: "5px",
        // },
        // "& .MuiDataGrid-columnHeaderTitleContainer": {
        //   alignItems: "start",
        // },
        // maxHeight: handleCellClick ? "60vh" : "60vh",
        overflow: "hidden",
        "& .MuiDataGrid-columnHeader": {
          border: "0.9px solid #e0e0e0",
          //alignItems: 'center',
        },
        "& .MuiDataGrid-columnHeaderTitleContainer": {
          //border: "0.9px solid #e0e0e0",
          justifyContent: "center"
        },
        // "& .MuiDataGrid-columnSeparator": { display: "none" },
        "& .MuiDataGrid-row.Mui-selected": {
          backgroundColor: "#FFFdf3",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
          fontWeight: "600",
          "&:hover": {
            backgroundColor: "#FFFdf3",
          },
        },
        // "& .css-yz9k0d-MuiInputBase-input": {
          
        // }
      }}
    />
  );
};
