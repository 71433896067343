import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useEffect, useState, useContext, useRef } from "react";
import NavContext from "../../NavContext";
import { baseURL } from "../../../index";
import DetailModal from "../SizingComponents/DetailModal";
import Paginator from "../../../util/VisionUtils/Paginator";
import {
  Select,
  Table,
  Td,
  Tr,
  Thead,
  Tbody,
  TableContainer,
  Th,
  Flex,
  Image,
  Spinner,
} from "@chakra-ui/react";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";

import { capitalizeFirstLetter } from "../../../util/sentenceCase";
import AlertTable from "../SizingComponents/AlertTable";

const getImage = (reason) => {
  if (reason === 0) {
    return "/SizingIcons/sizing.svg";
  } else if (reason === 2) {
    return "/SizingIcons/sizing.svg";
  } else if (reason === 1) {
    return "/SizingIcons/sizing.svg";
  }
};

const getReason = (reason) => {
  if (reason === 0) {
    return "Size";
  } else if (reason === 2) {
    return "Size";
  } else if (reason === 1) {
    return "Size";
  }
};

const Alerts = ({ plantId, cameraId, disable, plantCamMap }) => {
  const param = useParams();
  const { auth } = useContext(NavContext);
  let material = param.material.toLowerCase();
  let clientId = param?.clientId?.toLowerCase();
  const indexRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [alertsChanging, setAlertsChanging] = useState(false);
  const [fromTime, setFromTime] = useState(
    new Date(
      new Date().getTime() -
        24 * 60 * 60 * 1000 -
        new Date().getTimezoneOffset() * 60 * 1000
    )
      .toISOString()
      .slice(0, 16)
  );
  const [toTime, setToTime] = useState(
    new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
      .toISOString()
      .slice(0, 16)
  );
  const additionalCols = {
    kappa: ["current", "expected"],
    pentosan: ["current", "expected"],
    viscosity: ["current", "expected"],
  };
  const [selectedPlant, setSelectedPlant] = useState(
    disable ? plantId : "All Plants"
  );
  const [selectedCam, setSelectedCam] = useState(
    disable ? cameraId : "All Cams"
  );
  const apiCall = async () => {
    const requestData = JSON.stringify({
      clientId: clientId,
      material: material,
      startDate: new Date(fromTime).getTime(),
      endDate: new Date(toTime).getTime(),
      cameraId:
        selectedCam === "All Cams" || selectedPlant === "All Plants"
          ? "all"
          : selectedCam,
      plantName: selectedPlant === "All Plants" ? "all" : selectedPlant,
      maxLimit: 8000,
    });
    const response = await axios
      .post(baseURL + "vision/v2/sizing/alerts/overview/", requestData, {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      })
      .then((response) => {
        setAlerts(response.data);
        setAlertsChanging(false);
      })
      .catch((error) => {
        console.log(error);
        setAlertsChanging(false);
      });
  };

  const handleClick = () => {
    setAlertsChanging(true);
    apiCall();
  };

  const handleDetail = (index) => {
    indexRef.current = index;
    setOpenModal(true);
  };

  useEffect(() => {
    if (!disable & (selectedPlant != "All Plants")) {
      setSelectedCam("All Cams");
    }
  }, [selectedPlant]);
  useEffect(() => {
    handleClick();
  }, []);
  return (
    <div className="relative flex flex-col">
      <div className="absolute left-0 right-0 flex justify-center">
        <div className="p-5 pl-6 pr-6 gap-2 flex flex-col md:flex-row items-center bg-white rounded-xl shadow-md">
          <div>
            <FloatingInput
              text="From"
              type="datetime-local"
              setDateTime={setFromTime}
              value={fromTime}
              max={toTime}
            />
          </div>
          <div>
            <FloatingInput
              text="To"
              type="datetime-local"
              setDateTime={setToTime}
              value={toTime}
              min={fromTime}
              max={new Date().toISOString().slice(0, 16)}
            />
          </div>
          {/* <button
            className="text-center p-[10px] pl-4 pr-4 text-white text-xs md:text-base font-medium bg-[#084298] rounded-full"
            onClick={handleClick}
          >
            {alertsChanging ? <Spinner /> : "Show Alerts"}
          </button> */}
          <PrimaryButton
            height={"40px"}
            width={"110px"}
            text={alertsChanging ? <Spinner /> : "Show alerts"}
            disable={alertsChanging || !fromTime || !toTime}
            onClick={handleClick}
          />
        </div>
      </div>
      <div className="flex flex-col gap-4 mt-[160px] md:mt-11 pt-[57px] bg-white rounded-xl justify-start">
        <div className="flex justify-between gap-3">
          <div className="flex gap-2 ml-6 overflow-x-auto">
            <div className="min-w-[110px]">
              <Select
                borderColor="#CAC5CD"
                color="#605D64"
                placeholder={disable && capitalizeFirstLetter(plantId)}
                variant="outline"
                isDisabled={disable}
                className="!text-sm !font-medium text-[#605D64] "
                rounded={"base"}
                onChange={(e) => setSelectedPlant(e.target.value)}
                value={selectedPlant}
              >
                <option key="All Plants" value="All Plants">
                  {capitalizeFirstLetter("All plants")}
                </option>
                {!disable &&
                  Object.keys(plantCamMap).map((plant) => {
                    return (
                      <option key={plant} value={plant}>
                        {capitalizeFirstLetter(plant)}
                      </option>
                    );
                  })}
              </Select>
            </div>
            {selectedPlant !== "All Plants" && (
              <div className="min-w-[110px]">
                <Select
                  borderColor="#CAC5CD"
                  color="#605D64"
                  placeholder={disable && capitalizeFirstLetter(cameraId)}
                  variant="outline"
                  isDisabled={disable}
                  rounded={"base"}
                  className="!text-sm !font-medium text-[#605D64] "
                  onChange={(e) => setSelectedCam(e.target.value)}
                  value={selectedCam}
                >
                  {" "}
                  <option key="All Cams" value="All Cams">
                    {capitalizeFirstLetter("All cams")}
                  </option>
                  {!disable &&
                    plantCamMap[selectedPlant].map((cam) => {
                      return (
                        <option key={cam} value={cam}>
                          {capitalizeFirstLetter(cam)}
                        </option>
                      );
                    })}
                </Select>
              </div>
            )}
            {/* <button
              className="text-center py-2 px-4 text-white text-xs md:text-base font-medium bg-[#6CA6FC] rounded-full min-w-[80px]"
              onClick={handleClick}
            >
              {alertsChanging ? <Spinner /> : "Apply"}
            </button> */}
            <SecondaryButton
              height={"40px"}
              width={"80px"}
              text={alertsChanging ? <Spinner /> : "Apply"}
              disable={alertsChanging}
              onClick={handleClick}
            />
          </div>
          {/* {alerts.hasOwnProperty("data") && (
            <Paginator
              data={alerts.data}
              limit={30}
              setDisplayData={setDisplayData}
            />
          )} */}
        </div>
        {alerts.hasOwnProperty("data") && (
          <AlertTable
            rowData={alerts.data}
            setDisplayData={setDisplayData}
            setOpenModal={setOpenModal}
            indexRef={indexRef}
            additionalCols={additionalCols}
          />
        )}
        {/* {alerts.hasOwnProperty("data") && (
          <TableContainer className="!max-h-[80vh] !overflow-y-auto">
            <Table variant="simple">
              <Thead className="bg-[#FAFAFA] !text-xs !sticky !top-0">
                <Tr>
                  <Th color="#79767D" fontWeight={400}>
                    SR. NO.
                  </Th>
                  {alerts.order.map((item) => {
                    return (
                      <Th color="#79767D" fontWeight={400}>
                        {item.toUpperCase()}
                      </Th>
                    );
                  })}
                  {alerts?.data?.some((item) =>
                    item?.hasOwnProperty("pulpParamOutput")
                  ) &&
                    Object.keys(additionalCols)?.map((item) => {
                      return (
                        <Th color="#79767D" fontWeight={400}>
                          <div className="flex flex-col gap-0 uppercase items-center">
                            <p>{item}</p>
                            <div className="flex justify-between gap-4 items-center">
                              {additionalCols[item]?.map((val) => {
                                return <p>{val}</p>;
                              })}
                            </div>
                          </div>
                        </Th>
                      );
                    })}
                  <Th color="#79767D" fontWeight={400}>
                    {""}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {displayData.map((item, index) => {
                  return (
                    <Tr
                      key={index}
                      className="!text-sm !text-[#3E3C42] !font-medium even:bg-[#FAFAFA] odd:bg-white"
                    >
                      <Td className="">
                        {String(item["idx"]).padStart(2, "0")}
                      </Td>
                      <Td className="">{item.plantName}</Td>
                      <Td className="">{item.cameraId}</Td>
                      <Td className="">
                        {new Date(item.timestamp).toLocaleDateString() +
                          " " +
                          new Date(item.timestamp).toLocaleTimeString()}
                      </Td>
                      <Td className="">
                        <Flex gap="1rem" align="center">
                          <div className="flex flex-col justify-center items-center">
                            <Image
                              className="h-8 w-8"
                              src={getImage(item.alertCodes[0])}
                              alt="none"
                            />
                            <span>{getReason(item.alertCodes[0])}</span>
                          </div>
                        </Flex>{" "}
                      </Td>
                      <Td className="">
                        {item.alertMessages.map((message, index) => (
                          <div className="w-fit" key={index}>
                            {message}
                          </div>
                        ))}
                      </Td>
                      {alerts?.data?.some((x) =>
                        x?.hasOwnProperty("pulpParamOutput")
                      ) &&
                        Object.keys(additionalCols)?.map((x) => {
                          return (
                            <Td>
                              <div className="flex justify-between gap-4 items-center w-full">
                                {additionalCols[x]?.map((val) => {
                                  return (
                                    <p className="text-center w-full">
                                      {item?.pulpParamOutput?.[x]?.[val]}
                                    </p>
                                  );
                                })}
                              </div>
                            </Td>
                          );
                        })}
                      <Td>
                        <p
                          className="text-blue-800 cursor-pointer hover:text-blue-200 font-semibold min-w-[80px]"
                          onClick={() => handleDetail(index)}
                        >
                          View Details
                        </p>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
        )} */}
      </div>
      {openModal && (
        <DetailModal
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          data={displayData}
          index={indexRef.current}
          PlantName={selectedPlant}
        />
      )}
    </div>
  );
};

export default Alerts;
