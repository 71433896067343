import { useToast } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import NavContext from "../../NavContext";
import { baseURL } from "../../..";
import DraftModal from "../DraftModal";
import AlertsInfoForm from "./components/AlertsInfoForm";
import AlertsDetailsForm from "./components/AlertsDetailsForm";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';


const initState = {
    alertId: "",
    alertName: "",
    alertGroupId: "",
    alertGroupName: "",
    alertType: "",
    product: "",
    productGroup: "",
    alertsDetail: [],
};

export const postAlertApi = async (
    toast,
    auth,
    data,
    activeStep,
    status,
    update = null,
    setDummyStateForm,
    setShowAlertsTab
) => {
    //return;
    try {
        data["status"] = status;
        data["isdeleted"] = false;

        const newData = structuredClone(data);
        newData?.alertsDetail?.forEach((item) => {
            let x = JSON.stringify(item?.contacts);
            item.contacts = x;
            let y = JSON.stringify(item?.camera);
            item.camera = y;
            let z = JSON.stringify(item?.extraParameters);
            item.extraParameters = z;
        })
        const requestData = JSON.stringify(newData);

        // console.log(requestData);
        const response = await axios.patch(
            baseURL + "alert/v1/addAlert",
            requestData,
            {
                headers: {
                    "Content-Type": "application/json",
                    "X-Auth-Token": auth,
                },
            }
        );
        if (response.status == 200) {

            if (status == 0) {
                toast({
                    title: "Saved",
                    description: "The progress is saved",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                    position: "top",
                });
            }
            else {
                toast({
                    title: "Submitted",
                    description: "Alert submitted successfully",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                    position: "top",
                });
            }

            let res = structuredClone(response.data);
            res?.alertsDetail?.forEach(item => {
                let x = JSON.parse(item?.contacts);
                item.contacts = x;
                let y = JSON.parse(item?.camera);
                item.camera = y;
                let z = JSON.parse(item?.extraParameters);
                item.extraParameters = z;
            });
            setDummyStateForm(res);
            if (update) {
                update(res);
            }

            if (status == 1) {
                setTimeout(() => {
                    setShowAlertsTab((prev) => "alerts")
                }, 1500)
            }
        }
    } catch (error) {
        console.log(error);
    }
};


const AddNewAlert = ({ setShowAlertsTab, selectedAlert, update = false, view = false }) => {

    const [userForm, setUserForm] = useState(initState);
    const [dummyStateForm, setDummyStateForm] = useState(initState);
    const [activeStep, setActiveStep] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const toast = useToast();
    const { auth } = useContext(NavContext);

    const handleBackButton = () => {
        if (!deepEqual(userForm, dummyStateForm)) {
            setIsModalOpen(true);
            return;
        }
        setShowAlertsTab((prev) => "alerts");
    };

    const handleConfirmDiscard = () => {
        setIsModalOpen(false);
        setShowAlertsTab((prev) => "alerts");
    }

    useEffect(() => {
        if (selectedAlert && Object.entries(selectedAlert)?.length > 0) {
            setUserForm(selectedAlert);
            setDummyStateForm(selectedAlert);
            if (view) setActiveStep(3);
            else setActiveStep(0);
        }
    }, []);

    useEffect(() => {
        if (activeStep < 2) {
            const elem = document.getElementById("step" + activeStep);
            elem.scrollIntoView({
                behavior: "smooth",
                block: "center",
            });
        }
        if (activeStep != 0 && !view) {
            console.log("Hello");
            let status = activeStep == 2 ? 1 : 0;
            postAlertApi(toast, auth, userForm, activeStep, status, setUserForm, setDummyStateForm, setShowAlertsTab);
        }
    }, [activeStep]);

    function deepEqual(obj1, obj2) {

        if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
            return obj1 === obj2;
        }

        if (obj1 === null && obj2 === null) {
            return true;
        }

        const keys1 = Object.keys(obj1 || {});
        const keys2 = Object.keys(obj2 || {});

        if (keys1.length !== keys2.length) {
            return false;
        }

        for (let key of keys1) {
            if (!keys2?.includes(key) || !deepEqual(obj1[key], obj2[key])) {
                return false;
            }
        }

        return true;
    }

    const handleSubmit = () => {

        if (!userForm?.alertGroupId || !userForm?.alertName) {
            toast({
                title: "Incomplete Details",
                description: "Please fill in all required details.",
                status: "error",
                duration: 4000,
                isClosable: true,
                position: "top",
            });
            return;
        }

        if (activeStep > 0) {
            for (let x of userForm["alertsDetail"]) {
                if (x?.isDeleted == true) continue;
                if (
                    x?.alertInsName?.length > 100 ||
                    !x?.alertInsName ||
                    !x?.clientId ||
                    !x.location ||
                    x?.plant?.length > 100 ||
                    x?.plantUnit?.length > 100 ||
                    x?.description?.length > 1000 ||
                    x?.emailTemplate?.length > 1000 ||
                    x?.whatsappTemplate?.length > 1000 ||
                    x?.smsTemplate?.length > 1000 ||
                    x?.destination?.length > 100 ||
                    x?.triggerValue?.length > 1000 ||
                    x?.destinationUrl?.length > 100 ||
                    x?.alertSourceUrl?.length > 100 ||
                    !x?.useCase
                ) {
                    toast({
                        title: "Please enter all the required details",
                        status: "error",
                        duration: 4000,
                        isClosable: true,
                        position: "top",
                    });
                    return;
                }

                if (x?.extraParameters?.length > 0) {
                    for (let y of x?.extraParameters) {
                        if (y?.isDeleted == true) continue;
                        if (
                            y?.key?.length > 100 ||
                            y?.value?.length > 100
                        ) {
                            toast({
                                title: "Please enter all the required details",
                                status: "error",
                                 duration: 4000,
                                isClosable: true,
                                position: "top",
                            });
                            return false;
                        }
                    }
                }

                if (x?.parameters?.length > 0) {
                    for (let y of x?.parameters) {
                        if (y?.isDeleted == true) continue;
                        if (
                            y?.key?.length > 100 ||
                            ((userForm?.alertType != "Usecase" && userForm?.alertType != "Other") && !y?.key) ||
                            y?.value?.length > 100 ||
                            // ((userForm?.alertType != "Usecase" && userForm?.alertType != "Other" && userForm?.alertType != "camera") && !y?.value) ||
                            y?.logic?.length > 100 ||
                            ((userForm?.alertType != "Usecase" && userForm?.alertType != "Other") && !y?.logic) ||
                            y?.frequency?.length > 100 ||
                            y?.alertText?.length > 250 ||
                            y?.alertSnapshot?.length > 250 ||
                            y?.aggTime?.length > 100 ||
                            y?.aggThreshold?.length > 100 ||
                            y?.unit?.length > 100 ||
                            y?.heartbeat?.length > 100 ||
                            y?.downProcessCnt?.length > 100 ||
                            y?.activeProcessCnt?.length > 100
                        ) {
                            toast({
                                title: "Please enter all the required details",
                                status: "error",
                                duration: 4000,
                                isClosable: true,
                                position: "top",
                            });
                            return;
                        }
                    }
                }
                let regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
                if (x?.contacts?.length > 0) {
                    for (let y of x?.contacts) {
                        if (y?.isDeleted == true) continue;
                        else {
                            if (y?.name?.length > 100 || y?.email.length > 100 || y?.phoneNo?.length > 100) {
                                toast({
                                    title: "Please enter valid contact details",
                                    status: "error",
                                    duration: 4000,
                                    isClosable: true,
                                    position: "top",
                                });
                                return false;
                            }
                            if (regex.test(y?.email)) continue;
                            else {
                                toast({
                                    title: "Please enter valid email id",
                                    status: "error",
                                    duration: 4000,
                                    isClosable: true,
                                    position: "top",
                                });
                                return false;
                            }
                        }
                    }
                }
            }
        }

        postAlertApi(toast, auth, userForm, activeStep, 0, setUserForm, setDummyStateForm, setShowAlertsTab);
    }

    console.log(activeStep);

    return (
        <div className="font-roboto flex flex-col gap-1">
            <div className="flex items-center m-2">
                <div className="cursor-pointer w-8" onClick={handleBackButton}>
                    <KeyboardBackspaceIcon sx={{ color: "#084298" }} />
                </div>
                {view ? <p className="text-[#084298] font-medium text-xl ml-2">
                    View Alert
                </p> : (update ? <p className="text-[#084298] font-medium text-xl ml-2">
                    Update Alert
                </p> : <p className="text-[#084298] font-medium text-xl ml-2">
                    Add new Alert
                </p>)}

            </div>

            <AlertsInfoForm
                userForm={userForm}
                setUserForm={setUserForm}
                setActiveStep={setActiveStep}
                activeStep={activeStep}
                update={update}
                view={view}
                setDummyStateForm={setDummyStateForm}
            />
            <AlertsDetailsForm
                userForm={userForm}
                setUserForm={setUserForm}
                show={activeStep >= 1}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                page={"alertsDetail"}
                view={view}
                setDummyStateForm={setDummyStateForm}
            />

            {isModalOpen && <DraftModal isOpen={isModalOpen} setShowDraft={setIsModalOpen} handleSubmit={handleSubmit} handleConfirmDiscard={handleConfirmDiscard} />}

        </div>
    );
};

export default AddNewAlert;
