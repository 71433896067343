import { ReportsGraph } from "../components/ReportsGraphs";
import {
  PalletBoardReplacementLogTable,
  ReportsTable,
} from "../components/ReportsTable";

export const Report = ({ plantId, cameraId, disable, plantCamMap }) => {
  // variables

  return (
    <div className="relative flex flex-col gap-10 mt-5 md:mt-0 mb-10">
      {/* Shift Selection Modal */}
      {/* <IconButton
        position={"absolute"}
        top={"-30px"}
        right={0}
        colorScheme="gray"
        size={"lg"}
        rounded={"100px"}
        color={"#084298"}
        onClick={() => setShiftSelectOpen(true)}
        icon={<GroupAddIcon />}
      /> */}
      <ReportsTable plantId={plantId} />
      <PalletBoardReplacementLogTable plantId={plantId} />
      <ReportsGraph type={"vNotchGratebar"} />
      <ReportsGraph type={"overallBeltPermeability"} />
      {/* {shiftSelectOpen && (
        <ShiftSelectModal
          openModal={shiftSelectOpen}
          closeModal={() => setShiftSelectOpen(false)}
          clientId={param.clientId.toLowerCase()}
        />
      )} */}
    </div>
  );
};
