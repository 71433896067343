import { LineChartWithBaseline } from "../Components/SingleElement/LineChartWithBaseline";
import { useContext, useEffect, useState } from "react";
import { SingleElementSkeleton } from "../Components/LoadingState/SingleElementSkeleton";
import { getSpecificElementDataApi } from "../Services/specificElementServices";
import { useParams } from "react-router-dom";
import NavContext from "../../NavContext";
import { useToast } from "@chakra-ui/react";
import {
  ParametersAccordian,
  ParametersChangeTrend,
} from "../Components/SingleElement/ParametersChangeTrend";
import { generateParamertersColumns } from "../utilis/dataGridColumns";
import { formatDateTime } from "../utilis/dateTimeFormatter";
// import TonalButton from "../../../util/Buttons/TonalButton";
// import { EditNote } from "@mui/icons-material";

const Card = ({ title, timeStamp, color, backgroundColor, value }) => {
  return (
    <div className="flex flex-col gap-0 justify-center rounded-lg shadow-md w-full">
      <div className="px-2 py-2 flex gap-4 items-start self-start rounded-t-lg w-full">
        <p className="text-sm whitespace-nowrap capitalize">{title}</p>
        {timeStamp && (
          <p className="text-sm whitespace-nowrap text-[#79767D]">
            {" "}
            {formatDateTime(timeStamp)}
          </p>
        )}
      </div>
      <div
        className="px-2 py-2 text-left text-base font-medium rounded-b-lg w-full border-t"
        style={{ color: color, backgroundColor: backgroundColor }}
      >
        Zn% in Slag <span className="font-bold">{value}</span>%
      </div>
    </div>
  );
};
export const ZincPercentInSlag = ({ handleTabsChage, plantMap }) => {
  const param = useParams();
  const { auth } = useContext(NavContext);
  const [firstTimeLoaded, setFirstTimeLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  // state to manage data
  const [columns, setColumns] = useState(null);
  const [zincPercentData, setZincPercentData] = useState(null);

  const getZincPercentData = async () => {
    const requestData = JSON.stringify({
      clientId: param.clientId.toLowerCase(),
      plantName: "chanderia",
      useCase: "ISFOPTIMIZER",
    });
    try {
      const res = await getSpecificElementDataApi(
        auth,
        requestData,
        setLoading,
        toast,
        "zinc_slag_predicted"
        // zincPercentAbortController
      );
      // console.log(res);
      if (res?.data) {
        if (firstTimeLoaded === false) {
          const tempColumns = await generateParamertersColumns(
            res.data?.topControllableParameters.columns
          );
          setColumns((prev) => tempColumns);
        }
        setZincPercentData((prev) => res?.data);
      } else {
        throw new Error("Error fetching data");
      }
    } catch (error) {
      console.error(error);
      setColumns((prev) => null);
      setZincPercentData((prev) => null);
    } finally {
      setFirstTimeLoaded((prev) => true);
    }
  };

  // useeffects
  useEffect(() => {
    // const zincPercentAbortController = new AbortController();
    getZincPercentData();

    // interval to make api call
    const intervalId = setInterval(() => {
      getZincPercentData();
    }, 1000 * 60);

    return () => {
      clearInterval(intervalId);
    };

    // return () => {
    //   zincPercentAbortController.abort();
    // };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {firstTimeLoaded === false && loading ? (
        <SingleElementSkeleton />
      ) : (
        <div className="flex flex-col gap-4 mb-5 ">
          {/* Single Chart */}
          <div className="flex flex-col gap-2 bg-white p-2 rounded-xl">
            <p className="text-sm  md:text-base lg:text-lg font-bold px-2 md:px-4">
              {"Zinc% in Slag"}
            </p>
            {zincPercentData?.lineChartData ? (
              <LineChartWithBaseline
                chartData={zincPercentData?.lineChartData}
                title={"Zinc%"}
              />
            ) : (
              <div className="flex items-center justify-center text-gray-400 text-base h-[20vh]">
                <p className="">No Data</p>
              </div>
            )}
          </div>

          {/* Controllable and other parameters */}
          <div className="flex flex-col gap-4 md:gap-3 bg-white p-2 rounded-xl">
            <div className="flex flex-col md:flex-row py-2 px-1 md:px-3 gap-3 md:gap-0 justify-between items-start md:items-end">
              <div className="flex flex-col md:flex-row gap-4 items-start md:items-center">
                {zincPercentData?.lastKnown && (
                  <Card
                    title={"Last Known"}
                    value={zincPercentData?.lastKnown?.value}
                    timeStamp={zincPercentData?.lastKnown?.timeStamp}
                    color={"#3E3C42"}
                    backgroundColor={"#FFFFED"}
                  />
                )}
                {zincPercentData?.predictiveForNextTapping && (
                  <Card
                    title={"Predictive for next Tapping"}
                    value={zincPercentData?.predictiveForNextTapping?.value}
                    color={"#3E3C42"}
                    backgroundColor={"#D2E4FE"}
                  />
                )}
              </div>
              {/* <div className="flex flex-row w-full justify-start md:justify-end">
                  <TonalButton
                    text={"Edit"}
                    width={"80px"}
                    height={"30px"}
                    Icon={<EditNote />}
                    onClick={() => {
                      handleTabsChage(3);
                    }}
                  />
                </div> */}
            </div>
            <ParametersAccordian
              title={"Top Controllable Parameters for Model"}
              columns={columns}
              rowData={zincPercentData?.topControllableParameters?.tableData}
            />
            <ParametersAccordian
              title={"Other Parameters"}
              columns={columns}
              rowData={zincPercentData?.otherParameters?.tableData}
            />
          </div>

          {/*  Parameter change trend since Last prediction  */}
          <div>
            {zincPercentData?.parametersChangeTrend && (
              <ParametersChangeTrend
                data={zincPercentData?.parametersChangeTrend}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};
