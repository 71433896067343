import { Flex } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import NavContext from "./NavContext";
import { useWindowSize } from "@uidotdev/usehooks";
import MessageNav from "./Main/Messages/MessageNav";
import ProfileNav from "./Main/ProfileNav";
import AppNav from "./Main/AppNav";
import { baseURL } from "..";
import axios from "axios";

const NavBox = () => {
  const size = useWindowSize();
  const { setLogin, userType } = useContext(NavContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState(null);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [searchData, setSearchData] = useState([]);
  const [prevLocation, setPrevLocation] = useState("/home");

  useEffect(() => {
    if (!location.pathname?.includes("/search")) {
      setPrevLocation(location.pathname);
    }
  }, [location.pathname]);

  const handleSearchTermChange = (e) => {
    const term = e.target.value;
    setSearchParams({ q: term });
    setSearchTerm(term);
    navigate(`/search?q=${term}`, {
      state: { path: prevLocation },
    });
  };

  useEffect(() => {
    if (!location.pathname.includes("/search")) {
      setSearchTerm("");
    }
  }, [location.pathname]);

  return (
    <>
      {size.width >= 768 ? (
        <Flex
          p="4px"
          justify="gap"
          w="calc(100vw - 125px)"
          h="7vh"
          m="auto"
          shadow=""
          bgColor="#FAFAFA"
          position="fixed"
          top="16"
          zIndex="100"
          align="center"
          left="0"
          className="border-b"
          marginTop="-7px"
          marginLeft="125px"
        >
          <div className="bg-white w-full px-3 py-2 h-10 rounded-md shadow-md border flex items-center gap-2">
            <img className="h-5" src="/search.svg" />
            <div className="relative w-full">
              <input
                value={searchTerm}
                // onClick={handleSearchInputClick}
                onChange={handleSearchTermChange}
                className="w-full focus:outline-none"
                placeholder="Search Intelliverse"
              />
            </div>
          </div>
          <div className="flex justify-end gap-5 min-w-fit items-center ml-5 mr-12">
            {userType != "EXPERT" && <AppNav />}
            <Link to="/settings">
              <img
                className="hover:scale-110 hover:transition duration-200 cursor-pointer"
                src="/setting.svg"
              />
            </Link>
            {/* <MessageNav /> */}
            <ProfileNav setLogin={setLogin} />
          </div>
        </Flex>
      ) : (
        <Flex
          p="4px"
          justify="gap"
          w="100vw"
          h="7vh"
          m="auto"
          shadow=""
          bgColor="#FAFAFA"
          position="fixed"
          top="14"
          zIndex="100"
          align="center"
          left="0"
          className="border-b"
          marginTop="0px"
          marginLeft="0px"
        >
          <div
            style={{ width: "75vw" }}
            className="bg-white px-3 py-2 h-10 rounded-md shadow-md flex items-center gap-2 ml-1"
          >
            <img className="h-5" src="/search.svg" />
            <div className="relative w-full">
              <input
                value={searchTerm}
                // onClick={handleSearchInputClick}
                onChange={handleSearchTermChange}
                className="w-full focus:outline-none"
                placeholder="Search Intelliverse"
              />
              {/* {searchSuggestions.length > 0 && (
                <div className="search-suggestions absolute bg-white rounded-md shadow-md mt-2 w-full">
                  {searchSuggestions.map((suggestion) => (
                    <div
                      key={suggestion}
                      onClick={() => handleSuggestionClick(suggestion)}
                      className="cursor-pointer p-2 hover:bg-gray-100"
                    >
                      {suggestion}
                    </div>
                  ))}
                </div>
              )} */}
            </div>
          </div>

          <div className="flex gap-5 items-center ml-5">
            <Link to="/settings">
              <img
                className="hover:scale-110 hover:transition duration-200 cursor-pointer h-7"
                src="/setting.svg"
              />
            </Link>
            <ProfileNav setLogin={setLogin} />
          </div>
        </Flex>
      )}
    </>
  );
};

export default NavBox;
