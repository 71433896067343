import { useContext, useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import html2canvas from "html2canvas";
import axios from "axios";
import { baseURL } from "../../../index";
import TextButton from "../../../util/Buttons/TextButton";
import { Input, useToast } from "@chakra-ui/react";
import NavContext from "../../NavContext";
import Timer from "../../../util/VisionUtils/Timer";
import DisplayImage from "../utils/DisplayImage";
import CommonProgressBar from "../Components/CommonProgressBar";

const LiveOutput = ({ projectId }) => {
  const [progressState, setProgressState] = useState({
    open: false,
    state: null,
  });
  const [initialRender, setInitialRender] = useState(true);
  const [rtsp, setRtsp] = useState("");
  const [outputStream, setOutputStream] = useState([]);
  const [hlsLink, setHlsLink] = useState("");
  const toast = useToast();
  const { auth } = useContext(NavContext);
  const [viewImage, setViewImage] = useState(null);
  const [output, setOutput] = useState([]);
  const header = ["TagName", "Prob"];
  const [startCapture, setStartCapture] = useState(false);

  const dataURItoBlob = (dataURI) => {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleScreenshot = async () => {
    try {
      const elem = document.getElementById("#video");
      const canvas = await html2canvas(elem);

      const screenshot = canvas.toDataURL();

      const blob = dataURItoBlob(screenshot);

      const formData = new FormData();
      formData.append("files", blob, "files.png");
      const param = { projectId: projectId };
      const response = await axios.post(
        baseURL + "selfserve/v1/published/v1/acv/predict/file/",
        formData,
        { params: param, headers: { "Content-Type": "multipart/form-data" } }
      );

      if (response?.status === 200) {
        setViewImage(screenshot);
        let data = response?.data?.results;
        if (data?.length > 0) {
          let hasPoints = data[0]?.predictions?.some((item) =>
            item?.hasOwnProperty("boundingBox")
          );
          if (!hasPoints) setOutput(data[0]?.predictions);
          else {
            let topPrediction = data[0]?.predictions[0];
            let x1 = topPrediction?.boundingBox?.left;
            let y1 = topPrediction?.boundingBox?.top;
            let x2 = x1 + topPrediction?.boundingBox?.width;
            let y2 = y1 + topPrediction?.boundingBox?.height;
            let obj = [
              {
                points: [
                  [x1, y1],
                  [x1, y2],
                  [x2, y2],
                  [x2, y1],
                ],
                label: topPrediction?.tagName,
                precision: topPrediction?.probability,
              },
            ];
            let newData = data[0]?.predictions?.map((item, idx) => {
              if (idx === 0)
                return {
                  ...item,
                  annotationData: obj,
                };
              else return item;
            });
            setOutput(newData);
          }
          let obj = {
            img: screenshot,
            tagName: data[0]?.predictions?.[0]?.tagName,
            timestamp: new Date().toLocaleString("en-IN"),
          };
          setOutputStream((prev) => [obj, ...prev]);
        } else setOutput([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handlRtspAdd = async () => {
    setProgressState((prev) => ({ ...prev, open: true, state: 1 }));
    try {
      const requestData = JSON.stringify({
        uri: rtsp,
        alias: "liveRtsp",
      });
      const response = await axios.post(
        "https://rtsp.backend-ripik.com/start",
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response?.status === 200) {
        let data = response?.data;
        if (data?.running) {
          let hlsUrl = "https://rtsp.backend-ripik.com" + data?.uri;
          setHlsLink(hlsUrl);
          setProgressState((prev) => ({ ...prev, state: 2 }));
        } else {
          toast({
            title: "Rtsp error",
            description: "Error occured in processing rtsp",
            status: "error",
            position: "top-right",
            duration: 6000,
            isClosable: true,
          });
          setProgressState((prev) => ({ ...prev, state: 0 }));
        }
      }
    } catch (error) {
      toast({
        title: error?.response?.status + " Error",
        description: error?.response?.data?.message,
        status: "error",
        duration: 6000,
        isClosable: true,
        position: "top-right",
      });
      console.log(error);
    }
  };

  const playerWrapper = ({ children }) => (
    <div
      className="h-full w-full rounded flex justify-center items-center bg-black"
      id="#video"
    >
      {children}
    </div>
  );

  useEffect(() => {
    if (startCapture) {
      setTimeout(() => {
        handleScreenshot();
      }, 1000);
    }
  }, [startCapture]);

  return (
    <div className="grid grid-cols-3 gap-2 h-fit w-full">
      {hlsLink !== "" ? (
        <>
          <div className="col-span-full text-end whitespace-nowrap">
            <Timer
              initialSeconds={15}
              callFunc={startCapture ? handleScreenshot : () => {}}
              initialRender={initialRender}
              setInitialRender={setInitialRender}
            />
          </div>
          <div className="flex flex-col gap-2 w-full">
            <p>Live running feed</p>
            <ReactPlayer
              url={hlsLink}
              playing={true}
              muted={true}
              height="full"
              wrapper={playerWrapper}
              width="100%"
              style={{
                borderRadius: "4px",
              }}
              onReady={() => setStartCapture(true)}
            />
          </div>
          <div className="flex flex-1 flex-col gap-2">
            <p>
              Captured frame:{" "}
              {!initialRender && new Date()?.toLocaleString("en-IN")}
            </p>
            <div className="h-full w-full rounded flex justify-center items-center bg-black">
              {!output[0]?.hasOwnProperty("boundingBox") ? (
                <img
                  className="h-full w-full rounded"
                  src={viewImage}
                  alt="image"
                  loading="eager"
                />
              ) : (
                <DisplayImage
                  url={viewImage}
                  width={210}
                  height={210}
                  annotationData={output[0]?.annotationData}
                />
              )}
            </div>
          </div>
          <div className="flex flex-1 flex-col gap-2">
            <p>Output</p>
            <div className="grid grid-cols-2 gap-x-[46px] gap-y-4 pt-4 px-4 bg-[#FAFAFA] border border-[#EBEBEB] rounded">
              {header?.map((item) => {
                return <p className="text-[#938F96] text-base">{item}</p>;
              })}
              <div className="border-b border-[#EBEBEB] col-span-2" />
              {output?.map((item) => {
                return (
                  <>
                    <p className="text-[#605D64] text-base font-medium">
                      {item?.tagName}
                    </p>
                    <p
                      className="text-base font-medium"
                      style={{
                        color:
                          Math.round(item?.probability * 100) <= 30
                            ? "#FF0000"
                            : "#008000",
                      }}
                    >
                      {Math.round(item?.probability * 100)}%
                    </p>
                    <div className="border-b border-[#EBEBEB] col-span-2" />
                  </>
                );
              })}
            </div>
          </div>
          <div className="col-span-full flex gap-2 flex-wrap">
            {outputStream?.map((item) => {
              return (
                <div className="h-[210px] w-[210px] rounded relative">
                  <img
                    src={item?.img}
                    className="h-full w-full rounded"
                    alt="noImage"
                  />
                  <p className="absolute rounded p-1 top-2 right-2 bg-black bg-opacity-60 text-white text-sm">
                    {item?.tagName}
                  </p>
                  <p className="absolute rounded p-1 bottom-2 left-2 bg-black bg-opacity-60 text-white text-sm">
                    {item?.timestamp}
                  </p>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <div className="col-span-full h-[50vh] flex gap-2 justify-center border-2 border-dashed border-blue-800 items-center">
          <Input
            placeholder="Please add rtsp"
            value={rtsp}
            onChange={(e) => setRtsp(e.target.value)}
            width={"fit-content"}
          />
          <TextButton
            text={"Add rtsp"}
            width={"fit-content"}
            onClick={handlRtspAdd}
          />
        </div>
      )}
      {progressState?.open && progressState?.state && (
        <CommonProgressBar
          openModal={progressState?.open}
          closeModal={() =>
            setProgressState((prev) => ({
              ...prev,
              open: false,
              state: null,
            }))
          }
          state={progressState?.state}
        />
      )}
    </div>
  );
};

export default LiveOutput;
