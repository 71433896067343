import { Info } from "@mui/icons-material";

const AddLogo = ({
  logo,
  setLogo,
  toast,
  logoPreview,
  setLogoPreview,
  submitClicked,
  desiredRatio,
  text,
  info,
  disable,
  setFieledsChange,
}) => {
  //   console.log(
  //     // logo,
  //     // setLogo,
  //     // toast,
  //     logoPreview,
  //     setLogoPreview
  //     // submitClicked
  //     //   desiredRatio,
  //     // text,
  //     // info
  //   );
  const handleImageUpload = (files) => {
    const imageFile = files[0];
    if (imageFile) {
      const image = new Image();
      image.src = URL.createObjectURL(imageFile);

      image.onload = () => {
        const ratio = info === "1" || info == "3" ? 1 / 1 : 3 / 2; // 6:1 ratio

        const currentRatio = image.width / image.height;
        console.log(ratio === currentRatio);
        if (currentRatio === ratio || info == "2") {
          setLogo(imageFile);
          setLogoPreview(URL.createObjectURL(imageFile));
          setFieledsChange(true);
        } else {
          toast({
            title: "Invalid Image Dimensions",
            description: `Please upload an image with ratio ${desiredRatio}.`,
            status: "error",
            duration: 4000,
            isClosable: true,
            position: "top",
          });
          setLogo(null);
          setLogoPreview(null);
        }
      };
    }
  };

  return (
    <div className="mt-5">
      <p className="text-[#79767D] text-sm font-medium mb-1">
        Upload company logo
      </p>
      <p className="text-[#79767D] font-medium text-sm mb-3">
        ( Supported formats: .png, .svg, .jpeg )
      </p>
      <div className="flex items-center gap-2">
        {!disable && (
          <div
            className={`w-[140px] rounded-md text-[white] border bg-[#6CA6FC] h-8`}
          >
            <label
              htmlFor={`${info}-image-uploader`}
              className="cursor-pointer"
            >
              <div className="flex justify-evenly py-1">
                <img src="/superadmin/upload.png" alt="upload" />
                <p>{"Upload logo"}</p>
              </div>
            </label>
            <input
              type="file"
              accept="image/*"
              name={`${info}-image-uploader`}
              id={`${info}-image-uploader`}
              style={{ display: "none" }}
              onChange={(e) => handleImageUpload(e.target.files)}
            />
          </div>
        )}
        {/* {info !== "3" &&
          info !== "4" &&
          info != "2" &&
          submitClicked &&
          !logo && (
            <div className="text-red-500 text-sm mt-1">
              Upload image in the above-mentioned ratio
            </div>
          )} */}
        {logoPreview && (
          <div
            className={`flex items-center w-[50px] h-[50px] ${
              disable ? "mt-4" : ""
            }`}
          >
            <img
              src={logoPreview}
              alt="Logo Preview"
              className="w-full"
              // style={{ width: "180px", height: "30px", marginTop: "10px" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AddLogo;
