import axios from "axios";
import { baseURL } from "../../..";
import { dummyAlertsData } from "../utilis/dummyData";

export const getInitialDataApi = async (auth, requestData) => {
  try {
    const res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/info/initialize/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    const data = res?.data?.cameraGpIds[0];
    if (data) {
      return {
        success: true,
        data,
      };
    } else {
      throw new Error("Error while fetching data!");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error while fetching data!");
  }
};

export const getImageAndAllBurnersDataApi = async (auth, requestData) => {
  try {
    let res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/analysis/overview/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    const data = res?.data;
    if (data?.maximumTemperature && Array.isArray(data.maximumTemperature)) {
      let count = 0;
      let total = 0;
      for (let i = 0; i < data.maximumTemperature.length; i++) {
        if (data?.maximumTemperature[i]) {
          total += data.maximumTemperature[i];
          count++;
        }
      }
      const average = count > 0 ? total / count : 0;
      data["overallBedTemperature"] = average;
    }
    if (data) {
      return {
        success: true,
        data,
      };
    } else {
      throw new Error("Error while fetching data!");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error while fetching data!");
  }
};

export const getTotalSulphurAndOverallBeltTemperatureApi = async (
  auth,
  requestData
) => {
  try {
    let res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/analysis/data/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data,
      };
    } else {
      throw new Error("Error while fetching data!");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error while fetching data!");
  }
};
export const getIndividualBurnersDataApi = async (
  auth,
  requestData,
  isIndividual = false,
  cameraInfo = null
) => {
  try {
    let res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/analytics/history/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    const data = res?.data;
    if (data) {
      if (isIndividual) {
        return {
          success: true,
          data,
        };
      } else {
        if (cameraInfo && Array.isArray(cameraInfo)) {
          const tempData = {};
          cameraInfo?.forEach((el, index) => {
            tempData[el.name] = data?.[el.cameraId]?.data || [];
            if (index === 0) {
              tempData["times"] = data[el.cameraId]?.times || [];
            }
          });
          return {
            success: true,
            data: tempData,
          };
        } else {
          throw new Error("Error while fetching data");
        }
      }
    } else {
      throw new Error("Error while fetching data!");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error while fetching data!");
  }
};
export const getThermocoupleTemperatureReportDataApi = async (
  auth,
  requestData
) => {
  try {
    let res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/history/single/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data,
      };
    } else {
      throw new Error("Error while fetching data!");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error while fetching data!");
  }
};

export const downloadReportDataApi = async (auth, requestData) => {
  try {
    const res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/report/download/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
        responseType: "arraybuffer",
      }
    );
    if (res?.data) {
      return {
        success: true,
        data: res.data,
      };
    } else {
      throw new Error("Error while downloading report!");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error while downloading report!");
  }
};

export const getAlertsDataApi = async (auth, requestData, setLoading) => {
  try {
    setLoading((prev) => true);
    let res = dummyAlertsData;

    const data = res?.data;
    if (data) {
      const { columns, tableData } = data;
      return {
        success: true,
        data: { columns, tableData: tableData },
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
  } finally {
    setLoading((prev) => false);
  }
};

export const getVideoDataApi = async (auth, requestData, setLoading) => {
  try {
    setLoading(true);
    const res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/feedLibrary/video/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    const data = res.data;
    if (data) {
      if (data?.success) {
        return {
          success: data?.success,
          url: data?.url,
          message: data?.message,
        };
      } else {
        throw new Error("Error while generating video!");
      }
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error while generating video!");
  } finally {
    setLoading(false);
  }
};

export const getDetailModalDataApi = async (auth, requestData, setLoading) => {
  try {
    return new Promise((resolve, reject) => {
      setLoading(true);
      setTimeout(() => {
        const mockResponseData = {
          success: true,
          data: dummyAlertsData,
        };
        resolve(mockResponseData);
        setLoading(false);
      }, 500);
    });
    // const response = await axios.post(`${baseURL}/endpoint`, requestData, {
    //   credentials: "same-origin",
    //   headers: {
    //     "Content-Type": "application/json",
    //     "X-Auth-Token": auth,
    //   },
    // });
    // const data = response.data;
    // if (data) {
    //   return {
    //     success: true,
    //     data,
    //   };
    // } else {
    //   throw new Error("Error while fetching data!");
    // }
  } catch (error) {
    console.error(error);
    throw new Error("Error while fetching data!");
  }
};
