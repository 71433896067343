import { useWindowSize } from "@uidotdev/usehooks";
import CamCard from "./CamCard";
import DetailModal from "./DetailModal";
import React, { useState } from "react";

const Capitalize = (str) => {
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
};

const getAvgMps = (data) => {
  let count = 0;
  let mps = 0;
  for (let camName in data) {
    if (data?.[camName]?.[0]?.showOverallMps) {
      mps += data?.[camName]?.[0]?.mps ?? 0;
      count++;
    }
  }
  if (count === 0) return false;
  return (mps / count)?.toFixed(2);
};

const PlantCard = ({ PlantName, CamData, thresholds }) => {
  const size = useWindowSize();
  const [openModal, setOpenModal] = useState(false);
  let totalAlerts = [];
  let totalData = [];
  Object.keys(CamData)?.map((cam) => {
    let data = CamData?.[cam]?.[0];
    if (data && data?.noCoal !== 1) {
      totalAlerts?.push(data?.["alertMessages"]?.length);
      if (data?.["alertMessages"]?.length > 0) totalData?.push(data);
    } else totalAlerts?.push(0);
  });
  let sum = totalAlerts?.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);
  return (
    <div
      className={`flex flex-col bg-white rounded-xl pt-4 gap-1 col-span-2 ${
        Object.keys(CamData).length > 1 ? "xl:col-span-2" : "xl:col-span-1"
      }`}
    >
      <div className="flex justify-between gap-2 sm:gap-3 pl-6 pr-6">
        <div className="flex flex-col sm:flex-row sm:items-center gap-2">
          <p className="text-xl font-medium">{Capitalize(PlantName)}</p>
          {getAvgMps(CamData) && (
            <div className="flex bg-[#F75C03] px-2 py-1 gap-[10px] rounded w-full">
              <p className="text-[#FFFFFF] sm:font-semibold truncate w-full sm:text-xl">
                {size?.width > 768
                  ? "Overall Mean Particle Size"
                  : "Overall MPS"}{" "}
                <span className="sm:font-bold">{getAvgMps(CamData)} mm</span>
              </p>
            </div>
          )}
        </div>
        {sum !== 0 && (
          <div className="flex gap-4 items-end sm:items-center whitespace-nowrap">
            <p className="rounded-lg border-2 text-xs border-red-400 pl-2 pr-2 pt-1 pb-1 bg-[#F9DEDC]">
              {sum} alerts
            </p>
            <p
              className="text-[#DC362E] text-sm font-medium cursor-pointer"
              onClick={() => setOpenModal(true)}
            >
              See detail
            </p>
            {openModal && (
              <DetailModal
                openModal={openModal}
                closeModal={() => setOpenModal(false)}
                data={totalData}
                index={0}
              />
            )}
          </div>
        )}
      </div>
      <div
        className={`grid grid-cols-1 ${
          Object.keys(CamData).length > 1 ? "xl:grid-cols-2" : "xl:grid-cols-1"
        }`}
      >
        {Object.keys(CamData)?.map((cam, idx) => {
          return (
            <CamCard
              plantId={PlantName}
              cameraName={cam}
              data={CamData[cam][0] ?? null}
              alert={totalAlerts?.[idx]}
              thresholds={thresholds}
            />
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(PlantCard);
