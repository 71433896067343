import { useToast } from "@chakra-ui/react";
import { useContext, useEffect, useId, useRef, useState } from "react";
import NavContext from "../../NavContext";
import * as XLSX from "xlsx";
import { postConcApi } from "../BlendComplianceTracking";
import BctContext from "../BctContext";

const FileUploader = ({ file, setFile, setConcData }) => {
  //init, success
  // 0 no file 1 success
  const [idx, setIdx] = useState(0);
  const [isCorrect, setIsCorrect] = useState(true);
  const { conc, setConc } = useContext(BctContext);
  const bg = ["#FFFFED", "#CDEEBF33"];
  const color = ["#FFC107", "#69B04B"];
  const state = ["fileYellow", "fileSuccess"];
  const toast = useToast();
  const { auth } = useContext(NavContext);
  const fileRef = useRef();

  const handleFileDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files[0];
    if (
      files.type !=
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    )
      setIsCorrect(false);
    else {
      setFile(files);
      setIsCorrect(true);
    }
  };

  const handleFileChange = () => {
    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const excelData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
        let outData = [];
        let newNames = [];
        excelData.forEach((item) => {
          const concentrateKey = Object.keys(item).find((key) =>
            key.toLowerCase().includes("concentrate")
          );
          const blendKey = Object.keys(item).find((key) =>
            key.toLowerCase().includes("blend")
          );
          let obj = {};
          if (concentrateKey && blendKey) {
            const concentrateName = item[concentrateKey].trim();
            obj["conc"] = concentrateName;
            obj["ratio"] = item[blendKey];
            if (
              !conc.some(
                (item) => item.toLowerCase() == concentrateName.toLowerCase()
              )
            ) {
              newNames.push(concentrateName);
            }
          }
          outData.push(obj);
        });
        if (newNames.length > 0)
          postConcApi(auth, [...conc, ...newNames], toast, setConc);
        setConcData(outData);
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const handleDummyDownload = () => {
    const data = [
      ["Concentrate", "Blend Ratio %"],
      ["Carapateena", 27.5],
      ["Centinela", 10],
    ];
    const ws = XLSX.utils.aoa_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    XLSX.writeFile(wb, "dummyBlend.xlsx");
  };

  useEffect(() => {
    if (file) {
      setIdx(1);
      handleFileChange();
    } else setIdx(0);
  }, [file]);

  return (
    <div
      className="w-full py-3 pl-6 pr-[60x] border-[1.5px] border-dashed flex gap-4 items-center rounded relative"
      style={{ borderColor: color[idx], backgroundColor: bg[idx] }}
      onDrop={handleFileDrop}
      onDragOver={(e) => e.preventDefault()}
    >
      <input
        ref={fileRef}
        type="file"
        hidden
        accept=".xlsx"
        onChange={(e) => {
          setFile(e.target.files[0]);
        }}
      />
      <img src={`/BlendComplianceIcons/${state[idx]}.svg`} alt="file" />
      {idx == 0 ? (
        <div className="flex flex-col gap-2">
          <p className="text-[#3E3C42] text-sm">
            Drag and Drop file or{" "}
            <span
              className="text-[#447ED4] font-medium cursor-pointer"
              onClick={() => fileRef.current.click()}
            >
              Choose file{" "}
            </span>{" "}
            to upload
          </p>
          {isCorrect ? (
            <p className="text-[#79767D] text-sm">Supported formats: .xlsx.</p>
          ) : (
            <p className="text-[#DC362E] text-sm">
              Incorrect data format or column headers. Please upload correct
              file.
            </p>
          )}
          <div
            className="absolute right-1 cursor-pointer hover:scale-105 top-0 flex items-center text-base font-medium"
            onClick={handleDummyDownload}
          >
            <img src="/download.svg" alt="download" className="w-5 h-5" />
            Sample
          </div>
        </div>
      ) : (
        <div className="flex gap-4 items-center">
          <div className="flex gap-2 items-center text-sm text-[#3E3C42]">
            <img src={`/BlendComplianceIcons/filexlsx.svg`} alt="file" />
            <p className="font-medium">{file?.name}</p>
            <p>successfully uploaded</p>
          </div>
          <img
            src="/SinterflameIcons/cross.svg"
            className="hover:scale-105 cursor-pointer"
            onClick={() => {
              setFile(null);
              setConcData([]);
              fileRef.current.value = "";
            }}
            alt="cross"
          />
        </div>
      )}
    </div>
  );
};

export default FileUploader;
