import React, { useContext, useEffect, useState } from "react";
import NavContext from "../../NavContext";
import { useParams } from "react-router-dom";
import { Select, Spinner } from "@chakra-ui/react";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import {
  BeltPermeabilityTrendLineChart,
  VNotchTrendLineChart,
} from "../../Charts/SinterBeltCharts/TrendLineChart";
import { getReportsGraphDataApi } from "../services/reportServices";
import { MoodBad } from "@mui/icons-material";

const GraphComponent = ({ plantId, type }) => {
  // refs and params
  const { auth } = useContext(NavContext);
  const param = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedRange, setSelectedRange] = useState("last7Days");
  const [filters, setFilters] = useState({
    selectedPlant: plantId,
    fromTime: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16),
    toTime: new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16),
  });
  const [data, setData] = useState(null);
  const handleRangeSelect = (e) => {
    setSelectedRange(e.target.value);
    if (e.target.value === "last7Days") {
      setFilters((prev) => ({
        ...prev,
        fromTime: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 16),
        toTime: new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 16),
      }));
    }
  };
  const getData = async () => {
    try {
      const requestData = {
        clientId: param.clientId.toLowerCase(),
        useCase: "SINTERBELT",
        cameraId: "all",
        plantName: filters.selectedPlant,
        startDate: new Date(filters.fromTime).getTime() + 5.5 * 60 * 60 * 1000,
        endDate: new Date(filters.toTime).getTime() + 5.5 * 60 * 60 * 1000,
      };
      const response = await getReportsGraphDataApi(
        auth,
        requestData,
        setLoading,
        type
      );
      if (response.success) {
        setData((prev) => response.data);
      } else {
        throw new Error("Error fetching graphs data");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="pt-6 bg-white rounded-xl flex flex-col gap-3">
      <div className="flex flex-row gap-5 justify-between w-full items-center px-2 overflow-x-auto py-2">
        <p className="text-[16px] font-bold whitespace-nowrap">
          {type === "vNotchGratebar"
            ? "V Notch / Grate Bar Missing"
            : "Overall Belt Permeability"}
        </p>
        <div className="gap-6 flex flex-row items-center">
          <div className="min-w-[110px]">
            <Select
              borderColor="#CAC5CD"
              color="#605D64"
              value={selectedRange}
              variant="outline"
              rounded={"base"}
              className="!text-sm !font-medium !text-[#605D64]"
              onChange={(e) => handleRangeSelect(e)}
            >
              <option
                key="Last 7 days"
                value={"last7Days"}
              >
                Last 7 days
              </option>
              <option
                key="custom"
                value={"custom"}
              >
                Custom
              </option>
            </Select>
          </div>
          {selectedRange === "custom" && (
            <div className="min-w-[110px]">
              <FloatingInput
                text="From"
                type="datetime-local"
                setDateTime={(value) =>
                  setFilters((prev) => ({ ...prev, fromTime: value }))
                }
                value={filters.fromTime}
                min={"2021-01-01T00:00"}
                max={new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
                  .toISOString()
                  .slice(0, 16)}
              />
            </div>
          )}
          {selectedRange === "custom" && (
            <div className="min-w-[110px]">
              <FloatingInput
                text="To"
                type="datetime-local"
                setDateTime={(value) =>
                  setFilters((prev) => ({ ...prev, toTime: value }))
                }
                value={filters.toTime}
                min={filters.fromTime}
                max={new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
                  .toISOString()
                  .slice(0, 16)}
              />
            </div>
          )}

          <SecondaryButton
            height={"40px"}
            width={"80px"}
            text={loading ? <Spinner /> : "Apply"}
            onClick={getData}
            disable={!filters.fromTime || !filters.toTime || loading}
          />
        </div>
      </div>
      {loading ? (
        <>Loading</>
      ) : data ? (
        <div className="h-[50vh] w-full">
          {type === "vNotchGratebar" ? (
            <VNotchTrendLineChart chartData={data} />
          ) : (
            <BeltPermeabilityTrendLineChart chartData={data} />
          )}
        </div>
      ) : (
        <div className="h-[50vh] w-full flex flex-row items-center justify-center border rounded-md">
          <div className="flex flex-row items-center gap-2 text-gray-400">
            <MoodBad fontSize={"large"} />
            <h2 className="text-2xl">No Data!</h2>
          </div>
        </div>
      )}
    </div>
  );
};

const ReportsGraph = React.memo(GraphComponent);
export { ReportsGraph };
