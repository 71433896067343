import {
  Input,
  Radio,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  useToast,
  Select,
  Stack,
  Textarea,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  Button,
  Text,
  CloseButton,
  IconButton,
} from "@chakra-ui/react";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

import { useState, useRef, useEffect, useContext } from "react";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import TextButton from "../../../util/Buttons/TextButton";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import TonalButton from "../../../util/Buttons/TonalButton";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../index";
import NavContext from "../../NavContext";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import AddLogo from "./Components/AddLogo";
import AddCardLogo from "./Components/AddCardLogo";
import DiscardModal from "../DiscardModal";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DraftModal from "../DraftModal";
import ClientSelectLogo from "./Components/ClientSelectLogo";
import AddClientRelation from "./Components/AddClientRelation";
import CompanySizeDetails from "./Components/CompanySizeDetails";
import CompanyType from "./Components/CompanyType";

const initialState = {
  clientName: "",
  parentCompany: "",
  companyType: "",
  industry: "",
  subIndustry: "",
  companySize: "",
  numberOfUsers: "",
  clientHqLocation: "",
  hqLocationAddress: "",
  totalClientLocation: "",
  enterAllLocation: "",
  clientAllLocation: "",
  clientPrimContactName: "",
  clientPrimEmail: "",
  clientContactNumber: "",
  clientSecContactName: "",
  clientSecEmail: "",
  clientSecContactNumber: "",
  ripikContactPrimName: "",
  ripikContactEmail: "",
  ripikContactNumber: "",
  ripikContactSecName: "",
  ripikContactSecEmail: "",
  ripikContactSecNumber: "",
  creationAt: null,
  lastUpdatedDate: null,
  clientRelStartDate: "",
  ripikProductUseDate: "",
  dealValue: "",
  remarks: "",
  purchaseOrderCode: "",
  status: null,
  clientId: "",
  logo32url: "",
};

const CreateClient = ({ setShowClientTab }) => {
  const { auth } = useContext(NavContext);
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [formData, setFormData] = useState({ ...initialState });
  const [editableIndex, setEditableIndex] = useState(null);
  const [companyType, setCompanyType] = useState();

  const [companySize, setCompanySize] = useState("");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [error, setError] = useState({
    clientName: "",
  });
  const [selectedCurrency, setSelectedCurrency] = useState("₹");
  const [selectedCountryCodeClient, setSelectedCountryCodeClient] =
    useState("+91");
  const [secSelectedCountryCodeClient, setSecSelectedCountryCodeClient] =
    useState("+91");
  const [selectedCountryCodeRipik, setSelectedCountryCodeRipik] =
    useState("+91");
  const [secSelectedCountryCodeRipik, setSecSelectedCountryCodeRipik] =
    useState("+91");

  const toast = useToast();
  const [cardLogo, setCardLogo] = useState(null);
  const [cardLogoPreview, setCardLogoPreview] = useState(null);
  const [locations, setLocations] = useState([]);
  const [isAddCompanyLocation, setAddCompanyLocation] = useState(false);
  const [errorAllLocations, setErrorAllLocations] = useState("");
  const [existingClientNames, setExistingClientNames] = useState([]);
  const [relationDate, setRelationDate] = useState(null);
  const [clientNameError, setClientNameError] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isfieldsChanged, setFieledsChange] = useState(false);
  const [review, setReview] = useState(false);
  const [isSaved, setSaved] = useState(false);
  const [productDate, setProductDate] = useState(null);
  const [mode, setMode] = useState({
    isdeleted: false,
    status: 1,
    isSavedDraft: false,
  });
  const [icon, setIcon] = useState("Select default logo");
  const [defaultIcon, setDefaultIcon] = useState(null);
  const [logoList, setLogoList] = useState(null);
  let isFormDataEmpty;

  const [validationErrors, setValidationErrors] = useState({
    clientName: "",
    parentCompany: "",
    companyType: "",
    industry: "",
    subIndustry: "",
    companySize: "",
    numberOfUsers: "",
    clientHqLocation: "",
    hqLocationAddress: "",
    totalClientLocation: "",
    enterAllLocation: "",
    clientAllLocation: "",
    clientPrimContactName: "",
    clientPrimEmail: "",
    clientContactNumber: "",
    clientSecContactName: "",
    clientSecContactEmail: "",
    clientSecContactNumber: "",
    ripikContactPrimName: "",
    ripikContactEmail: "",
    ripikContactNumber: "",
    ripikContactSecName: "",
    ripikContactSecEmail: "",
    ripikContactSecNumber: "",
    clientRelStartDate: "",
    ripikProductUseDate: "",
    dealValue: "",
    remarks: "",
    purchaseOrderCode: "",
  });
  const countryCodes = ["+91", "+1", "+44", "+81"];

  const dataOptions = {
    companyType: {
      type: ["Public", "Private", "Others"],
    },
    industry: {
      type: [
        "Aerospace",
        "Artificial Intelligence",
        "Automotive",
        "Chemical",
        "Construction",
        "Consumer goods",
        "Defence",
        "Energy",
        "Food & Beverage",
        "Glass",
        "Healthcare",
        "Information Technology",
        "Metal",
        "Pharmaceutical",
        "Software Development",
        "Others",
      ],
    },
  };

  const fetchClients = async () => {
    try {
      const response = await axios.get(baseURL + "iam/fetchClient", {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });

      const clientNames = response?.data?.data;
      setExistingClientNames(clientNames);
    } catch (error) {
      console.log(error);
    }
  };

  // const handleDiscard = () => {
  //   setIsModalOpen(true);
  // };

  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  // };

  const handleConfirmDiscard = () => {
    setFormData((prev) => {
      return initialState;
    });

    setIsModalOpen(false);
    setShowClientTab((prev) => "addclient");
  };

  const isValidEmail = (email) => {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    return emailRegex.test(email.trim());
  };

  const isContactNameValid = (name) => {
    // Regular expression for a simple email validation
    const nameRegex = /^[a-zA-Z ]{2,50}$/;
    return nameRegex.test(name.trim());
  };

  const isValidName = (name) => {
    // Regular expression for a simple name validation
    const nameRegex = /^(?!-)(?!.*-$)[\w\d\s\S]{2,50}$/;

    return nameRegex.test(name.trim());
  };

  const isValidClientPhoneNumber = (phoneNumber) => {
    // Regular expression for validating phone number (assuming digits only)
    const phoneNumberRegex = /^\d+$/;

    return (
      phoneNumber &&
      phoneNumberRegex.test(phoneNumber) &&
      phoneNumber.length >= 8 &&
      phoneNumber.length <= 15
    );
  };
  const isNumbersOnly = (numberOfUsers) => {
    // Define a regular expression pattern for numbers only
    const regexPattern = /^\d+$/;

    // Check if numberOfUsers matches the pattern
    return regexPattern.test(numberOfUsers);
  };

  const handleSubmit = async () => {
    let allLocations;
    if (formData?.clientAllLocation?.trim() !== "") {
      const updatedLocations = [...locations, formData.clientAllLocation];

      // Join all locations into a single string
      allLocations = updatedLocations?.filter(Boolean).join(",");
      setFormData((prevData) => ({
        ...prevData,
        clientAllLocation: "", // Clear the enterAllLocation after adding it to locations
      }));
      formData.totalClientLocation = updatedLocations?.filter(
        (loc) => loc.trim() !== ""
      )?.length;
      setAddCompanyLocation(null);
    } else {
      allLocations = locations?.filter(Boolean).join(",");

      formData.totalClientLocation = locations?.filter(
        (loc) => loc.trim() !== ""
      ).length;
    }

    if (formData.status == 0) {
      // hiding the save button after editing
      setEditableIndex(null);
    }

    // formData.enterAllLocation = allLocations;

    formData.creationAt = new Date().getTime();
    formData.lastUpdatedDate = new Date().getTime();
    formData.clientRelStartDate = relationDate;
    formData.ripikProductUseDate = productDate;
    formData.companyType = companyType;
    formData.companySize = companySize;
    formData.clientContactCode = selectedCountryCodeClient;
    formData.clientSecContactCode = secSelectedCountryCodeClient;
    formData.ripikContactCode = selectedCountryCodeRipik;
    formData.ripikContactSecCode = secSelectedCountryCodeRipik;
    formData.dealCurrency = selectedCurrency;
    // Set submitClicked to true
    setSubmitClicked(true);
    const newValidationErrors = {};
    if (clientNameError) {
      //if client already exists
      return;
    }

    if (!formData?.clientName || !isValidName(formData?.clientName)) {
      if (!formData?.clientName || !isValidName(formData?.clientName)) {
        newValidationErrors.clientName = "Please enter a valid name";
      } else if (
        formData?.clientName.length < 2 ||
        formData?.clientName.length > 50
      ) {
        if (formData?.clientName.length < 2) {
          newValidationErrors.clientName = "Minimum 2 characters required";
        } else if (formData?.clientName.length > 50) {
          newValidationErrors.clientName = `Exceeded the character limit of 50 `;
        }
      }
    }
    if (!formData.companyType) {
      newValidationErrors.companyType = "Please add company type";
    }
    console.log(allLocations);
    if (!allLocations) {
      // checking location field
      newValidationErrors.clientAllLocation = "Please add location";
    }
    if (formData?.parentCompany.length > 50) {
      newValidationErrors.parentCompany = `You have exceeded the character limit of 50 by ${
        formData?.parentCompany.length - 50
      }`;
    }

    if (formData?.subIndustry.length > 50) {
      newValidationErrors.subIndustry = `You have exceeded the character limit of 50 by ${
        formData?.subIndustry.length - 50
      }`;
    }
    if (formData?.numberOfUsers && !isNumbersOnly(formData?.numberOfUsers)) {
      newValidationErrors.numberOfUsers = "Please add numbers only";
    }
    if (formData?.numberOfUsers.length > 50) {
      newValidationErrors.numberOfUsers = `You have exceeded the character limit of 50 by ${
        formData?.numberOfUsers.length - 50
      }`;
    }

    if (formData?.hqLocationAddress.length > 200) {
      newValidationErrors.hqLocationAddress = `You have exceeded the character limit of 200 by ${
        formData?.hqLocationAddress.length - 200
      }`;
    }
    if (formData?.clientHqLocation.length > 50) {
      newValidationErrors.clientHqLocation = `You have exceeded the character limit of 50 by ${
        formData?.clientHqLocation.length - 50
      }`;
    }
    if (formData?.clientAllLocation.length > 50) {
      newValidationErrors.clientAllLocation = `You have exceeded the character limit of 50 by ${
        formData?.clientAllLocation.length - 50
      }`;
    }
    if (
      formData?.clientPrimContactName &&
      !isContactNameValid(formData?.clientPrimContactName)
    ) {
      if (!isContactNameValid(formData?.clientPrimContactName)) {
        newValidationErrors.clientPrimContactName = "Please enter a valid name";
      } else {
        if (formData?.clientPrimContactName.length > 50) {
          newValidationErrors.clientPrimContactName = `You have exceeded the character limit of 50 by ${
            formData?.clientPrimContactName.length - 50
          }`;
        }
      }
    }
    if (formData?.clientPrimEmail && !isValidEmail(formData?.clientPrimEmail)) {
      if (!isValidEmail(formData?.clientPrimEmail)) {
        newValidationErrors.clientPrimEmail = "Please enter a valid email";
      }
    }

    if (
      formData?.clientContactNumber &&
      !isValidClientPhoneNumber(formData?.clientContactNumber)
    ) {
      newValidationErrors.clientContactNumber =
        "Please add a valid phone number";
    }

    if (
      formData?.clientSecContactName &&
      !isContactNameValid(formData?.clientSecContactName)
    ) {
      if (!isContactNameValid(formData?.clientSecContactName)) {
        newValidationErrors.clientSecContactName = "Please enter a valid name";
      } else {
        if (formData?.clientSecContactName.length > 50) {
          newValidationErrors.clientSecContactName = `You have exceeded the character limit of 50 by ${
            formData?.clientSecContactName.length - 50
          }`;
        }
      }
    }
    if (formData?.clientSecEmail && !isValidEmail(formData?.clientSecEmail)) {
      if (!isValidEmail(formData?.clientSecEmail)) {
        newValidationErrors.clientSecContactEmail =
          "Please enter a valid email";
      }
    }

    if (
      formData?.clientSecEmail &&
      formData?.clientPrimEmail &&
      formData?.clientSecEmail === formData?.clientPrimEmail
    ) {
      newValidationErrors.clientSecContactEmail =
        "Primary and secondary email should be different";
    }

    if (
      formData?.clientSecContactNumber &&
      formData?.clientContactNumber &&
      formData?.clientSecContactNumber === formData?.clientContactNumber
    ) {
      newValidationErrors.clientSecContactNumber =
        "Primary and secondary number should be different";
    }

    if (
      formData?.clientSecContactNumber &&
      !isValidClientPhoneNumber(formData?.clientSecContactNumber)
    ) {
      newValidationErrors.clientSecContactNumber =
        "Please add a valid phone number";
    }

    if (
      formData?.ripikContactPrimName &&
      !isContactNameValid(formData?.ripikContactPrimName)
    ) {
      if (!isContactNameValid(formData?.ripikContactPrimName)) {
        newValidationErrors.ripikContactPrimName = "Please enter a valid name";
      } else {
        if (formData?.ripikContactPrimName.length > 50) {
          newValidationErrors.ripikContactPrimName = `You have exceeded the character limit of 50 by ${
            formData?.ripikContactPrimName.length - 50
          }`;
        }
      }
    }
    if (
      formData?.ripikContactEmail &&
      !isValidEmail(formData?.ripikContactEmail)
    ) {
      if (!isValidEmail(formData?.ripikContactEmail)) {
        newValidationErrors.ripikContactEmail = "Please enter a valid email";
      }
    }

    if (
      formData?.ripikContactNumber &&
      !isValidClientPhoneNumber(formData?.ripikContactNumber)
    ) {
      newValidationErrors.ripikContactNumber =
        "Please add a valid phone number";
    }

    if (
      formData?.ripikContactSecName &&
      !isContactNameValid(formData?.ripikContactSecName)
    ) {
      if (!isContactNameValid(formData?.ripikContactSecName)) {
        newValidationErrors.ripikContactSecName = "Please enter a valid name";
      } else {
        if (formData?.ripikContactSecName.length > 50) {
          newValidationErrors.ripikContactSecName = `You have exceeded the character limit of 50 by ${
            formData?.ripikContactSecName.length - 50
          }`;
        }
      }
    }
    if (
      formData?.ripikContactSecEmail &&
      !isValidEmail(formData?.ripikContactSecEmail)
    ) {
      if (!isValidEmail(formData?.ripikContactSecEmail)) {
        newValidationErrors.ripikContactSecEmail = "Please enter a valid email";
      }
    }

    if (
      formData?.ripikContactSecNumber &&
      !isValidClientPhoneNumber(formData?.ripikContactSecNumber)
    ) {
      newValidationErrors.ripikContactSecNumber =
        "Please add a valid phone number";
    }

    if (formData?.dealValue.length > 50) {
      newValidationErrors.dealValue = `You have exceeded the character limit of 50 by ${
        formData?.dealValue.length - 50
      }`;
    }

    if (formData?.purchaseOrderCode.length > 50) {
      newValidationErrors.purchaseOrderCode = `You have exceeded the character limit of 50 by ${
        formData?.purchaseOrderCode.length - 50
      }`;
    }
    if (formData?.remarks.length > 200) {
      newValidationErrors.remarks = `You have exceeded the character limit of 200 by ${
        formData?.remarks.length - 200
      }`;
    }

    const newLocations = allLocations
      ?.split(",")
      ?.map((loc) => loc.trim()?.toLowerCase());

    // Check for duplicates
    if (new Set(newLocations).size !== newLocations.length) {
      newValidationErrors.clientAllLocation =
        "Duplicate locations are not allowed";
      setErrorAllLocations("");
    }

    setValidationErrors(newValidationErrors);
    if (Object.keys(newValidationErrors).length > 0) {
      const requiredKeys = ["clientName", "companyType"];

      // Check if any required field is empty

      const isAnyKeyEmpty = requiredKeys.some((key) => !formData[key]);
      if (isAnyKeyEmpty || !allLocations) {
        setLocations(
          allLocations?.split(",").filter((loc) => loc.trim() !== "")
        );
        setAddCompanyLocation(false);
        toast({
          title: "Incomplete Details",
          description: "Please fill the mandatory fields before submitting",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
        return;
      }
      return;
    }

    formData.clientAllLocation = allLocations; //setting data here to validate the field

    // Clear any previous error
    if (icon === "Select default logo") {
      formData.logo32url = defaultIcon;
    }
    setError("");

    formData.isdeleted = false;

    for (let key in formData) {
      if (
        typeof formData[key] === "string" &&
        formData[key] !== "clientAllLocation"
      ) {
        formData[key] = formData[key]?.trim();
      }
    }
    // console.log(formData);

    const json = JSON.stringify(formData);
    const blob = new Blob([json], {
      type: "application/json",
    });
    const FormData = require("form-data");
    let data = new FormData();
    data.append("data", blob);
    if (icon === "Upload your own logo") {
      data.append("file", cardLogo);
    }

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: baseURL + "iam/addClient",
      headers: {
        "X-Auth-Token": auth,
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        const id = response?.data?.clientId;

        setFormData((prev) => ({
          ...prev,
          clientId: id,
        }));
        if (formData.status == 1) {
          toast({
            title: "Client Added successfully",
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top",
          });

          setTimeout(() => {
            setShowClientTab("addclient");
          }, 1500);
        } else {
          toast({
            title: "Draft saved successfully",
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top",
          });
          setLocations(allLocations?.split(",")); //setting alllocations to update locations

          setFormData((prevData) => ({
            ...prevData,
            clientAllLocation: "",
          }));

          setFieledsChange(false);
        }
      })
      .catch((error) => {
        const err = error?.response;
        console.log("errror..");
        toast({
          title: error?.response?.data?.data,
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });

        setFormData((prevData) => ({
          //emptying the key to avoid data duplication when clicking on save draft again
          ...prevData,
          clientAllLocation: "",
        }));
      });
  };

  const handleChange = (e, index) => {
    const { value, name } = e.target;
    setFieledsChange(true);

    if (name.includes("location-")) {
      // Updating an existing location
      const updatedLocations = [...locations];
      const locationIndex = parseInt(name.split("-")[1], 10);
      updatedLocations[locationIndex] = value;
      setLocations(updatedLocations);
    }

    // Check if the input is the client name
    if (name === "clientName") {
      const trimmedClientName = value.split(" ").join("").toLowerCase().trim();
      const isExistingClient = existingClientNames.includes(trimmedClientName);
      if (isExistingClient) {
        setClientNameError("Client already exists");
      } else {
        setClientNameError("");
      }
    }
    if (
      (name === "hqLocationAddress" || name === "remarks") &&
      value?.trim()?.length > 200
    ) {
      setValidationErrors((prev) => ({
        ...prev,
        [name]: `You have exceeded the character limit of 200 by ${
          value?.trim()?.length - 200
        }`,
      }));
    } else if (
      name !== "hqLocationAddress" &&
      name !== "remarks" &&
      value?.trim()?.length > 50
    ) {
      setValidationErrors((prev) => ({
        ...prev,
        [name]: `You have exceeded the character limit of 50 by ${
          value?.trim()?.length - 50
        }`,
      }));
    } else {
      setValidationErrors({});
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    setErrorAllLocations("");
  };

  const fetchSearchSuggestions = async (term) => {
    const groups = existingClientNames.filter((item) =>
      item.clientName.toLowerCase().includes(term.toLowerCase())
    );

    setSearchSuggestions(groups);
  };

  const handleSearchTermChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
  };

  useEffect(() => {
    const delay = 300; // Adjust the delay as needed
    const timeoutId = setTimeout(() => {
      if (searchTerm) {
        fetchSearchSuggestions(searchTerm);
      } else {
        setSearchSuggestions([]);
      }
    }, delay);

    return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount or searchTerm change
  }, [searchTerm]);

  const handleAddLocation = () => {
    if (formData.clientAllLocation.trim() !== "") {
      const newLocations = formData.clientAllLocation
        .split(",")
        .map((loc) => loc.trim());

      const allLocations = [...locations, ...newLocations];
      const duplicate = allLocations?.map((loc) => loc?.trim()?.toLowerCase());

      // Check for duplicates
      if (new Set(duplicate).size !== duplicate.length) {
        setErrorAllLocations("Duplicate locations are not allowed");
      }

      // Check for length
      if (duplicate.length > 50) {
        setErrorAllLocations(`Maximum allowed locations is ${50}`);
      }

      // setLocations((prevLocations) => [...prevLocations, ...newLocations]);
      if (editableIndex !== null) {
        // Editing a location
        const updatedLocations = [...locations];
        updatedLocations[editableIndex] = formData.clientAllLocation;
        setLocations(updatedLocations);
        setEditableIndex(null); // Reset editableIndex after editing
      } else {
        // Adding new locations
        setLocations((prevLocations) => [...prevLocations, ...newLocations]);
      }
      setFormData((prevData) => ({
        ...prevData,
        clientAllLocation: "",
      }));
      setValidationErrors((prev) => ({
        ...prev,
        clientAllLocation: "",
      }));
      setAddCompanyLocation(false);
    }

    // setAddCompanyLocation(false);
  };
  const handleEditLocation = (index) => {
    setEditableIndex(index);
    setFormData((prevData) => ({
      ...prevData,
      clientAllLocation: locations[index],
    }));
  };

  const handleSaveLocation = (index) => {
    // saving an edited location
    if (locations[index]?.trim() !== "") {
      setEditableIndex(null);
      setFormData((prevData) => ({
        ...prevData,
        clientAllLocation: "",
      }));
      setValidationErrors((prev) => ({
        ...prev,
        clientAllLocation: "",
      }));
    }
  };

  const handleLocationChange = (e, index) => {
    const updatedLocations = [...locations];
    updatedLocations[index] = e.target.value;

    setLocations(updatedLocations);
    setFormData((prevData) => ({
      ...prevData,
      clientAllLocation: "",
    }));
  };

  const handleAddCompanyLocation = () => {
    setAddCompanyLocation(true);
  };

  const handleBackButton = () => {
    isFormDataEmpty = !Object.values(formData).some(
      (value) => value !== "" && value !== null
    );

    if (
      isFormDataEmpty &&
      !companySize &&
      !companyType &&
      !cardLogo &&
      !defaultIcon &&
      !relationDate &&
      !productDate &&
      !locations
    ) {
      // setFormData(initialState);
      setShowClientTab((prev) => "addclient");
    } else if (isfieldsChanged) {
      setIsModalOpen(true);
    } else {
      // setFormData(initialState);
      setShowClientTab((prev) => "addclient");
    }
  };

  const handleModalSubmit = () => {
    handleAddLocation();
    formData.status = 0;
    handleSubmit(0); //sending status zero to avaoid duplication
  };

  const handlAddClient = () => {
    setMode((prev) => ({
      ...prev,
      isSavedDraft: false,
      status: 1,
    }));
    formData.status = 1;
    handleSubmit();
  };
  useEffect(() => {
    fetchClients();
  }, []);

  return (
    <div className="font-roboto flex flex-col gap-2">
      <div className="flex items-center">
        <div className="cursor-pointer w-8" onClick={handleBackButton}>
          <KeyboardBackspaceIcon sx={{color: "#084298"}} />
        </div>
        <p className="text-[#084298] font-medium text-xl ml-2">
          Add new client
        </p>
      </div>
      <div className="flex flex-col gap-3">
        <div className="p-6 rounded-lg flex flex-col gap-3 bg-white">
          <div className="flex flex-row justify-between items-center">
            <p className="text-[#3E3C42] text-lg font-medium ">
              Company information
            </p>
          </div>

          <div className="flex flex-col md:flex-row items-start gap-4">
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Client name<span className="text-[red] ml-1">*</span>
              </p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="text"
                  name="clientName"
                  value={formData?.clientName || ""}
                  required
                  borderColor={
                    (submitClicked && !isValidName(formData?.clientName)) ||
                    clientNameError
                      ? "red.500"
                      : "gray.300"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                  borderWidth={"2px"}
                  // onChange={(e) => setClientName(e.target.value)}
                  onChange={(e) => {
                    handleChange(e);
                    handleSearchTermChange(e);
                  }}
                />

                {(validationErrors?.clientName || clientNameError) && (
                  <p className="!mb-1 !text-[red] !text-sm">
                    {clientNameError || validationErrors?.clientName}
                  </p>
                )}
              </div>

              {searchSuggestions.length > 0 && (
                <div>
                  <div className="search-suggestions rounded-md mt-2 w-[400px]">
                    <p className="text-red-500 text-sm">
                      Similar client(s) already exist. Please check if your
                      client is duplicate
                    </p>
                  </div>
                  <div className="search-suggestions border bg-white rounded-md shadow-lg shadow-gray-200 mt-2 w-[500px] z-10">
                    <div className="p-2">
                      <p className="text-gray-400 mb-1">Existing clients</p>
                      <div className="grid grid-cols-2 gap-2">
                        {searchSuggestions?.map((item) => (
                          <div className="flex flex-row">
                            <img
                              className="w-[30px] h-[30px]"
                              src={
                                item.logo32url
                                  ? item.logo32url
                                  : "/superadmin/clients/companyLogo.png"
                              }
                              alt=""
                            />
                            <p className="font-medium text-lg ml-1">
                              {item.clientName}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Parent company{" "}
                {/* <span className="text-[#AEA9B1]">(if applicable)</span> */}
              </p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="text"
                  name="parentCompany"
                  value={formData?.parentCompany || ""}
                  borderColor={
                    validationErrors?.parentCompany ? "red.500" : "gray.300"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                  borderWidth={validationErrors?.parentCompany ? "2px" : "2px"}
                  onChange={handleChange}
                />
              </div>
              {validationErrors?.parentCompany && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.parentCompany}
                </p>
              )}
            </div>
          </div>
          {/* <div className="flex flex-col gap-3">
            <p className="text-[#3E3C42] text-lg font-medium">
              Company type<span className="text-[red] ml-1">*</span>
            </p>

            <RadioGroup
              onChange={(value) => {
                setCompanyType(value);
                setFieledsChange(true);
              }}
              value={companyType}
              isDisabled={review}
            >
              <div
                className="flex flex-col md:grid md:grid-cols-4 gap-1 md:gap-2"
                style={{ width: "fit-content" }}
              >
                {dataOptions.companyType.type.map((x) => {
                  return (
                    <div
                      style={{
                        backgroundColor:
                          companyType == x ? "#DDEEFF80" : "#FFF",
                        borderRadius: "8px",
                        mb: "12px",
                        // border
                      }}
                    >
                      <Radio
                        value={x}
                        py={"8px"}
                        pl={"8px"}
                        pr={"12px"}
                        fontSize={"14px"}
                        fontWeight={500}
                        color={"#3E3C42"}
                        _checked={{
                          bg: "#6CA6FC",
                          borderColor: "#6CA6FC",
                        }}
                        _hover={{
                          borderColor: "#6CA6FC",
                        }}
                        borderColor={
                          submitClicked && !companyType ? "red.500" : "gray.300"
                        }
                        disabled={review}
                      >
                        {x}
                      </Radio>
                    </div>
                  );
                })}
              </div>
            </RadioGroup>
            {submitClicked && !companyType && (
              <Text fontSize="sm" mt="1" className="!text-[red]">
                Please select the company type
              </Text>
            )}
          </div> */}
          <CompanyType
            setCompanyType={setCompanyType}
            setFieledsChange={setFieledsChange}
            companyType={companyType}
            review={review}
            dataOptions={dataOptions}
            submitClicked={submitClicked}
          />
          <div className="flex flex-col md:flex-row items-start gap-4 mt-5">
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Industry
              </p>
              <div style={{ width: "fit-content", width: "225px" }}>
                <select
                  value={formData?.industry}
                  disabled={review}
                  name="industry"
                  onChange={handleChange}
                  borderColor={"gray.300"}
                  className="w-full p-2 border-[2px] rounded-lg max-h-[300px] overflow-y-auto"
                >
                  <option value="" disabled selected>
                    Select one
                  </option>
                  {dataOptions.industry.type.map((value) => (
                    <option value={value}>{value}</option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Sub-Industry
                {/* <span className="text-[#AEA9B1] ml-1">(optional)</span> */}
              </p>
              <div style={{ width: "fit-content", width: "225px" }}>
                <Input
                  type="text"
                  name="subIndustry"
                  value={formData?.subIndustry}
                  borderColor={
                    validationErrors?.subIndustry ? "red.500" : "gray.300"
                  }
                  borderWidth={validationErrors?.subIndustry ? "2px" : "2px"}
                  disabled={review}
                  border={review ? "none" : ""}
                  // onChange={(e) => setSubIndustryValue(e.target.value)}
                  onChange={handleChange}
                />
              </div>
              {validationErrors?.subIndustry && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.subIndustry}
                </p>
              )}
            </div>
          </div>
          <div className="mt-5">
            <p className="text-[#3E3C42] text-lg font-medium">
              Select Company logo
            </p>
            <ClientSelectLogo
              icon={icon}
              setIcon={setIcon}
              setCardLogo={setCardLogo}
              defaultIcon={defaultIcon}
              auth={auth}
              setLogoList={setLogoList}
              logoList={logoList}
              cardLogo={cardLogo}
              toast={toast}
              cardLogoPreview={cardLogoPreview}
              setCardLogoPreview={setCardLogoPreview}
              submitClicked={submitClicked}
              setFieledsChange={setFieledsChange}
              setDefaultIcon={setDefaultIcon}
              showDisable={review}
            />
          </div>
        </div>

        <CompanySizeDetails
          setCompanySize={setCompanySize}
          companySize={companySize}
          review={review}
          formData={formData}
          validationErrors={validationErrors}
          handleChange={handleChange}
          setFieledsChange={setFieledsChange}
        />
        <div className="p-6 rounded-lg flex flex-col gap-3 bg-white">
          <p className="text-[#3E3C42] text-lg font-medium ">
            Company’s location
          </p>

          <div className="flex flex-col md:flex-row items-start md:items-center gap-4">
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Client HQ location
              </p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="text"
                  name="clientHqLocation"
                  value={formData?.clientHqLocation}
                  // onChange={(e) => setClientHqLocation(e.target.value)}
                  onChange={handleChange}
                  borderColor={
                    validationErrors?.clientHqLocation ? "red.500" : "gray.300"
                  }
                  borderWidth={
                    validationErrors?.clientHqLocation ? "2px" : "2px"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                />
                {/* {submitClicked && !formData?.clientHqLocation && (
                  <Text color="red.500" fontSize="sm" mt="1">
                    Please enter Client HQ location
                  </Text>
                )} */}
              </div>
              {validationErrors?.clientHqLocation && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.clientHqLocation}
                </p>
              )}
            </div>
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                HQ location address
                {/* <span className="text-[#AEA9B1] mx-1">( if applicable )</span> */}
              </p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="text"
                  name="hqLocationAddress"
                  width={{ base: "220px", lg: "516px" }}
                  borderColor={
                    validationErrors?.hqLocationAddress ? "red.500" : "gray.300"
                  }
                  borderWidth={
                    validationErrors?.hqLocationAddress ? "2px" : "2px"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                  value={formData?.hqLocationAddress}
                  // onChange={(e) => setHqLocationAddress(e.target.value)}
                  onChange={handleChange}
                />
              </div>
              {validationErrors?.hqLocationAddress && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.hqLocationAddress}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col lg:flex-row items-start  gap-4 mb-5">
            <div>
              <div>
                {locations.map((location, index) => (
                  <>
                    <p className="text-[#79767D] text-sm font-medium mb-3">
                      Location {index + 1}
                    </p>
                    <div className="flex flex-col md:flex-row justify-start items-start md:items-center ">
                      <div
                        key={index}
                        className="flex flex-row items-center justify-between gap-1 mb-4  h-10"
                        style={{ width: "fit-content" }}
                      >
                        <Input
                          type="text"
                          name={`location-${index}`}
                          value={location}
                          // border={"none"}
                          isReadOnly={editableIndex !== index}
                          // onChange={(e) => handleChange(e, index)}
                          onChange={(e) => {
                            handleLocationChange(e, index);
                            setFieledsChange(true);
                            setErrorAllLocations("");
                          }}
                          w={{ base: "", md: "475px" }}
                          borderColor={"gray.300"}
                          bgColor={editableIndex == index ? "" : "gray.100"}
                          borderWidth={"2px"}
                          color={editableIndex == index ? "none" : "#AEA9B1"}
                          // color={"#AEA9B1"}
                        />

                        {editableIndex !== index && review === false && (
                          <IconButton
                            aria-label="delete"
                            color="red.600"
                            bg={"none"}
                            _hover={{ bg: "none" }}
                            minW={10}
                            onClick={() => {
                              const updatedLocations = [...locations];

                              updatedLocations.splice(index, 1);

                              setLocations(updatedLocations);
                              setErrorAllLocations("");
                              setFieledsChange(true);
                              setValidationErrors((prev) => ({
                                ...prev,
                                clientAllLocation: "",
                              }));
                              setFormData((prev) => ({
                                ...prev,
                                clientAllLocation: "",
                              }));
                            }}
                          >
                            {/* <DeleteIcon /> */}
                            <DeleteOutlineIcon sx={{color: "#E46962"}} />
                          </IconButton>
                        )}
                      </div>
                      <div className="flex items-center">
                        {editableIndex !== index && review === false && (
                          <IconButton
                            aria-label="update"
                            color="#3A74CA"
                            bg={"none"}
                            _hover={{ bg: "none" }}
                            mb="16px"
                            onClick={() => handleEditLocation(index)}
                          >
                            <EditNoteIcon />
                          </IconButton>
                        )}
                        {editableIndex === index ? (
                          <div className="ml-1 mb-4">
                            <TonalButton
                              text={"Save"}
                              width={"fit-content"}
                              onClick={() => handleSaveLocation(index)}
                              disable={review}
                            />
                          </div>
                        ) : (
                          <p className="text-[#7AC958] text-sm ml-1 font-semibold mb-5">
                            Saved
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                ))}
                {(locations?.length == 0 || isAddCompanyLocation) && (
                  <>
                    <p className="text-[#79767D] text-sm font-medium mb-3">
                      Enter location{" "}
                      {locations?.length ? locations?.length + 1 : 1}
                      {locations?.length < 1 && (
                        <span className="text-[red] mx-1">*</span>
                      )}
                    </p>
                    <div className="flex flex-col md:flex-row md:items-center gap-4">
                      <Input
                        type="text"
                        name="clientAllLocation"
                        // value={formData?.enterAllLocation}
                        onChange={handleChange}
                        // placeholder="loc1,loc2,..."
                        w={{ base: "", md: "520px" }}
                        borderColor={
                          validationErrors?.clientAllLocation
                            ? "red.500"
                            : "gray.300"
                        }
                        borderWidth={
                          validationErrors?.clientAllLocation ? "2px" : "2px"
                        }
                        disabled={review}
                        border={review ? "none" : ""}
                      />
                      {/* <button onClick={handleAddLocation}>
                        
                        Save
                      </button> */}
                      <TonalButton
                        text={"Save"}
                        width={"fit-content"}
                        onClick={handleAddLocation}
                        disable={review}
                      />
                    </div>
                  </>
                )}
                {validationErrors?.clientAllLocation && (
                  <p className="!mb-1 !text-[red] !text-sm">
                    {validationErrors?.clientAllLocation}
                  </p>
                )}
                {locations?.length > 0 && errorAllLocations && (
                  <Text className="!mb-1 !text-[red] !text-sm">
                    {errorAllLocations}
                  </Text>
                )}
              </div>
            </div>
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3 ml-1">
                Total client locations
                <span className="text-[#AEA9B1] ml-1">(count) </span>{" "}
              </p>
              <div className="mt-5 ml-3">
                {locations.filter((location) => location !== "").length}
              </div>
            </div>
          </div>

          <PrimaryButton
            text={"Add another location"}
            width={"fit-content"}
            disable={review}
            onClick={handleAddCompanyLocation}
          />
        </div>
        <div className="p-6 rounded-lg flex flex-col gap-3 bg-white">
          <p className="text-[#3E3C42] text-lg font-medium ">
            Client’s contact details
          </p>

          <div className="flex flex-col md:flex-row items-start gap-4 ">
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Primary contact name
              </p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="text"
                  name="clientPrimContactName"
                  value={formData?.clientPrimContactName}
                  // onChange={(e) => setClientPrimaryContactName(e.target.value)}
                  onChange={handleChange}
                  borderColor={
                    validationErrors?.clientPrimContactName
                      ? "red.500"
                      : "gray.300"
                  }
                  borderWidth={
                    validationErrors?.clientPrimContactName ? "2px" : "2px"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                />
              </div>
              {(validationErrors?.clientPrimContactName ||
                !isContactNameValid(formData.clientPrimContactName)) && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.clientPrimContactName}
                </p>
              )}
            </div>
            <div className="">
              <p className="text-[#79767D] text-sm font-medium mb-3">E-mail</p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="email"
                  name="clientPrimEmail"
                  value={formData?.clientPrimEmail}
                  // onChange={(e) => setClientEmail(e.target.value)}
                  onChange={handleChange}
                  borderColor={
                    validationErrors?.clientPrimEmail ? "red.500" : "gray.300"
                  }
                  borderWidth={
                    validationErrors?.clientPrimEmail ? "2px" : "2px"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                />
              </div>
              {(validationErrors?.clientPrimEmail ||
                !isValidEmail(formData.clientPrimEmail)) && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.clientPrimEmail}
                </p>
              )}
            </div>
            <div className="">
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Phone number
              </p>
              {/* <div className=""> */}
              <div className="flex">
                <Select
                  // border={"none"}
                  w={submitClicked ? "135px" : "110px"}
                  value={selectedCountryCodeClient}
                  onChange={(e) => {
                    setSelectedCountryCodeClient(e.target.value);
                    setFieledsChange(true);
                  }}
                  disabled={review}
                >
                  {countryCodes.map((code) => (
                    <option key={code} value={code}>
                      {code}
                    </option>
                  ))}
                </Select>

                <Input
                  type="number"
                  name="clientContactNumber"
                  value={formData?.clientContactNumber}
                  // onChange={(e) => setClientPhoneNumber(e.target.value)}
                  onChange={handleChange}
                  borderColor={
                    validationErrors?.clientContactNumber
                      ? "red.500"
                      : "gray.300"
                  }
                  borderWidth={
                    validationErrors?.clientContactNumber ? "2px" : "2px"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                />
              </div>
              {/* </div> */}
              {validationErrors?.clientContactNumber && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.clientContactNumber}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-start gap-4">
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Secondary contact name
              </p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="text"
                  name="clientSecContactName"
                  value={formData?.clientSecContactName}
                  // onChange={(e) => setClientSecContactName(e.target.value)}
                  onChange={handleChange}
                  borderColor={
                    validationErrors?.clientSecContactName
                      ? "red.500"
                      : "gray.300"
                  }
                  borderWidth={
                    validationErrors?.clientSecContactName ? "2px" : "2px"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                />
              </div>
              {(validationErrors?.clientSecContactName ||
                !isContactNameValid(formData.clientSecContactName)) && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.clientSecContactName}
                </p>
              )}
            </div>
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Secondary contact E-mail
              </p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="email"
                  name="clientSecEmail"
                  value={formData?.clientSecEmail}
                  // onChange={(e) => setClientSecContactName(e.target.value)}
                  onChange={handleChange}
                  borderColor={
                    validationErrors?.clientSecContactEmail
                      ? "red.500"
                      : "gray.300"
                  }
                  borderWidth={
                    validationErrors?.clientSecContactEmail ? "2px" : "2px"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                />
              </div>
              {(validationErrors?.clientSecContactEmail ||
                !isValidEmail(formData.clientSecEmail)) && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.clientSecContactEmail}
                </p>
              )}
            </div>
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Secondary contact Phone number
              </p>
              <div style={{ width: "fit-content" }}>
                <div className="flex">
                  <Select
                    // border={"none"}
                    w={submitClicked ? "135px" : "110px"}
                    value={secSelectedCountryCodeClient}
                    onChange={(e) => {
                      setSecSelectedCountryCodeClient(e.target.value);
                      setFieledsChange(true);
                    }}
                    disabled={review}
                  >
                    {countryCodes.map((code) => (
                      <option key={code} value={code}>
                        {code}
                      </option>
                    ))}
                  </Select>
                  <Input
                    type="number"
                    name="clientSecContactNumber"
                    value={formData?.clientSecContactNumber}
                    // onChange={(e) => setClientSecContactName(e.target.value)}
                    onChange={handleChange}
                    borderColor={
                      validationErrors?.clientSecContactNumber
                        ? "red.500"
                        : "gray.300"
                    }
                    borderWidth={
                      validationErrors?.clientSecContactNumber ? "2px" : "2px"
                    }
                    disabled={review}
                    border={review ? "none" : ""}
                  />
                </div>
              </div>
              {validationErrors?.clientSecContactNumber && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.clientSecContactNumber}
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="p-6 rounded-lg flex flex-col gap-3 bg-white">
          <p className="text-[#3E3C42] text-lg font-medium ">
            Ripik's contact details
          </p>

          <div className="flex flex-col md:flex-row items-start gap-4">
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Primary contact name
              </p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="text"
                  name="ripikContactPrimName"
                  value={formData?.ripikContactPrimName}
                  onChange={handleChange}
                  borderColor={
                    validationErrors?.ripikContactPrimName
                      ? "red.500"
                      : "gray.300"
                  }
                  borderWidth={
                    validationErrors?.ripikContactPrimName ? "2px" : "2px"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                />
              </div>
              {(validationErrors?.ripikContactPrimName ||
                !isContactNameValid(formData.ripikContactPrimName)) && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.ripikContactPrimName}
                </p>
              )}
            </div>
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">E-mail</p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="text"
                  name="ripikContactEmail"
                  value={formData?.ripikContactEmail}
                  onChange={handleChange}
                  borderColor={
                    validationErrors?.ripikContactEmail ? "red.500" : "gray.300"
                  }
                  borderWidth={
                    validationErrors?.ripikContactEmail ? "2px" : "2px"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                />
              </div>
              {(validationErrors?.ripikContactEmail ||
                !isValidEmail(formData.ripikContactEmail)) && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.ripikContactEmail}
                </p>
              )}
            </div>
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Phone number
              </p>

              <div className="flex ">
                <Select
                  w={submitClicked ? "135px" : "110px"}
                  value={selectedCountryCodeRipik}
                  onChange={(e) => {
                    setSelectedCountryCodeRipik(e.target.value);
                    setFieledsChange(true);
                  }}
                  disabled={review}
                >
                  {countryCodes.map((code) => (
                    <option key={code} value={code}>
                      {code}
                    </option>
                  ))}
                </Select>

                <Input
                  type="number"
                  name="ripikContactNumber"
                  value={formData?.ripikContactNumber}
                  onChange={handleChange}
                  borderColor={
                    validationErrors?.ripikContactNumber
                      ? "red.500"
                      : "gray.300"
                  }
                  borderWidth={
                    validationErrors?.ripikContactNumber ? "2px" : "2px"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                />
              </div>

              {validationErrors?.ripikContactNumber && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.ripikContactNumber}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-start gap-4">
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Secondary contact name
              </p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="text"
                  name="ripikContactSecName"
                  value={formData?.ripikContactSecName}
                  onChange={handleChange}
                  borderColor={
                    validationErrors?.ripikContactSecName
                      ? "red.500"
                      : "gray.300"
                  }
                  borderWidth={
                    validationErrors?.ripikContactSecName ? "2px" : "2px"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                />
              </div>
              {validationErrors?.ripikContactSecName && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.ripikContactSecName}
                </p>
              )}
            </div>
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Secondary contact Email
              </p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="email"
                  name="ripikContactSecEmail"
                  value={formData?.ripikContactSecEmail}
                  onChange={handleChange}
                  borderColor={
                    validationErrors?.ripikContactSecEmail
                      ? "red.500"
                      : "gray.300"
                  }
                  borderWidth={
                    validationErrors?.ripikContactSecEmail ? "2px" : "2px"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                />
              </div>
              {(validationErrors?.ripikContactSecEmail ||
                !isValidEmail(formData.ripikContactSecEmail)) && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.ripikContactSecEmail}
                </p>
              )}
            </div>
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Secondary contact Phone number
              </p>
              <div style={{ width: "fit-content" }}>
                <div className="flex">
                  <Select
                    w={submitClicked ? "135px" : "110px"}
                    value={secSelectedCountryCodeRipik}
                    onChange={(e) => {
                      setSecSelectedCountryCodeRipik(e.target.value);
                      setFieledsChange(true);
                    }}
                    disabled={review}
                  >
                    {countryCodes.map((code) => (
                      <option key={code} value={code}>
                        {code}
                      </option>
                    ))}
                  </Select>
                  <Input
                    type="number"
                    name="ripikContactSecNumber"
                    value={formData?.ripikContactSecNumber}
                    onChange={handleChange}
                    borderColor={
                      validationErrors?.ripikContactSecNumber
                        ? "red.500"
                        : "gray.300"
                    }
                    borderWidth={
                      validationErrors?.ripikContactSecNumber ? "2px" : "2px"
                    }
                    disabled={review}
                    border={review ? "none" : ""}
                  />
                </div>

                {validationErrors?.ripikContactSecNumber && (
                  <p className="!mb-1 !text-[red] !text-sm">
                    {validationErrors?.ripikContactSecNumber}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <AddClientRelation
          setRelationDate={setRelationDate}
          relationDate={relationDate}
          review={review}
          setProductDate={setProductDate}
          productDate={productDate}
          selectedCurrency={selectedCurrency}
          setSelectedCurrency={setSelectedCurrency}
          formData={formData}
          handleChange={handleChange}
          validationErrors={validationErrors}
          setFieledsChange={setFieledsChange}
        />
      </div>
      <div className="flex gap-[10px] items-center ml-6 md:ml-0 mt-2 mb-20 md:mb-0">
        <div className="flex items-center gap-2 mb-5">
          {review === false ? (
            <TonalButton
              text={"Save draft"}
              width={"130px"}
              onClick={() => {
                setMode((prev) => ({
                  ...prev,
                  isSavedDraft: true,
                  status: 0,
                }));
                setSaved(true);
                handleModalSubmit();
              }}
              disable={!isfieldsChanged}
            />
          ) : (
            <TonalButton
              text={"Back to editing"}
              width={"130px"}
              onClick={() => {
                setReview(false);
              }}
            />
          )}
          {review === false ? (
            <button
              className="bg-[#084298] rounded text-white text-sm"
              onClick={() => {
                setReview(true);
              }}
              style={{
                width: "125px",

                padding: "8px 0px 8px 0px",
              }}
            >
              Review
            </button>
          ) : (
            <button
              className="bg-[#69B04B] rounded text-white text-sm"
              onClick={handlAddClient}
              style={{
                width: "125px",
                padding: "8px 0px 8px 0px",
              }}
            >
              {"Submit"}
            </button>
          )}
        </div>
        {isModalOpen && (
          <DraftModal
            isOpen={isModalOpen}
            handleSubmit={handleModalSubmit}
            handleConfirmDiscard={handleConfirmDiscard}
            setShowDraft={setIsModalOpen}
          />
        )}
      </div>
    </div>
  );
};

export default CreateClient;
