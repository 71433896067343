import ReactApexChart from "react-apexcharts";
import { useState, useEffect } from "react";

const LabelPie = ({ predictionData }) => {
  const [series, setSeries] = useState([]);
  const [label, setLabel] = useState([]);

  useEffect(() => {
    if (predictionData && Object.keys(predictionData).length > 0) {
      setSeries((prev) => {
        let data = [];
        let names = [];
        Object.keys(predictionData).forEach((item) => {
          data.push(predictionData[item]?.img?.length);
          names.push(item);
        });
        setLabel((prev) => {
          return names;
        });
        return data;
      });
    }
  }, [predictionData]);

  const options = {
    chart: {
      type: "pie",
    },
    colors: [
      "#ffc107",
      "#5193f6",
      "#ef6f12",
      "#1c56ac",
      "#e91e63",
      "#00bcd4",
      "#8bc34a",
      "#9c27b0",
      "#673ab7",
      "#ff9800",
      "#4caf50",
      "#795548",
    ],
    labels: label,
    legend: {
      show: false,
      position: "right",
      fontSize: "12px",
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
      },
      background: {
        enabled: true,
        foreColor: "#000",
      },
      dropShadow: {
        enabled: false,
      },
    },
  };
  return (
    <ReactApexChart
      options={options}
      series={series}
      type="pie"
      height={'180px'}
      width={'180px'}
    />
  );
};

export default LabelPie;
