import { utils } from "xlsx";

export const status = (val) => {
  switch (val) {
    case "DRAFT":
      return "draft";
    case "ACTIVE":
      return "live";
    case "INACTIVE":
      return "fail";
    default:
      break;
  }
};

export const exportAsExcel = (data, sheetName, wb) => {
  let newData = [];

  if (data?.length > 0 && data[0]) {
    const fileColumns = Object.keys(data[0]);

    if (fileColumns && fileColumns?.length > 0) {
      newData.push(fileColumns.map((column) => column?.toLowerCase()));

      data.forEach((item) => {
        if (item) {
          const newArray = fileColumns?.map((column) => {
            return column.toLowerCase()?.includes("time")
              ? new Date(item[column])?.toLocaleString()
              : item[column];
          });
          newData.push(newArray);
        }
      });

      let ws = utils.aoa_to_sheet(newData);
      utils.book_append_sheet(wb, ws, sheetName);
    } else {
      console.error("fileColumns is undefined or empty");
    }
  } else {
    console.error(
      "data array is empty or does not have the expected structure"
    );
  }
};
