import { DataGrid } from "@mui/x-data-grid";
import {
  createTheme,
  ThemeProvider,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import NavContext from "../../NavContext";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { status } from "../Components/ProjectCard";

const MuiTheme = createTheme();

const ModelListView = ({ data, selectedView }) => {
  const [rowData, setRowData] = useState([]);
  const { email } = useContext(NavContext);
  const columns = [
    {
      field: "id",
      headerName: "SR No.",
    },
    {
      field: "createdAt",
      headerName: "Created on",
      valueFormatter: ({ value }) => {
        return new Date(value)?.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      },
    },
    {
      field: "owner",
      headerName: "Project owner",
    },
    {
      field: "name",
      headerName: "Project name",
    },
    {
      field: "stateChangeTs",
      headerName: "Runtime",
      valueGetter: ({ value }) => {
        return value?.COMPLETED
          ? ((value?.COMPLETED - value?.RUNNING) / 60000)?.toFixed(1) + " min"
          : "N/A";
      },
    },
    {
      field: "status",
      headerName: "Project status",
      renderCell: ({ value }) => {
        return (
          <div className="flex items-center gap-1 w-full">
            <div className="w-8 h-8 rounded-full bg-white p-1 shadow-md">
              {status(value) != "run" ? (
                <img
                  src={`/selfServiceIcons/${status(value)}.svg`}
                  alt="status"
                />
              ) : (
                <CircularProgress
                  sx={{ color: "#69b04b" }}
                  size={25}
                  thickness={5}
                />
              )}
            </div>
            {value}
          </div>
        );
      },
    },
    {
      field: "modelLink",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ value, row }) => {
        const handleClick = () => {
          let a = document.createElement("a");
          a.href = value;
          a.download = `${row?.projectId + row?.trainingId}.pt`;
          a.click();
        };
        return (
          <IconButton onClick={handleClick} disabled={!value}>
            <CloudDownloadIcon sx={{ color: !value ? "" : "#3a97e8" }} />
          </IconButton>
        );
      },
    },
  ];

  useEffect(() => {
    setRowData((prev) => {
      let newData = data
        .filter((item) => {
          if (selectedView == "Owned") return item.owner == email;
          else if (selectedView == "Shared") return item.owner != email;
          else return item;
        })
        ?.map((item) => {
          let result = item.trainings.map((val) => {
            val["name"] = item?.name;
            return val;
          });
          return result;
        })
        ?.flat();
      return newData;
    });
  }, [data, selectedView]);

  const headerClass =
    "text-sm font-medium text-[#525056] bg-[#ddeeff] capitalize";
  const cellClass = "text-sm font-medium text-[#3E3C42]";
  let flexmap = {
    id: 0,
    createdAt: 1,
    owner: 1.5,
    name: 1,
    stateChangeTs: 1,
    status: 1,
    modelLink: 1,
  };
  columns.map((val, idx) => {
    val["headerClassName"] = headerClass;
    val["cellClassName"] = cellClass;
    val["flex"] = flexmap[val.field];
    val["headerAlign"] = "center";
    val["align"] = "center";
  });
  console.log(rowData, "model data");
  return (
    <div className="overflow-x-auto col-span-full">
      <ThemeProvider theme={MuiTheme}>
        {rowData.length > 0 && (
          <DataGrid
            rows={rowData}
            columns={columns}
            columnVisibilityModel={{
              id: false,
            }}
            initialState={{
              pagination: { paginationModel: { pageSize: 5 } },
            }}
            getRowId={(row) => row?.projectId + row?.trainingId}
            pageSizeOptions={[]}
            sx={{
              minWidth: "1000px",
              borderStartStartRadius: "8px",
            }}
          />
        )}
      </ThemeProvider>
    </div>
  );
};

export default ModelListView;
