import ReactApexCharts from "react-apexcharts";
import { indexWordMap } from "../../SinterFlame/Sinterflame";

const minMaxFinder = (data, val) => {
  switch (val) {
    case "min":
      if (data?.length > 2) {
        return Math.min(...data[1]?.data, ...data[2]?.data);
      } else return Math.min(...data[1]?.data);
    case "max":
      if (data?.length > 2) {
        return Math.max(...data[1]?.data, ...data[2]?.data);
      } else return Math.max(...data[1]?.data);
    default:
      break;
  }
};

const seriesNameFinder = (num) => {
  if (num != 8 && num != 1) return [`T${num - 1}`, `T${num}`];
  else if (num == 8) return [`T${num - 1}`];
  else return [`T${num}`];
};

const TrendLineChart = ({ data, num, camId, color }) => {
  const times = [];
  const series = [
    {
      name: "Flame health",
      data: [],
    },
  ];
  if (num - 1 !== 0) {
    series.push({
      name: "T" + (num - 1),
      data: [],
    });
  }

  if (num !== 8) {
    series.push({
      name: "T" + num,
      data: [],
    });
  }
  data
    .filter((item) => {
      return item.healthIndex != 0;
    })
    .reverse()
    .map((item) => {
      times.push(item.timestamp);
      series[0].data.push(item.healthIndex);
      if (num - 1 !== 0) {
        let idx = series.findIndex((x) => x.name == "T" + (num - 1));
        series[idx].data.push(item["T" + (num - 1)]?.toFixed(2));
      }
      if (num !== 8) {
        let idx = series.findIndex((x) => x.name == "T" + num);
        series[idx].data.push(item["T" + num]?.toFixed(2));
      }
    });
  //chart options
  console.log(minMaxFinder(series, "min"), minMaxFinder(series, "max"));
  const options = {
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y, { seriesIndex }) {
          return seriesIndex != 0 ? y : indexWordMap[y];
        },
      },
      theme: "dark",
      fillSeriesColor: true,
      style: {
        fontSize: "16px",
      },
    },
    chart: {
      type: "line",
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: true,
        },
      },
      animations: {
        enabled: false,
      },
    },
    annotations: {
      yaxis: [
        {
          y: 1.5,
          y2: 2.5,
          borderColor: "#69B04B",
          fillColor: "#B2D8B2",
          label: {
            text: "Optimal range",
          },
        },
      ],
    },
    xaxis: {
      categories: times,
      type: "datetime",
      labels: {
        show: true,
        formatter: function (value) {
          const date = new Date(value);
          const hours = date.getHours();
          const minutes = date.getMinutes().toString().padStart(2, "0");
          const ampm = hours >= 12 ? "PM" : "AM";
          const formattedHours = hours === 0 || hours === 12 ? 12 : hours % 12;

          return `${formattedHours}:${minutes} ${ampm}`;
        },
        style: {
          fontSize: "14px",
        },
      },
    },
    yaxis: [
      {
        opposite: true,
        title: {
          text: "",
          style: {
            fontSize: "12px",
            color: "#000000",
            fontWeight: 500,
            fontFamily: "Poppins",
          },
        },
        tickAmount: 3,
        labels: {
          style: {
            fontSize: "14px",
          },
          formatter: function (value) {
            return indexWordMap[value]?.split(" ");
          },
          offsetY: -8,
          minWidth: 60,
        },
        min: 0,
        max: 3,
      },
      {
        title: {
          text: "Burner Temperatures",
          style: {
            fontSize: "12px",
            color: "#3E3C42",
            fontWeight: 500,
            fontFamily: "Poppins",
          },
        },
        min: minMaxFinder(series, "min"),
        max: minMaxFinder(series, "max"),
        seriesName: seriesNameFinder(num),
      },
      {
        title: {
          text: "Burner Temperatures",
          style: {
            fontSize: "12px",
            color: "#3E3C42",
            fontWeight: 500,
            fontFamily: "Poppins",
          },
        },
        min: minMaxFinder(series, "min"),
        max: minMaxFinder(series, "max"),
        seriesName: seriesNameFinder(num),
        show: false,
      },
    ],
    colors: ["#084298", "#6CA6FC", "#5CFFFF"],
    stroke: {
      curve: ["stepline", "smooth", "smooth"],
      width: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#FAFAFA", "transparent"],
        opacity: 1,
      },
    },
    legend: {
      show: true,
      offsetY: 15,
      offsetX: 0,
      position: "top",
      fontSize: "12px",
    },
  };

  return (
    <ReactApexCharts
      options={options}
      series={series}
      type="line"
      height="100%"
      width="100%"
    />
  );
};

export default TrendLineChart;
