import { useState } from "react";
import FillBar from "./FillBar";
import EditIcon from "@mui/icons-material/Edit";
import Stockdrawer from "./StockDrawer";
import EditNoteIcon from "@mui/icons-material/EditNote";

const FlipCard = ({ title, sections, bay, data, materialNum, fetchData }) => {
  const [stockDrawer, setStockDrawer] = useState(false);
  const [bulkUpdate, setBulkUpdate] = useState(false);
  return (
    <div className="w-full h-[220px] shadow-md border border-gray-200">
      <div className="py-3 px-[18px] flex flex-col gap-3 relative w-full h-full">
        <div className="flex justify-between items-center w-full">
          <p className="text-sm text-[#3E3C42] font-semibold">{title}</p>
          <div className="flex gap-2 items-center">
            <EditIcon
              onClick={() => {
                setStockDrawer(true);
                setBulkUpdate(false);
              }}
              sx={{ fontSize: 18 }}
              className="cursor-pointer hover:scale-105 font-medium"
            />
            <EditNoteIcon
              onClick={() => {
                setBulkUpdate(true);
                setStockDrawer(true);
              }}
              className="cursor-pointer hover:scale-105"
            />
          </div>
        </div>
        <div className="flex gap-7 justify-evenly w-full h-full px-2">
          {Object.keys(sections || {})?.map((val, idx) => {
            let sum = sections[val]?.reduce((a, b) => {
              return a + parseFloat(b.current);
            }, 0);
            return (
              <FillBar
                current={sum}
                total={sections[val][0]?.total}
                isActive={true}
                data={data}
                section={val}
                bay={bay}
              />
            );
          })}
        </div>
        {/* {hasHopper && <Hopper isActiveHopper={isActiveHopper} />} */}
      </div>
      {stockDrawer && (
        <Stockdrawer
          openModal={stockDrawer}
          closeModal={() => setStockDrawer(false)}
          bay={bay}
          data={data}
          sections={sections}
          bulk={bulkUpdate}
          fetchData={fetchData}
        />
      )}
    </div>
  );
};

export default FlipCard;
