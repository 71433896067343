import { useContext, useEffect, useMemo, useState } from "react";
import { CommanSingleSelect } from "../components/CommanSelectBox";
import { Select, Spinner } from "@chakra-ui/react";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import { getAnalyticsDataApi } from "../services/flarestack.api";
import NavContext from "../../NavContext";
import { dataGridSlotProps, generateAlertsColumns } from "../utilis/dataGrid";
import {
  CustomFooter,
  CustomStyledDataGrid,
} from "../../../util/MaterialDataGrid/CustomStyledDatagrid";
import { AnanlyticsSkeleton, NoData } from "../components/Loading";
import {
  ColumnChart,
  DonutChart,
  StackedColumnChart,
} from "../components/CommanCharts";
import { getFlareTypeOptions } from "../utilis/utilityFunctions";
import { FlareStackContext } from "../context/FlareStackContext";

const Analytics = () => {
  const initialState = {
    columns: null,
    tableData: null,
    donutChartData: null,
    flareData: null,
    alertsData: null,
  };
  const { auth } = useContext(NavContext);
  const { initialData } = useContext(FlareStackContext);
  const [selectedRange, setSelectedRange] = useState("last7days");
  const [loading, setLoading] = useState(false);

  const [data, setData] = useState(initialState);

  const [filterData, setFilterData] = useState({
    fromTime: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10),
    toTime: new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10),
    flareStack: initialData?.[0]?.cameraGpId || "",
  });

  const handleRangeSelect = (e) => {
    setSelectedRange(e.target.value);
    if (e.target.value === "last7days") {
      setFilterData((prev) => ({
        ...prev,
        fromTime: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 10),
        toTime: new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 10),
      }));
    }
  };

  const fetchAnalyticsData = async () => {
    try {
      const requestData = {};
      const response = await getAnalyticsDataApi(auth, requestData, setLoading);
      setData((prev) => response);
      const tempColumns = await generateAlertsColumns(response?.columns);
      setData((prev) => ({
        ...prev,
        columns: [...tempColumns],
        tableData: response?.tableData,
        donutChartData: response?.donutChartData,
        flareData: response?.flareData,
        alertsData: response?.alertsData,
      }));
    } catch (error) {
      console.log(error);
      setData((prev) => initialState);
    }
  };

  const flareTypeOptions = useMemo(() => getFlareTypeOptions(), []);

  useEffect(() => {
    fetchAnalyticsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col gap-4 bg-white p-4 rounded-xl w-full h-full">
      <div className="flex flex-row gap-4 py-3 w-full overflow-auto">
        <div className="w-[200px] min-w-[200px]">
          <CommanSingleSelect
            label={"Flare Stack"}
            options={initialData?.map((el) => ({
              name: el.cameraGpName,
              value: el.cameraGpId,
            }))}
            value={filterData.flareStack}
            handleChange={(event) => {
              const {
                target: { value },
              } = event;
              setFilterData((prev) => ({ ...prev, flareStack: value }));
            }}
          />
        </div>
        <div className="min-w-[200px]">
          <Select
            borderColor="#CAC5CD"
            color="#605D64"
            value={selectedRange}
            variant="outline"
            rounded={"base"}
            className="!font-medium !text-[#605D64] !text-sm"
            onChange={(e) => handleRangeSelect(e)}
          >
            <option
              key="Last 7 days"
              value={"last7days"}
            >
              Last 7 days
            </option>
            <option
              key="custom"
              value={"custom"}
            >
              Custom
            </option>
          </Select>
        </div>
        {selectedRange === "custom" && (
          <div className="min-w-[200px]">
            <FloatingInput
              text="From"
              type="date"
              setDateTime={(value) => {
                setFilterData((prev) => ({ ...prev, fromTime: value }));
              }}
              value={filterData?.fromTime}
              max={filterData?.toTime}
            />
          </div>
        )}
        {selectedRange === "custom" && (
          <div className="min-w-[200px]">
            <FloatingInput
              text="To"
              type="date"
              setDateTime={(value) => {
                setFilterData((prev) => ({ ...prev, toTime: value }));
              }}
              value={filterData?.toTime}
              min={filterData?.fromTime}
              max={new Date().toISOString().slice(0, 10)}
            />
          </div>
        )}
        <SecondaryButton
          onClick={fetchAnalyticsData}
          text={loading ? <Spinner /> : "Apply"}
          height={"40px"}
          width={"120px"}
          disable={!filterData?.fromTime || !filterData?.toTime || loading}
        />
      </div>

      {loading ? (
        <AnanlyticsSkeleton />
      ) : (
        <div className="flex flex-col gap-6">
          <div className="">
            <h2 className="min-w-fit font-bold text-lg">
              Flame Health Distribution
            </h2>
            <div className="flex lg:flex-row flex-col items-center gap-5 pt-5 w-full h-full">
              <div className="flex flex-row justify-center items-center w-[100%] lg:w-[40%] xl:w-[30%] h-[350px] ">
                {data?.donutChartData ? (
                  <DonutChart data={data?.donutChartData} />
                ) : (
                  <NoData />
                )}
              </div>
              <div className="w-[100%] lg:w-[70%] h-[350px]">
                {data?.flareData?.data && data?.flareData?.times ? (
                  <StackedColumnChart
                    data={data?.flareData?.data}
                    times={data?.flareData?.times}
                  />
                ) : (
                  <NoData />
                )}
              </div>
            </div>
          </div>
          <div>
            <h2 className="min-w-fit font-bold text-lg">High Alert Count</h2>
            <div className="w-full h-[350px]">
              {data?.alertsData?.data && data?.alertsData?.times ? (
                <ColumnChart
                  data={data?.alertsData?.data}
                  times={data?.alertsData?.times}
                />
              ) : (
                <NoData />
              )}
            </div>
          </div>
          <div
            className="border rounded-md w-full"
            style={{
              height:
                90 + Math.min(10, data?.tableData?.length || 1) * 45 + "px",
            }}
            id="tableContainer"
          >
            {data?.columns && data?.tableData ? (
              <CustomStyledDataGrid
                columns={data?.columns || []}
                rows={data?.tableData || []}
                rowHeight={45}
                columnHeaderHeight={45}
                disableColumnSelector={true}
                disableRowSelectionOnClick={true}
                pageSizeOptions={[10, 20, 50]}
                pagination={true}
                disableColumnReorder={true}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 10, page: 0 },
                  },
                }}
                getRowClassName={(params) =>
                  params.indexRelativeToCurrentPage % 2 === 0
                    ? "bg-[#FAFAFA]"
                    : "bg-white"
                }
                sx={{
                  "& .MuiDataGrid-columnHeaderTitle": {
                    whiteSpace: "normal",
                    lineHeight: "normal",
                  },
                }}
                slots={{
                  footer: CustomFooter,
                }}
                slotProps={dataGridSlotProps && dataGridSlotProps()}
                showColumnVerticalBorder
              />
            ) : (
              <NoData />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Analytics;
