import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import NavContext from "../../NavContext";
import LibraryGrid from "../Components/LibraryGrid";
import { Select, Spinner } from "@chakra-ui/react";
import { baseURL } from "../../../index";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import axios from "axios";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { capitalizeFirstLetter } from "../../../util/sentenceCase";

const FeedLibrary = ({ cameraId, disable }) => {
  const { auth } = useContext(NavContext);
  let param = useParams();
  const [imgData, setImgData] = useState([]);
  const [imgDataChanging, setImgDataChanging] = useState(false);
  const [selectedCam, setSelectedCam] = useState(4);
  const [fromTime, setFromTime] = useState(
    new Date(new Date().getTime() - 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16)
  );
  const [toTime, setToTime] = useState(
    new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16)
  );

  const apiCall = async () => {
    const requestData = {
      loader: "loader-" + selectedCam,
      starttime: new Date(fromTime).getTime(),
      endtime: new Date(toTime).getTime(),
    };
    const response = await axios
      .get(baseURL + "vision/v1/workforceMonitoring/cb/getAlerts", {
        params: requestData,
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      })
      .then((response) => {
        setImgData(response.data);
        setImgDataChanging(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClick = () => {
    setImgDataChanging(true);
    apiCall();
  };

  useEffect(() => {
    handleClick();
  }, []);

  return (
    <div className="relative pl-6 pr-6 flex flex-col gap-6">
      <div className="absolute left-0 right-0 flex justify-center">
        <div className="p-5 pl-6 pr-6 gap-2 flex flex-col md:flex-row items-center bg-white rounded-xl shadow-md">
          <div className="min-w-[110px]">
            <Select
              borderColor="#CAC5CD"
              color="#605D64"
              placeholder={disable && capitalizeFirstLetter(selectedCam)}
              variant="outline"
              isDisabled={disable}
              className=" !text-sm !font-medium text-[#605D64]"
              onChange={(e) => setSelectedCam(e.target.value)}
              value={selectedCam}
            >
              {["4", "5"].map((cam) => {
                return (
                  <option key={cam} value={cam}>
                    {capitalizeFirstLetter(`Loader ${cam}`)}
                  </option>
                );
              })}
            </Select>
          </div>
          <div className="min-w-[190px]">
            <FloatingInput
              text="From"
              type="datetime-local"
              setDateTime={setFromTime}
              value={fromTime}
              max={toTime}
            />
          </div>
          <div className="min-w-[190px]">
            <FloatingInput
              text="To"
              type="datetime-local"
              setDateTime={setToTime}
              value={toTime}
              max={new Date().toISOString().slice(0, 16)}
              min={fromTime}
            />
          </div>
          <PrimaryButton
            disable={!fromTime || !toTime}
            onClick={handleClick}
            width={"80px"}
            height={"40px"}
            text={imgDataChanging ? <Spinner /> : "Apply"}
          />
        </div>
      </div>
      <div className="flex flex-col gap-8 mt-[160px] md:mt-11 pt-[57px] bg-white rounded-xl justify-start">
        <LibraryGrid img={imgData} />
      </div>
    </div>
  );
};

export default FeedLibrary;
