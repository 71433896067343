import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { forwardRef, useEffect, useRef, useState } from "react";
import { createTheme, ThemeProvider, Tooltip } from "@mui/material";
import { Flex, Image } from "@chakra-ui/react";
import { CustomStyledDataGrid } from "../../../util/MaterialDataGrid/CustomStyledDatagrid";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import LoopIcon from "@mui/icons-material/Loop";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  gridPaginatedVisibleSortedGridRowEntriesSelector,
  useGridApiRef,
} from "@mui/x-data-grid";
import { capitalizeFirstLetter } from "../../../util/sentenceCase";
import { useWindowSize } from "@uidotdev/usehooks";
const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
};

const getImage = (reason) => {
  if (reason === 0) {
    return "/SizingIcons/sizing.svg";
  } else if (reason === 2) {
    return "/SizingIcons/sizing.svg";
  } else if (reason === 1) {
    return "/SizingIcons/sizing.svg";
  }
};

const getReason = (reason) => {
  if (reason === 0) {
    return "Size";
  } else if (reason === 2) {
    return "Size";
  } else if (reason === 1) {
    return "Size";
  }
};

const MuiTheme = createTheme();

const ActionButtons = ({ actionUpdate }) => {
  const [actionPerRow, setActionPerRow] = useState(actionUpdate);
  return (
    <div className="flex w-full gap-2 justify-center">
      {actionPerRow.actionTaken === false ? (
        <div className="w-full flex justify-center gap-6">
          <div
            className={`bg-transparent rounded-md cursor-pointer transform hover:scale-110 transition-transform text-blue-800
        
        `}
            fontSize={"20px"}
            onClick={() => {
              setActionPerRow((prevState) => ({
                ...prevState,
                actionStatus: true,
                actionTaken: true,
              }));
            }}
          >
            <img src="/actionTick.svg" alt="yes" />
          </div>

          <div
            className="bg-transparent rounded-md  cursor-pointer transform hover:scale-110 transition-transform text-[red]"
            fontSize={"20px"}
            onClick={() => {
              setActionPerRow((prevState) => ({
                ...prevState,
                actionStatus: false,
                actionTaken: true,
              }));
            }}
          >
            <img src="/actionCross.svg" alt="yes" />
          </div>
        </div>
      ) : (
        <div className="w-full flex justify-center gap-6">
          {actionPerRow.actionStatus === true ? (
            <div
              className={` ${
                actionPerRow.actionStatus === true
                  ? "cursor-not-allowed  transform  transition-transform text-blue-800"
                  : "cursor-not-allowed text-grey-600"
              } bg-transparent rounded-md 

`}
              fontSize={"20px"}
            >
              <img src="/actionTick.svg" alt="yes" />
            </div>
          ) : (
            <div
              className={`${
                actionPerRow.actionStatus === false
                  ? "cursor-not-allowed  transform  transition-transform text-[red]"
                  : "cursor-not-allowed text-grey-600"
              }
          bg-transparent rounded-md  `}
              fontSize={"20px"}
            >
              <img src="/actionCross.svg" alt="yes" />
            </div>
          )}
          <div
            className="text-blue-600 bg-transparent rounded-md cursor-pointer transform hover:scale-110 transition-transform"
            onClick={() => {
              setActionPerRow((prevState) => ({
                ...prevState,
                actionTaken: false,
              }));
            }}
          >
            <LoopIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export const stringDecimalFix = (str) => {
  const regex = /-?\d+\.\d+/;
  const match = str.match(regex);
  if (match) {
    const floatingNumber = parseFloat(match[0]);
    const fixedNumber = floatingNumber.toFixed(2);
    return str.replace(regex, fixedNumber);
  }
  return str;
};

const getAdditionalColumns = (additionalCols) => {
  let newColumns = [];

  Object.keys(additionalCols).forEach((key) => {
    additionalCols[key].forEach((subKey) => {
      newColumns.push({
        field: `${key}_${subKey}`,
        headerName: `${subKey}`,
        minWidth: 75,
        valueGetter: (params) => {
          const value = params?.row?.pulpParamOutput?.[key]?.[subKey];
          return value || "no data";
        },
        // renderHeader: () => (
        //   <div style={{ border: "2px solid blue" }}>{subKey}</div>
        // ),
      });
    });
  });

  return newColumns;
};

const additionnalColumnsForGrid = [
  {
    groupId: "Kappa",
    headerAlign: "center",
    children: [
      {
        field: "kappa_current",
        headerAlign: "center",
      },
      { field: "kappa_expected", headerAlign: "center" },
    ],
  },
  {
    groupId: "Pentosan",
    headerAlign: "center",
    children: [
      { field: "pentosan_current", headerAlign: "center" },
      { field: "pentosan_expected", headerAlign: "center" },
    ],
  },
  {
    groupId: "Viscosity",
    headerAlign: "center",
    children: [
      { field: "viscosity_current", headerAlign: "center" },
      { field: "viscosity_expected", headerAlign: "center" },
    ],
  },
];

const AlertTable = ({
  rowData,
  setDisplayData,
  setOpenModal,
  indexRef,
  additionalCols,
}) => {
  const size = useWindowSize();

  const gridApiRef = useGridApiRef();
  let columns = [
    {
      field: "id",
      headerName: "Sr No.",
    },
    {
      field: "plantName",
      type: "string",
      headerName: "Plant name",
      valueFormatter: (params) => {
        return capitalizeFirstLetter(params.value); // Convert value to sentence case
      },
      minWidth: 120,
    },
    {
      field: "cameraId",
      type: "string",

      headerName: "Camera",
      valueFormatter: (params) => {
        return capitalizeFirstLetter(params.value); // Convert value to sentence case
      },
      minWidth: 100,
    },
    {
      field: "createdAt",
      headerName: "Timestamp",
      valueGetter: ({ value }) => new Date(value * 1000),
      valueFormatter: ({ value }) => new Date(value).toLocaleString("en-GB"),
      type: "dateTime",
      minWidth: 150,
    },
    {
      field: "alertCodes",
      type: "actions",
      headerAlign: "center",
      headerName: "Reason",
      renderCell: ({ value }) => (
        <div className="flex flex-col justify-center items-center  w-full">
          <img className="h-8 w-8" src={getImage(value[0])} alt="none" />
          <span>{getReason(value[0])}</span>
        </div>
      ),
      minWidth: 75,
    },
    {
      field: "alertMessages",
      headerName: "Comment",
      type: "string",
      minWidth: 155,
    },

    {
      field: "",
      type: "actions",
      headerName: "View details",

      minWidth: 115,
      type: "actions",
      headerAlign: "center",
      renderCell: (params) => (
        <button
          onClick={() => handleViewDetails(params)}
          className="cursor-pointer w-full text-[#084298] font-semibold text-normal hover:scale-105 flex justify-center"
        >
          <VisibilityIcon />
        </button>
      ),
    },
    // {
    //   field: "actionUpdate",
    //   headerName: "Action taken",
    //   type: "actions",
    //   headerAlign: "center",
    //   renderCell: ({ row }) => (
    //     <ActionButtons actionUpdate={row.actionUpdate} />
    //   ),
    //   minWidth: 120,
    // },
  ];

  if (rowData?.some((item) => item.hasOwnProperty("pulpParamOutput"))) {
    const additionalColumns = getAdditionalColumns(additionalCols);
    columns.splice(6, 0, ...additionalColumns);
  }

  const [rows, setRows] = useState([]);
  const headerClass = "text-md font-medium text-[#525056] ";
  const cellClass = "text-sm font-medium text-[#3E3C42]  p-4";
  const flexMap = {
    id: 0,
    plantName: 0.5,
    cameraId: 0.5,
    timestamp: 0.8,
    alertCodes: 0.4,

    alertMessages: 2,

    kappa_current: 0.5,
    kappa_expected: 0.5,

    pentosan_current: 0.5,
    pentosan_expected: 0.5,

    viscosity_current: 0.5,
    viscosity_expected: 0.5,
    actionUpdate: 1,
  };
  columns.map((val, idx) => {
    val["headerClassName"] = headerClass;
    val["cellClassName"] = cellClass;
    val["flex"] = flexMap[val.field];
    return val;
  });

  const handleViewDetails = (params) => {
    const tempDisplayData =
      gridPaginatedVisibleSortedGridRowEntriesSelector(gridApiRef);
    const updatedData = tempDisplayData.map((el, id) => el.model);
    setDisplayData((prev) => updatedData);

    const index = params.api.getRowIndexRelativeToVisibleRows(params.row.id);
    indexRef.current = index;
    setOpenModal(true);
  };
  useEffect(() => {
    setRows((prev) => {
      let newData = rowData.map((item, idx) => {
        return {
          ...item,
          actionUpdate: {
            actionTaken: false, // wheather action taken or not
            actionStatus: true, // if action taken then it is positive or negative
          },
        };
      });
      return newData;
    });
  }, [rowData]);

  return (
    <div className=" w-full h-[69vh]">
      <CustomStyledDataGrid
        apiRef={gridApiRef}
        rows={rows}
        columns={columns}
        columnVisibilityModel={{
          id: false,
        }}
        columnHeaderHeight={45}
        showColumnVerticalBorder={true}
        rowHeight={80}
        disableColumnSelector={true}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
          sorting: {
            sortModel: [{ field: "timestamp", sort: "desc" }],
          },
        }}
        pagination
        // slots={{
        //   toolbar: CustomToolbar,
        // }}
        pageSizeOptions={[5, 10, 25]}
        sx={{
          "& .MuiDataGrid-columnHeader": {
            border: "0.9px solid #e0e0e0",
            textTransform: "none",
            fontSize: size.width < 768 ? "13px" : "14px",
          },
        }}
        columnGroupingModel={additionnalColumnsForGrid}
        experimentalFeatures={{ columnGrouping: true }}
        slotProps={{
          panel: {
            sx: {
              maxWidth: "98vw",
              alignSelf: "center",
              ".css-154sxbz-MuiPaper-root-MuiDataGrid-paper": {
                minWidth: 0,
              },
              // margin: 2,
            },
          },
          filterPanel: {
            filterFormProps: {
              // input box for operator selection(for multi-columns => and, or)
              logicOperatorInputProps: {
                sx: { display: "none" },
              },
              // input box for column selection
              columnInputProps: {
                variant: "outlined",
                size: "small",
              },
              // input box for operator selection(for a particular columns => contains, start with, is empty ....)
              operatorInputProps: {
                sx: { display: "none" },
              },
              // input box to write the value on the basis of which data get's filtered
              valueInputProps: {
                InputComponentProps: {
                  variant: "outlined",
                  size: "small",
                },
              },
              // delete icon
              deleteIconProps: {
                sx: {
                  "& .MuiSvgIcon-root": { color: "#D32F2F" },
                },
              },
            },
            sx: {
              overflowX: "auto",
              "& .MuiDataGrid-filterForm": { p: 2 },
              "& .MuiDataGrid-filterFormLogicOperatorInput": {
                mr: 2,
                minWidth: 80,
              },
              "& .MuiDataGrid-filterFormColumnInput": {
                mr: 2,
                minWidth: 150,
              },
              "& .MuiDataGrid-filterFormValueInput": { minWidth: 150 },
            },
          },
        }}
      />
    </div>
  );
};
export default AlertTable;
