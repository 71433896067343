import { useState, useContext, useEffect } from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast,
} from "@chakra-ui/react";
import { useParams, Link, useNavigate } from "react-router-dom";
import PredictionCard from "../Components/PredictionCard";
import OutputDetail from "../Components/OutputDetail";
import ViewForm from "./ViewForm";
import axios from "axios";
import { baseURL } from "../../..";
import NavContext from "../../NavContext";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { startTrainApi } from "../Components/ReRunModal";
import Paginator from "../../../util/VisionUtils/Paginator";
import HistoryTable from "../Tables/HistoryTable";
import ModelStatusFallback from "../Components/ModelStatusFallback";
import { useWindowSize } from "@uidotdev/usehooks";
import Home from "../Home/Home";
import { initState } from "./CreateForm";
import { draftPostApi } from "./DraftForm";
import TonalButton from "../../../util/Buttons/TonalButton";
import LiveOutput from "../RtspLive/LiveOutput";

const Capitalize = (str) => {
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
  }
  const str2 = arr.join(" ");
  return str2;
};

export const CalF1 = (precision, recall) => {
  if (precision && recall) {
    let f1 = (2 * precision * recall) / (precision + recall);
    return Math.round(f1 * 100) + "%";
  } else return "N/A";
};

const DetailView = () => {
  const [page, setPage] = useState(0);
  const { projectId } = useParams();
  const param = {
    projectId: projectId,
  };
  const navigate = useNavigate();
  const toast = useToast();
  const { auth } = useContext(NavContext);
  const [userState, setUserState] = useState(initState);
  const [selectedIteration, setSelectedIteration] = useState({
    index: 0,
    version: null,
  });
  const [modelInfo, setModelInfo] = useState({
    status: "",
    performance: null,
    version: 0,
    runTime: {},
    remarks: "",
  });
  const [history, setHistory] = useState([]);
  const [predictionCardData, setPredictionCardData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [predictionData, setPredictionData] = useState({});
  const size = useWindowSize();
  let pollTimer;

  const getSingle = async () => {
    try {
      const resposne = await axios.get(
        baseURL + "selfserve/v1/project/v1/getSingle/",
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      setUserState((prev) => {
        let newData = { ...prev };
        let data = resposne.data;
        let selectedIdx = Number.isInteger(selectedIteration?.version)
          ? data?.details?.findIndex(
              (item) => item.version == selectedIteration?.version
            )
          : 0;
        let baseKey = data?.details[selectedIdx];
        let val = Object.keys(baseKey?.dataset || {})[0];
        let videoObj = Object.entries(
          baseKey?.videoDataset?.[val] || {}
        )?.[0]?.[1];
        newData["name"] = data?.name || "";
        newData["projectId"] = projectId;
        newData["dataType"] = Capitalize(baseKey?.datasetType || "") || "Image";
        newData["whatToDetect"] = data?.remarks || "";
        newData["isAnnotated"] = baseKey?.isAnnotated ? "Yes" : "No";
        newData["annotationType"] =
          Capitalize(baseKey?.modelType || "") || "Classify";
        newData["savedFiles"] = "Dummy";
        newData["rtspLink"] = videoObj
          ? videoObj?.format == "RTSP"
            ? videoObj?.link
            : ""
          : "";
        newData["modelService"] = baseKey?.modelService || "RIPIK_VISION";
        newData["uploadedFiles"] = baseKey?.dataset?.[val] || {};
        newData["annotatedData"] = baseKey?.annotations?.[val] || [];
        newData["lastUpdatedAt"] = data?.lastUpdatedAt;
        return newData;
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getStatus = async () => {
    try {
      const response = await axios.get(
        baseURL + "selfserve/v1/project/v1/training/status/",
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.status == 200 && response.data) {
        let {
          status,
          performance = null,
          predictions = [],
          stateChangeTs = {},
          version,
          remarks,
        } = response.data[selectedIteration?.index];
        setModelInfo((prev) => ({
          ...prev,
          status: status,
          performance: performance,
          version: version,
          runTime: stateChangeTs,
          remarks: remarks,
        }));
        if (
          status == "COMPLETED" ||
          status == "FAILED" ||
          status == "TERMINATED"
        ) {
          clearInterval(pollTimer);
          setHistory(response.data);
          if (status == "COMPLETED") updateData(performance, predictions);
        }
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 404) {
        setModelInfo((prev) => ({
          ...prev,
          status: "NO-TRAINING",
        }));
      }
      clearInterval(pollTimer);
    }
  };

  const updateData = (performance, predictions) => {
    let output = {};
    let labels = [];
    let uniqueLabels;
    if (predictions && predictions.length > 0) {
      switch (userState.annotationType) {
        case "Detect":
          uniqueLabels = new Set();
          predictions.map((item) => {
            item.cls.map((val) => {
              uniqueLabels.add(val);
            });
          });
          labels = Array.from(uniqueLabels);
          labels.map((l) => {
            let labelData = [];
            predictions.map((item) => {
              let imgData = [];
              item.cls.map((val, idx) => {
                if (val == l) {
                  let [x1, y1, x2, y2] = item.boxCoords[idx];
                  imgData.push({
                    points: [
                      [x1, y1],
                      [x1, y2],
                      [x2, y2],
                      [x2, y1],
                    ],
                    label: l,
                    precision: item.probs[idx],
                  });
                }
              });
              if (imgData.length > 0) {
                labelData.push({
                  img: item.fileUrl,
                  annotationData: imgData,
                });
              }
            });
            output[l] = {
              img: labelData,
            };
          });
          setPredictionData(output);
          break;
        case "Segment":
          uniqueLabels = new Set();
          predictions.map((item) => {
            item.cls.map((val) => {
              uniqueLabels.add(val);
            });
          });
          labels = Array.from(uniqueLabels);
          labels.map((l) => {
            let labelData = [];
            predictions.map((item) => {
              let imgData = [];
              item.cls.map((val, idx) => {
                if (val == l) {
                  imgData.push({
                    points: item.maskCoords[idx],
                    label: l,
                    precision: item.probs[idx],
                  });
                }
              });
              if (imgData.length > 0) {
                labelData.push({
                  img: item.fileUrl,
                  annotationData: imgData,
                });
              }
            });
            output[l] = {
              img: labelData,
            };
          });
          setPredictionData(output);
          break;
        case "Classify":
          if (
            performance &&
            Object.keys(performance).length > 0 &&
            userState.modelService == "AZURE_CUSTOM_VISION"
          ) {
            performance.per_tag_performance.map((item) => {
              let imgSet = [];
              predictions.map((val) => {
                const maxProbabilityObject = val.result.reduce(
                  (maxObject, currentObject) => {
                    return currentObject.probability > maxObject.probability
                      ? currentObject
                      : maxObject;
                  },
                  val.result[0]
                );
                if (maxProbabilityObject.tag_name == item.name) {
                  imgSet.push({
                    img: val.fileUrl,
                    precision: maxProbabilityObject.probability,
                    tag_name: item.name,
                  });
                }
              });

              output[item.name] = {
                precision: item.precision,
                img: imgSet,
              };
            });
          } else {
            uniqueLabels = new Set();
            predictions.map((item) => {
              uniqueLabels.add(item.cls);
            });
            labels = Array.from(uniqueLabels);
            labels.map((l) => {
              let imgData = predictions
                .filter((val) => val.cls == l)
                .map((item) => {
                  return {
                    img: item.fileUrl,
                    precision: item.probs,
                    tag_name: item.cls,
                  };
                });
              output[l] = {
                img: imgData,
              };
            });
          }
          setPredictionData(output);
          break;
        default:
          console.log("unsupported type");
          break;
      }
    }
  };

  const retrainApi = async () => {
    try {
      const param = {
        projectId: projectId,
        partitionType: "NEWTRAIN",
      };
      const requestData = new FormData();
      requestData?.append("files", null);
      const response = await axios.post(
        baseURL + "selfserve/v1/project/v1/file/upload/",
        requestData,
        {
          params: param,
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response?.status == 200) {
        let obj = {
          ...userState,
          uploadedFiles: {},
          annotatedData: null,
          videoUploaded: false,
          mode: "Retraining",
        };
        draftPostApi(obj, 2, toast, auth);
        setTimeout(() => {
          navigate(`/Sandbox/Draft/${projectId}`);
        }, 2000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSingle();
  }, [selectedIteration]);

  useEffect(() => {
    if (["Detect", "Classify", "Segment"].includes(userState.annotationType)) {
      getStatus();
      pollTimer = setInterval(getStatus, 10000);
    }
    return () => clearInterval(pollTimer);
  }, [userState]);

  useEffect(() => {
    if (predictionData) {
      setPredictionCardData((prev) => {
        let newData = [];
        Object.keys(predictionData)?.map((label) => {
          return predictionData[label].img.map((item) => {
            newData.push(item);
          });
        });
        let sortedData = newData?.sort((a, b) => {
          let regex = /\/i-(\d+)\.jpg$/;
          let timeA = String(a?.img)?.match(regex)
            ? Number(String(a?.img)?.match(regex)[1])
            : null;
          let timeB = String(b?.img)?.match(regex)
            ? Number(String(b?.img)?.match(regex)[1])
            : null;
          return timeB - timeA;
        });
        return sortedData;
      });
    } else {
      setDisplayData([]);
    }
  }, [predictionData]);

  const tabList = [
    {
      index: 0,
      tabName: "Project details",
      element: <ViewForm userState={userState} />,
    },
    {
      index: 1,
      tabName: "Model details",
      element: userState?.annotationType != "" && (
        <HistoryTable
          annotationType={userState.annotationType}
          selectedIteration={selectedIteration}
          setSelectedIteration={setSelectedIteration}
        />
      ),
    },
    {
      index: 2,
      tabName: "Output summary",
      element:
        modelInfo?.status == "COMPLETED" && predictionData ? (
          <Home
            userState={userState}
            modelInfo={modelInfo}
            predictionData={predictionData}
          />
        ) : (
          <ModelStatusFallback modelInfo={modelInfo} userData={userState} />
        ),
    },
    {
      index: 3,
      tabName: "Output images",
      element:
        modelInfo?.status == "COMPLETED" && predictionData ? (
          <div className="flex flex-col gap-1">
            <div className="self-end">
              {displayData && (
                <Paginator
                  data={predictionCardData}
                  limit={10}
                  setDisplayData={setDisplayData}
                />
              )}
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-4 w-full h-fit">
              {displayData &&
                displayData.map((item) => {
                  let regex = /\/i-(\d+)\.jpg$/;
                  let url = String(item?.img);
                  let time = url?.match(regex)
                    ? Number(url?.match(regex)[1])
                    : null;
                  return <PredictionCard data={item} time={time} />;
                })}
            </div>
          </div>
        ) : (
          <ModelStatusFallback modelInfo={modelInfo} userData={userState} />
        ),
    },
    {
      index: 4,
      tabName: "Output image details",
      element:
        modelInfo?.status == "COMPLETED" && predictionData ? (
          <OutputDetail userState={userState} predictionData={predictionData} />
        ) : (
          <ModelStatusFallback modelInfo={modelInfo} userData={userState} />
        ),
    },
    {
      index: 5,
      tabName: "Live Output",
      element:
        modelInfo?.status == "COMPLETED" && predictionData ? (
          <LiveOutput projectId={projectId} />
        ) : (
          <ModelStatusFallback modelInfo={modelInfo} userData={userState} />
        ),
    },
  ];

  return (
    <div
      className="flex flex-col gap-2 mt-6 h-full"
      style={{ width: size.width >= 768 ? "calc(100vw - 168px)" : "100vw" }}
    >
      <div className="flex gap-2 items-center">
        <Link
          to={`/Sandbox`}
          style={{
            textDecoration: "none",
          }}
        >
          <img src="/backtick.svg" />
        </Link>
        <p className="text-xl font-medium text-[#084298] capitalize">
          {userState?.name}
        </p>
      </div>
      <div className="rounded-lg bg-white p-4 flex flex-col gap-4 w-full h-full relative">
        <Tabs isLazy={false} index={page}>
          <TabList
            display={"flex"}
            justifyContent={"space-between"}
            border={0}
            alignItems={"center"}
          >
            <div className="flex items-center gap-4 overflow-x-auto max-w-[42vw] h-fit min-[1200px]:max-w-max p-1">
              {tabList
                .filter((item) => {
                  if (item.index < 5) return item;
                  else {
                    return history?.some(
                      (item) => item?.publishStatus == "COMPLETED"
                    );
                  }
                })
                .map((item) => {
                  let x = item.index;
                  return (
                    <Tab
                      px={"24px"}
                      py={"6px"}
                      borderRadius={"4px"}
                      borderWidth={"1px"}
                      fontSize={"16px"}
                      style={{
                        borderColor: x == page ? "#6CA6FC" : "#EBEBEB",
                        fontWeight: x == page ? 500 : 400,
                        color: x == page ? "#084298" : "#605D64",
                        cursor: x != page ? "pointer" : "default",
                        backgroundColor: x == page ? "#F1F7FF" : "#fff",
                      }}
                      whiteSpace={"nowrap"}
                      onClick={() => setPage(x)}
                    >
                      {item.tabName}
                    </Tab>
                  );
                })}
            </div>
            {page == 0 ? (
              <div className="flex flex-col sm:flex-row items-start sm:items-center gap-3 whitespace-nowrap">
                <SecondaryButton
                  text={"Refresh"}
                  width={"fit-content"}
                  Icon={<AutorenewIcon />}
                  onClick={() =>
                    startTrainApi(toast, auth, projectId, userState)
                  }
                  disable={
                    (modelInfo?.status != "COMPLETED" &&
                      modelInfo?.status != "TERMINATED" &&
                      modelInfo?.status != "FAILED" &&
                      modelInfo?.status != "NO-TRAINING") ||
                    history?.some((item) => item?.publishStatus == "COMPLETED")
                  }
                />
                <div className="flex flex-col 2xl:flex-row gap-1">
                  <p className="text-[#938F96] text-sm">Last updated</p>
                  <p className="text-[#938F96] text-sm">
                    {new Date(userState?.lastUpdatedAt).toLocaleString()}
                  </p>
                </div>
              </div>
            ) : (
              <div className="flex flex-col sm:flex-row items-start sm:items-center gap-3 whitespace-nowrap">
                <TonalButton
                  text={"Re-Train"}
                  Icon={
                    <img src="/selfServiceIcons/retrain.svg" alt="retrain" />
                  }
                  onClick={retrainApi}
                  disable={
                    (modelInfo?.status != "COMPLETED" &&
                      modelInfo?.status != "TERMINATED" &&
                      modelInfo?.status != "FAILED") ||
                    history?.some((item) => item?.publishStatus == "COMPLETED")
                  }
                  width={"fit-content"}
                />
                {modelInfo?.status == "COMPLETED" ? (
                  <div className="flex flex-col gap-0">
                    <div className="flex items-center gap-1">
                      <p className="text-[#938F96] text-sm">Model precision</p>
                      <p className="text-[#69B04B] text-sm font-medium">
                        {Math.round(modelInfo?.performance?.precision * 100)}%
                      </p>
                    </div>
                    <div className="flex items-center gap-1">
                      <p className="text-[#938F96] text-sm">Model F1</p>
                      <p className="text-[#69B04B] text-sm font-medium">
                        {CalF1(
                          modelInfo?.performance?.precision,
                          modelInfo?.performance?.recall
                        )}
                      </p>
                    </div>
                  </div>
                ) : (
                  <p className="text-[#938F96] text-sm whitespace-nowrap">
                    STATUS: {modelInfo?.status}
                  </p>
                )}
              </div>
            )}
          </TabList>

          <TabPanels>
            {tabList.map((item) => {
              return (
                <TabPanel className="!pl-0 !pr-0">{item.element}</TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

export default DetailView;
