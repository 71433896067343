import { Link, useParams } from "react-router-dom";
import { Skeleton, useToast } from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import { baseURL } from "../../../index";
import axios from "axios";
import NavContext from "../../NavContext";
import CustomSizingBar from "./CustomSizingBar";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import CommonTable from "../CommonTable/CommonTable";
import TableSkeleton from "../CommonTable/TableSkeleton";
import CameraFeed from "./CameraFeed";
import DetailModal from "./DetailModal";
import { formatDateTime } from "../utils/formatDateTime";
import DataTable from "../CommonTable/DataTable";
import Barchartloader from "../CommonTable/Barchartloader";
import CompareBarChart from "./CompareBarChart";

const CameraCard = ({
  plantId,
  cameraName,
  data,
  alert,
  thresholds,
  loading,
  sizeDataChanging = true,
  selectedCategoryBar,
  setSelectedBasis,
  selectedBasis,
  fromTime,
  toTime,
}) => {
  let param = useParams();
  const toast = useToast();
  const { auth } = useContext(NavContext);
  let material = "sinter";
  const indexRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [displayData, setDisplayData] = useState([]);
  const [filterData, setFilterData] = useState({ items: [] });

  const [camMap, setCamMap] = useState({
    cameraKeys: null,
    cameraMapping: null,
  });

  useEffect(() => {
    setCamMap((prev) => ({
      ...prev,
      cameraKeys: data?.cameraKeys,
      cameraMapping: data?.cameraMapping,
    }));
  }, [data]);
  console.log(fromTime, "data123");
  return (
    <div className="relative flex flex-col gap-4 pt-1 pb-6 px-1 md:px-4 ">
      <div className="flex flex-col xs:flex-col xl:flex-row gap-4  w-full ">
        <div className="flex flex-col h-full w-full xl:w-[25%]  gap-2  p-2 bg-slate-50 rounded-md ">
          <div className="flex w-full justify-between">
            <p className=" py-0 flex gap-1 items-center  capitalize text-xs sm:text-base text-black self-start  justify-between">
              {cameraName}{" "}
            </p>

            <div className="flex gap-4 items-center">
              <p className="text-sm md:text-sm text-[#6b696e] font-semibold">
                {data?.timestamp ? (
                  formatDateTime(data?.timestamp)
                ) : (
                  <Skeleton w={20} h={5} />
                )}
              </p>
            </div>
          </div>

          <div className="bg-slate-50 h-[67vh]  sm:h-[30vh] xl:h-[63vh] w-full  flex flex-col sm:flex-row xl:flex-col gap-6 rounded-md overflow-y-auto pt-1">
            {loading || !data ? (
              [1, 2].map((e, i) => {
                return (
                  <div key={i} className="w-full ">
                    <Skeleton h={"200px"} w={"100%"} />
                  </div>
                );
              })
            ) : (
              <>
                {camMap?.cameraKeys?.length > 0 ? (
                  camMap?.cameraKeys.map((cameraKey, i) => {
                    const mappedValues = camMap.cameraMapping[cameraKey];

                    return (
                      <div className="w-full flex flex-col gap-2">
                        <p className="w-full justify-center border rounded-md font-semibold text-sm h-[35px] flex items-center">
                          {cameraKey}
                        </p>
                        <CameraFeed
                          key={i}
                          cameraName={mappedValues?.[0]}
                          data={data}
                          openingFromFeedPage={true}
                        />
                      </div>
                    );
                  })
                ) : (
                  <>
                    {[1, 2].map((e, i) => {
                      return (
                        <div
                          key={i}
                          className="h-[200px] w-full flex justify-center items-center  font-semibold rounded-md"
                        >
                          No Data
                        </div>
                      );
                    })}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        {data?.emptyBelt !== 1 ? (
          <div className="flex flex-col h-full items-center gap-3 w-full  xl:w-[75%] px-2 pt-2 ">
            <div className="w-full flex flex-col h-full gap-2">
              <div className="w-full h-full ">
                {data?.lastUpdated?.data?.length > 0 &&
                data?.lastUpdated?.order?.length > 0 ? (
                  // <CommonTable
                  //   rowData={data.lastUpdated.data}
                  //   order={data.lastUpdated.order}
                  //   tableHeight={"177px"}
                  //   rowHeight={35}
                  //   hideFooter={true}
                  // />
                  <DataTable data={data?.lastUpdated?.data || []} />
                ) : (
                  <TableSkeleton
                    headers={["1", "2", "3"]}
                    rows={2}
                    cellsPerRow={3}
                  />
                )}
              </div>
            </div>
            <p className="text-xs sm:text-base text-black self-start font-semibold">
              Previous 8 hours trend
            </p>
            <div
              className={`flex flex-col lg:flex-row justify-start lg:justify-between gap-2 items-center overflow-x-auto   w-full `}
            >
              <div className="flex flex-col md:flex-row gap-2 text-[#49454F] text-xs lg:text-base w-full ">
                <div
                  className={`p-3 min-w-[200px] flex items-center gap-2 cursor-pointer  rounded-md ${
                    selectedBasis == 0 ? "bg-[#e7effb] rounded-md" : "bg-white"
                  }`}
                  data-value={0}
                  onClick={(e) =>
                    setSelectedBasis(e.currentTarget.getAttribute("data-value"))
                  }
                >
                  <input
                    value={0}
                    checked={selectedBasis == 0}
                    onChange={(e) => setSelectedBasis(e.target.value)}
                    type="radio"
                    name="freq"
                    className="cursor-pointer accent-[#3A74CA] h-[18px] w-[18px]"
                  />
                  <p>Cold spot analysis</p>
                </div>
                <div
                  className={`p-3 flex items-center min-w-[200px] gap-2 cursor-pointer   rounded-md ${
                    selectedBasis == 1 ? "bg-[#e7effb] rounded-md" : "bg-white"
                  }`}
                  data-value={1}
                  onClick={(e) =>
                    setSelectedBasis(e.currentTarget.getAttribute("data-value"))
                  }
                >
                  <input
                    value={1}
                    checked={selectedBasis == 1}
                    onChange={(e) => setSelectedBasis(e.target.value)}
                    type="radio"
                    name="freq"
                    className="cursor-pointer accent-[#3A74CA] h-[18px] w-[18px]"
                  />
                  <p>Distance analysis</p>
                </div>
              </div>
              <div className="w-full flex justify-start lg:justify-end p-2">
                <div className="flex md:flex-row flex-col text-sm gap-2 ">
                  {fromTime ? (
                    <p>{new Date(fromTime).toLocaleString()}</p>
                  ) : (
                    <Skeleton w={20} h={4} />
                  )}
                  <p className="">To</p>
                  {toTime ? (
                    <p>{new Date(toTime).toLocaleString()}</p>
                  ) : (
                    <Skeleton w={20} h={4} />
                  )}
                </div>
              </div>
            </div>

            <div className="flex gap-1 sm:gap-[40px] items-center  h-full overflow-x-auto w-full">
              {sizeDataChanging ? (
                <Barchartloader />
              ) : selectedCategoryBar?.series?.length > 0 &&
                selectedCategoryBar?.categories?.length > 0 ? (
                <CompareBarChart
                  series={selectedCategoryBar?.series}
                  categories={selectedCategoryBar?.categories}
                  setFilterData={setFilterData}
                />
              ) : (
                <div className="w-full h-[300px] flex justify-center items-center border rounded-md">
                  <p className=" font-semibold">No Data</p>
                </div>
              )}
            </div>
            <div className="w-full flex flex-col h-full gap-2">
              <p className="text-xs sm:text-base text-black self-start font-semibold">
                Overview
              </p>
              <div className="w-full h-full">
                {data?.overview?.data && data?.overview?.order ? (
                  <CommonTable
                    rowData={data.overview.data || []}
                    order={data.overview.order || []}
                    tableHeight={"525px"}
                    rowHeight={40}
                    pagesize={10}
                    showViewDetail={true}
                    setDisplayData={setDisplayData}
                    setOpenModal={setOpenModal}
                    indexRef={indexRef}
                    minWidthMap={{ last_analysis_time: 180 }}
                  />
                ) : (
                  <TableSkeleton
                    rows={9}
                    cellsPerRow={4}
                    headers={["1", "2", "3", "4"]}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="h-full flex flex-col gap-8 items-center justify-center text-black font-bold text-center text-2xl xl:w-[75%] border">
            <img src="/Common/noCoal.svg" className="h-[10vh]" />
            <p>Empty belt</p>
          </div>
        )}
      </div>
      {/* <div className="flex flex-col gap-4 min-[400px]:flex-row justify-between">
        <Link
          to={`./${plantId}/${cameraName}`}
          style={{ textDecoration: "none" }}
        >
        <div>
          {" "}
          <PrimaryButton text={"View detail"} height={"40px"} />
        </div>

        </Link>
        <div className="flex gap-4 items-center">
          <p className="text-sm md:text-sm text-[#79767D]">Last updated</p>
          <p className="text-sm md:text-sm text-[#79767D]">
            {new Date(data?.timestamp).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "2-digit",
            })}
            &nbsp;&nbsp;&nbsp;
            {new Date(data?.timestamp).toLocaleTimeString("en-US", {
              hour12: true,
            })}
          </p>
        </div>
      </div> */}
      {openModal && (
        <DetailModal
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          data={displayData}
          index={indexRef.current}
          camMap={camMap}
        />
      )}
    </div>
  );
};

export default CameraCard;
