import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { useWindowSize } from "@uidotdev/usehooks";
import { useContext, useEffect, useState } from "react";
import { baseURL } from "../..";
import axios from "axios";
import NavContext from ".././NavContext";
import ClearIcon from "@mui/icons-material/Clear";

let wordLimit = 500;

const ReadMore = ({ setHomebadge, userBadge, setUserBadge }) => {
  const size = useWindowSize();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { auth } = useContext(NavContext);

  const [badges, setBadges] = useState([]);
  const dataPath = [
    {
      badgeLogo: "/advisor/newbie.svg",
      tag: "Newbie Explorer",
      coloredBadgeLogo: "/advisor/newBadge.svg",
    },

    {
      badgeLogo: "/advisor/proficientEnquirer.svg",
      tag: "Proficient Inquirer",
      coloredBadgeLogo: "/Common/batchok.svg",
    },
    {
      badgeLogo: "/advisor/curiousDoodle.svg",
      tag: "Curious Doodle",
      coloredBadgeLogo: "/advisor/curiousDoodleColor.svg",
    },
    {
      badgeLogo: "/advisor/promptenthu.svg",
      tag: "Prompt Enthusiast",
      coloredBadgeLogo: "/advisor/promptEnthusiast.svg",
    },
    {
      badgeLogo: "/advisor/promptengi.svg",
      tag: "Prompt Engineer",
      coloredBadgeLogo: "/advisor/promptEngineer.svg",
    },
    {
      badgeLogo: "/advisor/undisputed.svg",
      tag: "Undisputed Champion",
      coloredBadgeLogo: "/advisor/undisputedChampion.svg",
    },
  ];

  useEffect(() => {
    fetchBadges();
  }, []);
  const fetchBadges = async () => {
    try {
      const response = await axios.get(baseURL + "badges/get-badges", {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });

      // setUserBadge(response?.data.data);
      const data = response?.data.data;
      const updatedBadges = data.map((badge) => {
        const matchingLogo = dataPath.find((logo) => logo.tag === badge.tag);

        // Check if a matching logo is found
        if (matchingLogo) {
          // Add the badgeLogo key with the corresponding URL
          return {
            ...badge,
            badgeLogo: badge.recieved
              ? matchingLogo.coloredBadgeLogo
              : matchingLogo.badgeLogo,
          };
        }

        // If no matching logo is found, return the original badge object
        return badge;
      });
      const selectedBadge = updatedBadges.find(
        (badge) => badge.selectedForProfile === true
      );

      setBadges(updatedBadges);
      if (selectedBadge) {
        setHomebadge(selectedBadge);
      }
    } catch (error) {
      console.error("Error fetching badges:", error);
    }
  };

  return (
    <>
      <p
        className=" text-[#1C56AC] text-[14px] self-end cursor-pointer"
        onClick={onOpen}
      >
        Read more
      </p>

      <Modal isOpen={isOpen} onClose={onClose} isCentered="true">
        <ModalOverlay />
        <ModalContent style={{ borderRadius: "12px" }} maxW="700px">
          <ModalBody pos="relative" p="20px" rounded="12px">
            <div className={`grid grid-cols-3 gap-8 ${size.width < 768}`}>
              {badges &&
                badges.length > 0 &&
                badges.map((item) => {
                  return (
                    <div
                      key={item?.batchId}
                      className="flex flex-col justify-between gap-2"
                    >
                      <div className="flex flex-col justify-center items-center">
                        <div className="flex p-2 justify-center w-[100px] h-[100px]">
                          <img src={item.badgeLogo} alt="" />
                        </div>
                        <div className="w-full gap-1">
                          <p
                            className={`text-[16px] font-semibold text-center ${
                              item.recieved == true
                                ? "text-[#02CEFE]"
                                : "text-[#79767D]"
                            } `}
                          >
                            {item.tag}
                          </p>
                          <p className="text-[#938F96] text-[14px]  text-center">
                            {item.rewardFor}
                          </p>
                        </div>
                      </div>
                      {/* <div className="flex gap-2 justify-center">
                      <input
                        type="checkbox"
                        name="freq"
                        disabled={item.recieved ? false : true}
                        checked={item.seclectedForProfile}
                        onClick={() => {
                          setBadges((prevBadges) =>
                            prevBadges.map((badge) => ({
                              ...badge,
                              seclectedForProfile:
                                badge.seclectedForProfile == true
                                  ? item.index == badge.index
                                    ? true
                                    : false
                                  : item.index == badge.index
                                  ? true
                                  : false,
                            }))
                          );
                          setHomebadge({
                            badgeLogo: item.badgeLogo,
                            badgeTag: item.tag,
                          });
                        }}
                        className="cursor-pointer accent-[#3A74CA] h-[18px] w-[18px]"
                      />
                      <p className="text-[#a7a4a9] text-[12px]">
                        Show on my profile picture
                      </p>
                    </div> */}
                    </div>
                  );
                })}
            </div>
            <div
              className="absolute top-[-20px] left-1/2 transform -translate-x-1/2 w-[45px] h-[45px] p-2 flex rounded-full bg-white shadow-md cursor-pointer"
              onClick={onClose}
            >
              <ClearIcon className="m-auto" />
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ReadMore;
