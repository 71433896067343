import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import Feedback from "./Feedback";
import { useParams } from "react-router-dom";
import IndexChart from "../../Charts/SinterFlameCharts/IndexChart";
import axios from "axios";
import { baseURL } from "../../../index";
import NavContext from "../../NavContext";
import { useState, useContext, useRef, useEffect } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import GaugeMeter from "../../Charts/SinterFlameCharts/GaugeMeter";
import { ChevronLeft, ChevronRight, Close } from "@mui/icons-material";

const DetailModal = ({ openModal, closeModal, data, index }) => {
  console.log("detail modal", openModal, closeModal, data, index);
  let param = useParams();
  const size = useWindowSize();
  const { auth } = useContext(NavContext);
  let material = "sinterflame";
  let clientId = param.clientId.toLowerCase();
  const indexRef = useRef();
  const idRef = useRef();
  const cameraRef = useRef();
  const plantRef = useRef();
  const [modalData, setModalData] = useState({});
  const [num, setNum] = useState(0);
  const [openFeedback, setopenFeedback] = useState(false);
  const toast = useToast();

  const apiCall = async () => {
    const requestData = JSON.stringify({
      clientId: clientId,
      useCase: material.toUpperCase(),
      plantName: plantRef.current,
      cameraId: cameraRef.current,
      recordId: idRef.current,
    });
    const response = await axios
      .post(
        baseURL + "vision/v2/processMonitoring/history/single/",
        requestData,
        {
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      )
      .then((response) => {
        setModalData(response.data);
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: "Something went wrong",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        closeModal();
      });
  };

  useEffect(() => {
    indexRef.current = index;
    idRef.current = data[index].id;
    cameraRef.current = data[index].cameraId;
    plantRef.current = data[index].plantName;
    apiCall();
  }, []);

  const toggleMove = (val) => {
    //index -1
    let x = indexRef.current;
    if (val == 1) {
      indexRef.current = x > 0 ? x - 1 : data.length - 1;
    }
    //index +1
    else if (val == 2) {
      indexRef.current = x < data.length - 1 ? x + 1 : 0;
    }

    let idx = indexRef.current;
    cameraRef.current = data[idx].cameraId;
    idRef.current = data[idx].id;
    plantRef.current = data[idx].plantName;
    apiCall();
  };

  useEffect(() => {
    if ("cameraId" in modalData) {
      setNum(parseInt(modalData?.cameraId?.replace("burner", "")));
    }
  }, [modalData]);

  return (
    modalData.hasOwnProperty("annotatedImage") && (
      <>
        {size.width >= 768 ? (
          <Modal isOpen={openModal} onClose={closeModal} isCentered="true">
            <ModalOverlay />
            <ModalContent style={{ borderRadius: "12px" }} maxW="800px">
              <ModalHeader padding="0px">
                <div className="py-3 pr-2 pl-6 flex justify-between items-center bg-[#F5F5F5] rounded-tr-xl rounded-tl-xl">
                  <div className="flex gap-3 items-center">
                    <p className="text-[#AEA9B1] font-semibold text-xl">
                      {data[indexRef.current].hasOwnProperty("idx") &&
                        data[indexRef.current]["idx"] + "."}
                    </p>
                    <p className="text-black font-semibold text-sm">
                      {new Date(modalData.timestamp).toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "short",
                          day: "2-digit",
                        }
                      ) +
                        " " +
                        new Date(modalData.timestamp).toLocaleTimeString()}
                    </p>
                    <p className="text-black font-semibold text-sm">
                      {modalData.cameraId}
                    </p>
                  </div>
                  <IconButton
                  backgroundColor={"transparent"}
                  cursor={"pointer"}
                  icon={<Close sx={{ color: "gray" }} />}
                  onClick={closeModal}
                />
                </div>
              </ModalHeader>
              <ModalBody pos="relative" px="0px">
                <div className="flex">
                  {data.length > 1 && (
                    <IconButton
                    bg={"transparent"}
                    rounded={"full"}
                    size={"sm"}
                    boxShadow={"md"}
                    icon={<ChevronLeft />}
                    onClick={() => toggleMove(1)}
                  />
                  )}
                  {!(modalData.healthIndex == 0) ? (
                    <>
                      <div className="flex-1 grid grid-cols-2 gap-4 p-5">
                        <div className="flex flex-col gap-2 items-center">
                          <p className="text-sm text-[#3E3C42] self-start">
                            Original Image
                          </p>
                          <div className="flex flex-col gap-2 w-full h-full">
                            <div
                              className={`cursor-pointer hover:bg-opacity-50 text-[#525056] text-sm font-medium flex ${
                                num - 1 == 0 ? "justify-end" : "justify-between"
                              } items-center w-full px-1 py-2 bg-[#F5F5F5] rounded-lg`}
                            >
                              {num - 1 != 0 && (
                                <p>
                                  T{num - 1}:{" "}
                                  {modalData["T" + (num - 1)]?.toFixed(2)}{" "}
                                  &deg;C
                                </p>
                              )}
                              {num != 8 && (
                                <p>
                                  T{num}: {modalData["T" + num]?.toFixed(2)}{" "}
                                  &deg;C
                                </p>
                              )}
                            </div>
                            <div className="flex items-center justify-center w-full h-full bg-black rounded-lg">
                              <img
                                className="rounded-lg h-[150px]"
                                src={modalData.annotatedImage}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col gap-2">
                          <p className="text-black text-base font-medium">
                            Health Index
                          </p>
                          {/* <div className="h-full w-full">
                            <IndexChart
                              accuracy={modalData?.conf?.toFixed(0)}
                              value={modalData.healthIndex}
                            />
                          </div> */}
                          <GaugeMeter
                            accuracy={modalData?.conf?.toFixed(0)}
                            index={modalData.healthIndex}
                          />
                          {/* <div className="h-full w-full border-2 border-red-400 text-black font-medium text-base bg-red-100 flex justify-center items-center rounded-xl">
                            {modalData.alert}
                          </div> */}
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="flex-1 grid grid-cols-2 gap-4 p-5">
                      <div className="flex flex-col gap-2 items-center">
                        <p className="text-sm text-[#3E3C42] self-start">
                          Original Image
                        </p>
                        <div className="flex items-center justify-center w-full h-full bg-black rounded-lg">
                          <img
                            className="rounded-lg h-[150px]"
                            src={modalData.annotatedImage}
                          />
                        </div>
                      </div>
                      <div className="flex-1 flex flex-col gap-8 items-center justify-center">
                        <img
                          src="/SinterflameIcons/viewObstruct.svg"
                          className="h-[10vh]"
                        />
                        <p>View Obstructed</p>
                      </div>
                    </div>
                  )}
                  {data.length > 1 && ( 
                    <IconButton
                    bg={"transparent"}
                    rounded={"full"}
                    size={"sm"}
                    boxShadow={"md"}
                    icon={<ChevronRight />}
                    onClick={() => toggleMove(2)}
                  />
                  )}
                </div>
              </ModalBody>

              <ModalFooter>
                <div className="flex w-full justify-end">
                  <div className="flex flex-col gap-1 items-end">
                    <p className="text-[#605D64] text-base">
                      Noticed incorrect data?
                    </p>
                    <p
                      className="text-[#084298] text-base font-semibold cursor-pointer hover:opacity-60"
                      onClick={() => setopenFeedback(true)}
                    >
                      Give us feedback
                    </p>
                  </div>
                </div>
              </ModalFooter>
            </ModalContent>
          </Modal>
        ) : (
          <Drawer onClose={closeModal} isOpen={openModal} size="full">
            <DrawerOverlay />
            <DrawerContent>
              <DrawerHeader padding="0px">
                <div className="py-3 pr-2 pl-6 flex justify-between items-center bg-[#F5F5F5] rounded-tr-xl rounded-tl-xl">
                  <div className="flex gap-3 items-center">
                    <p className="text-[#AEA9B1] font-semibold text-xl">
                      {data[indexRef.current].hasOwnProperty("idx") &&
                        data[indexRef.current]["idx"] + "."}
                    </p>
                    <p className="text-black font-semibold text-sm">
                      {new Date(modalData.timestamp).toLocaleDateString(
                        "en-US",
                        {
                          year: "numeric",
                          month: "short",
                          day: "2-digit",
                        }
                      ) +
                        " " +
                        new Date(modalData.timestamp).toLocaleTimeString()}
                    </p>
                    <p className="text-black font-semibold text-sm">
                      {modalData.cameraId}
                    </p>
                  </div>  
                   <IconButton
                  backgroundColor={"transparent"}
                  cursor={"pointer"}
                  icon={<Close sx={{ color: "gray" }} />}
                  onClick={closeModal}
                />
                </div>
              </DrawerHeader>
              <DrawerBody padding="0px">
                <div className="flex justify-center items-center h-full">
                  {data.length > 1 && (                  
                    <IconButton
                    bg={"white"}
                    position={"absolute"}
                    top={"45%"}
                    left={0}
                    rounded={"full"}
                    size={"sm"}
                    boxShadow={"md"}
                    icon={<ChevronLeft />}
                    onClick={() => toggleMove(1)}
                    zIndex={1}
                  />
                  )}
                  {!(modalData.healthIndex == 0) ? (
                    <div className="self-start flex-1 grid grid-cols-1 gap-20 p-3">
                      <div className="flex flex-col gap-2 items-center">
                        <p className="text-sm text-[#3E3C42] self-start">
                          Original Image
                        </p>
                        <div className="flex flex-col gap-2 w-full h-full">
                          <div
                            className={`cursor-pointer hover:bg-opacity-50 text-[#525056] text-sm font-medium flex ${
                              num - 1 == 0 ? "justify-end" : "justify-between"
                            } items-center w-full px-1 py-2 bg-[#F5F5F5] rounded-lg`}
                          >
                            {num - 1 != 0 && (
                              <p>
                                T{num - 1}:{" "}
                                {modalData["T" + (num - 1)]?.toFixed(2)} &deg;C
                              </p>
                            )}
                            {num != 8 && (
                              <p>
                                T{num}: {modalData["T" + num]?.toFixed(2)}{" "}
                                &deg;C
                              </p>
                            )}
                          </div>
                          <div className="flex items-center justify-center w-full h-full bg-black rounded-lg">
                            <img
                              className="rounded-lg h-[150px]"
                              src={modalData.annotatedImage}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 h-[100px] w-full items-center justify-center">
                        <p className="text-black text-base font-medium">
                          Health Index
                        </p>
                        {/* <div className="h-full w-full">
                          <IndexChart
                            accuracy={modalData?.conf?.toFixed(0)}
                            value={modalData.healthIndex}
                          />
                        </div> */}
                        <GaugeMeter
                          accuracy={modalData?.conf?.toFixed(0)}
                          index={modalData.healthIndex}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="self-start flex-1 grid grid-cols-1 gap-20 p-3">
                      <div className="flex flex-col gap-2 items-center">
                        <p className="text-sm text-[#3E3C42] self-start">
                          Original Image
                        </p>
                        <div className="flex items-center justify-center w-full h-full bg-black rounded-lg">
                          <img
                            className="rounded-lg h-[150px]"
                            src={modalData.annotatedImage}
                          />
                        </div>
                      </div>
                      <div className="flex-1 flex gap-8 items-center justify-center">
                        <img
                          src="/SinterflameIcons/viewObstruct.svg"
                          className="h-[10vh]"
                        />
                        <p>View Obstructed</p>
                      </div>
                    </div>
                  )}
                  {data.length > 1 && (         
                    <IconButton
                    bg={"white"}
                    position={"absolute"}
                    top={"45%"}
                    right={0}
                    rounded={"full"}
                    size={"sm"}
                    boxShadow={"md"}
                    icon={<ChevronRight />}
                    onClick={() => toggleMove(2)}
                  />
                  )}
                </div>
              </DrawerBody>
              <DrawerFooter>
                <div className="flex w-full justify-end">
                  <div className="flex flex-col gap-1 items-end">
                    <p className="text-[#605D64] text-base">
                      Noticed incorrect data?
                    </p>
                    <p
                      className="text-[#084298] text-base font-semibold cursor-pointer hover:opacity-60"
                      onClick={() => setopenFeedback(true)}
                    >
                      Give us feedback
                    </p>
                  </div>
                </div>
              </DrawerFooter>
            </DrawerContent>
          </Drawer>
        )}
        {openFeedback && (
          <Feedback
            openModal={openFeedback}
            closeModal={() => setopenFeedback(false)}
            clientId={clientId}
            material={material}
            cameraId={cameraRef.current}
            plantName={plantRef.current}
            id={idRef.current}
          />
        )}
      </>
    )
  );
};

export default DetailModal;
