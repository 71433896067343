import { useContext, useEffect } from "react";
import NavContext from "../../NavContext";
import { useState } from "react";
import TableSkeleton from "../components/LoadingState/TableSkeleton";
import {
  generateDynamicColumns,
  generateWeightsTableColumns,
} from "../utilis/dataGridColumns";
import { CustomAutoComplete, WeightsTable } from "../components/WeightsTable";
import {
  getParametersForWeightsApi,
  getWeightsDataApi,
  postTestingDataApi,
  getPlaceholdersApi
} from "../services/advancedServices";
import { Input, Spinner } from "@chakra-ui/react";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import {
  Autocomplete,
  CircularProgress,
  FormControl,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";

import { getUniqueSteelGradeDataApi } from "../services/qualityMatrixServices";
import { ConfigSkeleton } from "../components/LoadingState/ConfigSkeleton";
import { SingleConfig } from "../components/SingleConfig";
import { getSimilarPlatesDataApi, getTestSimilarPlatesDataApi } from "../services/biasRecommenderServices";
import { BiasRecommenderTable } from "../components/BiasRecommenderTable";
import { nearestNeighbourColumnGroupingModel } from "../utilis/dataGridColumns";
import ExlcButton from "../utilis/ExlcButton";

export const CustomParameterEditCell = (params) => {
  const { value, colDef, api, id, field } = params;
  const handleChange = async (event, newValue) => {
    await api.setEditCellValue({ id, field, value: newValue });
  };

  return (
    <CustomAutoComplete
      value={value}
      handleChange={handleChange}
      options={colDef.valueOptions}
    />
  );
};
const CustomAsyncAutoComplete = ({ value, handleChange }) => {
  const MuiTheme = createTheme();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleAutoCompleteChange = (e, newValue) => {
    handleChange(e, "autocomplete", newValue);
  };
  useEffect(() => {
    let active = true;

    if (!loading) {
      return undefined;
    }

    const fetchData = async () => {
      try {
        const steelGradesResponse = await getUniqueSteelGradeDataApi();
        if (active && steelGradesResponse?.success) {
          setOptions(steelGradesResponse.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        if (active) {
          setLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <ThemeProvider theme={MuiTheme}>
      <FormControl
        fullWidth
        size={"small"}
      >
        <Autocomplete
          id="asynchronous-steel-grade"
          size={"small"}
          open={open}
          value={value || ""}
          onChange={handleAutoCompleteChange}
          inputValue={value || ""}
          onInputChange={handleAutoCompleteChange}
          options={options}
          loading={loading}
          onOpen={() => {
            setOpen(true);
            setLoading(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          sx={{
            "& .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input": {
              fontSize: "13px",
            },

            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              border: "0.9px solid #CBD5E0",
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant={"outlined"}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading ? (
                      <CircularProgress
                        color="inherit"
                        size={12}
                      />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
          ListboxProps={{ style: { maxHeight: "300px", fontSize: "13px" } }}
        />
      </FormControl>
    </ThemeProvider>
  );
};

export const Advanced = () => {
  const { auth } = useContext(NavContext);
  const [rows, setRows] = useState(null);
  const [columns, setColumns] = useState(null);
  const [loading, setLoading] = useState({
    weights: false,
    recommended: false,
    similarPlates: false,
  });
  const [testingData, setTestingData] = useState({
    steel_grade: "",
    primary_data_thickness: "",
    primary_data_width: "",
    primary_data_length: "",
    AFRT: "",
    // measured_data_water_temp: "",
  });
  const [recommendedConfigData, setRecommendedConfigData] = useState(null);
  const [similarPlatesColumns, setSimilarPlatesColumns] = useState(null);
  const [similarPlatesData, setSimilarPlatesData] = useState(null);
  const [placeHolders, setPlaceHolders] = useState({
    thickness: "",
    width: "",
    length: "",
    afrt: "",
  })
  const handleChange = async (e, type, newValue) => {
    if (type && type === "autocomplete") {
      await fetchPlaceHolders(newValue)
      setTestingData((prev) => ({
        ...prev,
        steel_grade: newValue,
      }));

    } else {
      const { name, value } = e.target;
      setTestingData((prev) => ({
        ...prev,
        [name]: isNaN(value) ? "" : value,
      }));
    }
  };

  const handleSubmitTestingData = async () => {
    try {
      const response = await postTestingDataApi(auth, testingData, setLoading);
      //console.log(response);
      if (response.success) {
        setRecommendedConfigData((prev) => response.data);
        const requestData = JSON.stringify(testingData);
        await fetchTestSimilarPlates(requestData);
      }
    } catch (error) {
      console.log(error);
      setRecommendedConfigData((prev) => null);
    }
  };

  const fetchPlaceHolders = async (steelGrade) => {
    try {
      const requestData = {
        steel_grade: steelGrade
      }
      const response = await getPlaceholdersApi(auth, requestData);
      //console.log(response);
      if (response?.success) {
        setPlaceHolders(response?.data);
      }
    } catch (error) {
      console.log(error);
    }
  }
  const fetchParameterWeightData = async () => {
    try {
      const weightsResponse = await getWeightsDataApi(auth, setLoading);
      const parametersForWeightsResponse = await getParametersForWeightsApi();
      if (weightsResponse?.success && parametersForWeightsResponse?.success) {
        const tempColumns = await generateWeightsTableColumns(
          weightsResponse?.data?.columns,
          true,
          true,
          false,
          true,
          parametersForWeightsResponse.data
        );
        setColumns((prev) => tempColumns);
        setRows((prev) => weightsResponse?.data?.tableData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getOrder = (columns) => {

    console.log(columns, "columns");
    let newArray = [];

    columns?.forEach((column) => {
      newArray.push(column?.value ? column?.value : column?.field);
    })

    return newArray;
  }

  const fetchSimilarPlates = async (requestData) => {
    try {
      const response = await getSimilarPlatesDataApi(
        auth,
        requestData,
        setLoading
      );
      if (response?.success && response?.data) {
        // to generate incoming columns only on the first render

        const columns = Object.keys(response?.data[0]);
        const tempSimilarPlatesColumns = await generateDynamicColumns(
          columns,
          false,
          false,
          true
        );
        // adding bias recommender specific column
        setSimilarPlatesColumns((prev) => [...tempSimilarPlatesColumns]);
        setSimilarPlatesData((prev) => response?.data);
      } else {
        setSimilarPlatesData((prev) => null);
      }
    } catch (error) {
      console.log(error);
      setSimilarPlatesData((prev) => null);
    }
  };

  const fetchTestSimilarPlates = async (requestData) => {
    try {
      const response = await getTestSimilarPlatesDataApi(
        auth,
        requestData,
        setLoading
      );
      if (response?.success && response?.data) {
        // to generate incoming columns only on the first render

        const columns = Object.keys(response?.data[0]);
        const tempSimilarPlatesColumns = await generateDynamicColumns(
          columns,
          true,
          true,
          false
        );
        // adding bias recommender specific column
        setSimilarPlatesColumns((prev) => [...tempSimilarPlatesColumns]);
        setSimilarPlatesData((prev) => response?.data);
      } else {
        setSimilarPlatesData((prev) => null);
      }
    } catch (error) {
      console.log(error);
      setSimilarPlatesData((prev) => null);
    }
  };
  //   fetch data on initial load
  useEffect(() => {
    fetchParameterWeightData();
  }, []);

  return (
    <div className="w-full flex flex-col gap-8 bg-white">
      {/* Weights Table */}

      {
        !loading?.weights && columns && rows &&
        <div className="flex flex-row w-full justify-between px-2 py-2">
          <p className="rounded-e-xl rounded-s-xl text-xl font-medium">Weights Table</p>
          <ExlcButton order={getOrder(columns)} data={rows} name={"Weights_Table"} />
        </div>
      }
      {loading.weights ? (
        <TableSkeleton
          headers={["Header 1", "Header 2", "Header 3"]}
          rows={9}
          cellsPerRow={3}
        />
      ) : (
        columns &&
        rows && (
          <WeightsTable
            rows={rows}
            columns={columns}
            setRows={setRows}
            fetchData={fetchParameterWeightData}
          />
        )
      )}
      {/* Testing */}
      <div className="bg-white rounded-xl py-3 flex flex-col gap-4">
        <p className="rounded-e-xl rounded-s-xl px-2 text-xl font-medium">
          Testing
        </p>
        <div className="grid grid-cols-2 md:grid-cols-4 w-full gap-5 px-5">
          <div className="flex flex-col max-w-[320px] gap-2 ">
            <p className="text-[#2660B6] text-sm font-semibold">Grade</p>
            <CustomAsyncAutoComplete
              value={testingData?.steel_grade}
              handleChange={handleChange}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 w-full gap-5 px-5">

          <div className="flex flex-col w-full gap-2">
            <p className="text-[#2660B6] text-sm font-semibold">Thickness (metres)</p>
            <Input
              name="primary_data_thickness"
              value={testingData?.primary_data_thickness}
              onChange={handleChange}
              placeHolder={placeHolders?.thickness}
            />
          </div>
          <div className="flex flex-col w-full gap-2">
            <p className="text-[#2660B6] text-sm font-semibold">Width (metres)</p>
            <Input
              name="primary_data_width"
              value={testingData?.primary_data_width}
              onChange={handleChange}
              placeholder={placeHolders?.width}
            />
          </div>
          <div className="flex flex-col w-full gap-2">
            <p className="text-[#2660B6] text-sm font-semibold">Length (metres) </p>
            <Input
              name="primary_data_length"
              value={testingData?.primary_data_length}
              onChange={handleChange}
              placeholder={placeHolders?.length}
            />
          </div>
          <div className="flex flex-col w-full gap-2">
            <p className="text-[#2660B6] text-sm font-semibold">AFRT</p>
            <Input
              name="AFRT"
              value={testingData?.AFRT}
              placeholder={placeHolders?.afrt}
              onChange={handleChange}
            />
          </div>

          {/* <div className="flex flex-col w-full gap-2">
            <p className="text-[#2660B6] text-sm font-semibold">
              Water Temperature
            </p>
            <Input
              name="measured_data_water_temp"
              value={testingData?.measured_data_water_temp}
              onChange={handleChange}
            />
          </div> */}
        </div>
        <div className="flex flex-row self-start px-5">
          <PrimaryButton
            text={loading.recommended ? <Spinner /> : "Test"}
            onClick={handleSubmitTestingData}
            width={"80px"}
            disable={loading.recommended}
          />
        </div>
      </div>
      {loading.recommended ? (
        <ConfigSkeleton />
      ) : (
        <div className="w-full bg-white rounded-xl pb-1">
          {/* Recommended Config */}
          {recommendedConfigData && recommendedConfigData !== -1 && (
            <p className="font-medium text-xl px-2 py-3">Recommended Config</p>
          )}
          {recommendedConfigData && recommendedConfigData !== -1 ? (
            <SingleConfig
              data={recommendedConfigData}
              isSingle={true}
              isRecommended={true}
            />
          ) : (
            <div>
              {/* <p className="text-md text-gray-400 text-left px-2">
                No Recommendation Config Found
              </p> */}
            </div>
          )}
        </div>
      )}
      <div className="w-full rounded-xl bg-white">
        {/* Historical Data for a particular row */}
        <div className="flex flex-row w-full justify-between px-2 pt-2">

          {similarPlatesData && similarPlatesColumns && (
            <p className="rounded-e-xl rounded-s-xl px-2 py-3 text-xl font-medium">
              Nearest Neighbour
            </p>
          )}
          {similarPlatesData && similarPlatesColumns && <ExlcButton order={getOrder(similarPlatesColumns)} data={similarPlatesData} name={"Nearest_Neighbour"} />}
        </div>

        <div className="h-[60vh] w-full">
          {loading?.similarPlates ? (
            <TableSkeleton
              headers={[
                "Header 1",
                "Header 2",
                "Header 3",
                "Header 4",
                "Header 5",
                "Header 6",
                "Header 7",
                "Header 8",
              ]}
              rows={7}
              cellsPerRow={8}
            />
          ) : (
            similarPlatesData &&
            similarPlatesColumns && (
              <BiasRecommenderTable
                columns={similarPlatesColumns || []}
                rowData={similarPlatesData || []}
                columnGroupingModel={nearestNeighbourColumnGroupingModel}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};
