import { CustomStyledDataGrid } from "../../../../util/MaterialDataGrid/CustomStyledDatagrid";
import TableSkeleton from "../../../SinterBelt/components/LoadingState/TableSkeleton";
import CommonTable from "../../Tables/CommonTable";
import PurchaseOrder from "../FeedComponents/PurchaseOrder";

const FeedPageParent = ({ tabName, data, laoding }) => {
  console.log({ tabName });
  const rowData = data?.[tabName] || [];
  // const startTime = showCurrentTabData?.startTime;
  // const endTime = showCurrentTabData?.endTime;
  let order = [];
  if (data?.order?.length > 0) {
    order = [...data?.order, "deviation"];
  }

  // let insertRowData = [
  //   {
  //     concentrate: "Start time",
  //     deviation: data?.poStart,
  //     concentrateStatus: true,
  //   },
  //   {
  //     concentrate: "End time",
  //     deviation: data?.poEnd,
  //     concentrateStatus: true,
  //   },
  // ];
  // const recipeDetailsData = Array.isArray(
  //   showCurrentTabData?.recipeDetails?.data
  // )
  //   ? showCurrentTabData.recipeDetails.data
  //   : [];

  // let insertTotal = [
  //   {
  //     concentrate: "Total",
  //     recipeQuantity: showCurrentTabData?.recipeQuantity
  //       ? showCurrentTabData.recipeQuantity
  //       : null,
  //     actualQuantity: showCurrentTabData?.actualQuantity
  //       ? showCurrentTabData.actualQuantity
  //       : null,
  //     concentrateStatus: true,
  //   },
  // ];

  // const rowData = [...insertRowData, ...recipeDetailsData];
  const handleGetRowClassName = (params) => {
    const { row } = params;
    console.log({ row });
    if (row?.concentrateStatus === false) {
      return "bg-[#FCEEEE]";
    }
  };

  return (
    <div>
      {/* <PurchaseOrder purchaseDetail={showCurrentTabData?.purchaseDetail} /> */}
      <div className="">
        {!laoding ? (
          <CommonTable
            order={order || []}
            getRowClassName={handleGetRowClassName}
            rowData={rowData ? rowData : []}
            tableHeight={"425px"}
          />
        ) : (
          <TableSkeleton
            headers={["Header 1", "Header 2", "Header 3", "Header 4"]}
            rows={8}
            cellsPerRow={4}
          />
        )}
      </div>
    </div>
  );
};
export default FeedPageParent;
