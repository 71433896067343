import React, { useState, useEffect, useRef, useContext } from "react";
import { baseURL } from "../..";
import NavContext from "../NavContext";
import Typewriter from "./Typewriter";
import { Link, useParams } from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";
import { getCreditsRemaining } from "../../util/utilFunctions";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import { Text } from "@chakra-ui/react";

const AiAdvisor = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [send, setSend] = useState(false);
  const { chatId } = useParams();
  const { auth } = useContext(NavContext);
  const ref = useRef(null);
  const size = useWindowSize();

  const [response, setResponse] = useState([]);
  const [typing, setTyping] = useState(false);
  const [text, setText] = useState("");
  const [credits, setCredits] = useState(0);
  const [isErrorShown, setIsErrorShown] = useState(false);

  const sampleQuestions = [
    {
      id: 1,
      question:
        "What are the recommended strategies and techniques for effectively mitigating scaffold build-up on the refractory lining of a blast furnace?",
    },
    {
      id: 2,
      question:
        "Ask me to generate test plans for the manufacturing of automobile axels including test cases, and acceptance criteria.",
    },
  ];

  useEffect(() => {
    getBalance();
    if (chatId !== "chatId") {
      fetchChatHistory();
    }

    return () => {
      clearChat();
    };
  }, []);

  const clearChat = async () => {
    try {
      const data = await fetch(baseURL + "chat/clear", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      const res = await data.json();

      console.log("ai adv res", res);
    } catch (error) {
      console.log(error);
    }
  };

  const getBalance = async () => {
    try {
      const data = await fetch(baseURL + "token-wallet/v1/balance", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      const res = await data.json();
      // setCredits(getCreditsRemaining(res));
      setCredits(res.User.balance);
      console.log("ai adv res", res);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchChatHistory = async () => {
    const param = {
      chatId: chatId || "",
    };
    try {
      const response = await axios.get(baseURL + "chat/id", {
        params: param,
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });

      const chatData = response?.data.tchatQuestionAnswers;

      // Create a new array to store organized questions and answers
      const organizedData = [];

      // Iterate through the original data and organize it
      chatData.forEach((item) => {
        // Push question
        organizedData.push(item.chatQuestion);

        // Push answer
        organizedData.push(item.chatAnswer);
      });
      setResponse((current) => [
        ...current,
        ...addBreakOrganizedData(organizedData),
      ]);
      setSend(true);
      // setTimeout(() => {
      //   setSend(false);
      // }, 1500);
      // console.log(organizedData);
      // console.log("chat history", response?.data.tchatQuestionAnswers);
    } catch (error) {
      console.error("Error fetching badges:", error);
    }
  };
  console.log(response);
  const callChatGpt = async (ask) => {
    if (ask.length < 2 || ask.length > 2048) {
      setIsErrorShown(true);
      return;
    }
    setIsErrorShown(false);
    setTyping(true);
    setText("");
    setResponse((current) => [...current, ask]);
    const data = await fetch(baseURL + "chat", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": auth,
      },
      body: JSON.stringify({
        message: ask,
      }),
    });
    const res = await data.json();
    setSend(true);
    setTyping(false);
    setResponse((current) => [...current, addBreak(res?.data?.reply)]);
    getBalance();
  };

  function addBreak(str) {
    return str?.replace("\n\n", "<br><br>");
  }

  function addBreakOrganizedData(strArray) {
    return strArray?.map((str) => str.replace(/\n\n/g, "<br><br>"));
  }

  useEffect(() => {
    if (response.length) {
      ref?.current?.scrollIntoView({
        behaviour: "smooth",
        block: "end",
      });
    }
  }, [response.length]);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setSend(true);
      callChatGpt(text);
    }
  };

  const handleBackButton = () => {
    const pathName = location?.state?.path;
    if (pathName == "/home") {
      navigate("/home");
    } else if (pathName == "/community") {
      navigate("/community");
    } else {
      navigate("/home");
    }
  };

  return (
    <div className="">
      <div className="mt-[3vh] bg-white">
        <div className="flex items-center">
          <div className="cursor-pointer w-8" onClick={handleBackButton}>
            <img
              src="/backtick.svg"
              className="w-full h-full"
              alt="backarrow_img"
            />
          </div>
          <p className="text-[#084298] font-medium text-xl ml-2">AI Advisor</p>
        </div>
        {send === false ? (
          <div className="flex flex-col justify-center">
            <div className="rounded-md border border-[#3A74CA] w-full">
              <div className="grid grid-cols-8">
                <div className="col-span-1 m-10 mt-4 mb-4">
                  <img src="/doc.svg" />
                </div>
                <div className="col-span-7 mt-3 mr-20 ">
                  {/* <div className="text-2xl font-bold">
                  Hello
                </div> */}
                  <div className="mt-3 text-[#605D64] md:text-base text-sm ">
                    I am your go to AI Advisor. Ask me all your doubts about
                    manufacturing and I will be more than happy to answer them!
                  </div>
                  <div className="text-[#605D64] text-xs mt-3">
                    Note : It is important to note that while I try to provide
                    accurate information, I can sometimes make errors. So always
                    double-check the important facts independently.
                  </div>
                </div>
              </div>
            </div>
            <div className="md:visible invisible mt-3 w-full">
              <div className=" text-xs mb-3 flex justify-center w-full">
                <div
                  style={{ width: "85.5vw" }}
                  className="flex justify-between gap-4"
                >
                  {sampleQuestions?.map((item, index) => {
                    return (
                      <div
                        key={index}
                        onClick={() => {
                          callChatGpt(item?.question);
                          setSend(true);
                        }}
                        className="rounded-md border border-[#605D64] px-2 py-2 w-1/2 text-[#605D64] bg-white cursor-pointer hover:bg-gray-100 hover:transition duration-200"
                      >
                        {item?.question}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="w-full md:h-[60vh] h-[55vh] overflow-y-scroll">
            {response?.map((item, index) => {
              return (
                <div className="mt-[1vh] overflow-y-scroll border rounded-md">
                  <div className="grid grid-cols-12">
                    {index % 2 !== 0 ? (
                      <div className="col-span-1">
                        <img
                          className="md:h-[8vh] md:w-[3vw] md:mt-0 mt-4 ml-4"
                          src="/doc.svg"
                        />
                      </div>
                    ) : (
                      <div className="col-span-1">
                        <div className="ml-4 mt-3 h-[5vh] w-[3vw]  px-4 py-4 border bg-[#FAFAFA] flex justify-center items-center font-bold rounded-md">
                          Q
                        </div>
                      </div>
                    )}
                    {index % 2 !== 0 ? (
                      <div
                        key={index}
                        className=" col-span-11 mt-[3vh] mb-[3vh] md:-ml-8 ml-8 mr-6 text-gray-500 text-sm"
                      >
                        <Typewriter
                          text={item}
                          delay={chatId !== "chatId" ? 0 : 10}
                          infinite
                        />
                        {/* <div ref={ref} lassName='h-4 border'></div> */}
                      </div>
                    ) : (
                      <div
                        key={index}
                        className="col-span-10 mt-[3vh] mb-[3vh] md:-ml-8 ml-8 mr-6 text-gray-500 text-sm"
                      >
                        {item}
                      </div>
                    )}
                    {index % 2 === 0 ? (
                      <div key={index} className="col-span-1">
                        {/* <img
                          className="ml-14 mt-6 cursor-pointer hover:scale-110 hover:transition duration-200"
                          src="/share.svg"
                        /> */}
                      </div>
                    ) : null}
                  </div>
                </div>
              );
            })}
            <div className="h-4" ref={ref}></div>
          </div>
        )}
        {typing === true && send === true ? (
          <div className="chat-bubble">
            <div className="typing">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          </div>
        ) : null}
        {/* {send === false ? (
          <div className="md:visible invisible">
            <div className="fixed bottom-32 ml-4 text-xs mb-3">
              <div style={{ width: "85.5vw" }} className="flex gap-4">
                {sampleQuestions?.map((item, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        callChatGpt(item?.question);
                        setSend(true);
                      }}
                      className="rounded-md border border-[#605D64] px-2 py-2 w-1/2 text-[#605D64] bg-white cursor-pointer hover:bg-gray-100 hover:transition duration-200"
                    >
                      {item?.question}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : null} */}

        <div
          className={`md:fixed  md:bottom-12 lg:bottom-8 w-full md:pr-40 pr-4 `}
        >
          {send === false && (
            <div className="flex flex-row justify-end mb-3">
              <div className="mt-[1vh] border border-[#3A74CA] rounded-md">
                <div className="flex flex-row ">
                  <div className="">
                    <img
                      className="md:h-[8vh] md:w-[3vw] md:mt-0 mt-4 ml-4"
                      src="/doc.svg"
                    />
                  </div>
                  <div className="mt-[3vh] mb-[3vh] ml-8 mr-6 text-gray-500 text-sm">
                    {"Hi, I am your AI advisor. How can I help you today?"}
                  </div>
                </div>
              </div>
            </div>
          )}

          {
            <div className="rounded-md border border-[#3A74CA] h-16 bg-white px-5 py-2 flex gap-2 items-center ">
              <input
                value={text}
                onKeyDown={handleKeyDown}
                onChange={(e) => setText(e.target.value)}
                className="w-full px-2 py-2 select-none"
                disabled={typing}
                placeholder="Send a message"
              />

              {/* (text.length >= 2 && text.length <= 2048) &&  */}
              <div className="cursor-pointer" onClick={() => callChatGpt(text)}>
                <img src="/send.svg" />
              </div>
            </div>
          }

          <div className="flex flex-row">
            <div className="w-[40%] flex justify-start items-center">
              {(text.length < 2 || text.length > 2048) && isErrorShown && (
                <Text color="red.500" fontSize="sm" mt="1">
                  ⚠️ Character limit should be between 2 - 2048 characters
                </Text>
              )}
            </div>
            <div className="w-[60%] flex justify-end items-center">
              {/* {size.width > 420 ? (
              <p className="pt-1 text-[10px] ml-2 text-gray-500 md:mr-0 mr-2">
                Note : It is important to note that while the bot tries to
                provide accurate information, it can sometimes make errors. So
                always double-check the important facts independently.
              </p>
            ) : null} */}

              <div
                className="flex flex-col md:flex-row md:items-start md:justify-start mb-0"
                style={{ marginBottom: "0px" }}
              >
                <div className="flex flex-col md:flex-row items-end md:items-center gap-2 md:gap-4 text-[14px] mt-3 mr-5 md:mb-0 whitespace-nowrap">
                  <p
                    className={
                      credits < 5
                        ? "font-bold text-[#DC362E]"
                        : "font-bold text-black"
                    }
                  >
                    {credits}{" "}
                    <img
                      src="/token.svg"
                      className="h-[12px] inline-block -mt-[2px]"
                    />{" "}
                    Remaining
                  </p>

                  <Link to="/community/advisor/buycredits/user/foruser">
                    <div className="text-[#124CA2] font-bold cursor-pointer">
                      Buy tokens
                    </div>
                  </Link>
                  <Link to="/user/transactionhistory">
                    <div className="text-[#124CA2] font-bold cursor-pointer">
                      View Transaction History
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`flex flex-col mr-5 items-end mb-10 md:mb-0 md:flex-row mr justify-end ${
              credits > 0 ? "md:mr-44" : "md:mr-32"
            } `}
          >
            {/* credits > 0 && credits < 20 && */}
            <div className="">
              {credits > 0 && credits < 20 && (
                <Text color="red.500" fontSize="sm" mt="1">
                  Token balance low, please add more
                </Text>
              )}
              {credits === 0 && (
                <Text color="red.500" fontSize="sm" mt="1">
                  ⚠️ Minimum token balance criteria not met
                </Text>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* <div className="md:fixed mb-10 md:mb-0 md:bottom-2 md:w-[76.2%] 2xl:w-[82.2%] flex flex-row justify-end">
        <div className="">
          {credits > 0 && credits < 20 && (
            <Text color="red.500" fontSize="sm" mt="1">
              Token balance low, please add more
            </Text>
          )}
          {credits === 0 && (
            <Text color="red.500" fontSize="sm" mt="1">
              ⚠️ Minimum token balance criteria not met
            </Text>
          )}
        </div>
      </div> */}
    </div>
  );
};

export default AiAdvisor;
