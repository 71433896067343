const isValidAlertGrpName = (name) => {
  // Regular expression for name validation (allowing only letters, numbers, and underscores, with no numbers at the start and a minimum length of 2 characters)
  // const regex = /^[a-zA-Z][a-zA-Z0-9_\s]{1,}$/;
  // const regex = /^[a-zA-Z]([a-zA-Z0-9_-]*[a-zA-Z0-9])?$/;
  const regex = /^[a-zA-Z]([a-zA-Z0-9_ -]*[a-zA-Z0-9])?$/;

  return regex.test(name?.trim());
};

export const alertGroupValidation = (
  formData,
  validationErrors,
  setValidationErrors,
  toast
) => {
  const newValidationErrors = {};

  if (!formData?.alertGroup || !isValidAlertGrpName(formData?.alertGroup)) {
    if (!formData?.alertGroup) {
      newValidationErrors.alertGroup = "Please enter alert group name";
    } else if (!isValidAlertGrpName(formData?.alertGroup)) {
      newValidationErrors.alertGroup = "Please enter a valid alert group name";
    }
  }
  if (
    validationErrors?.alertGroup &&
    validationErrors?.alertGroup === "Alert group already exists"
  ) {
    // already set the error in fetchSearchSuggestions
    // check for similar alert group name exists or not
    newValidationErrors.alertGroup = "Alert group already exists";
    toast({
      // showing toast if alert group name exists
      title: "Alert group already exists",
      status: "error",
      duration: 4000,
      isClosable: true,
      position: "top",
    });
  }

  if (!formData?.alertType) {
    newValidationErrors.alertType = "Please select alert type";
  }

  if (!formData?.alertTypeName && formData?.alertType == "product") {
    newValidationErrors.alertTypeName = "Please select an option";
  }
  if (formData?.alertGroup?.trim()?.length > 50) {
    newValidationErrors.alertGroup = `You have exceeded the character limit of 50 by ${
      formData?.alertGroup.length - 50
    }`;
  }
  if (formData?.alertGroupDesc?.trim()?.length > 200) {
    newValidationErrors.alertGroupDesc = `You have exceeded the character limit of 200 by ${
      formData?.alertGroupDesc?.length - 200
    }`;
  }

  setValidationErrors(newValidationErrors);

  return newValidationErrors;
};
