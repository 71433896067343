import { saveAs } from "file-saver";
import { useState, useEffect, useMemo } from "react";
import { capitalizeFirstLetter } from "../../../util/sentenceCase";
import { Button, IconButton, Select, Tooltip } from "@chakra-ui/react";
import { formatDateTime } from "../utils/formatDateTime";
import CompareModal from "./CompareModal";
import { AspectRatio } from "@mui/icons-material";
import PlantCardNoData from "./PlantCardNoData";
import LibraryGridSkeleton from "../CommonTable/LibraryGridSkeleton";
import Paginator from "../utils/Paginator";
import { ZoomedImageModal } from "./ZoomedImageModal";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import DetailModal from "./DetailModal";
const LibraryGrid = ({
  plantName,
  img,
  imgType,
  filterForCam,
  camMap,
  setCamMap,
  imgDataChanging,
  currect_page,
  setCurrect_page,
}) => {
  const [displayData, setDisplayData] = useState([]);
  const [selectedPoints, setSelectedPoints] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [openZoomModal, setOpenZoomModal] = useState(false);
  const [showProcessData, setShowProcessData] = useState(false);
  const filteredImg = useMemo(() => {
    let filtered = img?.filter((item) => item?.hasOwnProperty(imgType));
    if (showProcessData) {
      filtered = filtered.filter((item) => item.avgFlameDistance != null);
    }
    return filtered;
  }, [img, imgType, showProcessData]);

  const uniqueOptions = useMemo(() => {
    let data = new Set();
    for (let i in img) {
      for (let key in img[i]) {
        if (key?.includes("Image")) data?.add(key);
      }
    }
    return Array.from(data);
  }, [img]);

  const ImgDownload = (url, idx) => {
    const blob = new Blob([url], { type: "image/jpeg" });
    saveAs(url, `image-${idx}.jpg`);
  };

  const handleCheckboxClick = (val) => {
    setSelectedPoints((prev) => {
      let updatedData = [...prev];
      let idx = updatedData.findIndex((item) => item.id === val.id);

      if (idx !== -1) {
        // If the item is found, remove it from the array
        updatedData.splice(idx, 1);
      } else {
        // If the item is not found, add it to the array
        updatedData.push(val);
      }

      return updatedData;
    });
  };

  const handlePreviewImage = (idToOpen) => {
    setSelectedImageIndex(
      (prev) => isNaN(idToOpen) === false && Number(idToOpen)
    );
    setOpenZoomModal((prev) => true);
  };

  const handleCheckboxChange = () => {
    setShowProcessData((prev) => !prev);
    setCurrect_page((prev) => ({
      ...prev,
      active: false,
    }));
  };

  useEffect(() => {
    if (filteredImg.length == 0) setDisplayData([]);
  }, [filteredImg]);

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-end">
        {/* {filterForCam && (
          <div className="min-w-[110px]">
            <Select
              borderColor="#CAC5CD"
              color="#605D64"
              variant="outline"
              rounded={"base"}
              onChange={(e) => setImgType(e.target.value)}
              value={imgType ? imgType : filterForCam?.[0]?.camera}
            >
              {filterForCam.map((e) => {
                return <option value={e?.camera}>{e?.displayName}</option>;
              })}
            </Select>
          </div>
        )} */}
        <div className="flex flex-col sm:flex-row gap-3 items-center w-full  justify-end">
          <div
            style={{
              background: showProcessData ? "#DEF" : "",
              border: showProcessData
                ? "2px solid #3A74CA"
                : "2px solid #ECEFF1",
              opacity: imgDataChanging || filteredImg.length == 0 ? 0.5 : 1, // Add this to visually indicate it's disabled
              pointerEvents:
                imgDataChanging || filteredImg.length == 0 ? "none" : "auto", // Prevent interaction when disabled
            }}
            className="flex gap-2 items-center py-1 px-2 border rounded-md cursor-pointer"
            data-value={showProcessData}
            onClick={handleCheckboxChange}
          >
            <p
              style={{
                fontWeight: showProcessData ? "semibold" : "",
              }}
            >
              Show only proccesed data
            </p>
            <input
              type="checkbox"
              checked={showProcessData}
              readOnly
              className="cursor-pointer"
              disabled={imgDataChanging || filteredImg.length == 0}
            />
          </div>
          <div className="flex gap-2">
            <Button
              onClick={() => setOpenModal(true)}
              isDisabled={selectedPoints.length < 2}
              size="sm"
              colorScheme="facebook"
              rounded={"base"}
            >
              {`Compare (${selectedPoints.length}/4)`}
            </Button>
            <p
              className="text-[#024D87]  rounded-sm flex justify-center items-center cursor-pointer"
              onClick={() => setSelectedPoints([])}
            >
              <Tooltip label="Remove All" placement="top">
                <RestartAltIcon className="hover:scale-105" />
              </Tooltip>
            </p>
          </div>
          {filteredImg.length != 0 && (
            <div className="w-full sm:w-[200px] flex justify-end">
              <Paginator
                data={filteredImg}
                limit={100}
                setDisplayData={setDisplayData}
                initialPage={currect_page.active ? currect_page.page : 1}
                setCurrect_page={setCurrect_page}
              />
            </div>
          )}
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  border rounded-md p-2 gap-1 max-h-[80vh] overflow-y-scroll ">
        {imgDataChanging ? (
          <div className="w-full h-full col-span-1 sm:col-span-2 lg:col-span-3 xl:col-span-4">
            <LibraryGridSkeleton />
          </div>
        ) : displayData?.length > 0 ? (
          displayData.map((x, id) => {
            return (
              <div
                key={id}
                className="relative w-full object-cover text-center overflow-hidden h-[24vh] max-h-[25vh]   group"
              >
                <div className="flex flex-row  absolute top-2 left-2 items-center gap-1">
                  <IconButton
                    onClick={() => handlePreviewImage(id)}
                    size={"xs"}
                    padding={"2px"}
                    backgroundColor={"grey"}
                    _hover={{ backgroundColor: "black" }}
                    _active={{ backgroundColor: "transparent" }}
                    icon={
                      <AspectRatio
                        className="preview-image text-white hover:cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
                        data-index={id}
                        fontSize={"medium"}
                      />
                    }
                  />
                  <p className="text-white text-xs font-semibold bg-black rounded-sm px-[4px] py-[3px]">
                    Board No- {capitalizeFirstLetter(x.boardNo)}
                  </p>
                </div>
                <div className="flex gap-2 absolute top-2 right-2 items-center justify-center">
                  <div className="bg-black rounded-sm flex   px-[4px] py-[3px]">
                    <p className="text-white text-xs font-semibold">
                      {formatDateTime(x?.timestamp)}
                    </p>
                  </div>
                  {x?.avgFlameDistance != null && (
                    <input
                      type="checkbox"
                      checked={selectedPoints.some((item) => item.id === x.id)}
                      disabled={
                        !selectedPoints.some((item) => item.id === x.id) &&
                        selectedPoints.length === 4
                      }
                      onChange={() => handleCheckboxClick(x)}
                      className={`w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 accent-blue-500 rounded focus:ring-blue-500 hover:cursor-pointer`}
                    />
                  )}
                </div>
                {x?.[imgType] ? (
                  <img
                    className="w-full rounded-lg h-full object-fit"
                    src={x?.[imgType]}
                    alt="Image"
                  />
                ) : (
                  <div className="w-full h-[24vh] max-h-[25vh]  font-semibold text-[#263238] rounded-lg text-sm border flex justify-center items-center">
                    No Image
                  </div>
                )}
                <div className="flex gap-4 absolute top-8 right-1 opacity-50 hover:opacity-75">
                  {/* <img src="/SizingIcons/ShareIcon.svg" alt="" /> */}
                  {x?.[imgType] && (
                    <img
                      className="cursor-pointer rounded-full"
                      src="/SizingIcons/DownloadIcon.svg"
                      alt=""
                      onClick={() => ImgDownload(x?.[imgType], x.id)}
                    />
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <div className="w-full h-full col-span-1 sm:col-span-2 lg:col-span-3 xl:col-span-4">
            <PlantCardNoData />
          </div>
        )}
      </div>

      {openModal && (
        <CompareModal
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          data={selectedPoints}
          setData={setSelectedPoints}
          clientId={"jsw-stage"}
          plantName={plantName}
          filterForCam={filterForCam}
          camMap={camMap}
        />
      )}
      {openZoomModal && selectedImageIndex !== null && (
        <DetailModal
          openModal={openZoomModal}
          closeModal={() => {
            setOpenZoomModal((prev) => false);
            setSelectedImageIndex(null);
          }}
          data={displayData}
          index={selectedImageIndex}
          //  PlantName={selectedPlant}
          camMap={camMap}
        />
        // <ZoomedImageModal
        //   imageIndex={selectedImageIndex}
        //   openModal={openZoomModal}
        //   data={displayData}
        //   closeModal={() => {
        //     setOpenZoomModal((prev) => false);
        //     setSelectedImageIndex(null);
        //   }}
        //   camMap={camMap}
        // />
      )}
    </div>
  );
};

export default LibraryGrid;
