// ReusableSkeleton.jsx
import React from "react";
import { Skeleton } from "@chakra-ui/react";

export const SingleElementSkeleton = () => {
  return (
    <div className="flex flex-col gap-4 mb-5">
      {/* Single Chart */}
      <div className="flex flex-col gap-2 bg-white p-2 rounded-xl">
        <Skeleton height="320px" width="100%" borderRadius="md" />
      </div>

      {/* Accordian for Drivers */}
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3 gap-4 bg-white p-2 rounded-md">
        {Array.from({ length: 6 }).map((_, index) => (
          <Skeleton key={index} height="320px" width="100%" borderRadius="md" />
        ))}
      </div>
    </div>
  );
};
