import { Checkbox } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Paginator from "../../../util/VisionUtils/Paginator";

const ImageSelector = ({
  selectedImages,
  setSelectedImages,
  annotatedImages,
  setAnnotatedImages,
  images,
  setImages,
  labels,
  page,
  setPage,
}) => {
  const [displayData, setDisplayData] = useState([]);
  const [paginatedData, setPaginatedData] = useState([]);
  const [checkedAll, setCheckedAll] = useState(false);

  const handleChange = (x) => {
    setSelectedImages((prev) => {
      let newData = [...prev];
      let idx = newData.findIndex((item) => item.id == x.id);
      if (idx == -1) {
        newData.push(x);
      } else {
        newData.splice(idx, 1);
      }
      return newData;
    });
  };

  const handleRemove = (x) => {
    setAnnotatedImages((prev) => {
      let newData = [...prev];
      let idx = newData.findIndex((item) => item.id == x.id);
      newData.splice(idx, 1);
      return newData;
    });
    setImages((prev) => {
      let newData = [...prev];
      delete x.label;
      newData.push(x);
      return newData;
    });
  };

  const SelectAll = (e) => {
    let pages = Math.ceil(displayData.length / 8);
    let currentPage = Math.ceil(paginatedData[0]?.idx / 8);
    let ImagePageMap = {};
    [...Array(pages)].map((i, idx) => (ImagePageMap[idx + 1] = []));
    displayData.forEach((item) => {
      let idx = Math.ceil(item.idx / 8);
      ImagePageMap[idx].push(item);
    });
    if (e.target.checked) {
      setSelectedImages((prev) => {
        let newData = [...prev];
        ImagePageMap[currentPage].forEach((item) => {
          if (newData.findIndex((val) => val.id === item.id) === -1) {
            newData.push(item);
          }
        });
        return newData;
      });
    } else {
      setSelectedImages((prev) => {
        let newData = [...prev];
        ImagePageMap[currentPage].forEach((item) => {
          let idx = newData.findIndex((val) => val.id === item.id);
          if (idx !== -1) newData.splice(idx, 1);
        });
        return newData;
      });
    }
  };

  const checkSelectAll = () => {
    let checked = true;
    paginatedData.forEach((item) => {
      if (!selectedImages.some((val) => val.id == item.id)) checked = false;
    });
    return checked;
  };

  const handleClick = (x) => {
    if (!x.hasOwnProperty("label")) handleChange(x);
  };

  useEffect(() => {
    if (page == "Unannotated") setDisplayData(images);
    else setDisplayData(annotatedImages);
  }, [images, annotatedImages, page]);

  useEffect(() => {
    if (displayData.length == 0) setPaginatedData([]);
  }, [displayData]);

  useEffect(() => {
    setCheckedAll(checkSelectAll());
  }, [paginatedData, selectedImages]);
  console.log(selectedImages.length, "selections");
  return (
    <div className="w-full flex flex-col gap-4">
      <div className="flex flex-col sm:flex-row gap-4 items-start sm:items-center justify-between">
        <p className="text-[#3E3C42] text-sm font-medium">
          Annotate min {labels.length * 15 || 10}, Annotated:{" "}
          {annotatedImages.length}
        </p>
        <div className="flex gap-2 items-center">
          {["Unannotated", "Annotated"].map((x) => {
            return (
              <div
                className={
                  page === x
                    ? "text-[#605D64] text-xs sm:text-sm bg-[#6CABFC] bg-opacity-20 rounded-full px-4 py-1 border border-[#6CA6FC]"
                    : "text-xs sm:text-sm text-[#605D64] border border-[#EBEBEB] rounded-full px-4 py-1 cursor-pointer"
                }
                onClick={() => setPage(x)}
              >
                {x}
              </div>
            );
          })}
        </div>
      </div>
      <div
        className={`flex w-full ${
          page == "Unannotated" ? "justify-between" : "justify-end"
        } items-center`}
      >
        {page == "Unannotated" && (
          <Checkbox
            p={0}
            _hover={{
              borderColor: "#FFC107",
            }}
            _checked={{
              "& .chakra-checkbox__control": {
                background: "#FFC107",
                borderColor: "#FFC107",
              },
            }}
            onChange={(e) => {
              SelectAll(e);
            }}
            isChecked={checkedAll}
          >
            Select all
          </Checkbox>
        )}
        {displayData && displayData.length > 0 && (
          <Paginator
            data={displayData}
            limit={8}
            setDisplayData={setPaginatedData}
          />
        )}
      </div>
      <div className="w-full h-fit grid-cols-1 grid min-[430px]:grid-cols-2 2xl:grid-cols-4 gap-5">
        {paginatedData.map((item, idx) => {
          return (
            <div
              className={`w-full h-[250px] bg-black relative rounded flex items-center justify-center ${
                item.hasOwnProperty("label")
                  ? "cursor-default"
                  : "cursor-pointer"
              }`}
              onClick={() => handleClick(item)}
            >
              <img
                src={item.img}
                alt="image"
                className="w-auto max-h-full rounded"
              />
              {!item.hasOwnProperty("label") && (
                <Checkbox
                  p={0}
                  _hover={{
                    borderColor: "#FFC107",
                  }}
                  _checked={{
                    "& .chakra-checkbox__control": {
                      background: "#FFC107",
                      borderColor: "#FFC107",
                    },
                  }}
                  isChecked={selectedImages.some(
                    (selectedItem) => selectedItem.id === item.id
                  )}
                  onChange={() => handleChange(item)}
                  position={"absolute"}
                  top={"8px"}
                  left={"8px"}
                  shadow={"dark-lg"}
                />
              )}
              {item.hasOwnProperty("label") && (
                <>
                  <p className="flex items-center gap-1 p-1 rounded-full bg-black absolute top-2 right-2 text-white font-medium text-sm bg-opacity-50">
                    {item.label}
                    <img
                      className="cursor-pointer w-5 h-5 hover:scale-110 text-white"
                      alt="delete"
                      src="/selfServiceIcons/cross.svg"
                      onClick={() => handleRemove(item)}
                    />
                  </p>
                </>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ImageSelector;
