import ReactApexCharts from "react-apexcharts";
import { useWindowSize } from "@uidotdev/usehooks";

const LineChartOverlay = ({ series, title, referencePoint = [] }) => {
  const size = useWindowSize();

  let yAxisData = new Array(series ? series.length : 0);

  let leftyAxisData = [];
  let rightyAxisData = [];

  let yAxisAnnotation = [];

  let maxReferencePoint = 0;

  referencePoint?.forEach((point) => {
    maxReferencePoint = Math.max(point, maxReferencePoint);
    yAxisAnnotation.push(
      {
        y: point,
        fillColor: '#5193f6',
        borderColor: '#5193f6',
        label: {
          borderColor: '#c2c2c2',
          text: 'Reference line ' + 'at ' + `y = ${point}`
        },
      }
    )
  })

  const updatedSeries = [];

  series?.forEach((item, index) => {
    item?.type == 1 && rightyAxisData.push(index)
    item?.type == 0 && leftyAxisData.push(index)

    let newData = [];

    let init = -1;

    item?.data?.forEach((item1, index) => {
      if (init == -1) init = item1?.x;
      newData.push({
        ...item1,
        x: Math.round((item1?.x - init) / 1000),
      })
    })

    updatedSeries.push({
      name: item?.name,
      data: newData,
    })
  })

  console.log(updatedSeries, "updatedSeries");

  let mini = -1;
  let maxi = 0;

  let fg = 0;

  leftyAxisData?.forEach((item, index) => {
    series[item]?.data?.forEach((item2) => {

      if (fg == 0) {
        mini = item2?.y;
        maxi = item2?.y;
        fg = 1;
      }
      else {
        mini = Math.min(mini, item2?.y);
        maxi = Math.max(maxi, item2?.y);
      }

    })
  })

  if (leftyAxisData?.length > 0) {

    let fx = 0;
    leftyAxisData?.forEach((item) => {
      let obj = {
        opposite: false,
        title: {
          text: "",
          style: {
            fontSize: "12px",
            color: "#3E3C42",
            fontWeight: 500,
            fontFamily: "Poppins",
          },
        },
        min: mini,
        max: Math.max(maxi, maxReferencePoint),
        seriesName: "Left Series",
        show: fx ? false : true,
      }
      yAxisData[item] = obj
      fx = 1;
    })
    // yAxisData.push(
    //   {
    //     opposite: false,
    //     title: {
    //       text: "",
    //       style: {
    //         fontSize: "12px",
    //         color: "#3E3C42",
    //         fontWeight: 500,
    //         fontFamily: "Poppins",
    //       },
    //     },
    //     min: mini,
    //     max: maxi,
    //     seriesName: "A",
    //   },
    // )
  }

  mini = -1;
  maxi = 0;
  fg = 0;

  rightyAxisData?.forEach((item, index) => {
    series[item]?.data?.forEach((item2) => {

      if (fg == 0) {
        mini = item2?.y;
        maxi = item2?.y;
        fg = 1;
      }
      else {
        mini = Math.min(mini, item2?.y);
        maxi = Math.max(maxi, item2?.y);
      }

    })
  })

  if (rightyAxisData?.length > 0) {
    let fx = 0;
    rightyAxisData?.forEach((item) => {
      let obj = {
        opposite: true,
        title: {
          text: "",
          style: {
            fontSize: "12px",
            color: "#3E3C42",
            fontWeight: 500,
            fontFamily: "Poppins",
          },
        },
        min: mini,
        max: Math.max(maxi, maxReferencePoint),
        seriesName: "rightSeries",
        show: fx ? false : true,
      }
      yAxisData[item] = obj
      fx = 1;
    })
  }

  // console.log(yAxisData)
  // if (!series) return;

  const options = {
    tooltip: {
      shared: true,
      intersect: false,
      style: {
        fontSize: "10px",
      },
      y: {
        formatter: function (value, { series, seriesIndex }) {
          console.log(series, seriesIndex, value, "y axis value")
          return value
        }
      }
    },
    title: {
      text: title,
      align: 'center',
      style: {
        fontSize: '16px',
        fontWeight: 'medium',
        fontFamily: 'Arial, sans-serif',
      },
    },
    chart: {
      type: "line",
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: true,
        },
      },
      animations: {
        enabled: false,
      },
    },
    xaxis: {
      type: 'category',
      tickAmount: 10,
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#707070',
        height: 6,
        offsetX: 0,
        offsetY: 0
      },
      // tickAmount: 6,
      labels: {
        show: true,
        datetimeUTC: false,
        // hideOverlappingLabels: true,
        formatter: function (value) {
          console.log(value, typeof value, "formatter value");
          return `${isNaN(value) ? value : (+value)?.toFixed(2)}s`
        },
        // style: {
        //   fontSize: "12px",
        // },
      },
    },
    yaxis: yAxisData,
    colors: [
      "#5193f6",
      "#4caf50",
      "#E46962",
      "#1c56ac",
      "#e91e63",
      "#00bcd4",
      "#8bc34a",
      "#9c27b0",
      "#673ab7",
      "#ff9800",
      "#4caf50",
      "#795548",
      "#FFE485",
    ],
    stroke: {
      curve: "smooth",
      width: 2,
      // dashArray: curves,
      // width: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#FAFAFA", "transparent"],
        opacity: 1,
      },
    },
    // subtitle: {
    //   text: 'Reference(---) Actual(────) ',
    //   align: 'center',
    // },

    annotations: {
      yaxis: yAxisAnnotation,
    },
    legend: {
      show: true,
      offsetY: 15,
      offsetX: 0,
      position: "top",
      fontSize: "12px",
    },
  };

  return (
    // <div className={`h-full w-full ${size.width < 700 ? "overflow-x-scroll" : "overflow-hidden"
    //   }`}>
    <div className="min-w-[400px] w-full h-full min-h-[400px]">
      <ReactApexCharts
        options={options}
        series={updatedSeries}
        type="line"
        height={'100%'}
      // width={size.width < 1024 ? "700px" : "100%"}
      />
    </div>
  );
};

export default LineChartOverlay;
