export const formatDateTime = (epochTimestamp) => {
  if (!epochTimestamp) {
    return "";
  }

  if (isNaN(epochTimestamp)) {
    return "";
  }

  const formattedDate = new Date(epochTimestamp).toLocaleDateString();
  const formattedTime = new Date(epochTimestamp).toLocaleTimeString();

  return `${formattedDate} ${formattedTime}`;
};

export const humanReadableDateTimeFormat = (epochTimestamp) => {
  if (!epochTimestamp) {
    return "";
  }

  if (isNaN(epochTimestamp)) {
    return "";
  }
  const date = new Date(epochTimestamp);

  const day = date.getDate();
  const month = date.toLocaleString("en-IN", {
    month: "short",
  });
  const year = date.getFullYear().toString().slice(-2);
  const hour = date.getHours();
  const minute = date.getMinutes();
  const period = hour >= 12 ? "pm" : "am";

  const formattedTime = `${day} ${month} ‘${year} ${hour % 12 || 12}:${minute
    .toString()
    .padStart(2, "0")} ${period}`;

  return formattedTime;
};
