import { Select, Input, Spinner } from "@chakra-ui/react";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import { useState, useContext } from "react";
import NavContext from "../../NavContext";
import { useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import { getVideoDataApi } from "../services/feedServices";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { mixpanelTrackButton } from "../../../util/mixpanelFunctions";
import { capitalizeFirstLetter } from "../../../util/sentenceCase";

const VideoInputForm = ({ setIsVideo, plantId, disable, plantCamMap }) => {
  const param = useParams();
  const { auth } = useContext(NavContext);
  const [selectedPlant, setSelectedPlant] = useState(
    disable ? plantId : "select plant"
  );
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [toTime, setToTime] = useState("12:00");
  const [fromTime, setFromTime] = useState("00:00");
  const toast = useToast();

  const handleSubmit = async () => {
    setIsVideo((prev) => "");
    try {
      const requestBody = JSON.stringify({
        plantName: selectedPlant,
        startDate:
          new Date(date + "T" + fromTime).getTime() + 5.5 * 60 * 60 * 1000,
        endDate: new Date(date + "T" + toTime).getTime() + 5.5 * 60 * 60 * 1000,
        clientId: param.clientId.toLowerCase(),
        useCase: "SINTERBELT",
      });
      const res = await getVideoDataApi(auth, requestBody, setLoading);
      if (res.success) {
        setIsVideo((prev) => res.url);
        toast({
          position: "top-right",
          title: "Video loaded",
          description: res.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          position: "top-right",
          title: "Failed to load",
          description: res.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        position: "top-right",
        title: "Connection Error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsVideo((prev) => "");
    }
    mixpanelTrackButton(
      "video gallery",
      "Sinter Belt Monitoring",
      "Generate Video"
    );
  };

  return (
    <div className="flex flex-col gap-8 w-[24vw] min-w-[245px] sm:min-w-[375px] h-full">
      <div className="flex flex-col items-center gap-6">
        <div className="flex flex-col items-start gap-3 w-full">
          <p className="font-medium text-[#938F96] text-sm">Select a plant</p>
          <div className="w-full">
            <Select
              borderColor="#CAC5CD"
              color="#605D64"
              placeholder={disable && capitalizeFirstLetter(selectedPlant)}
              variant="outline"
              isDisabled={disable}
              className="!rounded-md !font-medium !text-sm text-[#605D64]"
              onChange={(e) => setSelectedPlant(e.target.value)}
              value={selectedPlant}
            >
              <option value="select plant">Select Plant</option>
              {!disable &&
                Object.keys(plantCamMap).map((plant) => {
                  return (
                    <option
                      key={plant}
                      value={plant}
                    >
                      {capitalizeFirstLetter(plant)}
                    </option>
                  );
                })}
            </Select>
          </div>
        </div>
        <div className="flex flex-col items-start gap-3 w-full">
          <p className="font-medium text-[#938F96] text-sm">Enter date</p>
          <div className="w-full">
            <Input
              borderColor="#79767D"
              color="#605D64"
              type="date"
              placeholder="Select Date"
              variant="outline"
              className="!rounded-[5px] !text-[#AEA9B1] !text-base"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              max={new Date().toISOString().slice(0, 10)}
            />
          </div>
        </div>
        <div className="flex flex-col items-start gap-3 w-full">
          <p className="font-medium text-[#938F96] text-sm">Enter time range</p>
          <div className="flex gap-2 w-full">
            <FloatingInput
              text="Start Time"
              type="time"
              setDateTime={setFromTime}
              value={fromTime}
            />
            <FloatingInput
              text="End Time"
              type="time"
              setDateTime={setToTime}
              value={toTime}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <PrimaryButton
          onClick={handleSubmit}
          text={loading ? <Spinner /> : "Generate video"}
          height={"100%"}
          width={"140px"}
          disable={
            selectedPlant === "select plant" ||
            !date ||
            fromTime === toTime ||
            loading
          }
        />
      </div>
    </div>
  );
};

export default VideoInputForm;
