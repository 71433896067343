import { useToast } from "@chakra-ui/react";
import {
  GridActionsCellItem,
  GridPagination,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { useContext, useEffect, useState } from "react";
import {
  CancelOutlined,
  DeleteOutline,
  EditNote,
  FileOpenOutlined,
  Save,
} from "@mui/icons-material";
import { generateQualityMatrixColumns } from "../utilis/dataGridColumns";
import { CustomStyledDataGrid } from "../../../util/MaterialDataGrid/CustomStyledDatagrid";
import {
  deleteMatrixQualityRowApi,
  generateFlatFileApi,
  getMatrixQualityDataApi,
  updateMatrixQualityRowApi,
} from "../services/qualityMatrixServices";
import NavContext from "../../NavContext";
import { AddIcon } from "@chakra-ui/icons";
import TableSkeleton from "../components/LoadingState/TableSkeleton";
import TonalButton from "../../../util/Buttons/TonalButton";
import { mixpanelTrackButton } from "../../../util/mixpanelFunctions";
import ConfirmationModal from "../components/ConfirmationModal";
import ExlcButton from "../utilis/ExlcButton";

// add a new row
const CustomFooter = (props) => {
  const { auth } = useContext(NavContext);
  const { rows, setRows, setRowModesModel, setLoading, } =
    props;
  const getIdForNewRow = async (data = {}) => {
    try {
      const requestData = null;
      const response = await updateMatrixQualityRowApi(
        auth,
        requestData,
        setLoading
      );
      if (response.success) {
        return response.id;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickAddNewRow = async () => {
    try {
      const id = await getIdForNewRow();
      if (id) {
        const lastRowIndex = rows.length - 1;
        const lastRow = rows[lastRowIndex];

        const newFields = {};
        Object.keys(lastRow).forEach((key) => {
          newFields[key] = "";
        });

        setRows((oldRows) => [{ ...newFields, id, isNew: true }, ...oldRows,]);

        setRowModesModel((oldModel) => ({
          ...oldModel,
          [id]: {
            mode: GridRowModes.Edit,
            fieldToFocus: "STEELGRADE",
          },
        }));
        // const lastPage = Math.ceil((rows.length + 1) / 10);
        // setPaginationModel((prev) => ({ ...prev, page: lastPage }));
      }
      mixpanelTrackButton("Quality Matrix", "Mulpic", "Add") // Track button
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickGenerateFlatFile = async () => {
    try {
      const response = await generateFlatFileApi();
      mixpanelTrackButton("Quality Matrix", "Mulpic", "Save Flat File") // Track button
      //console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="flex flex-row gap-0 items-center h-[45px] justify-between border-t border-gray-200">
        <div className="flex flex-row items-center gap-4">
          <GridToolbarContainer
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "0px 0px 0px 8px",
            }}
          >
            <TonalButton
              text={"Add"}
              height={"30px"}
              onClick={handleClickAddNewRow}
              Icon={<AddIcon />}
            />
          </GridToolbarContainer>
          <div>
            <TonalButton
              text={"Save Flat File"}
              height={"30px"}
              onClick={handleClickGenerateFlatFile}
              Icon={<FileOpenOutlined />}
            />
          </div>
        </div>
        <div>
          <GridPagination />
        </div>
      </div>
    </>
  );
};

const MatrixTable = ({ rows, columns, setRows }) => {
  const { auth } = useContext(NavContext);
  const toast = useToast();
  const [rowModesModel, setRowModesModel] = useState({});
  const [loading, setLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [rowToBeDeletedId, setRowToBeDeletedId] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 9,
    page: 0,
  });

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => async () => {
    try {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      const editedRow = rows.find((row) => row.id === id);
      if (editedRow?.isNew) {
        await deleteMatrixQualityRowApi(auth, { id }, setLoading);
        setRows(rows.filter((row) => row.id !== id));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteClick = (id) => async () => {
    setRowToBeDeletedId((prev) => id);
    setConfirmationModal((prev) => true);
  };

  const handleDeleteModalConfirmation = async () => {
    // delete the selected row
    try {
      if (rowToBeDeletedId) {
        const response = await deleteMatrixQualityRowApi(
          auth,
          { id: rowToBeDeletedId },
          setLoading
        );
        if (response.success) {
          setRows(rows.filter((row) => row.id !== rowToBeDeletedId));
          toast({
            title: "Parameter deleted successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        }
      }
    } catch (error) {
      toast({
        title: "Error while deleting parameter",
        description: "Please try again...",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      console.log(error);
    }
  };

  const processRowUpdate = async (newRow, originalRow) => {
    try {
      let flag = true;
      for (let key in newRow) {
        if (key !== "id" && key !== "isNew") {
          if (newRow[key]) {
            flag = false;
            break;
          }
        }
      }
      if (flag === true) {
        throw new Error("Invalid data")
      }
      const updatedRow = { ...newRow, isNew: false };

      if (newRow?.isNew) {
        delete newRow?.isNew;
      }
      const apiResponse = await updateMatrixQualityRowApi(
        auth,
        newRow,
        setLoading
      );
      if (apiResponse?.success) {
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        toast({
          title: "Row Updated Successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        return updatedRow;
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Invalid data",
        description: "Please ensure that data entered is valid",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      // return originalRow;
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  // columns with action cell
  const updatedColumnsWithActionCell = [
    ...columns,
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 150,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Save sx={{ color: "#3A74CA", fontSize: "24px" }} />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={
                <CancelOutlined sx={{ color: "#616161", fontSize: "24px" }} />
              }
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditNote sx={{ color: "#3A74CA", fontSize: "28px" }} />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteOutline sx={{ color: "#FF7043", fontSize: "24px" }} />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const getOrder = (columns) => {

    console.log(columns, "columns");
    let newArray = [];

    columns?.forEach((column) => {
      newArray.push(column?.value ? column?.value : column?.field);
    })

    return newArray;
  }

  return (
    <div className="w-full gap-8 flex flex-col">
      {
        rows && updatedColumnsWithActionCell && <div className="flex flex-row w-full justify-between px-2 pt-2">
          <p className="rounded-e-xl rounded-s-xl text-xl font-medium">Quality Matrix</p>
          <ExlcButton order={getOrder(updatedColumnsWithActionCell)} data={rows} name={"Weights_Table"} />
        </div>
      }
      <div className="w-full border border-gray-200 rounded-md">
        <CustomStyledDataGrid
          rows={rows || []}
          columns={updatedColumnsWithActionCell || []}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          rowHeight={45}
          columnHeaderHeight={45}
          disableColumnSelector={true}
          editMode={"row"}
          pagination={true}
          loading={loading}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[30]}
          onCellDoubleClick={(params, event) => {
            event.defaultMuiPrevented = true;
          }}
          sx={{
            maxHeight: "70vh",
            overflow: "hidden",
          }}
          slots={{
            footer: CustomFooter,
          }}
          slotProps={{
            footer: {
              rows,
              setRows,
              setRowModesModel,
              setLoading,
            },
          }}
        />
      </div>
      {confirmationModal && (
        <ConfirmationModal
          isOpen={confirmationModal}
          onClose={() => setConfirmationModal(false)}
          onConfirm={handleDeleteModalConfirmation}
        />
      )}
    </div>
  );
};

export const QualityMatrix = () => {
  const { auth } = useContext(NavContext);
  const [rows, setRows] = useState(null);
  const [columns, setColumns] = useState(null);
  const [loading, setLoading] = useState(false);
  const fetchData = async () => {
    try {
      const response = await getMatrixQualityDataApi(auth, setLoading);
      // const uniqueSteelGrades = await getUniqueSteelGradeDataApi();
      // console.log(uniqueSteelGrades);
      if (response?.success && response?.data) {
        const tempColumns = await generateQualityMatrixColumns(
          response?.data?.columns,
          true,
          true,
          false,
          true
        );
        setColumns((prev) => tempColumns);
        setRows((prev) => response?.data?.tableData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="w-full bg-white">
      {loading ? (
        <TableSkeleton
          headers={[
            "Header 1",
            "Header 2",
            "Header 3",
            "Header 4",
            "Header 5",
            "Header 6",
          ]}
          rows={10}
          cellsPerRow={6}
        />
      ) : (
        columns &&
        rows && (
          <MatrixTable
            rows={rows}
            columns={columns}
            setRows={setRows}
          />
        )
      )}
    </div>
  );
};
