import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  IconButton,
  Badge,
  Checkbox,
} from "@chakra-ui/react";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useContext, useEffect, useRef, useState } from "react";
import { utils, writeFile } from "xlsx";

import PrimaryButton from "../../../../util/Buttons/PrimaryButton";
import TonalButton from "../../../../util/Buttons/TonalButton";
import DownloadIcon from "@mui/icons-material/Download";

import { baseURL } from "../../../../index";
import NavContext from "../../../NavContext";
import axios from "axios";

import { Tooltip } from "@chakra-ui/react";
import { formatDate } from "../../utils/timeFormatter";
import DisableModal from "../../DisableModal/DisableModal";
import { status } from "../../utils/common";
// const status = (val) => {
//   switch (val) {
//     case "DRAFT":
//       return "draft";
//     case "ACTIVE":
//       return "live";
//     case "INACTIVE":
//       return "fail";
//     default:
//       break;
//   }
// };
const ClientCard = ({
  data,
  fetchClientsFun,
  setShowClientTab,
  setSelectedClient,
}) => {
  const { auth } = useContext(NavContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  let wb = utils.book_new();

  useEffect(() => {
    // fetchClientInfo();
  }, [data]);

  const handleSwitchChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setTimeout(() => {
        setIsModalOpen(true);
      }, 300);
    } else {
      patchClient(1, false);
      setTimeout(() => {
        setIsMenuOpen(false);
      }, 1000);
    }
  };

  const patchClient = async (status, isdeleted) => {
    const clientId = data?.clientMasterDataEntity?.clientId;

    const payload = {
      clientId,
      status,
      isdeleted,
    };

    const json = JSON.stringify(payload);
    const blob = new Blob([json], {
      type: "application/json",
    });
    const FormData = require("form-data");
    let data1 = new FormData();
    data1.append("data", blob);

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: baseURL + "iam/updateClient",
      headers: {
        "X-Auth-Token": auth,
      },
      data: data1,
    };
    axios
      .request(config)
      .then((response) => {
        fetchClientsFun();
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchClientInfo = async () => {
    const param = {
      organisation: data.organisation,
    };
    try {
      const response = await axios.get(baseURL + "client/info", {
        params: param,
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      const data = response?.data;

      // console.log("info", response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = () => {
    setShowClientTab((prev) => "viewclient");

    setSelectedClient(data?.clientMasterDataEntity);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleUpdate = () => {
    setShowClientTab((prev) => "updateClient");
    setSelectedClient(data?.clientMasterDataEntity);
  };

  const handleConfirmDisable = async () => {
    await patchClient(2, true);

    // fetchClientsFun();

    setIsModalOpen(false);
    setTimeout(() => {
      setIsMenuOpen(false);
    }, 1000);
  };

  const exportAsExcel = (data, sheetName) => {
    let newData = [];

    if (data.length > 0 && data[0]?.clientMasterDataEntity) {
      const fileColumns = Object.keys(data[0]?.clientMasterDataEntity);

      if (fileColumns && fileColumns.length > 0) {
        newData.push(fileColumns.map((column) => column.toLowerCase()));

        data.forEach((item) => {
          if (item?.clientMasterDataEntity) {
            const newArray = fileColumns.map((column) => {
              return column.toLowerCase().includes("time")
                ? new Date(item.clientMasterDataEntity[column]).toLocaleString()
                : item.clientMasterDataEntity[column];
            });
            newData.push(newArray);
          }
        });

        // Assuming utils.aoa_to_sheet and utils.book_append_sheet are properly defined
        let ws = utils.aoa_to_sheet(newData);
        utils.book_append_sheet(wb, ws, sheetName);
      } else {
        console.error("fileColumns is undefined or empty");
      }
    } else {
      console.error(
        "data array is empty or does not have the expected structure"
      );
    }
  };

  const exportAsCSV = (data, fileName) => {
    if (data.length === 0 || !data[0]?.clientMasterDataEntity) {
      console.error(
        "Data array is empty or does not have the expected structure"
      );
      console.error(
        "First item in data:",
        data.length > 0 ? data[0] : "No items"
      );
      return;
    }

    const fileColumns = Object.keys(data[0]?.clientMasterDataEntity);

    if (!fileColumns || fileColumns.length === 0) {
      console.error("File columns are undefined or empty");
      return;
    }

    // Prepare CSV content
    let csvContent = fileColumns.join(",") + "\n";

    data.forEach((item) => {
      if (item?.clientMasterDataEntity) {
        const row = fileColumns.map((column) => {
          const cellValue = column.toLowerCase().includes("time")
            ? new Date(item.clientMasterDataEntity[column]).toLocaleString()
            : item.clientMasterDataEntity[column];
          return `"${cellValue}"`;
        });
        csvContent += row.join(",") + "\n";
      }
    });

    // Create a Blob and trigger download
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Example usage:
  // Replace 'yourData' with your actual data array and 'yourFileName' with the desired file name

  // const exportAsExcel = (data, sheetName) => {
  //   console.log(data);
  //   let newData = [Object?.keys(data[0]?.clientMasterDataEntity)];
  //   const fileColumns = Object?.keys(data[0]?.clientMasterDataEntity);

  //   if (data.length != 0) {
  //     data?.forEach((item) => {
  //       console.log(item);
  //       const newArray = fileColumns?.map((i) => {
  //         return i.toLowerCase().includes("time")
  //           ? new Date(item[i]).toLocaleString()
  //           : item[i];
  //       });
  //       newData.push(newArray);
  //     });
  //   }

  //   let ws = utils.aoa_to_sheet(newData);
  //   utils.book_append_sheet(wb, ws, `${sheetName}`);
  // };

  const fetchSubsData = async () => {
    const param = {
      organisation: data.organisation,
    };
    try {
      const response = await axios.get(baseURL + "subscription/fetchAll", {
        params: param,
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });

      return response?.data?.subscriptions;
    } catch (e) {
      console.error(e);
    }
  };

  const fetchUserMgmtData = async () => {
    const param = {
      organisation: data.organisation,
    };
    try {
      const response = await axios.get(baseURL + "iam/users", {
        credentials: "same-origin",
        params: param,
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });

      const sortedUsers = [...response?.data];
      sortedUsers.sort((a, b) => {
        const dateA = new Date(a.createdat);
        const dateB = new Date(b.createdat);

        // Compare timestamps in descending order (newest first)
        return dateB - dateA;
      });

      return sortedUsers;
    } catch (err) {
      console.log(err);
    }
  };

  const fetchSessionData = async () => {
    const obj = { header: "logs" };
    const param = {
      organisation: data.organisation,
    };
    try {
      const response = await axios.get(baseURL + "iam/logs", {
        params: param,
        headers: {
          "Content-Type": "application/json",
          "X-auth-Token": auth,
        },
      });
      const sortedUsers = [...response?.data.data];
      sortedUsers.sort((a, b) => {
        // Compare timestamps in descending order (newest first)
        return b.loginTime - a.loginTime;
      });
      return sortedUsers;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllocateTokenData = async () => {
    const param = {
      organisation: data.organisation,
    };
    try {
      const response = await axios.get(
        baseURL + `token-wallet/v1/user-balance`,
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-auth-Token": auth,
          },
        }
      );
      return response?.data.Users;
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAllocationHistoryData = async () => {
    const param = {
      organisation: data.organisation,
    };
    try {
      const response = await axios.get(
        baseURL + `token-wallet/v1/allocation-log`,
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-auth-Token": auth,
          },
        }
      );

      if (Array.isArray(response?.data?.org)) {
        const sortedOrgData = response.data.org.sort(
          (a, b) =>
            new Date(b.transactionDate).getTime() -
            new Date(a.transactionDate).getTime()
        );
        return sortedOrgData;
      } else {
        console.error("Org data is not an array:", response?.data?.org);
      }

      if (Array.isArray(response?.data?.user)) {
        const sortedUserData = response?.data?.user.sort(
          (a, b) =>
            new Date(b.transactionDate).getTime() -
            new Date(a.transactionDate).getTime()
        );
        return sortedUserData;
      } else {
        console.error("User data is not an array:", response?.data?.user);
      }
    } catch (error) {
      console.log(error);
    }
    return [];
  };

  const fetchTokenTransactionsData = async () => {
    const param = {
      organisation: data.organisation,
    };
    try {
      const response = await axios.get(
        baseURL + `token-wallet/v1/token-transaction`,
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );

      // Filter out entries with description "Token Allocation"
      const filteredTableData = response?.data?.transactionDetail.filter(
        (entry) => entry.description !== "Token Allocation"
      );

      return filteredTableData;
    } catch (error) { }
  };

  const handleDownloadClick = async () => {
    const data1 = await fetchSubsData();
    const data2 = await fetchUserMgmtData();
    const data3 = await fetchSessionData();
    //const data4 = await fetchSessionData();
    const data5 = await fetchAllocateTokenData();
    const data6 = await fetchAllocationHistoryData();

    if (Array.isArray(data)) exportAsExcel(data, "Summary");
    else exportAsExcel([data], "Summary");

    if (Array.isArray(data1)) exportAsExcel(data1, "Subscriptions");
    else exportAsExcel([data1], "Subscriptions");

    if (Array.isArray(data2)) exportAsExcel(data2, "User Management");
    else exportAsExcel([data2], "User Management");

    if (Array.isArray(data3)) exportAsExcel(data3, "Session Logs");
    else exportAsExcel([data3], "Session Logs");

    if (Array.isArray(data5)) exportAsExcel(data5, "Allocate Tokens");
    else exportAsExcel([data5], "Allocate Tokens");

    if (data6.length != 0) {
      if (Array.isArray(data6)) exportAsExcel(data6, "Allocation History");
      else exportAsExcel([data6], "Allocation History");
    }

    writeFile(wb, `report_data.xlsx`);
  };

  const handleDownloadCSVClick = async () => {
    exportAsCSV([data], "report_data");
  };

  const locationString = data?.clientMasterDataEntity?.clientAllLocation;
  const locations = locationString?.split(",");
  const [maxLocationsToShow, setMaxLocationsToShow] = useState(0);
  const [locationLengthExceeds, setLocationLengthExceeds] = useState(false);
  const spaceAddedLocations = locations?.join(", ");
  const locationRef = useRef(null);

  useEffect(() => {
    const calculateLocation = () => {
      const divWidth = locationRef.current?.offsetWidth || 0;
      const averageCharacterWidth = 7; // Adjust this based on your actual character width

      let totalLength = 0;
      let locationsToShow = 0;

      for (let i = 0; i < locations?.length; i++) {
        // Estimate the number of characters that can fit inside the div
        const charactersThatFit = Math.floor(divWidth / averageCharacterWidth);

        // Increment locationsToShow if the total length plus characters already shown is within the limit
        if (totalLength + locations[i]?.length <= charactersThatFit) {
          totalLength += locations[i]?.length;
          locationsToShow++;
        } else {
          setLocationLengthExceeds(true);
          // Stop incrementing if the limit is exceeded
          break;
        }
      }

      setMaxLocationsToShow(locationsToShow);
    };

    calculateLocation();

    const resizeObserver = new ResizeObserver(() => {
      calculateLocation();
    });

    resizeObserver.observe(locationRef.current);

    // Initial calculation
    calculateLocation();

    return () => {
      resizeObserver.disconnect();
    };
  }, [locations]);
  return (
    <div
      className="px-4 py-5 justify-between rounded flex flex-col gap-4 relative bg-white h-[250px]"
      style={{
        boxShadow:
          "-4px -4px 24px 0px rgba(0, 0, 0, 0.07), 4px 4px 24px 0px rgba(0, 0, 0, 0.07)",
      }}
    >
      <div className="flex flex-col h-full justify-evenly gap-4">
        <div className="flex items-center gap-0">
          <div className="w-8 h-8">
            {data?.clientMasterDataEntity.logo32url ? (
              <img
                src={data?.clientMasterDataEntity?.logo32url}
                alt="logo"
                className="w-full h-full"
              />
            ) : (
              <img
                src="/superadmin/clients/companyLogo.png"
                alt=""
                className="w-full h-full"
              />
            )}
          </div>
          <div className="ml-2 w-[75%] h-18">
            <Tooltip
              label={data?.clientMasterDataEntity?.clientName}
              placement="top-start"
            >
              <p className="text-[#3E3C42] text-[16px] font-medium whitespace-nowrap overflow-hidden overflow-ellipsis">
                {data?.clientMasterDataEntity?.clientName}
              </p>
            </Tooltip>
            <Tooltip
              label={formatDate(data?.clientMasterDataEntity?.creationAt)}
              placement="top-start"
            >
              <p className=" text-sm font-medium whitespace-nowrap overflow-hidden overflow-ellipsis">
                <span className="text-[#79767D]">Since</span>{" "}
                {formatDate(data?.clientMasterDataEntity?.creationAt)}
              </p>
            </Tooltip>
          </div>
          <div className="ml-3 mb-5">
            <Menu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<MoreVertIcon />}
                variant="simple"
                position={"absolute"}
                top={"20px"}
                right={"1px"}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              />
              <MenuList
                px={"12px"}
                py={"16px"}
                rounded={"8px"}
                display={"flex"}
                flexDirection={"column"}
                gap={"16px"}
                minWidth={"fit-content"}
                w={"180px"}
                position={"absolute"}
                right={"-50px"}
                top={"-15px"}
              >
                <MenuItem
                  icon={<DownloadIcon />}
                  textColor={"#605D64"}
                  fontSize={"14px"}
                  fontWeight={500}
                  p={"8px"}
                  _hover={{ bg: "#DDEEFF80", borderRadius: "4px" }}
                  _focus={{ bg: "#DDEEFF80", borderRadius: "4px" }}
                  onClick={handleDownloadClick}
                // onClick={handleDownloadCSVClick}
                >
                  {"Download"}
                </MenuItem>
                <div className="flex ml-3">
                  <Checkbox
                    isChecked={data?.clientMasterDataEntity?.isdeleted}
                    // color={
                    //   data?.clientMasterDataEntity?.isdeleted ? "#E46962" : ""
                    // }
                    colorScheme={
                      data?.clientMasterDataEntity?.isdeleted ? "red" : ""
                    }
                    fontWeight={500}
                    fontSize={"14px"}
                    onChange={handleSwitchChange}
                    isDisabled={
                      data?.clientMasterDataEntity?.status === "DRAFT"
                    }
                  >
                    {data?.clientMasterDataEntity?.isdeleted
                      ? "Disabled"
                      : "Disable"}
                  </Checkbox>
                </div>
              </MenuList>
            </Menu>
            {isModalOpen && (
              <DisableModal
                isOpen={isModalOpen}
                text={"Are you sure you would like to disable the client ?"}
                onClose={handleCloseModal}
                handleConfirmDisable={handleConfirmDisable}
              />
            )}
          </div>
        </div>
        <div className="flex  items-center justify-between">
          <div className="mr-1">
            <div className="flex flex-col items-center">
              <p className="text-[#938F96] text-sm">Vision</p>
              <div className="flex items-center w-full">
                <div className="">
                  <img src="/VisionIcon.svg" alt="" />
                </div>
                <div className="ml-2">
                  <p>{data?.visonProductCount}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="mr-1">
            <div className="">
              <p className="text-[#938F96] text-sm">Optimus</p>
              <div className="flex items-center w-full">
                <div className="w-[20px] h-[20px]">
                  <img
                    src="/OptimusIcon.svg"
                    alt=""
                    className="w-full h-full"
                  />
                </div>
                <div className="ml-2">
                  <p>{data?.optimusProductCount}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="">
              <p className="text-[#938F96] text-sm">Users</p>
              <div className="flex items-center w-full">
                <div className="">
                  <img src="/superusers.svg" alt="" />
                </div>
                <div className="ml-2">
                  <p>{data?.userCount}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <div>
            <p className="text-[#938F96] text-sm">Locations</p>
            <Tooltip label={locations?.join(", ")} placement="top-start">
              <div className="flex flex-row items-center justify-between ">
                <div className={`flex justify-between gap-[2px] mt-1 w-full`}>
                  {locations && locations?.length > 0 ? (
                    <div
                      className={`flex items-center ${locations && locations?.length > maxLocationsToShow
                          ? "w-[90%]"
                          : "w-full"
                        }`}
                      ref={locationRef}
                    >
                      <div className="min-w-[20px]">
                        {data?.clientMasterDataEntity?.clientAllLocation ? (
                          <img
                            src="/location.png"
                            alt=""
                            className="w-full h-full"
                          />
                        ) : (
                          ""
                        )}
                      </div>

                      <p
                        className={`text-[#938F96] text-sm ml-1 whitespace-nowrap overflow-hidden overflow-ellipsis`}
                      >
                        {spaceAddedLocations}
                      </p>
                    </div>
                  ) : (
                    <p className="text-[#938F96] text-sm">
                      No locations entered
                    </p>
                  )}
                  {locations && locations?.length > maxLocationsToShow && (
                    <div className="flex">
                      <Badge
                        ml={1}
                        color={"#447ED4"}
                        bg={"white"}
                        fontWeight={600}
                        fontSize="14px"
                        pl={0}
                      >
                        +{locations?.length - maxLocationsToShow}
                      </Badge>
                    </div>
                  )}
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className="flex gap-2 items-center">
        <PrimaryButton
          text={"View"}
          onClick={() => handleClick()}
          disable={""}
        />
        <TonalButton
          text={"Update"}
          onClick={() => handleUpdate()}
          disable={data?.clientMasterDataEntity?.status === "INACTIVE"}
        />
      </div>
      <Tooltip
        label={data?.clientMasterDataEntity?.status?.toLowerCase()}
        hasArrow
      >
        {
          data?.clientMasterDataEntity?.status == "DRAFT" ? <img
            src={`/Common/draft.svg`}
            className="w-8 h-8 absolute -top-2 -right-2 rounded-full bg-white p-1 shadow-md"
            alt="status"
          /> : (
            data?.clientMasterDataEntity?.status == "ACTIVE" ? <CheckCircleIcon sx={{
            position: 'absolute',
            top: "-8px",
            right: "-8px",
            backgroundColor: "white",
            padding: "4px",
            width: "32px",
            height: "32px",
            borderRadius: "9999px",
            color: "green",
            boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
          }} /> : <CancelIcon sx={{
            position: 'absolute',
            top: "-8px",
            right: "-8px",
            backgroundColor: "white",
            padding: "4px",
            borderRadius: "9999px",
            width: "32px",
            height: "32px",
            color: "#E46962",
            boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
          }} />
          )
        }

      </Tooltip>
    </div>
  );
};

export default ClientCard;
