import { Lens, WarningAmberRounded } from "@mui/icons-material";
import React, { useMemo } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  capitalizeCamelCaseString,
  formatDateTimeLocale,
  generateUniqueColors,
} from "../utilis/utilityFunctions";
import ReactApexChart from "react-apexcharts";
import { useWindowSize } from "@uidotdev/usehooks";

ChartJS.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const calculateMinMax = (arr1, valueToBeAdded) => {
  let min = Infinity;
  let max = -Infinity;
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i].y !== null || arr1[i].y !== undefined) {
      if (arr1[i].y < min) {
        min = arr1[i].y;
      }
      if (arr1[i].y > max) {
        max = arr1[i].y;
      }
    }
  }
  max = max + (valueToBeAdded || 20);

  return { min, max };
};

export const AllBurnersAndThermocoupleChart = (props) => {
  const colors = useMemo(
    () => ({
      maximumTemperature: "#E46962",
      averageTemperature: "#1C56AC",
      minimumTemperature: "#6AB04B",
      thermocoupleTemperature: "#FFC107",
    }),
    []
  );
  const titles = [
    "maximumTemperature",
    "averageTemperature",
    "minimumTemperature",
  ];
  if (props?.thermocoupleTemperature) {
    titles.push("thermocoupleTemperature");
  }

  const data = {
    labels: props?.xAxis,
    datasets: titles?.map((el, idx) => {
      return {
        label: capitalizeCamelCaseString(el),
        data: props?.[el] || [],
        borderColor: colors[el],
        fill: false,
        spanGaps: true,
        borderDash: [6, 6],
        pointStyle: "circle",
        pointRadius: 4,
        pointHoverRadius: 6,
        backgroundColor: colors[el],
        borderWidth: 2,
      };
    }),
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: { duratin: 200 },
    layout: {
      padding: 0,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        position: "nearest",
        usePointStyle: true,
        backgroundColor: "#FAFAFA",
        titleColor: "#373d3f",
        bodyColor: "#373d3f",
        borderColor: "#E0E0E0",
        borderWidth: 1,
        bodySpacing: 8,
        titleMarginBottom: 10,
        cornerRadius: 4,
        titleFont: { size: 14, weight: "bold" },
        bodyFont: { size: 12, weight: "normal" },
        callbacks: {
          afterTitle: (data) => {
            if (data.length === 1 && props?.thermocoupleTime) {
              const formattedDateTime = formatDateTimeLocale(
                props?.thermocoupleTime[data[0]?.dataIndex]
              );
              return formattedDateTime || null;
            }
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: false,
        },
        border: {
          display: true,
        },
        grid: {
          display: true,
          drawOnChartArea: false,
          drawTicks: true,
        },
        ticks: {
          color: "#373d3f",
          font: { size: 11 },
        },
      },
      y: {
        title: {
          display: true,
          text: "Temperature in °C",
          color: "#938F96",
        },

        grid: {
          display: true,
          drawOnChartArea: true,
          drawTicks: true,
          borderDash: [6, 3],
          color: "#e7e7e7",
        },
        ticks: {
          color: "#373d3f",
          font: { size: 11 },
        },
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
  };

  return (
    <div className="bg-white w-full h-full overflow-x-scroll md:overflow-x-hidden">
      <div
        className={`grid grid-cols-2 items-center px-2 gap-2 py-[6px] w-[700px] md:w-full pb-3`}
      >
        {titles?.map((element, index) => (
          <div
            key={`${element}-${index}`}
            className="flex flex-row items-center gap-1"
          >
            <Lens
              fontSize={"inherit"}
              sx={{
                color: colors[element],
              }}
            />
            <p className="text-[11px] md:text-[12px] lg:text-[12px]">
              {`${element && capitalizeCamelCaseString(element)} ${
                element !== "thermocoupleTemperature" ? "(From IR Camera)" : ""
              }`}
            </p>
          </div>
        ))}
      </div>
      <div className="w-[700px] md:w-full h-[305px]">
        <Line
          data={data}
          options={options}
        />
      </div>
    </div>
  );
};

export const SingleBurnerChart = ({
  title = "",
  data = [],
  times = [],
  alert = [],
}) => {
  const size = useWindowSize();
  const width = size.width < 768 ? 700 : "100%";
  const isAlert = alert && alert?.length > 0;
  const series = [
    {
      name: `${title}`,
      type: "line",
      data: data,
    },
  ];
  const options = {
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
        export: {
          csv: {
            dateFormatter(timestamp) {
              return `${formatDateTimeLocale(timestamp)}`;
            },
          },
        },
      },
      animations: {
        enabled: false,
      },
    },
    grid: {
      borderColor: isAlert ? "#CAC5CD" : "#e7e7e7",
      strokeDashArray: 6,
      padding: {
        top: -5,
        right: 5,
        bottom: 0,
        left: 5,
      },
    },
    markers: {
      size: 0,
    },
    xaxis: {
      categories: times,
      type: "datetime",
      labels: {
        show: true,
        datetimeUTC: false,
        style: {
          fontSize: "11px",
        },
      },
    },
    yaxis: {
      type: "numeric",
      title: {
        text: "Temperature in °C",
        style: {
          color: "#938F96",
          fontSize: "12px",
          fontWeight: 500,
        },
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
    },
    colors: isAlert ? ["#E46962"] : ["#1C56AC"],
    tooltip: {
      x: {
        format: "dd MMM yyyy HH:mm:ss",
      },
      y: {
        formatter: function (val) {
          return val.toString();
        },
      },
    },
    dataLabel: {
      enabled: false,
    },

    stroke: {
      curve: "straight",
      width: [2, 0],
      dashArray: [0, 6],
      colors: isAlert ? ["#E46962"] : ["#1C56AC"],
    },
    legend: {
      show: false,
    },
    noData: {
      text: "No Data!",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#757575",
        fontSize: "16px",
      },
    },
  };
  if (isAlert) {
    const points = alert?.map((el) => {
      return {
        x: el.time,
        y: el.data,
        marker: {
          size: 6,
          fillColor: "#fff",
          strokeColor: "#E46962",
          strokeWidth: 3,
        },
      };
    });
    options.annotations = { points };
  }
  return (
    <div
      className={`w-full h-full  rounded-md ${
        isAlert ? "bg-[#FCEEEE] border border-[#E46962]" : "bg-white border"
      }`}
    >
      <div className="flex flex-row justify-between items-center px-2 pt-1 pb-0 w-full h-10">
        <p className="font-medium text-[#525056]">{`${title} Temperature`}</p>
        {isAlert && (
          <div className="flex flex-row items-center gap-2 bg-[#E46962] px-2 py-1 rounded-md text-white">
            <WarningAmberRounded />
            <p>Alert</p>
          </div>
        )}
      </div>
      <div
        id="chart"
        className={`h-[250px] w-full ${
          width === 700 ? "overflow-x-auto" : "overflow-hidden"
        }`}
      >
        <ReactApexChart
          options={options}
          series={series}
          height={"100%"}
          width={width}
        />
      </div>
    </div>
  );
};

export const TotalSulphurAndOverallBeltTemperatureChart = ({ chartData }) => {
  const titles =
    Object.keys(chartData)
      ?.filter((key) => key !== "times")
      ?.sort() || [];
  const colors = useMemo(() => ["#E46962", "#1C56AC"], []);
  const minMaxValues =
    titles?.length > 0 &&
    titles.reduce((acc, title) => {
      if (title === "overallBeltTemperature") {
        const { min, max } = calculateMinMax(chartData[title], 20);
        acc[title] = { min, max };
      } else {
        const { min, max } = calculateMinMax(chartData[title], 2);
        acc[title] = { min, max };
      }
      return acc;
    }, {});
  const series = titles?.map((el, index) => ({
    name: capitalizeCamelCaseString(el),
    type: "line",
    data: chartData?.[el] || [],
    yaxis: titles.length > 1 ? index : 0,
  }));

  const yAxisOptions =
    titles.length > 1
      ? titles.map((title, index) => ({
          title: {
            text:
              title === "overallBeltTemperature"
                ? "Temperature in °C"
                : "Total Sulphur % in Sinter",
            style: {
              color: "#938F96",
              fontSize: "12px",
              fontWeight: 500,
            },
          },
          opposite: index === 1,
          min: title === "overallBeltTemperature" ? minMaxValues[title].min : 0,
          max: minMaxValues[title].max,
          labels: {
            offsetX: -15,
          },
        }))
      : [
          {
            title: {
              text:
                titles[0] === "overallBeltTemperature"
                  ? "Temperature in °C"
                  : "Total Sulphur % in Sinter",
              style: {
                color: "#938F96",
                fontSize: "12px",
                fontWeight: 500,
              },
            },
            opposite: false,
            min: minMaxValues?.[titles[0]]?.min,
            max: minMaxValues?.[titles[0]]?.max,
            labels: {
              offsetX: -15,
            },
          },
        ];

  const options = {
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
        export: {
          csv: {
            dateFormatter(timestamp) {
              return `${formatDateTimeLocale(timestamp)}`;
            },
          },
        },
      },
      animations: {
        enabled: false,
      },
      zoom: {
        enabled: true,
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        show: true,
        datetimeUTC: false,
        style: {
          fontSize: "11px",
        },
      },
    },
    yaxis: yAxisOptions,
    colors: colors,
    tooltip: {
      x: {
        format: "dd MMM yyyy HH:mm:ss",
      },
    },
    dataLabel: {
      enabled: false,
    },
    grid: {
      borderColor: "#e7e7e7",
      strokeDashArray: 6,
      padding: {
        top: -3,
        right: -10,
        bottom: -3,
        left: -11,
      },
    },
    stroke: {
      curve: "straight",
      width: [2, 2],
      dashArray: [0, 6],
      colors: colors,
    },
    legend: {
      show: false,
      position: "top",
      horizontalAlign: "left",
      fontSize: "12px",
    },
  };

  return (
    <div className="w-full h-full">
      <div className="overflow-x-scroll md:overflow-x-hidden">
        <div
          className={`flex flex-row items-center px-2 gap-2 py-[6px] w-[700px] md:w-full`}
        >
          {titles?.map((element, index) => (
            <div
              key={`${element}-${index}`}
              className="flex flex-row items-center gap-1"
            >
              <Lens
                fontSize={"inherit"}
                sx={{
                  color: colors[index],
                }}
              />
              <p className="text-[11px] md:text-[12px] lg:text-[12px]">
                {`${element && capitalizeCamelCaseString(element)} ${
                  element === "overallBeltTemperature" ? "(From IR Camera)" : ""
                }`}
              </p>
            </div>
          ))}
        </div>
        <div
          className={`w-[700px] md:w-[100%] h-[310px] bg-white overflow-hidden`}
        >
          <ReactApexChart
            options={options}
            series={series}
            height={"100%"}
            width={"100%"}
          />
        </div>
      </div>
    </div>
  );
};

export const CommanMultipleYAxisLineChart = ({ chartData }) => {
  const titles =
    Object.keys(chartData)
      ?.filter((key) => key !== "times")
      ?.sort() || [];
  const series = titles.map((key) => ({
    name: key,
    type: "line",
    data: chartData[key] || [],
  }));

  const colors = generateUniqueColors(titles.length);
  // const colors = undefined;
  const size = useWindowSize();
  const width = size.width < 768 ? 700 : "100%";
  const options = {
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
        export: {
          csv: {
            dateFormatter(timestamp) {
              return `${formatDateTimeLocale(timestamp)}`;
            },
          },
        },
      },
      animations: {
        enabled: false,
      },
    },
    grid: {
      borderColor: "#e7e7e7",
      strokeDashArray: 6,
      padding: {
        top: -15,
        right: 5,
        bottom: 0,
        left: 5,
      },
    },
    markers: {
      size: 0,
    },
    xaxis: {
      categories: chartData?.times || [],
      type: "datetime",
      labels: {
        show: true,
        datetimeUTC: false,
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      type: "numeric",
      title: {
        text: "Temperature in °C",
        style: {
          color: "#938F96",
          fontSize: "12px",
          fontWeight: 500,
        },
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
    },
    colors: colors,
    tooltip: {
      x: {
        format: "dd MMM yyyy HH:mm:ss",
      },
      y: {
        formatter: function (val) {
          return val.toString();
        },
      },
    },
    dataLabel: {
      enabled: false,
    },

    stroke: {
      curve: "straight",
      width: titles.map((el) => 2),
      colors: colors,
      dashArray: [0],
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "left",
      fontSize: "12px",
    },

    noData: {
      text: "No Data!",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#757575",
        fontSize: "24px",
      },
    },
  };

  return (
    <div
      id="chart"
      className={`h-[350px] w-full ${width === 700 && "overflow-x-auto"}`}
    >
      <style>
        {`
          .apexcharts-tooltip-series-group.apexcharts-active {
            padding-bottom:0;
          }
        `}
      </style>
      <ReactApexChart
        options={options}
        series={series}
        height={"100%"}
        width={width}
      />
    </div>
  );
};
