import React from "react";
import {
  Skeleton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";

const TableSkeleton = ({
  headers = [],
  rows = 3,
  cellsPerRow = 3,
  padding = null,
  backgroundColor = "#CCEAFF",
  disableHeader = false,
}) => {
  return (
    <TableContainer overflowX={"scroll"}>
      <Table variant="simple">
        {disableHeader === false && (
          <Thead>
            <Tr backgroundColor={backgroundColor}>
              {headers.map((header, index) => (
                <Th
                  key={index}
                  minW={"150px"}
                  padding={padding}
                >
                  <Skeleton height="20px" />
                </Th>
              ))}
            </Tr>
          </Thead>
        )}
        <Tbody>
          {[...Array(rows)].map((_, rowIndex) => (
            <Tr key={rowIndex}>
              {[...Array(cellsPerRow)].map((_, cellIndex) => (
                <Td
                  key={cellIndex}
                  padding={padding}
                >
                  <Skeleton height="20px" />
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default TableSkeleton;
