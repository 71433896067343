import { Tooltip } from "@chakra-ui/react";

const calWidth = (data, val) => {
  let max = Math.max(...data);
  let percent = val / max;
  return percent * 150;
};

const TokenHorizontalBar = ({ data, role }) => {
  let { names, questions } = data;
  return (
    <div className="flex flex-col justify-start gap-2  w-[300px] h-full items-center relative">
      {names.map((item, idx) => {
        return (
          <div className="flex gap-1 items-center text-sm text-[#605D64] w-full">
            <div className="w-[140px]">
              <Tooltip label={item} hasArrow placement="top">
                <p className="overflow-hidden text-ellipsis w-[140px] whitespace-nowrap">
                  {item}
                </p>
              </Tooltip>
            </div>
            <div
              className="h-4 rounded-r bg-[#6CA6FC]"
              style={{
                width: `${calWidth(questions, questions[idx])}px`,
              }}
            />
            <p>{questions[idx]}</p>
          </div>
        );
      })}
      {role == "EXPERT" && (
        <p className="absolute -top-[30px]  right-2 text-sm text-[#605D64]">
          No. of questions
        </p>
      )}
    </div>
  );
};

export default TokenHorizontalBar;
