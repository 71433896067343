import "./App.css";
import Navbar from "./components/Navbar";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useRoutes,
  useNavigate,
} from "react-router-dom";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState, useCallback } from "react";
import LoginV2 from "./components/Auth/LoginV2";
import Sidebar from "./components/Sidebar/Sidebar";
import AiAdvisor from "./components/Main/AIAdvisor";
import NavContext from "./components/NavContext";
import BF_Dashboard from "./components/BlastFurnace/BF_Components/BF_Dashboard";
import ConatctUs from "./components/ContactUs/ConatctUs";
import NavBox from "./components/NavBox";
import mixpanel from "mixpanel-browser";
import { routes } from "./Route";
import SuperAdminDashboard from "./components/SuperAdmin/SuperAdminDashboard";
import SearchResults from "./components/SearchResults";
import DraftQuestion from "./components/community/AskYourQuestion/DraftQuestion";
import IsfOptimizerClientSelect from "./components/IsfOptimizer/IsfOptimizerClientSelect";
import IsfOptimizer from "./components/IsfOptimizer/IsfOptimizer";
// import offline from "./offline.svg"
import axios from "axios";
import { baseURL } from ".";
import {
  mixpanelAppRegister,
  mixpanelPathnameEvent,
} from "./util/mixpanelFunctions";

function generateFilteredRoutes(userType, homeRef) {
  return routes
    .filter((route) => route.role.includes(userType))
    .map((route) => ({
      ...route,
      element: route.role.includes(userType) ? (
        route.hasHomeRef ? (
          React.cloneElement(route.element, {
            homeRef: homeRef,
          })
        ) : (
          route.element
        )
      ) : (
        <div>Access Denied</div>
      ),
    }));
}

function App() {
  const homeRef = useRef();
  const navigate = useNavigate();
  const [login, setLogin] = useState(
    localStorage.getItem("logged_in") || false
  ); // used on Login.jsx to set login provider to true
  const [auth, setAuth] = useState(localStorage.getItem("auth_token")); // used on Login.jsx to set auth provider to true
  const [email, setEmail] = useState(localStorage.getItem("email"));
  const [userType, setUserType] = useState(localStorage.getItem("user_type"));
  const [organisation, setOrganisation] = useState(
    localStorage.getItem("organization")
  );
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [location, setLocation] = useState(localStorage.getItem("location"));
  const [baseLocation, setBaseLocation] = useState(
    localStorage.getItem("base_location")
  );
  const [fullName, setFullName] = useState(localStorage.getItem("fullname"));
  const [phoneNumber, setPhoneNumber] = useState(localStorage.getItem("phone"));
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [productGroups, setProductGroups] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);

  const [twoFactorAuthoInfo, setTwoFactorAuthInfo] = useState({
    is2FAuthenticatedAdmin: JSON.parse(localStorage.getItem("authInfo"))
      ?.isAdminAuthenticated
      ? true
      : false,
    is2FAuthenticatedSuperAdmin: JSON.parse(localStorage.getItem("authInfo"))
      ?.isSuperAdminAuthenticated
      ? true
      : false,
  });
  const getSubscriptionsNew = async () => {
    const param = {
      organisation: organisation,
    };
    try {
      const response = await axios.get(
        baseURL + `subscription/fetch/subscription`,
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );

      setSubscriptions(response?.data?.subscribed?.subscriptionData);
      //console.log(response);
    } catch (error) {
      //console.log(error);
      setSubscriptions([]);
    }
  };

  const getProductGroupsNew = async () => {
    try {
      const response = await axios.get(
        baseURL + "product/v1/fetch/productGroup/detail",
        {
          headers: {
            "Content-Type": "application/json",
            "X-auth-Token": auth,
          },
        }
      );
      let data = response?.data?.filter((item) => item.status === "ACTIVE");
      //console.log(data);
      // data.sort((a, b) => {
      //   // let x = a.productGroupName.toLowerCase();
      //   // let y = b.productGroupName.toLowerCase();

      //   let x = a.updatedAt;
      //   let y = b.updatedAt;
      //   if (a.isactive && !b.isactive) return -1;
      //   if (!a.isactive && b.isactive) return 1;
      //   if (x < y) return 1;
      //   if (x > y) return -1;
      //   return 0;
      // });

      //console.log(data1);
      setProductGroups(data);
    } catch (error) {}
  };

  useEffect(() => {
    if (auth) getProductGroupsNew();
    if (organisation) {
      getSubscriptionsNew();
    }
  }, [organisation, auth]);

  useEffect(() => {
    const handleOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, []);

  useEffect(() => {
    if (phoneNumber === null) {
      setLogin(false);
    }
  }, []);

  useEffect(() => {
    setAuth(localStorage.getItem("auth_token"));
    setEmail(localStorage.getItem("email"));
    setUserType(localStorage.getItem("user_type"));
    setLocation(localStorage.getItem("location"));
    setOrganisation(localStorage.getItem("organisation"));
    setFullName(localStorage.getItem("fullname"));
    setPhoneNumber(localStorage.getItem("phone"));
    setBaseLocation(localStorage.getItem("baseLocation"));
  }, [login]);

  useEffect(() => {
    if (login) {
      mixpanelAppRegister();
    }
    console.log(login, "auth state");
  }, [login, organisation, fullName, phoneNumber, userType]);

  const pageLocation = useLocation();
  const { hash, pathname, search } = pageLocation;

  useEffect(() => {
    mixpanelPathnameEvent(pathname);
  }, [pathname]);

  useEffect(() => {
    setFilteredRoutes(generateFilteredRoutes(userType, homeRef));
  }, [userType]);

  //allroutes
  const allRoutes = useRoutes(filteredRoutes);

  // const checkNetworkStatus = async () => {
  //   try {
  //     const response = await axios.get(baseURL + "session/validate", {
  //       headers: {
  //         "Content-Type": "application/json",
  //         "X-Auth-Token": auth,
  //       },
  //     });
  //     // console.log(response);

  //     if (response.data.data === true) {
  //       setIsOfflineModalOpen(false);
  //     } else {
  //       setLogin(false);
  //     }
  //   } catch (error) {
  //     setIsOfflineModalOpen(true);
  //   }
  // };

  // const InternetErrMessagenger = () => {
  //   setIsOfflineModalOpen(navigator.onLine === false);
  //   // for do like this shortform

  //   console.log(navigator);
  //   if (navigator.onLine) console.log("Online");
  //   else console.log("Offline");
  // }

  //const [isOnline, setIsOnline] = useState(navigator.onLine);

  // useEffect(() => {
  //   let interval = setInterval(InternetErrMessagenger, 6000); // call the function name only not with function with call `()`
  //   return () => {
  //     clearInterval(interval) // for component unmount stop the interval
  //   }
  // }, [])

  // useEffect(() => {
  //   let id;
  //   if (login && auth) {
  //     id = setInterval(() => {
  //       checkNetworkStatus();
  //     }, 5000)
  //   }

  //   return () => clearInterval(id);
  // }, [login,auth])

  return (
    <>
      <NavContext.Provider
        value={{
          setLogin,
          login,
          setAuth,
          auth,
          setEmail,
          email,
          userType,
          organisation,
          fullName,
          productGroups,
          subscriptions,
          getProductGroupsNew,
          getSubscriptionsNew,
          twoFactorAuthoInfo,
          setTwoFactorAuthInfo,
          location,
          baseLocation,
        }}
      >
        <div>
          {login ? (
            <>
              <Navbar />
              <NavBox />
              <Sidebar
                userRole={userType}
                homeRef={homeRef}
                organisation={organisation}
              />
              <div className="!font-roboto" style={{ display: "flex" }}>
                <div className="md:ml-32 md:mr-10 md:mt-[12vh] w-full mr-2 ml-2 mt-28 mb-10 md:mb-10">
                  {allRoutes}
                </div>
              </div>
            </>
          ) : (
            <Routes>
              <Route path="*" element={<LoginV2 />} />
            </Routes>
          )}

          {!isOnline && (
            <Modal
              size={"sm"}
              isOpen={!isOnline}
              onClose={() => setIsOnline(true)}
            >
              <ModalOverlay />
              <ModalContent>
                <ModalCloseButton className=" w-1 h-1" />
                <ModalBody m={"auto"} fontSize={"15px"}>
                  <div className="flex flex-col items-center justify-center w-full mt-4">
                    <div className="flex flex-col items-center mt-4">
                      <p className="font-bold">⚠️ No internet connection !</p>
                      <div className="flex justify-center items-center">
                        Seems like your internet connection is broken
                      </div>
                      <div className="flex justify-center items-center">
                        Please check and try to refresh the page
                      </div>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter></ModalFooter>
              </ModalContent>
            </Modal>
          )}
        </div>
      </NavContext.Provider>
    </>
  );
}

export default App;
