import { Skeleton } from "@chakra-ui/react";
import TableSkeleton from "./TableSkeleton";

const SingleConfigSkeleton = () => {
  return (
    <>
      <div className="flex flex-col gap-2 w-full px-1">
        <div className="w-full flex flex-col md:flex-row gap-2 justify-evenly flex-auto items-start">
          <div className="border rounded-md pt-1 w-full flex flex-col gap-2">
            <Skeleton w={"80%"} height={"15px"} margin={"auto"} padding={"0"} />
            <TableSkeleton
              headers={["Header 1", "Header 2"]}
              rows={4}
              cellsPerRow={2}
              backgroundColor={"#FAFAFA"}
              disableHeader
              padding={"2"}
            />
          </div>

          <div className="border rounded-md pt-1 w-full flex flex-col gap-2">
            <Skeleton w={"90%"} height={"15px"} margin={"auto"} padding={"0"} />
            <TableSkeleton
              headers={["Header 1", "Header 2"]}
              rows={4}
              cellsPerRow={2}
              backgroundColor={"#FAFAFA"}
              disableHeader
              padding={"2"}
            />
          </div>
          <div className="border rounded-md pt-1 w-full flex flex-col gap-2">
            <Skeleton w={"90%"} height={"15px"} margin={"auto"} padding={"0"} />
            <TableSkeleton
              headers={["Header 1", "Header 2"]}
              rows={3}
              cellsPerRow={2}
              backgroundColor={"#FAFAFA"}
              disableHeader
              padding={"2"}
            />
          </div>
        </div>
        <div className="border rounded-md pt-1 w-full flex flex-col gap-2">
          <Skeleton w={"80%"} height={"15px"} margin={"auto"} padding={"0"} />
          <TableSkeleton
            headers={["Header 1", "Header 2", "Header 2"]}
            rows={4}
            cellsPerRow={3}
            backgroundColor={"#FAFAFA"}
            padding={"2"}
          />
        </div>
        <div className="border rounded-md pt-1 w-full flex flex-col gap-2">
          <Skeleton w={"80%"} height={"15px"} margin={"auto"} padding={"0"} />
          <TableSkeleton
            headers={["Header 1", "Header 2", "Header 3", "Header 4"]}
            rows={4}
            cellsPerRow={4}
            backgroundColor={"#FAFAFA"}
            padding={"2"}
          />
        </div>
      </div>
    </>
  );
};
export const ConfigSkeleton = () => {
  return (
    <div className="w-full">
      <div className="font-bold text-lg px-2 py-3">
        <Skeleton
          className="font-bold text-lg px-2 py-3"
          height={"10px"}
          width={["80%", "70%", "60%", "50%", "40%"]}
        />
      </div>

      <SingleConfigSkeleton />
    </div>
  );
};
