import React from "react";
import { overallPermeabilityIndex } from "../SinterBelt";
import { capitalizeCamelCaseString, formatDateTime } from "./dateTimeFormatter";
import { Lens } from "@mui/icons-material";
import { getGridStringOperators } from "@mui/x-data-grid";

export const generateAlertReportColumns = async (
  columnNames,
  handleViewDetails,
  isReport = false
) => {
  const staticViewDetailColumn = {
    field: "view_details",
    headerName: "View Details",
    minWidth: 110,
    flex: 1,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,

    renderCell: (params) => {
      //   const getRowIndex = params.api.getSortedRowIds().indexOf(params.row.id);
      return (
        <button
          onClick={() => handleViewDetails(params)}
          className="text-blue-800 cursor-pointer hover:text-blue-200 font-semibold min-w-[80px]"
        >
          View Details
        </button>
      );
    },
  };
  //   dynamic columns
  const dynamicColumns = columnNames.map((columnName) => {
    let columnConfig = {
      field: columnName,
      headerName: columnName?.split("_")?.join(" "),
      flex: 1,
      minWidth: 130,
      sortable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "contains"
      ),
    };

    // add column specific props
    switch (columnName) {
      case "time_stamp":
        columnConfig.minWidth = 140;
        columnConfig.flex = 1.2;
        columnConfig.valueFormatter = (params) => {
          return params.value ? formatDateTime(params.value) : "";
        };
        break;
      case "permeability_index":
        columnConfig.minWidth = 140;
        break;
      case "value_gap":
        columnConfig.minWidth = 120;
        columnConfig.headerName = "Value/Gap";
        columnConfig.sortComparator = (v1, v2) => {
          return parseFloat(v1) - parseFloat(v2);
        };
        break;
      default:
        break;
    }

    return columnConfig;
  });
  return [...(dynamicColumns || []), staticViewDetailColumn];
};

export const generateStatusColumns = async (columnNames, handleViewDetails) => {
  const staticViewDetailColumn = {
    field: "view_details",
    headerName: "View Details",
    minWidth: 120,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      return (
        <button
          onClick={() => handleViewDetails(params)}
          className="text-blue-800 cursor-pointer hover:text-blue-200 font-semibold min-w-[80px]"
        >
          View Details
        </button>
      );
    },
  };
  //   dynamic columns
  const dynamicColumns = columnNames.map((columnName) => {
    let columnConfig = {
      field: columnName,
      headerName: capitalizeCamelCaseString(columnName),
      flex: 1,
      minWidth: 80,
      sortable: false,
      disableColumnMenu: true,
    };

    // add column specific props
    switch (columnName) {
      case "boardPermeability":
        columnConfig.minWidth = 120;
        columnConfig.valueFormatter = (params) => {
          const { value } = params;
          const [permeabilityIndex, actualValue] =
            value && typeof value === "string"
              ? value?.split("_")?.map(Number)
              : [null, null];
          return `${overallPermeabilityIndex[permeabilityIndex]} ${actualValue}% `;
        };
        break;
      case "board":
        columnConfig.minWidth = 100;
        break;
      case "grateBar":
        columnConfig.minWidth = 100;
        columnConfig.renderCell = (params) => {
          if (params.value === true) {
            return (
              <Lens
                fontSize={"small"}
                sx={{
                  color: "#69b04b",
                }}
              />
            );
          } else {
            return <p className="text-red-400">{params.value}px</p>;
          }
        };
        break;
      case "vNotch":
        columnConfig.minWidth = 100;
        columnConfig.renderCell = (params) => {
          if (params.value) {
            return <p className="text-red-400">{params.value}px</p>;
          } else {
            return (
              <Lens
                fontSize={"small"}
                sx={{
                  color: "#69b04b",
                }}
              />
            );
          }
        };
        break;
      default:
        break;
    }

    return columnConfig;
  });
  return [...(dynamicColumns || []), staticViewDetailColumn];
};
export const generatePalletBoardReplacementLogColumns = async (columnNames) => {
  //   dynamic columns
  const dynamicColumns = columnNames.map((columnName) => {
    let columnConfig = {
      field: columnName,
      headerName: capitalizeCamelCaseString(columnName),
      flex: 1,
      minWidth: 150,
      sortable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "contains"
      ),
    };

    // add column specific props
    switch (columnName) {
      case "timeStamp":
        columnConfig.valueFormatter = (params) => {
          return params.value ? formatDateTime(params.value) : "";
        };
        break;
      default:
        break;
    }

    return columnConfig;
  });
  return [...dynamicColumns];
};

export const dataGridSlotProps = () => {
  return {
    // column menu
    columnMenu: {
      sx: {
        minWidth: 170,
        padding: 0,
        ".MuiTypography-root": {
          fontSize: "13px",
        },
        "& .css-1asgcec-MuiButtonBase-root-MuiMenuItem-root": {
          paddingY: 0,
          minHeight: "35px",
        },
      },
    },
    // the panel which contains filtering menu (top wrapper)
    panel: {
      sx: {
        maxWidth: "85vw",
        ".css-154sxbz-MuiPaper-root-MuiDataGrid-paper": {
          minWidth: 0,
        },
      },
    },
    // actual filter panel in which all elements are stored
    filterPanel: {
      filterFormProps: {
        // input box for operator selection(for multi-columns => and, or)
        logicOperatorInputProps: {
          sx: { display: "none" },
        },
        // input box for column selection
        columnInputProps: {
          variant: "outlined",
          size: "small",
        },
        // input box for operator selection(for a particular columns => contains, start with, is empty ....)
        operatorInputProps: {
          sx: { display: "none" },
        },
        // input box to write the value on the basis of which data get's filtered
        valueInputProps: {
          InputComponentProps: {
            variant: "outlined",
            size: "small",
          },
        },
        // delete icon
        deleteIconProps: {
          sx: {
            "& .MuiSvgIcon-root": { color: "#d32f2f" },
          },
        },
      },
      sx: {
        overflowX: "auto",
        "& .MuiDataGrid-filterForm": { p: 2 },
        "& .MuiDataGrid-filterFormLogicOperatorInput": {
          mr: 2,
          minWidth: 80,
        },
        "& .MuiDataGrid-filterFormColumnInput": {
          mr: 2,
          minWidth: 150,
        },
        "& .MuiDataGrid-filterFormValueInput": { minWidth: 200 },
      },
    },
  };
};
