import React, { useState, useEffect, useRef, useContext } from "react";
import { Select, Spinner } from "@chakra-ui/react";
import TransactionTable from "./TransactionTable";
import ReadMore from "./ReadMore";
import ExpertHistoryTable from "./ExpertHistoryTable";
import DownloadExcel from "./DownloadExcel";
import FloatingInput from "../../util/VisionUtils/FloatingInput";
import Paginator from "../../util/VisionUtils/Paginator";
import ExlCsvDownload from "../../util/VisionUtils/ExlCsvDownload";
import AskAnExpertHistoryTable from "./AskAnExpertHistoryTable";
import { filter } from "d3-array";
import axios from "axios";
import NavContext from ".././NavContext";
import { baseURL } from "../../index";
import TopEnquirerChart from "./AskAnExpertCharts/TopEnquirerChart";
import TopClientsChart from "./AskAnExpertCharts/TopClientsChart";
import PrimaryButton from "../../util/Buttons/PrimaryButton";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import { Link } from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";
import CustomChart from "./AskAnExpertCharts/CustomChart";
import HorizontalBarCustom from "../Charts/CommunityCharts/HorizontalBarCustom";
import TokenHorizontalBar from "../Charts/CommunityCharts/TokenHorizontalBar";

const AskAnExpertHistory = () => {
  const { auth } = useContext(NavContext);
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRange, setSelectedRange] = useState(0);

  const [stateChanging, setStateChanging] = useState(false);
  const [changingbutton, setChangingbutton] = useState("All");

  const size = useWindowSize();
  const location = useLocation();

  const [startDate, setFromTime] = useState(null);
  const [endDate, setToTime] = useState(null);
  const [customStartDate, setCustomFromTime] = useState("");
  const [customEndDate, setCustomToTime] = useState("");
  const [pendingQuestions, setPendingQuestions] = useState(0);
  const [inProgressQuestions, setInProgressQuestions] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState(0);
  const [fullName, setFullName] = useState(null);
  const [downloadData, setDownloadData] = useState({});
  const [loading, setLoading] = useState(false);
  const [top3clients, setTopClients] = useState([]);
  const [top3Enquirers, setTopEnquirers] = useState([]);
  const [top3Experts, setExperts] = useState([]);
  const [tokenSummary, setTokenSummary] = useState([]);
  const [role, setRole] = useState("");
  const [imageurl, setImageUrl] = useState("");
  const [currentBalance, SetCurrentBalance] = useState(0);

  const handleClick = () => {
    setStateChanging(false);
    // apiCall();
    // Set the time of the selected start date to 00:00:00
    const customStartDateObject = new Date(customStartDate);
    customStartDateObject.setHours(0, 0, 0);
    const customStartTimeInMs = customStartDateObject.getTime();
    // Set the time of the selected end date to 23:59:59
    const customEndDateObject = new Date(customEndDate);
    customEndDateObject.setHours(23, 59, 59);
    const customEndTimeInMs = customEndDateObject.getTime();

    // Call API with custom values
    if (role === "EXPERT") {
      fetchQueries(customStartTimeInMs, customEndTimeInMs);
    } else {
      handleQuestionsExceptexpert(customStartTimeInMs, customEndTimeInMs);
    }
  };

  const handleRangeSelect = (e) => {
    setSelectedRange(e.target.value);

    // Get the current time
    const currentTime = new Date().getTime();

    // Calculate start and end times based on the selected range
    let startTime, endTime;

    switch (e.target.value) {
      case "0": // All Time
        // Set startTime to the beginning of the year 2022
        startTime = new Date("2022-01-01T00:00:00").getTime();
        // Set endTime to the current time
        endTime = currentTime;
        break;

      case "1": // Last Seven Days
        startTime = currentTime - 7 * 24 * 60 * 60 * 1000;
        endTime = currentTime;
        break;

      case "2": // This Month
        const startOfMonth = new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          1,
          0,
          0,
          0
        ).getTime();
        startTime = startOfMonth;
        endTime = currentTime;
        break;

      case "3": // This Quarter
        let currentMonth = new Date(currentTime).getMonth();
        let startQuarter = currentMonth - (currentMonth % 3);
        startTime = new Date(
          new Date().getFullYear(),
          startQuarter,
          1,
          0,
          0,
          0
        ).getTime();
        endTime = currentTime;
        break;

      case "4": // Previous Quarter
        let currentMonthPre = new Date(currentTime).getMonth();

        const startPreviousQuarter =
          currentMonthPre - (currentMonthPre % 3) - 3;
        startTime = new Date(
          new Date().getFullYear(),
          startPreviousQuarter,
          1,
          0,
          0,
          0
        ).getTime();
        endTime = new Date(
          new Date().getFullYear(),
          startPreviousQuarter + 2,
          31,
          23,
          59,
          59
        ).getTime();
        break;

      case "5": // This Year
        const startOfYear = new Date(
          new Date().getFullYear(),
          0,
          1,
          0,
          0,
          0
        ).getTime();
        startTime = startOfYear;
        endTime = currentTime;
        break;

      default:
        break;
    }

    const newStartDate = new Date(startTime).setHours(0, 0, 0); // Set the time of the selected start date to 00:00:00
    const newEndDate = new Date(endTime).setHours(23, 59, 59); // Set the time of the selected start date to 23:59:59
    console.log(startTime, newStartDate);
    // Update state with the calculated start and end times
    setFromTime(newStartDate);
    setToTime(newEndDate);

    // Call API when a new range is selected
    if (role === "EXPERT") {
      fetchQueries(newStartDate, newStartDate);
    } else {
      handleQuestionsExceptexpert(newStartDate, newStartDate);
    }
  };

  const fetchQueries = async (startTime = null, endTime = null) => {
    setLoading(true);
    try {
      let requestData = null;

      if (startTime != null && endTime !== null) {
        // If startTime and endTime are provided, create the request data
        requestData = {
          startDate: startTime,
          endDate: endTime,
        };
      }

      const response = await axios.post(
        baseURL + "questions/expert",
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      setLoading(false);
      const data = response?.data.data;

      setDownloadData(response?.data);
      // Count questions based on status
      const pendingCount = data.filter(
        (item) => item.status === "Pending"
      ).length;
      const inProgressCount = data.filter(
        (item) => item.status === "In Progress"
      ).length;
      const answeredCount = data.filter(
        (item) => item.status === "Answered"
      ).length;

      setPendingQuestions(pendingCount);
      setInProgressQuestions(inProgressCount);
      setAnsweredQuestions(answeredCount);

      // Sort data based on the order "In Progress" -> "Pending" -> "Answered"
      const sortedData = data.sort((a, b) => {
        const statusOrder = ["In Progress", "Pending", "Answered"];
        return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
      });
      // console.log(sortedData);
      setTableData(sortedData);
      setFilteredData(sortedData);
      setTopEnquirers(response?.data?.top3enquirer);
      setTopClients(response?.data?.top3client);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const fetchUser = async () => {
    try {
      const response = await axios.get(baseURL + "user", {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      setFullName(response?.data.data.fullname);
      setRole(response?.data.data.role);
      if (response?.data?.data?.imageurl) {
        setImageUrl(response?.data?.data?.imageurl + `?${performance.now()}`);
      } else {
        setImageUrl("");
      }

      if (response?.data.data.role === "EXPERT") {
        fetchQueries(startDate, endDate);
      } else {
        handleQuestionsExceptexpert(startDate, endDate);
      }
      // console.log("users",response?.data.data.fullname);
    } catch (e) {
      console.error(e);
    }
  };

  const handleQuestionsExceptexpert = async (
    startTime = null,
    endTime = null
  ) => {
    console.log("inside func", startDate, endDate);
    setLoading(true);
    try {
      let requestData = null;

      if (startTime != null && endTime !== null) {
        // If startTime and endTime are provided, create the request data
        requestData = {
          startDate: startTime,
          endDate: endTime,
        };
      }

      const response = await axios.post(
        baseURL + "questions/user",
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );

      const data = response?.data.data;

      setDownloadData(response?.data);
      // Count questions based on status
      const pendingCount = data.filter(
        (item) => item.status === "Pending"
      ).length;
      const inProgressCount = data.filter(
        (item) => item.status === "In Progress"
      ).length;
      const answeredCount = data.filter(
        (item) => item.status === "Answered"
      ).length;

      setPendingQuestions(pendingCount);
      setInProgressQuestions(inProgressCount);
      setAnsweredQuestions(answeredCount);

      // Sort data based on the order "In Progress" -> "Pending" -> "Answered"
      const sortedData = data.sort((a, b) => {
        const statusOrder = ["In Progress", "Pending", "Answered"];
        return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
      });

      setTableData(sortedData);

      setFilteredData(sortedData);
      setExperts(response?.data?.allExpert);
      setTokenSummary(response?.data?.tokenSummary);
      setLoading(false);
    } catch (e) {
      setLoading(false);

      console.error(e);
    }
  };

  useEffect(() => {
    let intervalId;
    if (auth) {
      setLoading(true);
      fetchUser();
      intervalId = setInterval(() => {
        fetchUser();
      }, 5000);
      fetchCurrentBalance();
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [auth, startDate, endDate]);

  const fetchCurrentBalance = async () => {
    try {
      const response = await axios.get(baseURL + "token-wallet/v1/balance", {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });

      SetCurrentBalance(response?.data.User.balance);
      // console.log("balance", response.data);
    } catch (e) {
      console.error(e);
    }
  };

  const handleStatus = (selectedStatus) => {
    setChangingbutton(selectedStatus);
    if (selectedStatus === "All") {
      setFilteredData(tableData);
    } else if (selectedStatus === "starred") {
      // Filter data based on the "starred" status
      const starredData = tableData.filter((item) => item.starred);
      setFilteredData(starredData);
    } else {
      // Filter data based on other status
      const filtered = tableData.filter(
        (item) => item.status === selectedStatus
      );
      setFilteredData(filtered);
    }
  };

  const handleQuestionPage = () => {
    navigate("/community/askanexpert/question");
  };

  const handleBackButton = () => {
    const pathName = location?.state?.path;
    if (role !== "EXPERT") {
      if (pathName == "/home") {
        navigate("/home");
      } else if (pathName == "/community") {
        navigate("/community");
      } else {
        navigate("/home");
      }
    } else {
      navigate("/community");
    }
  };

  return (
    <div className="mt-[4vh]  flex flex-col w-full gap-1 ">
      <div className="w-full  flex flex-col md:flex-row justify-between md:justify-between lg:items-center">
        <div className="flex items-start lg:justify-start w-full md:w-auto">
          <div className="cursor-pointer w-8 mr-1" onClick={handleBackButton}>
            <img
              src="/backtick.svg"
              className="w-[70%] md:w-full h-full"
              alt="backarrow_img"
            />
          </div>
          <p className="text-[16px] md:text-[20px] font-semibold text-[#024D87]">
            {fullName
              ? `${fullName}'s Dashboard`
              : role === "Expert"
              ? "Expert Dashboard"
              : "Enquirer Dashboard"}
          </p>
        </div>

        <div
          className={`flex md:gap-3 ${
            size.width < 768
              ? "mx-2 gap-3 flex-col justify-end items-end w-full pr-3"
              : ""
          } `}
        >
          {role !== "EXPERT" && (
            <PrimaryButton
              text={"Ask a question"}
              width={"fit-content"}
              onClick={handleQuestionPage}
            />
          )}
          <div
            className={`flex flex-col lg:flex-row lg:items-center gap-5 lg:gap-1 `}
          >
            <div
              className={`flex min-w-[110px]  items-center ${
                size.width < 468 ? " min-w-[50px]" : ""
              }`}
            >
              <Select
                borderColor="#CAC5CD"
                color="#605D64"
                variant="outline"
                className="!text-sm !font-medium text-[#605D64]"
                _focus={{ borderColor: "blue.500" }}
                onChange={(e) => handleRangeSelect(e)}
                value={selectedRange}
              >
                <option
                  key="All Time"
                  value={0}
                  className="bg-white hover:bg-blue-200"
                >
                  All time
                </option>
                <option
                  key="Last Seven Days"
                  value={1}
                  className="bg-white hover:bg-blue-200"
                >
                  This Week
                </option>
                <option
                  key="This Month"
                  value={2}
                  className="bg-white hover:bg-blue-200"
                >
                  Month to Date
                </option>
                <option
                  key="This Quarter"
                  value={3}
                  className="bg-white hover:bg-blue-200"
                >
                  Quarter to Date
                </option>
                {/* <option
                key="Previous Quarter"
                value={4}
                className="bg-white hover:bg-blue-200"
              >
                Previous Quarter
              </option> */}
                <option
                  key="This Year"
                  value={5}
                  className="bg-white hover:bg-blue-200"
                >
                  Year to Date
                </option>
                <option
                  key="custom"
                  value={6}
                  className="bg-white hover:bg-blue-200"
                >
                  Custom Range
                </option>
              </Select>
            </div>
            {selectedRange == 6 && (
              <div className="gap-5 lg:gap-2 flex flex-col lg:flex-row lg:justify-end">
                <div className="min-w-[110px]">
                  <FloatingInput
                    text="From"
                    type="date"
                    setDateTime={setCustomFromTime}
                    value={customStartDate}
                    max={
                      customEndDate ||
                      new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
                        .toISOString()
                        .slice(0, 10)
                    }
                  />
                </div>

                <div className="min-w-[110px]">
                  <FloatingInput
                    text="To"
                    type="date"
                    min={customStartDate}
                    max={new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
                      .toISOString()
                      .slice(0, 10)}
                    setDateTime={setCustomToTime}
                    value={customEndDate}
                  />
                </div>
                <div className="flex justify-end">
                  <button
                    className={`${
                      !customStartDate || !customEndDate
                        ? "hover:cursor-not-allowed text-gray-400 bg-slate-100"
                        : "bg-[#6CA6FC] text-white"
                    } text-center py-2 px-2 text-xs md:text-base font-medium rounded-md min-w-[80px]`}
                    onClick={handleClick}
                    disabled={!customStartDate || !customEndDate}
                  >
                    Apply
                  </button>
                </div>
              </div>
            )}
          </div>
          {role !== "EXPERT" && (
            <div className="flex items-center lg:gap-4  gap-0 sm:gap-1 px-0 md:px-3 py-1 rounded-sm bg-[#FFFFD8]">
              <div>
                <p className={`text-[14px] text-[#605D64] font-normal p-1`}>
                  Current Balance
                </p>
              </div>
              <div className="flex items-center gap-1">
                <p className={`text-[#3E3C42] font-semibold text-[14px] `}>
                  {currentBalance}
                </p>
                <img
                  src="/token.svg"
                  className={`w-full h-full `}
                  alt="token"
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="flex flex-col w-full h-full  bg-white p-4 rounded-xl">
        {/* <div className=" flex items-center  rounded-lg "> */}
        <div className="flex flex-col lg:flex-row items-center shadow-lg  rounded-lg gap-2">
          <div className="bg-[#124CA2] w-full lg:w-[30vw] flex items-center justify-center lg:justify-evenly rounded-lg lg:rounded-l-lg gap-10  lg:gap-4 h-[100px] lg:h-[200px] mr-2">
            <div className="rounded-xl xl:rounded-full  w-[60px] h-[60px] xl:w-[100px] xl:h-[100px]">
              {imageurl ? (
                <img
                  src={imageurl}
                  className="w-full xl:object-covered rounded-xl xl:rounded-full xl:h-[100px]"
                  alt="img"
                />
              ) : (
                <img
                  src="/profile.svg"
                  className="w-full object-cover rounded-full"
                  alt="img"
                />
              )}
              {/* <AccountBoxIcon /> */}
            </div>
            <div className=" flex h-full flex-col items-start text-[#FAFAFA] ">
              <div className=" md:mb-5 mt-2">
                <div className=" font-md text-[16px] xl:text-[20px]">
                  Question Summary
                </div>
              </div>
              <div className="flex xl:items-center gap-1 xl:gap-5">
                <div className="">
                  {/* <div className="mb-3">
                <img src="/questionmarkorange.svg" alt="questionmark" />
              </div> */}
                  <p className="text-[20px] xl:text-[40px] font-[500] text-[#69B04B]">
                    {inProgressQuestions}
                  </p>
                  <p className="text-[11px] xl:text-[14px] font-[400] ">
                    In Progress
                  </p>
                </div>
                <div>
                  {/* <div className="mb-3">
                <img src="/questionmarkgreen.svg" alt="questionmark" />
              </div> */}
                  <p className="text-[20px] xl:text-[40px] font-[500] text-[#FFC107]">
                    {pendingQuestions}
                  </p>

                  <p className="text-[11px] xl:text-[14px] font-[400] ">
                    {/* Pending */}
                    New Question
                  </p>
                </div>
                <div>
                  {/* <div className="mb-3">
                <img src="/questionmarkblue.svg" alt="questionmark" />
              </div> */}
                  <p className="text-[20px] xl:text-[40px] font-[500]">
                    {answeredQuestions}
                  </p>
                  <p className="text-[11px] xl:text-[14px] font-[400]">
                    Answered
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`flex ${
              size.width < 650 ? "flex-col h-auto mt-3" : "flex-1"
            } bg-white gap-8 justify-center rounded-r-lg h-[200px] relative`}
          >
            <div className="flex flex-col mt-2 gap-8 ml-2">
              <div className={`text-[14px] font-semibold`}>
                {role === "EXPERT" ? "Top 3 Clients" : "Top 3 Experts"}
              </div>
              <div className="text-[#fff] h-full w-full flex items-center justify-center">
                {top3clients && top3clients?.names?.length > 0 && (
                  // <TopClientsChart data={top3clients} role={role} />
                  <HorizontalBarCustom data={top3clients} expertView={true} />
                )}
                {top3Experts &&
                  role !== "EXPERT" &&
                  top3Experts?.length > 0 && (
                    // <TopClientsChart data={top3Experts} role={role} />
                    <HorizontalBarCustom data={top3Experts} />
                  )}
              </div>
              <Link to="/community/askanexpert/question">
                {" "}
                <div
                  className={`text-[14px] text-[#447ED4] font-semibold ${
                    size.width < 1024 ? "absolute bottom-2" : ""
                  }`}
                >
                  {role !== "EXPERT" ? "View all experts" : ""}
                </div>
              </Link>
            </div>
            <div className=" flex flex-col mt-2 gap-8">
              <div
                className={`text-[14px] font-semibold ${
                  role !== "EXPERT" ? "" : ""
                }`}
              >
                {role === "EXPERT" ? "Top 3 Enquirers" : "Summary"}
              </div>
              <div className="text-[#fff] h-full w-full flex items-center justify-center">
                {top3Enquirers &&
                  top3Enquirers?.names?.length > 0 &&
                  top3Enquirers?.questions?.length > 0 && (
                    // <TopEnquirerChart data={top3Enquirers} role={role} />
                    <TokenHorizontalBar data={top3Enquirers} role={role} />
                    // <CustomChart data={top3Enquirers} />
                  )}
                {tokenSummary &&
                  role !== "EXPERT" &&
                  tokenSummary?.names?.length > 0 &&
                  tokenSummary?.questions?.length > 0 && (
                    // <TopEnquirerChart data={tokenSummary} role={role} />
                    <TokenHorizontalBar data={tokenSummary} role={role} />
                  )}
              </div>
              <div className="h-[21px]" />
            </div>
          </div>
          {size.width > 900 && (
            <div className="p-2 xl:p-10 w-[100px] xl:w-auto">
              <img src="/Common/batchok.svg" alt="" />
            </div>
          )}
          {/* </div> */}
        </div>
        <div className="w-full flex items-center gap-4 justify-between mt-3">
          {/* filter button */}
          <div className="flex gap-1 justify-start p-2 overflow-x-auto w-full">
            <button
              className={`w-[130px] flex justify-center items-center ${
                changingbutton == "All"
                  ? "bg-[#DDEEFF] text-[#605D64] border-[#6CA6FC]"
                  : "border-[#EBEBEB]"
              } border-[2px] rounded-full px-[20px] md:px-[10px] py-[4px] `}
              onClick={() => handleStatus("All")}
            >
              <p className="text-[#605D64] text-[14px]">All</p>
            </button>
            <button
              className={`w-[130px] py-[0px] px-[10px]  ${
                changingbutton == "In Progress"
                  ? "bg-[#DDEEFF] text-[#605D64] border-[#6CA6FC]"
                  : "border-[#EBEBEB]"
              } border-[2px] rounded-full`}
              onClick={() => handleStatus("In Progress")}
            >
              <p className="text-[#605D64] text-[14px] whitespace-nowrap">
                In progress
              </p>
            </button>
            <button
              className={`w-[130px] flex justify-center items-center ${
                changingbutton == "Pending"
                  ? "bg-[#DDEEFF] text-[#605D64] border-[#6CA6FC]"
                  : "border-[#EBEBEB]"
              } border-[2px] rounded-full px-[16px] py-[8px] `}
              onClick={() => handleStatus("Pending")}
            >
              <p className="text-[#605D64] text-[14px] whitespace-nowrap">
                {/* Pending */}
                New Question
              </p>
            </button>

            <button
              className={`w-[130px] flex justify-center items-center ${
                changingbutton == "Answered"
                  ? "bg-[#DDEEFF] text-[#605D64] border-[#6CA6FC]"
                  : "border-[#EBEBEB]"
              } border-[2px] rounded-full px-[16px] py-[8px]`}
              onClick={() => handleStatus("Answered")}
            >
              <p className="text-[#605D64] text-[14px] whitespace-nowrap">
                Answered
              </p>
            </button>
            <button
              className={`w-[130px] flex justify-center items-center ${
                changingbutton == "starred"
                  ? "bg-[#DDEEFF] text-[#605D64] border-[#6CA6FC]"
                  : "border-[#EBEBEB]"
              } border-[2px] rounded-full px-[16px] py-[8px]`}
              onClick={() => handleStatus("starred")}
            >
              <p className="text-[#605D64] text-[14px] whitespace-nowrap">
                Starred
              </p>
            </button>
          </div>
          {/* paginator */}
          <div className="flex justify-end mt-0">
            {filteredData && filteredData.length != 0 && role === "EXPERT" ? (
              <ExlCsvDownload
                data={filteredData}
                order={downloadData.summary}
                orderDetail={downloadData.details}
                enable={true}
              />
            ) : (
              filteredData &&
              filteredData.length != 0 && (
                <ExlCsvDownload
                  data={filteredData}
                  order={downloadData.summary}
                  orderDetail={downloadData.details}
                  enable={true}
                />
              )
            )}
          </div>
        </div>

        <div>
          <div>
            <React.Fragment>
              {filteredData && filteredData.length !== 0 && role ? (
                <AskAnExpertHistoryTable
                  rowData={filteredData}
                  fetchQueries={fetchQueries}
                  role={role}
                  handleQuestionsExceptexpert={handleQuestionsExceptexpert}
                />
              ) : (
                <p className="ml-[45%]">No data available!</p>
              )}
            </React.Fragment>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AskAnExpertHistory;
