import { CustomParameterEditCell } from "../Tabs/Advanced";
import { formatDateTime } from "./dateFormatter";

export const generateDynamicColumns = async (
  columnNames,
  filterable = false,
  sortable = false,
  disableColumnMenu = true,
  flexMap,
  minWidthMap
) => {
  //   dynamic columns
  const dynamicColumns = columnNames.map((columnName, index) => {
    let columnConfig = {
      field: columnName,
      headerName: columnName?.split("_")?.join(" "),
      // checking for array and index is not out bound value both needs to be of same length as that of columnNames
      flex: flexMap && flexMap?.[columnName] ? flexMap[columnName] : 1,
      minWidth:
        minWidthMap && minWidthMap?.[columnName] ? minWidthMap[columnName] : 80,
      sortable: sortable,
      disableColumnMenu: disableColumnMenu,
      filterable: filterable,
    };
    switch (columnName) {
      // case "TOC":
      case "TOM":
        columnConfig.valueFormatter = (params) => {
          return params.value ? formatDateTime(params.value) : "";
        };
        break;
      default:
        break;
    }

    return columnConfig;
  });
  return dynamicColumns;
};

export const generateGroupedColumns = async (
  columnNames,
  filterable = false,
  sortable = false,
  disableColumnMenu = true
) => {
  //   dynamic columns
  const dynamicColumns = columnNames.map((columnName, index) => {
    let columnConfig = {
      field: columnName?.value,
      headerName: columnName?.label,
      align: "center",
      flex: 1,
      minWidth: 65,
      sortable: sortable,
      disableColumnMenu: disableColumnMenu,
      filterable: filterable,
      // cellClassName:"py-5"
    };
    switch (columnName?.value) {
      case "TOC":
      case "TOM":
        columnConfig.minWidth = 130;
        columnConfig.valueFormatter = (params) => {
          return isNaN(params.value)
            ? params.value
            : formatDateTime(params.value);
        };
        break;
      case "steel_grade":
        columnConfig.minWidth = 80;
        columnConfig.flex = 1.2;
        break;
      case "MEID":
        columnConfig.minWidth = 50;
        columnConfig.flex = 0.7;
        break;
      case "thickness":
      case "width":
      case "length":
      case "measured_actual_FRT":
      case "target_FRT":
        columnConfig.valueGetter = (params) => {
          return isNaN(params.value) === false
            ? params.value?.toFixed(4)
            : params.value;
        };
        break;
      default:
        break;
    }

    return columnConfig;
  });
  return dynamicColumns;
};

export const generateReportsGroupedColumns = async (
  columnNames,
  filterable = false,
  sortable = false,
  disableColumnMenu = true
) => {
  //   dynamic columns
  const dynamicColumns = columnNames.map((columnName, index) => {
    let columnConfig = {
      field: columnName?.value,
      headerName: columnName?.label,
      align: "center",
      flex: 1,
      minWidth: 65,
      sortable: sortable,
      disableColumnMenu: disableColumnMenu,
      filterable: filterable,
      editable: false,
      // cellClassName:"py-5"
    };
    switch (columnName?.value) {
      case "TOC":
      case "TOM":
        columnConfig.minWidth = 130;
        columnConfig.valueGetter = (params) => {
          return isNaN(params.value)
            ? params.value
            : formatDateTime(params.value);
        };
        break;
      case "steel_grade":
        columnConfig.minWidth = 80;
        columnConfig.flex = 1.2;
        break;
      case "MEID":
        columnConfig.minWidth = 50;
        columnConfig.flex = 0.7;
        break;
      case "thickness":
      case "width":
      case "length":
      case "measured_actual_FRT":
      case "target_FRT":
        columnConfig.valueGetter = (params) => {
          return isNaN(params.value) === false
            ? params.value?.toFixed(4)
            : params.value;
        };
        break;
      case "comment_1":
      case "comment_2":
      case "comment_3":
        columnConfig.editable = true;
        break;
      default:
        break;
    }

    return columnConfig;
  });
  return dynamicColumns;
};

export const upcomingBiasReommenderColumnGroupingModel = [
  {
    groupId: "Time Stamp",
    headerAlign: "center",
    children: [{ field: "TOC", headerAlign: 'center', }, { field: "TOM", headerAlign: 'center', }],
  },
  {
    groupId: "Primary Data",
    headerAlign: "center",
    children: [
      { field: "plate_id", headerName: "id", headerAlign: 'center', },
      { field: "MEID", headerAlign: 'center', },
      { field: "steel_grade", headerAlign: 'center', },
      { field: "thickness", headerAlign: 'center', },
      { field: "width", headerAlign: 'center', },
      { field: "length", headerAlign: 'center', },
    ],
  },
  {
    groupId: "Measured",
    headerAlign: "center",
    children: [{
      field: "surface_temperature", headerAlign: "center"
    }, { field: "measured_actual_FRT", headerAlign: 'center', },],
  },
  {
    groupId: "Recommended",
    headerAlign: "center",
    children: [{ field: "recommended_actual_FRT", headerAlign: 'center', },],
  },
  {
    groupId: "Target",
    headerAlign: "center",
    children: [
      { field: "target_FRT", headerAlign: 'center', },
      { field: "target_SCT", headerAlign: 'center', },
      { field: "target_FCT", headerAlign: 'center', },
    ],
  },
];

export const upcomingRolledPlatesColumnGroupingModel = [
  {
    groupId: "Time Stamp",
    headerAlign: "center",
    children: [
      { field: "TOC", headerAlign: 'center', },
      { field: "TOM", headerAlign: 'center', }
    ],
  },
  {
    groupId: "Primary Data",
    headerAlign: "center",
    children: [
      { field: "plate_id", headerName: "id", headerAlign: 'center', },
      { field: "steel_grade", headerAlign: 'center', },
      { field: "thickness", headerAlign: 'center', },
      { field: "width", headerAlign: 'center', },
      { field: "length", headerAlign: 'center', },
    ],
  },
  {
    groupId: "Measured",
    headerAlign: "center",
    children: [
      { field: "actual_surface_temperature", headerAlign: 'center', },
      { field: "measured_actual_FRT", headerAlign: 'center', },
      { field: "measured_AFCT", headerAlign: 'center', },
      { field: "measured_Head_FCT", headerAlign: 'center', },
      { field: "measured_Body_FCT", headerAlign: 'center', },
      { field: "measured_Tail_FCT", headerAlign: 'center', },
    ],
  },
  {
    groupId: "Recommended",
    headerAlign: "center",
    children: [
      { field: "recommended_surface_temperature", headerAlign: "center" },
      { field: "recommended_actual_FRT", headerAlign: 'center', }
    ],
  },
  {
    groupId: "Target",
    headerAlign: "center",
    children: [
      { field: "target_FRT", headerAlign: 'center', },
      { field: "target_SCT", headerAlign: 'center', },
      { field: "target_FCT", headerAlign: 'center', },
    ],
  },
  
];

export const upcomingSlabReommenderColumnGroupingModel = [
  {
    groupId: "Time Stamp",
    headerAlign: "center",
    children: [{ field: "TOC", headerAlign: 'center', }, { field: "TOM", headerAlign: 'center', }],
  },
  {
    groupId: "Primary Data",
    headerAlign: "center",
    children: [
      { field: "plate_id", headerName: "id", headerAlign: 'center', },
      { field: "MEID", headerAlign: 'center', },
      { field: "steel_grade", headerAlign: 'center', },
    ],
  },
  {
    groupId: "Target Dimensions",
    headerAlign: "center",
    children: [
      { field: "thickness", headerAlign: 'center', },
      { field: "width", headerAlign: 'center', },
      { field: "length", headerAlign: 'center', },
    ],
  },
  {
    groupId: "Measured",
    headerAlign: "center",
    children: [{ field: "measured_actual_FRT", headerAlign: 'center', }],
  },
  {
    groupId: "Recommended",
    headerAlign: "center",
    children: [{
      field: "surface_temperature", headerAlign: "center"
    }, { field: "recommended_actual_FRT", headerAlign: 'center', }],
  },
  {
    groupId: "Target",
    headerAlign: "center",
    children: [
      { field: "target_FRT", headerAlign: 'center', },
      { field: "target_SCT", headerAlign: 'center', },
      { field: "target_FCT", headerAlign: 'center', },
    ],
  },
];

export const nearestNeighbourColumnGroupingModel = [
  {
    groupId: "Primary Data",
    headerAlign: "center",
    children: [

      { field: "Plate ID", headerAlign: 'center', },
      { field: "Steel Grade", headerAlign: 'center', },
      { field: "Thickness", headerAlign: 'center', },
      { field: "Width", headerAlign: 'center', },
      { field: "Length", headerAlign: 'center', },
      { field: "Gradeshift", headerAlign: 'center', },
      { field: "Thickshift", headerAlign: 'center', },
      { field: "Lenshift", headerAlign: 'center', },
      { field: "Widthshift", headerAlign: 'center', },
      { field: "Water Temp", headerAlign: 'center', },
      { field: "Cooling Speed", headerAlign: 'center', },
    ],
  },
  {
    groupId: "Temperatures",
    headerAlign: "center",
    children: [
      { field: "TFRT" },
      { field: "AFRT", headerAlign: 'center', },
      { field: "TSCT", headerAlign: 'center', },
      { field: "PSCT", headerAlign: 'center', },
      { field: "ASCT", headerAlign: 'center', },
      { field: "FCT MIN", headerAlign: 'center', },
      { field: "TFCT", headerAlign: 'center', },
      { field: "FCT MAX", headerAlign: 'center', },
      { field: "PFCT", headerAlign: 'center', },
      { field: "AFCT", headerAlign: 'center', },
      { field: "FCT QC", headerAlign: 'center', },
      { field: "Temp Shift", headerAlign: 'center' },
      { field: "Head FCT", headerAlign: 'center', },
      { field: "Body FCT", headerAlign: 'center', },
      { field: "Tail FCT", headerAlign: 'center', },
      { field: "Length FCT", headerAlign: 'center', },
      { field: "TFCT Error", headerAlign: 'center', },
      { field: "Head FCT Error", headerAlign: 'center', },
      { field: "Tail FCT Error", headerAlign: 'center', },
      { field: "Body FCT Error", headerAlign: 'center', },
      { field: "Length FCT Error", headerAlign: 'center', },
      { field: "PFCT Error", headerAlign: 'center', },
      { field: "AFRT TSCT Error", headerAlign: 'center', },
      { field: "ASCT TSCT Error", headerAlign: 'center', },
    ],
  },
  {
    groupId: "PRT Indices",
    headerAlign: "center",
    children: [
      { field: "Prt Indices Wfr Mulpic" },
      { field: "Prt Indices Edge Mask", headerAlign: 'center', },
      { field: "Prt Indices Total Flow", headerAlign: 'center', },
      { field: "Prt Indices Speed Curve", headerAlign: 'center', },
      { field: "Prt Indices H-T Mask", headerAlign: 'center', },
      { field: "Prt Indices Watercrown", headerAlign: 'center', },
    ],
  },
  {
    groupId: "Intermediate",
    headerAlign: "center",
    children: [
      { field: "Effi1" },
      { field: "Effi2", headerAlign: 'center', },
      { field: "Dtemp", headerAlign: 'center', },
    ],
  },
  {
    groupId: "Result",
    headerAlign: "center",
    children: [
      { field: "Temp Equal", headerAlign: 'center', },
      { field: "Total Flow", headerAlign: 'center', },
      { field: "Initial Spd", headerAlign: 'center', },
      { field: "Final Spd", headerAlign: 'center', },
      { field: "Coeff A", headerAlign: 'center', },
      { field: "Coeff B", headerAlign: 'center', },
      { field: "Delta T", headerAlign: 'center', },
    ],
  },
  {
    groupId: "Edge Masking",
    headerAlign: "center",
    children: [
      { field: "Ws Position B1" },
      { field: "Ws Position B2", headerAlign: 'center', },
      { field: "Ws Position B3", headerAlign: 'center', },
      { field: "Ws Position B4" },
      { field: "Ds Position B1", headerAlign: 'center', },
      { field: "Ds Position B2", headerAlign: 'center', },
      { field: "Ds Position B3" },
      { field: "Ds Position B4", headerAlign: 'center', },
      { field: "Ws Length B1", headerAlign: 'center', },
      { field: "Ws Length B2" },
      { field: "Ws Length B3", headerAlign: 'center', },
      { field: "Ws Length B4", headerAlign: 'center', },
      { field: "Ds Length B1" },
      { field: "Ds Length B2", headerAlign: 'center', },
      { field: "Ds Length B3", headerAlign: 'center', },
      { field: "Ds Length B4" },
    ],
  },
  {
    groupId: "Flow Related Biases",
    headerAlign: "center",
    children: [
      { field: "Prt Total" },
      { field: "Prt Top", headerAlign: 'center', },
      { field: "Prt Btm", headerAlign: 'center', },
      { field: "Prt Ratio" },
    ],
  },
  {
    groupId: "Speed Related PRT Biases",
    headerAlign: "center",
    children: [
      { field: "Initial Speed" },
      { field: "Final Speed", headerAlign: 'center', },
      { field: "Curve Shift", headerAlign: 'center', },
      { field: "Max Speed" },
    ],
  },
  {
    groupId: "WCV and EM PRT Biases",
    headerAlign: "center",
    children: [
      { field: "Overall Edge Mask Bias Work Side" },
      { field: "Overall Edge Mask Bias Drive Side" },
      { field: "Edge Mask Bias Ds B1", headerAlign: 'center', },
      { field: "Edge Mask Bias Ds B2", headerAlign: 'center', },
      { field: "Edge Mask Bias Ds B3", headerAlign: 'center', },
      { field: "Edge Mask Bias Ds B4", headerAlign: 'center', },
      { field: "Edge Mask Bias Os B1", headerAlign: 'center', },
      { field: "Edge Mask Bias Os B2", headerAlign: 'center', },
      { field: "Edge Mask Bias Os B3", headerAlign: 'center', },
      { field: "Edge Mask Bias Os B4", headerAlign: 'center', },
    ],
  },
  {
    groupId: "Masking Bank A",
    headerAlign: "center",
    children: [
      { field: "Ramp Head H1" },
      { field: "Ramp Head H2" },
      { field: "Ramp Head H3" },
      { field: "Ramp Head H4" },
      { field: "Ramp Head H5" },
      { field: "Ramp Head H6" },
      { field: "Ramp Tail H1" },
      { field: "Ramp Tail H2" },
      { field: "Ramp Tail H3" },
      { field: "Ramp Tail H4" },
      { field: "Ramp Tail H5" },
      { field: "Ramp Tail H6" },
      { field: "Lgth Head H1" },
      { field: "Lgth Head H2" },
      { field: "Lgth Head H3" },
      { field: "Lgth Head H4" },
      { field: "Lgth Head H5" },
      { field: "Lgth Head H6" },
      { field: "Lgth Tail H1" },
      { field: "Lgth Tail H2" },
      { field: "Lgth Tail H3" },
      { field: "Lgth Tail H4" },
      { field: "Lgth Tail H5" },
      { field: "Lgth Tail H6" },
      { field: "Oset Head H1" },
      { field: "Oset Head H2" },
      { field: "Oset Head H3" },
      { field: "Oset Head H4" },
      { field: "Oset Head H5" },
      { field: "Oset Head H6" },
      { field: "Oset Tail H1" },
      { field: "Oset Tail H2" },
      { field: "Oset Tail H3" },
      { field: "Oset Tail H4" },
      { field: "Oset Tail H5" },
      { field: "Oset Tail H6" },
    ],
  },
  {
    groupId: "Masking A",
    headerAlign: "center",
    children: [
      { field: "Botm Ramp Head H1" },
      { field: "Botm Ramp Head H2" },
      { field: "Botm Ramp Head H3" },
      { field: "Botm Ramp Head H4" },
      { field: "Botm Ramp Head H5" },
      { field: "Botm Ramp Head H6" },
      { field: "Botm Ramp Tail H1" },
      { field: "Botm Ramp Tail H2" },
      { field: "Botm Ramp Tail H3" },
      { field: "Botm Ramp Tail H4" },
      { field: "Botm Ramp Tail H5" },
      { field: "Botm Ramp Tail H6" },
      { field: "Botm Lgth Head H1" },
      { field: "Botm Lgth Head H2" },
      { field: "Botm Lgth Head H3" },
      { field: "Botm Lgth Head H4" },
      { field: "Botm Lgth Head H5" },
      { field: "Botm Lgth Head H6" },
      { field: "Botm Lgth Tail H1" },
      { field: "Botm Lgth Tail H2" },
      { field: "Botm Lgth Tail H3" },
      { field: "Botm Lgth Tail H4" },
      { field: "Botm Lgth Tail H5" },
      { field: "Botm Lgth Tail H6" },
      { field: "Botm Oset Head H1" },
      { field: "Botm Oset Head H2" },
      { field: "Botm Oset Head H3" },
      { field: "Botm Oset Head H4" },
      { field: "Botm Oset Head H5" },
      { field: "Botm Oset Head H6" },
      { field: "Botm Oset Tail H1" },
      { field: "Botm Oset Tail H2" },
      { field: "Botm Oset Tail H3" },
      { field: "Botm Oset Tail H4" },
      { field: "Botm Oset Tail H5" },
      { field: "Botm Oset Tail H6" },
    ],
  },
  {
    groupId: "Product Range Masking",
    headerAlign: "center",
    children: [
      { field: "Bock Head H1" },
      { field: "Bock Head H2" },
      { field: "Bock Head H3" },
      { field: "Bock Head H4" },
      { field: "Bock Head H5" },
      { field: "Bock Head H6" },
      { field: "Bock Tail H1" },
      { field: "Bock Tail H2" },
      { field: "Bock Tail H3" },
      { field: "Bock Tail H4" },
      { field: "Bock Tail H5" },
      { field: "Bock Tail H6" },
      { field: "Tock Head H1" },
      { field: "Tock Head H2" },
      { field: "Tock Head H3" },
      { field: "Tock Head H4" },
      { field: "Tock Head H5" },
      { field: "Tock Head H6" },
      { field: "Tock Tail H1" },
      { field: "Tock Tail H2" },
      { field: "Tock Tail H3" },
      { field: "Tock Tail H4" },
      { field: "Tock Tail H5" },
      { field: "Tock Tail H6" },
      { field: "Ulfv H1" },
      { field: "Ulfv H2" },
      { field: "Ulfv H3" },
      { field: "Ulfv H4" },
      { field: "Ulfv H5" },
      { field: "Ulfv H6" },
    ],
  },
  {
    groupId: "Product Range WCV and EM Operator Biases",
    headerAlign: "center",
    children: [
      { field: "Flow Coeff B1" },
      { field: "Flow Coeff B2" },
      { field: "Flow Coeff B3" },
      { field: "Flow Coeff B4" },
      { field: "Offset B1" },
      { field: "Offset B2" },
      { field: "Offset B3" },
      { field: "Offset B4" },
      { field: "Thick Coeff B1" },
      { field: "Thick Coeff B2" },
      { field: "Thick Coeff B3" },
      { field: "Thick Coeff B4" },
      { field: "Width Coeff B1" },
      { field: "Width Coeff B2" },
      { field: "Width Coeff B3" },
      { field: "Width Coeff B4" },
      { field: "Overall Watercrown Bias Drive Side" },
      { field: "Overall Watercrown Bias Work Side" },
      { field: "Water Crown Bias Ds B1" },
      { field: "Water Crown Bias Ds B2" },
      { field: "Water Crown Bias Ds B3" },
      { field: "Water Crown Bias Ds B4" },
      { field: "Water Crown Bias Os B1" },
      { field: "Water Crown Bias Os B2" },
      { field: "Water Crown Bias Os B3" },
      { field: "Water Crown Bias Os B4" }
    ],
  },
  {
    groupId: "Product Range Flow Related Biases",
    headerAlign: "center",
    children: [
      { field: "Fa" },
      { field: "Prt Btm B1" },
      { field: "Prt Btm B2" },
      { field: "Prt Btm B3" },
      { field: "Prt Btm B4" },
      { field: "Prt Top B1" },
      { field: "Prt Top B2" },
      { field: "Prt Top B3" },
      { field: "Prt Top B4" },
      { field: "Prt Total B1" },
      { field: "Prt Total B2" },
      { field: "Prt Total B3" },
      { field: "Prt Total B4" },
    ],
  },
  {
    groupId: "Results",
    headerAlign: "center",
    children: [
      { field: "Ca-Du" },
      { field: "H B1" },
      { field: "H B2" },
      { field: "H B3" },
      { field: "H B4" },
      { field: "B1 H1" },
      { field: "B1 H2" },
      { field: "B1 H3" },
      { field: "B1 H4" },
      { field: "B1 H5" },
      { field: "B1 H6" },
      { field: "B2 H1" },
      { field: "B3 H1" },
      { field: "B4 H1" },
      { field: "Cocohg B1" },
      { field: "Cocohg B2" },
      { field: "Cocohg B3" },
      { field: "Cocohg B4" },
      { field: "Cocotw B1" },
      { field: "Cocotw B2" },
      { field: "Cocotw B3" },
      { field: "Cocotw B4" },
      { field: "Corwfr B1" },
      { field: "Corwfr B2" },
      { field: "Corwfr B3" },
      { field: "Corwfr B4" },
    ],
  },
  {
    groupId: "Water Flow Rate Coefficients Mulpic",
    headerAlign: "center",
    children: [
      { field: "A0" },
      { field: "A1" },
      { field: "A2" },
      { field: "A3" },
      { field: "A4" },
      { field: "A5" },
      { field: "A6" },
      { field: "A7" },
    ],
  },
];

export const historicalColumnGroupingModel = () => {
  return [
    {
      groupId: "Primary Data",
      children: [
        { field: "primary_data_plate_id" },
        { field: "primary_data_steel_grade" },
        { field: "primary_data_thickness" },
        { field: "primary_data_width" },
        { field: "primary_data_length" },
      ],
    },
    {
      groupId: "Measured Data",
      children: [
        { field: "measured_data_actual_frt" },
        { field: "measured_data_FCT_error" },
        { field: "measured_data_water_temp" },
      ],
    },
    {
      groupId: "PRT Indices",
      children: [
        { field: "PRT_indices_total_flow" },
        { field: "PRT_indices_speed_curve" },
        { field: "PRT_indices_WFR" },
        { field: "PRT_indices_edge_mask" },
        { field: "PRT_indices_H-T_mask" },
        { field: "prt_speed_bias" },
        { field: "initial_speed" },
        { field: "final_speed" },
        { field: "curve_shift" },
        { field: "max_speed" },
      ],
    },
    {
      groupId: "PRT Indices",
      children: [
        { field: "PRT_indices_total_flow" },
        { field: "PRT_indices_speed_curve" },
        { field: "PRT_indices_WFR" },
        { field: "PRT_indices_edge_mask" },
        { field: "PRT_indices_H-T_mask" },
        { field: "prt_speed_bias" },
        { field: "initial_speed" },
        { field: "final_speed" },
        { field: "curve_shift" },
        { field: "max_speed" },
      ],
    },
    {
      groupId: "Water Flow Rate Coefficients",
      children: [
        { field: "A0" },
        { field: "A1" },
        { field: "A2" },
        { field: "A7" },
      ],
    },
    {
      groupId: "Intermediate Results",
      children: [{ field: "eff1" }, { field: "eff2" }],
    },
    {
      groupId: "Edge Masking",
      children: [
        { field: "position" },
        { field: "worker_side" },
        { field: "driver_side" },
      ],
    },
    {
      groupId: "Head Masking",
      children: [
        { field: "bank_a" },
        { field: "ramp" },
        { field: "H1" },
        { field: "H2" },
        { field: "H3" },
        { field: "H4" },
        { field: "H5" },
        { field: "H6" },
      ],
    },
    {
      groupId: "Tail Masking",
      children: [
        { field: "bank_a" },
        { field: "ramp" },
        { field: "H1" },
        { field: "H2" },
        { field: "H3" },
        { field: "H4" },
        { field: "H5" },
        { field: "H6" },
      ],
    },
  ];
};

export const generateQualityMatrixColumns = async (
  columnNames,
  filterable = false,
  sortable = false,
  disableColumnMenu = true,
  editable = true
) => {
  //   dynamic columns
  const dynamicColumns = columnNames.map((columnName, index) => {
    let columnConfig = {
      field: columnName?.value,
      headerName: columnName?.label,
      flex: 1,
      minWidth: 200,
      sortable: sortable,
      disableColumnMenu: disableColumnMenu,
      filterable: filterable,
      editable: editable,
      type: "number",
      align: "left",
      headerAlign: "left",
    };
    switch (columnName?.value) {
      case "STEELGRADE":
        columnConfig.type = "text";
        break;
      default:
        break;
    }

    return columnConfig;
  });
  return dynamicColumns;
};

export const generateWeightsTableColumns = async (
  columnNames,
  filterable = false,
  sortable = false,
  disableColumnMenu = true,
  editable = true,
  parameter
) => {
  //   dynamic columns
  const dynamicColumns = columnNames.map((columnName, index) => {
    let columnConfig = {
      field: columnName?.value,
      headerName: columnName?.label,
      flex: 1,
      minWidth: 200,
      sortable: sortable,
      disableColumnMenu: disableColumnMenu,
      filterable: filterable,
      editable: editable,
      type: "number",
      align: "left",
      headerAlign: "left",
    };
    switch (columnName?.value) {
      case "parameter":
        columnConfig.flex = 2;
        columnConfig.type = "singleSelect";
        columnConfig.valueOptions = parameter;
        columnConfig.valueGetter = ({ value }) => {
          if (value) {
            return value?.split("_").join(" ");
          } else {
            return "";
          }
        };
        columnConfig.renderEditCell = (params) => (
          <CustomParameterEditCell {...params} />
        );

        break;
      default:
        break;
    }

    return columnConfig;
  });
  return dynamicColumns;
};

export const dummySimilarPlatesColumns = [
  "TOC",
  "Plate ID",
  "Steel Grade",
  "Thickness",
  "Width",
  "Length",
  "Water Temp",
  "Gradeshift",
  "Thickshift",
  "Lenshift",
  "Widthshift",
  "Cooling Speed",
  "TFRT",
  "AFRT",
  "TSCT",
  "PSCT",
  "ASCT",
  "PFCT",
  "AFCT",
  "PFCT Error",
  "Head FCT",
  "Body FCT",
  "Tail FCT",
  "Length FCT",
  "Head FCT Error",
  "Body FCT Error",
  "Tail FCT Error",
  "Length FCT Error",
  "FCT MIN",
  "FCT MAX",
  "TFCT",
  "FCT QC",
  "TFCT Error",
  "AFRT TSCT Error",
  "ASCT TSCT Error",

  "Prt Indices Wfr Mulpic",
  "Prt Indices Edge Mask",
  "Prt Indices Total Flow",
  "Prt Indices Speed Curve",
  "Prt Indices H-T Mask",
  "Prt Indices Watercrown",
  "Effi1",
  "Effi2",
  "Dtemp",
  "Total Flow",
  "Initial Spd",
  "Final Spd",
  "Coeff A",
  "Coeff B",
  "Delta T",
  "Ws Position B1",
  "Ws Position B2",
  "Ws Position B3",
  "Ws Position B4",
  "Ds Position B1",
  "Ds Position B2",
  "Ds Position B3",
  "Ds Position B4",
  "Ws Length B1",
  "Ws Length B2",
  "Ws Length B3",
  "Ws Length B4",
  "Ds Length B1",
  "Ds Length B2",
  "Ds Length B3",
  "Ds Length B4",
  "Prt Total",
  "Prt Top",
  "Prt Btm",
  "Prt Ratio",
  "Initial Speed",
  "Final Speed",
  "Curve Shift",
  "Max Speed",
  "Overall Edge Mask Bias Work Side",
  "Overall Edge Mask Bias Drive Side",
  "Edge Mask Bias Ds B1",
  "Edge Mask Bias Ds B2",
  "Edge Mask Bias Ds B3",
  "Edge Mask Bias Ds B4",
  "Edge Mask Bias Os B1",
  "Edge Mask Bias Os B2",
  "Edge Mask Bias Os B3",
  "Edge Mask Bias Os B4",
  "Ramp Head H1",
  "Ramp Head H2",
  "Ramp Head H3",
  "Ramp Head H4",
  "Ramp Head H5",
  "Ramp Head H6",
  "Ramp Tail H1",
  "Ramp Tail H2",
  "Ramp Tail H3",
  "Ramp Tail H4",
  "Ramp Tail H5",
  "Ramp Tail H6",
  "Lgth Head H1",
  "Lgth Head H2",
  "Lgth Head H3",
  "Lgth Head H4",
  "Lgth Head H5",
  "Lgth Head H6",
  "Lgth Tail H1",
  "Lgth Tail H2",
  "Lgth Tail H3",
  "Lgth Tail H4",
  "Lgth Tail H5",
  "Lgth Tail H6",
  "Oset Head H1",
  "Oset Head H2",
  "Oset Head H3",
  "Oset Head H4",
  "Oset Head H5",
  "Oset Head H6",
  "Oset Tail H1",
  "Oset Tail H2",
  "Oset Tail H3",
  "Oset Tail H4",
  "Oset Tail H5",
  "Oset Tail H6",
  "Botm Ramp Head H1",
  "Botm Ramp Head H2",
  "Botm Ramp Head H3",
  "Botm Ramp Head H4",
  "Botm Ramp Head H5",
  "Botm Ramp Head H6",
  "Botm Ramp Tail H1",
  "Botm Ramp Tail H2",
  "Botm Ramp Tail H3",
  "Botm Ramp Tail H4",
  "Botm Ramp Tail H5",
  "Botm Ramp Tail H6",
  "Botm Lgth Head H1",
  "Botm Lgth Head H2",
  "Botm Lgth Head H3",
  "Botm Lgth Head H4",
  "Botm Lgth Head H5",
  "Botm Lgth Head H6",
  "Botm Lgth Tail H1",
  "Botm Lgth Tail H2",
  "Botm Lgth Tail H3",
  "Botm Lgth Tail H4",
  "Botm Lgth Tail H5",
  "Botm Lgth Tail H6",
  "Botm Oset Head H1",
  "Botm Oset Head H2",
  "Botm Oset Head H3",
  "Botm Oset Head H4",
  "Botm Oset Head H5",
  "Botm Oset Head H6",
  "Botm Oset Tail H1",
  "Botm Oset Tail H2",
  "Botm Oset Tail H3",
  "Botm Oset Tail H4",
  "Botm Oset Tail H5",
  "Botm Oset Tail H6",
  "Meid",
  "Acr",
  "Actualcooltime",
  "Adaptationon",
  "Aeq",
  "Aeqcr",
  "AFRT Range",
  "Aircr",
  "Avg Thk",
  "A Avg Wfd",
  "A Btmwfd",
  "A Topwfd",
  "Bcd Btmwfd",
  "Bcd Topwfd",
  "B Avg Wfd",
  "Eqtime",
  "Ghostcoolon",
  "Len Range",
  "Maxcr",
  "Pcr",
  "Predeq",
  "Productchange",
  "Thk Range",
  "Total Wfd",
  "Width Range",
  "Analysis Al",
  "Analysis C",
  "Analysis Cr",
  "Analysis Cu",
  "Analysis Mn",
  "Analysis Mo",
  "Analysis N",
  "Analysis Nb",
  "Analysis Ni",
  "Analysis P",
  "Analysis S",
  "Analysis Si",
  "Analysis V",
  "Analysis Steel Matid",
  "Primary Data Pdnbmesh",
  "Primary Data Reference",
  "Bock Head H1",
  "Bock Head H2",
  "Bock Head H3",
  "Bock Head H4",
  "Bock Head H5",
  "Bock Head H6",
  "Bock Tail H1",
  "Bock Tail H2",
  "Bock Tail H3",
  "Bock Tail H4",
  "Bock Tail H5",
  "Bock Tail H6",
  "Tock Head H1",
  "Tock Head H2",
  "Tock Head H3",
  "Tock Head H4",
  "Tock Head H5",
  "Tock Head H6",
  "Tock Tail H1",
  "Tock Tail H2",
  "Tock Tail H3",
  "Tock Tail H4",
  "Tock Tail H5",
  "Tock Tail H6",
  "Ulfv H1",
  "Ulfv H2",
  "Ulfv H3",
  "Ulfv H4",
  "Ulfv H5",
  "Ulfv H6",
  "Flow Coeff B1",
  "Flow Coeff B2",
  "Flow Coeff B3",
  "Flow Coeff B4",
  "Offset B1",
  "Offset B2",
  "Offset B3",
  "Offset B4",
  "Thick Coeff B1",
  "Thick Coeff B2",
  "Thick Coeff B3",
  "Thick Coeff B4",
  "Width Coeff B1",
  "Width Coeff B2",
  "Width Coeff B3",
  "Width Coeff B4",
  "Overall Watercrown Bias Drive Side",
  "Overall Watercrown Bias Work Side",
  "Water Crown Bias Ds B1",
  "Water Crown Bias Ds B2",
  "Water Crown Bias Ds B3",
  "Water Crown Bias Ds B4",
  "Water Crown Bias Os B1",
  "Water Crown Bias Os B2",
  "Water Crown Bias Os B3",
  "Water Crown Bias Os B4",
  "Fa",
  "Prt Btm B1",
  "Prt Btm B2",
  "Prt Btm B3",
  "Prt Btm B4",
  "Prt Top B1",
  "Prt Top B2",
  "Prt Top B3",
  "Prt Top B4",
  "Prt Total B1",
  "Prt Total B2",
  "Prt Total B3",
  "Prt Total B4",
  "Ca-Du",
  "H B1",
  "H B2",
  "H B3",
  "H B4",
  "B1 H1",
  "B1 H2",
  "B1 H3",
  "B1 H4",
  "B1 H5",
  "B1 H6",
  "B2 H1",
  "B3 H1",
  "B4 H1",
  "Cocohg B1",
  "Cocohg B2",
  "Cocohg B3",
  "Cocohg B4",
  "Cocotw B1",
  "Cocotw B2",
  "Cocotw B3",
  "Cocotw B4",
  "Corwfr B1",
  "Corwfr B2",
  "Corwfr B3",
  "Corwfr B4",
  "A0",
  "A1",
  "A2",
  "A3",
  "A4",
  "A5",
  "A6",
  "A7",
  "id"
]

export const reportsColumnGroupingModel = [
  {
    groupId: "Time Stamp",
    headerAlign: "center",
    children: [{ field: "TOC", headerAlign: 'center', }],
  },
  {
    groupId: "Primary Data",
    headerAlign: "center",
    children: [
      { field: "primary_data_plate_id", headerAlign: 'center', },
      { field: "primary_data_steel_grade", headerAlign: 'center', },
      { field: "primary_data_thickness", headerAlign: 'center', },
      { field: "primary_data_width", headerAlign: 'center', },
      { field: "primary_data_length", headerAlign: 'center', },
      { field: "measured_data_FCT_QC", headerAlign: 'center', },
      { field: "measured_data_FCT_Error", headerAlign: 'center', },
    ],
  },
  {
    groupId: "Compliance",
    headerAlign: "center",
    children: [
      { field: "bias", headerAlign: 'center', },
      { field: "wfr", headerAlign: 'center', },
      { field: "edge_masking", headerAlign: 'center', },
      { field: "head_masking", headerAlign: 'center', },
      { field: "tail_masking", headerAlign: 'center', },
      { field: "flow_related_bias", headerAlign: 'center', },
    ],
  },
  {
    groupId: "Comments",
    headerAlign: "center",
    children: [
      { field: "upcoming_plate_comment", headerAlign: 'center', },
      { field: "comment_1", headerAlign: 'center', },
      { field: "comment_2", headerAlign: 'center', },
      { field: "comment_3", headerAlign: 'center', },
    ],
  }

]

export const ibaColumnGroupingModel = [
  {
    groupId: "Bank A WS",
    headerAlign: "center",
    children: [
      { field: "bank_a_edge_masking_ws_reference_(mm)", headerAlign: 'center', },
      { field: "bank_a_edge_masking_ws_actual_(mm)", headerAlign: 'center', },
    ],
  },
  {
    groupId: "Bank A DS",
    headerAlign: "center",
    children: [
      { field: "bank_a_edge_masking_ds_reference_(mm)", headerAlign: 'center', },
      { field: "bank_a_edge_masking_ds_actual_(mm)", headerAlign: 'center', },
    ],
  },
  {
    groupId: "Bank B WS",
    headerAlign: "center",
    children: [
      { field: "bank_b_edge_masking_ws_reference_(mm)", headerAlign: 'center', },
      { field: "bank_b_edge_masking_ws_actual_(mm)", headerAlign: 'center', },
    ],
  },
  {
    groupId: "Bank B DS",
    headerAlign: "center",
    children: [
      { field: "bank_b_edge_masking_ds_reference_(mm)", headerAlign: 'center', },
      { field: "bank_b_edge_masking_ds_actual_(mm)", headerAlign: 'center', },
    ],
  },
  {
    groupId: "Bank C WS",
    headerAlign: "center",
    children: [
      { field: "bank_c_edge_masking_ws_reference_(mm)", headerAlign: 'center', },
      { field: "bank_c_edge_masking_ws_actual_(mm)", headerAlign: 'center', },
    ],
  },
  {
    groupId: "Bank C DS",
    headerAlign: "center",
    children: [
      { field: "bank_c_edge_masking_ds_reference_(mm)", headerAlign: 'center', },
      { field: "bank_c_edge_masking_ds_actual_(mm)", headerAlign: 'center', },
    ],
  },
  {
    groupId: "Bank D WS",
    headerAlign: "center",
    children: [
      { field: "bank_d_edge_masking_ws_reference_(mm)", headerAlign: 'center', },
      { field: "bank_d_edge_masking_ws_actual_(mm)", headerAlign: 'center', },
    ],
  },
  {
    groupId: "Bank D DS",
    headerAlign: "center",
    children: [
      { field: "bank_d_edge_masking_ds_reference_(mm)", headerAlign: 'center', },
      { field: "bank_d_edge_masking_ds_actual_(mm)", headerAlign: 'center', },
    ],
  }
]