import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Select,
  DrawerCloseButton,
  Radio,
  RadioGroup,
  Table,
  Th,
  Tr,
  Tbody,
  Thead,
  Td,
  Input,
  TableContainer,
  InputGroup,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import TonalButton from "../../../util/Buttons/TonalButton";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { useContext, useEffect, useId, useRef, useState } from "react";
import TextButton from "../../../util/Buttons/TextButton";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import NavContext from "../../NavContext";
import { baseURL } from "../../..";
import ConcentrateSelect from "./ConcentrateSelect";
import FileUploader from "./FileUploader";
import { getRecipeConcs } from "../BlendComplianceTracking";
import BctContext from "../BctContext";

const mach = {
  1: {
    loader: [4, 5],
    hopper: [1, 2],
  },
  3: {
    loader: [5, 4],
    hopper: [3],
  },
};

const ConcRow = ({ data, setData, index }) => {
  const handleChange = (name, value) => {
    setData((prev) => {
      let newData = [...prev];
      newData[index][name] = value;
      return newData;
    });
  };

  return (
    <Tr fontSize={"14px"} fontWeight={500}>
      <Td color={"#605D64"} pt={"16px"} border={0} textAlign={"center"}>
        <ConcentrateSelect
          value={data[index]?.conc}
          func={(e) => handleChange("conc", e.target.value)}
        />
      </Td>
      <Td color={"#605D64"} pt={"16px"} border={0} textAlign={"center"}>
        <InputGroup>
          <Input
            type="Number"
            value={data[index]?.ratio}
            name="ratio"
            onChange={(e) =>
              handleChange(
                "ratio",
                parseFloat(e.target.value) > 100
                  ? 100
                  : parseFloat(e.target.value || 0)
              )
            }
          />
          <InputRightElement>%</InputRightElement>
        </InputGroup>
      </Td>
      <Td color={"#605D64"} pt={"16px"} border={0} textAlign={"center"}>
        {((200 * data[index]?.ratio) / 100).toFixed(2)}
      </Td>
    </Tr>
  );
};

function generateRandomId() {
  const timestamp = new Date().getTime();
  const random = Math.random().toString(36).substring(2);

  return `${timestamp}${random}`;
}

const RecipeDrawer = ({ closeModal, openModal, smelter }) => {
  const columns = ["concentrate", "blend ratio%", "200 mt"];
  const [file, setFile] = useState(null);
  const { auth } = useContext(NavContext);
  const { setCurrentRecipeConcs } = useContext(BctContext);
  const [existing, setExisting] = useState(false);
  const [concData, setConcData] = useState([]);
  const toast = useToast();
  const [sm, setSm] = useState(smelter);
  const [hopper, setHopper] = useState(1);
  const [loader, setLoader] = useState(4);
  const [existingData, setExistingData] = useState([]);
  const [bayMapConc, setBayMapConc] = useState([]);

  const fetchExisting = async () => {
    try {
      const param = {
        sm: "SM" + sm,
        hopper: "hopper" + hopper,
      };
      const response = await axios.get(
        baseURL + "vision/v1/workforceMonitoring/blend",
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response?.data?.length != 0) {
        let data = response?.data[0];
        let output = [];
        Object.entries(data?.blend)?.forEach((item) => {
          output.push({
            concentrate: item[0],
            "blend ratio%": item[1],
            "200 mt": ((200 * item[1]) / 100)?.toFixed(2),
          });
        });
        setExistingData(output);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const saveApi = async (val) => {
    try {
      const param = {
        sm: "SM" + sm,
        hopper: "hopper" + val,
        loader_id: "loader-" + loader,
      };
      let dum = {};
      if (existing) {
        existingData.forEach((item) => {
          dum[item.concentrate] = item["blend ratio%"];
        });
      } else {
        concData.forEach((item) => {
          dum[item.conc] = item.ratio;
        });
      }
      const requestData = JSON.stringify(dum);
      const response = await axios.post(
        baseURL + "vision/v1/workforceMonitoring/blend",
        requestData,
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.status == 200) {
        toast({
          title: "Updated",
          description: "Data successfully updated",
          status: "success",
          position: "top-right",
          duration: 2000,
          isClosable: true,
        });
        getRecipeConcs({
          auth: auth,
          setCurrentRecipeConcs: setCurrentRecipeConcs,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBayMapApi = async () => {
    try {
      const response = await axios.get(
        baseURL + "vision/v1/workforceMonitoring/concentrate",
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.data) {
        setBayMapConc((prev) => {
          let newData = new Set();
          let apiData = response.data;
          delete apiData["_id"];
          for (let bay in apiData) {
            for (let section in apiData[bay]) {
              for (let i = 0; i < apiData[bay][section].length; i++) {
                newData.add(apiData[bay][section][i]?.concentrate);
              }
            }
          }
          return Array.from(newData);
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const checkOk = () => {
    let sum = concData?.reduce((a, b) => {
      return a + parseFloat(b.ratio);
    }, 0);
    let existInBayMap = true;
    concData.forEach((item) => {
      if (!bayMapConc.includes(item.conc)) existInBayMap = false;
    });
    if (!existing && (sum != 100 || !existInBayMap)) {
      toast({
        title: "Error",
        description: (
          <div>
            {sum != 100 && <p>The blend ratio don't add upto 100</p>}
            {!existInBayMap && (
              <p>
                The concentrates don't match the bay map provided, Please update
                the map
              </p>
            )}
          </div>
        ),
        status: "error",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    setHopper(mach[sm].hopper[0]);
    setLoader(mach[sm].loader[0]);
    getBayMapApi();
  }, [sm]);

  useEffect(() => {
    fetchExisting();
  }, [sm, hopper]);

  return (
    <Drawer
      onClose={closeModal}
      closeOnOverlayClick={false}
      isOpen={openModal}
      size="lg"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader padding="0px" position={"relative"}>
          <ClearIcon
            style={{ boxShadow: "0px 2px 35px 0px rgba(0, 0, 0, 0.06)" }}
            onClick={closeModal}
            className="absolute top-4 right-4 cursor-pointer hover:scale-105"
          />
        </DrawerHeader>
        <DrawerBody px={"24px"} pb={"24px"} pt={"40px"}>
          <div className="flex flex-col gap-7">
            <p className="text-[#3E3C42] text-lg font-medium">
              {existing ? "Use Existing Recipe" : "Upload Blend Recipe"}
            </p>
            <div className="flex flex-col gap-2">
              <p className="text-[#3E3C42] text-base font-medium">Use</p>
              <RadioGroup
                onChange={(e) => setExisting(e == "true" ? true : false)}
                value={existing}
              >
                <div className="flex gap-1 items-center">
                  {["New blend recipe", "Existing blend recipe"].map(
                    (x, idx) => {
                      return (
                        <div
                          style={{
                            backgroundColor:
                              (idx == 0 && !existing) || (idx == 1 && existing)
                                ? "#DDEEFF80"
                                : "#FFF",
                            borderRadius: "8px",
                          }}
                        >
                          <Radio
                            value={idx == 0 ? false : true}
                            py={"8px"}
                            pl={"8px"}
                            pr={"12px"}
                            fontSize={"14px"}
                            fontWeight={500}
                            color={"#3E3C42"}
                            _checked={{
                              bg: "#6CA6FC",
                              borderColor: "#6CA6FC",
                            }}
                            _hover={{
                              borderColor: "#6CA6FC",
                            }}
                            isDisabled={idx == 1 && existingData.length == 0}
                          >
                            {x}
                          </Radio>
                        </div>
                      );
                    }
                  )}
                </div>
              </RadioGroup>
            </div>
            <div className="flex flex-col gap-4 items-start">
              <p className="text-[#3E3C42] text-base font-medium">
                {existing ? "Use existing recipe for" : "Upload recipe for"}
              </p>
              <div className="flex gap-3 items-center">
                <div className="text-sm font-medium text-[#3E3C42] border border-[#E0E0E0] rounded bg-[#EBEBEB4D] w-20 h-8 text-center content-center">
                  SM {sm}
                </div>
                {mach[sm]?.hopper?.map((x) => {
                  return (
                    <div className="text-sm font-medium text-[#3E3C42] border border-[#E0E0E0] rounded bg-[#EBEBEB4D] w-20 h-8 text-center content-center">
                      Hopper {x}
                    </div>
                  );
                })}
                {/* {mach[sm]?.loader?.map((x) => {
                  return (
                    <div className="text-sm font-medium text-[#3E3C42] border border-[#E0E0E0] rounded bg-[#EBEBEB4D] w-20 h-8 text-center content-center">
                      Loader {x}
                    </div>
                  );
                })} */}
                <Select
                  color={"#3E3C42"}
                  border={"1px solid #E0E0E0"}
                  rounded={4}
                  bg={"#EBEBEB4D"}
                  fontSize={14}
                  fontWeight={500}
                  size={"sm"}
                  width={"fit-content"}
                  onChange={(e) => setLoader(e.target.value)}
                >
                  {mach[sm]?.loader?.map((item) => {
                    return (
                      <option key={item} value={item}>
                        Loader {item}
                      </option>
                    );
                  })}
                </Select>
              </div>
            </div>
            <div className="flex flex-col gap-4 items-center">
              {!existing && (
                <FileUploader
                  file={file}
                  setFile={setFile}
                  setConcData={setConcData}
                />
              )}
              {!file && !existing && (
                <>
                  <p className="text-[#3E3C42] text-base font-medium">or</p>
                  <div className="flex flex-col gap-4 w-full">
                    <div className="w-full flex items-center justify-between">
                      <p className="text-[#3E3C42] text-base font-medium">
                        Add recipe
                      </p>
                      <TextButton
                        text={"Add concentrate"}
                        Icon={<AddIcon />}
                        width={"fit-content"}
                        onClick={() =>
                          setConcData((prev) => [
                            ...prev,
                            { conc: "", ratio: 0, id: generateRandomId() },
                          ])
                        }
                      />
                    </div>
                    <TableContainer width={"full"} overflowX={"auto"}>
                      <Table variant="simple" width={"full"} minWidth={"600px"}>
                        {" "}
                        <Thead bg={"white"} position={"sticky"} top={"0px"}>
                          <Tr>
                            {["Concentrate", "blend ratio%", "200MT"].map(
                              (item, idx) => {
                                return (
                                  <Th
                                    color="#938F96"
                                    fontSize={"14px"}
                                    fontWeight={500}
                                    border={0}
                                    p={0}
                                    className="!capitalize"
                                    textAlign={"center"}
                                    width={"100px"}
                                  >
                                    {item}
                                  </Th>
                                );
                              }
                            )}
                          </Tr>
                        </Thead>
                        <Tbody>
                          {concData.map((item, idx) => {
                            return (
                              <ConcRow
                                data={concData}
                                setData={setConcData}
                                index={idx}
                              />
                            );
                          })}
                        </Tbody>
                      </Table>
                    </TableContainer>
                  </div>
                </>
              )}
            </div>
            {(file || existing) && (
              <div className="flex flex-col gap-3">
                <p className="text-[#3E3C42] text-base font-medium">
                  {existing ? "Existing recipe" : "Your uploaded recipe"}
                </p>
                <TableContainer width={"full"} roundedTop={"8px"}>
                  <Table
                    variant="simple"
                    borderTopRadius={"8px"}
                    width={"full"}
                  >
                    <Thead bg={"#DEF"} position={"sticky"} top={"0px"}>
                      <Tr>
                        {columns.map((item) => {
                          return (
                            <Th
                              color="#79767D"
                              fontSize={"12px"}
                              fontWeight={400}
                              py={"12px"}
                              px={"12px"}
                              textAlign={"center"}
                            >
                              {item.toUpperCase()}
                            </Th>
                          );
                        })}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {existing &&
                        existingData.map((item) => {
                          return (
                            <Tr>
                              {columns.map((x) => {
                                return (
                                  <Td
                                    color={"#3E3C42"}
                                    fontSize={"14px"}
                                    fontWeight={500}
                                    textAlign={"center"}
                                  >
                                    {item[x] + (x == "blend ratio%" ? "%" : "")}
                                  </Td>
                                );
                              })}
                            </Tr>
                          );
                        })}
                      {file &&
                        concData.map((item) => {
                          return (
                            <Tr>
                              <Td
                                color={"#3E3C42"}
                                fontSize={"14px"}
                                fontWeight={500}
                                textAlign={"center"}
                              >
                                {item.conc}
                              </Td>
                              <Td
                                color={"#3E3C42"}
                                fontSize={"14px"}
                                fontWeight={500}
                                textAlign={"center"}
                              >
                                {item.ratio}%
                              </Td>
                              <Td
                                color={"#3E3C42"}
                                fontSize={"14px"}
                                fontWeight={500}
                                textAlign={"center"}
                              >
                                {((200 * item.ratio) / 100)?.toFixed(2)}
                              </Td>
                            </Tr>
                          );
                        })}
                    </Tbody>
                  </Table>
                </TableContainer>
              </div>
            )}
          </div>
        </DrawerBody>
        <DrawerFooter>
          <div className="flex gap-6 items-center self-end">
            <TonalButton
              text={"Discard"}
              width={"fit-content"}
              onClick={closeModal}
            />
            <PrimaryButton
              text={"Save Recipe"}
              width={"fit-content"}
              disable={concData.length == 0 && !existing}
              onClick={() => {
                if (checkOk()) {
                  if (sm == 1) {
                    saveApi(1);
                    // saveApi(2);
                  } else saveApi(3);
                  closeModal();
                }
              }}
            />
          </div>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default RecipeDrawer;
