import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

const FileUploader = ({ setSend, send, text, width, info }) => {
  const selectPicture = (event) => {
    setSend([...send, event.target.files[0]]);
    // console.log("send", send);
  };

  return (
    <>
      <div className="my-3">
        <div
          className={`w-[${width}] border border-dotted border-yellow-500 p-1`}
        >
          <label htmlFor={info} className="cursor-pointer">
            <div className="flex justify-evenly">
              <AddCircleRoundedIcon sx={{color: "#ffc008"}}/>
              <p className="ml-1">{text}</p>
            </div>
          </label>
          <input
            onChange={(e) => selectPicture(e)}
            type="file"
            name={info}
            id={info}
            style={{ display: "none" }}
          />
        </div>
        <div>
          <input
            onChange={(e) => selectPicture(e)}
            type="file"
            name="image"
            id="image"
            style={{ display: "none" }}
          />
        </div>
      </div>
    </>
  );
};

export default FileUploader;
