import { saveAs } from "file-saver";
import Paginator from "../../../util/VisionUtils/Paginator";
import { Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import CompareModal from "./CompareModal";
import { overallPermeabilityIndex } from "../SinterBelt";
import { formatDateTime } from "../utilis/dateTimeFormatter";
import { ZoomedImageModal } from "./ZoomedImageModal";
import { AspectRatio, DownloadForOfflineOutlined } from "@mui/icons-material";
import { useParams } from "react-router-dom";

const LibraryGrid = ({ plantName, img }) => {
  const params = useParams();
  // state
  const [selectedPoints, setSelectedPoints] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openZoomModal, setOpenZoomModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  // image downloading function
  const handleImageDownload = async (index) => {
    try {
      const singleImageData = displayData[index];
      const lastSlashIndex = singleImageData?.original_image?.lastIndexOf("/");
      const imageName = singleImageData?.original_image?.substr(
        lastSlashIndex + 1
      );
      const response = await fetch(singleImageData?.original_image);
      const blob = await response.blob();
      saveAs(blob, `${imageName}.jpg`);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  const handleCheckboxClick = (val) => {
    setSelectedPoints((prev) => {
      let updatedData = [...prev];
      let idx = updatedData.findIndex((item) => item.recordId === val.recordId);

      if (idx !== -1) {
        // If the item is found, remove it from the array
        updatedData.splice(idx, 1);
      } else {
        // If the item is not found, add it to the array
        updatedData.push(val);
      }

      return updatedData;
    });
  };

  const handleClickOnContainer = (event) => {
    const { target } = event;
    if (target.tagName === "INPUT" && target.type === "checkbox") {
      let dataIndex = target?.getAttribute("data-index");
      if (dataIndex && !isNaN(dataIndex)) {
        dataIndex = +dataIndex;
        const item = displayData[dataIndex];
        handleCheckboxClick(item);
      }
      // for checkbox
    } else if (target.tagName === "svg" || target.tagName === "path") {
      if (target?.classList?.contains("download-image")) {
        // to download image
        let dataIndex = target?.getAttribute("data-index");
        if (!isNaN(dataIndex)) {
          dataIndex = Number(dataIndex);
          handleImageDownload(dataIndex);
        }
      } else if (target?.classList?.contains("preview-image")) {
        const dataIndex = target?.getAttribute("data-index");
        if (dataIndex) {
          setSelectedImageIndex(
            (prev) => isNaN(dataIndex) === false && Number(dataIndex)
          );
          setOpenZoomModal((prev) => true);
        }
      }
    }
  };

  useEffect(() => {
    if (img.length === 0) setDisplayData([]);
  }, [img]);

  return (
    <div className="flex flex-col gap-3 pb-4">
      <div className="flex justify-between items-baseline">
        <div className="flex gap-2 items-baseline">
          <p className="text-[#3E3C42] font-medium text-xl capitalize">
            {plantName}
          </p>
        </div>
        <div className="flex flex-col sm:flex-row gap-1 items-center">
          <Button
            onClick={() => setOpenModal(true)}
            isDisabled={selectedPoints.length < 2}
            size="sm"
            colorScheme="facebook"
            rounded={"base"}
          >
            {`Compare (${selectedPoints.length}/4)`}
          </Button>
          {img.length != 0 && (
            <Paginator
              data={img}
              limit={18}
              setDisplayData={setDisplayData}
            />
          )}
        </div>
      </div>

      <div
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-1 overflow-y-scroll max-h-[80vh]"
        onClick={handleClickOnContainer}
      >
        {displayData?.map((element, index) => {
          return (
            <div
              key={`image-${index}`}
              className="relative w-full text-center max-h-[200px] group"
            >
              {/* Original Image */}
              <img
                className="rounded-lg w-full h-[200px]"
                src={element.original_image}
                alt="img"
                recordid={element.recordId}
                // onClick={() => handleImageClick(x?.original_image)}
              />

              {/* content to show over image */}
              {/* top bar */}
              {element?.board && (
                <div className="absolute flex flex-row gap-1 top-1 px-2 left-0 items-center hover:">
                  <AspectRatio
                    className="preview-image text-white hover:cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
                    data-index={index}
                    fontSize={"medium"}
                  />
                  <p className="text-white text-xs px-1 py-[1px] font-semibold bg-black rounded-md opacity-75">
                    {element?.sequenceNo}. {element?.board}
                  </p>
                </div>
              )}
              <div className="flex flex-col  absolute top-1 right-0 gap-2 items-end px-2">
                <div className="flex flex-row gap-2 items-center">
                  <p className="text-white text-xs p-[2px] font-semibold bg-black rounded-md">
                    {element?.time_stamp && formatDateTime(element?.time_stamp)}
                  </p>
                  <input
                    type="checkbox"
                    checked={selectedPoints.some(
                      (item) => item.recordId === element.recordId
                    )}
                    disabled={
                      !selectedPoints.some(
                        (item) => item.recordId === element.recordId
                      ) && selectedPoints.length === 4
                    }
                    readOnly={true}
                    data-index={index}
                    className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 accent-blue-500 rounded focus:ring-blue-500 hover:cursor-pointer"
                  />
                </div>
                <DownloadForOfflineOutlined
                  download-image-src={element.original_image}
                  data-index={index}
                  className="download-image text-gray-400 rounded-full bg-white hover:cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
                />
              </div>
              {/* Bottom bar */}
              <div className="flex flex-col items-end gap-2 absolute bottom-1 right-2 opacity-50 hover:opacity-90">
                {/* checks to only render permeability index if it's value is not null */}
                {element?.board_permeability_index &&
                  element?.value !== null && (
                    <p className="text-white text-xs p-[2px] font-semibold bg-black rounded-md">
                      {`${
                        overallPermeabilityIndex[
                          element?.board_permeability_index
                        ]
                      }(${element?.value}%)`}
                    </p>
                  )}
                {element?.maxVNotchWidth !== 0 &&
                  element?.maxVNotchWidth !== null && (
                    <p className="text-white text-xs p-[2px] font-semibold bg-black rounded-md">
                      {`V Notch ${element?.maxVNotchWidth}px`}
                    </p>
                  )}
                {element?.maxGrateBarMissingWidth !== 0 &&
                  element?.maxGrateBarMissingWidth !== null && (
                    <p className="text-white text-xs p-[2px] font-semibold bg-black rounded-md">
                      {`Grate Bar Missing ${element?.maxGrateBarMissingWidth}px`}
                    </p>
                  )}
              </div>
            </div>
          );
        })}
      </div>

      {openModal && (
        <CompareModal
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          data={selectedPoints}
          setData={setSelectedPoints}
          clientId={params?.clientId || "hindustanzinc"}
          plantName={plantName}
        />
      )}
      {openZoomModal && selectedImageIndex !== null && (
        <ZoomedImageModal
          imageIndex={selectedImageIndex}
          openModal={openZoomModal}
          data={displayData}
          closeModal={() => {
            setOpenZoomModal((prev) => false);
            setSelectedImageIndex(null);
          }}
        />
      )}
    </div>
  );
};

export default LibraryGrid;
