import { useEffect, useState, useContext, type, useRef } from "react";
import NavContext from "../../NavContext";
import { baseURL } from "../../../index";
import { useParams } from "react-router-dom";
import {
  Box,
  Select,
  Skeleton,
  SkeletonText,
  Spinner,
  Stack,
} from "@chakra-ui/react";
import axios from "axios";
import LiquidGauge from "../../Charts/SizingCharts/LiquidGauge";
import { useWindowSize } from "@uidotdev/usehooks";
import { capitalizeFirstLetter } from "../../../util/sentenceCase";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import CompareBarChart from "../FeedComponent/CompareBarChart";
import Barchartloader from "../CommonTable/Barchartloader";
import CommonTable from "../CommonTable/CommonTable";
import TableSkeleton from "../CommonTable/TableSkeleton";
import DetailModal from "../FeedComponent/DetailModal";

const analysisType = {
  0: "Cold spot analysis",
  1: "Distance analysis",
};

const Analytics = ({ plantId, cameraId, disable, plantCamMap, material }) => {
  let param = useParams();
  const { auth } = useContext(NavContext);
  const size = useWindowSize();
  const [filterData, setFilterData] = useState({ items: [] });
  const [sizeData, setSizeData] = useState([]);
  const [sizeDataChanging, setSizeDataChanging] = useState(false);
  const [selectedBasis, setSelectedBasis] = useState(0);
  const typeRef = useRef();
  const [selectedRange, setSelectedRange] = useState(0);
  const [selectedPlant, setSelectedPlant] = useState(
    disable ? plantId : "All Plants"
  );
  const [camMap, setCamMap] = useState({
    cameraKeys: null,
    cameraMapping: null,
  });
  const [tableData, setTableData] = useState({});
  const [showType, setShowType] = useState(0);
  const [selectedCategoryBar, setSelectedCategoryBar] = useState();
  const [displayData, setDisplayData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const indexRef = useRef();
  const [selectedCam, setSelectedCam] = useState(cameraId);
  const [fromTime, setFromTime] = useState(
    new Date(new Date().getTime() - 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16)
  );
  const [toTime, setToTime] = useState(
    new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16)
  );

  const handleSelect = (e) => {
    let val = e.target.value;
    setShowType(val);
    if (val == 0) {
      setFromTime(
        new Date(
          new Date().getTime() + 5.5 * 60 * 60 * 1000 - 1 * 60 * 60 * 1000
        )
          .toISOString()
          .slice(0, 16)
      );
      setToTime(
        new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 16)
      );
    }
  };

  const response = {
    data: {
      spots: {
        series: [
          {
            name: "Cold spots",
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
          },
          {
            name: "Hot spots",
            data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
          },
        ],
        categories: [
          "11526",
          "11527",
          "11528",
          "11529",
          "11530",
          "11531",
          "11532",
          "11533",
          "11534",
        ],
      },
      distance: {
        series: [
          {
            name: "Distance",
            data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
          },
        ],
        categories: [
          "11526",
          "11527",
          "11528",
          "11529",
          "11530",
          "11531",
          "11532",
          "11533",
          "11534",
        ],
      },

      tableData: {
        order: [
          "serialNo",

          "averageFlameDistance",
          "areaColdspots",
          "areaHotspots",
          "lastAnalysisTime",
          "alert (Y/N)",
        ],
        data: [
          {
            serialNo: 50009,

            averageFlameDistance: "12 px",
            id: 0,
            lastAnalysisTime: 1717604736,
            averageFlameDistance: "8px",
            areaColdspots: "12%",
            areaHotspots: "3%",
            camID1: "/JSWSalem/annoted.jpg",
            camID2: "/JSWSalem/real.jpg",
            cameraKeys: ["camID1", "camID2"],
            "alert (Y/N)": "Yes",
          },
          {
            serialNo: 30154,

            averageFlameDistance: "6 px",
            id: 1,
            lastAnalysisTime: 1717604736,
            averageFlameDistance: "8px",
            areaColdspots: "12%",
            areaHotspots: "3%",
            camID1: "/JSWSalem/annoted.jpg",
            camID2: "/JSWSalem/real.jpg",
            cameraKeys: ["camID1", "camID2"],
            "alert (Y/N)": "No",
          },
          {
            serialNo: 89746,

            averageFlameDistance: "2 px",
            id: 2,
            lastAnalysisTime: 1717604736,
            averageFlameDistance: "8px",
            areaColdspots: "12%",
            areaHotspots: "3%",
            camID1: "/JSWSalem/annoted.jpg",
            camID2: "/JSWSalem/real.jpg",
            cameraKeys: ["camID1", "camID2"],
            "alert (Y/N)": "No",
          },
          {
            serialNo: 84939,

            averageFlameDistance: "12 px",
            id: 3,
            lastAnalysisTime: 1717604736,
            averageFlameDistance: "8px",
            areaColdspots: "12%",
            areaHotspots: "3%",
            camID1: "/JSWSalem/annoted.jpg",
            camID2: "/JSWSalem/real.jpg",
            cameraKeys: ["camID1", "camID2"],
            "alert (Y/N)": "No",
          },
          {
            serialNo: 65915,

            averageFlameDistance: "12 px",
            id: 4,
            lastAnalysisTime: 1717604736,
            averageFlameDistance: "8px",
            areaColdspots: "12%",
            areaHotspots: "3%",
            camID1: "/JSWSalem/annoted.jpg",
            camID2: "/JSWSalem/real.jpg",
            cameraKeys: ["camID1", "camID2"],
            "alert (Y/N)": "Yes",
          },
          {
            serialNo: 47550,

            averageFlameDistance: "6 px",
            id: 5,
            lastAnalysisTime: 1717604736,
            averageFlameDistance: "8px",
            areaColdspots: "12%",
            areaHotspots: "3%",
            camID1: "/JSWSalem/annoted.jpg",
            camID2: "/JSWSalem/real.jpg",
            cameraKeys: ["camID1", "camID2"],
            "alert (Y/N)": "Yes",
          },
          {
            serialNo: 70737,

            averageFlameDistance: "18 px",
            id: 6,
            lastAnalysisTime: 1717604736,
            averageFlameDistance: "8px",
            areaColdspots: "12%",
            areaHotspots: "3%",
            camID1: "/JSWSalem/annoted.jpg",
            camID2: "/JSWSalem/real.jpg",
            cameraKeys: ["camID1", "camID2"],
            "alert (Y/N)": "No",
          },
          {
            serialNo: 28336,

            averageFlameDistance: "6 px",
            id: 7,
            lastAnalysisTime: 1717604736,
            averageFlameDistance: "8px",
            areaColdspots: "12%",
            areaHotspots: "3%",
            camID1: "/JSWSalem/annoted.jpg",
            camID2: "/JSWSalem/real.jpg",
            cameraKeys: ["camID1", "camID2"],
            "alert (Y/N)": "No",
          },
          {
            serialNo: 77387,

            averageFlameDistance: "12 px",
            id: 8,
            lastAnalysisTime: 1717604736,
            averageFlameDistance: "8px",
            areaColdspots: "12%",
            areaHotspots: "3%",
            camID1: "/JSWSalem/annoted.jpg",
            camID2: "/JSWSalem/real.jpg",
            cameraKeys: ["camID1", "camID2"],
            "alert (Y/N)": "No",
          },
          {
            serialNo: 49374,

            averageFlameDistance: "6 px",
            id: 9,
            lastAnalysisTime: 1717604736,
            averageFlameDistance: "8px",
            areaColdspots: "12%",
            areaHotspots: "3%",
            camID1: "/JSWSalem/annoted.jpg",
            camID2: "/JSWSalem/real.jpg",
            cameraKeys: ["camID1", "camID2"],
            "alert (Y/N)": "No",
          },
          {
            serialNo: 83349,

            averageFlameDistance: "12 px",
            id: 10,
            lastAnalysisTime: 1717604736,
            averageFlameDistance: "8px",
            areaColdspots: "12%",
            areaHotspots: "3%",
            camID1: "/JSWSalem/annoted.jpg",
            camID2: "/JSWSalem/real.jpg",
            cameraKeys: ["camID1", "camID2"],
            "alert (Y/N)": "No",
          },
          {
            serialNo: 39512,

            averageFlameDistance: "2 px",
            id: 11,
            lastAnalysisTime: 1717604736,
            averageFlameDistance: "8px",
            areaColdspots: "12%",
            areaHotspots: "3%",
            camID1: "/JSWSalem/annoted.jpg",
            camID2: "/JSWSalem/real.jpg",
            cameraKeys: ["camID1", "camID2"],
            "alert (Y/N)": "No",
          },
          {
            serialNo: 92074,

            averageFlameDistance: "18 px",
            id: 12,
            lastAnalysisTime: 1717604736,
            averageFlameDistance: "8px",
            areaColdspots: "12%",
            areaHotspots: "3%",
            camID1: "/JSWSalem/annoted.jpg",
            camID2: "/JSWSalem/real.jpg",
            cameraKeys: ["camID1", "camID2"],
            "alert (Y/N)": "No",
          },
          {
            serialNo: 34352,

            averageFlameDistance: "18 px",
            id: 13,
            lastAnalysisTime: 1717604736,
            averageFlameDistance: "8px",
            areaColdspots: "12%",
            areaHotspots: "3%",
            camID1: "/JSWSalem/annoted.jpg",
            camID2: "/JSWSalem/real.jpg",
            cameraKeys: ["camID1", "camID2"],
            "alert (Y/N)": "No",
          },
          {
            serialNo: 68269,

            averageFlameDistance: "8 px",
            id: 14,
            lastAnalysisTime: 1717604736,
            averageFlameDistance: "8px",
            areaColdspots: "12%",
            areaHotspots: "3%",
            camID1: "/JSWSalem/annoted.jpg",
            camID2: "/JSWSalem/real.jpg",
            cameraKeys: ["camID1", "camID2"],
            "alert (Y/N)": "No",
          },
        ],
      },
    },
  };

  const apiCall = async () => {
    try {
      const requestData = JSON.stringify({
        clientId: param?.clientId?.toLowerCase(),
        useCase: material,
        cameraId: "cam101",
        plantName: "salem",
        startDate: new Date(fromTime).getTime(),
        endDate: new Date(toTime).getTime(),
      });
      const response = await axios.post(
        baseURL + "vision/v2/processMonitoring/analytics/history/",
        requestData,
        {
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      setSizeData(response.data);
      setTableData(
        response.data?.tableData
          ? response.data?.tableData
          : { order: [], data: [] }
      );
      setCamMap((prev) => ({
        ...prev,
        cameraKeys: response?.data?.tableData?.cameraKeys,
        cameraMapping: response?.data?.tableData?.cameraMapping,
      }));
    } catch (error) {
      console.log(error);

      setTableData({ order: [], data: [] });
      setSizeData([]);
    } finally {
      setSizeDataChanging(false);
    }
    // setSizeData(response.data);
  };

  const handleClick = () => {
    setSizeDataChanging(true);
    apiCall();
  };

  const handleGetRowClassName = (params) => {
    const { row } = params;
    if (row?.["alert (Y/N)"] === "Yes") {
      return "bg-[#FCEEEE]";
    }
  };

  useEffect(() => {
    typeRef.current = "SIZE";
    // checkApi();
    setSelectedBasis(0);
    setSizeDataChanging(true);
    apiCall();
  }, []);

  useEffect(() => {
    if (selectedBasis == 0) {
      setSelectedCategoryBar(sizeData?.spots);
    }
    if (selectedBasis == 1) {
      setSelectedCategoryBar(sizeData?.distance);
    }
  }, [selectedBasis, sizeData]);

  useEffect(() => {
    if (!disable && selectedPlant != "All Plants") {
      setSelectedCam("All Cams");
    }
  }, [selectedPlant]);

  return (
    <div className="flex flex-col gap-4  pb-0 mb-3 md:mb-0">
      <div className="flex flex-col p-6 pt-3 bg-white rounded-xl gap-4  pb-3">
        <div className="flex flex-col gap-0 p-0 ">
          <div
            className={`flex flex-col xs:flex-row xs:items-center  lg:justify-between overflow-x-auto h-full py-2 w-full gap-3 `}
          >
            <div className="text-md font-semibold whitespace-nowrap">
              History data
            </div>
            <div className="flex justify-end items-center gap-2 w-fit ">
              <div className="min-w-[160px]">
                <Select
                  borderColor="#CAC5CD"
                  color="#605D64"
                  variant="outline"
                  rounded={"base"}
                  onChange={(e) => handleSelect(e)}
                  value={showType}
                >
                  <option value={0}>Past 24 hours</option>

                  <option value={1}>Custom</option>
                </Select>
              </div>
              {showType == 1 && (
                <div className="flex gap-2 min-w-[160px]">
                  <FloatingInput
                    text="From"
                    type="datetime-local"
                    setDateTime={setFromTime}
                    value={fromTime}
                    max={toTime}
                  />
                </div>
              )}
              {showType == 1 && (
                <div className="flex gap-2 min-w-[160px] ">
                  <FloatingInput
                    text="To"
                    type="datetime-local"
                    setDateTime={setToTime}
                    value={toTime}
                    min={fromTime}
                    max={new Date().toISOString().slice(0, 16)}
                  />
                </div>
              )}

              <SecondaryButton
                width={"90px"}
                height={"40px"}
                text={sizeDataChanging ? <Spinner /> : "Apply"}
                onClick={handleClick}
                disable={!fromTime || !toTime}
              />
            </div>
          </div>
          <div
            className={`flex justify-between gap-2 items-center overflow-x-auto h-[10vh]  w-full`}
          >
            <div className="flex gap-2 text-[#49454F] text-xs lg:text-base w-[430px] ">
              <div
                className={`px-3  min-w-[200px] flex items-center gap-2 ${
                  selectedBasis == 0 ? "bg-[#e7effb] rounded-xl" : "bg-white"
                }`}
              >
                <input
                  value={0}
                  checked={selectedBasis == 0}
                  onChange={(e) => setSelectedBasis(e.target.value)}
                  type="radio"
                  name="freq"
                  className="cursor-pointer accent-[#3A74CA] h-[18px] w-[18px]"
                />
                <p>Cold spot analysis</p>
              </div>
              <div
                className={`p-3 flex items-center min-w-[200px]  gap-2 ${
                  selectedBasis == 1 ? "bg-[#e7effb] rounded-xl" : "bg-white"
                }`}
              >
                <input
                  value={1}
                  checked={selectedBasis == 1}
                  onChange={(e) => setSelectedBasis(e.target.value)}
                  type="radio"
                  name="freq"
                  className="cursor-pointer accent-[#3A74CA] h-[18px] w-[18px]"
                />
                <p>Distance analysis</p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap-1 sm:gap-[40px] items-center  h-full overflow-x-auto">
          <div className=" h-full  min-w-[500px] flex-grow  ">
            {sizeDataChanging ? (
              <Barchartloader />
            ) : selectedCategoryBar?.series?.length > 0 &&
              selectedCategoryBar?.categories?.length > 0 ? (
              <CompareBarChart
                series={selectedCategoryBar?.series}
                categories={selectedCategoryBar?.categories}
                setFilterData={setFilterData}
              />
            ) : (
              <div className="w-full h-[300px] flex justify-center items-center border rounded-md">
                <p className=" font-semibold">No Data</p>
              </div>
            )}
          </div>
        </div>
        <div className="w-full">
          {!sizeDataChanging ? (
            <CommonTable
              rowData={tableData?.data || []}
              order={tableData?.order || []}
              getRowClassName={handleGetRowClassName}
              showViewDetail={true}
              setDisplayData={setDisplayData}
              setOpenModal={setOpenModal}
              indexRef={indexRef}
              tableHeight={size.width <= 1024 ? "400px" : "550px"}
              pagesize={50}
              rowHeight={45}
              filterData={filterData}
              setFilterModel={setFilterData}
            />
          ) : (
            <TableSkeleton
              rows={7}
              cellsPerRow={7}
              headers={["1", "2", "3", "4", "5", "6", "7"]}
            />
          )}
        </div>
      </div>
      {openModal && (
        <DetailModal
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          data={displayData}
          index={indexRef.current}
          PlantName={selectedPlant}
          camMap={camMap}
        />
      )}
    </div>
  );
};

export default Analytics;
