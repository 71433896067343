import { useContext, useEffect, useRef, useState } from "react";
import { useGridApiRef } from "@mui/x-data-grid";
import { Select, Spinner, useToast } from "@chakra-ui/react";
import axios from "axios";
import CrusherEffectivenessTable from "./components/CrusherEffectivenessTable";
import CrusherCombinationTable from "./components/CrusherCombinationTable";
import { CrusherLineChart } from "./charts/CrusherLineChart";
import { capitalizeFirstLetter } from "../../util/sentenceCase";
import CrusherEffectivenessSubmitModal from "./components/CrusherEffectivenessModal";
import CrusherTabLockModal from "./components/CrusherTabLockModal";
import {
  checkDuplicateEntry,
  flattenKeys,
  transformChartData,
} from "./utils/util";
import NavContext from "../NavContext";
import { baseURL } from "../..";
import ExlCsvDownload from "../../util/VisionUtils/ExlCsvDownload";
import FloatingInput from "../../util/VisionUtils/FloatingInput";
import PrimaryButton from "../../util/Buttons/PrimaryButton";
import SecondaryButton from "../../util/Buttons/SecondaryButton";

const order = [
  "belt",
  "timestamp",
  "shift",
  "crusherSet1",
  "directionSet1",
  "crusherSet2",
  "directionSet2",
];

const CrusherEffectiveness = ({
  material,
  clientId,
  handlePageChange,
  page,
}) => {
  const { auth } = useContext(NavContext);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [isOpenModal, setOpenModal] = useState(false);
  const [isOpenTabLockModal, setOpenTabLockModal] = useState({
    open: false,
    verified: false,
  });
  const [selectedRange, setSelectedRange] = useState({
    input: 0,
    output: 0,
  });
  const [validationMessage, setValidationMessage] = useState({
    message: "",
    shift: "",
    gapAndNone: "",
  });
  const toast = useToast();
  const [loading, setLoading] = useState({
    input: false,
    output: false,
  });
  const gridApiRef = useGridApiRef();
  const unsavedChangesRef = useRef({
    unsavedRow: {},
    oldRow: {},
  });
  const [valueOptionsMapping, setValueOptionsMapping] = useState({
    optionsMapping: {},
    combinationMapping: {},
  });
  const [fromTime, setFromTime] = useState(
    new Date(
      new Date().getTime() -
        6 * 24 * 60 * 60 * 1000 -
        new Date().getTimezoneOffset() * 60 * 1000
    )
      .toISOString()
      .slice(0, 10)
  );
  const [toTime, setToTime] = useState(
    new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
      .toISOString()
      .slice(0, 10)
  );

  const [outPutFromTime, setOutputFromTime] = useState(
    new Date(
      new Date().getTime() -
        6 * 24 * 60 * 60 * 1000 -
        new Date().getTimezoneOffset() * 60 * 1000
    )
      .toISOString()
      .slice(0, 10)
  );
  const [outPutToTime, setOutPutToTime] = useState(
    new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
      .toISOString()
      .slice(0, 10)
  );

  const [rowData, setRowData] = useState({
    data: [],
    sizes: [],
    downloadData: [],
  });

  const [rowIdmapping, setRowIdMapping] = useState({});

  const [combinationResult, setCombinationResult] = useState({});
  const [chartData, setChartData] = useState({});
  const shifts = [
    {
      shift: "Shift A",
      time: "6 am - 2 pm",
    },
    {
      shift: "Shift B",
      time: "2 pm - 10 pm",
    },
    {
      shift: "Shift C",
      time: "10 pm - 6 am",
    },
  ];

  const updateData = async (data, isDeleted) => {
    try {
      const mappedData = data?.map((item) => {
        return {
          ...item,
          isDeleted: isDeleted,
        };
      });
      const payload = JSON.stringify(mappedData);
      const response = await axios.post(
        baseURL + "vision/v2/sizing/update/crusher/",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response?.data) {
        fetchData();
        fetchCombinationResults();
        fetchChartData();
        toast({
          description: response?.data?.message,
          status: "success",
          duration: 2000,
          position: "top",
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const postdata = async (data, auth, clientId, material, isDeleted) => {
    try {
      const filteredData = data
        .filter((item) => item?.isNew === true)
        .map(({ _id, isNew, ...rest }) => ({
          ...rest,
          isNew: false, // Or remove the isNew key entirely if desired
        }));
      const generateKeyCombination = (item) => {
        const keyCombination = [
          valueOptionsMapping?.combinationMapping?.[item?.crusherSet1],
          valueOptionsMapping?.combinationMapping?.[item?.directionSet1],
          item?.gapSet1,
          valueOptionsMapping?.combinationMapping?.[item?.crusherSet2],
          valueOptionsMapping?.combinationMapping?.[item?.directionSet2],
          item?.gapSet2,
        ].join("-");
        return keyCombination;
      };
      const mappedData = filteredData?.map((item) => {
        const timestamp = new Date(
          new Date(item?.timestamp).getTime()
        ).setHours(0, 0, 0, 0);
        let startDate;
        let endDate;
        if (item?.shift === "shiftA") {
          startDate = new Date(
            new Date(timestamp).setHours(6, 0, 0, 0)
          ).getTime();
          endDate = new Date(
            new Date(timestamp).setHours(13, 59, 59)
          ).getTime();
        } else if (item?.shift === "shiftB") {
          startDate = new Date(
            new Date(timestamp).setHours(14, 0, 0, 0)
          ).getTime();
          endDate = new Date(
            new Date(timestamp).setHours(21, 59, 59)
          ).getTime();
        } else {
          startDate = new Date(
            new Date(timestamp).setHours(22, 0, 0, 0)
          ).getTime();
          endDate =
            new Date(new Date(timestamp).setHours(5, 59, 59)).getTime() +
            1 * 24 * 60 * 60 * 1000;
        }

        return {
          ...item,
          clientId,
          material,
          isDeleted: isDeleted,
          timestamp: timestamp,
          createdAt: timestamp,
          startDate,
          endDate,
          combination: generateKeyCombination(item),
        };
      });
      const payload = JSON.stringify(mappedData);
      const response = await axios.post(
        baseURL + "vision/v2/sizing/insert/crusher/",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response?.data?.data) {
        if (!isDeleted) {
          fetchData();
          fetchCombinationResults();
          fetchChartData();
        }
        toast({
          description: "Data updated successfully",
          status: "success",
          duration: 2000,
          position: "top",
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      setLoading((prev) => ({
        ...prev,
        input: true,
      }));
      const requestData = JSON.stringify({
        clientId: clientId,
        material: material,
        startDate: new Date(new Date(fromTime).getTime()).setHours(0, 0, 0, 0),
        endDate: new Date(new Date(toTime).getTime()).setHours(23, 59, 59, 999),
      });
      const response = await axios.post(
        baseURL + "vision/v2/sizing/report/crusher/",
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );

      if (response?.status === 200) {
        const rowData = response?.data?.data || [];
        const sizes = response?.data?.crusherOrder || [];
        const cameraOptions = response?.data?.crusherMapping?.cameraId || [];
        // flatten the keys and add belt key for downloading
        const downloadData =
          rowData?.map((item) => flattenKeys(item, cameraOptions)) || [];

        setRowData((prev) => ({
          ...prev,
          data: rowData,
          sizes: sizes,
          downloadData: downloadData,
        }));

        setRowIdMapping({});

        setValueOptionsMapping((prev) => ({
          ...prev,
          optionsMapping: response?.data?.crusherMapping,
          combinationMapping: response?.data?.crusherMap,
        }));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prev) => ({
        ...prev,
        input: false,
      }));
    }
  };

  const fetchCombinationResults = async () => {
    try {
      setLoading((prev) => ({
        ...prev,
        output: true,
      }));
      const requestData = JSON.stringify({
        clientId: clientId,
        material: material,
        startDate: new Date(new Date(outPutFromTime).getTime()).setHours(
          0,
          0,
          0,
          0
        ),
        endDate: new Date(new Date(outPutToTime).getTime()).setHours(
          23,
          59,
          59,
          999
        ),
      });

      const response = await axios.post(
        baseURL + "vision/v2/sizing/report/crusher/overview",
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response?.data?.data) {
        // modify the belt key to show belt instead of cam
        const modifiedData = response?.data?.data?.map((item) => {
          const beltKey = response?.data?.cameraMap?.find(
            (cam) => cam?.value === item?.belt
          );

          return {
            ...item,
            belt: beltKey?.name,
            previousBelt: beltKey?.prevbelt,
          };
        });

        setCombinationResult({
          data: modifiedData,
          order: response?.data?.order,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prev) => ({
        ...prev,
        output: false,
      }));
    }
  };

  const fetchChartData = async () => {
    try {
      setLoading((prev) => ({
        ...prev,
        output: true,
      }));
      const requestData = JSON.stringify({
        clientId: clientId,
        material: material,
        startDate: new Date(new Date(outPutFromTime).getTime()).setHours(
          0,
          0,
          0,
          0
        ),
        endDate: new Date(new Date(outPutToTime).getTime()).setHours(
          23,
          59,
          59,
          999
        ),
      });
      const response = await axios.post(
        baseURL + "vision/v2/sizing/report/crusher/analysis",
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );

      if (response?.status === 200) {
        const result = transformChartData(response?.data);
        setChartData(result);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading((prev) => ({
        ...prev,
        output: false,
      }));
    }
  };

  const handleInputClick = () => {
    fetchData();
  };

  const handleOutputClick = () => {
    fetchCombinationResults();
    fetchChartData();
  };

  const handleSubmitButton = () => {
    setIsInEditMode((prev) => !prev); //change edit mode by clicking on edit button
  };

  const handleSubmit = () => {
    let allrows = Array?.from(gridApiRef?.current?.getRowModels()?.values());
    const isDuplicate = checkDuplicateEntry(allrows);
    if (
      validationMessage?.message ||
      validationMessage?.shift ||
      validationMessage?.gapAndNone ||
      isDuplicate
    ) {
      if (validationMessage?.message) {
        toast({
          description: validationMessage?.message,
          status: "error",
          duration: 5000,
          position: "top",
          isClosable: true,
        });
      }
      if (validationMessage?.shift) {
        toast({
          description: validationMessage?.shift,
          status: "error",
          duration: 5000,
          position: "top",
          isClosable: true,
        });
      }

      if (validationMessage?.gapAndNone) {
        toast({
          description: validationMessage?.gapAndNone,
          status: "error",
          duration: 5000,
          position: "top",
          isClosable: true,
        });
      }
      if (isDuplicate) {
        toast({
          description:
            "Check 'Belt', 'Date' & 'Shift' values input. These 3 already exist in 1 row",
          status: "error",
          duration: 5000,
          position: "top",
          isClosable: true,
        });
      }
      return;
    }

    let isWrongCrusherSelected = false;
    // check whether selected crusher is in the camera options or not
    for (let el of allrows) {
      if (
        !valueOptionsMapping?.optionsMapping?.[el?.camera]?.includes(
          el.crusherSet1
        )
      ) {
        isWrongCrusherSelected = true;
        break;
      }
      if (
        !valueOptionsMapping?.optionsMapping?.[el?.camera]?.includes(
          el.crusherSet2
        )
      ) {
        isWrongCrusherSelected = true;
        break;
      }
    }

    if (isWrongCrusherSelected) {
      toast({
        description: "Wrong crusher has been selected",
        status: "error",
        duration: 5000,
        position: "top",
        isClosable: true,
      });

      return;
    }
    postdata(allrows, auth, clientId, material, false);

    const allRowIds = gridApiRef?.current?.getAllRowIds();
    allRowIds?.forEach((rowId) => {
      const mode = gridApiRef?.current?.getRowMode(rowId);
      if (mode === "edit") gridApiRef?.current?.stopRowEditMode({ id: rowId });
    });

    setOpenModal(false);
  };

  const handleInputRangeSelect = (e) => {
    setSelectedRange((prev) => ({
      ...prev,
      input: e.target.value,
    }));
    if (e.target.value == 0) {
      setFromTime(
        new Date(
          new Date().getTime() -
            6 * 24 * 60 * 60 * 1000 -
            new Date().getTimezoneOffset() * 60 * 1000
        )
          .toISOString()
          .slice(0, 10)
      );
      setToTime(
        new Date(
          new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000
        )
          .toISOString()
          .slice(0, 10)
      );
    }
  };

  const handleOutputRangeSelect = (e) => {
    setSelectedRange((prev) => ({
      ...prev,
      output: e.target.value,
    }));
    if (e.target.value == 0) {
      setOutputFromTime(
        new Date(
          new Date().getTime() -
            6 * 24 * 60 * 60 * 1000 -
            new Date().getTimezoneOffset() * 60 * 1000
        )
          .toISOString()
          .slice(0, 10)
      );
      setOutPutToTime(
        new Date(
          new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000
        )
          .toISOString()
          .slice(0, 10)
      );
    }
  };

  useEffect(() => {
    if (isOpenTabLockModal?.verified) {
      fetchData(toTime);
      fetchCombinationResults();
      fetchChartData();
    }
  }, [isOpenTabLockModal?.verified]);

  useEffect(() => {
    if (page === 5 && !isOpenTabLockModal?.verified) {
      setOpenTabLockModal((prev) => ({
        ...prev,
        open: true,
      }));
    }
  }, [page]);

  return (
    <div className="flex flex-col gap-4 justify-start">
      <div className="flex flex-col gap-4 bg-white rounded-xl p-4">
        <div className="flex items-center gap-2 overflow-x-auto">
          <div className=" bg-[#FFFFED] rounded px-3 py-1 border border-[#CAC5CD]">
            <p className="text-[#3E3C42] text-xs font-bold">Note</p>
            <p className="whitespace-nowrap text-[#3E3C42] text-xs font-normal">
              Select only those shifts which have 100% coverage of a certain
              crusher setting
            </p>
          </div>
          <div className="flex gap-2 items-center">
            {shifts?.map((item) => (
              <div className="text-xs px-3 py-1 rounded bg-[#F5F5F5] border border-[#CAC5CD] whitespace-nowrap">
                <p className="font-bold">{item?.shift}</p>
                <p className="font-normal">{item?.time}</p>
              </div>
            ))}
          </div>
        </div>
        <p className="text-base font-semibold text-[#3E3C42]">
          Crusher effectiveness input table
        </p>
        <div className="flex items-center justify-between flex-row gap-3">
          <div className="flex items-center gap-2 overflow-x-auto py-2">
            <div className="min-w-[110px]">
              <Select
                borderColor="#CAC5CD"
                color="#605D64"
                value={selectedRange?.input}
                variant="outline"
                rounded={"base"}
                className="!text-sm !font-medium !text-[#605D64]"
                onChange={(e) => handleInputRangeSelect(e)}
              >
                <option key="Last 7 days" value={0}>
                  Last 7 days
                </option>
                <option key="custom" value={1}>
                  Custom
                </option>
              </Select>
            </div>
            {selectedRange?.input == 1 && (
              <div className="min-w-[110px]">
                <FloatingInput
                  text="From"
                  type="date"
                  setDateTime={setFromTime}
                  value={fromTime}
                  max={toTime}
                />
              </div>
            )}
            {selectedRange?.input == 1 && (
              <div className="min-w-[110px]">
                <FloatingInput
                  text="To"
                  type="date"
                  setDateTime={setToTime}
                  value={toTime}
                  min={fromTime}
                  max={new Date().toISOString().slice(0, 10)}
                />
              </div>
            )}
            <SecondaryButton
              width={"80px"}
              height={"40px"}
              text={loading?.input ? <Spinner /> : "Apply"}
              onClick={handleInputClick}
              disable={!fromTime || !toTime}
            />
          </div>
          <div className="flex gap-4 justify-end h-fit">
            <PrimaryButton
              text={"Submit"}
              width={"fit-content"}
              onClick={async () => {
                handleSubmitButton();
                setOpenModal(true);
              }}
              disable={!isInEditMode}
            />
            {rowData &&
              rowData?.downloadData &&
              Object?.keys(rowData?.downloadData) &&
              Object?.keys(rowData?.downloadData)?.length > 0 && (
                <ExlCsvDownload
                  order={[...order, ...rowData?.sizes]}
                  data={rowData?.downloadData || []}
                />
              )}
          </div>
        </div>
        <CrusherEffectivenessTable
          isInEditMode={isInEditMode}
          rowData={rowData?.data}
          sizes={rowData?.sizes}
          gridApiRef={gridApiRef}
          unsavedChangesRef={unsavedChangesRef}
          setValidationMessage={setValidationMessage}
          valueOptionsMapping={valueOptionsMapping}
          updateData={updateData}
          setIsInEditMode={setIsInEditMode}
          setRowIdMapping={setRowIdMapping}
        />
      </div>
      <div className="flex flex-col gap-4 bg-white rounded-xl p-4">
        <p className="text-base font-semibold text-[#3E3C42] whitespace-nowrap">
          Crusher combination wise results
        </p>
        <div className="flex justify-between flex-row gap-3">
          <div className="flex items-center gap-2 overflow-x-auto py-2">
            <div className="min-w-[110px]">
              <Select
                borderColor="#CAC5CD"
                color="#605D64"
                value={selectedRange?.output}
                variant="outline"
                rounded={"base"}
                className="!text-sm !font-medium !text-[#605D64]"
                onChange={(e) => handleOutputRangeSelect(e)}
              >
                <option key="Last 7 days" value={0}>
                  Last 7 days
                </option>
                <option key="custom" value={1}>
                  Custom
                </option>
              </Select>
            </div>
            {selectedRange?.output == 1 && (
              <div className="min-w-[110px]">
                <FloatingInput
                  text="From"
                  type="date"
                  setDateTime={setOutputFromTime}
                  value={outPutFromTime}
                  max={outPutToTime}
                />
              </div>
            )}
            {selectedRange?.output == 1 && (
              <div className="min-w-[110px]">
                <FloatingInput
                  text="To"
                  type="date"
                  setDateTime={setOutPutToTime}
                  value={outPutToTime}
                  min={outPutFromTime}
                  max={new Date().toISOString().slice(0, 10)}
                />
              </div>
            )}
            <SecondaryButton
              width={"80px"}
              height={"40px"}
              text={loading?.output ? <Spinner /> : "Apply"}
              onClick={handleOutputClick}
              disable={!fromTime || !toTime}
            />
          </div>
          <div className="flex items-center gap-4 justify-end">
            {combinationResult.hasOwnProperty("data") &&
              combinationResult.hasOwnProperty("order") &&
              combinationResult?.data?.length > 0 && (
                <ExlCsvDownload
                  order={combinationResult?.order || []}
                  data={combinationResult?.data || []}
                />
              )}
          </div>
        </div>

        {combinationResult.hasOwnProperty("data") &&
          combinationResult.hasOwnProperty("order") && (
            <CrusherCombinationTable
              rowData={combinationResult?.data || []}
              order={combinationResult?.order || []}
              valueOptionsMapping={valueOptionsMapping}
            />
          )}
        <div className=" grid gap-4 grid-cols-1 lg:grid-cols-2">
          {Object.keys(chartData) &&
            Object.keys(chartData)?.length > 0 &&
            valueOptionsMapping?.optionsMapping?.cameraId?.map((cam) => {
              const mpsData = chartData?.[cam?.value]?.mps;
              const bucketWiseData = chartData?.[cam?.value]?.bucketWise;
              const combinations = chartData?.[cam?.value]?.combination;
              const chartName = chartData?.camera?.find(
                (item) => item?.value === cam?.value
              );
              return (
                <>
                  <CrusherLineChart
                    title={`${chartName?.name} - MPS`}
                    data={mpsData ? mpsData : []}
                    combinations={combinations}
                    measurement="mm"
                  />
                  <CrusherLineChart
                    title={`${capitalizeFirstLetter(
                      cam?.name
                    )} - Bucket wise composition`}
                    data={bucketWiseData ? bucketWiseData : []}
                    combinations={combinations}
                    measurement="percentage"
                  />
                </>
              );
            })}
        </div>
      </div>
      {isOpenModal && (
        <CrusherEffectivenessSubmitModal
          openModal={isOpenModal}
          handleModal={() => {
            handleSubmitButton();
            setOpenModal(false);
            setValidationMessage((prev) => ({
              // reset the validation error message when click on cancel
              ...prev,
              message: "",
              shift: "",
              gapAndNone: "",
            }));
          }}
          handleSubmit={handleSubmit}
          rowIdmapping={rowIdmapping}
        />
      )}
      {isOpenTabLockModal?.open && (
        <CrusherTabLockModal
          isOpen={isOpenTabLockModal?.open}
          handlePageChange={handlePageChange}
          setOpenTabLockModal={setOpenTabLockModal}
        />
      )}
    </div>
  );
};

export default CrusherEffectiveness;
