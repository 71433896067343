import ReactApexCharts from "react-apexcharts";
import { useWindowSize } from "@uidotdev/usehooks";

const LineTrendChart = ({ series, times }) => {
  const size = useWindowSize();

  if(!series || !times)return;

  let newTimes = [];
  let prev = -1;

  // times?.forEach((i) => {
  //   const date = new Date(i);
  //   const dayOfMonth = String(date.getDate()).padStart(2, "0");
  //   const monthsAbbreviated = [
  //     "Jan",
  //     "Feb",
  //     "Mar",
  //     "Apr",
  //     "May",
  //     "Jun",
  //     "Jul",
  //     "Aug",
  //     "Sep",
  //     "Oct",
  //     "Nov",
  //     "Dec",
  //   ];
  //   const monthAbbreviated = monthsAbbreviated[date.getMonth()];

  //   if (prev == -1) {
  //     prev = monthAbbreviated;
  //     newTimes.push(`${dayOfMonth} ${monthAbbreviated}`);
  //     //return `${dayOfMonth} ${monthAbbreviated}`;
  //   }
  //   else {
  //     if (monthAbbreviated == prev) {
  //       newTimes.push(`${dayOfMonth}`);
  //     }
  //     else {
  //       prev = monthAbbreviated;
  //       newTimes.push(`${dayOfMonth} ${monthAbbreviated}`);
  //       // return `${dayOfMonth} ${monthAbbreviated}`;
  //     }
  //   }
  // })

  const options = {
    tooltip: {
      shared: true,
      intersect: false,
      style: {
        fontSize: "16px",
      },
    },
    title: {
      text: 'No. of Orders',
      align: 'center',
      style: {
        fontSize: '16px',
        fontWeight: 'medium',
        fontFamily: 'Arial, sans-serif',
      },
    },
    chart: {
      type: "line",
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: true,
        },
      },
      animations: {
        enabled: false,
      },
    },
    xaxis: {
      categories: times,
      type: 'datetime',
      labels: {
        show: true,

      },
    },
    // yaxis: {
    //   title: {
    //     text: "",
    //     style: {
    //       fontSize: "16px",
    //       color: "#000000",
    //       fontWeight: 500,
    //       fontFamily: "Poppins",
    //     },
    //   },
    //   labels: {
    //     style: {
    //       fontSize: "14px",
    //     },
    //   },
    // },
    colors: [
      "#5193f6",
      "#4caf50",
      "#5193f6",
      "#E46962",
      "#1c56ac",
      "#e91e63",
      "#00bcd4",
      "#8bc34a",
      "#9c27b0",
      "#673ab7",
      "#ff9800",
      "#4caf50",
      "#795548",
    ],
    stroke: {
      curve: "smooth",
      width: [3],
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#FAFAFA", "transparent"],
        opacity: 1,
      },
    },
    legend: {
      show: false,
      offsetY: 15,
      offsetX: 0,
      position: "top",
      fontSize: "12px",
    },
  };

  return (
    // <div className={`h-full w-full ${size.width < 700 ? "overflow-x-scroll" : "overflow-hidden"
    //   }`}>
    <div className="min-w-[400px] px-2 w-full">
      <ReactApexCharts
        options={options}
        series={series}
        type="line"
        height={300}
        // width={size.width < 1024 ? "700px" : "100%"}
      />
    </div>
  );
};

export default LineTrendChart;