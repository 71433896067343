import {
  Input,
  Radio,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  useToast,
  Select,
  Stack,
  Textarea,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  Button,
} from "@chakra-ui/react";
import { useState, useRef, useEffect, useContext } from "react";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import TextButton from "../../../util/Buttons/TextButton";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import TonalButton from "../../../util/Buttons/TonalButton";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../index";
import NavContext from "../../NavContext";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
//import Tabs from "../Tabs/TabsView";
// import AdminTabs from "../Tabs/TabsView";

const ViewClient = ({ setDeployClicked, selectedClient }) => {
  const { auth } = useContext(NavContext);
  const { clientId } = useParams();
  // const navigate = useNavigate();
  // const [companyType, setCompanyType] = useState();
  // const [industryValue, setIndustryValue] = useState("");
  // const [subIndustryValue, setSubIndustryValue] = useState("");
  // const [companySize, setCompanySize] = useState("");
  // const [numberOfUsers, setNumberOfUsers] = useState("");
  // const [parentCompany, setParentCompany] = useState("");
  // const [clientName, setClientName] = useState("");
  // const [clientHqLocation, setClientHqLocation] = useState("");
  // const [hqLocationAddress, setHqLocationAddress] = useState("");
  // const [allLocations, setAllLocations] = useState("");
  // const [totalClientLocations, setTotalClientLocations] = useState("");
  // const [clientEmail, setClientEmail] = useState("");
  // const [clientPrimaryContactName, setClientPrimaryContactName] = useState("");
  // const [clientSecContactName, setClientSecContactName] = useState("");
  // const [clientPhoneNumber, setClientPhoneNumber] = useState("");
  // const [ripikPhoneNumber, setRipikPhoneNumber] = useState("");
  // const [ripikEmail, setRipikEmail] = useState("");
  // const [ripikSecContactName, setRipikSecContactName] = useState("");
  // const [ripikPrimaryContactName, setRipikPrimaryContactName] = useState("");
  // const [dealValue, setDealValue] = useState("");
  // const [purchaseOrderCode, setPurchaseOrderCode] = useState("");
  // const [remarks, setRemarks] = useState("");
  // const [clientOrg, setClientOrg] = useState("");
  // const [logoPreview, setLogoPreview] = useState(null);
  // const toast = useToast();
  // const [relationDate, setRelationDate] = useState("");

  // const [productDate, setProductDate] = useState("");
  // const fetchClientDetails = async () => {
  //   // Check if any required field is empty

  //   try {
  //     const response = await axios.get(
  //       baseURL + `iam/fetchClientById?clientId=${clientId}`,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           "X-Auth-Token": auth,
  //         },
  //       }
  //     );
  //     //   toast({
  //     //     title: "Client",
  //     //     description: "Please fill in all required details.",
  //     //     status: "error",
  //     //     duration: 4000,
  //     //     isClosable: true,
  //     //     position: 'top',
  //     //   });
  //     const data = response.data.clientData;
  //     setCompanyType(data.companyType);
  //     setIndustryValue(data.companyType);
  //     setSubIndustryValue(data.industry);
  //     setCompanySize(data.companySize);
  //     setNumberOfUsers(data.numberOfUsers);
  //     setParentCompany(data.parentCompany);
  //     setClientName(data.clientName);
  //     setClientHqLocation(data.clientHqLocation);
  //     setHqLocationAddress(data.hqLocationAddress);
  //     setAllLocations(data.clientAllLocation);
  //     setTotalClientLocations(data.totalClientLocation);
  //     setClientEmail(data.clientPrimEmail);
  //     setClientPrimaryContactName(data.clientPrimContactName);
  //     setClientSecContactName(data.clientSecContactName);
  //     setClientPhoneNumber(data.clientContactNumber);
  //     setRipikPhoneNumber(data.ripikContactNumber);
  //     setRipikEmail(data.ripikContactEmail);
  //     setRipikSecContactName(data.ripikContactSecName);
  //     setRipikPrimaryContactName(data.ripikContactPrimName);
  //     setDealValue(data.dealValue);
  //     setPurchaseOrderCode(data.purchaseOrderCode);
  //     setRemarks(data.remarks);
  //     setProductDate(data.ripikProductUseDate);
  //     setRelationDate(data.clientRelStartDate);
  //     setClientOrg(data.organisation);
  //     setLogoPreview(data.logo32url);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   // fetchClientDetails();
  //   console.log("mounted");
  //   return () => {
  //     console.log("unmounted");
  //   };
  // }, []);

  return (
    <div className="font-roboto flex flex-col gap-2">
      <div className="flex flex-col gap-3">
        <div className=" rounded-lg flex flex-col gap-4 bg-white mb-[24px]">
          <div className="flex justify-between mt-2">
            <p className="text-[#3E3C42] text-lg font-medium ">
              Company information
            </p>
          </div>

          <div className="flex flex-col md:flex-row items-start gap-10">
            <div>
              <p className="text-[#48464C] text-md font-medium mb-3">
                Client name
              </p>
              <div className="text-[#525056] text-sm font-medium">
                {selectedClient.clientName || ""}
              </div>
            </div>
            <div>
              <p className="text-[#48464C] text-md font-medium mb-3">
                Parent company{" "}
              </p>
              <div className="text-[#525056] text-sm font-medium">
                {selectedClient.parentCompany || ""}
              </div>
            </div>
          </div>
          <hr />

          <div className="">
            <p className="text-[#3E3C42] text-lg font-medium mb-3">
              Company Type
            </p>

            <div className="text-[#525056] text-sm font-medium">
              {selectedClient.companyType || ""}
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-start gap-5">
            <div className="">
              <p className="text-[#48464C] text-md font-medium mb-3">
                Industry
              </p>
              <div className="text-[#525056] text-sm font-medium">
                {selectedClient.industry || ""}
              </div>
            </div>
            <div className="">
              <p className="text-[#48464C] text-md font-medium mb-3">
                Sub-Industry
              </p>
              <div className="text-[#525056] text-sm font-medium">
                {selectedClient.subIndustry || ""}
              </div>
            </div>
          </div>
          <hr />

          <div className="">
            <p className="text-[#3E3C42] text-lg font-medium ">Company logo</p>
            <div className=" flex items-center w-[50px] h-[50px] mt-5">
              <img
                src={
                  selectedClient.logo32url ||
                  "/superadmin/clients/companyLogo.png"
                }
                alt="Logo"
                className="w-full"
              />
            </div>
          </div>
        </div>
        <hr />

        <div className="rounded-lg flex flex-col gap-4 bg-white mb-[12px]">
          <p className="text-[#3E3C42] text-lg font-medium ">Company size</p>

          <div className="flex flex-col md:flex-row gap-4 md:gap-10">
            <div className="">
              <p className="text-[#48464C] text-md font-medium mb-3">
                Company Size
              </p>
              <div className="text-[#525056] text-sm font-medium">
                {selectedClient.companySize || ""}
              </div>
            </div>

            <div>
              <p className="text-[#48464C] text-md font-medium mb-3">
                Number of users
              </p>
              <div className="text-[#525056] text-sm font-medium">
                {selectedClient.numberOfUsers || ""}
              </div>
            </div>
          </div>
        </div>
        <hr />

        <div className="rounded-lg flex flex-col gap-4 bg-white mt-1 mb-[12px]">
          <p className="text-[#3E3C42] text-lg font-medium ">
            Company’s location
          </p>

          <div className="flex flex-col md:flex-row items-start gap-5">
            <div>
              <p className="text-[#48464C] text-md font-medium mb-3">
                Client HQ location
              </p>
              <div className="text-[#525056] text-sm font-medium">
                {selectedClient.clientHqLocation || ""}
              </div>
            </div>
            <div>
              <p className="text-[#48464C] text-md font-medium mb-3">
                HQ location address
              </p>
              <div className="text-[#525056] text-sm font-medium">
                {selectedClient.hqLocationAddress || ""}
              </div>
            </div>
          </div>
          <div className="flex flex-col lg:flex-row gap-5 lg:gap-10">
            <div>
              {selectedClient?.clientAllLocation?.split(",")?.map(
                (location, index) =>
                  location.trim() !== "" && (
                    <>
                      <p className="text-[#48464C] text-md font-medium mb-2">
                        Location {index + 1}
                      </p>
                      <div className="flex items-center">
                        <div
                          key={index}
                          className="flex flex-row justify-between gap-4 mb-1  h-10"
                        >
                          <Input
                            type="text"
                            name={`location-${index}`}
                            value={location}
                            border={"none"}
                            pl={0}
                            fontWeight={500}
                            fontSize={"14px"}
                            isReadOnly
                            borderWidth={"2px"}
                          />
                        </div>
                      </div>
                    </>
                  )
              )}
              {selectedClient?.clientAllLocation?.trim() === "" && (
                <p className="text-[#79767D] text-sm font-medium">
                  No location entered
                </p>
              )}
            </div>
            <div>
              <p className="text-[#48464C] text-md font-medium mb-3 ml-1">
                Total client locations{" "}
                <span className="text-[#AEA9B1] ml-1">(count) </span>{" "}
              </p>
              <div className="mt-5 ml-3 text-[#525056] text-sm font-medium">
                {selectedClient.totalClientLocation || 0}
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div className=" md:mt-0 rounded-lg flex flex-col gap-4 bg-white mb-[12px]">
          <p className="text-[#3E3C42] text-lg font-medium ">
            Client’s contact details
          </p>

          <div className="flex flex-col md:flex-row gap-4 md:gap-10">
            <div>
              <p className="text-[#48464C] text-md font-medium mb-3">
                Primary contact name
              </p>
              <div className="text-[#525056] text-sm font-medium">
                {selectedClient.clientPrimContactName || ""}
              </div>
            </div>
            <div>
              <p className="text-[#48464C] text-md font-medium mb-3">E-mail</p>
              <div className="text-[#525056] text-sm font-medium">
                {selectedClient.clientPrimEmail || ""}
              </div>
            </div>
            <div>
              <p className="text-[#48464C] text-md font-medium mb-3">
                Phone number
              </p>
              <div className="text-[#525056] text-sm font-medium">
                {`${selectedClient.clientContactCode || ""} ${
                  selectedClient.clientContactNumber || ""
                }`}
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row gap-4 md:gap-10">
            <div>
              <p className="text-[#48464C] text-md font-medium mb-3">
                Secondary contact name{" "}
              </p>
              <div className="text-[#525056] text-sm font-medium">
                {selectedClient.clientSecContactName || ""}
              </div>
            </div>
            <div>
              <p className="text-[#48464C] text-md font-medium mb-3">E-mail</p>
              <div className="text-[#525056] text-sm font-medium">
                {selectedClient.clientSecEmail || ""}
              </div>
            </div>
            <div>
              <p className="text-[#48464C] text-md font-medium mb-3">
                Phone number
              </p>
              <div className="text-[#525056] text-sm font-medium">
                {`${selectedClient.clientSecContactCode || ""} ${
                  selectedClient.clientSecContactNumber || ""
                }`}
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div className=" md:mt-0 rounded-lg flex flex-col gap-4 bg-white mb-[12px]">
          <p className="text-[#3E3C42] text-lg font-medium ">
            Ripik's contact details
          </p>

          <div className="flex flex-col md:flex-row  gap-4 md:gap-10">
            <div>
              <p className="text-[#48464C] text-md font-medium mb-3">
                Primary contact name
              </p>
              <div className="text-[#525056] text-sm font-medium">
                {selectedClient.ripikContactPrimName || ""}
              </div>
            </div>
            <div>
              <p className="text-[#48464C] text-md font-medium mb-3">E-mail</p>
              <div className="text-[#525056] text-sm font-medium">
                {selectedClient.ripikContactEmail || ""}
              </div>
            </div>
            <div>
              <p className="text-[#48464C] text-md font-medium mb-3">
                Phone number
              </p>

              <div className="text-[#525056] text-sm font-medium">
                {`${selectedClient.ripikContactCode || ""} ${
                  selectedClient.ripikContactNumber || ""
                }`}
              </div>
            </div>
          </div>
          <div className="flex flex-col md:flex-row  gap-4 md:gap-10">
            <div>
              <p className="text-[#48464C] text-md font-medium mb-3">
                Secondary contact name
              </p>
              <div className="text-[#525056] text-sm font-medium">
                {selectedClient.ripikContactSecName || ""}
              </div>
            </div>
            <div>
              <p className="text-[#48464C] text-md font-medium mb-3">E-mail</p>
              <div className="text-[#525056] text-sm font-medium">
                {selectedClient.ripikContactSecEmail || ""}
              </div>
            </div>
            <div>
              <p className="text-[#48464C] text-md font-medium mb-3">
                Phone number
              </p>

              <div className="text-[#525056] text-sm font-medium">
                {`${selectedClient.ripikContactSecCode || ""} ${
                  selectedClient.ripikContactSecNumber || ""
                }`}
              </div>
            </div>
          </div>
          <hr />
        </div>
        <div className=" md:mt-0 pb-6 md:pb-0 rounded-lg flex flex-col gap-4 bg-white mb-[24px]">
          <p className="text-[#3E3C42] text-lg font-medium ">
            Client relation details
          </p>
          <div className="flex flex-col md:flex-row items-start gap-10">
            <div>
              <p className="text-[#48464C] text-md font-medium mb-3">
                Relation start date
              </p>
              <div className="text-[#525056] text-sm font-medium">
                {selectedClient.clientRelStartDate || ""}
              </div>
            </div>
            <div>
              <p className="text-[#48464C] text-md font-medium mb-3">
                First Ripik product use date
              </p>
              <div className="text-[#525056] text-sm font-medium">
                {selectedClient.ripikProductUseDate || ""}
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div>
              <p className="text-[#48464C] text-md font-medium mb-3">
                Deal value
              </p>
              <div className="text-[#525056] text-sm font-medium">
                {selectedClient.dealCurrency
                  ? selectedClient.dealCurrency +
                    " " +
                    (selectedClient.dealValue
                      ? selectedClient.dealValue +
                        (selectedClient.dealCurrency === "₹"
                          ? " Lakhs"
                          : " Thousand")
                      : "")
                  : ""}
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div>
              <p className="text-[#48464C] text-md font-medium mb-3">
                First purchase order code
              </p>
              <div className="text-[#525056] text-sm font-medium">
                {selectedClient.purchaseOrderCode || ""}
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4 mb-10">
            <div>
              <p className="text-[#48464C] text-md font-medium mb-3">
                Remarks / additional information
              </p>
              <div className="text-[#525056] text-sm font-medium">
                {selectedClient.remarks || ""}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewClient;
