export function formatColumnName(columnName) {
  if (!columnName) return "";

  // First, replace underscores with spaces
  let formattedName = columnName.split("_").join(" ");

  // Add a space before each uppercase letter and number
  formattedName = formattedName.replace(/([a-z])([A-Z0-9])/g, "$1 $2");

  // Add a space after the last number if it exists
  formattedName = formattedName.replace(/(\d+)(?!\d)/g, "$1 ");

  // Capitalize the first letter of each word
  formattedName = formattedName
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedName.trim();
}
