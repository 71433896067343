import React, { useState } from 'react'
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  Box
} from '@chakra-ui/react';

const colors = [
  "#5193f6",
  "#4caf50",
  "#E46962",
  "#1c56ac",
  "#e91e63",
  "#00bcd4",
  "#8bc34a",
  "#9c27b0",
  "#673ab7",
  "#ff9800",
  "#4caf50",
  "#795548",
  "#FFE485",
]

const MetaDataComponent = ({ data, index }) => {

  const stats = data?.stats

  const [isOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);
  return (
    <Popover
      isOpen={isOpen}
      onClose={close}
      placement="bottom"
      closeOnBlur={false}
    >
      <PopoverTrigger>
        <div className='flex gap-1 items-center'>
          <Box
            bg={index < 11 ? colors[index] : "#5193f6"}
            borderRadius="50%"
            width={3}
            height={3}
          /><p onMouseEnter={open} onMouseLeave={close} className='text-xs'> {data?.name}</p>
        </div>
      </PopoverTrigger>
      <PopoverContent onMouseEnter={open} onMouseLeave={close}>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader className='text-sm'>{data?.name}</PopoverHeader>
        <PopoverBody>
          <div className='flex flex-col gap-2'>
            {
              Object.keys(stats)?.map((key) => {
                return (
                  <div className='grid grid-cols-2 gap-2'>
                    <p className='capitalize text-xs'>{key}</p>
                    <p className='text-xs'>{key == "startTime" ? new Date(stats?.[key])?.toLocaleString() : stats?.[key]}</p>
                  </div>
                )
              })
            }
          </div>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

export default MetaDataComponent