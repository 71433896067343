import React, { useEffect, useRef, useState } from "react";
import DetailModal from "./DetailModal";
import { generateStatusColumns } from "../utilis/dataGridColumns";
import {
  CustomFooter,
  CustomStyledDataGrid,
  CustomToolbar,
} from "../../../util/MaterialDataGrid/CustomStyledDatagrid";
import {
  gridPaginatedVisibleSortedGridRowEntriesSelector,
  useGridApiRef,
} from "@mui/x-data-grid";
import useShallowCompareEffect from "../hooks/useShallowCompare";

const StatusTable = ({ tableData, hidePagination = false, toolBarId }) => {
  const indexRef = useRef();
  const gridApiRef = useGridApiRef();
  const buttonRef = useRef();

  const columnsName = [
    "sequenceNo",
    "board",
    "grateBar",
    "vNotch",
    "boardPermeability",
  ];

  // state
  const [openModal, setOpenModal] = useState(false);
  const [columns, setColumns] = useState(null);
  const [displayData, setDisplayData] = useState(null);

  const playAudio = async () => {
    try {
      const audioTone = new Audio(
        "https://intelliverse-demo-new.s3.ap-south-1.amazonaws.com/cullingham.mp3"
      );
      const audioMessafe = new Audio(
        "https://intelliverse-demo-new.s3.ap-south-1.amazonaws.com/gratebaralert.mp3"
      );

      await audioTone.play();
      await audioMessafe.play();
    } catch (error) {
      console.log(error);
    }
  };

  const handleViewDetail = (params) => {
    const tempDisplayData =
      gridPaginatedVisibleSortedGridRowEntriesSelector(gridApiRef);
    setDisplayData((prev) => tempDisplayData);
    const index = params.api.getRowIndexRelativeToVisibleRows(params?.row?.id);
    if (index !== null || index !== undefined) {
      indexRef.current = index;
      setOpenModal(true);
    }
  };

  useEffect(() => {
    generateStatusColumns(columnsName, handleViewDetail).then((res) => {
      setColumns((prev) => res);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // to check if the grate bar is changed on 1st row only
  const grateBarData = tableData?.[0]?.grateBar;
  useShallowCompareEffect(() => {
    if (hidePagination) {
      if (tableData && tableData?.length > 0) {
        if (grateBarData >= 50) {
          setDisplayData((prev) => [{ model: tableData[0] }]);
          indexRef.current = 0;
          setOpenModal(true);
          buttonRef?.current?.click();
        }
      }
    }
  }, [grateBarData, hidePagination]);

  return (
    <>
      <button
        className="hidden"
        onClick={playAudio}
        ref={buttonRef}
      />
      {columns && (
        <CustomStyledDataGrid
          apiRef={gridApiRef}
          rows={tableData && tableData}
          columns={columns}
          rowHeight={40}
          columnHeaderHeight={40}
          disableColumnSelector={true}
          disableRowSelectionOnClick={true}
          pageSizeOptions={[60]}
          disableColumnResize={true}
          disableColumnReorder={true}
          pagination={true}
          initialState={{
            pagination: { paginationModel: { pageSize: 60, page: 0 } },
          }}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0
              ? "bg-[#FAFAFA]"
              : "bg-white"
          }
          hideFooter={hidePagination}
          slots={{
            ...(toolBarId && { toolbar: CustomToolbar }),
            footer: CustomFooter,
          }}
          {...(toolBarId && {
            slotProps: {
              toolbar: { toolBarId },
            },
          })}
          showColumnVerticalBorder
          sx={{
            "& .MuiDataGrid-columnHeaderTitle": {
              whiteSpace: "normal",
              lineHeight: "normal",
            },
            "& .MuiDataGrid-columnHeaderTitleContainer": {
              alignItems: "center",
            },
          }}
        />
      )}
      {openModal && (
        <DetailModal
          openModal={openModal}
          closeModal={() => setOpenModal((prev) => false)}
          data={displayData}
          index={indexRef.current}
          // plantName={"chanderia"}
        />
      )}
    </>
  );
};

export default React.memo(StatusTable);
