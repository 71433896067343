import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
  Input,
  useToast,
} from "@chakra-ui/react";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import TonalButton from "../../../util/Buttons/TonalButton";
import { useContext, useRef, useState } from "react";
import axios from "axios";
import { baseURL } from "../../../index";
import NavContext from "../../NavContext";

const StatusEdit = ({ openModal, closeModal, data }) => {
  const valueRef = useRef();
  const { auth } = useContext(NavContext);
  const toast = useToast();

  const updateApi = async () => {
    try {
      const requestData = {
        concentrate: data.concentrate,
        batch_id: data.batchId,
        dumped_tonnage: parseFloat(valueRef.current.value),
        required_tonnage: data.required,
        smelter: data.smelter,
        endtime: new Date().getTime() / 1000,
        rounds: parseFloat(data.round?.slice(1)),
      };
      const response = await axios.post(
        baseURL + "vision/v1/workforceMonitoring/blendComplianceTracking/cb/updateRound",
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.status == 200) {
        toast({
          title: "Updated",
          description: "Data successfully updated will reflect in some time",
          status: "success",
          position: "top-right",
          duration: 2000,
          isClosable: true,
        });
        closeModal();
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Error",
        description: "Patch update round api failed",
        status: "error",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal
      isOpen={openModal}
      onClose={closeModal}
      closeOnOverlayClick={false}
      isCentered="true"
    >
      <ModalOverlay />
      <ModalContent style={{ borderRadius: "12px" }} maxW="360px">
        <ModalBody p="16px" rounded="9px">
          <div className="flex flex-col gap-4">
            <p className="text-[#3E3C42] text-base font-medium">
              Edit consumption
            </p>
            <div className="flex gap-6">
              <div className="flex flex-col gap-4 whitespace-nowrap">
                <p className="text-sm text-[#938F96] font-medium">
                  Concentrate
                </p>
                <p className="text-[#605D64] text-sm font-medium">
                  {data.concentrate}
                </p>
              </div>
              <div className="flex flex-col gap-4 whitespace-nowrap">
                <p className="text-sm text-[#938F96] font-medium">
                  Round {data.round?.slice(1)}
                </p>
                <Input
                  ref={valueRef}
                  color={"#605D64"}
                  fontSize={"14px"}
                  type="number"
                  fontWeight={500}
                  border={"1px solid var(--Neutral-N90, #EBEBEB)"}
                  borderRadius={"5px"}
                  defaultValue={data.value}
                />
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <TonalButton
                text={"Discard changes"}
                width={"140px"}
                onClick={closeModal}
              />
              <PrimaryButton
                text={"Save changes"}
                width={"140px"}
                onClick={updateApi}
              />
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default StatusEdit;
