import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useLayoutEffect,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import ExpertReadMore from "./ExpertReadMore";
import NavContext from "../NavContext";
import { baseURL } from "../..";
import { Spinner, Tooltip, useToast } from "@chakra-ui/react";
import axios from "axios";
import { useWindowSize } from "@uidotdev/usehooks";
import { getQuestionsCredit } from "../../util/utilFunctions";
import AskYourQuestion from "../community/AskYourQuestion/AskYourQuestion";
import PrimaryButton from "../../util/Buttons/PrimaryButton";

const AskAnExpert = () => {
  const { auth } = useContext(NavContext);
  const size = useWindowSize();
  const navigate = useNavigate();

  const [submitted, setSubmitted] = useState(false);
  const [selected, setSelected] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [expert, setExpert] = useState(null);
  const [credits, setCredits] = useState(0);
  const [disabled, setDisabled] = useState(true);
  // const [showQuetionPage, setShowQuestion] = useState(false);

  const [expertDetails, setExpertDetails] = useState([]);
  const [question, setQuestion] = useState("");
  const toast = useToast();

  const [loader, setLoader] = useState(false);

  const [val1, setVal1] = useState(false);
  const [val2, setVal2] = useState(false);
  const [val3, setVal3] = useState(false);
  const [val4, setVal4] = useState(false);

  const [expertId, setExpertId] = useState(""); // expert id

  const [send, setSend] = useState([]);

  const selectPicture = (event) => {
    setSend([...send, event.target.files[0]]);
    console.log("send", send);
  };

  const getData = async () => {
    setLoader(true);

    try {
      const response = await axios.get(baseURL + "experts/v1/fetch/experts", {
        headers: {
          "Content-Type": "application/json",
          "X-auth-Token": auth,
        },
      });
      const data = response?.data;
      const filteredExperts = data?.filter((item) => item.status === "ACTIVE");
      // console.log(filteredExperts);

      setExpertDetails(filteredExperts);

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const getBalance = async () => {
    try {
      const data = await fetch(baseURL + "token-wallet/v1/balance", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      const res = await data.json();
      if (data.status !== 400) {
        // setCredits(getQuestionsCredit(res, setDisabled));
        setCredits(res.User.balance);
        setDisabled(false);
      } else if (data.status === 400) {
        setCredits(0);
        setDisabled(true);
      }
      const tokens = res?.User?.balance;
      if (tokens < 20) {
        toast({
          title: `Insufficient token balance, Please add tokens!`,
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const truncateStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "300px", // Adjust the max-width as needed
  };

  useEffect(() => {
    getData();
    getBalance();
  }, []);

  useEffect(() => {
    if (val1 === true || val2 === true || val3 === true || val4 === true) {
      setSelected(true);
    } else if (
      val1 === false ||
      val2 === false ||
      val3 === false ||
      val4 === false
    ) {
      setSelected(false);
    }
  }, [val1, val2, val3, val4]);
  const handleShowPage = (expertId) => {
    // setVal1(true);
    setExpertId(expertId);
    navigate(`/community/askanexpert/askquestion/${expertId}`);
  };

  const handleBackButton = () => {
    navigate("/community/askanexpert");
  };

  return (
    <div className="mt-6 w-full">
      <div className="w-full border shadow-md bg-white rounded-md mb-5 ">
        <div className="mt-6 ml-5 flex justify-between items-center mr-5">
          <div className="flex">
            <div className="cursor-pointer w-8 mr-1" onClick={handleBackButton}>
              <img
                src="/backtick.svg"
                className="w-[70%] md:w-full h-full"
                alt="backarrow_img"
              />
            </div>
            <p className=" text-[#024D87] text-xl font-semibold">
              Ask An Expert
            </p>
          </div>
        </div>

        <div>
          <p className="mt-3 ml-5 font-light mr-2">
            Have your most pressing questions answered by world renown experts.
          </p>
          <p className="font-semibold mt-5 ml-5 text-sm">Choose An Expert</p>
        </div>

        <div className="mt-6">
          <div className="w-full rounded-md mb-5 ">
            <div className={`mx-5 mb-[5vh] ${loader ? "h-screen" : ""}`}>
              {loader ? (
                <div className="flex justify-center">
                  <Spinner />
                </div>
              ) : (
                <div className="w-full grid md:grid-cols-2 gap-6 mt-2">
                  {expertDetails && expertDetails.length > 0 ? (
                    expertDetails?.map((expert, index) => (
                      <div
                        key={index}
                        className="border-dashed rounded-md border-gray-700 border w-[90%] sm:w-full grid md:grid-cols-3 h-auto"
                      >
                        <div className="col-span-1 flex items-center justify-center mr-2 ml-2 m-auto">
                          {expert.imageUrl ? (
                            <img
                              className="border w-20 h-20 xl:w-36 xl:h-36 rounded-md shadow-xl md:ml-0 ml-2 md:mt-0 mt-2"
                              src={expert.imageUrl}
                              alt="expert"
                            />
                          ) : (
                            <div className="py-1">
                              <img
                                className="border w-20 h-20 xl:w-36 xl:h-36 rounded-md shadow-xl md:ml-0 ml-2 md:mt-0 mt-2"
                                src="/Common/exp_placeholder.jpg"
                                alt="expert"
                              />
                            </div>
                          )}
                        </div>
                        <div className="col-span-2 md:ml-0 mr-0 ">
                          <div className="flex flex-col lg:flex-row items-start justify-start lg:items-center lg:justify-between ">
                            <p className=" font-semibold text-lg mt-3 md:ml-0 ml-3">
                              {expert.expertName}
                            </p>
                            <div className=" mt-1 md:ml-0 ml-3 mr-1 w-[150px] lg:flex lg:justify-end lg:items-end">
                              <PrimaryButton
                                text={"Ask a question"}
                                width={"fit-content"}
                                onClick={() => handleShowPage(expert.expertId)}
                                disable={credits < 20}
                              />
                            </div>
                          </div>

                          <p className="w-[80%] mt-2 text-sm text-gray-700 md:ml-0 ml-3 mr-1">
                            {expert.designation}
                          </p>
                          <Tooltip label={expert.industry} placement={"top"}>
                            <p
                              className="w-[80%] mt-2 text-sm text-gray-700 mb-4 md:ml-0 ml-3 mr-1"
                              style={truncateStyle}
                            >
                              {expert.industry}
                            </p>
                          </Tooltip>
                          <div className="w-full mt-2 text-[#034D86] font-bold mb-5 flex justify-between">
                            <p
                              onClick={() => {
                                setExpert((prev) => expert);
                                setIsOpen(true);
                              }}
                              className="cursor-pointer text-sm md:ml-0 ml-3"
                            >
                              Read More
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex justify-center md:justify-end w-full">
                      No data available
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-end items-center md:mb-0 mb-20">
        <div className="flex flex-col items-end md:flex-row md:items-start gap-4 text-[14px] mt-0 mr-2 whitespace-nowrap">
          <p
            className={
              credits < 5 ? "font-bold text-[#DC362E]" : "font-bold text-black"
            }
          >
            {credits}{" "}
            <img
              src="/token.svg"
              className="h-[12px] inline-block -mt-[2px]"
              alt=""
            />{" "}
            Remaining
          </p>
          <Link to="/community/advisor/buycredits/user/foruser">
            <div className="text-[#124CA2] font-bold cursor-pointer">
              Buy tokens
            </div>
          </Link>
          <Link to="/user/transactionhistory">
            <div className="text-[#124CA2] font-bold cursor-pointer">
              View Transaction History
            </div>
          </Link>
        </div>
      </div>
      {expert && (
        <ExpertReadMore
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          expertDetails={expert}
        />
      )}
    </div>
  );
};

export default AskAnExpert;
