import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
  } from "@chakra-ui/react";
  
  const DraftModal = ({ isOpen, setShowDraft , handleSubmit, handleConfirmDiscard }) => {
    return (
      <Modal isOpen={isOpen} onClose={() => setShowDraft(false)} isCentered size={"sm"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader
            className=" !flex !justify-center !rounded-t-md !bg-red-400 !text-white "
            fontSize={"18px"}
          >
            Save changes ?
          </ModalHeader>
          {/* <div className="text-white w-full flex bg-[#034D86] font-semibold justify-center items-center rounded-t-md">
            hi
          </div> */}
          <div className="flex flex-col justify-center items-center mt-4">
            <div>
              <img src="/images/img.svg" alt="" />
            </div>
            <div className="font-bold">You have unsaved changes</div>
            <div >
              Would you like to save those changes?
            </div>
          </div>
          <ModalCloseButton className="mt-2" color={"white"} />
          <ModalBody>
            <div className="flex justify-center items-center gap-3 mt-5">
              <button
                onClick={() => {
                  handleSubmit(0);
                  setShowDraft(false);
                }}
                className="bg-[#084298] text-white px-7 py-2 rounded-md mb-5 w-[120px]"
                mr={3}
              >
                Save
              </button>
              <button
                onClick={() => handleConfirmDiscard()}
                className="bg-[#DDEEFF] text-[#3A74CA] px-7 py-2 rounded-md mb-5 w-[120px]"
                mr={3}
              >
                Discard
              </button>
            </div>
          </ModalBody>
          {/* <ModalFooter>
            {submitted === false ? (
              <button
                onClick={() => setSubmitted(true)}
                className="bg-[#084298] text-white px-7 py-2 rounded-md mb-5 "
                mr={3}
              >
                Submit
              </button>
            ) : (
              <button
                onClick={onClose}
                className="bg-[#084298] text-white px-7 py-2 rounded-md mb-5 "
                mr={3}
              >
                Close
              </button>
            )}
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    );
  };
  
  export default DraftModal;
  