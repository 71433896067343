import { useContext, useEffect, useState } from "react";
import Header from "./Header";
import TonalButton from "../../../util/Buttons/TonalButton";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { Spinner, useToast } from "@chakra-ui/react";
import FileUploader from "../AnswerExpert/FileUploader";
//import { CloseIcon } from "@chakra-ui/icons";
import QuestionInstructions from "./QuestionInstructions";
import { useNavigate, useParams } from "react-router-dom";
import NavContext from "../../NavContext";
import { baseURL } from "../../..";
import axios from "axios";
import { useWindowSize } from "@uidotdev/usehooks";
import CloseIcon from "@mui/icons-material/Close";
import SaveDraftModal from "../Modals/SaveDraftModal";

const DraftQuestion = () => {
  const { questionId } = useParams();
  //console.log(questionId);

  const { auth, organisation, fullName } = useContext(NavContext);
  const [loader, setLoader] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const [customEndDate, setCustomToTime] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [review, setReview] = useState(false);
  const [summary, setSummary] = useState("");
  const [question, setQuestion] = useState("");
  const [expertList, setExpertList] = useState([]);
  const [selectedExpert, setSelectedExpert] = useState(null);
  const [selectedExpertId, setSelectedExpertId] = useState();
  const [isSaved, setSaved] = useState(false);
  const [savedQuestion, setSaveQuestion] = useState("");
  const [toTimeInMs, setToTimeInMs] = useState("");
  const [savedSummary, setSavedSummary] = useState("");
  const [send, setSend] = useState([]);
  const [commentsAttachments, setCommentsAttachments] = useState([]);
  const toast = useToast();
  const [saveDraft, setSaveDraft] = useState(false);

  const [enquirerDetails, setEnquirerDetails] = useState({
    enqName: "",
    enqImgUrl: "",
    enqOrg: "",
  });
  const [errors, setErrors] = useState({
    summary: "",
    question: "",
  });
  const [prevInputs, setPrevInputs] = useState({
    summary: "",
    question: "",
  });
  const [isSaveModalOpen, setSaveModalOpen] = useState(false);

  const size = useWindowSize();

  const setEndOfDay = (dateString) => {
    const parsedDate = new Date(dateString);
    // Set time to the end of the day (23:59:59.999)
    parsedDate.setHours(23, 59, 59, 999);
    return parsedDate;
  };

  const onCloseSavemodal = () => {
    setSaveModalOpen(false);
  };

  useEffect(() => {
    setToTimeInMs(setEndOfDay(customEndDate).getTime());
  }, [customEndDate]);

  const navigate = useNavigate();

  const getAttachmentsForEnquirer = async () => {
    try {
      const res = await axios.get(baseURL + `attachment/expert/${questionId}`, {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });

      console.log("Attachments");
      console.log(res.data);

      let arr = [];
      for (let x of res.data) {
        let name = x.substring(x.lastIndexOf("/") + 1, x.length);
        let obj = {};
        obj.bucketKey = x;
        obj.name = name;
        arr.push(obj);
      }

      //setCommentsAttachments(arr);
      setSend(arr);
      // console.log("enq attt", res);
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputsCheck = () => {
    if (summary.length < 10) {
      setErrors((prevData) => ({
        ...prevData,
        summary: "Minimum 10 characters required",
      }));
    }
    if (question.length < 50) {
      setErrors((prevData) => ({
        ...prevData,
        question: "Minimum 50 characters required",
      }));
    }
    if (summary.length > 50 || question.length > 500) {
      if (summary.length > 50) {
        setErrors((prevData) => ({
          ...prevData,
          summary: `You have exceeded the character limit of 50 by ${
            summary.length - 50
          }`,
        }));
      }
      if (question.length > 500) {
        setErrors((prevData) => ({
          ...prevData,
          question: `You have exceeded the character limit of 500 by ${
            question.length - 500
          }`,
        }));
      }
    }
  };

  const postQuestion = async () => {
    console.log("sub2");

    if (summary.length < 10 || question.length < 50) {
      console.log(summary.length, question.length);
      setSpinner(false);
      console.log("ok");
      if (summary.length < 10) {
        console.log("1");

        // console.log("summ");
        setErrors((prevData) => ({
          ...prevData,
          summary: "Minimum 10 characters required",
        }));
      }
      if (question.length < 50) {
        console.log("2");

        setErrors((prevData) => ({
          ...prevData,
          question: "Minimum 50 characters required",
        }));
      }

      return;
    }
    if (summary.length > 50 || question.length > 500) {
      console.log("54");
      console.log(summary.length, question.length);
      setSpinner(false);
      if (summary.length > 50) {
        console.log("3");

        setErrors((prevData) => ({
          ...prevData,
          summary: `You have exceeded the character limit of 50 by ${
            summary.length - 50
          }`,
        }));
      }
      if (question.length > 500) {
        console.log("4");

        setErrors((prevData) => ({
          ...prevData,
          question: `You have exceeded the character limit of 500 by ${
            question.length - 500
          }`,
        }));
      }

      return;
    }
    // Check if summary is empty
    if (!summary && !savedSummary) {
      console.log("5");

      setSpinner(false);
      setErrors((prevData) => ({
        ...prevData,
        summary: "Summary field is mandatory",
      }));
      return;
    }

    // Check if details is empty
    if (!question && !savedQuestion) {
      console.log("6");

      setSpinner(false);

      setErrors((prevData) => ({
        ...prevData,
        question: "Question details field is mandatory",
      }));
      return;
    }
    setLoader(true);
    const cap = {
      expertId: selectedExpertId,
      question: question,
      subject: summary,
      needBy: toTimeInMs,
      questionId: questionId ? questionId : null,
    };
    const json = JSON.stringify(cap);
    const blob = new Blob([json], {
      type: "application/json",
    });
    console.log("api");
    const FormData = require("form-data");
    let data = new FormData();
    data.append("json", blob);
    data.append("files", send[0]);
    data.append("files", send[1]);
    data.append("files", send[2]);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: baseURL + "questions",
      headers: {
        "X-Auth-Token": auth,
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        //console.log(JSON.stringify(response.data));
        setSubmitted(true);

        setLoader(false);
        setSpinner(false);
      })
      .catch((error) => {
        setLoader(false);
        setSpinner(false);
        console.log(error);
      });
  };

  const handleSave = async () => {
    // const data = {
    //   subject: summary,
    //   question: question,
    //   expertId: selectedExpertId,
    //   needBy: customEndDate,
    // };

    const cap = {
      questionId: questionId ? questionId : null,
      expertId: selectedExpertId,
      question: question,
      subject: summary,
      needBy: toTimeInMs,
    };

    console.log(cap);
    const json = JSON.stringify(cap);
    const blob = new Blob([json], {
      type: "application/json",
    });
    const FormData = require("form-data");
    let data = new FormData();
    data.append("json", blob);

    console.log(send.length + " Hello");
    if (send.length > 0) data.append("files", send[0]);
    if (send.length > 1) data.append("files", send[1]);
    if (send.length > 2) data.append("files", send[2]);
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: baseURL + "questions/draft",
      headers: {
        "X-Auth-Token": auth,
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        //console.log(JSON.stringify(response.data));
        setSubmitted(true);
        setSaveDraft(true);
        setLoader(false);
        setSpinner(false);

        toast({
          title: "Draft Saved Successfully",
          // description: "Please fill in all required details.",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
      })
      .catch((error) => {
        setLoader(false);
        setSpinner(false);
        console.log(error);
        toast({
          title: `Draft not saved, please try again`,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      });

    // try {
    //   const response = await axios.post(
    //     baseURL + `questions/draft`,
    //     data,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         "X-Auth-Token": auth,
    //       },
    //     }
    //   );
    //   if (response?.status) {
    //     setSaved(true);
    //   } else {
    //     toast({
    //       title: `Draft not saved, please try again`,
    //       status: "error",
    //       duration: 3000,
    //       isClosable: true,
    //       position: "top",
    //     });
    //   }
    // } catch (e) {
    //   toast({
    //     title: `Draft not saved, please try again`,
    //     status: "error",
    //     duration: 3000,
    //     isClosable: true,
    //     position: "top",
    //   });

    //   console.error(e);
    // }
    // try {
    //   const response = await axios.post(
    //     baseURL + `questions/saveQuestion/${selectedExpertId}`,
    //     data,
    //     {
    //       headers: {
    //         "Content-Type": "application/json",
    //         "X-Auth-Token": auth,
    //       },
    //     }
    //   );
    //   if (response?.status) {
    //     setSaved(true);
    //   } else {
    //     toast({
    //       title: `Draft not saved, please try again`,
    //       status: "error",
    //       duration: 3000,
    //       isClosable: true,
    //       position: "top",
    //     });
    //   }
    // } catch (e) {
    //   toast({
    //     title: `Draft not saved, please try again`,
    //     status: "error",
    //     duration: 3000,
    //     isClosable: true,
    //     position: "top",
    //   });

    //   console.error(e);
    // }
  };

  useEffect(() => {
    const fetchExperts = async () => {
      try {
        const response = await axios.get(baseURL + "experts", {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        });
        setExpertList(response?.data);
        // const selectedExpert = response?.data.find(
        //   (expert) => expert.expertId === expertId
        // );
        // setSelectedExpert(selectedExpert);
      } catch (error) {
        console.error("Error fetching experts:", error);
      }
    };

    fetchExperts();
    getSavedQuestion();
  }, [auth]);

  const getEnteredDetails = async () => {
    try {
      const response = await axios.get(
        baseURL + `questions/${questionId}`,

        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );

      setSelectedExpert(response?.data.expertName);
      setSelectedExpertId(response?.data.expertId);
      setSaveQuestion(response?.data.question);
      setSavedSummary(response?.data.subject);
      setQuestion(response?.data.question);
      setSummary(response?.data.subject);
      setPrevInputs((prev) => ({
        ...prev,
        summary: response?.data.subject,
        question: response?.data.question,
      }));
      // let obj = {
      //     enqName: response?.data.userName,
      //     enqImgUrl: response?.data.userImageUrl,
      //     enqOrg: response?.data.userOrganisation,
      // }
      setEnquirerDetails((prev) => ({
        enqName: response?.data.userName,
        enqImgUrl: response?.data.userImageUrl,
        enqOrg: response?.data.userOrganisation,
      }));

      const date = new Date(response?.data.needBy);
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
      const day = date.getDate().toString().padStart(2, "0");

      // Format the date as "YYYY-MM-DD"
      const formattedDate = `${year}-${month}-${day}`;
      console.log("hehhehe");
      console.log(formattedDate);
      //console.log(Date(response?.data.needBy));
      setCustomToTime(formattedDate);
    } catch (e) {
      console.error(e);
    }
  };

  const getSavedQuestion = async () => {
    try {
      const response = await axios.get(
        baseURL + `questions/saveQuestion/${selectedExpertId}`,

        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      setSaveQuestion(response?.data.question);
      setSavedSummary(response?.data.subject);
      setQuestion(response?.data.question);
      setSummary(response?.data.subject);
      setPrevInputs((prev) => ({
        ...prev,
        summary: response?.data.subject,
        question: response?.data.question,
      }));
      console.log(response);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchUser = async () => {
    try {
      const response = await axios.get(baseURL + "user", {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      const name = response?.data.data.fullname;
      const imageUrl = response?.data?.data?.imageurl;
      const org = response?.data.data.organisation;

      setEnquirerDetails((prevData) => ({
        ...prevData,
        enqName: name,
        enqImgUrl: imageUrl,
        enqOrg: org,
      }));

      //console.log("fetch user details", response);

      // console.log("users",response?.data.data.fullname);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchUser();
    const getCustomEndDate = () => {
      const currentDate = new Date();

      // Calculate the number of days to add to reach the next 4th working day
      let daysToAdd = 0;
      while (daysToAdd < 3) {
        currentDate.setDate(currentDate.getDate() + 1);
        // Skip weekends (Saturday and Sunday)
        if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
          daysToAdd += 1;
        }
      }

      // Set the time to EOD IST (17:30:00)
      currentDate.setHours(17, 30, 0, 0);

      // Format the date as "yyyy-MM-dd"
      const formattedDate = currentDate.toISOString().slice(0, 10);

      return formattedDate;
    };
    // Set the initial custom end date when the component mounts
    if (questionId == null) setCustomToTime(getCustomEndDate());
    getEnteredDetails();
    getAttachmentsForEnquirer();
  }, []);

  const handleBackButton = () => {
    if (
      (summary?.length > 10 &&
        !saveDraft &&
        prevInputs?.summary?.length != summary?.length) ||
      (question?.length > 50 &&
        !saveDraft &&
        prevInputs?.question?.length != question?.length)
    ) {
      // console.log("modalopen called");
      setSaveModalOpen(true);
    } else {
      navigate(-1);
    }
    // navigate(-1);
  };

  const handleBackToDashboard = () => {
    navigate("/community/askanexpert");
  };

  const handleSubmit = () => {
    console.log("sub1");
    setSaveDraft(false);
    setSpinner(true);
    setLoader(true);
    postQuestion();
  };

  const handleBackEditing = () => {
    setReview(false);
    setSaved(false);
  };

  const removeFile = (index, asset) => {
    const updatedSend = [...send];
    updatedSend.splice(index, 1);

    setSend(updatedSend);
    // Remove the file at the specified index
  };

  const handleDeleteIcon = (asset, index) => {
    axios({
      url: baseURL + `attachment/delete/attachment?key=${asset}`, //your url
      method: "delete",
      responseType: "blob", // important
    }).then((response) => {
      const updatedSend = [...send];
      updatedSend.splice(index, 1);
      setSend(updatedSend);

      // create file link in browser's memory
      // const href = URL.createObjectURL(response.data);

      // // create "a" HTML element with href to file & click
      // const link = document.createElement("a");
      // link.href = href;
      // link.setAttribute("download", removeExtension(asset)); //or any other extension
      // document.body.appendChild(link);
      // link.click();
      // // clean up "a" element & remove ObjectURL
      // document.body.removeChild(link);
      // URL.revokeObjectURL(href);
    });
  };

  const downloadAsset = async (file) => {
    let asset = file.bucketKey;
    axios({
      url: baseURL + `attachment/download?key=${asset}`, //your url
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      console.log(response.data);
      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", removeExtension(asset)); //or any other extension
      document.body.appendChild(link);
      link.click();
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);

      // console.log(data);
    });
  };

  function removeExtension(file) {
    let filename = file.bucketKey;
    console.log("Hello");
    console.log(filename);
    return (
      filename.substring(filename.lastIndexOf("/") + 1, filename.length) ||
      filename
    );
  }
  //console.log("sum", summary);
  console.log("prev inputs", prevInputs);

  return submitted === false || saveDraft === true ? (
    <>
      <div className="bg-[#FAFAFA] mt-[3vh]">
        <div
          className="flex justify-between items-center bg-[#FAFAFA] pr-6"
          // style={{ width: size.width >= 768 ? "calc(100vw - 168px)" : "100vw" }}
        >
          <div className="flex">
            <div className="cursor-pointer w-8" onClick={handleBackButton}>
              <img
                src="/backtick.svg"
                className="w-[70%] md:w-full h-full"
                alt="backarrow_img"
              />
            </div>
            <p className="text-base sm:text-2xl font-semibold text-[#024D87]">
              QnA Details
            </p>
          </div>
          {/* <div className="flex items-center gap-2 my-3 ">
            {review === false ? (
              <TonalButton
                text={"Save Draft"}
                width={"fit-content"}
                onClick={handleSave}
                disable={isSaved}
              />
            ) : (
              <TonalButton
                text={"Back to Editing"}
                width={"fit-content"}
                onClick={handleBackEditing}
              />
            )}
            {review === false ? (
              // <PrimaryButton
              //   text={"Review"}
              //   width={"fit-content"}
              //   onClick={() => setReview(true)}
              // />
              <button
                className="bg-[#348114] rounded text-white text-sm"
                onClick={() => setReview(true)}
                style={{
                  width: "98px",
                  // height: "auto",
                  padding: "8px 0px 8px 0px",
                }}
              >
                Review
              </button>
            ) : spinner === false ? (
              // <PrimaryButton
              //   text={spinner ? "Submitting" : "Submit"}
              //   width={"fit-content"}
              //   onClick={handleSubmit}
              // />
              <button
                className="bg-[#348114] rounded text-white text-sm"
                onClick={handleSubmit}
                style={{
                  width: "125px",
                  // height: "auto",
                  padding: "8px 0px 8px 0px",
                }}
              >
                {spinner ? "Submitting" : "Submit"}
              </button>
            ) : (
              <Spinner />
            )}
          </div> */}
        </div>
      </div>
      <div className=" px-6 w-full border shadow-md bg-white rounded-md mb-5 pt-3">
        <Header
          customEndDate={customEndDate}
          setCustomToTime={setCustomToTime}
          selectedExpert={selectedExpert}
          selectedExpertId={selectedExpertId}
          setSelectedExpert={setSelectedExpert}
          setSelectedExpertId={setSelectedExpertId}
          expertList={expertList}
          organisation={organisation}
          fullName={fullName}
          auth={auth}
          enquirerDetails={enquirerDetails}
        />
        <div className="py-6">
          <p className="text-[14px] font-semibold">
            Question summary<span className="text-[red] ml-1">*</span>
          </p>
          <input
            type="text"
            placeholder="Be concise and summarize your question"
            className={`w-full md:w-[80%] mt-1 h-10 px-4 rounded-md border ${
              review ? "border-none cursor-not-allowed pointer-events-none" : ""
            }`}
            value={summary || savedSummary}
            onChange={(e) => {
              setSummary(e.target.value);
              setSavedSummary(e.target.value);
              setSaved(false);
              const value = e.target.value;
              if (value.length > 50) {
                setErrors((prevData) => ({
                  ...prevData,
                  summary: `You have exceeded the character limit of 50 by ${
                    value.length - 50
                  }`,
                }));
              }
              //  else if (value.length < 10) {
              //   // Clear the error if within the character limit
              //   setErrors((prevData) => ({
              //     ...prevData,
              //     summary: "Minimum 10 characters required",
              //   }));
              // }
              else {
                // Clear the error if within the character limit
                setErrors((prevData) => ({
                  ...prevData,
                  summary: "",
                }));
              }
            }}
          />
          {errors?.summary && (
            <p className="mt-1 ml-1 text-[red] text-sm">{errors?.summary}</p>
          )}
        </div>
        <div className="w-full bg-white rounded-md py-3">
          <p className="text-[14px] font-semibold">
            Question Details<span className="text-[red] ml-1">*</span>
          </p>
          <QuestionInstructions />
          <div className="flex items-center gap-3">
            {review == false && send.length < 1 && (
              <FileUploader
                setSend={setSend}
                send={send}
                text={"Upload document 1"}
                width={"170px"}
                info="user_doc1"
              />
            )}
            {review == false && send.length < 2 && (
              <FileUploader
                setSend={setSend}
                send={send}
                text={"Upload document 2"}
                width={"170px"}
                info="user_doc2"
              />
            )}
            <div className="mt-3 flex gap-3 items-center ml-3">
              {send.length <= 2 &&
                send?.map((item, index) => {
                  return (
                    <div>
                      {item.hasOwnProperty("bucketKey") ? (
                        <div
                          key={index}
                          className="flex items-center gap-2 cursor-pointer"
                        >
                          {/* <img src="/pdf.svg" alt="pdf" /> */}
                          {/* <p className="font-light text-[#AEA9B1] mb-3">
                                            {item.name}
                                        </p> */}
                          <p
                            onClick={() => downloadAsset(item)}
                            className="font-light text-[#034C85] underline cursor-pointer text-sm"
                            key={index}
                          >
                            {item.name}
                          </p>
                          <p className="mb-1">
                            <CloseIcon
                              onClick={() =>
                                handleDeleteIcon(item.bucketKey, index)
                              }
                            />
                          </p>
                        </div>
                      ) : (
                        <div
                          key={index}
                          className="flex items-center gap-2 cursor-pointer"
                        >
                          {/* <img src="/pdf.svg" alt="pdf" /> */}
                          {/* <p className="font-light text-[#AEA9B1] mb-3">
                                            {item.name}
                                        </p> */}
                          <p
                            className="font-light text-[#034C85] underline cursor-pointer text-sm"
                            key={index}
                          >
                            {item.name}
                          </p>
                          <p className="mb-1">
                            <CloseIcon onClick={() => removeFile(index)} />
                          </p>
                        </div>
                      )}
                    </div>
                  );
                })}

              {/* {commentsAttachments && commentsAttachments.length > 0 && (
                                <div className="flex items-center gap-2 my-2">
                                    {commentsAttachments &&
                                        commentsAttachments.length > 0 &&
                                        commentsAttachments?.map((item, index) => {
                                            return (
                                                <div className="flex items-center gap-2">
                                                    <p
                                                        onClick={() => downloadAsset(item)}
                                                        className="font-light text-[#034C85] underline cursor-pointer text-sm"
                                                        key={index}
                                                    >
                                                        {removeExtension(item)}
                                                    </p>
                                                    <CloseIcon onClick={() => handleDeleteIcon(item)} />
                                                </div>


                                            );
                                        })}
                                </div>
                            )} */}
            </div>
          </div>

          {review === false ? (
            <div className="mt-4 md:mt-2">
              <textarea
                // ref={""}
                value={question || savedQuestion}
                onChange={(e) => {
                  setQuestion(e.target.value);
                  setSaveQuestion(e.target.value);

                  setSaved(false);

                  const value = e.target.value;
                  if (value.length > 500) {
                    setErrors((prevData) => ({
                      ...prevData,
                      question: `You have exceeded the character limit of 500 by ${
                        value.length - 500
                      }`,
                    }));
                  }
                  //  else if (value.length < 50) {
                  //   setErrors((prevData) => ({
                  //     ...prevData,
                  //     question: "Minimum 50 characters required",
                  //   }));
                  // }
                  else {
                    // Clear the error if within the character limit
                    setErrors((prevData) => ({
                      ...prevData,
                      question: "",
                    }));
                  }
                }}
                placeholder="Your question here"
                className={`w-full md:w-[80%] border rounded-md px-2 py-2  ${
                  review
                    ? "border-none cursor-not-allowed pointer-events-none"
                    : ""
                }`}
              />
            </div>
          ) : (
            ""
          )}
          {review === true ? (
            <div className="w-full mt-4">
              {/* <p className="text-[#034C85]">Your Question</p> */}
              {question.length < 1 && (
                <p
                  className="text-[#808080]  w-full md:w-[80%] ml-4"
                  // placeholder="   Your question here"
                >
                  Your question here
                </p>
              )}
              <div className="w-full md:w-[80%]  rounded-md px-0 py-2 ml-4">
                {question}
              </div>
            </div>
          ) : null}
          {errors?.question && (
            <p className="mt-1 text-[red] text-sm">{errors?.question}</p>
          )}
        </div>
        <div className="flex items-center gap-2 mb-5">
          {review === false ? (
            // <TonalButton
            //   text={"Save Draft"}
            //   width={"fit-content"}
            //   onClick={handleSave}
            //   disable={isSaved}
            // />
            <button
              className={`${
                isSaved || (summary.length < 5 && question.length < 10)
                  ? "text-[#AEA9B1] bg-[#EBEBEB] cursor-not-allowed"
                  : "bg-[#DEF] text-[#3A74CA]"
              } rounded  text-sm`}
              onClick={handleSave}
              style={{
                width: "125px",
                // height: "auto",
                // color: "#3A74CA",
                padding: "8px 0px 8px 0px",
              }}
              disabled={isSaved}
            >
              Save Draft
            </button>
          ) : (
            // <TonalButton
            //   text={"Back to Editing"}
            //   width={"fit-content"}
            //   onClick={handleBackEditing}
            // />
            <button
              className="bg-[#DEF] rounded text-white text-sm"
              onClick={handleBackEditing}
              style={{
                width: "125px",
                // height: "auto",
                color: "#3A74CA",
                padding: "8px 0px 8px 0px",
              }}
            >
              Back to Editing
            </button>
          )}
          {review === false ? (
            // <PrimaryButton
            //   text={"Review"}
            //   width={"fit-content"}
            //   onClick={() => setReview(true)}
            // />
            <button
              className="bg-[#084298] rounded text-white text-sm"
              onClick={() => {
                setReview(true);
                handleInputsCheck();
              }}
              style={{
                width: "125px",
                // height: "auto",
                padding: "8px 0px 8px 0px",
              }}
            >
              Review
            </button>
          ) : spinner === false ? (
            // <PrimaryButton
            //   text={spinner ? "Submitting" : "Submit"}
            //   width={"fit-content"}
            //   onClick={handleSubmit}
            // />
            <button
              className="bg-[#69B04B] rounded text-white text-sm"
              onClick={handleSubmit}
              style={{
                width: "125px",
                // height: "auto",
                padding: "8px 0px 8px 0px",
              }}
            >
              {spinner ? "Submitting" : "Submit"}
            </button>
          ) : (
            <Spinner />
          )}
        </div>
      </div>
      {isSaveModalOpen && (
        <SaveDraftModal
          isOpen={isSaveModalOpen}
          onClose={onCloseSavemodal}
          handleSave={handleSave}
          navigate={navigate}
        />
      )}
    </>
  ) : (
    <div className="mt-40">
      <div className="w-full flex justify-center mt-10">
        <img src="/query.svg" alt="" />
      </div>
      <div className="w-full flex justify-center">
        <p className="font-semibold text-2xl mt-4">Thank You for Your Query!</p>
      </div>
      <div className="mt-5 w-full flex justify-center">
        {/* <p className="px-10 font-light">
          Your insights and expertise are greatly appreciated.
        </p> */}
      </div>
      <div className="mt-5 w-full flex justify-center">
        <p className="px-10 font-light">
          If any further updates or clarifications are required, you will be
          contacted through comments. Feel free to use other tools.
        </p>
      </div>
      <div className="flex justify-center items-center mt-2">
        <PrimaryButton
          text={"Go back to the Enquirer Dashboard"}
          width={"fit-content"}
          onClick={handleBackToDashboard}
        />
      </div>
    </div>
  );
};

export default DraftQuestion;
