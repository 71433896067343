import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";
import SessionLogs from "./Tabs/SessionLogs";
import UserMgmt from "./Tabs/UserMgmt";
import ActiveSubs from "./Tabs/ActiveSubs";
import TokenTransaction from "./Tabs/TokenTransaction";
import AllotToken from "./Tabs/AllotToken";
import AdminTransactions from "./Tabs/AdminTransactions";
import SubscriptionHistory from "./Tabs/SubscriptionHistory";
import { useContext } from "react";
import NavContext from "../NavContext";

const Capitalize = (str) => {
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
};

const AdminHome = () => {
  const { twoFactorAuthoInfo } = useContext(NavContext);

  const size = useWindowSize();
  // let param = useParams();
  const [plantCamMap, setPlantCamMap] = useState({});
  const [page, setPage] = useState("activesubs");
  const [isFetchTranChanged, setTranTableChange] = useState(false);
  const [issubscriptionHistoryChanged, setSubHistoryChange] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const tabIndex = searchParams.get("tabIndex")
    ? parseInt(searchParams.get("tabIndex"), 10)
    : 0;

  const tabMapping = {
    0: "activesubs",
    1: "subscription_history",
    2: "user_management",
    3: "session_logs",
    4: "allot_token",
    5: "token_transactions",
    6: "admin_transactions",
  };
  const handleTabIndex = (index) => {
    const tabName = tabMapping[index];
    setSearchParams({ tabIndex: index });
    setPage(tabName);
  };

  useEffect(() => {
    handleTabIndex(tabIndex);
  }, [tabIndex]);

  return (
    <div
      className="pl-0 pr-5  font-roboto flex flex-col rounded-lg"
      style={{ width: size.width >= 768 ? "calc(100vw - 168px)" : "100vw" }}
    >
      <div className="flex justify-between mb-3 mt-6">
        <p className="text-lg sm:text-2xl font-semibold text-[#024D87]">
          {Capitalize("Admin Panel")}
        </p>
      </div>

      {twoFactorAuthoInfo?.is2FAuthenticatedAdmin && ( // rendering the tabs based on 2FA
        <Tabs
          className="bg-white rounded-md px-3 border-2 py-4"
          isLazy={true}
          index={tabIndex}
          onChange={handleTabIndex}
        >
          <TabList className="!flex !border-0">
            <div className="flex items-center gap-4 overflow-x-auto h-14 xl:h-10">
              <Tab
                className={
                  page === "activesubs"
                    ? "!text-[#605D64] !text-xs xl:!text-sm !bg-[#6CABFC] !bg-opacity-20 rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#6CA6FC] w-40 md:w-auto"
                    : "!text-xs xl:!text-sm !text-[#605D64] !border !border-[#EBEBEB] !rounded-full w-40 md:w-auto"
                }
                onClick={() => setPage("activesubs")}
              >
                Subscriptions
              </Tab>
              <Tab
                className={
                  page === "subscription_history"
                    ? "!text-[#605D64] !text-xs xl:!text-sm !bg-[#6CABFC] !bg-opacity-20 rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#6CA6FC] w-24 md:w-auto"
                    : "!text-xs xl:!text-sm !text-[#605D64] !border !border-[#EBEBEB] !rounded-full w-24 md:w-auto"
                }
                onClick={() => setPage("subscription_history")}
              >
                Subscription History
              </Tab>
              <Tab
                className={
                  page === "user_management"
                    ? "!text-[#605D64] !text-xs xl:!text-sm !bg-[#6CABFC] !bg-opacity-20 rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#6CA6FC] w-24 md:w-auto"
                    : "!text-xs xl:!text-sm !text-[#605D64] !border !border-[#EBEBEB] !rounded-full w-24 md:w-auto"
                }
                onClick={() => setPage("user_management")}
              >
                User Management
              </Tab>
              <Tab
                className={
                  page === "session_logs"
                    ? "!text-[#605D64] !text-xs xl:!text-sm !bg-[#6CABFC] !bg-opacity-20 rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#6CA6FC] w-40 md:w-auto"
                    : "!text-xs xl:!text-sm !text-[#605D64] !border !border-[#EBEBEB] !rounded-full w-40 md:w-auto"
                }
                onClick={() => setPage("session_logs")}
              >
                Session Logs
              </Tab>
              <Tab
                className={
                  page === "allot_token"
                    ? "!text-[#605D64] !text-xs xl:!text-sm !bg-[#6CABFC] !bg-opacity-20 rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#6CA6FC] w-24 md:w-auto"
                    : "!text-xs xl:!text-sm !text-[#605D64] !border !border-[#EBEBEB] !rounded-full w-24 md:w-auto"
                }
                onClick={() => setPage("allot_token")}
              >
                Allocate Tokens
              </Tab>
              <Tab
                className={
                  page === "token_transactions"
                    ? "!text-[#605D64] !text-xs xl:!text-sm !bg-[#6CABFC] !bg-opacity-20 rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#6CA6FC] w-24 md:w-auto"
                    : "!text-xs xl:!text-sm !text-[#605D64] !border !border-[#EBEBEB] !rounded-full w-24 md:w-auto"
                }
                onClick={() => setPage("token_transactions")}
              >
                Allocation History
              </Tab>

              <Tab
                className={
                  page === "admin_transactions"
                    ? "!text-[#605D64] !text-xs xl:!text-sm !bg-[#6CABFC] !bg-opacity-20 rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#6CA6FC] w-24 md:w-auto"
                    : "!text-xs xl:!text-sm !text-[#605D64] !border !border-[#EBEBEB] !rounded-full w-24 md:w-auto"
                }
                onClick={() => setPage("admin_transactions")}
              >
                Token Transactions
              </Tab>
            </div>
          </TabList>

          <TabPanels className="!pt-4">
            <TabPanel className="!pl-0 !pr-0">
              <ActiveSubs
                setSubHistoryChange={setSubHistoryChange}
                issubscriptionHistoryChanged={issubscriptionHistoryChanged}
              />
            </TabPanel>
            <TabPanel className="!pl-0 !pr-0">
              <SubscriptionHistory
                issubscriptionHistoryChanged={issubscriptionHistoryChanged}
              />
            </TabPanel>
            <TabPanel className="!pl-0 !pr-0">
              <UserMgmt />
            </TabPanel>
            <TabPanel className="!pl-0 !pr-0">
              <SessionLogs />
            </TabPanel>
            <TabPanel className="!pl-0 !pr-0">
              <AllotToken
                setTranTableChange={setTranTableChange}
                isFetchTranChanged={isFetchTranChanged}
              />
            </TabPanel>
            <TabPanel className="!pl-0 !pr-0">
              <TokenTransaction isFetchTranChanged={isFetchTranChanged} />
            </TabPanel>

            <TabPanel className="!pl-0 !pr-0">
              <AdminTransactions />
            </TabPanel>
          </TabPanels>
        </Tabs>
      )}
    </div>
  );
};

export default AdminHome;
