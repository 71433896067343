import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Radio,
  RadioGroup,
} from "@chakra-ui/react";
import AddIcon from "../../Products/Components/AddIcon";
import { ChevronDownIcon } from "@chakra-ui/icons";
import axios from "axios";
import { baseURL } from "../../../..";
import { useEffect } from "react";
import AddLogo from "./AddLogo";

const ClientSelectLogo = ({
  icon,
  showDisable,
  //   setShowDiscard,
  setIcon,
  setCardLogo,
  defaultIcon,
  auth,
  setLogoList,
  logoList,
  cardLogo,
  setCardLogoPreview,
  toast,
  submitClicked,
  cardLogoPreview,
  setDefaultIcon,
  setFieledsChange,
}) => {
  const getLogos = async () => {
    try {
      const response = await axios.get(baseURL + "draft/v1/fetch/defaultLogo", {
        headers: {
          "Content-Type": "application/json",
          "X-auth-Token": auth,
        },
      });
      const data = response?.data;

      const filteredLogo = data?.filter(
        (el) => el.defaultLogoType === "CLIENT"
      );

      setLogoList(filteredLogo);
    } catch (error) {}
  };
  const dataOptions = {
    icon: {
      type: ["Select default logo", "Upload your own logo"],
    },
  };
  useEffect(() => {
    getLogos();
  }, []);

  //   console.log(logoList);
  return (
    <div className="flex flex-col gap-3 mt-5">
      {/* <p className="text-[#3E3C42] text-lg font-medium">
        Select logo for the product group
      </p> */}

      <div className="">
        <RadioGroup
          onChange={(e) => {
            setIcon(e);
            // setShowDiscard(true);
          }}
          value={icon}
          isDisabled={showDisable}
        >
          <div
            className="flex flex-col md:grid md:grid-cols-3 gap-1 md:gap-2"
            style={{ width: "fit-content" }}
          >
            {dataOptions.icon.type.map((x) => {
              return (
                <div
                  style={{
                    backgroundColor: icon == x ? "#DDEEFF80" : "#FFF",
                    borderRadius: "8px",
                    mb: "12px",
                    // border
                  }}
                >
                  <Radio
                    value={x}
                    py={"8px"}
                    pl={"8px"}
                    pr={"12px"}
                    fontSize={"14px"}
                    fontWeight={500}
                    color={"#3E3C42"}
                    _checked={{
                      bg: "#6CA6FC",
                      borderColor: "#6CA6FC",
                    }}
                    _hover={{
                      borderColor: "#6CA6FC",
                    }}
                    borderColor={
                      // submitClicked && !useDemo
                      //     ? "red.500" :
                      "gray.300"
                      //  {/*submitClicked &&*/}
                    }
                    // isDisabled={showDisable}
                  >
                    {x === "Select default logo"
                      ? "Select default logo"
                      : "Upload your own logo"}
                  </Radio>
                </div>
              );
            })}
          </div>
        </RadioGroup>
      </div>

      {icon === "Select default logo" && (
        <div className="w-full lg:w-[400px]">
          <Menu>
            <MenuButton
              isDisabled={showDisable}
              as={Button}
              rightIcon={<ChevronDownIcon />}
              className="w-full lg:w-[200px] font-normal bg-none"
            >
              {defaultIcon ? (
                <img
                  className="h-[20px] w-[20px]"
                  src={defaultIcon}
                  alt="img"
                />
              ) : (
                "Select one"
              )}
            </MenuButton>
            <MenuList className="w-full lg:w-[400px] grid grid-cols-4 font-normal max-h-[250px] overflow-y-auto">
              {logoList?.map((item, index) => (
                <MenuItem
                  onClick={() => {
                    setDefaultIcon(item.defaultLogoUrl);
                    setFieledsChange(true);
                    setCardLogo(null);
                    // setShowDiscard(true);
                  }}
                >
                  {
                    <img
                      className="h-[40px] w-[40px]"
                      src={item.defaultLogoUrl}
                      alt="logo"
                    />
                  }
                  {/* {item} */}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </div>
      )}

      {icon === "Upload your own logo" && (
        <AddLogo
          logo={cardLogo}
          setLogo={setCardLogo}
          toast={toast}
          logoPreview={cardLogoPreview}
          setLogoPreview={setCardLogoPreview}
          submitClicked={submitClicked}
          desiredRatio={"2 : 2"}
          text={"Supported formats: .png, .svg, .jpeg"}
          info={"2"}
          //   labelText={"Upload icon for the product group"}
          // setShowDiscard={setShowDiscard}
          setFieledsChange={setFieledsChange}
          disable={showDisable}
        />
      )}
    </div>
  );
};

export default ClientSelectLogo;
