import { useEffect, useState } from "react";
import Paginator from "../../../util/VisionUtils/Paginator";

const ImageLibrary = ({ img }) => {
  const [displayData, setDisplayData] = useState([]);

  useEffect(() => {
    if (img.length == 0) setDisplayData([]);
  }, [img]);

  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between">
        {img.length != 0 && (
          <Paginator data={img} limit={6} setDisplayData={setDisplayData} />
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-1">
        {displayData.map((x, id) => {
          return (
            <div
              key={id}
              className="relative w-full object-cover text-center p-2"
            >
              <div className="bg-black rounded-md opacity-70 p-[2px] absolute top-2 left-2">
                <p className="text-white text-xs font-semibold">
                  Slab ID- {x.slab_id}
                </p>
              </div>

              <img
                className="rounded-lg w-full h-[280px]"
                src={x.showImageKey}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ImageLibrary;
