import {
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
} from "@chakra-ui/react";
import {
  Cameraswitch,
  ChevronLeft,
  ChevronRight,
  Close,
} from "@mui/icons-material";
import React, { useState } from "react";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { formatDateTime } from "../utilis/dateTimeFormatter";
import { overallPermeabilityIndex } from "../SinterBelt";
import ReactCardFlip from "react-card-flip";

export const ZoomedImageModal = ({
  imageIndex,
  closeModal,
  openModal,
  height = "50vh",
  data,
}) => {
  const [loading, setLoading] = useState({
    cam2OriginalImage: true,
    originalImage: true,
  });
  const [index, setIndex] = useState(imageIndex);
  const [flip, setFlip] = useState(false);
  const handleNavigate = (value) => {
    if (value === -1) {
      // going on previous
      setIndex((prev) => {
        return (prev - 1 + data.length) % data.length;
      });
    } else if (value === 1) {
      // going on next
      setIndex((prev) => {
        return (prev + 1) % data.length;
      });
    }
  };

  return (
    <Modal
      isOpen={openModal}
      onClose={closeModal}
      isCentered="true"
      returnFocusOnClose={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent
        style={{ borderRadius: "12px" }}
        maxW={["95vw", "90vw", "90vw", "90vw", "70vw"]}
        maxH={["100vh", "90vh", "90vh", "90vh", "90vh"]}
      >
        <ModalHeader
          padding="0px"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          position={"relative"}
        >
          <IconButton
            position={"absolute"}
            rounded={"full"}
            bg={"white"}
            cursor={"pointer"}
            top={-5}
            icon={<Close sx={{ color: "gray" }} />}
            onClick={closeModal}
            boxShadow={"md"}
          />
        </ModalHeader>
        <ModalBody padding={[3, 3, 6, 6, 6, 6]}>
          <div className="relative flex flex-col items-center py-2 md:py-0 justify-center opacity-90 gap-2 px-0 md:px-2">
            <IconButton
              position={"absolute"}
              top={"45%"}
              left={[-3, -3, -5, -6, -6, -6]}
              bg={"white"}
              rounded={"full"}
              size={"sm"}
              boxShadow={"md"}
              icon={<ChevronLeft />}
              zIndex={50}
              onClick={() => handleNavigate(-1)}
            />
            <div className="flex flex-col md:flex-row w-full gap-2 md:gap-0 justify-between">
              <div className="flex flex-row gap-2 justify-start font-bold items-center">
                <p className="text-[#3E3C42] text-sm md:text-base">
                  {data[index]?.sequenceNo}.
                </p>
                <p className="text-[#3E3C42] text-sm md:text-base">
                  {data[index]?.board}
                </p>
                <p className="text-[#79767D] text-xs md:text-base">
                  {data[index]?.time_stamp &&
                    formatDateTime(data[index].time_stamp)}{" "}
                </p>
              </div>
              <div className="flex flex-row gap-2 font-bold text-sm md:text-base rounded-md self-start md:self-auto">
                {data[index]?.maxVNotchWidth ? (
                  <p className="bg-[#F5F5F5] px-1 rounded-md">{`V Notch ${data[index]?.maxVNotchWidth}px`}</p>
                ) : null}
                {data[index]?.maxGrateBarMissingWidth ? (
                  <p className="bg-[#F5F5F5] px-1 rounded-md">
                    {`Grate Bar Missing ${data[index]?.maxGrateBarMissingWidth}px`}
                  </p>
                ) : null}
                {data[index]?.board_permeability_index ? (
                  <p className="bg-[#F5F5F5] px-1 rounded-md">
                    {`${
                      overallPermeabilityIndex[
                        data[index].board_permeability_index
                      ]
                    }(${data[index]?.value}%)`}
                  </p>
                ) : null}
              </div>
            </div>

            <div className="flex flex-col gap-2 max-h-[80vh] overflow-y-auto w-full">
              {loading.cam2OriginalImage && (
                // Skeleton
                <Skeleton
                  height={["150px", "150px", "250px", "250px", "250px"]}
                  width={"100%"}
                  rounded={"md"}
                  border={"1px solid red"}
                />
              )}
              <div
                className="w-full h-[150px] md:h-[250px]"
                style={{
                  display: loading?.cam2OriginalImage ? "none" : "block",
                }}
              >
                <img
                  src={data[index]?.cam2OriginalImage}
                  alt="image_alt"
                  className="w-full h-full rounded-md"
                  onLoad={() =>
                    setLoading((prev) => ({
                      ...prev,
                      cam2OriginalImage: false,
                    }))
                  }
                />
              </div>

              {/* Flipper for original image */}
              {/* Skeleton */}
              {loading.originalImage && (
                <Skeleton
                  height={["200px", "200px", "400px", "400px", "400px"]}
                  rounded={"md"}
                  width={"100%"}
                />
              )}
              <ReactCardFlip
                isFlipped={flip}
                flipDirection="vertical"
                containerClassName="w-full h-full"
                containerStyle={{
                  display: loading?.originalImage ? "none" : "block",
                }}
              >
                {/* original image on front view */}

                <div className="w-full h-full">
                  <TransformWrapper
                    initialScale={1}
                    wheel={{ disabled: true }}
                    doubleClick={{ disabled: true }}
                    pinch={{ disabled: true }}
                  >
                    {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
                      <div className="w-full h-full">
                        <div className="absolute bg-[#616161] text-white flex flex-col top-0 z-50 right-0 text-[18px] gap-0 rounded-md">
                          <button
                            onClick={() => zoomIn()}
                            className="w-full h-full py-0 px-[6px]"
                          >
                            +
                          </button>
                          <button
                            onClick={() => zoomOut()}
                            className="w-full h-full py-0 px-[6px]"
                          >
                            -
                          </button>
                          <button
                            onClick={() => resetTransform()}
                            className="w-full h-full py-0 px-[6px]"
                          >
                            x
                          </button>
                        </div>
                        <IconButton
                          aria-label="flip-image-front"
                          position={"absolute"}
                          bg={"#616161"}
                          size={"sm"}
                          color={"white"}
                          _hover={{ bg: "#616161" }}
                          onClick={() => setFlip((prev) => !prev)}
                          className="absolute right-0 bottom-0 text-white z-50"
                          icon={
                            <Cameraswitch
                              fontSize={"large"}
                              className="p-1"
                            />
                          }
                        />

                        <TransformComponent
                          wrapperStyle={{
                            width: "100%",
                            zIndex: 0,
                            borderRadius: "6px",
                          }}
                          contentStyle={{
                            width: "100%",
                            borderRadius: "6px",
                          }}
                        >
                          <img
                            src={data[index]?.original_image}
                            alt="image_alt"
                            className="w-full h-[200px] md:h-[380px] rounded-md"
                            onLoad={() =>
                              setLoading((prev) => ({
                                ...prev,
                                originalImage: false,
                              }))
                            }
                          />
                        </TransformComponent>
                      </div>
                    )}
                  </TransformWrapper>
                </div>
                {/* annotated image on back view */}
                <div className="w-full h-full">
                  {/* overlay over image to show zone and image icon */}
                  <div className="absolute w-full h-full flex flex-row">
                    <div className="flex flex-col justify-between items-start w-full h-full">
                      <p className="bg-[#616161] rounded-sm text-white px-1">
                        N1
                      </p>
                      <p className="bg-[#616161] rounded-sm text-white px-1">
                        N2
                      </p>
                      <div className="flex flex-col items-start gap-1">
                        <p className="bg-[#616161] rounded-sm text-white px-1">
                          N3
                        </p>
                        <p className="h-9"></p>
                      </div>
                    </div>
                    <div className="flex flex-col justify-between items-end  w-full h-full">
                      <p className="bg-[#616161] rounded-sm text-white px-1">
                        S1
                      </p>
                      <p className="bg-[#616161] rounded-sm text-white px-1">
                        S2
                      </p>
                      <div className="flex flex-col items-end gap-1">
                        <p className="bg-[#616161] rounded-sm text-white px-1">
                          S3
                        </p>
                        <IconButton
                          aria-label="flip-image-back"
                          bg="#616161"
                          color={"white"}
                          size={"sm"}
                          _hover={{ bg: "#616161" }}
                          onClick={() => setFlip((prev) => !prev)}
                          icon={
                            <Cameraswitch
                              fontSize="large"
                              className="p-1"
                            />
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <img
                    src={data[index]?.annotated_image}
                    alt="annotated_image_alt"
                    className="w-full h-[200px] md:h-[380px] rounded-md"
                  />
                </div>
              </ReactCardFlip>
            </div>
            <IconButton
              position={"absolute"}
              top={"45%"}
              right={[-3, -3, -5, -6, -6, -6]}
              bg={"white"}
              rounded={"full"}
              size={"sm"}
              boxShadow={"md"}
              icon={<ChevronRight />}
              zIndex={50}
              onClick={() => handleNavigate(1)}
            />
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
