import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import { useState, useEffect, useContext } from "react";
import { baseURL } from "../../../index";
import { useParams } from "react-router-dom";
import axios from "axios";
import ExlCsvDownload from "../../../util/VisionUtils/ExlCsvDownload";
import ReportTable from "../Tables/ReportTable";
import NavContext from "../../NavContext";
import { Select, Spinner } from "@chakra-ui/react";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";

const Report = ({ plantId, cameraId, disable, plantCamMap }) => {
  const param = useParams();
  const { auth } = useContext(NavContext);
  const [report, setReport] = useState([]);
  const [downloadData, setDownloadData] = useState({});
  const [reportChanging, setReportChanging] = useState(false);
  const [liveFeed, setLiveFeed] = useState(true);
  const [reportDataType, setReportDataType] = useState("live");
  const [fromTime, setFromTime] = useState(
    new Date(
      new Date().getTime() - 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000
    ).toISOString()
  );
  const [toTime, setToTime] = useState(
    new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString()
  );

  const ReportApi = async () => {
    const requestData = JSON.stringify({
      clientId: param.clientId.toLowerCase(),
      useCase: "WORKFORCESAFETY",
      plantName: "khandala",
      cameraGpId: "all",
      startDate: new Date(fromTime).getTime() + 5.5 * 60 * 60 * 1000,
      endDate: new Date(toTime).getTime() + 5.5 * 60 * 60 * 1000,
    });

    const response = await axios
      .post(
        baseURL + "vision/v1/workforceMonitoring/report/overview/",
        requestData,
        {
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      )
      .then((response) => {
        setReport((prev) => {
          return response.data;
        });
        setReportChanging(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DownloadApi = async () => {
    const requestData = JSON.stringify({
      clientId: param.clientId.toLowerCase(),
      useCase: "WORKFORCESAFETY",
      plantName: "khandala",
      cameraGpId: "all",
      startDate: new Date(fromTime).getTime() + 5.5 * 60 * 60 * 1000,
      endDate: new Date(toTime).getTime() + 5.5 * 60 * 60 * 1000,
    });
    const response = await axios
      .post(
        baseURL + "vision/v1/workforceMonitoring/report/detail/",
        requestData,
        {
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      )
      .then((response) => {
        setDownloadData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const LiveFeedCustom = (value) => {
    console.log("value", value);
    if (value == "custom") {
      setLiveFeed(false);
    } else {
      setLiveFeed(true);
    }
    setReportDataType(value);
  };

  const handleClick = () => {
    setReportChanging(true);
    ReportApi();
    DownloadApi();
  };

  useEffect(() => {
    if (liveFeed) {
      // Set up an interval to call handleClick every 1 minute
      const newFromTime = new Date(
        new Date().getTime() - 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000
      ).toISOString();

      setFromTime(newFromTime);

      const newToTime = new Date(
        new Date().getTime() + 5.5 * 60 * 60 * 1000
      ).toISOString();

      setToTime(newToTime);
      const intervalId = setInterval(() => {
        const newFromTime = new Date(
          new Date().getTime() - 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000
        ).toISOString();

        setFromTime(newFromTime);

        const newToTime = new Date(
          new Date().getTime() + 5.5 * 60 * 60 * 1000
        ).toISOString();

        setToTime(newToTime);
      }, 60 * 1000);

      // Clean up the interval when the component unmounts or when liveFeed is false
      return () => clearInterval(intervalId);
    }
  }, [liveFeed]);

  useEffect(() => {
    if (liveFeed) {
      handleClick();
    }
  }, [toTime]);

  return (
    <div className="relative flex flex-col">
      <div className="absolute left-0 right-0 flex justify-center">
        <div className="p-5 pl-6 pr-6 gap-2 flex flex-col lg:flex-row items-center bg-white rounded-xl shadow-md">
          <div className="min-w-[110px]">
            <Select
              placeholder={disable && plantId}
              borderColor="#CAC5CD"
              variant="outline"
              color={"white"}
              className="!text-sm !font-medium  !bg-[#084298] !hover:bg-[#3A74CA]"
              isDisabled={disable}
              // className="!text-center !p-[10px] !pl-4 !pr-4 !w-[130px] !h-[40px] !text-white text-xs md:text-base font-medium !bg-[#084298] !rounded-full"
              onChange={(e) => LiveFeedCustom(e.target.value)}
              value={reportDataType}
            >
              <option key="All Plants" value="live">
                {reportChanging ? <Spinner /> : "Live Feed"}
              </option>
              <option key="All Plants" value="custom">
                Custom
              </option>
            </Select>
          </div>
          <div>
            <FloatingInput
              text="From"
              type="datetime-local"
              setDateTime={setFromTime}
              value={fromTime?.slice(0, 16)}
              disabled={liveFeed}
              max={toTime?.slice(0, 16)}
            />
          </div>
          <div>
            <FloatingInput
              text="To"
              type="datetime-local"
              setDateTime={setToTime}
              value={toTime?.slice(0, 16)}
              disabled={liveFeed}
              min={fromTime?.slice(0, 16)}
              max={new Date().toISOString().slice(0, 16)}
            />
          </div>
          {!liveFeed && (
            // <button
            //   // className="text-center p-[10px] pl-4 pr-4 text-white text-xs md:text-base font-medium bg-[#084298] rounded-full"
            //   className="text-center py-2 px-2 text-white text-xs md:text-base font-medium bg-[#6CA6FC] rounded-md min-w-[80px]"
            //   onClick={handleClick}
            // >
            //   {reportChanging ? <Spinner /> : "Apply"}
            // </button>
            <PrimaryButton
              width={"130px"}
              height={"40px"}
              text={reportChanging ? <Spinner /> : "Show report"}
              onClick={handleClick}
              disable={!fromTime || !toTime}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col gap-4 mt-[100px] md:mt-26 lg:mt-2 pt-[120px]  lg:pt-[87px]  bg-white rounded-xl justify-start">
        <div className="flex justify-end gap-2 pl-4 pr-6 mr-3 overflow-x-auto">
          {downloadData.hasOwnProperty("order") && (
            <ExlCsvDownload
              order={downloadData.order}
              data={downloadData.data}
            />
          )}
        </div>
        {report.hasOwnProperty("data") && report.data.length > 0 && (
          <ReportTable rowData={report.data} />
        )}
      </div>
    </div>
  );
};

export default Report;
