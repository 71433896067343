import React, { useState, useEffect, useRef, useContext } from "react";
import { Spinner } from "@chakra-ui/react";

import { useWindowSize } from "@uidotdev/usehooks";

import { useNavigate, useParams } from "react-router-dom";

import { SpinnerIcon } from "@chakra-ui/icons";
import axios from "axios";

import NavContext from "../../NavContext";
import { baseURL } from "../../..";
import ReadMore from "../../community/ReadMore";

import AiAvisorHistoryTable from "../../community/AiAdvisorHistoryTable";
import AdminTransactionsTable from "../Tables/AdminTransactionsTable";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import AdminTokensData from "../AdminTokensData";

const AdminTransactions = ({ clientOrg }) => {
  const size = useWindowSize();
  // const { clientOrg, mode } = useParams();
  const { auth } = useContext(NavContext);

  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const [totalTokens, setTotalTokens] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const [startBalance, setStartBalance] = useState(0);
  const [endBalance, setEndBalance] = useState(0);
  const [tokensPurchase, setTokensPurchased] = useState(0);
  const [tokensUsed, setTokensUsed] = useState(0);

  const [allocated, setAllocated] = useState(0);
  const [unallocated, setUnAllocated] = useState(0);
  const [avgConsumption, setAvgConsumption] = useState(0);

  const [stateChanging, setStateChanging] = useState(false);
  const [fromTime, setFromTime] = useState(
    new Date(
      new Date().getTime() - 30 * 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000
    )
      .toISOString()
      .slice(0, 10)
  );
  const [toTime, setToTime] = useState(
    new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10)
  );
  const [fromTimeInMs, setFromTimeInMs] = useState("");
  const [toTimeInMs, setToTimeInMs] = useState("");

  const setEndOfDay = (dateString) => {
    const parsedDate = new Date(dateString);
    // Set time to the end of the day (23:59:59.999)
    parsedDate.setHours(23, 59, 59, 999);
    return parsedDate;
  };
  const setStartOfDay = (dateString) => {
    const parsedDate = new Date(dateString);
    // Set time to the start of the day (00:00:00.000)
    parsedDate.setHours(0, 0, 0, 0);
    return parsedDate;
  };

  useEffect(() => {
    setFromTimeInMs(setStartOfDay(fromTime).getTime());
    setToTimeInMs(setEndOfDay(toTime).getTime());
  }, [fromTime, toTime]);

  useEffect(() => {
    fetchTransactionsHistory();
  }, [clientOrg]);

  const [homebadge, setHomebadge] = useState({
    badgeLogo: "/Common/batchok.svg",
    badgeTag: "Proficient Inquirer",
  });

  const handleClick = () => {
    setStateChanging(false);
    fetchTransactionsHistory();
  };

  const series = [
    {
      name: "Passed",
      type: "column",
      data: [10, 20, 10, 11, 12, 15, 16],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      stacked: false,
      // stackType: "100%",

      toolbar: {
        show: false,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          // reset: true | '<img src="/static/icons/reset.png" width="20">',
          reset: false,
        },

        // autoSelected: "zoom",
      },
    },
    stroke: {
      width: [0, 0, 0],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
      },
    },
    colors: ["#CDEEBF", "#EF6F12", "#6CA6FC"],

    // fill: {
    //   opacity: [0.8, 0.8, 0.8],
    //   gradient: {
    //     inverseColors: false,
    //     shade: "light",
    //     type: "vertical",
    //     opacityFrom: 0.85,
    //     opacityTo: 0.55,
    //     stops: [0, 100, 100, 100],
    //   },
    // },

    markers: {
      size: 0,
    },
    xaxis: {
      type: "category",
      categories: [
        "01/01/2003",
        "02/01/2003",
        "03/01/2003",
        "04/01/2003",
        "05/01/2003",
        "06/01/2003",
        "07/01/2003",
      ],
      labels: {
        show: true,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: true,
        style: {
          fontSize: "12px",
          fontWeight: 300,
          cssClass: "apexcharts-xaxis-label",
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    grid: {
      strokeDashArray: 5,
    },
    yaxis: {
      tickAmount: 4,
      // labels: {
      //   formatter: function (y) {
      //     return y + "%";
      //   },
      // },
    },
  };
  const [displayData, setDisplayData] = useState([]);

  const fetchTransactionsHistory = async () => {
    setLoading(true);
    const param = {
      startDate: fromTimeInMs,
      endDate: toTimeInMs,
      organisation: clientOrg || "",
    };
    try {
      const response = await axios.get(
        baseURL + `token-wallet/v1/token-transaction`,
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );

      // Filter out entries with description "Token Allocation"
      const filteredTableData = response?.data?.transactionDetail.filter(
        (entry) => entry.description !== "Token Allocation"
      );

      setStartBalance(response?.data?.startingBalance);
      setEndBalance(response?.data?.endingBalance);
      setTokensPurchased(response?.data?.totalPurchasedToken);
      setTokensUsed(response?.data?.totalSpentToken);
      setAllocated(response?.data?.totalAllotedToken);
      setUnAllocated(response?.data?.orgBalance);
      setAvgConsumption(response?.data?.averageConsumption);
      setTableData(filteredTableData);
      // setTableData(response?.data?.transactionDetail);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching experts:", error);
    }
  };

  return (
    <div className="mt-[4vh] w-full flex flex-col gap-1 ">
      {/* bottom white background part */}
      <div className="flex flex-col w-full h-full gap-[8px]  bg-white px-4 rounded-xl   ">
        <div className=" pr-6 gap-6 flex flex-col md:flex-row items-center">
          {/* Date Picker */}
          <div>
            <FloatingInput
              text="Start time "
              type="date"
              max={toTime}
              setDateTime={setFromTime}
              value={fromTime}
            />
          </div>
          <div>
            <FloatingInput
              text="End time"
              type="date"
              min={fromTime}
              max={new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
                .toISOString()
                .slice(0, 10)}
              setDateTime={setToTime}
              value={toTime}
            />
          </div>
          <button
            className="text-center p-[8px] pl-4 pr-4 text-white text-xs md:text-base font-medium bg-[#084298] rounded-lg"
            onClick={handleClick}
          >
            {isLoading ? <Spinner speed="0.65s" size="sm" /> : "Show"}
          </button>
        </div>
        <AdminTokensData
          startBalance={startBalance}
          endBalance={endBalance}
          tokensUsed={tokensUsed}
          tokensPurchase={tokensPurchase}
          avgConsumption={avgConsumption}
          allocated={allocated}
          unallocated={unallocated}
        />
        <div>
          <p className="text-[18px] text-[#605D64] font-medium">
            Recent Transactions
          </p>
        </div>
        <div>
          {isLoading ? (
            <div className="ml-[50%]">
              <SpinnerIcon speed="0.65s" />
            </div>
          ) : (
            <React.Fragment>
              {tableData && tableData.length !== 0 ? (
                <AdminTransactionsTable rowData={tableData} />
              ) : (
                <p className="ml-[45%]">No data available</p>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminTransactions;
