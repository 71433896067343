import { useWindowSize } from "@uidotdev/usehooks";
import { formatDateTimeLocale } from "../utilis/utilityFunctions";
import ReactApexChart from "react-apexcharts";

export const ParameterLineChart = ({ title = "", data = [], times = [] }) => {
  const size = useWindowSize();
  const width = size.width < 768 ? 700 : "100%";
  const series = [
    {
      name: `${title}`,
      type: "line",
      data: data,
    },
  ];
  const options = {
    chart: {
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
        export: {
          csv: {
            dateFormatter(timestamp) {
              return `${formatDateTimeLocale(timestamp)}`;
            },
          },
        },
      },
      animations: {
        enabled: false,
      },
    },
    grid: {
      borderColor: "#e7e7e7",
      strokeDashArray: 6,
      padding: {
        top: -5,
        right: 5,
        bottom: 0,
        left: 5,
      },
    },
    markers: {
      size: 0,
    },
    xaxis: {
      categories: times,
      type: "datetime",
      labels: {
        show: true,
        datetimeUTC: false,
        style: {
          fontSize: "11px",
        },
      },
    },
    yaxis: {
      type: "numeric",
      title: {
        text: "Value",
        style: {
          color: "#938F96",
          fontSize: "12px",
          fontWeight: 500,
        },
      },
      labels: {
        formatter: function (val) {
          return val.toFixed(0);
        },
      },
    },
    colors: ["#1C56AC"],
    tooltip: {
      x: {
        format: "dd MMM yyyy HH:mm:ss",
      },
      y: {
        formatter: function (val) {
          return val.toString();
        },
      },
    },
    dataLabel: {
      enabled: false,
    },

    stroke: {
      curve: "straight",
      width: [2, 0],
      dashArray: [0, 6],
      colors: ["#1C56AC"],
    },
    legend: {
      show: false,
    },
    noData: {
      text: "No Data!",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#757575",
        fontSize: "16px",
      },
    },
  };
  return (
    <div className={`w-full h-full  rounded-md bg-white`}>
      <div
        id="chart"
        className={`h-[398px] w-full ${
          width === 700 ? "overflow-x-auto" : "overflow-hidden"
        }`}
      >
        <ReactApexChart
          options={options}
          series={series}
          height={"100%"}
          width={width}
        />
      </div>
    </div>
  );
};
