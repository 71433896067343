import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";
import Report from "./tabs/Report";
import { SodaAshOptimizer } from "./tabs/SodaAshOptimizer";

/*------------------------ BASE URL FOR SODA ASH AND LIME OPTIMIZER -----------------------*/

export const sodaAshAndLimeOptimizerBaseUrl = "https://hzl-soda-ash.backend-ripik.com/";

/*------------------------ BASE URL FOR SODA ASH AND LIME OPTIMIZER -----------------------*/

const SodaAshAndLimeOptimizer = () => {
  const size = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlParamTabIndex = !isNaN(searchParams.get("tabIndex"))
    ? Number(searchParams.get("tabIndex"))
    : 0;

  // handling tab change
  const handleTabsChange = (index) => {
    if (index !== urlParamTabIndex) {
      setSearchParams({ tabIndex: index }, { replace: true });
    }
  };

  // tablist
  const tabs = [
    {
      label: "Soda Ash Optimizer",
      index: 0,
      component: <SodaAshOptimizer />,
    },
    {
      label: "Report",
      index: 1,
      component: <Report />,
    },
  ];
  useEffect(() => {
    const originalTitle = document.title;
    document.title = "Soda Ash and Lime Optimizer | IntelliVerse";
    return () => {
      document.title = originalTitle;
    };
  }, []);

  // use effect to look for change in tab-index
  useEffect(() => {
    handleTabsChange(urlParamTabIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParamTabIndex]);

  return (
    <div
      className="flex-col -mx-2 md:mx-0 px-1 rounded-lg lex"
      style={{ width: size.width >= 768 ? "calc(100vw - 168px)" : "100vw" }}
    >
      <div
        className="top-5 right-0 fixed bg-white w-[180px] h-[30px]"
        style={{ zIndex: 1000 }}
      >
        <img
          className="top-[-20px] right-2 absolute w-[120px] h-[50px]"
          src="/clientLogos/hzl.png"
          alt="clientLogo"
        />
      </div>
      <div className="flex justify-start items-center gap-2 mt-6 mb-3">
        <Link
          to={`/optimus/sodaashandlimeoptimizer`}
          style={{
            textDecoration: "none",
          }}
        >
          <img
            src="/backtick.svg"
            alt="backtick"
          />
        </Link>
        <p className="font-semibold text-[#024D87] text-lg sm:text-2xl">
          {"Soda Ash and Lime Optimizer"}
        </p>
      </div>
      <Tabs
        isLazy={true}
        index={urlParamTabIndex}
        onChange={handleTabsChange}
        paddingRight={{ base: "8px", md: "0px", lg: "0px" }}
      >
        <TabList className="!flex !border-0">
          <div className="flex items-center gap-4 h-14 md:h-10 overflow-x-auto">
            {tabs &&
              tabs.map((tab, index) => (
                <Tab
                  key={`${tab.label}-${index}`}
                  minW={"fit-content"}
                  className={`${
                    urlParamTabIndex === tab.index
                      ? "!text-black !text-xs sm:!text-sm !bg-white rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#79767D]"
                      : "!text-xs sm:!text-sm !text-[#938F96] !border-0"
                  }`}
                  onClick={() => handleTabsChange(tab.index)}
                >
                  {tab.label}
                </Tab>
              ))}
          </div>
        </TabList>
        <TabPanels>
          {tabs &&
            tabs.map((tab, index) => (
              <TabPanel
                key={`${tab.label}-${index}`}
                className="!pr-0 !pl-0"
              >
                {tab.component}
              </TabPanel>
            ))}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default SodaAshAndLimeOptimizer;
