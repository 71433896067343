import ReactApexChart from "react-apexcharts";
import { useState, useEffect } from "react";

const CalMax = (data) => {
  let values = data?.map((item) => {
    return item?.precision;
  });
  return Math.max(...values);
};

const LabelBar = ({ data = [] }) => {
  const [series, setSeries] = useState([
    {
      data: [],
    },
  ]);

  useEffect(() => {
    setSeries((prev) => {
      let d = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      data.forEach((item) => {
        let val;
        if (!item.hasOwnProperty("annotationData"))
          val = (item.precision * 100)?.toFixed(2);
        else val = (CalMax(item.annotationData) * 100)?.toFixed(2);
        let idx = Math.ceil(val / 10) - 1;
        d[idx]++;
      });
      let newData = [
        {
          name: "barPlot",
          type: "column",
          data: d,
        },
        {
          name: "linePlot",
          type: "area",
          data: d,
        },
      ];
      return newData;
    });
  }, [data]);

  const options = {
    chart: {
      type: "area",
    },
    plotOptions: {},
    stroke: {
      width: [0, 2],
    },
    fill: {
      opacity: [1, 0.35],
    },
    dataLabels: {
      enabled: true,
      enabledOnSeries: [1],
      style: {
        fontSize: "14px",
        fontWeight: "bold",
        colors: ["#ffc107"],
      },
      background: {
        enabled: true,
      },
    },
    legend: {
      show: false,
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return value;
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value;
        },
      },
      title:{
        text: 'No. of files'
      }
    },
    colors: ["#5193f6", "#ffc107"],
    xaxis: {
      categories: [
        "0%-10%",
        "10%-20%",
        "20%-30%",
        "30%-40%",
        "40%-50%",
        "50%-60%",
        "60%-70%",
        "70%-80%",
        "80%-90%",
        "90%-100%",
      ],
      labels: {
        formatter: function (value) {
          return value;
        },
        maxHeight: 60
      },
      title:{
        text: 'Confidence bins'
      }
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      height={"200px"}
      width={"100%"}
    />
  );
};

export default LabelBar;
