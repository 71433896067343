import ReactApexCharts from "react-apexcharts";
import { overallPermeabilityIndex } from "../../SinterBelt/SinterBelt";
import { useWindowSize } from "@uidotdev/usehooks";

export const BeltPermeabilityTrendLineChart = ({
  chartData,
  color = ["#1C56AC"],
}) => {
  const size = useWindowSize();
  const width = size.width < 768 ? 700 : "100%";
  const yAxisLabelMapping = {
    0: ["Belt", "Downtime"],
    1: ["Maintenance", "Required"],
    2: "Poor",
    3: "Average",
    4: "Good",
    5: "Excellent",
  };

  const series = [
    {
      name: "Overall Belt Permeability",
      data: chartData?.overallBeltPermeability,
    },
  ];

  //chart options
  const options = {
    tooltip: {
      shared: true,
      intersect: false,
      x: {
        format: "dd MMM yyyy HH:mm",
      },
      y: {
        formatter: function (y) {
          return overallPermeabilityIndex[y];
        },
      },
      style: {
        fontSize: "12px",
      },
    },
    chart: {
      type: "line",
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: false,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: true,
        },
      },
      animations: {
        enabled: false,
      },
    },
    xaxis: {
      categories: chartData?.timeStamp,
      type: "datetime",
      labels: {
        show: true,
        offsetX: 5,
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      title: {
        text: "",
        style: {
          fontSize: "12px",
          color: "#000000",
          fontWeight: 500,
          fontFamily: "Poppins",
        },
      },
      tickAmount: 5,
      labels: {
        style: {
          fontSize: "13px",
        },
        offsetY: -5,
        offsetX: 5,
        formatter: function (value) {
          return yAxisLabelMapping[value];
        },
      },
      min: 0,
      max: 5,
    },

    colors: color,
    stroke: {
      curve: "straight",
      width: [3],
    },
    grid: {
      borderColor: "#e7e7e7",
      strokeDashArray: 6,
    },
    legend: {
      show: true,
      position: "right",
      fontSize: "14px",
    },
  };

  return (
    <div
      id="chart"
      className={`h-full w-full ${
        width === 700 ? "overflow-x-scroll" : "overflow-hidden"
      }`}
    >
      <ReactApexCharts
        options={options}
        series={series}
        type="line"
        height="100%"
        width={width}
      />
    </div>
  );
};

export const VNotchTrendLineChart = ({
  chartData,
  color = ["#1C56AC", "#6CA6FC"],
}) => {
  const size = useWindowSize();
  const width = size.width < 768 ? 700 : "100%";
  const series = [
    {
      name: "V Notch",
      data: chartData?.vNotch,
    },
    {
      name: "Grate Bar Missing",
      data: chartData?.grateBarMissing,
    },
  ];

  const options = {
    chart: {
      type: "line",
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: false,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: true,
        },
      },
      animations: {
        enabled: false,
      },
    },
    xaxis: {
      categories: chartData?.timeStamp,
      type: "datetime",
      labels: {
        show: true,
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      title: {
        style: {
          fontSize: "14px",
          color: "#000000",
          fontWeight: 500,
          fontFamily: "Poppins",
        },
      },
      labels: {
        style: {
          fontSize: "13px",
        },
      },
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy HH:mm",
      },
    },
    colors: color,
    stroke: {
      curve: "straight",
      width: [3, 3],
    },
    grid: {
      borderColor: "#e7e7e7",
      strokeDashArray: 6,
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "left",
      fontSize: "13px",
    },
  };

  return (
    <div
      id="chart"
      className={`h-full w-full ${
        width === 700 ? "overflow-x-scroll" : "overflow-hidden"
      }`}
    >
      <ReactApexCharts
        options={options}
        series={series}
        type="line"
        height="100%"
        width={width}
      />
    </div>
  );
};
