import axios from "axios";
import { baseURL } from "../../../index";
import NavContext from "../../NavContext";
import { useContext, useEffect, useState } from "react";
import ExlCsvDownload from "../../../util/VisionUtils/ExlCsvDownload";
import FeedCard from "../Components/FeedComponents/FeedCard";
import {
  Skeleton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
} from "@chakra-ui/react";
import { useParams, useSearchParams } from "react-router-dom";
import FeedPageParent from "../Components/FeedPageComponents/FeedPageParent";
import CameraFeed from "../Components/CommonComponents/CameraFeed";
import PurchaseOrder from "../Components/FeedComponents/PurchaseOrder";
import TableSkeleton from "../../SinterBelt/components/LoadingState/TableSkeleton";
import { useWindowSize } from "@uidotdev/usehooks";

const FeedPage = ({ material, clientId, initialData = {} }) => {
  const { auth } = useContext(NavContext);
  const [data, setData] = useState([]);
  // const size=useWindowSize();
  const [loading, setLoading] = useState(false);
  const [poDetail, setPoDetail] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const toast = useToast();

  //   variable for overall tabs switching
  const urlParamTabIndex = !isNaN(searchParams.get("tabIndex"))
    ? Number(searchParams.get("tabIndex"))
    : 0;

  // variable for mixer tabs
  const urlParamMixerIndex = !isNaN(searchParams.get("mixerIndex"))
    ? Number(searchParams.get("mixerIndex"))
    : 0;

  // handling tab change
  const handleTabsChange = (index) => {
    if (index !== urlParamMixerIndex) {
      // only switch the tabs if the clicked tab is different froom the active one
      setSearchParams(
        { tabIndex: urlParamTabIndex, mixerIndex: index },
        { replace: true }
      );
    }
  };

  //    listen for change in mixer index in tabs
  useEffect(() => {
    handleTabsChange(urlParamMixerIndex);
  }, [urlParamMixerIndex]);

  const payload = {
    order: ["concentrate", "recipeQuantity", "actualQuantity"],
    poNumber: null,
    poStart: 1722505271000,
    poEnd: null,
    lastModifyAt: 1723029872000,
    tsd_mixer: [
      {
        recIdList: [
          "b_e_r_m_o_c_o_l_62111f89d59842bbbde9f491b93e997c",
          "b_e_r_m_o_c_o_l_75859ab214734458bc0b1c0dbc0d2e8a",
          "b_e_r_m_o_c_o_l_2d133a45256349e7bc7984787f9afb4b",
          "b_e_r_m_o_c_o_l_2036eb5d93934016a17c2b3e7eed462f",
          "b_e_r_m_o_c_o_l_5f54dba0ce9b41d886e4f532f12e1b59",
          "b_e_r_m_o_c_o_l_a9c670614de94debb8ad47f7e7e7d012",
          "b_e_r_m_o_c_o_l_0707e1f2620b4fe2bbb59f23901ebda9",
          "b_e_r_m_o_c_o_l_7f2896a79160404fa6ed77ad5f94f0f3",
          "b_e_r_m_o_c_o_l_f598d91a345e479690f4c2112fb05e31",
          "b_e_r_m_o_c_o_l_1db8d9d06a6143faa045afe57bede8e4",
          "b_e_r_m_o_c_o_l_30313532d4d046c28a26ef94c7d5a88e",
          "b_e_r_m_o_c_o_l_0b8fb227ddfb496eaed353fd13c9b78c",
          "b_e_r_m_o_c_o_l_322082f98e184d5f85e810b7ec10a049",
          "b_e_r_m_o_c_o_l_447ae031e1a54c51a01de1f10488b682",
          "b_e_r_m_o_c_o_l_cda031d880a04666acd70d4dac96da6e",
          "b_e_r_m_o_c_o_l_1028a2d2a568473c8339600b27b1e18f",
          "b_e_r_m_o_c_o_l_c3097323d1c746babe0c1f3b7c3bb8bf",
          "b_e_r_m_o_c_o_l_efb600a837784b6c8c49809ddad95595",
          "b_e_r_m_o_c_o_l_737a9a7e0af044188924f361a3a520b5",
          "b_e_r_m_o_c_o_l_c7509d9fe3394aa58fd66e6653396caa",
          "b_e_r_m_o_c_o_l_0012af3770c84720aa9a91c813a3f172",
          "b_e_r_m_o_c_o_l_b438e19b964e485e8a9fb35cdce27d91",
          "b_e_r_m_o_c_o_l_6e15e4085fdc44adb7503ddf09ce6ca8",
          "b_e_r_m_o_c_o_l_0ead70baae3a4d25b4d4e90bb8456dde",
          "b_e_r_m_o_c_o_l_6699f23864004b85a1247c49fb0c699c",
          "b_e_r_m_o_c_o_l_5b1b8b2d3f0644c8bbcc8ed6e0c7cea9",
          "b_e_r_m_o_c_o_l_d881674e06c6440481be16a463326ad2",
          "b_e_r_m_o_c_o_l_51541b00b32d49928afaae1d523a945b",
          "b_e_r_m_o_c_o_l_b12b365d381148f1b0027360dd4b0909",
          "b_e_r_m_o_c_o_l_ed47bade9bde488583975215b67d18c4",
          "b_e_r_m_o_c_o_l_95759bdede1f4b9293ac4a38530f61e5",
          "b_e_r_m_o_c_o_l_eff81a8d04934748a702178ff7b505a0",
          "b_e_r_m_o_c_o_l_b2e5171f31ca40cd8deadc682da7d78e",
          "b_e_r_m_o_c_o_l_26d6dd29ee3f4485b9765fa41a5a9f2c",
          "b_e_r_m_o_c_o_l_e2a05fb7da9a49d5b3ccc00b088a451b",
          "b_e_r_m_o_c_o_l_781c48f31ded4e3a91a33949b319e181",
          "b_e_r_m_o_c_o_l_71ee25c93b11416e8a064d1a77248134",
          "b_e_r_m_o_c_o_l_042b7266c0fd43358c20283e85265e30",
          "b_e_r_m_o_c_o_l_9d6fae40006f4860a1e4124e475e4f0e",
          "b_e_r_m_o_c_o_l_e2f5102b0a2a4607b759909db7dd251a",
          "b_e_r_m_o_c_o_l_360990908497464a9e43acd2769e7875",
          "b_e_r_m_o_c_o_l_a6c27a5617564caa8f0f4be350863a47",
          "b_e_r_m_o_c_o_l_6027df52813b4d12b36454bf5269a01a",
          "b_e_r_m_o_c_o_l_09bf625d8ead4c4fa0400a782e7fc33a",
          "b_e_r_m_o_c_o_l_b422f3fce41243d3a28e95e44bef96bb",
          "b_e_r_m_o_c_o_l_72c6ac5e4c8d419293ab6f9894dba352",
          "b_e_r_m_o_c_o_l_1a480be8c0684b3796f54c8571fd8037",
          "b_e_r_m_o_c_o_l_72e0ebd7aab24e61acf8ef6c0fc8202d",
          "b_e_r_m_o_c_o_l_d1f619d1f7014b79a78c4058331d0abb",
          "b_e_r_m_o_c_o_l_c36faa2af32648ac83418580e20850d6",
          "b_e_r_m_o_c_o_l_826d29c062594f69ae8afe146a6b2196",
          "b_e_r_m_o_c_o_l_ffd4beab1e104afd8e06b52ebc3bd0cf",
          "b_e_r_m_o_c_o_l_e332de5b7ef84c2e97e35abd75bd8b67",
          "b_e_r_m_o_c_o_l_3e5eb488960a46b1a35e62c249621237",
          "b_e_r_m_o_c_o_l_d0d8f35672674f569877d7d29fd23be5",
          "b_e_r_m_o_c_o_l_87c0022b7d1d428fadb4fe0590c98e0b",
          "b_e_r_m_o_c_o_l_7a4eb673253f41239d6c04cc5490a8d1",
          "b_e_r_m_o_c_o_l_203d7b50f58d4240ba9ca8ed76ef1f8f",
          "b_e_r_m_o_c_o_l_d32a76fa0470477baef82878ad66f443",
          "b_e_r_m_o_c_o_l_a41e4a31b7994df8a57482da4570169e",
          "b_e_r_m_o_c_o_l_e400bf98c4364b00b133109d7fc3278b",
          "b_e_r_m_o_c_o_l_5f54866c2ec24f5eb6b8636fc0f3a83c",
          "b_e_r_m_o_c_o_l_d07722365e824f55a4b21115346cd335",
          "b_e_r_m_o_c_o_l_e3f3696413a7482bb963a2124c9f63d7",
          "b_e_r_m_o_c_o_l_f31f5e11d4b54d81a7b14bc0f57627b6",
          "b_e_r_m_o_c_o_l_cb5dbdedaa044a8190d112a8d237622e",
          "b_e_r_m_o_c_o_l_d9d30a379e3f418da564caa468284472",
          "b_e_r_m_o_c_o_l_55d62fd8a7d443e2b911fae3e8fef925",
          "b_e_r_m_o_c_o_l_e5fe591264c54fdcbafdf64e57dc05ce",
          "b_e_r_m_o_c_o_l_4441873ac5eb43d996c66c706d7c95d6",
          "b_e_r_m_o_c_o_l_e7a31cefe2f54716baba42a177ef3b12",
          "b_e_r_m_o_c_o_l_a6cf18b5541e4a479f79d77fbe79678d",
          "b_e_r_m_o_c_o_l_2f6b1c6095c5466badf1ed3cfab605f7",
          "b_e_r_m_o_c_o_l_9d3818ed548a4a96a6c20220f2f46481",
          "b_e_r_m_o_c_o_l_2e13862be7bc4e1e997d5440a09ef5d1",
          "b_e_r_m_o_c_o_l_7920f33551944b2dbf6c61422cdb6c17",
          "b_e_r_m_o_c_o_l_cdbcc230459d47b9af8b813e1c0b66db",
          "b_e_r_m_o_c_o_l_01af76d043214d258a634a0cecac68e4",
          "b_e_r_m_o_c_o_l_333c776d9792486a9d76ebfe26013d36",
          "b_e_r_m_o_c_o_l_724a98df3d424c2ebda1d5e0304ba3ee",
          "b_e_r_m_o_c_o_l_6cde28732798459480c34d647f9ff275",
          "b_e_r_m_o_c_o_l_7281b69d95ca4769bcf6f68080c3f779",
          "b_e_r_m_o_c_o_l_1ede46a228e242f9b44dae7337363d55",
          "b_e_r_m_o_c_o_l_51630e45d13f43e7b303e9047989f603",
          "b_e_r_m_o_c_o_l_81f514c8db944ddbbc2585a0a634aad4",
          "b_e_r_m_o_c_o_l_a19f256351e74dfdba7b3019cba0289b",
          "b_e_r_m_o_c_o_l_24ff0eb176e343f490c54b3b4763c1e4",
          "b_e_r_m_o_c_o_l_ff720e7a9b2b49b7a213a4cd3e7e7b47",
          "b_e_r_m_o_c_o_l_cfc88290416d4dc985203a98a8727b5a",
          "b_e_r_m_o_c_o_l_9fc357f49de944e6950f1b8236c1fa3f",
          "b_e_r_m_o_c_o_l_628195e3dd0148e9948b43ee144a43b8",
          "b_e_r_m_o_c_o_l_58a06f55a27446559fb316c318e87028",
          "bermocol_ca1ba0acfc6749a7bb208f1a8de9efa6",
          "bermocol_f18921ae73624b11b80ff89d1adccfab",
          "bermocol_59d508acd98a4a6293cb967d41bcac0f",
          "bermocol_22cda47be8c9409fa4c88663581f4b49",
          "bermocol_b0b9170eebe64d5c87c8a41b7d6a229b",
          "bermocol_a12fd2a78bec40c999135bf367f849bd",
          "bermocol_3dd71d10d5f44d499bf6ca315ec4e34c",
          "bermocol_c9be1cf14da34d419ff123b9c7f69774",
          "bermocol_c9053f48c62941ecb4f11a69679775b0",
          "bermocol_d8c763bc45d34789ba7ff32adff97554",
          "bermocol_5d0358dced9e4535ada4e172e04dd41c",
          "bermocol_7cc5e3abad7142fc8add5ad4296cef88",
          "bermocol_2e7da0601d3b4f9cb3a066b8f9c32aa3",
          "bermocol_2b62c67cb7bc4c928d07bd63ae698196",
          "bermocol_ab9c0d014a234121bfef73add3b8e67d",
          "bermocol_a724e093df2549b29fe6ec854656168e",
          "bermocol_d3df0497760f45dcbab65d7d0c460dfd",
          "bermocol_91522c99da8c42739d25c60398eac976",
          "bermocol_93357f01e74449e59bf0d6a7d1a08746",
          "bermocol_5164b7c7b28b4738996b891f7c1fe199",
          "bermocol_86f98cd8353a4cce897f3f84d737e547",
          "bermocol_9e00768450374a979f838edf0d1fd22d",
          "bermocol_08161601d34544a386680f4ab8874aa3",
          "bermocol_7470a7b0653245daa42d53f179d89b3f",
          "bermocol_f8a38cca44ac413cb5ce0285d109fc62",
          "bermocol_52183e31c2ad4d7f8b3ab694d5b28f20",
          "bermocol_982ce327c6a84efea79f2a15d47f5f5f",
          "bermocol_e670e8d18cbb446c963cb40762d4f617",
          "bermocol_58d5fcfe7e414170bf4c3e17144f3c95",
          "bermocol_5341e9b2e87448419b989568c29b15be",
          "bermocol_647deb51677442688d442687f9c75c3a",
          "bermocol_74499adff4f9454888033fc14f4a5f96",
          "bermocol_da0f188ec98b498692810b2bdf086284",
          "bermocol_a06383df92e9420fb0ec3759611d18cb",
          "bermocol_9f062497cbce4a5b80b0f0892ddb91dc",
          "bermocol_80e9ed7f4bc949e3ada365557da2ff1e",
          "bermocol_f18d0bb7d3aa46df962bd1433afa3a4f",
          "bermocol_55e68487e28f46199ef7839be8cc592e",
          "bermocol_2c2170b6094c42619ce792adc0198e3e",
          "bermocol_2860b0e134354dfb8df4dd4d5417fc4c",
          "bermocol_295ce00e451147978a06847ee5b37ac3",
          "bermocol_067d905f4fdd4e0e891d4ebc4c0225ce",
          "bermocol_78c338b48f454c55a163c56e1d1b1e03",
          "bermocol_59fe3fc5a1584534b00a5ff2e4af5698",
          "bermocol_aa8cbb73012f4905bcc0fa8aebdd15b4",
          "bermocol_100b441ef955489583f7d2ba2ab887e6",
          "bermocol_72295e2351ad425d9592af618714d7ce",
          "bermocol_2a158d2908ea45788cfb14f4e3b24226",
          "bermocol_15d83b04fa89471894186761eab94786",
          "bermocol_359fb037aae8444abed3ee5b6f735052",
          "bermocol_2e758a72eee44bdb996f2c3d6eddc8ca",
          "bermocol_857242a159a549a38002033a7d05196d",
          "bermocol_f7693f227de849c8b7f30ec1e1bcb73c",
          "bermocol_9720599548ef497f80b1d162edb963e9",
          "bermocol_d98a500e9b10455b889372d66a181d25",
          "bermocol_2fef984ac0a941ef83862dbbd50faf4e",
          "bermocol_d27acd9831fc4a1193c0b19f6fad4439",
          "bermocol_c7333ac629b34d1d8f01d10ac3ef0a34",
          "bermocol_dcc982ca898946609bbc9b5faedff8b3",
          "bermocol_bae125f8fc084fdaa5e599cc04d761a1",
          "bermocol_c06161a4d63548e486123a40b85fad9e",
          "bermocol_888f210d7ac84a3897344e9e1c235cf3",
          "bermocol_453d176f5e6e4dab9551537d04620975",
          "bermocol_e8c7955ec1d34e639fc82adef3136e22",
          "bermocol_6d6374c00ee1426babb6c0847e01aa2e",
          "bermocol_8fcd1f2204284ddfa15b2801a1d89c98",
          "bermocol_dffcdb3bcdd243cd9ae4af95e41201f1",
          "bermocol_039ccac47678497d824f9cc4fbff4e81",
          "bermocol_3ef9f65f68374b60b1d9fdfec15c32fe",
          "bermocol_fda88bcaeef14cfeb3fa4453770b616d",
          "bermocol_2ae487332f304fe1bf3041f904c583b3",
          "bermocol_4fb64cad854140c4b287a862021fe7a5",
          "bermocol_1888acfcc36347d6af1251557189254d",
          "bermocol_c77427823ec2465c8335f01364f321a8",
          "bermocol_7f26eb08de4344ce8bbd0920ab157a4a",
          "bermocol_7f60fc49ffe14f16b2d7758f65d5dad6",
          "bermocol_468d131cb6a14878b1ede5108a7d4def",
          "bermocol_475c8771e3a4455a9b7df41879c5f613",
          "bermocol_cf1a1523d07942f6a275990f7355eeb1",
          "bermocol_21dd928e53524ece8988c40c21a4a970",
          "bermocol_45fdcdfd1d2d487abbe723c6fab09725",
          "bermocol_b805ebcd83d44a718dea5e8c8fac492a",
          "bermocol_f60fa60669c545868ddbe381ada21224",
          "bermocol_235fed9d8ad44b5ab976187b0cb24a8f",
          "bermocol_228ec66c12b1413cb63700578a862258",
          "bermocol_6d87afcfc81947f9ac079d2b9f75ae0f",
          "bermocol_8733a16891374c7d9bde4f6b094d9924",
          "bermocol_428e05aaeb3846f3a7781f68b8d2fe42",
          "bermocol_7591cf92edd6493388f41577bef7dfc1",
          "bermocol_22a1c9b0a6114330926df7a85acb129d",
          "bermocol_f521b5159628491db1f892b5b3b36793",
          "bermocol_2bb5c6713e034a2aa57a505b70ed8076",
          "bermocol_9f070cd1853646b5a87b4fd59fadcb94",
          "bermocol_bc45e00bde1844879d7518df8520ac3a",
          "bermocol_83b6d054560f4f3f928ab2f400f5808e",
          "bermocol_d1875d7d06104162a2d7b06c4454c640",
          "bermocol_364ae3a683ac4652ad852cd85086d796",
          "bermocol_b83bf3023f0543249d9d8b56f0be9f36",
          "bermocol_fde94e07ae63486eb5539e58de80e1d2",
          "bermocol_197002c1a636465ab36e1300cab19583",
          "bermocol_f0e6b324aa9d46aea2615cf12c92e07b",
          "bermocol_37465b63f98d4edeb60ff74191f14f76",
          "bermocol_a50edff3343c4ce4abb6c090676bc818",
          "bermocol_4af14cab21224a668d3f1e88622aebd7",
          "bermocol_8ea004ff0d2e4297a1f844b7674b1020",
          "bermocol_0f8355eb8b2c4375a61a3a7a1033226a",
          "bermocol_0bab28b780b14005b6f4dd561a3641bc",
          "bermocol_ad457368b0bd4187a17500d18a373b3a",
          "bermocol_598f81c219b9499883ce07a8012786f1",
          "bermocol_bf5c44ed499e40de843a64d63c9b3ae1",
          "bermocol_62e524bdef2d4447a863779e3aa3194b",
          "bermocol_0c881d7c4a7c4b4aa0e0d97427a9cf37",
          "bermocol_e25b75496af544fea3d3d51dc2f38c7e",
          "bermocol_d68d5d40020c4539b8dfa798678e6156",
          "bermocol_5a7cce475eef45d7a6e6a8db31c52e6a",
          "bermocol_ca24176350bf4187a3c025acb53b5ef7",
          "bermocol_3a45515f2b424c728846645d4ef90d48",
        ],
        recMaterial: null,
        recQuantity: 0,
        actualMaterial: "BERMOCOL",
        actualQuantityFull: 4180,
        actualQuantityOpen: 0,
        actualQuantityTotal: 4180,
        complianceStatus: false,
        actualBagFull: 209,
        actualBagOpen: 0,
        actualBagTotal: 209,
        concentrate: "BERMOCOL",
        recipeQuantity: 0,
        actualQuantity: 4180,
        concentrateStatus: false,
      },
      {
        recIdList: [
          "e_l_o_t_e_x_ _e_r_a_ _2_0_0_9370ab7a2ea54a539a84034daf2195a6",
          "e_l_o_t_e_x_ _e_r_a_ _2_0_0_87604e8f5c8b4cfe8f287267cabd945b",
          "e_l_o_t_e_x_ _e_r_a_ _2_0_0_185afaca0d6141f183e2005df34693a1",
          "e_l_o_t_e_x_ _e_r_a_ _2_0_0_75fdf5fe97d04027b85765bafb4ca07c",
          "e_l_o_t_e_x_ _e_r_a_ _2_0_0_36d10dd504dc453ca7ce95499ff7d528",
          "elotex_era_200_ebe40b70640a45a68d3ba8b88063d7f8",
          "elotex_era_200_d9edfb95f4d1448cb34df9ece1907f3a",
          "elotex_era_200_e227a46095ee4735a35941505243b776",
          "elotex_era_200_0b28d3fc5cfb40519a01637a21d67ccb",
          "elotex_era_200_5153954876844b18a9324ad80c7aa107",
          "elotex_era_200_6fb786fbd9c140919b97a7d26c3037a3",
          "elotex_era_200_f805d3199a994b698a64f46b5c911c0e",
          "elotex_era_200_4e9c65da35b7417c8f880fedda9591d6",
          "elotex_era_200_3f974d4746974c9eb269f83550383276",
          "elotex_era_200_92590673b2ca40f7b0a7bfdd70c47a3d",
          "elotex_era_200_8c1b7be6d498453fab15c84163740a9b",
          "elotex_era_200_edad12b05df945a9b361d72bffac91bd",
          "elotex_era_200_b21ea3eb67cb475980b3d1b8862a11ab",
          "elotex_era_200_670f6f76cecc4137801325c3f7a35096",
        ],
        recMaterial: null,
        recQuantity: 0,
        actualMaterial: "Elotex ERA 200",
        actualQuantityFull: 190,
        actualQuantityOpen: 0,
        actualQuantityTotal: 190,
        complianceStatus: false,
        actualBagFull: 19,
        actualBagOpen: 0,
        actualBagTotal: 19,
        concentrate: "Elotex ERA 200",
        recipeQuantity: 0,
        actualQuantity: 190,
        concentrateStatus: false,
      },
      {
        recIdList: [
          "t_e_t_r_a_ _p_o_t_a_s_s_i_u_m_ _p_y_r_o_ _p_h_o_s_p_h_a_t_e_4578c6ded3414e66b97298c3cb8884ce",
          "t_e_t_r_a_ _p_o_t_a_s_s_i_u_m_ _p_y_r_o_ _p_h_o_s_p_h_a_t_e_8b50a0e5186c4009be243a79202508f7",
          "t_e_t_r_a_ _p_o_t_a_s_s_i_u_m_ _p_y_r_o_ _p_h_o_s_p_h_a_t_e_c4ad2d5a3cdb43518aae1d829f2496b1",
          "t_e_t_r_a_ _p_o_t_a_s_s_i_u_m_ _p_y_r_o_ _p_h_o_s_p_h_a_t_e_2787d336f047450385edda5f1f3c7453",
          "t_e_t_r_a_ _p_o_t_a_s_s_i_u_m_ _p_y_r_o_ _p_h_o_s_p_h_a_t_e_74e821eacdff40d7a4aa22fb7553872b",
          "tetra_potassium_pyro_phosphate_f78aaf57af0a4665bb47ec7f428699b2",
          "tetra_potassium_pyro_phosphate_a3659b39bb314f80a4ecf6272ed7f246",
          "tetra_potassium_pyro_phosphate_636fe8aca7114e3bb8266acac685f087",
          "tetra_potassium_pyro_phosphate_ac00170755a744d39e741d6695f4a845",
          "tetra_potassium_pyro_phosphate_fa8343aa3c5449cfbcefe2ec6a480b35",
          "tetra_potassium_pyro_phosphate_b32201c3ee0f4b5db88919a52004fa7b",
          "tetra_potassium_pyro_phosphate_aa272ddfcf0f4e67870a556831039b79",
        ],
        recMaterial: null,
        recQuantity: 0,
        actualMaterial: "TETRA POTASSIUM PYRO PHOSPHATE",
        actualQuantityFull: 300,
        actualQuantityOpen: 0,
        actualQuantityTotal: 300,
        complianceStatus: false,
        actualBagFull: 12,
        actualBagOpen: 0,
        actualBagTotal: 12,
        concentrate: "TETRA POTASSIUM PYRO PHOSPHATE",
        recipeQuantity: 0,
        actualQuantity: 300,
        concentrateStatus: false,
      },
      {
        recIdList: [
          "h_e_c_ _b_r_ _1_5_0_0_0_ _h_cf40f30dfcce4e15bf297d24b5485d00",
          "hec_br_15000_h_4e4f86a8d70f4570815c14e10dadf5e7",
          "hec_br_15000_h_504d0d44d74c4d46b6bc3e286f1d151b",
          "hec_br_15000_h_da773cf1985b4ea5ab25b68943cebe87",
        ],
        recMaterial: null,
        recQuantity: 0,
        actualMaterial: "HEC BR 15000 H",
        actualQuantityFull: 100,
        actualQuantityOpen: 0,
        actualQuantityTotal: 100,
        complianceStatus: false,
        actualBagFull: 4,
        actualBagOpen: 0,
        actualBagTotal: 4,
        concentrate: "HEC BR 15000 H",
        recipeQuantity: 0,
        actualQuantity: 100,
        concentrateStatus: false,
      },
      {
        recIdList: [
          "h_e_c_e_l_l_o_s_e_fcc22278bf8046d0a7a8ae992f25b31c",
          "h_e_c_e_l_l_o_s_e_3f0769816d7e4315b818d2a6aa6bc9eb",
          "h_e_c_e_l_l_o_s_e_71190c50261840d7a557ff12989998af",
          "h_e_c_e_l_l_o_s_e_e3accb5a810548fa8ac411c9b45bf6bd",
          "h_e_c_e_l_l_o_s_e_e0eb9c2e2fcc4d19be79c5c6175edb12",
          "h_e_c_e_l_l_o_s_e_d27e5f6714b44883b87d02b27ec66500",
          "h_e_c_e_l_l_o_s_e_8be0f299367f445a823a131c92081778",
          "h_e_c_e_l_l_o_s_e_649ae39d9bc847b4a52f1a5497aa09dd",
          "h_e_c_e_l_l_o_s_e_c7b6dd4cf0b94223abae7263c492d3c6",
          "h_e_c_e_l_l_o_s_e_507f631709434afc9725b5a088dd42e6",
          "h_e_c_e_l_l_o_s_e_f35e2e896fa74e6d894aa4777ad97969",
          "h_e_c_e_l_l_o_s_e_29043181682e4275947dfa23bee2e5f3",
          "h_e_c_e_l_l_o_s_e_50270103a10340c683dc5977bed9d007",
          "h_e_c_e_l_l_o_s_e_31c2f01930524cab9c9a67def329a31e",
          "h_e_c_e_l_l_o_s_e_e8c9e35bf57c4bd782031fbbac6f08ba",
          "h_e_c_e_l_l_o_s_e_569f255f717545e89cef517e24e4b218",
          "h_e_c_e_l_l_o_s_e_459d5a2ad4d6439395de3b04d3de9ba3",
          "hecellose_20f571621b8f48a0a0683392f7034490",
          "hecellose_72f33d70219542919d3d11d992556591",
          "hecellose_faedafd0a6e544eea6a6a3b2e4fc8451",
          "hecellose_30cfb9dcbb9d4393914f56d0f830ce2c",
          "hecellose_b4ab155f5b634aa5acffd9c5a35fe145",
          "hecellose_26f00f83c2e7417c9351e069f7a91ebe",
          "hecellose_7d7355fd2f374e2fad60c56e40de220d",
          "hecellose_60f055f128dc4551a3e26b877f9ba9b3",
          "hecellose_016905b2a2a64c8d9551dd5f91f3be2b",
          "hecellose_4080bb8a0865429d968041f1f3a8d508",
          "hecellose_f20844edbfce4bab90a8caad2abfe350",
          "hecellose_f499e0862d864ad4ba0a7e90d5c3c77f",
          "hecellose_3ae6c6afd06d4e82a7d3725c299c8efa",
          "hecellose_5c350b65ca3b4abb84bbed9a66f58a37",
          "hecellose_6479e92e4624420fbaaf9d86d0ef9849",
          "hecellose_816354e7238c4f1f90736cc142a7619f",
          "hecellose_3660a04f6f614422a7eccb9b56ba2c0d",
          "hecellose_14566e2c48c54799b3907ffcddb1ad2e",
          "hecellose_5f585f31b52949d2b35c52cdbc56189b",
          "hecellose_f703bf3e769f4aac885785cf78cec0ec",
          "hecellose_12a0f6328a244eccbf749ab37fab9872",
          "hecellose_d2653fedee5942ad98efdd793f3278e5",
          "hecellose_325b3fc2fa864ce9878defa9feb96bfe",
          "hecellose_55ff0ac8322b4c4eae24661313f1120d",
          "hecellose_0e08a85bed1d424287ca73b307c60dba",
          "hecellose_be952666c5a6440384b3dce0473ba790",
          "hecellose_e61db1c4fa3c4c7aa6ceeb66cd0f0f9d",
          "hecellose_d215ae94f98f410cae92d8efe7bd2377",
          "hecellose_ee8bdcd0556a4b53b3b55f127cb07036",
          "hecellose_153f8df80506471c8bf527db48857a97",
          "hecellose_adf62e614e974d2e88e2e354099063e2",
          "hecellose_520fd1f467af4696b62dac28f2f4991d",
          "hecellose_bdadadc6d1f941b4a36879cc88755bc1",
          "hecellose_05a3abefc10d4749abf3cec593a2d0c3",
          "hecellose_6a87a595ff3e4031bdea9d5381576ffb",
          "hecellose_9c12b75d16434680b58f446adad19af7",
          "hecellose_61ecc5a00c724191838c85b14c8d1550",
          "hecellose_25ff479954fb434bb731feab9ce67c09",
          "hecellose_387851623cbe4b8380e9c629f29acd8a",
          "hecellose_f443a7c5bb3740ada970836cfcae5acb",
          "hecellose_986b10a979e146939fdb6ac126f2dd11",
          "hecellose_c275df0d855c4a369341a8b417714db7",
          "hecellose_3683fdbec42c438d99bc398ab76994ba",
          "hecellose_62c5c14254204489a68213f0283078d7",
          "hecellose_fe97f4c358b1459a93dbc3e33b9eb505",
          "hecellose_404b570711374437ba5047141a8381ea",
          "hecellose_3ec1a4457e4148b49e89926f6a0a4df8",
          "hecellose_3c181e80a03f4b83a1aefbf6607d2f0e",
          "hecellose_1a23ccec4d5d43269be582e5ece2668f",
          "hecellose_60c52a90e33041fda882c51b61ae586f",
          "hecellose_203223a7d6ef4a7884378f61293678f3",
          "hecellose_4cd67a1369144293a7219313f73fc98d",
          "hecellose_8fcb43a9ad424664ac8fae007c795988",
          "hecellose_6ca4768288a34f9a8c295ac5290934f0",
          "hecellose_92a40bc1165745b6b697a516e1490eb4",
          "hecellose_7c2c35ba2b454b1492201f666f3eabbf",
          "hecellose_39332f7724e04b378c2b0fd6a29eeb97",
          "hecellose_8a3e79af9dcc4cefb9e34745cf7a1539",
          "hecellose_f6c51318e90745b7b702f18c3270de5d",
          "hecellose_d4070275f4b74e45a17ba6a390016141",
          "hecellose_102f3b0cf9784d6b8367edcec042a206",
          "hecellose_a53596804ddd4f50acf214e5aca6b4b3",
          "hecellose_1c45f96a2ec34369bf830239bfb68a32",
          "hecellose_a5814ec753464ab09a95dfad639dd965",
          "hecellose_81b2b2514eb5485280ba87ee174bda70",
          "hecellose_9593485c95ce43b3b8cf9f04562e88f2",
          "hecellose_dd05c186868d4241b1a2cf72ab91f0dc",
          "hecellose_eddb5e4a0f854ffea93711348dca3e99",
          "hecellose_d4b12bee2ca0406ab7531de78c1a32ba",
          "hecellose_4cd42e65146943aa8942ff62f10a3e86",
          "hecellose_af2f412176744a23a049b25a86da178a",
          "hecellose_92f2f950e99f429a8a5fa39262c67868",
          "hecellose_8d50238058a04ac2b3bf9f119b3fabed",
          "hecellose_339530fecf0142de979dc3a867624de0",
          "hecellose_852f1acee9be4b9ea1bf94f599cc7603",
          "hecellose_3fc117aa5160432dbb7abdcc0768a6a8",
          "hecellose_b42cafdd02ef454a977056a4838d2e94",
          "hecellose_260d5b3cdb0f42b2b1cfdb189edf08aa",
          "hecellose_6f9084a1e13b4da8ab972789a4f28fa7",
          "hecellose_cbc3420899264d69bd4701d77f92a8f3",
          "hecellose_2e250ec3860748ec8ca7e8184ffba425",
          "hecellose_9f04a2694f204c63a8feb46f85f55150",
          "hecellose_e3c1ec8b6fd5422391eeb9f7d0e5e3a5",
          "hecellose_c944d193d22048528a5d0b203746900f",
          "hecellose_cde0b20730e540f992a49799802575d8",
          "hecellose_bf8f39a7ac8b4799b60f8931894d8027",
          "hecellose_4b070d40cb7743bab5b855b7dd7f57ec",
        ],
        recMaterial: null,
        recQuantity: 0,
        actualMaterial: "HECELLOSE",
        actualQuantityFull: 2600,
        actualQuantityOpen: 0,
        actualQuantityTotal: 2600,
        complianceStatus: false,
        actualBagFull: 104,
        actualBagOpen: 0,
        actualBagTotal: 104,
        concentrate: "HECELLOSE",
        recipeQuantity: 0,
        actualQuantity: 2600,
        concentrateStatus: false,
      },
      {
        recIdList: [
          "n_a_t_r_o_s_a_l_8e53260fcac74365a5709b6c79d4608e",
          "n_a_t_r_o_s_a_l_dba23a643f5144369a5d69003dff7506",
          "n_a_t_r_o_s_a_l_a76f422998954a7c95dd597944a03d4f",
          "natrosal_943ebb29552e46c2aca40fb07c941f6e",
          "natrosal_3b2bf25f739d4c87a443dd3d305835b1",
          "natrosal_d286e87f54bc445db35a2593950f1c05",
          "natrosal_f5e2901de7d8490a919c737c64386fb9",
          "natrosal_f3173c8bbb6e41d59b64f1e1164b421a",
          "natrosal_bee6c2f5cc074ed39505d47379e39159",
          "natrosal_ce804e59bcd448d18c9b8c56ded21837",
          "natrosal_9006e0d8ecd7444dadf036b9571894ab",
          "natrosal_1a47a38fcb914e848614006bcd880f3b",
        ],
        recMaterial: null,
        recQuantity: 0,
        actualMaterial: "NATROSAL",
        actualQuantityFull: 300,
        actualQuantityOpen: 0,
        actualQuantityTotal: 300,
        complianceStatus: false,
        actualBagFull: 12,
        actualBagOpen: 0,
        actualBagTotal: 12,
        concentrate: "NATROSAL",
        recipeQuantity: 0,
        actualQuantity: 300,
        concentrateStatus: false,
      },
      {
        recIdList: [
          "s_o_d_i_u_m_ _h_e_x_a_ _m_e_t_a_ _p_h_o_s_p_h_a_t_e_bfc441213cbb4b318cebc809898ebcc6",
          "sodium_hexa_meta_phosphate_72999f1b9c8447b889dc6ea587b71bc4",
          "sodium_hexa_meta_phosphate_e712047598ad4664b1fa979748fed499",
          "sodium_hexa_meta_phosphate_0f2e65625d8049038ea80a38e8cf2641",
        ],
        recMaterial: null,
        recQuantity: 0,
        actualMaterial: "SODIUM HEXA META PHOSPHATE",
        actualQuantityFull: 200,
        actualQuantityOpen: 0,
        actualQuantityTotal: 200,
        complianceStatus: false,
        actualBagFull: 4,
        actualBagOpen: 0,
        actualBagTotal: 4,
        concentrate: "SODIUM HEXA META PHOSPHATE",
        recipeQuantity: 0,
        actualQuantity: 200,
        concentrateStatus: false,
      },
    ],
    weighment: [
      {
        recIdList: [
          "b_e_r_m_o_c_o_l_7e28baea2c634264a69e0ffb9b54bace",
          "b_e_r_m_o_c_o_l_84e15fe621144775b5cbb47ff80bde73",
          "b_e_r_m_o_c_o_l_594001961fba4e38b1a7dd452837a359",
          "b_e_r_m_o_c_o_l_ec2f7e2565734457808eca9cad22c07a",
          "b_e_r_m_o_c_o_l_3a7825d5dd9d4464a4c3805fa6811ef0",
          "b_e_r_m_o_c_o_l_517399e422324677ba4e2b52ffc973e8",
          "b_e_r_m_o_c_o_l_be89a75db4264e9cb8c56da11c177fa8",
          "b_e_r_m_o_c_o_l_503e43edc95e4c04a8e527706814a702",
          "b_e_r_m_o_c_o_l_8a01981636674e6e8b13a5cb92d1fa4d",
          "b_e_r_m_o_c_o_l_a8caacdee1c24c14b8b2a8548c9d8502",
          "b_e_r_m_o_c_o_l_0d2615a18527429c801c0162f238e5aa",
          "b_e_r_m_o_c_o_l_c244bcd3d8ba4142a36eb45d006549b4",
          "b_e_r_m_o_c_o_l_71f520985a2943f2a61ff7d7bd2d31a4",
          "b_e_r_m_o_c_o_l_5ae457dc998542e1b0d755fd625eb304",
          "b_e_r_m_o_c_o_l_563931200c5f4b04adcf46fcbac33145",
          "b_e_r_m_o_c_o_l_238d062cd7ce427ca63a8da021a3d2f5",
          "b_e_r_m_o_c_o_l_4d471adaa2b64ac6a3c4e63180474101",
          "b_e_r_m_o_c_o_l_f60da23fddcd4540b31896f5e51ae0cb",
          "b_e_r_m_o_c_o_l_5ba3430ab04b4e08beb723fd9b2cb17d",
          "bermocol_ade7d1346f2d49cdaea657eb78cd3b11",
          "bermocol_0a9cf7a6782842babee941753f8f67dc",
          "bermocol_e949994cc7e147048818589d9da57383",
          "bermocol_82165da64b554afd8aa20ed1f70ebec5",
          "bermocol_26bfca9337094333b36dae871c3a5632",
          "bermocol_9b1f5d3f808c480381abb76bfb545bf9",
          "bermocol_20f442d53b4e4decb6d382d64b5cf33a",
          "bermocol_adcbe4101d7346f5b0a2799c178a4452",
          "bermocol_d58975362d0e465f9bffca62e384625d",
          "bermocol_6c252ce097c34aa383bd4505b127f6d6",
          "bermocol_e2d4b69327af413cb4e9655fe589f2f0",
          "bermocol_677273a04e0b4d8686cbea7f3ce579af",
          "bermocol_61491e3a3e0c4e5e98a431d294a93dd4",
          "bermocol_11c6e0aef4a34098b5580ea46890a5c5",
          "bermocol_85a978fc231c43e78c92d95e2899444e",
          "bermocol_64abf14bf7ba41e6bc64b8b886b3e84b",
          "bermocol_61a1f0cd76494e38932ade6bf32bda30",
          "bermocol_07f878a3c600420ea3ee00fe8c638f29",
          "bermocol_bccf2753197746c6b74c5144ecf93f3f",
          "bermocol_fa152c84e8e94ee0845585c7b4f14434",
          "bermocol_60772d948c8c4e30ac432e0d32644e32",
          "bermocol_b50dfa0c73e24a2083c9749bc5df6f7f",
          "bermocol_8bc8c9dfd2a64d55bcf2816385307596",
          "bermocol_14b8bc53526c44bea706df12d0dd29d2",
          "bermocol_985c5ec349ed4d50900066e1ed3fea6f",
          "bermocol_d214a0e495a64a2894b7352408219634",
          "bermocol_ccef806fa8ca49c4afb4373dc9980866",
          "bermocol_da0a0f7c657c4c9d87963d4807832e64",
          "bermocol_c9c86cdc2a73457a902b2bdc4cc4bcca",
          "bermocol_5207567091924a0ea18f2d512f720a3f",
          "bermocol_4f5330cc657e468da1c062f9e341afca",
          "bermocol_bf24d5e03f764ec495c8cce35e564248",
          "bermocol_728e65e31fcd406583c695541b2673e0",
          "bermocol_a2932574a3f448febf662e05f0431f07",
          "bermocol_934c1e4c2f3f42e48c1e19a393a42dfd",
          "bermocol_fe019a64ef9a4a67875ac17c6e5dd4ab",
          "bermocol_a60b4cf01eb945658263eb10faddc6cb",
          "bermocol_599bda069a144d19b4bf8111ae2d0232",
          "bermocol_81294331f5e745bf8f656e69beaf88ac",
          "bermocol_cc47a850d7d544ec85b0647164f068b1",
          "bermocol_fa323933c2704533ae86066cf4fe10d5",
          "bermocol_ae68d14f04e343a2bf256300e0952c33",
          "bermocol_94ac67204b9e4ca78bd14d7505051796",
          "bermocol_55851e2de1bc422a8326a1e3d8d97c11",
          "bermocol_d06d69cf81b4451e990b3a6683cddc72",
          "bermocol_64bb6623681e4c7994f3dd5bc0ca28c1",
          "bermocol_aab0819ac3694775ad950345db1c859e",
          "bermocol_34adb9621eaa47cd8aaaa57be3a6b18a",
          "bermocol_9bf8ed6c6b2349d9bdd04ab710ff0793",
          "bermocol_bc9e206702de46c4869d2d612cc26b49",
          "bermocol_b8ec18551d2446ef97e0e0827c088d5e",
          "bermocol_5d53dd25e4a3493a98ec4af147dcb53d",
          "bermocol_9bc7c20d380648de9cbc50abfe1d2761",
          "bermocol_ff06c30914e049cab7cdf296e32fb241",
          "bermocol_58e83a54bb3a4b999aab48ec4f5f2e8c",
          "bermocol_f6751c450b0d45e394f06bd4e24789d1",
          "bermocol_1045fe63ddf74878940641f267179291",
          "bermocol_9721f7a625524580a9c739c5af822f8b",
          "bermocol_a14e450deb5347b79f81845546d67494",
          "bermocol_c91fa9c0e3984c5d9426b759aef0efa0",
          "bermocol_0fee34faa88b4497a503498c82c33361",
          "bermocol_c759c3dca63c4ee1b1e1fc9134650873",
          "bermocol_2bd930b3b62548eb8db08b26d64d5a4b",
          "bermocol_aaa6adc76b52435bacf1a14f94bf1793",
          "bermocol_79a1085d675647eba70ddc17d7e4e68a",
          "bermocol_13ee00068b084a6089e2acb185903b0a",
          "bermocol_6e891977ccee4f5aa3f4c8bf9d08a86d",
          "bermocol_05df9d72d7bf472ebb6a440ffae4d082",
          "bermocol_ff90dda86e4341e39ca34ccc0b15030c",
          "bermocol_ff6f3b79036046f0a6c2f8ce3b151561",
          "bermocol_47c1070f06b1424d8b5b94a1ba01ba90",
          "bermocol_1ed77c014ef84816879f60444bdf4053",
          "bermocol_1025ebd728534ea781876191f506eeeb",
          "bermocol_073a1e54035d4a1a94abfd7234439c06",
          "bermocol_9c8999b3608b46d591000bf24a89ccab",
          "bermocol_f0a277c7b7ad47549a86d001620ed557",
          "bermocol_3d17708f76f2461687338f60f0dd4bd1",
          "bermocol_2d15ff9c8ae44b25ae72fa9fbcd9682d",
          "bermocol_74fb6e7d7d8549c5bba0b047877426de",
          "bermocol_3c330662dc9e4f3ca493873df0cda212",
          "bermocol_6bd5c588d3c64db69eeb36ee8826a351",
          "bermocol_9a72c0fecb724670b227ce77e5822dda",
          "bermocol_70698eddb5414d088262567c97bfe4e9",
          "bermocol_24835e3fa92d402997c8b8a968b0700c",
          "bermocol_fd15cb95b7514098b69cd7ded6b5cf56",
          "bermocol_fb81808bfab94c82a54e687a89513690",
          "bermocol_3187c2a93a1d4f32ac78a818cc8a7007",
          "bermocol_17f47d52be0d4c9e9d292b633f1c2cdc",
          "bermocol_bac3eca573d24e6899708169c6bfbc18",
          "bermocol_abc3100a4ff541698666a5f31ae33023",
          "bermocol_bafbaad51e1c4c1095a0039a5affb431",
          "bermocol_83b3eae4dee646b3939148120e41f3aa",
          "bermocol_afa29c3a0bf54a4a833c4b67680e447a",
          "bermocol_75152e98689c4d83be57647622641140",
          "bermocol_b3dbfe84feb14dde8f888eb2a12d5183",
          "bermocol_fa5e3ad9decd43ab8af8fccfcdadb806",
          "bermocol_e06fb22d75c64640be1ecb97d0fa8131",
          "bermocol_aaf10b5db55a4d8fa0eb1bfa3fd0ccab",
          "bermocol_46e84a9b2ddb4c70a962f5efdd5ce3cd",
          "bermocol_935fe1b62540439ba907ec3e2f66e5ae",
          "bermocol_bba74920a88744cb88a854b6861c9744",
          "bermocol_4e9007fdf2b547eebfb403f0f643f859",
          "bermocol_0bd2f43a0b7e4116a73c051e424767a8",
          "bermocol_299d8ee1ba2949d198a0101a9a44d36d",
          "bermocol_09b11513015940b1895c304e2ee9ed9a",
          "bermocol_1c2ead5a5b17402dbc203f1bafc50fd0",
          "bermocol_6223d279635542a7912b066cf3bec0e3",
          "bermocol_558748a9070349739f56138facfab839",
          "bermocol_820a9e8f77754375ab47b672045d2a6b",
          "bermocol_18dadaaaa74041c0a358b079b3385f55",
          "bermocol_db761fd3372247a692ceacf87d9fada7",
          "bermocol_2233a70fead6460986f249585003c6e2",
          "bermocol_aa1c638fd5ca448ea8888fd300cd3567",
          "bermocol_cbb6cc37ebf4478e93a8961d1076ba59",
          "bermocol_0ec0af83176b440faf03495cbf3bbaae",
          "bermocol_e46637c931214dc09f27800f2ab2b650",
          "bermocol_1a54c9643f394684b8ddb0af7e6510c9",
          "bermocol_555aad6492dd4573b6e0a435019ac017",
          "bermocol_8d6da59913a842c68cd4888bad2240bb",
          "bermocol_3c406d6ff339417aa6d3c82b8ca3fe88",
          "bermocol_78be5f72ae11413ebe19e8bd1d9f5a89",
          "bermocol_b758de059ea94e819b77906e5d17c2e7",
          "bermocol_9f1f96015edc469b99970170eaa926ae",
          "bermocol_ea03035c385d48e6a290feb79de1a0b1",
          "bermocol_0f3950350fe84ac886f029ed53cb4660",
          "bermocol_8f899e0ef74e4693879e4493b68f3312",
          "bermocol_823486f3972f4da68bf3fc2ddd90c679",
          "bermocol_7625999b667d4640abe9a5748433b5c5",
          "bermocol_1ab076fe62dc454fac7970cb4c9cf601",
          "bermocol_aa6bd6be96bb4ee6a73dc1f92d98157d",
          "bermocol_9b52c1ae70104b8a824d4bc8f5ec4889",
          "bermocol_f9f2bd8b0c7b49ffabc9307fd855ece9",
          "bermocol_496a95fb392240e19cf4b5a396a2f4d7",
          "bermocol_08c764656457453db9adcb39e058c05d",
          "bermocol_260718aeadba4e38b1591e67edc0b001",
          "bermocol_b129983370e144d7a9fe4f9e14e6374d",
          "bermocol_b2a4679984a9465d8f58df63060e86cc",
          "bermocol_fce3b86dcc6c44d4ba2d9a8fe4944283",
          "bermocol_2f9e8efd765d40a9888918a8bd44cdf5",
          "bermocol_b54a0ab942514889acb2516809352bf7",
          "bermocol_866bd85ce6354a83906777aa55e52a8b",
          "bermocol_03839f8df8ad40e39170493cd366e29b",
          "bermocol_8bbafb74b28a43ee9aca851a48b83aa0",
          "bermocol_3dc319e8bd3a42588e4e21ff59dafafb",
          "bermocol_2ae81c8b424749c191f6932a04959e44",
          "bermocol_788bb1e2c55a4dfdb3decdaa874d0a9e",
          "bermocol_051233b246684def9e8368e42277a45e",
          "bermocol_d2e12ffbf119473b8662fe195adc73ee",
          "bermocol_6dd7143288014331bbd22227b6305106",
          "bermocol_6725895963b94f0fb6fa4552ae260c4d",
          "bermocol_ccc47d1f223c416490f02a98b5399f3c",
          "bermocol_41b0514e856446f79cdb518f9b10fb3a",
          "bermocol_960d646c6fe84723a88326941f1ae624",
          "bermocol_0a4169b780524135aa30611eccaa4c69",
          "bermocol_cc7a8d81ef44420b869fcfd0cf624e9b",
          "bermocol_9f9d376fb9b94e83b505eb1e37754e49",
          "bermocol_5233c093cfe54962a6b84a0e0ed8224a",
          "bermocol_bd2e81770b524100b572d9d753431fbf",
          "bermocol_cf92cefd769e4a3ab4f42c5eec9a2ec0",
          "bermocol_68c750bc35a747b590eb5084e42fb17e",
          "bermocol_c0aa8ed4886949739812ec00b4187af6",
          "bermocol_2d8d711f507e43fba2d4abb75c31d911",
          "bermocol_7d2ddd255777478a8020e7dcc8c5a5a6",
          "bermocol_37fff27879e542a9a70aa636f9ede11a",
          "bermocol_67dfdf1f3f444d2a9342a4b226a1cfa7",
          "bermocol_25de763f4f894a7cb8ea3434ae7b18a3",
          "bermocol_99d2de7ceb1548219f57aee8d33f0cc2",
          "bermocol_8ad87bca6f494c5d9d3c8cf9293e7828",
          "bermocol_99fb457206a043a89751be921b51204f",
          "bermocol_2cd8cc76d27d49b9ab1f02314b7de7ac",
          "bermocol_1f9da9096d3f4cc08fd0712ef7be163c",
          "bermocol_aa57b20262d54ff98cca4771e64fe9a4",
          "bermocol_bb350f5d57b744c1a84a68f5dd97fad8",
          "bermocol_71e59a9cfd60451eaa7ba2671f55f745",
          "bermocol_b223892acf3e4c508360840711bfd581",
          "bermocol_785ea17673424ea380857c3c9a57c5c4",
          "bermocol_bd1d9792c9de4623b69fdf0ddf6d4486",
          "bermocol_69044d64740648458a5e4ac0c6fb5c52",
          "bermocol_b9472febb49d4809b70acd44fee40041",
          "bermocol_017f737953e04ee48de19d382b1ef0a5",
          "bermocol_4194a15d9ddc4ba6bd2bb82e5ca01992",
          "bermocol_cb623097e8e145968288e59338ea778f",
          "bermocol_03ec8ea5e67d4335a16373db596c30a3",
          "bermocol_3239720838a54a32bd94912def855961",
          "bermocol_53440d1c90cc4a3188ec9607a3312764",
          "bermocol_a4ab8883ff3547239e01e484e34f5b37",
          "bermocol_e7c4ee628abd4a43a52587aa5c59abbd",
          "bermocol_a534431677144df4a2300290feaa3ac0",
          "bermocol_4d82d85306e34d9aaf310f81eb931261",
          "bermocol_f201f7765bde4312a15a4e8770dab233",
          "bermocol_79d8af6cb96c4a5990ca952771a89149",
          "bermocol_71a7f089bab64a5c9cfdb810fd02969d",
          "bermocol_c87c910d1ec644f0afca89e3097b0287",
          "bermocol_2179f2a66152474c8fc2a3db388c8f48",
          "bermocol_d010b2d9c4df4b199b953250e0bf9264",
          "bermocol_04f69aca8fa94ad3a542663db38bc3fc",
          "bermocol_b31c14038ea24c92a437bd508b7dd8df",
          "bermocol_ae51cdf19a404797a9232f89958275f4",
          "bermocol_2f7c4deefc42452e8426150dcf93872a",
          "bermocol_43d8e2083fa041829b5aa0509b73a3c2",
          "bermocol_2d43bb2697b94bc6a38c5f5def5f6138",
          "bermocol_fdad19caac104194a931d77cf8542802",
          "bermocol_3146e6156dbf4c7fa0a257818eb3ce99",
          "bermocol_0b7f2c10dc0a4e04920e06e7e391c26b",
          "bermocol_dca14a5ffeaf4b06a1002f3dc58127ca",
          "bermocol_39bfd35d17bb4145a592f6a1f0f816d2",
          "bermocol_127f8f9428f9486d8121c1bbcc16682c",
          "bermocol_f47ec66824fa4209bca02a651fc716e1",
          "bermocol_aae6515b68964b25a2b15cac28df878a",
          "bermocol_bb4b0b028b334527beae158e7750ac0a",
          "bermocol_ea62a317c85b4a4981c4033a28755b7f",
          "bermocol_70470e3ce9c643cd9cc073b42dbf25a9",
          "bermocol_c949c92a97934efb9fd77d5dff10eece",
          "bermocol_40ad52c503d140eebd09a852b771d9c2",
          "bermocol_ec1cef2cd45741548bf2c5d84a5eb8a8",
          "bermocol_7da6b4e41aa84d46924e7099518a295c",
          "bermocol_a4d785c643314bd0bfb03e5df4cc1b54",
          "bermocol_c1c84407d1a34ca2af8bdf54f1303caf",
          "bermocol_d0234d35bb034ec187f8ef1428e67f80",
          "bermocol_7227a9cb0b764ceb88bdf4bd804c3726",
          "bermocol_5d1d0c6d2bc04bd8a0b06ecccbc85166",
          "bermocol_648c3669b12e428cb876fda62bc7fc15",
          "bermocol_08e0f66b3acf455d82d1d2e010659d04",
          "bermocol_f85101655c7c4baa80e6e9293029569d",
          "bermocol_5baf50a7960443a8a39d586dd1b0c99b",
          "bermocol_9da85f287f824bd78e66a58400b839da",
          "bermocol_a17736cd516e4014808ef7bc9326576a",
          "bermocol_cfbe969565884b5b941b3a2a03c3baff",
          "bermocol_818c7f56d6194570a011418c3b982384",
          "bermocol_2f62164b81e94749816dd2fe8122f5ee",
          "bermocol_f46f1f1834494217bd5b3bed7689fe04",
          "bermocol_b0f3958088ec44ee96bcb510b8977a36",
          "bermocol_e92c56443f7643599dc6358f191c860b",
          "bermocol_bac8c77dac224a0baae6d9dca9a82a0c",
          "bermocol_4023e69ae69f4191a056473360df39fb",
          "bermocol_c4e6f36052874d168f635d1ad5b26459",
          "bermocol_ce1376e06d1d461c8f5835f9c4780158",
          "bermocol_4e62a1dca89f42ab8ec9dc0a372158ce",
          "bermocol_509ade139fc24a39852257e38ef8d49d",
          "bermocol_4bb3bf4e40dc41c1af77979eb2313984",
          "bermocol_59cd518a2f0048babdd1c93b27814909",
          "bermocol_58f4ce0149b74c039f163ff76da3ab2b",
          "bermocol_96369b7215ec4d99a60f8c24e2d8e390",
          "bermocol_b52db84310b04e96ab05f7127d9f854c",
          "bermocol_c86adbd9086a49ffaf3d6811bd4817ad",
          "bermocol_2d0ab4dfda8e4bbd82d0cbe56fdc57d8",
          "bermocol_f97eb325417c4120aa881d2ef50dd37b",
          "bermocol_3a37b199b8494e96b926f0e1b12b236f",
          "bermocol_1d2d941731804b709bae534b2c864f52",
          "bermocol_f2490ea7109a48e28f168d134e48af58",
          "bermocol_e64900e262ad4942b7926e175b84acd8",
          "bermocol_a57ee7bedff14565ab79ca711b7e35f4",
          "bermocol_5f548674810a43f58a01a10bb8a57769",
          "bermocol_09d1276bddc34d4ea8f97d83d3ee6109",
          "bermocol_1a3f4bc96e794b1f9767cb65113f0bc3",
          "bermocol_443aea94ca854fd7b164b61bc4528c52",
          "bermocol_7f5360d3a45f459faaa3318b643e9f89",
          "bermocol_86f58d941dbb4cd895192ab2014018fa",
          "bermocol_799af2d1907149869e0efc4d9dccd07e",
          "bermocol_0f750fd5dcbc4085bb1c574d24090972",
          "bermocol_ced49200af7240e18edc62d502b789e3",
          "bermocol_12162982f4dc4d2e9f16d1b57e3a4bba",
          "bermocol_6020ad54eb2f492c8ec8f34e3b75cdd7",
          "bermocol_1119f25aaa4a488ebf87c682b9910982",
          "bermocol_ecfc51ff2a974de58fdf756817a8cd52",
          "bermocol_c0ce578adff94901a06e9a516fa49ea9",
          "bermocol_a1a3f2bc5e134c22852acc9eb12cea01",
          "bermocol_30513d5edbd2401b86caa15a726628fb",
          "bermocol_aa19dfc3179d469293e0eed8107bb2ed",
          "bermocol_aeaf0f7d23b048249951993794cfceda",
          "bermocol_08872980ca434cee89418aa5e0555dc9",
          "bermocol_23a56988ffaf4ab7b2127205bcbae332",
          "bermocol_1c1fadf1a6714b36ba2cb3d187a3a77b",
          "bermocol_a44f181bcfad4209a5cf31fd79dd1b91",
          "bermocol_c3fce9b2f0be43bca8c47a5ee0170827",
          "bermocol_b63ee9eef05e42ada778971a4d44d877",
          "bermocol_61969ce0b40d4fe5b0fbca50c2d15a8d",
          "bermocol_6c80c256a4ee4b7bb9f6f41b7e17df1c",
          "bermocol_1c40453ed4b142189ed66c5e1c0fd31b",
          "bermocol_0a9aaa5d82aa4c1891993914a652949b",
          "bermocol_ce19b59cbb4240d5a5af14b2e6266c37",
          "bermocol_2b81cb79963948ef9af7fd87ff256e8c",
          "bermocol_3ffe0cc37720468a82c6df4d4fb39acb",
          "bermocol_2687772959b8493caac983fab352a787",
          "bermocol_dc2afec7af534260af5c83a817a29005",
          "bermocol_d931a0b581c1427f8b7be79b624cc0d9",
          "bermocol_cd346ae4a18a4020931f0f519d1fcd40",
          "bermocol_9de2d28859074a508bc4f5e3e2dc7017",
          "bermocol_e2d2b969922a46379417175f623a8f8f",
          "bermocol_808ad35ee64744ea98ed179e1a8fae76",
          "bermocol_1990b1d4016a43858cfc52a340d7dfcd",
          "bermocol_e310448030924c81b53a382fb13e892e",
          "bermocol_b0a31bd74a8849c1a89197f73deed191",
          "bermocol_37e6936cc94947668fb59747e0353b10",
          "bermocol_f78b1fb961a54e05b0e59db087e3043b",
          "bermocol_fb679cb8816a4cf990c40c504892d965",
          "bermocol_61c4f1483ee04ad881a114e1d0c435b4",
          "bermocol_181e6f706c9b4d8cb1afbee75cbc9843",
          "bermocol_f21682d1462b4a19b09bf714257f8983",
          "bermocol_116d2ed861f240128b9fe4b8f3a2f8b6",
          "bermocol_7ef44578965a4f71825952b01ee2978d",
          "bermocol_9aeee95e09784da89e30db9f424b296d",
          "bermocol_ec1f8d5281c54ae09c663024c9e6f219",
          "bermocol_4f94b753d2ff4226b6435b1217287dad",
          "bermocol_67c6e7689edc478c9d410f435ab6ea03",
          "bermocol_2e46cb2db7ea4a07b1a1135b7615a67e",
          "bermocol_8e7da5cfcb354de2a4dbb353ec3d3f59",
          "bermocol_58b2dd6485a3454eab5122e9a7bbe3c5",
          "bermocol_58cf45444b9842668c64bb507125ae04",
          "bermocol_55f46815eeb144fca0c7ee8df72d914f",
          "bermocol_0ed158130b1a4afaac17c8f5c9966a61",
          "bermocol_7a70396f196b4397a304ffff3cebbe1d",
          "bermocol_3239e015cb024f8aadb9d18db4d895bd",
          "bermocol_d0b6082af87745d09e003e6711dfed97",
          "bermocol_da3be1ebcf1b4a58bbeeea587eeae973",
          "bermocol_3a9876e732e74018be5ec6ec93f028ca",
          "bermocol_17a5dfafeccb48f8baec8358aba3c3a5",
          "bermocol_604469ae41064707be2c0b182ae0903a",
          "bermocol_40b8d9d74e4f49b1b582148c0b9e1098",
          "bermocol_566492d2493841dcae5b6ced5e6341fb",
          "bermocol_b484480ea5334a52b16bd2966900babb",
          "bermocol_82c4762898654a3d8582fb0bd55d690c",
          "bermocol_81516ed0f163437c8b2ec6f3236973e4",
          "bermocol_cb5a34650c8f4da88dc7123744ccae3e",
          "bermocol_ee75056dc08f46fbad71454598e50c89",
          "bermocol_eaa27a20a90f4dcaad5b6f91db4d5eb4",
          "bermocol_9399d959e893477eadd6750133073d37",
          "bermocol_3e2678853a1f43998f1984dac2e81675",
          "bermocol_edb3c0870ca64eb481cce9feb1a8fe03",
          "bermocol_e6bb441e31194fe781c0928613673df2",
          "bermocol_65c306795a4645b3b66b5e4f9e0f04d4",
          "bermocol_8c47043de9554cef9d22404ea8700a44",
          "bermocol_107bfe09b07640c6942673eae0f33d2a",
          "bermocol_a10602474a014a6383a350d71ee4f34a",
          "bermocol_ce262cd434b244b6871887843588ce66",
          "bermocol_93986bf507e44f34b256c54b4b55b4bf",
          "bermocol_190931c2e41f43f89b9269c7c28e35c3",
          "bermocol_63ebf06e67e74eb290c89c7a2531f009",
          "bermocol_46b5f23cf9ca4b1f8eed918dae355cec",
          "bermocol_afbe48a56e4d43a2a64c2b2ad33f467d",
          "bermocol_70a237d2785c4f13b3eaf2455aaddb8c",
          "bermocol_4f34d4c057864027b3cacadb4186b69b",
          "bermocol_3ac8e7bb8e21494ca3a6ebeabfb0a0b2",
          "bermocol_e03076abf0674e19aa8e81d43620d97a",
          "bermocol_f753e1fe1d6544858cc57f1b19823f4b",
          "bermocol_9dae2a8aaacd46c5ac0f957b9e5c2576",
          "bermocol_16a0827358754d1888359827883426d1",
          "bermocol_6d4f676143a44eb790fdaad5d71ed165",
          "bermocol_3b20273fea8946478ce10666c7c9099d",
          "bermocol_18ca3474f84c4104be03a3baf839576b",
          "bermocol_68499f55e20a4f1ba3a58c77cdb9612b",
          "bermocol_124f9b85734f4d68b73b88072ef5d1ae",
          "bermocol_465dfc6380d948039fd251cf8f4cfcf8",
          "bermocol_f0881911379c4873a93b91b5831f5b16",
          "bermocol_0e8e22e2e8c64409b2414e8f2015e0ee",
          "bermocol_fa11094512d24fec99f5ff152f7a34ce",
          "bermocol_d9dc754577154323ac65f9ef30e58495",
          "bermocol_d4f47b12ef3c4fc7b845ff21c6dd5bc1",
          "bermocol_a531898ee890465898e4bbff5a49ade8",
          "bermocol_7acd7b10c10c4625aefdd78a666ab054",
          "bermocol_4af0111ee43344ce88bda48db645f0dc",
          "bermocol_44aad122d9cf40cdb3b285d6ddbbfa3b",
          "bermocol_9b3eef33c8c146e98e7a0761e8ed90dd",
          "bermocol_03c1511101964e92a12e03b8a6c9e20f",
          "bermocol_2e67039a9a854e2aa92a5d60987d3b94",
          "bermocol_81677d6ebf63402a9ee06a72202a67a9",
          "bermocol_2ca4b352cc1e4bd4bdaad6b9e524d254",
          "bermocol_28686f5da1f34f07bfaa8f88346c4da1",
          "bermocol_34772a4b4f63428790d20a2aef1d6d0f",
          "bermocol_847d8002c84342cdb41a06bcc1463026",
          "bermocol_5c60a83df0664b5894cc98f65d5ecafd",
          "bermocol_ef6059d3fb0548e3b7a72b96ddb5dfd0",
          "bermocol_780803dd2d9848ff9c91c1457fd12a78",
          "bermocol_3b730b57da634c009c547f80bcec40e1",
          "bermocol_adc06b96dd864ab79f9e916d2cdcb844",
          "bermocol_cfef51fc2dc54d22bac74437009bfa5a",
          "bermocol_1915b78d54504a1693bdb5eb3a57d495",
          "bermocol_b8f477c18fd54fba81ea4d7cfb554a88",
          "bermocol_80b6ffa7e85f4a7593682a215e0cbbb9",
          "bermocol_a49855b6f487469f93ec5f768675d3bc",
          "bermocol_1a601c86ccab4d66aa289fc8d9cfcb0f",
          "bermocol_b09841106f66406a9ce6581509613738",
          "bermocol_cbee3673925341d1a2ff8ebfbc227509",
          "bermocol_f84663e26df247a8848746d0588d3eae",
          "bermocol_4a1a19abc33b43a4a6497c0e2c61a313",
          "bermocol_c13f0b01bb9d464a9ef50bf32e39420a",
          "bermocol_66725c38f5e2470fa205b25b4641a854",
          "bermocol_16b1982b891a4f10be67fa225e6db9ca",
          "bermocol_abbdb6db18a448ec833f2e84aeafc15b",
          "bermocol_7fec40a1ab1e40ebb504c7365d6da560",
          "bermocol_f4c4a06482054ab7984aa91ec4c898dd",
          "bermocol_019eb03dd00b43778a6beb0d05c65812",
          "bermocol_1e480d9bf0ed49ec9a3db22c3a315878",
          "bermocol_de377a1a3d6a4549b0cd8f7dd99ae69c",
          "bermocol_de5719d2318541abae7ee65d9b4110a5",
          "bermocol_670359c032f945d19515226d68d7aa32",
          "bermocol_29c3278d39dd4fcfbf03bf7aebd2c39b",
          "bermocol_a4b5e25f07904ab5bb21cd45d2d5b977",
          "bermocol_ba2adb626168463cb32df2138b2d1594",
          "bermocol_d126c29bee92487e9fa0472936c09811",
          "bermocol_2a00dbf225f14d6b9138b0ba85d9f625",
          "bermocol_48e4d6f7c371482ba6d3203da65c1884",
          "bermocol_3aaee3930ef54c7dbb85795ffb511897",
          "bermocol_a007f9d3d3024d3587961e9993c1592a",
          "bermocol_064707781a7a476dab03b080ff583b71",
          "bermocol_2261f5c682a346c3b273682d09e7c51f",
          "bermocol_2575705d629f4bad919c1091bea71338",
          "bermocol_0539fa84e0fc4ebfa30a983ca0862edf",
          "bermocol_00a29fabe11541658aca89770c00970c",
          "bermocol_d540821cd9de4ecfa0babfd6dc74d405",
          "bermocol_997fabefed704615b2b2e09de94d979f",
          "bermocol_ad8060c958ab4230b378655795fdbe40",
          "bermocol_d6ec3115d2554c3a864a1fe4d9aad8a5",
          "bermocol_9298cdb35b4c4ad0a15b77009da1d093",
          "bermocol_acd7a1b1d2344af381220ee182d6a7b8",
          "bermocol_fe0b8eaa17c444819acfe1720da5d8d1",
        ],
        recMaterial: null,
        recQuantity: 0,
        actualMaterial: "BERMOCOL",
        actualQuantityFull: 0,
        actualQuantityOpen: 1169.9800000000002,
        actualQuantityTotal: 1169.9800000000002,
        complianceStatus: true,
        actualBagFull: 0,
        actualBagOpen: 435,
        actualBagTotal: 435,
        concentrate: "BERMOCOL",
        recipeQuantity: 0,
        actualQuantity: 1169.9800000000002,
        concentrateStatus: true,
      },
      {
        recIdList: [
          "n_a_t_r_o_s_a_l_213ebe70f9b047e8a9e864f9c8fb2a4d",
          "n_a_t_r_o_s_a_l_af601aa69a0f4c489d50329efeb8e953",
          "n_a_t_r_o_s_a_l_5942a559764446739dd86fcb6e2d2c3c",
          "n_a_t_r_o_s_a_l_78d6c8f885f44e69a9130467427713dc",
          "n_a_t_r_o_s_a_l_a108669e51454e73b61101da014f22bf",
          "n_a_t_r_o_s_a_l_df9b78e53fbc4d47a814e05eaa59af8f",
          "n_a_t_r_o_s_a_l_3cf28c2b83374ba1844d593c095bc2ec",
          "n_a_t_r_o_s_a_l_80e9885dfd834b93839cd6271ccba6ec",
          "n_a_t_r_o_s_a_l_23c457492b0b418f969090742910b3af",
          "n_a_t_r_o_s_a_l_304e25370a9f4878ab546329eec9b884",
          "n_a_t_r_o_s_a_l_cdb1ae2011bf48dabb9894cbeb7db3f8",
          "natrosal_b3eedee891ab42fdb3845a742a4c8dea",
          "natrosal_a6e75e2cbec94bc7bb152227a55745bb",
          "natrosal_29a6a72c2caa4e9b89bbc78289018444",
          "natrosal_df845fbfa5414da6be8a9133202b57f5",
          "natrosal_4e01a242fe964f7b9e06740507dae368",
          "natrosal_9f62af293f5341ff9965884ddef86ef9",
          "natrosal_650c4b9c63c642f9a2a9d4c28bf81190",
          "natrosal_a502e9c9579e43ae9ec877934144bfd1",
          "natrosal_4be4fee33a3a466584b101fa28b1cec6",
          "natrosal_e761638caef345ed82e2011c393b8a50",
          "natrosal_6df2fe14bca34b74b55afc0ee703adfb",
          "natrosal_9615693371e34436b9fec9f0f8b37fba",
          "natrosal_1ebde0b1202a4b458f36eb49e74d9fd1",
          "natrosal_07922460582149b093e21e4dd9bab89b",
          "natrosal_4d203ce518924787bb761cc7c6067dd2",
          "natrosal_58900a2a149e4476a4bcc5d586071a57",
          "natrosal_769c140361bc439f873d5009a845c2fb",
          "natrosal_9a2031e6703a4f57965709e4ac1dd7a6",
          "natrosal_8ecca4f95ce346a6965050ab19773c5e",
          "natrosal_0ccc79355bef4f46b6af1f99ad4d2626",
          "natrosal_4ef0b07327404bd3a3e2012d48fb5161",
          "natrosal_193c2a37808c4066a491bb8c29d12361",
          "natrosal_18e1b6568ce841819cb810cd94a42be2",
          "natrosal_f0738cd916c5411fa59104b94a660669",
          "natrosal_91e8ce079f5741a4a274f645c27dee12",
          "natrosal_a15e87a12a2b4b9ca3ca9ba5d70b77e1",
          "natrosal_dd33652a94df444fad4fd670fa366531",
          "natrosal_5462eb70fb4c47ba93fbd96f7594a383",
          "natrosal_c97d306b6d174e1eb0b732a1f5f62ccf",
          "natrosal_8236af01b3574204bdac4704c27748b0",
          "natrosal_19b51fe2f51e46a4a408b665a986fbce",
          "natrosal_48f6abed2c1e4eda95ba47d3821695b2",
          "natrosal_34367cf93bd747f891ace7708df69bb8",
          "natrosal_1f46359b12a4425989d0e542fc41d8c1",
          "natrosal_0fcd15acb81b4e209abdc67046d9697e",
          "natrosal_088365d8bf87417a8bdce44305d76a9e",
          "natrosal_1b2dc91b306842a2bc19d742f4078680",
        ],
        recMaterial: null,
        recQuantity: 0,
        actualMaterial: "NATROSAL",
        actualQuantityFull: 0,
        actualQuantityOpen: 248.87999999999997,
        actualQuantityTotal: 248.87999999999997,
        complianceStatus: true,
        actualBagFull: 0,
        actualBagOpen: 48,
        actualBagTotal: 48,
        concentrate: "NATROSAL",
        recipeQuantity: 0,
        actualQuantity: 248.87999999999997,
        concentrateStatus: false,
      },
      {
        recIdList: [
          "hecellose_568e7561a55c4371837d10acf45d36c8",
          "hecellose_64c44f876ef14dd8bd2ec9aeb703b020",
          "hecellose_41afd309520c41ba96487b00a554e1d1",
          "hecellose_a4c9fbfbfaf548f3aa348e2566cf386e",
          "hecellose_4c13d79fbf764a738f5915b892bb3c33",
          "hecellose_716e76c7965349e59ae979efb60ac28e",
          "hecellose_d160c8e96883400fb778d01573a3f055",
          "hecellose_d8ce1efde46f4e2e9d44798027e59e23",
          "hecellose_2079d3b4ad0044a6b3c7e1875972f53b",
          "hecellose_1e26f6b586b84b3d9c41c3961890ec1e",
          "hecellose_36cfdf69e22a4c61a502a60fc5b030c9",
          "hecellose_91482446ae144f4eb7abd1b0f0ab2af3",
          "hecellose_085c4e0212f3410fb8ffccb9dd3fc99b",
          "hecellose_ab5b886450f146f789c52d5e87b4d3ba",
          "hecellose_79b8f780b0fa411aa83f0cd3e6239b47",
          "hecellose_a141727c308142a1b990a78af5553382",
          "hecellose_4f0becc8c98c4850a324bbdb483c4231",
          "hecellose_06c516b8f728432997c413e6bcaac645",
          "hecellose_d77b41a038a744d4903b0aeeca8c9b2e",
          "hecellose_0879e3d56a414d77bcf821fbd0e46a66",
          "hecellose_b0b4f0e1974147ddb4080d0f5f8a7f12",
          "hecellose_db026b11f0c54d15b808f104db3dcd8d",
          "hecellose_6efac39585b8476192c33ed83a6818cb",
          "hecellose_d7a72dd2edc64e2baf5013ff0e0b603f",
          "hecellose_fc13703545294668ab4f4bc1108d3d84",
          "hecellose_5c1f61602f304aa18252761495d3685b",
          "hecellose_27cdb1a76edb4ddaad4dc96ac706252b",
          "hecellose_8e45c82e74f94fc2874f6a903d9bf7c3",
          "hecellose_9d3a17f70630488a82ae0806ecdc82d7",
          "hecellose_0af62c82ecbb477298b7af200bf8e2c2",
          "hecellose_474494f3aaea4d7c8e7cf1207379ac1c",
          "hecellose_1bcd06f495c44ff9921703afe4fe207c",
          "hecellose_248bb647772d4f46afcd089bcc08174b",
          "hecellose_d5c2bd691b504d2caa943001735e0c37",
          "hecellose_6d0d53b5b2c246c89e78745d2f36075f",
          "hecellose_6d05678c7a6f4cd8b2b1dc0acbeabeba",
          "hecellose_090cf63e936544059cd49576815bc0b3",
          "hecellose_0c808284fb0a478f8752605b1f2ede39",
          "hecellose_a4a96bf9a2b8420bb032d626a289bdb9",
          "hecellose_c41c72e037bf4b018578d3f7a1a2343f",
          "hecellose_8522c199dea0401cb2ae76820834ae81",
          "hecellose_479ac9abd4224eb0a7b94d26b8bb98ec",
          "hecellose_dd43b1e6729844669ad0a26c006d7267",
          "hecellose_0e22494c3dd7484e89a0c5f4b8c1d622",
          "hecellose_7af0bc53b8d64d67a7299c06684ccfa5",
          "hecellose_5e0598ed2d1942a0b730ff711ecfc601",
          "hecellose_082a7aec35e04e42a10b60b33d3ea5dc",
          "hecellose_2ee48c42df3d4f4a9828214e00686f07",
          "hecellose_b0d95d9885c64185866d6e8a8ad85c89",
          "hecellose_39f1632bea784d8b888b976fbbdc102f",
          "hecellose_253ec44a43cb4107857b978b7b8f8d5d",
          "hecellose_98a0cdefcba74dff9663f8f904e75cd1",
          "hecellose_33ac534b85394d7e8233138a0139bea0",
          "hecellose_9a9011f5d8624f96acd15cbaf78db88e",
          "hecellose_9caaab0cf2ed4a8f968d4d49c3d6f616",
          "hecellose_aa01416614c540efb362fffd28c7f714",
          "hecellose_21cdf6657f54473f81863f4535babe67",
          "hecellose_efe24e80bb33475687f7ae1e977c085d",
          "hecellose_6b7958137e25428980dece082678ce62",
          "hecellose_3832770a05f74191baf2d8d0286ba2c5",
          "hecellose_ab5a622360dc42198cd0aca5fd317a0d",
          "hecellose_60c27f9ebd9f4e91bf43c254da27ae83",
          "hecellose_34712bc7e8654b8ca57fad6ee3125cf5",
          "hecellose_80386abcb25a4247be34dce3f3d9c313",
          "hecellose_7263ec4c22574d28bbca639cfaf3fe7e",
          "hecellose_ec6f5d6721c146c7990d0e63b5ea214a",
          "hecellose_4a45a70b065243aca7a93df23995dad5",
          "hecellose_9eab1f3f93a34fb7b68fe54c4a364007",
          "hecellose_a1e85a6e5b6b4787bb11202fc619716d",
          "hecellose_303dddb8b18c46cc9b4a9be38589ec73",
          "hecellose_51f60de2b05e48ff932db79230c09d57",
          "hecellose_81c560e2b5414fe59ce65baae4f8c6fe",
          "hecellose_f5150605eb5b49e18189665700b48e12",
          "hecellose_524027efccc241018b94c5b5d50d378c",
          "hecellose_49a7e90a706747559511c70932b19811",
          "hecellose_c1b0bb88766b4064ae8361c97fcca9b6",
          "hecellose_63dbf5d638ce4144a395991da5a5dc1a",
          "hecellose_82cd8182f50d4623844550ac9f68a877",
          "hecellose_4531c3205c0842b1bc67e67692ca8bad",
          "hecellose_d374884c0a0f418991fc993cb0849a87",
          "hecellose_e82e53a7dcaa41c29e6b9eefae5ba2d1",
          "hecellose_e58c74be8a184c53b461e363a6772090",
          "hecellose_a63f77dac4dc419ea56f8164dd787a96",
          "hecellose_6f5224d4d5504f19ab23b9a31e524f08",
          "hecellose_5d0481e9f88a4118af98fdee683cb2bc",
          "hecellose_8f2036b7757a422795e5bb20278a7637",
          "hecellose_759d50293aa14a1792cc88c60449e5ab",
          "hecellose_6a555f3002464f708bdb4609e482aa04",
          "hecellose_c7dc13dbc8124a479b9fc0226bcebe77",
          "hecellose_bd6c435698274f3c90b90b10c48fbf28",
          "hecellose_4ffbda10d6e6455f888ce805b7a307e3",
          "hecellose_2a24e282511f4307a479e6c3d010b8bb",
          "hecellose_734091be80af4107b698e45e75ed3694",
          "hecellose_3cf204dbde0d49348315ab2f6594b33d",
          "hecellose_a4c8f56f943143e3a7c9ec493d16caa4",
          "hecellose_22107325931c497fbc62e582ca0e028d",
          "hecellose_eefafe24091b42e69fc857467a42e131",
          "hecellose_0001d78d6a43466bb695c48ede620a65",
          "hecellose_8f71f1950a0f475fbb7bde1b08f94a23",
          "hecellose_aa4a794f6b0e40ea889c773e6d2c3a95",
          "hecellose_80ee8202bc0845088f0dd63b85f0a0c3",
          "hecellose_91bc2a7bff6c4df59f8c5f10846d57bb",
          "hecellose_684bf4cb2b8248bfb1ea04e43ed97866",
          "hecellose_6d3a7bd5c0bd4243b6d04d8bf6ee4d3b",
          "hecellose_93767bf4666342538ab96e673d7814ff",
          "hecellose_d2b976c338f841f8b7f0568a6fd06eee",
          "hecellose_1a1eb316f31541f2a3ecb985aa32146a",
          "hecellose_814b27fc82ee48c09ddd3dbc70e5dadc",
          "hecellose_dfb09194d7724276b1d16df4ca3119ad",
          "hecellose_9450ac40497442009692008d27c24045",
          "hecellose_21cf5b6936b7437e8ab789387619affb",
          "hecellose_52364606679b4b8f83581b857b05c410",
          "hecellose_21ed563d4aba4dd1aa133cbc4ea211ae",
          "hecellose_537e1058655a492fbc872cebe340009e",
          "hecellose_6064c3ab7bd44b0ca48e48ff3a77b282",
          "hecellose_2932f4e7dfa443efa3b41295add5fdd0",
          "hecellose_2be256f822774b809c83a58356fa97ea",
          "hecellose_8ddf982ccf064b72a9edd5086da5f3c6",
          "hecellose_74823fd8cafd4535950ede308be7ac54",
          "hecellose_1413bde403134730933cdae7dc855959",
          "hecellose_f103dee396914768ae9799d824a27d0f",
          "hecellose_c8b1e60a59704b198251b13ccabd2335",
          "hecellose_f02d6b55b0c047ac898ddbc7ef1b0583",
          "hecellose_a3579bf1468149eab968761c28205112",
          "hecellose_c7eb25159ebf4bb1b29930c078ab89cb",
          "hecellose_a860872131fd48a29f7abceb074e55fb",
          "hecellose_c4c462140ac645c88f00f851075ad2bd",
          "hecellose_764dd280ed4a4c1bba622866ebd79af5",
          "hecellose_a564267299f341a89f4161ccbd737cc9",
          "hecellose_f11bb756e2244aa8b30cb9fa2e833deb",
          "hecellose_f58c707b65bd4144a11c17cf5a56b52e",
          "hecellose_364ba720aaa04b2582ea0efa9f170cc7",
          "hecellose_d539b3ef3fa94ac981639dcef566906a",
          "hecellose_77d872ae4f514c0bb7a961cd631c5950",
          "hecellose_23f42852bccc42669e7c9bb34ad91d81",
          "hecellose_831e051704774af097d9f967cad6ca7b",
          "hecellose_5b204d36e07d4994855569d8f2f340eb",
          "hecellose_a1014a02e1994543a391f83c76998cb3",
          "hecellose_8a56927cd3614c79b37a409ee22b7d18",
          "hecellose_14cd6a23630941e79c5b329ed86e7f63",
          "hecellose_32b073d16a2a4ec1be78afea53e8cc43",
          "hecellose_8477782b532e49728d339b7af11cfdc3",
          "hecellose_fd97bfbee3ec4af6936697c84e77e89f",
          "hecellose_b1ef84001087494bb08877eb9af8c25b",
          "hecellose_216dadce41ee4716be931447ac8c9fd8",
          "hecellose_aaaee98e947a4f10b0a3e4010779b141",
          "hecellose_09041231ac1044948ba4bace1cf0cc9a",
          "hecellose_62213a1f1e77461b9990ab63bfaf1960",
          "hecellose_eb4b60e5cbf640c0ae17c35b49fd55cf",
          "hecellose_c7111644a8344d8687f3b853f306fb6c",
          "hecellose_0ff59988b48548249395c1ee18a36fd7",
          "hecellose_07ea2d25f99e444b9af18954608d7bfc",
          "hecellose_df9365ce50e043a1b24c0d0b45a967d9",
          "hecellose_7a400f72f1ab496e84c15834707c5cd1",
          "hecellose_898da88c812c45d8b8b6db047f1a22ac",
          "hecellose_8589aab6b0314659a49bb1488a0d8fcb",
          "hecellose_79739229ba1e4b88b9499c652afab3be",
          "hecellose_487bc1a05a1d47efa7de31c168f99657",
          "hecellose_c89189d595bc4079add848dce0e5a37d",
          "hecellose_6d34881af63b4d168c0134d6ab0e9d87",
          "hecellose_c20c61d6981d4ab4af5fd90af0fc4af8",
          "hecellose_a11e64013b61491a9b4bbe03ab664491",
          "hecellose_8566c543c5bc4e589a481403fff2e678",
          "hecellose_9296b29140c44ecba471e115fcf27873",
          "hecellose_7dc34167934d499087eab5c8a61a828c",
          "hecellose_fe42469b62144e9a8ca96486c2620242",
          "hecellose_68391397bb2e42a493b48887a9034363",
          "hecellose_3ac02b077f7c4a578f0c16ae67a104b5",
          "hecellose_46f75616685b4c3598eb4c2c46bb0db1",
          "hecellose_c7483bc1cb2e478e9c84c8d0e302cf65",
          "hecellose_3b6615fa1c394925a54e07777a8139eb",
          "hecellose_4568f2b470834a11a52903893ef656bf",
          "hecellose_881b21d30304422c9ec5af216c7be815",
          "hecellose_940f0b48689a49599f98f07fe521e116",
          "hecellose_fd553088976a45cb860e9f5e9281f8e7",
          "hecellose_da3ce9f3ffe54375ba8f02f728070a50",
          "hecellose_054293bd6a2048189a295e7150642850",
          "hecellose_10df6828a7764542b2edc5f197eba93e",
          "hecellose_48dd79b7f764425293ff80a6bdb6e63b",
          "hecellose_d90ca3befb5e4308b1e2535c48c580e6",
          "hecellose_dcc2dcbf5b4c47459a6eeb76103ec0e2",
          "hecellose_e5e439aad5c3487fb9bd4e0649ec47a2",
          "hecellose_2752b30f1ede4b6a89f7f7d103f287aa",
          "hecellose_b7d0fb1cef184b188cce8fdacb624b61",
          "hecellose_e16ee98f550d42faaf68a41ca6155639",
          "hecellose_e39a66295ff34981ac3fc539a2a04add",
          "hecellose_3b0a147f4c4846a5a93a1633660fb121",
          "hecellose_1a185bd5580c41139bfd9145ef573739",
          "hecellose_eb71de1868034333b79f54e5abc8b62e",
          "hecellose_0fed595134b747b99a43977083b69470",
          "hecellose_e059b01ff7a84423af8b1d27da8b08b9",
          "hecellose_1a9909d5acda418288cbced5eaa532ca",
          "hecellose_93bca1bfdbfd40b4a9620389698aa55f",
          "hecellose_766c045bd60f4fe2983fdbfc96ba9d5d",
          "hecellose_533f40e98a5043b0b399e1bb696386cd",
          "hecellose_f1b498274e6c46f18fd457f48bbe55a5",
          "hecellose_bf7948e77bb64ae09c0b567ebc64855f",
          "hecellose_7c6597ac78ee461ba868826961c9ea8c",
          "hecellose_d42ef044d6e94645b13a55ccba34f0f1",
          "hecellose_d32c2eebc3ce4af6a8e09bb56b1ac59a",
          "hecellose_b170ac4975864336a0dbeac8dd02b233",
          "hecellose_b2db6b8fc8044cabb2902c413792e2b3",
          "hecellose_a6bcf3b44ebd494498d07517601df90d",
          "hecellose_6261938ac1884e3e8798262bec71d7fb",
          "hecellose_b6714b1d01c24b50b248c62ed91ba949",
          "hecellose_30ed4e578412459f85012b4bbffdfc67",
          "hecellose_249a7a6d699f4ca58312a27e16a5ce8b",
          "hecellose_f170331bc51f4e05b3d2a3db94c0e247",
          "hecellose_371591a004444d9b8d6ecdd8c8677499",
          "hecellose_cf79b5a749d243428c90a63a56464799",
          "hecellose_de206ff3f6d143c0b1c4b2a1b9f9a45e",
          "hecellose_562facd559624c038a3c6974d746f963",
          "hecellose_6c860b9dec6d4d89878507bb056dca16",
          "hecellose_0932b97b5fed47d59b95c1b3f1accc33",
          "hecellose_f7561f1e81764a339677e1d6487a8cfb",
          "hecellose_fdecfea1b6ba4f6eb2b6af51699311b9",
          "hecellose_5d5c22331c58407ba2150e8f5cc80da2",
          "hecellose_28e2648c1f354fe1a993e204454e7283",
          "hecellose_5942004989364aa29a09a4666bfbe3e7",
          "hecellose_ad7b0a5188664fd995055ed16c695e0e",
          "hecellose_2c1510da739f4a12b880676bc8395ee5",
          "hecellose_683b6554509d4b3ea06fd051defca24a",
          "hecellose_6ca05629cdfb4c5fa7a525ca1214fdef",
          "hecellose_3181aca85042482f888a5b411cac3403",
          "hecellose_43f9639b5c3341efa3e76d9f9c4ddfc9",
          "hecellose_19fc4528c60f484e86a14742353aaace",
          "hecellose_b973a037c79f41d5900eb4eae66a2ea2",
          "hecellose_eebd90f9f6a748a9a4ddebd0bfc05d12",
          "hecellose_d0663e31583b409b963fb169914f6509",
          "hecellose_79e22ad456394d83ad50e477f4078f2e",
          "hecellose_b530d3ff6b704eb59af1e3ef2885c12b",
          "hecellose_0967f98df1f445318517a1262f09c540",
          "hecellose_a8b475c9376741619726ec9edcef6748",
          "hecellose_4dcf05dd144e42c1af71810cb116cf93",
          "hecellose_441822faf6a5460282c7365d43b2768d",
          "hecellose_b877a48652ab433dba95d5e068dadd91",
          "hecellose_c79af8e2051840b2a7222702e3259dfa",
          "hecellose_4d5e39f9179948f4acd3289c0fc1564c",
          "hecellose_ae612699ce624c499e33709e9ea013ae",
          "hecellose_cb0a8b79d37f4323a3ed50f2006b269c",
          "hecellose_fe732997e05a4896a2e2ed3e9b0d5f4e",
          "hecellose_f49ee0acfaac4aa7af5daaedfd13fc0f",
          "hecellose_e3ea807eaf464376ac3478e160194ff4",
          "hecellose_d78a98c82c6447be8d7f041c44b8c19c",
          "hecellose_bdc36a55a26e41e19abaa09e9d1dc131",
          "hecellose_68a78c32cca748e7bd2e13cf7149f2b8",
          "hecellose_a93625b5be9c4641951b16d0e9ca39dd",
          "hecellose_ab1b7bb3f4604cc18f9b405beff17ea1",
          "hecellose_c075ae79d0cd4074a15f53e03b128a72",
          "hecellose_f9bee8946148447fadcde7409cf1e3a2",
          "hecellose_360f7162e323446cbbe9b79147383c52",
          "hecellose_785f2d11c31440ce8ff2dc1cd4d7b318",
          "hecellose_1ebf7c341d0d48a38f8bd6352fa60510",
          "hecellose_44d73e3a218946269a8d402bc612c1cd",
          "hecellose_ffce3fa680fd4aa295ff6f9880ea1d47",
          "hecellose_43ef845c1d2f4776bcb37a74ba8bf5fd",
          "hecellose_ca301f0ed4c3441191c5602830402362",
          "hecellose_80f419d9313849b2bd4e7409083d3d1c",
          "hecellose_b5e89ddc5d9e4db8a74e2464c1e32c40",
          "hecellose_097359b301a5418288ef02e93753ed29",
          "hecellose_95eb8682b9454c36b193c42fcf775bb3",
          "hecellose_b3984af8f12f4136b25df8f3ad52b1d0",
          "hecellose_afe76855439144b789ea62542309ba9e",
          "hecellose_8445db55953b4aceb0f05041ec63c458",
          "hecellose_d794181f8a1d401890062a2973f8d2cc",
          "hecellose_d12a4c9137694185822a9df4a75e00ca",
          "hecellose_4bb8d0ea9a1a44dcaba01499ab0707e9",
          "hecellose_c1f2ad3568f14e59829de80a3819e54f",
          "hecellose_fa6f2eabbf00454bbc2b88d3f5ddbe43",
          "hecellose_8262dbbac5f14f7db992f1c83780cdbe",
          "hecellose_636dbf8155fb4ea6bb8785e3ba3ac0db",
          "hecellose_da744683b235454b8141058794a26ffd",
          "hecellose_2861e20986ae4629af3abaa913ca8e1a",
          "hecellose_dffc514134a048ae9069a45cc4bca862",
          "hecellose_3c42f95e2a12412889d6e2fc60bef47c",
          "hecellose_3f58cf59792d484195d0ced34623df32",
          "hecellose_8449940617fc4e0ca7aed27003928f10",
          "hecellose_63a0e0fea4f54a57886116a06a9a24e7",
          "hecellose_f456ac04eff345069933b5bd5bf6a61f",
          "hecellose_0d980dad35074c38a3e6fc6aa3389fba",
          "hecellose_3a6e1b4eaed4453dad989a42c30b3718",
          "hecellose_70e65e67bc8c4e6d8cde9cab7ca5cf96",
          "hecellose_da5cf195303640b2aa669ffcad9530e5",
          "hecellose_f383069676c54c7ea569b38f2c733ff7",
          "hecellose_aec5df51babe47a1b3ed95ccc3bb252e",
          "hecellose_b43f03c9a5914fdd8b6d4e03d7c80318",
          "hecellose_33d4c2ecdd7a4db7abfddb861e1c1b2f",
          "hecellose_619bdb7699274220ad94bc5537a25ff6",
          "hecellose_aac80169e0744715a6f70c0bb3fd1fcb",
          "hecellose_d5bd9f7603864553994d699a265a3947",
          "hecellose_2f6cd4f2b6e54cb28a83b3375ef072f0",
          "hecellose_e9f9637480604dce9e647967fdae4ddd",
          "hecellose_3b291b0f290745549586b26ea58f7978",
          "hecellose_cd485d836ce84b15942afbce7a7b63c0",
          "hecellose_ff6da3a7299e4d6b810778dcb65a1710",
          "hecellose_494f2a73a9704571bafcd7e92123e2ee",
          "hecellose_bba19036119645e989b574a205033c08",
          "hecellose_0145f9f69b5c412f82bdb87c4bdbea85",
          "hecellose_49ffb1d4b61d49a5b29a407d24be439b",
          "hecellose_aa023912cd1f42099003677500fd7d79",
          "hecellose_081f260891fb4713a2da3eacc11e0b95",
          "hecellose_65f6a2a5ccd64316b1dc88608f8cb4fe",
          "hecellose_1897f8a892434525889cef02dd8f9644",
          "hecellose_151769e122b4496098d934d28adfb8f7",
          "hecellose_f7cacd4b6c094ab6a05f7d7a6cc601d9",
          "hecellose_281e4350b0ad404cb77b6228b72de032",
          "hecellose_77bbbff0e06d465abaaa1a19ddfdd458",
          "hecellose_64da397fd7624ec9ac8174cf014e97cb",
          "hecellose_68182e9e52de4892a9b0f889bfb388e2",
          "hecellose_97301a4f1a9e4d79b545da0b502302e5",
          "hecellose_d43918526ade42d4bdc35ab16b0caf7a",
          "hecellose_46898fa4c42d470b9a87865e5e626036",
          "hecellose_5d9b9084c5ca4a21b76c909e6937fb1f",
          "hecellose_3e4c75b9edc745e19f1c056e935709a6",
          "hecellose_906f4ee3e5a445f190df50ab272f5e35",
          "hecellose_bdce08fed9e947c2805eec4ef91ba95e",
          "hecellose_3e6dc5ecb0c64318b5c2d03e0d372f24",
          "hecellose_773818f4b75a41258cf0312995e0c882",
          "hecellose_8f66eeeafd7e4ba5b12651065b89a090",
          "hecellose_0847cb1946f94023a0350d454e94d277",
          "hecellose_c29ff8c1a7044f20b1ff4f1140478cc9",
          "hecellose_56fd456d0c014595b1a74e767251c5ff",
          "hecellose_e3b1c8f0a8604fbabd84902b153427d6",
          "hecellose_ebc68ca9a391488fb99336db1d4fa0f5",
          "hecellose_b48c334e0c494ad1a29d067fd5c41be1",
          "hecellose_f8d4dde05b904fbc9c1fe592671c094b",
          "hecellose_0fd2ae29f8854af99918c9e69213dcb9",
          "hecellose_746e4d9c7f5042fca35617dbd151988c",
          "hecellose_b2a7a6d3180045b4b5a32b783a2bf5ec",
          "hecellose_55d134009d3746528e195e2557d582e2",
          "hecellose_4558fdf61cf743659e8c4fac8282a8bf",
          "hecellose_9d6d4acb571341c290500a7d5c35c7cb",
          "hecellose_0c0b66e130dc4a51860d2bcee44a1751",
          "hecellose_2cf78fae84994776be47bbfb1fe3f2a8",
          "hecellose_b880b46b02a043e4ab68e2e8b9237a44",
          "hecellose_e7169135929447a19d32e864a721f071",
          "hecellose_1fc7258dcb524a37b36f58604c59cef0",
          "hecellose_778ca5dfdc2148d0a99a0c0da9790327",
          "hecellose_46d8ebed1c8345f8adb02572c7b8c780",
          "hecellose_632011cf49b24f7f9dd5833ada71e44a",
          "hecellose_a5689724659848b4b4c4239380dc544d",
          "hecellose_db784c580801400ebb532a5c400a019d",
          "hecellose_da0695e9fa1747908de7ced43d01da36",
          "hecellose_ca019d9daa634b4a94eaed6b0e294479",
          "hecellose_0886d2b02fa240f3b94957f9cd50b8c6",
          "hecellose_6717c184f894460e8ebe00d16e19acf3",
          "hecellose_1b0ad08ec6fd4faf9bb80fbb7931c7c9",
          "hecellose_ea1ec81d266b462bb751723d23647838",
          "hecellose_8d7a4b268dc946a7928303679c6968b5",
          "hecellose_32d3b1753b584412ac02418475354e81",
          "hecellose_77ce8bf20518497db4aae6954c7bb663",
          "hecellose_1f3abad833fe49618c02e1dad9ab39dd",
          "hecellose_f17558488a704538aa4545b4cee92dab",
          "hecellose_9873e79fb04a400f9e97593d76803d8a",
          "hecellose_9a2240bab4a04fff90ad2a6aa7d66a95",
          "hecellose_c92a5dde142a4f568963f6942a7fe035",
          "hecellose_518e1de6509142f9bcf3d4934c8e9de9",
          "hecellose_a8a0beb1361c4ecb863b494254c9b1a3",
          "hecellose_2f11f873bb0345028674ef177f77563a",
          "hecellose_49fec4206394486395cabe9157e9e2a4",
          "hecellose_633ddf42b6434184ba734bd208212e06",
          "hecellose_54f05155cf3044f89ea190a75a2864d1",
          "hecellose_7f323ab438ac47f3a17c71964a828440",
          "hecellose_7515dfeab31e49dcb40a10fccd16e060",
          "hecellose_cbeaead7304841deb6dc86ae27089b74",
          "hecellose_8bac1f550b1a49cda80b1d1a18020a26",
          "hecellose_ec2adda330ec436a8966bf56f03816bc",
          "hecellose_2ae5dbad58d442adaf9973c57fe4673c",
          "hecellose_e34f31d948814a5dab99a0a8d56670a8",
          "hecellose_d14e3daca87b432d84f1bff69fa5044a",
          "hecellose_2e207742d18b4a4c970e75a6efe36de1",
          "hecellose_728a313d4e714e0c9ef53df105aaf177",
          "hecellose_8adb9ce91ce24a038f8979e3631ea693",
          "hecellose_9e28589889e148309f4b0c6938bc9bf3",
          "hecellose_75633ab1276546689a572b0bf622dd84",
          "hecellose_cae9cb125b364302a76ff94282e2b72d",
          "hecellose_d1c27bdcb66744198ea59d175866de69",
          "hecellose_46e9de9a98984afd85cbfc1a182daf5c",
          "hecellose_8215ab6b73b845d0b53ea2c0e67c5f06",
          "hecellose_43af7ddfc6d04f9d8d2101ee9991ba66",
          "hecellose_05b6350d741c49229757c8e5bcff9114",
          "hecellose_9526284379794edab24b19c6e6c20ac9",
          "hecellose_30c0768ba7b449f5b1e5978a3b742705",
          "hecellose_53fe7670227945429a318665c558e3b9",
          "hecellose_53238a64293841d0bf7723a0209eca4e",
          "hecellose_59ac433e6b9f4e799619f46288ed2ece",
          "hecellose_e8302e50c7c44768bf1d7b70271869a3",
          "hecellose_7dd8ce1d8a8b41f7849bca036e844b5b",
        ],
        recMaterial: null,
        recQuantity: 0,
        actualMaterial: "HECELLOSE",
        actualQuantityFull: 0,
        actualQuantityOpen: 993.4600000000003,
        actualQuantityTotal: 993.4600000000003,
        complianceStatus: true,
        actualBagFull: 0,
        actualBagOpen: 388,
        actualBagTotal: 388,
        concentrate: "HECELLOSE",
        recipeQuantity: 0,
        actualQuantity: 993.4600000000003,
        concentrateStatus: true,
      },
      {
        recIdList: [
          "sodium_hexa_meta_phosphate_b720518a8b164e4ea54bfeb0be0e43b2",
          "sodium_hexa_meta_phosphate_67a378cebb5d497c8d16120734ff8de5",
          "sodium_hexa_meta_phosphate_64568178385e4a80872ef015455522a6",
          "sodium_hexa_meta_phosphate_6339f77a223b4a22be1f857b639a6b0f",
          "sodium_hexa_meta_phosphate_2c2b595242ee4a4fa5eeee2b437b7777",
          "sodium_hexa_meta_phosphate_05f0b0f9c4c4483aabfe7ddcc768b8f1",
          "sodium_hexa_meta_phosphate_23866375aa7c4731a2fe15019bb09bf7",
          "sodium_hexa_meta_phosphate_61b941179f0a45c1a54fde6c65807d32",
          "sodium_hexa_meta_phosphate_6feb70d1f276440e9bb20e6e33f98865",
          "sodium_hexa_meta_phosphate_c6a71821cfda48adb43da02a45cb288b",
          "sodium_hexa_meta_phosphate_eff90b8cea33495ab3e1f332e0c5cf3d",
          "sodium_hexa_meta_phosphate_f3e9200dc9e543caa59af89a05c3c3e5",
          "sodium_hexa_meta_phosphate_ef3e966be2624f659e93ab7e61e3cd74",
          "sodium_hexa_meta_phosphate_ee8ffd163c9e4b9c83e37dbac0e41cb8",
          "sodium_hexa_meta_phosphate_9961aa11ec14498eb0b61a1208a1e3aa",
          "sodium_hexa_meta_phosphate_0e8d8dd7f8ab4188bb2703b0cc73f800",
        ],
        recMaterial: null,
        recQuantity: 0,
        actualMaterial: "SODIUM HEXA META PHOSPHATE",
        actualQuantityFull: 0,
        actualQuantityOpen: 14.72,
        actualQuantityTotal: 14.72,
        complianceStatus: true,
        actualBagFull: 0,
        actualBagOpen: 16,
        actualBagTotal: 16,
        concentrate: "SODIUM HEXA META PHOSPHATE",
        recipeQuantity: 0,
        actualQuantity: 14.72,
        concentrateStatus: false,
      },
    ],
    dumped: [
      {
        recIdList: [
          "b_e_r_m_o_c_o_l_2036eb5d93934016a17c2b3e7eed462f",
          "b_e_r_m_o_c_o_l_5f54dba0ce9b41d886e4f532f12e1b59",
          "b_e_r_m_o_c_o_l_f95d132da26445b3882a95d4fc0f2634",
          "b_e_r_m_o_c_o_l_a9c670614de94debb8ad47f7e7e7d012",
          "b_e_r_m_o_c_o_l_0707e1f2620b4fe2bbb59f23901ebda9",
          "b_e_r_m_o_c_o_l_7f2896a79160404fa6ed77ad5f94f0f3",
          "b_e_r_m_o_c_o_l_f598d91a345e479690f4c2112fb05e31",
          "b_e_r_m_o_c_o_l_1db8d9d06a6143faa045afe57bede8e4",
          "b_e_r_m_o_c_o_l_13ea26253b464214aee9bae1374f3065",
          "b_e_r_m_o_c_o_l_30313532d4d046c28a26ef94c7d5a88e",
          "b_e_r_m_o_c_o_l_0b8fb227ddfb496eaed353fd13c9b78c",
          "b_e_r_m_o_c_o_l_322082f98e184d5f85e810b7ec10a049",
          "b_e_r_m_o_c_o_l_2ebedd74943c4519869ab8db56518cd6",
          "b_e_r_m_o_c_o_l_cda031d880a04666acd70d4dac96da6e",
          "b_e_r_m_o_c_o_l_1028a2d2a568473c8339600b27b1e18f",
          "b_e_r_m_o_c_o_l_c3097323d1c746babe0c1f3b7c3bb8bf",
          "b_e_r_m_o_c_o_l_efb600a837784b6c8c49809ddad95595",
          "b_e_r_m_o_c_o_l_737a9a7e0af044188924f361a3a520b5",
          "b_e_r_m_o_c_o_l_c7509d9fe3394aa58fd66e6653396caa",
          "b_e_r_m_o_c_o_l_0012af3770c84720aa9a91c813a3f172",
          "b_e_r_m_o_c_o_l_b438e19b964e485e8a9fb35cdce27d91",
          "b_e_r_m_o_c_o_l_6e15e4085fdc44adb7503ddf09ce6ca8",
          "b_e_r_m_o_c_o_l_0ead70baae3a4d25b4d4e90bb8456dde",
          "b_e_r_m_o_c_o_l_6699f23864004b85a1247c49fb0c699c",
          "b_e_r_m_o_c_o_l_5b1b8b2d3f0644c8bbcc8ed6e0c7cea9",
          "b_e_r_m_o_c_o_l_d881674e06c6440481be16a463326ad2",
          "b_e_r_m_o_c_o_l_51541b00b32d49928afaae1d523a945b",
          "b_e_r_m_o_c_o_l_b12b365d381148f1b0027360dd4b0909",
          "b_e_r_m_o_c_o_l_ed47bade9bde488583975215b67d18c4",
          "b_e_r_m_o_c_o_l_95759bdede1f4b9293ac4a38530f61e5",
          "b_e_r_m_o_c_o_l_eff81a8d04934748a702178ff7b505a0",
          "b_e_r_m_o_c_o_l_b2e5171f31ca40cd8deadc682da7d78e",
          "b_e_r_m_o_c_o_l_26d6dd29ee3f4485b9765fa41a5a9f2c",
          "b_e_r_m_o_c_o_l_e2a05fb7da9a49d5b3ccc00b088a451b",
          "b_e_r_m_o_c_o_l_781c48f31ded4e3a91a33949b319e181",
          "b_e_r_m_o_c_o_l_71ee25c93b11416e8a064d1a77248134",
          "b_e_r_m_o_c_o_l_042b7266c0fd43358c20283e85265e30",
          "b_e_r_m_o_c_o_l_709316cfe23c4ff09e6947728ff7590d",
          "b_e_r_m_o_c_o_l_d912fcfae87641f9a94355a0e075a6e3",
          "b_e_r_m_o_c_o_l_7ae5791c0e3b4a9da40461d701a5a14f",
          "b_e_r_m_o_c_o_l_a88d0d1f5f5c44428dc07b6ae5489316",
          "b_e_r_m_o_c_o_l_0951a8acb017460e9d3b974323dcad34",
          "b_e_r_m_o_c_o_l_4c0a69ec38d743b99b8ef8c88aab9f35",
          "b_e_r_m_o_c_o_l_a099c2e177e544d2a381e979507c3817",
          "b_e_r_m_o_c_o_l_9d6fae40006f4860a1e4124e475e4f0e",
          "b_e_r_m_o_c_o_l_e2f5102b0a2a4607b759909db7dd251a",
          "b_e_r_m_o_c_o_l_360990908497464a9e43acd2769e7875",
          "b_e_r_m_o_c_o_l_a6c27a5617564caa8f0f4be350863a47",
          "b_e_r_m_o_c_o_l_6027df52813b4d12b36454bf5269a01a",
          "b_e_r_m_o_c_o_l_09bf625d8ead4c4fa0400a782e7fc33a",
          "b_e_r_m_o_c_o_l_b422f3fce41243d3a28e95e44bef96bb",
          "b_e_r_m_o_c_o_l_72c6ac5e4c8d419293ab6f9894dba352",
          "b_e_r_m_o_c_o_l_1a480be8c0684b3796f54c8571fd8037",
          "b_e_r_m_o_c_o_l_72e0ebd7aab24e61acf8ef6c0fc8202d",
          "b_e_r_m_o_c_o_l_d1f619d1f7014b79a78c4058331d0abb",
          "b_e_r_m_o_c_o_l_c36faa2af32648ac83418580e20850d6",
          "b_e_r_m_o_c_o_l_826d29c062594f69ae8afe146a6b2196",
          "b_e_r_m_o_c_o_l_ffd4beab1e104afd8e06b52ebc3bd0cf",
          "b_e_r_m_o_c_o_l_e332de5b7ef84c2e97e35abd75bd8b67",
          "b_e_r_m_o_c_o_l_d0d8f35672674f569877d7d29fd23be5",
          "b_e_r_m_o_c_o_l_87c0022b7d1d428fadb4fe0590c98e0b",
          "b_e_r_m_o_c_o_l_7a4eb673253f41239d6c04cc5490a8d1",
          "b_e_r_m_o_c_o_l_203d7b50f58d4240ba9ca8ed76ef1f8f",
          "b_e_r_m_o_c_o_l_d32a76fa0470477baef82878ad66f443",
          "b_e_r_m_o_c_o_l_a41e4a31b7994df8a57482da4570169e",
          "b_e_r_m_o_c_o_l_e400bf98c4364b00b133109d7fc3278b",
          "b_e_r_m_o_c_o_l_5f54866c2ec24f5eb6b8636fc0f3a83c",
          "b_e_r_m_o_c_o_l_d07722365e824f55a4b21115346cd335",
          "b_e_r_m_o_c_o_l_e3f3696413a7482bb963a2124c9f63d7",
          "b_e_r_m_o_c_o_l_f31f5e11d4b54d81a7b14bc0f57627b6",
          "b_e_r_m_o_c_o_l_cb5dbdedaa044a8190d112a8d237622e",
          "b_e_r_m_o_c_o_l_d9d30a379e3f418da564caa468284472",
          "b_e_r_m_o_c_o_l_55d62fd8a7d443e2b911fae3e8fef925",
          "b_e_r_m_o_c_o_l_e5fe591264c54fdcbafdf64e57dc05ce",
          "b_e_r_m_o_c_o_l_4441873ac5eb43d996c66c706d7c95d6",
          "b_e_r_m_o_c_o_l_e7a31cefe2f54716baba42a177ef3b12",
          "b_e_r_m_o_c_o_l_a6cf18b5541e4a479f79d77fbe79678d",
          "b_e_r_m_o_c_o_l_34901ec587954bfcbdeec326af62d07c",
          "b_e_r_m_o_c_o_l_4e2f56ebadb64e2e81a6808dcf9c101c",
          "b_e_r_m_o_c_o_l_0c11f932283f4f27911392e2ab547de1",
          "b_e_r_m_o_c_o_l_cb12106269d94abdb8c1a6537b79dd7c",
          "b_e_r_m_o_c_o_l_ebe2a66984314cccad152b4e43e118eb",
          "b_e_r_m_o_c_o_l_adc2e1ce6b79410a8bfddc45ad0b2da3",
          "b_e_r_m_o_c_o_l_8a755de5e3a2400a835b8c9b70c668f5",
          "b_e_r_m_o_c_o_l_8daf59076dd54eeca52026928accc6bb",
          "b_e_r_m_o_c_o_l_2f6b1c6095c5466badf1ed3cfab605f7",
          "b_e_r_m_o_c_o_l_9d3818ed548a4a96a6c20220f2f46481",
          "b_e_r_m_o_c_o_l_2e13862be7bc4e1e997d5440a09ef5d1",
          "b_e_r_m_o_c_o_l_7920f33551944b2dbf6c61422cdb6c17",
          "b_e_r_m_o_c_o_l_cdbcc230459d47b9af8b813e1c0b66db",
          "b_e_r_m_o_c_o_l_01af76d043214d258a634a0cecac68e4",
          "b_e_r_m_o_c_o_l_333c776d9792486a9d76ebfe26013d36",
          "b_e_r_m_o_c_o_l_724a98df3d424c2ebda1d5e0304ba3ee",
          "b_e_r_m_o_c_o_l_6cde28732798459480c34d647f9ff275",
          "b_e_r_m_o_c_o_l_7281b69d95ca4769bcf6f68080c3f779",
          "b_e_r_m_o_c_o_l_1ede46a228e242f9b44dae7337363d55",
          "b_e_r_m_o_c_o_l_51630e45d13f43e7b303e9047989f603",
          "b_e_r_m_o_c_o_l_81f514c8db944ddbbc2585a0a634aad4",
          "bermocol_5d0358dced9e4535ada4e172e04dd41c",
          "bermocol_7cc5e3abad7142fc8add5ad4296cef88",
          "bermocol_2e7da0601d3b4f9cb3a066b8f9c32aa3",
          "bermocol_2b62c67cb7bc4c928d07bd63ae698196",
          "bermocol_ab9c0d014a234121bfef73add3b8e67d",
          "bermocol_a724e093df2549b29fe6ec854656168e",
          "bermocol_d3df0497760f45dcbab65d7d0c460dfd",
          "bermocol_91522c99da8c42739d25c60398eac976",
          "bermocol_93357f01e74449e59bf0d6a7d1a08746",
          "bermocol_5164b7c7b28b4738996b891f7c1fe199",
          "bermocol_86f98cd8353a4cce897f3f84d737e547",
          "bermocol_9e00768450374a979f838edf0d1fd22d",
          "bermocol_08161601d34544a386680f4ab8874aa3",
          "bermocol_7470a7b0653245daa42d53f179d89b3f",
          "bermocol_f8a38cca44ac413cb5ce0285d109fc62",
          "bermocol_52183e31c2ad4d7f8b3ab694d5b28f20",
          "bermocol_d4766dcba42743078b9a494846bd2ce8",
          "bermocol_5341e9b2e87448419b989568c29b15be",
          "bermocol_647deb51677442688d442687f9c75c3a",
          "bermocol_811a6fec67e3445eb1834ab9774caa72",
          "bermocol_74499adff4f9454888033fc14f4a5f96",
          "bermocol_2d4b58645b454ffa9c18876d90708706",
          "bermocol_42d003f685be45de9e2f65f132689dfe",
          "bermocol_68517e90c4294162aec76f540915fba2",
          "bermocol_3d41c44abc8a43d5b0fdcbbb8b9a8f01",
          "bermocol_af7071a5e1af476d88cf3cce716251e5",
          "bermocol_bf5a1e47c85e49b9a505cf1ed6f0f4d7",
          "bermocol_0d6757642670479bb70a679410b516d0",
          "bermocol_da0f188ec98b498692810b2bdf086284",
          "bermocol_a06383df92e9420fb0ec3759611d18cb",
          "bermocol_9f062497cbce4a5b80b0f0892ddb91dc",
          "bermocol_80e9ed7f4bc949e3ada365557da2ff1e",
          "bermocol_f18d0bb7d3aa46df962bd1433afa3a4f",
          "bermocol_55e68487e28f46199ef7839be8cc592e",
          "bermocol_2c2170b6094c42619ce792adc0198e3e",
          "bermocol_2860b0e134354dfb8df4dd4d5417fc4c",
          "bermocol_295ce00e451147978a06847ee5b37ac3",
          "bermocol_067d905f4fdd4e0e891d4ebc4c0225ce",
          "bermocol_78c338b48f454c55a163c56e1d1b1e03",
          "bermocol_59fe3fc5a1584534b00a5ff2e4af5698",
          "bermocol_aa8cbb73012f4905bcc0fa8aebdd15b4",
          "bermocol_100b441ef955489583f7d2ba2ab887e6",
          "bermocol_72295e2351ad425d9592af618714d7ce",
          "bermocol_2a158d2908ea45788cfb14f4e3b24226",
          "bermocol_15d83b04fa89471894186761eab94786",
          "bermocol_359fb037aae8444abed3ee5b6f735052",
          "bermocol_2e758a72eee44bdb996f2c3d6eddc8ca",
          "bermocol_857242a159a549a38002033a7d05196d",
          "bermocol_f7693f227de849c8b7f30ec1e1bcb73c",
          "bermocol_9720599548ef497f80b1d162edb963e9",
          "bermocol_d98a500e9b10455b889372d66a181d25",
          "bermocol_2fef984ac0a941ef83862dbbd50faf4e",
          "bermocol_d27acd9831fc4a1193c0b19f6fad4439",
          "bermocol_c7333ac629b34d1d8f01d10ac3ef0a34",
          "bermocol_dcc982ca898946609bbc9b5faedff8b3",
          "bermocol_bae125f8fc084fdaa5e599cc04d761a1",
          "bermocol_c06161a4d63548e486123a40b85fad9e",
          "bermocol_888f210d7ac84a3897344e9e1c235cf3",
          "bermocol_453d176f5e6e4dab9551537d04620975",
          "bermocol_e8c7955ec1d34e639fc82adef3136e22",
          "bermocol_6d6374c00ee1426babb6c0847e01aa2e",
          "bermocol_8fcd1f2204284ddfa15b2801a1d89c98",
          "bermocol_dffcdb3bcdd243cd9ae4af95e41201f1",
          "bermocol_3ef9f65f68374b60b1d9fdfec15c32fe",
          "bermocol_fda88bcaeef14cfeb3fa4453770b616d",
          "bermocol_0a3d201917aa4468a698e9d24d8fe36e",
          "bermocol_2ae487332f304fe1bf3041f904c583b3",
          "bermocol_4fb64cad854140c4b287a862021fe7a5",
          "bermocol_1888acfcc36347d6af1251557189254d",
          "bermocol_c77427823ec2465c8335f01364f321a8",
          "bermocol_7f26eb08de4344ce8bbd0920ab157a4a",
          "bermocol_7f60fc49ffe14f16b2d7758f65d5dad6",
          "bermocol_468d131cb6a14878b1ede5108a7d4def",
          "bermocol_475c8771e3a4455a9b7df41879c5f613",
          "bermocol_7ca36cc1cf4a4841ac0deca8995e2ea8",
          "bermocol_cf1a1523d07942f6a275990f7355eeb1",
          "bermocol_21dd928e53524ece8988c40c21a4a970",
          "bermocol_45fdcdfd1d2d487abbe723c6fab09725",
          "bermocol_b805ebcd83d44a718dea5e8c8fac492a",
          "bermocol_f60fa60669c545868ddbe381ada21224",
          "bermocol_235fed9d8ad44b5ab976187b0cb24a8f",
          "bermocol_228ec66c12b1413cb63700578a862258",
          "bermocol_c3e8779a4da24a44a9820c0737bcef37",
          "bermocol_982089d601774f93bb764884e4875034",
          "bermocol_e144e772cfb3410b93b55c0ad9a8e10c",
          "bermocol_b5ba4ce8d7e34fc88c1e3f9147abe325",
          "bermocol_6d87afcfc81947f9ac079d2b9f75ae0f",
          "bermocol_c3ea40377ed846f3aba00e88fd4a6176",
          "bermocol_8c8ff623e956465685cd2b1d71e32c4d",
          "bermocol_8733a16891374c7d9bde4f6b094d9924",
          "bermocol_433ec9cc97504dc789632dd49161a554",
          "bermocol_428e05aaeb3846f3a7781f68b8d2fe42",
          "bermocol_782ec20b2ec040a7863ca1490a1c18e8",
          "bermocol_97e8690407074b7da9fb6feb325b1e8e",
          "bermocol_5d45eef6564f43fba29e1b48940a622b",
          "bermocol_59445938c9284212b2603e4a63288505",
          "bermocol_7591cf92edd6493388f41577bef7dfc1",
          "bermocol_22a1c9b0a6114330926df7a85acb129d",
          "bermocol_a2cda6ad89f24095aa654860cf1aeb07",
          "bermocol_ccd2827a99be4fc097bd609ce5888b2e",
          "bermocol_f3400ceda2af41daa9cdf4a2b777e3e4",
          "bermocol_f521b5159628491db1f892b5b3b36793",
          "bermocol_2bb5c6713e034a2aa57a505b70ed8076",
          "bermocol_6a30b806c9bb47708eb5d99f165089ad",
          "bermocol_540a536b1b10482ab2ee04574d126039",
          "bermocol_9f070cd1853646b5a87b4fd59fadcb94",
          "bermocol_b5200eeecc374a8998dfcb966d92a6cc",
          "bermocol_de9d8c0d040349fb82c7c7fb7ad18d15",
          "bermocol_ae2c4833ee824e01a951ff88d626b5f7",
          "bermocol_bc45e00bde1844879d7518df8520ac3a",
          "bermocol_83b6d054560f4f3f928ab2f400f5808e",
          "bermocol_d1875d7d06104162a2d7b06c4454c640",
          "bermocol_364ae3a683ac4652ad852cd85086d796",
          "bermocol_b83bf3023f0543249d9d8b56f0be9f36",
          "bermocol_fde94e07ae63486eb5539e58de80e1d2",
          "bermocol_197002c1a636465ab36e1300cab19583",
          "bermocol_f0e6b324aa9d46aea2615cf12c92e07b",
          "bermocol_37465b63f98d4edeb60ff74191f14f76",
          "bermocol_a50edff3343c4ce4abb6c090676bc818",
          "bermocol_4af14cab21224a668d3f1e88622aebd7",
          "bermocol_8ea004ff0d2e4297a1f844b7674b1020",
          "bermocol_0f8355eb8b2c4375a61a3a7a1033226a",
          "bermocol_0bab28b780b14005b6f4dd561a3641bc",
          "bermocol_ad457368b0bd4187a17500d18a373b3a",
          "bermocol_598f81c219b9499883ce07a8012786f1",
          "bermocol_bf5c44ed499e40de843a64d63c9b3ae1",
          "bermocol_ca24176350bf4187a3c025acb53b5ef7",
          "bermocol_47ee51c367c14c54868f8f607ee18a4e",
          "bermocol_a557444df70c41c9b858cf097ebd1fd0",
        ],
        recMaterial: null,
        recQuantity: 0,
        actualMaterial: "BERMOCOL",
        actualQuantityFull: 4540,
        actualQuantityOpen: 0,
        actualQuantityTotal: 4540,
        complianceStatus: false,
        actualBagFull: 227,
        actualBagOpen: 0,
        actualBagTotal: 227,
        concentrate: "BERMOCOL",
        recipeQuantity: 0,
        actualQuantity: 4540,
        concentrateStatus: false,
      },
      {
        recIdList: [
          "n_a_t_r_o_s_a_l_8e53260fcac74365a5709b6c79d4608e",
          "natrosal_943ebb29552e46c2aca40fb07c941f6e",
          "natrosal_3b2bf25f739d4c87a443dd3d305835b1",
          "natrosal_1ac2888efaf34b43b70ad3a6b17dc290",
          "natrosal_d286e87f54bc445db35a2593950f1c05",
          "natrosal_c0c5f7918f3c4f38a8bbcbbd46f9c047",
          "natrosal_3a56efa267c14e4186dc65373e63864e",
          "natrosal_f5e2901de7d8490a919c737c64386fb9",
          "natrosal_bee6c2f5cc074ed39505d47379e39159",
          "natrosal_ce804e59bcd448d18c9b8c56ded21837",
        ],
        recMaterial: null,
        recQuantity: 0,
        actualMaterial: "NATROSAL",
        actualQuantityFull: 250,
        actualQuantityOpen: 0,
        actualQuantityTotal: 250,
        complianceStatus: false,
        actualBagFull: 10,
        actualBagOpen: 0,
        actualBagTotal: 10,
        concentrate: "NATROSAL",
        recipeQuantity: 0,
        actualQuantity: 250,
        concentrateStatus: false,
      },
      {
        recIdList: [
          "h_e_c_e_l_l_o_s_e_fcc22278bf8046d0a7a8ae992f25b31c",
          "h_e_c_e_l_l_o_s_e_3f0769816d7e4315b818d2a6aa6bc9eb",
          "h_e_c_e_l_l_o_s_e_71190c50261840d7a557ff12989998af",
          "h_e_c_e_l_l_o_s_e_e3accb5a810548fa8ac411c9b45bf6bd",
          "h_e_c_e_l_l_o_s_e_e0eb9c2e2fcc4d19be79c5c6175edb12",
          "h_e_c_e_l_l_o_s_e_d27e5f6714b44883b87d02b27ec66500",
          "h_e_c_e_l_l_o_s_e_8be0f299367f445a823a131c92081778",
          "h_e_c_e_l_l_o_s_e_649ae39d9bc847b4a52f1a5497aa09dd",
          "hecellose_20f571621b8f48a0a0683392f7034490",
          "hecellose_72f33d70219542919d3d11d992556591",
          "hecellose_faedafd0a6e544eea6a6a3b2e4fc8451",
          "hecellose_30cfb9dcbb9d4393914f56d0f830ce2c",
          "hecellose_b4ab155f5b634aa5acffd9c5a35fe145",
          "hecellose_26f00f83c2e7417c9351e069f7a91ebe",
          "hecellose_7d7355fd2f374e2fad60c56e40de220d",
          "hecellose_60f055f128dc4551a3e26b877f9ba9b3",
          "hecellose_016905b2a2a64c8d9551dd5f91f3be2b",
          "hecellose_4080bb8a0865429d968041f1f3a8d508",
          "hecellose_f20844edbfce4bab90a8caad2abfe350",
          "hecellose_f499e0862d864ad4ba0a7e90d5c3c77f",
          "hecellose_3ae6c6afd06d4e82a7d3725c299c8efa",
          "hecellose_5c350b65ca3b4abb84bbed9a66f58a37",
          "hecellose_6479e92e4624420fbaaf9d86d0ef9849",
          "hecellose_816354e7238c4f1f90736cc142a7619f",
          "hecellose_3660a04f6f614422a7eccb9b56ba2c0d",
          "hecellose_14566e2c48c54799b3907ffcddb1ad2e",
          "hecellose_5f585f31b52949d2b35c52cdbc56189b",
          "hecellose_f703bf3e769f4aac885785cf78cec0ec",
          "hecellose_12a0f6328a244eccbf749ab37fab9872",
          "hecellose_d2653fedee5942ad98efdd793f3278e5",
          "hecellose_325b3fc2fa864ce9878defa9feb96bfe",
          "hecellose_55ff0ac8322b4c4eae24661313f1120d",
          "hecellose_e61db1c4fa3c4c7aa6ceeb66cd0f0f9d",
          "hecellose_d215ae94f98f410cae92d8efe7bd2377",
          "hecellose_ee8bdcd0556a4b53b3b55f127cb07036",
          "hecellose_153f8df80506471c8bf527db48857a97",
          "hecellose_adf62e614e974d2e88e2e354099063e2",
          "hecellose_520fd1f467af4696b62dac28f2f4991d",
          "hecellose_bdadadc6d1f941b4a36879cc88755bc1",
          "hecellose_05a3abefc10d4749abf3cec593a2d0c3",
          "hecellose_6a87a595ff3e4031bdea9d5381576ffb",
          "hecellose_9c12b75d16434680b58f446adad19af7",
          "hecellose_61ecc5a00c724191838c85b14c8d1550",
          "hecellose_387851623cbe4b8380e9c629f29acd8a",
          "hecellose_f443a7c5bb3740ada970836cfcae5acb",
          "hecellose_986b10a979e146939fdb6ac126f2dd11",
          "hecellose_c275df0d855c4a369341a8b417714db7",
          "hecellose_3683fdbec42c438d99bc398ab76994ba",
          "hecellose_3c181e80a03f4b83a1aefbf6607d2f0e",
          "hecellose_1a23ccec4d5d43269be582e5ece2668f",
          "hecellose_60c52a90e33041fda882c51b61ae586f",
          "hecellose_203223a7d6ef4a7884378f61293678f3",
          "hecellose_d0c7db6ba4d9495dbd32da89107d3002",
          "hecellose_2aef4583636d4eb69926665ef45b8c0d",
          "hecellose_eb9f4b1386cc42bfb0723a2037f0a7ba",
          "hecellose_5e4d4b41d319480c979c56628bf2823c",
          "hecellose_6369383c822f49848594e1964007e21c",
          "hecellose_c4451375d9b741238c8fea86799b4461",
          "hecellose_5280a6029197406e893ab5e17b12f645",
          "hecellose_44adfce4e0f44648b891bc69b33adb09",
          "hecellose_03bebdf166ca48489b7022490b15e37d",
          "hecellose_4cd67a1369144293a7219313f73fc98d",
          "hecellose_56f0045addeb46998bae1e751cb11c17",
          "hecellose_6321581797a54bf983b55969cad439d4",
          "hecellose_50ea688bd21441a3b3ab1e0eabe94d2d",
          "hecellose_23dc2d9fcb9d440da6541814a610cb5f",
          "hecellose_9ef788c5913845e98d4dc424e09a93d3",
          "hecellose_ad7d8c4c78304f879ac42b9dbf1adf21",
          "hecellose_8fcb43a9ad424664ac8fae007c795988",
          "hecellose_6ca4768288a34f9a8c295ac5290934f0",
          "hecellose_92a40bc1165745b6b697a516e1490eb4",
          "hecellose_7c2c35ba2b454b1492201f666f3eabbf",
          "hecellose_39332f7724e04b378c2b0fd6a29eeb97",
          "hecellose_8a3e79af9dcc4cefb9e34745cf7a1539",
          "hecellose_f6c51318e90745b7b702f18c3270de5d",
          "hecellose_d4070275f4b74e45a17ba6a390016141",
          "hecellose_102f3b0cf9784d6b8367edcec042a206",
          "hecellose_a53596804ddd4f50acf214e5aca6b4b3",
          "hecellose_1c45f96a2ec34369bf830239bfb68a32",
          "hecellose_a5814ec753464ab09a95dfad639dd965",
          "hecellose_81b2b2514eb5485280ba87ee174bda70",
          "hecellose_9593485c95ce43b3b8cf9f04562e88f2",
          "hecellose_dd05c186868d4241b1a2cf72ab91f0dc",
          "hecellose_eddb5e4a0f854ffea93711348dca3e99",
          "hecellose_d4b12bee2ca0406ab7531de78c1a32ba",
          "hecellose_4cd42e65146943aa8942ff62f10a3e86",
          "hecellose_af2f412176744a23a049b25a86da178a",
          "hecellose_92f2f950e99f429a8a5fa39262c67868",
          "hecellose_8d50238058a04ac2b3bf9f119b3fabed",
          "hecellose_339530fecf0142de979dc3a867624de0",
          "hecellose_3fc117aa5160432dbb7abdcc0768a6a8",
          "hecellose_b42cafdd02ef454a977056a4838d2e94",
          "hecellose_260d5b3cdb0f42b2b1cfdb189edf08aa",
          "hecellose_6f9084a1e13b4da8ab972789a4f28fa7",
          "hecellose_cbc3420899264d69bd4701d77f92a8f3",
          "hecellose_bf8f39a7ac8b4799b60f8931894d8027",
        ],
        recMaterial: null,
        recQuantity: 0,
        actualMaterial: "HECELLOSE",
        actualQuantityFull: 2400,
        actualQuantityOpen: 0,
        actualQuantityTotal: 2400,
        complianceStatus: false,
        actualBagFull: 96,
        actualBagOpen: 0,
        actualBagTotal: 96,
        concentrate: "HECELLOSE",
        recipeQuantity: 0,
        actualQuantity: 2400,
        concentrateStatus: false,
      },
      {
        recIdList: [
          "elotex_era_200_f805d3199a994b698a64f46b5c911c0e",
          "elotex_era_200_4e9c65da35b7417c8f880fedda9591d6",
          "elotex_era_200_3f974d4746974c9eb269f83550383276",
          "elotex_era_200_92590673b2ca40f7b0a7bfdd70c47a3d",
        ],
        recMaterial: null,
        recQuantity: 0,
        actualMaterial: "Elotex ERA 200",
        actualQuantityFull: 40,
        actualQuantityOpen: 0,
        actualQuantityTotal: 40,
        complianceStatus: false,
        actualBagFull: 4,
        actualBagOpen: 0,
        actualBagTotal: 4,
        concentrate: "Elotex ERA 200",
        recipeQuantity: 0,
        actualQuantity: 40,
        concentrateStatus: false,
      },
      {
        recIdList: [
          "tetra_potassium_pyro_phosphate_636fe8aca7114e3bb8266acac685f087",
          "tetra_potassium_pyro_phosphate_ac00170755a744d39e741d6695f4a845",
          "tetra_potassium_pyro_phosphate_ca057cdab26e448385f89798ddacef01",
          "tetra_potassium_pyro_phosphate_ee0c1ddd138144ec9244ae489d1eb435",
          "tetra_potassium_pyro_phosphate_45a5132fb67249be98d7c07b2653139d",
          "tetra_potassium_pyro_phosphate_7bd895c8bd764f77b90a2136dec1296a",
          "tetra_potassium_pyro_phosphate_2f53ef84b5664be48a95d7ed918b3412",
          "tetra_potassium_pyro_phosphate_e4044f314c1a4e7aa675b5ae257ce9e9",
          "tetra_potassium_pyro_phosphate_5e04282c45bc4ae4846f1600bcb5f4ed",
        ],
        recMaterial: null,
        recQuantity: 0,
        actualMaterial: "TETRA POTASSIUM PYRO PHOSPHATE",
        actualQuantityFull: 225,
        actualQuantityOpen: 0,
        actualQuantityTotal: 225,
        complianceStatus: false,
        actualBagFull: 9,
        actualBagOpen: 0,
        actualBagTotal: 9,
        concentrate: "TETRA POTASSIUM PYRO PHOSPHATE",
        recipeQuantity: 0,
        actualQuantity: 225,
        concentrateStatus: false,
      },
    ],
  };

  const getData = async () => {
    try {
      console.log(material);
      const requestData = JSON.stringify({
        clientId: clientId,
        useCase: material,
      });
      const response = await axios.post(
        baseURL + `vision/v1/workforce/recipeCompliance/analysis/data/`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      setPoDetail(response.data);
    } catch (error) {
      console.log(error);
      setPoDetail({});
    } finally {
      setLoading(true);
    }
  };

  const handleGetData = () => {
    setLoading(true);
    getData();
  };
  useEffect(() => {
    handleGetData();
    const interId = setInterval(handleGetData, 10000);
    return () => {
      clearInterval(interId);
    };
  }, []);

  console.log({ poDetail }, { initialData });

  return (
    <div className="flex flex-col gap-8 w-full bg-white p-2 rounded-xl ">
      <Tabs
        isLazy={true}
        index={urlParamMixerIndex}
        onChange={handleTabsChange}
        paddingRight={{ base: "8px", md: "0px", lg: "0px" }}
      >
        <TabList className="!flex !border-0">
          <div className="flex items-center gap-2 overflow-x-auto h-12 md:h-14  w-full">
            {initialData?.cameraGpIds?.length > 0
              ? initialData?.cameraGpIds?.map((ele, index) => (
                  <Tab
                    key={`${ele?.cameraGpId}-${index}`}
                    minW={"fit-content"}
                    className={`${
                      urlParamMixerIndex === index
                        ? "!text-[#3E3C42] !text-xs sm:!text-sm !bg-[#f1f7ff] !rounded pl-2 pr-2 md:pl-4 md:pr-4 pt-1 pb-1 !border !border-[#6CA6FC]"
                        : "!text-xs sm:!text-sm !text-[#605D64] !rounded !border !border-gray-200 bg-[#white]"
                    }`}
                    onClick={() => handleTabsChange(index)}
                  >
                    {ele?.cameraGpName ? ele?.cameraGpName : ""}
                  </Tab>
                ))
              : [1, 2].map(() => <Skeleton height={"30px"} width={"160px"} />)}
          </div>
        </TabList>
        <TabPanels>
          {initialData?.cameraGpIds?.length > 0 ? (
            initialData?.cameraGpIds?.map((tab, index) => (
              <TabPanel
                key={`${tab?.cameraGpId}-${index}`}
                className="!pl-0 !pr-0 h-full"
              >
                {/* <div className="w-full lg:w-[69%]">
                  <PurchaseOrder
                    purchaseDetail={{
                      purchaseOrderNo: poDetail?.poNumber || null,
                      startTime: poDetail?.poStart || null,
                    }}
                  />
                </div> */}

                <div className="flex flex-col-reverse lg:flex-row mt-4 gap-4 ">
                  <div className="w-full lg:w-[70%] flex flex-col gap-4">
                    <PurchaseOrder
                      purchaseDetail={{
                        purchaseOrderNo: poDetail?.poNumber || null,
                        startTime: poDetail?.poStart || null,
                      }}
                    />
                    <FeedPageParent
                      tabName={tab?.cameraGpId}
                      data={poDetail}
                      loading={loading}
                    />
                  </div>
                  <div className="w-full lg:w-[30%]">
                    <CameraFeed
                      tabName={tab?.cameraGpId}
                      cameraStructure={tab?.cameraInfo}
                      material={material}
                      clientId={clientId}
                    />
                  </div>
                </div>
              </TabPanel>
            ))
          ) : (
            <TabPanel className="!pl-0 !pr-0 h-full">
              <PurchaseOrder />

              <div className="flex flex-col-reverse lg:flex-row mt-4 gap-4 ">
                <div className="w-full lg:w-[70%]">
                  <TableSkeleton
                    headers={["Header 1", "Header 2", "Header 3", "Header 4"]}
                    rows={8}
                    cellsPerRow={4}
                  />
                </div>
                <div className="w-full lg:w-[30%]">
                  <CameraFeed />
                </div>
              </div>
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default FeedPage;
