import * as React from "react";





export default function BlockCubes({cube}) {
  return (
    <div class="bg-[#EBEBEB] text-center text-[#79767D] w-[30px] h-[30px] rounded-sm">{cube}</div>
  );
}
