import axios from "axios";
import { baseURL } from "../../..";
import { indexWordMap } from "../SinterBelt";

// api's for report
export const getReportDataApi = async (auth, requestData, setLoading) => {
  try {
    setLoading((prev) => ({ ...prev, report: true }));
    let res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/report/overview/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    const data = res?.data;
    if (data) {
      const { columns, table_data } = data;
      const updatedDataWithIndex = table_data?.map((element, index) => {
        const [value, gap] =
          element?.value_gap && typeof element?.value_gap === "string"
            ? element?.value_gap?.split("_")?.map(Number)
            : [null, null];
        return {
          ...element,
          permeability_index: element?.permeability_index
            ? indexWordMap[element?.permeability_index]
            : null,
          value_gap: gap ? `${gap}px` : `${value}%`,
        };
      });
      return {
        success: true,
        data: { columns, table_data: updatedDataWithIndex },
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      error: error,
      data: null,
    };
  } finally {
    setLoading((prev) => ({ ...prev, report: false }));
  }
};

// api to download report data
export const downloadReportDataApi = async (auth, requestData, setLoading) => {
  try {
    setLoading((prev) => ({ ...prev, download: true }));
    let res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/report/download/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
        responseType: "blob",
      }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data: data,
      };
    } else {
      throw new Error("Error while downloading file");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error while downloading file");
  } finally {
    setLoading((prev) => ({ ...prev, download: false }));
  }
};
// to get shift data
export const getShiftSelectDataApi = async (auth, requestData, setLoading) => {
  try {
    setLoading((prev) => true);
    const res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/workerInfo/shift/get/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      error: error,
      data: null,
    };
  } finally {
    setLoading((prev) => false);
  }
};

// update data
export const updateShiftSelectDataApi = async (auth, requestData) => {
  try {
    const res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/workerInfo/shift/update/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    if (res.status === 200) {
      return {
        success: true,
        message: "Shift Updated Successfully",
      };
    } else {
      return {
        success: false,
        message: "Error while updating shift",
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: "Error while updating shift",
    };
  }
};

// get workers
export const getWorkersInfoApi = async (auth, requestData) => {
  try {
    const res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/workerInfo/name/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      error: error,
      data: null,
    };
  } finally {
  }
};

// to get replacement log data
export const getPalletBoardReplacementLogDataApi = async (
  auth,
  requestData,
  setLoading
) => {
  try {
    setLoading((prev) => true);
    let res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/boardSequence/updateHistory/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    const data = res?.data;
    if (data) {
      const { columns, table_data } = data;
      const tempData = table_data?.map((el) => ({
        ...el,
        formerSecondRowDivider:
          el?.formerSecondRowDivider === "null"
            ? null
            : el.formerSecondRowDivider,
      }));
      return {
        success: true,
        data: { columns, table_data: tempData },
      };
    } else {
      throw new Error("Error while fetching data");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error while fetching data");
  } finally {
    setLoading((prev) => false);
  }
};

// api for graph data
export const getReportsGraphDataApi = async (
  auth,
  requestData,
  setLoading,
  type
) => {
  try {
    setLoading((prev) => true);
    let res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/history/vnotch/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    const data = res?.data;
    if (data) {
      const { grateBarMissing, overallBeltPermeability, vNotch, timeStamp } =
        data;
      let tempTimeStamp = timeStamp?.map((el) => el * 1000);
      if (
        type === "overallBeltPermeability" &&
        overallBeltPermeability &&
        tempTimeStamp
      ) {
        return {
          success: true,
          data: { overallBeltPermeability, timeStamp: tempTimeStamp },
        };
      } else if (
        type === "vNotchGratebar" &&
        grateBarMissing &&
        vNotch &&
        tempTimeStamp
      ) {
        return {
          success: true,
          data: { grateBarMissing, vNotch, timeStamp: tempTimeStamp },
        };
      } else {
        throw new Error("Error while fetching graph data");
      }
    } else {
      throw new Error("Error while fetching graph data");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error while fetching graph data");
  } finally {
    setLoading((prev) => false);
  }
};
