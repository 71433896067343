export const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  // Define the date formatting options
  const options = {
    month: "long", // Display the full month name
    year: "numeric", // Display the full year
  };
  // Format the date using the specified options
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
  // Get the day of the month and determine the appropriate suffix (st, nd, rd, or th)
  const day = new Intl.DateTimeFormat("en-US", { day: "numeric" }).format(date);
  const suffix = getDaySuffix(day);
  // Combine the day, suffix, and formatted date to create the final date string
  return `${day}${suffix} ${formattedDate}`;
};

export const getDaySuffix = (day) => {
  // Determine the day suffix based on common English rules
  if (day >= 11 && day <= 13) {
    return "th";
  }
  const lastDigit = day % 10;
  switch (lastDigit) {
    case 1:
      return "st";
    case 2:
      return "nd";
    case 3:
      return "rd";
    default:
      return "th";
  }
};
