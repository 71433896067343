const urls = [
  "/WorkforceSafetyIcons/tick.svg",
  "/WorkforceSafetyIcons/alert.svg",
];

const BgColorHandler = (data) => {
  return Object.values(data).some((reasons) => reasons === 1);
};

const WeighmentCard = ({ reason, num, truckNo, createdAt }) => {
  console.log("reaosn", createdAt);
  return (
    <div className="flex flex-col gap-1 w-full items-center">
      <div className="flex justify-between w-full">
        <p className="text-[#3E3C42] text-[16px] font-normal self-start">
          {num}
        </p>
      </div>
      <p className="text-[12px] w-full text-[#605D64] font-normal self-start">
        {"In time: "}
        {/* 6:08:50 PM */}
        {createdAt
          ? new Date((createdAt - 5.5 * 60 * 60) * 1000).toLocaleString(
              "en-IN",
              {
                timeZone: "Asia/Kolkata",
              }
            )
          : "..."}
      </p>

      <div className="flex flex-col gap-0 min-w-[240px] w-full h-[120px] shadow-md rounded-lg">
        <div
          className="py-3 pl-2 text-[#525056] text-[16px]  font-semibold w-full rounded-t-lg capitalize flex gap-3 items-center"
          style={{
            backgroundColor: reason && reason != -1 ? "#EC928E" : "#CDEEBF",
          }}
        >
          Truck no. <p className=" uppercase">{truckNo}</p>
        </div>
        <div
          className={`p-3 flex items-center h-full justify-between rounded ${
            reason == 1 ? "border-2 border-[#E46962]" : "border-0"
          }`}
        >
          <div className="flex items-center gap-[10px]">
            <img
              className="h-[40px] w-[40px]"
              src="/WorkforceSafetyIcons/weighment.svg"
            />
            <p className="text-[#79767D] text-base">Clearance</p>
          </div>
          {reason != -1 ? (
            <img src={urls[reason]} />
          ) : (
            <div className="flex">
              <div className="animate-beatloader-item animate-delay-0"></div>
              <div className="animate-beatloader-item animate-delay-1000"></div>
              <div className="animate-beatloader-item animate-delay-2000"></div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WeighmentCard;
