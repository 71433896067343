import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Tooltip } from "@chakra-ui/react";
import mixpanel from "mixpanel-browser";
import { mixpanelTrackClientSelect } from "./mixpanelFunctions";

const Capitalize = (str) => {
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
};

const useCase = {
  Sizing: "Sizing",
  ProcessMonitoring: "Analysis",
  qualityTracking: "Tracking",
  workforceMonitoring: "Monitoring",
  datadigitization: "",
};

const MaterialCard = ({
  material,
  alerts,
  deployments,
  useCase,
  subscribed,
  imageUrl,
}) => {
  let param = useParams();
  let category = param.category;
  //console.log(useCase, "Jlmsfljnkhsbk");
  return (
    <Link
      to={`/vision/${category}/${useCase}`}
      onClick={() => {
        mixpanelTrackClientSelect(null, material);
      }}
      style={{
        textDecoration: "none",
        pointerEvents: !subscribed || deployments <= 0 ? "none" : "",
      }}
    >
      <div
        className={`w-28 h-[115px] relative rounded-xl shadow-md ${
          !subscribed ? "bg-gray-200" : "bg-white"
        } border border-gray-200 hover:bg-blue-100 hover:transition duration-200 cursor-pointer`}
      >
        <div className="absolute top-[-15px] right-[-15px]">
          {alerts !== 0 && (
            <div className="h-8 w-8 rounded-full bg-orange-500 flex justify-center items-center text-lg text-white">
              {alerts}
            </div>
          )}
        </div>
        <div className="w-full flex justify-center ">
          <img
            className="mt-1 h-20 w-20 p-3"
            src={
              imageUrl
                ? imageUrl + `?${performance.now()}`
                : "/ProductDefaultIcon.svg"
            }
          />
        </div>
        <div
          className={`w-full flex justify-center ${
            !subscribed || deployments <= 0
              ? "text-[#3E3C42]"
              : "text-[#024D87]"
          }  text-xs`}
        >
          <div
            className={`${
              !subscribed || deployments <= 0 ? "bg-[#CAC5CD]" : "bg-[#CCEAFF]"
            } px-2 py-1 w-full font-bold text-xs whitespace-nowrap text-center`}
          >
            {!subscribed
              ? "Not Subscribed"
              : deployments + " Deployment" + (deployments > 1 ? "s" : "")}
          </div>
        </div>
      </div>
      <div className="mt-4 flex justify-center h-fit line-clamp-3 w-28 text-center capitalize text-sm">
        <Tooltip label={Capitalize(material)} placement="bottom">
          <p
            className="font-bold text-[#024D87]"
            style={{ pointerEvents: "all" }}
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            {material}
          </p>
        </Tooltip>
      </div>
    </Link>
  );
};

export default MaterialCard;
