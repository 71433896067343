import axios from "axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { baseURL } from "..";

const SearchResults = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [searchTerm, setSearchTerm] = useState(searchParams.get("q") || null);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [searchData, setSearchData] = useState([]);

  //   DEBOUNCE TO LIMIT API CALLS
  const fetchSearchSuggestions = async (term) => {
    try {
      const response = await axios.get(baseURL + `intelli/v2/search/${term}`, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = response?.data?.searchId?.map((result) => result.searchName);
      const searchItems = response?.data?.searchId?.map((result) => result);
      setSearchSuggestions(data);
      setSearchData(searchItems);
      console.log("ff", searchItems);
    } catch (error) {
      console.error("Error fetching search suggestions:", error);
    }
  };

  const handleSuggestionClick = (clickedSuggestion) => {
    // Find the matching route based on the clicked suggestion
    console.log(clickedSuggestion);
    const matchingResult = searchData?.find(
      (suggestion) => suggestion.searchName === clickedSuggestion.searchName
    );

    if (matchingResult) {
      // You can navigate to the corresponding route here

      setSearchData([]);
      setSearchSuggestions([]);
      setSearchTerm((prev) => "");
      const relativePath = new URL(matchingResult.searchRoute).pathname;
      console.log(`Navigate to route: ${matchingResult.searchRoute}`);
      navigate(`${relativePath}`);
    }
  };

  useEffect(() => {
    const pathName = location?.state?.path;
    const delay = 300; // Adjust the delay as needed
    // const timeoutId = setTimeout(() => {
    const term = searchParams.get("q") || null;
    setSearchTerm(term);

    if (term) {
      fetchSearchSuggestions(term);
    } else {
      navigate(`${pathName}`);
      console.log("term empty", pathName);
      setSearchData([]);
    }
    // }, delay);

    // return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount or searchTerm change
  }, [location.search]);

  return (
    <div className="mt-[4vh]">
      {searchData.length > 0 && (
        <div className="search-suggestions bg-[#fafafa]  p-4 overflow-y-auto sm:h-full grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 min-[1440px]:grid-cols-6 min-[1750px]:grid-cols-7 min-[2150px]:grid-cols-12 gap-3 text-base font-medium text-[#3E3C42] rounded-md  mt-2">
          {searchData.map((suggestion, index) => (
            <div
              key={index}
              onClick={() => handleSuggestionClick(suggestion)}
              className="cursor-pointer p-2 hover:bg-gray-100 shadow-md m-auto rounded h-40 whitespace-nowrap"
            >
              <div className="flex flex-col justify-center items-center">
                <div className="w-32 h-32">
                  <img
                    src={suggestion?.searchImage}
                    alt=""
                    className="w-full h-full"
                  />
                </div>

                <p>{suggestion?.searchName}</p>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SearchResults;
