import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import OTPInput from "react-otp-input";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TonalButton from "../../../util/Buttons/TonalButton";
import { Verified } from "@mui/icons-material";

const CrusherTabLockModal = ({
  isOpen,
  handlePageChange,
  setOpenTabLockModal,
}) => {
  const [otp, setOtp] = useState("");
  const { clientId, material, cameraId, plantId } = useParams();
  const navigate = useNavigate();
  const handleClose = () => {
    setOpenTabLockModal((prev) => ({
      ...prev,
      open: false,
    }));
    handlePageChange(0);
    window.location.reload();
  };
  const handleSubmit = () => {
    if (otp === "370371") {
      setOpenTabLockModal((prev) => ({
        ...prev,
        open: false,
        verified: true,
      }));
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
    >
      <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
      <ModalContent mx={{ base: 4, sm: 8 }}>
        <div
          className={`text-white w-full h-12 flex bg-[#2660B6]
           font-semibold justify-center items-center rounded-t-md `}
        >
          Enter code
        </div>
        <ModalCloseButton className="" color={"white"} onClick={handleClose} />
        <ModalBody>
          <div className="pb-4">
            <div className="flex items-center w-full justify-center mt-4">
              <OTPInput
                value={otp}
                onChange={setOtp}
                type="number"
                numInputs={6}
                renderSeparator={<span>{"  "}</span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={{
                  border: "0.5px solid #79767D",
                  padding: "8px",
                  width: "25%",
                  borderRadius: "5px",
                }}
                containerStyle={{
                  width: "220px",
                  gap: "5px",
                  marginBottom: "32px",
                  justifySelf: "center",
                }}
              />
            </div>
            <div className=" flex gap-3 justify-center">
              <PrimaryButton
                onClick={handleSubmit}
                className="bg-[#084298] text-white px-7 py-2 rounded-md mb-5 "
                text={"Submit"}
                width={"fit-content"}
                disable={otp?.length === 6 ? false : true}
              />
              <TonalButton
                onClick={handleClose}
                text={"Cancel"}
                width={"fit-content"}
              />
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CrusherTabLockModal;
