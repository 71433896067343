import axios from "axios";
import { mulpicJsplBaseUrl } from "../Mulpic";

// to get parameter-weights data
export const getWeightsDataApi = async (auth, setLoading) => {
  try {
    setLoading((prev) => ({ ...prev, weights: true }));
    const res = await axios.get(
      `${mulpicJsplBaseUrl}mulpic/weights/get`
      // requestData,
      // {
      //   credentials: "same-origin",
      //   headers: {
      //     "Content-Type": "application/json",
      //     "X-Auth-Token": auth,
      //   },
      // }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data,
      };
    } else {
      throw new Error("Error fetching weights");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error fetching weights");
  } finally {
    setLoading((prev) => ({ ...prev, weights: false }));
  }
};

export const getPlaceholdersApi = async (auth, requestData) => {
  try {
    const res = await axios.post(`${mulpicJsplBaseUrl}mulpic/get_grade_range`,
    requestData, 
    {
      credentials: "same-origin",
      // headers: {
      //   "Content-Type": "application/json",
      //   "X-Auth-Token": auth,
      // },
    });
    if (res?.data) {
      return {
        success: true,
        data: res?.data,
      };
    } else {
      return {
        success: false,
        data: null
      }
    }
  } catch (error) {
    return {
      success: false,
      data: null
    }
  }
};
// to update parameter-weights data
export const updateParameterWeightApi = async (
  auth,
  requestData,
  setLoading
) => {
  try {
    setLoading(true);
    const res = await axios.post(
      `${mulpicJsplBaseUrl}mulpic/weights/add`,
      requestData && requestData,
      {
        credentials: "same-origin",
        // headers: {
        //   "Content-Type": "application/json",
        //   "X-Auth-Token": auth,
        // },
      }
    );
    if (res.status === 200 && res.data) {
      return {
        success: true,
        id: res.data,
        message: "Parameter Added Successfully",
      };
    } else {
      throw new Error("Error while adding parameter");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error while adding parameter");
  } finally {
    setLoading(false);
  }
};
// to delete parameter-weights data
export const deleteParameterWeightApi = async (
  auth,
  requestData,
  setLoading
) => {
  try {
    setLoading(true);
    const res = await axios.delete(
      `${mulpicJsplBaseUrl}mulpic/weights/delete`,
      {
        credentials: "same-origin",
        data: requestData,
        // headers: {
        //   "Content-Type": "application/json",
        //   "X-Auth-Token": auth,
        // },
      }
    );
    if (res.status === 200 && res.data) {
      return {
        success: true,
        message: "Parameter deleted successfully",
      };
    } else {
      throw new Error("Error while deleting parameter");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error while deleting parameter");
  } finally {
    setLoading(false);
  }
};
// to get parameters for weights
export const getParametersForWeightsApi = async (auth) => {
  try {
    const res = await axios.get(
      `${mulpicJsplBaseUrl}mulpic/weights/get_parameters`,
      {
        credentials: "same-origin",
        // headers: {
        //   "Content-Type": "application/json",
        //   "X-Auth-Token": auth,
        // },
      }
    );
    if (res.status === 200 && res.data) {
      const tempData = res.data?.map((el) => el?.split("_").join(" "));
      return {
        success: true,
        data: tempData,
      };
    } else {
      throw new Error("Error while fetching weights paramters");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error while fetching weights paramters");
  }
};
// to reset to default paramter-weight data
export const resetToDefaultParameterWeightApi = async (auth, setLoading) => {
  try {
    setLoading(true);
    const res = await axios.get(
      `${mulpicJsplBaseUrl}mulpic/weights/reset_to_default`,
      {
        credentials: "same-origin",
        // headers: {
        //   "Content-Type": "application/json",
        //   "X-Auth-Token": auth,
        // },
      }
    );
    if (res.status === 200 && res.data) {
      return {
        success: true,
        data: res.data,
      };
    } else {
      throw new Error("Error while resetting");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error while resetting");
  } finally {
    setLoading(false);
  }
};
// to save weights data
export const saveWeightsDataApi = async (auth, setLoading) => {
  try {
    setLoading(true);
    const res = await axios.post(`${mulpicJsplBaseUrl}mulpic/weights/save`, {
      credentials: "same-origin",
      // headers: {
      //   "Content-Type": "application/json",
      //   "X-Auth-Token": auth,
      // },
    });
    if (res.status === 200 && res.data) {
      return {
        success: true,
        data: res.data,
      };
    } else {
      throw new Error("Error while saving");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error while saving");
  } finally {
    setLoading(false);
  }
};

// to post testing data
export const postTestingDataApi = async (auth, requestData, setLoading) => {
  try {
    setLoading((prev) => ({ ...prev, recommended: true }));
    const res = await axios.post(
      `${mulpicJsplBaseUrl}mulpic/test_recommendation`,
      requestData,
      {
        credentials: "same-origin",
        // headers: {
        //   "Content-Type": "application/json",
        //   "X-Auth-Token": auth,
        // },
      }
    );
    if (res.status === 200 && res.data) {
      return {
        success: true,
        data: res.data,
      };
    } else {
      throw new Error("Error while posting test data");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error while posting test data");
  } finally {
    setLoading((prev) => ({ ...prev, recommended: false }));
  }
};
