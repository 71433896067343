import React, { useState, useEffect, useContext } from "react";
import TonalButton from "../../../../util/Buttons/TonalButton";
import PrimaryButton from "../../../../util/Buttons/PrimaryButton";
import DiscardModal from "../../DiscardModal";
import axios from "axios";
import NavContext from "../../../NavContext";
import { baseURL } from "../../../..";
import { postAlertApi } from "../AddNewAlert";
import {
  Input,
  useToast,
  Text,
  Select,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Checkbox,
  Button,
  Stack
} from "@chakra-ui/react";
import UpdateModal from "./UpdateModal";
import { ChevronRightIcon, ChevronLeftIcon, ChevronDownIcon } from "@chakra-ui/icons";

const AlertsDetailsForm = ({
  userForm,
  setUserForm,
  show,
  activeStep,
  page,
  setActiveStep,
  view,
  setDummyStateForm,
}) => {
  const toast = useToast();

  const parametersInitState = {
    key: "",
    value: "",
    unit: "",
    logic: "",
    frequency: "",
    unitTime: "",
    priority: "",
    messageContent: "",
    alertSnapshot: "",
    alertText: "",
    aggLogic: "",
    aggThreshold: "",
    freqUnit: "",
    aggTime: "",
    heartbeat: "",
    activeProcessCnt: "",
    downProcessCnt: "",
    isDeleted: false,
    isSnooze:false,
    snoozeTime:"",
    snoozeUnit:""
  }

  const contactInitState = {
    name: "",
    email: "",
    phoneNo: "",
    isDeleted: false,
  }

  const additionalParameterInitState = {
    key: "",
    value: "",
    isDeleted: false,
  }

  const initAlertState = {
    alertInsName: "",
    clientId: "",
    organisation: "",
    location: "all",
    useCase: "",
    plant: "",
    plantUnit: "",
    alertMappingId: "",
    description: "",
    camera: [],
    product: "All",
    system: "All",
    alertType: "",
    parameters: [],
    contacts: [],
    extraParameters: [],
    defaultContactsFlag: false,
    //defaultContacts: [],
    whatsappFlag: false,
    smsFlag: false,
    emailFlag: false,
    emailTemplate: "",
    whatsappTemplate: "",
    smsTemplate: "",
    alertSource: "",
    destination: "",
    alertSourceUrl: "",
    destinationUrl: "",
    trigger: "",
    triggerValue: "",
    isDeleted: false,
  };

  const { auth } = useContext(NavContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disable, setDisable] = useState(view);
  const [review, setReview] = useState(false);
  const [clients, setClients] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [isEditOpen, setIsEditOpen] = useState(-1);
  const [cameraOptions, setCameraOptions] = useState([]);
  const [systemOptions, setSystemOptions] = useState(['Edge', 'VM', 'Server', 'Network Infra', 'Others']);
  const [productOptions, setProductOptions] = useState([]);
  const [defaultContacts, setDefaultContacts] = useState([]);
  const [disableModal, setDisableModal] = useState(view);
  const [useCases, setUseCases] = useState([]);

  const getClients = async () => {
    try {
      const response = await axios.get(baseURL + "iam/fetchClient", {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      if (response.data?.data?.length > 0) {
        setClients(
          response?.data?.data?.filter((item) => item.status == "ACTIVE")
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDefaultContacts = async () => {
    try {
      const response = await axios.get(baseURL + "iam/users", {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      if (response.data?.length > 0) {
        let x = response?.data?.filter((item) => item.isactive);
        let contacts = x?.map((item) => ({ name: item.fullname, email: item.email, phoneNo: item.phoneNumber }))
        setDefaultContacts(
          contacts
        );
      }
    } catch (error) {
      console.log(error);
    }
  }

  const getCameras = async (clientId, useCase) => {

    const data = {
      clientId: clientId,
    }
    try {
      const response = await axios.post(baseURL + "vision/v2/product/filter/clientId/",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        },
      );

      let cameras = response?.data?.filter((camera) => camera?.useCase == useCase)?.map((camera) => camera?.cameraId);
      setCameraOptions(cameras);
    } catch (error) {
      console.log(error);
    }
  }

  const getUsecases = async (clientId) => {

    const data = {
      clientId: clientId,
    }
    try {
      const response = await axios.post(baseURL + "vision/v2/product/filter/clientId/",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        },
      );

      let uc = response?.data?.map((camera) => camera?.useCase);
      let updatedUc = uc?.filter((value, index) => uc?.indexOf(value) === index);
      setUseCases(updatedUc);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getClients();
  }, []);

  console.log("Hello")

  useEffect(() => {
    let copyAlerts = [];
    userForm[page]?.forEach((x) => {
      copyAlerts.push(structuredClone(x));
    });
    setAlerts(copyAlerts);
  }, [userForm]);

  //console.log(alerts)

  const checkOk = () => {
    for (let x of alerts) {
      if (x?.isDeleted == true) continue;
      if (
        x?.alertInsName?.length > 100 ||
        !x?.alertInsName ||
        !x?.clientId ||
        !x.location ||
        x?.plant?.length > 100 ||
        x?.plantUnit?.length > 100 ||
        x?.description?.length > 1000 ||
        x?.emailTemplate?.length > 1000 ||
        x?.whatsappTemplate?.length > 1000 ||
        x?.smsTemplate?.length > 1000 ||
        x?.destination?.length > 100 ||
        x?.triggerValue?.length > 1000 ||
        x?.destinationUrl?.length > 100 ||
        x?.alertSourceUrl?.length > 100 ||
        !x?.useCase
      ) {
        toast({
          title: "Please enter all the required details",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
        return false;
      }

      if (x?.parameters?.length > 0) {
        for (let y of x?.parameters) {
          if (y?.isDeleted == true) continue;
          if (
            y?.key?.length > 100 ||
            ((userForm?.alertType != "Usecase" && userForm?.alertType != "Other") && !y?.key) ||
            y?.value?.length > 100 ||
            // ((userForm?.alertType != "Usecase" && userForm?.alertType != "Other" && userForm?.alertType != "camera") && !y?.value) ||
            y?.logic?.length > 100 ||
            ((userForm?.alertType != "Usecase" && userForm?.alertType != "Other") && !y?.logic) ||
            y?.frequency?.length > 100 ||
            y?.alertText?.length > 250 ||
            y?.alertSnapshot?.length > 250 ||
            y?.aggTime?.length > 100 ||
            y?.aggThreshold?.length > 100 ||
            y?.unit?.length > 100 ||
            y?.heartbeat?.length > 100 ||
            y?.downProcessCnt?.length > 100 ||
            y?.activeProcessCnt?.length > 100
          ) {
            toast({
              title: "Please enter all the required details",
              status: "error",
              duration: 4000,
              isClosable: true,
              position: "top",
            });
            return false;
          }
        }
      }

      if (x?.extraParameters?.length > 0) {
        for (let y of x?.extraParameters) {
          if (y?.isDeleted == true) continue;
          if (
            y?.key?.length > 100 ||
            y?.value?.length > 100
          ) {
            toast({
              title: "Please enter all the required details",
              status: "error",
              duration: 4000,
              isClosable: true,
              position: "top",
            });
            return false;
          }
        }
      }

      let regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (x?.contacts?.length > 0) {
        for (let y of x?.contacts) {
          if (y?.isDeleted == true) continue;
          else {
            if (y?.name?.length > 100 || y?.email.length > 100 || y?.phoneNo?.length > 100) {
              toast({
                title: "Please enter valid contact details",
                status: "error",
                duration: 4000,
                isClosable: true,
                position: "top",
              });
              return false;
            }
            if (regex.test(y?.email)) continue;
            else {
              toast({
                title: "Please enter valid email id",
                status: "error",
                duration: 4000,
                isClosable: true,
                position: "top",
              });
              return false;
            }
          }
        }
      }
    }

    return true;
  };

  const handleSubmit = (status) => {
    if (checkOk()) {
      if (status === true) {
        postAlertApi(
          toast,
          auth,
          userForm,
          activeStep,
          0,
          setUserForm,
          setDummyStateForm
        );
        return;
      }
      setDisable(true);
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmDiscard = () => {
    setIsModalOpen(false);
  };

  const handleChange = async (name, value, index, idx = -1, groupKey = "parameters") => {

    //console.log(name, value, index);

    // if (name == "organisation") {
    //   await getCameras(value);
    // }

    if (idx == -1) {
      setUserForm((prev) => {
        let newData = structuredClone(prev);
        //console.log(newData)
        newData[page][index][name] = value;
        // if (name == "defaultContactsFlag") {
        //   if (value == true) newData[page][index]["defaultContacts"] = defaultContacts;
        //   else newData[page][index]["defaultContacts"] = [];
        // }
        return newData;
      });
    }
    else {
      setUserForm((prev) => {
        let newData = structuredClone(prev);
        newData[page][index][groupKey][idx][name] = value;
        return newData;
      });
    }


  };

  const handleMenuChange = async (name, value, index) => {

    setUserForm((prev) => {
      let newData = structuredClone(prev);

      console.log(newData);
      //console.log(newData)
      console.log(newData[page][index][name])

      if(value == "All"){
        if(cameraOptions?.length == newData[page][index][name]?.length){
          newData[page][index][name]=[];
        }
        else{
          newData[page][index][name]=[];
          cameraOptions?.forEach((option)=>{newData[page][index][name].push(option);})
        }
      }
      else if (newData[page][index][name]?.includes(value)) {
        if (value == "All") newData[page][index][name] = [];
        else {
          let idx = newData[page][index][name]?.indexOf(value)
          newData[page][index][name]?.splice(idx, 1);
        }
      }
      else {
        if (value == "All") newData[page][index][name] = ["All"];
        else {
          newData[page][index][name].push(value);
        }
      }
      return newData;
    });

  };

  const handleDelete = (index) => {
    let updatedAlert = [];
    for (let x of alerts) {
      updatedAlert.push(structuredClone(x));
    }
    updatedAlert[index].isDeleted = true;
    setUserForm((prev) => ({
      ...prev,
      [page]: updatedAlert,
    }));
  };

  const handleDeleteParameter = (index, idx) => {
    let updatedAlert = [];
    for (let x of alerts) {
      updatedAlert.push(structuredClone(x));
    }

    let newParameters = [];

    for (let x in updatedAlert[index]["parameters"]) {
      newParameters.push(updatedAlert[index]["parameters"][x])
    }

    newParameters[idx]["isDeleted"] = true;
    updatedAlert[index]["parameters"] = newParameters;
    setUserForm((prev) => ({
      ...prev,
      [page]: updatedAlert,
    }));
  }

  const handleAddAlert = () => {
    for (let x of alerts) {
      if (x?.isDeleted) continue;
      if (!x?.alertInsName || !x?.location || !x?.clientId || !x?.useCase) {
        toast({
          title: "Please enter all the required fields in added alert instances.",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
        return;
      }
    }
    let updatedAlert = [];
    for (let x of alerts) {
      updatedAlert.push(structuredClone(x));
    }
    let newState = structuredClone(initAlertState);
    updatedAlert.push(newState);
    setAlerts(updatedAlert);
    setUserForm((prev) => ({
      ...prev,
      [page]: updatedAlert,
    }));
  };

  const handleAddParameter = (index) => {

    let updatedAlert = [];
    for (let x of alerts) {
      updatedAlert.push(structuredClone(x));
    }
    let newParameterState = structuredClone(parametersInitState);
    updatedAlert[index]?.parameters?.push(newParameterState);

    setAlerts(updatedAlert);
    setUserForm((prev) => ({
      ...prev,
      [page]: updatedAlert,
    }))
  }

  const handleDeleteContact = (index, idx) => {
    let updatedAlert = [];
    for (let x of alerts) {
      updatedAlert.push(structuredClone(x));
    }

    let newContacts = [];

    for (let x in updatedAlert[index]["contacts"]) {
      newContacts.push(updatedAlert[index]["contacts"][x])
    }

    newContacts[idx]["isDeleted"] = true;
    updatedAlert[index]["contacts"] = newContacts;
    setUserForm((prev) => ({
      ...prev,
      [page]: updatedAlert,
    }));
  }

  const handleAddContact = (index) => {

    let updatedAlert = [];
    for (let x of alerts) {
      updatedAlert.push(structuredClone(x));
    }
    let newContactState = structuredClone(contactInitState);
    updatedAlert[index]?.contacts?.push(newContactState);

    setAlerts(updatedAlert);
    setUserForm((prev) => ({
      ...prev,
      [page]: updatedAlert,
    }))
  }

  const handleDeleteAddParameter = (index, idx) => {
    let updatedAlert = [];
    for (let x of alerts) {
      updatedAlert.push(structuredClone(x));
    }

    let newParameters = [];

    for (let x in updatedAlert?.[index]?.["extraParameters"]) {
      newParameters.push(updatedAlert[index]["extraParameters"][x])
    }

    newParameters[idx]["isDeleted"] = true;
    updatedAlert[index]["extraParameters"] = newParameters;
    setUserForm((prev) => ({
      ...prev,
      [page]: updatedAlert,
    }));
  }

  const handleAddAddParameter = (index) => {

    let updatedAlert = [];
    for (let x of alerts) {
      updatedAlert.push(structuredClone(x));
    }
    let newParameterState = structuredClone(additionalParameterInitState);
    updatedAlert[index]?.extraParameters?.push(newParameterState);

    setAlerts(updatedAlert);
    setUserForm((prev) => ({
      ...prev,
      [page]: updatedAlert,
    }))
  }

  const handleCloseEditModal = () => {
    setIsEditOpen(-1);
  };

  console.log(userForm);

  return (
    <div
      className="font-roboto flex flex-col gap-2 p-4 pt-2 m-2 bg-white rounded-lg border transition-all ease-in duration-500"
      id="step1"
      style={{ opacity: show ? 1 : 0 }}
    >
      <p className="text-[#3E3C42] text-xl font-medium">
        Alert details
      </p>

      {alerts?.map((alert, index) => {
        if (alert?.isDeleted == true) return;
        return (
          <div className={`flex flex-row gap-2 border p-2 rounded-md mt-6`}>

            <div className="grid grid-cols-1 sm:grid-cols-2 min-[940px]:grid-cols-3 min-[1120px]:grid-cols-4 xl:grid-cols-5 gap-2 w-[90%]">
              <div className="flex flex-col gap-3">
                <p className="text-[#3E3C42] text-lg font-medium">
                  Alert Instance name<span className="text-red-500">*</span>
                </p>
                {view === true ? (
                  <p className="text-[#525056] text-sm font-medium">
                    {alert?.alertInsName}
                  </p>
                ) : (
                  <div className="w-full">
                    <Input
                      type="text"
                      value={alert?.alertInsName}
                      onChange={(e) => {
                        let val = e.target.value.replace(/\s/g, '');
                        console.log(val);
                        handleChange("alertInsName", val, index)
                      }}
                      borderColor={"gray.300"}
                      borderWidth={"2px"}
                      isDisabled={disable}
                    />
                    {alert?.alertInsName?.length > 100 && (
                      <div>
                        <Text color="red.500" fontSize="sm" mt="1">
                          You have exceed the character limit of 100 by{" "}
                          {alert?.alertInsName?.length - 100}
                        </Text>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-3">
                <p className="text-[#3E3C42] text-lg font-medium">
                  Client<span className="text-red-500">*</span>
                </p>
                {view === true ? (
                  <p className="text-[#525056] text-sm font-medium">
                    {alert?.organisation}
                  </p>
                ) : (
                  <div className="w-full">
                    <Select
                      value={alert?.clientId}
                      onChange={(e) => {
                        handleChange(
                          "organisation",
                          clients?.filter(
                            (item) => item.clientId == e.target.value
                          )[0]?.organisation,
                          index
                        );
                        handleChange("clientId", e.target.value, index);
                      }}
                      borderColor={"gray.300"}
                      borderWidth={'2px'}
                      className="w-[100%] border-[2px] rounded-lg max-h-[300px] overflow-y-auto"
                      disabled={disable}
                    >
                      <option value="" disabled selected>
                        Select one
                      </option>
                      {clients?.map((item, index) => (
                        <option value={item?.clientId}>{item?.clientName}</option>
                      ))}
                    </Select>
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-3">
                <p className="text-[#3E3C42] text-lg font-medium">
                  Location<span className="text-red-500">*</span>
                </p>
                {view === true ? (
                  <p className="text-[#525056] text-sm font-medium">
                    {alert?.location}
                  </p>
                ) : (
                  <div className="w-full">
                    <Select
                      value={alert?.location}
                      onChange={(e) =>
                        handleChange("location", e.target.value, index)
                      }
                      borderColor={"gray.300"}
                      borderWidth={'2px'}
                      className="w-[100%] border-[2px] rounded-lg max-h-[300px] overflow-y-auto"
                      disabled={disable}
                    >
                      <option value="all" selected>
                        All
                      </option>
                      {clients
                        ?.filter((item) => item.clientId == alert?.clientId)[0]
                        ?.clientAllLocation?.split(",")
                        .map((item, index) => (
                          <option value={item}>{item}</option>
                        ))}
                    </Select>
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-3">
                <p className="text-[#3E3C42] text-lg font-medium">
                  Usecase<span className="text-red-500">*</span>
                </p>
                {view === true ? (
                  <p className="text-[#525056] text-sm font-medium">
                    {alert?.useCase}
                  </p>
                ) : (
                  <div className="w-full">
                    <Select
                      // defaultValue={alert?.useCase}
                      value={alert?.useCase}
                      // placeholder={alert?.useCase ? alert?.useCase : "Select one"}
                      onChange={(e) =>
                        handleChange("useCase", e.target.value, index)
                      }
                      onClick={async () => await getUsecases(alert?.organisation)}
                      borderColor={"gray.300"}
                      borderWidth={'2px'}
                      className="w-[100%] border-[2px] rounded-lg max-h-[300px] overflow-y-auto"
                      disabled={disable}
                    >
                      {!alert?.useCase && <option value="" disabled>
                        Select one
                      </option>}
                      {
                        alert?.useCase && useCases?.length === 0 && <option value={alert?.useCase}>{alert?.useCase}</option>
                      }
                      {
                        useCases?.map((item) => {
                          return <option value={item}>{item}</option>
                        })
                      }
                    </Select>
                  </div>
                )}
              </div>
              <div className=" flex flex-col gap-3">
                <p className="text-[#3E3C42] capitalize text-lg font-medium">
                  {"Camera"}
                </p>
                {view === true ? (
                  <p className="text-[#525056] text-sm font-medium">
                    {alert?.camera}
                    {/* {alert?.alertType === "System" && alert?.system}
                  {alert?.alertType === "Product" && alert?.product} */}
                  </p>
                ) : (
                  <div className="w-full">

                    <Menu onOpen={async () => await getCameras(alert?.organisation, alert?.useCase)}>
                      <MenuButton as={Button} rightIcon={<ChevronDownIcon />} variant='outline' width={'full'} fontWeight={'normal'} textAlign={'left'} borderColor={'gray.300'} borderWidth={'2px'}>
                        <p className="truncate w-full">{alert?.camera?.length > 0 ? alert?.camera?.join(",") :  "Select Cameras"}</p>
                      </MenuButton>
                      <MenuList height={'200px'} overflowY={'scroll'}>
                        <Stack >
                          <Checkbox
                            colorScheme="blue"
                            p={"8px"}
                            onChange={(e) => handleMenuChange("camera", "All", index)}
                            isChecked={alert?.camera?.length == cameraOptions?.length}
                            // checked={alert?.camera?.includes("All")}
                          >
                            {"All"}
                          </Checkbox>
                          {
                            cameraOptions?.map((item, idx) => (
                              <Checkbox
                                colorScheme="blue"
                                p={"8px"}
                                isDisabled={alert?.camera?.length == cameraOptions?.length}
                                onChange={() => handleMenuChange("camera", item, index)}
                                isChecked={alert?.camera?.includes(item) && (alert?.camera?.length != cameraOptions?.length)}
                              >
                                {item}
                              </Checkbox>
                            ))
                          }
                          {/* {
                          alert?.alertType === "System" && systemOptions?.map((item, index) => (
                            <Checkbox
                              colorScheme="blue"
                              p={"8px"}
                              onChange={() => handleMenuChange(alert?.alertType?.toLowerCase(), item, index)}
                            >
                              {item}
                            </Checkbox>
                          ))
                        }
                        {
                          alert?.alertType === "Product" && productOptions?.map((item, index) => (
                            <Checkbox
                              colorScheme="blue"
                              p={"8px"}
                              onChange={() => handleMenuChange(alert?.alertType?.toLowerCase(), item, index)}
                            >
                              {item}
                            </Checkbox>
                          ))
                        } */}

                        </Stack>

                      </MenuList>
                    </Menu>
                  </div>
                )}
              </div>
              {userForm?.alertType == 'system' && <div className=" flex flex-col gap-3">
                <p className="text-[#3E3C42] capitalize text-lg font-medium">
                  {userForm?.alertType}
                </p>
                {view === true ? (
                  <p className="text-[#525056] text-sm font-medium">
                    {/* {userForm?.alertType === "camera" && alert?.camera} */}
                    {userForm?.alertType === "system" && alert?.system}
                    {/* {userForm?.alertType === "Product" && alert?.product} */}
                  </p>
                ) : (
                  <div className="w-full">
                    <Select
                      value={alert?.system}
                      onChange={(e) =>
                        handleChange(userForm?.alertType?.toLowerCase(), e.target.value, index)
                      }
                      borderColor={"gray.300"}
                      borderWidth={'2px'}
                      className="w-[100%] border-[2px] rounded-lg max-h-[300px] overflow-y-auto"
                      disabled={disable}
                    >
                      <option value="All" selected>
                        All
                      </option>

                      {/* {
                      alert?.alertType === "Camera" && cameraOptions?.map((item, index) => (
                        <option value={item}>{item}</option>
                      ))
                    } */}

                      {
                        userForm?.alertType === "system" && systemOptions?.map((item, index) => (
                          <option value={item}>{item}</option>
                        ))
                      }

                      {/* {
                      alert?.alertType === "Product" && productOptions?.map((item, index) => (
                        <option value={item}>{item}</option>
                      ))
                    } */}

                    </Select>
                  </div>
                )}
              </div>}
              <div className="flex flex-col gap-3">
                <p className="text-[#3E3C42] text-lg font-medium">Plant</p>
                {view === true ? (
                  <p className="text-[#525056] text-sm font-medium">
                    {alert?.plant}
                  </p>
                ) : (
                  <div className="w-full">
                    <Input
                      type="text"
                      defaultValue={alert?.plant}
                      onChange={(e) =>
                        handleChange("plant", e.target.value, index)
                      }
                      borderColor={"gray.300"}
                      borderWidth={"2px"}
                      isDisabled={disable}
                    />
                    {alert?.plant?.length > 100 && (
                      <div>
                        <Text color="red.500" fontSize="sm" mt="1">
                          You have exceed the character limit of 100 by{" "}
                          {alert?.plant?.length - 100}
                        </Text>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-3">
                <p className="text-[#3E3C42] text-lg font-medium">Plant Unit</p>
                {view === true ? (
                  <p className="text-[#525056] text-sm font-medium">
                    {alert?.plantUnit}
                  </p>
                ) : (
                  <div className="w-full">
                    <Input
                      type="text"
                      defaultValue={alert?.plantUnit}
                      onChange={(e) =>
                        handleChange("plantUnit", e.target.value, index)
                      }
                      borderColor={"gray.300"}
                      borderWidth={"2px"}
                      isDisabled={disable}
                    />
                    {alert?.plantUnit?.length > 100 && (
                      <div>
                        <Text color="red.500" fontSize="sm" mt="1">
                          You have exceed the character limit of 100 by{" "}
                          {alert?.plantUnit?.length - 100}
                        </Text>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className=" items-start flex flex-row gap-4">
              <button onClick={() => handleDelete(index)} disabled={disable}>
                {disable ? (
                  <img
                    src="/disabledDelete.svg"
                    className="w-[30px] h-[30px]"
                  />
                ) : (
                  <img src="/deleteIcon.svg" className="w-[30px] h-[30px]" />
                )}
              </button>

              <button
                onClick={async () => {
                  if (alert?.parameters?.length === 0) {
                    handleAddParameter(index);
                  }
                  await getDefaultContacts(alert?.organisation);
                  setDisableModal(false);
                  setIsEditOpen(index)

                }}
                disabled={view === true ? false : disable}
              >
                {disable && view === false ? (
                  <img
                    src="/disabledUpdate.svg"
                    className="w-[30px] h-[30px]"
                  />
                ) : (
                  <img src="/update.svg" className="w-[30px] h-[30px]" />
                )}
              </button>

              <button
                onClick={async () => {
                  // if (alert?.parameters?.length === 0) {
                  //   handleAddParameter(index);
                  // }
                  // await getDefaultContacts(alert?.organisation);
                  setDisableModal(true);
                  setIsEditOpen(index)

                }}
                disabled={false}
              >

                <img src="/viewIcon.svg" className="w-[25px] h-[25px]" />
              </button>


            </div>
          </div>
        )
      }

      )}

      {isEditOpen >= 0 && (
        <UpdateModal
          isOpen={isEditOpen}
          handleCloseEditModal={handleCloseEditModal}
          view={disableModal}
          alertId={userForm?.alertId}
          index={isEditOpen}
          alert={alerts[isEditOpen]}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          handleAddParameter={handleAddParameter}
          cameraOptions={cameraOptions}
          systemOptions={systemOptions}
          productOptions={productOptions}
          handleDeleteParameter={handleDeleteParameter}
          handleMenuChange={handleMenuChange}
          handleDeleteContact={handleDeleteContact}
          handleAddContact={handleAddContact}
          alertType={userForm?.alertType}
          defaultContacts={defaultContacts}
          handleDeleteAddParameter={handleDeleteAddParameter}
          handleAddAddParameter={handleAddAddParameter}
        />
      )}

      {view === true && <hr className="mt-3"></hr>}

      {view === false && <div className="mt-6">
        <TonalButton
          text={"Add new"}
          disable={disable}
          width={"130px"}
          onClick={handleAddAlert}
        />
      </div>
      }

      {view === false && (
        <div className="flex gap-[10px] items-center ml-0 mt-6 mb-20 md:mb-0">
          {!review ? (
            <>
              <TonalButton
                text={"Save as draft"}
                width={"130px"}
                //disable={disable}
                onClick={() => {
                  handleSubmit(true);
                }}
              />
              <PrimaryButton
                text={"Review"}
                width={"130px"}
                onClick={() => {
                  setReview(true);
                  setDisable(true);
                  checkOk();
                }}
              //disable={disable}
              />
            </>
          ) : (
            <>
              <TonalButton
                text={"Back to editing"}
                width={"130px"}
                onClick={() => {
                  setReview(false);
                  setDisable(false);
                }}
              //disable={activeStep > 2 || view}
              />
              <PrimaryButton
                text={"Submit"}
                width={"130px"}
                onClick={() => handleSubmit(false)}
                //disable={activeStep > 2 || view}
                color="green"
              />
            </>
          )}
        </div>
      )}

      <DiscardModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        handleConfirmDiscard={handleConfirmDiscard}
      />
    </div>
  );
};

export default AlertsDetailsForm;
