import { Clear, Lens, NotificationsNone } from "@mui/icons-material";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Tooltip,
} from "@chakra-ui/react";
import TonalButton from "../../../util/Buttons/TonalButton";

export const AlertsCountChart = ({ data, filterData, setFilterData }) => {
  const colors = ["#FEDAA9", "#B7DFFB", "#6AEFBC"];
  const title = ["High", "Medium", "Low"];
  const handleScrollToTable = () => {
    const table = document.getElementById("tableContainer");
    if (table) {
      table.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  };
  const handleClick = (event) => {
    const cardIndex = event.target
      .closest("[data-card-index]")
      ?.getAttribute("data-card-index");
    if (cardIndex !== null) {
      const clickedCardData = data[parseInt(cardIndex)];
      if (clickedCardData) {
        setFilterData((prev) => {
          const existingIndex = prev?.items?.findIndex(
            (item) => item?.value === clickedCardData.board
          );

          if (existingIndex !== -1) {
            return { items: [] };
          } else {
            return {
              items: [
                {
                  field: "pallet_board",
                  operator: "contains",
                  value: clickedCardData.board,
                },
              ],
            };
          }
        });
        handleScrollToTable();
      }
    }
  };
  const getColor = (val) => {
    if (val >= 10) {
      return colors[0];
    } else if (val > 5 && val < 10) {
      return colors[1];
    } else if (val <= 5) {
      return colors[2];
    }
  };

  const splitBoardName = (boardName) => {
    const lastHyphenIndex = boardName.lastIndexOf("-");
    const firstPart = boardName.substring(0, lastHyphenIndex);
    const secondPart = boardName.substring(lastHyphenIndex + 1);
    return [firstPart, secondPart];
  };

  const handleClearFilter = (e) => {
    e.stopPropagation();
    setFilterData((prev) => ({ items: [] }));
  };

  return (
    <Accordion
      defaultIndex={[0]}
      allowToggle={true}
      backgroundColor={"white"}
    >
      <AccordionItem border={"none"}>
        <AccordionButton
          borderTopRadius={"xl"}
          _hover={{
            backgroundColor: "white",
          }}
          paddingY={0}
          paddingX={0}
        >
          <div className="flex flex-row justify-between w-full items-center px-2 py-[2px] rounded-md shadow-sm">
            <p className="text-[16px] font-bold">Board wise alerts count</p>
            <div className="flex flex-row gap-2 items-center">
              <AccordionIcon
                rounded={"3xl"}
                boxSize={"6"}
                boxShadow={"md"}
              />
            </div>
          </div>
        </AccordionButton>
        <AccordionPanel p={0}>
          <div className="flex flex-col gap-0">
            <div className="flex flex-row justify-between items-center h-[35px] px-2 overflow-auto">
              <div
                className={`flex flex-row items-center gap-2   md:gap-4 w-full`}
              >
                {title?.map((element, index) => (
                  <div
                    key={`${element}-${index}`}
                    className="flex flex-row gap-1 items-center"
                  >
                    <Lens
                      fontSize={"inherit"}
                      sx={{
                        color: colors[index],
                      }}
                    />
                    <p className="text-[12px]">{element}</p>
                  </div>
                ))}
              </div>
              <div className="min-w-fit">
                {filterData?.items?.length > 0 && (
                  <TonalButton
                    text={"Clear Filter"}
                    width={"100%"}
                    height={"30px"}
                    Icon={<Clear fontSize={"small"} />}
                    onClick={handleClearFilter}
                  />
                )}
              </div>
            </div>

            <div
              className="w-full px-2 overflow-x-scroll xl:overflow-x-hidden py-1"
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(22,1fr)",
                gap: 6,
              }}
              onClick={handleClick}
            >
              {data?.map((element, index) => {
                return (
                  <Tooltip
                    label={"Click to filter"}
                    fontSize={"10px"}
                    hasArrow={true}
                    key={`${element}-${index}-tooltip`}
                  >
                    <div
                      key={`${element}-${index}`}
                      className="card flex flex-col items-center text-[12px] px-[0px] text-[#3E3C42] rounded-md py-[3px] hover:cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110 min-w-[50px]"
                      style={{
                        backgroundColor: getColor(element?.alertsCount),
                        outline:
                          element.board === filterData?.items[0]?.value
                            ? "2px dashed black"
                            : "none",
                      }}
                      data-card-index={index}
                    >
                      {element?.board &&
                        splitBoardName(element?.board)?.map((item, idx) => (
                          <p
                            key={`${item}-${idx}-board`}
                            className="font-semibold leading-tight"
                          >
                            {item}
                          </p>
                        ))}
                      {/* <p className="font-bold">{element?.board && }</p> */}
                      <p className="flex items-center">
                        <NotificationsNone sx={{ fontSize: "14px" }} />{" "}
                        {element.alertsCount}
                      </p>
                    </div>
                  </Tooltip>
                );
              })}
            </div>
          </div>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
