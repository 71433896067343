const theme = {
  blue: {
    text: "text-[#3A74CA]",
    bg: "bg-[#DEF]",
    hover: "hover:bg-[#CFE7FF]",
    active: "active:bg-[#CFE7FF]",
    focus: "focus:bg-[#CFE7FF]",
  },
  green: {
    text: "text-[#2E7D32]",
    bg: "bg-[#A5D6A7]",
    hover: "hover:bg-[#C8E6C9]",
    active: "active:bg-[#C8E6C9]",
    focus: "focus:bg-[#C8E6C9]",
  },
};

const TonalButton = ({
  disable = false,
  onClick = null,
  text,
  width = null,
  height = null,
  Icon = null,
  color = "blue",
}) => {
  return (
    <button
      className={
        disable
          ? "flex items-center justify-center gap-1 text-[#AEA9B1] text-sm rounded bg-[#EBEBEB]"
          : `flex items-center justify-center gap-1 ${theme[color].text} text-sm rounded ${theme[color].bg} ${theme[color].hover} hover:shadow-[0px_2px_6px_2px_rgba(0,0,0,0.10),0px_1px_2px_0px_rgba(0,0,0,0.10)] ${theme[color].focus} focus:shadow-[0px_2px_6px_2px_rgba(0,0,0,0.10),0px_1px_2px_0px_rgba(0,0,0,0.10)] focus:outline-none ${theme[color].active}`
      }
      style={{
        width: width ? width : "100%",
        height: height ? height : "auto",
        padding: Icon ? "6px 16px 6px 8px" : "8px 16px 8px 16px",
      }}
      onClick={onClick}
      disabled={disable}
    >
      {Icon}
      {text}
    </button>
  );
};

export default TonalButton;
