import { useToast, Input } from "@chakra-ui/react";
import { useRef, useState } from "react";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";

const CamName = ({
  userData,
  setUserData,
  activeStep,
  setActiveStep,
  viewMode = false,
}) => {
  const [disable, setDisable] = useState(viewMode);
  const nameRef = useRef();
  const toast = useToast();
  let regex = /^[a-zA-Z0-9\-_\s]+$/;

  const handleSave = () => {
    if (
      nameRef.current.value == "" ||
      nameRef.current.value.length > 100 ||
      !regex.test(nameRef.current.value)
    ) {
      toast({
        title: "Error",
        description: (
          <div>
            {nameRef.current.value == "" && (
              <p>Please ensure camera name is not empty</p>
            )}
            {nameRef.current.value.length > 100 && (
              <p>The name cannot be greater then 100 characters</p>
            )}
            {!regex.test(nameRef.current.value) && (
              <p>The name can only contain alphabets, numbers, "_" or "-" </p>
            )}
          </div>
        ),
        status: "error",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    setUserData((prev) => ({
      ...prev,
      name: nameRef?.current?.value,
    }));
    setDisable(true);
    if (activeStep < 1) setActiveStep((prev) => prev + 1);
  };

  return (
    <div className="bg-white rounded-lg flex flex-col gap-8 p-6" id="step0">
      <div className="flex flex-col gap-3">
        <p className="text-sm text-[#2660B6] font-medium">
          Add camera name<span className="text-[#DC362E]">*</span>
        </p>
        <Input
          width={"fit-content"}
          name="name"
          defaultValue={userData.name}
          ref={nameRef}
          isDisabled={disable}
          placeholder="Max char 100"
        />
      </div>
      <div className="flex gap-2 items-center">
        <PrimaryButton
          text={"Save"}
          width={"fit-content"}
          onClick={handleSave}
          disable={disable}
        />
      </div>
    </div>
  );
};

export default CamName;
