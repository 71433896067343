import React, { useState, useEffect, useContext } from "react";
import {
  Input,
  useToast,
  Text,
} from "@chakra-ui/react";
import TonalButton from "../../../../util/Buttons/TonalButton";
import PrimaryButton from "../../../../util/Buttons/PrimaryButton";
import DiscardModal from "../../DiscardModal";
import axios from "axios";
import NavContext from "../../../NavContext";
import { baseURL } from "../../../..";
import { postDeviceApi } from "../AddNewDevice";
import UpdateModal from "./UpdateModal";


const ClientsDetailsForm = ({
  userForm,
  setUserForm,
  show,
  activeStep,
  page,
  setActiveStep,
  view,
  setDummyStateForm,
}) => {
  const toast = useToast();

  const initDeviceState = {
    relativeDeviceName: "",
    clientId: "",
    organisation: "",
    location: "",
    plant: "",
    plantUnit: "",
    relativeDeviceId: "",
    deviceInstanceId: "",
    devicePhase: "",
    serialNumber: "",
    addReferanceNotes: "",
    instanceNotes: "",
    installationNotes: "",
    feedStatus: false,
    feedType: "",
    connectionType: "",
    dataFeed: "",
    port: "",
    userName: "",
    password: "",
    isDeleted: false,
  };

  const { auth } = useContext(NavContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [disable, setDisable] = useState(view);
  const [review, setReview] = useState(false);
  const [clients, setClients] = useState([]);
  const [devices, setDevices] = useState([]);
  const [isEditOpen, setIsEditOpen] = useState(-1);

  const getClients = async () => {
    try {
      const response = await axios.get(baseURL + "iam/fetchClient", {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      if (response.data?.data?.length > 0) {
        setClients(
          response?.data?.data?.filter((item) => item.status == "ACTIVE")
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    let copydevices = [];
    userForm[page]?.forEach((x) => {
      if (x?.isDeleted === false) copydevices.push({ ...x });
    });
    setDevices(copydevices);
  }, [userForm]);

  const checkOk = () => {
    for (let x of devices) {
      if (
        x?.relativeDeviceName?.length > 100 ||
        !x?.relativeDeviceName ||
        !x?.clientId ||
        !x.location ||
        x?.plant?.length > 100 ||
        x?.plantUnit?.length > 100 ||
        x?.serialNumber?.length > 100 ||
        x?.addReferanceNotes?.length > 100 ||
        x?.instanceNotes?.length > 1000 ||
        x?.installationNotes?.length > 1000 ||
        x?.dataFeed?.length > 100 ||
        x?.port?.length > 100 ||
        x?.userName?.length > 100 ||
        x?.password?.length > 100
      ) {
        toast({
          title: "Please enter all the required details",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
        return false;
      }
    }

    return true;
  };

  const handleSubmit = (status) => {
    if (checkOk()) {
      if (status === true) {
        postDeviceApi(
          toast,
          auth,
          userForm,
          activeStep,
          0,
          setUserForm,
          setDummyStateForm
        );
        return;
      }
      setDisable(true);
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmDiscard = () => {
    setIsModalOpen(false);
  };

  const handleChange = (name, value, index) => {
    setUserForm((prev) => {
      let newData = { ...prev };
      newData[page][index][name] = value;
      return newData;
    });
  };

  const handleDelete = (index) => {
    let updatedDevice = [];
    for (let x of devices) {
      updatedDevice.push({ ...x });
    }
    updatedDevice[index].isDeleted = true;
    setUserForm((prev) => ({
      ...prev,
      [page]: updatedDevice,
    }));
  };

  const handleAddDevice = () => {
    for (let x of devices) {
      if (!x?.relativeDeviceName || !x?.location || !x?.clientId) {
        toast({
          title: "Please enter all the required fields in above devices",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
        return;
      }
    }
    let updatedDevice = [];
    for (let x of devices) {
      updatedDevice.push({ ...x });
    }
    let newState = { ...initDeviceState };
    updatedDevice.push(newState);
    setDevices(updatedDevice);
    setUserForm((prev) => ({
      ...prev,
      [page]: updatedDevice,
    }));
  };

  const handleCloseEditModal = () => {
    setIsEditOpen(-1);
  };

  return (
    <div
      className="font-roboto flex flex-col gap-2 p-4 pt-2 m-2 bg-white rounded-lg border transition-all ease-in duration-500"
      id="step1"
      style={{ opacity: show ? 1 : 0 }}
    >
      <p className="text-[#3E3C42] text-xl font-medium">
        Client and plant/location details
      </p>

      {devices
        ?.filter((device) => device?.isDeleted === false)
        ?.map((device, index) => (
          <div className="flex flex-col sm:flex-row gap-2">
            <div className="sm:w-[18%] flex flex-col gap-3 mt-6">
              <p className="text-[#3E3C42] text-lg font-medium">
                Device Instance name<span className="text-red-500">*</span>
              </p>
              {view === true ? (
                <p className="text-[#525056] text-sm font-medium">
                  {device?.relativeDeviceName}
                </p>
              ) : (
                <div className="w-full">
                  <Input
                    type="text"
                    defaultValue={device?.relativeDeviceName}
                    onChange={(e) =>
                      handleChange("relativeDeviceName", e.target.value, index)
                    }
                    borderColor={"gray.300"}
                    borderWidth={"2px"}
                    isDisabled={disable}
                  />
                  {device?.relativeDeviceName?.length > 100 && (
                    <div>
                      <Text color="red.500" fontSize="sm" mt="1">
                        You have exceed the character limit of 100 by{" "}
                        {device?.relativeDeviceName?.length - 100}
                      </Text>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="sm:w-[18%] flex flex-col gap-3 mt-6">
              <p className="text-[#3E3C42] text-lg font-medium">
                Client<span className="text-red-500">*</span>
              </p>
              {view === true ? (
                <p className="text-[#525056] text-sm font-medium">
                  {device?.organisation}
                </p>
              ) : (
                <div className="w-full">
                  <select
                    value={device?.clientId}
                    onChange={(e) => {
                      handleChange(
                        "organisation",
                        clients?.filter(
                          (item) => item.clientId == e.target.value
                        )[0]?.organisation,
                        index
                      );
                      handleChange("clientId", e.target.value, index);
                    }}
                    borderColor={"gray.300"}
                    className="w-[100%] p-2 border-[2px] rounded-lg max-h-[300px] overflow-y-auto"
                    disabled={disable}
                  >
                    <option value="" disabled selected>
                      Select one
                    </option>
                    {clients?.map((item, index) => (
                      <option value={item?.clientId}>{item?.clientName}</option>
                    ))}
                  </select>
                </div>
              )}
            </div>
            <div className="sm:w-[18%] flex flex-col gap-3 mt-6">
              <p className="text-[#3E3C42] text-lg font-medium">
                Location<span className="text-red-500">*</span>
              </p>
              {view === true ? (
                <p className="text-[#525056] text-sm font-medium">
                  {device?.location}
                </p>
              ) : (
                <div className="w-full">
                  <select
                    value={device?.location}
                    onChange={(e) =>
                      handleChange("location", e.target.value, index)
                    }
                    borderColor={"gray.300"}
                    className="w-[100%] p-2 border-[2px] rounded-lg max-h-[300px] overflow-y-auto"
                    disabled={disable}
                  >
                    <option value="" disabled selected>
                      Select one
                    </option>
                    {clients
                      ?.filter((item) => item.clientId == device?.clientId)[0]
                      ?.clientAllLocation?.split(",")
                      .map((item, index) => (
                        <option value={item}>{item}</option>
                      ))}
                  </select>
                </div>
              )}
            </div>
            <div className="sm:w-[18%] flex flex-col gap-3 mt-6">
              <p className="text-[#3E3C42] text-lg font-medium">Plant</p>
              {view === true ? (
                <p className="text-[#525056] text-sm font-medium">
                  {device?.plant}
                </p>
              ) : (
                <div className="w-full">
                  <Input
                    type="text"
                    defaultValue={device?.plant}
                    onChange={(e) =>
                      handleChange("plant", e.target.value, index)
                    }
                    borderColor={"gray.300"}
                    borderWidth={"2px"}
                    isDisabled={disable}
                  />
                  {device?.plant?.length > 100 && (
                    <div>
                      <Text color="red.500" fontSize="sm" mt="1">
                        You have exceed the character limit of 100 by{" "}
                        {device?.plant?.length - 100}
                      </Text>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="sm:w-[18%] flex flex-col gap-3 mt-6">
              <p className="text-[#3E3C42] text-lg font-medium">Plant Unit</p>
              {view === true ? (
                <p className="text-[#525056] text-sm font-medium">
                  {device?.plantUnit}
                </p>
              ) : (
                <div className="w-full">
                  <Input
                    type="text"
                    defaultValue={device?.plantUnit}
                    onChange={(e) =>
                      handleChange("plantUnit", e.target.value, index)
                    }
                    borderColor={"gray.300"}
                    borderWidth={"2px"}
                    isDisabled={disable}
                  />
                  {device?.plantUnit?.length > 100 && (
                    <div>
                      <Text color="red.500" fontSize="sm" mt="1">
                        You have exceed the character limit of 100 by{" "}
                        {device?.plantUnit?.length - 100}
                      </Text>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="sm:w-[10%] items-center flex flex-row gap-4 mt-6">
              <button onClick={() => handleDelete(index)} disabled={disable}>
                {disable ? (
                  <img
                    src="/disabledDelete.svg"
                    className="w-[30px] h-[30px]"
                  />
                ) : (
                  <img src="/deleteIcon.svg" className="w-[30px] h-[30px]" />
                )}
              </button>

              <button
                onClick={() => setIsEditOpen(index)}
                disabled={view === true ? false : disable}
              >
                {disable && view === false ? (
                  <img
                    src="/disabledUpdate.svg"
                    className="w-[30px] h-[30px]"
                  />
                ) : (
                  <img src="/update.svg" className="w-[30px] h-[30px]" />
                )}
              </button>
            </div>
          </div>
        ))}

      {isEditOpen >= 0 && (
        <UpdateModal
          isOpen={isEditOpen}
          handleCloseEditModal={handleCloseEditModal}
          view={view}
          deviceId={userForm["deviceInfo"]?.deviceId}
          index={isEditOpen}
          device={devices[isEditOpen]}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
        />
      )}

      {view === true && <hr className="mt-3"></hr>}

      <div className="mt-6">
        <TonalButton
          text={"Add new"}
          disable={disable}
          width={"130px"}
          onClick={handleAddDevice}
        />
      </div>

      {view === false && (
        <div className="flex gap-[10px] items-center ml-6 md:ml-0 mt-6 mb-20 md:mb-0">
          {!review ? (
            <>
              <TonalButton
                text={"Save as draft"}
                width={"130px"}
                //disable={disable}
                onClick={() => {
                  handleSubmit(true);
                }}
              />
              <PrimaryButton
                text={"Review"}
                width={"130px"}
                onClick={() => {
                  setReview(true);
                  setDisable(true);
                  checkOk();
                }}
                //disable={disable}
              />
            </>
          ) : (
            <>
              <TonalButton
                text={"Back to editing"}
                width={"130px"}
                onClick={() => {
                  setReview(false);
                  setDisable(false);
                }}
                //disable={activeStep > 2 || view}
              />
              <PrimaryButton
                text={"Submit"}
                width={"130px"}
                onClick={() => handleSubmit(false)}
                //disable={activeStep > 2 || view}
                color="green"
              />
            </>
          )}
        </div>
      )}

      <DiscardModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        handleConfirmDiscard={handleConfirmDiscard}
      />
    </div>
  );
};

export default ClientsDetailsForm;

