const RipikAdminCards = ({
  noOfActiveSubs,
  noOfClients,
  noOflocations,
  isSuperAdminClient,
}) => {
  return (
    <div className="flex justify-center items-center xl:justify-start xl:w-1/2 gap-1  sm:gap-3">
      {!isSuperAdminClient && (
        <div className="flex shadow-md rounded-lg">
          <div className="w-[10px] border rounded-l-lg bg-[#6CA6FC]"></div>

          <div className="p-2">
            <p className="text-[#3E3C42] font-medium text-[20px]">
              {noOfClients}
            </p>
            <p className="text-[#605D64] text-[11px] sm:text-[14px]">
              All clients
            </p>
          </div>
        </div>
      )}
      <div className="flex shadow-md rounded-lg">
        <div className="w-[10px] border rounded-l-lg bg-[#7AC958]"></div>
        <div className="p-2">
          <p className="text-[#3E3C42] font-medium text-[20px]">
            {noOfActiveSubs}
          </p>
          <p className="text-[#605D64] text-[11px] sm:text-[14px]">
            {" "}
            Active subscriptions
          </p>
        </div>
      </div>
      <div className="flex shadow-md rounded-lg">
        <div className="w-[10px] border rounded-l-lg bg-[#FFC107]"></div>
        <div className="p-2">
          <p className="text-[#3E3C42] font-medium text-[20px]">
            {noOflocations}
          </p>
          <p className="text-[#605D64] text-[11px] sm:text-[14px]">
            All locations
          </p>
        </div>
      </div>
    </div>
  );
};

export default RipikAdminCards;
