import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useEffect, useState, useContext, useRef } from "react";
import NavContext from "../../NavContext";
import { baseURL } from "../../../index";
import { Select, Spinner } from "@chakra-ui/react";
import AlertTable from "../Tables/AlertTable";
import AlertCard from "../Components/AlertCard";
import ExlCsvDownload from "../../../util/VisionUtils/ExlCsvDownload";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";

const Alerts = ({ plantId, cameraId, disable, plantCamMap }) => {
  const param = useParams();
  const { auth } = useContext(NavContext);
  const [alerts, setAlerts] = useState([]);
  const [downloadData, setDownloadData] = useState({
    order: [
      "plantName",
      "cameraGpId",
      "vehicleNo",
      "startTsStr",
      "endTsStr",
      "event",
      "subEvent",
      "msg",
    ],
    data: [],
  });
  const [alertCards, setAlertCards] = useState({});
  const [alertsChanging, setAlertsChanging] = useState(false);
  const [liveFeed, setLiveFeed] = useState(true);
  const [reportDataType, setReportDataType] = useState("live");
  const [filterData, setFilterData] = useState({ items: [] });
  const [fromTime, setFromTime] = useState(
    new Date(
      new Date().getTime() - 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000
    ).toISOString()
  );
  const [toTime, setToTime] = useState(
    new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString()
  );

  const AlertApi = async () => {
    const requestData = JSON.stringify({
      clientId: param.clientId.toLowerCase(),
      useCase: "WORKFORCESAFETY",
      plantName: "khandala",
      cameraGpId: "all",
      startDate: new Date(fromTime).getTime() + 5.5 * 60 * 60 * 1000,
      endDate: new Date(toTime).getTime() + 5.5 * 60 * 60 * 1000,
    });
    const response = await axios
      .post(
        baseURL + "vision/v1/workforceMonitoring/alerts/ranged/",
        requestData,
        {
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      )
      .then((response) => {
        setAlertCards(response.data.eventSummary);
        setAlerts(response.data.subEventHistory.data);
        setDownloadData((prev) => ({
          ...prev,
          data: response.data.subEventHistory.data,
        }));
        setAlertsChanging(false);
      })
      .catch((error) => {
        console.log(error);
        setAlertsChanging(false);
      });
  };

  const LiveFeedCustom = (value) => {
    console.log("value", value);
    if (value == "custom") {
      setLiveFeed(false);
      setAlertsChanging(false);
    } else {
      setLiveFeed(true);
    }
    setReportDataType(value);
  };

  const handleClick = () => {
    setAlertsChanging(true);
    AlertApi();
  };

  useEffect(() => {
    if (liveFeed) {
      // Set up an interval to call handleClick every 1 minute
      const newFromTime = new Date(
        new Date().getTime() - 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000
      ).toISOString();

      setFromTime(newFromTime);

      const newToTime = new Date(
        new Date().getTime() + 5.5 * 60 * 60 * 1000
      ).toISOString();

      setToTime(newToTime);
      const intervalId = setInterval(() => {
        const newFromTime = new Date(
          new Date().getTime() - 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000
        ).toISOString();

        setFromTime(newFromTime);

        const newToTime = new Date(
          new Date().getTime() + 5.5 * 60 * 60 * 1000
        ).toISOString();

        setToTime(newToTime);
      }, 60 * 1000);

      // Clean up the interval when the component unmounts or when liveFeed is false
      return () => clearInterval(intervalId);
    }
  }, [liveFeed]);

  useEffect(() => {
    if (liveFeed) {
      handleClick();
    }
  }, [toTime]);

  console.log("param", downloadData);

  return (
    <div className="relative flex flex-col">
      <div className="absolute left-0 right-0 flex justify-center">
        <div className="p-5 pl-6 pr-6 gap-2 flex flex-col lg:flex-row items-center bg-white rounded-xl shadow-md">
          <div className="min-w-[110px]">
            <Select
              isDisabled={disable}
              placeholder={disable && plantId}
              borderColor="#CAC5CD"
              color={"white"}
              variant="outline"
              className="!text-sm !font-medium  !bg-[#084298] !hover:bg-[#3A74CA]"
              onChange={(e) => LiveFeedCustom(e.target.value)}
              value={reportDataType}
            >
              <option
                key="All Plants"
                value="live"
                className="bg-blue-500 text-white"
              >
                {alertsChanging ? <Spinner /> : "Live Feed"}
              </option>
              <option key="All Plants" value="custom">
                Custom
              </option>
            </Select>
          </div>
          <div>
            <FloatingInput
              text="From"
              type="datetime-local"
              setDateTime={setFromTime}
              value={fromTime?.slice(0, 16)}
              disabled={liveFeed}
              max={toTime?.slice(0, 16)}
            />
          </div>
          <div>
            <FloatingInput
              text="To"
              type="datetime-local"
              setDateTime={setToTime}
              value={toTime?.slice(0, 16)}
              disabled={liveFeed}
              min={fromTime?.slice(0, 16)}
              max={new Date().toISOString().slice(0, 16)}
            />
          </div>
          {!liveFeed && (
            // <button
            //   className="text-center py-2 px-2 text-white text-xs md:text-base font-medium bg-[#6CA6FC] rounded-md min-w-[80px]"
            //   onClick={handleClick}
            // >
            //   {alertsChanging ? <Spinner /> : "Apply"}
            // </button>

            <PrimaryButton
              width={"130px"}
              height={"40px"}
              text={alertsChanging ? <Spinner /> : "Show alerts"}
              onClick={handleClick}
              disable={!fromTime || !toTime}
            />
          )}
        </div>
      </div>
      <div className="flex flex-col gap-8 mt-[160px] lg:mt-2 pt-[73px]  lg:pt-[87px] bg-white rounded-xl justify-start  ">
        {Object.keys(alertCards).length === 0 ? (
          <div className="pl-6 text-xl font-medium text-[#3E3C42]">
            Alerts will be shown here
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-4 ">
              <p className=" text-xl font-medium text-[#3E3C42]  text-center">
                Compliance Score
              </p>
              <div className=" flex justify-center">
                {/* <div className=" grid grid-cols-6 gap-x-[10px] gap-y-[28px]   "> */}
                <div className="grid grid-cols-1 min-[480px]:grid-cols-2 md:grid-cols-2 lg:grid-cols-4  min-[1380px]:grid-cols-6 gap-x-3  min-[1380px]:gap-x-2 gap-y-8">
                  {Object.keys(alertCards).map((val, idx) => {
                    return (
                      <AlertCard
                        parameter={val}
                        count={alertCards[val]["passed"]}
                        total={alertCards[val]["total"]}
                        setFilterData={setFilterData}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="flex justify-end gap-2 pl-4 pr-6 mr-3 overflow-x-auto">
              {downloadData.hasOwnProperty("order") && (
                <ExlCsvDownload
                  order={downloadData.order}
                  data={downloadData.data}
                />
              )}
            </div>
            {alerts.length > 0 && (
              <AlertTable
                rowData={alerts}
                filterData={filterData}
                setFilterModel={setFilterData}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Alerts;
