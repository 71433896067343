import {
  Table,
  Td,
  Tr,
  Thead,
  Tbody,
  TableContainer,
  Th,
  InputGroup,
  Input,
  Select,
  InputRightElement,
  useToast,
} from "@chakra-ui/react";
import { useContext, useRef, useState, useEffect } from "react";
import NavContext from "../../NavContext";
import { baseURL } from "../../..";
import axios from "axios";
import { totalCal } from "../Tabs/Status";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

const ActualQtyCell = ({ data, fetchData }) => {
  const { auth } = useContext(NavContext);
  const [actualValue, setActualValue] = useState(false);
  const toast = useToast();

  const saveActualApi = async () => {
    const requestData = JSON.stringify({
      id: data?.id,
      val: actualValue,
    });
    try {
      const response = await axios.patch(
        baseURL + "vision/v1/workforceMonitoring/cb/updateSummaryRecords",
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.status == 200) {
        toast({
          title: "Updated",
          description: "Data successfully updated",
          status: "success",
          position: "top-right",
          duration: 2000,
          isClosable: true,
        });
        fetchData();
      }
    } catch (error) {
      console.log(error);
      fetchData();
    }
  };

  const handleSave = (e) => {
    if (e.code == "Enter") {
      saveActualApi();
    }
  };

  useEffect(() => {
    setActualValue(data?.actual_quantity);
  }, [data]);

  return (
    <Td p={"8px"} textAlign={"center"}>
      <InputGroup width={"fit-content"} border={1} borderColor={"#D8D8D8"}>
        <Input
          p={0}
          value={actualValue}
          onChange={(e) => setActualValue(e.target.value)}
          onKeyDown={(e) => handleSave(e)}
        />
        <InputRightElement>
          <KeyboardReturnIcon sx={{ color: "#828189" }} />
        </InputRightElement>
      </InputGroup>
    </Td>
  );
};

export const shiftCal = (timestamp) => {
  const date = new Date(timestamp * 1000);
  const localTime = date?.toLocaleString("en-GB", {
    hour12: false,
  });
  const hour = parseInt(localTime?.split(",")[1]?.split(":")[0]);
  if (hour >= 6 && hour < 14) {
    return "A";
  } else if (hour >= 14 && hour < 22) {
    return "B";
  } else {
    return "C";
  }
};

const SummaryReportTable = ({ rowData, fetchData }) => {
  const columns = [
    "Concentrate",
    "Date",
    "Shift",
    "Initial Qty",
    "SM 1",
    "SM 3",
    "Qty expected in yard",
    "Actual quantity",
  ];
  return (
    <TableContainer
      className="w-full !overflow-y-auto max-h-[45vh]"
      rounded={"8px"}
    >
      <Table variant="simple">
        <Thead className="bg-[#DEF] !text-xs !sticky !top-0 !z-10">
          <Tr>
            {columns.map((id, idx) => {
              return (
                <Th
                  key={idx}
                  color="#79767D"
                  fontWeight={400}
                  p={"12px"}
                  w={150}
                >
                  <div
                    className="flex gap-1 items-center"
                    style={{
                      justifyContent: idx == 0 ? "left" : "center",
                    }}
                  >
                    {id.toUpperCase()}
                  </div>
                </Th>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {rowData?.map((item, idx) => {
            return (
              <Tr color={"#3E3C42"} fontWeight={500} fontSize={"14px"}>
                <Td p={"12px"} textAlign={"left"}>
                  {item?.concentrate}
                </Td>
                <Td p={"12px"} textAlign={"center"}>
                  {new Date(item?.timestamp * 1000)?.toLocaleDateString(
                    "en-GB"
                  )}
                </Td>
                <Td p={"12px"} textAlign={"center"}>
                  {shiftCal(item?.timestamp)}
                </Td>
                <Td p={"12px"} textAlign={"center"}>
                  {item?.initial_quantity?.toFixed(2)}
                </Td>
                <Td p={"12px"} textAlign={"center"}>
                  {item?.SM1?.toFixed(2)}
                </Td>
                <Td p={"12px"} textAlign={"center"}>
                  {item?.SM3?.toFixed(2)}
                </Td>
                <Td p={"12px"} textAlign={"center"}>
                  {item?.expected_quantity?.toFixed(2)}
                </Td>
                <ActualQtyCell data={item} fetchData={fetchData} />
              </Tr>
            );
          })}
          <Tr color={"#3E3C42"} fontWeight={500} fontSize={"14px"}>
            <Td p={"12px"} textAlign={"left"}>
              Total
            </Td>
            <Td p={"12px"} textAlign={"center"}>
              -
            </Td>
            <Td p={"12px"} textAlign={"center"}>
              -
            </Td>
            {[
              "initial_quantity",
              "SM1",
              "SM3",
              "expected_quantity",
              "actual_quantity",
            ]?.map((a) => {
              return (
                <Td p={"12px"} textAlign={"center"}>
                  {Math.round(totalCal(rowData, a))
                    ? Math.round(totalCal(rowData, a))
                    : 0}
                </Td>
              );
            })}
          </Tr>
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default SummaryReportTable;
