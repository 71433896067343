import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  useToast,
  Skeleton,
  IconButton,
} from "@chakra-ui/react";
import { useWindowSize } from "@uidotdev/usehooks";
import { useContext, useEffect, useState } from "react";
import { CustomEditableDataGrid } from "./CustomEditableDataGrid";
import { formatDateTime } from "../utilis/dateTimeFormatter";
import {
  getSequenceSetModalDataApi,
  initializePalletBoardApi,
} from "../services/statusServices";
import NavContext from "../../NavContext";
import TableSkeleton from "./LoadingState/TableSkeleton";
import { CustomMaterialSingleSelect } from "./CustomMaterialSingleSelect";
import { Close } from "@mui/icons-material";

export const SequenceSetModal = ({ openModal, closeModal, clientId }) => {
  const { auth } = useContext(NavContext);
  const toast = useToast();
  const [rows, setRows] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);
  const [selectedBoard, setSelectedBoard] = useState("");
  const size = useWindowSize();
  const handleSaveSelectedButtonClick = () => {
    if (selectedBoard) {
      const requestData = JSON.stringify({
        clientId: clientId,
        useCase: "SINTERBELT",
        plantName: "chanderia",
        board: selectedBoard,
      });
      initializePalletBoardApi(auth, requestData)
        .then((res) => {
          console.log(res);
          if (res.success) {
            toast({
              title: res?.message,
              status: "success",
              duration: 3000,
              isClosable: true,
            });
          } else {
            toast({
              title: res?.message,
              status: "error",
              duration: 3000,
              isClosable: true,
            });
          }
        })
        .catch((error) => {
          console.log(error);
          toast({
            title: "Error while Updating",
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        });
    } else {
      toast({
        title: "Please select board number",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const getData = () => {
    const requestBody = JSON.stringify({
      clientId: clientId,
      plantName: "chanderia",
      useCase: "SINTERBELT",
    });
    getSequenceSetModalDataApi(auth, requestBody, setLoading)
      .then((res) => {
        console.log(res);
        if (res.data) {
          setData((prev) => res.data);
          setRows((prev) => res.data.tableData);
          setSelectedBoard((prev) => res.data?.board);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {size.width >= 768 ? (
        <Modal
          isOpen={openModal}
          onClose={closeModal}
          isCentered="true"
        >
          <ModalOverlay zIndex={1200} />
          <ModalContent
            borderRadius={"12px"}
            containerProps={{ zIndex: 1200 }}
            maxW={["85vw", "85vw", "85vw", "70vw", "70vw"]}
          >
            <ModalHeader
              padding="0px"
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              position={"relative"}
            >
              <IconButton
                position={"absolute"}
                rounded={"full"}
                bg={"white"}
                cursor={"pointer"}
                top={-5}
                icon={<Close sx={{ color: "gray" }} />}
                onClick={closeModal}
                boxShadow={"md"}
              />
            </ModalHeader>
            <ModalBody
              p={0}
              py={"12px"}
              maxH={"80vh"}
              overflowY={"auto"}
            >
              <div className="flex flex-col lg:flex-row bg-white px-6 py-0  gap-6 rounded-xl my-5">
                <div className="flex flex-col gap-4 items-center overflow-x-auto rounded-md bg-[#FAFAFA]  w-[100%] lg:w-[50%]">
                  {/* Camera View */}
                  <div className="flex flex-col gap-4 pt-0 pb-4 p-6 w-full">
                    <div className="flex flex-col sm:flex-row gap-6 items-center">
                      <div className="flex flex-col h-full w-full items-center gap-5">
                        <div className="w-full">
                          <p className="text-left text-md font-bold">
                            Live View{" "}
                          </p>
                        </div>
                        <div className="w-full h-full flex flex-col gap-1">
                          {/* Board Number Live Image */}
                          {loading ? (
                            <div className="w-full">
                              <Skeleton
                                height="150px"
                                width="100%"
                              />
                            </div>
                          ) : (
                            <div className="h-[150px] w-full flex justify-center items-center rounded-md">
                              <img
                                className="object-cover w-full h-full rounded-md"
                                src={data?.cam2OriginalImage}
                                alt="img"
                              />
                            </div>
                          )}
                          {/* Pallet Board Live Image */}
                          {loading ? (
                            <div className="w-full">
                              <Skeleton
                                height="150px"
                                width="100%"
                              />
                            </div>
                          ) : (
                            <div className="h-[150px] w-full flex justify-center items-center rounded-md">
                              <img
                                className="rounded-lg h-full w-full min-h-[150px]"
                                src={data?.originalImage}
                                alt="img"
                              />
                            </div>
                          )}
                          <div className="flex gap-1 items-center justify-end">
                            <p className="text-xs text-[#938F96]">
                              Last Updated
                            </p>
                            <p className="text-xs text-[#79767D]">
                              {data?.lastUpdated &&
                                formatDateTime(data?.lastUpdated)}
                            </p>
                          </div>
                        </div>

                        <div className="flex flex-col gap-4 h-full w-full">
                          <p className="text-md text-[#024D87] font-semibold text-left">
                            Initialize Pallet Board
                          </p>
                          {rows && (
                            <div className="flex flex-row gap-2">
                              <div className="w-full">
                                <CustomMaterialSingleSelect
                                  selectedBoard={selectedBoard}
                                  rows={rows}
                                  setSelectedBoard={setSelectedBoard}
                                />
                              </div>

                              <button
                                className="text-center px-4 text-white text-xs md:text-base font-medium bg-[#084298] rounded-md h-10"
                                onClick={handleSaveSelectedButtonClick}
                              >
                                {"Save"}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col md:pt-0 gap-4 my-0 items-center rounded-md w-[100%] lg:w-[50%]">
                  <div className="w-full">
                    <p className="text-left text-md font-bold">
                      Edit Pallet Board Sequence
                    </p>
                  </div>
                  {loading ? (
                    <TableSkeleton
                      headers={["Header 1", "Header 2", "Header 3"]}
                      rows={8}
                      cellsPerRow={3}
                    />
                  ) : (
                    <>
                      {rows && (
                        <>
                          <CustomEditableDataGrid
                            auth={auth}
                            rows={rows}
                            setRows={setRows}
                            clientId={clientId}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        <Drawer
          onClose={closeModal}
          isOpen={openModal}
          size="full"
          isFullHeight={true}
        >
          <DrawerOverlay zIndex={1200} />
          <DrawerContent containerProps={{ zIndex: 1200 }}>
            <DrawerHeader padding="0px">
              <div className="flex items-center justify-center sm:justify-start px-2 relative">
                <IconButton
                  rounded={"full"}
                  bg={"white"}
                  cursor={"pointer"}
                  icon={<Close sx={{ color: "gray" }} />}
                  onClick={closeModal}
                  boxShadow={"md"}
                />
              </div>
            </DrawerHeader>
            <DrawerBody
              p={0}
              maxH={"90vh"}
              overflowY={"auto"}
            >
              <div className="flex flex-col lg:flex-row bg-white px-6 py-0 gap-6 rounded-xl">
                <div className="flex flex-col gap-4 items-center overflow-x-auto rounded-md bg-[#FAFAFA] w-[100%] lg:w-[40%]">
                  {/* Camera View */}
                  <div className="flex flex-col gap-4 pt-0 pb-4  p-2 w-full">
                    <div className="flex flex-col sm:flex-row gap-6 items-center">
                      <div className="flex flex-col h-full w-full items-center gap-5">
                        <div className="w-full">
                          <p className="text-left text-md font-bold">
                            Live View{" "}
                          </p>
                        </div>
                        <div className="w-full h-full">
                          <div className="h-full w-full flex flex-col gap-2 items-center rounded-md">
                            {/* Board Number live image */}
                            {loading ? (
                              <div className="w-full">
                                <Skeleton
                                  height="150px"
                                  width="100%"
                                />
                              </div>
                            ) : (
                              <img
                                className="h-[150px] object-cover w-full rounded-md"
                                src={data?.cam2OriginalImage}
                                alt="img"
                              />
                            )}
                            {/* Pallet belt live image */}
                            {loading ? (
                              <div className="w-full">
                                <Skeleton
                                  height="150px"
                                  width="100%"
                                />
                              </div>
                            ) : (
                              <img
                                className="rounded-lg h-full w-full min-h-[150px]"
                                src={data?.originalImage}
                                alt="img"
                              />
                            )}
                          </div>
                          <div className="flex gap-1 items-center justify-end">
                            <p className="text-xs text-[#938F96]">
                              Last Updated
                            </p>
                            <p className="text-xs text-[#79767D]">
                              {data?.lastUpdated &&
                                formatDateTime(data?.lastUpdated)}
                            </p>
                          </div>
                        </div>

                        {rows && (
                          <div className="flex flex-col gap-4 h-full w-full">
                            <p className="text-md text-[#024D87] font-semibold text-left">
                              Initialize Pallet Board
                            </p>

                            <div className="w-full">
                              <CustomMaterialSingleSelect
                                selectedBoard={selectedBoard}
                                rows={rows}
                                setSelectedBoard={setSelectedBoard}
                              />
                            </div>
                            <button
                              className="text-center px-4 text-white text-xs md:text-base font-medium bg-[#084298] rounded-md h-11"
                              onClick={handleSaveSelectedButtonClick}
                            >
                              {"Save"}
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col md:pt-0 gap-4 my-0 items-center rounded-md w-[100%] lg:w-[60%] mb-5">
                  <div className="w-full">
                    <p className="text-left text-md font-bold">
                      Edit Pallet Board Sequence
                    </p>
                  </div>
                  {loading ? (
                    <TableSkeleton
                      headers={["Header 1", "Header 2", "Header 3"]}
                      rows={8}
                      cellsPerRow={3}
                    />
                  ) : (
                    <>
                      {rows && (
                        <>
                          <CustomEditableDataGrid
                            auth={auth}
                            rows={rows}
                            setRows={setRows}
                            clientId={clientId}
                          />
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};
