import { useState } from "react";

import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { PhotoGallery } from "../components/PhotoGallery";
import { VideoGallery } from "../components/VideoGallery";

const FeedLibrary = () => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (index) => {
    setTabIndex((prev) => index);
  };

  const tabs = [
    {
      label: "Photo Gallery",
      index: 0,
      component: <PhotoGallery />,
    },

    {
      label: "Video Gallery",
      index: 1,
      component: <VideoGallery />,
    },
  ];

  return (
    <div className="flex flex-col gap-0 bg-white p-5 rounded-xl w-full">
      <Tabs
        index={tabIndex}
        onChange={handleTabChange}
      >
        <TabList
          display={"flex"}
          gap={0}
          width={"full"}
          border={"1px solid #EBEBEB"}
          rounded={"8px"}
        >
          {tabs?.map((element, index) => (
            <Tab
              flex={1}
              padding={0}
              key={`${element.label}-${index}-tab`}
              px={"6px"}
              py={"8px"}
              border={0}
            >
              <div
                className={`flex items-center justify-center w-full p-2 cursor-pointer rounded-[4px] ${
                  tabIndex === element.index && "bg-[#E2EDFE]"
                }`}
              >
                <p
                  className={
                    tabIndex === element.index
                      ? "text-[#447ED4] text-base font-semibold"
                      : "text-[#79767D] text-base font-semibold"
                  }
                >
                  {element.label}
                </p>
              </div>
            </Tab>
          ))}
        </TabList>

        <TabPanels p={0}>
          {tabs?.map((element, index) => (
            <TabPanel
              key={`${element.label}-${index}-tabpanel`}
              padding={0}
            >
              {element?.component}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default FeedLibrary;
