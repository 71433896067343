import axios from "axios";
import { baseURL } from "../../..";

export const fetchDevices = async (auth) => {
  try {
    const response = await axios.get(baseURL + "device/v1/fetch/device", {
      headers: {
        "Content-Type": "application/json",
        "X-Auth-Token": auth,
      },
    });

    const filteredDevices = response?.data?.filter(
      (item) => item?.deviceInfo?.status === "ACTIVE"
    );
    return filteredDevices;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};

export const fetchAllProducts = async (auth) => {
  try {
    const response = await axios.get(
      baseURL + "product/v1/fetch/product/detail",
      {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching products:", error);
    throw error;
  }
};
