import ReactPlayer from "react-player";
import PrimaryButton from "../../../../util/Buttons/PrimaryButton";
import { useState } from "react";
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
} from "@chakra-ui/react";

const dummy = {
  url: "https://intelliverse-demo-new.s3.ap-south-1.amazonaws.com/Self+service.mp4",
  name: "Camera 1",
  labelData: {
    Tool: 3,
    Table: 5,
    Curtain: 6,
  },
  conf: 80,
};

const ConfidenceLimit = ({ conf }) => {
  const [confidence, setConfidence] = useState(conf);
  return (
    <div className="flex flex-col gap-6 w-full">
      <p>Confidence Threshold</p>
      <div className="flex flex-col gap-4 p-4 rounded bg-[#FAFAFA] border border-[#EBEBEB]">
        <div className="flex gap-4 items-center">
          <Slider
            aria-label="slider-ex-1"
            value={confidence}
            onChange={setConfidence}
          >
            <SliderTrack>
              <SliderFilledTrack bg={"#084298"} />
            </SliderTrack>
            <SliderThumb bg={"#084298"} />
          </Slider>
          <p className="p-1 rounded-lg border border-[#CAC5CD] text-[#306AC0] text-base font-medium">
            {confidence}%
          </p>
        </div>
        <PrimaryButton
          text={"Apply"}
          width={"fit-content"}
          disable={conf == confidence}
        />
      </div>
    </div>
  );
};

const CamInference = () => {
  const playerWrapper = ({ children }) => (
    <div className="w-full rounded-lg relative">
      {children}
      <p
        className="absolute top-4 left-4 text-xs md:text-base font-medium text-white px-2 py-1 rounded-[32px] bg-[#AEA9B1]"
        style={{ boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.10)" }}
      >
        Live feed from {dummy?.name}
      </p>
    </div>
  );
  const header = ["Object Type", "Count"];
  return (
    <div className="flex flex-col lg:flex-row w-full bg-white rounded p-6 gap-6 text-[#3E3C42] text-lg font-medium">
      <div className="flex flex-col gap-6">
        <p>Live Feed</p>
        <ReactPlayer
          url={dummy.url}
          volume={0}
          muted
          controls
          height={"auto"}
          wrapper={playerWrapper}
        />
      </div>
      <div className="flex flex-col sm:flex-row lg:flex-col gap-6 whitespace-nowrap min-w-full lg:min-w-[250px]">
        <div className="flex flex-col gap-6 w-full">
          <p>Feed Details</p>
          <div className="grid grid-cols-2 gap-x-[46px] gap-y-4 pt-4 px-4 bg-[#FAFAFA] border border-[#EBEBEB] rounded">
            {header?.map((item) => {
              return <p className="text-[#938F96] text-base">{item}</p>;
            })}
            <div className="border-b border-[#EBEBEB] col-span-2" />
            {Object?.entries(dummy?.labelData)?.map((item) => {
              return (
                <>
                  <p className="text-[#605D64] text-base font-medium">
                    {item[0]}
                  </p>
                  <p className="text-[#DC362E] text-base font-medium">
                    {item[1]}
                  </p>
                  <div className="border-b border-[#EBEBEB] col-span-2" />
                </>
              );
            })}
          </div>
        </div>
        <ConfidenceLimit conf={dummy.conf} />
      </div>
    </div>
  );
};

export default CamInference;
