import { Flex, Image, useToast } from "@chakra-ui/react";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "../util/util";
import { useWindowSize } from "@uidotdev/usehooks";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { baseURL } from "..";
import { useAuth0 } from "@auth0/auth0-react";
import NavContext from "./NavContext";

const Navbar = () => {
  const toast = useToast();
  let query = useQuery();
  const param = useAuth0();
  const { auth } = useContext(NavContext);
  const size = useWindowSize();
  const { clientId } = useParams();
  const [imageUrl, setImageUrl] = useState();
  const [organisation, setOrganisation] = useState();

  //const plant_name = query.get("plant_name");
  const fetchClientDetails = async () => {
    // Check if any required field is empty

    try {
      const response = await axios.get(
        baseURL + `iam/fetchClientById?clientId=${clientId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );

      const data = response?.data?.clientData;
      setImageUrl(data?.logo61url);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchUser = async () => {
    try {
      const response = await axios.get(baseURL + "user", {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });

      setOrganisation(response?.data?.data?.organisation);
    } catch (e) {
      //console.error(e);

      if (e?.response?.status == 403) {
        toast({
          title: "Session expired",
          description: "Please Login again.",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
        setTimeout(() => {
          localStorage.clear();
          if (param?.isAuthenticated) {
            param.logout({
              logoutParams: {
                returnTo: window.location.origin,
              },
            });
          }
          window.location.reload();
        }, 2000);
      }
    }
  };

  useEffect(() => {

    let IntervalTime = 10*60*1000;

    let id = setInterval(() => {
      if(auth)fetchUser();
    },IntervalTime)

    return () => {
      clearInterval(id);
    }
    
    // fetchClientDetails();
  }, []);

  return (
    <Flex
      p="4px"
      justify="space-between"
      w="100vw"
      m="auto"
      bgColor="white"
      position="fixed"
      top="0"
      zIndex="100"
      className="border-b"
      align="center"
      left="0"
    >
      <Flex
        alignItems="center"
        marginLeft="10px"
        style={{
          backgroundColor: "#ffffff",
          padding: "0px 10px",
        }}
      >
        <Image
          className="-ml-5 md:ml-0"
          // src="https://www.ultratechcement.com/content/dam/ultratechcementwebsite/new-header-design/desk.png"
          src={"/logo.svg"}
        />
      </Flex>
      {size.width >= 430 ? (
        <Flex alignItems="center" marginRight="30px">
          <Link to="/home">
            <img
              className="w-40 h-12 cursor-pointer"
              src="/verse.jpg"
              alt="heading"
            />
          </Link>
        </Flex>
      ) : (
        <div className="h-12"></div>
      )}
      <Flex alignItems="center" marginRight="30 px">
        {/* <div className="px-2 py-2 italic text-xl ">Steel Co.</div> */}
        {/* {clientId ? (
          // Render something when clientId is truthy
          <YourComponentForClientId />
        ) : organisation !== "Ripik.Ai" ? (
          // Render something when organisation is not "Ripik.Ai"
          <Image className="-ml-5 md:ml-0" src={imageUrl} />
        ) : (
          // Render something for other cases
          <Image className="-ml-5 md:ml-0" src={"/logo.svg"} />
        )} */}
      </Flex>
      {/* <Heading
				fontSize={["md", "2xl"]}
				fontWeight="bold"
				textTransform="capitalize"
				color="black"
				fontStyle="italic">
				Kiln Analyzer {plant_name && `- ${plant_name}`}
			</Heading>
			<Flex alignItems="center"> */}
      {/* //https://i.ibb.co/84MjLRt/Ripik-Vision.png */}
      {/* <Image
          h="60px"
          src="https://media-exp1.licdn.com/dms/image/C4D0BAQFX0dxczQGdgw/company-logo_200_200/0/1601122161236?e=1674691200&v=beta&t=iaVZkyz1w96-vERbMRNsmxR9FF2SIwnuGRuRQ81wOd0"
        /> */}
      {/* <Image
					h={["25px", "60px"]}
					src="https://i.ibb.co/84MjLRt/Ripik-Vision.png"
				/>
			</Flex> */}
    </Flex>
  );
};

export default Navbar;
