export const formatDateTimeUTC = (epochTimestamp) => {
  const isMilliseconds = epochTimestamp >= 1000000000000;

  const date = new Date(
    isMilliseconds ? epochTimestamp : epochTimestamp * 1000
  );

  const formattedDate = date.toLocaleDateString("en-IN", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour12: false,
    timeZone: "UTC",
  });

  const formattedTime = date.toLocaleTimeString("en-IN", {
    hour12: false,
    timeZone: "UTC",
  });

  return `${formattedDate} ${formattedTime}`;
};

export const formatDateTimeLocale = (epochTimestamp) => {
  const isMilliseconds = epochTimestamp >= 1000000000000;

  const date = new Date(
    isMilliseconds ? epochTimestamp : epochTimestamp * 1000
  );
  const formattedDate = date.toLocaleDateString(undefined, {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour12: false,
  });
  const formattedTime = date.toLocaleTimeString(undefined, {
    hour12: false,
  });
  return `${formattedDate} ${formattedTime}`;
};

export const formatForDateTimePickerLocale = (date) => {
  // multiply by 60000 cos getTimezoneOffset returns the difference in minutes
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, 16);
};

export const capitalizeCamelCaseString = (input) => {
  if (typeof input !== "string") {
    throw new Error("Input should be a string");
  }

  const words = input.split(/(?=[A-Z])/).join(" ");

  const capitalizedWords = words
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return capitalizedWords;
};

export const getAggregationInterval = (startDateStr, endDateStr) => {
  // previously used logic
  // less than or equal to 1 day = 1 min (max points = 1440 pts)
  // less than or equal to 7 and greater than 1 = 3 min (max points = 3360 pts)
  // less than or equal to 30 and greater than 7 = 15 min (max points = 2880 pts)
  const startDate = new Date(startDateStr.replace(" ", "T"));
  const endDate = new Date(endDateStr.replace(" ", "T"));

  const diffInMillis = Math.abs(endDate - startDate);
  const diffInDays = diffInMillis / (24 * 60 * 60 * 1000);

  if (diffInDays <= 1) {
    return 1 * 60 * 1000;
  } else if (diffInDays <= 7) {
    return 3 * 60 * 1000;
  } else if (diffInDays <= 30) {
    return 15 * 60 * 1000;
  }

  return 1 * 60 * 1000;
};

export const getAggregationBasis = (startDateStr, endDateStr) => {
  // for last 1 hr data, data should be live i.e. on second basis return second as a string
  // for last 1 day data, data should be aggregated on minute basis  return minute as a string
  // for last 1 hr data, data should be aggregated on hour basis return hour as a string
  const startDate = new Date(startDateStr.replace(" ", "T"));
  const endDate = new Date(endDateStr.replace(" ", "T"));

  const currentDate = new Date();
  const differenceInMilliseconds = endDate - startDate; // substracting date obj gives difference in mili second
  const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
  const differenceInDays = differenceInHours / 24;
  if (differenceInHours <= 1 && endDate.getTime() >= currentDate.getTime()) {
    return "second";
  } else if (
    differenceInDays <= 1 &&
    endDate.getTime() >= currentDate.getTime()
  ) {
    return "minute";
  } else {
    return "hour";
  }
};

export const generateUniqueColors = (n) => {
  const colors = [];
  const goldenRatioConjugate = 0.618033988749895;
  let hue = 0.0;

  for (let i = 0; i < n; i++) {
    hue += goldenRatioConjugate;
    hue %= 1;
    const saturation = 60 + (i % 2) * 10;
    const lightness = 50 + (i % 3) * 10;
    colors.push(`hsl(${Math.round(hue * 360)}, ${saturation}%, ${lightness}%)`);
  }

  return colors;
};
