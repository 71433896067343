import { Input, useConst, useToast } from "@chakra-ui/react";
import DeviceInfoForm from "./Components/DeviceInfoForm";
import { useContext, useEffect, useState } from "react";
import ClientsDetailsForm from "./Components/ClientsDetailsForm";
import FeedForm from "./Components/FeedForm";
import axios from "axios";
import NavContext from "../../NavContext";
import { baseURL } from "../../..";
import DraftModal from "../DraftModal";
import { ElevatorSharp } from "@mui/icons-material";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';


const initState = {
  deviceInfo: {
    deviceId: "",
    deviceGroupId: "",
    deviceName: "",
    deviceMake: "",
    referanceNo: "",
    secReferanceNo: "",
    deviceDetail: "",
    deviceNotes: "",
  },
  clientPlantInfo: [],
};

export const postDeviceApi = async (
  toast,
  auth,
  data,
  activeStep,
  status,
  update = null,
  setDummyStateForm
) => {
  try {
    data.deviceInfo["activeStep"] = activeStep;
    data.deviceInfo["isdeleted"] = false;
    data.deviceInfo["status"] = status;
    const requestData = JSON.stringify(data);
    console.log(data);
    const response = await axios.post(
      baseURL + "device/v1/add/device",
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    if (response.status == 200) {

      if (status == 0) {
        toast({
          title: "Saved",
          description: "The progress is saved",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
      }
      else {
        toast({
          title: "Submitted",
          description: "Device submitted successfully",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
      }

      setDummyStateForm(response.data);
      if (update) {
        update(response.data);
      }
    }
  } catch (error) {
    console.log(error);
  }
};


const AddNewDevice = ({ setShowDevicesTab, selectedDevice, update = false, view = false }) => {
  const [userForm, setUserForm] = useState(initState);
  const [dummyStateForm, setDummyStateForm] = useState(initState);
  const [activeStep, setActiveStep] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toast = useToast();
  const { auth } = useContext(NavContext);

  const handleBackButton = () => {
    if (!deepEqual(userForm, dummyStateForm)) {

      console.log(userForm);
      console.log(dummyStateForm);
      setIsModalOpen(true);
      return;
    }
    setShowDevicesTab((prev) => "devices");
  };

  const handleConfirmDiscard = () => {
    setIsModalOpen(false);
    setShowDevicesTab((prev) => "devices");
  }

  useEffect(() => {
    if (selectedDevice && Object.entries(selectedDevice).length > 0) {
      setUserForm(selectedDevice);
      setDummyStateForm(selectedDevice);
      console.log(selectedDevice, "data");
      if (view) setActiveStep(3);
      else setActiveStep(0);
    }
  }, []);

  useEffect(() => {
    if (activeStep < 2) {
      const elem = document.getElementById("step" + activeStep);
      elem.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    if (activeStep != 0 && !view) {
      let status = activeStep == 2 ? 1 : 0;
      postDeviceApi(toast, auth, userForm, activeStep, status, setUserForm, setDummyStateForm);
    }
  }, [activeStep]);

  function deepEqual(obj1, obj2) {

    if (typeof obj1 !== 'object' || typeof obj2 !== 'object') {
      return obj1 === obj2;
    }

    if (obj1 === null && obj2 === null) {
      return true;
    }

    const keys1 = Object.keys(obj1 || {});
    const keys2 = Object.keys(obj2 || {});

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  }

  const handleSubmit = () => {

    if (!userForm["deviceInfo"]?.deviceGroupId ||
      !userForm["deviceInfo"]?.deviceName ||
      !userForm["deviceInfo"]?.deviceMake ||
      userForm["deviceInfo"]?.deviceName?.length > 100 ||
      userForm["deviceInfo"]?.deviceMake?.length > 100 ||
      userForm["deviceInfo"]?.referanceNo?.length > 100 ||
      userForm["deviceInfo"]?.secReferanceNo?.length > 100 ||
      userForm["deviceInfo"]?.deviceDetail?.length > 1000 ||
      userForm["deviceInfo"]?.deviceNotes?.length > 1000) {
      toast({
        title: "Incomplete Details",
        description: "Please fill in all required details.",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    if (activeStep > 0) {
      for (let x of userForm["clientPlantInfo"]) {
        if (x?.relativeDeviceName?.length > 100 || !x?.relativeDeviceName || !x?.clientId || !x.location || x?.plant?.length > 100 || x?.plantUnit?.length > 100 || x?.serialNumber?.length > 100 || x?.addReferanceNotes?.length > 100 || x?.instanceNotes?.length > 1000 || x?.installationNotes?.length > 1000 || x?.dataFeed?.length > 100 || x?.port?.length > 100 || x?.userName?.length > 100 || x?.password?.length > 100) {
          toast({
            title: "Please enter all the required details",
            status: "error",
            duration: 4000,
            isClosable: true,
            position: "top",
          });
          return;
        }
      }
    }

    postDeviceApi(toast, auth, userForm, activeStep, 0, setUserForm, setDummyStateForm);
  }

  return (
    <div className="font-roboto flex flex-col gap-1">
      <div className="flex items-center m-2">
        <div className="cursor-pointer w-8" onClick={handleBackButton}>
          <KeyboardBackspaceIcon sx={{ color: "#084298" }} />
        </div>
        {view ? <p className="text-[#084298] font-medium text-xl ml-2">
          View device
        </p> : (update ? <p className="text-[#084298] font-medium text-xl ml-2">
          Update device
        </p> : <p className="text-[#084298] font-medium text-xl ml-2">
          Add new device
        </p>)}

      </div>

      <DeviceInfoForm
        userForm={userForm}
        setUserForm={setUserForm}
        setActiveStep={setActiveStep}
        activeStep={activeStep}
        page={"deviceInfo"}
        view={view}
        setDummyStateForm={setDummyStateForm}
      />
      <ClientsDetailsForm
        userForm={userForm}
        setUserForm={setUserForm}
        show={activeStep >= 1}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        page={"clientPlantInfo"}
        view={view}
        setDummyStateForm={setDummyStateForm}
      />
      {/* <FeedForm
        userForm={userForm}
        setUserForm={setUserForm}
        show={activeStep >= 2}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        page={"feedInfo"}
        view={view}
      /> */}

      {isModalOpen && <DraftModal isOpen={isModalOpen} setShowDraft={setIsModalOpen} handleSubmit={handleSubmit} handleConfirmDiscard={handleConfirmDiscard} />}

    </div>
  );
};

export default AddNewDevice;
