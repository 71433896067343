import { useWindowSize } from "@uidotdev/usehooks";
import { useState } from "react";

const AdminTokensData = ({
  startBalance,
  endBalance,
  tokensUsed,
  tokensPurchase,
  avgConsumption,
  allocated,
  unallocated,
}) => {
  const size = useWindowSize();

  return (
    <div className="flex flex-col xl:flex-row xl:justify-evenly lg:items-start xl:items-center gap-4 mt-6 mb-6">
      <div
        className={`flex border justify-evenly gap-3 border-[#FFC107] xl:whitespace-nowrap bg-[#FFFFED] p-3 rounded-lg xl:w-1/2`}
      >
        <div className="flex flex-col sm:flex-row gap-4">
          <div>
            <div className="w-full flex gap-1">
              <p className="text-[#3E3C42] font-semibold ">
                {startBalance || 0}
              </p>
              {/* coin icons */}
              <img src="/token.svg" alt="coins" />
            </div>
            <p>Starting balance</p>
          </div>
          <div>
            <div className="w-full flex gap-1">
              <p className="text-[#3E3C42] font-semibold ">{endBalance || 0}</p>
              {/* coin icons */}
              <img src="/token.svg" alt="coins" />
            </div>
            <p>Ending balance</p>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row gap-4 ">
          <div>
            <div className="w-full flex gap-1">
              <p className="text-[#3E3C42] font-semibold ">
                {tokensPurchase || 0}
              </p>
              {/* coin icons */}
              <img src="/token.svg" alt="coins" />
            </div>
            <p>Tokens purchased</p>
          </div>
          <div>
            <div className="w-full flex gap-1">
              <p className="text-[#3E3C42] font-semibold ">{tokensUsed || 0}</p>
              {/* coin icons */}
              <img src="/token.svg" alt="coins" />
            </div>
            <p>Tokens used</p>
          </div>
        </div>
      </div>
      <div className="xl:w-1/2 flex flex-col sm:flex-row sm:justify-evenly gap-3 items-start sm:items-center whitespace-nowrap border border-[#7AC958] bg-[rgba(205, 238, 191, 0.50)] p-3 rounded-lg">
        <div className="">
          <div className="w-full flex gap-1">
            <p className="text-[#3E3C42] font-semibold ">{allocated || 0}</p>
            {/* coin icons */}
            <img src="/token.svg" alt="coins" />
          </div>
          <p>Allocatted to users</p>
        </div>
        <div>
          <div className="w-full flex gap-1">
            <p className="text-[#3E3C42] font-semibold ">{unallocated || 0}</p>
            {/* coin icons */}
            <img src="/token.svg" alt="coins" />
          </div>
          <p>Unallocatted to users</p>
        </div>
        <div>
          <div className="w-full flex gap-1">
            <p className="text-[#3E3C42] font-semibold ">
              {avgConsumption || 0}
            </p>
            {/* coin icons */}
            <img src="/token.svg" alt="coins" />
          </div>
          <p>Avg consumption per user</p>
        </div>
      </div>
    </div>
  );
};

export default AdminTokensData;
