import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  CheckboxGroup,
  Checkbox,
  useToast,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import axios from "axios";
import { baseURL } from "../../../index";
import NavContext from "../../NavContext";
import CommonProgressBar from "./CommonProgressBar";
import { draftPostApi } from "../pages/DraftForm";

const VideoSelect = ({
  openModal,
  closeModal,
  userData,
  selectedFiles,
  setLoading,
  activeStep,
  setUSerData,
}) => {
  const [selectedNames, setSelectedNames] = useState([]);
  const [progressState, setProgressState] = useState({
    open: false,
    state: null,
  });
  const { auth } = useContext(NavContext);
  const toast = useToast();

  const videoInfoReturn = async (file) => {
    const video = document.createElement("video");
    video.preload = "metadata";
    const loadMetadata = new Promise((resolve, reject) => {
      video.onloadedmetadata = resolve;
      video.onerror = reject;
    });
    video.src = URL.createObjectURL(file);

    try {
      await loadMetadata;
      window.URL.revokeObjectURL(video.src);
      return video;
    } catch (error) {
      console.error("Error loading video metadata:", error);
      return {};
    }
  };

  const uploadVideoApi = async () => {
    setProgressState((prev) => ({ ...prev, open: true, state: 1 }));
    const promise = new Promise(async (resolve, reject) => {
      const totalChunks = selectedFiles.length;
      let completedChunks = 0;
      for (let i = 0; i < totalChunks; i++) {
        const videoFile = selectedFiles[i];
        const val = await videoInfoReturn(videoFile);
        let param = {
          projectId: userData.projectId,
          length: val?.duration,
          dimension: `${val?.videoWidth}x${val?.videoHeight}`,
          codec: "h.264",
          fps: 30,
          sizeBytes: videoFile?.size,
        };
        param["videoDatasetType"] = selectedNames.includes(
          selectedFiles[i].name
        )
          ? "TRAIN"
          : "PREDICT";
        param["startFraming"] = i == totalChunks - 1 ? true : false;
        let requestData = new FormData();
        requestData.append("files", videoFile);
        await axios
          .post(
            baseURL + "selfserve/v1/project/v1/video/upload/",
            requestData,
            {
              params: param,
              headers: {
                "Content-Type": "multipart/form-data",
                "X-Auth-Token": auth,
              },
            }
          )
          .then((response) => {
            completedChunks++;
            if (completedChunks == totalChunks) {
              resolve(200);
              setProgressState((prev) => ({ ...prev, state: 2 }));
              setLoading(false);
              closeModal();
              setUSerData((prev) => {
                let updateData = { ...prev };
                updateData["videoUploaded"] = true;
                draftPostApi(updateData, activeStep, toast, auth);
                return updateData;
              });
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
            setProgressState((prev) => ({ ...prev, state: 0 }));
            setLoading(false);
          });
      }
    });
  };
  console.log(selectedNames, "value");
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={openModal}
      onClose={closeModal}
      isCentered="true"
    >
      <ModalOverlay />
      <ModalContent style={{ borderRadius: "8px" }} maxW="360px">
        <ModalHeader
          py={"8px"}
          px={"16px"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          borderBottom={"1px solid #EBE2F0"}
        >
          <p className="text-[#605D64] text-sm font-medium">
            Select training video
          </p>
        </ModalHeader>
        <ModalBody p={"16px"}>
          <div className="flex flex-col gap-2 items-end">
            <CheckboxGroup onChange={setSelectedNames} value={selectedNames}>
              <div className="flex gap-3 flex-wrap items-center self-start">
                {selectedFiles.map((x, idx) => {
                  return (
                    <div
                      style={{
                        backgroundColor: selectedNames.includes(x.name)
                          ? "#DDEEFF80"
                          : "#FFF",
                        borderRadius: "8px",
                      }}
                    >
                      <Checkbox
                        value={x.name}
                        py={"8px"}
                        pl={"8px"}
                        pr={"12px"}
                        fontSize={"14px"}
                        fontWeight={500}
                        color={"#3E3C42"}
                        _hover={{ borderColor: "#6CA6FC" }}
                        isDisabled={
                          selectedNames.length == selectedFiles.length - 1 &&
                          !selectedNames.includes(x.name)
                        }
                      >
                        {x.name}
                      </Checkbox>
                    </div>
                  );
                })}
              </div>
            </CheckboxGroup>
            <PrimaryButton
              text={"Save"}
              width={"fit-content"}
              onClick={uploadVideoApi}
            />
          </div>
        </ModalBody>
      </ModalContent>
      {progressState?.open && progressState?.state && (
        <CommonProgressBar
          openModal={progressState?.open}
          closeModal={() =>
            setProgressState((prev) => ({
              ...prev,
              open: false,
              state: null,
            }))
          }
          state={progressState?.state}
        />
      )}
    </Modal>
  );
};

export default VideoSelect;
