import ReactApexCharts from "react-apexcharts";
import { useWindowSize } from "@uidotdev/usehooks";

const BoxPlotChart = ({ series, title }) => {

    const size = useWindowSize();

    console.log(series);

    const updatedSeries = [
        {
            type: 'boxPlot',
            data: series,
        }
    ]

    const options = {
        chart: {
            type: 'boxPlot',
            height: 350
        },
        title: {
            // text: 'Basic BoxPlot Chart',
            // align: 'left'
        },
        plotOptions: {
            boxPlot: {
                colors: {
                    upper: '#5C4742',
                    lower: '#A5978B'
                }
            }
        }

    };

    return (
        <div className="min-w-[400px] w-full h-full min-h-[400px]">
            <ReactApexCharts
                options={options}
                series={updatedSeries}
                type="boxPlot"
                height={'100%'}
            />
        </div>
    );
};

export default BoxPlotChart;
