const CustomSizingBar = ({ size, thresholds = null }) => {
  let binData = Object.values(size);
  let sum = binData.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);
  let vals = [];
  let binSizes = Object.keys(size);
  let labels = [];
  binData.map((item, idx) => {
    let x = (item / sum) * 100;
    if (x !== 0) {
      vals.push(x.toFixed(2));
      labels.push(binSizes[idx]);
    }
  });
  const colors = [
    "#ffc107",
    "#5193f6",
    "#ef6f12",
    "#1c56ac",
    "#e91e63",
    "#00bcd4",
    "#8bc34a",
    "#9c27b0",
    "#673ab7",
    "#ff9800",
    "#4caf50",
    "#795548",
  ];
  let isSmall;
  if (typeof window !== "undefined") {
    if (window.innerWidth > 1024) isSmall = false;
    else isSmall = true;
  }
  return (
    <div className="flex flex-col gap-4 min-w-[15vw]">
      {/* <p className="text-base text-black">Size Distribution</p> */}
      <div className="flex flex-col h-[30vh]">
        {vals.map((x, idx) => {
          return (
            <div style={{ height: `${x}%` }} className="flex gap-3">
              <div
                className={`w-[50%] lg:w-[6vw]  ${
                  idx === 0 && "rounded-tr-md rounded-tl-md"
                } ${
                  idx === vals.length - 1 && "rounded-bl-md rounded-br-md"
                } text-white flex justify-center text-[8px] sm:text-xs items-center`}
                style={{ backgroundColor: colors[idx] }}
              >
                {isSmall && x > 4 ? `${x}%` : ""}
              </div>
              {x > 4 &&
                (!isSmall ? (
                  <div className="flex gap-2 items-center w-[50%] ">
                    <p
                      className="font-medium w-[50%] text-sm "
                      style={{ color: colors[idx] }}
                    >
                      {x}%
                    </p>
                    <p className="text-black flex items-center gap-[1px] font-medium text-sm whitespace-nowrap w-[50%] ">
                      {labels[idx]}{" "}
                      {thresholds &&
                      thresholds?.["size"]?.[labels[idx]]?.max <= x ? (
                        <span class="text-red-700 text-base">&#9888;</span>
                      ) : null}
                    </p>
                  </div>
                ) : (
                  <div className="flex items-center mr-[-50px] w-[50%]">
                    <div className="text-black flex items-center gap-[1px] w-full font-medium text-xs" title={labels[idx]}>
                      <p className="w-full truncate">{labels[idx]}{" "}</p>
                      {thresholds &&
                      thresholds?.["size"]?.[labels[idx]]?.max <= x ? (
                        <span class="text-red-700 text-base">&#9888;</span>
                      ) : null}
                    </div>
                  </div>
                ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default CustomSizingBar;
