import StatusTooltip from "./StatusTooltip";
import { useState } from "react";

const FillBar = ({ current, total, isActive = false, data, section, bay }) => {
  const [hover, setHover] = useState(false);
  var fill = (current / total) * 100;
  return (
    <div
      className="flex flex-col gap-[6px] w-[16px] h-full items-center"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <p
        className={`${isActive ? "font-medium" : "font-normal"} text-xs   ${
          isActive ? "text-[#024D87]" : "text-[#AEA9B1]"
        }`}
      >
        {section}
      </p>
      <div className="h-full w-full flex flex-col gap-0 relative ">
        <div
          style={{
            height: `${100 - fill}%`,
            backgroundColor: "#ECEFF1",
          }}
          className=" whitespace-nowrap  "
        />
        <div
          style={{
            height: `${fill}%`,
            backgroundColor: isActive ? "#90CAF9" : "#DDEEFF",
          }}
          className=" "
        />
        {hover && (
          <StatusTooltip
            bay={bay}
            section={section}
            data={{
              concentrate: data[bay][section]?.reduce((a, b) => {
                return a + b.concentrate + ",";
              }, ""),
              current: current,
              lastUpdated: data[bay][section][0]?.lastUpdated,
            }}
          />
        )}
        <p className="text-black text-xs font-medium absolute -rotate-90 whitespace-nowrap bottom-[50%] -right-5 w-[90px] h-fit text-ellipsis overflow-clip">
          {data[bay][section].reduce((a, b) => {
            // console.log("ab", a + b.concentrate);
            return a + b.concentrate == "" ? "" : a + b.concentrate + ",";
          }, "")}
        </p>
        <p className="text-[#263238] text-xs font-semibold absolute -rotate-90 bottom-1 left-0 right-0 whitespace-nowrap  tracking-wides">
          {current}
        </p>
      </div>
    </div>
  );
};

export default FillBar;
