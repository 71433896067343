import IndexChart from "../../Charts/SinterBeltCharts/IndexChart";
import { formatDateTime } from "../utilis/dateTimeFormatter";

const CamCard = ({ data }) => {
  return (
    <div className="flex flex-col gap-4 py-3 px-1 md:px-6 w-full">
      <div className="flex flex-col sm:flex-row gap-6 items-center">
        <div className="flex flex-col h-full w-full items-center gap-2">
          <div className="w-full">
            <p className="text-left text-md font-bold">Live view </p>
          </div>
          <div className="w-full h-full flex flex-col gap-1">
            {/* Board Number Live Image */}
            <div className="h-[150px] lg:h-[130px] xl:h-[110px] w-full flex justify-center items-center rounded-md">
              <img
                className="object-cover w-full h-full rounded-md"
                src={data?.cam2OriginalImage}
                alt="img"
              />
            </div>
            {/* Pallet Belt Live Image  */}
            <div className="h-full w-full flex justify-center items-center rounded-md">
              <img
                className="h-[150px] lg:h-[130px] xl:h-[150px] w-full rounded-md"
                src={data?.originalImage}
                alt="img"
              />
            </div>
            <div className="flex flex-row gap-1 items-center justify-end">
              <p className="text-xs text-[#938F96]">Last updated</p>
              <p className="text-xs text-[#79767D]">
                {data?.lastUpdated && formatDateTime(data?.lastUpdated)}
              </p>
            </div>
          </div>
          <div className="w-full">
            <p className="text-md text-[#024D87] font-semibold text-left">
              {data?.sequenceNo}. Pallet board {data?.board}
            </p>
          </div>
          <div className="flex flex-col gap-4 h-full w-full">
            <div className="flex flex-row gap-4 items-center">
              <p className="text-sm min-w-[20%]">Row 1</p>
              <IndexChart
                permeabilityValue={data?.row1Permeability?.value}
                value={data?.row1Permeability?.permeability}
                vals={[3, 4, 5, 6, 7]}
              />
            </div>
            <div className="flex flex-row gap-4 items-center">
              <p className="text-sm min-w-[20%]">Row 2</p>
              <IndexChart
                permeabilityValue={data?.row2Permeability?.value}
                value={data?.row2Permeability?.permeability}
                vals={[3, 4, 5, 6, 7]}
              />
            </div>
            <div className="flex flex-row gap-4 items-center">
              <p className="text-sm min-w-[20%]">Row 3</p>
              <IndexChart
                permeabilityValue={data?.row3Permeability?.value}
                value={data?.row3Permeability?.permeability}
                vals={[3, 4, 5, 6, 7]}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CamCard;
