import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import NavContext from "../../NavContext";
import LibraryGrid from "./LibraryGrid";
import { Select, Spinner } from "@chakra-ui/react";
import { baseURL } from "../../../index";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import axios from "axios";
import { mixpanelTrackButton } from "../../../util/mixpanelFunctions";
import { capitalize } from "@mui/material";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import { capitalizeFirstLetter } from "../../../util/sentenceCase";

const PhotoGallery = ({ plantId, cameraId, disable, plantCamMap }) => {
  const { auth } = useContext(NavContext);
  let param = useParams();
  const [imgData, setImgData] = useState([]);
  const [imgDataChanging, setImgDataChanging] = useState(false);
  const [hasBigParticle, setHasBigParticle] = useState(0);
  const [showType, setShowType] = useState(0);
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [selectedPlant, setSelectedPlant] = useState(plantId);
  const [selectedCam, setSelectedCam] = useState(cameraId);
  const handleSelect = (e) => {
    let val = e.target.value;
    setShowType(val);
    if (val == 0) {
      setDate(new Date().toISOString().slice(0, 10));
    } else if (val == 1) {
      setDate(
        new Date(new Date() - 24 * 60 * 60 * 1000).toISOString().slice(0, 10)
      );
    }
  };

  const apiCall = async () => {
    const requestData = JSON.stringify({
      clientId: param.clientId.toLowerCase(),
      material: param.material.toLowerCase(),
      plantName: selectedPlant,
      cameraId: selectedCam,
      startDate: new Date(new Date(date).setHours(0, 0, 0, 0)).getTime(),
    });
    const response = await axios
      .post(baseURL + "vision/v2/sizing/feedLibrary/images/", requestData, {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      })
      .then((response) => {
        let fitlerData = response.data?.filter((item) => {
          if (hasBigParticle === 0) return item;
          return "bigParticlePresent" in item && item?.bigParticlePresent;
        });
        setImgData(fitlerData);
        setImgDataChanging(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClick = () => {
    setImgDataChanging(true);
    apiCall();
    let tool = capitalize(param.material.toLowerCase()) + " Sizing";
    mixpanelTrackButton("photo gallery", tool, "View");
  };

  useEffect(() => {
    handleClick();
  }, []);

  useEffect(() => {
    if (!disable) {
      setSelectedCam(plantCamMap[selectedPlant][0]);
    }
  }, [selectedPlant]);

  return (
    <div className="bg-white pl-6 pr-6 flex flex-col gap-6">
      <div className="flex pt-5 gap-2 py-[2px] items-center overflow-x-auto">
        <div className="min-w-[110px]">
          <Select
            borderColor="#CAC5CD"
            color="#605D64"
            placeholder={disable && capitalizeFirstLetter(selectedPlant)}
            variant="outline"
            isDisabled={disable}
            rounded={"base"}
            className="!text-sm !font-medium text-[#605D64] "
            onChange={(e) => setSelectedPlant(e.target.value)}
            value={selectedPlant}
          >
            {!disable &&
              Object.keys(plantCamMap).map((plant) => {
                return (
                  <option
                    key={plant}
                    value={plant}
                  >
                    {capitalizeFirstLetter(plant)}
                  </option>
                );
              })}
          </Select>
        </div>
        <div className="min-w-[110px]">
          <Select
            borderColor="#CAC5CD"
            color="#605D64"
            placeholder={disable && capitalizeFirstLetter(selectedCam)}
            variant="outline"
            isDisabled={disable}
            rounded={"base"}
            className="!text-sm !font-medium text-[#605D64]"
            onChange={(e) => setSelectedCam(e.target.value)}
            value={selectedCam}
          >
            {!disable &&
              plantCamMap[selectedPlant].map((cam) => {
                return (
                  <option
                    key={cam}
                    value={cam}
                  >
                    {capitalizeFirstLetter(cam)}
                  </option>
                );
              })}
          </Select>
        </div>
        <div className="min-w-[110px]">
          <Select
            borderColor="#CAC5CD"
            color="#605D64"
            variant="outline"
            rounded={"base"}
            onChange={(e) => handleSelect(e)}
            value={showType}
          >
            <option value={0}>Today</option>
            <option value={1}>Yesterday</option>
            <option value={2}>Custom</option>
          </Select>
        </div>
        {(imgData?.some((item) => item?.hasOwnProperty("bigParticlePresent")) ||
          hasBigParticle === 1) && (
          <div className="min-w-[110px]">
            <Select
              borderColor="#CAC5CD"
              color="#605D64"
              variant="outline"
              rounded={"base"}
              onChange={(e) => setHasBigParticle(Number(e.target.value))}
              value={hasBigParticle}
            >
              <option value={0}>All</option>
              <option value={1}>Big particle</option>
            </Select>
          </div>
        )}
        {showType == 2 && (
          <div className="min-w-[110px]">
            <FloatingInput
              text="Date"
              type="date"
              setDateTime={setDate}
              max={new Date().toISOString().slice(0, 10)}
              value={date}
            />
          </div>
        )}
        {/* <button
          className="text-center py-2 px-4 text-white text-xs md:text-base font-medium bg-[#6CA6FC] rounded-full min-w-[80px]"
          onClick={handleClick}
        >
          {imgDataChanging ? <Spinner /> : "Apply"}
        </button> */}
        <SecondaryButton
          height={"40px"}
          width={"80px"}
          onClick={handleClick}
          text={imgDataChanging ? <Spinner /> : "Apply"}
          disable={imgDataChanging || !date}
        />
      </div>
      <LibraryGrid
        plantName={selectedPlant}
        img={imgData}
      />
    </div>
  );
};

export default PhotoGallery;
