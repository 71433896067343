import { useContext, useEffect, useState } from "react";
import UseCaseDetail from "../Components/UseCaseDetail";
import UploadDetails from "../Components/UploadDetails";
import AnnotateData from "../Components/AnnotateData";
import ProjectDetails from "../Components/ProjectDetails";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { useNavigate, useParams, Link } from "react-router-dom";
import ModelUpload from "../Components/ModelUpload";
import axios from "axios";
import { baseURL } from "../../..";
import NavContext from "../../NavContext";
import { useToast } from "@chakra-ui/react";
import TonalButton from "../../../util/Buttons/TonalButton";
import { initState } from "./CreateForm";

const TimeLengthMap = (val) => {
  if (val > 1000) return "30 min";
  else if (val > 500) return "15 min";
  else return "a few min";
};

export const draftPostApi = async (data, step, toast, auth) => {
  try {
    data["activeStep"] = step;
    delete data["savedFiles"];
    const requestBody = JSON.stringify(data);
    const param = {
      projectId: data?.projectId,
    };
    const response = await axios.post(
      baseURL + "selfserve/v1/project/v1/draft/",
      requestBody,
      {
        params: param,
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    if (response.status == 200) {
      toast({
        title: "Success",
        description: "Draft saved",
        status: "success",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
    }
  } catch (error) {
    console.log(error);
  }
};

const DraftForm = () => {
  const { auth } = useContext(NavContext);
  const [userState, setUserState] = useState(initState);
  const [activeStep, setActiveStep] = useState(0);
  const [callDrafts, setCallDrafts] = useState(false);
  const { projectId } = useParams();
  const toast = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (activeStep != 0) {
      const elem = document.getElementById("step" + activeStep);
      elem.scrollIntoView({
        behavior: "smooth", // You can use 'auto' instead of 'smooth' for instant scrolling
        block: "center", // You can use 'center' or 'end' instead of 'start'
      });
      setCallDrafts(true);
    }
    if (callDrafts) draftPostApi(userState, activeStep, toast, auth);
  }, [activeStep]);

  const draftGetApi = async () => {
    try {
      const param = {
        projectId: projectId,
      };
      const response = await axios.get(
        baseURL + "selfserve/v1/project/v1/draft/",
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.status == 200) {
        toast({
          title: "Success",
          description: "Draft restored successfull",
          status: "success",
          position: "top-right",
          duration: 2000,
          isClosable: true,
        });
        setUserState((prev) => {
          let newData = response.data[0]?.data;
          newData["savedFiles"] = null;
          return newData;
        });
        setActiveStep((prev) => {
          return response.data[0]?.data?.activeStep;
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    draftGetApi();
  }, []);
  console.log(userState, "draft State");
  return (
    <div className="flex flex-col gap-2 h-screen mt-6 font-roboto">
      <div className="flex gap-2 items-center">
        <Link
          to={`/Sandbox`}
          style={{
            textDecoration: "none",
          }}
        >
          <img src="/backtick.svg" />
        </Link>
        <p className="text-xl font-medium text-[#084298] capitalize">
          Create new project
        </p>
      </div>
      {activeStep != 0 && (
        <div className="flex flex-col gap-3 h-full">
          <ProjectDetails
            userData={userState}
            setUSerData={setUserState}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            viewMode={activeStep > 0}
          />
          <UseCaseDetail
            userData={userState}
            setUSerData={setUserState}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            show={activeStep >= 1}
            viewMode={activeStep > 1}
          />
          <ModelUpload
            userData={userState}
            setUSerData={setUserState}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            show={activeStep >= 2 || activeStep == 50}
            viewMode={activeStep >= 2 && activeStep != 50}
          />
          <UploadDetails
            userData={userState}
            setUSerData={setUserState}
            setActiveStep={setActiveStep}
            activeStep={activeStep}
            show={activeStep >= 2 && activeStep != 50}
            viewMode={activeStep > 2 && activeStep != 50}
          />
          {userState.isAnnotated == "No" && (
            <AnnotateData
              userData={userState}
              setUSerData={setUserState}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              show={activeStep >= 3 && activeStep != 50}
              draft={userState?.draft || false}
            />
          )}
          {activeStep == 4 && (
            <div
              className={`p-6 flex flex-col gap-8 rounded-lg bg-white transition-all ease-in duration-500 ${
                activeStep == 4 ? "opacity-100" : "opacity-0"
              }`}
              id="step4"
            >
              <p className="text-[#3E3C42] text-xl font-medium">
                Traning request Submitted
              </p>
              <p className="text-base font-medium text-[#3E3C42] whitespace-normal">
                You saved {Object.entries(userState.uploadedFiles)?.length}{" "}
                files and annotated {userState.annotatedData?.length} Files.
                Based on the information provided the model will take{" "}
                {TimeLengthMap(userState.annotatedData?.length)} approx. Please
                click on close to move to home page
              </p>
              <div className="flex items-center gap-2 mt-2">
                <TonalButton
                  text={"Close"}
                  width={"fit-content"}
                  onClick={() => navigate(`/Sandbox`)}
                />
                <PrimaryButton
                  text={"View"}
                  width={"fit-content"}
                  onClick={() =>
                    navigate(`/Sandbox/View/${userState?.projectId}`)
                  }
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default DraftForm;
