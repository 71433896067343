export const apiErrorHandler = (error) => {
  let errorMessage = "An unknown error occurred";
  if (error.response) {
    if (error.response.status === 401) {
      errorMessage = "Authentication failed. Please login again.";
    } else {
      errorMessage = `Server Error: ${error.response.status}`;
    }
  } else if (error.request) {
    if (error?.code === "ERR_NETWORK") {
      errorMessage = "Network Error";
    } else errorMessage = "Cancelled by user";
    // Unable to connect to the server.
  } else {
    errorMessage = `Error: ${error.message}`;
  }
  return errorMessage;
};
