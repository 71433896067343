import { IconButton, Skeleton } from "@chakra-ui/react";
import { AspectRatio } from "@mui/icons-material";
import React, { useState } from "react";
import { ZoomSingleImage } from "./ZoomSingleImage";

function CameraFeed({
  data,
  cameraName,
  showLargeImage = false,
  openingFromFeedPage = false,
}) {
  const [selectedImage, setSelectedImage] = useState(data?.[cameraName]);
  const [openZoomModal, setOpenZoomModal] = useState(false);

  const handlePreviewImage = (imageUrl) => {
    setSelectedImage((prev) => imageUrl);
    setOpenZoomModal((prev) => true);
  };

  return (
    <div className="flex flex-col w-full gap-2 h-full">
      <div className=" w-full relative  h-full rounded-md ">
        {data?.[cameraName] ? (
          <img
            src={data?.[cameraName]}
            alt="not supported"
            style={{
              height: showLargeImage ? showLargeImage : "150px",
            }}
            className="w-full  rounded-lg"
          />
        ) : (
          // <img
          //   className=" h-[28vh] sm:h-[30vh] xl:h-[28vh] rounded-lg w-full  object-contain bg-black"
          //   src={data?.[cameraName]}
          //   alt="no Support"
          // />
          <div
            style={{
              height: showLargeImage ? showLargeImage : "150px",
            }}
            className="'w-full flex justify-center items-center   font-semibold border rounded-md"
          >
            No Image
          </div>
        )}
        {openingFromFeedPage && (
          <div className="flex flex-row  absolute bottom-2 right-2 items-center gap-1">
            <IconButton
              onClick={() => handlePreviewImage(data?.[cameraName] || "")}
              size={"xs"}
              backgroundColor={"transparent"}
              _hover={{ backgroundColor: "transparent" }}
              _active={{ backgroundColor: "transparent" }}
              icon={
                <AspectRatio
                  className="preview-image text-white hover:cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
                  data-index={data?.[cameraName]}
                  fontSize={"medium"}
                />
              }
            />
            {/* <p className="text-white text-xs font-semibold bg-black rounded-md p-[2px]">
                    Board No- {capitalizeFirstLetter(x.boardNo)}
                  </p> */}
          </div>
        )}
        {openZoomModal && (
          <ZoomSingleImage
            imageUrl={selectedImage}
            openModal={openZoomModal}
            closeModal={() => {
              setOpenZoomModal((prev) => false);
              setSelectedImage(null);
            }}
          />
        )}
      </div>
    </div>
  );
}

export default CameraFeed;
