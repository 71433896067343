import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { CustomStyledDataGrid } from "../../util/MaterialDataGrid/CustomStyledDatagrid";

const slotProps = {
  // column menu
  columnMenu: {
    sx: {
      minWidth: 170,
      padding: 0,
      ".MuiTypography-root": {
        fontSize: "13px",
      },
      "& .css-1asgcec-MuiButtonBase-root-MuiMenuItem-root": {
        paddingY: 0,
        minHeight: "35px",
      },
    },
  },
  // the panel which contains filtering menu (top wrapper)
  panel: {
    sx: {
      maxWidth: "85vw",
      ".css-154sxbz-MuiPaper-root-MuiDataGrid-paper": {
        minWidth: 0,
      },
    },
  },
  // actual filter panel in which all elements are stored
  filterPanel: {
    filterFormProps: {
      // input box for operator selection(for multi-columns => and, or)
      logicOperatorInputProps: {
        sx: { display: "none" },
      },
      // input box for column selection
      columnInputProps: {
        variant: "outlined",
        size: "small",
      },
      // input box for operator selection(for a particular columns => contains, start with, is empty ....)
      operatorInputProps: {
        sx: { display: "none" },
      },
      // input box to write the value on the basis of which data get's filtered
      valueInputProps: {
        InputComponentProps: {
          variant: "outlined",
          size: "small",
        },
      },
      // delete icon
      deleteIconProps: {
        sx: {
          "& .MuiSvgIcon-root": { color: "#D32F2F" },
        },
      },
    },
    sx: {
      overflowX: "auto",
      "& .MuiDataGrid-filterForm": { p: 2 },
      "& .MuiDataGrid-filterFormLogicOperatorInput": {
        mr: 2,
        minWidth: 80,
      },
      "& .MuiDataGrid-filterFormColumnInput": {
        mr: 2,
        minWidth: 150,
      },
      "& .MuiDataGrid-filterFormValueInput": { minWidth: 200 },
    },
  },
}

const AiAdvisorHistoryTable = ({ rowData }) => {
  const columns = [
    {
      field: "chatId",
      headerName: "SR No.",
    },
    {
      field: "createdAt",
      headerName: "Date Time",
      type: "dateTime",
      minWidth:150,
      valueGetter: ({ value }) => new Date(value),
      valueFormatter: (params) => {
        const value = params?.value;
        return value
          ? new Date(value).toDateString().split(" ")[2] +
              " " +
              new Date(value).toDateString().split(" ")[1] +
              " '" +
              new Date(value).toDateString().split(" ")[3].slice(2, 4) +
              " " +
              new Date(value).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              })
          : "";
      },
      sortComparator: (v1, v2, param1, param2) => {
        // Custom sorting logic based on the "createdAt" values
        const date1 = new Date(v1);
        const date2 = new Date(v2);
        return date1 - date2;
      },
    },
    {
      field: "summary",
      headerName: "Summary",
      minWidth:250,
    },
    {
      field: "amount",
      headerName: "Amount",
      minWidth:150,
      renderCell: ({ row }) => {
        return (
          <div className="w-full flex gap-1 ">
            {row.amount}
            <img src="/token.svg" alt="coins" />
          </div>
        );
      },
    },
    {
      field: "link",
      headerName: "Link",
      minWidth:150,
      renderCell: ({ row }) => {
        return (
          <div className="w-full flex item-center gap-2 text-[#5892E8] cursor-pointer ">
            <Link to={`/community/advisor/${row.chatId}`}>
              <p>Link to Q/A</p>
            </Link>
            <div className="flex items-center justify-center">
              <ExternalLinkIcon />
            </div>
          </div>
        );
      },
    },
  ];
  const headerClass = "text-sm font-normal text-[#79767D] bg-[#DDEEFF]";
  const cellClass = "text-sm font-normal text-[#3E3C42] whitespace-nowrap";
  const flexMap = [0, 1, 3, 1, 1];
  columns.map((val, idx) => {
    val["headerClassName"] = headerClass;
    val["cellClassName"] = cellClass;
    val["flex"] = flexMap[idx];
  });

  return (
    <div className="mt-2 w-full" 
    style={{
      height:
        108 +
        Math.min(5, rowData?.length || 1) * 52 +
        "px",
    }}
    >
      <CustomStyledDataGrid
        rows={rowData}
        columns={columns}
        getRowId={(row) => row.chatId}
        columnVisibilityModel={{
          chatId: false,
          username: false,
        }}
        pagination
        initialState={{
          sorting: {
            sortModel: [{ field: "createdAt", sort: "desc" }],
          },
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        slotProps={slotProps}
      />
    </div>
  );
};

export default AiAdvisorHistoryTable;
