import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";
import Alerts from "./Tabs/Alerts";
import { Status } from "./Tabs/Status";
import { Report } from "./Tabs/Report";
import FeedLibrary from "./Tabs/Feed";
import { mixpanelTrackTabs } from "../../util/mixpanelFunctions";

export const indexWordMap = {
  "-1": "Can't Compute",
  0: "Belt Downtime",
  1: "Side Walls Missing",
  2: "Side Wall Pin Missing",
  3: "Grate Bar Missing",
  4: "V Notch Present",
  5: "Poor",
  6: "Average",
  7: "Good",
  8: "Excellent",
};

// to be used for overall belt or board permeability
export const overallPermeabilityIndex = {
  "-1": "Can't Compute",
  0: "Belt Downtime",
  1: "Maintenance Required",
  2: "Poor",
  3: "Average",
  4: "Good",
  5: "Excellent",
};

const SinterBelt = () => {
  const size = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlParamTabIndex = !isNaN(searchParams.get("tabIndex"))
    ? Number(searchParams.get("tabIndex"))
    : 0;

  // handling tab change
  const handleTabsChange = (index) => {
    if (index !== urlParamTabIndex) {
      setSearchParams({ tabIndex: index }, { replace: true });
    }
  };
  // const location = useLocation();
  const [plantCamMap, setPlantCamMap] = useState({
    chanderia: ["cam1"],
  });
  const tabs = [
    {
      label: "Status",
      index: 0,
      component: <Status />,
    },

    {
      label: "Alerts",
      index: 1,
      component: (
        <Alerts
          plantId="All Plants"
          disable={false}
          plantCamMap={plantCamMap}
        />
      ),
    },
    {
      label: "Report",
      index: 2,
      component: (
        <Report
          plantId="All Plants"
          disable={false}
          plantCamMap={plantCamMap}
        />
      ),
    },
    {
      label: "Feed",
      index: 3,
      component: (
        <FeedLibrary
          plantId="All Plants"
          disable={false}
          plantCamMap={plantCamMap}
        />
      ),
    },
  ];
  useEffect(() => {
    const originalTitle = document.title;
    document.title = "Sinter Belt | IntelliVerse";
    return () => {
      document.title = originalTitle;
    };
  }, []);
  // use effect to look for change in tab-index
  useEffect(() => {
    handleTabsChange(urlParamTabIndex);
    let page = tabs.filter((item) => item.index == urlParamTabIndex)[0].label;
    let tool = "Sinter Belt Monitoring";
    mixpanelTrackTabs(page.toLowerCase(), tool);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParamTabIndex]);
  return (
    <div
      className="  font-poppins flex flex-col rounded-lg"
      style={{ width: size.width >= 768 ? "calc(100vw - 168px)" : "100vw" }}
    >
      <div
        className="fixed top-5 right-0 h-[30px] w-[180px] bg-white"
        style={{ zIndex: 1000 }}
      >
        <img
          className="absolute h-[50px] w-[120px] top-[-20px] right-2"
          src="/clientLogos/hzl.png"
          alt="clientLogo"
        />
      </div>
      <div className="flex justify-start gap-2 items-center mb-3 mt-6">
        <Link
          to={`/vision/equipmentmonitoring/sinterbelt`}
          style={{
            textDecoration: "none",
          }}
        >
          <img
            src="/backtick.svg"
            alt="backtick"
          />
        </Link>
        <p className="text-lg sm:text-2xl font-semibold text-[#024D87] capitalize">
          Sinter Belt Monitoring
        </p>
      </div>
      <Tabs
        isLazy={true}
        index={urlParamTabIndex}
        onChange={handleTabsChange}
      >
        <TabList className="!flex !border-0">
          <div className="flex items-center gap-4 overflow-x-auto h-14 md:h-10">
            {tabs.map((tab, index) => (
              <Tab
                key={`${tab.label}-${index}`}
                minW={"fit-content"}
                className={`${
                  urlParamTabIndex === tab.index
                    ? "!text-black !text-xs sm:!text-sm !bg-white rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#79767D]"
                    : "!text-xs sm:!text-sm !text-[#938F96] !border-0"
                }`}
                onClick={() => handleTabsChange(tab.index)}
              >
                {tab.label}
              </Tab>
            ))}
          </div>
        </TabList>
        <TabPanels>
          {tabs.map((tab, index) => (
            <TabPanel
              key={index}
              className="!pl-0 !pr-0"
            >
              {tab.component}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default SinterBelt;
