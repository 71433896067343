import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import { useParams, Link, useSearchParams } from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";
import KilnFeed from "./Tabs/KilnFeed";
import Alerts from "./Tabs/Alerts";
import Report from "./Tabs/Report";
import FeedLibrary from "./Tabs/FeedLibrary";
import Analytics from "./Tabs/Analytics";
import { mixpanelTrackTabs } from "../../util/mixpanelFunctions";
import { capitalizeFirstLetter } from "../../util/sentenceCase";

const Capitalize = (str) => {
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
};

const Kiln = () => {
  const size = useWindowSize();
  let param = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [plantCamMap, setPlantCamMap] = useState({});
  const page = !isNaN(searchParams?.get("tabIndex"))
    ? Number(searchParams?.get("tabIndex"))
    : 0;
  let material = "kilnhealth";

  const handleTabChange = (val) => {
    if (val !== page) {
      setSearchParams({ tabIndex: val }, { replace: true });
    }
  };

  useEffect(() => {
    handleTabChange(page);
    let tool = Capitalize(material) + " Monitoring";
    mixpanelTrackTabs(tabList[page]?.tabName?.toLowerCase(), tool);
  }, [page]);

  const tabList = [
    {
      index: 0,
      tabName: "Feed",
      element: (
        <KilnFeed
          material={"kilnhealth"}
          clientId={param.clientId.toLowerCase()}
          setPlantCamMap={setPlantCamMap}
          Map={plantCamMap}
        />
      ),
    },
    {
      index: 1,
      tabName: "Alerts",
      element: (
        <Alerts
          plantId="All Plants"
          cameraId=""
          disable={false}
          plantCamMap={plantCamMap}
        />
      ),
    },
    {
      index: 2,
      tabName: "Feed library",
      element: (
        <FeedLibrary
          plantId="All Plants"
          cameraId=""
          disable={false}
          plantCamMap={plantCamMap}
        />
      ),
    },
    {
      index: 3,
      tabName: "Analytics",
      element: Object.keys(plantCamMap).length != 0 && (
        <Analytics
          plantId={Object.keys(plantCamMap)[0]}
          cameraId={plantCamMap[Object.keys(plantCamMap)[0]][0]}
          disable={false}
          plantCamMap={plantCamMap}
        />
      ),
    },
    {
      index: 4,
      tabName: "Report",
      element: (
        <Report
          plantId="All Plants"
          cameraId=""
          disable={false}
          plantCamMap={plantCamMap}
        />
      ),
    },
  ];

  return (
    <div
      className="  font-poppins flex flex-col rounded-lg"
      style={{ width: size.width >= 768 ? "calc(100vw - 168px)" : "100vw" }}
    >
      <div className="flex justify-start gap-2 items-center mb-3 mt-6">
        <Link
          to={`/vision/ProcessMonitoring/kilnhealth`}
          style={{
            textDecoration: "none",
          }}
        >
          <img src="/backtick.svg" />
        </Link>
        <p className="text-lg sm:text-2xl font-semibold text-[#024D87]">
          {capitalizeFirstLetter("kiln vision")}
        </p>
      </div>

      <Tabs index={page} onChange={handleTabChange}>
        <TabList className="!flex !border-0">
          <div className="flex items-center gap-4 overflow-x-auto h-14 md:h-10">
            {tabList?.map((item, idx) => {
              return (
                <Tab
                  key={idx}
                  className={
                    item?.index === page
                      ? "!text-black !text-xs sm:!text-sm !bg-white rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#79767D]"
                      : "!text-xs sm:!text-sm !text-[#938F96] !border-0"
                  }
                  onClick={() => handleTabChange(item?.index)}
                >
                  {item?.tabName}
                </Tab>
              );
            })}
          </div>
        </TabList>

        <TabPanels>
          {tabList?.map((item, idx) => (
            <TabPanel key={idx} className="!pl-0 !pr-0">
              {item?.element}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default Kiln;
