import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useEffect, useState, useContext, useRef } from "react";
import NavContext from "../../NavContext";
import { baseURL } from "../../../index";
import DetailModal from "../components/DetailModal";
import Paginator from "../../../util/VisionUtils/Paginator";
import ConstraintModal from "../components/ConstraintModal";
import { SettingsIcon } from "@chakra-ui/icons";
import {
  Select,
  Table,
  Td,
  Tr,
  Thead,
  Tbody,
  TableContainer,
  Th,
  Flex,
  Image,
  Button,
  Spinner,
  IconButton,
} from "@chakra-ui/react";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { capitalizeFirstLetter } from "../../../util/sentenceCase";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import AlertTable from "../components/AlertTable";
import DataGridTable from "../components/DataGridTable";

const getAlertMessage = () => {
  return "Health index is poor";
};

const Alerts = ({ plantId, cameraId, disable, plantCamMap }) => {
  const param = useParams();
  const { auth } = useContext(NavContext);
  let material = "sinterflame";
  let clientId = param.clientId.toLowerCase();
  const [selectedDetails, setSelectedDetails] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openConstraintModal, setOpenConstraintModal] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [alertsChanging, setAlertsChanging] = useState(false);
  const [fromTime, setFromTime] = useState(
    new Date(new Date().getTime() - 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16)
  );
  const [toTime, setToTime] = useState(
    new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16)
  );
  const [selectedPlant, setSelectedPlant] = useState(
    disable ? plantId : "All Plants"
  );
  const [selectedCam, setSelectedCam] = useState(
    disable ? cameraId : "All Burners"
  );
  const apiCall = async () => {
    const requestData = JSON.stringify({
      clientId: clientId,
      useCase: material.toUpperCase(),
      startDate: new Date(fromTime).getTime() + 5.5 * 60 * 60 * 1000,
      endDate: new Date(toTime).getTime() + 5.5 * 60 * 60 * 1000,
      cameraId:
        selectedCam === "All Burners" || selectedPlant === "All Plants"
          ? "all"
          : selectedCam,
      plantName: selectedPlant === "All Plants" ? "all" : selectedPlant,
      maxLimit: 8000,
    });
    const response = await axios
      .post(
        baseURL + "vision/v2/processMonitoring/alerts/overview/",
        requestData,
        {
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      )
      .then((response) => {
        setAlerts(response.data);
        setAlertsChanging(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClick = () => {
    setAlertsChanging(true);
    apiCall();
  };

  const handleDetail = (obj) => {
    setSelectedDetails(obj);
    setOpenModal(true);
  };

  useEffect(() => {
    handleClick();
  }, []);
  return (
    <div className="relative flex flex-col">
      <IconButton
        position={"absolute"}
        top={"-20px"}
        right={0}
        colorScheme="gray"
        size={"lg"}
        rounded={"100px"}
        color={"#79767D"}
        zIndex={100}
        onClick={() => setOpenConstraintModal(true)}
        icon={<SettingsIcon />}
      >
        Update Constraint
      </IconButton>
      <div className="absolute left-0 right-0 flex justify-center">
        <div className="p-5 pl-6 pr-6 gap-2 flex flex-col md:flex-row items-center bg-white rounded-xl shadow-md">
          <div>
            <FloatingInput
              text="From"
              type="datetime-local"
              setDateTime={setFromTime}
              value={fromTime}
              max={toTime}
            />
          </div>
          <div>
            <FloatingInput
              text="To"
              type="datetime-local"
              setDateTime={setToTime}
              value={toTime}
              max={new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
                .toISOString()
                .slice(0, 16)}
              min={fromTime}
            />
          </div>
          {/* <button
            className="text-center p-[10px] pl-4 pr-4 text-white text-xs md:text-base font-medium bg-[#084298] rounded-full"
            onClick={handleClick}
          >
            {alertsChanging ? <Spinner /> : "Show Alerts"}
          </button> */}
          <PrimaryButton
            height={"40px"}
            width={"110px"}
            disable={alertsChanging || !fromTime || !toTime}
            text={alertsChanging ? <Spinner /> : "Show alerts"}
            onClick={handleClick}
          />
        </div>
      </div>
      <div className="flex flex-col gap-4 mt-[160px] md:mt-11 pt-[57px] bg-white rounded-xl justify-start">
        <div className="flex justify-between gap-3">
          <div className="flex gap-2 ml-6 overflow-x-auto">
            <div className="min-w-[110px]">
              <Select
                borderColor="#CAC5CD"
                color="#605D64"
                placeholder={disable && capitalizeFirstLetter(plantId)}
                variant="outline"
                isDisabled={disable}
                className="!text-sm !font-medium text-[#605D64]"
                onChange={(e) => setSelectedPlant(e.target.value)}
                value={selectedPlant}
              >
                <option key="All Plants" value="All Plants">
                  {capitalizeFirstLetter("All plants")}
                </option>
                {!disable &&
                  Object.keys(plantCamMap).map((plant) => {
                    return (
                      <option key={plant} value={plant}>
                        {capitalizeFirstLetter(plant)}
                      </option>
                    );
                  })}
              </Select>
            </div>
            <div className="min-w-[110px]">
              <Select
                borderColor="#CAC5CD"
                color="#605D64"
                placeholder={disable && cameraId}
                variant="outline"
                isDisabled={disable || selectedPlant == "All Plants"}
                className="!text-sm !font-medium text-[#605D64]"
                onChange={(e) => setSelectedCam(e.target.value)}
                value={selectedCam}
              >
                {" "}
                <option key="All Burners" value="All Burners">
                  {capitalizeFirstLetter("All Burners")}
                </option>
                {!disable &&
                  selectedPlant != "All Plants" &&
                  plantCamMap[selectedPlant].map((cam) => {
                    return (
                      <option key={cam} value={cam}>
                        {capitalizeFirstLetter(
                          cam.replace(/^(\D+)(\d+)/, "$1 $2")
                        )}
                      </option>
                    );
                  })}
              </Select>
            </div>
            {/* <button
              className="text-center py-2 px-4 text-white text-xs md:text-base font-medium bg-[#6CA6FC] rounded-full min-w-[80px]"
              onClick={handleClick}
            >
              {alertsChanging ? <Spinner /> : "Apply"}
            </button> */}
            <SecondaryButton
              height={"40px"}
              width={"80px"}
              text={alertsChanging ? <Spinner /> : "Apply"}
              disable={alertsChanging}
              onClick={handleClick}
            />
          </div>
          {/* {alerts.hasOwnProperty("data") && (
            <Paginator
              data={alerts.data}
              limit={30}
              setDisplayData={setDisplayData}
            />
          )} */}
        </div>
        {alerts.hasOwnProperty("data") && alerts.hasOwnProperty("order") && (
          <DataGridTable
            rowData={alerts.data}
            order={alerts.order}
            handleDetail={handleDetail}
            showViewDetail={true}
            // showActionButton={true}
            showDetailModalByRowData={true}
          />
        )}
        {/* {alerts.hasOwnProperty("data") && (
          <TableContainer className="!max-h-[80vh] !overflow-y-auto">
            <Table variant="simple">
              <Thead className="bg-[#FAFAFA] !text-xs !sticky !top-0">
                <Tr>
                  <Th color="#79767D" fontWeight={400}>
                    SR. NO.
                  </Th>
                  {alerts.order.map((item) => {
                    return (
                      <Th color="#79767D" fontWeight={400}>
                        {item.toUpperCase()}
                      </Th>
                    );
                  })}
                  <Th color="#79767D" fontWeight={400}>
                    {""}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {displayData.map((item, index) => {
                  return (
                    <Tr
                      key={index}
                      className="!text-sm !text-[#3E3C42] !font-medium even:bg-[#FAFAFA] odd:bg-white"
                    >
                      <Td className="">
                        {String(item["idx"]).padStart(2, "0")}
                      </Td>
                      <Td className="">{item.plantName}</Td>
                      <Td className="">{item.cameraId}</Td>
                      <Td className="">
                        {new Date(item.startTime * 1000).toLocaleString(
                          "en-US",
                          {
                            timeZone: "UTC",
                          }
                        )}
                      </Td>
                      <Td className="">
                        {new Date(item.endTime * 1000).toLocaleString("en-US", {
                          timeZone: "UTC",
                        })}
                      </Td>
                      <Td className="">
                        {Math.floor(item?.deviationDuration / 60) +
                          " min " +
                          (item?.deviationDuration % 60) +
                          " s"}
                      </Td>
                      <Td>
                        <p
                          className="text-blue-800 cursor-pointer hover:text-blue-200 font-semibold min-w-[80px]"
                          onClick={() => handleDetail(item)}
                        >
                          View Details
                        </p>
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>
              )} */}
      </div>
      {openModal && selectedDetails && (
        <DetailModal
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          data={selectedDetails?.idList?.map((item) => {
            return {
              id: item,
              cameraId: selectedDetails?.cameraId,
              plantName: selectedDetails?.plantName,
            };
          })}
          index={0}
          PlantName={selectedPlant}
        />
      )}
      {openConstraintModal && (
        <ConstraintModal
          openModal={openConstraintModal}
          closeModal={() => setOpenConstraintModal(false)}
          plantName={selectedPlant}
          material={material}
          clientId={clientId}
        />
      )}
    </div>
  );
};

export default Alerts;
