import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { createTheme, ThemeProvider, Tooltip } from "@mui/material";
import TextButton from "../../../../util/Buttons/TextButton";

const MuiTheme = createTheme();

const dummy = [
  {
    date: "2022-03-17",
    hour: "08:42",
    alertRaised: 57,
    detectedLabels: ["label1", "label3", "label5"],
    details: "https://example.com/dummy-image.jpg",
  },
  {
    date: "2022-06-25",
    hour: "17:29",
    alertRaised: 12,
    detectedLabels: ["label2", "label4"],
    details: "https://example.com/dummy-image.jpg",
  },
  {
    date: "2022-12-10",
    hour: "03:15",
    alertRaised: 33,
    detectedLabels: ["label1", "label3", "label5"],
    details: "https://example.com/dummy-image.jpg",
  },
  {
    date: "2022-07-20",
    hour: "21:05",
    alertRaised: 80,
    detectedLabels: ["label2", "label3"],
    details: "https://example.com/dummy-image.jpg",
  },
  {
    date: "2022-05-07",
    hour: "12:39",
    alertRaised: 92,
    detectedLabels: ["label1", "label4", "label5"],
    details: "https://example.com/dummy-image.jpg",
  },
  {
    date: "2022-08-11",
    hour: "19:18",
    alertRaised: 68,
    detectedLabels: ["label2", "label3", "label4"],
    details: "https://example.com/dummy-image.jpg",
  },
  {
    date: "2022-10-28",
    hour: "09:47",
    alertRaised: 23,
    detectedLabels: ["label1", "label2", "label3", "label5"],
    details: "https://example.com/dummy-image.jpg",
  },
  {
    date: "2022-11-19",
    hour: "05:55",
    alertRaised: 41,
    detectedLabels: ["label1", "label4", "label5"],
    details: "https://example.com/dummy-image.jpg",
  },
  {
    date: "2022-02-13",
    hour: "22:30",
    alertRaised: 64,
    detectedLabels: ["label3", "label4"],
    details: "https://example.com/dummy-image.jpg",
  },
  {
    date: "2022-09-04",
    hour: "14:22",
    alertRaised: 8,
    detectedLabels: ["label1", "label2", "label4", "label5"],
    details: "https://example.com/dummy-image.jpg",
  },
];

const ViewDetail = ({ row }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <TextButton text={"View details"} width={"fit-content"} />
    </>
  );
};

const columns = [
  {
    field: "date",
    headerName: "Date",
  },
  {
    field: "hour",
    headerName: "Hour",
  },
  {
    field: "alertRaised",
    headerName: "alert raised",
  },
  {
    field: "detectedLabels",
    headerName: "Detected labels",
    renderCell: ({ value }) => {
      let excess = value?.length - 3;
      return (
        <div className="flex gap-1 items-center w-full">
          {value?.slice(0, 3)?.map((item) => {
            return (
              <p className="px-2 py-1 rounded-lg bg-[#EBEBEB] text-[#525056] text-sm">
                {item}
              </p>
            );
          })}
          {excess > 0 && (
            <Tooltip title={value?.join(" ")} arrow placement="top">
              <p className="px-2 py-1 rounded-lg bg-[#EBEBEB] text-[#447ED4] text-sm">
                +{excess}
              </p>
            </Tooltip>
          )}
        </div>
      );
    },
  },
  {
    field: "details",
    headerName: "Details",
    renderCell: ({ row }) => <ViewDetail row={row} />,
  },
];

const HistoryTable = ({ rowData = dummy }) => {
  const [rows, setRows] = useState([]);
  const headerClass =
    "text-xs font-medium text-[#525056] bg-[#ddeeff] uppercase";
  const cellClass = "text-sm font-medium text-[#3E3C42]";
  const flexMap = {
    date: 1,
    hour: 1,
    alertRaised: 1,
    detectedLabels: 1,
    details: 1,
  };
  columns.map((val, idx) => {
    val["headerClassName"] = headerClass;
    val["cellClassName"] = cellClass;
    val["flex"] = flexMap[val.field];
    val["align"] = idx == 0 ? "left" : "center";
    val["headerAlign"] = idx == 0 ? "left" : "center";
    return val;
  });

  useEffect(() => {
    setRows((prev) => {
      let newData = rowData.map((item, idx) => {
        item["id"] = idx + 1;
        return item;
      });
      return newData;
    });
  }, [rowData]);

  return (
    <div className="overflow-x-auto max-w-full">
      <ThemeProvider theme={MuiTheme}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          pagination
          pageSizeOptions={[5, 10, 25]}
          sx={{ minWidth: "1000px" }}
        />
      </ThemeProvider>
    </div>
  );
};

export default HistoryTable;
