import { CSVLink } from "react-csv";
import { utils, writeFile } from "xlsx";
import { useState, useEffect } from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuOptionGroup,
  MenuItem,
  Button,
} from "@chakra-ui/react";

export const DownloadButton = ({ order = [], data = [] }) => {
  const [exportData, setExportData] = useState([]);
  const [fileColumns, setFileColumns] = useState(order);

  useEffect(() => {
    setFileColumns(order);
  }, [order]);

  useEffect(() => {
    if (!fileColumns || !data.length) return;

    const formattedData = data.map((item) =>
      fileColumns.map((col) => item[col] ?? "")
    );

    setExportData([fileColumns, ...formattedData]);
  }, [data, fileColumns]);

  const exportAsExcel = () => {
    const wb = utils.book_new();
    const ws = utils.aoa_to_sheet(exportData);
    utils.book_append_sheet(wb, ws, "report_data");
    writeFile(wb, "report_data.xlsx");
  };

  return (
    <div className="flex items-center bg-[#6CA6FC] pr-4 pl-4 rounded h-[40px] text-white text-xs md:text-base">
      <Menu
        closeOnSelect={false}
        isLazy
      >
        <MenuButton
          as={Button}
          bgColor="#6CA6FC"
          _hover={{ bgColor: "#6CA6FC" }}
          _focus={{ bgColor: "#6CA6FC" }}
          _expanded={{ bgColor: "#6CA6FC" }}
          color="white"
          size="xs"
          fontWeight="medium"
          fontSize="sm"
        >
          Download
        </MenuButton>
        <MenuList bgColor="#6CA6FC">
          <MenuOptionGroup
            title="Format"
            bgColor="#6CA6FC"
          >
            <MenuItem
              onClick={exportAsExcel}
              bgColor="#6CA6FC"
              closeOnSelect
            >
              .xlsx
            </MenuItem>
            <MenuItem
              bgColor="#6CA6FC"
              closeOnSelect
            >
              <CSVLink
                data={exportData}
                filename="report_data.csv"
                className="p-0 w-full h-full cursor-pointer"
                target="_blank"
              >
                .csv
              </CSVLink>
            </MenuItem>
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </div>
  );
};
