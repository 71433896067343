import { Link, useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import CustomSizingBar from "../../Charts/SizingCharts/CustomSizingBar";
import { useContext, useEffect } from "react";
import { baseURL } from "../../../index";
import axios from "axios";
import NavContext from "../../NavContext";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";

export const playNotificationSound = (
  toast,
  msg = [],
  title = "Alert",
  sound
) => {
  const audio = new Audio(
    "https://intelliverse-public-videos.s3.ap-south-1.amazonaws.com/security-alarm-80493.mp3"
  );
  if (sound) {
    const playPromise = audio.play();
    audio.volume = 0.09;
    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          toast({
            title: title,
            description: (
              <div>
                {msg?.map((item, idx) => {
                  return (
                    <p>
                      {idx + 1}. {item}
                    </p>
                  );
                })}
              </div>
            ),
            status: "error",
            duration: 60 * 1000,
            isClosable: true,
            position: "top-right",
          });
        })
        .catch((error) => {
          toast({
            title: title,
            description: (
              <div>
                {msg?.map((item, idx) => {
                  return (
                    <p>
                      {idx + 1}. {item}
                    </p>
                  );
                })}
              </div>
            ),
            status: "error",
            duration: 60 * 1000,
            isClosable: true,
            position: "top-right",
          });
        });
    }
  } else
    toast({
      title: title,
      description: (
        <div>
          {msg?.map((item, idx) => {
            return (
              <p>
                {idx + 1}. {item}
              </p>
            );
          })}
        </div>
      ),
      status: "error",
      duration: 60 * 1000,
      isClosable: true,
      position: "top-right",
    });
};

export const getLiverAlerts = async (
  auth,
  toast,
  plantId,
  cameraName,
  material,
  clientId,
  sound
) => {
  try {
    const requestData = JSON.stringify({
      clientId: clientId,
      material: material,
      plantName: plantId,
      cameraId: cameraName,
      endDate: new Date().getTime(),
    });
    const response = await axios.post(
      baseURL + "vision/v2/sizing/alerts/live/",
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    if (response.data?.data?.length > 0) {
      playNotificationSound(
        toast,
        response?.data?.data[0]?.alertMessages,
        plantId + " " + cameraName,
        sound
      );
    }
  } catch (error) {
    console.log(error);
  }
};

const CamCard = ({ plantId, cameraName, data, alert, thresholds }) => {
  let param = useParams();
  const toast = useToast();
  const { auth } = useContext(NavContext);
  let material = param.material.toLowerCase();
  useEffect(() => {
    getLiverAlerts(
      auth,
      toast,
      plantId,
      cameraName,
      material,
      param?.clientId?.toLowerCase(),
      false
    );
  }, [data]);
  return (
    <div className="relative flex flex-col gap-4 pt-1 pb-4 p-6">
      {alert !== 0 && (
        <p className="absolute top-0 right-[50%] min-[1040px]:right-8 p-1 pl-2 pr-2 text-sm text-[#DC362E]">
          {alert} alert
        </p>
      )}
      <div className="flex gap-6 items-center">
        <div className="flex flex-col h-full w-[65vw] xl:w-full items-center gap-4">
          <p className=" py-1 flex gap-1 items-center  capitalize text-xs sm:text-base text-black self-start">
            {cameraName}{" "}
            {data?.bigParticlePresent ? (
              <span className="text-xs p-1 rounded border border-red-700 bg-red-100 font-semibold text-red-700">
                Big particle detected
              </span>
            ) : (
              ""
            )}
          </p>
          <div className="bg-black h-full w-full flex justify-center items-center rounded-md">
            <img
              className="h-[20vh] sm:h-[30vh] rounded-lg"
              src={data?.originalImage ?? "/noFeed.svg"}
              alt="no Support"
            />
          </div>
        </div>
        {data?.noCoal !== 1 ? (
          <div className="flex flex-col h-full items-center gap-6">
            <p className="text-xs sm:text-base text-black self-start">
              Size distribution
            </p>
            <CustomSizingBar
              size={data?.size ?? {}}
              thresholds={thresholds ?? null}
            />
          </div>
        ) : (
          <div className="h-full flex flex-col gap-8 items-center justify-center text-black font-bold text-center text-2xl min-w-[15vw]">
            <img src="/Common/noCoal.svg" className="h-[10vh]" />
            <p>No {material} on belt</p>
          </div>
        )}
      </div>
      {data?.hasOwnProperty("mpsAvg") && (
        <div className="px-4 py-1 max-w-fit flex gap-4 rounded bg-[#F75C03] text-md font-medium overflow-x-auto">
          <div className="flex gap-2 px-3 py-[2px] items-baseline">
            <p className="text-[#FFFFFF] sm:text-xl sm:font-bold">MPS</p>
          </div>
          {Object.keys(data?.mpsAvg)?.map((i) => {
            return (
              <div className="flex gap-2 px-3 py-[2px] items-baseline min-w-[150px] text-[#FFFFFF] sm:text-xl sm:font-bold">
                <p className="whitespace-nowrap">{data?.mpsAvg[i]} mm</p>
                <p>{i}</p>
              </div>
            );
          })}
        </div>
      )}
      <div className="flex flex-col gap-4 min-[400px]:flex-row justify-between">
        <Link
          to={`./${plantId}/${cameraName}`}
          style={{ textDecoration: "none" }}
        >
          {/* <button className="hover:opacity-70 text-center p-[10px] pl-4 pr-4 text-white text-xs md:text-base font-medium bg-[#084298] rounded-full">
            View detail
          </button> */}
          <PrimaryButton text={"View detail"} disable={!data} height={"40px"} />
        </Link>
        <div className="flex gap-4 items-center">
          <p className="text-sm md:text-sm text-[#79767D]">Last updated</p>
          <p className="text-sm md:text-sm text-[#79767D]">
            {new Date(data?.createdAt * 1000).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "2-digit",
            })}
            &nbsp;&nbsp;&nbsp;
            {new Date(data?.createdAt * 1000).toLocaleTimeString("en-GB")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CamCard;
