import { Input, Select, Textarea } from "@chakra-ui/react";
import FloatingInput from "../../../../util/VisionUtils/FloatingInput";

const AddClientRelation = ({
  setRelationDate,
  relationDate,
  review,
  setProductDate,
  productDate,
  selectedCurrency,
  setSelectedCurrency,
  formData,
  handleChange,
  validationErrors,
  setFieledsChange,
}) => {
  return (
    <div className="p-6 rounded-lg flex flex-col gap-3 bg-white">
      <p className="text-[#3E3C42] text-lg font-medium ">
        Client relation details
      </p>
      <div className="flex flex-col md:flex-row items-start md:items-center gap-4">
        <div>
          <p className="text-[#79767D] text-sm font-medium mb-3">
            Relation start date
          </p>
          <div>
            <FloatingInput
              text=""
              type="date"
              setDateTime={(value) => {
                setRelationDate(value);
                setFieledsChange(true);
              }}
              value={relationDate}
              disabled={review}
            />
          </div>
        </div>
        <div>
          <p className="text-[#79767D] text-sm font-medium mb-3">
            First Ripik product use date
          </p>
          <div>
            <FloatingInput
              text=""
              type="date"
              setDateTime={(value) => {
                setProductDate(value);
                setFieledsChange(true);
              }}
              value={productDate}
              disabled={review}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center gap-4">
        <div>
          <p className="text-[#79767D] text-sm font-medium mb-3">
            Deal value
            {/* <span className="text-[#AEA9B1] ml-1">(optional)</span> */}
          </p>

          <div className="flex items-center">
            <Select
              value={selectedCurrency}
              onChange={(e) => setSelectedCurrency(e.target.value)}
              // variant="filled"
              // width="fit-content"
              isDisabled={review}
            >
              <option value="₹">₹ (In Lakhs, Optional)</option>
              <option value="€">€ (In Thousands, Optional)</option>
              <option value="$">$ (In Thousands, Optional)</option>
            </Select>
            <Input
              type="number"
              name="dealValue"
              placeholder=""
              value={formData?.dealValue}
              // onChange={(e) => setDealValue(e.target.value)}
              onChange={handleChange}
              marginLeft="2"
              borderColor={validationErrors?.dealValue ? "red.500" : "gray.300"}
              borderWidth={validationErrors?.dealValue ? "2px" : "2px"}
              disabled={review}
              border={review ? "none" : ""}
            />
          </div>
          {validationErrors?.dealValue && (
            <p className="!mb-1 !text-[red] !text-sm">
              {validationErrors?.dealValue}
            </p>
          )}
        </div>
      </div>
      <div className="flex items-center gap-4">
        <div>
          <p className="text-[#79767D] text-sm font-medium mb-3">
            First purchase order code
          </p>
          <div style={{ width: "fit-content" }}>
            <Input
              type="text"
              name="purchaseOrderCode"
              value={formData?.purchaseOrderCode}
              // onChange={(e) => setPurchaseOrderCode(e.target.value)}
              onChange={handleChange}
              borderColor={
                validationErrors?.purchaseOrderCode ? "red.500" : "gray.300"
              }
              borderWidth={validationErrors?.purchaseOrderCode ? "2px" : "2px"}
              disabled={review}
              border={review ? "none" : ""}
            />
          </div>
          {validationErrors?.purchaseOrderCode && (
            <p className="!mb-1 !text-[red] !text-sm">
              {validationErrors?.purchaseOrderCode}
            </p>
          )}
        </div>
      </div>
      <div className="flex items-center gap-4">
        <div>
          <p className="text-[#79767D] text-sm font-medium mb-3">
            Remarks / additional information
            {/* <span className="text-[#AEA9B1] ml-1">(optional)</span> */}
          </p>
          <div style={{ width: "" }}>
            <Textarea
              style={{ width: "60vw" }}
              name="remarks"
              value={formData?.remarks}
              // onChange={(e) => setRemarks(e.target.value)}
              onChange={handleChange}
              borderColor={validationErrors?.remarks ? "red.500" : "gray.300"}
              borderWidth={validationErrors?.remarks ? "2px" : "2px"}
              disabled={review}
              border={review ? "none" : ""}
            />
          </div>
          {validationErrors?.remarks && (
            <p className="!mb-1 !text-[red] !text-sm">
              {validationErrors?.remarks}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddClientRelation;
