import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import NavContext from "../../NavContext";
import { useParams } from "react-router-dom";
import { IconButton, Select, Spinner } from "@chakra-ui/react";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import { FlareStackContext } from "../context/FlareStackContext";
import { CommanMultipleSelect, CommanSingleSelect } from "./CommanSelectBox";
import { getPhotoGalleryDataApi } from "../services/flarestack.api";
import Paginator from "../../../util/VisionUtils/Paginator";
import {
  formatDateTimeLocale,
  getCameraOptions,
  getFlareTypeOptions,
} from "../utilis/utilityFunctions";
import { LibraryGridSkeleton, NoData } from "./Loading";
import { PreviewImageModal } from "./PreviewImageModal";
import { AspectRatio, DownloadForOfflineOutlined } from "@mui/icons-material";
import { saveAs } from "file-saver";

const LibraryGrid = ({ plantName, data }) => {
  // state
  const [displayData, setDisplayData] = useState([]);
  const [openZoomModal, setOpenZoomModal] = useState({
    open: false,
    index: null,
  });

  const handlePreviewImage = (index) => {
    setOpenZoomModal((prev) => ({ ...prev, open: true, index }));
  };

  const handleImageDownload = async (index) => {
    try {
      const singleImageData = displayData[index];
      const lastSlashIndex = singleImageData?.image?.lastIndexOf("/");
      const imageName = singleImageData?.image?.substr(lastSlashIndex + 1);
      const response = await fetch(singleImageData?.image);
      const blob = await response.blob();
      saveAs(blob, `${imageName}`);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  useEffect(() => {
    if (data.length === 0) setDisplayData([]);
  }, [data]);

  return (
    <div className="flex flex-col gap-3 pb-4">
      <div className="flex justify-between items-baseline">
        <div className="flex items-baseline gap-2">
          <p className="font-medium text-[#3E3C42] text-xl capitalize">
            {plantName}
          </p>
        </div>
        <div className="flex sm:flex-row flex-col items-center gap-1">
          {data.length !== 0 && (
            <Paginator
              data={data}
              limit={20}
              setDisplayData={setDisplayData}
            />
          )}
        </div>
      </div>

      <div className="gap-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 max-h-[80vh] overflow-y-scroll">
        {displayData?.map((element, index) => {
          return (
            <div
              key={`image-${index}`}
              className="relative w-full max-h-[350px] text-center group"
            >
              {/* Original Image */}
              <img
                className="rounded-lg w-full h-[350px]"
                src={element.image}
                alt="img"
              />

              {/* content to show over image */}
              {element?.cameraName && (
                <div className="top-2 left-0 absolute flex flex-row items-center gap-1 px-2 hover:">
                  <IconButton
                    size={"xs"}
                    backgroundColor={"transparent"}
                    transition={"ease-in-out"}
                    _hover={{ cursor: "pointer", scale: 110 }}
                    _active={{ backgroundColor: "transparent" }}
                    color={"white"}
                    className="transform transition-transform duration-300 hover:scale-110"
                    icon={<AspectRatio sx={{ fontSize: "26px" }} />}
                    onClick={() => handlePreviewImage(index)}
                  />

                  <p className="bg-black px-2 py-[3px] rounded-[4px] font-semibold text-white text-xs">
                    {element?.cameraName}
                  </p>
                </div>
              )}
              <div className="top-2 right-0 absolute flex flex-col items-end gap-2 px-2">
                <div className="flex flex-col items-end gap-2">
                  <p className="bg-black px-2 py-[3px] rounded-[4px] font-semibold text-white text-xs">
                    {element?.timeStamp &&
                      formatDateTimeLocale(element?.timeStamp)}
                  </p>
                  <IconButton
                    size={"xs"}
                    rounded={"full"}
                    _hover={{ cursor: "pointer", scale: 110 }}
                    color={"gray.400"}
                    className="transform transition-transform duration-300 hover:scale-110"
                    icon={<DownloadForOfflineOutlined />}
                    onClick={() => handleImageDownload(index)}
                  />
                </div>
              </div>
              <div className="right-0 bottom-2 absolute items-end gap-2 px-2">
                {element?.flareVolume && (
                  <p className="bg-black px-2 py-[3px] rounded-[4px] font-semibold text-white text-xs">
                    {element?.flareVolume}
                  </p>
                )}
              </div>
            </div>
          );
        })}
      </div>
      {openZoomModal.open && openZoomModal.index !== null && (
        <PreviewImageModal
          imageIndex={openZoomModal.index}
          openModal={openZoomModal.open}
          data={displayData}
          closeModal={() => {
            setOpenZoomModal((prev) => ({ ...prev, open: false, index: null }));
          }}
        />
      )}
    </div>
  );
};

export const PhotoGallery = ({ plantId }) => {
  // params refs and variables

  const { auth } = useContext(NavContext);
  const { initialData } = useContext(FlareStackContext);
  let param = useParams();

  // state
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [showType, setShowType] = useState("today");

  const [filterData, setFilterData] = useState({
    plant: plantId,
    flareStack: initialData?.[0]?.cameraGpId || "",
    cameraType: [initialData?.[0]?.cameraInfo?.[0]?.cameraId],
    flareType: ["veryHighFlare"],
    date: new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10),
  });

  // filter updation
  const handleFilters = (value, name) => {
    setFilterData((prev) => ({ ...prev, [name]: value }));
  };

  // handling showing date input
  const handleSelect = (e) => {
    let val = e.target.value;
    setShowType(val);
    if (val === "today") {
      handleFilters(new Date().toISOString().slice(0, 10), "date");
    } else if (val === "yesterday") {
      handleFilters(
        new Date(new Date() - 24 * 60 * 60 * 1000).toISOString().slice(0, 10),
        "date"
      );
    }
  };

  // to be called on changing filter's/ first load
  const fetchPhotoGalleryData = async () => {
    try {
      const requestData = JSON.stringify({
        clientId: param.clientId.toLowerCase(),
        useCase: "FLARESTACK",
        plantName: filterData.plant,
        cameraId: filterData.cameraId,
        startDate: new Date(filterData.date).getTime(),
        filters: filterData.filters,
        sortType: filterData.sort,
      });
      const response = await getPhotoGalleryDataApi(
        auth,
        requestData,
        setLoading
      );
      setData((prev) => response);
    } catch (error) {
      console.error(error);
      setData((prev) => null);
    }
  };

  const flareTypeOptions = useMemo(() => getFlareTypeOptions(), []);

  useEffect(() => {
    fetchPhotoGalleryData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col gap-6 bg-white rounded-lg">
      <div className="flex items-center gap-4 px-1 pt-5 pb-1 overflow-x-auto">
        {/* plant selector */}
        {/* <div className="min-w-[110px]">
          <Select
            borderColor={"#CAC5CD"}
            placeholder={disable && capitalizeFirstLetter(filterData.plant)}
            variant="outline"
            isDisabled={disable}
            fontSize={"sm"}
            fontWeight={"medium"}
            textColor={"#605D64"}
            rounded={"base"}
            name="selectedPlant"
            onChange={(event) => handleFilters(event.target.value, "plant")}
            value={filterData.plant}
          >
            {!disable &&
              Object.keys(plantCamMap).map((plant) => {
                return (
                  <option
                    key={plant}
                    value={plant}
                    style={{ textTransform: "capitalize" }}
                  >
                    {capitalizeFirstLetter(plant)}
                  </option>
                );
              })}
          </Select>
        </div> */}

        <div className="w-[200px] min-w-[200px]">
          <CommanSingleSelect
            label={"Flare Stack"}
            options={initialData?.map((el) => ({
              name: el.cameraGpName,
              value: el.cameraGpId,
            }))}
            value={filterData.flareStack}
            handleChange={(event) => {
              const {
                target: { value },
              } = event;
              handleFilters(value, "flareStack");
            }}
          />
        </div>
        <div className="w-[200px] min-w-[200px]">
          <CommanMultipleSelect
            label={"Camera Type"}
            value={filterData.cameraType}
            options={getCameraOptions(filterData.flareStack, initialData)}
            handleChange={(event, obj) => {
              const {
                target: { value },
              } = event;
              if (typeof value === "string") {
                handleFilters(value.split(","), "cameraType");
              } else {
                if (value?.length > 0) {
                  handleFilters(value, "cameraType");
                }
              }
            }}
          />
        </div>
        <div className="w-[200px] min-w-[200px]">
          <CommanMultipleSelect
            label={"Flare Type"}
            options={flareTypeOptions}
            value={filterData.flareType}
            handleChange={(event, obj) => {
              const {
                target: { value },
              } = event;
              if (typeof value === "string") {
                handleFilters(value.split(","), "flareType");
              } else {
                if (value?.length > 0) {
                  handleFilters(value, "flareType");
                }
              }
            }}
          />
        </div>
        <div className="min-w-[200px]">
          <Select
            borderColor={"#CAC5CD"}
            variant="outline"
            fontSize={"sm"}
            fontWeight={"medium"}
            textColor={"#605D64"}
            rounded={"base"}
            onChange={(e) => handleSelect(e)}
            value={showType}
          >
            <option value={"today"}>Today</option>
            <option value={"yesterday"}>Yesterday</option>
            <option value={"custom"}>Custom</option>
          </Select>
        </div>

        {showType === "custom" && (
          <div className="min-w-[200px]">
            <FloatingInput
              text="Date"
              type="date"
              setDateTime={(value) => handleFilters(value, "date")}
              value={filterData.date}
              max={new Date().toISOString().slice(0, 10)}
            />
          </div>
        )}
        <SecondaryButton
          height={"40px"}
          width={"80px"}
          text={loading ? <Spinner /> : "Apply"}
          disable={!filterData.date || loading}
          onClick={fetchPhotoGalleryData}
        />
      </div>
      {loading ? (
        <LibraryGridSkeleton />
      ) : data && data?.length > 0 ? (
        <LibraryGrid
          plantName={"VijayNagar"}
          data={data}
        />
      ) : (
        <div className="w-full h-[350px]">
          <NoData />
        </div>
      )}
    </div>
  );
};
