import React from "react";
import { useContext, useEffect, useState } from "react";
import NavContext from "../../NavContext";
import axios from "axios";
import { baseURL } from "../../..";
import { Spinner } from "@chakra-ui/react";
import DeviceGroupsTable from "../Devices/Components/DeviceGroupsTable";
import DevicesTable from "../Devices/Components/DevicesTable";

const DevicesTablePage = ({
  setShowDevicesTab,
  deviceType,
  setSelectedDevice,
  devices,
  getDeviceApi,
  getDeviceGroupApi,
  deviceGroups
}) => {
  const { auth } = useContext(NavContext);
  const [loading, setLoading] = useState(false);

  // const devices = [
  //   {
  //     product: "Camera",
  //     tool: 2, //devices
  //     deployments: 100,
  //     clients: 4, //locations
  //     tools: "Infrared Camera, Image Camera, Infrared Camera, Image Camera",
  //     deviceGroup: "Camera",
  //     make: "CP Plus",
  //     serialNumber: "SMPH00123",
  //     location: "Ahmedabad",
  //     id: 7,
  //   },
  //   {
  //     product: "Camera",
  //     tool: 2, //devices
  //     deployments: 100,
  //     clients: 4, //locations
  //     tools: "Infrared Camera, Image Camera, Infrared Camera, Image Camera",
  //     deviceGroup: "Camera",
  //     make: "CP Plus",
  //     serialNumber: "SMPH00123",
  //     location: "Ahmedabad",
  //     id: 6,
  //   },
  //   {
  //     product: "Camera",
  //     tool: 2, //devices
  //     deployments: 100,
  //     clients: 4, //locations
  //     tools: "Infrared Camera, Image Camera, Infrared Camera, Image Camera",
  //     deviceGroup: "Camera",
  //     make: "CP Plus",
  //     serialNumber: "SMPH00123",
  //     location: "Ahmedabad",
  //     id: 5,
  //   },
  //   {
  //     product: "Camera",
  //     tool: 2, //devices
  //     deployments: 100,
  //     clients: 4, //locations
  //     tools: "Infrared Camera, Image Camera, Infrared Camera, Image Camera",
  //     deviceGroup: "Camera",
  //     make: "CP Plus",
  //     serialNumber: "SMPH00123",
  //     location: "Ahmedabad",
  //     id: 1,
  //   },
  //   {
  //     product: "Camera",
  //     tool: 2, //devices
  //     deployments: 100,
  //     clients: 4, //locations
  //     tools: "Infrared Camera, Image Camera, Infrared Camera, Image Camera",
  //     deviceGroup: "Camera",
  //     make: "CP Plus",
  //     serialNumber: "SMPH00123",
  //     location: "Ahmedabad",
  //     id: 4,
  //   },
  //   {
  //     product: "Camera",
  //     tool: 2, //devices
  //     deployments: 100,
  //     clients: 4, //locations
  //     tools: "Infrared Camera, Image Camera, Infrared Camera, Image Camera",
  //     deviceGroup: "Camera",
  //     make: "CP Plus",
  //     serialNumber: "SMPH00123",
  //     location: "Ahmedabad",
  //     id: 3,
  //   },
  //   {
  //     product: "Camera",
  //     deviceGroup: "Camera",
  //     make: "CP Plus",
  //     serialNumber: "SMPH00123",
  //     location: "Ahmedabad",
  //     id: 2,
  //     tool: 2, //devices
  //     deployments: 100,
  //     clients: 4, //locations
  //     tools: "Infrared Camera, Image Camera, Infrared Camera, Image Camera",
  //   },
  //   {
  //     product: "Camera",
  //     tool: 2, //devices
  //     deployments: 100,
  //     clients: 4, //locations
  //     tools: "Infrared Camera, Image Camera, Infrared Camera, Image Camera",
  //     deviceGroup: "Camera",
  //     make: "CP Plus",
  //     serialNumber: "SMPH00123",
  //     location: "Ahmedabad",
  //     id: 1,
  //   },
  // ];

  // const deviceGroups = [
  //   {
  //     product: "Camera",
  //     tool: 2, //devices
  //     deployments: 100,
  //     clients: 4, //locations
  //     tools: "Infrared Camera, Image Camera, Infrared Camera, Image Camera",
  //     devices: "Infrared Camera, Image Camera, Infrared Camera, Image Camera",
  //     locations: "Ahmedabad, Surat, Mumbai, Pune",
  //     id: 1,
  //   },
  //   {
  //     product: "Camera",
  //     tool: 2, //devices
  //     deployments: 100,
  //     clients: 4, //locations
  //     tools: "Infrared Camera, Image Camera, Infrared Camera, Image Camera",
  //     devices: "Infrared Camera, Image Camera, Infrared Camera, Image Camera",
  //     locations: "Ahmedabad, Surat, Mumbai, Pune",
  //     id: 2,
  //   },
  //   {
  //     product: "Camera",
  //     tool: 2, //devices
  //     deployments: 100,
  //     clients: 4, //locations
  //     tools: "Infrared Camera, Image Camera, Infrared Camera, Image Camera",
  //     devices: "Infrared Camera, Image Camera, Infrared Camera, Image Camera",
  //     locations: "Ahmedabad, Surat, Mumbai, Pune",
  //     id: 3,
  //   },
  //   {
  //     product: "Camera",
  //     tool: 2, //devices
  //     deployments: 100,
  //     clients: 4, //locations
  //     tools: "Infrared Camera, Image Camera, Infrared Camera, Image Camera",
  //     devices: "Infrared Camera, Image Camera, Infrared Camera, Image Camera",
  //     locations: "Ahmedabad, Surat, Mumbai, Pune",
  //     id: 4,
  //   },
  //   {
  //     product: "Camera",
  //     tool: 2, //devices
  //     deployments: 100,
  //     clients: 4, //locations
  //     tools: "Infrared Camera, Image Camera, Infrared Camera, Image Camera",
  //     devices: "Infrared Camera, Image Camera, Infrared Camera, Image Camera",
  //     locations: "Ahmedabad, Surat, Mumbai, Pune",
  //     id: 5,
  //   },
  //   {
  //     product: "Camera",
  //     tool: 2, //devices
  //     deployments: 100,
  //     clients: 4, //locations
  //     tools: "Infrared Camera, Image Camera, Infrared Camera, Image Camera",
  //     devices: "Infrared Camera, Image Camera, Infrared Camera, Image Camera",
  //     locations: "Ahmedabad, Surat, Mumbai, Pune",
  //     id: 6,
  //   },
  //   {
  //     product: "Camera",
  //     tool: 2, //devices
  //     deployments: 100,
  //     clients: 4, //locations
  //     tools: "Infrared Camera, Image Camera, Infrared Camera, Image Camera",
  //     devices: "Infrared Camera, Image Camera, Infrared Camera, Image Camera",
  //     locations: "Ahmedabad, Surat, Mumbai, Pune",
  //     id: 7,
  //   },
  //   {
  //     product: "Camera",
  //     tool: 2, //devices
  //     deployments: 100,
  //     clients: 4, //locations
  //     tools: "Infrared Camera, Image Camera, Infrared Camera, Image Camera",
  //     devices: "Infrared Camera, Image Camera, Infrared Camera, Image Camera",
  //     locations: "Ahmedabad, Surat, Mumbai, Pune",
  //     id: 8,
  //   },
  // ];

  return (
    <div className="p-4 bg-[#fafafa]">
      {deviceGroups && deviceGroups.length !== 0 && deviceType == "Groups" && (
        <DeviceGroupsTable
          deviceGroups={deviceGroups}
          setShowDevicesTab={setShowDevicesTab}
          setSelectedDevice={setSelectedDevice}
          getDeviceGroupApi={getDeviceGroupApi}
        />
      )}

      {devices && devices.length !== 0 && deviceType == "Devices" && (
        <DevicesTable
          devices={devices}
          setShowDevicesTab={setShowDevicesTab}
          setSelectedDevice={setSelectedDevice}
          getDeviceApi={getDeviceApi}
        />
      )}
    </div>
  );
};

export default DevicesTablePage;
