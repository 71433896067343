import { IconButton } from "@chakra-ui/react";
import { ThemeProvider, Tooltip, createTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { styled } from "@mui/material/styles";
import { status } from "../../utils/common";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
const MuiTheme = createTheme();

// Create a custom style for the Tooltip
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  "& .MuiTooltip-tooltip": {
    backgroundColor: "white",
    color: "black",
    boxShadow: theme.shadows[1],
    fontSize: 12,
    borderRadius: 0,
  },
}));

const AlertGroupTable = ({
  alertGroups,
  setShowAlertsTab,
  setSelectedAlert,
  getAlertGroupApi,
}) => {
  const columns = [
    {
      field: "alertGroupName",
      headerName: (
        <div className="flex gap-1">
          <img src="/superadmin/tableBell.svg" alt="" />
          <p className="ml-1 capitalize">alert group name</p>
        </div>
      ),
    },
    {
      field: "alertTypeGroup",
      headerName: (
        <div className="flex gap-1">
          <img src="/productGroup.svg" alt="" />
          <p className="ml-1 capitalize">alert group</p>
        </div>
      ),
      renderCell: ({ row }) => {
        return row?.alertTypeGroup ? (
          <CustomTooltip title={row?.alertTypeGroup} placement="bottom-end">
            <p>{row?.alertTypeGroup}</p>
          </CustomTooltip>
        ) : (
          <p>N/A</p>
        );
      },
    },
    {
      field: "alertType",
      headerName: (
        <div className="flex gap-1">
          <img src="/superadmin/clients/prodCount.svg" alt="" />
          <p className="ml-1 capitalize">alert type</p>
        </div>
      ),
    },

    {
      field: "alertTypeNameProduct",
      headerName: (
        <div className="flex gap-1">
          <img src="/toolsIcon.svg" alt="" />
          <p className="ml-1 capitalize">product</p>
        </div>
      ),
      renderCell: ({ row }) => {
        return row?.alertType === "product" ? (
          <CustomTooltip title={row?.alertTypeName} placement="bottom-end">
            <p className=" whitespace-nowrap overflow-hidden overflow-ellipsis">
              {row?.alertTypeName}
            </p>
          </CustomTooltip>
        ) : (
          "N/A"
        );
      },
    },
    {
      field: "alertTypeNameDevice",
      headerName: (
        <div className="flex gap-1">
          <img src="/toolsIcon.svg" alt="" />
          <p className="ml-1 capitalize">Device</p>
        </div>
      ),
      renderCell: ({ row }) => {
        return row?.alertType === "device" ? (
          <CustomTooltip title={row?.alertTypeName} placement="bottom-end">
            <p>{row?.alertTypeName}</p>
          </CustomTooltip>
        ) : (
          "N/A"
        );
      },
    },

    {
      field: "createdAt",
      headerName: (
        <div className="flex  gap-1">
          <img src="/superadmin/clients/calender.svg" alt="" />
          <p className="ml-1 capitalize">created On</p>
        </div>
      ),
      valueFormatter: (params) => {
        return params.value
          ? new Date(params.value).toDateString().split(" ")[2] +
              " " +
              new Date(params.value).toDateString().split(" ")[1] +
              " '" +
              new Date(params.value).toDateString().split(" ")[3].slice(2, 4) +
              " " +
              new Date(params.value).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              })
          : "";
      },
    },
    {
      field: "updatedAt",
      headerName: (
        <div className="flex gap-1">
          <img src="/superadmin/clients/calender2.svg" alt="" />
          <p className="ml-1 capitalize">updated on</p>
        </div>
      ),
      valueFormatter: (params) => {
        return params.value
          ? new Date(params.value).toDateString().split(" ")[2] +
              " " +
              new Date(params.value).toDateString().split(" ")[1] +
              " '" +
              new Date(params.value).toDateString().split(" ")[3].slice(2, 4) +
              " " +
              new Date(params.value).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
              })
          : "";
      },
    },
    {
      field: "status",
      headerName: (
        <div className="flex gap-1">
          <img src="/superadmin/clients/timer.svg" alt="" />
          <p className="ml-1 capitalize">status</p>
        </div>
      ),
      renderCell: ({ row }) => {
        return (
          <div
            className={`flex items-center ${
              row?.status === "DRAFT" ? "gap-1.5" : "gap-1"
            }`}
          >
            {/* {row?.status === "DRAFT" ? ( */}
            {row?.status === "DRAFT" ? (
              <img
                src={`/Common/draft.svg`}
                className="w-5 h-5 rounded-full"
                alt="status"
              />
            ) : row.status === "ACTIVE" ? (
              <CheckCircleIcon sx={{
                width: "20px",
                height: "20px",
                borderRadius: "9999px",
                color: "green",
              }} />
            ) : (
              <CancelIcon sx={{
                width: "20px",
                height: "20px",
                borderRadius: "9999px",
                color: "#E46962",
              }} />
            )}
            <p className="capitalize">{row?.status?.toLowerCase()}</p>
          </div>
        );
      },
    },
    {
      field: "view",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,

      renderCell: ({ row }) => (
        <IconButton onClick={() => handleViewClick(row)} color="#2196F3">
          <VisibilityIcon />
        </IconButton>
      ),
    },
    {
      field: "update",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <div>
            <IconButton
              aria-label="edit"
              color={row?.status === "INACTIVE" ? "gray.400" : "#1E88E5"}
              onClick={() => {
                handleUpdateClick(row);
              }}
              isDisabled={row.status === "INACTIVE"}
            >
              <EditNoteIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const handleViewClick = (row) => {
    setSelectedAlert(row);
    setShowAlertsTab((prev) => "viewAlertGroup");
  };

  const handleUpdateClick = (row) => {
    setSelectedAlert(row);
    setShowAlertsTab((prev) => "updateAlertGroup");
  };

  const headerClass = "text-sm font-normal text-[#79767D] bg-[#DEF] capitalize";
  const cellClass = "text-sm font-normal text-[#3E3C42] whitespace-nowrap";
  const flexMap = [2, 1.5, 1.5, 1.5, 1.5, 1.5, 1.5, 1, 0.5, 0.5];
  columns.map((val, idx) => {
    val["headerClassName"] = headerClass;
    val["cellClassName"] = cellClass;
    val["flex"] = flexMap[idx];
  });

  return (
    <div className="overflow-x-auto mt-2 w-full">
      <ThemeProvider theme={MuiTheme}>
        <DataGrid
          rows={alertGroups}
          columns={columns}
          getRowId={(row) => row?.alertGroupId}
          initialState={{
            pagination: { paginationModel: { pageSize: 8 } },
          }}
          sx={{ minWidth: "1000px" }}
        />
      </ThemeProvider>
    </div>
  );
};

export default AlertGroupTable;
