import { useWindowSize } from "@uidotdev/usehooks";
import DailyOutput from "./DailyOutput";
import WeeklyOutput from "./WeeklyOutput";

function OverallOutput({ dailyOutput, stateForBarChart }) {
  const size = useWindowSize();
  return (
    <div className="w-full h-full">
      <div
        className={`flex ${
          size.width < 768 ? "flex-col h-[80px]" : " h-[40px] "
        } text-start w-full  items-center gap-3`}
      >
        <p className="text-[#000] text-base">Overall Output</p>
        <p className="text-[#79767D] text-base"> A summary of the production</p>
      </div>
      <div
        className={`w-full flex ${size.width < 768 ? "flex-col gap-3" : ""}`}
      >
        <div className={` ${size.width < 768 ? "w-full" : "w-[30%] "}`}>
          <DailyOutput dailyOutput={dailyOutput} />
        </div>
        <div className={` ${size.width < 768 ? "w-full" : "w-[70%] "}`}>
          <WeeklyOutput stateForBarChart={stateForBarChart} />
        </div>
      </div>
    </div>
  );
}

export default OverallOutput;
