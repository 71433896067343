import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useEffect, useRef, useState } from "react";

const MuiTheme = createTheme();

const AllotTokenTable = ({
  clientOrg,
  rowData,
  handleAddButtonClick,
  handleAddToken,
  addTokensArray,
  mode,
  selectedPage,
  setSelectedPage,
}) => {
  const CustomCell = ({ rowIndex, value, user, userName }) => {
    const inputRef = useRef();
    return (
      <div className="w-full flex justify-start gap-2">
        <input
          type="number"
          className="border w-12 h-8 text-[#605D64] pl-2 pr-5 rounded-sm"
          defaultValue={20}
          ref={inputRef}
          style={{ width: "70px" }}
        />
        <button
          className="!text-[#3A74CA] !font-semibold text-[14px]"
          onClick={() => {
            handleAddToken(
              rowIndex,
              Number(inputRef.current.value),
              user,
              userName
            );
          }}
        >
          Add
        </button>
      </div>
    );
  };

  const updatedData = rowData.map((item, id) => ({
    ...item,
    addToken: 20, // Add the "addToken" property with a default value of 0
  }));
  const columns = [
    {
      field: "userId",
      headerName: "user id",
      hide: true,
    },
    {
      field: "userName",
      headerName: "User Name",
    },

    {
      field: "email",
      headerName: "email",
    },
    {
      field: "tokenBalance",
      headerName: "Token Remaining",
      renderCell: (params) => (
        <div className="w-full !text-left flex gap-1 !text-[14px]">
          <p className="!text-left">{params.value}</p>
          <img src="/token.svg" alt="token" />
        </div>
      ),
    },
    {
      field: "addToken",
      headerName: "Add Token",
      renderCell: (params) =>
        mode !== "view" ? (
          <CustomCell
            rowIndex={params.row.idx}
            value={addTokensArray[params.row.idx]}
            user={params.row.userId}
            userName={params.row.userName}
          />
        ) : null,
    },
  ];
  const headerClass =
    "text-sm font-normal text-[#79767D] bg-[#DDEEFF] capitalize";
  const cellClass = "text-sm font-normal text-[#3E3C42] whitespace-normal";
  const flexMap = clientOrg
    ? [0, 1, 2, 1, 1, 3, 1.5]
    : [0, 1.5, 2, 1.5, 2, 3, 1];
  columns.map((val, idx) => {
    val["headerClassName"] = headerClass;
    val["cellClassName"] = cellClass;
    val["flex"] = flexMap[idx];
  });
  const handlePageChange = (params) => {
    setSelectedPage(params.page);
  };

  return (
    <div className="mt-2 overflow-auto">
      <ThemeProvider theme={MuiTheme}>
        <DataGrid
          rows={rowData}
          columns={columns}
          getRowId={(row) => row.userId}
          getRowHeight={() => {
            return "37px";
          }}
          onPaginationModelChange={(param) => handlePageChange(param)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: mode == "view" ? 4 : 7,
                page: selectedPage,
              },
            },
          }}
          columnVisibilityModel={{
            userId: false,
            addToken: mode != "view" ? true : false,
          }}
          hideFooter={false}
          rowsPerPageOptions={[mode == "view" ? 4 : 7, 10, 20]}
          sx={{ minWidth: "1000px", margin: 0, padding: 0 }}
        />
      </ThemeProvider>
    </div>
  );
};

export default AllotTokenTable;
