import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Radio,
  RadioGroup,
  useToast,
  ChakraProvider,
  Tooltip,
} from "@chakra-ui/react";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import { CalF1 } from "../pages/DetailView";
import JSZip from "jszip";
import axios from "axios";
import { baseURL } from "../../..";
import NavContext from "../../NavContext";
import CommonProgressBar from "./CommonProgressBar";
import { useContext, useEffect, useState } from "react";

const InfoDetailModal = ({ openModal, closeModal, row }) => {
  const { auth } = useContext(NavContext);
  const [imgData, setImgData] = useState([]);
  const [requested, setRequested] = useState(false);
  const [progressState, setProgressState] = useState({
    open: false,
    state: null,
  });
  const toast = useToast();
  const Download = async (val) => {
    let a;
    setRequested(true);
    switch (val) {
      case "Model":
        a = document.createElement("a");
        a.href = row?.modelLink;
        a.download = `${row?.projectId + row?.trainingId}.pt`;
        a.click();
        setRequested(false);
        break;
      case "Zip":
        downloadApi();
        break;
      case "Doc":
        a = document.createElement("a");
        a.href =
          "https://intelliverse-public-videos.s3.ap-south-1.amazonaws.com/API+Documentation+-+Ripik+AI+Sandbox.pdf";
        a.download = `ApiDoc.pdf`;
        a.click();
        setRequested(false);
        break;
      default:
        console.log("whoops");
        break;
    }
  };

  const getDetail = async () => {
    try {
      const param = {
        projectId: row?.projectId,
        version: row?.version,
      };
      const resposne = await axios.get(
        baseURL + "selfserve/v1/project/v1/getSingle/",
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (resposne.data) {
        setImgData((prev) => {
          let data = resposne.data;
          let val = Object.keys(data?.details[0]?.dataset || {})[0];
          let newData = data?.details[0]?.dataset?.[val] || {};
          let formattedData = Object.values(newData);
          return formattedData;
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadApi = async () => {
    try {
      const param = {
        projectId: row?.projectId,
        trainingId: row?.trainingId,
      };
      const response = await axios.get(
        baseURL + "selfserve/v1/project/v1/training/downloadDataset/",
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.data) {
        let data = response.data;
        if (data.hasOwnProperty("localDownload")) {
          if (data?.localDownload) {
            handleDownload();
          } else {
            toast({
              title: "Requested",
              description:
                "You will get an email with zip file link that contains the dataset",
              status: "success",
              position: "top-right",
              duration: 6000,
              isClosable: true,
            });
            setRequested(false);
          }
        } else handleDownload();
      }
    } catch (error) {
      console.log(error);
      setRequested(false);
      if (error.response.status == 429) {
        toast({
          title: "Warning",
          description: error.response?.data?.message,
          status: "warning",
          position: "top-right",
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  useEffect(() => {
    getDetail();
  }, []);

  const handleDownload = async () => {
    setProgressState((prev) => ({ ...prev, open: true, state: 1 }));
    const promise = new Promise(async (resolve, reject) => {
      try {
        const imageLinks = imgData;
        const jsonData = JSON.stringify(row?.predictions, null, 2);
        const blob = new Blob([jsonData], { type: "application/json" });

        const imageRequests = imageLinks.map((link) =>
          axios.get(link + `?${performance.now()}`, {
            responseType: "arraybuffer",
          })
        );
        const [imageResponses] = await Promise.all([
          Promise.all(imageRequests),
        ]);
        const zip = new JSZip();
        imageResponses.forEach((response, index) => {
          zip.file(`image_${index + 1}.jpg`, response.data, {
            binary: true,
          });
        });
        zip.file(`predictions.json`, blob, {
          binary: true,
        });
        const zipContent = await zip.generateAsync({ type: "blob" });

        // Create a download link and trigger the download
        const downloadLink = document.createElement("a");
        downloadLink.href = URL.createObjectURL(zipContent);
        downloadLink.download = `Data-${row?.projectId}.zip`;
        downloadLink.click();
        resolve(200);
        setProgressState((prev) => ({ ...prev, state: 2 }));
        setRequested(false);
      } catch (error) {
        console.error("Error downloading files:", error);
        reject();
        setProgressState((prev) => ({ ...prev, state: 0 }));
        setRequested(false);
      }
    });
  };
  return (
    <ChakraProvider>
      <Modal
        closeOnOverlayClick={false}
        isOpen={openModal}
        onClose={closeModal}
        isCentered="true"
      >
        <ModalOverlay />
        <ModalContent style={{ borderRadius: "8px" }} maxW="360px">
          <ModalHeader
            py={"8px"}
            px={"16px"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            borderBottom={"1px solid #EBE2F0"}
          >
            <p className="text-[#605D64] text-sm font-medium">Model detail</p>
            <CloseIcon
              className="cursor-pointer hover:scale-105"
              onClick={closeModal}
              sx={{ color: "#605D64" }}
            />
          </ModalHeader>
          <ModalBody
            px={"24px"}
            py={"16px"}
            display={"flex"}
            flexDirection={"column"}
            gap={"32px"}
          >
            <div className="flex flex-col gap-2">
              <div className="grid grid-cols-2 items-center">
                <p className="text-[#938F96] w-[200px]">Last updated</p>
                <p className="text-[#3E3C42] font-medium">
                  {new Date(row?.lastUpdatedAt).toLocaleString()}
                </p>
                <p className="text-[#938F96] w-[200px]">Model service</p>
                <p className="text-[#3E3C42] font-medium">
                  {row?.projectDetail?.modelService?.includes("AZURE")
                    ? "Microsoft Azure"
                    : "Ripik"}
                </p>
              </div>
              <div className="grid grid-cols-2 items-center">
                <p className="text-[#938F96] w-[200px]">Precision</p>
                <p className="text-[#3E3C42] font-medium">
                  {Math.round(row?.performance?.precision * 100)}%
                </p>
                <p className="text-[#938F96] w-[200px]">Recall</p>
                <p className="text-[#3E3C42] font-medium">
                  {Math.round(row?.performance?.recall * 100)}%
                </p>
                <p className="text-[#938F96] w-[200px]">F1 score</p>
                <p className="text-[#3E3C42] font-medium">
                  {CalF1(row?.performance?.precision, row?.performance?.recall)}
                </p>
                {row?.projectDetail?.modelService?.includes("AZURE") && (
                  <p className="text-[#938F96] whitespace-normal text-xs col-span-2 mt-2">
                    Microsoft azure models aren't downloadable, deploy and use
                    api instead.
                  </p>
                )}
              </div>
            </div>
            <div className="flex gap-2 items-center justify-end">
              {row?.projectDetail?.modelService?.includes("AZURE") ? (
                <SecondaryButton
                  text={"Doc"}
                  Icon={<DownloadIcon />}
                  width={"fit-content"}
                  onClick={() => Download("Doc")}
                  disable={
                    !row?.hasOwnProperty("publishStatus") ||
                    row?.publishStatus != "COMPLETED"
                  }
                />
              ) : (
                <SecondaryButton
                  text={"Model"}
                  Icon={<DownloadIcon />}
                  width={"fit-content"}
                  onClick={() => Download("Model")}
                  disable={!row?.modelLink || requested}
                />
              )}
              <SecondaryButton
                text={"Data"}
                Icon={<DownloadIcon />}
                width={"fit-content"}
                onClick={() => Download("Zip")}
                disable={!row?.predictions || requested}
              />
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
      {progressState?.open && progressState?.state && (
        <CommonProgressBar
          openModal={progressState?.open}
          closeModal={() =>
            setProgressState((prev) => ({
              ...prev,
              open: false,
              state: null,
            }))
          }
          state={progressState?.state}
        />
      )}
    </ChakraProvider>
  );
};

export default InfoDetailModal;
