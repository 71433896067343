import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  IconButton,
  Badge,
  Switch,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";

import PrimaryButton from "../../../../util/Buttons/PrimaryButton";
import TonalButton from "../../../../util/Buttons/TonalButton";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../../../index";
import NavContext from "../../../NavContext";
import axios from "axios";
import { blue } from "@mui/material/colors";
import { Tooltip } from "@chakra-ui/react";
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import { utils, writeFile } from "xlsx";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ProductCard = ({
  data,
  getProducts,
  setSelectedProductGroup,
  setShowProductTab,
  getProductGroups
}) => {

  console.log(data);

  const { auth } = useContext(NavContext);
  const toast = useToast();
  const navigate = useNavigate();
  const [isSwitchOn, setIsSwitchOn] = useState(data?.product.status === "INACTIVE");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [vision, setVision] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [optimus, setOptimus] = useState(0);
  const [productCategory, setProductCategory] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const updateProducts = async (flag, status) => {

    const data1 = {
      productId: data.product.productId,
      isdeleted: flag,
      status: status,
    }
    const json = JSON.stringify(data1);
    const blob = new Blob([json], {
      type: "application/json",
    });
    const FormData = require("form-data");
    let datax = new FormData();
    datax.append("data", blob);

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: baseURL + "product/v1/update/product",
      headers: {
        "X-Auth-Token": auth,
      },
      data: datax,
    };
    axios
      .request(config)
      .then((response) => {
        getProducts();
        getProductGroups();
      })
      .catch((error) => {
        //console.log(error);
        toast({
          title: "Something went wrong",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
      });

  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmDiscard = async () => {
    setIsSwitchOn(!isSwitchOn);
    await updateProducts(true, 2);
    //getProducts();
    //fetchClientsFun();
    setIsModalOpen(false);
    setTimeout(() => {
      setIsMenuOpen(false);
    }, 1000);
  };

  const handleSwitchChange = async () => {
    if (!isSwitchOn) {
      setIsModalOpen(true);
      return;
    }
    setIsSwitchOn(!isSwitchOn);
    await updateProducts(false, 1);
    //getProducts();

    setTimeout(() => {
      setIsMenuOpen(false);
    }, 1000);
  };

  const switchLabelText = isSwitchOn ? "Disabled" : "Disable";

  const handleClick = () => {

    console.log(data);
    setSelectedProductGroup(data);
    setShowProductTab((prev) => "viewProduct");
    //   navigate(
    //     `/superadmin/viewDetails/${data.organisation}/${data.clientId}/view`
    //   );
  };

  const exportAsExcel = (data, sheetName) => {
    let wb = utils.book_new();


    let fileColumns = Object.keys(data[0]);
    fileColumns = fileColumns.filter((value) => (value !== "productId" && value !== "createdAt" && value !== "updatedAt"))
    let newData = [fileColumns];

    if (data.length != 0) {
      data?.forEach((item) => {
        const newArray = fileColumns?.map((i) => {
          return i.toLowerCase().includes("time")
            ? new Date(item[i]).toLocaleString()
            : item[i];
        });
        newData.push(newArray);
      });
    }

    let ws = utils.aoa_to_sheet(newData);
    utils.book_append_sheet(wb, ws, `${sheetName}`);
    writeFile(wb, `report_data.xlsx`);
  };

  const handleUpdate = () => {
    //   navigate(`/superadmin/update/${data.organisation}/${data.clientId}/update`);
    setSelectedProductGroup(data);
    setShowProductTab((prev) => "updateProduct");
  };

  const handleDownloadClick = async () => {
    if (Array.isArray(data.product)) exportAsExcel(data.product, "Summary");
    else exportAsExcel([data.product], "Summary");
  };

  useEffect(() => {
    // getProductCategory();
  }, []);

  return (
    <div
      className="px-4 py-5 rounded flex flex-col justify-between gap-4 h-[250px] relative bg-white w-full"
      style={{
        boxShadow:
          "-4px -4px 24px 0px rgba(0, 0, 0, 0.07), 4px 4px 24px 0px rgba(0, 0, 0, 0.07)",
      }}
    >
      <div className="flex flex-col gap-2 w-full">
        <div className="flex h-[45px] gap-0 w-full">
          <div className=" flex justify-start">
            {data?.product.productLogoUrl ? (
              <img
                src={data?.product.productLogoUrl + `?${performance.now()}`}
                alt="logo"
                className="w-full max-w-[30px] h-[30px]"
              />
            ) : (
              <img src="/ProductDefaultIcon.svg" alt="" className="w-full max-w-[30px] h-[30px]" />
            )}
          </div>

          <div className="ml-2 h-[45px] w-[70%] overflow-hidden">
            <Tooltip label={data?.product.productName} placement="top">
              <p className="text-[#3E3C42] text-[16px] font-medium whitespace-pre-line line-clamp-2 overflow-hidden overflow-ellipsis">
                {data?.product.productName}
              </p>
            </Tooltip>
          </div>

          <div className="w-[10%]">
            <Menu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<MoreVertIcon />}
                variant="simple"
                position={"absolute"}
                top={"15px"}
                right={"0px"}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              />
              <MenuList
                px={"12px"}
                py={"16px"}
                rounded={"8px"}
                display={"flex"}
                flexDirection={"column"}
                gap={"16px"}
                minWidth={"fit-content"}
                w={"180px"}
                position={"absolute"}
                right={"-50px"}
                top={"-15px"}
              >
                <MenuItem
                  icon={<DownloadIcon />}
                  textColor={"#605D64"}
                  fontSize={"14px"}
                  fontWeight={500}
                  p={"8px"}
                  _hover={{ bg: "#DDEEFF80", borderRadius: "4px" }}
                  _focus={{ bg: "#DDEEFF80", borderRadius: "4px" }}
                  onClick={handleDownloadClick}
                >
                  {"Download"}
                </MenuItem>
                {/* <MenuItem
                  icon={<DeleteIcon />}
                  textColor={"#605D64"}
                  fontSize={"14px"}
                  fontWeight={500}
                  p={"8px"}
                  _hover={{ bg: "#DDEEFF80", borderRadius: "4px" }}
                  _focus={{ bg: "#DDEEFF80", borderRadius: "4px" }}
                >
                  {"Delete"}
                </MenuItem> */}
                {/* <div className="flex items-center justify-center"> */}
                <Checkbox
                  //iconColor='red'
                  colorScheme="red"
                  p={"8px"}
                  isChecked={isSwitchOn}
                  disabled={data?.product.status === "DRAFT"}
                  //isIndeterminate={isIndeterminate}
                  onChange={handleSwitchChange}
                >
                  {switchLabelText}
                </Checkbox>
                {/* </div> */}
              </MenuList>
            </Menu>
            <Modal size={"sm"} isOpen={isModalOpen} onClose={handleCloseModal}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader className=" flex justify-center rounded-t-md bg-red-400 text-white ">
                  Confirm Disable ?
                </ModalHeader>
                {/* <ModalCloseButton /> */}
                <ModalBody m={"auto"} fontSize={"15px"}>
                  <div className="flex flex-col items-center justify-center w-full">
                    <img
                      src={require("./disable.png")}
                      className="w-[100px] h-[100px]"
                    />

                    <p className="ml-4 font-medium">
                      Are you sure you would like to disable the product?
                    </p>
                  </div>
                </ModalBody>

                <div className="mt-4 flex flex-row justify-center">
                  <Button
                    colorScheme="blue"
                    mr={4}
                    onClick={handleConfirmDiscard}
                  >
                    Confirm
                  </Button>
                  <Button onClick={handleCloseModal}>Go Back</Button>
                </div>

                <ModalFooter></ModalFooter>
              </ModalContent>
            </Modal>
          </div>
          {/* <div></div> */}
        </div>
        <div className="grid grid-cols-2 gap-2 w-full">
          <div className="flex items-center justify-start w-full">
            <div className="flex flex-col items-start justify-center w-full">
              <p className="text-[#938F96] text-sm">Parent</p>
              <div className="flex items-center justify-center w-full">

                {
                  data?.productCategory?.toLowerCase() === "community" && <img className="w-[20px] h-[20px]" src="/CommunityIcon.svg" alt="" />
                }
                {
                  data?.productCategory?.toLowerCase() === "vision" && <img className="w-[20px] h-[20px]" src="/VisionIcon.svg" alt="" />
                }
                {
                  data?.productCategory?.toLowerCase() === "optimus" && <img className="w-[20px] h-[20px]" src="/OptimusIcon.svg" alt="" />
                }
                {
                  data?.productCategory?.toLowerCase() === "others" && <img className="w-[20px] h-[20px]" src="/casino.svg" alt="" />
                }

                <div className="ml-2 overflow-hidden w-full">
                  <Tooltip label={data?.productCategory} placement="top">
                    <p className="whitespace-nowrap text-ellipsis overflow-clip">{data?.productCategory}</p>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-start justify-start w-full">
            <div className="flex flex-col items-start justify-start w-full">
              <p className="text-[#938F96] text-sm">Product Group</p>
              <div className="flex gap-2 items-center w-full">
                <img
                  className="w-[20px] h-[20px]"
                  src="/productGroup.svg"
                  alt=""
                />

                <Tooltip label={data?.productGroup} placement="top">
                  <p className="text-[#3E3C42] text-[16px] font-medium truncate">{data?.productGroup}</p>
                </Tooltip>

              </div>
            </div>
          </div>

        </div>

        <div className="grid grid-cols-2 gap-2 ">

          <div className="flex items-center justify-start">
            <div className="flex flex-col items-start justify-center">
              <p className="text-[#938F96] text-sm">Subscription</p>
              <div className="flex items-center justify-start">
                {/* <div className=""> */}
                <img
                  className="w-[20px] h-[20px]"
                  src="/subs.svg"
                  alt=""
                />
                {/* </div> */}
                <div className="ml-2">
                  <p>{data?.subscriptionCount}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-start">
            <div className="flex flex-col items-start justify-center">
              <p className="text-[#938F96] text-sm">Clients</p>
              <div className="flex items-center justify-start">
                {/* <div className=""> */}
                <img
                  className="w-[20px] h-[20px]"
                  src="/clients.png"
                  alt=""
                />
                {/* </div> */}
                <div className="ml-2">
                  <p>{data?.clientCount}</p>
                </div>
              </div>
            </div>
          </div>

        </div>
        {/* <div className="">
          <Tooltip label={allTools?.join(", ")} placement="top">
            <div>
              <p className="text-[#938F96] text-sm">Locations</p>
              <div className="grid grid-cols-2 gap-[2px] mt-2">
                {allTools?.slice(0, maxToolsToShow)?.map((el, index) => (
                  <div key={index} className="flex items-center ">
                    <div>
                      <img src="/location.png" alt="" />
                    </div>
                    <p className="text-[#938F96] text-sm">{el}</p>
                  </div>
                ))}
                {allTools?.length > maxToolsToShow && (
                  <Badge
                    ml={1}
                    color={"#447ED4"}
                    bg={"white"}
                    fontWeight={600}
                    fontSize="14px"
                  >
                    +{allTools?.length - maxToolsToShow}
                  </Badge>
                )}
              </div>
            </div>
          </Tooltip>
        </div> */}
      </div>
      <div className="flex gap-2 items-center">
        <PrimaryButton
          text={"View"}
          onClick={() => handleClick()}
        // disable={!data.product.isactive}
        />
        <TonalButton
          text={"Update"}
          onClick={() => handleUpdate()}
          disable={data?.product.status === "INACTIVE"}
        />
      </div>

      <Tooltip
        label={data?.product?.status?.toLowerCase()}
        hasArrow
      >

        {data?.product?.status === "DRAFT" ?
          <img
            src={`/Common/draft.svg`}
            className="w-8 h-8 absolute -top-2 -right-2 rounded-full bg-white p-1 shadow-md"
            alt="status"
          /> :
          data?.product?.status === "ACTIVE" ? <CheckCircleIcon sx={{
            position: 'absolute',
            top: "-8px",
            right: "-8px",
            backgroundColor: "white",
            padding: "4px",
            width: "32px",
            height: "32px",
            borderRadius: "9999px",
            color: "green",
            boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
          }} /> :
            <CancelIcon sx={{
              position: 'absolute',
              top: "-8px",
              right: "-8px",
              backgroundColor: "white",
              padding: "4px",
              borderRadius: "9999px",
              width: "32px",
              height: "32px",
              color: "#E46962",
              boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
            }} />}
        {/* {
            data?.status 
          } */}

      </Tooltip>
    </div>
  );
};

export default ProductCard;
