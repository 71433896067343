import {
  Select,
  Table,
  Td,
  Tr,
  Thead,
  Tbody,
  TableContainer,
  Th,
  Flex,
  Image,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import { useWindowSize } from "@uidotdev/usehooks";
import Viewdetails from "./Viewdetails";
import Feedback from "./Feedback";

const AlertsTable = ({ tableData }) => {
  const size = useWindowSize();

  const editDates = (TimeStamp) => {
    const timestamp = TimeStamp * 1000; // Multiply by 1000 to convert from seconds to milliseconds
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };

  return (
    <TableContainer
      className={` ${
        size.width < 768 ? "h-[50vh] mb-[50px]" : "h-[44vh]"
      }  w-[91vw] `}
    >
      <Table variant="simple">
        <Thead className="bg-[#DDEEFF] !text-xs !h-[30px] !sticky !top-0">
          <Tr>
            <Th
              color="#79767D"
              fontWeight={400}
              style={{ width: "50px", textAlign: "left" }}
            >
              SR. NO.
            </Th>
            <Th
              color="#79767D"
              fontWeight={400}
              style={{ width: "100px", textAlign: "center" }}
            >
              SLAB ID
            </Th>
            <Th
              color="#79767D"
              fontWeight={400}
              style={{ width: "200px", textAlign: "center" }}
            >
              SHIFT
            </Th>
            <Th
              color="#79767D"
              fontWeight={400}
              style={{ width: "100px", textAlign: "center" }}
            >
              TIME
            </Th>
            <Th
              color="#79767D"
              fontWeight={400}
              style={{ width: "300px", textAlign: "center" }}
            >
              VARIANCE
            </Th>
            <Th
              color="#79767D"
              fontWeight={400}
              style={{ width: "400px", textAlign: "center" }}
            >
              COMMENT
            </Th>
            {/* <Th
                color="#79767D"
                fontWeight={400}
                style={{ width: "200px", textAlign: "center" }}
              >
                DETAIL
              </Th> */}

            {/* <Th color="#79767D" fontWeight={400}>
              {"hello "}
            </Th> */}
          </Tr>
        </Thead>
        <Tbody>
          {tableData.map((item, index) => {
            return (
              <Tr
                key={index}
                className="!text-sm !text-[#3E3C42] !h-[30px]  text-center !font-medium even:bg-[#FAFAFA] odd:bg-white"
              >
                {/* sl */}
                <Td style={{ width: "50px", textAlign: "left" }}>
                  {String(item["idx"]).padStart(2, "0")}
                </Td>
                {/* slab id */}
                <Td style={{ width: "100px", textAlign: "center" }}>
                  {item.slab_id}
                </Td>
                {/* shift */}
                <Td style={{ width: "100px", textAlign: "center" }}>
                  {item.shift}
                </Td>
                {/* timestamp */}
                <Td style={{ width: "200px", textAlign: "center" }}>
                  {editDates(item.timestamp - 5.5 * 60 * 60)}
                </Td>
                {/* variance */}
                <Td className="flex gap-2 p-2  w-full !justify-between ">
                  {/* length */}
                  <div className="w-[80px] flex gap-1">
                    <p>L :</p>{" "}
                    {item?.alert?.length != 0 ? (
                      <p className="text-[#E46962]">
                        {item?.alert?.length?.toFixed(0) + " mm"}
                      </p>
                    ) : (
                      <img src="/slabsizing/greentick.svg" alt="" />
                    )}{" "}
                  </div>
                  {/* breadth */}
                  <div className="w-[80px] flex gap-1">
                    <p>B :</p>{" "}
                    {item?.alert?.breadth != 0 ? (
                      <p className="text-[#E46962]">
                        {item?.alert?.breadth.toFixed(0) + " mm"}
                      </p>
                    ) : (
                      <img src="/slabsizing/greentick.svg" alt="" />
                    )}{" "}
                  </div>
                  {/* height */}
                  <div className="w-[80px] flex gap-1">
                    <p>H :</p>{" "}
                    {item?.alert?.depth == null ? (
                      "..."
                    ) : item?.alert?.depth != 0 ? (
                      <p className="text-[#E46962]">
                        {item?.alert?.depth.toFixed(0) + " mm"}
                      </p>
                    ) : (
                      <img src="/slabsizing/greentick.svg" alt="" />
                    )}{" "}
                  </div>
                </Td>
                <Td className=" gap-2  w-[400px]">
                  <Tooltip label={item?.alert?.message} placement="top">
                    <p className=" ">
                      {item?.alert?.message?.split(" ").length > 8
                        ? item?.alert?.message
                            .split(" ")
                            .slice(0, 8)
                            .join(" ") + "..."
                        : item?.alert?.message}
                    </p>
                  </Tooltip>
                </Td>

                {/* <Td className=" gap-2 justify-between w-[200px]">
                    <Viewdetails
                      slab={item.slab_id}
                      idx={item.id}
                      item={item}
                    />
                  </Td> */}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default AlertsTable;
