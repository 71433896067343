import { Input, useToast } from "@chakra-ui/react";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { useContext, useRef, useState } from "react";
import TonalButton from "../../../util/Buttons/TonalButton";
import axios from "axios";
import { baseURL } from "../../..";
import NavContext from "../../NavContext";

const ProjectDetails = ({
  userData,
  setUSerData,
  activeStep,
  setActiveStep,
  viewMode = false,
}) => {
  const nameRef = useRef();
  const { auth } = useContext(NavContext);
  const [disable, setDisable] = useState(viewMode);
  const toast = useToast();
  let regex = /^[a-zA-Z0-9\-_\s]+$/;

  const handlSave = async () => {
    if (
      nameRef.current.value == "" ||
      nameRef.current.value.length > 100 ||
      !regex.test(nameRef.current.value)
    ) {
      toast({
        title: "Error",
        description: (
          <div>
            {nameRef.current.value == "" && (
              <p>Please ensure project name is not empty</p>
            )}
            {nameRef.current.value.length > 100 && (
              <p>The name cannot be greater then 100 characters</p>
            )}
            {!regex.test(nameRef.current.value) && (
              <p>The name can only contain alphabets, numbers, "_" or "-" </p>
            )}
          </div>
        ),
        status: "error",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    try {
      const requestBody = JSON.stringify({
        name: nameRef.current.value,
        remarks: "Self Serve project",
        clientId: "ripik",
      });
      const response = await axios.post(
        baseURL + "selfserve/v1/project/v1/add/",
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.status == 200) {
        setUSerData((prev) => {
          let newData = { ...prev };
          newData["name"] = nameRef.current.value;
          newData["projectId"] = response.data.projectId;
          return newData;
        });
        toast({
          title: "Success",
          description: "Project created with name " + nameRef.current.value,
          status: "success",
          position: "top-right",
          duration: 2000,
          isClosable: true,
        });
        chunkApi(response.data.projectId);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 400) {
        toast({
          title: "Warning",
          description: error.response?.data?.message,
          status: "warning",
          position: "top-right",
          duration: 2000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: "Creation api failed",
          status: "error",
          position: "top-right",
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  const chunkApi = async (id) => {
    const param = {
      projectId: id,
    };
    try {
      const response = await axios.get(
        baseURL + "selfserve/v1/sentry/v1/project/",
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.data) {
        setUSerData((prev) => {
          let newData = { ...prev };
          newData["chunkSize"] = response.data[0]?.uploadChunkSize;
          return newData;
        });
        if (activeStep < 1) setActiveStep((prev) => prev + 1);
        setDisable(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="bg-white rounded-lg flex flex-col gap-8 p-6" id="step0">
      <p className="text-[#3E3C42] text-xl font-medium">Project Details</p>
      <div className="flex flex-col gap-3">
        <p className="text-sm text-[#3E3C42] font-medium">
          Project name<span className="text-[#DC362E]">*</span>
        </p>
        <Input
          width={"fit-content"}
          name="name"
          defaultValue={userData.name}
          ref={nameRef}
          isDisabled={disable}
          placeholder="Max char 100"
        />
      </div>
      <div className="flex gap-2 items-center">
        <PrimaryButton
          text={"Save"}
          width={"fit-content"}
          onClick={handlSave}
          disable={disable}
        />
        {/* {activeStep > 0 && (
          <TonalButton
            text={"Edit"}
            width={"fit-content"}
            onClick={() => setDisable(false)}
            disable={!disable}
          />
        )} */}
      </div>
    </div>
  );
};

export default ProjectDetails;
