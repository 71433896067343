import React from "react";
import {
  Skeleton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  SkeletonText,
} from "@chakra-ui/react";
import { useWindowSize } from "@uidotdev/usehooks";
import { MoodBad } from "@mui/icons-material";

// No
export const NoData = () => {
  return (
    <div className="flex flex-row justify-center items-center border rounded-md w-full h-full">
      <div className="flex flex-row items-center gap-2 text-gray-400">
        <MoodBad fontSize={"large"} />
        <h2 className="text-2xl">No Data!</h2>
      </div>
    </div>
  );
};

// loading state
export const TableSkeleton = ({
  headers = [],
  rows = 3,
  cellsPerRow = 3,
  padding = null,
  backgroundColor = "#CCEAFF",
  disableHeader = false,
}) => {
  return (
    <TableContainer overflowX={"scroll"}>
      <Table variant="simple">
        {disableHeader === false && (
          <Thead>
            <Tr backgroundColor={backgroundColor}>
              {headers.map((header, index) => (
                <Th
                  key={index}
                  minW={"150px"}
                  padding={padding}
                >
                  <Skeleton height="20px" />
                </Th>
              ))}
            </Tr>
          </Thead>
        )}
        <Tbody>
          {[...Array(rows)].map((_, rowIndex) => (
            <Tr key={rowIndex}>
              {[...Array(cellsPerRow)].map((_, cellIndex) => (
                <Td
                  key={cellIndex}
                  padding={padding}
                >
                  <Skeleton height="20px" />
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export const DetailModalSkeleton = ({ isFeed = false }) => {
  const parentClassName = isFeed
    ? "flex lg:flex-row flex-col-reverse gap-2 px-2 md:px-0 w-full h-full overflow-auto"
    : "flex flex-col-reverse gap-2 px-2 md:px-0 w-full h-full overflow-auto";
  const className = isFeed
    ? "gap-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 grid-flow-row w-full h-full"
    : "gap-2 grid grid-cols-1 md:grid-cols-4 w-full h-full";
  const cameraCardHeight = isFeed ? "395px" : "300px";
  const lineChartClass = isFeed ? "w-full lg:w-[30%]" : "w-full h-[500px]";

  return (
    <div className={parentClassName}>
      <div className={className}>
        {[1, 2, 3, 4].map((el) => (
          <div className="flex flex-col col-span-1 w-full h-full">
            <div className="relative w-full h-full">
              <Skeleton
                className={`w-full h-[${cameraCardHeight}]`}
                height={cameraCardHeight}
              />
            </div>
            <div className="flex flex-col justify-between gap-2 py-1 w-full text-sm">
              <SkeletonText noOfLines={1} />
              <SkeletonText noOfLines={1} />
            </div>
          </div>
        ))}
      </div>
      <div className={lineChartClass}>
        <Skeleton className="w-full h-full" />
      </div>
    </div>
  );
};

export const LibraryGridSkeleton = () => {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between items-baseline">
        <div className="flex items-baseline gap-2 w-[30%] md:w-[10%]">
          <Skeleton
            height={"20px"}
            width={"100%"}
          />
        </div>
        <div className="flex sm:flex-row flex-col items-center gap-1 w-[50%] md:w-[20%]">
          <Skeleton
            height={"35px"}
            width={"100%"}
          />
        </div>
      </div>
      <div className="gap-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 h-[80vh] overflow-y-scroll">
        {[...Array(18)].map((_, id) => (
          <div
            key={id}
            className="relative w-full text-center object-cover"
          >
            <div className="top-2 absolute flex justify-between gap-2 px-2 w-full">
              <Skeleton
                height="20px"
                width="100px"
              />
              <Skeleton
                height="20px"
                width="100px"
              />
            </div>
            <Skeleton
              height="350px"
              rounded={"md"}
            />
            <div className="bottom-2 left-0 absolute flex flex-col items-end gap-2 opacity-50 hover:opacity-100 px-2 rounded-md">
              <Skeleton
                height="20px"
                width="150px"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const AnanlyticsSkeleton = () => {
  const { width } = useWindowSize();
  const stackedColumnChart = Array.from(
    { length: width < 700 ? 4 : 7 },
    () => ({
      height: `${Math.floor(Math.random() * 51) + 50}%`,
    })
  );
  const columnChart = Array.from({ length: width < 700 ? 4 : 13 }, () => ({
    height: `${Math.floor(Math.random() * 51) + 50}%`,
  }));

  return (
    <div>
      {/* Skeleton for Flame Health Distribution heading */}
      <Skeleton
        height="20px"
        width="200px"
        className="mb-4"
      />

      <div className="flex lg:flex-row flex-col items-center gap-5 w-full">
        {/* Skeleton for Donut Chart */}
        <div className="w-[100%] lg:w-[30%] h-[350px]">
          <div className="relative flex justify-center items-center w-full h-full">
            <Skeleton
              className="rounded-full w-[80%] h-[80%]"
              rounded={"full"}
            />
            <div
              className="absolute bg-white rounded-full w-[45%] h-[45%]"
              rounded={"full"}
            />
          </div>
        </div>

        {/* Skeleton for StackedColumnChart */}
        <div className="flex flex-col justify-center gap-0 item-center p-2 w-[100%] lg:w-[70%] h-[350px]">
          <div className="!flex flex-row !justify-between items-end w-[100%] h-[100%]">
            {stackedColumnChart?.map((bar, index) => (
              <Skeleton
                key={index}
                height={bar.height}
                width="70px"
                borderTopRadius={"6px"}
                borderBottomRadius={0}
              />
            ))}
          </div>
          <Skeleton
            w={"100%"}
            h={4}
            borderTopRadius={0}
          />
        </div>
      </div>

      {/* Skeleton for High Alert Count heading */}
      <Skeleton
        height="20px"
        width="150px"
        className="mt-8 mb-4"
      />
      {/* Skeleton for ColumnChart */}
      <div className="flex flex-col justify-center gap-0 item-center mb-4 p-2 rounded-md w-full h-[300px]">
        <div className="!flex flex-row !justify-between items-end w-[100%] h-[100%]">
          {columnChart?.map((bar, index) => (
            <Skeleton
              key={index}
              height={bar.height}
              width="50px"
              borderTopRadius={"6px"}
              borderBottomRadius={0}
            />
          ))}
        </div>
        <Skeleton
          w={"100%"}
          h={4}
          borderTopRadius={0}
        />
      </div>
      <TableSkeleton
        headers={["Header 1", "Header 2", "Header 3"]}
        rows={10}
        cellsPerRow={3}
      />
    </div>
  );
};
