import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Flex,
  Button,
  FormControl,
  Modal,
  ModalBody,
  ModalFooter,
  ModalOverlay,
  ModalCloseButton,
  ModalContent,
  Spinner,
  useToast,
  FormLabel,
  Input,
  CircularProgress,
  Checkbox,
} from "@chakra-ui/react";

import NavContext from "../../NavContext";
import TonalButton from "../../../util/Buttons/TonalButton";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { baseURL } from "../../..";
import axios from "axios";

const AddSubscriptionsModal = ({
  isOpen,
  onClose,
  data,
  isEditMode,
  fetchTableData,
  setSubHistoryChange,
  issubscriptionHistoryChanged,
  clientOrg,
}) => {
  const { auth, getSubscriptionsNew } = useContext(NavContext);
  const [organisation, setOrganisation] = useState("");
  const [endDate, setEndDate] = useState(
    (data?.expiryAt && new Date(data?.expiryAt).toISOString().slice(0, 10)) ||
      null
  );

  const plantRef = useRef(null);

  const [prodCategory, setProdCategory] = useState(data?.productCategory || "");
  const [prodGroup, setProdGroup] = useState(data?.productGroup || "");
  const [locations, setLocations] = useState([]);

  const [error, setError] = useState("");
  const toast = useToast();
  const [clients, setClients] = useState([]);
  const [products, setProducts] = useState([]);
  const [clientId, setClientId] = useState(data?.clientId || "");
  const [clientName, setClientName] = useState(data?.clientName || "");
  const [servId, setServId] = useState(data?.productId || "");
  const [servName, setServName] = useState(data?.product || "");
  const [remarks, setRemarks] = useState("");
  const [baseLocation, setBaseLocation] = useState(data?.location || "");
  const [plant, setPlant] = useState(data?.plantName || "");
  const [status, setStatus] = useState(
    data?.status == "INACTIVE" ? true : false
  );

  const fetchClients = async () => {
    try {
      const response = await axios.get(baseURL + "iam/fetchClient", {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      const filteredData = response?.data?.data.filter(
        (item) => item.status === "ACTIVE"
      );

      if (clientOrg) {
        const filteredOrganisation = response?.data?.data.filter(
          (item) => item.organisation == clientOrg
        );
        // const filteredOrgClientId = response?.data?.data.find(
        //   (item) => item.organisation == clientOrg
        // );

        setClients(filteredOrganisation);

        setClientId((prev) => filteredOrganisation[0]?.clientId || "");
        setOrganisation(filteredOrganisation[0]?.organisation || "");
        setClientName(filteredOrganisation[0]?.clientName || "");

        setLocations(
          filteredOrganisation[0]?.clientAllLocation
            ?.split(",")
            ?.map((location) => location?.trim())
        );

        return filteredOrganisation;
      } else {
        setClients(filteredData);

        return filteredData;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchProducts = async () => {
    try {
      const response = await axios.get(baseURL + "product/v1/fetch/product", {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      setProducts(response?.data.filter((item) => item.status === "ACTIVE"));
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    if (isEditMode) {
      patchAddSubscriptions();
    } else {
      AddSubscriptions();
    }
  };

  const AddSubscriptions = async () => {
    if (!clientName || !baseLocation || !servId) {
      setError("Please fill in all required fields.");
      return;
    }

    const data = JSON.stringify({
      plantName: plantRef.current.value,
      clientName: clientName,
      productId: servId,
      baseLocation: baseLocation,
      organisation: organisation,
      expiryAt: convertToEpoch(endDate),
      remarks: remarks,
    });

    try {
      const response = await axios.post(baseURL + "subscription/add", data, {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      getSubscriptionsNew();
      onClose();
      fetchTableData();
      setSubHistoryChange(!issubscriptionHistoryChanged);

      //console.log(response);
    } catch (error) {
      //console.log(error);
    }
  };

  const patchAddSubscriptions = async () => {
    if (!clientName || !baseLocation || !remarks) {
      setError("Please fill in all required fields.");
      return;
    }

    const payload = {
      clientName: clientName,
      productId: servId,
      baseLocation: baseLocation,
      organisation: data?.organisation,
      remarks: remarks,
      subscriptionId: data?.subscriptionId,
      plantName: plantRef.current.value,
      status: status ? 1 : 0,
    };
    if (endDate) {
      payload.expiryAt = convertToEpoch(endDate);
    }

    try {
      const response = await axios.patch(
        baseURL + "subscription/update",
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      getSubscriptionsNew();
      fetchTableData();
      setSubHistoryChange(!issubscriptionHistoryChanged);
      onClose();

      //console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  function convertToEpoch(dateString) {
    // Convert the date string to a JavaScript Date object
    const dateObject = new Date(dateString);

    // Get the epoch time (Unix timestamp) in milliseconds
    const epochTimeMilliseconds = dateObject.getTime();

    // Convert milliseconds to seconds (as Unix timestamps are usually in seconds)
    const epochTimeSeconds = epochTimeMilliseconds;

    return epochTimeSeconds;
  }

  const handleClientChange = (clientId) => {
    fetchClients().then((res) => {
      const selectedClient = res?.find(
        (client) => client?.clientId === clientId
      );

      setClientId((prev) => selectedClient?.clientId || "");
      setOrganisation(selectedClient?.organisation || "");
      setClientName(selectedClient?.clientName || "");
      //setServId(selectedClient?.)
      setLocations(
        selectedClient?.clientAllLocation
          ?.split(",")
          ?.map((location) => location?.trim())
      );
    });
  };

  const handleProduct = async (servName) => {
    const selectedProd = products?.find(
      (prod) => prod?.productName === servName
    );

    setServName(selectedProd?.productName || "");
    setServId(selectedProd?.productId || "");
    setProdCategory(selectedProd?.productCategory || "");

    try {
      const response = await axios.get(
        baseURL +
          `product/v1/fetch/productGroup/id?productGroupId=${selectedProd.productGroupId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-auth-Token": auth,
          },
        }
      );
      let data = response?.data;
      setProdGroup(data.productGroupName);
      //setLoading(false);
    } catch (error) {
      //setLoading(false);
      console.log(error);
    }
    //setProdGroup(selectedProd?.servCategory || "");
    //setPlant(selectedProd?.plantName)
  };

  const handleDiscard = () => {
    setClientName("");
    setBaseLocation("");
    setOrganisation("");
    setServId("");
    setProdCategory("");
    setProdGroup("");
    setRemarks("");
    setEndDate("");
    setPlant("");
    onClose();
  };

  useEffect(() => {
    fetchClients();
    fetchProducts();
  }, []);

  const handlePlant = (e) => {
    setPlant(e.target.value);
  };
  const handleDeactivateToggle = (e) => {
    const isChecked = e.target.checked;

    setStatus((prev) => !prev);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"sm"} width={740}>
      <ModalOverlay />
      <ModalContent>
        <div className="text-white w-full h-12  flex bg-[#2660B6] font-semibold justify-center items-center rounded-t-md">
          {isEditMode ? "Update subscription" : "Add subscription"}
        </div>
        <ModalCloseButton className="" color={"white"} />
        <ModalBody className="mt-6">
          <Flex flexDirection={"column"} gap={"30px"}>
            <FormControl className="!h-12" isRequired>
              <FormLabel className="!text-xs !text-[#2660B6] !mb-2 !font-semibold">
                Client name
              </FormLabel>
              {isEditMode ? (
                <input
                  value={data?.clientName}
                  className="w-full border rounded text-sm border-[#938F96] py-2 px-1"
                  disabled={true}
                />
              ) : (
                <select
                  value={clientId}
                  onChange={(e) => handleClientChange(e.target.value)}
                  className="!w-full !border !rounded !text-sm !border-[#938F96] !py-2 !px-[1px]"
                  disabled={isEditMode ? true : false}
                  required
                >
                  <option value={""} className="!text-[#AEA9B1]">
                    Select client name
                  </option>
                  {clients &&
                    clients?.length > 0 &&
                    clients?.map((client) => (
                      <option key={client.clientId} value={client.clientId}>
                        {client.clientName}
                      </option>
                    ))}
                </select>
              )}
            </FormControl>
            <FormControl className="!h-12" isRequired>
              <FormLabel
                isRequired
                className="!text-xs !text-[#2660B6] !mb-2 !font-semibold"
              >
                Location
              </FormLabel>

              {isEditMode ? (
                <input
                  value={data?.location}
                  className="w-full border rounded text-sm border-[#938F96] py-2 px-1"
                  disabled={true}
                />
              ) : (
                <select
                  value={baseLocation}
                  onChange={(e) => setBaseLocation(e.target.value)}
                  className="!w-full !border !rounded !text-sm !border-[#938F96] !py-2 !px-[1px]"
                  disabled={isEditMode ? true : false}
                  required
                >
                  <option value={""} className="!text-[#AEA9B1]">
                    Select location
                  </option>

                  {locations &&
                    locations?.length > 0 &&
                    locations?.map((location) => (
                      <option key={location} value={location}>
                        {location}
                      </option>
                    ))}
                </select>
              )}
            </FormControl>
            <FormControl className="!h-12">
              <FormLabel className="!text-xs !text-[#2660B6] !mb-2 !font-semibold">
                Plant
              </FormLabel>
              <input
                value={plant}
                className="w-full border rounded text-sm border-[#938F96] py-2 px-1"
                placeholder={status ? "" : "Enter Plant"}
                ref={plantRef}
                disabled={status}
                onChange={handlePlant}
              />
            </FormControl>
            <FormControl className="!h-12" isRequired>
              <FormLabel
                isRequired
                className="!text-xs !text-[#2660B6] !mb-2 !font-semibold"
              >
                Product
              </FormLabel>
              <select
                value={servName}
                onChange={(e) => handleProduct(e.target.value)}
                className="!w-full !border !rounded !text-sm !border-[#938F96] !py-2 !px-[1px]"
                required
                disabled={status}
              >
                <option value={""} className="!text-[#AEA9B1]">
                  Select product
                </option>
                {products &&
                  products?.length > 0 &&
                  products?.map((product) => (
                    <option key={product.productId} value={product.productName}>
                      {product.productName}
                    </option>
                  ))}
              </select>
            </FormControl>
            <FormControl className="!h-12">
              <div
                className={`text-xs text-[#2660B6] mb-2 font-semibold ${
                  prodCategory.length > 0 ? "border-none text-[#AEA9B1]" : ""
                }`}
              >
                Product Category
              </div>
              <input
                value={prodCategory}
                className={`w-full border rounded text-sm border-[#938F96] py-2 px-1 ${
                  prodCategory.length > 0 ? "border-none border-[#AEA9B1]" : ""
                }`}
                placeholder="Product Category"
                onChange={(e) => setProdCategory(e.target.value)}
                disabled={true}
                readOnly
              />
            </FormControl>
            <FormControl className="!h-12">
              <div
                className={`text-xs text-[#2660B6] mb-2 font-semibold ${
                  prodGroup.length > 0 ? "border-none text-[#AEA9B1]" : ""
                }`}
              >
                Product Group
              </div>
              <input
                value={prodGroup}
                className={`w-full border rounded text-sm border-[#938F96] py-2 px-1 ${
                  prodGroup.length > 0 ? "border-none border-[#AEA9B1]" : ""
                }`}
                placeholder="Product Group"
                onChange={(e) => setProdGroup(e.target.value)}
                disabled={true}
                readOnly
              />
            </FormControl>
            <FormControl className="!h-12">
              <div className="text-xs text-[#2660B6] mb-2 font-semibold">
                Subscription End date
              </div>
              <div className="flex">
                <input
                  text=""
                  type="date"
                  setDateTime={setEndDate}
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  className={` ${
                    isEditMode ? "w-[150px]" : "w-full"
                  } border rounded text-sm border-[#938F96] py-2 px-1`}
                  disabled={status}
                />
                {isEditMode && (
                  <div className="flex ml-6">
                    <Checkbox
                      isChecked={status}
                      onChange={handleDeactivateToggle}
                      mr={"4px"}
                    >
                      Deactivate
                    </Checkbox>
                  </div>
                )}
              </div>
            </FormControl>

            <FormControl className="!h-12" isRequired={isEditMode}>
              <FormLabel className="!text-xs !text-[#2660B6] !mb-2 !font-semibold">
                Remarks
              </FormLabel>
              <input
                value={remarks}
                className="w-full border rounded text-sm border-[#938F96] py-2 px-1"
                placeholder={
                  isEditMode
                    ? `Enter reason for update, please`
                    : `Enter remarks`
                }
                onChange={(e) => setRemarks(e.target.value)}
                disabled={isEditMode && status && data?.status === "INACTIVE"} //disable if deactivive is checked
              />
            </FormControl>
          </Flex>

          {/* Display error message if there is any */}
          {error && <div className="text-red-500 mt-4">{error}</div>}
        </ModalBody>
        <ModalFooter className="!w-full !mt-4 !flex !flex-row !items-center !justify-start !gap-2">
          <PrimaryButton
            text={"Save"}
            width="80px"
            onClick={handleSubmit}
            disable={
              !(clientName && baseLocation && servName) ||
              (isEditMode && !remarks)
            }
          />
          <TonalButton text={"Discard"} width="80px" onClick={handleDiscard} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export { AddSubscriptionsModal };
