import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  IconButton,
  Skeleton,
  Tooltip,
} from "@chakra-ui/react";

import { useWindowSize } from "@uidotdev/usehooks";
import CameraFeed from "./CameraFeed";
import { Cameraswitch, Circle } from "@mui/icons-material";
import { formatDateTime } from "../utils/formatDateTime";
import { useEffect, useState } from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import ReactCardFlip from "react-card-flip";

const DetailModal = ({ openModal, closeModal, data, index, camMap }) => {
  const [currIndex, setCurrIndex] = useState(index);
  const [flip, setFlip] = useState({});

  const handleImageFlip = (recordId) => {
    setFlip((prevState) => ({
      ...prevState,
      [recordId]: !prevState[recordId],
    }));
  };

  const toggleMove = (val) => {
    //index -1
    if (val === 1) {
      setCurrIndex((prev) => (prev > 0 ? prev - 1 : data.length - 1));
    }
    //index +1
    else if (val === 2) {
      setCurrIndex((prev) => (prev < data.length - 1 ? prev + 1 : 0));
    }
  };

  const size = useWindowSize();
  const labelsMapping = ["Coldspots", "Hotspots", "Flame_distance"];
  const labelDictionary = {
    Coldspots: {
      svg: "#3E3C42",
      displayName: "Coldspots",
    },
    Hotspots: {
      svg: "#B3261E",
      displayName: "Hotspots",
    },
    Flame_distance: {
      svg: "/JSWSalem/distanceLabel.svg",
      displayName: "Flame distance",
    },
  };

  useEffect(() => {
    if (index !== null || index !== undefined) {
      setCurrIndex((prev) => index);
    }
  }, [data, index]);

  console.log(data[currIndex], "data to show");
  return (
    <>
      {size.width >= 1024 ? (
        <Modal isOpen={openModal} onClose={closeModal} isCentered="true">
          <ModalOverlay />
          <ModalContent style={{ borderRadius: "12px", minWidth: "1000px" }}>
            <ModalHeader padding="0px">
              <div
                className={` px-3 py-4 flex gap-2 justify-between items-center bg-[#F5F5F5] rounded-tr-xl rounded-tl-xl`}
              >
                <div className="text-sm flex gap-4 items-center justify-between  w-full">
                  <div className="flex gap-4">
                    {data?.length > 0 && currIndex + 1}. Board no :{" "}
                    {data?.[currIndex]?.boardNo !== null &&
                    data?.[currIndex]?.boardNo !== undefined ? (
                      data[currIndex].boardNo
                    ) : (
                      <Skeleton height={"20px"} width={"100px"} />
                    )}
                    {(data?.[currIndex]?.lastAnalysisTime && (
                      <div className="flex gap-2 items-center  justify-end">
                        <p className="text-sm md:text-sm text-[#79767D]">
                          Last updated
                        </p>
                        <p className="text-sm md:text-sm text-[#79767D]">
                          {formatDateTime(
                            data?.[currIndex]?.lastAnalysisTime ||
                              data?.[currIndex]?.timestamp
                          )}
                        </p>
                      </div>
                    )) ||
                      (data?.[currIndex]?.timestamp && (
                        <div className="flex gap-2 items-center  justify-end">
                          <p className="text-sm md:text-sm text-[#79767D]">
                            Last updated
                          </p>
                          <p className="text-sm md:text-sm text-[#79767D]">
                            {formatDateTime(
                              data?.[currIndex]?.lastAnalysisTime ||
                                data?.[currIndex]?.timestamp
                            )}
                          </p>
                        </div>
                      ))}
                  </div>
                  <div>
                    {data?.[currIndex]?.alert &&
                      (data?.[currIndex]?.alert != "Normal" ? (
                        <p className="text-sm md:text-sm text-[#79767D] border px-4 py-1 rounded-md border-[#E46962] bg-[#FCEEEE] w-[300px] whitespace-nowrap truncate">
                          Alert:{" "}
                          <Tooltip
                            label={data?.[currIndex]?.alert}
                            hasArrow
                            placement="top"
                          >
                            <span className="font-bold">
                              {" "}
                              {data?.[currIndex]?.alert}
                            </span>
                          </Tooltip>
                        </p>
                      ) : (
                        <p className="text-sm md:text-sm text-[#79767D] border px-4 py-1 rounded-md border-[#A5E28D] bg-[#C0ECB1] w-full ">
                          No alert
                        </p>
                      ))}
                  </div>
                </div>

                <div className="text-sm  cursor-pointer" onClick={closeModal}>
                  <HighlightOffIcon />
                </div>
              </div>
            </ModalHeader>
            <ModalBody pos="relative" px="10px">
              <div className="flex flex-row items-center gap-1 relative h-full pb-4 mt-3">
                {data.length > 1 && (
                  <IconButton
                    bg={"transparent"}
                    rounded={"full"}
                    size={"sm"}
                    boxShadow={"md"}
                    icon={<ChevronLeftIcon />}
                    onClick={() => toggleMove(1)}
                  />
                )}
                <div className="flex flex-col gap-4 w-full">
                  <div className="grid grid-cols-7 border rounded-md ">
                    <div className="text-sm text-[#3E3C42] col-span-3 font-medium text-center p-2 bg-[#DEF]">
                      Average flame distance {"(px)"}
                    </div>
                    <div className="text-sm text-[#3E3C42] col-span-2 font-medium text-center p-2 bg-[#DEF]">
                      Area coldspots {"(%)"}
                    </div>
                    <div className="text-sm text-[#3E3C42] col-span-2 font-medium text-center p-2 bg-[#DEF]">
                      Area hotspots {"(%)"}
                    </div>

                    <div className="text-sm text-[#3E3C42] col-span-3 text-center p-2">
                      {(
                        data?.[currIndex]?.["averageFlameDistance(px)"] ||
                        data?.[currIndex]?.["avgFlameDistance"]
                      )?.toFixed(2) || "-"}
                    </div>
                    <div className="text-sm text-[#3E3C42] col-span-2 text-center p-2">
                      {(
                        data?.[currIndex]?.["areaColdspots(%)"] ||
                        data?.[currIndex]?.["coldSpotPercentage"]
                      )?.toFixed(2) || "-"}
                    </div>
                    <div className="text-sm text-[#3E3C42] col-span-2 text-center p-2">
                      {(
                        data?.[currIndex]?.["areaHotspots(%)"] ||
                        data?.[currIndex]?.["hotSpotPercentage"]
                      )?.toFixed(2) || "-"}
                    </div>
                  </div>
                  <div className="w-full flex gap-2 overflow-x-auto">
                    <>
                      {camMap?.cameraKeys?.length > 0 &&
                      camMap?.cameraMapping ? (
                        camMap.cameraKeys.map((cameraKey, i) => {
                          const mappedValues = camMap.cameraMapping[cameraKey];

                          return (
                            <div key={i} className="w-full flex flex-col gap-1">
                              <p className="w-full justify-center border rounded-md font-semibold text-sm h-[35px] flex items-center">
                                {cameraKey}
                              </p>
                              {data[currIndex]?.[mappedValues?.[1]] ? (
                                <ReactCardFlip
                                  isFlipped={flip[i] || false}
                                  flipDirection="vertical"
                                  containerClassName="w-full h-full"
                                >
                                  {/* first view original image */}
                                  <div className="w-full h-full  ">
                                    <IconButton
                                      aria-label="flip-image-front"
                                      position={"absolute"}
                                      bg={"#616161"}
                                      size={"xs"}
                                      color={"white"}
                                      _hover={{ bg: "#616161" }}
                                      onClick={() => handleImageFlip(i)}
                                      className="absolute right-0 bottom-0 text-white"
                                      icon={
                                        <Cameraswitch
                                          fontSize={"medium"}
                                          className="p-[2px]"
                                        />
                                      }
                                    />
                                    <img
                                      src={data[currIndex]?.[mappedValues?.[0]]}
                                      alt="not supported"
                                      className="w-full h-[300px] rounded-lg "
                                    />
                                  </div>
                                  {/* fliping to show annotated image */}
                                  <div className="w-full h-full relative">
                                    {/* overlay over image to show zone and image icon */}

                                    <img
                                      src={data[currIndex]?.[mappedValues?.[1]]}
                                      alt="not supported"
                                      className="w-full h-[300px] rounded-lg "
                                    />
                                    <IconButton
                                      aria-label="flip-image-back"
                                      bg="#616161"
                                      color={"white"}
                                      size={"xs"}
                                      _hover={{ bg: "#616161" }}
                                      onClick={() => handleImageFlip(i)}
                                      position={"absolute"}
                                      bottom={0}
                                      right={0}
                                      icon={
                                        <Cameraswitch
                                          fontSize={"medium"}
                                          className="p-[2px]"
                                        />
                                      }
                                    />
                                  </div>
                                </ReactCardFlip>
                              ) : (
                                <CameraFeed
                                  key={i}
                                  cameraName={mappedValues?.[0]}
                                  data={data[currIndex]}
                                  showLargeImage={"300px"}
                                />
                              )}
                            </div>
                          );
                        })
                      ) : (
                        // {camMap?.cameraKeys?.length > 0 ? (
                        //   camMap?.cameraKeys?.map((CameraName, i) => {
                        //     return (
                        //       camMap?.cameraMapping &&
                        //       Object.keys(camMap?.cameraMapping)?.map(
                        //         (CameraType) => {
                        //           return camMap?.cameraMapping;
                        //         }
                        //       )
                        //     );

                        // return (
                        //   <CameraFeed
                        //     key={i}
                        //     cameraName={ele}
                        //     data={data[currIndex]}
                        //   />
                        // );
                        // })
                        <>
                          {[1, 2].map((e, i) => {
                            return (
                              <div
                                key={i}
                                className="h-[200px] w-full flex justify-center items-center border font-semibold rounded-md"
                              >
                                No Data
                              </div>
                            );
                          })}
                        </>
                      )}
                    </>
                  </div>
                </div>
                {data.length > 1 && (
                  <IconButton
                    bg={"transparent"}
                    rounded={"full"}
                    size={"sm"}
                    boxShadow={"md"}
                    icon={<ChevronRightIcon />}
                    onClick={() => toggleMove(2)}
                  />
                )}
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        <Drawer onClose={closeModal} isOpen={openModal} size="full">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader padding="0px">
              {" "}
              <div
                className={` px-3 py-4 flex justify-between items-center bg-[#F5F5F5] rounded-tr-xl rounded-tl-xl`}
              >
                <div className="text-sm flex flex-col gap-2 justify-start">
                  {data?.length > 0 && currIndex + 1}. Board no :{" "}
                  {data?.[currIndex]?.boardNo !== null &&
                  data?.[currIndex]?.boardNo !== undefined ? (
                    data[currIndex].boardNo
                  ) : (
                    <Skeleton height={"20px"} width={"100px"} />
                  )}
                  {(data?.[currIndex]?.lastAnalysisTime && (
                    <div className="flex gap-2 items-center  justify-start">
                      <p className="text-sm md:text-sm text-[#79767D]">
                        Last updated
                      </p>
                      <p className="text-sm md:text-sm text-[#79767D]">
                        {formatDateTime(
                          data?.[currIndex]?.lastAnalysisTime ||
                            data?.[currIndex]?.timestamp
                        )}
                      </p>
                    </div>
                  )) ||
                    (data?.[currIndex]?.timestamp && (
                      <div className="flex gap-2 items-center  justify-start">
                        <p className="text-sm md:text-sm text-[#79767D]">
                          Last updated
                        </p>
                        <p className="text-sm md:text-sm text-[#79767D]">
                          {formatDateTime(
                            data?.[currIndex]?.lastAnalysisTime ||
                              data?.[currIndex]?.timestamp
                          )}
                        </p>
                      </div>
                    ))}
                  {data?.[currIndex]?.alert &&
                    (data?.[currIndex]?.alert != "Normal" ? (
                      <p className="text-sm md:text-sm text-[#79767D] border px-2 py-1 rounded-md border-[#E46962] bg-[#FCEEEE] w-[250px] whitespace-nowrap truncate">
                        Alert:{" "}
                        <Tooltip
                          label={data?.[currIndex]?.alert}
                          hasArrow
                          placement="top"
                        >
                          <span className="font-bold">
                            {" "}
                            {data?.[currIndex]?.alert}
                          </span>
                        </Tooltip>
                      </p>
                    ) : (
                      <p className="text-sm md:text-sm text-[#79767D] border px-4 py-1 rounded-md border-[#A5E28D] bg-[#C0ECB1] w-[150px] text-center ">
                        No alert
                      </p>
                    ))}
                </div>

                <div className="text-sm  cursor-pointer" onClick={closeModal}>
                  <HighlightOffIcon />
                </div>
              </div>
            </DrawerHeader>
            <DrawerBody padding="0px">
              <div className="flex flex-row items-center gap-1 relative h-full ">
                {data.length > 1 && (
                  <IconButton
                    bg={"transparent"}
                    rounded={"full"}
                    size={"sm"}
                    boxShadow={"md"}
                    icon={<ChevronLeftIcon />}
                    onClick={() => toggleMove(1)}
                  />
                )}
                <div className="flex flex-col gap-4 w-full h-full mt-4">
                  <div className="grid grid-cols-7 border rounded-md ">
                    <div className="text-sm text-[#3E3C42] col-span-5 font-medium text-center p-2 bg-[#DEF]">
                      Average flame distance {"(px)"}
                    </div>
                    <div className="text-sm text-[#3E3C42] col-span-2 text-center p-2">
                      {(
                        data?.[currIndex]?.["averageFlameDistance(px)"] ||
                        data?.[currIndex]?.["avgFlameDistance"]
                      )?.toFixed(2) || "-"}
                    </div>
                    <div className="text-sm text-[#3E3C42] col-span-5 font-medium text-center p-2 bg-[#DEF]">
                      Area coldspots {"(%)"}
                    </div>
                    <div className="text-sm text-[#3E3C42] col-span-2 text-center p-2">
                      {(
                        data?.[currIndex]?.["areaColdspots(%)"] ||
                        data?.[currIndex]?.["coldSpotPercentage"]
                      )?.toFixed(2) || "-"}
                    </div>
                    <div className="text-sm text-[#3E3C42] col-span-5 font-medium text-center p-2 bg-[#DEF]">
                      Area hotspots {"(%)"}
                    </div>

                    <div className="text-sm text-[#3E3C42] col-span-2 text-center p-2">
                      {(
                        data?.[currIndex]?.["areaHotspots(%)"] ||
                        data?.[currIndex]?.["hotSpotPercentage"]
                      )?.toFixed(2) || "-"}
                    </div>
                  </div>
                  <div className="w-full flex flex-col gap-2 pb-3">
                    <>
                      {camMap?.cameraKeys?.length > 0 &&
                      camMap?.cameraMapping ? (
                        camMap.cameraKeys.map((cameraKey, i) => {
                          const mappedValues = camMap.cameraMapping[cameraKey];

                          return (
                            <div key={i} className="w-full flex flex-col gap-1">
                              <p className="w-full justify-center border rounded-md font-semibold text-sm h-[35px] flex items-center">
                                {cameraKey}
                              </p>
                              {data[currIndex]?.[mappedValues?.[1]] ? (
                                <ReactCardFlip
                                  isFlipped={flip[i] || false}
                                  flipDirection="vertical"
                                  containerClassName="w-full h-full"
                                >
                                  {/* first view original image */}
                                  <div className="w-full h-full  ">
                                    <IconButton
                                      aria-label="flip-image-front"
                                      position={"absolute"}
                                      bg={"#616161"}
                                      size={"xs"}
                                      color={"white"}
                                      _hover={{ bg: "#616161" }}
                                      onClick={() => handleImageFlip(i)}
                                      className="absolute right-0 bottom-0 text-white"
                                      icon={
                                        <Cameraswitch
                                          fontSize={"medium"}
                                          className="p-[2px]"
                                        />
                                      }
                                    />
                                    <img
                                      src={data[currIndex]?.[mappedValues?.[0]]}
                                      alt="not supported"
                                      className="w-full h-[250px] rounded-lg "
                                    />
                                  </div>
                                  {/* fliping to show annotated image */}
                                  <div className="w-full h-full relative">
                                    {/* overlay over image to show zone and image icon */}

                                    <img
                                      src={data[currIndex]?.[mappedValues?.[1]]}
                                      alt="not supported"
                                      className="w-full h-[250px] rounded-lg "
                                    />
                                    <IconButton
                                      aria-label="flip-image-back"
                                      bg="#616161"
                                      color={"white"}
                                      size={"xs"}
                                      _hover={{ bg: "#616161" }}
                                      onClick={() => handleImageFlip(i)}
                                      position={"absolute"}
                                      bottom={0}
                                      right={0}
                                      icon={
                                        <Cameraswitch
                                          fontSize={"medium"}
                                          className="p-[2px]"
                                        />
                                      }
                                    />
                                  </div>
                                </ReactCardFlip>
                              ) : (
                                <CameraFeed
                                  key={i}
                                  cameraName={mappedValues?.[0]}
                                  data={data[currIndex]}
                                  showLargeImage={"250px"}
                                />
                              )}
                            </div>
                          );
                        })
                      ) : (
                        // {camMap?.cameraKeys?.length > 0 ? (
                        //   camMap?.cameraKeys?.map((CameraName, i) => {
                        //     return (
                        //       camMap?.cameraMapping &&
                        //       Object.keys(camMap?.cameraMapping)?.map(
                        //         (CameraType) => {
                        //           return camMap?.cameraMapping;
                        //         }
                        //       )
                        //     );

                        // return (
                        //   <CameraFeed
                        //     key={i}
                        //     cameraName={ele}
                        //     data={data[currIndex]}
                        //   />
                        // );
                        // })
                        <>
                          {[1, 2].map((e, i) => {
                            return (
                              <div
                                key={i}
                                className="h-[200px] w-full flex justify-center items-center border font-semibold rounded-md"
                              >
                                No Data
                              </div>
                            );
                          })}
                        </>
                      )}
                    </>
                  </div>
                </div>
                {data.length > 1 && (
                  <IconButton
                    bg={"transparent"}
                    rounded={"full"}
                    size={"sm"}
                    boxShadow={"md"}
                    icon={<ChevronRightIcon />}
                    onClick={() => toggleMove(2)}
                  />
                )}
              </div>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};

export default DetailModal;
