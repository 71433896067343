import { Link, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { recommendations } from "../Sinterflame";
import { indexWordMap } from "../Sinterflame";
import { Text, Tooltip } from "@chakra-ui/react";
import Feedback from "./Feedback";
import { capitalizeFirstLetter } from "../../../util/sentenceCase";

const colorMap = {
  "healthy flame": "#74C154",
  "flame out": "#FFCB11",
  "low flame": "#E46962",
  "view obstructed": "#000",
};

const CamCard = ({ plantId, cameraName, data, alert, toggleRef }) => {
  const [lastGoodData, setLastGoodData] = useState(data);
  const [feedback, setFeedback] = useState(false);
  const [feedbackType, setFeedbackType] = useState("");
  const [disableFeedback, setDisableFeedback] = useState(false);

  useEffect(() => {
    if (data.hasOwnProperty("lastGoodRecord")) {
      setLastGoodData(data.lastGoodRecord);
    } else setLastGoodData(data);

    setDisableFeedback(data?.hasFeedback);
  }, [data]);

  return (
    <div className="flex flex-col gap-2 p-2 rounded border border-[#EBEBEB] items-start min-w-[160px] h-full w-full">
      <p className="text-[#525056] text-center w-full text-sm font-medium">
        {capitalizeFirstLetter(cameraName.replace(/^(\D+)(\d+)/, "$1 $2"))}
      </p>
      {/*Image */}
      <div className="relative flex justify-center items-center rounded bg-black w-full  ">
        <img
          src={lastGoodData?.annotatedImage}
          alt="image"
          className="rounded max-w-full h-[88px]"
        />
        <p
          className="absolute top-[2px] right-[2px] rounded text-[8px] font-bold text-[#000] py-[2px] px-1"
          style={{
            backgroundColor:
              colorMap[indexWordMap[lastGoodData?.healthIndex]?.toLowerCase()],
          }}
        >
          {indexWordMap[lastGoodData?.healthIndex]}
        </p>
      </div>
      {/*conf and average */}
      <div className="flex flex-col gap-1 justify-between w-full">
        <p className="text-xs text-[#938F96]">
          Confidence{" "}
          <span className="text-[#69B04B] text-xs font-medium">
            {lastGoodData?.conf?.toFixed(2)
              ? lastGoodData?.conf?.toFixed(2)
              : "00.00"}
            %
          </span>
        </p>
        <p className="text-[#938F96] text-xs">
          30-min avg:{" "}
          <span
            className="text-[9px] font-bold"
            style={{
              color:
                colorMap[indexWordMap[data?.halfHourAverage]?.toLowerCase()],
            }}
          >
            {indexWordMap[data?.halfHourAverage]}
          </span>
        </p>
      </div>
      {/*Recommendation */}
      <div
        className="w-full border  rounded-lg p-2 text-[#605D64] text-[10px]"
        style={{
          backgroundColor: data?.healthIndex == 2 ? "#EFFCEE" : "#FCEEEE",
          borderColor: data?.healthIndex == 2 ? "#6CE462" : "#E46962",
        }}
      >
        <Tooltip
          label={
            data.hasOwnProperty("remarks")
              ? data?.remarks
              : recommendations[data?.healthIndex]
          }
          hasArrow
          placement="right"
        >
          <p className="w-full truncate h-[20px] flex items-center">
            {data.hasOwnProperty("remarks")
              ? data?.remarks
              : recommendations[data?.healthIndex] ?? "No data"}
          </p>
        </Tooltip>
      </div>
      {/*times, button and feedback */}
      <div className="h-full flex flex-col w-full justify-between gap-2">
        <div className="flex flex-col gap-2 text-[10px]">
          <div className="flex flex-col gap-1 whitespace-nowrap">
            <p className="text-[#938F96]">Last updated</p>
            <p className=" text-[#79767D]">
              {new Date(data?.timestamp).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "2-digit",
              })}
              &nbsp;
              {new Date(data?.timestamp).toLocaleTimeString()}
            </p>
          </div>
          {data?.hasOwnProperty("lastGoodRecord") && (
            <div className="flex flex-col gap-1 whitespace-nowrap">
              <p className="text-[#938F96]">Last flame view</p>
              <p className="text-[#79767D]">
                {new Date(data?.lastGoodRecord?.timestamp).toLocaleDateString(
                  "en-US",
                  {
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                  }
                )}
                &nbsp;
                {new Date(data?.lastGoodRecord?.timestamp).toLocaleTimeString()}
              </p>
            </div>
          )}
        </div>
        <div className="flex justify-between gap-1 items-center w-full">
          <Link
            to={`./${plantId}/${cameraName}`}
            style={{ textDecoration: "none" }}
            className="w-fit"
          >
            <button className="w-fit hover:scale-105 text-center py-[5px] px-2 text-white text-[10px] font-medium bg-[#084298] rounded-sm">
              View detail
            </button>
          </Link>
          {!disableFeedback && (
            <div className="flex flex-col gap-0">
              <p className="text-[#938F96] text-[10px] font-medium">
                Feedback:{" "}
              </p>
              <div className="flex justify-between gap-1 items-center">
                <img
                  src="/SinterflameIcons/thumU.svg"
                  alt="thumbs up"
                  className="hover:scale-105 h-4 w-4 cursor-pointer"
                  onClick={() => {
                    setFeedbackType("GOOD");
                    setFeedback(true);
                  }}
                />
                <img
                  src="/SinterflameIcons/thumD.svg"
                  alt="thumbs down"
                  className="hover:scale-105 h-4 w-4 cursor-pointer"
                  onClick={() => {
                    setFeedbackType("BAD");
                    setFeedback(true);
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      {feedback && feedbackType != "" && (
        <Feedback
          openModal={feedback}
          closeModal={() => setFeedback(false)}
          clientId={data?.clientId}
          material={"sinterflame"}
          cameraId={cameraName}
          plantName={plantId}
          id={data?.id}
          type={feedbackType}
          setDisableFeedback={setDisableFeedback}
        />
      )}
    </div>
  );
};

export default CamCard;
