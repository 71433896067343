import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Textarea,
  useToast,
  list,
  Select,
} from '@chakra-ui/react';
import axios from 'axios';
import { baseURL } from '../../../index';
import { useContext, useEffect, useState } from 'react';
import NavContext from '../../NavContext';

let wordLimit = 100;

const tagName = {
  dusty: 'Dusty',
  healthy: 'Healthy',
  hot: 'Hot',
  hot_dusty: 'Hot & Dusty',
  negative: 'Negative',
};

const Feedback = ({
  openModal,
  closeModal,
  clientId,
  material,
  cameraId,
  plantName,
  id,
  health,
}) => {
  const { auth } = useContext(NavContext);
  const toast = useToast();
  const [correctPrediction, setCorrectPrediction] = useState('');
  const [text, setText] = useState('');
  const [exceedLimit, setExceedLimit] = useState(false);

  const apiCall = async () => {
    toast({
      title: 'Feedback Submitted',
      description: 'Your feedback has been recieved',
      status: 'success',
      position: 'top-right',
      duration: 3000,
      isClosable: true,
    });
    closeModal();
    // const requestData = JSON.stringify({
    //   clientId: clientId,
    //   material: material,
    //   plantName: plantName,
    //   cameraId: cameraId,
    //   recordId: id,
    //   feedbackStatus: 'BAD',
    //   feedbackMessage: text,
    // });
    // if (issue.length == 0 || exceedLimit || text.length == 0) {
    //   toast({
    //     title: 'Invalid data',
    //     description: 'Please ensure that data entered is valid',
    //     status: 'warning',
    //     position: 'top-right',
    //     duration: 1000,
    //     isClosable: true,
    //   });
    //   return;
    // }
    // const response = await axios
    //   .post(baseURL + 'vision/v2/sizing/feedback/single/', requestData, {
    //     credentials: 'same-origin',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       'X-Auth-Token': auth,
    //     },
    //   })
    //   .then((response) => {
    //     if (response.data.message == 'Success') {
    //       toast({
    //         title: 'Feedback Submitted',
    //         description: 'Your feedback has been recieved',
    //         status: 'success',
    //         position: 'top-right',
    //         duration: 3000,
    //         isClosable: true,
    //       });
    //       closeModal();
    //     } else {
    //       toast({
    //         title: 'Error',
    //         description: 'Please try again',
    //         status: 'error',
    //         position: 'top-right',
    //         duration: 1000,
    //         isClosable: true,
    //       });
    //     }
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  };

  const textChangeHandler = (e) => {
    const inputValue = e.target.value;
    // Split based on spaces, full stops, and common special characters
    const words = inputValue.split(/[\s.!,;?]+/).filter(Boolean);

    if (words.length <= wordLimit) {
      setText(inputValue);
      if (exceedLimit) setExceedLimit(false);
    } else {
      setExceedLimit(true);
    }
  };

  console.log(health, 'Health');

  return (
    <Modal isOpen={openModal} onClose={closeModal} isCentered="true">
      <ModalOverlay />
      <ModalContent style={{ borderRadius: '12px' }} maxW="400px">
        <ModalBody pos="relative" p="24px" rounded="12px">
          <div className="flex flex-col gap-6">
            <div className="flex flex-col gap-4">
              <p className="text-base text-[#141619]">
                Please enter your feedback{' '}
              </p>
              <div className="flex flex-col gap-3">
                <div className="min-w-[80px]">
                  <Select
                    borderColor="#CAC5CD"
                    color="#605D64"
                    placeholder={'Select correct prediction'}
                    variant="outline"
                    className="!rounded-xl !text-sm !font-medium text-[#605D64]"
                    onChange={(e) => setCorrectPrediction(e.target.value)}
                    value={correctPrediction}
                  >
                    {Object.keys(tagName).map((key) => {
                      if (key !== health) {
                        return (
                          <option key={key} value={key}>
                            {tagName[key]}
                          </option>
                        );
                      }
                    })}
                  </Select>
                </div>
              </div>
            </div>
            <div className="flex flex-col relative">
              <p className="text-[#141619] text-base">Your feedback</p>
              <Textarea
                placeholder="Please explain your concern"
                mt="4px"
                mb="4px"
                isInvalid={exceedLimit}
                onChange={textChangeHandler}
              />
              <p className="absolute top-0 right-0 text-xs text-gray-600">
                Words: {text.split(/[\s.!,;?]+/).filter(Boolean).length}{' '}
                {`(Limit ${wordLimit})`}
              </p>
            </div>
            <div className="flex justify-end">
              <button
                className="text-white py-3 px-4 rounded-[100px] bg-[#084298] text-base font-medium w-[50%] hover:opacity-60"
                onClick={() => apiCall()}
              >
                Submit
              </button>
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Feedback;
