import { useWindowSize } from "@uidotdev/usehooks";
import ReactApexChart from "react-apexcharts";

export const CrusherLineChart = ({
  title = "",
  data = [],
  combinations = [],
  measurement,
}) => {
  
  const size = useWindowSize();
  const width = size.width < 768 ? 700 : "100%";
  const strokeWidth = new Array(data?.length)?.fill(3);
  const strokeDashArray = new Array(data?.length)?.fill(7);

  const series = data;
  const options = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: false,
          reset: true ,
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: strokeWidth,
      curve: "straight",
      dashArray: strokeDashArray,
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "left",
    },
    markers: {
      size: 7,
      hover: {
        sizeOffset: 6,
      },
    },
    xaxis: {
      categories: combinations,
      title: {
        text: "Unique combinations",
        style: {
          color: "#525056",
          fontSize: "12px",
          fontWeight: 400,
        },
      },
      labels:{
        show:true,
        style:{
          fontSize:"11px"
        }
      }
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return measurement === "percentage" ? val + "%" : val?.toFixed(2);   
        },
      },
      title: {
        text: measurement === "percentage" ? "Percentage" : "Size (in mm)",
        style: {
          color: "#525056",
          fontSize: "12px",
          fontWeight: 400,
        },
      },
    },
    tooltip: {
      y: [
        {
          title: {
            formatter: function (val) {
              return val;
            },
          },
        },
      ],
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  return (
    <div className={`w-full h-full  rounded-md bg-white border`}>
      <div className="px-4 pt-4 pb-6 w-full">
        <p className="font-medium text-base text-[#3E3C42]">{`${title}`}</p>
      </div>
      <div
        id="chart"
        className={`h-[250px] w-full overflow-y-hidden ${
          width === 700 ? "overflow-x-auto" : "overflow-hidden"
        }`}
      >
        <ReactApexChart
          options={options}
          series={series}
          height={"100%"}
          width={width}
        />
      </div>
    </div>
  );
};
