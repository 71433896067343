import CamCard from "./CamCard";
import { useRef, useState } from "react";
import DetailModal from "./DetailModal";
import OverviewGraphs from "./OverviewGraphs";
import { useWindowSize } from "@uidotdev/usehooks";

const Capitalize = (str) => {
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
};

const SortBurners = (data) => {
  const sortedData = {};
  const keys = Object.keys(data);
  keys.sort((a, b) => {
    const indexA = parseInt(a.match(/\d+/)[0], 10);
    const indexB = parseInt(b.match(/\d+/)[0], 10);
    return indexA - indexB;
  });
  keys.forEach((key) => {
    sortedData[key] = data[key];
  });
  return sortedData;
};

const getColNum = (val, x) => {
  if (val >= 1440) return Math.min(8, x);
  else if (val >= 900) return Math.min(4, Math.ceil(x / 2));
  else return Math.min(2, Math.ceil(x / 4));
};

const PlantCard = ({ PlantName, CamData }) => {
  const [openModal, setOpenModal] = useState(false);
  const toggleRef = useRef();
  const size = useWindowSize();
  let totalAlerts = [];
  let totalData = [];
  CamData = SortBurners(CamData);
  Object.keys(CamData).map((cam) => {
    if (CamData[cam][0].hasAlert) {
      totalAlerts.push(1);
      totalData.push(CamData[cam][0]);
    } else totalAlerts.push(0);
  });
  let sum = totalAlerts.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);
  return (
    <div
      className={`flex flex-col gap-3 col-span-2 ${
        Object.keys(CamData).length > 1 ? "xl:col-span-2" : "xl:col-span-1"
      }`}
    >
      <div className="flex flex-col bg-white rounded-xl pt-4 gap-2 w-full">
        <div className="flex justify-between px-3  ">
          <div className="flex flex-col md:flex-row gap-2 items-start md:items-center">
            <p className="text-base font-semibold">{Capitalize(PlantName)}</p>
            <p className="text-base font-medium">
              Suction pressure:{" "}
              <span
                style={{
                  color:
                    CamData[Object.keys(CamData || {})[0]][0]
                      ?.currentSuctionPressure >
                    CamData[Object.keys(CamData || {})[0]][0]
                      ?.maxSuctionPressure
                      ? "#FF3232"
                      : "#329932",
                }}
              >
                {CamData[
                  Object.keys(CamData || {})[0]
                ][0]?.currentSuctionPressure?.toFixed(2)}
              </span>{" "}
              mmwc
            </p>
          </div>
          {sum !== 0 && (
            <div className="flex gap-4 items-center">
              <p className="rounded-lg border-2 text-xs border-red-400 pl-2 pr-2 pt-1 pb-1 bg-[#F9DEDC]">
                {sum} alerts
              </p>
              <p
                className="text-[#DC362E] text-sm font-medium cursor-pointer"
                onClick={() => setOpenModal(true)}
              >
                See Detail
              </p>
              {openModal && (
                <DetailModal
                  openModal={openModal}
                  closeModal={() => setOpenModal(false)}
                  data={totalData}
                  index={0}
                />
              )}
            </div>
          )}
        </div>
        <div
          className="grid px-3 gap-[1px]"
          style={{
            gridTemplateColumns: `repeat(${getColNum(
              size.width,
              Object.keys(CamData)?.length
            )},1fr)`,
          }}
        >
          {Object.keys(CamData).map((cam, idx) => {
            return (
              <CamCard
                plantId={PlantName}
                cameraName={cam}
                data={CamData[cam][0]}
                alert={totalAlerts[idx]}
                toggleRef={toggleRef}
              />
            );
          })}
        </div>
        <OverviewGraphs
          CamData={CamData}
          toggleRef={toggleRef}
          plantName={PlantName}
        />
      </div>
    </div>
  );
};

export default PlantCard;
