import { useState, useContext } from "react";
import NavContext from "../../NavContext";
import { SinterBedContext } from "../components/SinterBedContext";

const useFetchData = (apiFunction, initialParams = {}) => {
  const { auth } = useContext(NavContext);
  const { initialData } = useContext(SinterBedContext);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const fetchData = async (params = initialParams) => {
    setLoading(true);
    try {
      const response = await apiFunction(
        auth,
        params,
        false,
        initialData.cameraInfo
      );
      if (response.data) {
        setData(response.data);
      } else {
        throw new Error("Error while fetching data!");
      }
    } catch (error) {
      console.error(error);
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, fetchData };
};

export default useFetchData;
