import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  IconButton,
} from "@chakra-ui/react";
import { useWindowSize } from "@uidotdev/usehooks";
import { useState } from "react";
import ReactCardFlip from "react-card-flip";
import {
  AddCircle,
  Cameraswitch,
  Close,
  ThumbDown,
  ThumbUp,
} from "@mui/icons-material";
import { formatDateTime } from "../utilis/dateTimeFormatter";
import OverallIndexChart from "../../Charts/SinterBeltCharts/OverallIndexChart";
import Feedback from "./Feedback";

const CommanCardsWithFlipper = ({
  data,
  handleDelete,
  flip,
  handleImageFlip,
  closeModal,
  handleThumbsUpClick,
  handleThumbsDownClick,
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 items-start">
      {[...Array(4)].map((i, idx) => {
        return data.length >= idx + 1 ? (
          <div
            className="px-6 pb-4 pt-2  md:pt-4 rounded-lg flex flex-col items-center gap-2 min-h-[300px]"
            style={{
              boxShadow:
                "4px 4px 7px 0px rgba(0, 0, 0, 0.05), -4px -4px 7px 0px rgba(0, 0, 0, 0.05)",
            }}
            key={`image-card-flip-${idx}`}
          >
            <div className="flex justify-between items-center w-full ">
              <div className="flex flex-row gap-3 items-center">
                <p className="text-sm text-[#525056] font-bold capitalize">
                  {data[idx]?.sequenceNo}. {data[idx]?.board}
                </p>
                <p className="text-xs md:text-sm text-[#938F96]">
                  {data[idx]?.time_stamp &&
                    formatDateTime(data[idx]?.time_stamp)}
                </p>
              </div>
              <img
                src="/delete.svg"
                alt="Not supported"
                className="w-[12px] h-[14px] cursor-pointer"
                onClick={() => handleDelete(data[idx]?.recordId)}
                style={{
                  filter: data.length > 2 ? "" : "grayscale(100%)",
                }}
              />
            </div>
            <div className="flex flex-col gap-4 md:gap-3 items-center w-full ">
              {/* board no camera  */}
              <div className="w-full h-full">
                <img
                  src={data[idx]?.cam2OriginalImage}
                  alt="not supported"
                  className="w-full h-[110px] object-cover rounded-lg"
                />
              </div>

              {/* flipper for belt camera */}
              <div className="h-full w-full flex justify-center items-center rounded-lg">
                {data[idx]?.board_permeability_index !== null &&
                data[idx].annotated_image ? (
                  <ReactCardFlip
                    isFlipped={flip[data[idx]?.recordId] || false}
                    flipDirection="vertical"
                    containerClassName="w-full h-full"
                  >
                    {/* first view original image */}
                    <div className="w-full h-full">
                      <IconButton
                        aria-label="flip-image-front"
                        position={"absolute"}
                        bg={"#616161"}
                        size={"xs"}
                        color={"white"}
                        _hover={{ bg: "#616161" }}
                        onClick={() => handleImageFlip(data[idx]?.recordId)}
                        className="absolute right-0 bottom-0 text-white"
                        icon={
                          <Cameraswitch
                            fontSize={"medium"}
                            className="p-[2px]"
                          />
                        }
                      />
                      <img
                        src={data[idx]?.original_image}
                        alt="not supported"
                        className="w-full h-[150px] rounded-lg"
                      />
                    </div>
                    {/* fliping to show annotated image */}
                    <div className="w-full h-full">
                      {/* overlay over image to show zone and image icon */}
                      <div className="absolute w-full h-full flex flex-row">
                        <div className="flex flex-col justify-between items-start w-full h-full">
                          <p className="bg-[#616161] rounded-sm text-white px-1 text-xs">
                            N1
                          </p>
                          <p className="bg-[#616161] rounded-sm text-white px-1 text-xs">
                            N2
                          </p>
                          <div className="flex flex-col items-start gap-1">
                            <p className="bg-[#616161] rounded-sm text-white px-1 text-xs">
                              N3
                            </p>
                            <p className="h-6"></p>
                          </div>
                        </div>
                        <div className="flex flex-col justify-between items-end  w-full h-full">
                          <p className="bg-[#616161] rounded-sm text-white px-1 text-xs">
                            S1
                          </p>
                          <p className="bg-[#616161] rounded-sm text-white px-1 text-xs">
                            S2
                          </p>
                          <div className="flex flex-col items-end gap-1">
                            <p className="bg-[#616161] rounded-sm text-white px-1 text-xs">
                              S3
                            </p>
                            <IconButton
                              aria-label="flip-image-back"
                              bg="#616161"
                              color={"white"}
                              size={"xs"}
                              _hover={{ bg: "#616161" }}
                              onClick={() =>
                                handleImageFlip(data[idx]?.recordId)
                              }
                              icon={
                                <Cameraswitch
                                  fontSize={"medium"}
                                  className="p-[2px]"
                                />
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <img
                        src={data[idx]?.annotated_image}
                        alt="not supported"
                        className="w-full h-[150px] rounded-lg"
                      />
                    </div>
                  </ReactCardFlip>
                ) : (
                  <img
                    src={data[idx]?.original_image}
                    alt="not supported"
                    className="w-full h-[200px] md:h-[150px] rounded-lg"
                  />
                )}
              </div>
              {data[idx]?.board_permeability_index !== null && (
                <div className="flex gap-3 items-center self-start w-full">
                  <p className="text-[#605D64] text-sm font-medium">
                    Permeability Index
                  </p>

                  {data[idx]?.board_permeability_index != 0 ? (
                    <OverallIndexChart
                      value={data[idx]?.board_permeability_index}
                      permeabilityValue={data[idx]?.value}
                    />
                  ) : (
                    <div className="text-[#FFA500] font-bold text-sm">
                      Belt Downtime
                    </div>
                  )}
                </div>
              )}
              {data[idx]?.maxVNotchWidth ? (
                <div className="flex gap-3 text-[#605D64] text-sm items-center self-start w-full">
                  <p className="font-medium">V Notch </p>
                  <p className="font-semibold">{data[idx]?.maxVNotchWidth}px</p>
                </div>
              ) : null}
              {data[idx]?.maxGrateBarMissingWidth ? (
                <div className="flex gap-3 text-[#605D64] text-sm items-center self-start w-full">
                  <p className="font-medium">Grate Bar Missing </p>
                  <p className="font-semibold">
                    {data[idx]?.maxGrateBarMissingWidth}px
                  </p>
                </div>
              ) : null}
              <div className="w-full flex flex-row items-center justify-end">
                <p className="text-sm">Feedback : </p>
                <IconButton
                  size={"xs"}
                  padding={0}
                  backgroundColor={"white"}
                  icon={<ThumbUp sx={{ fontSize: "18px", color: "#024d87" }} />}
                  onClick={() => handleThumbsUpClick(data[idx]?.recordId)}
                />
                <IconButton
                  size={"xs"}
                  padding={0}
                  backgroundColor={"white"}
                  icon={
                    <ThumbDown sx={{ fontSize: "18px", color: "#024d87" }} />
                  }
                  onClick={() => handleThumbsDownClick(data[idx]?.recordId)}
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            className="px-6 pb-4 pt-4 rounded-lg flex flex-col items-center gap-2 h-[300px] md:h-full w-full"
            style={{
              boxShadow:
                "4px 4px 7px 0px rgba(0, 0, 0, 0.05), -4px -4px 7px 0px rgba(0, 0, 0, 0.05)",
            }}
          >
            <div className="rounded-2xl border-2 border-[#79767D] border-dashed w-full h-full flex justify-center items-center">
              <div className="flex gap-3 items-center">
                <IconButton
                  icon={
                    <AddCircle sx={{ color: "#9E9E9E", fontSize: "40px" }} />
                  }
                  rounded={"full"}
                  padding={0}
                  onClick={closeModal}
                  backgroundColor={"transparent"}
                />
                <p className="text-[#79767D] text-base">Add Board</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
const CompareModal = ({
  openModal,
  closeModal,
  data,
  setData,
  clientId,
  plantName,
}) => {
  const [flip, setFlip] = useState({});
  const [openFeedback, setopenFeedback] = useState(false);
  const [feedBackData, setFeedBackData] = useState({
    id: null,
    feedbackStatus: "",
  });
  const size = useWindowSize();
  const handleImageFlip = (recordId) => {
    setFlip((prevState) => ({
      ...prevState,
      [recordId]: !prevState[recordId],
    }));
  };
  const handleDelete = (recordId) => {
    if (data.length > 2) {
      setData((prev) => {
        let updatedData = [...prev];
        let idx = updatedData.findIndex((item) => item.recordId === recordId);
        updatedData.splice(idx, 1);
        return updatedData;
      });
    }
  };
  const handleThumbsUpClick = (id) => {
    setFeedBackData((prev) => ({ ...prev, id: id, feedbackStatus: "GOOD" }));
    setopenFeedback((prev) => true);
  };
  const handleThumbsDownClick = (id) => {
    setFeedBackData((prev) => ({ ...prev, id: id, feedbackStatus: "BAD" }));
    setopenFeedback((prev) => true);
  };

  return (
    <>
      {size.width >= 768 ? (
        <Modal
          isOpen={openModal}
          onClose={closeModal}
          isCentered="true"
        >
          <ModalOverlay />
          <ModalContent
            style={{ borderRadius: "12px" }}
            maxW={"90vw"}
            maxH={"95vh"}
          >
            <ModalHeader
              padding="0px"
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              position={"relative"}
            >
              <IconButton
                position={"absolute"}
                top={-5}
                rounded={"full"}
                bg={"white"}
                cursor={"pointer"}
                icon={<Close sx={{ color: "gray" }} />}
                onClick={closeModal}
                boxShadow={"md"}
              />
            </ModalHeader>
            <ModalBody
              p="24px"
              overflowY={"auto"}
            >
              <CommanCardsWithFlipper
                data={data}
                handleDelete={handleDelete}
                flip={flip}
                handleImageFlip={handleImageFlip}
                closeModal={closeModal}
                handleThumbsUpClick={handleThumbsUpClick}
                handleThumbsDownClick={handleThumbsDownClick}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        <Drawer
          onClose={closeModal}
          isOpen={openModal}
          size="full"
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader padding="0px">
              <div className="flex justify-center items-center">
                <IconButton
                  rounded={"full"}
                  bg={"white"}
                  cursor={"pointer"}
                  icon={<Close sx={{ color: "gray" }} />}
                  onClick={closeModal}
                  boxShadow={"md"}
                />
              </div>
            </DrawerHeader>
            <DrawerBody
              padding="24px"
              w={"full"}
            >
              <CommanCardsWithFlipper
                data={data}
                handleDelete={handleDelete}
                flip={flip}
                handleImageFlip={handleImageFlip}
                closeModal={closeModal}
                handleThumbsUpClick={handleThumbsUpClick}
                handleThumbsDownClick={handleThumbsDownClick}
              />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
      {feedBackData && openFeedback && feedBackData?.id && (
        <Feedback
          openModal={openFeedback}
          closeModal={() => setopenFeedback((prev) => false)}
          clientId={clientId}
          material={"SINTERBELT"}
          // cameraId={cameraRef.current}
          plantName={plantName}
          id={feedBackData?.id}
          {...(feedBackData?.feedbackStatus && {
            feedbackStatus: feedBackData.feedbackStatus,
          })}
        />
      )}
    </>
  );
};

export default CompareModal;
