import axios from "axios";
import { mulpicJsplBaseUrl } from "../Mulpic";

export const getMatrixQualityDataApi = async (auth, setLoading) => {
  try {
    setLoading((prev) => true);
    const res = await axios.get(
      `${mulpicJsplBaseUrl}mulpic/matrix/get`

      // requestData,
      // {
      //   credentials: "same-origin",
      //   headers: {
      //     "Content-Type": "application/json",
      //     "X-Auth-Token": auth,
      //   },
      // }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data: data,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      error: error,
    };
  } finally {
    setLoading((prev) => false);
  }
};

export const updateMatrixQualityRowApi = async (
  auth,
  requestData,
  setLoading
) => {
  try {
    setLoading(true);
    const res = await axios.post(
      `${mulpicJsplBaseUrl}mulpic/matrix/add`,
      requestData && requestData,
      {
        credentials: "same-origin",
        // headers: {
        //   "Content-Type": "application/json",
        //   "X-Auth-Token": auth,
        // },
      }
    );
    if (res.status === 200 && res.data) {
      return {
        success: true,
        id: res.data,
        message: "Row Added Successfully",
      };
    } else {
      throw new Error("Error while adding");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error while adding");
  } finally {
    setLoading(false);
  }
};
export const deleteMatrixQualityRowApi = async (
  auth,
  requestData,
  setLoading
) => {
  try {
    setLoading(true);
    const res = await axios.delete(`${mulpicJsplBaseUrl}mulpic/matrix/delete`, {
      credentials: "same-origin",
      data: requestData,
      // headers: {
      //   "Content-Type": "application/json",
      //   "X-Auth-Token": auth,
      // },
    });
    if (res.status === 200 && res.data) {
      return {
        success: true,
        id: res.data,
        message: "Row Added Successfully",
      };
    } else {
      throw new Error("Error while deleting");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error while deleting");
  } finally {
    setLoading(false);
  }
};

// to get unique steel grades
export const getUniqueSteelGradeDataApi = async (auth) => {
  try {
    const res = await axios.get(`${mulpicJsplBaseUrl}mulpic/get_steel_grades`, {
      credentials: "same-origin",
      // headers: {
      //   "Content-Type": "application/json",
      //   "X-Auth-Token": auth,
      // },
    });
    if (res.status === 200 && res.data) {
      return {
        success: true,
        data: res.data,
      };
    } else {
      throw new Error("Error while fetching steel grades");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error while fetching steel grades");
  }
};

// to generate flat file
export const generateFlatFileApi = async (auth) => {
  try {
    const res = await axios.post(
      `${mulpicJsplBaseUrl}mulpic/generate_flatfile`,
      {
        credentials: "same-origin",
        // headers: {
        //   "Content-Type": "application/json",
        //   "X-Auth-Token": auth,
        // },
      }
    );
    if (res.status === 200 && res.data) {
      return {
        success: true,
        data: res.data,
      };
    } else {
      throw new Error("Error while generating flat file");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error while fetching flat file");
  }
};
