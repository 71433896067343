import { useEffect, useState, useContext, useRef } from "react";
import NavContext from "../../NavContext";
import { useParams } from "react-router-dom";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import { baseURL } from "../../../index";
import axios from "axios";
import FuelSuctionChart from "../../Charts/SinterFlameCharts/FuelSuctionMap";
import {
  Select,
  Spinner,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Skeleton,
} from "@chakra-ui/react";
import { capitalizeFirstLetter } from "../../../util/sentenceCase";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";

const TempraturePressureAnalytics = ({
  plantId,
  cameraId,
  disable,
  plantCamMap,
}) => {
  let param = useParams();
  const { auth } = useContext(NavContext);
  const [graphData, setGraphData] = useState([]);
  const [sizeDataChanging, setSizeDataChanging] = useState(false);
  const [selectedRange, setSelectedRange] = useState(0);
  const [selectedPlant, setSelectedPlant] = useState(plantId);
  const [fromTime, setFromTime] = useState(
    new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10)
  );
  const [toTime, setToTime] = useState(
    new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10)
  );

  const handleRangeSelect = (e) => {
    setSelectedRange(e.target.value);
    if (e.target.value == 0) {
      setFromTime(
        new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 10)
      );
      setToTime(
        new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 10)
      );
    }
  };

  const getGraphDataApi = async () => {
    try {
      const requestData = JSON.stringify({
        clientId: param?.clientId,
        plantName: selectedPlant?.toLowerCase(),
        useCase: "SINTERFLAME",
        cameraId: "all",
        startDate: new Date(fromTime)?.getTime(),
        endDate: new Date(toTime)?.getTime(),
      });
      const response = await axios.post(
        baseURL + "vision/v2/processMonitoring/analysis/graphData/",
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response?.data) {
        setGraphData(response?.data);
        setSizeDataChanging(false);
      }
    } catch (error) {
      console.log(error);
      setSizeDataChanging(false);
    }
  };

  const handleClick = () => {
    setSizeDataChanging(true);
    getGraphDataApi();
  };

  useEffect(() => {
    handleClick();
  }, []);

  useEffect(() => {
    if (selectedRange == 1 && fromTime) {
      setToTime(new Date(fromTime).toISOString().slice(0, 10));
    }
  }, [fromTime]);

  return (
    <div className="flex flex-col p-6 pt-4 bg-white rounded-xl">
      <div className="w-full">
        {" "}
        <p className="text-[#3E3C42] font-medium text-xl py-2">
          Suction pressure and burner temperatures
        </p>
      </div>
      <div className="flex justify-start xl:justify-end items-center overflow-x-auto h-[60px]">
        <div className="flex items-center gap-2">
          <div className="min-w-[110px]">
            <Select
              borderColor="#CAC5CD"
              color="#605D64"
              placeholder={disable && capitalizeFirstLetter(plantId)}
              variant="outline"
              isDisabled={disable}
              className="!text-sm !font-medium text-[#605D64]"
              onChange={(e) => setSelectedPlant(e.target.value)}
              value={selectedPlant}
            >
              <option key="All Plants" value="All Plants">
                {capitalizeFirstLetter("All plants")}
              </option>
              {!disable &&
                Object.keys(plantCamMap).map((plant) => {
                  return (
                    <option key={plant} value={plant}>
                      {capitalizeFirstLetter(plant)}
                    </option>
                  );
                })}
            </Select>
          </div>
          <div className="min-w-[110px]">
            <Select
              borderColor="#CAC5CD"
              color="#605D64"
              value={selectedRange}
              variant="outline"
              className=" !text-sm !font-medium !text-[#605D64]"
              onChange={(e) => handleRangeSelect(e)}
            >
              <option key="Last 7 days" value={0}>
                Last 7 days
              </option>
              <option key="custom" value={1}>
                Custom
              </option>
            </Select>
          </div>
          {selectedRange == 1 && (
            <div className="min-w-[110px]">
              <FloatingInput
                text="From"
                type="date"
                setDateTime={setFromTime}
                value={fromTime}
                max={toTime}
              />
            </div>
          )}
          {selectedRange == 1 && (
            <div className="min-w-[110px]">
              <FloatingInput
                text="To"
                type="date"
                setDateTime={setToTime}
                value={toTime}
                min={fromTime}
                max={fromTime && new Date().toISOString().slice(0, 10)}
              />
            </div>
          )}
          {/* <button
            className="text-center py-2 px-4 text-white text-xs md:text-base font-medium bg-[#6CA6FC] rounded-full min-w-[80px]"
            onClick={handleClick}
          >
            {sizeDataChanging ? <Spinner /> : "Apply"}
          </button> */}
          <SecondaryButton
            width={"80px"}
            height={"40px"}
            text={sizeDataChanging ? <Spinner /> : "Apply"}
            onClick={handleClick}
            disable={!fromTime || !toTime}
          />
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-1 lg:gap-[40px] items-center w-full h-fit">
        {/*suction pressure graph */}
        <div className="w-full h-full">
          {graphData?.hasOwnProperty("suctionPressure") ? (
            <FuelSuctionChart
              series={graphData?.suctionPressure}
              yTitle={"Suction pressure(mmwc)"}
              annotations={{
                yaxis: [
                  {
                    y: graphData?.suctionPressureUpperLimit,
                    y2: null,
                    strokeDashArray: 10,
                    borderColor: "#E46962",
                    fillColor: "#E46962",
                    label: {
                      text: "Upper limit",
                      style: {
                        background: "#E46962",
                        color: "#fff",
                        fontSize: "12px",
                        cssClass: "apexcharts-yaxis-annotation-label",
                        padding: {
                          left: 4,
                          right: 4,
                          top: 4,
                          bottom: 4,
                        },
                      },
                    },
                  },
                ],
              }}
              xAxis={{
                categories: graphData?.suctionPressureTimestamps?.map(
                  (item) => item * 1000 - 5.5 * 60 * 60 * 1000
                ),
                type: "datetime",
                labels: {
                  show: true,
                  formatter: function (value) {
                    const mos = [
                      "Jan",
                      "Feb",
                      "Mar",
                      "Apr",
                      "May",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dec",
                    ];
                    const date = new Date(value);
                    const dayMonth =
                      date?.getDate() + " " + mos[date?.getMonth()];
                    const hours = date.getHours();
                    const minutes = date
                      .getMinutes()
                      .toString()
                      .padStart(2, "0");
                    const ampm = hours >= 12 ? "PM" : "AM";
                    const formattedHours =
                      hours === 0 || hours === 12 ? 12 : hours % 12;

                    return `${dayMonth} ${formattedHours}:${minutes} ${ampm}`;
                  },
                  style: {
                    fontSize: "14px",
                  },
                },
              }}
              height="400px"
            />
          ) : (
            <Skeleton width={"full"} height={"200px"} />
          )}
        </div>
        {/*Temprature trend */}
        <div className="w-full h-full">
          {graphData?.hasOwnProperty("temperature") ? (
            <FuelSuctionChart
              series={graphData?.temperature}
              timestamps={graphData?.temperatureTimestamps?.map(
                (item) => item * 1000 - 5.5 * 60 * 60 * 1000
              )}
              yTitle={"Burner Temperatures(deg C)"}
              colors={[
                "#ffc107",
                "#5193f6",
                "#ef6f12",
                "#1c56ac",
                "#e91e63",
                "#00bcd4",
                "#8bc34a",
                "#9c27b0",
                "#673ab7",
                "#ff9800",
                "#4caf50",
                "#795548",
              ]}
              xAxis={{
                categories: graphData?.temperatureTimestamps?.map(
                  (item) => item * 1000 - 5.5 * 60 * 60 * 1000
                ),
                type: "datetime",
                labels: {
                  show: true,
                  formatter: function (value) {
                    const mos = [
                      "Jan",
                      "Feb",
                      "Mar",
                      "Apr",
                      "May",
                      "Jun",
                      "Jul",
                      "Aug",
                      "Sep",
                      "Oct",
                      "Nov",
                      "Dec",
                    ];
                    const date = new Date(value);
                    const dayMonth =
                      date?.getDate() + " " + mos[date?.getMonth()];
                    const hours = date.getHours();
                    const minutes = date
                      .getMinutes()
                      .toString()
                      .padStart(2, "0");
                    const ampm = hours >= 12 ? "PM" : "AM";
                    const formattedHours =
                      hours === 0 || hours === 12 ? 12 : hours % 12;

                    return `${dayMonth} ${formattedHours}:${minutes} ${ampm}`;
                  },
                  style: {
                    fontSize: "14px",
                  },
                },
              }}
              height="400px"
            />
          ) : (
            <Skeleton width={"full"} height={"200px"} />
          )}
        </div>
      </div>
    </div>
  );
};

export default TempraturePressureAnalytics;
