import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import {
  useLocation,
  useParams,
  Link,
  useSearchParams,
} from "react-router-dom";
import { baseURL } from "../..";
import { useWindowSize } from "@uidotdev/usehooks";
import NavContext from "../NavContext";
import axios from "axios";
import Feed from "./Tabs/Feed";
import Alerts from "./Tabs/Alerts";
import Reports from "./Tabs/Reports";
import FeedPage from "./Tabs/FeedPage";

const RecipeCompliance = () => {
  const size = useWindowSize();
  const param = useParams();
  const { auth } = useContext(NavContext);

  let material = "RECIPECOMPLIANCE";
  let clientId = param?.clientId ? param?.clientId : null;
  const [liveFeedData, setLiveFeedData] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const urlParamTabIndex = !isNaN(searchParams.get("tabIndex"))
    ? Number(searchParams.get("tabIndex"))
    : 0;

  const initialize = async () => {
    try {
      const requestData = JSON.stringify({
        clientId: clientId,
        useCase: material?.toUpperCase(),
      });
      const response = await axios.post(
        baseURL + "vision/v1/workforce/recipeCompliance/info/initialize/",
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );

      setLiveFeedData(response.data);
    } catch (error) {
      console.log(error, "error");
    }
  };

  // handling tab change
  const handleTabsChange = (index) => {
    if (index !== urlParamTabIndex) {
      setSearchParams({ tabIndex: index }, { replace: true });
    }
  };

  // tablist
  const tabs = [
    {
      label: "Feed",
      index: 0,
      component: (
        <FeedPage
          material={material?.toUpperCase()}
          clientId={clientId}
          initialData={liveFeedData}
        />
      ),
    },
    // {
    //   label: "Alerts",
    //   index: 1,
    //   component: <Alerts initialData={liveFeedData} />,
    // },
    // {
    //   label: "Report",
    //   index: 2,
    //   component: <Reports initialData={liveFeedData} />,
    // },
  ];

  // init payload
  const payload2 = {
    cameraGpIds: [
      {
        cameraGpId: "weighment",
        cameraGpName: "Weighment Live Feed",
        cameraInfo: [
          {
            name: "Raw Material",
            cameraId: "cam_weighment",
          },
          {
            name: "Weight of Material",
            cameraId: "cam_weighment_meter",
          },
        ],
      },
      {
        cameraGpId: "tsd_mixer",
        cameraGpName: "TSD Mixer Live Feed",
        cameraInfo: [
          {
            cameraId: "cam_tsd_right",
            name: "Right Side View",
          },
          {
            cameraId: "cam_tsd_left",
            name: "Left Side View",
          },
        ],
      },
    ],
  };

  useEffect(() => {
    handleTabsChange(urlParamTabIndex);
  }, [urlParamTabIndex]);

  useEffect(() => {
    initialize();
  }, []);

  return (
    <div
      className=" font-poppins flex flex-col rounded-lg"
      style={{ width: size.width >= 768 ? "calc(100vw - 168px)" : "100vw" }}
    >
      <div className="flex justify-start items-center gap-2 mb-3 mt-0 md:mt-6">
        <Link
          to={`/vision/workforceMonitoring/recipecompliance`}
          style={{
            textDecoration: "none",
          }}
        >
          <img src="/backtick.svg" />
        </Link>
        <p className="text-lg sm:text-2xl font-semibold text-[#024D87] capitalize">
          {"SRIP Video Analytics"}
        </p>
      </div>

      <Tabs
        isLazy={true}
        index={urlParamTabIndex}
        onChange={handleTabsChange}
        paddingRight={{ base: "0px", md: "0px", lg: "0px" }}
      >
        <TabList className="!flex !border-0">
          <div className="flex items-center gap-4 overflow-x-auto h-14 md:h-10">
            {tabs &&
              tabs.map((tab, index) => (
                <Tab
                  key={`${tab.label}-${index}`}
                  minW={"fit-content"}
                  className={`${
                    urlParamTabIndex === tab.index
                      ? "!text-black !text-xs sm:!text-sm !bg-white rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#79767D]"
                      : "!text-xs sm:!text-sm !text-[#938F96] !border-0"
                  }`}
                  onClick={() => handleTabsChange(tab.index)}
                >
                  {tab.label}
                </Tab>
              ))}
          </div>
        </TabList>
        <TabPanels>
          {tabs &&
            tabs.map((tab, index) => (
              <TabPanel
                key={`${tab.label}-${index}`}
                className="!pl-0 !pr-0 h-full"
              >
                {tab.component}
              </TabPanel>
            ))}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default RecipeCompliance;
