import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { baseURL } from "../../../index";
import NavContext from "../../NavContext";
import PlantCard from "../FeedComponent/PlantCard";
import PlantCardNoData from "../FeedComponent/PlantCardNoData";

const Feed = ({ material, clientId, setPlantCamMap }) => {
  const [plantData, setPlantData] = useState("noPlant");
  const [sizeData, setSizeData] = useState([]);
  const [sizeDataChanging, setSizeDataChanging] = useState(false);
  const [selectedBasis, setSelectedBasis] = useState(0);
  const [thresholds, setThresholds] = useState(null);
  const [loading, setLoading] = useState(true);
  const { auth } = useContext(NavContext);
  const [selectedCategoryBar, setSelectedCategoryBar] = useState();
  const [fromTime, setFromTime] = useState(
    new Date(
      new Date().getTime() -
        8 * 60 * 60 * 1000 -
        new Date().getTimezoneOffset() * 60 * 1000
    )
      .toISOString()
      .slice(0, 16)
  );
  const [toTime, setToTime] = useState(
    new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
      .toISOString()
      .slice(0, 16)
  );
  const response = {
    data: {
      plants: {
        salem: {
          sinterflameprogression: {
            IR_Camera: [
              {
                _id: {
                  timestamp: 1717570129,
                  date: "2024-06-05T06:48:49.000+00:00",
                },
                cameraId: "IR_Camera",
                clientId: "jsw",
                emptyBelt: 0,
                size: {
                  "0-2 px": 30.01,
                  "2-6 px": 39.4,
                  "6-8 px": 5.0,
                  "8+ px": 26.93,
                },
                originalImage: "/JSWSalem/annoted.jpg",
                particleImage:
                  "https://jspl-datafiles-dev.s3.ap-south-1.amazonaws.com/contour05-06-2024-06-48-48-cam1.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240605T065205Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1800&X-Amz-Credential=AKIAWMM7GHFYWS4SWV5S%2F20240605%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=6b674755c13ec12167f13967a060aae5f07994bf868805be110de289413249b8",
                perspectiveImage:
                  "https://jspl-datafiles-dev.s3.ap-south-1.amazonaws.com/particle-05-06-2024-06-48-48-cam1.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240605T065205Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1800&X-Amz-Credential=AKIAWMM7GHFYWS4SWV5S%2F20240605%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=913cf57a2eaa23d743c2ce251a45f01e3356f5e59541d2ceab350fe6924b319b",
                colorBarImage:
                  "https://jspl-datafiles-dev.s3.ap-south-1.amazonaws.com/color_bar_05-06-2024-06-48-48-cam1.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240605T065205Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1800&X-Amz-Credential=AKIAWMM7GHFYWS4SWV5S%2F20240605%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=ad9a467fe5db097bcdba6bdad39fab42f6e72909fc8232335bea96e9fecb9bdf",
                createdAt: 1715278675,
                timestamp: "2024-05-09 18:17:55",
                material: "coal",
                id: "663cc659b735555c25354a84",
                plantName: "salem",
              },
            ],
            Camera2: [
              {
                _id: {
                  timestamp: 1715258969,
                  date: "2024-05-09T12:49:29.000+00:00",
                },
                cameraId: "Camera2",
                clientId: "jsw",
                useCase: "sinter",
                emptyBelt: 0,
                size: {
                  "0-2 px": 30.01,
                  "2-6 px": 39.4,
                  "6-8 px": 5.0,
                  "8+ px": 26.93,
                },
                originalImage: "/JSWSalem/real.jpg",
                particleImage:
                  "https://jspl-datafiles-dev.s3.ap-south-1.amazonaws.com/contour09-05-2024-12-49-26-cam2.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240605T065205Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1800&X-Amz-Credential=AKIAWMM7GHFYWS4SWV5S%2F20240605%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=40bd1f77da645a4d1eafaec2cd3cdf03cc195cf5af2c022d7a896ccbe9c890c8",
                perspectiveImage:
                  "https://jspl-datafiles-dev.s3.ap-south-1.amazonaws.com/particle-09-05-2024-12-49-26-cam2.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240605T065205Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1800&X-Amz-Credential=AKIAWMM7GHFYWS4SWV5S%2F20240605%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=f3e85da58ac3364f5986c66acb40ac24169ce9bce21cf3432c6f04c4d946db3a",
                colorBarImage:
                  "https://jspl-datafiles-dev.s3.ap-south-1.amazonaws.com/color_bar_09-05-2024-12-49-26-cam2.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240605T065205Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1800&X-Amz-Credential=AKIAWMM7GHFYWS4SWV5S%2F20240605%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=c8a9fa624f2040ef6c590dcc762f6441e7e715815945414c7ee42ab5043daf95",

                createdAt: 1715278675,
                timestamp: "2024-05-09 18:17:55",
                material: "coal",
                id: "663cc659b735555c25354a84",
                plantName: "salem",
              },
            ],
            Camera3: [
              {
                _id: {
                  timestamp: 1715258969,
                  date: "2024-05-09T12:49:29.000+00:00",
                },
                cameraId: "Camera2",
                clientId: "jsw",
                useCase: "sinter",
                emptyBelt: 1,
                size: {
                  "0-2 px": 30.01,
                  "2-6 px": 39.4,
                  "6-8 px": 5.0,
                  "8+ px": 26.93,
                },
                originalImage: "/JSWSalem/real.jpg",
                particleImage:
                  "https://jspl-datafiles-dev.s3.ap-south-1.amazonaws.com/contour09-05-2024-12-49-26-cam2.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240605T065205Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1800&X-Amz-Credential=AKIAWMM7GHFYWS4SWV5S%2F20240605%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=40bd1f77da645a4d1eafaec2cd3cdf03cc195cf5af2c022d7a896ccbe9c890c8",
                perspectiveImage:
                  "https://jspl-datafiles-dev.s3.ap-south-1.amazonaws.com/particle-09-05-2024-12-49-26-cam2.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240605T065205Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1800&X-Amz-Credential=AKIAWMM7GHFYWS4SWV5S%2F20240605%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=f3e85da58ac3364f5986c66acb40ac24169ce9bce21cf3432c6f04c4d946db3a",
                colorBarImage:
                  "https://jspl-datafiles-dev.s3.ap-south-1.amazonaws.com/color_bar_09-05-2024-12-49-26-cam2.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20240605T065205Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1800&X-Amz-Credential=AKIAWMM7GHFYWS4SWV5S%2F20240605%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Signature=c8a9fa624f2040ef6c590dcc762f6441e7e715815945414c7ee42ab5043daf95",

                createdAt: 1715278675,
                timestamp: "2024-05-09 18:17:55",
                material: "coal",
                id: "663cc659b735555c25354a84",
                plantName: "salem",
              },
            ],
          },
        },
      },
      thresholds: null,
    },
  };
  const response2 = {
    data: {
      plants: {
        salem: {
          sinterflameprogression: {
            cameraGorup1: [
              {
                cameraKeys: ["camID1", "camID2"],
                cameraId: "IR_Camera",
                clientId: "jsw",
                timestamp: 1715278675,
                emptyBelt: 0,
                camID1: "/JSWSalem/annoted.jpg",
                camID2: "/JSWSalem/real.jpg",

                material: "sinter",
                id: "663cc659b735555c25354a84",
                plantName: "salem",
                overview: {
                  order: [
                    "serial_no",
                    "average_flame_distance",
                    "area_coldspots",
                    "area_hotspots",
                    "last_analysis_time",
                  ],
                  data: [
                    {
                      serial_no: 11526,
                      average_flame_distance: "8px",
                      area_coldspots: "12%",
                      area_hotspots: "3%",
                      last_analysis_time: 1717604736,
                    },
                    {
                      serial_no: 11527,
                      average_flame_distance: "8px",
                      area_coldspots: "12%",
                      area_hotspots: "3%",
                      last_analysis_time: 1717604736,
                    },
                    {
                      serial_no: 11528,
                      average_flame_distance: "8px",
                      area_coldspots: "12%",
                      area_hotspots: "3%",
                      last_analysis_time: 1717604736,
                    },
                    {
                      serial_no: 11529,
                      average_flame_distance: "8px",
                      area_coldspots: "12%",
                      area_hotspots: "3%",
                      last_analysis_time: 1717604736,
                    },
                    {
                      serial_no: 11530,
                      average_flame_distance: "8px",
                      area_coldspots: "12%",
                      area_hotspots: "3%",
                      last_analysis_time: 1717604736,
                    },
                    {
                      serial_no: 11531,
                      average_flame_distance: "8px",
                      area_coldspots: "12%",
                      area_hotspots: "3%",
                      last_analysis_time: 1717604736,
                    },
                    {
                      serial_no: 51132,
                      average_flame_distance: "8px",
                      area_coldspots: "12%",
                      area_hotspots: "3%",
                      last_analysis_time: 1717604736,
                    },
                    {
                      serial_no: 11533,
                      average_flame_distance: "8px",
                      area_coldspots: "12%",
                      area_hotspots: "3%",
                      last_analysis_time: 1717604736,
                    },
                    {
                      serial_no: 11534,
                      average_flame_distance: "8px",
                      area_coldspots: "12%",
                      area_hotspots: "3%",
                      last_analysis_time: 1717604736,
                    },
                    {
                      serial_no: 11535,
                      average_flame_distance: "8px",
                      area_coldspots: "12%",
                      area_hotspots: "3%",
                      last_analysis_time: 1717604736,
                    },
                  ],
                },
                lastUpdated: {
                  order: ["parameter", "previous_hour", "today"],
                  data: [
                    {
                      parameter: "Average flame distance",
                      previous_hour: "8px",
                      today: "10 px",
                    },

                    {
                      parameter: "% Area coldspots",
                      previous_hour: "12%",
                      today: "3%",
                    },
                    {
                      parameter: "% Area hotspots",
                      previous_hour: "12%",
                      today: "3%",
                    },
                  ],
                },
              },
            ],
          },
        },
      },
    },
  };
  const apiCall = async () => {
    try {
      const requestData = JSON.stringify({
        clientId: clientId,
        useCase: material,
        cameraId: "cam101",
        plantName: "salem",
      });
      const response = await axios
        .post(
          baseURL + `vision/v2/processMonitoring/analysis/overview/`,
          requestData,
          {
            credentials: "same-origin",
            headers: {
              "Content-Type": "application/json",
              "X-Auth-Token": auth,
            },
          }
        )
        .then((response) => {
          setPlantData(response?.data?.plants);

          if ("thresholds" in response?.data)
            setThresholds(response?.data?.thresholds);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const apiCallForBarchart = async () => {
    try {
      const requestData = JSON.stringify({
        clientId: clientId,
        useCase: material,
        cameraId: "cam101",
        plantName: "salem",
        startDate: new Date(fromTime).getTime(),
        endDate: new Date(toTime).getTime(),
      });
      const response = await axios.post(
        baseURL + "vision/v2/processMonitoring/analytics/history/",
        requestData,
        {
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      setSizeData(response.data);
    } catch (error) {
      console.log(error);

      setSizeData([]);
    } finally {
      setSizeDataChanging(false);
    }
    // setSizeData(response.data);
  };

  const updateTime = () => {
    setFromTime(
      new Date(
        new Date().getTime() -
          8 * 60 * 60 * 1000 -
          new Date().getTimezoneOffset() * 60 * 1000
      )
        .toISOString()
        .slice(0, 16)
    );
    setToTime(
      new Date(
        new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000
      )
        .toISOString()
        .slice(0, 16)
    );
  };

  useEffect(() => {
    apiCallForBarchart();
  }, [fromTime]);

  useEffect(() => {
    if (plantData !== "noPlant") {
      const plantCamMap = {};
      Object.keys(plantData ?? {}).map((plant) => {
        plantCamMap[plant] = Object.keys(
          plantData[plant][material?.toLowerCase()]
        );
      });
      const sortedData = Object.entries(plantCamMap)
        .sort((a, b) => b[1].length - a[1].length)
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});
      setPlantCamMap(sortedData);
    }
  }, [plantData]);

  useEffect(() => {
    setSizeDataChanging(true);
    setLoading(true);
    setSelectedBasis(0);
    updateTime();
    apiCallForBarchart();
    apiCall();
    const intervalId = setInterval(() => {
      apiCall();
    }, 3000);
    const intervalForBarChartId = setInterval(() => {
      updateTime();
    }, 10000);
    return () => {
      clearInterval(intervalId);
      clearInterval(intervalForBarChartId);
    };
  }, []);

  useEffect(() => {
    if (selectedBasis == 0) {
      setSelectedCategoryBar(sizeData?.spots);
    }
    if (selectedBasis == 1) {
      setSelectedCategoryBar(sizeData?.distance);
    }
  }, [selectedBasis, sizeData]);

  return (
    <div className="flex flex-col gap-4 ">
      {loading ? (
        <PlantCard />
      ) : plantData &&
        plantData !== "noPlant" &&
        Object.keys(plantData).length > 0 ? (
        Object.keys(plantData).map((plant) => {
          console.log("true", plantData[plant][material?.toLowerCase()]);
          return (
            <PlantCard
              PlantName={plant}
              CamData={plantData[plant][material?.toLowerCase()] ?? {}}
              thresholds={thresholds}
              loading={loading}
              sizeDataChanging={sizeDataChanging}
              selectedCategoryBar={selectedCategoryBar}
              setSelectedBasis={setSelectedBasis}
              selectedBasis={selectedBasis}
              fromTime={fromTime}
              toTime={toTime}
            />
          );
        })
      ) : (
        <PlantCardNoData />
      )}
    </div>
  );
};

export default Feed;
