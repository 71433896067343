import React, { useEffect, useState } from "react";
import ReactImageAnnotate from "@starwit/react-image-annotate";

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Skeleton,
  Text,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverFooter,
  PopoverArrow,
  PopoverCloseButton,
  PopoverAnchor,
  useToast,
} from "@chakra-ui/react";
import CompareSharpIcon from "@mui/icons-material/CompareSharp";
import { useWindowSize } from "@uidotdev/usehooks";
import TonalButton from "../../../util/Buttons/TonalButton";
import { CloseIcon } from "@chakra-ui/icons";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { slabSizingJsplBaseURl } from "../SlabSizing";
import axios from "axios";

const AnnotateImage = ({
  setEditingSLabId,
  fetchSLabDetails,
  imgUrl,
  imageName,
  masking,
  uniqueID,
  alreadyEditedByUser,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [points, setPoints] = useState({
    _id: uniqueID,
    view: "top",
    mask: [],
  });
  const [annotationData, setAnnotationData] = useState([
    {
      src: imgUrl ? imgUrl : "",
      name: imageName ? imageName : "Image to annotate",
      regions: [],
    },
  ]);

  const handleSubmit = async () => {
    let data = JSON.stringify(points);

    try {
      const response = await axios.post(
        slabSizingJsplBaseURl + "update_mask/",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("res", response);
      toast({
        title: "Masking updated",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setPoints((prev) => ({
        ...prev,
        mask: [],
      }));
      setEditingSLabId(false);
      onClose();
    } catch (error) {
      console.log("error", error);
      toast({
        title: "Something went wrong",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      setPoints((prev) => ({
        ...prev,
        mask: [],
      }));
      setEditingSLabId(false);
      onClose();
      return;
    }
  };

  const handleCLoseWithoutSubmit = () => {
    setPoints((prev) => ({
      ...prev,
      mask: [],
    }));
    toast({
      title: "Can't submit after deleting annotation",
      status: "error",
      duration: 4000,
      isClosable: true,
      position: "top-right",
    });

    setEditingSLabId(false);
    onClose();
  };

  const size = useWindowSize();

  useEffect(() => {
    setAnnotationData((prev) => {
      let newData = JSON.parse(JSON.stringify(prev));
      newData[0]["regions"] =
        masking.length > 0
          ? [
              {
                cls: "slab",
                color: alreadyEditedByUser ? "#FF6F00" : "#f44336",
                highlighted: true,
                id: "0",
                open: false,
                type: "polygon",
                points: masking,
              },
            ]
          : [];
      return newData;
    });
  }, [masking]);
  return (
    <div>
      {size.width >= 768 ? (
        <>
          {/* <Button onClick={onOpen}>
            <CompareSharpIcon className="text-blue" />
          </Button> */}
          <PrimaryButton
            width={"180px"}
            height={"40px"}
            onClick={() => {
              onOpen();
              setEditingSLabId(true);
            }}
            Icon={<img src="/RecipeComp/Annotation.svg" alt="annotation" />}
            text={"Change masking"}
          />

          <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent style={{ borderRadius: "12px" }} maxW="800px">
              <ModalHeader>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Text>Enhance Your Model</Text>
                  <Popover>
                    <PopoverTrigger>
                      <img
                        src="/WorkforceSafetyIcons/cross.svg"
                        className="cursor-pointer hover:bg-slate-200 rounded-md"
                      />
                    </PopoverTrigger>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverHeader fontWeight="semibold" fontSize="md">
                        Confirmation!
                      </PopoverHeader>
                      <PopoverBody fontSize="sm" fontWeight="normal">
                        Are you sure you want to close the modal?
                      </PopoverBody>
                      <PopoverFooter display="flex" justifyContent="flex-end">
                        <Button
                          colorScheme="red"
                          onClick={() => onClose()}
                          size="sm"
                        >
                          Yes
                        </Button>
                      </PopoverFooter>
                    </PopoverContent>
                  </Popover>
                  {/* <img
                    src="/WorkforceSafetyIcons/cross.svg"
                    className="cursor-pointer hover:bg-slate-200 rounded-md"
                    onClick={() => handleModalClose()}
                  /> */}
                </Box>
              </ModalHeader>

              <ModalBody>
                {" "}
                <div className="w-full flex flex-col gap-2">
                  <Text color="#3E3C42" fontSize="lg" fontWeight="medium">
                    Review and adjust the annotation points as needed. Click
                    save to proceed.
                  </Text>

                  <div className="border min-h-[420px] max-h-[450px] overflow-auto rounded-md shadow-sm">
                    {annotationData[0]?.regions?.length > 0 ? (
                      <ReactImageAnnotate
                        taskDescription="Annotate each image according to this _markdown_ specification."
                        regionClsList={["slab"]}
                        enabledTools={["select"]}
                        selectedImage={annotationData[0]?.src}
                        images={annotationData}
                        hideClone={true}
                        // hideFullScreen={true}
                        hideNext={true}
                        hidePrev={true}
                        hideSettings={true}
                        hideHeaderText={true}
                        onExit={(val) => {
                          setPoints((prev) => ({
                            ...prev,
                            mask: val?.images[0]?.regions[0]?.points
                              ? val?.images[0]?.regions[0]?.points
                              : [],
                          }));
                          if (val.images[0].regions.length == 0) {
                            handleCLoseWithoutSubmit();
                          }
                        }}
                      />
                    ) : (
                      <Skeleton height={"300px"} width={"full"} />
                    )}
                  </div>
                </div>
              </ModalBody>

              <ModalFooter>
                <div className=" gap-2 w-full pr-3 h-[40px] py-1 flex items-end justify-end">
                  <p className="text-xs text-[#084298] font-semibold text-end">
                    Click save icon on top right corner first then submit
                  </p>
                </div>

                <PrimaryButton
                  text="Submit"
                  width={"120px"}
                  disable={points?.mask.length == 0}
                  onClick={() => {
                    handleSubmit();
                  }}
                />
              </ModalFooter>
            </ModalContent>
          </Modal>
        </>
      ) : (
        <Drawer
          // onClose={closeModal} isOpen={openModal}
          isOpen={isOpen}
          onClose={onClose}
          size="full"
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader padding="0px"></DrawerHeader>
            <DrawerBody padding="0px"></DrawerBody>
            <DrawerFooter></DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
    </div>
  );
};

export default AnnotateImage;
