import { useState, useEffect, useContext } from "react";
import { baseURL } from "../../../index";
import { useParams } from "react-router-dom";
import axios from "axios";
import NavContext from "../../NavContext";
import {
  Table,
  Td,
  Tr,
  Thead,
  Tbody,
  TableContainer,
  Th,
  Select,
  Spinner,
} from "@chakra-ui/react";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import CommonTable from "../CommonTable/CommonTable";
import { capitalizeFirstLetter } from "../../../util/sentenceCase";
import TableSkeleton from "../CommonTable/TableSkeleton";
import ExlCsvDownload from "../utils/ExlCsvDownload";
import { useWindowSize } from "@uidotdev/usehooks";

const Report = ({ plantId, cameraId, disable, plantCamMap, material }) => {
  const param = useParams();
  const size = useWindowSize();
  const { auth } = useContext(NavContext);
  const [report, setReport] = useState(null);
  const [selectedBasis, setSelectedBasis] = useState(0);
  const [reportChanging, setReportChanging] = useState(false);
  const [fromTime, setFromTime] = useState(
    new Date(new Date().getTime() - 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16)
  );
  const [toTime, setToTime] = useState(
    new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16)
  );
  const [selectedPlant, setSelectedPlant] = useState(
    disable ? plantId : "All Plants"
  );
  const [selectedCam, setSelectedCam] = useState(
    disable ? cameraId : "All Cams"
  );
  const response = {
    data: {
      order: [
        "startTime",
        "endTime",
        "average_flame_distance",
        "area_coldspots",

        "area_hotspots",
      ],
      data: [
        {
          serial_no: 50009,
          area_hotspots: "3%",
          area_coldspots: "12%",
          average_flame_distance: "12 px",
          id: 0,
          endTime: 1717606736,
          startTime: 1717606736,
        },
        {
          serial_no: 30154,
          area_hotspots: "3%",
          area_coldspots: "12%",
          average_flame_distance: "6 px",
          id: 1,
          endTime: 1717606736,
          startTime: 1717606736,
        },
        {
          serial_no: 89746,
          area_hotspots: "3%",
          area_coldspots: "12%",
          average_flame_distance: "2 px",
          id: 2,
          endTime: 1717606736,
          startTime: 1717606736,
        },
        {
          serial_no: 84939,
          area_hotspots: "3%",
          area_coldspots: "12%",
          average_flame_distance: "12 px",
          id: 3,
          endTime: 1717606736,
          startTime: 1717606736,
        },
        {
          serial_no: 65915,
          area_hotspots: "3%",
          area_coldspots: "12%",
          average_flame_distance: "12 px",
          id: 4,
          endTime: 1717606736,
          startTime: 1717606736,
        },
        {
          serial_no: 47550,
          area_hotspots: "3%",
          area_coldspots: "12%",
          average_flame_distance: "6 px",
          id: 5,
          endTime: 1717606736,
          startTime: 1717606736,
        },
        {
          serial_no: 70737,
          area_hotspots: "3%",
          area_coldspots: "12%",
          average_flame_distance: "18 px",
          id: 6,
          endTime: 1717606736,
          startTime: 1717606736,
        },
        {
          serial_no: 28336,
          area_hotspots: "3%",
          area_coldspots: "12%",
          average_flame_distance: "6 px",
          id: 7,
          endTime: 1717606736,
          startTime: 1717606736,
        },
        {
          serial_no: 77387,
          area_hotspots: "3%",
          area_coldspots: "12%",
          average_flame_distance: "12 px",
          id: 8,
          endTime: 1717606736,
          startTime: 1717606736,
        },
        {
          serial_no: 49374,
          area_hotspots: "3%",
          area_coldspots: "12%",
          average_flame_distance: "6 px",
          id: 9,
          endTime: 1717606736,
          startTime: 1717606736,
        },
        {
          serial_no: 83349,
          area_hotspots: "3%",
          area_coldspots: "12%",
          average_flame_distance: "12 px",
          id: 10,
          endTime: 1717606736,
          startTime: 1717606736,
        },
        {
          serial_no: 39512,
          area_hotspots: "3%",
          area_coldspots: "12%",
          average_flame_distance: "2 px",
          id: 11,
          endTime: 1717606736,
          startTime: 1717606736,
        },
        {
          serial_no: 92074,
          area_hotspots: "3%",
          area_coldspots: "12%",
          average_flame_distance: "18 px",
          id: 12,
          endTime: 1717606736,
          startTime: 1717606736,
        },
        {
          serial_no: 34352,
          area_hotspots: "3%",
          area_coldspots: "12%",
          average_flame_distance: "18 px",
          id: 13,
          endTime: 1717606736,
          startTime: 1717606736,
        },
        {
          serial_no: 68269,
          area_hotspots: "3%",
          area_coldspots: "12%",
          average_flame_distance: "8 px",
          id: 14,
          endTime: 1717606736,
          startTime: 1717606736,
        },
        {
          serial_no: 84003,
          area_hotspots: "3%",
          area_coldspots: "12%",
          average_flame_distance: "2 px",
          id: 15,
          endTime: 1717606736,
          startTime: 1717606736,
        },
        {
          serial_no: 94098,
          area_hotspots: "3%",
          area_coldspots: "12%",
          average_flame_distance: "6 px",
          id: 16,
          endTime: 1717606736,
          startTime: 1717606736,
        },
        {
          serial_no: 61463,
          area_hotspots: "3%",
          area_coldspots: "12%",
          average_flame_distance: "6 px",
          id: 17,
          endTime: 1717606736,
          startTime: 1717606736,
        },
        {
          serial_no: 44718,
          area_hotspots: "3%",
          area_coldspots: "12%",
          average_flame_distance: "18 px",
          id: 18,
          endTime: 1717606736,
          startTime: 1717606736,
        },
        {
          serial_no: 41031,
          area_hotspots: "3%",
          area_coldspots: "12%",
          average_flame_distance: "18 px",
          id: 19,
          endTime: 1717606736,
          startTime: 1717606736,
        },
        {
          serial_no: 60422,
          area_hotspots: "3%",
          area_coldspots: "12%",
          average_flame_distance: "2 px",
          id: 20,
          endTime: 1717606736,
          startTime: 1717606736,
        },
        {
          serial_no: 36910,
          area_hotspots: "3%",
          area_coldspots: "12%",
          average_flame_distance: "18 px",
          id: 21,
          endTime: 1717606736,
          startTime: 1717606736,
        },
        {
          serial_no: 34188,
          area_hotspots: "3%",
          area_coldspots: "12%",
          average_flame_distance: "12 px",
          id: 22,
          endTime: 1717606736,
          startTime: 1717606736,
        },
      ],
    },
  };
  const apiCall = async () => {
    try {
      const requestData = JSON.stringify({
        clientId: param?.clientId?.toLowerCase(),
        useCase: material,
        cameraId: "cam101",
        plantName: "salem",

        startDate: new Date(fromTime).getTime(),
        endDate: new Date(toTime).getTime(),

        basis: selectedBasis,
      });
      const response = await axios.post(
        baseURL + "vision/v2/processMonitoring/report/overview/",
        requestData,
        {
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      setReport(response.data);
      setReportChanging(false);
    } catch (error) {
      console.log(error);
      setReport(null);
      setReportChanging(false);
    }
  };

  const handleClick = () => {
    setReportChanging(true);
    apiCall();
  };

  useEffect(() => {
    handleClick();
  }, []);

  useEffect(() => {
    if (!disable & (selectedPlant != "All Plants")) {
      setSelectedCam("All Cams");
    }
  }, [selectedPlant]);

  return (
    <div className="relative flex flex-col">
      <div className="absolute left-0 right-0 flex justify-center">
        <div className="p-5 pb-3 pl-6 pr-6 gap-2 flex flex-col md:flex-row items-center bg-white rounded-xl shadow-md">
          <div>
            <FloatingInput
              text="From"
              type="datetime-local"
              setDateTime={setFromTime}
              value={fromTime}
              max={toTime}
            />
          </div>
          <div>
            <FloatingInput
              text="To"
              type="datetime-local"
              setDateTime={setToTime}
              value={toTime}
              min={fromTime}
              max={new Date().toISOString().slice(0, 16)}
            />
          </div>

          <PrimaryButton
            width={"130px"}
            height={"40px"}
            text={reportChanging ? <Spinner /> : "Show report"}
            onClick={handleClick}
            disable={!fromTime || !toTime}
          />
        </div>
      </div>
      {/* <div className="flex  gap-4 justify-between    ">
        <div className="  gap-2 grid sm:grid-cols-2 lg:grid-cols-7  xl:grid-cols-9 items-center   justify-center w-full ">
          <div className=" lg:col-span-2  xl:col-span-2">
            <FloatingInput
              text="From"
              type="datetime-local"
              setDateTime={setFromTime}
              value={fromTime}
              max={toTime}
            />
          </div>
          <div className="  lg:col-span-2 xl:col-span-2">
            <FloatingInput
              text="To"
              type="datetime-local"
              setDateTime={setToTime}
              value={toTime}
              min={fromTime}
              max={new Date().toISOString().slice(0, 16)}
            />
          </div>
          <div className="min-w-[110px]">
            <Select
              borderColor="#CAC5CD"
              color="#605D64"
              placeholder={disable && capitalizeFirstLetter(plantId)}
              variant="outline"
              isDisabled={disable}
              rounded={"base"}
              className="!text-sm !font-medium text-[#605D64]"
              onChange={(e) => setSelectedPlant(e.target.value)}
              value={selectedPlant}
            >
              <option key="All Plants" value="All Plants">
                {capitalizeFirstLetter("All plants")}
              </option>
              {!disable &&
                Object.keys(plantCamMap).map((plant) => {
                  return (
                    <option key={plant} value={plant}>
                      {capitalizeFirstLetter(plant)}
                    </option>
                  );
                })}
            </Select>
          </div>
       
          <div className="min-w-[110px]">
            <Select
              borderColor="#CAC5CD"
              color="#605D64"
              placeholder={disable && capitalizeFirstLetter(cameraId)}
              variant="outline"
              isDisabled={disable}
              rounded={"base"}
              className="!text-sm !font-medium text-[#605D64]"
              onChange={(e) => setSelectedCam(e.target.value)}
              value={selectedCam}
            >
              {" "}
              <option key="All Cams" value="All Cams">
                {capitalizeFirstLetter("All cams")}
              </option>
              {!disable &&
                plantCamMap[selectedPlant]?.map((cam) => {
                  return (
                    <option key={cam} value={cam}>
                      {capitalizeFirstLetter(cam)}
                    </option>
                  );
                })}
            </Select>
          </div>
        
          <div className="min-w-[110px]">
            <Select
              borderColor="#CAC5CD"
              color="#605D64"
              variant="outline"
              rounded={"base"}
              className="!text-sm !font-medium !text-[#605D64]"
              value={selectedBasis}
              onChange={(e) => setSelectedBasis(e.target.value)}
            >
              <option value={0}>Daily basis</option>
              <option value={1}>Shift basis</option>
              <option value={2}>Hourly basis</option>
            </Select>
          </div>

          <div className="flex gap-2 sm:col-span-2 lg:col-span-7 xl:col-span-2 w-full justify-center xl:justify-start">
            <div className=" min-w-[100px] ">
              <PrimaryButton
                width={"130px"}
                height={"40px"}
                text={reportChanging ? <Spinner /> : "Show report"}
                onClick={handleClick}
                disable={!fromTime || !toTime}
              />
            </div>
            {report.hasOwnProperty("order") && (
              <ExlCsvDownload order={report.order} data={report.data} />
            )}
          </div>
        </div>
      </div> */}
      <div className="flex flex-col gap-4 mt-[160px] md:mt-11 pt-[57px] pb-6 bg-white rounded-xl justify-start mb-3 md:mb-0">
        <div className="flex justify-between gap-2 sm:pl-4 sm:pr-4  overflow-x-auto">
          <div className="flex gap-2">
            {/* <div className="min-w-[110px]">
              <Select
                borderColor="#CAC5CD"
                color="#605D64"
                placeholder={disable && capitalizeFirstLetter(plantId)}
                variant="outline"
                isDisabled={disable}
                rounded={"base"}
                className="!text-sm !font-medium text-[#605D64]"
                onChange={(e) => setSelectedPlant(e.target.value)}
                value={selectedPlant}
              >
                <option key="All Plants" value="All Plants">
                  {capitalizeFirstLetter("All plants")}
                </option>
                {!disable &&
                  Object.keys(plantCamMap).map((plant) => {
                    return (
                      <option key={plant} value={plant}>
                        {capitalizeFirstLetter(plant)}
                      </option>
                    );
                  })}
              </Select>
            </div>
            {selectedPlant !== "All Plants" && (
              <div className="min-w-[110px]">
                <Select
                  borderColor="#CAC5CD"
                  color="#605D64"
                  placeholder={disable && capitalizeFirstLetter(cameraId)}
                  variant="outline"
                  isDisabled={disable}
                  rounded={"base"}
                  className="!text-sm !font-medium text-[#605D64]"
                  onChange={(e) => setSelectedCam(e.target.value)}
                  value={selectedCam}
                >
                  {" "}
                  <option key="All Cams" value="All Cams">
                    {capitalizeFirstLetter("All cams")}
                  </option>
                  {!disable &&
                    plantCamMap[selectedPlant].map((cam) => {
                      return (
                        <option key={cam} value={cam}>
                          {capitalizeFirstLetter(cam)}
                        </option>
                      );
                    })}
                </Select>
              </div>
            )} */}
            <div className="min-w-[100px]">
              <Select
                borderColor="#CAC5CD"
                color="#605D64"
                variant="outline"
                rounded={"base"}
                className="!text-sm !font-medium !text-[#605D64]"
                value={selectedBasis}
                onChange={(e) => setSelectedBasis(e.target.value)}
              >
                <option value={0}>Daily basis</option>
                <option value={1}>Shift basis</option>
                <option value={2}>Hourly basis</option>
              </Select>
            </div>

            <SecondaryButton
              width={"80px"}
              height={"40px"}
              text={reportChanging ? <Spinner /> : "Apply"}
              onClick={handleClick}
            />
          </div>
          {report?.order && (
            <ExlCsvDownload order={report.order} data={report.data} />
          )}
        </div>
        {!reportChanging ? (
          <CommonTable
            rowData={report?.data || []}
            order={report?.order || []}
            rowHeight={45}
            tableHeight={size.width <= 1024 ? "400px" : "550px"}
            pagesize={50}
          />
        ) : (
          <TableSkeleton
            rows={7}
            cellsPerRow={5}
            headers={["1", "2", "3", "4", "5"]}
          />
        )}
      </div>
    </div>
  );
};

export default Report;
