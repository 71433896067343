import React, {
  useState,
  useEffect,
  useContext,
  useLayoutEffect,
  useRef,
} from "react";
import Navbar from "../Navbar";
import { useNavigate, useParams } from "react-router-dom";
import { baseURL } from "../..";
import { Input, Spinner, useToast } from "@chakra-ui/react";
import NavContext from "../NavContext";
import axios from "axios";
import PrimaryButton from "../../util/Buttons/PrimaryButton";
import ExpertComments from "./ExpertComments";
import TodayIcon from '@mui/icons-material/Today';
import FileUploader from "./FileUploader";
import CloseIcon from "@mui/icons-material/Close";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useWindowSize } from "@uidotdev/usehooks";
import SaveDraftModal from "./SaveDraftModal";

const Expert = () => {
  const { auth, userType } = useContext(NavContext);
  const [submitted, setSubmitted] = useState(false);
  const [review, setReview] = useState(false);
  const navigate = useNavigate();
  const [fullName, setFullName] = useState(localStorage.getItem("fullname"));
  const [expName, setExpertName] = useState("");

  let param = useParams();

  const [spinner, setSpinner] = useState(false);
  const [question, setQuestion] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [enqAttachments, setEnquirerAttachments] = useState([]);
  const toast = useToast();
  const [send, setSend] = useState([]);
  const [answer, setAnswer] = useState("");
  const [comment, setComment] = useState("");
  const [savedAnswer, setSavedAnswer] = useState("");
  const [reply, setReply] = useState("");
  const [subject, setSubject] = useState("");
  const [retrievedComments, setRetrievedComments] = useState([]);
  const [enquirer, setEnquirer] = useState("");
  const [deadline, setDeadLine] = useState();
  const [enqOrg, setEnqOrg] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [expImage, setExpImage] = useState("");
  const [userImage, setUserImage] = useState("");
  const [expertOrg, setExpertOrg] = useState("");
  const [isCommentDisabled, setCommentDisabled] = useState(true);
  const [isCommentsExceeded, setCommentsExceeded] = useState(false);
  const [commentsAttachments, setCommentsAttachments] = useState([]);
  const [sendCommDoc, setSendCommDoc] = useState([]);
  const [questionCost, setQuestionCost] = useState(0);
  const [trackPrevSavedAnswer, setPrevSavedAnswer] = useState("");

  const [errors, setErrors] = useState({
    answer: "",
    comment: "",
  });
  const [isSaveModalOpen, setSaveModalOpen] = useState(false);

  const size = useWindowSize();
  const { login } = useContext(NavContext);
  const [loginDetails, setLoginDetails] = useState({
    name: "",
    imgUrl: "",
    org: "",
  });

  const fetchSavedAnswer = async () => {
    try {
      const response = await axios.get(
        baseURL + `questions/saveAnswer/${param.questionId}`,

        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setSavedAnswer(response?.data.answer);
      setReply(response?.data.answer);
      setPrevSavedAnswer(response?.data.answer);
      // console.log(response);
    } catch (e) {
      console.error(e);
    }
  };

  const handleSave = async () => {
    const data = {
      answer: reply,
    };
    try {
      const response = await axios.post(
        baseURL + `questions/saveAnswer/${param.questionId}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response?.status == 200) {
        setIsSaved(true);
        toast({
          title: "Draft Saved Successfully",
          // description: "Please fill in all required details.",
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
      } else {
        toast({
          title: `Draft not saved, please try again`,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (e) {
      toast({
        title: `Draft not saved, please try again`,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
      console.error(e);
    }
  };

  const postComments = async () => {
    // Check if answer is empty
    if (errors?.comment) {
      setSpinner(false);
      setErrors((prevData) => ({
        ...prevData,
        comment: errors?.comment,
      }));
      return;
    }
    setSpinner(true);
    if (comment?.length === 0) {
      postCommentAttachment();
      return;
    }
    setComment("");
    const data = {
      comment: comment,
    };
    try {
      const response = await axios.post(
        baseURL + `questions/comment/${param.questionId}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (sendCommDoc && sendCommDoc.length > 0) {
        postCommentAttachment();
      }
      getComments();
      setSpinner(false);
    } catch (e) {
      setSpinner(false);
      console.error(e);
    }
  };

  const getComments = async () => {
    const data = {
      comment,
    };
    try {
      const response = await axios.get(
        baseURL + `questions/comment/${param.questionId}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      setRetrievedComments(response?.data);
      if (response?.data?.length > 5) {
        setCommentsExceeded(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const fetchUserImageUrl = async (id) => {
    const param = {
      userId: id,
    };
    try {
      const response = await axios.get(baseURL + `iam/users/id`, {
        params: param,
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });

      return response?.data?.imageurl;
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getData();
    fetchUser();
    getAttachments();
    fetchSavedAnswer();
    getComments();
    getAttachmentsForEnquirer();
    getAdditionalAttachments();
    getQuestionCost();
  }, [userType]);

  const getAttachments = async () => {
    try {
      const res = await axios.get(
        baseURL + `attachment/expert/${param.questionId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      setAttachments(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAttachmentsForEnquirer = async () => {
    try {
      const res = await axios.get(
        baseURL + `attachment/user/${param.questionId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      setEnquirerAttachments(res.data);
      console.log("enq attt", res);
    } catch (error) {
      console.log(error);
    }
  };

  const getQuestionCost = async () => {
    try {
      const data = await fetch(baseURL + "token-wallet/v1/token-cost", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      const res = await data.json();
      if (data.status !== 400) {
        const filteredItems = res.find(
          (el) => el.productDescription === "Ask an expert"
        );
        const cost = filteredItems?.productCost;
        setQuestionCost(cost);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getData = async () => {
    const data = await fetch(baseURL + `questions/${param.questionId}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const res = await data.json();

    setQuestion(res?.question);
    setAnswer(res?.answer);
    setEnquirer(res?.userName);
    setSubject(res?.subject);
    setDeadLine(formatTimestampToIST(res?.needBy));
    setEnqOrg(res?.userOrganisation);
    setExpertName(res?.expertName);
    setExpImage(res?.expertImageUrl);
    setUserImage(res?.userImageUrl);
    setExpertOrg(res?.expertOrganisation);
    if (res?.answer != null || res?.answer?.length > 0) {
      setCommentDisabled(false);
    }
  };

  const handleInputsCheck = () => {
    if (reply.length < 50) {
      setErrors((prevData) => ({
        ...prevData,
        answer: "Minimum 50 characters required",
      }));
    }
    if (reply.length > 1000) {
      setErrors((prevData) => ({
        ...prevData,
        answer: `You have exceeded the character limit of 1000 by ${
          reply.length - 1000
        }`,
      }));
    }
  };

  const postAnswer = async () => {
    // console.log(errors);
    if (reply.length < 50) {
      setSpinner(false);

      setErrors((prevData) => ({
        ...prevData,
        answer: "Minimum 50 characters required",
      }));

      return;
    }
    if (reply.length > 1000) {
      setSpinner(false);

      setErrors((prevData) => ({
        ...prevData,
        answer: `You have exceeded the character limit of 1000 by ${
          reply.length - 1000
        }`,
      }));

      return;
    }
    // Check if answer is empty
    if (!reply && !savedAnswer) {
      console.log(1);
      setSpinner(false);
      setErrors((prevData) => ({
        ...prevData,
        answer: "Answer field is mandatory",
      }));
      return;
    }

    setSpinner(true);

    const cap = {
      answer: reply,
    };
    const json = JSON.stringify(cap);
    const blob = new Blob([json], {
      type: "application/json",
    });
    const FormData = require("form-data");
    let data = new FormData();
    data.append("json", blob);
    data.append("files", send[0]);
    data.append("files", send[1]);
    data.append("files", send[2]);
    let config = {
      method: "post",
      credentials: "same-origin",
      maxBodyLength: Infinity,
      redirect: "follow",
      url: baseURL + `questions/answer/${param.questionId}`,
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setSpinner(false);
        setSubmitted(true);
      })
      .catch((error) => {
        setSpinner(false);
        console.log(error);
      });
  };

  const getAdditionalAttachments = async () => {
    try {
      const res = await axios.get(
        baseURL + `attachment/comment/${param.questionId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      setCommentsAttachments(res.data);
      console.log("comm attt", res);
    } catch (error) {
      console.log(error);
    }
  };

  const postCommentAttachment = async () => {
    const FormData = require("form-data");
    let data = new FormData();

    data.append("files", sendCommDoc[0]);
    let config = {
      method: "post",
      credentials: "same-origin",
      maxBodyLength: Infinity,
      redirect: "follow",
      url: baseURL + `attachment/comment?questionId=${param.questionId}`,
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setSendCommDoc([]);
        getAdditionalAttachments();
        setSpinner(false);
      })
      .catch((error) => {
        setSpinner(false);
        console.log(error);
      });
  };

  const downloadAsset = async (asset) => {
    axios({
      url: baseURL + `attachment/download?key=${asset}`, //your url
      method: "GET",
      responseType: "blob", // important
    }).then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", removeExtension(asset)); //or any other extension
      document.body.appendChild(link);
      link.click();
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  function formatTimestampToIST(timestamp) {
    const date = new Date(timestamp);

    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      timeZone: "Asia/Kolkata", // IST timezone
    };

    return date.toLocaleDateString("en-IN", options);
  }

  const fetchUser = async () => {
    try {
      const response = await axios.get(baseURL + "user", {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      const name = response?.data.data.fullname;
      const imageUrl = response?.data?.data?.imageurl;
      const org = response?.data.data.organisation;

      setLoginDetails((prevData) => ({
        ...prevData,
        name: name,
        imgUrl: imageUrl,
        org: org,
      }));
    } catch (e) {
      console.error(e);
    }
  };

  function removeExtension(filename) {
    return (
      filename.substring(filename.lastIndexOf("/") + 1, filename.length) ||
      filename
    );
  }

  const onCloseSavemodal = () => {
    setSaveModalOpen(false);
  };
  const handleClickHistory = () => {
    // console.log("Click");
    // setSaveModalOpen(true);
    // console.log(reply?.length);
    if (
      reply?.length > 50 &&
      !isSaved &&
      reply?.length != trackPrevSavedAnswer?.length
    ) {
      // console.log("modalopen called");
      setSaveModalOpen(true);
    } else {
      navigate("/community/askanexpert");
    }
  };

  const removeFile = (index) => {
    const updatedSend = [...send];
    updatedSend.splice(index, 1); // Remove the file at the specified index
    setSend(updatedSend);
  };

  const removeCommentFile = (index) => {
    const updatedSend = [...sendCommDoc];
    updatedSend.splice(index, 1); // Remove the file at the specified index
    setSendCommDoc(updatedSend);
  };
  const handleBackEditing = () => {
    setReview(false);
    setIsSaved(false);
  };

  const handleBackButton = () => {
    navigate(-1);
  };

  const handleCloseModal = () => {};

  const handleBackDashboard = () => {
    navigate("/community/askanexpert");
  };

  return (
    <>
      <Navbar />
      <div className={login === false ? "mt-20 mx-10" : "mt-10"}>
        <div className="flex justify-between items-end w-full gap-2 mb-2   bg-[#FAFAFA]">
          <div className="flex items-center gap-2">
            <div
              className="cursor-pointer w-6 h-6"
              onClick={handleClickHistory}
            >
              <img
                src="/backtick.svg"
                className="w-full h-full"
                alt="backarrow_img"
              />
            </div>
            <div className="flex justify-center items-center cursor-pointer">
              <p className="font-semibold text-[20px] text-[#084298] text-bold">
                QnA Details
              </p>
            </div>
          </div>
        </div>
        {submitted === false ? (
          <>
            <div className="w-full p-6 border bg-white rounded-md mb-5">
              <div className="flex justify-between">
                <div>
                  <p className="text-[20px]" style={{ fontWeight: 500 }}>
                    Hello,
                    <span className="ml-1">
                      {loginDetails.name
                        ? loginDetails.name
                        : userType === "EXPERT"
                        ? "Expert"
                        : "Enquirer"}
                    </span>
                  </p>
                </div>
                <div className="flex items-center lg:gap-4 sm:gap-2 px-3 py-1 rounded-sm bg-[#FFFFD8]">
                  <div>
                    <p className="text-[14px] text-[#605D64] font-normal p-1">
                      Question cost
                    </p>
                  </div>
                  <div className="flex items-center gap-1">
                    <p className="text-[#3E3C42] font-semibold text-[16px]">
                      {questionCost}
                    </p>
                    <img
                      src="/token.svg"
                      className="w-full h-full"
                      alt="token"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col md:flex-row items-start pt-2 md:pt-0 md:gap-16 text-[14px]">
                <div className="flex flex-row gap-16">
                  <div>
                    <p className=" font-semibold mt-1">Expert</p>
                    <div className="flex gap-1 items-center justify-center">
                      <div className="w-[30px] h-[30px]">
                        {expImage === "" || expImage == null ? (
                          <AccountCircleIcon />
                        ) : (
                          <img
                            src={expImage + `?${performance.now()}`}
                            alt=""
                            className="max-h-full w-full"
                          />
                        )}
                      </div>

                      <div>
                        <p>{expName || "Not specified"}</p>
                        <p className="text-[#AEA9B1]">
                          {expertOrg || "Unaffiliated"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <p className=" font-semibold mt-1">Need by</p>
                    <div className="flex gap-1 justify-center mt-1">
                      <div className="">
                        <TodayIcon sx={{color: '#447ed4', fontSize: '36px'}}/>
                      </div>
                      <div className="flex justify-center">
                        <p>{deadline}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p className=" font-semibold mt-1">Enquirer</p>
                  <div className="flex gap-1 items-center justify-center ">
                    <div className="w-[30px] h-[30px]">
                      {userImage === "" || userImage == null ? (
                        <AccountCircleIcon />
                      ) : (
                        <img
                          src={userImage + `?${performance.now()}`}
                          alt=""
                          className="max-h-full w-full"
                        />
                      )}
                    </div>
                    <div>
                      <p>{enquirer || "Not specified"}</p>
                      <p className="text-[#AEA9B1]">
                        {enqOrg || "Unaffiliated"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-6 border bg-white rounded-md mb-5">
              <p className="text-[14px] font-semibold">Question summary</p>
              <p className="text-[16px] mt-3 w-full md:w-[80%]">{subject}</p>
            </div>
            <div className="p-6 border bg-white rounded-md mb-5">
              <p className="text-[14px] font-semibold">Question details</p>
              <div className="flex items-center gap-2 my-3">
                {attachments &&
                  attachments.length > 0 &&
                  attachments?.map((item, index) => {
                    return (
                      <p
                        onClick={() => downloadAsset(item)}
                        className="font-light text-[#034C85] underline cursor-pointer text-sm"
                        key={index}
                      >
                        {removeExtension(item)}
                      </p>
                    );
                  })}
              </div>

              <p className="text-[16px] mt-3 w-full md:w-[80%]">{question}</p>
            </div>
            <div className="w-full p-6 border bg-white rounded-md mb-5">
              <p className="text-[14px] font-semibold">
                Answer details<span className="text-[red] ml-1">*</span>
              </p>

              <div className="flex flex-col md:flex-row items-start md:items-center md:gap-3">
                {userType === "EXPERT" && (
                  <>
                    {answer?.length > 0
                      ? ""
                      : review == false &&
                        send.length < 1 && (
                          <FileUploader
                            setSend={setSend}
                            send={send}
                            text={"Upload document 1"}
                            width={"170px"}
                            info="doc_upload1"
                          />
                        )}
                    {answer?.length > 0
                      ? ""
                      : review == false &&
                        send.length < 2 && (
                          <FileUploader
                            setSend={setSend}
                            send={send}
                            text={"Upload document 2"}
                            width={"170px"}
                            info="doc_upload2"
                          />
                        )}
                  </>
                )}
                <div className="mt-1 flex gap-3 items-center">
                  {send &&
                    send.length > 0 &&
                    send.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="flex items-center gap-2 cursor-pointer"
                        >
                          <p className="font-light text-[#AEA9B1] mb-3">
                            {item.name}
                          </p>
                          <p className="mb-3">
                            <CloseIcon onClick={() => removeFile(index)} />
                          </p>
                        </div>
                      );
                    })}
                </div>
              </div>

              {review === false ? (
                <div>
                  {answer == null || answer.length == 0 ? (
                    userType === "EXPERT" && (
                      <>
                        <textarea
                          value={reply || savedAnswer || ""}
                          onChange={(e) => {
                            setReply(e.target.value);
                            setSavedAnswer(e.target.value);

                            setIsSaved(false);
                            const value = e.target.value;
                            if (value.length > 1000) {
                              setErrors((prevData) => ({
                                ...prevData,
                                answer: `You have exceeded the character limit of 1000 by ${
                                  value.length - 1000
                                }`,
                              }));
                            }
                            // else if (value.length < 50 ) {
                            //   setErrors((prevData) => ({
                            //     ...prevData,
                            //     answer: `Minimum 50 characters required`,
                            //   }));
                            // }
                            else {
                              // Clear the error if within the character limit
                              setErrors((prevData) => ({
                                ...prevData,
                                answer: "",
                              }));
                            }
                          }}
                          placeholder="Your answer here"
                          className={`w-full md:w-[80%] border rounded-md px-2 py-2 h-[105px] ${
                            review
                              ? "border-none cursor-not-allowed pointer-events-none"
                              : ""
                          }`}
                        />
                      </>
                    )
                  ) : (
                    <div>
                      <div className="w-full rounded-md px-0 py-2 overflow-y-auto mb-3">
                        <div className="flex items-center gap-2 my-2">
                          {enqAttachments &&
                            enqAttachments.length > 0 &&
                            enqAttachments?.map((item, index) => {
                              return (
                                <p
                                  onClick={() => downloadAsset(item)}
                                  className="font-light text-[#034C85] underline cursor-pointer text-sm"
                                  key={index}
                                >
                                  {removeExtension(item)}
                                </p>
                              );
                            })}
                        </div>
                        <p className="w-full md:w-[80%]">{answer}</p>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
              {review === true ? (
                <div className="w-full md:w-[80%]">
                  {/* <p className="text-[#034C85]">Your Reply</p> */}
                  {/* {errors?.answer && (
                    <p className="mt-1 mb-1 text-[red] text-sm">
                      {errors?.answer}
                    </p>
                  )} */}
                  {/* <textarea
                    className="w-full min-h-[88px] rounded-md px-0 py-2 border-none cursor-not-allowed pointer-events-none"
                    value={reply || savedAnswer || ""}
                  /> */}
                  {reply.length < 1 && (
                    <p
                      className="text-[#808080]  w-full md:w-[80%] ml-4"
                      // placeholder="   Your question here"
                    >
                      Your question here
                    </p>
                  )}
                  <div className="w-full md:w-[80%]  rounded-md px-0 py-2">
                    {reply || savedAnswer || ""}
                  </div>
                </div>
              ) : null}
              {errors?.answer && (
                <p className="mt-1 mb-1 text-[red] text-sm">{errors?.answer}</p>
              )}
              {userType !== "EXPERT" &&
                (answer == null ||
                  answer.length == 0 ||
                  answer == "" ||
                  savedAnswer.length == 0) && (
                  <p className="mt-2">
                    The expert has received your question, please watch this
                    page for the response.
                  </p>
                )}
              {userType !== "EXPERT" ? (
                ""
              ) : (
                <>
                  {answer == null || answer.length == 0 ? (
                    <div className="flex items-center gap-2">
                      {review === false
                        ? submitted === false && (
                            // <TonalButton
                            //   text={"Save Draft"}
                            //   width={"fit-content"}
                            //   onClick={handleSave}
                            //   disable={isSaved}
                            // />
                            <button
                              className={`${
                                isSaved
                                  ? "text-[#AEA9B1] bg-[#EBEBEB] cursor-not-allowed"
                                  : "bg-[#DEF] text-[#3A74CA]"
                              } rounded  text-sm`}
                              onClick={handleSave}
                              style={{
                                width: "125px",
                                // height: "auto",
                                // color: "#3A74CA",
                                padding: "8px 0px 8px 0px",
                              }}
                              disabled={isSaved}
                            >
                              Save Draft
                            </button>
                          )
                        : submitted === false && (
                            // <PrimaryButton
                            //   text={"Back to Editing"}
                            //   width={"fit-content"}
                            //   onClick={handleBackEditing}
                            // />
                            <button
                              className="bg-[#DEF] rounded text-white text-sm"
                              onClick={handleBackEditing}
                              style={{
                                width: "125px",
                                // height: "auto",
                                color: "#3A74CA",
                                padding: "8px 0px 8px 0px",
                              }}
                            >
                              Back to Editing
                            </button>
                          )}
                      {review === false ? (
                        submitted === false && (
                          <button
                            className="bg-[#084298] rounded text-white text-sm"
                            onClick={() => {
                              setReview(true);
                              handleInputsCheck();
                            }}
                            style={{
                              width: "125px",

                              padding: "8px 0px 8px 0px",
                            }}
                          >
                            Review
                          </button>
                        )
                      ) : spinner === false ? (
                        submitted === false && (
                          <button
                            className="bg-[#69B04B] rounded text-white text-sm"
                            onClick={() => postAnswer()}
                            style={{
                              width: "125px",
                              padding: "8px 0px 8px 0px",
                            }}
                          >
                            Submit
                          </button>
                        )
                      ) : (
                        <Spinner />
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </>
              )}
              {userType === "EXPERT" &&
                (retrievedComments?.length > 0 ||
                  answer ||
                  answer?.length > 0) && (
                  <div className="">
                    <p className="text-[#605D64] text-[14px] mt-2 mb-3 font-semibold">
                      Comments{" "}
                      <span className="ml-1 font-normal">
                        {retrievedComments.length == 6
                          ? "Comment limit exceeded. Please raise another question."
                          : `( ${
                              6 - retrievedComments.length
                            } comments remaining )`}
                      </span>
                    </p>
                    {commentsAttachments && commentsAttachments.length > 0 && (
                      <div className="flex items-center gap-2 my-2">
                        {commentsAttachments &&
                          commentsAttachments.length > 0 &&
                          commentsAttachments?.map((item, index) => {
                            return (
                              <p
                                onClick={() => downloadAsset(item)}
                                className="font-light text-[#034C85] underline cursor-pointer text-sm"
                                key={index}
                              >
                                {removeExtension(item)}
                              </p>
                            );
                          })}
                      </div>
                    )}
                    <div className="my-1 flex gap-3 items-center">
                      {sendCommDoc &&
                        sendCommDoc.length > 0 &&
                        sendCommDoc.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="flex items-center gap-2 cursor-pointer"
                            >
                              <p className="font-light text-[#AEA9B1] mb-3">
                                {item.name}
                              </p>
                              <p className="mb-3">
                                <CloseIcon
                                  onClick={() => removeCommentFile(index)}
                                />
                              </p>
                            </div>
                          );
                        })}
                    </div>
                    {retrievedComments && retrievedComments.length !== 0 && (
                      <ExpertComments
                        retrievedComments={retrievedComments}
                        userType={userType}
                        fetchUserImageUrl={fetchUserImageUrl}
                      />
                    )}
                    {/* {isCommentsExceeded && (
                        <div className="mt-2">
                          Comment limit exceeded. Please raise another question.
                        </div> 
                      )} */}

                    <div className="relative w-full md:w-[80%]">
                      <textarea
                        type="text"
                        value={comment}
                        disabled={isCommentsExceeded || !answer}
                        onChange={(e) => {
                          setComment(e.target.value);
                          const value = e.target.value;
                          if (value.length > 500) {
                            setErrors((prevData) => ({
                              ...prevData,
                              comment: `You have exceeded the character limit of 500 by ${
                                value.length - 500
                              }`,
                            }));
                          } else {
                            // Clear the error if within the character limit
                            setErrors((prevData) => ({
                              ...prevData,
                              comment: "",
                            }));
                          }
                        }}
                        placeholder="Add your comment"
                        className={`w-full border h-20 rounded-md px-2 py-2 mt-2 ${
                          isCommentsExceeded || !answer
                            ? "border-none disabled: opacity-50"
                            : "border"
                        }`}
                      />
                      {errors?.comment && (
                        <p className="mt-1 mb-1 text-[red] text-sm">
                          {errors?.comment}
                        </p>
                      )}
                      <div
                        className={`mb-1 ml-1 flex flex-col sm:flex-row items-start sm:items-center gap-2`}
                      >
                        {isCommentsExceeded || !answer
                          ? ""
                          : sendCommDoc &&
                            sendCommDoc?.length < 1 && (
                              <FileUploader
                                setSend={setSendCommDoc}
                                send={sendCommDoc}
                                text={"Upload document"}
                                width={"168px"}
                                info="add_doc_expert"
                              />
                            )}

                        <button
                          className={`text-[#FFF] text-sm rounded bg-[#6CA6FC] h-[33px] w-[168px] ${
                            isCommentsExceeded || !answer
                              ? "border-none bg-[#cbcccd] cursor-not-allowed pointer-events-none"
                              : ""
                          }`}
                          onClick={() => postComments()}
                          disabled={isCommentsExceeded || !answer}
                        >
                          {spinner ? "Posting" : "Post comment"}
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              {userType !== "EXPERT" && (
                <div className="">
                  <p className="text-[#605D64] text-[14px] mt-2 mb-3 font-semibold">
                    Comments
                    <span className="ml-1 font-normal">
                      {retrievedComments.length == 6
                        ? "Comment limit exceeded. Please raise another question."
                        : `( ${
                            6 - retrievedComments.length
                          } comments remaining )`}
                    </span>
                  </p>
                  {commentsAttachments && commentsAttachments.length > 0 && (
                    <div className="flex items-center gap-2 my-2">
                      {/* <p> */}
                      {commentsAttachments &&
                        commentsAttachments.length > 0 &&
                        commentsAttachments?.map((item, index) => {
                          return (
                            <p
                              onClick={() => downloadAsset(item)}
                              className="font-light text-[#034C85] underline cursor-pointer text-sm"
                              key={index}
                            >
                              {removeExtension(item)}
                            </p>
                          );
                        })}
                      {/* </p> */}
                    </div>
                  )}
                  <div className="my-1 flex gap-3 items-center">
                    {sendCommDoc &&
                      sendCommDoc.length > 0 &&
                      sendCommDoc.map((item, index) => {
                        return (
                          <div
                            key={index}
                            className="flex items-center gap-2 cursor-pointer"
                          >
                            {/* <img src="/pdf.svg" alt="pdf" /> */}
                            <p className="font-light text-[#AEA9B1] mb-3">
                              {item.name}
                            </p>
                            <p className="mb-3">
                              <CloseIcon
                                onClick={() => removeCommentFile(index)}
                              />
                            </p>
                          </div>
                        );
                      })}
                  </div>
                  {retrievedComments && retrievedComments.length !== 0 && (
                    <ExpertComments
                      retrievedComments={retrievedComments}
                      userType={userType}
                      fetchUserImageUrl={fetchUserImageUrl}
                    />
                  )}
                  {/* {isCommentsExceeded && (
                    <div className="mt-2">
                      Comment limit exceeded. Please raise another question.
                    </div>
                  )} */}
                  <div className="w-full relative  md:w-[80%]">
                    <textarea
                      type="text"
                      value={comment}
                      onChange={(e) => {
                        setComment(e.target.value);
                        const value = e.target.value;
                        if (value.length > 500) {
                          setErrors((prevData) => ({
                            ...prevData,
                            comment: `You have exceeded the character limit of 500 by ${
                              value.length - 500
                            }`,
                          }));
                        } else {
                          // Clear the error if within the character limit
                          setErrors((prevData) => ({
                            ...prevData,
                            comment: "",
                          }));
                        }
                      }}
                      placeholder="Add your comment"
                      className={`w-full h-20 rounded-md px-2 py-2 mt-2 ${
                        isCommentsExceeded ? "border-none" : "border"
                      }`}
                    />
                    {errors?.comment && (
                      <p className="mt-1 mb-1 text-[red] text-sm">
                        {errors?.comment}
                      </p>
                    )}
                    <div
                      className={`mb-1 ml-1 flex flex-col sm:flex-row items-start sm:items-center gap-2`}
                    >
                      {isCommentsExceeded
                        ? ""
                        : sendCommDoc &&
                          sendCommDoc?.length < 1 && (
                            <FileUploader
                              setSend={setSendCommDoc}
                              send={sendCommDoc}
                              text={"Upload document"}
                              width={"168px"}
                              info="add_doc_user"
                            />
                          )}

                      <button
                        className={`text-[#FFF] text-sm rounded bg-[#6CA6FC] h-[33px] w-[168px] ${
                          isCommentsExceeded
                            ? "border-none bg-[#cbcccd] cursor-not-allowed"
                            : ""
                        }`}
                        onClick={() => postComments()}
                        disabled={isCommentsExceeded}
                      >
                        {spinner ? "Posting" : "Post comment"}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <div className="mt-40">
            <div className="w-full flex justify-center mt-10">
              <img src="/query.svg" />
            </div>
            <div className="w-full flex justify-center">
              <p className="font-semibold text-2xl mt-4">
                Thank you for your expertise in addressing the query.
              </p>
            </div>
            <div className="mt-5 w-full flex justify-center">
              <p className="px-10 font-light">
                Your insights and expertise are greatly appreciated.
              </p>
            </div>
            <div className="mt-5 w-full flex justify-center">
              <p className="px-10 font-light">
                If any further updates or clarifications are required, you will
                be contacted through comments.
              </p>
            </div>
            <div className="flex justify-center items-center mt-2">
              <PrimaryButton
                text={"Go back to Expert Dashboard"}
                width={"fit-content"}
                onClick={handleBackDashboard}
              />
            </div>
          </div>
        )}
      </div>

      {isSaveModalOpen && (
        <SaveDraftModal
          isOpen={isSaveModalOpen}
          onClose={onCloseSavemodal}
          handleSave={handleSave}
          navigate={navigate}
        />
      )}
    </>
  );
};

export default Expert;
