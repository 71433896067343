import { Tooltip } from "@chakra-ui/react";

const calWidth = (data, val, flag = false) => {
  let max = flag
    ? Math.max(...data)
    : Math.max(...data.map((item) => item.questionAsked));
  let percent = val / max;
  return percent * 150;
};

const HorizontalBarCustom = ({ data, expertView = false }) => {
  let { names, questions } = data;
  return (
    <div className="flex flex-col justify-start gap-2 w-[300px] h-full relative">
      {expertView
        ? names.map((item, idx) => {
            return (
              <div className="flex gap-1 items-center text-sm text-[#605D64] w-full">
                <div className="w-[100px]">
                  <Tooltip label={item} hasArrow placement="top">
                    <p className="overflow-hidden text-ellipsis w-[100px] whitespace-nowrap">
                      {item}
                    </p>
                  </Tooltip>
                </div>
                <div
                  className="h-4 rounded-r bg-[#6CA6FC]"
                  style={{
                    width: `${calWidth(
                      questions,
                      questions[idx],
                      expertView
                    )}px`,
                  }}
                />
                <p>{questions[idx]}</p>
              </div>
            );
          })
        : data.slice(0, 3).map((item) => {
            return (
              <div className="flex gap-1 items-center text-sm text-[#605D64] w-full">
                <div className="w-[100px]">
                  <Tooltip label={item.expertName} hasArrow placement="top">
                    <p className="overflow-hidden text-ellipsis w-[100px] whitespace-nowrap">
                      {item.expertName}
                    </p>
                  </Tooltip>
                </div>
                <div
                  className="h-4 rounded-r bg-[#6CA6FC]"
                  style={{
                    width: `${calWidth(
                      data.slice(0, 3),
                      item.questionAsked
                    )}px`,
                  }}
                />
                <p>{item.questionAsked}</p>
              </div>
            );
          })}
      <p className="absolute -top-[30px] flex w-full justify-center items-center text-sm text-[#605D64]">
        No. of questions
      </p>
    </div>
  );
};

export default HorizontalBarCustom;
