import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { Breadcrumb, BreadcrumbItem } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import Alerts from "./Tabs/Alerts";
import FeedLibrary from "./Tabs/FeedLibrary";
import Report from "./Tabs/Report";
import CamFeed from "./Tabs/CamFeed";
import Analytics from "./Tabs/Analytics";
import Timer from "../../util/VisionUtils/Timer";
import { useWindowSize } from "@uidotdev/usehooks";
import { mixpanelTrackTabs } from "../../util/mixpanelFunctions";
import RetrainContact from "../../util/RetrainContact/RetrainContact";
import { capitalizeFirstLetter } from "../../util/sentenceCase";
import CrusherEffectiveness from "../CrusherEffectiveness/CrusherEffectiveness";


const Capitalize = (str) => {
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
};

const SingleCam = () => {
  const size = useWindowSize();
  const param = useParams();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const page = !isNaN(searchParams?.get("tabIndex"))
    ? Number(searchParams?.get("tabIndex"))
    : 0;
  const [callApi, setCallApi] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  let material = param.material.toLowerCase();
  let cameraId = param.cameraId;
  let clientId = param.clientId;
  let plantId = param.plantId;
  const [showCrusherTab, setShowCrusherTab] = useState(false);
  const handlePageChange = (val) => {
    if (val !== page) {
      setSearchParams({ tabIndex: val }, { replace: true });
    }
  };

  useEffect(() => {
    handlePageChange(page);
    let tool = Capitalize(param.material.toLowerCase()) + " Sizing";
    mixpanelTrackTabs(tabList[page]?.tabName.toLowerCase(), tool);
  }, [page]);

  const tabList = [
    {
      index: 0,
      tabName: "Feed",
      element: (
        <CamFeed
          material={material}
          cameraId={cameraId}
          clientId={clientId}
          plantId={plantId}
          callApi={callApi}
          initialRender={initialRender}
          setShowCrusherTab={setShowCrusherTab}
        />
      ),
    },
    {
      index: 1,
      tabName: "Alert",
      element: <Alerts plantId={plantId} cameraId={cameraId} disable={true} />,
    },
    {
      index: 2,
      tabName: "Feed library",
      element: (
        <FeedLibrary plantId={plantId} cameraId={cameraId} disable={true} />
      ),
    },
    {
      index: 3,
      tabName: "Analytics",
      element: (
        <Analytics plantId={plantId} cameraId={cameraId} disable={true} />
      ),
    },
    {
      index: 4,
      tabName: "Report",
      element: <Report plantId={plantId} cameraId={cameraId} disable={true} />,
    },
    {
      index: 5,
      tabName: "Crusher Effectiveness",
      element: (
        <CrusherEffectiveness
          material={param?.material?.toLowerCase()}
          clientId={param?.clientId?.toLowerCase()}
          handlePageChange={handlePageChange}
          page={page}
        />
      ),
    },
  ].filter((tab) => {
    if (tab.tabName === "Crusher Effectiveness") {
      return showCrusherTab;
    }
    return true;
  });

  return (
    <div
      className=" flex flex-col rounded-lg"
      style={{ width: size.width >= 768 ? "calc(100vw - 168px)" : "100vw" }}
    >
      {location.pathname.includes("blastfurnace") ? (
        <></>
      ) : (
        <div className="flex justify-between mb-3 mt-6 items-center">
          <Breadcrumb
            spacing="8px"
            separator={<ChevronRightIcon color="#024D87" />}
          >
            <BreadcrumbItem>
              <div className="flex justify-start items-center gap-2">
                <Link
                  to={`/vision/Sizing/${material}/${clientId}`}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <img src="/backtick.svg" />
                </Link>
                <p className="text-lg sm:text-2xl font-semibold text-[#024D87]">
                  {capitalizeFirstLetter(material + " Sizing")}
                </p>
              </div>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <p className="text-lg sm:text-2xl font-semibold text-[#024D87]">
                {capitalizeFirstLetter(plantId)}
              </p>
            </BreadcrumbItem>
            <BreadcrumbItem>
              <p className="text-lg sm:text-2xl font-semibold text-[#024D87]">
                {param.cameraId}
              </p>
            </BreadcrumbItem>
          </Breadcrumb>
        </div>
      )}
      <Tabs index={page} onChange={handlePageChange}>
        <div className="flex justify-between items-center overflow-x-auto h-14 md:h-10">
          <TabList className="!flex !border-0">
            <div className="flex items-center gap-4">
              {tabList?.map((item) => {
                return (
                  <Tab
                    className={
                      page === item?.index
                        ? "!text-black !text-xs sm:!text-sm !bg-white rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#79767D]"
                        : "!text-xs sm:!text-sm !text-[#938F96] !border-0"
                    }
                    onClick={() => handlePageChange(item?.index)}
                  >
                    {item?.tabName}
                  </Tab>
                );
              })}
            </div>
          </TabList>
          <div className="flex gap-4">
            {
              <div
                className={`${page === "feed" ? "opacity-100" : "opacity-0"}`}
              >
                <Timer
                  initialSeconds={30}
                  callFunc={setCallApi}
                  initialRender={initialRender}
                  setInitialRender={setInitialRender}
                />
              </div>
            }
            {/* <RetrainContact /> */}
          </div>
        </div>

        <TabPanels>
          {tabList?.map((item) => {
            return <TabPanel className="!pl-0 !pr-0">{item?.element}</TabPanel>;
          })}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default SingleCam;
