import ReactApexChart from "react-apexcharts";

const map = {
  "Low flame": 1,
  "Healthy flame": 2,
  "Flame out": 3,
  "View Obstructed": 0,
};

const StackBarChart = ({ data }) => {
  const series = [
    {
      name: "Low flame",
      data: [],
    },
    {
      name: "Healthy flame",
      data: [],
    },
    {
      name: "Flame out",
      data: [],
    },
    {
      name: "View Obstructed",
      data: [],
    },
  ];

  const burners = [];

  data.map((val) => {
    burners.push(val.cameraId);
  });

  series.map((obj, idx) => {
    data.map((val) => {
      obj.data.push(val.freqDist[map[obj.name]]);
    });
  });

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      type: "bar",
      stacked: true,
      stackType: "100%",
    },
    tooltip: {
      y: {
        show: true,
        formatter: function (
          value,
          { series, seriesIndex, dataPointIndex, w }
        ) {
          let percent = w.globals.seriesPercent[seriesIndex][dataPointIndex];
          return Math.round(percent) + "%";
        },
      },
      theme: "dark",
      fillSeriesColor: true,
      style: {
        fontSize: "16px",
      },
    },
    colors: ["#FF006E", "#FB5607", "#FFBE0B", "#6c757d", "#3A86FF"],
    xaxis: {
      // categories: ["1 (N)", "2", "3", "4", "5", "6", "7", "8 (S)"],
      categories: burners,
      labels: {
        show: true,
        formatter: function (value) {
          return value;
        },
        style: {
          fontSize: "14px",
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "right",
      offsetX: -30,
      offsetY: 0,
      fontSize: "14px",
      labels: {
        colors: "#79767D",
      },
      markers: {
        width: 18,
        height: 18,
        radius: 12,
      },
      alignLabels: true,
      itemMargin: {
        horizontal: 0,
        vertical: 5,
      },
    },
  };
  return (
    <ReactApexChart
      options={options}
      series={series}
      type="bar"
      height="300px"
    />
  );
};

export default StackBarChart;
