const DataTable = ({ data }) => {
  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
      {data.map((item, index) => (
        <div
          key={index}
          className=" col-span-1 rounded-md border border-[#EBEBEB] bg-[#FFFFED] text-base pt-0.5"
        >
          <div className=" font-semibold whitespace-nowrap px-4 py-2 bg-[#FFF] text-[#48464C] text-base  ">
            {item?.parameter}
          </div>
          <div className="px-4 py-2  font-semibold  border-t flex justify-between">
            <div className="text-[#79767D] whitespace-nowrap">
              Previous 24 Hrs
            </div>
            <div className="whitespace-nowrap">
              {item?.last24Hours && (
                <>
                  {item.last24Hours}
                  {item.parameter?.includes("px") ? " px" : " %"}
                </>
              )}
            </div>
          </div>
          <div className="px-4 py-2  font-semibold  border-t flex justify-between">
            <div className="text-[#79767D] whitespace-nowrap">
              Previous shift
            </div>
            <div className="whitespace-nowrap">
              {item?.lastShift && (
                <>
                  {item.lastShift}
                  {item.parameter?.includes("px") ? " px" : " %"}
                </>
              )}
            </div>
          </div>
          <div className="px-4 py-2  font-semibold  border-t flex justify-between">
            <div className="text-[#79767D] whitespace-nowrap">
              Previous hour
            </div>
            <div className="whitespace-nowrap">
              {item?.last1Hour && (
                <>
                  {item.last1Hour}
                  {item.parameter?.includes("px") ? " px" : " %"}
                </>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DataTable;
