import { CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
import { capitalizeFirstLetter } from "../../../util/sentenceCase";
import { formatColumnName } from "./formatColumnName";
import { formatDateTime } from "./formatDateTime";

export const generateDynamicColumns = async (
  columnNames,
  filterable = false,
  sortable = false,
  disableColumnMenu = true,
  flexMap,
  minWidthMap
) => {
  const dynamicColumns = columnNames.map((columnName, index) => {
    let columnConfig = {
      field: columnName,
      headerName: capitalizeFirstLetter(formatColumnName(columnName)),

      // checking for array and index is not out bound value both needs to be of same length as that of columnNames
      flex: flexMap && flexMap?.[columnName] ? flexMap[columnName] : 1,
      minWidth:
        minWidthMap && minWidthMap?.[columnName]
          ? minWidthMap[columnName]
          : 130,
      sortable: sortable,
      disableColumnMenu: disableColumnMenu,
      filterable: filterable,
    };
    switch (columnName) {
      case "startTime":
      case "endTime":
      case "timestamp":
      case "createdAt":
      case "poEnd":
      case "poStart":
        columnConfig.valueGetter = (params) => {
          return params.value ? formatDateTime(params.value) : "";
        };
        columnConfig.minWidth = 140;
        break;
      case "alertType":
        columnConfig.valueFormatter = (params) => {
          return params.value
            ? capitalizeFirstLetter(formatColumnName(params.value))
            : "";
        };
        break;

      case "recipeQuantity":
      case "actualQuantity":
        columnConfig.valueFormatter = (params) => {
          if (
            typeof params.value === "number" &&
            isNaN(params.value) === false
          ) {
            return Math.floor(params?.value);
          }
          return params.value !== null && params.value !== undefined
            ? params.value
            : "-";
        };
        break;
      case "deviation":
        columnConfig.minWidth = 150;
        columnConfig.renderCell = (params) => {
          let percentage = 0;
          let wt = 0;
          let sign = "";
          const {
            deviation,
            concentrate,
            actualQuantity,
            recipeQuantity,
            concentrateStatus,
          } = params?.row || {};

          if (concentrate === "Start time" || concentrate === "End time") {
            if (deviation && deviation.toString().length === 13) {
              return (
                <div className="font-semibold">
                  {new Date(deviation).toLocaleString("en-GB", {
                    timeZone: "IST",
                  })}
                </div>
              );
            } else if (!deviation || deviation.toString().length < 10) {
              return <div className="font-semibold">-</div>;
            } else {
              return (
                <div className="font-semibold">
                  {new Date(deviation * 1000).toLocaleString("en-GB", {
                    timeZone: "UTC",
                  })}
                </div>
              );
            }
          } else if (
            actualQuantity !== null &&
            actualQuantity !== undefined &&
            recipeQuantity !== null &&
            recipeQuantity !== undefined
          ) {
            percentage = Math.round((actualQuantity / recipeQuantity) * 100);
            if (recipeQuantity > actualQuantity) {
              wt = recipeQuantity - actualQuantity;
              sign = "-";
            } else if (recipeQuantity < actualQuantity) {
              wt = actualQuantity - recipeQuantity;
              sign = "+";
            }

            return (
              <div className="flex justify-center w-full">
                <CircularProgress
                  value={percentage}
                  thickness={"8px"}
                  color={percentage > 100 ? "red.500" : "blue.500"}
                >
                  <CircularProgressLabel>
                    {sign}
                    {Math.floor(wt)}
                  </CircularProgressLabel>
                </CircularProgress>
              </div>
            );
          }
          return null; // Add a default return to handle all cases
        };
        columnConfig.valueGetter = (params) => {
          const {
            deviation,
            concentrate,
            actualQuantity,
            recipeQuantity,
            concentrateStatus,
          } = params?.row || {};

          if (concentrate === "Start time" || concentrate === "End time") {
            return "";
          } else {
            let wt = 0;
            let sign = "";

            if (recipeQuantity > actualQuantity) {
              wt = recipeQuantity - actualQuantity;
            } else if (recipeQuantity < actualQuantity) {
              wt = actualQuantity - recipeQuantity;
            }
            console.log("Setting", wt);
            return wt ? Math.floor(wt) : "";
          }

          return "";
        };
        break;

      case "plantName":
      case "cameraId":
        columnConfig.valueFormatter = (params) => {
          return params.value ? capitalizeFirstLetter(params.value) : "";
        };
        break;
      default:
        break;
    }

    return columnConfig;
  });
  return dynamicColumns;
};
