import React from "react";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';


const ViewProduct = ({
    selectedProductGroup,
    setShowProductTab,
}) => {

    const industries = selectedProductGroup?.product?.prospectiveIndustries?.split(",");
    const clouds = selectedProductGroup?.product?.cloud?.split(",");


    console.log(selectedProductGroup);

    const handleBackButton = () => {
        // setShowAddNewProductCategoryForm(false);
        setShowProductTab((prev) => "products");
    };


    return (
        <div className="font-roboto flex flex-col gap-2 mt-0">
            <div className="flex items-center">
                <div className="cursor-pointer w-8" onClick={handleBackButton}>
                    <KeyboardBackspaceIcon sx={{ color: "#084298" }} />
                </div>
                <p className="text-[#084298] font-medium text-xl ml-2">
                    View product
                </p>
            </div>

            <div className='flex flex-row gap-3'>
                <div className="w-[50%] flex flex-col gap-3 mt-6">
                    <p className="text-[#3E3C42] text-lg font-medium">
                        Product group<span className="text-red-500">*</span>
                    </p>
                    <div className="w-full lg:w-[400px]">
                        <p className="text-[#525056] text-sm font-medium">{selectedProductGroup?.productGroup}</p>

                    </div>
                </div>
                <div className="w-[50%] flex flex-col gap-3 mt-6">
                    <p className="text-[#3E3C42] text-lg font-medium">
                        Product parent group
                    </p>
                    <div className="w-full lg:w-[400px]">

                        <p className="text-[#525056] text-sm font-medium">  {selectedProductGroup?.productCategory}</p>

                    </div>
                </div>
            </div>

            <hr className="mt-3"></hr>

            <div className="flex flex-col gap-3 mt-3">
                <p className="text-[#3E3C42] text-lg font-medium">
                    Product name<span className="text-red-500">*</span>
                </p>
                <div className="flex flex-row items-center md:w-[400px]">

                    {selectedProductGroup?.product?.productLogoUrl ? <img className="w-[40px] h-[40px] mr-2" src={selectedProductGroup?.product?.productLogoUrl} /> : <img className="w-[40px] h-[40px] mr-2" src="/ProductDefaultIcon.svg" />}


                    <p className="text-[#525056] text-sm font-medium">{selectedProductGroup?.product?.productName}</p>





                </div>
            </div>

            <hr className="mt-3"></hr>

            {/* <AddIcon
                logo={cardLogo}
                setLogo={setCardLogo}
                toast={toast}
                logoPreview={cardLogoPreview}
                setLogoPreview={setCardLogoPreview}
                submitClicked={submitClicked}
                desiredRatio={"3 : 2"}
                text={"2:2 ratio, Supported formats: .png, .svg, .jpeg"}
                info={"2"}
                labelText={"Upload a square icon for the product"}
            /> */}

            <div className="flex items-center gap-4 mt-3">
                <div>
                    <p className="text-[#3E3C42] text-lg font-medium">
                        Description
                    </p>
                    <div className="mt-6 md:w-[400px]">
                        <p className="text-[#525056] text-sm font-medium"> {selectedProductGroup?.product?.productDescription ? selectedProductGroup?.product?.productDescription : "...."}</p>

                    </div>
                </div>
            </div>

            <hr className="mt-3"></hr>

            <div className="flex flex-col gap-3 mt-3">
                <p className="text-[#3E3C42] text-lg font-medium">
                    Usecase type
                </p>
                <div className="md:w-[400px]">
                    <p className="text-[#525056] text-sm font-medium">{selectedProductGroup?.product?.usecaseType ? selectedProductGroup?.product?.usecaseType : "...."}</p>
                </div>
            </div>

            <hr className="mt-3"></hr>

            <div className="flex flex-col gap-3 mt-3">
                <p className="text-[#3E3C42] text-lg font-medium">
                    Regulatory requirements
                </p>

                <div className="md:w-[400px]">
                    <p className="text-[#525056] text-sm font-medium">   {selectedProductGroup?.product?.regulatoryRequirement ? selectedProductGroup?.product?.regulatoryRequirement : "...."} </p>
                </div>

            </div>

            <hr className="mt-3"></hr>

            <div className="flex flex-col gap-3 mt-3">
                <p className="text-[#3E3C42] text-lg font-medium">
                    Prospective industries
                </p>

                <div className=" flex flex-wrap ">

                    {industries?.length > 0 ? industries?.map((industry) =>
                        <div className="pr-2">
                            <p className="text-[#525056] text-sm font-medium">{industry} </p>
                        </div>
                    ) : "...."}
                </div>

            </div>

            <hr className="mt-3"></hr>

            <div className="flex flex-col gap-3 mt-3">
                <p className="text-[#3E3C42] text-lg font-medium">
                    Prospective clients
                </p>

                <div className="md:w-[400px]">

                    <p className="text-[#525056] text-sm font-medium"> {selectedProductGroup?.product?.prospectiveClient ? selectedProductGroup?.product?.prospectiveClient : "...."} </p>

                </div>

            </div>

            <hr className="mt-3"></hr>

            <div className="flex flex-col gap-3 mt-3">
                <p className="text-[#3E3C42] text-lg font-medium">
                    User persona
                </p>
                <div className="md:w-[400px]">

                    <p className="text-[#525056] text-sm font-medium">   {selectedProductGroup?.product?.userPersona ? selectedProductGroup?.product?.userPersona : "...."} </p>



                </div>
            </div>

            {/* <div className="flex flex-col gap-3 mt-6">
                <p className="text-[#3E3C42] text-lg font-medium">
                    Geographies
                </p>
                <div className="md:w-[400px]">

                    {selectedProductGroup.geographies}

                </div>
            </div> */}

            <hr className="mt-3"></hr>

            <div className="flex flex-col gap-3 mt-3">
                <p className="text-[#3E3C42] text-lg font-medium">
                    Existing demo/ Prototype link
                </p>


                {selectedProductGroup?.product?.demoLink ? (<div className="md:w-[400px]">

                    <p className="text-[#525056] text-sm font-medium">    {selectedProductGroup?.product?.demoLink} </p>
                </div>) : "...."}

            </div>

            <hr className="mt-3"></hr>

            <div className="flex flex-col gap-3 mt-3">
                <p className="text-[#3E3C42] text-lg font-medium">
                    Time for MVP/full product (estimate)
                </p>
                <div className="md:w-[400px]">

                    <p className="text-[#525056] text-sm font-medium">     {selectedProductGroup?.product?.mvpTime ? selectedProductGroup?.product?.mvpTime : "...."} </p>
                </div>


            </div>

            {/* <div className="flex flex-col gap-3 mt-6">
                <p className="text-[#3E3C42] text-lg font-medium">
                    Development resources for MVP/ full product (estimate)
                </p>
                <div className="md:w-[400px]">

                    {selectedProductGroup.mvpDemoResources}



                </div>

            </div> */}

            <hr className="mt-3"></hr>

            <div className="flex flex-col gap-3 mt-3">
                <p className="text-[#3E3C42] text-lg font-medium">
                    Requirements
                </p>
                <div className="md:w-[400px]">

                    <p className="text-[#525056] text-sm font-medium">    {selectedProductGroup?.product?.requirement ? selectedProductGroup?.product?.requirement : "...."} </p>



                </div>
            </div>

            <hr className="mt-3"></hr>

            <div className="flex flex-col gap-3 mt-3">
                <p className="text-[#3E3C42] text-lg font-medium">
                    AI model
                </p>
                <div className="md:w-[400px]">

                    <p className="text-[#525056] text-sm font-medium">     {selectedProductGroup?.product?.aiModel ? selectedProductGroup?.product?.aiModel : "...."} </p>



                </div>
            </div>

            <hr className="mt-3"></hr>

            <div className="flex flex-col gap-3 mt-3">
                <p className="text-[#3E3C42] text-lg font-medium">
                    Databases
                </p>
                <div className="md:w-[400px]">

                    <p className="text-[#525056] text-sm font-medium">    {selectedProductGroup?.product?.databases ? selectedProductGroup?.product?.databases : "...."} </p>



                </div>
            </div>

            <hr className="mt-3"></hr>

            <div className="flex flex-col gap-3 mt-3">
                <p className="text-[#3E3C42] text-lg font-medium">
                    Storage
                </p>
                <div className="md:w-[400px]">

                    <p className="text-[#525056] text-sm font-medium">    {selectedProductGroup?.product?.storage ? selectedProductGroup?.product?.storage : "...."} </p>



                </div>
            </div>

            <hr className="mt-3"></hr>

            <div className="flex flex-col gap-3 mt-3">
                <p className="text-[#3E3C42] text-lg font-medium">
                    Server
                </p>
                <div className="md:w-[400px]">

                    <p className="text-[#525056] text-sm font-medium">    {selectedProductGroup?.product?.server ? selectedProductGroup?.product?.server : "...."} </p>



                </div>
            </div>

            <hr className="mt-3"></hr>

            <div className="flex flex-col gap-3 mt-3">
                <p className="text-[#3E3C42] text-lg font-medium">
                    Cloud
                </p>
                <div className="md:w-[400px]">

                    {clouds ? clouds?.map((cloud) =>
                        <div className="pr-2">
                            <p className="text-[#525056] text-sm font-medium">     {cloud} </p>
                        </div>
                    ) : "...."}

                    {/* {selectedProductGroup.product.cloud ? selectedProductGroup.product.cloud : '....'} */}



                </div>
            </div>

            <hr className="mt-3"></hr>

            <div className="flex flex-col gap-3 mt-3">
                <p className="text-[#3E3C42] text-lg font-medium">
                    Close Looping
                </p>
                <div className="md:w-[400px]">

                    <p className="text-[#525056] text-sm font-medium">    {selectedProductGroup?.product?.closeLooping ? selectedProductGroup?.product?.closeLooping : "...."} </p>



                </div>
            </div>

            <hr className="mt-3"></hr>

            <div className="flex flex-col gap-3 mt-3">
                <p className="text-[#3E3C42] text-lg font-medium">
                    Deployment
                </p>
                <div className="md:w-[400px]">

                    <p className="text-[#525056] text-sm font-medium">     {selectedProductGroup?.product?.deployment ? selectedProductGroup?.product?.deployment : "...."} </p>



                </div>
            </div>

            <hr className="mt-3"></hr>

            <div className="flex flex-col gap-3 mt-3">
                <p className="text-[#3E3C42] text-lg font-medium">
                    Data Size estimate
                </p>
                <div className="md:w-[400px]">

                    <p className="text-[#525056] text-sm font-medium">   {selectedProductGroup?.product?.dataSize ? selectedProductGroup?.product?.dataSize : "...."} </p>



                </div>
            </div>

            <hr className="mt-3"></hr>

            <div className="flex flex-col gap-3 mt-3">
                <p className="text-[#3E3C42] text-lg font-medium">
                    Video FPS
                </p>
                <div className="md:w-[400px]">

                    <p className="text-[#525056] text-sm font-medium">    {selectedProductGroup?.product?.videoFps ? selectedProductGroup?.product?.videoFps : "...."} </p>



                </div>
            </div>

            <hr className="mt-3"></hr>

            <div className="flex flex-col gap-3 mt-3">
                <p className="text-[#3E3C42] text-lg font-medium">
                    Feature List/PRD
                </p>
                <div className="md:w-[400px]">

                    <p className="text-[#525056] text-sm font-medium">     {selectedProductGroup?.product?.featureList ? selectedProductGroup?.product?.featureList : "...."} </p>



                </div>
            </div>

        </div>

    );
};

export default ViewProduct;
