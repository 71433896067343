import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { Breadcrumb, BreadcrumbItem } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Alerts from "./Tabs/Alerts";
import FeedLibrary from "./Tabs/FeedLibrary";
import Report from "./Tabs/Report";
import CamFeed from "./Tabs/CamFeed";
import Analytics from "./Tabs/Analytics";
import Timer from "../../util/VisionUtils/Timer";
import { useWindowSize } from "@uidotdev/usehooks";
import { mixpanelTrackTabs } from "../../util/mixpanelFunctions";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { capitalizeFirstLetter } from "../../util/sentenceCase";

const Capitalize = (str) => {
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
};

const SinterflameSingle = () => {
  const size = useWindowSize();
  const param = useParams();
  const [searchParam, setSearchParam] = useSearchParams();
  const page = !isNaN(searchParam?.get("tabIndex"))
    ? Number(searchParam?.get("tabIndex"))
    : 0;
  const [callApi, setCallApi] = useState(false);
  const [initialRender, setInitialRender] = useState(true);
  let material = "sinterflame";
  let cameraId = param.cameraId;
  let clientId = param.clientId;
  let plantId = param.plantId;

  const handleTabChange = (val) => {
    if (val !== page) {
      setSearchParam({ tabIndex: val }, { replace: true });
    }
  };

  useEffect(() => {
    handleTabChange(page);
    let tool = "Sinter Flame Monitoring";
    mixpanelTrackTabs(tabList[page]?.tabName?.toLowerCase(), tool);
  }, [page]);

  const tabList = [
    {
      index: 0,
      tabName: "Feed",
      element: (
        <CamFeed
          material={material}
          cameraId={cameraId}
          clientId={clientId}
          callApi={callApi}
          initialRender={initialRender}
        />
      ),
    },
    {
      index: 1,
      tabName: "Alerts",
      element: <Alerts plantId={plantId} cameraId={cameraId} disable={true} />,
    },
    {
      index: 2,
      tabName: "Feed library",
      element: (
        <FeedLibrary plantId={plantId} cameraId={cameraId} disable={true} />
      ),
    },
    {
      index: 3,
      tabName: "Analytics",
      element: (
        <Analytics plantId={plantId} cameraId={cameraId} disable={true} />
      ),
    },
    {
      index: 4,
      tabName: "Report",
      element: <Report plantId={plantId} cameraId={cameraId} disable={true} />,
    },
  ];

  return (
    <div
      className="flex flex-col rounded-lg"
      style={{ width: size.width >= 768 ? "calc(100vw - 168px)" : "100vw" }}
    >
      <div
        className="fixed top-5 right-0 h-[30px] w-[180px] bg-white"
        style={{ zIndex: 1000 }}
      >
        <img
          className="absolute h-[50px] w-[120px] top-[-20px] right-2"
          src="/clientLogos/hzl.png"
        />
      </div>
      <div className="flex justify-between mb-3 mt-6 items-center">
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="#024D87" />}
        >
          <BreadcrumbItem>
            <div className="flex justify-start items-center gap-2">
              <Link
                to={`/vision/processMonitoring/${material}/${clientId}`}
                style={{ textDecoration: "none" }}
              >
                <img src="/backtick.svg" />
              </Link>
              <p className="text-lg sm:text-2xl font-semibold text-[#024D87]">
                {capitalizeFirstLetter("Sinter Flame Monitoring")}
              </p>
            </div>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <p className="text-lg sm:text-2xl font-semibold text-[#024D87]">
              {capitalizeFirstLetter(param.plantId)}
            </p>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <p className="text-lg sm:text-2xl font-semibold text-[#024D87]">
              {param.cameraId?.replace(/^(\D+)(\d+)/, "$1 $2")}
            </p>
          </BreadcrumbItem>
        </Breadcrumb>
      </div>
      <Tabs index={page} onChange={handleTabChange}>
        <div className="flex justify-between items-center overflow-x-auto h-14 md:h-10">
          <TabList className="!flex !border-0">
            <div className="flex items-center gap-4">
              {tabList?.map((item, idx) => {
                return (
                  <Tab
                    key={idx}
                    className={
                      item?.index === page
                        ? "!text-black !text-xs sm:!text-sm !bg-white rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#79767D]"
                        : "!text-xs sm:!text-sm !text-[#938F96] !border-0"
                    }
                    onClick={() => handleTabChange(item?.index)}
                  >
                    {item?.tabName}
                  </Tab>
                );
              })}
            </div>
          </TabList>
          {
            <div className={`${page === "feed" ? "opacity-100" : "opacity-0"}`}>
              <Timer
                initialSeconds={120}
                callFunc={setCallApi}
                initialRender={initialRender}
                setInitialRender={setInitialRender}
              />
            </div>
          }
        </div>

        <TabPanels>
          {tabList?.map((item, idx) => (
            <TabPanel key={idx} className="!pl-0 !pr-0">
              {item?.element}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default SinterflameSingle;
