import React from "react";
import { Skeleton, Checkbox } from "@chakra-ui/react";

const LibraryGridSkeleton = () => {
  return (
    <div className="flex flex-col gap-3">
      {/* <div className="flex justify-between items-baseline">
        <div className="flex gap-2 items-baseline  w-[30%] md:w-[10%]">
          <Skeleton height={"20px"} width={"100%"} />
        </div>
        <div className="flex flex-col sm:flex-row gap-1 items-center w-[50%] md:w-[20%]">
          <Skeleton height={"35px"} width={"100%"} />
        </div>
      </div> */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 gap-1 h-full">
        {[...Array(18)].map((_, id) => (
          <div key={id} className="relative w-full object-cover text-center">
            <div className="flex gap-2 absolute top-2 right-2">
              <Skeleton height="20px" width="70px" />
              <Skeleton height="20px" width="70px" />
              <Checkbox isChecked={false} isDisabled />
            </div>
            <Skeleton height="150px" rounded={"md"} />
            <div className="flex flex-col items-end gap-2 absolute top-[40px] right-2 opacity-50 hover:opacity-100">
              <Skeleton height="20px" width="70px" />
              <Skeleton height="20px" width="70px" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LibraryGridSkeleton;
