import { useContext, useEffect, useState } from "react";
import CamCard from "../components/CamCard";
import { getStatusDataApi } from "../services/statusServices";
import NavContext from "../../NavContext";
import { useParams } from "react-router-dom";
import { InfoModal } from "../components/InfoModal";
import OverallIndexChart from "../../Charts/SinterBeltCharts/OverallIndexChart";
import { IconButton } from "@chakra-ui/react";
import { EditRoad, MoodBad } from "@mui/icons-material";
import { SequenceSetModal } from "../components/SequenceSetModal";
import LiveViewSkeleton from "../components/LoadingState/LiveViewSkeleton";
import StatusTable from "../components/StatusTable";
export const Status = () => {
  // params, refs, variables
  const param = useParams();
  const { auth } = useContext(NavContext);

  // state
  const [statusData, setStatusData] = useState(null);
  const [openSequenceModal, setOpenSequenceModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstTimeLoaded, setFirstTimeLoaded] = useState(false);

  const getData = async () => {
    try {
      const requestData = JSON.stringify({
        clientId: param.clientId.toLowerCase(),
        plantName: "chanderia",
        useCase: "SINTERBELT",
      });
      const res = await getStatusDataApi(auth, requestData, setLoading);
      setStatusData((prev) => res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setFirstTimeLoaded((prev) => true);
    }
  };
  // useEffect
  useEffect(() => {
    getData();

    const intervalId = setInterval(() => {
      getData();
    }, 5000);
    return () => {
      clearInterval(intervalId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="top-36 right-14 lg:absolute flex flex-row justify-end items-center gap-2 mb-2 lg:mb-0">
        <p className="font-bold text-mf">Pallet board sequencing</p>
        <IconButton
          colorScheme="gray"
          size={"lg"}
          rounded={"100px"}
          color={"#084298"}
          onClick={() => setOpenSequenceModal((prev) => true)}
          icon={<EditRoad />}
        />
      </div>
      {firstTimeLoaded === false && loading ? (
        <LiveViewSkeleton />
      ) : statusData ? (
        <div className="flex lg:flex-row flex-col gap-6 bg-white px-2 md:px-6 py-0 rounded-xl">
          <div className="items-center bg-[#FAFAFA] my-2 md:my-4 rounded-md w-[100%] lg:w-[45%] xl:w-[30%] overflow-x-auto">
            <CamCard data={statusData} />
          </div>
          <div className="items-center gap-4 my-0 mb-6 pt-5 md:pt-0 rounded-md w-[100%] lg:w-[55%] xl:w-[70%]">
            <div className="overflow-hidden">
              <div className="flex flex-col gap-2 mt-[10px] w-full h-full">
                <div className="flex xl:flex-row flex-col justify-between xl:items-center gap-4 xl:gap-10 w-full">
                  <div className="flex flex-col gap-0 py-1 border rounded-md w-[100%] xl:w-[40%]">
                    <p className="px-2 font-normal text-base text-left">
                      {`Total number of V notch present -
                        `}
                      <span className="font-bold">
                        {statusData?.totalVNotchPresentCount}
                      </span>
                    </p>
                    <hr />
                    <p className="px-2 font-normal text-base text-left">
                      {`Total number of grate bar missing - 
                        `}
                      <span className="font-bold">
                        {statusData?.totalGrateBarMissingCount}
                      </span>
                    </p>
                  </div>
                  <div className="flex md:flex-row flex-col md:items-center gap-4 md:gap-4 px-0 xl:px-3 w-[100%] xl:w-[60%]">
                    <div className="flex flex-row items-center gap-1 md:gap-0 md:max-w-[120px]">
                      <span className="font-normal text-base">
                        Overall belt permeability
                      </span>
                      <InfoModal />
                    </div>
                    <OverallIndexChart
                      permeabilityValue={
                        statusData?.overallBeltPermeability?.value
                      }
                      value={statusData?.overallBeltPermeability?.permeability}
                    />
                  </div>
                </div>
                {/* Summary Table */}
                <div>
                  <p className="mb-2 font-bold text-left text-md">
                    Pallet board alerts
                  </p>
                  <div
                    className="border rounded-md w-full"
                    style={{
                      height:
                        108 +
                        Math.min(6, statusData?.summaryTableData?.length || 0) *
                          40 +
                        "px",
                    }}
                  >
                    {statusData?.summaryTableData && (
                      <StatusTable
                        tableData={statusData?.summaryTableData}
                        hidePagination={true}
                      />
                    )}
                  </div>
                </div>
                {/* Overall Table */}
                <div className="mt-5">
                  <div className="flex flex-row justify-between items-center">
                    <p className="mb-2 font-bold text-left text-md">
                      Sinter pallet belt overview
                    </p>
                    <div id="overall-table-filter-panel"></div>
                  </div>
                  <div className="border rounded-md w-full h-[80vh]">
                    {statusData.summaryTableData && (
                      <StatusTable
                        tableData={statusData?.overallTableData}
                        toolBarId={"overall-table-filter-panel"}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-row justify-center items-center border rounded-md w-full h-[60vh]">
          <div className="flex flex-row items-center gap-2 text-gray-400">
            <MoodBad fontSize={"large"} />
            <h2 className="text-2xl">No Data!</h2>
          </div>
        </div>
      )}

      {openSequenceModal && (
        <SequenceSetModal
          openModal={openSequenceModal}
          closeModal={() => setOpenSequenceModal(false)}
          clientId={param.clientId.toLowerCase()}
        />
      )}
    </>
  );
};
