// to get data

import axios from "axios";
import { baseURL } from "../../..";

export const getDetailModalDataApi = async (auth, requestData, setLoading) => {
  try {
    setLoading(true);
    const res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/analysis/detail/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    const data = res?.data;
    // console.log(data);
    if (data) {
      return {
        success: true,
        data,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      error: error,
    };
  } finally {
    setLoading(false);
  }
};

export const postFeedbackApi = async (auth, requestData, setLoading) => {
  try {
    // console.log(auth, requestData);
    setLoading(true);
    const res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/feedback/single/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    console.log(res?.status, res.data.message);
    if (res?.status === 200 && res?.data?.message === "Success") {
      return {
        success: true,
        message: "Feedback Submitted",
      };
    } else {
      return {
        success: false,
        message: "Error",
      };
    }
  } catch (error) {
    console.log(error);
    throw error;
  } finally {
    setLoading(false);
  }
};
