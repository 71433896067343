import { useEffect, useState } from "react";
import {
  Select,
  Table,
  Td,
  Tr,
  Thead,
  Tbody,
  TableContainer,
  Th,
  Flex,
  Image,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import Feedback from "./Feedback";
import Viewdetails from "./Viewdetails";

const UserModals = ({ item, setHistory }) => {
  const [editedComment, setEditedComment] = useState("");

  useEffect(() => {
    setEditedComment("");
  }, [item]);
  return (
    <>
      {" "}
      <Td className=" gap-2  w-[100px]">
        <div className="flex w-full justify-center cursor-pointer gap-2">
          <Feedback
            slab={item.slab_id}
            idx={item._id}
            comment={item?.operators_comment}
            editedComment={editedComment}
            setEditedComment={setEditedComment}
            setHistory={setHistory}
          />
        </div>
      </Td>
      <Td className=" gap-2 justify-between w-[200px]">
        <Viewdetails
          slab={item.slab_id}
          idx={item._id}
          item={item}
          editedComment={editedComment}
        />
      </Td>
    </>
  );
};

export default UserModals;
