import { DataGrid } from "@mui/x-data-grid";
import {
  createTheme,
  IconButton,
  ThemeProvider,
  Button,
  Menu,
  MenuItem,
  CircularProgress,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import NavContext from "../../NavContext";
import { useNavigate, useParams } from "react-router-dom";
import { useToast, ChakraProvider } from "@chakra-ui/react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModelListView from "./ModelListView";
import {
  status,
  model,
  options,
  deleteApi,
  getStatus,
  removeShareApi,
} from "../Components/ProjectCard";
import ShareModal from "../Components/ShareModal";
import InfoDetailModal from "../Components/InfoDetailModal";

const MuiTheme = createTheme();

const OptionMenu = ({ data, toast, auth, fetchData, email }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [downloadModal, setDownloadModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [shareModal, setShareModal] = useState(false);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleMenuClick = (val) => {
    switch (val) {
      case "Delete":
        deleteApi(data, toast, auth, fetchData);
        break;
      case "Download":
        getStatus(data, setModalData, setDownloadModal, auth);
        break;
      case "Share access":
        setShareModal(true);
        break;
      case "Remove share":
        removeShareApi(data, toast, auth, fetchData);
        break;
      default:
        console.log("error");
    }
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <img src="/selfServiceIcons/menu.svg" alt="menu" />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {options
          .filter((item) => {
            if (data.owner != email)
              return ["Download", "Remove share"]?.includes(item.type);
            else if (
              data?.status == "DRAFT" ||
              data?.trainings?.length == 0 ||
              status(data?.trainings[0]?.status) != "live"
            )
              return !["Download", "Remove share"]?.includes(item.type);
            else return item.type != "Remove share";
          })
          .map((item) => {
            return (
              <MenuItem onClick={() => handleMenuClick(item.type)}>
                <img
                  src={item.icon}
                  alt={item.type}
                  height={"15px"}
                  width={"15px"}
                  className="mr-2"
                />
                {item.type}
              </MenuItem>
            );
          })}
      </Menu>
      {shareModal && (
        <ChakraProvider>
          <ShareModal
            data={data}
            closeModal={() => setShareModal(false)}
            openModal={shareModal}
          />
        </ChakraProvider>
      )}
      {modalData && downloadModal && (
        <ChakraProvider>
          <InfoDetailModal
            openModal={downloadModal}
            closeModal={() => setDownloadModal(false)}
            row={modalData}
          />
        </ChakraProvider>
      )}
    </div>
  );
};

const ProjectListView = ({ data, selectedView, fetchData }) => {
  const [rowData, setRowData] = useState([]);
  const { auth, email } = useContext(NavContext);
  const navigate = useNavigate();
  const toast = useToast();
  const columns = [
    {
      field: "id",
      headerName: "SR No.",
    },
    {
      field: "createdAt",
      headerName: "Created on",
      valueFormatter: ({ value }) => {
        return new Date(value)?.toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        });
      },
    },
    {
      field: "owner",
      headerName: "Project owner",
    },
    {
      field: "name",
      headerName: "Project name",
    },
    {
      field: "status",
      headerName: "Project status",
      valueGetter: ({ row }) => {
        if (row?.trainings[0]?.publishStatus == "COMPLETED") return "PUBLISHED";
        else if (row?.status == "DRAFT") return "DRAFT";
        else return row?.trainings[0]?.status;
      },
      renderCell: ({ value }) => {
        return (
          <div className="flex items-center gap-1 w-full">
            <div className="w-8 h-8 rounded-full bg-white p-1 shadow-md">
              {status(value) != "run" ? (
                <img
                  src={`/selfServiceIcons/${status(value)}.svg`}
                  alt="status"
                  className="w-full h-full"
                />
              ) : (
                <CircularProgress
                  sx={{ color: "#69b04b" }}
                  size={25}
                  thickness={5}
                />
              )}
            </div>
            {value}
          </div>
        );
      },
    },
    {
      field: "model",
      headerName: "Model service",
      valueGetter: ({ row }) => {
        return row?.details[0]?.modelService;
      },
      renderCell: ({ value }) => {
        return (
          <div className="flex items-center gap-1 w-full">
            <img src={model(value)} className="w-5 h-5" alt="model" />
            {value}
          </div>
        );
      },
    },
    {
      field: "dataSet",
      headerName: "Data Type",
      valueGetter: ({ row }) => {
        return row?.details[0]?.datasetType;
      },
    },
    {
      field: "view",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <IconButton
            onClick={() => {
              if (row?.status == "ACTIVE")
                navigate(`/Sandbox/View/${row?.projectId}`);
              else if (row?.status == "DRAFT")
                navigate(`/Sandbox/Draft/${row?.projectId}`);
            }}
          >
            <VisibilityIcon sx={{ color: "#3a97e8" }} />
          </IconButton>
        );
      },
    },
    {
      field: "menu",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <OptionMenu
          data={row}
          toast={toast}
          auth={auth}
          fetchData={fetchData}
          email={email}
        />
      ),
    },
  ];

  useEffect(() => {
    setRowData((prev) => {
      let newData = data.filter((item) => {
        if (selectedView == "Owned") return item.owner == email;
        else if (selectedView == "Shared") return item.owner != email;
        else if (selectedView == "Published")
          return item?.trainings[0]?.publishStatus == "COMPLETED";
        else return item;
      });
      return newData;
    });
  }, [data, selectedView]);

  const headerClass =
    "text-sm font-medium text-[#525056] bg-[#ddeeff] capitalize";
  const cellClass = "text-sm font-medium text-[#3E3C42]";
  let flexmap = {
    id: 0,
    createdAt: 2,
    owner: 1,
    name: 2,
    status: 1.5,
    model: 2,
    dataSet: 1,
    view: 0.2,
    menu: 0.2,
  };
  columns.map((val, idx) => {
    val["headerClassName"] = headerClass;
    val["cellClassName"] = cellClass;
    val["flex"] = flexmap[val.field];
    val["headerAlign"] = "center";
    val["align"] = "center";
  });
  //max-w-full sm:max-w-[45vw] lg:max-w-[63vw] xl:max-w-[72vw] min-[1440px]:max-w-[73vw] min-[1750px]:max-w-[81vw] min-[2150px]:max-w-[83vh]
  return (
    <div className="flex flex-col gap-4 col-span-full">
      <div className="overflow-x-auto w-full">
        <ThemeProvider theme={MuiTheme}>
          {rowData.length > 0 && (
            <DataGrid
              rows={rowData}
              columns={columns}
              columnVisibilityModel={{
                id: false,
              }}
              initialState={{
                pagination: { paginationModel: { pageSize: 5 } },
              }}
              getRowId={(row) => row?.projectId}
              pageSizeOptions={[]}
              sx={{
                minWidth: "1000px",
                borderStartStartRadius: "8px",
              }}
            />
          )}
        </ThemeProvider>
      </div>
      <ModelListView data={data} selectedView={selectedView} />
    </div>
  );
};

export default ProjectListView;
