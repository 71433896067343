import { TriangleDownIcon } from "@chakra-ui/icons";
import {
  indexWordMap,
  overallPermeabilityIndex,
} from "../../SinterBelt/SinterBelt";

const IndexChart = ({
  permeabilityValue,
  value,
  overall = false,
  vals = [3, 4, 5, 6, 7],
}) => {
  const colorMap = {
    3: "#E57373", //red
    4: "#E57373", //red
    5: "#FFB74D	", //orange
    6: "#FFE550", //yellow
    7: "#81C784", //light green
    8: "#4CAF50	", //green
  };

  const textColor = {
    3: "#E57373", //red
    4: "#E57373", //red
    5: "#FFB74D	", //orange
    6: "#FFA500", //yellow
    7: "#81C784", //light green
    8: "#4CAF50	", //green
  };

  const coloredDivs = value === 3 ? 3 : value - 1;
  return (
    <div className="flex flex-col justify-center items-end sm:items-center h-full w-full">
      {vals && (
        <div className="flex gap-1 w-full h-[40px] items-end">
          {vals.map((i) => {
            const isColored = i <= coloredDivs;
            const isLastColored = isColored && i === coloredDivs;
            return (
              <div
                key={i}
                className="h-3 w-full rounded-sm relative flex items-center justify-center"
                style={{
                  backgroundColor: isColored ? colorMap[value] : "#F2F2F2",
                }}
              >
                {isLastColored && (
                  <div className="flex flex-col gap-0 absolute top-[-40px] items-center">
                    <p
                      className="px-2 py-1 text-[#FFA500] font-bold text-sm rounded-md z-0 whitespace-nowrap bg-white"
                      style={{
                        boxShadow:
                          "4px 4px 4px 0px rgba(226, 240, 220, 0.51), -4px -4px 18px 0px rgba(226, 240, 220, 0.38)",
                        color: textColor[value],
                      }}
                    >
                      {overall
                        ? overallPermeabilityIndex[value]
                        : indexWordMap[value]}
                    </p>
                    <TriangleDownIcon
                      style={{
                        color: "#CCCCCC",
                        marginTop: "-5px",
                      }}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
      <div className="flex flex-row sm:flex-col md:flex-row items-center gap-1 self-end">
        <p className="text-xs text-[#938F96]">Permeability</p>
        <p className="text-sm text-[#69B04B]">{permeabilityValue + "%"}</p>
      </div>
    </div>
  );
};

export default IndexChart;
