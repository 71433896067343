import RunDetails from "./HomeComponents/RunDetails";
import LabelStats from "./HomeComponents/LabelStats";
import LabelCard from "./HomeComponents/LabelCard";

const MaxLabelFinder = (data) => {
  let label;
  let max = 0;
  Object.keys(data).forEach((item) => {
    let len = data[item]?.img?.length;
    if (len >= max) {
      max = len;
      label = item;
    }
  });
  return label;
};

const Home = ({ userState, modelInfo, predictionData }) => {
  return (
    <div className="flex flex-col gap-6 mt-8">
      <div className="flex flex-col xl:flex-row gap-4 items-start xl:items-center">
        <RunDetails modelInfo={modelInfo} />
        <LabelStats predictionData={predictionData} userData={userState} />
      </div>
      <div className="flex flex-col gap-4">
        <p className="text-[#605D64] text-base font-medium">Label cards</p>
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5 min-[2200px]:grid-cols-6 p-1 h-fit w-full max-h-[50vh] overflow-y-auto gap-4">
          {predictionData &&
            Object.keys(predictionData).length > 0 &&
            Object.keys(predictionData).map((item) => {
              let label = MaxLabelFinder(predictionData);
              return (
                <LabelCard
                  predictionData={predictionData}
                  label={item}
                  userData={userState}
                  starred={label == item}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Home;
