// api's for alerts page

import axios from "axios";
import { baseURL } from "../../..";
import { indexWordMap } from "../SinterBelt";
// import { dummyAlertsCountData } from "./data";

export const getAlertsDataApi = async (auth, requestData, setLoading) => {
  try {
    setLoading((prev) => true);
    let res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/alerts/overview/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    const data = res?.data;
    // console.log(data);
    if (data) {
      const { columns, table_data, alertGridData } = data;
      const updatedDataWithIndex = table_data?.map((element, index) => {
        const [value, gap] =
          element?.value_gap && typeof element?.value_gap === "string"
            ? element?.value_gap?.split("_")?.map(Number)
            : [null, null];
        return {
          ...element,
          permeability_index: element?.permeability_index
            ? indexWordMap[element?.permeability_index]
            : null,
          value_gap: gap ? `${gap}px` : `${value}%`,
        };
      });
      return {
        success: true,
        data: { columns, table_data: updatedDataWithIndex, alertGridData },
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      error: error,
      data: null,
    };
  } finally {
    setLoading((prev) => false);
  }
};

export const getAlertParamsValueApi = async (auth, requestData, setLoading) => {
  try {
    setLoading(true);
    const res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/alerts/params/get/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    const data = res.data;
    if (data) {
      return {
        success: true,
        data: data.alertIndexValue,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      error: error,
    };
  } finally {
    setLoading(false);
  }
};

export const updateAlertParamsApi = async (auth, requestData) => {
  try {
    const res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/alerts/params/set/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    console.log(res);
    if (res?.status === 200 && res?.data?.message === "Success") {
      return {
        success: true,
        message: "Alert Limit Updated",
      };
    } else {
      return {
        success: false,
        message: res?.data?.message || "Error while Updating",
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: "Error while Updating",
    };
  }
};
