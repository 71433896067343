import React, { useState } from "react";
import { Button, IconButton, Input, useToast } from "@chakra-ui/react";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useParams } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";

const MuiTheme = createTheme();

const SubscriptionHistoryTable = ({ activeSubs, organisation }) => {
  const { clientId, mode } = useParams();
  const toast = useToast();
  const role = "clientAdmin";
  const columns = [
    {
      field: "imageUrl",
      headerName: "",
      renderCell: ({ row }) => (
        <div style={{ display: "", alignItems: "" }}>
          <img
            src={
              row?.imageUrl
                ? row?.imageUrl
                : "/superadmin/clients/companyLogo.png"
            }
            alt=""
            style={{
              width: 24,
              height: 20,
              borderRadius: "50%",
              // marginRight: 8,
            }}
          />
        </div>
      ),
    },
    {
      field: "clientName",
      headerName: "client",
      // renderCell: ({ row }) => (
      //   <div style={{ display: "", alignItems: "" }}>
      //     {/* <img
      //       src={row?.imageUrl} // Replace with the actual import or URL for your client logo
      //       alt=""
      //       style={{
      //         width: 24,
      //         height: 20,
      //         borderRadius: "50%",
      //         marginRight: 8,
      //       }}
      //     /> */}
      //     {row.clientName}
      //   </div>
      // ),
    },

    { field: "product", headerName: "product" },
    {
      field: "productGroup",
      headerName: "group",
    },
    {
      field: "productCategory",
      headerName: "parent",
    },
    {
      field: "location",
      headerName: "location",
    },
    {
      field: "status",
      headerName: "status",
      renderCell: ({ row }) => {
        if (row.status === "INACTIVE") {
          return (
            <span className="text-[#938F96] text-sm font-semibold">
              Inactive
            </span>
          );
        } else if (row.status === "ACTIVE") {
          return (
            <span className="text-[#7AC958] text-sm font-semibold">Active</span>
          );
        } else if (row.status === "MODIFIED") {
          return (
            <span className="text-[#FFC107] text-sm font-semibold">
              Modified
            </span>
          );
        } else {
          // Handle other status values if needed
          return (
            <span className="text-[your-default-color] text-sm font-semibold">
              {row.status}
            </span>
          );
        }
      },
    },
    {
      field: "createdAt",
      headerName: "sys start date",
      type: 'date',
      valueGetter: ({value}) => new Date(value),
      valueFormatter: (params) => {
        return params.value
          ? new Date(params.value).toDateString().split(" ")[2] +
              " " +
              new Date(params.value).toDateString().split(" ")[1] +
              " '" +
              new Date(params.value).toDateString().split(" ")[3].slice(2, 4)
          : "";
      },
    },
    {
      field: "updatedAt",
      headerName: "update time",
      type:"dateTime",
      valueGetter:({value})=> new Date(value),
      valueFormatter: ({value}) =>{
        return value
          ? new Date(value).toDateString().split(" ")[2] +
            " " +
            new Date(value).toDateString().split(" ")[1] +
            " '" +
            new Date(value)
              .toDateString()
              .split(" ")[3]
              .slice(2, 4) +
            " " +
            new Date(value).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            })
          : ""},
    },
    {
      field: "remarks",
      headerName: "remarks",
    },
  ];

  const headerClass =
    "text-sm font-normal text-[#79767D] bg-[#DDEEFF] capitalize";
  const cellClass = "text-sm font-normal text-[#3E3C42] whitespace-nowrap";
  const flexMap = [0.3, 1.5, 1.5, 1.5, 1, 1, 1, 1, 1.5, 3];
  columns.map((val, idx) => {
    val["headerClassName"] = headerClass;
    val["cellClassName"] = cellClass;
    val["flex"] = flexMap[idx];
  });

  const handleStarClick = async (queryId, event) => {
    // Prevent the click from triggering when clicking on a button inside the row
    if (event.target.tagName.toLowerCase() === "button") {
      return;
    }
  };
  // console.log("aa", activeSubs);
  return (
    <div className="overflow-x-auto mt-4">
      <ThemeProvider theme={MuiTheme}>
        <DataGrid
          // rows={extendedRowData}
          rows={activeSubs}
          columns={columns}
          getRowId={(row) => row.subscriptionId}
          columnVisibilityModel={{
            clientName: organisation === "Ripik.Ai" ? true : false,
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "updatedAt", sort: "desc" }],
            },
            pagination: { paginationModel: { pageSize: 6 } },
          }}
          slotProps={{
            // column menu
            columnMenu: {
              sx: {
                minWidth: 170,
                padding: 0,
                ".MuiTypography-root": {
                  fontSize: "13px",
                },
                "& .css-1asgcec-MuiButtonBase-root-MuiMenuItem-root": {
                  paddingY: 0,
                  minHeight: "35px",
                },
              },
            },
            // the panel which contains filtering menu (top wrapper)
            panel: {
              sx: {
                maxWidth: "85vw",
                ".css-154sxbz-MuiPaper-root-MuiDataGrid-paper": {
                  minWidth: 0,
                },
              },
            },
            // actual filter panel in which all elements are stored
            filterPanel: {
              filterFormProps: {
                // input box for operator selection(for multi-columns => and, or)
                logicOperatorInputProps: {
                  sx: { display: "none" },
                },
                // input box for column selection
                columnInputProps: {
                  variant: "outlined",
                  size: "small",
                },
                // input box for operator selection(for a particular columns => contains, start with, is empty ....)
                operatorInputProps: {
                  sx: { display: "none" },
                },
                // input box to write the value on the basis of which data get's filtered
                valueInputProps: {
                  InputComponentProps: {
                    variant: "outlined",
                    size: "small",
                  },
                },
                // delete icon
                deleteIconProps: {
                  sx: {
                    "& .MuiSvgIcon-root": { color: "#D32F2F" },
                  },
                },
              },
              sx: {
                overflowX: "auto",
                "& .MuiDataGrid-filterForm": { p: 2 },
                "& .MuiDataGrid-filterFormLogicOperatorInput": {
                  mr: 2,
                  minWidth: 80,
                },
                "& .MuiDataGrid-filterFormColumnInput": {
                  mr: 2,
                  minWidth: 150,
                },
                "& .MuiDataGrid-filterFormValueInput": { minWidth: 200 },
              },
            },
          }}
          sx={{ minWidth: "1200px" }}
        />
      </ThemeProvider>
    </div>
  );
};

export default SubscriptionHistoryTable;
