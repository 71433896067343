import {
  Select,
  Table,
  Td,
  Tr,
  Thead,
  Tbody,
  TableContainer,
  Th,
  Flex,
  Image,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
//   import ReactSearchBox from "react-search-box";
import { SmallCloseIcon } from "@chakra-ui/icons";
import { useWindowSize } from "@uidotdev/usehooks";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import Paginator from "../../../util/VisionUtils/Paginator";
import { ThemeProvider, createTheme } from "@mui/material";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import AlertsTable from "../components/AlertsTable";
import { slabSizingJsplBaseURl } from "../SlabSizing";
const MuiTheme = createTheme();

const Alert = () => {
  const size = useWindowSize();
  const [alerts, setAlerts] = useState({
    data: [],
  });
  const [filetAlers, setFiletAlerts] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [alertsChanging, setAlertsChanging] = useState(false);
  //   const [selectedValue, setSelectedValue] = useState(null);

  const alertsData = alerts.data;
  const searchData = alertsData.map((item) => {
    if (item?.slab_id && item?.slab_id?.toString()) {
      return { label: item.slab_id.toString() };
    } else {
      return { label: "" };
    }
  });
  const uniqueSearchData = Array.from(
    new Set(searchData.map((item) => item.label))
  ).map((label) => {
    return {
      label,
    };
  });

  uniqueSearchData.sort((a, b) => {
    const labelA = a.label.toUpperCase(); // Convert labels to uppercase for case-insensitive comparison
    const labelB = b.label.toUpperCase();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });

  const [fromTime, setFromTime] = useState(
    new Date(new Date().getTime() - 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10)
  );
  const [toTime, setToTime] = useState(
    new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10)
  );

  const callAlertsTableData = async () => {
    try {
      const response = await axios.get(
        `${slabSizingJsplBaseURl}alerts/?start_date=${fromTime}&end_date=${toTime}`
      );

      let data = response.data;
      setAlerts((prev) => ({
        ...prev,
        data: data.reverse(),
      }));
      setAlertsChanging(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setAlertsChanging(false);
    }
  };

  const handleClick = () => {
    setAlertsChanging(true);
    callAlertsTableData();
    //   apiCall();
  };

  useEffect(() => {
    handleClick();
  }, []);

  //   search bar

  const editDates = (TimeStamp) => {
    const timestamp = TimeStamp * 1000; // Multiply by 1000 to convert from seconds to milliseconds
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };

  const handleAutocompleteChange = (event, selectedValue) => {
    if (selectedValue && selectedValue.label) {
      const filteredAlerts = alerts.data.filter(
        (alert) => alert.slab_id == selectedValue.label
      );
      setFiletAlerts(filteredAlerts);
    } else {
      setFiletAlerts([]);
    }
  };
  console.log("alerts", alerts);
  return (
    <div className="relative flex flex-col p-1">
      <div className={`absolute left-0 right-0 flex justify-center z-50 `}>
        <div className="p-5 pl-6 pr-6 gap-2 flex flex-col md:flex-row items-center bg-white rounded-xl shadow-md">
          <div>
            <FloatingInput
              text="From"
              type="date"
              setDateTime={setFromTime}
              value={fromTime}
              max={toTime}
            />
          </div>
          <div>
            <FloatingInput
              text="To"
              type="date"
              setDateTime={setToTime}
              value={toTime}
              max={new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
                .toISOString()
                .slice(0, 10)}
              min={fromTime}
            />
          </div>

          {alertsChanging ? (
            <Spinner />
          ) : (
            <PrimaryButton
              text={"Apply"}
              width={"fit-content"}
              onClick={handleClick}
              height={"40px"}
            />
          )}
        </div>
      </div>
      {alertsChanging ? (
        <div className="w-full h-full  mt-[200px] flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col gap-4 mt-[160px] md:mt-8 pt-[57px] bg-white rounded-xl justify-start  ">
          {alerts.hasOwnProperty("data") && (
            <div className="w-full flex justify-between px-4">
              <ThemeProvider theme={MuiTheme}>
                <Autocomplete
                  disablePortal
                  //   value={selectedValue}
                  id="combo-box-demo"
                  options={uniqueSearchData}
                  onChange={handleAutocompleteChange}
                  sx={{
                    width: "200px",
                    "& .MuiInputBase-root": {
                      height: "40px", // Adjust the height here
                    },
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Slab ID"
                      sx={{
                        width: "100%",
                        height: "100%",

                        "& .MuiInputLabel-root": {
                          transform: "translate(14px, 10px) scale(1)", // Adjust the label position
                          fontSize: "14px", // Adjust the label font size
                        },
                        "& .MuiInputLabel-shrink": {
                          transform: "translate(14px, -6px) scale(0.75)", // Adjust the shrunk label position
                        },
                      }}
                    />
                  )}
                />
              </ThemeProvider>

              <Paginator
                data={filetAlers.length != 0 ? filetAlers : alerts.data}
                limit={5}
                setDisplayData={setDisplayData}
              />
            </div>
          )}

          {filetAlers.length != 0 ? (
            <AlertsTable tableData={displayData} />
          ) : (
            alerts.data.length != 0 && <AlertsTable tableData={displayData} />
          )}
        </div>
      )}
    </div>
  );
};

export default Alert;
