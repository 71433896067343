function SubDailyOutput({ show, state }) {
  let showing = {};

  if (show == 0) {
    showing = {
      head: "Last Hour",
      pass: state.lastHr.pass,
      fail: state.lastHr.fail,
    };
  } else if (show == 1) {
    showing = { head: "Shift", pass: state.shift.pass, fail: state.shift.fail };
  } else if (show == 2) {
    showing = { head: "Today", pass: state.today.pass, fail: state.today.fail };
  } else if (show == 3) {
    showing = {
      head: "Yesterday",
      pass: state.yesterday.pass,
      fail: state.yesterday.fail,
    };
  }

  return (
    <div className="w-full  h-full bg-[#FAFAFA] rounded-lg">
      <div className="bg-[#DDEEFF] text-sm font-semibold  px-1 py-2">
        {showing.head}
      </div>
      <div className="flex gap-2 justify-between p-2">
        <div className="text-[#79767D] text-sm">Passed </div>
        <div className="text-[#69B24A] text-sm">{showing.pass}</div>
      </div>
      <div className="flex gap-2 justify-between p-2">
        <div className="text-[#79767D] text-sm">Rejected</div>
        <div className="text-[#DC362E] text-sm">{showing.fail}</div>
      </div>
    </div>
  );
}

export default SubDailyOutput;
