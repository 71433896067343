import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import Camerafeed from "../components/Camerafeed";
import OverallOutput from "../components/OverallOutput";
import OverallDimensions from "../components/OverallDimensions";
import DynamicTable from "../components/DynamicTable";
import NavContext from "../../NavContext";
import { slabSizingJsplBaseURl } from "../SlabSizing";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import { useToast } from "@chakra-ui/react";
import LiveFeed from "../components/LiveFeed";
import AnnotateImage from "../components/AnnotateImage";

const Capitalize = (str) => {
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
};

const Feed = () => {
  const [currentDateTime, setCurrentDateTime] = useState("");
  const [editingSlabId, setEditingSLabId] = useState(false);
  const size = useWindowSize();
  const toast = useToast();
  const { auth } = useContext(NavContext);
  const [cameraAngles, setCameraAngles] = useState({
    TopView: "",
    RightView: "",
    LeftView: "",
    AnnotatedTopView: "",
  });
  const [liveFeed, setLiveFeed] = useState({
    TopView: "",
    RightView: "",
    LeftView: "",
  });
  const [dailyOutput, setDailyOutput] = useState({
    lastHr: {
      pass: null,
      fail: null,
    },
    shift: {
      pass: null,
      fail: null,
    },
    today: {
      pass: null,
      fail: null,
    },
    yesterday: {
      pass: null,
      fail: null,
    },
  });
  const [overallDimentions, setOverallDimentions] = useState({
    slab_id: "...",
    uniqueID: null,
    slab_found: null,
    dimentions: {
      actual: {
        length: null,
        breadth: null,
        depth: null,
      },
    },
    standard_length: null,
    standard_breadth: null,
    standard_depth: null,
    diagonal_dimentions: {
      leftSide: null,
      rightSide: null,
    },
    edge_contours: { 1: 1, 2: 1, 3: 1, 4: 1 },
    surfaceArea: null,
    top_mask: [],
    _id: null,
    edited: false,
  });

  const [stateForStandard, setStateForStandard] = useState({
    standard: {
      length: null,
      breadth: null,
      depth: null,
    },
  });

  const [stateForBarChart, setStateForBarChart] = useState(null);

  const [stateForSequenceTable, setStateForSequenceTable] = useState(null);

  const inputRef = useRef();
  const [editedText, setEditedText] = useState();
  const [editCell, setEditCell] = useState(false);

  const fetchStandardData = async () => {
    try {
      const response = await axios.get(`${slabSizingJsplBaseURl}standard`);

      let data = response.data;
      setStateForStandard((prev) => ({
        ...prev,
        standard: {
          length: data?.length,
          breadth: data?.breadth,
          depth: data?.depth,
        },
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchSLabDetails = async () => {
    try {
      const response = await axios.get(`${slabSizingJsplBaseURl}dimensions/`);

      let data = response.data[0];

      setCameraAngles((prev) => ({
        ...prev,
        TopView: data?.top_view || "",
        RightView: data?.right_side_view || "",
        LeftView: data?.left_side_view || "",
        AnnotatedTopView: data?.top_annotated_view || "",
      }));
      setOverallDimentions((prev) => ({
        ...prev,
        uniqueID: data?._id ? data._id : null,
        slab_id: data?.slab_id ? data.slab_id : "...",
        slab_found: data?.slab_found ? data?.slab_found : null,
        dimentions: {
          actual: {
            length: data?.length ? data?.length.toFixed(0) : null,
            breadth: data?.breadth ? data?.breadth.toFixed(0) : null,
            depth: data?.depth ? data?.depth.toFixed(0) : null,
          },
        },
        standard_length: data?.standard_length
          ? data.standard_length.toFixed(0)
          : null,
        standard_breadth: data?.standard_breadth
          ? data.standard_breadth.toFixed(0)
          : null,
        standard_depth: data?.standard_depth
          ? data.standard_depth.toFixed(0)
          : null,
        diagonal_dimentions: {
          leftSide: data?.left_diagonal ? data?.left_diagonal.toFixed(0) : null,
          rightSide: data?.right_diagonal
            ? data?.right_diagonal.toFixed(0)
            : null,
        },
        edge_contours: data?.edge_contours
          ? data?.edge_contours
          : { 1: 1, 2: 1, 3: 1, 4: 1 },

        surfaceArea: data?.area ? data?.area.toFixed(0) : null,
        top_mask: data?.top_mask ? data?.top_mask : [],
        _id: data?._id ? data._id : null,
        edited: data?.edited ? data.edited : false,
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchOverallOutput = async () => {
    try {
      const response = await axios.get(`${slabSizingJsplBaseURl}daily_output/`);

      let data = response.data;

      setDailyOutput((prev) => ({
        ...prev,
        lastHr: {
          pass: data?.last_hour_count_accept,
          fail: data?.last_hour_count_reject,
        },
        shift: {
          pass: data?.current_shift_count_accept,
          fail: data?.current_shift_count_reject,
        },
        today: {
          pass: data?.today_count_accept,
          fail: data?.today_count_reject,
        },
        yesterday: {
          pass: data?.yesterdays_count_accept,
          fail: data?.yesterdays_count_reject,
        },
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const WeeklyOutput = async () => {
    try {
      const currentDate = new Date();
      const endDate = currentDate.toISOString().split("T")[0];

      // Calculate the end date (seven days ago)
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(currentDate.getDate() - 7);
      const startDate = sevenDaysAgo.toISOString().split("T")[0];

      const response = await axios.get(
        `${slabSizingJsplBaseURl}weekly_output/?start_date=${startDate}&end_date=${endDate}`
      );

      let data = response.data;
      setStateForBarChart(data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const sequenceTable = async () => {
    try {
      const response = await axios.get(`${slabSizingJsplBaseURl}sequence/`);

      let data = response.data;
      setStateForSequenceTable(data.reverse());
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchLiveFeed = async () => {
    try {
      const response = await axios.get(`${slabSizingJsplBaseURl}get_live_feed`);

      let data = response.data;

      setLiveFeed((prev) => ({
        ...prev,
        TopView: data?.top_view || "",
        RightView: data?.right_side_view || "",
        LeftView: data?.left_side_view || "",
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchStandardData();

    fetchOverallOutput();
    WeeklyOutput();
    const intervalId = setInterval(() => {
      const now = new Date();
      const date = formatDate(now);
      const time = formatTime(now);
      setCurrentDateTime(`${date}  ${time}`);

      fetchStandardData();

      fetchOverallOutput();
      WeeklyOutput();
    }, 15000);

    // Clear the interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (editingSlabId === false) {
      sequenceTable();
      fetchSLabDetails();
      const intervalId = setInterval(() => {
        sequenceTable();
        fetchSLabDetails();
      }, 15000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [editingSlabId]);

  useEffect(() => {
    fetchLiveFeed();
    const intervalId = setInterval(() => {
      fetchLiveFeed();
    }, 10000);

    // Clear the interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  const formatDate = (date) => {
    const year = date.getFullYear().toString().slice(-2);
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day}/${month}/${year}`;
  };

  const formatTime = (date) => {
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    return `${hours}:${minutes}:${seconds}`;
  };

  const cam = [0, 1, 2];

  const handleChangeTruckName = async () => {
    let data = JSON.stringify({
      _id: overallDimentions.uniqueID,
      slab_id: inputRef.current.value,
    });

    if (inputRef.current.value == "") {
      toast({
        title: `You can't set empty slab id`,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      setEditCell(false);
      return;
    }

    if (inputRef.current.value.length >= 26) {
      toast({
        title: `Slab ID can't be more than 25 characters`,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      setEditCell(false);
      return;
    }

    try {
      const response = await axios.post(
        slabSizingJsplBaseURl + "update/slab_id/",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data == "slab updated") {
        toast({
          title: `Slab ID Updated`,
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        // setEditedText(inputRef.current.value);
        inputRef.current = "";
      }
    } catch (error) {
      console.log("error", error);
      toast({
        title: error?.response?.data
          ? error?.response?.data
          : "Something went wrong",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      setEditCell(false);
      return;
    }

    setEditCell(false);
  };

  return (
    <div className="flex flex-col w-full gap-3  h-full shadow-md px-4 py-4 md:pb-5 pl-5 border rounded-md text-[15px]  bg-white">
      {/* live feed */}
      <div
        className={`grid w-full  ${
          size.width >= 768
            ? "grid-cols-3 gap-4"
            : size.width < 425
            ? "grid-cols-1"
            : "grid-cols-2 gap-3"
        }`}
      >
        {cam.map((e) => {
          return <LiveFeed show={e} cameraAngles={liveFeed} />;
        })}
      </div>
      {/* Top of home */}
      <div className="w-full flex flex-col gap-4">
        <div className="text-start flex gap-3   w-full ">
          <div className="flex text-base md:text-[16px] font-medium w-fit gap-2">
            <p className="flex items-center min-w-[143px] text-base">
              Last rolled slab ID-
            </p>
            {editCell ? (
              <div className="flex w-[180px] items-center  gap-2 border px-2 h-[40px] rounded-sm  border-slate-300">
                <input
                  type="text"
                  className="  w-full h-8 text-[#605D64] px-2 "
                  defaultValue={overallDimentions.slab_id}
                  ref={inputRef}
                />

                <SaveIcon
                  onClick={() => {
                    handleChangeTruckName(overallDimentions.slab_id);
                    setEditingSLabId(false);
                  }}
                  sx={{
                    cursor: "pointer",
                    fontSize: "18px",
                    color: "#1565C0",
                  }}
                />
              </div>
            ) : (
              <div
                className={` flex justify-between w-[180px] items-center h-[40px]  px-2 gap-4 cursor-pointer rounded-md ${
                  overallDimentions.slab_found !== undefined
                    ? overallDimentions?.slab_found
                      ? "  border-[1px]  "
                      : "border-[#EF9A9A] border-2"
                    : ""
                }`}
                onClick={() => {
                  setEditCell(true);
                  setEditingSLabId(true);
                }}
              >
                <p className={`  w-full flex px-3`}>
                  {editedText ? editedText : overallDimentions.slab_id}
                </p>

                <EditIcon
                  sx={{
                    cursor: "pointer",
                    fontSize: "16px",
                    color: "#1565C0",
                  }}
                />
              </div>
            )}
          </div>
          {cameraAngles?.TopView != "" && (
            <div className="w-[80px] ">
              <AnnotateImage
                setEditingSLabId={setEditingSLabId}
                fetchSLabDetails={fetchSLabDetails}
                imgUrl={cameraAngles?.TopView}
                masking={overallDimentions?.top_mask}
                imageName={
                  overallDimentions?.slab_id
                    ? overallDimentions?.slab_id
                    : "Image to annotate"
                }
                uniqueID={overallDimentions._id}
                alreadyEditedByUser={overallDimentions.edited}
              />
            </div>
          )}
        </div>

        {/* cam fed */}
        <div
          className={`grid w-full  ${
            size.width >= 768
              ? "grid-cols-3 gap-4"
              : size.width < 425
              ? "grid-cols-1"
              : "grid-cols-2 gap-3"
          }`}
        >
          {[0, 1, 2].map((e) => {
            return (
              <Camerafeed
                show={e}
                cameraAngles={cameraAngles}
                masking={overallDimentions?.top_mask}
                alreadyEditedByUser={overallDimentions.edited}
              />
            );
          })}
        </div>
      </div>

      {/* overall dime */}
      <OverallDimensions
        overallDimentions={overallDimentions}
        stateForStandard={stateForStandard}
      />

      {/* overall output */}
      <OverallOutput
        dailyOutput={dailyOutput}
        stateForBarChart={stateForBarChart}
      />

      <div className="w-full">
        <DynamicTable
          tableData={stateForSequenceTable}
          stateForStandard={stateForStandard}
          setEditingSLabId={setEditingSLabId}
        />
      </div>
    </div>
  );
};

export default Feed;
