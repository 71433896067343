import { TriangleDownIcon } from "@chakra-ui/icons";
import { overallPermeabilityIndex } from "../../SinterBelt/SinterBelt";

const OverallIndexChart = ({ permeabilityValue, value }) => {
  const vals = [1, 2, 3, 4, 5];
  const colorMap = {
    1: "#E57373", //red
    2: "#FFE550	", //orange
    3: "#FFE550", //yellow
    4: "#81C784", //light green
    5: "#4CAF50	", //green
  };

  const textColor = {
    1: "#EF5350", //red
    2: "#FFA500	", //orange
    3: "#FFA500", //yellow
    4: "#81C784", //light green
    5: "#4CAF50	", //green
  };
  return (
    <div className="flex flex-col justify-center items-end sm:items-center h-full w-full">
      <div className="flex gap-1 w-full h-[40px] items-end">
        {vals.map((i) => {
          return (
            <div
              key={`overall-index-chart-${i}`}
              className="h-3 w-full rounded-sm relative flex items-center justify-center"
              style={{
                backgroundColor: i <= value ? colorMap[value] : "#F2F2F2",
                // border: `2px solid ${color[type]}`,
              }}
            >
              {i == value && (
                <div className="flex flex-col gap-0 absolute top-[-40px] items-center">
                  <p
                    className="px-2 py-1 text-[#FFA500] font-bold text-sm rounded-md z-0 whitespace-nowrap bg-white"
                    style={{
                      boxShadow:
                        "4px 4px 4px 0px rgba(226, 240, 220, 0.51), -4px -4px 18px 0px rgba(226, 240, 220, 0.38)",
                      color: textColor[value],
                    }}
                  >
                    {overallPermeabilityIndex[value]}
                  </p>
                  <TriangleDownIcon
                    style={{
                      color: "#CCCCCC",
                      marginTop: "-5px",
                    }}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
      <div className="flex flex-row sm:flex-col md:flex-row items-center gap-1 self-end mt-2">
        <p className="text-xs text-[#938F96]">Permeability</p>
        <p className="text-sm text-[#69B04B]">{`${permeabilityValue?.toFixed(
          2
        )}%`}</p>
      </div>
    </div>
  );
};
export default OverallIndexChart;
