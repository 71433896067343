
// base url  which will connect only with forticlient(vpn)
// export const BASE_URL_FOR_BF = 'http://10.36.0.105:8000/api'; 


// base url for trial;
export const BASE_URL_FOR_BF = 'https://15.206.88.112.nip.io:443/api'; 







// // for jindal with forticlient(vpn)
export const clientIdbf="jindalsteel";







