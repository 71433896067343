import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import { useState, useEffect, useContext } from "react";
import { baseURL } from "../../../index";
import { useParams } from "react-router-dom";
import axios from "axios";
import ExlCsvDownload from "../../../util/VisionUtils/ExlCsvDownload";
import NavContext from "../../NavContext";
import { indexWordMap } from "../Sinterflame";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ShiftSelectModal from "../components/ShiftSelectModal";
import {
  Table,
  Td,
  Tr,
  Thead,
  Tbody,
  TableContainer,
  Th,
  Select,
  Spinner,
  IconButton,
} from "@chakra-ui/react";
import DataGridTable from "../components/DataGridTable";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { capitalizeFirstLetter } from "../../../util/sentenceCase";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";

export const getComment = (val) => {
  return indexWordMap[Math.round(val)];
};

const Report = ({ plantId, cameraId, disable, plantCamMap }) => {
  const param = useParams();
  const { auth } = useContext(NavContext);
  const [report, setReport] = useState([]);
  const [shiftSelectOpen, setShiftSelectOpen] = useState(false);
  const [selectedBasis, setSelectedBasis] = useState(0);
  const [reportChanging, setReportChanging] = useState(false);
  const [fromTime, setFromTime] = useState(
    new Date(new Date().getTime() - 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16)
  );
  const [toTime, setToTime] = useState(
    new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16)
  );
  const [selectedPlant, setSelectedPlant] = useState(
    disable ? plantId : "All Plants"
  );
  const [selectedCam, setSelectedCam] = useState(
    disable ? cameraId : "All Burners"
  );

  const apiCall = async () => {
    const requestData = JSON.stringify({
      clientId: param.clientId.toLowerCase(),
      useCase: "SINTERFLAME",
      startDate: new Date(fromTime).getTime() + 5.5 * 60 * 60 * 1000,
      endDate: new Date(toTime).getTime() + 5.5 * 60 * 60 * 1000,
      cameraId:
        selectedCam === "All Burners" || selectedPlant === "All Plants"
          ? "all"
          : selectedCam,
      plantName: selectedPlant === "All Plants" ? "all" : selectedPlant,
      basis: selectedBasis,
    });
    const response = await axios
      .post(
        baseURL + "vision/v2/processMonitoring/report/overview/",
        requestData,
        {
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      )
      .then((response) => {
        setReport(response.data);
        setReportChanging(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClick = () => {
    setReportChanging(true);
    apiCall();
  };

  useEffect(() => {
    handleClick();
  }, []);

  return (
    <div className="relative flex flex-col">
      <IconButton
        position={"absolute"}
        top={"-20px"}
        right={0}
        colorScheme="gray"
        size={"lg"}
        rounded={"100px"}
        color={"#084298"}
        zIndex={100}
        onClick={() => setShiftSelectOpen(true)}
        icon={<GroupAddIcon />}
      />
      <div className="absolute left-0 right-0 flex justify-center">
        <div className="p-5 pl-6 pr-6 gap-2 flex flex-col md:flex-row items-center bg-white rounded-xl shadow-md">
          <div>
            <FloatingInput
              text="From"
              type="datetime-local"
              setDateTime={setFromTime}
              value={fromTime}
              max={toTime}
            />
          </div>
          <div>
            <FloatingInput
              text="To"
              type="datetime-local"
              setDateTime={setToTime}
              value={toTime}
              min={fromTime}
              max={new Date().toISOString().slice(0, 16)}
            />
          </div>
          {/* <button
            className="text-center p-[10px] pl-4 pr-4 text-white text-xs md:text-base font-medium bg-[#084298] rounded-full"
            onClick={handleClick}
          >
            {reportChanging ? <Spinner /> : "Show Report"}
          </button> */}
          <PrimaryButton
            width={"130px"}
            height={"40px"}
            text={reportChanging ? <Spinner /> : "Show report"}
            onClick={handleClick}
            disable={!fromTime || !toTime}
          />
        </div>
      </div>
      <div className="flex flex-col gap-4 mt-[160px] md:mt-11 pt-[57px] bg-white rounded-xl justify-start">
        <div className="flex justify-between gap-2 pl-4 pr-4 overflow-x-auto">
          <div className="flex gap-2">
            <div className="min-w-[110px]">
              <Select
                borderColor="#CAC5CD"
                color="#605D64"
                placeholder={disable && capitalizeFirstLetter(plantId)}
                variant="outline"
                isDisabled={disable}
                className=" !text-sm !font-medium text-[#605D64]"
                onChange={(e) => setSelectedPlant(e.target.value)}
                value={selectedPlant}
              >
                <option key="All Plants" value="All Plants">
                  {capitalizeFirstLetter("All plants")}
                </option>
                {!disable &&
                  Object.keys(plantCamMap).map((plant) => {
                    return (
                      <option key={plant} value={plant}>
                        {plant}
                      </option>
                    );
                  })}
              </Select>
            </div>
            <div className="min-w-[110px]">
              <Select
                borderColor="#CAC5CD"
                color="#605D64"
                placeholder={disable && capitalizeFirstLetter(cameraId)}
                variant="outline"
                isDisabled={disable || selectedPlant == "All Plants"}
                className=" !text-sm !font-medium text-[#605D64]"
                onChange={(e) => setSelectedCam(e.target.value)}
                value={selectedCam}
              >
                {" "}
                <option key="All Burners" value="All Burners">
                  {capitalizeFirstLetter("All burners")}
                </option>
                {!disable &&
                  selectedPlant != "All Plants" &&
                  plantCamMap[selectedPlant].map((cam) => {
                    return (
                      <option key={cam} value={cam}>
                        {cam}
                      </option>
                    );
                  })}
              </Select>
            </div>
            <div className="min-w-[110px]">
              <Select
                borderColor="#CAC5CD"
                color="#605D64"
                variant="outline"
                className=" !text-sm !font-medium !text-[#605D64]"
                value={selectedBasis}
                onChange={(e) => setSelectedBasis(e.target.value)}
              >
                <option value={0}>Daily basis</option>
                <option value={1}>Shift basis</option>
                <option value={2}>Hourly basis</option>
              </Select>
            </div>
            {/* <button
              className="text-center py-2 px-4 text-white text-xs md:text-base font-medium bg-[#6CA6FC] rounded-full min-w-[80px]"
              onClick={handleClick}
            >
              {reportChanging ? <Spinner /> : "Apply"}
            </button> */}
            <SecondaryButton
              width={"80px"}
              height={"40px"}
              text={reportChanging ? <Spinner /> : "Apply"}
              onClick={handleClick}
            />
          </div>
          {report.hasOwnProperty("order") && (
            <ExlCsvDownload order={report.order} data={report.data} />
          )}
        </div>
        {report.hasOwnProperty("data") && report.hasOwnProperty("order") && (
          <DataGridTable
            rowData={report.data}
            order={report.order}
            showHealthIndex={true}
          />
        )}
        {/* {report.hasOwnProperty("data") && (
          <div className="flex flex-col gap-0 w-full">
            <TableContainer className="!max-h-[80vh] !overflow-y-auto">
              <Table variant="simple">
                <Thead className="bg-[#FAFAFA] !text-xs !sticky !top-0">
                  <Tr>
                    <Th color="#79767D" fontWeight={400}>
                      SR. NO.
                    </Th>
                    {report.order.map((id, idx) => {
                      return (
                        <Th key={idx} color="#79767D" fontWeight={400}>
                          {id.toUpperCase()}
                        </Th>
                      );
                    })}
                    <Th color="#79767D" fontWeight={400}>
                      COMMENT
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {report.data.map((item, index) => {
                    return (
                      <Tr
                        key={index}
                        className="!text-sm !text-[#3E3C42] !font-medium even:bg-[#FAFAFA] odd:bg-white"
                      >
                        <Td className="">
                          {String(index + 1).padStart(2, "0")}
                        </Td>
                        {report.order.map((x, idx) => {
                          return (
                            <Td key={idx} className="">
                              {x.toLowerCase().includes("time")
                                ? new Date(item[x]).toLocaleDateString() +
                                  " " +
                                  new Date(item[x]).toLocaleTimeString()
                                : item[x]}
                            </Td>
                          );
                        })}
                        <Td className="">{getComment(item.healthIndex)}</Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </div>
        )} */}
      </div>
      {shiftSelectOpen && (
        <ShiftSelectModal
          openModal={shiftSelectOpen}
          closeModal={() => setShiftSelectOpen(false)}
          clientId={param.clientId.toLowerCase()}
        />
      )}
    </div>
  );
};

export default Report;
