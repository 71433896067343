import { CustomStyledDataGrid } from "../../../util/MaterialDataGrid/CustomStyledDatagrid";


const CustomRadioRenderCell = ({ id, timeStamp, selectedRows, setSelectedRows, selectedTimeStamps, setSelectedTimeStamps }) => {

    let fg = false;
    if(selectedRows?.length >= 10)fg=true;

    if(selectedRows?.includes(id))fg=false;

    return (
        <input type="checkbox" disabled={fg} onChange={(e) => {
            if (e.target.checked) {
                if (!selectedRows?.includes(id)) {
                    setSelectedRows((prev) => {
                        return [...prev, id];
                    })
                    setSelectedTimeStamps((prev) => {
                        return [...prev, timeStamp];
                    })
                }
            }
            else {
                if (selectedRows?.includes(id)) {
                    let index = 0;
                    setSelectedRows((prev) => {
                        // /console.log(prev);
                        let a = [...prev];
                        index = a?.indexOf(id);
                        a?.splice(index, 1);
                        return a;
                    })

                    setSelectedTimeStamps((prev) => {
                        let a = [...prev];
                        a?.splice(index, 1);
                        return a;
                    })

                }
            }
        }}
            checked={selectedRows?.includes(id)}
        />
    )
};
export const IbaTable = ({
    columns,
    rowData,
    handleCellClick,
    rowSelectionModel,
    columnGroupingModel,
    hideFooter = true,
    pagination,
    initialState,
    slots,
    selectedRows,
    setSelectedRows,
    selectedTimeStamps,
    setSelectedTimeStamps
}) => {

    const updatedColumns = [{
        field: "selector",
        headerName: "",
        renderCell: (params) => {
            const { row } = params;
            return <CustomRadioRenderCell id={row?.plate_id} timeStamp={row?.TOC} selectedRows={selectedRows} setSelectedRows={setSelectedRows} selectedTimeStamps={selectedTimeStamps} setSelectedTimeStamps={setSelectedTimeStamps}/>;
        },
    }, ...columns,
    ]

    return (
        <CustomStyledDataGrid
            columns={updatedColumns || []}
            rows={rowData || []}
            hideFooter={hideFooter}
            rowHeight={40}
            columnHeaderHeight={35}
            showColumnVerticalBorder
            disableColumnSelector={true}
            disableRowSelectionOnClick={true}
            experimentalFeatures={{ columnGrouping: true }}
            {...(columnGroupingModel && {
                columnGroupingModel: columnGroupingModel,
            })}
            {...(handleCellClick && {
                onCellClick: handleCellClick,
            })}
            pagination={pagination}
            initialState={initialState}
            slots={slots}
            // checkbox selection
            {...(rowSelectionModel && {
                checkboxSelection: true,
            })}
            {...(rowSelectionModel && {
                rowSelectionModel: rowSelectionModel,
            })}
            //   getRowClassName={(params) => {
            //     // console.log(params);
            //     return (params?.row?.measured_data_FCT_QC != "pass" && rowSelectionModel[0] === params?.id ? '!shadow-inner !border !border-red-200 !border-1 !bg-[#fffefb]' : (params?.row?.measured_data_FCT_QC != "pass" ? '!bg-red-50' : ''))
            //   }}
            sx={{
                // "& .MuiDataGrid-columnHeaderTitle": {
                //   whiteSpace: "normal",
                //   lineHeight: "normal",
                //   paddingTop: "5px",
                // },
                // "& .MuiDataGrid-columnHeaderTitleContainer": {
                //   alignItems: "start",
                // },
                maxHeight: handleCellClick ? "60vh" : "60vh",
                overflow: "hidden",
                "& .MuiDataGrid-columnHeader": {
                    border: "0.9px solid #e0e0e0",
                    alignItems: 'center',
                },
                "& .MuiDataGrid-columnHeaderTitleContainer": {
                    //border: "0.9px solid #e0e0e0",
                    justifyContent: "center"
                },
                // "& .MuiDataGrid-columnSeparator": { display: "none" },
                "& .MuiDataGrid-row.Mui-selected": {
                    backgroundColor: "#FFFdf3",
                    boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
                    fontWeight: "600",
                    "&:hover": {
                        backgroundColor: "#FFFdf3",
                    },
                },
            }}
        />
    );
};
