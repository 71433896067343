import {
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
} from "@chakra-ui/react";
import { ChevronLeft, ChevronRight, Close } from "@mui/icons-material";
import React, { useState } from "react";
import { formatDateTimeLocale } from "../utilis/utilityFunctions";

export const PreviewImageModal = ({
  imageIndex,
  closeModal,
  openModal,
  data,
}) => {
  console.log({ data });
  const [loading, setLoading] = useState({
    originalImage: true,
  });
  const [index, setIndex] = useState(imageIndex);
  const handleNavigate = (value) => {
    if (value === -1) {
      // going on previous
      setIndex((prev) => {
        return (prev - 1 + data.length) % data.length;
      });
    } else if (value === 1) {
      // going on next
      setIndex((prev) => {
        return (prev + 1) % data.length;
      });
    }
  };

  return (
    <Modal
      isOpen={openModal}
      onClose={closeModal}
      isCentered="true"
      returnFocusOnClose={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent
        style={{ borderRadius: "12px" }}
        maxW={["95vw", "90vw", "90vw", "90vw", "60vw"]}
        maxH={["80vh", "80vh", "80vh", "95vh", "95vh"]}
      >
        <ModalHeader
          padding="0px"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          position={"relative"}
          width={"100%"}
        >
          <IconButton
            position={"absolute"}
            rounded={"full"}
            bg={"white"}
            cursor={"pointer"}
            top={-5}
            icon={<Close sx={{ color: "gray" }} />}
            onClick={closeModal}
            boxShadow={"md"}
          />
        </ModalHeader>
        <ModalBody padding={[3, 3, 6, 6, 6, 6]}>
          <div className="relative flex flex-col justify-center items-center gap-2 opacity-90 px-0 md:px-2 py-2 md:py-0 w-full h-full">
            <IconButton
              position={"absolute"}
              top={"50%"}
              left={[-3, -3, -5, -6, -6, -6]}
              bg={"white"}
              rounded={"full"}
              size={"sm"}
              boxShadow={"md"}
              icon={<ChevronLeft />}
              zIndex={50}
              onClick={() => handleNavigate(-1)}
            />
            <div className="flex md:flex-row flex-col justify-between gap-2 md:gap-0 w-full">
              <div className="flex flex-row justify-start items-center gap-2 font-bold">
                <p className="text-[#3E3C42] text-sm md:text-base">
                  {data[index]?.cameraName}
                </p>
                <p className="text-[#79767D] text-xs md:text-base">
                  {data[index]?.timeStamp &&
                    formatDateTimeLocale(data[index].timeStamp)}{" "}
                </p>
              </div>
              <div className="flex flex-row gap-2 rounded-md font-bold text-sm md:text-base self-start md:self-auto">
                {data[index]?.flareVolume ? (
                  <p className="bg-[#F5F5F5] px-1 rounded-md">
                    {`${data[index]?.flareVolume}`}
                  </p>
                ) : null}
              </div>
            </div>

            <div className="flex flex-col gap-2 w-full h-full sm:h-[50vh] md:h-[60vh] lg:h-[70vh] xl:h-[85vh]">
              {loading.originalImage && (
                // Skeleton
                <Skeleton
                  height={["150px", "150px", "250px", "250px", "250px"]}
                  width={"100%"}
                  rounded={"md"}
                  border={"1px solid red"}
                />
              )}
              <div
                className="w-full h-full"
                style={{
                  display: loading?.originalImage ? "none" : "block",
                }}
              >
                <img
                  src={data[index]?.image}
                  alt="image_alt"
                  className="rounded-md w-full h-full"
                  onLoad={() =>
                    setLoading((prev) => ({
                      ...prev,
                      originalImage: false,
                    }))
                  }
                />
              </div>
            </div>
            <IconButton
              position={"absolute"}
              top={"50%"}
              right={[-3, -3, -5, -6, -6, -6]}
              bg={"white"}
              rounded={"full"}
              size={"sm"}
              boxShadow={"md"}
              icon={<ChevronRight />}
              zIndex={50}
              onClick={() => handleNavigate(1)}
            />
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
