import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Select,
  DrawerCloseButton,
  Radio,
  RadioGroup,
  Input,
  Table,
  Th,
  Tr,
  Tbody,
  Thead,
  Td,
  TableContainer,
  useToast,
  IconButton,
} from "@chakra-ui/react";
import TonalButton from "../../../util/Buttons/TonalButton";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { baseURL } from "../../..";
import NavContext from "../../NavContext";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ConcentrateSelect from "./ConcentrateSelect";
import BctContext from "../BctContext";

const EditableSection = ({
  total,
  bay,
  data,
  setData,
  section,
  first = false,
  bulk,
}) => {
  const handleChange = (name, value, idx) => {
    if (bulk) {
      setData((prev) => {
        let newData = { ...prev };
        for (let s in newData[bay]) {
          for (let i = newData[bay][s].length - 1; i >= 0; i--) {
            if (i === 0) {
              newData[bay][s][i][name] = value;
              newData[bay][s][i]["lastUpdated"] = new Date().toLocaleString();
            } else {
              newData[bay][s].splice(i, 1);
            }
          }
        }
        return newData;
      });
    } else {
      console.log(idx);
      setData((prev) => {
        let newData = { ...prev };
        newData[bay][section][idx][name] = value;
        newData[bay][section][idx]["lastUpdated"] = new Date().toLocaleString();
        return newData;
      });
    }
  };
  const totalCheck = (val, targetValue, idx) => {
    let total = val.total;
    data[bay][section]?.forEach((item, i) => {
      if (i != idx) total -= item.current;
    });
    return targetValue < total ? targetValue : total;
  };
  const handleAdd = () => {
    setData((prev) => {
      let newData = { ...prev };
      let obj = {
        concentrate: "",
        current: 0,
        total: 5000,
      };
      newData[bay][section].push(obj);
      return newData;
    });
  };
  const handleRemove = (idx) => {
    setData((prev) => {
      let newData = { ...prev };
      newData[bay][section].splice(idx, 1);
      return newData;
    });
  };

  useEffect(() => {
    if (bulk) {
      setData((prev) => {
        let newData = { ...prev };
        newData[bay][section][0]["concentrate"] = "";
        newData[bay][section][0]["current"] = 0;
        return newData;
      });
    }
  }, [bulk]);

  return (
    <Tr>
      {first && (
        <Td
          border={0}
          pl={"16px"}
          pr={"8px"}
          pt={"0px"}
          pb={"16px"}
          color={"#3E3C42"}
          fontSize={"16px"}
          fontWeight={500}
          rowSpan={total}
          style={{ verticalAlign: "top" }}
        >
          Bay {bay[1]}
        </Td>
      )}
      <Td
        border={0}
        pl={"0px"}
        pr={"8px"}
        pt={"0px"}
        pb={"16px"}
        color={"#605D64"}
        fontSize={"14px"}
        fontWeight={500}
        style={{ verticalAlign: "top" }}
      >
        {bulk ? "All" : `Section ${section[1]}`}
      </Td>
      <Td
        border={0}
        pl={"0px"}
        pr={"8px"}
        pt={"0px"}
        pb={"16px"}
        color={"#605D64"}
        fontSize={"14px"}
        fontWeight={500}
      >
        <div className="flex flex-col gap-1">
          {(!bulk ? data[bay][section] : data[bay][section]?.slice(0, 1))?.map(
            (item, idx) => {
              return (
                <ConcentrateSelect
                  value={item?.concentrate}
                  func={(e) => {
                    handleChange("concentrate", e.target.value, idx);
                    if (e.target.value == "") handleChange("current", 0, idx);
                  }}
                />
              );
            }
          )}
        </div>
      </Td>
      <Td
        border={0}
        pl={"0px"}
        pr={"8px"}
        pt={"0px"}
        pb={"16px"}
        color={"#605D64"}
        fontSize={"14px"}
        fontWeight={500}
      >
        <div className="flex flex-col gap-1">
          {(!bulk ? data[bay][section] : data[bay][section]?.slice(0, 1))?.map(
            (item, idx) => {
              return (
                <Input
                  type="number"
                  value={item?.current}
                  width={"100px"}
                  border={"1px solid #CAC5CD"}
                  isDisabled={!item.concentrate}
                  onChange={(e) => {
                    let val = totalCheck(item, e.target.value, idx);
                    handleChange("current", val, idx);
                  }}
                />
              );
            }
          )}
        </div>
      </Td>
      <Td
        border={0}
        pl={"0px"}
        pr={"16px"}
        pt={"0px"}
        pb={"16px"}
        color={"#AEA9B1"}
        fontSize={"14px"}
        fontWeight={400}
      >
        <div className="flex flex-col gap-1">
          {(!bulk ? data[bay][section] : data[bay][section]?.slice(0, 1))?.map(
            (item, idx) => {
              return <p className="h-10 align-middle">{item?.lastUpdated}</p>;
            }
          )}
        </div>
      </Td>
      {!bulk && (
        <Td
          border={0}
          pl={"0px"}
          pr={"16px"}
          pt={"0px"}
          pb={"16px"}
          color={"#AEA9B1"}
          fontSize={"14px"}
          fontWeight={400}
        >
          <div className="flex flex-col gap-1">
            {data[bay][section]?.map((item, idx) => {
              return idx == 0 ? (
                <IconButton
                  icon={<AddIcon />}
                  size={"xs"}
                  onClick={() => handleAdd()}
                />
              ) : (
                <IconButton
                  icon={<RemoveIcon />}
                  size={"xs"}
                  onClick={() => handleRemove(idx)}
                />
              );
            })}
          </div>
        </Td>
      )}
    </Tr>
  );
};

const Stockdrawer = ({
  closeModal,
  openModal,
  bay,
  data,
  sections,
  bulk = false,
  fetchData,
}) => {
  const cols = ["Bay", "Section", "Concentrate", "Quantity", "Last updated"];
  const { auth } = useContext(NavContext);
  const { currentRecipeConcs } = useContext(BctContext);
  const toast = useToast();
  const [allData, setAllData] = useState({});
  useEffect(() => {
    setAllData((prev) => structuredClone(data));
  }, [data]);

  const checkInRecipe = () => {
    let isChangedConcs = [];
    for (let bay in data) {
      for (let section in data[bay]) {
        for (let i in data[bay][section]) {
          let conc = data[bay][section][i]?.concentrate;
          if (currentRecipeConcs?.includes(conc)) {
            let newConc = allData[bay][section][i]?.concentrate;
            if (newConc != conc) {
              isChangedConcs?.push({
                newConc,
                conc,
                position: bay + section,
              });
            }
          }
        }
      }
    }
    let allSame = isChangedConcs?.every(
      (item) => item?.newConc === isChangedConcs[0]?.newConc
    );
    if (isChangedConcs?.length > 0) {
      toast({
        title: "Warning",
        description: (
          <div className="flex flex-col gap-2 justify-center w-full">
            <p>You are making the below changes</p>
            {allSame ? (
              <p>
                "{isChangedConcs[0]?.newConc}" is replacing
                {isChangedConcs?.map((item, idx) => {
                  return `${idx === 0 ? "" : "and "}"${item?.conc}" in ${
                    item?.position
                  } `;
                })}
              </p>
            ) : (
              isChangedConcs?.map((item, idx) => (
                <p>
                  {`${idx}. "${item?.newConc}" is replacing "${item?.conc}" in ${item?.position}`}
                </p>
              ))
            )}
            <p>
              present in recipe. This change can affect the summary report
              generation.
            </p>
            <p>
              Note: You can ignore if you are going to update the blend recipe
            </p>
            <p>Click save if you wish to proceed</p>
            <TonalButton
              text={"Save"}
              width={"fit-content"}
              onClick={() => {
                updateApi();
              }}
            />
          </div>
        ),
        duration: 10000,
        status: "warning",
        isClosable: true,
        position: "top-right",
      });
    } else updateApi();
  };

  const updateApi = async () => {
    try {
      const requestBody = JSON.stringify(allData);
      const response = await axios.post(
        baseURL + "vision/v1/workforceMonitoring/concentrate",
        requestBody,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.status == 200) {
        toast({
          title: "Updated",
          description: "Data successfully updated",
          status: "success",
          position: "top-right",
          duration: 2000,
          isClosable: true,
        });
        fetchData();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Drawer
      onClose={closeModal}
      closeOnOverlayClick={false}
      isOpen={openModal}
      size="lg"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerHeader padding="0px" position={"relative"}></DrawerHeader>
        <DrawerBody p={"24px"}>
          <div className="flex flex-col gap-6">
            <div className="flex justify-between items-start">
              <p className="text-[#3E3C42] text-lg font-medium">
                Bay {[bay[1]]} blend ratio
              </p>
              <div className="flex gap-[10px] items-center">
                <TonalButton
                  text={"Close"}
                  width={"fit-content"}
                  onClick={() => {
                    fetchData();
                    closeModal();
                  }}
                />
                <PrimaryButton
                  text={"Save"}
                  width={"fit-content"}
                  onClick={checkInRecipe}
                />
              </div>
            </div>
            <TableContainer
              width={"full"}
              roundedTop={"8px"}
              overflowX={"auto"}
            >
              <Table
                variant="simple"
                borderTopRadius={"8px"}
                width={"full"}
                minWidth={"600px"}
              >
                <Thead bg={"white"} position={"sticky"} top={"0px"}>
                  <Tr>
                    {cols.map((item, idx) => {
                      return (
                        <Th
                          color="#938F96"
                          fontSize={"14px"}
                          fontWeight={500}
                          border={0}
                          pt={"0px"}
                          pb={"32px"}
                          pr={idx == cols.length - 1 ? "12px" : "8px"}
                          pl={idx == 0 ? "16px" : "0px"}
                          textAlign={"start"}
                          className="!capitalize"
                        >
                          {item}
                        </Th>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {allData &&
                    Object.keys(allData).length > 0 &&
                    (!bulk ? (
                      Object.keys(sections).map((item, idx) => (
                        <EditableSection
                          total={Object.keys(sections).length}
                          bay={bay}
                          data={allData}
                          section={item}
                          setData={setAllData}
                          first={idx === 0}
                          bulk={bulk}
                        />
                      ))
                    ) : (
                      <EditableSection
                        total={1}
                        bay={bay}
                        data={allData}
                        section={Object.keys(sections)[0]}
                        setData={setAllData}
                        first={true}
                        bulk={bulk}
                      />
                    ))}
                </Tbody>
              </Table>
            </TableContainer>
          </div>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default Stockdrawer;
