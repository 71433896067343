import { useEffect, useRef } from "react";

const useShallowCompareEffect = (callback, dependencies) => {
  const previousDependenciesRef = useRef();

  const dependenciesChanged = dependencies.some((dep, i) => {
    return dep !== previousDependenciesRef.current?.[i];
  });

  if (dependenciesChanged) {
    previousDependenciesRef.current = dependencies;
  }

  useEffect(() => {
    if (dependenciesChanged) {
      return callback();
    }
  }, [previousDependenciesRef.current]);
};

export default useShallowCompareEffect;
