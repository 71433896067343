import { forwardRef, useContext, useEffect, useState } from "react";
import { CustomStyledDataGrid } from "../../../util/MaterialDataGrid/CustomStyledDatagrid";
import { getWhatIfAnalysisDataApi } from "../Services/whatIfAnalysisServices";
import NavContext from "../../NavContext";
import { generateParamertersColumns } from "../utilis/dataGridColumns";
import TableSkeleton from "../../SinterBelt/components/LoadingState/TableSkeleton";
import TonalButton from "../../../util/Buttons/TonalButton";
import { SaveOutlined } from "@mui/icons-material";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { convertMapToArray } from "../utilis/capitalizeCamelCaseString";
import { LineChartWithBaseline } from "../Components/SingleElement/LineChartWithBaseline";
import { Skeleton } from "@chakra-ui/react";
export const CustomEditCell = (params) => {
  const { value, id, field, api } = params;
  const handleValueChange = (event) => {
    const newValue = event.target.value;
    api.updateRows([{ id, [field]: isNaN(newValue) ? "" : Number(newValue) }]);
  };

  return (
    <div className="w-full flex justify-start gap-2">
      <input
        type="number"
        className="border w-2/5 h-8 text-[#605D64] pl-2 pr-5 rounded-md"
        value={value}
        onChange={handleValueChange}
      />
    </div>
  );
};

const EditableTable = forwardRef(({ columns, rowData }, ref) => {
  return (
    <CustomStyledDataGrid
      rows={rowData || []}
      columns={columns || []}
      apiRef={ref}
      rowHeight={45}
      autoHeight={true}
      hideFooter={true}
      columnHeaderHeight={40}
      disableColumnSelector={true}
      disableRowSelectionOnClick={true}
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0
          ? "bg-[#FAFAFA]"
          : "bg-white"
      }
      showColumnVerticalBorder
    />
  );
});
export const WhatIfAnalysis = () => {
  const { auth } = useContext(NavContext);
  const topControllableParametersGridApiRef = useGridApiRef();
  const otherParametersGridApiRef = useGridApiRef();
  const [loading, setLoading] = useState(false);
  const [lineChartData, setLineChartData] = useState(null);
  const [columns, setColumns] = useState(null);
  const [topControllableParametersTableData, setTopControllableTableData] =
    useState(null);
  const [otherParametersTableData, setOtherParametersTableData] =
    useState(null);

  const fetchData = async () => {
    try {
      const response = await getWhatIfAnalysisDataApi(auth, null, setLoading);
      const tempColumns = await generateParamertersColumns(
        response?.data?.topControllableParameters?.columns,
        true
      );
      setLineChartData((prev) => response?.data?.lineChartData);
      setColumns((prev) => tempColumns);
      setTopControllableTableData(
        (prev) => response?.data?.topControllableParameters?.tableData
      );
      setOtherParametersTableData(
        (prev) => response?.data?.otherParameters?.tableData
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveClick = () => {
    const tempTopControllableParametersMap = convertMapToArray(
      topControllableParametersGridApiRef.current?.getRowModels()
    );
    const tempOtherControllableParametersMap = convertMapToArray(
      otherParametersGridApiRef.current?.getRowModels()
    );
    console.log({
      topControllableParameters: tempTopControllableParametersMap,
      otherParameters: tempOtherControllableParametersMap,
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="flex flex-col p-3 gap-10">
      {/* Single Chart */}
      <div className="flex flex-col gap-2 bg-white rounded-xl py-2">
        <p className="text-sm  md:text-base lg:text-lg font-bold px-1 md:px-3">
          {"Zinc% in Slag"}
        </p>
        {loading ? (
          <Skeleton
            height="320px"
            width="98%"
            borderRadius="md"
            margin={"auto"}
          />
        ) : (
          <>
            {lineChartData && (
              <LineChartWithBaseline
                chartData={lineChartData}
                title={"Zinc%"}
              />
            )}
          </>
        )}
      </div>
      {/* Parameters  */}
      <div className="flex flex-col gap-5 bg-white py-2 rounded-xl">
        <div className="flex flex-col gap-2 w-full px-1 md:px-3 py-2 bg-white">
          <div className="flex flex-row items-center justify-between">
            <p className="text-sm  md:text-base lg:text-lg font-bold text-left">
              {"Top Controllable Parameters for Model"}
            </p>
            <TonalButton
              text={"Save"}
              width={"80px"}
              Icon={<SaveOutlined />}
              onClick={handleSaveClick}
            />
          </div>
          <div className="h-full w-full border rounded-md">
            {loading ? (
              <TableSkeleton
                headers={["Header 1", "Header 2", "Header 3"]}
                rows={3}
                cellsPerRow={3}
              />
            ) : (
              <EditableTable
                ref={topControllableParametersGridApiRef}
                columns={columns}
                rowData={topControllableParametersTableData}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2 w-full px-1 md:px-3">
          <p className="text-sm  md:text-base lg:text-lg font-bold text-left">
            {"Other Parameters"}
          </p>
          <div className="h-full w-full border rounded-md">
            {loading ? (
              <TableSkeleton
                headers={["Header 1", "Header 2", "Header 3"]}
                rows={5}
                cellsPerRow={3}
              />
            ) : (
              <EditableTable
                ref={otherParametersGridApiRef}
                columns={columns}
                rowData={otherParametersTableData}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
