// //  api's for bias recommender page
import axios from "axios";
import { mulpicJsplBaseUrl } from "../Mulpic";

export const getUpcomingBiasRecommenderDataApi = async (
  auth,
  requestData,
  setLoading
) => {
  try {
    setLoading((prev) => {
      return { ...prev, upcomingBiasRecommender: true };
    });
    const res = await axios.get(
      `${mulpicJsplBaseUrl}mulpic/get_upcoming_plates`

      // requestData,
      // {
      //   credentials: "same-origin",
      //   headers: {
      //     "Content-Type": "application/json",
      //     "X-Auth-Token": auth,
      //   },
      // }
    );
    const data = res?.data;
    if (data) {
      const { columns, tableData } = data;
      const sortedData = tableData?.sort((a, b) => a?.["TOC"] - b?.["TOC"]);
      return {
        success: true,
        data: { columns, tableData: sortedData },
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      error: error,
    };
  } finally {
    setLoading((prev) => {
      return { ...prev, upcomingBiasRecommender: false };
    });
  }
};

export const getRollingPlatesDataApi = async (
  auth,
  requestData,
  setLoading
) => {
  try {
    setLoading((prev) => {
      return { ...prev, rolledPlates: true };
    });
    const res = await axios.get(
      `${mulpicJsplBaseUrl}mulpic/rolled_plates`
    );
    const data = res?.data;
    if (data) {
      const { columns, tableData } = data;
      const sortedData = tableData?.sort((a, b) => a?.["TOC"] - b?.["TOC"]);
      return {
        success: true,
        data: { columns, tableData: sortedData },
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      error: error,
    };
  } finally {
    setLoading((prev) => {
      return { ...prev, upcomingBiasRecommender: false };
    });
  }
};

export const getCurrentConfigDataApi = async (
  auth,
  requestData,
  setLoading
) => {

  console.log(requestData)
  try {
    setLoading((prev) => ({ ...prev, currentConfig: true }));
    const res = await axios.post(
      `${mulpicJsplBaseUrl}mulpic/currentconfig`,
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      error: error,
    };
  } finally {
    setLoading((prev) => ({ ...prev, currentConfig: false }));
  }
};

export const postIsRollingApi = async (requestData) => {
  try {
    const res = await axios.post(
      `${mulpicJsplBaseUrl}mulpic/rolling_plate_flag/`,
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      error: error,
    };
  }
}

export const postCommentApi = async (
  requestData,
) => {

  console.log(requestData)
  try {
    const res = await axios.post(
      `${mulpicJsplBaseUrl}mulpic/comments/`,
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      error: error,
    };
  }
};

export const getIsRollingApi = async (
  requestData,
) => {
  try {
    const res = await axios.get(
      `${mulpicJsplBaseUrl}mulpic/rolling_plate_flag/`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data: res?.data,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      error: error,
    };
  }
};

export const getPrtDataApi = async (
  auth,
  requestData,
  //setLoading
) => {
  try {
    //setLoading((prev) => ({ ...prev, currentConfig: true }));
    const res = await axios.post(
      `${mulpicJsplBaseUrl}mulpic/get_prt_indices`,
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      error: error,
    };
  }
  // } finally {
  //   setLoading((prev) => ({ ...prev, currentConfig: false }));
  // }
};

export const getRecommendedConfigDataApi = async (
  auth,
  requestData,
  setLoading
) => {
  try {
    setLoading((prev) => ({ ...prev, recommendedConfig: true }));
    const res = await axios.post(
      `${mulpicJsplBaseUrl}mulpic/recommendation`,
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      error: error,
    };
  } finally {
    setLoading((prev) => ({ ...prev, recommendedConfig: false }));
  }
};

export const getSimilarPlatesDataApi = async (
  auth,
  requestData,
  setLoading
) => {
  try {
    setLoading((prev) => {
      return { ...prev, similarPlates: true };
    });
    const res = await axios.post(
      `${mulpicJsplBaseUrl}mulpic/similar_plates`,
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = res?.data;
    const tempIdData = data?.map((el, idx) => ({
      ...el,
      id: idx + 1,
    }));
    if (data) {
      return {
        success: true,
        data: tempIdData,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      error: error,
    };
  } finally {
    setLoading((prev) => {
      return { ...prev, similarPlates: false };
    });
  }
};

export const getTestSimilarPlatesDataApi = async (
  auth,
  requestData,
  setLoading
) => {
  try {
    setLoading((prev) => {
      return { ...prev, similarPlates: true };
    });

    const response = await axios.post(
      mulpicJsplBaseUrl + 'mulpic/test_similar_plates',
      requestData, {
      headers: {
        'Content-Type': 'application/json'
      }
    }
    );
    
    const data = response?.data;
    const tempIdData = data?.map((el, idx) => ({
      ...el,
      id: idx + 1,
    }));
    if (data) {
      return {
        success: true,
        data: tempIdData,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      error: error,
    };
  } finally {
    setLoading((prev) => {
      return { ...prev, similarPlates: false };
    });
  }
};

export const getSlabsDataApi = async (auth, requestData, setLoading) => {
  try {
    setLoading((prev) => {
      return { ...prev, slabs: true };
    });
    const res = await axios.get(
      `${mulpicJsplBaseUrl}mulpic/get_slabs`

      // requestData,
      // {
      //   credentials: "same-origin",
      //   headers: {
      //     "Content-Type": "application/json",
      //     "X-Auth-Token": auth,
      //   },
      // }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data: data,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      error: error,
    };
  } finally {
    setLoading((prev) => {
      return { ...prev, slabs: false };
    });
  }
};

export const getRecommendedConfigRolledPlatesDataApi = async (
  auth,
  requestData,
  setLoadingConfig
) => {
  try {
    setLoadingConfig((prev) => ({ ...prev, recommendedConfig: true }));
    const res = await axios.get(
      `${mulpicJsplBaseUrl}mulpic/get_recommended_config`,

      {
        params: JSON.parse(requestData),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      error: error,
    };
  } finally {
    setLoadingConfig((prev) => ({ ...prev, recommendedConfig: false }));
  }
};

export const getCurrentConfigRolledPlatesDataApi = async (
  auth,
  requestData,
  setLoadingConfig
) => {
  console.log(requestData)
  try {
    setLoadingConfig((prev) => ({ ...prev, actualConfig: true }));
    const res = await axios.get(
      `${mulpicJsplBaseUrl}mulpic/get_actual_config`,
      {
        params: JSON.parse(requestData),
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      error: error,
    };
  } finally {
    setLoadingConfig((prev) => ({ ...prev, actualConfig: false }));
  }
};
