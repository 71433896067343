import { useState } from "react";
import { Link } from "react-router-dom";

const IsfOptimizerClientSelect = () => {
  const [clients, setClients] = useState(["hindustanzinc"]);

  return (
    <div className="h-full">
      <div className="bg-white rounded-xl shadow-md p-4 mt-5 border">
        <div className="flex flex-col gap-5">
          <div className="flex justify-start mt-3 ml-3 mr-3 mb-3 text-semibold text-2xl gap-2 items-center">
            <Link
              to="/home"
              style={{
                textDecoration: "none",
              }}
            >
              <img src="/backtick.svg" alt="go-back" className="border" />
            </Link>
            Please choose a client
            <p></p>
          </div>
          <div className="flex w-full flex-wrap gap-4 ml-3 mb-5">
            {clients?.map((item, index) => {
              return (
                <Link
                  to={`./${item}?tabIndex=0`}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    key={index}
                    className="p-1 h-40 w-40 border items-center uppercase text-[#024D87] font-bold rounded-md flex justify-center shadow-md hover:bg-gray-100 hover:transition duration-200 cursor-pointer text-center"
                  >
                    {item}
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default IsfOptimizerClientSelect;
