import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import NavContext from "../../NavContext";
import LibraryGrid from "./LibraryGrid";
import { Select, Spinner } from "@chakra-ui/react";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import { getFeedDataApi } from "../services/feedServices";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import LibraryGridSkeleton from "./LoadingState/LibraryGridSkeleton";
import { mixpanelTrackButton } from "../../../util/mixpanelFunctions";
import { CustomMaterialMultipleSelect } from "./CustomMaterialSingleSelect";
import { capitalizeFirstLetter } from "../../../util/sentenceCase";
import { MoodBad } from "@mui/icons-material";

const PhotoGallery = ({ plantId, disable, plantCamMap }) => {
  // params refs
  const { auth } = useContext(NavContext);
  let param = useParams();

  // state
  const [loading, setLoading] = useState(true);
  const [imgData, setImgData] = useState(null);
  const [showType, setShowType] = useState(0);
  const [filterData, setFilterData] = useState({
    cameraId: "all",
    plant: plantId,
    filters: [],
    date: new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10),
    sort: "",
  });

  // handling showing date input
  const handleSelect = (e) => {
    let val = e.target.value;
    setShowType(val);
    if (val == 0) {
      handleFilters(new Date().toISOString().slice(0, 10), "date");
    } else if (val == 1) {
      handleFilters(
        new Date(new Date() - 24 * 60 * 60 * 1000).toISOString().slice(0, 10),
        "date"
      );
    }
  };

  // filter updation
  const handleFilters = (value, name) => {
    setFilterData((prev) => ({ ...prev, [name]: value }));
  };

  // to be called on changing filter's/ first load
  const handleClickApply = () => {
    const requestData = JSON.stringify({
      clientId: param.clientId.toLowerCase(),
      useCase: "SINTERBELT",
      plantName: filterData.plant,
      cameraId: filterData.cameraId,
      startDate: new Date(filterData.date).getTime(),
      filters: filterData.filters,
      sortType: filterData.sort,
    });
    getFeedDataApi(auth, requestData, setLoading)
      .then((res) => {
        console.log(res);
        setImgData(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
    mixpanelTrackButton("photo gallery", "Sinter Belt Monitoring", "View");
  };

  useEffect(() => {
    handleClickApply();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-white px-5 flex flex-col gap-6 rounded-lg">
      <div className="flex pt-5 gap-2 items-center overflow-x-auto pb-1 px-1">
        <div className="min-w-[110px]">
          <Select
            borderColor={"#CAC5CD"}
            placeholder={disable && capitalizeFirstLetter(filterData.plant)}
            variant="outline"
            isDisabled={disable}
            fontSize={"sm"}
            fontWeight={"medium"}
            textColor={"#605D64"}
            rounded={"base"}
            name="selectedPlant"
            onChange={(event) => handleFilters(event.target.value, "plant")}
            value={filterData.plant}
          >
            {!disable &&
              Object.keys(plantCamMap).map((plant) => {
                return (
                  <option
                    key={plant}
                    value={plant}
                    style={{ textTransform: "capitalize" }}
                  >
                    {capitalizeFirstLetter(plant)}
                  </option>
                );
              })}
          </Select>
        </div>
        <div className="min-w-[110px]">
          <Select
            borderColor={"#CAC5CD"}
            variant="outline"
            fontSize={"sm"}
            fontWeight={"medium"}
            textColor={"#605D64"}
            rounded={"base"}
            onChange={(e) => handleSelect(e)}
            value={showType}
          >
            <option value={0}>Today</option>
            <option value={1}>Yesterday</option>
            <option value={2}>Custom</option>
          </Select>
        </div>
        <div className="w-[150px] min-w-[150px]">
          <CustomMaterialMultipleSelect
            value={filterData.filters}
            selectedItems={filterData.filters}
            handleChange={(event, obj) => {
              const {
                target: { value },
              } = event;
              const { props } = obj;
              console.log(value, props.value);
              if (props?.value === "No Alert") {
                if (filterData?.filters?.includes("No Alert")) {
                  handleFilters([], "filters");
                } else {
                  handleFilters(["No Alert"], "filters");
                }
              } else {
                typeof value === "string"
                  ? handleFilters(value.split(","), "filters")
                  : handleFilters(
                      value?.filter((el) => el !== "No Alert"),
                      "filters"
                    );
              }
            }}
            options={[
              "No Alert",
              "V-notch Present",
              "Grate bar missing",
              "Poor Permeability",
            ]}
            label={"Select Filter"}
            disable={disable}
          />
        </div>
        <div className="min-w-[110px]">
          <Select
            borderColor={"#CAC5CD"}
            variant="outline"
            fontSize={"sm"}
            fontWeight={"medium"}
            textColor={"#605D64"}
            rounded={"base"}
            value={filterData.sort}
            onChange={(e) => handleFilters(e.target.value, "sort")}
          >
            <option value={""}>Sort By Gap</option>
            <option value={"asc"}>Low to High</option>
            <option value={"desc"}>High to Low</option>
          </Select>
        </div>
        {showType == 2 && (
          <div className="min-w-[110px]">
            <FloatingInput
              text="Date"
              type="date"
              setDateTime={(value) => handleFilters(value, "date")}
              value={filterData.date}
              max={new Date().toISOString().slice(0, 10)}
            />
          </div>
        )}
        <SecondaryButton
          height={"40px"}
          width={"80px"}
          text={loading ? <Spinner /> : "Apply"}
          disable={!filterData.date || loading}
          onClick={handleClickApply}
        />
      </div>
      {loading ? (
        <LibraryGridSkeleton />
      ) : imgData && imgData?.length > 0 ? (
        <LibraryGrid
          plantName={filterData.selectedPlant}
          img={imgData}
        />
      ) : (
        <div className="h-[40vh] flex flex-row items-center justify-center gap-2 text-gray-400 w-full border rounded-md">
          <MoodBad fontSize={"large"} />
          <h2 className="text-2xl">No Data!</h2>
        </div>
      )}
    </div>
  );
};

export default PhotoGallery;
