import { Linechart } from "../../../Charts/IsfOptimizerCharts/LineChart";
import { formatDateTimeIST } from "../../../SinterBelt/utilis/dateTimeFormatter";

export const LineChartWithBaseline = ({
  chartData,
  title,
  colors = ["#FFFFFF", "#1C56AC", "#6AB04B", "#FFC107"],
}) => {
  const twoDaysAgoTimestamp = new Date().getTime() - 2 * 24 * 60 * 60 * 1000;
  const updatedChartData = {
    series: [
      // prediction
      {
        name: "Prediction",
        type: "line",
        data: chartData?.["prediction"],
      },
      // actual data
      {
        name: title,
        type: "line",
        data: chartData?.["data"],
      },
      {
        name: "Target",
        type: "line",
        data: Array(chartData?.["data"].length).fill(chartData?.["target"]),
      },
      {
        name: "Baseline",
        type: "line",
        data: Array(chartData?.["data"].length).fill(chartData?.["baseline"]),
      },
    ],

    options: {
      chart: {
        toolbar: {
          show: true,

          tools: {
            download: true,
            selection: true,
            zoomin: false,
            zoomout: false,
            pan: false,
          },
          export: {
            csv: {
              dateFormatter(timestamp) {
                console.log(timestamp);
                return `${formatDateTimeIST(timestamp)}`;
              },
            },
          },
        },
        animations: {
          enabled: false,
        },
        zoom: {
          enabled: true,
        },
      },

      markers: {
        size: 0,
      },
      xaxis: {
        categories: chartData?.["times"],
        type: "datetime",
        min: twoDaysAgoTimestamp,
        max: chartData?.["times"][chartData?.times.length - 1],
        labels: {
          show: true,
          datetimeUTC: false,
          // format: "hh tt",
          style: {
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        type: "numeric",
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy HH:mm",
        },
      },
      colors: colors,
      dataLabel: {
        enabled: false,
      },
      forecastDataPoints: {
        count: 1,
      },
      grid: {
        borderColor: "#e7e7e7",
        strokeDashArray: 6,
        padding: {
          top: 0,
          right: 5,
          bottom: 0,
          left: 5,
        },
      },
      stroke: {
        curve: "straight",
        width: [2, 2, 1, 1],
        dashArray: [7, 0, 7, 7],
        colors: colors,
      },
      fill: {
        type: "solid",
        opacity: [1, 1, 1],
      },
      legend: {
        show: true,
        position: "top",
        horizontalAlign: "left",
        fontSize: "13px",
      },
    },
  };
  return (
    <div className={`w-[100%] h-[320px] bg-white`}>
      <Linechart chart={updatedChartData} />
    </div>
  );
};
