import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import {
  useLocation,
  useParams,
  Link,
  useSearchParams,
} from "react-router-dom";
import { baseURL } from "../..";
import Feed from "./Tabs/Feed";
import Alerts from "./Tabs/Alerts";
import Status from "./Tabs/Status";
import Report from "./Tabs/Report";
import FeedLibrary from "./Tabs/FeedLibrary";
import { useWindowSize } from "@uidotdev/usehooks";
import BctContext from "./BctContext";
import NavContext from "../NavContext";
import axios from "axios";
import { mixpanelTrackTabs } from "../../util/mixpanelFunctions";

export const postConcApi = async (auth, data, toast, setData) => {
  try {
    const requestData = JSON.stringify({
      list: data,
    });
    const response = await axios.post(
      baseURL + "vision/v1/workforceMonitoring/cb/saveConcentrateList",
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    if (response.status == 200) {
      toast({
        title: "Updated",
        description: "Changed concentrate entries",
        status: "success",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
      setData(response.data);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getRecipeConcs = async ({
  auth = "",
  setCurrentRecipeConcs = () => {},
}) => {
  try {
    const response = await axios.get(
      baseURL + "vision/v1/workforceMonitoring/concentrateStatus",
      {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    if (response?.data?.length > 0) {
      let concs = new Set();
      response?.data?.map((item) => concs?.add(item?.concentrate));
      setCurrentRecipeConcs(Array.from(concs));
    }
  } catch (error) {
    console.log(error);
  }
};

const BlendComplianceTracking = () => {
  const size = useWindowSize();
  const { auth } = useContext(NavContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const page = !isNaN(searchParams?.get("tabIndex"))
    ? Number(searchParams?.get("tabIndex"))
    : 0;
  const [conc, setConc] = useState([]);
  const [currBayMap, setCurrBayMap] = useState({});
  const [currentRecipeConcs, setCurrentRecipeConcs] = useState([]);
  const getConcApi = async () => {
    try {
      const response = await axios.get(
        baseURL + "vision/v1/workforceMonitoring/cb/getConcentrateList",
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.data) {
        setConc(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBayMapConc = async () => {
    try {
      const response = await axios.get(
        baseURL + "vision/v1/workforceMonitoring/concentrate",
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.data) {
        setCurrBayMap((prev) => {
          let newData = response.data;
          delete newData["_id"];
          let dum = new Set();
          for (let bay in newData) {
            for (let section in newData[bay]) {
              newData[bay][section]?.map((item) => dum.add(item?.concentrate));
            }
          }
          dum = Array.from(dum)?.filter((item) => item !== "");
          return dum;
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTabChange = (val) => {
    if (val !== page) {
      setSearchParams({ tabIndex: val }, { replace: true });
    }
  };

  useEffect(() => {
    getConcApi();
    getBayMapConc();
    getRecipeConcs({
      auth: auth,
      setCurrentRecipeConcs: setCurrentRecipeConcs,
    });
  }, []);

  useEffect(() => {
    handleTabChange(page);
    let tool = "Blend Compliance Tracking";
    mixpanelTrackTabs(tabList[page]?.tabName?.toLowerCase(), tool);

    console.log(tabList[page]?.tabName?.toLowerCase(), "-", tool);
  }, [page]);

  const feedRef = useRef();

  const tabList = [
    {
      index: 0,
      tabName: "Status",
      element: <Status feedRef={feedRef} />,
    },
    {
      index: 1,
      tabName: "Alerts",
      element: <Alerts plantId="All Plants" cameraId="" disable={false} />,
    },
    {
      index: 2,
      tabName: "Report",
      element: <Report plantId="All Plants" cameraId="" disable={false} />,
    },
    {
      index: 3,
      tabName: "Feed library",
      element: <FeedLibrary />,
    },
    {
      index: 4,
      tabName: "Feed",
      element: <Feed />,
    },
  ];

  return (
    <BctContext.Provider
      value={{
        conc,
        setConc,
        currBayMap,
        currentRecipeConcs,
        setCurrentRecipeConcs,
      }}
    >
      <div
        className="  font-poppins flex flex-col rounded-lg"
        style={{ width: size.width >= 768 ? "calc(100vw - 168px)" : "100vw" }}
      >
        <div
          className="fixed top-5 right-0 h-[30px] w-[180px] bg-white"
          style={{ zIndex: 1000 }}
        >
          <img
            className="absolute h-[60px] w-auto top-[-25px] right-5"
            src="https://upload.wikimedia.org/wikipedia/en/thumb/2/22/Hindalco_Logo.svg/640px-Hindalco_Logo.svg.png"
          />
        </div>
        <div className="flex justify-start items-center gap-2 mb-3 mt-6">
          <Link
            to={`/vision/workforceMonitoring/blendcompliance`}
            style={{
              textDecoration: "none",
            }}
          >
            <img src="/backtick.svg" />
          </Link>
          <p className="text-lg sm:text-2xl font-semibold text-[#024D87] capitalize">
            {"Blend Compliance Tracking"}
          </p>
        </div>
        <Tabs isLazy={true} index={page} onChange={handleTabChange}>
          <TabList className="!flex !border-0">
            <div className="flex items-center gap-4 overflow-x-auto h-14 md:h-10">
              {tabList?.map((item) => {
                return (
                  <Tab
                    className={
                      page === item?.index
                        ? "!text-black !text-xs sm:!text-sm !bg-white rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#79767D]"
                        : "!text-xs sm:!text-sm !text-[#938F96] !border-0"
                    }
                    onClick={() => handleTabChange(item?.index)}
                    display={item?.index === 4 ? "none" : "block"}
                    ref={item?.index === 4 ? feedRef : null}
                  >
                    {item?.tabName}
                  </Tab>
                );
              })}
            </div>
          </TabList>
          <TabPanels>
            {tabList?.map((item) => {
              return (
                <TabPanel className="!pl-0 !pr-0">{item?.element}</TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      </div>
    </BctContext.Provider>
  );
};

export default BlendComplianceTracking;
