import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";

const Paginator = ({
  data = [],
  limit = 10,
  initialPage = 1,
  setDisplayData,
}) => {
  const theme = createTheme();
  const [selectedPage, setSelectedPage] = useState(initialPage || 1);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const totalPages = useMemo(
    () => Math.ceil(data.length / limit),
    [data, limit]
  );

  const perPageData = useMemo(() => {
    const newData = {};
    for (let i = 0; i < data.length; i += limit) {
      const pageData = data
        .slice(i, i + limit)
        .map((item, idx) => ({ ...item, idx: i + idx + 1 }));
      newData[i / limit + 1] = pageData;
    }
    return newData;
  }, [data, limit]);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handlePageClick = (pageNumber) => {
    setSelectedPage(pageNumber);
    setDisplayData(perPageData[pageNumber]);
    handleCloseMenu();
  };
  useEffect(() => {
    setSelectedPage(initialPage || 1);
    setDisplayData(perPageData[initialPage || 1] || []);
  }, [data, initialPage, perPageData, setDisplayData]);

  return (
    <ThemeProvider theme={theme}>
      <div className="relative flex gap-[2px] text-sm text-gray-400 items-center whitespace-nowrap p-2">
        <Button
          id="paginator-button"
          aria-controls={open ? "paginator-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleOpenMenu}
          sx={{ textTransform: "none" }}
        >
          {data.length > 0
            ? `${(selectedPage - 1) * limit + 1}-${Math.min(
                selectedPage * limit,
                data.length
              )} of ${data.length}`
            : "0-0 of 0"}
        </Button>
        <div className="">
          <IconButton
            variant="link"
            disabled={selectedPage === 1}
            onClick={() => handlePageClick(Math.max(selectedPage - 1, 1))}
          >
            <ChevronLeft />
          </IconButton>
          <IconButton
            variant="link"
            disabled={selectedPage === totalPages}
            onClick={() =>
              handlePageClick(Math.min(selectedPage + 1, totalPages))
            }
          >
            <ChevronRight />
          </IconButton>
        </div>
        <Menu
          id="paginator-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          MenuListProps={{
            "aria-labelledby": "paginator-button",
          }}
          slotProps={{
            paper: {
              style: {
                maxHeight: "30vh",
                width: "15ch",
              },
            },
          }}
        >
          {Object.keys(perPageData).map((pageNumber) => (
            <MenuItem
              key={pageNumber}
              onClick={() => handlePageClick(parseInt(pageNumber))}
            >
              {`${(pageNumber - 1) * limit + 1}-${Math.min(
                pageNumber * limit,
                data.length
              )}`}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </ThemeProvider>
  );
};

export default Paginator;
