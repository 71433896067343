import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Checkbox,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  IconButton,
  Badge,
  Switch,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { utils, writeFile } from "xlsx";

import PrimaryButton from "../../../../util/Buttons/PrimaryButton";
import TonalButton from "../../../../util/Buttons/TonalButton";
import DownloadIcon from "@mui/icons-material/Download";
import { deactiveDeviceApi } from "../../Tabs/DevicesPage";
import { Tooltip } from "@chakra-ui/react";
import NavContext from "../../../NavContext";
import { all } from "axios";
import { useWindowSize } from "@uidotdev/usehooks";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const status = (val) => {
  switch (val) {
    case "DRAFT":
      return "draft";
    case "ACTIVE":
      return "live";
    case "INACTIVE":
      return "fail";
    default:
      break;
  }
};

const DeviceCard = ({
  data,
  setSelectedDevice,
  setShowDevicesTab,
  getDeviceApi,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checked, setChecked] = useState(true);
  const toast = useToast();
  const { auth } = useContext(NavContext);

  useEffect(() => {
    setChecked(data?.deviceInfo?.status === "INACTIVE");
  }, [data]);

  const handleChange = async (event) => {
    if (event.target.checked) {
      setIsModalOpen(true);
      return;

    } else {
      await deactiveDeviceApi(data, toast, false, auth, getDeviceApi);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmDiscard = async () => {
    await deactiveDeviceApi(data, toast, true, auth, getDeviceApi);
    setIsModalOpen(false);
    setTimeout(() => {
      setIsMenuOpen(false);
    }, 1000);
  };

  const exportAsExcel = (data, sheetName) => {
    let wb = utils.book_new();


    let fileColumns = Object.keys(data[0]);
    fileColumns = fileColumns.filter((value) => (value !== "deviceId" && value !== "createdBy" && value !== "updatedBy"))
    let newData = [fileColumns];

    if (data.length != 0) {
      data?.forEach((item) => {
        const newArray = fileColumns?.map((i) => {
          return i.toLowerCase().includes("time")
            ? new Date(item[i]).toLocaleString()
            : item[i];
        });
        newData.push(newArray);
      });
    }

    let ws = utils.aoa_to_sheet(newData);
    utils.book_append_sheet(wb, ws, `${sheetName}`);
    writeFile(wb, `report_data.xlsx`);
  };

  const handleDownloadClick = () => {
    if (Array.isArray(data.deviceInfo)) exportAsExcel(data.deviceInfo, "Summary");
    else exportAsExcel([data.deviceInfo], "Summary");
  }

  const size = useWindowSize();
  const productString = data?.deviceNameList;
  const products = productString;
  let maxProductsToShow = 0;
  let productsLengthExceeds = false;
  let totalLength = 0;

  const calculateProduct = () => {
    for (let i = 0; i < products?.length; i++) {
      // Assuming each location contributes 10 characters on average

      // Calculate the total length with the new location
      totalLength += products[i]?.length;

      // Increment maxLocationsToShow if the total length is within the limit
      // (size.width < 1536 ? 14 : 25)
      if (totalLength <= 25) {
        maxProductsToShow++;
      } else {
        //maxProductsToShow++;
        productsLengthExceeds = true;
        // Stop incrementing if the limit is exceeded
        break;
      }
    }
  };

  products && calculateProduct();

  const switchLabelText = checked ? "Disabled" : "Disable";
  const toolsString = data?.deviceNameList.join(",")
  const allTools = toolsString.split(",");
  const maxToolsToShow = 3;

  const handleClick = () => {
    setShowDevicesTab((prev) => "viewDevice");
    setSelectedDevice(data);
  };

  const handleUpdate = () => {
    setShowDevicesTab((prev) => "updateDevice");
    setSelectedDevice(data);
  };

  return (
    <div
      className="px-4 py-5 rounded flex flex-col justify-between gap-4 relative bg-white h-[250px] w-full"
      style={{
        boxShadow:
          "-4px -4px 24px 0px rgba(0, 0, 0, 0.07), 4px 4px 24px 0px rgba(0, 0, 0, 0.07)",
      }}
    >
      <div className="flex flex-col gap-2 w-full">
        <div className="flex h-[45px] gap-0 w-full">
          <div className="flex justify-start">
            {data?.logo32url ? (
              <img
                src={data?.logo32url}
                alt="logo"
                className="w-full max-w-[30px] h-[30px]"
              />
            ) : (
              <img src="/deviceDefaultIcon.png" alt="" className="w-full max-w-[30px] h-[30px]" />
            )}
          </div>

          <div className="ml-2 h-[45px] w-[70%] overflow-hidden">
            <Tooltip label={data?.deviceInfo?.deviceName} placement="top">
              <p className="text-[#3E3C42] text-[16px] font-medium whitespace-pre-line line-clamp-2 overflow-hidden overflow-ellipsis">
                {data?.deviceInfo?.deviceName}
              </p>
            </Tooltip>
          </div>


          <div className="w-[10%]">
            <Menu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<MoreVertIcon />}
                variant="simple"
                position={"absolute"}
                top={"15px"}
                right={"0px"}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              />
              <MenuList
                px={"12px"}
                py={"16px"}
                rounded={"8px"}
                display={"flex"}
                flexDirection={"column"}
                gap={"16px"}
                minWidth={"fit-content"}
                w={"180px"}
                position={"absolute"}
                right={"-50px"}
                top={"-15px"}
              >
                <MenuItem
                  icon={<DownloadIcon />}
                  textColor={"#605D64"}
                  fontSize={"14px"}
                  fontWeight={500}
                  p={"8px"}
                  _hover={{ bg: "#DDEEFF80", borderRadius: "4px" }}
                  _focus={{ bg: "#DDEEFF80", borderRadius: "4px" }}
                  onClick={handleDownloadClick}
                >
                  {"Download"}
                </MenuItem>
                {/* <div className="flex items-center justify-start gap-2"> */}
                <Checkbox
                  size="md"
                  colorScheme="red"
                  p={"8px"}
                  isChecked={checked}
                  disabled={data?.deviceInfo?.status === "DRAFT"}
                  onChange={handleChange}
                >
                  {switchLabelText}
                </Checkbox>
                {/* <p className="mr-2">{`${switchLabelText}`}</p> */}
                {/* </div> */}
              </MenuList>
            </Menu>
            <Modal size={"sm"} isOpen={isModalOpen} onClose={handleCloseModal}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader className=" flex justify-center rounded-t-md bg-red-400 text-white ">
                  Confirm Disable ?
                </ModalHeader>
                {/* <ModalCloseButton /> */}
                <ModalBody m={"auto"} fontSize={"15px"}>
                  <div className="flex flex-col items-center justify-center w-full">
                    <img
                      src="/disableIcon.png"
                      className="w-[100px] h-[100px]"
                    />

                    <p className="ml-4 font-medium">
                      Are you sure you would like to disable the device?
                    </p>
                  </div>
                </ModalBody>

                <div className="mt-4 flex flex-row justify-center">
                  <Button
                    colorScheme="blue"
                    mr={4}
                    onClick={handleConfirmDiscard}
                  >
                    Confirm
                  </Button>
                  <Button onClick={handleCloseModal}>Go Back</Button>
                </div>

                <ModalFooter></ModalFooter>
              </ModalContent>
            </Modal>
          </div>
          {/* <div></div> */}
        </div>
        <div className="flex  items-center justify-between">
          <div className="flex items-center justify-center">
            <div className="flex flex-col justify-center">
              <p className="text-[#938F96] text-sm">Instances</p>
              <div className="flex items-center justify-start">
                <div >
                  <img className="w-[20px] h-[20px]" src="/instance_colored.svg" alt="" />
                </div>
                <div className="ml-2">
                  <p>{data?.deviceNameList?.length}</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex-col justify-center">
              <p className="text-[#938F96] text-sm">Clients</p>
              <div className="flex items-center justify-start">
                <div className="">
                  <img className="w-[20px] h-[20px]" src="/clients.png" alt="" />
                </div>
                <div className="ml-2">
                  <p>{data?.clientList?.length}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="flex flex-col justify-center">
              <p className="text-[#938F96] text-sm">Locations</p>
              <div className="flex items-center justify-start">
                <div class Name="">
                  <img className="w-[20px] h-[20px]" src="/location.png" alt="" />
                </div>
                <div className="ml-2">
                  <p>{data?.locationList?.length}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <Tooltip label={allTools && allTools?.join(", ")} placement="top">
            <div>
              <p className="text-[#938F96] text-sm">Device instances</p>
              <div className="flex mt-2 justify-between">

                <div className="flex w-[180px] 2xl:w-[90%]">
                  <img className="w-[20px] h-[20px] mr-1" src="/deviceTableIcon.svg" alt="" />
                  <p className="text-[#938F96] text-sm font-medium truncate">{products.join(", ")}</p>
                  {/* {allTools && allTools?.slice(0, maxToolsToShow).map((el, index) => (
                    <div key={index} className="flex items-center overflow-hidden overflow-ellipsis whitespace-nowrap">

                      <p className="text-[#938F96] text-sm font-medium whitespace-pre-line line-clamp-1">{el + " "}</p>

                    </div>
                  ))} */}
                </div>
                {products?.length > maxProductsToShow && (
                  <Badge
                    ml={1}
                    color={"#447ED4"}
                    bg={"white"}
                    fontWeight={600}
                    fontSize="14px"
                  >
                    +{products?.length - maxProductsToShow}
                  </Badge>
                )}
              </div>
            </div>
          </Tooltip>
        </div>

      </div>
      <div className="flex gap-2 items-center">
        <PrimaryButton
          text={"View"}
          onClick={() => handleClick()}
        // disable={data?.deviceInfo?.status == "INACTIVE"}
        />
        <TonalButton
          text={"Update"}
          onClick={() => handleUpdate()}
          disable={data?.deviceInfo?.status == "INACTIVE"}
        />
      </div>

      <Tooltip label={data?.deviceInfo?.status?.toLowerCase()} hasArrow>
        {
          data?.deviceInfo?.status == "DRAFT" ? <img
            src={`/Common/draft.svg`}
            className="w-8 h-8 absolute -top-2 -right-2 rounded-full bg-white p-1 shadow-md"
            alt="status"
          /> : (
            data?.deviceInfo?.status == "ACTIVE" ? <CheckCircleIcon sx={{
              position: 'absolute',
              top: "-8px",
              right: "-8px",
              backgroundColor: "white",
              padding: "4px",
              width: "32px",
              height: "32px",
              borderRadius: "9999px",
              color: "green",
              boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
            }} /> : <CancelIcon sx={{
              position: 'absolute',
              top: "-8px",
              right: "-8px",
              backgroundColor: "white",
              padding: "4px",
              borderRadius: "9999px",
              width: "32px",
              height: "32px",
              color: "#E46962",
              boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
            }} />
          )
        }
      </Tooltip>
    </div >
  );
};

export default DeviceCard;
