import { Skeleton } from "@chakra-ui/react";

export const AlertCountGridSkeleton = () => {
  return (
    <div className="flex flex-col gap-0 ">
      <div className="flex flex-row justify-between items-center h-[35px] px-2 overflow-auto">
        <Skeleton
          height="15px"
          width={"200px"}
        />
      </div>
      <div className="flex flex-row justify-between items-center h-[35px] px-2 overflow-auto">
        <Skeleton
          height="15px"
          width={"200px"}
        />
      </div>

      <div
        className="w-full px-2 overflow-x-scroll xl:overflow-x-hidden py-1"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(22,1fr)",
          gap: 8,
        }}
      >
        {Array.from({ length: 109 }).map((_, index) => (
          <Skeleton
            key={`skeleton-data-${index}`}
            height="50px"
            width="100%"
            minW={"40px"}
            rounded={"md"}
          />
        ))}
      </div>
    </div>
  );
};
