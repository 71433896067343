import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import TonalButton from "../../../util/Buttons/TonalButton";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import ExlCsvDownload from "../../../util/VisionUtils/ExlCsvDownload";
import { CloseIcon } from "@chakra-ui/icons";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";

const AddBulkUsersModal = ({
  isOpen,
  onClose,
  size,
  setSend,
  send,
  postBulkUpload,
  data,
  order,
  mode,
  headertext,
  buttontext,
}) => {
  const [submitted, setSubmitted] = useState(false);
  const removeFile = () => {
    const updatedSend = [...send];
    updatedSend.pop(); // Remove the last added file
    setSend(updatedSend);
  };

  // const selectPicture = (event) => {
  //   setSend([...send, event.target.files[0]]);
  //   // console.log("send", send);
  // };
  const [isUploadedFile, setIsUploadedFile] = useState(false);
  const selectPicture = (event) => {
    const selectedFile = event.target.files[0];

    // Check if the file is already in the list, if yes, remove it
    // const updatedSend = send.filter((file) => file.name !== selectedFile.name);

    // Add the selected file to the list
    // setSend([...updatedSend, selectedFile]);
    setSend([selectedFile]);
    if (send?.length > 0) {
      setIsUploadedFile(true);
    }
  };
  const removeUploadedFile = (index) => {
    const updatedSend = [...send];
    updatedSend.splice(index, 1); // Remove the file at the specified index
    setSend(updatedSend);
  };
  useEffect(() => {
    setSubmitted(false);
  }, [onClose, selectPicture]);

  const handlePost = () => {
    postBulkUpload();
    setSend([]);
    onClose();
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={size}>
        <ModalOverlay />
        <ModalContent>
          <div className="text-white w-full h-16 flex bg-[#2660B6] font-semibold justify-center items-center rounded-t-md">
            Bulk Add {headertext}
          </div>
          <ModalCloseButton className="mt-2" color={"white"} />
          <ModalBody>
            {submitted === false ? (
              <div className="p-6">
                <div className="">
                  <div className="flex flex-col sm:flex-row sm:justify-between items-center ">
                    <div className="">
                      <p className="text-14px] font-medium">Step 1:</p>
                      <p className="text-14px] font-normal text-[#605D64]">
                        Please download the following excel and fill out the
                        user details
                      </p>
                    </div>
                    <div>
                      <ExlCsvDownload
                        data={data}
                        order={order}
                        orderDetail={order}
                        enable={false}
                      />
                    </div>
                  </div>
                  <div className="flex justify-between items-center mt-6">
                    <div className="">
                      <p className="text-14px] font-medium">Step 2:</p>
                      <p className="text-14px] font-normal text-[#605D64]">
                        Please upload the file with user details
                      </p>
                    </div>
                  </div>
                </div>
                {send?.length > 0 ? (
                  <div className="flex mt-5">
                    <div className="flex flex-col sm:flex-row items-center ">
                      <div>
                        <CheckCircleIcon
                          sx={{ color: "#6ab04b", fontSize: "34px" }}
                        />
                      </div>
                      <div className="flex items-center h-full  justify-center">
                        {send &&
                          send.length > 0 &&
                          send.map((item, index) => (
                            <div
                              key={index}
                              className="flex items-center gap-2 cursor-pointer mr-3 ml-2"
                            >
                              {/* <img src="/pdf.svg" alt="pdf" /> */}
                              <p className="font-light text-[#AEA9B1] mb-3 mt-3">
                                {item.name}
                              </p>
                              <p className=" ml-1 text-sm">
                                <CloseIcon
                                  color={"#79767D"}
                                  onClick={() => removeUploadedFile(index)}
                                />
                              </p>
                              {/* <p className="ml-2">successfully uploaded</p> */}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="mt-6 border-4 border-[#FFC107] border-dotted flex bg-[#FFFFED] px-2 sm:px-6 py-3">
                      {send && send.length > 0 ? (
                        send.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className="flex items-center gap-2 cursor-pointer mr-3"
                            >
                              {/* <img src="/pdf.svg" alt="pdf" /> */}
                              <p className="font-light text-[#AEA9B1] mb-3">
                                {item.name}
                              </p>
                              {/* <p className="mb-3">
                            <CloseIcon onClick={() => removeFile(index)} />
                          </p> */}
                            </div>
                          );
                        })
                      ) : (
                        <div className="mr-1 sm:mr-4">
                          <UploadFileOutlinedIcon
                            sx={{
                              color: "#ffc008",
                              fontSize: "40px",
                            }}
                          />
                        </div>
                      )}

                      <div className="">
                        <div className="flex ">
                          <label htmlFor="image" className="cursor-pointer">
                            <div className="flex justify-evenly">
                              <p className="text-[14px] text-[#447ED4] font-medium">
                                Choose file
                              </p>
                            </div>
                          </label>
                          <input
                            onChange={(e) => selectPicture(e)}
                            type="file"
                            name="image"
                            id="image"
                            style={{ display: "none" }}
                          />
                          <p className="ml-2 text-[14px] font-medium">
                            to upload
                          </p>
                        </div>
                        <p className="text-[13px] text-[#79767D] font-medium">
                          Supported formats: .xlsx , .csv
                        </p>
                      </div>
                    </div>
                  </>
                )}

                <div className="flex justify-start mt-6">
                  <div className="flex gap-3">
                    <PrimaryButton
                      text={buttontext}
                      width="80px"
                      onClick={handlePost}
                      disable={send && send.length > 0 ? false : true}
                    />
                    {/* <TonalButton
                      text={"Discard"}
                      width="80px"
                      onClick={() => removeFile()}
                      disable={""}
                    /> */}
                  </div>
                </div>

                {/* <div className="border border-gray-100 rounded-xl mt-3">
                  <TableContainer className="!text-left  border rounded-md bg-white">
                    <Table variant="simple">
                      <Thead className="bg-[#EBEBEB]">
                        <Tr>
                          <Th px={2}>NAME</Th>
                          <Th px={2}>EMAIL ID</Th>
                          <Th px={2}>ROLE</Th>
                          <Th px={2}>DESIGNATION</Th>
                          <Th px={2}>
                            <div>
                              <p>PRIMARY</p>
                              <p>LOCATION</p>
                            </div>
                          </Th>
                          <Th px={2}>
                            <div>
                              <p>PHONE NUMBER</p>
                              <p>(optional)</p>
                            </div>
                          </Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        <Tr>
                          <Td px={2}>Ashok Kumar</Td>
                          <Td px={2}>ashok123@gmail.com</Td>
                          <Td px={2}>Admin</Td>
                          <Td px={2}>CXO</Td>
                          <Td px={2}>Pune</Td>
                          <Td px={2}>--</Td>
                        </Tr>
                        <Tr>
                          <Td px={2}>John Doe</Td>
                          <Td px={2}>john.doe@gmail.com</Td>
                          <Td px={2}>Regular</Td>
                          <Td px={2}>Manager</Td>
                          <Td px={2} width={"20px"}>
                            Pune
                          </Td>
                          <Td px={2}>9876354xxx</Td>
                        </Tr>
                        <Tr>
                          <Td px={2}>Jane Smith</Td>
                          <Td px={2}>jane.smith@gmail.com</Td>
                          <Td px={2}>Admin</Td>
                          <Td px={2}>Sr. Manager</Td>
                          <Td px={2} width={"20px"}>
                            Pune
                          </Td>
                          <Td px={2}>9876354xxx</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                  </TableContainer>
                </div> */}
              </div>
            ) : (
              <div>
                <div className="mt-5 w-full flex justify-center">
                  <img src="addusecase_submitted.svg" alt="submitted" />
                </div>
                <div className="mt-5 w-full flex justify-center font-bold text-[#034D86] text-lg">
                  Submitted
                </div>
              </div>
            )}
          </ModalBody>
          {/* <ModalFooter>
            {submitted === false ? (
              <button
                onClick={() => setSubmitted(true)}
                className="bg-[#084298] text-white px-7 py-2 rounded-md mb-5 "
                mr={3}
              >
                Submit
              </button>
            ) : (
              <button
                onClick={onClose}
                className="bg-[#084298] text-white px-7 py-2 rounded-md mb-5 "
                mr={3}
              >
                Close
              </button>
            )}
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  );
};

export { AddBulkUsersModal };
