

const Serverdown = () => {



      return (
        <div className="w-full h-[100px] mt-[50px] bg-red-100 rounded-md flex items-center justify-center text-[20px] font-bold">
         Server Feed Down
        </div>
     
      )
}
  
  
 

 


export default Serverdown;