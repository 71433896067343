const SubscriptionHistoryCards = ({
  allSubcriptions,
  noOfActiveSubs,
  noOfInActiveSubs,
  modifiedSubs,
}) => {
  return (
    <div className="flex lg:items-center lg:w-[70%] gap-3">
      <div className="flex flex-col lg:flex-row lg:items-center gap-3">
        <div className="flex shadow-md rounded-lg">
          <div className="w-[10px] border rounded-l-lg bg-[#6CA6FC]"></div>
          <div className="p-2">
            <p className="text-[#3E3C42] font-medium text-[20px]">
              {allSubcriptions || 0}
            </p>
            <p className="text-[#605D64] text-[12px] sm:text-[14px]">
              All subscriptions
            </p>
          </div>
        </div>
        <div className="flex shadow-md rounded-lg">
          <div className="w-[10px] border rounded-l-lg bg-[#7AC958]"></div>
          <div className="p-2">
            <p className="text-[#3E3C42] font-medium text-[20px]">
              {noOfActiveSubs || 0}
            </p>
            <p className="text-[#605D64] text-[12px] sm:text-[14px]">
              Active subscriptions
            </p>
          </div>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row lg:items-center gap-3">
        {/* <div className="flex shadow-md rounded-lg">
          <div className="w-[10px] border rounded-l-lg bg-[#FFC107]"></div>
          <div className="p-2">
            <p className="text-[#3E3C42] font-medium text-[20px]">
              {modifiedSubs || 0}
            </p>
            <p className="text-[#605D64] text-[12px] sm:text-[14px]">
              Inactive subscriptions
            </p>
          </div>
        </div> */}
        <div className="flex shadow-md rounded-lg">
          <div className="w-[10px] border rounded-l-lg bg-[#938F96]"></div>
          <div className="p-2">
            <p className="text-[#3E3C42] font-medium text-[20px]">
              {noOfInActiveSubs}
            </p>
            <p className="text-[#605D64] text-[12px] sm:text-[14px]">
              Inactive subscriptions
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionHistoryCards;
