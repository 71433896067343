import { useEffect, useState } from "react";
import { CustomStyledDataGrid } from "../../../util/MaterialDataGrid/CustomStyledDatagrid";
import { useGridApiRef } from "@mui/x-data-grid";
import { useWindowSize } from "@uidotdev/usehooks";
import { generateDynamicColumns } from "../../Sizing/SizingComponents/dataGridColumns";


const CrusherCombinationTable = ({
  rowData = [],
  order = [],
  valueOptionsMapping,
}) => {
  const size = useWindowSize();
  const gridApiRef = useGridApiRef();
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  useEffect(() => {
    const dynamicTableColumns = async () => {
      const columnsToUSe = await generateDynamicColumns(
        order,
        true,
        true,
        false
      );
      let col = [
        {
          field: "id",
          headerName: "Sr No.",
        },
      ];
      if (columnsToUSe && columnsToUSe.length > 0) {
        columnsToUSe.map((ele, i) => {
          col.push(ele);
        });
      }
      setColumns(col);
    };

    dynamicTableColumns();
  }, [order]);
  useEffect(() => {
    setRows((prev) => {
      let newData = rowData?.map((item, idx) => {
        let cameraValue = "";
        const cameraMatch = valueOptionsMapping?.optionsMapping?.cameraId?.find(
          (cam) => cam?.value === item?.camera
        );
        if (cameraMatch) {
          cameraValue = cameraMatch?.name;
        }
        if (!item?.hasOwnProperty("id")) {
          return {
            ...item,
            id: idx,
            camera: cameraValue,
          };
        } else {
          // If 'id' already exists, do nothing
          return {
            ...item,
            camera: cameraValue,
          };
        }
      });
      return newData;
    });
  }, [rowData]);

  return (
    <div
      className=" w-full"
      style={{
        height: 150 + Math.min(5, rows?.length || 1) * 50 + "px",
      }}
    >
      <CustomStyledDataGrid
        apiRef={gridApiRef}
        rows={rows}
        // getRowId={(row) => row?.id}
        columns={columns}
        columnVisibilityModel={{
          id: false,
        }}
        disableColumnSelector={true}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
        }}
        pagination
        pageSizeOptions={[5, 10, 25]}
        sx={{
          "& .MuiDataGrid-columnHeader": {
            border: "0.9px solid #e0e0e0",
            textTransform: "none",
            fontSize: size.width < 768 ? "13px" : "14px",
          },
        }}
      />
    </div>
  );
};

export default CrusherCombinationTable;
