import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { forwardRef, useEffect, useRef, useState } from "react";
import { createTheme, ThemeProvider, Tooltip } from "@mui/material";
import { Flex, IconButton, Image } from "@chakra-ui/react";
import { CustomStyledDataGrid } from "../../../util/MaterialDataGrid/CustomStyledDatagrid";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import LoopIcon from "@mui/icons-material/Loop";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  gridPaginatedVisibleSortedGridRowEntriesSelector,
  useGridApiRef,
} from "@mui/x-data-grid";
import { capitalizeFirstLetter } from "../../../util/sentenceCase";
import { useWindowSize } from "@uidotdev/usehooks";
import { generateDynamicColumns } from "../utils/dataGridColumns";
import { ThumbDown, ThumbUp } from "@mui/icons-material";

const getImage = (reason) => {
  if (reason === 0) {
    return "/SizingIcons/sizing.svg";
  } else if (reason === 2) {
    return "/SizingIcons/sizing.svg";
  } else if (reason === 1) {
    return "/SizingIcons/sizing.svg";
  }
};

const getReason = (reason) => {
  if (reason === 0) {
    return "Size";
  } else if (reason === 2) {
    return "Size";
  } else if (reason === 1) {
    return "Size";
  }
};

const MuiTheme = createTheme();

const ActionButtons = ({ actionUpdate }) => {
  const [actionPerRow, setActionPerRow] = useState(actionUpdate);
  return (
    <div className="flex w-full gap-2 justify-center">
      {actionPerRow.actionTaken === false ? (
        <div className="w-full flex justify-center gap-6">
          <div
            className={`bg-transparent rounded-md cursor-pointer transform hover:scale-110 transition-transform text-blue-800
          
          `}
            fontSize={"20px"}
            onClick={() => {
              setActionPerRow((prevState) => ({
                ...prevState,
                actionStatus: true,
                actionTaken: true,
              }));
            }}
          >
            <img src="/actionTick.svg" alt="yes" />
          </div>

          <div
            className="bg-transparent rounded-md  cursor-pointer transform hover:scale-110 transition-transform text-[red]"
            fontSize={"20px"}
            onClick={() => {
              setActionPerRow((prevState) => ({
                ...prevState,
                actionStatus: false,
                actionTaken: true,
              }));
            }}
          >
            <img src="/actionCross.svg" alt="yes" />
          </div>
        </div>
      ) : (
        <div className="w-full flex justify-center gap-6">
          {actionPerRow.actionStatus === true ? (
            <div
              className={` ${
                actionPerRow.actionStatus === true
                  ? "cursor-not-allowed  transform  transition-transform text-blue-800"
                  : "cursor-not-allowed text-grey-600"
              } bg-transparent rounded-md 
  
  `}
              fontSize={"20px"}
            >
              <img src="/actionTick.svg" alt="yes" />
            </div>
          ) : (
            <div
              className={`${
                actionPerRow.actionStatus === false
                  ? "cursor-not-allowed  transform  transition-transform text-[red]"
                  : "cursor-not-allowed text-grey-600"
              }
            bg-transparent rounded-md  `}
              fontSize={"20px"}
            >
              <img src="/actionCross.svg" alt="yes" />
            </div>
          )}
          <div
            className="text-blue-600 bg-transparent rounded-md cursor-pointer transform hover:scale-110 transition-transform"
            onClick={() => {
              setActionPerRow((prevState) => ({
                ...prevState,
                actionTaken: false,
              }));
            }}
          >
            <LoopIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export const stringDecimalFix = (str) => {
  const regex = /-?\d+\.\d+/;
  const match = str.match(regex);
  if (match) {
    const floatingNumber = parseFloat(match[0]);
    const fixedNumber = floatingNumber.toFixed(2);
    return str.replace(regex, fixedNumber);
  }
  return str;
};

const getAdditionalColumns = (additionalCols) => {
  let newColumns = [];

  Object.keys(additionalCols).forEach((key) => {
    additionalCols[key].forEach((subKey) => {
      newColumns.push({
        field: `${key}_${subKey}`,
        headerName: `${subKey}`,
        minWidth: 75,
        valueGetter: (params) => {
          const value = params?.row?.pulpParamOutput?.[key]?.[subKey];
          return value || "no data";
        },
        // renderHeader: () => (
        //   <div style={{ border: "2px solid blue" }}>{subKey}</div>
        // ),
      });
    });
  });

  return newColumns;
};

const additionnalColumnsForGrid = [
  {
    groupId: "Kappa",
    headerAlign: "center",
    children: [
      {
        field: "kappa_current",
        headerAlign: "center",
      },
      { field: "kappa_expected", headerAlign: "center" },
    ],
  },
  {
    groupId: "Pentosan",
    headerAlign: "center",
    children: [
      { field: "pentosan_current", headerAlign: "center" },
      { field: "pentosan_expected", headerAlign: "center" },
    ],
  },
  {
    groupId: "Viscosity",
    headerAlign: "center",
    children: [
      { field: "viscosity_current", headerAlign: "center" },
      { field: "viscosity_expected", headerAlign: "center" },
    ],
  },
];

const CommonTable = ({
  rowData,
  order,
  showViewDetail,
  showFeedBack,
  setDisplayData,
  setOpenModal,
  indexRef,
  additionalCols,
  tableHeight,
  rowHeight,
  pagesize,
  hideFooter,
  minWidthMap,
  getRowClassName,
  filterData,
  setFilterModel,
  setopenFeedback,
  setFeedBackData,
}) => {
  const size = useWindowSize();
  const gridApiRef = useGridApiRef();
  const [columns, setColumns] = useState([]);

  if (rowData?.some((item) => item.hasOwnProperty("pulpParamOutput"))) {
    const additionalColumns = getAdditionalColumns(additionalCols);
    columns.splice(6, 0, ...additionalColumns);
  }

  const [rows, setRows] = useState([]);
  const headerClass = "text-md font-medium text-[#525056] ";
  const cellClass = "text-sm font-medium text-[#3E3C42]  p-4";

  columns.map((val, idx) => {
    val["headerClassName"] = headerClass;
    val["cellClassName"] = cellClass;

    return val;
  });

  const handleViewDetails = (params) => {
    const tempDisplayData =
      gridPaginatedVisibleSortedGridRowEntriesSelector(gridApiRef);
    const updatedData = tempDisplayData.map((el, id) => el.model);
    setDisplayData((prev) => updatedData);

    const index = params.api.getRowIndexRelativeToVisibleRows(params.row.id);
    indexRef.current = index;
    setOpenModal(true);
  };

  const handleThumbsUpClick = (id) => {
    setFeedBackData((prev) => ({ ...prev, id: id, feedbackStatus: "GOOD" }));
    setopenFeedback((prev) => true);
  };
  const handleThumbsDownClick = (id) => {
    setFeedBackData((prev) => ({ ...prev, id: id, feedbackStatus: "BAD" }));
    setopenFeedback((prev) => true);
  };
  useEffect(() => {
    const dynamicTableColumns = async () => {
      const columnsToUSe = await generateDynamicColumns(
        order,
        true,
        true,
        false,
        null,
        minWidthMap
      );
      let col = [
        {
          field: "id",
          headerName: "Sr No.",
          filterable: false,
        },
      ];
      if (columnsToUSe && columnsToUSe.length > 0) {
        columnsToUSe.map((ele, i) => {
          col.push(ele);
        });
      }
      showViewDetail &&
        order.length > 0 &&
        col.push({
          field: "viewDetails",
          type: "actions",
          headerName: "View details",

          minWidth: 115,
          type: "actions",
          headerAlign: "center",
          renderCell: (params) => (
            <button
              onClick={() => handleViewDetails(params)}
              className="cursor-pointer w-full text-[#084298] font-semibold text-normal hover:scale-105 flex justify-center"
            >
              <VisibilityIcon />
            </button>
          ),
        });

      showFeedBack &&
        order.length > 0 &&
        col.push({
          field: "feedback",
          type: "actions",
          headerName: "Feedback",

          minWidth: 115,
          type: "actions",
          headerAlign: "center",
          renderCell: (params) => (
            <div className="w-full flex flex-row items-center justify-between px-5">
              <IconButton
                size={"xs"}
                padding={0}
                icon={<ThumbUp sx={{ fontSize: "18px", color: "#024d87" }} />}
                onClick={() => handleThumbsUpClick(params.row.id)}
              />
              <IconButton
                size={"xs"}
                padding={0}
                icon={<ThumbDown sx={{ fontSize: "18px", color: "#024d87" }} />}
                onClick={() => handleThumbsDownClick(params.row.id)}
              />
            </div>
          ),
        });

      //   col.push({
      //     field: "actionUpdate",
      //     headerName: "Action taken",
      //     type: "actions",
      //     headerAlign: "center",
      //     renderCell: ({ row }) => (
      //       <ActionButtons actionUpdate={row.actionUpdate} />
      //     ),
      //     minWidth: 120,
      //   });
      setColumns(col);
    };

    dynamicTableColumns();
  }, [order]);
  useEffect(() => {
    setRows((prev) => {
      let newData = rowData.map((item, idx) => {
        if (!item.hasOwnProperty("id")) {
          return {
            ...item,
            id: idx,
          };
        } else {
          // If 'id' already exists, do nothing
          return {
            ...item,
          };
        }
      });
      return newData;
    });
  }, [rowData]);
  console.log("rows", rows);
  return (
    <div
      className={`w-full border rounded-md `}
      style={{
        height: tableHeight ? tableHeight : "65vh",
        maxHeight: tableHeight ? tableHeight : "65vh",
      }}
    >
      <CustomStyledDataGrid
        apiRef={gridApiRef}
        rows={rows}
        columns={columns}
        columnVisibilityModel={{
          id: false,
        }}
        columnHeaderHeight={65}
        showColumnVerticalBorder={true}
        rowHeight={rowHeight ?? 60}
        disableColumnSelector={true}
        initialState={{
          pagination: {
            paginationModel: { pageSize: pagesize ? pagesize : 5 },
          },
          sorting: {
            sortModel: [{ field: "timestamp", sort: "desc" }],
          },
        }}
        pagination
        filterModel={filterData}
        {...(setFilterModel
          ? {
              onFilterModelChange: (newFilterModel) =>
                setFilterModel(newFilterModel),
            }
          : {})}
        hideFooter={hideFooter ? hideFooter : false}
        getRowClassName={getRowClassName}
        pageSizeOptions={[pagesize ? pagesize : 5, 15, 25]}
        sx={{
          "& .MuiDataGrid-columnHeader": {
            border: "0.9px solid #e0e0e0",
            textTransform: "none",
            fontSize: size.width < 768 ? "13px" : "14px",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "normal",
            lineHeight: "normal",
          },
        }}
        columnGroupingModel={additionnalColumnsForGrid}
        experimentalFeatures={{ columnGrouping: true }}
        slotProps={{
          panel: {
            sx: {
              maxWidth: "98vw",
              alignSelf: "center",
              ".css-154sxbz-MuiPaper-root-MuiDataGrid-paper": {
                minWidth: 0,
              },
              // margin: 2,
            },
          },
          filterPanel: {
            filterFormProps: {
              // input box for operator selection(for multi-columns => and, or)
              logicOperatorInputProps: {
                sx: { display: "none" },
              },
              // input box for column selection
              columnInputProps: {
                variant: "outlined",
                size: "small",
              },
              // input box for operator selection(for a particular columns => contains, start with, is empty ....)
              operatorInputProps: {
                sx: { display: "none" },
              },
              // input box to write the value on the basis of which data get's filtered
              valueInputProps: {
                InputComponentProps: {
                  variant: "outlined",
                  size: "small",
                },
              },
              // delete icon
              deleteIconProps: {
                sx: {
                  "& .MuiSvgIcon-root": { color: "#D32F2F" },
                },
              },
            },
            sx: {
              overflowX: "auto",
              "& .MuiDataGrid-filterForm": { p: 2 },
              "& .MuiDataGrid-filterFormLogicOperatorInput": {
                mr: 2,
                minWidth: 80,
              },
              "& .MuiDataGrid-filterFormColumnInput": {
                mr: 2,
                minWidth: 150,
              },
              "& .MuiDataGrid-filterFormValueInput": { minWidth: 150 },
            },
          },
        }}
      />
    </div>
  );
};
export default CommonTable;
