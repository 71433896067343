import React from "react";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';


const ViewProductGroup = ({
  selectedProductGroup,
  setShowProductTab,
}) => {

  console.log(selectedProductGroup);

  const handleBackButton = () => {
    // setShowAddNewProductCategoryForm(false);
    setShowProductTab((prev) => "products");
  };


  return (
    <div className="font-roboto flex flex-col gap-2 mt-0">
      <div className="flex items-center">
        <div className="cursor-pointer w-8" onClick={handleBackButton}>
          <KeyboardBackspaceIcon sx={{ color: "#084298" }} />
        </div>
        <p className="text-[#084298] font-medium text-xl ml-2">
          View product group
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-6">
        <p className="text-[#3E3C42] text-lg font-medium">
          Parent product group<span className="text-red-500">*</span>
        </p>
        <p className="text-[#525056] text-sm font-medium">{selectedProductGroup?.productGroupParent}</p>

      </div>

      <hr className="mt-3"></hr>

      <div className="flex flex-col gap-3 mt-3">
        <p className="text-[#3E3C42] text-lg font-medium">
          Product group name<span className="text-red-500">*</span>
        </p>
        <div className="flex flex-row items-center md:w-[400px]">
          {selectedProductGroup?.productGroupUrl ? <img className="w-[40px] h-[40px] mr-2" src={selectedProductGroup?.productGroupUrl} /> : <img className="w-[40px] h-[40px] mr-2" src="/ProductGroupDefaultIcon.svg" />}

          <p className="text-[#525056] text-sm font-medium">{selectedProductGroup?.productGroupName}</p>
        </div>
      </div>

      <hr className="mt-3"></hr>

      <div className="flex items-center gap-4 mt-3">
        <div>
          <p className="text-[#3E3C42] text-lg font-medium">
            Description
          </p>
          <div className="mt-2 md:w-[400px]">
            <p className="text-[#525056] text-sm font-medium">{selectedProductGroup?.productGroupDescription}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewProductGroup;
