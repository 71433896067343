import React from 'react'
import PieChart from '../../../Charts/MulpicCharts/PieChart';
import LineTrendChart from '../../../Charts/MulpicCharts/LineTrendChart';
import StackBarChart from '../../../Charts/MulpicCharts/StackBarChart';


const Production = ({data}) => {
    return (
        <div className="bg-white rounded-xl flex flex-col w-full h-full overflow-x-auto" id="#production">
            <div className="flex flex-row gap-4 py-[8px] bg-white rounded-xl justify-between items-center px-3">
                <p className="rounded-e-xl rounded-s-xl text-xl font-medium">
                    Production Analysis
                </p>
            </div>
            <div className="flex flex-col md:flex-row py-[2px] bg-white justify-between items-center px-3 w-full overflow-x-auto overflow-y-hidden h-full min-h-[320px]">
                <PieChart labels={["In Range", "Low", "High"]} series={data?.pieData} />
                <LineTrendChart series={data?.lineData?.data} times={data?.lineData?.timeStamps} />
            </div>
            <div className='py-[2px] bg-white px-3 w-full overflow-x-auto min-h-[300px]'>
                <StackBarChart data={data?.stackBarData} type={"data"} labels={["In Range", "Low", "High"]} />
            </div>
        </div>
    )
}

export default Production