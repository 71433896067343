import {
  Flex,
  Heading,
  Button,
  FormControl,
  VStack,
  Box,
  Img,
  Spinner,
} from "@chakra-ui/react";
import { useEffect, useState, useContext, useRef } from "react";
import { baseURL } from "../..";
import { useWindowSize } from "@uidotdev/usehooks";
import { Link } from "react-router-dom";

const UserProfile = () => {
  //Update states
  const imageRef = useRef();
  const [fullName, setFullName] = useState();
  const [designation, setDesignation] = useState();
  const [userid, setUserId] = useState();
  const [baseLocation, setBaseLocation] = useState();
  const [email, setEmail] = useState();
  const [imageUrl, setImageUrl] = useState("");
  const [sendImage, setSendImage] = useState();
  const [tokenBalance, setTokenBalance] = useState();
  const [org, setOrg] = useState("");
  const [validationErrors, setValidationErrors] = useState({
    fullname: "",
    baseLocation: "",
  });

  //Spinner State
  const [spinner, setSpinner] = useState(false);
  const [success, setSuccess] = useState(false);

  const size = useWindowSize();

  useEffect(() => {
    getProducts();
    getTokenDetails();
  }, []);

  const isValidName = (name) => {
    // Regular expression for a simple name validation
    const nameRegex = /^[a-zA-Z ]{2,60}$/;
    return nameRegex.test(name);
  };

  const getProducts = async () => {
    try {
      const data = await fetch(baseURL + "user", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": localStorage.getItem("auth_token"),
        },
      });
      const res = await data.json();

      setImageUrl(`${res?.data?.imageurl}?${Date.now()}`);
      setFullName(res?.data?.fullname);
      setDesignation(res?.data?.designation);
      setUserId(res?.data?.userid);
      setBaseLocation(res?.data?.baseLocation);
      setEmail(res?.data?.email);
      // setImageUrl(res?.data?.imageurl);
      setOrg(res?.data?.organisation);
    } catch (e) {
      console.log(e);
    }
  };

  // console.log(userid);

  const getTokenDetails = async () => {
    try {
      const data = await fetch(baseURL + "token-wallet/v1/balance", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": localStorage.getItem("auth_token"),
        },
      });
      const res = await data.json();
      //console.log(res);
      //console.log(data);
      setTokenBalance(res?.User.balance);
    } catch (e) {
      console.log(e);
    }
  };

  const updateProfile = async () => {
    const newValidationErrors = {};

    if (!fullName || !isValidName(fullName)) {
      newValidationErrors.fullname = "Please enter a valid name";
    } else if (fullName.length < 2 || fullName.length > 50) {
      if (fullName.length < 2) {
        newValidationErrors.fullname = "Minimum 2 characters required";
      } else if (fullName.length > 50) {
        newValidationErrors.fullname = `You have exceeded the character limit of 50 by ${
          fullName.length - 50
        }`;
      }
    }

    if (baseLocation.length > 50) {
      newValidationErrors.baseLocation = `You have exceeded the character limit of 50 by ${
        baseLocation.length - 50
      }`;
    }

    setValidationErrors(newValidationErrors);
    if (Object.keys(newValidationErrors).length > 0) {
      return;
    }

    setSpinner(true);
    var myHeaders = new Headers();
    myHeaders.append("X-Auth-Token", localStorage.getItem("auth_token"));
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      fullname: fullName,
      designation: designation,
      // organistaion: "",
      // department: department,
      userid: userid,
      baseLocation: baseLocation,
    });
    var requestOptions = {
      credentials: "same-origin",
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    const data = await fetch(`${baseURL}iam/users`, requestOptions);
    await imageUpload();
    const res = await data.json();
    setSpinner(false);
    setSuccess(true);
    setTimeout(() => {
      getProducts();
    }, 2000);
    //await getProducts()
  };

  const imageUpload = async () => {
    const data = new FormData();
    data.append("image", imageRef.current.files[0]);
    const call = await fetch(`${baseURL}user/uploadimage`, {
      method: "POST",
      headers: {
        "X-Auth-Token": localStorage.getItem("auth_token"),
      },
      body: data,
    });
    const res = await call.json();
    await getProducts();
  };

  const selectPicture = (event) => {
    var profile = event.target.files[0];
    setSendImage(profile);
    setImageUrl(URL.createObjectURL(profile));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value.length > 50) {
      setValidationErrors((prev) => ({
        ...prev,
        [name]: `You have exceeded the character limit of 50 by ${
          value.length - 50
        }`,
      }));
    } else {
      setValidationErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }
  };

  useEffect(() => {
    if (success) {
      setTimeout(() => {
        setSuccess(false);
      }, 2000);
    }
  }, [success]);
  return (
    <>
      <Flex
        padding={"5px"}
        marginTop={"3vh"}
        className="shadow-lg mt-4 border rounded-xl"
        bg="#FFFFFF"
      >
        {/* left container */}
        {size.width > 420 ? (
          <VStack
            height={"80vh"}
            color={"white"}
            justifyContent={"flex-start"}
            width={"35%"}
            backgroundColor={"#034D87"}
            gap={"100px"}
            alignItems={"flex-start"}
            borderRadius={"5px"}
            px={"25px"}
            py={"40px"}
          >
            <Heading fontSize={"30px"} fontWeight={"semibold"}>
              Profile Management
            </Heading>
            <Box position={"absolute"} top={"30%"} left={"15%"}>
              <Img src="/profile_management.svg" alt="profile" />
            </Box>
          </VStack>
        ) : null}

        {/* right container */}
        <Flex
          width={size.width > 420 ? "65%" : "100%"}
          padding={"30px"}
          paddingTop={"40px"}
          gap={"60px"}
          flexDirection={"column"}
        >
          <div className="w-full flex justify-center">
            <img
              className={imageUrl !== null ? "h-44" : null}
              src={
                imageUrl ? imageUrl : "/profile_sample.svg"
                // : `${imageUrl}?${Date.now()}`
              }
              alt=""
            />
            <span className="bg-[#034D87] h-10 w-10 rounded-full absolute mt-32 ml-28 flex justify-center items-center cursor-pointer">
              <img
                className="absolute cursor-pointer"
                src="pencil.svg"
                alt="pencil"
              />
              <input
                ref={imageRef}
                className="opacity-0 cursor-pointer"
                type="file"
                onChange={(e) => selectPicture(e)}
              />
            </span>
          </div>
          <Flex flexDirection={"column"} gap={"30px"}>
            <Flex gap={"35px"}>
              <FormControl>
                <div>
                  <div
                    style={{ zIndex: "100px" }}
                    className="text-[#084298] text-xs ml-2 absolute -mt-2 bg-white px-1 flex justify-center"
                  >
                    Full Name
                  </div>
                  <div
                    style={{ zIndex: "10px" }}
                    className="px-2 py-2 w-full rounded-md border border-[#084298] h-14 flex items-center"
                  >
                    <input
                      value={fullName}
                      name="fullname"
                      placeholder={"Enter Full Name"}
                      onChange={(e) => {
                        setFullName(e.target.value);
                        handleChange(e);
                      }}
                      className="w-full focus:outline-none pl-2 "
                    />
                  </div>
                </div>
                {validationErrors?.fullname && (
                  <p className="!mb-1 !text-[red] !text-sm">
                    {validationErrors?.fullname}
                  </p>
                )}
              </FormControl>
              {/* <FormControl>
                <div>
                  <div
                    style={{ zIndex: "100px" }}
                    className="text-[#084298] text-xs ml-2 absolute -mt-2 bg-white px-1 flex justify-center"
                  >
                   
                    Designation
                  </div>
                  <div
                    style={{ zIndex: "10px" }}
                    className="px-2 py-2 w-full rounded-md border border-[#084298] h-14 flex items-center"
                  >
                    <input
                      value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                      className="w-full focus:outline-none pl-2"
                      placeholder="Enter Your Job Title"
                    />
                  </div>
                </div>
              </FormControl> */}
              <FormControl className="">
                <div
                  style={{ zIndex: "100px" }}
                  className="text-[#084298] text-xs ml-2 absolute -mt-2 bg-white px-1 flex justify-center"
                >
                  Designation
                </div>
                <select
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                  style={{ zIndex: "10px" }}
                  className="px-2 py-2 w-full rounded-md border border-[#084298] h-14 flex items-center"
                  // style={{ height: '150px', overflowY: 'auto' }}
                >
                  <option value={""}>Select one</option>

                  <option value={"CXO"}>CXO</option>

                  <option value={"Senior Director"}>Senior Director</option>
                  <option value={"Associate Director"}>
                    Associate Director
                  </option>
                  <option value={"Director"}>Director</option>
                  <option value={"President"}>President</option>
                  <option value={"Senior Vice President"}>
                    Senior Vice President
                  </option>
                  <option value={"Vice President"}>Vice President</option>
                  <option value={"Associate Vice President"}>
                    Associate Vice President
                  </option>
                  <option value={"Senior Partner"}>Senior Partner</option>

                  <option value={"Associate Partner"}>Associate Partner</option>
                  <option value={"Partner"}>Partner</option>
                  <option value={"Manager"}>Manager</option>
                  <option value={"Senior Manager"}>Senior Manager</option>
                  <option value={"Plant Head"}>Plant Head</option>
                  <option value={"Shift Manager"}>Shift Manager</option>
                  <option value={"Plant Operator"}>Plant Operator</option>
                  <option value={"Business Analyst"}>Business Analyst</option>
                  <option value={"Consultant"}>Consultant</option>
                  <option value={"Corporate Staff"}>Corporate Staff</option>
                  <option value={"IT Analyst"}>IT Analyst</option>
                  <option value={"IT Developer"}>IT Developer</option>
                  <option value={"IT Manager"}>IT Manager</option>
                  <option value={"Plant Manager"}>Plant Manager</option>
                  <option value={"Mill Operator"}>Mill Operator</option>
                  <option value={"Automation Team"}>Automation Team</option>
                  <option value={"Automation Staff"}>Automation Staff</option>
                  <option value={"Equipment Operator"}>
                    Equipment Operator
                  </option>
                  <option value={"Device Operator"}>Device Operator</option>
                  <option value={"Other"}>Other</option>
                </select>
                {/* <Input placeholder="Enter Your Name" /> */}
              </FormControl>
            </Flex>
            <Flex gap={"35px"}>
              <FormControl>
                <div>
                  <div
                    style={{ zIndex: "100px" }}
                    className="text-[#084298] text-xs ml-2 absolute -mt-2 bg-white px-1 flex justify-center"
                  >
                    {/* Department */}
                    Organisation
                  </div>
                  <div
                    style={{ zIndex: "10px" }}
                    className="px-2 py-2 w-full rounded-md border border-[#084298] h-14 flex items-center bg-gray-100"
                  >
                    <input
                      value={org}
                      disabled
                      className="w-full focus:outline-none pl-2"
                      placeholder="Enter Your Department"
                    />
                  </div>
                </div>
              </FormControl>
              <FormControl>
                <div>
                  <div
                    style={{ zIndex: "100px" }}
                    className="text-[#084298] text-xs ml-2 absolute -mt-2 bg-white px-1 flex justify-center"
                  >
                    Base Location
                  </div>
                  <div
                    style={{ zIndex: "10px" }}
                    className="px-2 py-2 w-full rounded-md border border-[#084298] h-14 flex items-center"
                  >
                    <input
                      value={baseLocation}
                      name="baseLocation"
                      onChange={(e) => {
                        setBaseLocation(e.target.value);
                        handleChange(e);
                      }}
                      className="w-full focus:outline-none pl-2"
                      placeholder="Enter Your Base Location"
                    />
                  </div>
                </div>
                {validationErrors?.baseLocation && (
                  <p className="!mb-1 !text-[red] !text-sm">
                    {validationErrors?.baseLocation}
                  </p>
                )}
              </FormControl>
            </Flex>
            <div className="grid grid-cols-2 gap-9">
              <FormControl>
                <div>
                  <div
                    style={{ zIndex: "100px" }}
                    className="text-[#084298] text-xs ml-2 absolute -mt-2 bg-white px-1 flex justify-center"
                  >
                    Email
                  </div>
                  <div
                    style={{ zIndex: "10px" }}
                    className="px-2 py-2 w-full rounded-md border border-[#084298] h-14 flex items-center bg-gray-100"
                  >
                    <input
                      disabled
                      value={email}
                      className="w-full focus:outline-none pl-2"
                      placeholder="abc@email.com"
                    />
                  </div>
                </div>
              </FormControl>
              <FormControl>
                <div>
                  <div
                    style={{ zIndex: "100px" }}
                    className="text-[#084298] text-xs ml-2 absolute -mt-2 bg-white px-1 flex justify-center"
                  >
                    Ripik Token Balance
                  </div>
                  <div
                    style={{ zIndex: "10px" }}
                    className="px-2 py-2 w-full rounded-md border border-[#084298] h-14 flex items-center"
                  >
                    <div className="w-full focus:outline-none pl-2">
                      {tokenBalance}
                    </div>
                    <Link to={`/community/advisor/buycredits/user/foruser`}>
                      <div className="bg-[#034D87] shadow-md text-white cursor-pointer px-2 py-2 rounded-md md:w-32 flex justify-center">
                        <p className="text-xs">Buy Tokens</p>
                      </div>
                    </Link>
                  </div>
                </div>
              </FormControl>
            </div>
          </Flex>
          <Flex className="-mt-10" justifyContent={"flex-end"}>
            {success === false ? (
              <Button
                bg={"#034D87"}
                _hover={{ bg: "#034D87" }}
                onClick={updateProfile}
                color={"white"}
                borderRadius={"md"}
                width={"150px"}
              >
                {spinner === false ? <p>Save Changes</p> : <Spinner />}
              </Button>
            ) : (
              <Button
                bg={"green"}
                _hover={{ bg: "green" }}
                color={"white"}
                borderRadius={"md"}
                width={"150px"}
              >
                Success
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  );
};
export default UserProfile;
