import { CalF1 } from "../../pages/DetailView";

const RunDetails = ({ modelInfo }) => {
  return (
    <div className="flex gap-0 border border-[#EBEBEB] rounded-md shadow-md h-[180px] min-w-full sm:min-w-[435px]">
      <img src="/selfServiceIcons/bulb.svg" alt="bulb" className="h-full" />
      <div className="py-4 px-5 flex gap-9 items-center whitespace-nowrap overflow-x-auto max-w-[250px] min-[400px]:max-w-full">
        <div className="flex flex-col gap-2">
          <img
            src="/selfServiceIcons/file.svg"
            alt="file"
            className="w-[58px] h-[40px]"
          />
          <div className="flex flex-col gap-0">
            <p className="text-[#79767D] text-sm">Version no.</p>
            <p className="text-[#525056] text-2xl font-medium">
              {modelInfo?.version}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <img
            src="/selfServiceIcons/clock.svg"
            alt="file"
            className="w-[40px] h-[40px]"
          />
          <div className="flex flex-col gap-0">
            <p className="text-[#79767D] text-sm">Runtime</p>
            <p className="text-[#525056] text-2xl font-medium">
              {modelInfo?.runTime?.COMPLETED
                ? (
                    (modelInfo?.runTime?.COMPLETED -
                      modelInfo?.runTime?.RUNNING) /
                    60000
                  )?.toFixed(1) + " min"
                : "N/A"}
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <img
            src="/selfServiceIcons/brain.svg"
            alt="file"
            className="w-[35px] h-[40px]"
          />
          <div className="flex flex-col gap-0">
            <p className="text-[#79767D] text-sm">Model Confidence</p>
            <p className="text-[#525056] text-2xl font-medium">
              {CalF1(
                modelInfo?.performance?.precision,
                modelInfo?.performance?.recall
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RunDetails;
