import { Tooltip } from "@chakra-ui/react";

const StatusTooltip = ({ data, bay = "B2", section = "S2" }) => {
  return (
    <div
      className="rounded-md whitespace-nowrap py-2 px-4 flex flex-col gap-3 absolute top-0 left-[-230px] bg-white w-[215px] h-auto leading-normal"
      style={{
        boxShadow:
          "0px 4px 4px 0px rgba(226, 240, 220, 0.51), 4px 0px 4px 0px rgba(226, 240, 220, 0.51)",
        zIndex: 1000,
      }}
    >
      <div className="flex justify-start items-center relative">
        <div className="flex gap-2 items-center justify-center text-sm">
          <Tooltip
            label={bay[1]}
            hasArrow
            placement="top"
            isOpen={bay[1]?.length > 5}
          >
            <p className="font-medium text-[#3E3C42] ">
              Bay {bay[1]?.slice(0, 5)}
            </p>
          </Tooltip>
          <Tooltip
            label={section[1]}
            hasArrow
            placement="right"
            isOpen={section[1]?.length > 5}
          >
            <p className="text-[#3E3C42]">Section {section[1]?.slice(0, 5)}</p>
          </Tooltip>
        </div>
        {/* <img src="/BlendComplianceIcons/videoCam.svg" alt="no support" /> */}
        <img
          src="/BlendComplianceIcons/tooltipArrow.svg"
          alt="no support"
          className="absolute right-[-40px] transform scale-x-[-1]"
        />
      </div>
      <div className="flex flex-col gap-[6px]">
        <div className="flex gap-2 items-center">
          <p className="text-[#938F96] text-sm w-[80px] text-start">
            Concentrate
          </p>
          <Tooltip
            label={data?.concentrate}
            hasArrow
            isOpen={data?.concentrate?.length >= 12}
            placement="right"
          >
            <p className="text-[#2660B6] text-sm font-medium text-ellipsis overflow-clip">
              {data?.concentrate}
            </p>
          </Tooltip>
        </div>
        <div className="flex gap-2 items-center">
          <p className="text-[#938F96] text-sm w-[80px] text-start">Amount</p>
          <p className="text-[#2660B6] text-sm font-medium">
            {data?.current} MT
          </p>
        </div>
      </div>
      <div className="flex gap-[2px] ">
        <p className="text-[#938F96] text-xs">Last updated</p>
        <p className="text-[#938F96] text-xs">{data?.lastUpdated}</p>
      </div>
    </div>
  );
};

export default StatusTooltip;
