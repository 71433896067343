import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";

const ConfirmationModal = ({
  isOpen,
  onClose,
  size,
  submitted,
  setSubmitted,
  buttonText,
  onConfirm,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={size}
    >
      <ModalOverlay />
      <ModalContent>
        <div className="text-white w-full h-16 flex bg-[#034D86] font-semibold justify-center items-center rounded-t-md">
          Are you sure you want to delete ?{" "}
        </div>
        <ModalCloseButton
          className="mt-2"
          color={"white"}
        />
        <ModalFooter>
          <div className="flex gap-[10px] items-center mt-2">
            <Button
              onClick={onClose}
              colorScheme="gray"
              variant="outline"
            >
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={async () => {
                await onConfirm();
                onClose();
              }}
            >
              {"Delete"}
            </Button>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmationModal;
