import { Skeleton } from "@chakra-ui/react";
import { formatDateTime } from "../../utils/formatDateTime";

const PurchaseOrder = ({ feedFor, name, purchaseDetail, home }) => {
  return (
    <div className="flex flex-col  gap-[20px] w-full ">
      {home && (
        <div className="  flex p-0">
          <p
            className="text-[#3E3C42] text-xl font-semibold flex p-0 "
            style={{ lineHeight: "1" }}
          >
            {name ? name : <Skeleton height="23px" width="300px" />}
          </p>
        </div>
      )}
      <div className="flex justify-start w-full ">
        <div
          className={`flex w-full sm:flex-row flex-col gap-3  border-[1px] px-2 xs:px-6 py-2 border-[#EBEBEB] shadow-md rounded-lg bg-[#FAFAFA] ${
            home ? "" : ""
          } justify-between`}
        >
          <div className="flex xs:flex-row flex-col gap-2 items-center w-full">
            <p className="text-[#525056] text-sm md:text-base font-semibold whitespace-nowrap w-full xs:w-fit">
              Process Order
            </p>
            <p className=" text-sm md:text-base text-[#605D64] w-full ">
              {purchaseDetail?.purchaseOrderNo ? (
                purchaseDetail?.purchaseOrderNo
              ) : (
                <Skeleton height="25px" width="150px" />
              )}
            </p>
          </div>
          <div className="w-full  flex xs:flex-row flex-col gap-2  items-center ">
            <p className="text-[#525056] text-sm md:text-base font-semibold whitespace-nowrap w-full xs:w-fit">
              Start time
            </p>
            <p className=" text-sm md:text-base text-[#605D64] w-full">
              {purchaseDetail?.startTime ? (
                formatDateTime(purchaseDetail?.startTime)
              ) : (
                <Skeleton height="15px" width="70px" />
              )}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseOrder;
