import { Spinner } from "@chakra-ui/react";
import { useState } from "react";
import AlertCard from "../Alerts/components/AlertCard";
import AlertGroupCard from "../Alerts/components/AlertGroupCard";
import AddIcon from '@mui/icons-material/Add';

const ManageAlerts = ({
  setShowAlertsTab,
  alertType,
  setSelectedAlert,
  alerts,
  getAlertApi,
  getAlertGroupApi,
  alertGroups,
}) => {
  const [loading, setLoading] = useState(false);

  const handleAddAlert = () => {
    setShowAlertsTab((prev) => "addNewAlert");
  };

  const handleAddAlertGroup = () => {
    setShowAlertsTab((prev) => "addNewAlertGroup");
  };

  const handleViewDevice = () => {
    // setShowAlertsTab((prev) => "viewDevice");
  };

  const handleUpdateDevice = () => {
    // setShowAlertsTab((prev) => "updateDevice");
  };

  return (
    <div className="flex rounded-lg bg-[#fafafa] p-4">
      {alertType == "Groups" && (
        <div className="grid grid-cols-1 min-[900px]:grid-cols-2 xl:grid-cols-5 min-[2200px]:grid-cols-6 w-full gap-4 h-fit">
          <div
            className="rounded p-3 w-full flex flex-col justify-center gap-3 items-center bg-white h-[250px]"
            style={{
              boxShadow:
                "-4px -4px 24px 0px rgba(0, 0, 0, 0.07), 4px 4px 24px 0px rgba(0, 0, 0, 0.07)",
            }}
          >
            <div
              className="p-[10px] bg-[#CDEEBF] text-center rounded cursor-pointer hover:scale-105"
              onClick={handleAddAlertGroup}
            >
              <img src="/superadmin/addNewProduct.svg" alt="add" />
            </div>

            <p className="font-bold text-[#3E3C42]">Add New Alert Group</p>
          </div>
          {loading ? (
            <div className="flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            alertGroups &&
            alertGroups?.length != 0 &&
            alertGroups?.map((items) => {
              return (
                <AlertGroupCard
                  key={items?.alertGroupId}
                  data={items}
                  setSelectedAlert={setSelectedAlert}
                  setShowAlertsTab={setShowAlertsTab}
                  getAlertGroupApi={getAlertGroupApi}
                />
              );
            })
          )}
        </div>
      )}
      {alertType == "Alerts" && (
        <div className="grid grid-cols-1 min-[560px]:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 min-[2200px]:grid-cols-6 w-full gap-4 h-fit">
          <div
            className="rounded p-3 w-full flex flex-col justify-center gap-3 items-center bg-white h-[250px]"
            style={{
              boxShadow:
                "-4px -4px 24px 0px rgba(0, 0, 0, 0.07), 4px 4px 24px 0px rgba(0, 0, 0, 0.07)",
            }}
          >
            <div
              className="p-[10px] bg-[#DEF] text-center rounded cursor-pointer hover:scale-105 "
              onClick={handleAddAlert}
            >
              <AddIcon sx={{color: "#3A74CA"}} />
            </div>

            <p className="font-bold">Add New Alert</p>
          </div>
          {loading ? (
            <div className="flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            alerts &&
            alerts?.length != 0 &&
            alerts?.map((items) => {
              return (
                <AlertCard
                  data={items}
                  setSelectedAlert={setSelectedAlert}
                  setShowAlertsTab={setShowAlertsTab}
                  getAlertApi={getAlertApi}
                  getAlertGroupApi={getAlertGroupApi}
                />
              );
            })
          )}
        </div>
      )}
    </div>
  );
};

export default ManageAlerts;
