import GaugeChart from "react-gauge-chart";
import { indexWordMap } from "../../SinterFlame/Sinterflame";

let indexToPercent = {
  1: 0.15,
  2: 0.5,
  3: 0.85,
};

const GaugeMeter = ({ index, accuracy }) => {
  return (
    <div className="flex flex-col gap-2 justify-between w-full h-full">
      <div className="flex flex-col gap-1 w-full">
        <GaugeChart
          nrOfLevels={3}
          colors={["#CADEFD", "#85E45F", "#F9DEDC"]}
          needleColor="#79767D"
          needleBaseColor="#79767D"
          arcWidth={1}
          arcPadding={0}
          arcsLength={[0.3, 0.3, 0.3]}
          marginInPercent={0}
          cornerRadius={0}
          percent={indexToPercent[index]}
          hideText={true}
        />
        <p className="self-center text-[#938F96] text-xs uppercase">
          {indexWordMap[index]}
        </p>
      </div>
      <div className="flex flex-row items-center gap-1 self-start mt-2">
        <p className="text-xs text-[#938F96]">Confidence</p>
        <p className="text-sm text-[#69B04B]">{accuracy + "%"}</p>
      </div>
    </div>
  );
};

export default GaugeMeter;
