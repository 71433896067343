import { useState, useEffect, useContext } from "react";
import { createContext } from "react";
import NavContext from "../../NavContext";
import { useParams } from "react-router-dom";
import { MoodBad } from "@mui/icons-material";
import { getInitialDataApi } from "../services/flarestack.api";

export const FlareStackContext = createContext({
  initialData: null,
});

export const FlareStackContextProvider = ({ children }) => {
  const [initialData, setInitialData] = useState(null);
  const { auth } = useContext(NavContext);
  const { clientId } = useParams();

  const fetchData = async () => {
    try {
      const requestData = JSON.stringify({
        clientId: clientId,
        useCase: "flarestack",
      });
      const response = await getInitialDataApi(auth, requestData);
      setInitialData((prev) => response);
    } catch (error) {
      console.log(error);
      setInitialData((prev) => null);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FlareStackContext.Provider
      value={{
        initialData,
      }}
    >
      {initialData ? (
        children
      ) : (
        <div className="flex flex-row justify-center items-center border rounded-md w-full h-full min-h-[30vh]">
          <div className="flex flex-row items-center gap-2 text-gray-400">
            <MoodBad fontSize={"large"} />
            <h2 className="text-2xl">No Data!</h2>
          </div>
        </div>
      )}
    </FlareStackContext.Provider>
  );
};
