import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Radio,
  RadioGroup,
  useToast,
  ChakraProvider,
  Input,
} from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import TonalButton from "../../../util/Buttons/TonalButton";
import axios from "axios";
import NavContext from "../../NavContext";
import { baseURL } from "../../..";
import CommonProgressBar from "./CommonProgressBar";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import TextButton from "../../../util/Buttons/TextButton";

export const startTrainApi = async (
  toast,
  auth,
  projectId,
  userData,
  extramParams = {}
) => {
  try {
    const param = {
      projectId: projectId,
      ...extramParams,
    };
    const response = await axios.post(
      baseURL + "selfserve/v1/project/v1/training/start/",
      null,
      {
        params: param,
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    if (response.status == 200) {
      let eta = response.data?.etaTotalSeconds;
      toast({
        title: "Success",
        description: (
          <div>
            <p>Training started for </p>
            <p>Old :{Object.entries(userData?.uploadedFiles || {})?.length}</p>
            {eta && eta > 1 && <p>ETA: {(eta / 60).toFixed(1)} min</p>}
          </div>
        ),
        status: "success",
        position: "top-right",
        duration: 5000,
        isClosable: true,
      });
      setTimeout(() => window.location.reload(), 2000);
    }
  } catch (error) {
    console.log(error);
    toast({
      title: "Error",
      description: "Start train api failed",
      status: "error",
      position: "top-right",
      duration: 2000,
      isClosable: true,
    });
  }
};

const ReRunModal = ({ openModal, closeModal, userData }) => {
  const toast = useToast();
  const { auth } = useContext(NavContext);
  const fileInputRef = useRef();
  const folderInputRef = useRef();
  const [progressState, setProgressState] = useState({
    open: false,
    state: null,
  });
  const format = {
    Image: ["jpg", "jpeg", "png"],
    Video: ["mp4"],
  };
  const rtspRef = useRef();
  const options = ["Image", "Video"];
  const [videoUploaded, setVideoUploaded] = useState(false);
  const [selectedDataType, setSelectedDataType] = useState("Image");

  const handleFileChange = async (event) => {
    const selectedFile = Array.from(event.target.files);
    if (selectedFile) {
      const acceptedTypes = format[selectedDataType].map((ext) => {
        return selectedDataType.toLowerCase() + "/" + ext;
      });
      let goodToGo = true;
      if (selectedDataType == "Image") {
        selectedFile.map((file) => {
          if (
            !acceptedTypes.includes(file.type) ||
            file.size > 2 * 1024 * 1024
          ) {
            toast({
              title: "Error",
              description: (
                <div>
                  <p>{`Image name: ${file.name}`}</p>
                  <p>{`Accepted formats: [${acceptedTypes.join(", ")}]`}</p>
                  <p>{`Uploaded file: ${file.type}`}</p>
                  <p>{`Max size: 2MB, Uploaded size: ${(
                    file.size /
                    (1024 * 1024)
                  ).toFixed(1)}MB`}</p>
                </div>
              ),
              status: "error",
              position: "top-right",
              duration: 6000,
              isClosable: true,
            });
            goodToGo = false;
            return;
          }
        });
      } else if (selectedDataType == "Video") {
        if (selectedFile.length > 1) {
          toast({
            title: "Error",
            description: "Please upload 1 video file",
            status: "error",
            position: "top-right",
            duration: 6000,
            isClosable: true,
          });
          goodToGo = false;
          return;
        }
        await Promise.all(
          selectedFile.map(async (file) => {
            if (file.type.startsWith("video/")) {
              const video = document.createElement("video");
              video.preload = "metadata";
              const loadMetadata = new Promise((resolve, reject) => {
                video.onloadedmetadata = resolve;
                video.onerror = reject;
              });
              video.src = URL.createObjectURL(file);

              try {
                await loadMetadata;
                window.URL.revokeObjectURL(video.src);

                if (
                  video.duration > 15 * 60 ||
                  video.videoWidth > 1080 ||
                  video.videoHeight > 720 ||
                  file.size > 200 * 1024 * 1024
                ) {
                  toast({
                    title: "Error",
                    description: (
                      <div>
                        <p>{`Image name: ${file.name}`}</p>
                        {video.duration > 15 * 60 && (
                          <p>{`Video duration ${video.duration} exceeds the allowed duration of 15 mins`}</p>
                        )}
                        {(video.videoWidth > 1080 ||
                          video.videoHeight > 720) && (
                          <p>{`Video dimension ${
                            video.videoWidth + "x" + video.videoHeight
                          } exceeds the required dimensions of 1080x720`}</p>
                        )}
                        {file.size > 200 * 1024 * 1024 && (
                          <p>{`Uploaded size: ${(
                            file.size /
                            (1024 * 1024)
                          ).toFixed(1)}MB exceeds the max size of 200MB`}</p>
                        )}
                      </div>
                    ),
                    status: "error",
                    position: "top-right",
                    duration: 6000,
                    isClosable: true,
                  });
                  goodToGo = false;
                  return;
                }
              } catch (error) {
                console.error("Error loading video metadata:", error);
                goodToGo = false;
                return;
              }
            } else {
              toast({
                title: "Error",
                description: (
                  <div>
                    <p>{`File name: ${file.name}`}</p>
                    <p>{`Accepted formats: [${acceptedTypes.join(", ")}]`}</p>
                    <p>{`Uploaded file: ${file.type}`}</p>
                  </div>
                ),
                status: "error",
                position: "top-right",
                duration: 6000,
                isClosable: true,
              });
              goodToGo = false;
              return;
            }
          })
        );
      }
      if (goodToGo) {
        if (selectedDataType == "Image") UploadFiles(selectedFile);
        else if (selectedDataType == "Video") uploadVideoApi(selectedFile);
      } else {
        fileInputRef.current.value = null;
        folderInputRef.current.value = null;
      }
    }
  };

  const videoInfoReturn = async (file) => {
    const video = document.createElement("video");
    video.preload = "metadata";
    const loadMetadata = new Promise((resolve, reject) => {
      video.onloadedmetadata = resolve;
      video.onerror = reject;
    });
    video.src = URL.createObjectURL(file);

    try {
      await loadMetadata;
      window.URL.revokeObjectURL(video.src);
      return video;
    } catch (error) {
      console.error("Error loading video metadata:", error);
      return {};
    }
  };

  const AnnotateApi = async (data) => {
    try {
      const param = {
        projectId: userData?.projectId,
      };
      let predictionData;
      predictionData = data.map((item) => {
        return {
          fileId: item[0],
          fileUrl: item[1],
        };
      });
      const requestBody = JSON.stringify({
        predictionDataset: predictionData,
      });
      const response = await axios.post(
        baseURL + `selfserve/v1/project/v1/annotations/update/`,
        requestBody,
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.status == 200) {
        toast({
          title: "Success",
          description: "Annotated data received",
          status: "success",
          position: "top-right",
          duration: 2000,
          isClosable: true,
        });
        startTrainApi(toast, auth, userData?.projectId, userData, {
          trainingId: userData?.trainingId,
          toTrain: false,
          toPredict: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Error",
        description: "Set annotate api failed",
        status: "error",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const UploadFiles = async (data) => {
    setProgressState((prev) => ({ ...prev, open: true, state: 1 }));
    const promise = new Promise(async (resolve, reject) => {
      let chunkSize = 90;
      const totalChunks = Math.ceil(data.length / chunkSize);
      let completedChunks = 0;
      for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSize;
        const end = (i + 1) * chunkSize;
        const filesChunk = data.slice(start, end);
        let requestData = new FormData();
        filesChunk.map((x) => {
          requestData.append("files", x);
        });
        let param =
          completedChunks === 0
            ? {
                projectId: userData.projectId,
                partitionType: "REPREDICT",
              }
            : {
                projectId: userData.projectId,
              };
        await axios
          .post(baseURL + "selfserve/v1/project/v1/file/upload/", requestData, {
            params: param,
            headers: {
              "Content-Type": "multipart/form-data",
              "X-Auth-Token": auth,
            },
          })
          .then((response) => {
            completedChunks++;
            if (completedChunks == totalChunks) {
              resolve(200);
              setProgressState((prev) => ({ ...prev, state: 2 }));
              AnnotateApi(Object.entries(response?.data?.dataset || {}));
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
            setProgressState((prev) => ({ ...prev, state: 0 }));
          });
      }
    });
  };

  const LinkRtsp = async () => {
    let regex = /(rtsp):\/\/([^\s@/]+)([^\s/:]+)(?::([0-9]+))?(\/.*)/;
    if (!regex.test(rtspRef?.current?.value)) {
      toast({
        title: "Error",
        description: "Invalid rtsp link",
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    try {
      const requestData = JSON.stringify({
        link: rtspRef?.current?.value,
        linkType: "RTSP",
        config: {
          trainCaptureSeconds: 0,
          predictCaptureSeconds: 0,
          targetFps: 1,
        },
      });
      const param = {
        projectId: userData?.projectId,
        partitionType: "REPREDICT",
      };
      const response = await axios.post(
        baseURL + "selfserve/v1/project/v1/feed/upload/",
        requestData,
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response?.status == 200) {
        setVideoUploaded(true);
      }
    } catch (error) {
      console.log(error);
      setProgressState((prev) => ({ ...prev, state: 0 }));
    }
  };

  const getVideoFrames = async (rtspPollTimer) => {
    const param = {
      projectId: userData?.projectId,
    };
    try {
      const response = await axios.get(
        baseURL + "selfserve/v1/project/v1/video/status/",
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.status == 200) {
        let data = response?.data?.videoDataset;
        let startTrain = Object.keys(data)?.some((item) => {
          return (
            data[item]?.frameProcessStatus === "COMPLETED" &&
            data[item]?.videoDatasetType === "PREDICT"
          );
        });
        let failTrain = Object.keys(data)?.some((item) => {
          return (
            data[item]?.frameProcessStatus === "FAILED" &&
            data[item]?.videoDatasetType === "PREDICT"
          );
        });
        if (startTrain) {
          if (rtspPollTimer) clearInterval(rtspPollTimer);
          startTrainApi(toast, auth, userData?.projectId, userData, {
            trainingId: userData?.trainingId,
            toTrain: false,
            toPredict: true,
          });
          setProgressState((prev) => ({ ...prev, state: 2 }));
        } else if (failTrain) {
          if (rtspPollTimer) clearInterval(rtspPollTimer);
          setProgressState((prev) => ({ ...prev, state: 0 }));
        }
      }
    } catch (error) {
      console.log(error);
      if (rtspPollTimer) clearInterval(rtspPollTimer);
      setProgressState((prev) => ({ ...prev, state: 0 }));
    }
  };

  const handleRtspLink = () => {
    setProgressState((prev) => ({ ...prev, open: true, state: 1 }));
    LinkRtsp();
  };

  const uploadVideoApi = async (selectedFiles) => {
    setProgressState((prev) => ({ ...prev, open: true, state: 1 }));
    const totalChunks = selectedFiles.length;
    let completedChunks = 0;
    for (let i = 0; i < totalChunks; i++) {
      const videoFile = selectedFiles[i];
      const val = await videoInfoReturn(videoFile);
      let param = {
        projectId: userData.projectId,
        length: val?.duration,
        dimension: `${val?.videoWidth}x${val?.videoHeight}`,
        codec: "h.264",
        fps: 30,
        sizeBytes: videoFile?.size,
      };
      param["videoDatasetType"] = "PREDICT";
      param["startFraming"] = i == totalChunks - 1 ? true : false;
      param["partitionType"] = "REPREDICT";
      let requestData = new FormData();
      requestData.append("files", videoFile);
      await axios
        .post(baseURL + "selfserve/v1/project/v1/video/upload/", requestData, {
          params: param,
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Auth-Token": auth,
          },
        })
        .then((response) => {
          completedChunks++;
          if (completedChunks == totalChunks) {
            setVideoUploaded(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setProgressState((prev) => ({ ...prev, state: 0 }));
        });
    }
  };

  useEffect(() => {
    if (videoUploaded) {
      let rtspPollTimer = setInterval(() => {
        getVideoFrames(rtspPollTimer);
      }, 20 * 1000);
    }
  }, [videoUploaded]);

  return (
    <ChakraProvider>
      <Modal
        closeOnOverlayClick={false}
        isOpen={openModal}
        onClose={closeModal}
        isCentered="true"
      >
        <ModalOverlay />
        <ModalContent style={{ borderRadius: "8px" }} maxW="360px">
          <ModalHeader
            py={"8px"}
            px={"16px"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            borderBottom={"1px solid #EBE2F0"}
          >
            <p className="text-[#605D64] text-sm font-medium">ReRun model</p>
          </ModalHeader>
          <ModalBody
            px={"24px"}
            py={"16px"}
            display={"flex"}
            flexDirection={"column"}
            gap={"32px"}
          >
            <div className="flex flex-col gap-3">
              <p className="text-[#3E3C42] text-base font-medium">
                ReRun model
              </p>
              <RadioGroup
                onChange={setSelectedDataType}
                value={selectedDataType}
              >
                <div className="flex gap-2 items-center">
                  {options.map((x) => {
                    return (
                      <div
                        style={{
                          backgroundColor:
                            x == selectedDataType ? "#DDEEFF80" : "#FFF",
                          borderRadius: "8px",
                        }}
                        className="py-2 pl-2 pr-3 flex gap-3 items-center capitalize"
                      >
                        <Radio
                          value={x}
                          color={"#3E3C42"}
                          _checked={{
                            bg: "#6CA6FC",
                            borderColor: "#6CA6FC",
                          }}
                          _hover={{
                            borderColor: "#6CA6FC",
                          }}
                        >
                          {x}
                        </Radio>
                      </div>
                    );
                  })}
                </div>
              </RadioGroup>
              <input
                ref={fileInputRef}
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
                multiple
              />
              <input
                ref={folderInputRef}
                type="file"
                style={{ display: "none" }}
                onChange={handleFileChange}
                directory=""
                webkitdirectory=""
                multiple
              />
              <div className="flex gap-2 flex-wrap w-full items-center">
                <SecondaryButton
                  Icon={<img src="/selfServiceIcons/upload.svg" alt="upload" />}
                  text={"Upload files"}
                  width={"130px"}
                  onClick={() => {
                    fileInputRef.current.click();
                  }}
                />
                <SecondaryButton
                  Icon={<img src="/selfServiceIcons/upload.svg" alt="upload" />}
                  text={"Upload folder"}
                  width={"130px"}
                  onClick={() => {
                    folderInputRef.current.click();
                  }}
                />
                {selectedDataType === "Video" && (
                  <div className="flex flex-row-reverse gap-0 items-center">
                    <TextButton
                      text={"Link Rtsp"}
                      width={"fit-content"}
                      onClick={handleRtspLink}
                    />
                    <Input
                      ref={rtspRef}
                      width={"fit-content"}
                      placeholder="Enter rtsp"
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <TonalButton
                text={"Cancel"}
                width={"fit-content"}
                onClick={closeModal}
              />
            </div>
          </ModalBody>
        </ModalContent>
        {progressState?.open && progressState?.state && (
          <CommonProgressBar
            openModal={progressState?.open}
            closeModal={() =>
              setProgressState((prev) => ({
                ...prev,
                open: false,
                state: null,
              }))
            }
            state={progressState?.state}
          />
        )}
      </Modal>
    </ChakraProvider>
  );
};

export default ReRunModal;
