import React from "react";
import { useContext, useEffect, useState } from "react";
import NavContext from "../../NavContext";
import axios from "axios";
import { baseURL } from "../../..";
import { Spinner } from "@chakra-ui/react";
//import AlertGroupTable from "../Alerts/components/AlertGroupTable";
import AlertTable from "../Alerts/components/AlertTable";
import AlertGroupTable from "../Alerts/components/AlertGroupTable";


const AlertTablePage = ({
  setShowAlertsTab,
  alertType,
  setSelectedAlert,
  alerts,
  getAlertApi,
  getAlertGroupApi,
  alertGroups,
}) => {
  const { auth } = useContext(NavContext);
  const [loading, setLoading] = useState(false);
  return (
    <div className="p-4 bg-[#FAFAFA]">
      {alertGroups && alertGroups?.length !== 0 && alertType == "Groups" && (
        <AlertGroupTable
          alertGroups={alertGroups}
          setShowAlertsTab={setShowAlertsTab}
          setSelectedAlert={setSelectedAlert}
          getAlertGroupApi={getAlertGroupApi}
        />
      )}
      {alerts && alerts?.length !== 0 && alertType == "Alerts" && (
        <AlertTable
          alerts={alerts}
          setShowAlertsTab={setShowAlertsTab}
          setSelectedAlert={setSelectedAlert}
          getAlertApi={getAlertApi}
        />
      )}
    </div>
  );
};
export default AlertTablePage;