import {
  GridRowEditStopReasons,
  GridFooterContainer,
  GridFooter,
  GridActionsCellItem,
} from "@mui/x-data-grid";
import { CustomStyledDataGrid } from "../../../util/MaterialDataGrid/CustomStyledDatagrid";

import { useCallback, useContext, useEffect, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Input,
  InputBase,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { baseURL } from "../../..";
import NavContext from "../../NavContext";

import { canSelectShift } from "../utils/util";
import { useWindowSize } from "@uidotdev/usehooks";
import { border } from "@chakra-ui/react";
import TonalButton from "../../../util/Buttons/TonalButton";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";

function EditToolbar(props) {
  const {
    gridApiRef,
    setRows,
    setRowIdMapping,
  } = props;
  const addNewRow = async () => {
    const createdAt = new Date().getTime();
    try {
      let id = Math.random() * 1000000;
      setRows((oldRows) => [
        {
          _id: id,
          camera: "",
          createdAt: createdAt,
          timestamp: new Date(),
          shift: "",
          crusherSet1: "",
          directionSet1: "",
          crusherSet2: "",
          directionSet2: "",
          gapSet1: "",
          gapSet2: "",
          createdTime:new Date().getTime(),
          isNew: true,
        },
        ...oldRows,
      ]);
      let allrows = Array?.from(gridApiRef?.current?.getRowModels()?.values());
      gridApiRef?.current?.setRows([
        {
          _id: id,
          camera: "",
          timestamp: new Date(),
          createdAt: createdAt,
          shift: "",
          crusherSet1: "",
          directionSet1: "",
          crusherSet2: "",
          directionSet2: "",
          gapSet1: "",
          gapSet2: "",
          createdTime:new Date().getTime(),
          isNew: true,
        },
        ...allrows,
      ]);

      gridApiRef?.current?.startRowEditMode({ id: id });
      setRowIdMapping((prev) => ({
        ...prev,
        [id]: false,
      }));
    } catch (error) {
      console.log(error);
    }
  };
  const handleClick = () => {
    addNewRow();
  };

  return (
    <GridFooterContainer>
      <Button
        color="primary"
        startIcon={<AddIcon fontSize="small" />}
        onClick={handleClick}
      >
        Add new row
      </Button>
      <GridFooter />
    </GridFooterContainer>
  );
}

function CustomEditComponent(props) {
  const { id, value, field, api } = props;

  const handleValueChange = (event) => {
    const newValue = event.target.value; // The new value entered by the user
    api?.setEditCellValue({ id, field, value: newValue });
  };

  let formattedValue = new Date(
    new Date(value)?.getTime() - new Date().getTimezoneOffset() * 60 * 1000
  )
    .toISOString()
    .slice(0, 10);

  return (
    <input
      type="date"
      value={formattedValue}
      onChange={handleValueChange}
      max={new Date().toISOString().split("T")[0]}
    />
  );
}

function CustomInput(props) {
  const { id, field, value, colDef, hasFocus, api } = props;
  const [valueState, setValueState] = useState(value);

  const handleChange = (event) => {
    const newValue = event.target.value?.trim();

    if (newValue === "" || (Number(newValue) >= 0 && Number(newValue) <= 99)) {
      setValueState(newValue);
      api?.setEditCellValue({ id, field, value: newValue });
    }
  };

  return (
    <input
      id={`${id}-${field}`}
      min="0"
      max="2"
      step="1"
      onKeyDown={(e) => {
        if (e.key === "-" || e.key === "." || e.key === "e") {
          e.preventDefault();
        }
      }}
      style={{ height: "100%", outline: "none", border: "1px solid gray" }}
      value={valueState}
      onChange={handleChange}
    />
  );
}

function DeleteRowActionItem({ deleteRow, ...props }) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <GridActionsCellItem {...props} onClick={() => setOpen(true)} />
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete this row?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ paddingX: 4, paddingBottom: 3 }}>
          <PrimaryButton
            text={"Delete"}
            onClick={() => {
              setOpen(false);
              deleteRow();
            }}
          />
          <TonalButton text={"Cancel"} onClick={() => setOpen(false)} />
        </DialogActions>
      </Dialog>
    </>
  );
}

const CrusherEffectivenessTable = ({
  rowData = [],
  sizes,
  isInEditMode,
  gridApiRef,
  unsavedChangesRef,
  setValidationMessage,
  valueOptionsMapping,
  updateData,
  setIsInEditMode,
  setRowIdMapping,
}) => {

  const { width } = useWindowSize();
  const pinnedColumns =
    width > 1240
      ? {
          left: [
            "camera",
            "timestamp",
            "shift",
            "crusherSet1",
            "directionSet1",
            "gapSet1",
            "crusherSet2",
            "directionSet2",
            "gapSet2",
          ],
          right: ["actions"],
        }
      : { left: ["camera"], right: [] };

  const columns = [
    {
      field: "camera",
      headerName: "belt",
      editable: true,
      minWidth: 100,
      flex: 1,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option?.name,
      valueOptions: ({ row, id }) => {
        return valueOptionsMapping?.optionsMapping?.cameraId?.map(
          (camera) => camera
        );
      },
    },
    {
      field: "createdTime",
      headerName: "",
    },
    {
      field: "timestamp",
      headerName: "Date",
      type: "date",
      minWidth: 110,
      flex: 1,
      editable: true,
      valueGetter: ({ value }) => new Date(value),
      valueFormatter: (params) => {
        return params.value
          ? new Date(params.value).toLocaleDateString("en-GB")
          : "";
      },
      renderEditCell: CustomEditComponent, // render custom date component
    },
    {
      field: "shift",
      headerName: "Shift",
      editable: true,
      minWidth: 60,
      flex: 1,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option?.name,
      valueOptions: (params) => {
        return valueOptionsMapping?.optionsMapping?.Shift?.map((item) => {
          let name = "";
          if (item === "shiftA") {
            name = "A";
          } else if (item === "shiftB") {
            name = "B";
          } else if (item === "shiftC") {
            name = "C";
          }
          return { value: item, name: name };
        });
      },
    },
    {
      field: "crusherSet1",
      headerName: "crushers",
      headerClassName: "!bg-[#F5F5F5]",
      editable: true,
      minWidth: 140,
      flex: 1,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option?.name,
      valueOptions: ({ row, id }) => {
        const options = valueOptionsMapping?.optionsMapping?.[row?.camera]
          ?.filter((item) => item !== row?.crusherSet2)
          .map((item) => ({ value: item, name: item }));

        const cameras = valueOptionsMapping?.optionsMapping?.cameraId?.map(
          (item) => item?.value
        );
        const allOptions = Array.from(
          new Set(
            cameras?.flatMap(
              (camera) => valueOptionsMapping?.optionsMapping?.[camera]
            ) || []
          )
        );
        return options?.length > 0
          ? options
          : allOptions?.map((item) => ({ value: item, name: item }));
      },
    },
    {
      field: "directionSet1",
      headerName: "direction",
      headerClassName: "!bg-[#F5F5F5] !text-wrap",
      editable: true,
      minWidth: 110,
      flex: 1,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option?.name,
      valueOptions: valueOptionsMapping?.optionsMapping?.direction?.map(
        (direction) => ({
          value: direction,
          name: direction,
        })
      ),
    },
    {
      field: "gapSet1",
      headerName: "Gap (in mm)",
      headerClassName: "!bg-[#F5F5F5] !normal-case",
      editable: true,
      minWidth: 90,
      flex: 1,
      type: "string",
      valueGetter: ({ value }) => value,
      renderEditCell: (params) => <CustomInput {...params} />,
    },
    {
      field: "crusherSet2",
      headerName: "crushers",
      editable: true,
      minWidth: 140,
      flex: 1,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option?.name,
      valueOptions: ({ row }) => {
        const options = valueOptionsMapping?.optionsMapping?.[row?.camera]
          ?.filter((item) => item !== row?.crusherSet1)
          ?.map((item) => ({ value: item, name: item }));
        const cameras = valueOptionsMapping?.optionsMapping?.cameraId?.map(
          (item) => item?.value
        );

        const allOptions = Array.from(
          new Set(
            cameras?.flatMap(
              (camera) => valueOptionsMapping?.optionsMapping?.[camera]
            ) || []
          )
        );
        return options?.length > 0
          ? options
          : allOptions?.map((item) => ({ value: item, name: item }));
      },
    },
    {
      field: "directionSet2",
      headerName: "direction",
      editable: true,
      minWidth: 110,
      flex: 1,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option?.name,
      valueOptions: valueOptionsMapping?.optionsMapping?.direction?.map(
        (direction) => ({
          value: direction,
          name: direction,
        })
      ),
    },
    {
      field: "gapSet2",
      headerName: "Gap (in mm)",
      headerClassName: "!normal-case",
      editable: true,
      minWidth: 90,
      flex: 1,
      type: "string",
      valueGetter: ({ value }) => value,
      renderEditCell: (params) => <CustomInput {...params} />,
    },
  ];
  const columnGroupingModel = [
    {
      groupId: "1st Set of crushers",
      headerAlign: "center",
      headerClassName: "!bg-[#F5F5F5] !normal-case",
      children: [
        { field: "crusherSet1" },
        { field: "directionSet1" },
        { field: "gapSet1" },
      ],
    },
    {
      groupId: "2nd Set of crushers",
      headerAlign: "center",
      headerClassName: "!normal-case",
      children: [
        { field: "crusherSet2" },
        { field: "directionSet2" },
        { field: "gapSet2" },
      ],
    },
    {
      groupId: "actions",
      headerName: "",
      headerClassName: "!bg-[#F5F5F5]",
      headerAlign: "center",
      children: [{ field: "actions" }],
    },
  ];

  const [rows, setRows] = useState(rowData);

  sizes?.forEach((item) => {
    columns?.push({
      field: item,
      headerName: item,
      headerClassName: "!bg-[#F5F5F5] !normal-case",
      minWidth: 80,
      flex: 1,
      valueGetter: (params) => {
        const { row } = params;
        const value = row?.keys?.[item];
        return value;
      },
    });
  });

  columns?.push({
    field: "actions",
    type: "actions",
    headerName: "",
    headerClassName: "!bg-[#F5F5F5]",
    minWidth: 50,
    flex: 1,
    cellClassName: "actions",
    getActions: (params) => [
      <DeleteRowActionItem
        label="Delete"
        icon={<img src="/delete.svg" className="w-4 h-4" alt="delete" />}
        deleteRow={deleteRow(params?.id)}
        closeMenuOnClick={false}
      />,
    ],
  });

  const children = sizes?.map((item) => ({ field: item }));

  columnGroupingModel.push({
    groupId: "sizes",
    headerName: "",
    headerClassName: "!bg-[#F5F5F5]",
    headerAlign: "center",
    children: children,
  });

  const deleteRow = useCallback(
    (id) => () => {
      setTimeout(() => {
        setRows((prevRows) => prevRows?.filter((row) => row?._id !== id));
      });

      setRowIdMapping((prev) => {
        let temp = {
          ...prev,
        };
        delete temp[id];
        return temp;
      });

      const updatedRow = gridApiRef?.current?.getRowWithUpdatedValues(id);

      if (updatedRow?.isNew !== true) {
        // only call the API if it is not a new row
        updateData([updatedRow], true);
      }
    },
    [updateData]
  );
  const handleRowEditStop = (params, event) => {
    // persist edit mode when clicking outside of the editing row
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  useEffect(() => {
    const allRowIds = gridApiRef?.current?.getAllRowIds();
    if (isInEditMode) {
      // filter out the newly added rows
      const newRows = allRowIds?.filter((rowId) => {
        const rowData = gridApiRef?.current?.getRow(rowId);
        return rowData?.isNew === true;
      });

      newRows?.forEach((rowId) => {
        // only make the new added rows editable
        const mode = gridApiRef?.current?.getRowMode(rowId);
        if (mode === "view") {
          gridApiRef?.current?.startRowEditMode({ id: rowId });
        }
      });
    } else {
      allRowIds?.forEach((rowId) => {
        const mode = gridApiRef?.current?.getRowMode(rowId);
        if (mode === "edit")
          gridApiRef?.current?.stopRowEditMode({ id: rowId });
      });
    }
  }, [isInEditMode]);

  const processRowUpdate = useCallback((newRow, oldRow) => {
    //check whether all the fields are filled other wise persist the disable state of the submit button
    // exclude below keys from the check
    const keysToIgnore = [
      "mps",
      "0-3.15mm",
      "3.15-5mm",
      "5-10mm",
      "10-15mm",
      "15+mm",
      "isDeleted",
      "material",
      "clientId",
      "startDate",
      "endDate",
      "keys",
      "isNew",
      "createdTime"
    ];
    let id = newRow?._id;
    unsavedChangesRef.current.unsavedRow[id] = newRow;
    if (!unsavedChangesRef?.current?.oldRow[id]) {
      unsavedChangesRef.current.oldRow[id] = oldRow;
    }
    let isGoodToSubmit = true;

    for (let key in newRow) {
      if (!keysToIgnore?.includes(key) && !newRow[key]) {
        isGoodToSubmit = false;
        break;
      }
    }
    

    if (isGoodToSubmit) {
      setRowIdMapping((prev) => {
        let temp = {
          ...prev,
        };
        delete temp[id];
        return temp;
      });
    }else{
      setRowIdMapping((prev) => ({
        ...prev,
        [id]: false,
      }));
    }

    let updatedRow = gridApiRef?.current?.getRowWithUpdatedValues(id);

    if (
      (updatedRow?.crusherSet1?.toLowerCase() === "none" &&
        updatedRow.directionSet1?.toLowerCase() !== "none") ||
      (updatedRow?.crusherSet1?.toLowerCase() !== "none" &&
        updatedRow.directionSet1?.toLowerCase() === "none")
    ) {
      setValidationMessage((prev) => ({
        ...prev,
        message:
          "If crusher is selected as 'none', its direction will be 'none' only. And vice-versa",
      }));
    } else if (
      (updatedRow?.crusherSet2?.toLowerCase() === "none" &&
        updatedRow.directionSet2?.toLowerCase() !== "none") ||
      (updatedRow?.crusherSet2?.toLowerCase() !== "none" &&
        updatedRow.directionSet2?.toLowerCase() === "none")
    ) {
      setValidationMessage((prev) => ({
        ...prev,
        message:
          "If crusher is selected as 'none', its direction will be 'none' only. And vice-versa",
      }));
    }

    if (
      updatedRow?.crusherSet1?.toLowerCase() === "none" &&
      updatedRow.directionSet1?.toLowerCase() === "none" &&
      updatedRow?.gapSet1 != 0
    ) {
      setValidationMessage((prev) => ({
        ...prev,
        gapAndNone: "If crusher is selected as 'none', gap to be set as '0'",
      }));
    } else if (
      updatedRow?.crusherSet2?.toLowerCase() === "none" &&
      updatedRow.directionSet2?.toLowerCase() === "none" &&
      updatedRow?.gapSet2 != 0
    ) {
      setValidationMessage((prev) => ({
        ...prev,
        gapAndNone: "If crusher is selected as 'none', gap to be set as '0'",
      }));
    }

    let isShiftSelectable = canSelectShift(
      updatedRow?.shift,
      updatedRow?.timestamp
    );
    if (!isShiftSelectable) {
      setValidationMessage((prev) => ({
        ...prev,
        shift: "Future shifts cannot be selected",
      }));
    }

    return newRow;
  }, []);

  useEffect(() => {
    setRows(rowData);
  }, [rowData]);

  useEffect(() => {
    let hasNewRow = rows?.some((item) => item?.isNew === true);
    if (hasNewRow !== isInEditMode) {
      setIsInEditMode(hasNewRow);
    }
  }, [rows]);

  return (
    <div className=" w-full">
      <CustomStyledDataGrid
        apiRef={gridApiRef}
        rows={rows}
        getRowId={(row) => row?._id}
        columns={columns}
        columnVisibilityModel={{
          id: false,
          createdTime:false
        }}
        columnHeaderHeight={45}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
          sorting: {
            sortModel: [{ field: 'createdTime', sort: 'desc' }],
          },
        }}
        pinnedColumns={pinnedColumns}
        editMode="row"
        onRowEditStop={handleRowEditStop}
        slots={{
          footer: EditToolbar,
        }}
        processRowUpdate={processRowUpdate}
        onCellDoubleClick={(params, event) => {
          event.defaultMuiPrevented = true;
        }}
        experimentalFeatures={{ columnGrouping: true }}
        {...(columnGroupingModel && {
          columnGroupingModel: columnGroupingModel,
        })}
        slotProps={{
          footer: {
            gridApiRef,
            setRows,
            setRowIdMapping,
          },

          // column menu
          columnMenu: {
            sx: {
              minWidth: 170,
              padding: 0,
              ".MuiTypography-root": {
                fontSize: "13px",
              },
              "& .css-1asgcec-MuiButtonBase-root-MuiMenuItem-root": {
                paddingY: 0,
                minHeight: "35px",
              },
            },
          },
          // the panel which contains filtering menu (top wrapper)
          panel: {
            sx: {
              maxWidth: "85vw",
              ".css-154sxbz-MuiPaper-root-MuiDataGrid-paper": {
                minWidth: 0,
              },
            },
          },
          // actual filter panel in which all elements are stored
          filterPanel: {
            filterFormProps: {
              // input box for operator selection(for multi-columns => and, or)
              logicOperatorInputProps: {
                sx: { display: "none" },
              },
              // input box for column selection
              columnInputProps: {
                variant: "outlined",
                size: "small",
              },
              // input box for operator selection(for a particular columns => contains, start with, is empty ....)
              operatorInputProps: {
                sx: { display: "none" },
              },
              // input box to write the value on the basis of which data get's filtered
              valueInputProps: {
                InputComponentProps: {
                  variant: "outlined",
                  size: "small",
                },
              },
              // delete icon
              deleteIconProps: {
                sx: {
                  "& .MuiSvgIcon-root": { color: "#D32F2F" },
                },
              },
            },
            sx: {
              overflowX: "auto",
              "& .MuiDataGrid-filterForm": { p: 2 },
              "& .MuiDataGrid-filterFormLogicOperatorInput": {
                mr: 2,
                minWidth: 80,
              },
              "& .MuiDataGrid-filterFormColumnInput": {
                mr: 2,
                minWidth: 150,
              },
              "& .MuiDataGrid-filterFormValueInput": { minWidth: 200 },
            },
          },
        }}
        sx={{
          ".MuiSelect-select": { fontSize: 13 },
        }}
        pagination
        pageSizeOptions={[5, 10, 25]}
      />
    </div>
  );
};

export default CrusherEffectivenessTable;
