import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";
// import { Status } from "./Tabs/Status";
// import { Alerts } from "./Tabs/Alerts";
// import { Report } from "./Tabs/Report";
import { ZincPercentInSlag } from "./Tabs/ZincPercentInSlag";
import { mixpanelTrackTabs } from "../../util/mixpanelFunctions";
// import { WhatIfAnalysis } from "./Tabs/WhatIfAnalysis";
// import { SpecificCokeConsumption } from "./Tabs/SpecificCokeConsuption";

/*------------------------ BASE URL FOR ISF OPTIMIZER -----------------------*/

export const isfOptimizerHzlBaseUrl =
  process.env.REACT_APP_ISF_OPTIMIZER_HZL_BASE_URL;

/*------------------------ BASE URL FOR ISF OPTIMIZER -----------------------*/

const IsfOptimizer = () => {
  const size = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlParamTabIndex = !isNaN(searchParams.get("tabIndex"))
    ? Number(searchParams.get("tabIndex"))
    : 0;
  const [plantMap, setPlantMap] = useState(["chanderia"]);
  // handling tab change
  const handleTabsChange = (index) => {
    if (index !== urlParamTabIndex) {
      setSearchParams({ tabIndex: index }, { replace: true });
    }
  };

  // tablist
  const tabs = [
    // {
    //   label: "Status",
    //   index: 0,
    //   component: (
    //     <Status
    //       plantId="All Plants"
    //       plantMap={plantMap}
    //       handleTabsChange={handleTabsChange}
    //     />
    //   ),
    // },
    {
      label: "Zn% In Slag",
      index: 0,
      component: (
        <ZincPercentInSlag
          plantId="All Plants"
          cameraId=""
          disable={false}
          plantMap={plantMap}
          handleTabsChage={handleTabsChange}
        />
      ),
    },
    // {
    //   label: "Specific Coke Consumption",
    //   index: 2,
    //   component: (
    //     <SpecificCokeConsumption
    //       plantId="All Plants"
    //       disable={false}
    //       plantMap={plantMap}
    //       handleTabsChage={handleTabsChange}
    //     />
    //   ),
    // },
    // {
    //   label: "Alerts",
    //   index: 1,
    //   component: (
    //     <Alerts
    //       plantId="All Plants"
    //       disable={false}
    //       plantMap={plantMap}
    //     />
    //   ),
    // },
    // {
    //   label: "Report",
    //   index: 2,
    //   component: (
    //     <Report
    //       plantId="All Plants"
    //       disable={false}
    //       plantMap={plantMap}
    //     />
    //   ),
    // },
    // {
    //   label: "What If Analysis",
    //   index: 3,
    //   component: (
    //     <WhatIfAnalysis
    //       plantId="All Plants"
    //       disable={false}
    //       plantMap={plantMap}
    //     />
    //   ),
    // },
  ];
  useEffect(() => {
    const originalTitle = document.title;
    document.title = "ISF Optimizer | IntelliVerse";
    return () => {
      document.title = originalTitle;
    };
  }, []);
  // use effect to look for change in tab-index
  useEffect(() => {
    handleTabsChange(urlParamTabIndex);
    const tabName = tabs?.find(item => item.index == urlParamTabIndex)
    mixpanelTrackTabs(tabName?.label?.toLowerCase(), "Isfoptimizer");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParamTabIndex]);

  return (
    <div
      className="px-2 md:px-5 font-poppins flex flex-col rounded-lg"
      style={{ width: size.width >= 768 ? "calc(100vw - 168px)" : "100vw" }}
    >
      <div
        className="fixed top-5 right-0 h-[30px] w-[180px] bg-white"
        style={{ zIndex: 1000 }}
      >
        <img
          className="absolute h-[50px] w-[120px] top-[-20px] right-2"
          src="/clientLogos/hzl.png"
          alt="clientLogo"
        />
      </div>
      <div className="flex justify-start gap-2 items-center mb-3 mt-6">
        <Link
          to={`/optimus/isfoptimizer`}
          style={{
            textDecoration: "none",
          }}
        >
          <img
            src="/backtick.svg"
            alt="backtick"
          />
        </Link>
        <p className="text-lg sm:text-2xl font-semibold text-[#024D87]">
          {"ISF Optimizer"}
        </p>
      </div>
      <Tabs
        isLazy={true}
        index={urlParamTabIndex}
        onChange={handleTabsChange}
        paddingRight={{ base: "8px", md: "0px", lg: "0px" }}
      >
        <TabList className="!flex !border-0">
          <div className="flex items-center gap-4 overflow-x-auto h-14 md:h-10">
            {tabs &&
              tabs.map((tab, index) => (
                <Tab
                  key={`${tab.label}-${index}`}
                  minW={"fit-content"}
                  className={`${
                    urlParamTabIndex === tab.index
                      ? "!text-black !text-xs sm:!text-sm !bg-white rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#79767D]"
                      : "!text-xs sm:!text-sm !text-[#938F96] !border-0"
                  }`}
                  onClick={() => handleTabsChange(tab.index)}
                >
                  {tab.label}
                </Tab>
              ))}
          </div>
        </TabList>
        <TabPanels>
          {tabs &&
            tabs.map((tab, index) => (
              <TabPanel
                key={`${tab.label}-${index}`}
                className="!pl-0 !pr-0"
              >
                {tab.component}
              </TabPanel>
            ))}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default IsfOptimizer;
