import axios from "axios";
import { baseURL } from "../../../index";
import NavContext from "../../NavContext";
import { useContext, useEffect, useState } from "react";
import { RadioGroup, Radio } from "@chakra-ui/react";
import AddIcon from "@mui/icons-material/Add";
import CameraCard from "../CamComponents/CameraCard";
import CamAddModal from "../CamComponents/CamAddModal";

const AllCams = ({ cams, setCams }) => {
  const [selectedView, setSelectedView] = useState("All");
  const [addCam, setAddCam] = useState(false);
  const [ViewType, setViewType] = useState("Card");
  const { auth, email } = useContext(NavContext);
  const getAll = async () => {
    try {
      const response = await axios.get(
        baseURL + "selfserve/v1/project/v1/getAll/",
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      setCams((prev) => {
        if (response.data) {
          let newData = response.data.owned || [];
          newData = newData.concat(response.data.shared || []);
          return newData;
        }
        return prev;
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // getAll();
    // const intervalId = setInterval(() => {
    //   getAll();
    // }, 45000);
    // return () => {
    //   clearInterval(intervalId);
    // };
  }, []);
  return (
    <div className="flex flex-col gap-6 w-full px-5 h-[80vh] sm:h-screen">
      <div className="flex flex-col min-[1100px]:flex-row gap-2 items-start min-[1100px]:items-center w-full">
        <p className="text-[#605D64] text-lg font-medium whitespace-nowrap">
          Your list of Data Feed
        </p>
        <div className="flex flex-col min-[1100px]:flex-row w-full justify-between gap-2 items-start min-[1100px]:items-center">
          <RadioGroup
            name="dataType"
            onChange={setSelectedView}
            value={selectedView}
            overflowX={"auto"}
            maxW={"full"}
          >
            <div className="flex gap-1 items-center h-fit">
              {["All", "Owned", "Shared"].map((x) => {
                return (
                  <div
                    style={{
                      backgroundColor: x == selectedView ? "#DDEEFF80" : "",
                      borderRadius: "8px",
                    }}
                  >
                    <Radio
                      value={x}
                      py={"8px"}
                      pl={"8px"}
                      pr={"12px"}
                      fontSize={"14px"}
                      fontWeight={500}
                      color={"#3E3C42"}
                      _checked={{
                        bg: "#6CA6FC",
                        borderColor: "#6CA6FC",
                      }}
                      _hover={{
                        borderColor: "#6CA6FC",
                      }}
                    >
                      {x}
                    </Radio>
                  </div>
                );
              })}
            </div>
          </RadioGroup>
          <div className="flex gap-0">
            {["Card", "Table"].map((i, idx) => {
              return (
                <div
                  className={`py-[6px] px-3 capitalize border text-sm ${
                    idx == 0 ? "rounded-l" : "rounded-r"
                  }`}
                  style={{
                    backgroundColor: ViewType == i ? "#FFFFED" : "white",
                    borderColor: ViewType == i ? "#FFC107" : "#EBEBEB",
                    color: ViewType == i ? "#3E3C42" : "#605D64",
                    fontWeight: ViewType == i ? 500 : 400,
                    cursor: ViewType == i ? "" : "pointer",
                  }}
                  onClick={() => setViewType(i)}
                >
                  <img
                    src={`/${i == "Card" ? "cardIcon" : "tableIcon"}.svg`}
                    alt={i}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {ViewType == "Card" ? (
        <div
          className="bg-white overflow-x-hidden p-2 overflow-y-auto max-h-[50vh] sm:max-h-max grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 min-[1440px]:grid-cols-5 min-[1750px]:grid-cols-6 min-[2150px]:grid-cols-7 gap-6 text-base font-medium text-[#3E3C42]"
          style={{ height: "fit-content" }}
        >
          <div
            className="rounded p-3 w-full h-[210px] flex flex-col justify-center gap-3 items-center bg-white"
            style={{
              boxShadow:
                "-4px -4px 24px 0px rgba(0, 0, 0, 0.07), 4px 4px 24px 0px rgba(0, 0, 0, 0.07)",
            }}
          >
            <div
              className="p-[10px] bg-[#CDEEBF] text-center rounded cursor-pointer hover:scale-105"
              onClick={() => setAddCam(true)}
            >
              <AddIcon sx={{ color: "#69B04B" }} />
            </div>
            Add Data Feed
          </div>
          {cams
            .filter((item) => {
              if (selectedView == "Owned") return item.owner == email;
              else if (selectedView == "Shared") return item.owner != email;
              else return item;
            })
            .map((x) => {
              return <CameraCard data={x} fetchData={getAll} />;
            })}
        </div>
      ) : (
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 min-[1440px]:grid-cols-4 min-[1750px]:grid-cols-5 min-[2150px]:grid-cols-6 gap-6"></div>
      )}
      {addCam && (
        <CamAddModal openModal={addCam} closeModal={() => setAddCam(false)} />
      )}
    </div>
  );
};

export default AllCams;
