import React, { useState, useEffect, useContext } from 'react';
import {
    Input,
    Text,
    Radio,
    RadioGroup,
    useToast,
    Textarea,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from "@chakra-ui/react";

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { ChevronDownIcon } from '@chakra-ui/icons';
import AddIcon from '../Products/Components/AddIcon';
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import TonalButton from "../../../util/Buttons/TonalButton";
import axios from "axios";
import NavContext from "../../NavContext";
import { baseURL } from "../../..";
import DraftModal from '../DraftModal';



const initState = {
    deviceGroupName: "",
    logoType: "Select a pre-loaded logo",
    deviceGroupUrl: "",
    description: "",
}

const AddNewDeviceGroup = ({ setShowDevicesTab, selectedDevice, update = false, view = false }) => {

    //console.log(update);

    const toast = useToast();
    const { auth } = useContext(NavContext);

    const [userForm, setUserForm] = useState(initState);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [logoList, setLogoList] = useState(null);
    const [cardLogo, setCardLogo] = useState(null);
    const [cardLogoPreview, setCardLogoPreview] = useState(null);
    const [review, setReview] = useState(false);
    const [disable, setDisable] = useState(view);
    const [discard, setDiscard] = useState(false);
    const [deviceGroupId, setDeviceGroupId] = useState(null);
    const [showDraft, setShowDraft] = useState(false);
    const [deviceGroups, setDeviceGroups] = useState(null);
    const [searchTerm, setSearchTerm] = useState(null);
    const [searchSuggestions, setSearchSuggestions] = useState([]);

    const getLogos = async () => {
        try {
            const response = await axios.get(
                baseURL + "draft/v1/fetch/defaultLogo",
                {
                    headers: {
                        "Content-Type": "application/json",
                        "X-auth-Token": auth,
                    },
                }
            );
            const data = response?.data;
            setLogoList(data.filter((item) => item.defaultLogoType === "DEVICE"));
        } catch (error) {
        }
    }

    const getDeviceGroupApi = async () => {
        try {
            const response = await axios.get(baseURL + "device/group/v1/fetch/device/detail", {
                headers: {
                    "Content-Type": "application/json",
                    "X-Auth-Token": auth,
                },
            });
            if (response.data) {
                setDeviceGroups(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const fetchSearchSuggestions = async (term) => {

        const groups = deviceGroups.filter((item) => item.deviceGroup.deviceGroupName.toLowerCase().includes(term.trim().toLowerCase()));
        setSearchSuggestions(groups);

    };

    useEffect(() => {
        const delay = 300; // Adjust the delay as needed
        const timeoutId = setTimeout(() => {
            if (searchTerm) {
                fetchSearchSuggestions(searchTerm);
            } else {
                setSearchSuggestions([]);
            }
        }, delay);

        return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount or searchTerm change
    }, [searchTerm]);


    useEffect(() => {
        getLogos();
        getDeviceGroupApi();
        if (selectedDevice && Object.entries(selectedDevice).length > 0) {
            console.log(selectedDevice.deviceGroup);
            setUserForm(selectedDevice.deviceGroup);
            if (selectedDevice?.deviceGroup.logoType === "Upload your own logo") {
                setCardLogo(selectedDevice.deviceGroup.deviceGroupUrl);
                setCardLogoPreview(selectedDevice.deviceGroup.deviceGroupUrl);
            }
        }
    }, [])

    const handleBackButton = () => {
        if (discard) {
            setShowDraft(true);
            return;
        }
        setShowDevicesTab((prev) => "devices");
    };

    const handleConfirmDiscard = () => {
        setShowDraft(false);
        setShowDevicesTab((prev) => "devices");
    };

    const handleChange = (name, value) => {
        setDiscard(true);
        setUserForm((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const checkOk = () => {

        setSubmitClicked(true);
        let flag = false;
        for (let x of deviceGroups) {
            if (x.deviceGroup.deviceGroupName.toLowerCase() === userForm.deviceGroupName.trim().toLowerCase()) {
                flag = true;
                break;
            }
        }

        if (flag && update === false) {
            toast({
                title: "",
                description: "This device group already exists. Please add a new one.",
                status: "error",
                duration: 4000,
                isClosable: true,
                position: "top",
            });
            return false;
        }
        if (
            !userForm?.deviceGroupName ||
            userForm?.deviceGroupName?.length > 100 ||
            userForm?.description?.length > 1000
        ) {
            toast({
                title: "Incomplete Details",
                description: "Please fill in all required details.",
                status: "error",
                duration: 4000,
                isClosable: true,
                position: "top",
            });
            return false;
        } else {
            setSubmitClicked(false);
            return true;
        }
    };

    const postDeviceGroupApi = async (data, status) => {

        data.deviceGroupName = data?.deviceGroupName?.trim();
        data.description = data?.description?.trim();
        data["isdeleted"] = false;
        data["status"] = status;
        if (deviceGroupId) data.deviceGroupId = deviceGroupId

        const requestData = JSON.stringify(data);
        const blob = new Blob([requestData], {
            type: "application/json",
        });
        const FormData = require("form-data");
        let datax = new FormData();
        datax.append("data", blob);
        if (data.logoType === "Upload your own logo") datax.append("file", cardLogo);

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: baseURL + "device/group/v1/add/device",
            headers: {
                "X-Auth-Token": auth,
            },
            data: datax,
        };

        axios
            .request(config)
            .then((response) => {
                setShowDraft(false);
                setDiscard(false);
                setDeviceGroupId(response.data.deviceGroupId)
                toast({
                    title: "Saved",
                    description: "The progress is saved",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                    position: "top",
                });
            }).catch((error) => {

            });
    }

    const patchDeviceGroupApi = async (data, status) => {

        data.deviceGroupName = data?.deviceGroupName?.trim();
        data.description = data?.description?.trim();
        data["isdeleted"] = false;
        data["status"] = status;
        //data["deviceGroupId"] = 

        const requestData = JSON.stringify(data);
        const blob = new Blob([requestData], {
            type: "application/json",
        });
        const FormData = require("form-data");
        let datax = new FormData();
        datax.append("data", blob);
        if (data.logoType === "Upload your own logo") datax.append("file", cardLogo);

        let config = {
            method: "patch",
            maxBodyLength: Infinity,
            url: baseURL + "device/group/v1/update/device",
            headers: {
                "X-Auth-Token": auth,
            },
            data: datax,
        };

        axios
            .request(config)
            .then((response) => {
                setShowDraft(false);
                setDiscard(false);
                toast({
                    title: "Saved",
                    description: "The progress is saved",
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                    position: "top",
                });
            }).catch((error) => {

            });
    }


    const handleSubmit = (status) => {
        console.log(userForm);
        if (checkOk()) {
            if (status === 1) setDisable(true);
            if (update === true) patchDeviceGroupApi(userForm, status);
            else postDeviceGroupApi(userForm, status);
        }
    }

    const dataOptions = {
        icon: {
            type: ["Select a pre-loaded logo", "Upload your own logo"]
        }
    }


    return (
        <div className="font-roboto flex flex-col gap-2 mt-0">

            <div className="flex items-center">
                <div className="cursor-pointer w-8" onClick={handleBackButton}>
                    <KeyboardBackspaceIcon sx={{ color: "#084298" }} />
                </div>

                {view ? <p className="text-[#084298] font-medium text-xl ml-2">
                    View device group
                </p> : (update ? <p className="text-[#084298] font-medium text-xl ml-2">
                    Update device group
                </p> : <p className="text-[#084298] font-medium text-xl ml-2">
                    Add new device group
                </p>)}

            </div>

            <div className="flex flex-col gap-3 mt-6">
                <p className="text-[#3E3C42] text-lg font-medium">
                    Device group name<span className="text-red-500">*</span>
                </p>

                {view === true ? <p className='text-[#525056] text-sm font-medium'>{userForm.deviceGroupName}</p> :
                    (<div className="md:w-[400px]">
                        <Input
                            type="text"
                            value={userForm.deviceGroupName}
                            placeholder="Enter device group name"
                            required
                            disabled={disable}
                            borderColor={
                                submitClicked && !userForm.deviceGroupName ? "red.500" : "gray.300"
                            }
                            borderWidth={"2px"}
                            onChange={(e) => { handleChange("deviceGroupName", e.target.value); setSearchTerm(e.target.value); }}
                        />

                    </div>)}

                {(searchSuggestions.length > 0) && (

                    <div >
                        <div className="search-suggestions rounded-md mt-2 w-[400px]">
                            <p className="text-red-500">Similar group(s) already exist. Please check if your group is duplicate</p>
                        </div>
                        <div className="search-suggestions border bg-white rounded-md shadow-lg shadow-gray-200 mt-2 w-[500px] z-10">
                            <div className="p-2">
                                <p className="text-gray-400">
                                    Groups
                                </p>
                                <div className="grid grid-cols-2 gap-2">
                                    {searchSuggestions?.map((item) => (
                                        <div className="flex flex-row" >
                                            <img className="w-[30px] h-[30px]" src="/groupsCategory.png"></img>
                                            <p className="font-medium text-lg">{item.deviceGroup.deviceGroupName}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                )}

                {
                    userForm.deviceGroupName?.length > 100 && <div>
                        <Text color="red.500" fontSize="sm" mt="1">
                            You have exceed the character limit of 100 by {userForm.deviceGroupName.length - 100}
                        </Text>
                    </div>
                }

                {submitClicked && !userForm.deviceGroupName && (
                    <Text color="red.500" fontSize="sm" mt="1">
                        Please enter the device group name
                    </Text>
                )}
            </div>

            {view === true && <hr className="mt-3"></hr>}

            <div className="flex flex-col gap-3 mt-6" >
                <p className="text-[#3E3C42] text-lg font-medium">
                    Select logo for the device group
                </p>

                {view === true ? <img className='w-[30px] h-[30px]' src={selectedDevice.deviceGroup.deviceGroupUrl} /> :

                    <div className="mt-6">
                        <div >
                            <RadioGroup
                                onChange={(e) => { handleChange("logoType", e) }}
                                value={userForm.logoType}
                                isDisabled={disable}
                            >
                                <div
                                    className="flex flex-col md:grid md:grid-cols-3 gap-1 md:gap-2"
                                    style={{ width: "fit-content" }}
                                >
                                    {dataOptions.icon.type.map((x) => {
                                        return (
                                            <div
                                                style={{
                                                    backgroundColor:
                                                        userForm.logoType === x ? "#DDEEFF80" : "#FFF",
                                                    borderRadius: "8px",
                                                    mb: "12px",
                                                    // border
                                                }}
                                            >
                                                <Radio
                                                    value={x}
                                                    py={"8px"}
                                                    pl={"8px"}
                                                    pr={"12px"}
                                                    fontSize={"14px"}
                                                    fontWeight={500}
                                                    color={"#3E3C42"}
                                                    _checked={{
                                                        bg: "#6CA6FC",
                                                        borderColor: "#6CA6FC",
                                                    }}
                                                    _hover={{
                                                        borderColor: "#6CA6FC",
                                                    }}
                                                    borderColor={
                                                        // submitClicked && !useDemo
                                                        //     ? "red.500" :
                                                        "gray.300"
                                                        //  {/*submitClicked &&*/}
                                                    }
                                                    isDisabled={disable}
                                                >
                                                    {x}
                                                </Radio>
                                            </div>
                                        );
                                    })}
                                </div>
                            </RadioGroup>
                        </div>

                        {userForm.logoType === "Select a pre-loaded logo" && <div className="w-full md:w-[400px]">
                            <Menu>
                                <MenuButton
                                    isDisabled={disable}
                                    as={Button}
                                    rightIcon={<ChevronDownIcon />}
                                    className='w-full mt-3 md:w-[220px] font-normal bg-none'>
                                    {userForm?.deviceGroupUrl ? <img className='h-[20px] w-[20px]' src={userForm?.deviceGroupUrl} alt="image" /> : "Select one"}
                                </MenuButton>
                                <MenuList className='w-full md:w-[400px] grid grid-cols-4 font-normal max-h-[250px] overflow-y-auto'>
                                    {logoList?.map((item, index) => (
                                        <MenuItem onClick={() => {
                                            handleChange("deviceGroupUrl", item?.defaultLogoUrl);
                                            //setCardLogo(null);
                                            //setShowDiscard(true);
                                        }}>
                                            {<img className='h-[40px] w-[40px]' src={item?.defaultLogoUrl} alt="image" />}
                                            {/* {item} */}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </Menu>

                        </div>}

                        {userForm.logoType === "Upload your own logo" &&
                            <div >
                                <AddIcon
                                    logo={cardLogo}
                                    setLogo={setCardLogo}
                                    toast={toast}
                                    logoPreview={cardLogoPreview}
                                    setLogoPreview={setCardLogoPreview}
                                    submitClicked={submitClicked}
                                    // desiredRatio={"3 : 2"}
                                    text={"Supported formats: .png, .svg, .jpeg"}
                                    info={"2"}
                                    labelText={"Upload logo for the device group"}
                                    setShowDiscard={setDiscard}
                                    disable={disable}
                                />
                            </div>
                        }
                    </div>}


            </div>

            {view === true && <hr className="mt-3"></hr>}

            <div className="flex items-center gap-4 mt-6" >
                <div>
                    <p className="text-[#3E3C42] text-lg font-medium">
                        Description
                    </p>

                    {view === true ? <p className='text-[#525056] text-sm font-medium'> {userForm.description} </p> :
                        <div className="mt-6 md:w-[400px]">
                            <Textarea
                                // disabled={showDisable}
                                placeholder="A brief description of the device group"
                                borderWidth={"2px"}
                                value={userForm.description}
                                disabled={disable}
                                onChange={(e) => { handleChange("description", e.target.value) }}
                            />

                            {
                                userForm.description?.length > 1000 && <div>
                                    <Text color="red.500" fontSize="sm" mt="1">
                                        You have exceed the character limit of 1000 by {userForm.description.length - 1000}
                                    </Text>
                                </div>
                            }
                        </div>}
                </div>
            </div>

            {view === false && <div className="flex gap-[10px] items-center ml-6 md:ml-0 mt-6 mb-20 md:mb-0">
                {
                    !review ? (
                        <>
                            <PrimaryButton
                                text={"Save Draft"}
                                width={"130px"}
                                disable={view}
                                onClick={() => handleSubmit(0)}
                            />
                            <TonalButton
                                //disable={!showDiscard}
                                text={"Review"}
                                width={"130px"}
                                onClick={() => {
                                    setReview(true);
                                    setDisable(true);
                                }}
                                disable={view}
                            />
                        </>
                    ) :
                        (
                            <>
                                <TonalButton
                                    //disable={!showDiscard}
                                    text={"Back to Editing"}
                                    width={"130px"}
                                    onClick={() => {
                                        setReview(false);
                                        setDisable(false);
                                    }}
                                />
                                <PrimaryButton
                                    text={"Submit"}
                                    width={"130px"}
                                    //disable={showDisable}
                                    onClick={() => handleSubmit(1)}
                                    color="green"
                                />

                            </>
                        )
                }

                {/* <DiscardModal isModalOpen={isModalOpen} handleCloseModal={handleCloseModal} handleConfirmDiscard={handleConfirmDiscard} /> */}
                {showDraft && <DraftModal isOpen={showDraft} handleSubmit={handleSubmit} handleConfirmDiscard={handleConfirmDiscard} setShowDraft={setShowDraft} />}
            </div>}
        </div>
    )
}

export default AddNewDeviceGroup