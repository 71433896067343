import { useContext, useEffect, useState } from "react";
import StatusTable from "../Tables/StatusTable";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import RecipeDrawer from "../Components/RecipeDrawer";
import axios from "axios";
import NavContext from "../../NavContext";
import { baseURL } from "../../..";

const mach = {
  1: {
    loader: [4],
    hopper: [2, 3],
  },
  3: {
    loader: [5],
    hopper: [1],
  },
};

export const totalCal = (data, val) => {
  let total = data.reduce((a, b) => {
    return a + parseFloat(b[val]);
  }, 0);
  return total;
};

export const batches = [1, 3];

export const aggregationLogic = (data, setData, setColumns, locations) => {
  const obj = {
    Concentrate: "",
    "Blend Ratio": "",
    Bay: "",
    Section: "",
    "200 MT": "",
  };
  let maxRounds = Math.max(...data.map((item) => item.rounds));
  for (let i = maxRounds; i >= 1; i--) {
    obj["R" + i] = "0.00";
  }
  obj["Total"] = "0";
  setColumns(Object.keys(obj));
  const output = Array.from(
    new Set(data.map((item) => item.concentrate?.split("-")[0]))
  )
    .map((conc) => {
      let total = 0;
      const relevantData = data.filter(
        (item) => item.concentrate?.split("-")[0] === conc
      );
      let aggregateObj = {
        pos: "",
        amountSum: 0,
      };
      Array.from(new Set(relevantData.map((val) => val.concentrate))).map(
        (x) => {
          for (let i = 0; i < relevantData.length; i++) {
            if (relevantData[i].concentrate == x) {
              aggregateObj.pos +=
                relevantData[i].concentrate_position != ""
                  ? relevantData[i].concentrate_position + ","
                  : "";
              aggregateObj.amountSum = Math.max(
                aggregateObj.amountSum,
                relevantData[i].required_tonnage
              );
              break;
            }
          }
        }
      );
      let minData = relevantData[0];
      relevantData.forEach((val) => {
        total += parseFloat(val.dumped_tonnage);
        if (val.rounds < minData.rounds) {
          minData = val;
        }
      });
      if (
        total == 0 &&
        (conc.toLowerCase().includes("unknown") || aggregateObj.amountSum == 0)
      ) {
        return null;
      }
      let baySectionMap = {};
      (locations
        ? conc in locations
          ? locations[conc]
          : aggregateObj.pos
        : aggregateObj.pos
      )
        ?.split(",")
        ?.forEach((item) => {
          if (item[1] && item[3]) {
            if (baySectionMap.hasOwnProperty(item[1])) {
              baySectionMap[item[1]].push(item[3]);
            } else baySectionMap[item[1]] = [item[3]];
          }
        });
      return {
        ...obj,
        Concentrate: minData?.concentrate,
        Bay: Object.entries(baySectionMap)?.map((x) => {
          return x[0] + " ";
        }),
        Section: Object.entries(baySectionMap)?.map((x) => {
          return x[1] + " ";
        }),
        "Blend Ratio": ((aggregateObj.amountSum / 200) * 100)?.toFixed(2),
        "200 MT": aggregateObj.amountSum?.toFixed(2) || 0,
        ...Object.fromEntries(
          relevantData.map((val) => {
            let totalDump = relevantData
              .filter((i) => i.rounds == val.rounds)
              .reduce((a, b) => {
                return a + parseFloat(b.dumped_tonnage);
              }, 0);
            return [`R${val.rounds}`, totalDump?.toFixed(2)];
          })
        ),
        ...Object.fromEntries(
          relevantData.map((val) => {
            let totalTime = relevantData
              .filter((i) => i.rounds == val.rounds)
              .map((item) => item.endtime);
            return [`T${val.rounds}`, Math.max(...totalTime)];
          })
        ),
        ...Object.fromEntries(
          relevantData.map((val) => {
            let totalTime = relevantData
              .filter((i) => i.rounds == val.rounds)
              .map((item) => item.starttime);
            return [`TS${val.rounds}`, Math.max(...totalTime)];
          })
        ),
        baySectionMap: baySectionMap,
        smelter: minData?.smelter,
        endTime: minData?.endtime,
        batchId: minData?.batch_id,
        Total: total?.toFixed(2),
      };
    })
    .filter((entry) => entry !== null);
  let totalObj = {
    Concentrate: "Total",
    "Blend Ratio": totalCal(output, "Blend Ratio"),
    Bay: "-",
    Section: "-",
    "200 MT": totalCal(output, "200 MT")?.toFixed(2),
  };
  for (let i = maxRounds; i >= 1; i--) {
    totalObj["R" + i] = totalCal(output, "R" + i)?.toFixed(2);
  }
  totalObj["Total"] = totalCal(output, "Total")?.toFixed(2);
  output.push(totalObj);
  setData(output);
};

const Status = ({ feedRef }) => {
  const [sm, setSm] = useState(1);
  const { auth } = useContext(NavContext);
  const [data, setData] = useState([]);
  const [camData, setCamData] = useState([]);
  const [openRecipeDrawer, setOpenRecipeDrawer] = useState(false);
  const [columns, setColumns] = useState([]);

  const getStatusApi = async (locations) => {
    try {
      const response = await axios.get(
        baseURL + "vision/v1/workforceMonitoring/concentrateStatus",
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response?.data?.length > 0) {
        let data = response.data.filter((item) => item.smelter == "SM" + sm);
        aggregationLogic(data, setData, setColumns, locations);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getCamApi = async () => {
    try {
      const param = {
        smelter: "SM" + sm,
      };
      const response = await axios.get(
        baseURL + "vision/v1/workforceMonitoring/cb/getImages",
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.data) {
        let data = [];
        let val = response.data;
        data.push({
          url: val["loader-image"],
          time: val["loader_timestamp"],
        });
        data.push({
          url: val["ocr-image"],
          time: val["ocr_timestamp"],
        });
        setCamData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getBaySectionApi = async () => {
    try {
      const response = await axios.get(
        baseURL + "vision/v1/workforceMonitoring/cb/latestConcentrateMap",
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response?.data) {
        let locations = response?.data;
        getStatusApi(locations);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getBaySectionApi();
    getCamApi();
    const intervalId = setInterval(() => {
      getCamApi();
    }, 5000);
    const intervalId2 = setInterval(() => {
      getBaySectionApi();
    }, 30 * 1000);
    return () => {
      clearInterval(intervalId);
      clearInterval(intervalId2);
    };
  }, [sm]);

  return (
    <div className="flex flex-col gap-3 pb-6 pt-3 px-3 rounded-xl bg-white">
      <div className="flex flex-col sm:flex-row justify-between items-start gap-3 sm:items-start">
        <div className="flex flex-col gap-3">
          <div className="flex gap-2">
            {batches.map((i, idx) => {
              return (
                <div
                  className="py-[6px] px-3 rounded border text-sm h-[40px] flex items-center"
                  style={{
                    backgroundColor: sm == i ? "#f1f7ff" : "white",
                    color: sm == i ? "#3E3C42" : "#605D64",
                    borderColor: sm == i ? "#6CA6FC" : "#EBEBEB",
                    cursor: sm == i ? "" : "pointer",
                    fontWeight: sm == i ? 500 : 400,
                  }}
                  onClick={() => setSm(i)}
                >
                  SM {i}
                </div>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col min-[425px]:flex-row sm:flex-col gap-4 sm:gap-8 items-start min-[425px]:items-end">
          <div className="flex flex-col lg:flex-row gap-2 items-start lg:items-center">
            <PrimaryButton
              width={"190px"}
              text={"Upload blend recipe"}
              onClick={() => setOpenRecipeDrawer(true)}
              height={"40px"}
            />
            <PrimaryButton
              width={"190px"}
              text={"View/Update concentrate"}
              onClick={() => feedRef.current.click()}
              height={"40px"}
            />
          </div>
        </div>
      </div>
      <p className="text-[#938F96]  text-xs">
        You can click on the table cell to edit dumped tonnage
      </p>
      <div className="flex flex-col-reverse md:flex-row gap-3">
        <StatusTable rowData={data} cols={columns} />
        <div className="flex flex-col gap-4 w-full md:w-[50%] lg:w-[40%] xl:w-[30%] h-[50vh] md:h-[65vh] overflow-y-auto">
          {camData?.length > 0 &&
            ["Loader position", "OCR"]?.map((i, idx) => {
              return (
                <div className="flex flex-col gap-2 capitalize">
                  <p>{i}</p>
                  <div className="w-full h-full bg-black rounded flex justify-center items-center relative">
                    <img
                      src={camData[idx]?.url}
                      alt="no support"
                      className="w-full h-auto rounded"
                    />
                    <p className="bg-black bg-opacity-50 p-1 rounded text-white text-sm font-medium absolute top-2 right-2">
                      {new Date(camData[idx]?.time * 1000)?.toLocaleString()}
                    </p>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      {openRecipeDrawer && (
        <RecipeDrawer
          closeModal={() => setOpenRecipeDrawer(false)}
          openModal={openRecipeDrawer}
          smelter={sm}
        />
      )}
    </div>
  );
};

export default Status;
