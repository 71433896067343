import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  IconButton,
  Badge,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Checkbox } from '@chakra-ui/react'
import { useWindowSize } from "@uidotdev/usehooks";
import PrimaryButton from "../../../../util/Buttons/PrimaryButton";
import TonalButton from "../../../../util/Buttons/TonalButton";
import DownloadIcon from "@mui/icons-material/Download";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../../../index";
import NavContext from "../../../NavContext";
import axios from "axios";
import { Tooltip } from "@chakra-ui/react";
import { utils, writeFile } from "xlsx";
import { deactiveAlertApi } from "../../Tabs/AlertsPage";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const AlertCard = ({
  data,
  setSelectedAlert,
  setShowAlertsTab,
  getAlertApi,
  getAlertGroupApi,
}) => {
  const { auth } = useContext(NavContext);
  const toast = useToast();
  const [isSwitchOn, setIsSwitchOn] = useState(data?.status === "INACTIVE");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // console.log(data, "");

  // const updateAlerts = async (flag, status) => {

  //   const data1 = {
  //     alertId: data?.alertId,
  //     isdeleted: flag,
  //     status: status,
  //   }
  //   const json = JSON.stringify(data1);
  //   const blob = new Blob([json], {
  //     type: "application/json",
  //   });
  //   const FormData = require("form-data");
  //   let datax = new FormData();
  //   datax.append("data", blob);

  //   let config = {
  //     method: "patch",
  //     maxBodyLength: Infinity,
  //     url: baseURL + "alert/v1/addAlert",
  //     headers: {
  //       "X-Auth-Token": auth,
  //     },
  //     data: datax,
  //   };
  //   axios
  //     .request(config)
  //     .then((response) => {
  //       getAlertGroupApi();
  //       getAlertApi();
  //     })
  //     .catch((error) => {
  //       //console.log(error);
  //       toast({
  //         title: "Something went wrong",
  //         status: "error",
  //         duration: 4000,
  //         isClosable: true,
  //         position: "top",
  //       });
  //     });

  // }

  const handleSwitchChange = async () => {
    if (!isSwitchOn) {
      setIsModalOpen(true);
      return;
    }
    setIsSwitchOn(!isSwitchOn);
    await deactiveAlertApi(data, toast, false, auth, getAlertApi, getAlertGroupApi);
    //getProducts();

    setTimeout(() => {
      setIsMenuOpen(false);
    }, 1000);
  };


  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmDiscard = async () => {
    setIsSwitchOn(!isSwitchOn);
    await deactiveAlertApi(data, toast, true, auth, getAlertApi, getAlertGroupApi);
    // setTimeout(() => {
    //   getProductGroups();
    // }, 1500)
    setIsModalOpen(false);
    setTimeout(() => {
      setIsMenuOpen(false);
    }, 1000);
  };

  const handleClick = () => {
    setSelectedAlert(data);
    setShowAlertsTab((prev) => "viewAlert");
  };

  const formatDate = (timestamp) => {
    // Create a new Date object using the provided timestamp (in milliseconds)
    const date = new Date(timestamp);

    // Define the date formatting options
    const options = {
      month: "long", // Display the full month name
      year: "numeric", // Display the full year
    };

    // Format the date using the specified options
    const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
      date
    );

    // Get the day of the month and determine the appropriate suffix (st, nd, rd, or th)
    const day = new Intl.DateTimeFormat("en-US", { day: "numeric" }).format(
      date
    );
    const suffix = getDaySuffix(day);

    // Combine the day, suffix, and formatted date to create the final date string
    return `${day}${suffix} ${formattedDate}`;
  };

  const getDaySuffix = (day) => {
    // Determine the day suffix based on common English rules
    if (day >= 11 && day <= 13) {
      return "th";
    }
    const lastDigit = day % 10;
    switch (lastDigit) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };
  const exportAsExcel = (data, sheetName) => {
    let wb = utils.book_new();

    let fileColumns = Object.keys(data[0]);
    fileColumns = fileColumns.filter((value) => (value != "productIdUuid" && value != "createdAt" && value != "updatedAt" && value != "productList" && value != "alertsDetail"));
    let newData = [fileColumns];

    if (data.length != 0) {
      data?.forEach((item) => {
        const newArray = fileColumns?.map((i) => {
          return i.toLowerCase().includes("time")
            ? new Date(item[i]).toLocaleString()
            : item[i];
        });
        newData.push(newArray);
      });
    }

    let ws = utils.aoa_to_sheet(newData);
    utils.book_append_sheet(wb, ws, `${sheetName}`);
    writeFile(wb, `report_data.xlsx`);
  };

  const handleDownloadClick = async () => {
    if (Array.isArray(data)) exportAsExcel(data, "Summary");
    else exportAsExcel([data], "Summary");
  };

  const handleUpdate = () => {
    //   navigate(`/superadmin/update/${data.organisation}/${data.clientId}/update`);
    setSelectedAlert(data);
    setShowAlertsTab((prev) => "updateAlert");
  };

  const switchLabelText = isSwitchOn ? "Disabled" : "Disable";
  const size = useWindowSize();
  const productString = data?.alertsDetail?.filter((value, index) => value?.isDeleted === false)?.map((value, index) => value.organisation)
  const products = productString?.filter((item, idx) => productString?.indexOf(item) == idx);
  let maxProductsToShow = 0;
  let totalLength = 0;

  const calculateProduct = () => {
    for (let i = 0; i < products?.length; i++) {
      // Assuming each location contributes 10 characters on average

      // Calculate the total length with the new location
      totalLength += products[i]?.length;

      // Increment maxLocationsToShow if the total length is within the limit
      if (totalLength <= (size.width < 1536 ? 14 : 25)) {
        maxProductsToShow++;
      } else {
        maxProductsToShow++;
        // Stop incrementing if the limit is exceeded
        break;
      }
    }
  };

  products && calculateProduct();

  let allTools = data?.alertsDetail?.filter((value, index) => value?.isDeleted === false)?.map((value, index) => value.organisation)
  const maxToolsToShow = 2;

  useEffect(() => {
    // getProductCategory();
  }, []);

  return (
    <div
      className="px-4 py-5 rounded flex flex-col justify-between h-[250px] gap-4 relative bg-white w-full"
      style={{
        boxShadow:
          "-4px -4px 24px 0px rgba(0, 0, 0, 0.07), 4px 4px 24px 0px rgba(0, 0, 0, 0.07)",
      }}
    >

      <div className="flex flex-col gap-2 w-full">
        <div className="flex h-[45px] gap-0 w-full items-center">
          <div className="flex justify-start">
            {/* {data?.productGroupUrl ? (
              <img
                src={data?.productGroupUrl}
                alt="logo"
                className="w-full max-w-[30px] h-[30px]"
              />
            ) : ( */}
            <img src="/alertIcon.svg" alt="" className="w-full max-w-[30px] h-[30px]" />
            {/* ) */}
          </div>

          <div className="ml-2 h-[45px] w-[70%] overflow-hidden">
            <Tooltip label={data?.alertName} placement="top">
              <p className="text-[#3E3C42] text-[16px] font-medium whitespace-pre-line line-clamp-2 overflow-hidden overflow-ellipsis">
                {data?.alertName}
              </p>
            </Tooltip>
            <Tooltip
              label={formatDate(data?.createdAt)}
              placement="top"
            >
              <p className=" text-sm font-medium whitespace-nowrap overflow-hidden overflow-ellipsis">
                {/* <span className="text-[#79767D]">Created</span>{" "} */}
                {formatDate(data?.createdAt)}
              </p>
            </Tooltip>
          </div>

          {/* <div className="ml-2 w-[75%] h-18">
            <Tooltip
              label={data?.clientMasterDataEntity?.clientName}
              placement="top"
            >
              <p className="text-[#3E3C42] text-[16px] font-medium whitespace-nowrap overflow-hidden overflow-ellipsis">
                {data?.clientMasterDataEntity?.clientName}
              </p>
            </Tooltip>
            <Tooltip
              label={formatDate(data?.clientMasterDataEntity?.creationAt)}
              placement="top"
            >
              <p className=" text-sm font-medium whitespace-nowrap overflow-hidden overflow-ellipsis">
                <span className="text-[#79767D]">Since</span>{" "}
                {formatDate(data?.clientMasterDataEntity?.creationAt)}
              </p>
            </Tooltip>
          </div> */}

          <div className=" w-[10%]">
            <Menu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<MoreVertIcon />}
                variant="simple"
                position={"absolute"}
                top={"15px"}
                right={"0px"}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              />
              <MenuList
                px={"12px"}
                py={"16px"}
                rounded={"8px"}
                display={"flex"}
                flexDirection={"column"}
                gap={"16px"}
                minWidth={"fit-content"}
                w={"180px"}
                position={"absolute"}
                right={"-50px"}
                top={"-15px"}
              >
                <MenuItem
                  icon={<DownloadIcon />}
                  textColor={"#605D64"}
                  fontSize={"14px"}
                  fontWeight={500}
                  p={"8px"}
                  _hover={{ bg: "#DDEEFF80", borderRadius: "4px" }}
                  _focus={{ bg: "#DDEEFF80", borderRadius: "4px" }}
                  onClick={handleDownloadClick}
                >
                  {"Download"}
                </MenuItem>
                {/* <MenuItem
                  icon={<DeleteIcon />}
                  textColor={"#605D64"}
                  fontSize={"14px"}
                  fontWeight={500}
                  p={"8px"}
                  _hover={{ bg: "#DDEEFF80", borderRadius: "4px" }}
                  _focus={{ bg: "#DDEEFF80", borderRadius: "4px" }}
                >
                  {"Delete"}
                </MenuItem> */}
                <Checkbox
                  //iconColor='red'
                  colorScheme="red"
                  p={"8px"}
                  isChecked={isSwitchOn}
                  disabled={data?.status === "DRAFT"}
                  //isIndeterminate={isIndeterminate}
                  onChange={handleSwitchChange}
                >
                  {switchLabelText}
                </Checkbox>
              </MenuList>
            </Menu>
            <Modal size={"sm"} isOpen={isModalOpen} onClose={handleCloseModal}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader className=" flex justify-center rounded-t-md bg-red-400 text-white ">
                  Confirm Disable ?
                </ModalHeader>
                {/* <ModalCloseButton /> */}
                <ModalBody m={"auto"} fontSize={"15px"}>
                  <div className="flex flex-col items-center justify-center w-full">
                    <img
                      src={require("../../AddClients/Components/disable.png")}
                      className="w-[100px] h-[100px]"
                    />

                    <p className="ml-4 font-medium">
                      Are you sure you would like to disable the alert?
                    </p>
                  </div>
                </ModalBody>

                <div className="mt-4 flex flex-row justify-center">
                  <Button
                    colorScheme="blue"
                    mr={4}
                    onClick={handleConfirmDiscard}
                  >
                    Confirm
                  </Button>
                  <Button onClick={handleCloseModal}>Go Back</Button>
                </div>

                <ModalFooter></ModalFooter>
              </ModalContent>
            </Modal>
          </div>
          {/* <div></div> */}
        </div>
        <div className="grid grid-cols-2 gap-2">
          <div className="flex items-center justify-start">
            <div className="flex flex-col items-start justify-start">
              <p className="text-[#938F96] text-sm">Product</p>
              <div className="flex items-center justify-center">
                {/* <div className="">
                  {
                    data?.productGroupParent.toLowerCase() === "community" && <img className="w-[20px] h-[20px]" src="/CommunityIcon.svg" alt="" />
                  }
                  {
                    data?.productGroupParent.toLowerCase() === "vision" && <img className="w-[20px] h-[20px]" src="/VisionIcon.svg" alt="" />
                  }
                  {
                    data?.productGroupParent.toLowerCase() === "optimus" && <img className="w-[20px] h-[20px]" src="/OptimusIcon.svg" alt="" />
                  }
                  {
                    data?.productGroupParent.toLowerCase() === "others" && <img className="w-[20px] h-[20px]" src="/casino.svg" alt="" />
                  }
                </div> */}
                <div className=" overflow-hidden">
                  <Tooltip label={data?.alertGroupName} placement="top">
                    <p className="whitespace-pre-line line-clamp-1 overflow-hidden overflow-ellipsis">{data?.alertGroupName}</p>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-start justify-start w-full">
            <div className="flex flex-col items-start justify-start w-full">
              <p className="text-[#938F96] text-sm">Alert Instances</p>
              <div className="flex gap-2 items-center w-full">
                {/* <img
                  className="w-[20px] h-[20px]"
                  src="/productGroup.svg"
                  alt=""
                /> */}

                <Tooltip label={allTools?.length} placement="top">
                  <p className="text-[#3E3C42] text-[16px] font-medium truncate">{allTools?.length}</p>
                </Tooltip>

              </div>
            </div>
          </div>

        </div>
        <div className="">
          <Tooltip label={allTools?.join(", ")} placement="top">
            <div>
              <p className="text-[#938F96] text-sm">Client</p>
              <div className="flex mt-2 justify-between">

                <div className="flex w-[180px] 2xl:w-[90%] ">
                  <img src="/clients.png" className="w-[20px] h-[20px]" alt="" />
                  <p className="text-[#938F96] text-sm font-medium truncate">{products?.join(", ")}</p>
                  {/* {allTools?.slice(0, maxToolsToShow)?.map((el, index) => (
                    <div key={index} className="flex items-center overflow-hidden overflow-ellipsis whitespace-nowrap">
                      <p className="text-[#938F96] text-sm font-medium whitespace-pre-line line-clamp-1">{el + " "}</p>

                    </div>
                  ))} */}
                </div>

                {products?.length > maxProductsToShow && (
                  <Badge
                    ml={1}
                    color={"#447ED4"}
                    bg={"white"}
                    fontWeight={600}
                    fontSize="14px"
                  >
                    +{products?.length - maxProductsToShow}
                  </Badge>
                )}
              </div>
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="flex gap-2 items-center">

        <PrimaryButton
          text={"View"}
          onClick={() => handleClick()}
        // disable={!data.product.isactive}
        />
        <TonalButton
          text={"Update"}
          onClick={() => handleUpdate()}
          disable={data?.status === "INACTIVE"}
        />
        {/* <TonalButton
          color="#CDEEBF"
          text={"Update"}
          onClick={() => handleUpdate()}
          disable={""}
          textColor="#378805"
        /> */}
      </div>

      <Tooltip
        label={data?.status?.toLowerCase()}
        hasArrow
      >

        {data?.status === "DRAFT" ?
          <img
            src={`/Common/draft.svg`}
            className="w-8 h-8 absolute -top-2 -right-2 rounded-full bg-white p-1 shadow-md"
            alt="status"
          /> :
          data?.status === "ACTIVE" ? <CheckCircleIcon sx={{
            position: 'absolute',
            top: "-8px",
            right: "-8px",
            backgroundColor: "white",
            padding: "4px",
            width: "32px",
            height: "32px",
            borderRadius: "9999px",
            color: "green",
            boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
          }} /> :
            <CancelIcon sx={{
              position: 'absolute',
              top: "-8px",
              right: "-8px",
              backgroundColor: "white",
              padding: "4px",
              borderRadius: "9999px",
              width: "32px",
              height: "32px",
              color: "#E46962",
              boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
            }} />}

      </Tooltip>

    </div>
  );
};

export default AlertCard;
