import { CustomStyledDataGrid } from "../../../util/MaterialDataGrid/CustomStyledDatagrid";

export const BiasRecommenderTable = ({
  columns,
  rowData,
  handleCellClick,
  rowSelectionModel,
  columnGroupingModel,
  hideFooter = true,

}) => {
  // console.log(rowSelectionModel);
  return (
    <CustomStyledDataGrid
      columns={columns || []}
      rows={rowData || []}
      hideFooter={hideFooter}
      rowHeight={40}
      columnHeaderHeight={35}
      showColumnVerticalBorder
      disableColumnSelector={true}
      disableRowSelectionOnClick={true}
      experimentalFeatures={{ columnGrouping: true }}
      {...(columnGroupingModel && {
        columnGroupingModel: columnGroupingModel,
      })}
      {...(handleCellClick && {
        onCellClick: handleCellClick,
      })}
      // checkbox selection
      {...(rowSelectionModel && {
        checkboxSelection: true,
      })}
      {...(rowSelectionModel && {
        rowSelectionModel: rowSelectionModel,
      })}
      getRowClassName={(params) => {
        // console.log( params);
        return(params?.row?.insf_data == 1 && rowSelectionModel[0]===params?.id  ? '!shadow-inner !border !border-red-200 !border-1 !bg-[#fffefb]' : (params?.row?.insf_data == 1 ? '!bg-red-50' : ''))}}
      sx={{
        // '& .highlight-row': {
        //   border: '1px solid red', // Example border style
        // },
        // "& .MuiDataGrid-columnHeaderTitle": {
        //   whiteSpace: "normal",
        //   lineHeight: "normal",
        //   paddingTop: "5px",
        // },
        // "& .MuiDataGrid-columnHeaderTitleContainer": {
        //   alignItems: "start",
        // },
        maxHeight: handleCellClick ? "35vh" : "60vh",
        overflow: "hidden",
        "& .MuiDataGrid-columnHeader": {
          border: "0.9px solid #e0e0e0",
          //alignItems: 'center',
        },
        "& .MuiDataGrid-columnHeaderTitleContainer": {
          //border: "0.9px solid #e0e0e0",
          justifyContent: "center"
        },
        // "& .MuiDataGrid-columnSeparator": { display: "none" },
        "& .MuiDataGrid-row.Mui-selected": {
          backgroundColor: "#FFFdf3",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
          fontWeight: "600",
          "&:hover": {
            backgroundColor: "#FFFdf3",
          },
        },
      }}
    />
  );
};
