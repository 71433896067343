import { useWindowSize } from "@uidotdev/usehooks";
import { Link, useSearchParams } from "react-router-dom";
import { Status } from "./tabs/Status";
import { useEffect } from "react";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
// import { Alerts } from "./tabs/Alerts";
import { Report } from "./tabs/Report";
import { FeedLibrary } from "./tabs/FeedLibrary";
import { SinterBedContextProvider } from "./components/SinterBedContext";

const SinterBed = () => {
  const size = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlParamTabIndex = !isNaN(searchParams.get("tabIndex"))
    ? Number(searchParams.get("tabIndex"))
    : 0;

  // handling tab change
  const handleTabsChange = (index) => {
    if (index !== urlParamTabIndex) {
      setSearchParams({ tabIndex: index }, { replace: true });
    }
  };

  const tabs = [
    {
      label: "Status",
      index: 0,
      component: <Status />,
    },
    {
      label: "Report",
      index: 1,
      component: <Report />,
    },
    {
      label: "Feed Library",
      index: 2,
      component: <FeedLibrary />,
    },
    // {
    //   label: "Alerts",
    //   index: 3,
    //   component: (
    //     <Alerts
    //       plantId="All Plants"
    //       disable={false}
    //     />
    //   ),
    // },
  ];

  useEffect(() => {
    const originalTitle = document.title;
    document.title = "Sinter Bed | IntelliVerse";
    return () => {
      document.title = originalTitle;
    };
  }, []);

  // use effect to look for change in tab-index
  useEffect(() => {
    handleTabsChange(urlParamTabIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParamTabIndex]);

  return (
    <div
      className="flex flex-col -ml-2 md:ml-0 px-1 rounded-lg font-poppins"
      style={{ width: size.width >= 768 ? "calc(100vw - 168px)" : "100vw" }}
    >
      <div
        className="top-5 right-0 fixed bg-white w-[180px] h-[30px]"
        style={{ zIndex: 1000 }}
      >
        <img
          className="top-[-20px] right-2 absolute w-[120px] h-[50px]"
          src="/clientLogos/hzl.png"
          alt="clientLogo"
        />
      </div>
      <div className="flex justify-start items-center gap-2 mt-6 mb-3">
        <Link
          to={`/vision/qualityTracking/sinterbed`}
          style={{
            textDecoration: "none",
          }}
        >
          <img
            src="/backtick.svg"
            alt="backtick"
          />
        </Link>
        <p className="font-semibold text-[#024D87] text-lg sm:text-2xl capitalize">
          Sinter Bed Monitoring
        </p>
      </div>
      <SinterBedContextProvider>
        <Tabs
          isLazy={true}
          index={urlParamTabIndex}
          onChange={handleTabsChange}
        >
          <TabList className="!flex !border-0">
            <div className="flex items-center gap-4 h-14 md:h-10 overflow-x-auto">
              {tabs.map((tab, index) => (
                <Tab
                  key={`${tab.label}-${index}`}
                  minW={"fit-content"}
                  className={`${
                    urlParamTabIndex === tab.index
                      ? "!text-black !text-xs sm:!text-sm !bg-white rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#79767D]"
                      : "!text-xs sm:!text-sm !text-[#938F96] !border-0"
                  }`}
                  onClick={() => handleTabsChange(tab.index)}
                >
                  {tab.label}
                </Tab>
              ))}
            </div>
          </TabList>
          <TabPanels>
            {tabs.map((tab, index) => (
              <TabPanel
                key={index}
                className="mb-5 md:mb-0 !pr-0 !pl-0"
              >
                {tab.component}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </SinterBedContextProvider>
    </div>
  );
};

export default SinterBed;
