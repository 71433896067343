import React, { useState, useContext, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableCaption,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tfoot,
  Link,
  Button,
  Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import NavContext from "../../NavContext";
import { baseURL } from "../../..";
import Paginator from "../../../util/VisionUtils/Paginator";
import { useToast } from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import ExlCsvDownload from "../../../util/VisionUtils/ExlCsvDownload";
import { CSVLink } from "react-csv";
import ActiveSubsTable from "../Tables/ActiveSubsTable";
import RipikAdminCards from "../components/RipikAdminCards";
import ActiveSubsFilters from "../components/ActiveSubsFilters";
import ClientAdminCards from "../components/ClientAdminCards";
import { useParams } from "react-router-dom";

const ActiveSubs = ({
  setSubHistoryChange,
  issubscriptionHistoryChanged,
  clientOrg,
  mode,
  isSuperAdminClient,
}) => {
  const { clientId } = useParams();
  const [page, setPage] = useState(0);

  const activeSubsDataList = [];
  // const {clieno}
  const [activeSubs, setActiveSubs] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const { auth, organisation } = useContext(NavContext);
  const toast = useToast();
  const [downloadData, setDownloadData] = useState({});
  const [downloadProp, setDownloadProp] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noOfClients, setNoOfClients] = useState(0);
  const [noOfActiveSubs, setNoOfActiveSubs] = useState(0);
  const [noOflocations, setNoOfLocations] = useState(0);
  const [changingbutton, setChangingbutton] = useState("ACTIVE");
  const [commonLogo, setCommonLogo] = useState("");

  const fetchTableData = async () => {
    setLoading(true);
    const param = {
      organisation: clientOrg || organisation,
    };
    try {
      const response = await axios.get(baseURL + "subscription/fetchAll", {
        params: param,
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });

      if (changingbutton === "all") {
        setFilteredData((prev) => response?.data?.subscriptions);
        // setChangingbutton(changingbutton);
      } else if (changingbutton === "ACTIVE") {
        // Filter for both "ACTIVE" and "MODIFIED" statuses
        const filtered = response?.data?.subscriptions?.filter(
          (item) => item.status === "ACTIVE" || item.status === "MODIFIED"
        );
        setFilteredData((prev) => filtered);
      } else {
        setChangingbutton(changingbutton);
        const filtered = response?.data?.subscriptions?.filter(
          (item) => item.status === changingbutton
        );
        setFilteredData((prev) => filtered);
      }

      setActiveSubs(response?.data?.subscriptions);

      // setFilteredData((prev) => response?.data?.subscriptions);
      // setFilteredData((prev) => filtered);
      setNoOfClients(response?.data?.totalClient);
      setNoOfActiveSubs(response?.data?.totalSubscriptions);
      setNoOfLocations(response?.data?.totalLocation);
      setCommonLogo(response?.data?.subscriptions[0].imageUrl);

      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  const fetchDownloadApi = async () => {
    const header = { header: "subscribed" };
    try {
      const response = await axios.post(baseURL + "iam/header", header, {
        headers: {
          "Content-Type": "application/json",
          "X-auth-Token": auth,
        },
      });

      //setting order for downloading data
      setDownloadData(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleStatus = (selectedStatus) => {
    console.log(selectedStatus);
    const filtered = activeSubs?.filter(
      (item) => item.status === "ACTIVE" || item.status === "MODIFIED"
    );
    if (selectedStatus === "ACTIVE") {
      setFilteredData((prev) => filtered);
    } else if (selectedStatus === "starred") {
      // Filter data based on the "starred" status
      const starredData = activeSubs.filter((item) => {
        if (organisation != "Ripik.Ai") {
          return item.clientStarred === true;
        } else {
          return item.adminStarred === true;
        }
      });
      console.log(filtered);

      setFilteredData(starredData);
    } else if (selectedStatus === "all") {
      // Filter data based on the "all" status
      setFilteredData((prev) => activeSubs);
    } else {
      // Filter data based on other statuses
      const filtered = activeSubs.filter(
        (item) => item.status === selectedStatus
      );
      console.log(filtered);
      setFilteredData(filtered);
    }
    setChangingbutton(selectedStatus);
  };

  useEffect(() => {
    if (auth) {
      if (organisation) {
        fetchTableData();
      }
      // setFilteredData((prev) => activeSubs);
      fetchDownloadApi();
    }
  }, [auth, organisation, mode]);

  return (
    <div className="w-full px-2 mt-2 !font-roboto">
      <div className="flex flex-col xl:flex-row xl:justify-between">
        {organisation === "Ripik.Ai" ? (
          <RipikAdminCards
            noOfActiveSubs={noOfActiveSubs}
            noOfClients={noOfClients}
            noOflocations={noOflocations}
            isSuperAdminClient={isSuperAdminClient}
          />
        ) : (
          <ClientAdminCards
            noOfActiveSubs={noOfActiveSubs}
            noOflocations={noOflocations}
            organisation={organisation}
            commonLogo={commonLogo}
            isSuperAdminClient={isSuperAdminClient}
          />
        )}

        <ActiveSubsFilters
          organisation={organisation}
          handleStatus={handleStatus}
          setChangingbutton={setChangingbutton}
          changingbutton={changingbutton}
          fetchTableData={fetchTableData}
          setSubHistoryChange={setSubHistoryChange}
          issubscriptionHistoryChanged={issubscriptionHistoryChanged}
          mode={mode}
          clientOrg={clientOrg}
        />
      </div>

      {loading ? (
        <div className="ml-[50%]">
          <Spinner speed="0.65s" />
        </div>
      ) : (
        <React.Fragment>
          {filteredData && filteredData.length !== 0 ? (
            <ActiveSubsTable
              activeSubs={filteredData}
              organisation={organisation}
              fetchTableData={fetchTableData}
              page={page}
              setPage={setPage}
            />
          ) : (
            <p className="ml-[45%] mt-10">No data available</p>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default ActiveSubs;
