import { Link, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import Feed from "./tabs/Feed";
import Alert from "./tabs/Alert";
import Report from "./tabs/Report";
import FeedLib from "./tabs/FeedLib.jsx";
import { mixpanelTrackTabs } from "../../util/mixpanelFunctions.js";

// export const slabSizingJsplBaseURl = "https://13.235.0.138.nip.io/api/";
export const slabSizingJsplBaseURl =
  process.env.REACT_APP_SLAB_SIZING_JSPL_BASE_URL;

const Capitalize = (str) => {
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
};

const SlabSizing = () => {
  let param = useParams();
  const size = useWindowSize();
  const [page, setPage] = useState("feed");

  //   const { auth, email } = useContext(NavContext);

  //   const apiCall = async () => {
  //     const requestData = JSON.stringify({
  //       email: email,
  //       category: param.category.toLowerCase(),
  //     });
  //     const response = await axios
  //       .post(baseURL + "vision/v2/product/overview/", requestData, {
  //         credentials: "same-origin",
  //         headers: {
  //           "Content-Type": "application/json",
  //           "X-Auth-Token": auth,
  //         },
  //       })
  //       .then((response) => {
  //         setMaterialData(response.data);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //       });
  //   };

  useEffect(() => {
    mixpanelTrackTabs(page, "Slab Sizing");
  }, [page]);

  const [searchParams, setSearchParams] = useSearchParams();
  const urlParamTabIndex = !isNaN(searchParams.get("tabIndex"))
    ? Number(searchParams.get("tabIndex"))
    : 0;

  // handling tab change
  const handleTabsChange = (index) => {
    if (index !== urlParamTabIndex) {
      setSearchParams({ tabIndex: index }, { replace: true });
    }
  };

  const tabs = [
    {
      label: "Feed",
      index: 0,
      component: <Feed />,
    },
    {
      label: "Alerts",
      index: 1,
      component: <Alert />,
    },
    {
      label: "Feed Library",
      index: 2,
      component: <FeedLib />,
    },
    {
      label: "Report",
      index: 3,
      component: <Report />,
    },
  ];
  useEffect(() => {
    handleTabsChange(urlParamTabIndex);
  }, [urlParamTabIndex]);

  return (
    <div
      className="  font-poppins flex flex-col rounded-lg bg-red"
      style={{ width: size.width >= 768 ? "calc(100vw - 168px)" : "100vw" }}
    >
      <div className="flex justify-start gap-2 items-center mb-3 mt-6">
        <Link
          to={`/vision/qualityTracking/steelslab`}
          style={{
            textDecoration: "none",
          }}
        >
          <img src="/backtick.svg" />
        </Link>
        <p className="text-lg sm:text-2xl font-semibold text-[#024D87]">
          Slab sizing
        </p>
      </div>
      <Tabs
        isLazy={true}
        index={urlParamTabIndex}
        onChange={handleTabsChange}
        paddingRight={{ base: "8px", md: "0px", lg: "0px" }}
      >
        <TabList className="!flex !border-0">
          <div className="flex items-center gap-4 overflow-x-auto h-14 md:h-10">
            {tabs &&
              tabs.map((tab, index) => (
                <Tab
                  key={`${tab.label}-${index}`}
                  minW={"fit-content"}
                  className={`${
                    urlParamTabIndex === tab.index
                      ? "!text-black !text-xs sm:!text-sm !bg-white rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#79767D]"
                      : "!text-xs sm:!text-sm !text-[#938F96] !border-0"
                  }`}
                  onClick={() => handleTabsChange(tab.index)}
                >
                  {tab.label}
                </Tab>
              ))}
          </div>
        </TabList>
        <TabPanels>
          {tabs &&
            tabs.map((tab, index) => (
              <TabPanel
                key={`${tab.label}-${index}`}
                className="!pl-0 !pr-0 h-full"
              >
                {tab.component}
              </TabPanel>
            ))}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default SlabSizing;
