import { useWindowSize } from "@uidotdev/usehooks";
import ReactApexChart from "react-apexcharts";
import { formatDateTimeLocale } from "../utilis/utilityFunctions";

export const FlareStackLineChart = ({ data = [], times = [] }) => {
  const size = useWindowSize();
  const width = size.width < 768 ? 700 : "100%";
  const series = [
    {
      type: "line",
      data: data,
    },
  ];
  const options = {
    chart: {
      toolbar: {
        show: false,
        tools: {
          download: true,
          selection: false,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
        export: {
          csv: {
            dateFormatter(timestamp) {
              return `${formatDateTimeLocale(timestamp)}`;
            },
          },
        },
      },
      animations: {
        enabled: false,
      },
    },
    grid: {
      borderColor: "#e7e7e7",
      strokeDashArray: 6,
      padding: {
        top: -15,
        right: 5,
        bottom: 0,
        left: 5,
      },
    },
    markers: {
      size: 0,
    },
    xaxis: {
      categories: times,
      type: "datetime",
      labels: {
        show: true,
        datetimeUTC: false,
        style: {
          fontSize: "11px",
        },
      },
    },
    yaxis: {
      type: "numeric",
      title: {
        style: {
          color: "#938F96",
          fontSize: "12px",
          fontWeight: 500,
        },
      },
    },
    colors: ["#1C56AC"],
    tooltip: {
      x: {
        format: "dd MMM yyyy HH:mm:ss",
      },
    },
    dataLabel: {
      enabled: false,
    },

    stroke: {
      curve: "straight",
      width: [2, 0],
      dashArray: [0, 6],
      colors: ["#1C56AC"],
    },
    legend: {
      show: false,
    },
    noData: {
      text: "No Data!",
      align: "center",
      verticalAlign: "middle",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: "#757575",
        fontSize: "16px",
      },
    },
  };

  return (
    <div
      id="chart"
      className={`h-[250px] w-full ${
        width === 700 ? "overflow-x-auto" : "overflow-hidden"
      }`}
    >
      <ReactApexChart
        options={options}
        series={series}
        height={"100%"}
        width={width}
      />
    </div>
  );
};
export const DonutChart = ({ data }) => {
  const labels = [];
  const series = [];
  const colors = ["#E46962", "#EF6F12", "#FFBF60", "#6CA6FC"];

  data?.forEach((el) => {
    labels.push(el?.name);
    series.push(el?.data);
  });

  const options = {
    chart: {
      type: "donut",
    },
    legend: {
      show: false,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return `${val}%`;
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          size: "55%",
        },
      },
    },
    labels: labels,
    colors: colors,
  };
  return (
    <div className={`h-full w-full min-w-[350px]`}>
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height={"100%"}
      />
    </div>
  );
};

export const StackedColumnChart = ({ data, times }) => {
  const size = useWindowSize();
  const width = size.width < 768 ? 700 : "100%";
  const options = {
    chart: {
      type: "bar",
      stacked: true,
      toolbar: {
        show: true,
        tools: {
          download: true,
          selection: true,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
      },
      zoom: {
        enabled: true,
      },
    },
    colors: ["#E46962", "#EF6F12", "#FFBF60", "#6CA6FC"],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 6,
        columnWidth: "55%",
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "last",
      },
    },
    xaxis: {
      type: "datetime",
      categories: times,
    },
    yaxis: {
      min: 0,
      max: 100,
    },
    tooltip: {
      x: {
        format: "dd MMM yyyy",
      },
      y: {
        formatter: function (val) {
          return `${val}%`;
        },
      },
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "left",
      fontSize: "13px",
      markers: {
        width: 12,
        height: 12,
        radius: 8,
      },
    },
    grid: {
      borderColor: "#e7e7e7",
      strokeDashArray: 6,
      padding: {
        top: 0,
        right: 5,
        bottom: 0,
        left: 5,
      },
    },
    fill: {
      opacity: 1,
    },
  };
  return (
    <div
      id="stacked-column-chart"
      className={`h-full w-full ${
        width === 700 ? "overflow-x-scroll" : "overflow-hidden"
      }`}
    >
      <ReactApexChart
        options={options}
        series={data}
        height={"100%"}
        width={width}
        type={"bar"}
      />
    </div>
  );
};

export const ColumnChart = ({ data, times }) => {
  const size = useWindowSize();
  const width = size.width < 768 ? 700 : "100%";
  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
        tools: {
          download: false,
          selection: false,
          zoomin: false,
          zoomout: false,
          pan: false,
        },
      },
    },
    colors: ["#E46962"],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
        borderRadius: 6,
        borderRadiusApplication: "end",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: times,
      type: "datetime",
    },
    fill: {
      opacity: 1,
    },

    grid: {
      borderColor: "#e7e7e7",
      strokeDashArray: 6,
      padding: {
        top: 0,
        right: 5,
        bottom: 0,
        left: 5,
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: "top",
      horizontalAlign: "right",

      fontSize: "13px",

      markers: {
        width: 12,
        height: 12,
        radius: 8,
        offsetX: -10,
      },
    },
  };

  return (
    <div
      id="column-chart"
      className={`h-full w-full ${
        width === 700 ? "overflow-x-scroll" : "overflow-hidden"
      }`}
    >
      <ReactApexChart
        options={options}
        series={data}
        height={"100%"}
        width={width}
        type={"bar"}
      />
    </div>
  );
};
