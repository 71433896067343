import React, { useContext, useState } from "react";
import { Button, IconButton, Input, useToast } from "@chakra-ui/react";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useParams } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { AddSubscriptionsModal } from "../Modals/AddSubscriptionsModal";
import axios from "axios";
import { baseURL } from "../../..";
import NavContext from "../../NavContext";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { Tooltip } from "@mui/material";
const MuiTheme = createTheme();

const ActiveSubsTable = ({
  activeSubs,
  organisation,
  fetchTableData,
  page,
  setPage,
}) => {
  const { auth } = useContext(NavContext);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const { clientId, mode } = useParams();
  const toast = useToast();

  const columns = [
    {
      field: "star",
      headerName: "",
      renderCell: ({ row }) => {
        // console.log(row);
        const isStarred =
          organisation === "Ripik.Ai" ? row?.adminStarred : row?.clientStarred;
        return (
          <IconButton
            onClick={(e) => handleStarClick(row?.subscriptionId, isStarred, e)}
          >
            {isStarred ? (
              <StarIcon style={{ color: "#FFC107" }} />
            ) : (
              <StarOutlineIcon />
            )}
          </IconButton>
        );
      },
    },
    {
      field: "imageUrl",
      headerName: "",
      renderCell: ({ row }) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={
              row?.imageUrl
                ? row?.imageUrl
                : "/superadmin/clients/companyLogo.png"
            } // Replace with the actual import or URL for your client logo
            alt=""
            style={{
              width: 24,
              height: 20,
              borderRadius: "50%",
              // marginRight: 8,
            }}
          />
        </div>
      ),
    },
    {
      field: "clientName",
      headerName: "client",
    },

    { field: "product", headerName: "product" },
    {
      field: "productGroup",
      headerName: "group",
    },
    {
      field: "productCategory",
      headerName: "parent",
    },
    {
      field: "location",
      headerName: "location",
    },
    {
      field: "status",
      headerName: "status",
      renderCell: ({ row }) => {
        if (row.status === "INACTIVE") {
          return (
            <span className="text-[#938F96] text-sm font-semibold">
              Inactive
            </span>
          );
        } else if (row.status === "ACTIVE") {
          return (
            <span className="text-[#7AC958] text-sm font-semibold">Active</span>
          );
        } else if (row.status === "MODIFIED") {
          return (
            <span className="text-[#FFC107] text-sm font-semibold">
              Modified
            </span>
          );
        } else {
          // Handle other status values if needed
          return (
            <span className="text-[your-default-color] text-sm font-semibold">
              {row.status}
            </span>
          );
        }
      },
    },
    {
      field: "createdAt",
      headerName: "sys start date",
      sortComparator: (v1, v2, row1, row2) => {
        // Custom sorting logic for date strings
        const date1 = new Date(v1);
        const date2 = new Date(v2);

        if (date1 < date2) {
          return -1;
        }
        if (date1 > date2) {
          return 1;
        }
        // If dates are equal, sort based on alphabetical order of another field (e.g., clientName)
        const alphabeticalOrderComparison = row1?.clientName?.localeCompare(
          row2?.clientName
        );
        return alphabeticalOrderComparison;
      },
      valueFormatter: (params) => {
        return params.value
          ? new Date(params.value).toDateString().split(" ")[2] +
              " " +
              new Date(params.value).toDateString().split(" ")[1] +
              " '" +
              new Date(params.value).toDateString().split(" ")[3].slice(2, 4)
          : "";
      },
    },
    {
      field: "expiryAt",
      headerName: "subscription end date",
      valueFormatter: (params) => {
        return params.value
          ? new Date(params.value).toDateString().split(" ")[2] +
              " " +
              new Date(params.value).toDateString().split(" ")[1] +
              " '" +
              new Date(params.value).toDateString().split(" ")[3].slice(2, 4)
          : "";
      },
    },
    {
      field: "remarks",
      headerName: "remarks",
    },
    {
      field: "editButton",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      width: 100, // Set the width as needed
      renderCell: ({ row }) => (
        <div>
          {organisation === "Ripik.Ai" && (
            <IconButton
              aria-label="edit"
              color="#1E88E5"
              onClick={() => {
                handleUpdateRemarks(row);
              }}
            >
              <EditNoteIcon />
            </IconButton>
          )}
        </div>
      ),
    },
  ];

  const headerClass =
    "text-sm font-normal text-[#79767D] bg-[#DDEEFF] capitalize";
  const cellClass = "text-sm font-normal text-[#3E3C42] whitespace-nowrap";
  const flexMap = [0.3, 0.3, 2, 2, 1.5, 1, 1, 1, 1, 1, 3, 0.5];
  columns.map((val, idx) => {
    val["headerClassName"] = headerClass;
    val["cellClassName"] = cellClass;
    val["flex"] = flexMap[idx];
  });

  const handleStarClick = async (id, isStarred, event) => {
    // Prevent the click from triggering when clicking on a button inside the row
    if (event.target.tagName.toLowerCase() === "button") {
      return;
    }
    const data = {
      subscriptionId: id,
    };
    if (organisation === "Ripik.Ai") {
      data.adminStarred = !isStarred;
    } else {
      data.clientStarred = !isStarred;
    }

    try {
      const response = await axios.patch(
        baseURL + `subscription/update`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );

      fetchTableData();
    } catch (e) {
      console.error(e);
    }
  };

  const handleUpdateRemarks = (row) => {
    setSelectedRowData(row); // Set the selected row data when the "Update" button is clicked
    setIsEditModalOpen(true);
  };

  const handlePageChange = (params) => {
    setPage((prev) => params.page);
  };

  return (
    <div className="overflow-x-auto mt-4 min-h-[300px]">
      <ThemeProvider theme={MuiTheme}>
        <DataGrid
          // rows={extendedRowData}
          rows={activeSubs}
          columns={columns}
          getRowId={(row) => row.subscriptionId}
          columnVisibilityModel={{
            subscriptionstartdate: organisation === "Ripik.Ai" ? false : true,
            clientName: organisation === "Ripik.Ai" ? true : false,
            expiryAt: organisation === "Ripik.Ai" ? false : true,
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
            pagination: { paginationModel: { pageSize: 7, page: page } },
          }}
          onPaginationModelChange={handlePageChange}
          sx={{ minWidth: "1200px", minHeight: "300px" }}
        />
      </ThemeProvider>

      {/* Add or Edit Subscription Modal */}
      {isEditModalOpen && (
        <AddSubscriptionsModal
          isOpen={isEditModalOpen}
          onClose={() => {
            setIsEditModalOpen(false);
            setSelectedRowData(null);
          }}
          data={selectedRowData}
          fetchTableData={fetchTableData}
          isEditMode={true}
        />
      )}
    </div>
  );
};

export default ActiveSubsTable;
