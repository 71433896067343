import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";

import ViewClient from "../AddClients/ViewClient";
import AllotToken from "../../Admin/Tabs/AllotToken";
import TokenTransaction from "../../Admin/Tabs/TokenTransaction";
import SessionLogs from "../../Admin/Tabs/SessionLogs";
import UserMgmt from "../../Admin/Tabs/UserMgmt";
import ActiveSubs from "../../Admin/Tabs/ActiveSubs";
import DevelopmentInProgress from "../Deploy/DevelopmentInProgress";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import AdminTransactions from "../../Admin/Tabs/AdminTransactions";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const Capitalize = (str) => {
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
};

const ViewTabs = ({ setShowClientTab, selectedClient }) => {
  const navigate = useNavigate();
  const { clientId } = useParams();
  const [isDeployClicked, setDeployClicked] = useState(false);
  const size = useWindowSize();
  let param = useParams();
  const [plantCamMap, setPlantCamMap] = useState({});
  const [page, setPage] = useState("summary");
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };

  const handleBackButton = () => {
    setShowClientTab((prev) => "addclient");
  };

  return (
    <>
      {!isDeployClicked ? (
        <div
          className=" font-roboto flex flex-col rounded-lg"
        //   style={{ width: size.width >= 768 ? "calc(100vw - 168px)" : "100vw" }}
        >
          <div className="flex items-between mb-3">
            <div className="cursor-pointer w-8" onClick={handleBackButton}>
              <KeyboardBackspaceIcon sx={{ color: "#084298" }} />
            </div>
            <p className="text-lg sm:text-2xl font-semibold text-[#024D87]">
              View Details
            </p>
          </div>
          {/* <div className="flex border"> */}
          <Tabs
            className="bg-white rounded-md pt-4"
            width={"full"}
            index={tabIndex}
            onChange={handleTabsChange}
            isLazy={true}
          >
            <TabList
              className="!flex !border-0 !justify-between !items-center"
              width={"full"}
            >
              <div className="flex sm:w-[75%] xl:w-[100%] items-center gap-4 overflow-x-auto h-14 xl:h-10">
                <Tab
                  className={
                    tabIndex == 0
                      ? "!text-[#605D64] !text-xs xl:!text-sm !bg-[#6CABFC] !bg-opacity-20 rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#6CA6FC]"
                      : "!text-xs xl:!text-sm !text-[#605D64] !border !border-[#EBEBEB] !rounded-full"
                  }
                // onClick={() => setTabIndex()
                >
                  Summary
                </Tab>
                <Tab
                  className={
                    tabIndex === 1
                      ? "!text-[#605D64] !text-xs xl:!text-sm !bg-[#6CABFC] !bg-opacity-20 rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#6CA6FC]"
                      : "!text-xs xl:!text-sm !text-[#605D64] !border !border-[#EBEBEB] !rounded-full"
                  }
                // onClick={() => setPage("activesubs")}
                >
                  Subscriptions
                </Tab>
                <Tab
                  className={
                    tabIndex == 2
                      ? "!text-[#605D64] !text-xs xl:!text-sm !bg-[#6CABFC] !bg-opacity-20 rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#6CA6FC]"
                      : "!text-xs xl:!text-sm !text-[#605D64] !border !border-[#EBEBEB] !rounded-full"
                  }
                // onClick={() => setPage("user_management")}
                >
                  User Management
                </Tab>
                <Tab
                  className={
                    tabIndex == 3
                      ? "!text-[#605D64] !text-xs xl:!text-sm !bg-[#6CABFC] !bg-opacity-20 rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#6CA6FC]"
                      : "!text-xs xl:!text-sm !text-[#605D64] !border !border-[#EBEBEB] !rounded-full"
                  }
                // onClick={() => setPage("session_logs")}
                >
                  Session Logs
                </Tab>
                <Tab
                  className={
                    tabIndex == 4
                      ? "!text-[#605D64] !text-xs xl:!text-sm !bg-[#6CABFC] !bg-opacity-20 rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#6CA6FC]"
                      : "!text-xs xl:!text-sm !text-[#605D64] !border !border-[#EBEBEB] !rounded-full"
                  }
                // onClick={() => setPage("allot_token")}
                >
                  Allocate Tokens
                </Tab>
                <Tab
                  className={
                    tabIndex == 5
                      ? "!text-[#605D64] !text-xs xl:!text-sm !bg-[#6CABFC] !bg-opacity-20 rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#6CA6FC]"
                      : "!text-xs xl:!text-sm !text-[#605D64] !border !border-[#EBEBEB] !rounded-full"
                  }
                // onClick={() => setPage("token_transactions")}
                >
                  Allocation History
                </Tab>

                <Tab
                  className={
                    tabIndex == 6
                      ? "!text-[#605D64] !text-xs xl:!text-sm !bg-[#6CABFC] !bg-opacity-20 rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#6CA6FC]"
                      : "!text-xs xl:!text-sm !text-[#605D64] !border !border-[#EBEBEB] !rounded-full"
                  }
                // onClick={() => setPage("admin_transactions")}
                >
                  Token Transactions
                </Tab>
              </div>
              {/* <PrimaryButton
                text={"Deploy"}
                width={"fit-content"}
                onClick={() => setDeployClicked(true)}
              /> */}
            </TabList>
            <div className="flex">
              <TabPanels className="!pt-4">
                <TabPanel className="!pl-0 !pr-0">
                  {selectedClient && (
                    <ViewClient
                      setDeployClicked={setDeployClicked}
                      selectedClient={selectedClient}
                    />
                  )}
                </TabPanel>
                <TabPanel className="!pl-0 !pr-0">
                  {selectedClient && (
                    <ActiveSubs
                      clientOrg={selectedClient.organisation}
                      mode="view"
                      isSuperAdminClient={
                        selectedClient.organisation !== "Ripik.Ai"
                          ? true
                          : false
                      }
                    />
                  )}
                </TabPanel>
                <TabPanel className="!pl-0 !pr-0">
                  {selectedClient && (
                    <UserMgmt
                      clientId={selectedClient.clientId}
                      clientOrg={selectedClient.organisation}
                      mode="view"
                    />
                  )}
                </TabPanel>
                <TabPanel className="!pl-0 !pr-0">
                  {selectedClient && (
                    <SessionLogs
                      clientId={selectedClient.clientId}
                      clientOrg={selectedClient.organisation}
                      mode="view"
                    />
                  )}
                </TabPanel>
                <TabPanel className="!pl-0 !pr-0">
                  {selectedClient && (
                    <AllotToken
                      clientId={selectedClient.clientId}
                      clientOrg={selectedClient.organisation}
                      mode="view"
                    />
                  )}
                </TabPanel>
                <TabPanel className="!pl-0 !pr-0">
                  {selectedClient && (
                    <TokenTransaction
                      clientId={selectedClient.clientId}
                      clientOrg={selectedClient.organisation}
                    />
                  )}
                </TabPanel>

                <TabPanel className="!pl-0 !pr-0">
                  {selectedClient && (
                    <AdminTransactions
                      clientOrg={selectedClient.organisation}
                    />
                  )}
                </TabPanel>
              </TabPanels>
            </div>
          </Tabs>
        </div>
      ) : (
        <DevelopmentInProgress
          setDeployClicked={setDeployClicked}
        // setPage={setPage}
        // setTabIndex={setTabIndex}
        />
      )}
    </>
  );
};

export default ViewTabs;
