import { useContext, useEffect, useState } from "react";
import FeedDown from "./FeedDown";
import { Skeleton } from "@chakra-ui/react";
import { baseURL } from "../../../..";
import axios from "axios";
import VideoByCamId from "./VideoByCamId";
import NavContext from "../../../NavContext";

const CameraFeed = ({ tabName, cameraStructure, material, clientId }) => {
  const { auth } = useContext(NavContext);
  const [cameraData, setCameraData] = useState(cameraStructure);
  const [loadingApi, setLoadingApi] = useState(false);
  const apicall = async () => {
    try {
      const requestData = JSON.stringify({
        clientId: clientId,
        useCase: material,
      });
      const response = await axios.post(
        baseURL + "vision/v1/workforce/recipeCompliance/live/feed/",
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );

      if (response?.data && Object.keys(response.data).length > 0) {
        const filteredKey = Object.keys(response.data)?.filter(
          (obj) => obj?.toLowerCase() === tabName.toLowerCase()
        );
        if (filteredKey) {
          const filteredObject = response.data[filteredKey];

          // Update the cameraData state with URLs
          const updatedCameraData = cameraData?.map((camera) => ({
            ...camera,
            cameraUrl: filteredObject[camera.cameraId] || "noimage",
            loading: true,
          }));

          setCameraData(updatedCameraData);
        } else {
          const updatedCameraData = cameraData?.map((camera) => ({
            ...camera,
            cameraUrl: "noimage",
            loading: true,
          }));

          setCameraData(updatedCameraData);
        }
      } else {
      }
    } catch (error) {
      const updatedCameraData = cameraData?.map((camera) => ({
        ...camera,
        cameraUrl: "noimage",
        loading: false,
      }));

      setCameraData(updatedCameraData);
    } finally {
      setLoadingApi(false);
    }
  };

  useEffect(() => {
    setLoadingApi(true);

    apicall();
    const interval = setInterval(apicall, 10000);

    return () => {
      clearInterval(interval);
      setCameraData();
    };
  }, [tabName]);

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 border gap-3 bg-gray-100 p-3 rounded-lg  shadow-md overflow-y-auto max-h-[35vh] sm:max-h-[53vh] lg:max-h-[69vh]">
      {loadingApi ? (
        <>
          {[1, 2].map(() => {
            return (
              <div className="w-full  lg:w-full flex flex-col gap-2 h-full ">
                <p className="">
                  <Skeleton h={"20px"} w={"100px"} />
                </p>

                <Skeleton h={"170px"} />
              </div>
            );
          })}
        </>
      ) : (
        cameraData?.map((item, id) => {
          return (
            <div
              key={id}
              className="w-full  lg:w-full flex flex-col gap-2 h-full "
            >
              <p className="">
                {item?.name || item?.name?.length > 0 ? (
                  item.name
                ) : (
                  <Skeleton h={"20px"} w={"100px"} />
                )}
              </p>
              {item?.cameraId ? (
                <VideoByCamId
                  material={material}
                  clientId={clientId}
                  camId={item?.cameraId}
                  item={item}
                  timestamp={
                    cameraData?._id?.timestamp ? cameraData._id.timestamp : ""
                  }
                />
              ) : (
                <Skeleton h={"180px"} />
              )}
            </div>
          );
        })
      )}
    </div>
  );
};

export default CameraFeed;
