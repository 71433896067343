import { Skeleton } from "@chakra-ui/react";
import { useWindowSize } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import DisplayImage from "../../SelfService/utils/DisplayImage";

function Camerafeed({ show, cameraAngles, masking, alreadyEditedByUser }) {
  const size = useWindowSize();
  const [annotationData, setAnnotationData] = useState([]);

  useEffect(() => {
    if (show == 0) {
      setAnnotationData([
        {
          points: masking,
          label: "slab",
        },
      ]);
    }
  }, [masking, show]);
  const imgLink =
    show == 0
      ? cameraAngles?.TopView
      : show == 1
      ? cameraAngles?.RightView
      : show == 2
      ? cameraAngles?.LeftView
      : cameraAngles?.AnnotatedTopView;

  return (
    <div className={`flex flex-col w-full  h-[33vh]  gap-1 `}>
      <div className="w-full h-6vh  flex justify-between p-0 items-center  ">
        <p className="text-[#605D64] text-[13px]">
          {" "}
          {show == 0
            ? "Top View"
            : show == 1
            ? "Right side view"
            : "Left side view"}
        </p>
        {/* <div className="flex gap-1 items-center justify-center">
          <div className="w-full flex items-center justify-center border-2 border-red-500">
            <img
              src="/slabsizing/standard.svg"
              alt=""
              style={{
                width: "100%",
                height: "100%",
                border: "1px solid",
              }}
            />
          </div>
          <p className="text-[#605D64] text-[13px]">Standard</p>
        </div> */}
      </div>
      <div className={`w-full h-[27vh] overflow-auto bg-black `}>
        {imgLink === "" ? (
          <Skeleton width="100%" height="100%" />
        ) : show == 0 ? (
          <div className="w-[410px] h-[190px] bg-black ">
            {annotationData.length > 0 &&
            annotationData[0].points &&
            annotationData[0].points.length > 0 ? (
              <DisplayImage
                width={410}
                height={190}
                url={imgLink}
                annotationData={annotationData}
                alreadyEditedByUser={alreadyEditedByUser}
              />
            ) : (
              <img
                src={imgLink}
                alt=""
                style={{ width: "100%", height: "27vh" }}
              />
            )}
          </div>
        ) : (
          <img src={imgLink} alt="" style={{ width: "100%", height: "27vh" }} />
        )}
      </div>
    </div>
  );
}

export default Camerafeed;
