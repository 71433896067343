import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CreateCam from "../CreateCam";
import CamInference from "./CamInference";
import CamAlerts from "./CamAlerts";
import CamHistory from "./CamHistory";

const dummy = {
  name: "Camera 1",
  rtsp: "rtsp://admin:jsplIT321@115.247.181.84/",
  assignedProject: "Azure Truck crane",
  minProb: 0.45,
};

const CamViewDetail = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [page, setPage] = useState(
    searchParams.get("page") ? Number(searchParams.get("page")) : 0
  );
  const handleTabChange = (val) => {
    if (val !== page) {
      setSearchParams({ page: val }, { replace: true });
      setPage(val);
    }
  };
  const tabList = [
    {
      index: 0,
      tabName: "Camera details",
      element: <CreateCam data={dummy} viewMode={true} />,
    },
    {
      index: 1,
      tabName: "Inference",
      element: <CamInference />,
    },
    {
      index: 2,
      tabName: "Alerts",
      element: <CamAlerts />,
    },
    {
      index: 3,
      tabName: "History",
      element: <CamHistory />,
    },
  ];
  return (
    <div className="flex flex-col gap-2 mt-6">
      <div className="flex gap-2 items-center">
        <img
          src="/backtick.svg"
          alt="back"
          className="hover:scale-105 cursor-pointer"
          onClick={() => {
            navigate("/Sandbox?selectedGroup=data+feed");
          }}
        />
        <p className="text-[#084298] text-xl font-medium">Camera 1</p>
      </div>
      <div className="rounded-lg bg-white pt-6 flex flex-col gap-4 w-full h-full relative">
        <Tabs isLazy={true} index={page}>
          <TabList
            display={"flex"}
            justifyContent={"space-between"}
            border={0}
            alignItems={"center"}
          >
            <div className="flex items-center gap-4 overflow-x-auto max-w-full h-fit px-2 py-[2px]">
              {tabList.map((item) => {
                let x = item.index;
                return (
                  <Tab
                    px={"24px"}
                    py={"6px"}
                    borderRadius={"4px"}
                    borderWidth={"1px"}
                    fontSize={"16px"}
                    style={{
                      borderColor: x == page ? "#6CA6FC" : "#EBEBEB",
                      fontWeight: x == page ? 500 : 400,
                      color: x == page ? "#084298" : "#605D64",
                      cursor: x != page ? "pointer" : "default",
                      backgroundColor: x == page ? "#F1F7FF" : "#fff",
                    }}
                    whiteSpace={"nowrap"}
                    onClick={() => handleTabChange(x)}
                  >
                    {item.tabName}
                  </Tab>
                );
              })}
            </div>
          </TabList>
          <TabPanels>
            {tabList.map((item) => {
              return (
                <TabPanel className="!pl-0 !pr-0">{item.element}</TabPanel>
              );
            })}
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
};

export default CamViewDetail;
