import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
} from "@chakra-ui/react";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import TonalButton from "../../../util/Buttons/TonalButton";

const CrusherEffectivenessSubmitModal = ({
  openModal,
  onClose,
  handleModal,
  handleSubmit,
  rowIdmapping
}) => {

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={openModal}
      onClose={onClose}
      isCentered="true"
    >
      <ModalOverlay />
      <ModalContent style={{ borderRadius: "8px" }} maxW="420px">
        <ModalHeader>
          <div className="flex justify-center">
            {Object.keys(rowIdmapping)?.length >0
              ? <p className="!text-base">1 or more fields are not filled. Please check</p>
              : <p className="!text-base">Please click "Confirm" to submit</p>}
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="flex gap-4 justify-center pb-3">
            <PrimaryButton
              text={"Confirm"}
              width={"fit-content"}
              onClick={handleSubmit}
              disable={Object.keys(rowIdmapping)?.length > 0}
            />
            <TonalButton
              text={"Cancel"}
              width={"fit-content"}
              onClick={handleModal}
            />
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CrusherEffectivenessSubmitModal;
