import React, { useState } from "react";
import TonalButton from "../../../../util/Buttons/TonalButton";
import PrimaryButton from "../../../../util/Buttons/PrimaryButton";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalOverlay,
    ModalCloseButton,
    ModalContent,
    Textarea,
    Input,
    Text,
    Checkbox,
    ChakraProvider,
    Select,
} from "@chakra-ui/react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import TextEditor from "./TextEditor";

const UpdateModal = ({
    isOpen,
    handleCloseEditModal,
    alertId,
    view,
    alert,
    handleChange,
    index,
    handleSubmit,
    handleAddParameter,
    cameraOptions,
    systemOptions,
    productOptions,
    handleDeleteParameter,
    handleMenuChange,
    handleDeleteContact,
    handleAddContact,
    alertType,
    defaultContacts,
    handleAddAddParameter,
    handleDeleteAddParameter
}) => {

    const modules = {
        toolbar: [
            [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' },
            { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image', 'video'],
            ['clean']
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        }
    }

    const formats = [
        'font', 'size',
        'bold', 'italic', 'underline', 'strike',
        'color', 'background',
        'script',
        'header', 'blockquote', 'code-block',
        'indent', 'list',
        'direction', 'align',
        'link', 'image', 'video', 'formula',
    ]

    const [disable, setDisable] = useState(view ? true : false);

    const dataOptions = {
        alertType: {
            type: ["Camera", "System", "Product"]
        },
        cameras: [
            { cameraId: "1", cameraName: "cam1" },
            { cameraId: "2", cameraName: "cam2" },
            { cameraId: "3", cameraName: "cam3" }
        ],
        unitTime: {
            type: ["second", "minutes", "hours", "shift", "day"],
        },
        priority: {
            type: ["sev-1 critical", "sev-2 high", "sev-3 medium", "sev-4 low", "sev-5 information"],
        },
        medium: {
            type: ["App", "Email", "WhatsApp", "SMS"],
        },
        aggLogic: {
            type: ["none", "average", "min", "max", "sum",]
        },
        key: {
            type: ["CPU Util Percent", "GPU Util Percent", "RAM Util Percent", "Storage Util Percent", "CPU Util cores", "GPU Util size", "RAM Util size", "Storage Util size", "Networking In", "Networking Out"]
        },
        alertSource: {
            type: ["Object store", "Message queue", "Database", "Rest Endpoint"]
        },
        trigger: {
            type: ["Lambda", "Azure function", "Script"]
        },
        destination: {
            type: ["Database 1", "Database 2"]
        },
        messageContent: {
            type: ["Alert only", "Alert and snapshot"]
        }
    };

    const autoResize = (event) => {
        const textarea = event.target;
        const maxHeight = 200; // Set your desired maximum height
        textarea.style.height = "auto";
        textarea.style.height = `${Math.min(textarea.scrollHeight, maxHeight)}px`;
    };

    return (
        <Modal
            isOpen={isOpen >= 0}
            onClose={() => handleCloseEditModal()}
            isCentered
            size={"4xl"}
        >
            <ModalOverlay />
            <ModalContent>
                <div className="text-white w-full h-12 flex bg-[#2660B6] font-semibold justify-center items-center rounded-t-md">
                    Add new Alert
                </div>
                <ModalCloseButton className="" color={"white"} />
                <ModalBody className="w-full">
                    <div className="flex flex-col gap-2 w-full overflow-scroll overflow-x-scroll">



                        <div className="flex flex-row items-center gap-1">
                            <p className="text-xs text-[#3E3C42]">Alert UUID</p>
                            <p>{alertId}</p>
                        </div>

                        {(alertType == 'Usecase' || alertType == 'Other') && <div className="flex flex-row mt-6 gap-2 sm:w-[47%]">
                            <div className="w-[50%] flex flex-col gap-3">
                                <p className="text-[#2660B6] text-sm font-medium">
                                    Alert source
                                </p>
                                {view === true ? (
                                    <p className="text-[#525056] text-sm font-medium">
                                        {alert?.alertSource}
                                    </p>
                                ) : (
                                    <div className="w-full">
                                        <Select
                                            value={alert?.alertSource}
                                            onChange={(e) =>
                                                handleChange("alertSource", e.target.value, index)
                                            }
                                            borderColor={"gray.300"}
                                            borderWidth={'2px'}
                                            className="w-[100%] border-[2px] !rounded-lg max-h-[300px] overflow-y-auto"
                                            size={'sm'}
                                            disabled={disable}
                                        >
                                            <option value="" disabled selected>
                                                Select one
                                            </option>
                                            {dataOptions?.alertSource?.type?.map((item, index) => (
                                                <option value={item}>{item}</option>
                                            ))}
                                        </Select>
                                    </div>
                                )}
                            </div>
                            <div className="w-[50%] flex flex-col gap-3">
                                <p className="text-[#2660B6] text-sm font-medium">
                                    Url
                                </p>
                                {view === true ? (
                                    <p className="text-[#525056] text-sm font-medium">
                                        {alert?.alertSourceUrl}
                                    </p>
                                ) : (
                                    <div className="w-full">
                                        <Input
                                            type="text"
                                            defaultValue={alert?.alertSourceUrl}
                                            onChange={(e) =>
                                                handleChange("alertSourceUrl", e.target.value, index)
                                            }
                                            borderColor={"gray.300"}
                                            borderWidth={"2px"}
                                            isDisabled={disable}
                                            size='sm'
                                            className="!rounded-lg"
                                        />
                                        {alert?.alertSourceUrl?.length > 100 && (
                                            <div>
                                                <Text color="red.500" fontSize="sm" mt="1">
                                                    You have exceed the character limit of 100 by{" "}
                                                    {alert?.alertSourceUrl?.length - 100}
                                                </Text>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        }

                        {(alertType == 'Usecase' || alertType == 'Other') && <div className="flex flex-row mt-1 gap-2 sm:w-[47%]">
                            <div className="w-[50%] flex flex-col gap-3">
                                <p className="text-[#2660B6] text-sm font-medium">
                                    Destination
                                </p>
                                {view === true ? (
                                    <p className="text-[#525056] text-sm font-medium">
                                        {alert?.destination}
                                    </p>
                                ) : (
                                    <div className="w-full">
                                        <Select
                                            value={alert?.destination}
                                            onChange={(e) =>
                                                handleChange("destination", e.target.value, index)
                                            }
                                            borderColor={"gray.300"}
                                            borderWidth={'2px'}
                                            className="w-[100%] border-[2px] !rounded-lg max-h-[300px] overflow-y-auto"
                                            size={'sm'}
                                            disabled={disable}
                                        >
                                            <option value="" disabled selected>
                                                Select one
                                            </option>
                                            {dataOptions?.destination?.type?.map((item, index) => (
                                                <option value={item}>{item}</option>
                                            ))}
                                        </Select>
                                    </div>
                                )}
                            </div>
                            <div className="w-[50%] flex flex-col gap-3">
                                <p className="text-[#2660B6] text-sm font-medium">
                                    Url
                                </p>
                                {view === true ? (
                                    <p className="text-[#525056] text-sm font-medium">
                                        {alert?.destinationUrl}
                                    </p>
                                ) : (
                                    <div className="w-full">
                                        <Input
                                            minWidth={''}
                                            type="text"
                                            defaultValue={alert?.destinationUrl}
                                            onChange={(e) =>
                                                handleChange("destinationUrl", e.target.value, index)
                                            }
                                            borderColor={"gray.300"}
                                            borderWidth={"2px"}
                                            isDisabled={disable}
                                            size='sm'
                                            className="!rounded-lg"
                                        />
                                        {alert?.destinationUrl?.length > 100 && (
                                            <div>
                                                <Text color="red.500" fontSize="sm" mt="1">
                                                    You have exceed the character limit of 100 by{" "}
                                                    {alert?.destinationUrl?.length - 100}
                                                </Text>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        }

                        {(alertType == 'Usecase' || alertType == 'Other') && <div className="flex flex-row mt-1 gap-2 sm:w-[47%]">
                            <div className="w-[50%] flex flex-col gap-3 ">
                                <p className="text-[#2660B6] text-sm font-medium">
                                    Trigger
                                </p>
                                {view === true ? (
                                    <p className="text-[#525056] text-sm font-medium">
                                        {alert?.trigger}
                                    </p>
                                ) : (
                                    <div className="w-full">
                                        <Select
                                            value={alert?.trigger}
                                            onChange={(e) =>
                                                handleChange("trigger", e.target.value, index)
                                            }
                                            borderColor={"gray.300"}
                                            borderWidth={'2px'}
                                            className="w-[100%] border-[2px] !rounded-lg max-h-[300px] overflow-y-auto"
                                            size={'sm'}
                                            disabled={disable}
                                        >
                                            <option value="" disabled selected>
                                                Select one
                                            </option>
                                            {dataOptions?.trigger?.type?.map((item, index) => (
                                                <option value={item}>{item}</option>
                                            ))}
                                        </Select>
                                    </div>
                                )}
                            </div>
                        </div>
                        }

                        {(alertType == 'Usecase' || alertType == 'Other') &&
                            <div className="sm:w-[47%] flex flex-col gap-3 mt-1">
                                <p className="text-[#2660B6] text-sm font-medium">
                                    Paste {alert?.trigger}
                                </p>
                                {view === true ? (
                                    <p className="text-[#525056] text-sm font-medium">
                                        {alert?.triggerValue}
                                    </p>
                                ) : (
                                    <div className="w-full">
                                        <Textarea
                                            // placeholder="Whatsapp Template"
                                            borderWidth={"2px"}
                                            value={alert?.triggerValue}
                                            onChange={(e) => {
                                                handleChange("triggerValue", e.target.value, index)
                                                autoResize(e);
                                            }
                                            }
                                            //style={{ display: "block", overflow: "hidden", resize: "none" }}
                                            isDisabled={disable}
                                        />

                                        {alert?.triggerValue?.length > 1000 && (
                                            <div>
                                                <Text color="red.500" fontSize="sm" mt="1">
                                                    You have exceed the character limit of 1000 by{" "}
                                                    {alert?.triggerValue?.length - 1000}
                                                </Text>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        }

                        <p className="text-[black] text-lg font-medium mt-6">
                            Alert parameters
                        </p>

                        {
                            alert?.parameters?.map((parameter, idx) => {
                                if (parameter?.isDeleted === true) return;
                                return (
                                    <div className="flex flex-row border rounded-md gap-1 w-full overflow-x-scroll p-2">

                                        <div className="w-[95%] flex flex-col gap-2">
                                            <div className="grid min-[410px]:grid-cols-2 min-[580px]:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 w-[100%] gap-2">
                                                {(alertType != "Usecase" && alertType != "Other") &&
                                                    <div className="flex flex-col gap-1 ">
                                                        <p className="text-[#2660B6] text-sm font-medium">
                                                            Key<span className="text-red-500">*</span>
                                                        </p>
                                                        {view === true ? (
                                                            <p className="text-[#525056] text-sm font-medium">
                                                                {parameter?.key}
                                                            </p>
                                                        ) : (
                                                            <div className="w-full">
                                                                {alertType == "system" ?
                                                                    (<Select
                                                                        value={parameter?.key}
                                                                        onChange={(e) => {
                                                                            handleChange("key", e.target.value, index, idx);
                                                                        }}
                                                                        borderColor={"gray.300"}
                                                                        borderWidth={'2px'}
                                                                        size='sm'
                                                                        className="w-[100%] border-[2px] !rounded-lg max-h-[300px] overflow-y-auto "
                                                                        disabled={disable}
                                                                    >
                                                                        <option value="" disabled selected>
                                                                            Select one
                                                                        </option>
                                                                        {dataOptions?.key?.type?.map((item, i) => (
                                                                            <option value={item}>{item}</option>
                                                                        ))}
                                                                    </Select>) :
                                                                    (<Input
                                                                        type="text"
                                                                        defaultValue={parameter?.key}
                                                                        onChange={(e) =>
                                                                            handleChange("key", e.target.value, index, idx)
                                                                        }
                                                                        size='sm'
                                                                        borderColor={"gray.300"}
                                                                        borderWidth={"2px"}
                                                                        className="!rounded-lg"
                                                                        isDisabled={disable}
                                                                    />)}
                                                                {parameter?.key?.length > 100 && (
                                                                    <div>
                                                                        <Text color="red.500" fontSize="sm" mt="1">
                                                                            You have exceed the character limit of 100 by{" "}
                                                                            {parameter?.key?.length - 100}
                                                                        </Text>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                                {(alertType != "Usecase" && alertType != "Other") &&
                                                    <div className="flex flex-col gap-1 ">
                                                        <p className="text-[#2660B6] text-sm font-medium">
                                                            Value
                                                        </p>
                                                        {view === true ? (
                                                            <p className="text-[#525056] text-sm font-medium">
                                                                {parameter?.value}
                                                            </p>
                                                        ) : (
                                                            <div className="w-full">
                                                                <Input
                                                                    type="text"
                                                                    defaultValue={parameter?.value}
                                                                    onChange={(e) =>
                                                                        handleChange("value", e.target.value, index, idx)
                                                                    }
                                                                    className="!rounded-lg"
                                                                    borderColor={"gray.300"}
                                                                    borderWidth={"2px"}
                                                                    size='sm'
                                                                    isDisabled={disable}
                                                                />
                                                                {parameter?.value?.length > 100 && (
                                                                    <div>
                                                                        <Text color="red.500" fontSize="sm" mt="1">
                                                                            You have exceed the character limit of 100 by{" "}
                                                                            {parameter?.value?.length - 100}
                                                                        </Text>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                                {(alertType != "Usecase" && alertType != "Other") &&
                                                    <div className="flex flex-col gap-1 ">
                                                        <p className="text-[#2660B6] text-sm font-medium">
                                                            Unit
                                                        </p>
                                                        {view === true ? (
                                                            <p className="text-[#525056] text-sm font-medium">
                                                                {parameter?.unit}
                                                            </p>
                                                        ) : (
                                                            <div className="w-full">
                                                                <Input
                                                                    type="text"
                                                                    defaultValue={parameter?.unit}
                                                                    onChange={(e) =>
                                                                        handleChange("unit", e.target.value, index, idx)
                                                                    }
                                                                    size='sm'
                                                                    className="!rounded-lg"
                                                                    borderColor={"gray.300"}
                                                                    borderWidth={"2px"}
                                                                    isDisabled={disable}
                                                                />
                                                                {parameter?.unit?.length > 100 && (
                                                                    <div>
                                                                        <Text color="red.500" fontSize="sm" mt="1">
                                                                            You have exceed the character limit of 100 by{" "}
                                                                            {parameter?.unit?.length - 100}
                                                                        </Text>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                                {(alertType != "Usecase" && alertType != "Other") &&
                                                    <div className="flex flex-col gap-1  ">
                                                        <p className="text-[#2660B6] text-sm font-medium">
                                                            Trigger Logic<span className="text-red-500">*</span>
                                                        </p>
                                                        {view === true ? (
                                                            <p className="text-[#525056] text-sm font-medium">
                                                                {parameter?.logic}
                                                            </p>
                                                        ) : (
                                                            <div className="w-full">
                                                                <Input
                                                                    type="text"
                                                                    defaultValue={parameter?.logic}
                                                                    onChange={(e) =>
                                                                        handleChange("logic", e.target.value, index, idx)
                                                                    }
                                                                    className="!rounded-lg"
                                                                    size='sm'
                                                                    borderColor={"gray.300"}
                                                                    borderWidth={"2px"}
                                                                    isDisabled={disable}
                                                                />
                                                                {parameter?.logic?.length > 100 && (
                                                                    <div>
                                                                        <Text color="red.500" fontSize="sm" mt="1">
                                                                            You have exceed the character limit of 100 by{" "}
                                                                            {parameter?.logic?.length - 100}
                                                                        </Text>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                                <div className="flex flex-col gap-1 ">
                                                    <p className="text-[#2660B6] text-sm font-medium">
                                                        Alert frequency
                                                    </p>
                                                    {view === true ? (
                                                        <p className="text-[#525056] text-sm font-medium">
                                                            {parameter?.frequency}
                                                        </p>
                                                    ) : (
                                                        <div className="w-full">
                                                            <Input
                                                                type="text"
                                                                defaultValue={parameter?.frequency}
                                                                onChange={(e) =>
                                                                    handleChange("frequency", e.target.value, index, idx)
                                                                }
                                                                className="!rounded-lg"
                                                                size='sm'
                                                                borderColor={"gray.300"}
                                                                borderWidth={"2px"}
                                                                isDisabled={disable}
                                                            />
                                                            {parameter?.frequency?.length > 100 && (
                                                                <div>
                                                                    <Text color="red.500" fontSize="sm" mt="1">
                                                                        You have exceed the character limit of 100 by{" "}
                                                                        {parameter?.frequency?.length - 100}
                                                                    </Text>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                                {(alertType != "Usecase" && alertType != "Other") &&
                                                    <div className="flex flex-col gap-1 ">
                                                        <p className="text-[#2660B6] text-sm font-medium">
                                                            Frequency Unit
                                                        </p>
                                                        {view === true ? (
                                                            <p className="text-[#525056] text-sm font-medium">
                                                                {parameter?.freqUnit}
                                                            </p>
                                                        ) : (
                                                            <div className="w-full">
                                                                <Select
                                                                    value={parameter?.freqUnit}
                                                                    onChange={(e) => {
                                                                        handleChange("freqUnit", e.target.value, index, idx);
                                                                    }}
                                                                    borderColor={"gray.300"}
                                                                    borderWidth={'2px'}
                                                                    size='sm'
                                                                    className="w-[100%] border-[2px] !rounded-lg max-h-[300px] overflow-y-auto"
                                                                    disabled={disable}
                                                                >
                                                                    <option value="" disabled selected>
                                                                        Select one
                                                                    </option>
                                                                    {dataOptions?.unitTime?.type?.map((item, i) => (
                                                                        <option value={item}>{item}</option>
                                                                    ))}
                                                                </Select>
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                                {(alertType != "Usecase" && alertType != "Other") &&
                                                    <div className="flex flex-col gap-1 ">
                                                        <p className="text-[#2660B6] text-sm font-medium">
                                                            Aggregation Logic
                                                        </p>
                                                        {view === true ? (
                                                            <p className="text-[#525056] text-sm font-medium">
                                                                {parameter?.aggLogic}
                                                            </p>
                                                        ) : (
                                                            <div className="w-full">
                                                                <Select
                                                                    size='sm'
                                                                    value={parameter?.aggLogic}
                                                                    onChange={(e) => {
                                                                        handleChange("aggLogic", e.target.value, index, idx);
                                                                    }}
                                                                    borderColor={"gray.300"}
                                                                    borderWidth={'2px'}
                                                                    className="w-[100%] border-[2px] !rounded-lg max-h-[300px] overflow-y-auto"
                                                                    disabled={disable}
                                                                >
                                                                    <option value="" disabled selected>
                                                                        Select one
                                                                    </option>
                                                                    {dataOptions?.aggLogic?.type?.map((item, i) => (
                                                                        <option value={item}>{item}</option>
                                                                    ))}
                                                                </Select>
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                                {(alertType != "Usecase" && alertType != "Other") &&
                                                    <div className="flex flex-col gap-1 ">
                                                        <p className="text-[#2660B6] text-sm font-medium">
                                                            Aggregation Threshold
                                                        </p>
                                                        {view === true ? (
                                                            <p className="text-[#525056] text-sm font-medium">
                                                                {parameter?.aggThreshold}
                                                            </p>
                                                        ) : (
                                                            <div className="w-full">
                                                                <Input
                                                                    type="text"
                                                                    defaultValue={parameter?.aggThreshold}
                                                                    onChange={(e) =>
                                                                        handleChange("aggThreshold", e.target.value, index, idx)
                                                                    }
                                                                    size='sm'
                                                                    className="!rounded-lg"
                                                                    borderColor={"gray.300"}
                                                                    borderWidth={"2px"}
                                                                    isDisabled={disable}
                                                                />
                                                                {parameter?.aggThreshold?.length > 100 && (
                                                                    <div>
                                                                        <Text color="red.500" fontSize="sm" mt="1">
                                                                            You have exceed the character limit of 100 by{" "}
                                                                            {parameter?.aggThreshold?.length - 100}
                                                                        </Text>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                                {(alertType != "Usecase" && alertType != "Other") &&
                                                    <div className="flex flex-col gap-1 ">
                                                        <p className="text-[#2660B6] text-sm font-medium">
                                                            Aggregation Time
                                                        </p>
                                                        {view === true ? (
                                                            <p className="text-[#525056] text-sm font-medium">
                                                                {parameter?.aggTime}
                                                            </p>
                                                        ) : (
                                                            <div className="w-full">
                                                                <Input
                                                                    type="text"
                                                                    defaultValue={parameter?.aggTime}
                                                                    onChange={(e) =>
                                                                        handleChange("aggTime", e.target.value, index, idx)
                                                                    }
                                                                    className="!rounded-lg"
                                                                    size='sm'
                                                                    borderColor={"gray.300"}
                                                                    borderWidth={"2px"}
                                                                    isDisabled={disable}
                                                                />
                                                                {parameter?.aggTime?.length > 100 && (
                                                                    <div>
                                                                        <Text color="red.500" fontSize="sm" mt="1">
                                                                            You have exceed the character limit of 100 by{" "}
                                                                            {parameter?.aggTime?.length - 100}
                                                                        </Text>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                }
                                                <div className="flex flex-col gap-1 ">
                                                    <p className="text-[#2660B6] text-sm font-medium">
                                                        Unit time
                                                    </p>
                                                    {view === true ? (
                                                        <p className="text-[#525056] text-sm font-medium">
                                                            {parameter?.unitTime}
                                                        </p>
                                                    ) : (
                                                        <div className="w-full">
                                                            <Select
                                                                value={parameter?.unitTime}
                                                                onChange={(e) => {
                                                                    handleChange("unitTime", e.target.value, index, idx);
                                                                }}
                                                                borderColor={"gray.300"}
                                                                borderWidth={'2px'}
                                                                size='sm'
                                                                className="w-[100%] border-[2px] !rounded-lg max-h-[300px] overflow-y-auto"
                                                                disabled={disable}
                                                            >
                                                                <option value="" disabled selected>
                                                                    Select one
                                                                </option>
                                                                {dataOptions?.unitTime?.type?.map((item, i) => (
                                                                    <option value={item}>{item}</option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="flex flex-col gap-1 ">
                                                    <p className="text-[#2660B6] text-sm font-medium">
                                                        Priority
                                                    </p>
                                                    {view === true ? (
                                                        <p className="text-[#525056] text-sm font-medium">
                                                            {parameter?.priority}
                                                        </p>
                                                    ) : (
                                                        <div className="w-full">
                                                            <Select
                                                                value={parameter?.priority}
                                                                onChange={(e) => {
                                                                    handleChange("priority", e.target.value, index, idx);
                                                                }}
                                                                borderColor={"gray.300"}
                                                                borderWidth={'2px'}
                                                                size='sm'
                                                                className="w-[100%] border-[2px] !rounded-lg max-h-[300px] overflow-y-auto"
                                                                disabled={disable}
                                                            >
                                                                <option value="" disabled selected>
                                                                    Select one
                                                                </option>
                                                                {dataOptions?.priority?.type?.map((item, i) => (
                                                                    <option value={item}>{item}</option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    )}
                                                </div>
                                                <div className="flex flex-col gap-1 ">
                                                    <p className="text-[#2660B6] text-sm font-medium">
                                                        Message content
                                                    </p>
                                                    {view === true ? (
                                                        <p className="text-[#525056] text-sm font-medium">
                                                            {parameter?.messageContent}
                                                        </p>
                                                    ) : (
                                                        <div className="w-full">
                                                            <Select
                                                                value={parameter?.messageContent}
                                                                onChange={(e) => {
                                                                    handleChange("messageContent", e.target.value, index, idx);
                                                                }}
                                                                borderColor={"gray.300"}
                                                                borderWidth={'2px'}
                                                                size='sm'
                                                                className="w-[100%] border-[2px] !rounded-lg max-h-[300px] overflow-y-auto"
                                                                disabled={disable}
                                                            >
                                                                <option value="" disabled selected>
                                                                    Select one
                                                                </option>
                                                                {dataOptions?.messageContent?.type?.map((item, i) => (
                                                                    <option value={item}>{item}</option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    )}
                                                </div>

                                                {alertType == "system" && <div className="flex flex-col gap-1 ">
                                                    <p className="text-[#2660B6] text-sm font-medium">
                                                        Heartbeat
                                                    </p>
                                                    {view === true ? (
                                                        <p className="text-[#525056] text-sm font-medium">
                                                            {parameter?.heartbeat}
                                                        </p>
                                                    ) : (
                                                        <div className="w-full">
                                                            <Input
                                                                type="text"
                                                                defaultValue={parameter?.heartbeat}
                                                                onChange={(e) =>
                                                                    handleChange("heartbeat", e.target.value, index, idx)
                                                                }
                                                                className="!rounded-lg"
                                                                size='sm'
                                                                borderColor={"gray.300"}
                                                                borderWidth={"2px"}
                                                                isDisabled={disable}
                                                            />
                                                            {parameter?.heartbeat?.length > 100 && (
                                                                <div>
                                                                    <Text color="red.500" fontSize="sm" mt="1">
                                                                        You have exceed the character limit of 100 by{" "}
                                                                        {parameter?.heartbeat?.length - 100}
                                                                    </Text>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>}
                                                {alertType == "system" && <div className="flex flex-col gap-1">
                                                    <p className="text-[#2660B6] text-sm font-medium">
                                                        Active Process count
                                                    </p>
                                                    {view === true ? (
                                                        <p className="text-[#525056] text-sm font-medium">
                                                            {parameter?.activeProcessCnt}
                                                        </p>
                                                    ) : (
                                                        <div className="w-full">
                                                            <Input
                                                                type="text"
                                                                defaultValue={parameter?.activeProcessCnt}
                                                                onChange={(e) =>
                                                                    handleChange("activeProcessCnt", e.target.value, index, idx)
                                                                }
                                                                size='sm'
                                                                borderColor={"gray.300"}
                                                                borderWidth={"2px"}
                                                                isDisabled={disable}
                                                                className="!rounded-lg"
                                                            />
                                                            {parameter?.activeProcessCnt?.length > 100 && (
                                                                <div>
                                                                    <Text color="red.500" fontSize="sm" mt="1">
                                                                        You have exceed the character limit of 100 by{" "}
                                                                        {parameter?.activeProcessCnt?.length - 100}
                                                                    </Text>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>}
                                                {alertType == "system" && <div className="flex flex-col gap-1">
                                                    <p className="text-[#2660B6] text-sm font-medium">
                                                        Down Process Count
                                                    </p>
                                                    {view === true ? (
                                                        <p className="text-[#525056] text-sm font-medium">
                                                            {parameter?.downProcessCnt}
                                                        </p>
                                                    ) : (
                                                        <div className="w-full">
                                                            <Input
                                                                type="text"
                                                                defaultValue={parameter?.downProcessCnt}
                                                                onChange={(e) =>
                                                                    handleChange("downProcessCnt", e.target.value, index, idx)
                                                                }
                                                                size='sm'
                                                                borderColor={"gray.300"}
                                                                borderWidth={"2px"}
                                                                isDisabled={disable}
                                                                className="!rounded-lg"
                                                            />
                                                            {parameter?.downProcessCnt?.length > 100 && (
                                                                <div>
                                                                    <Text color="red.500" fontSize="sm" mt="1">
                                                                        You have exceed the character limit of 100 by{" "}
                                                                        {parameter?.downProcessCnt?.length - 100}
                                                                    </Text>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>}
                                            </div>
                                            {alertType == 'camera' && <div>
                                            <Checkbox 
                                            isChecked={parameter?.isSnooze} 
                                            onChange={(e) => handleChange("isSnooze", e.target.checked, index, idx) }
                                            >
                                                <Text className="!text-[#2660B6] !text-sm !font-medium">Snooze</Text>
                                            </Checkbox>
                                            </div>}
                                            {parameter?.isSnooze && alertType == 'camera' && <div className="grid min-[410px]:grid-cols-2 min-[580px]:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 w-[100%] gap-2">

                                                    <div className="flex flex-col gap-1 ">
                                                        <p className="text-[#2660B6] text-sm font-medium">
                                                            Snooze time
                                                        </p>
                                                        {view === true ? (
                                                            <p className="text-[#525056] text-sm font-medium">
                                                                {parameter?.snoozeTime}
                                                            </p>
                                                        ) : (
                                                            <div className="w-full">
                                                                <Input
                                                                    type="text"
                                                                    defaultValue={parameter?.snoozeTime}
                                                                    onChange={(e) =>
                                                                        handleChange("snoozeTime", e.target.value, index, idx)
                                                                    }
                                                                    className="!rounded-lg"
                                                                    size='sm'
                                                                    borderColor={"gray.300"}
                                                                    borderWidth={"2px"}
                                                                    isDisabled={disable}
                                                                />
                                                                {parameter?.snoozeTime?.length > 100 && (
                                                                    <div>
                                                                        <Text color="red.500" fontSize="sm" mt="1">
                                                                            You have exceed the character limit of 100 by{" "}
                                                                            {parameter?.snoozeTime?.length - 100}
                                                                        </Text>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>

                                                <div className="flex flex-col gap-1 ">
                                                    <p className="text-[#2660B6] text-sm font-medium">
                                                    Snooze Unit
                                                    </p>
                                                    {view === true ? (
                                                        <p className="text-[#525056] text-sm font-medium">
                                                            {parameter?.snoozeUnit}
                                                        </p>
                                                    ) : (
                                                        <div className="w-full">
                                                            <Select
                                                                value={parameter?.snoozeUnit}
                                                                onChange={(e) => {
                                                                    handleChange("snoozeUnit", e.target.value, index, idx);
                                                                }}
                                                                borderColor={"gray.300"}
                                                                borderWidth={'2px'}
                                                                size='sm'
                                                                className="w-[100%] border-[2px] !rounded-lg max-h-[300px] overflow-y-auto"
                                                                disabled={disable}
                                                            >
                                                                <option value="" disabled selected>
                                                                    Select one
                                                                </option>
                                                                {dataOptions?.unitTime?.type?.map((item, i) => (
                                                                    <option value={item}>{item}</option>
                                                                ))}
                                                            </Select>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>}
                                            <div className="flex flex-col gap-1 sm:w-[50%]">
                                                <p className="text-[#2660B6] text-sm font-medium">
                                                    Alert Text(App)
                                                </p>
                                                {view === true ? (
                                                    <p className="text-[#525056] text-sm font-medium">
                                                        {parameter?.alertText}
                                                    </p>
                                                ) : (
                                                    <div className="w-full">
                                                        <Textarea
                                                            type="text"
                                                            value={parameter?.alertText}
                                                            onChange={(e) =>
                                                                handleChange("alertText", e.target.value, index, idx)
                                                            }
                                                            // size='sm'
                                                            borderColor={"gray.300"}
                                                            borderWidth={"2px"}
                                                            isDisabled={disable}
                                                        // className="!rounded-lg"
                                                        />
                                                        {parameter?.alertText?.length > 250 && (
                                                            <div>
                                                                <Text color="red.500" fontSize="sm" mt="1">
                                                                    You have exceed the character limit of 250 by{" "}
                                                                    {parameter?.alertText?.length - 250}
                                                                </Text>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            {
                                                parameter?.messageContent == "Alert and snapshot" &&
                                                <div className="flex flex-col gap-1 sm:w-[50%]">
                                                    <p className="text-[#2660B6] text-sm font-medium">
                                                        Alert Snapshot
                                                    </p>
                                                    {view === true ? (
                                                        <p className="text-[#525056] text-sm font-medium">
                                                            {parameter?.alertSnapshot}
                                                        </p>
                                                    ) : (
                                                        <div className="w-full">
                                                            <Textarea
                                                                type="text"
                                                                value={parameter?.alertSnapshot}
                                                                onChange={(e) =>
                                                                    handleChange("alertSnapshot", e.target.value, index, idx)
                                                                }
                                                                // size='sm'
                                                                borderColor={"gray.300"}
                                                                borderWidth={"2px"}
                                                                isDisabled={disable}
                                                            // className="!rounded-lg"
                                                            />
                                                            {parameter?.alertSnapshot?.length > 250 && (
                                                                <div>
                                                                    <Text color="red.500" fontSize="sm" mt="1">
                                                                        You have exceed the character limit of 250 by{" "}
                                                                        {parameter?.alertSnapshot?.length - 250}
                                                                    </Text>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            }
                                        </div>

                                        {view === false &&
                                            <div className="w-[5%] flex justify-end items-start">
                                                <button onClick={() => handleDeleteParameter(index, idx)} disabled={disable}>
                                                    {disable ? (
                                                        <img
                                                            src="/disabledDelete.svg"
                                                            className="w-[25px] h-[25px]"
                                                        />
                                                    ) : (
                                                        <img src="/deleteIcon.svg" className="w-[25px] h-[25px]" />
                                                    )}
                                                </button>
                                            </div>
                                        }


                                    </div>
                                )
                            })
                        }

                        {view === false &&
                            <div className="mt-6">
                                <TonalButton
                                    text={"Add new"}
                                    disable={disable}
                                    width={"130px"}
                                    onClick={() => handleAddParameter(index)}
                                />
                            </div>
                        }

                        <p className="text-[black] text-lg font-medium mt-4">
                            Additional Parameters
                        </p>


                        {
                            alert?.extraParameters?.map((parameter, idx) => {
                                if (parameter?.isDeleted == true) return;
                                return (
                                    <div className="flex flex-row gap-2 border rounded-md p-2 sm:w-[60%] items-end">
                                        <div className="w-[90%] grid grid-cols-2 gap-2">
                                            <div className=" flex flex-col gap-3">
                                                <p className="text-[#2660B6] text-sm font-medium">
                                                    Key
                                                </p>
                                                {(view === true) ? (
                                                    <p className="text-[#525056] text-sm font-medium">
                                                        {parameter?.key}
                                                    </p>
                                                ) : (
                                                    <div className="w-full">
                                                        <Input
                                                            type="text"
                                                            defaultValue={parameter?.key}
                                                            onChange={(e) =>
                                                                handleChange("key", e.target.value, index, idx, "extraParameters")
                                                            }
                                                            className="!rounded-lg"
                                                            size={'sm'}
                                                            borderColor={"gray.300"}
                                                            borderWidth={"2px"}
                                                            isDisabled={disable} // disable
                                                        />
                                                        {parameter?.key?.length > 100 && (
                                                            <div>
                                                                <Text color="red.500" fontSize="sm" mt="1">
                                                                    You have exceed the character limit of 100 by{" "}
                                                                    {parameter?.key?.length - 100}
                                                                </Text>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="flex flex-col gap-3 ">
                                                <p className="text-[#2660B6] text-sm font-medium">
                                                    Value
                                                </p>
                                                {(view === true) ? (
                                                    <p className="text-[#525056] text-sm font-medium">
                                                        {parameter?.value}
                                                    </p>
                                                ) : (
                                                    <div className="w-full">
                                                        <Input
                                                            type="email"
                                                            defaultValue={parameter?.value}
                                                            onChange={(e) =>
                                                                handleChange("value", e.target.value, index, idx, "extraParameters")
                                                            }
                                                            className="!rounded-lg"
                                                            size='sm'
                                                            borderColor={"gray.300"}
                                                            borderWidth={"2px"}
                                                            isDisabled={disable} // disable
                                                        />
                                                        {parameter?.value?.length > 100 && (
                                                            <div>
                                                                <Text color="red.500" fontSize="sm" mt="1">
                                                                    You have exceed the character limit of 100 by{" "}
                                                                    {parameter?.value?.length - 100}
                                                                </Text>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {view === false &&
                                            <div className="w-[10%]">
                                                <button onClick={() => handleDeleteAddParameter(index, idx)} disabled={disable}>
                                                    {disable ? (
                                                        <img
                                                            src="/disabledDelete.svg"
                                                            className="w-[25px] h-[25px]"
                                                        />
                                                    ) : (
                                                        <img src="/deleteIcon.svg" className="w-[25px] h-[25px]" />
                                                    )}
                                                </button>
                                            </div>
                                        }
                                    </div>
                                )
                            }
                            )
                        }

                        {view === false &&
                            <div className="mt-6">
                                <TonalButton
                                    text={"Add new"}
                                    disable={disable}
                                    width={"130px"}
                                    onClick={() => handleAddAddParameter(index)}
                                />
                            </div>
                        }

                        <div className="flex flex-col gap-3 mt-6">
                            <p className="text-[black] text-lg font-medium">
                                Medium
                            </p>

                            <ChakraProvider >
                                <div className='flex flex-row'>

                                    <div className='py-[8px] pl-[8px] pr-[12px]'>
                                        <Checkbox
                                            disabled={disable}
                                            isChecked={alert?.whatsappFlag === true}
                                            onChange={() => handleChange("whatsappFlag", !alert?.whatsappFlag, index)}
                                        >
                                            {"WhatsApp"}
                                        </Checkbox>
                                    </div>
                                    <div className='py-[8px] pl-[8px] pr-[12px]'>
                                        <Checkbox
                                            disabled={disable}
                                            isChecked={alert?.emailFlag === true}
                                            onChange={() => handleChange("emailFlag", !alert?.emailFlag, index)}
                                        >
                                            {"Email"}
                                        </Checkbox>
                                    </div>
                                    <div className='py-[8px] pl-[8px] pr-[12px]'>
                                        <Checkbox
                                            disabled={disable}
                                            isChecked={alert?.smsFlag === true}
                                            onChange={() => handleChange("smsFlag", !alert?.smsFlag, index)}
                                        >
                                            {"SMS"}
                                        </Checkbox>
                                    </div>
                                </div>
                            </ChakraProvider>

                        </div>

                        <div className="flex flex-row h-full gap-4 overflow-x-auto">

                            {alert?.whatsappFlag &&
                                <div className="min-w-[250px] flex flex-col gap-3 mt-6">
                                    <p className="text-[#2660B6] text-sm font-medium">
                                        WhatsApp Template
                                    </p>
                                    {view === true ? (
                                        <p className="text-[#525056] text-sm font-medium">
                                            {alert?.whatsappTemplate}
                                        </p>
                                    ) : (
                                        <div className="w-full h-[250px] overflow-y-auto">
                                            <Textarea
                                                placeholder="Whatsapp Template"
                                                borderWidth={"2px"}
                                                value={alert?.whatsappTemplate}
                                                onChange={(e) => {
                                                    handleChange("whatsappTemplate", e.target.value, index)
                                                    // autoResize(e);
                                                }
                                                }
                                                style={{height: 250}}
                                                //style={{ display: "block", overflow: "hidden", resize: "none" }}
                                                isDisabled={disable || !alert?.whatsappFlag}
                                            />
                                            {/* <TextEditor setData={handleChange} keyValue={"whatsappTemplate"} index={index} value={alert?.whatsappTemplate} /> */}

                                            {alert?.whatsappTemplate?.length > 1000 && (
                                                <div>
                                                    <Text color="red.500" fontSize="sm" mt="1">
                                                        You have exceed the character limit of 1000 by{" "}
                                                        {alert?.whatsappTemplate?.length - 1000}
                                                    </Text>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            }

                            {alert?.emailFlag &&
                                <div className="min-w-[250px] flex flex-col gap-3 mt-6">
                                    <p className="text-[#2660B6] text-sm font-medium">
                                        Email Template
                                    </p>
                                    {view === true ? (
                                        <p className="text-[#525056] text-sm font-medium">
                                            {alert?.emailTemplate}
                                        </p>
                                    ) : (
                                        <div className="w-full h-[250px] overflow-y-auto">
                                            {/* <ReactQuill
                                                theme="snow"
                                                placeholder="Email Template"
                                                // borderWidth={"2px"}
                                                modules={modules}
                                                formats={formats}
                                                // bounds = {'.app'}
                                                value={alert?.emailTemplate}
                                                onChange={(e) => {
                                                    handleChange("emailTemplate", e, index)
                                                    // autoResize(e);
                                                }}
                                            // //style={{ display: "block", overflow: "hidden", resize: "none" }}
                                            // isDisabled={disable || !alert?.emailFlag}
                                            /> */}
                                            <TextEditor setData={handleChange} keyValue={"emailTemplate"} index={index} value={alert?.emailTemplate} />

                                            {alert?.emailTemplate?.length > 1000 && (
                                                <div>
                                                    <Text color="red.500" fontSize="sm" mt="1">
                                                        You have exceed the character limit of 1000 by{" "}
                                                        {alert?.emailTemplate?.length - 1000}
                                                    </Text>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            }

                            {alert?.smsFlag &&
                                <div className="min-w-[250px] flex flex-col gap-3 mt-6">
                                    <p className="text-[#2660B6] text-sm font-medium">
                                        SMS Template
                                    </p>
                                    {view === true ? (
                                        <p className="text-[#525056] text-sm font-medium">
                                            {alert?.smsTemplate}
                                        </p>
                                    ) : (
                                        <div className="w-full h-[250px] overflow-y-auto">
                                            <Textarea
                                                placeholder="SMS Template"
                                                borderWidth={"2px"}
                                                value={alert?.smsTemplate}
                                                onChange={(e) => {
                                                    handleChange("smsTemplate", e.target.value, index)
                                                    // autoResize(e);
                                                }}
                                                style={{height: 250}}
                                                //style={{ display: "block", overflow: "hidden", resize: "none" }}
                                                isDisabled={disable || !alert?.smsFlag}
                                            />

                                            {/* <TextEditor setData={handleChange} keyValue={"smsTemplate"} index={index} value={alert?.smsTemplate} /> */}

                                            {alert?.smsTemplate?.length > 1000 && (
                                                <div>
                                                    <Text color="red.500" fontSize="sm" mt="1">
                                                        You have exceed the character limit of 1000 by{" "}
                                                        {alert?.smsTemplate?.length - 1000}
                                                    </Text>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            }
                        </div>

                        <p className="text-[black] text-lg font-medium mt-1">
                            Contacts
                        </p>

                        <div className='py-[8px] pl-[8px] pr-[12px]'>
                            <Checkbox
                                disabled={disable}
                                isChecked={alert?.defaultContactsFlag}
                                onChange={() => handleChange("defaultContactsFlag", !alert?.defaultContactsFlag, index)}
                            >
                                {"Include Default Contacts"}
                            </Checkbox>
                        </div>

                        {
                            alert?.defaultContactsFlag && defaultContacts?.map((contact, idx) => (
                                <div className="flex gap-2 border rounded-md p-2 sm:w-[70%]">
                                    <div className="w-[90%] grid grid-cols-2 min-[500px]:grid-cols-3 gap-2">
                                        <div className="flex flex-col gap-1">
                                            <p className="text-[#2660B6] text-sm font-medium">
                                                Name
                                            </p>
                                            {(view === true || true) ? (
                                                <p className="text-[#525056] text-sm font-medium">
                                                    {contact?.name}
                                                </p>
                                            ) : (
                                                <div className="w-full">
                                                    <Input
                                                        type="text"
                                                        defaultValue={contact?.name}
                                                        onChange={(e) =>
                                                            handleChange("name", e.target.value, index, idx, "defaultContacts")
                                                        }
                                                        className="!rounded-lg"
                                                        // borderColor={"gray.300"}
                                                        borderWidth={"2px"}
                                                        size='sm'
                                                        isDisabled={true} // disable
                                                    />
                                                    {contact?.name?.length > 100 && (
                                                        <div>
                                                            <Text color="red.500" fontSize="sm" mt="1">
                                                                You have exceed the character limit of 100 by{" "}
                                                                {contact?.name?.length - 100}
                                                            </Text>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <div className="flex flex-col gap-1 ">
                                            <p className="text-[#2660B6] text-sm font-medium">
                                                Email
                                            </p>
                                            {(view === true || true) ? (
                                                <p className="text-[#525056] text-sm font-medium">
                                                    {contact?.email}
                                                </p>
                                            ) : (
                                                <div className="w-full">
                                                    <Input
                                                        type="email"
                                                        defaultValue={contact?.email}
                                                        onChange={(e) =>
                                                            handleChange("email", e.target.value, index, idx, "defaultContacts")
                                                        }
                                                        className="!rounded-lg"
                                                        size='sm'
                                                        // borderColor={"gray.300"}
                                                        // borderWidth={"2px"}
                                                        isDisabled={true} // disable
                                                    />
                                                    {contact?.email?.length > 100 && (
                                                        <div>
                                                            <Text color="red.500" fontSize="sm" mt="1">
                                                                You have exceed the character limit of 100 by{" "}
                                                                {contact?.email?.length - 100}
                                                            </Text>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                        <div className=" flex flex-col gap-1 ">
                                            <p className="text-[#2660B6] text-sm font-medium">
                                                Phone Number
                                            </p>
                                            {(view === true || true) ? (
                                                <p className="text-[#525056] text-sm font-medium">
                                                    {contact?.phoneNo}
                                                </p>
                                            ) : (
                                                <div className="w-full">
                                                    <Input
                                                        type="number"
                                                        defaultValue={contact?.phoneNo}
                                                        onChange={(e) =>
                                                            handleChange("phoneNo", e.target.value, index, idx, "defaultContacts")
                                                        }
                                                        className="!rounded-lg"
                                                        size='sm'
                                                        // borderColor={"gray.300"}
                                                        // borderWidth={"2px"}
                                                        isDisabled={true} // disable
                                                    />
                                                    {contact?.phoneNo?.length > 100 && (
                                                        <div>
                                                            <Text color="red.500" fontSize="sm" mt="1">
                                                                You have exceed the character limit of 100 by{" "}
                                                                {contact?.phoneNo?.length - 100}
                                                            </Text>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>
                            ))
                        }

                        {
                            alert?.contacts?.map((contact, idx) => {
                                if (contact?.isDeleted == true) return;
                                return (
                                    <div className="flex flex-row gap-2 border rounded-md p-2 sm:w-[70%] items-end">
                                        <div className="w-[90%] grid grid-cols-2 min-[500px]:grid-cols-3 gap-2">
                                            <div className=" flex flex-col gap-3">
                                                <p className="text-[#2660B6] text-sm font-medium">
                                                    Name
                                                </p>
                                                {(view === true) ? (
                                                    <p className="text-[#525056] text-sm font-medium">
                                                        {contact?.name}
                                                    </p>
                                                ) : (
                                                    <div className="w-full">
                                                        <Input
                                                            type="text"
                                                            defaultValue={contact?.name}
                                                            onChange={(e) =>
                                                                handleChange("name", e.target.value, index, idx, "contacts")
                                                            }
                                                            className="!rounded-lg"
                                                            size={'sm'}
                                                            borderColor={"gray.300"}
                                                            borderWidth={"2px"}
                                                            isDisabled={disable} // disable
                                                        />
                                                        {contact?.name?.length > 100 && (
                                                            <div>
                                                                <Text color="red.500" fontSize="sm" mt="1">
                                                                    You have exceed the character limit of 100 by{" "}
                                                                    {contact?.name?.length - 100}
                                                                </Text>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="flex flex-col gap-3 ">
                                                <p className="text-[#2660B6] text-sm font-medium">
                                                    Email
                                                </p>
                                                {(view === true) ? (
                                                    <p className="text-[#525056] text-sm font-medium">
                                                        {contact?.email}
                                                    </p>
                                                ) : (
                                                    <div className="w-full">
                                                        <Input
                                                            type="email"
                                                            defaultValue={contact?.email}
                                                            onChange={(e) =>
                                                                handleChange("email", e.target.value, index, idx, "contacts")
                                                            }
                                                            className="!rounded-lg"
                                                            size='sm'
                                                            borderColor={"gray.300"}
                                                            borderWidth={"2px"}
                                                            isDisabled={disable} // disable
                                                        />
                                                        {contact?.email?.length > 100 && (
                                                            <div>
                                                                <Text color="red.500" fontSize="sm" mt="1">
                                                                    You have exceed the character limit of 100 by{" "}
                                                                    {contact?.email?.length - 100}
                                                                </Text>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                            <div className="flex flex-col gap-3 ">
                                                <p className="text-[#2660B6] text-sm font-medium">
                                                    Phone Number
                                                </p>
                                                {(view === true) ? (
                                                    <p className="text-[#525056] text-sm font-medium">
                                                        {contact?.phoneNo}
                                                    </p>
                                                ) : (
                                                    <div className="w-full">
                                                        <Input
                                                            type="number"
                                                            defaultValue={contact?.phoneNo}
                                                            onChange={(e) =>
                                                                handleChange("phoneNo", e.target.value, index, idx, "contacts")
                                                            }
                                                            className="!rounded-lg"
                                                            size='sm'
                                                            borderColor={"gray.300"}
                                                            borderWidth={"2px"}
                                                            isDisabled={disable} // disable
                                                        />
                                                        {contact?.phoneNo?.length > 100 && (
                                                            <div>
                                                                <Text color="red.500" fontSize="sm" mt="1">
                                                                    You have exceed the character limit of 100 by{" "}
                                                                    {contact?.phoneNo?.length - 100}
                                                                </Text>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {view === false &&
                                            <div className="w-[10%]">
                                                <button onClick={() => handleDeleteContact(index, idx)} disabled={disable}>
                                                    {disable ? (
                                                        <img
                                                            src="/disabledDelete.svg"
                                                            className="w-[25px] h-[25px]"
                                                        />
                                                    ) : (
                                                        <img src="/deleteIcon.svg" className="w-[25px] h-[25px]" />
                                                    )}
                                                </button>
                                            </div>
                                        }
                                    </div>
                                )
                            }
                            )
                        }

                        {view === false &&
                            <div className="mt-6">
                                <TonalButton
                                    text={"Add new"}
                                    disable={disable}
                                    width={"130px"}
                                    onClick={() => handleAddContact(index)}
                                />
                            </div>
                        }

                        <div className="flex flex-row items-center gap-1 mt-2">
                            <p className="text-xs text-[#3E3C42]">Alert Id</p>
                            <p>{alert?.alertMappingId}</p>
                        </div>

                    </div>
                </ModalBody>
                <ModalFooter className="!w-full !flex !flex-row !items-center !justify-start !gap-2">
                    {view === false && (
                        <div className="flex gap-[10px] items-center ml-0 mt-6 mb-20 md:mb-0">
                            {disable ? (
                                <TonalButton
                                    text={"Back to editing"}
                                    width={"130px"}
                                    onClick={() => {
                                        setDisable(false);
                                    }}
                                    disable={view}
                                />
                            ) : (
                                <PrimaryButton
                                    text={"Save"}
                                    width={"130px"}
                                    onClick={() => {
                                        setDisable(true);
                                        handleSubmit(true);
                                    }}
                                    disable={view}
                                //color="green"
                                />
                            )}
                        </div>
                    )}

                    <div className="mt-6">
                        <TonalButton
                            text={"Close"}
                            width={"130px"}
                            onClick={() => {
                                handleCloseEditModal();
                            }}
                            disable={view}
                        />
                    </div>

                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default UpdateModal;