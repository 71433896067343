import ReactApexChart from "react-apexcharts";

const PieChart = ({ labels, series, colors = null }) => {

    if(!series) return;

    const options = {
        chart: {
            type: "pie",
        },
        colors: colors ? colors : [
            "#4caf50",
            "#5193f6",
            "#E46962",
            "#1c56ac",
            "#e91e63",
            "#00bcd4",
            "#8bc34a",
            "#9c27b0",
            "#673ab7",
            "#ff9800",
            "#4caf50",
            "#795548",
        ],
        title: {
            text: 'Overall',
            align: 'center',
            style: {
              fontSize: '16px',
              fontWeight: 'medium',
              fontFamily: 'Arial, sans-serif',
            },
          },
        labels: labels,
        legend: {
            show: true,
            position: "top",
            fontSize: "12px",
        },
        dataLabels: {
            enabled: true,
            style: {
                fontSize: "12px",
            },
            background: {
                enabled: true,
                foreColor: "#000",
            },
            dropShadow: {
                enabled: false,
            },
        },
    };
    return <ReactApexChart options={options} series={series} type="pie" minWidth={300} height={300} />;
};

export default PieChart;
