import { useToast, Input } from "@chakra-ui/react";
import { useRef, useState } from "react";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";

const CamLink = ({
  userData,
  setUserData,
  activeStep,
  setActiveStep,
  viewMode = false,
  show,
}) => {
  const [disable, setDisable] = useState(viewMode);
  const rtspRef = useRef();
  const toast = useToast();
  let regex = /(rtsp):\/\/([^\s@/]+)@([^\s/:]+)(?::([0-9]+))?(\/.*)/;
  const handleSave = () => {
    if (rtspRef.current.value == "" || !regex.test(rtspRef?.current?.value)) {
      toast({
        title: "Error",
        description: (
          <div>
            {rtspRef.current.value == "" && (
              <p>Please ensure camera rtsp is not empty</p>
            )}
            {!regex.test(rtspRef?.current?.value) && (
              <p>Please enter a valid rtsp link</p>
            )}
          </div>
        ),
        status: "error",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    setUserData((prev) => ({
      ...prev,
      rtsp: rtspRef?.current?.value,
    }));
    setDisable(true);
    if (activeStep < 2) setActiveStep((prev) => prev + 1);
  };

  return (
    <div
      className={`flex flex-col items-start justify-between gap-8 p-6 bg-white rounded-lg transition-all ease-in duration-700 ${
        show ? "opacity-100" : "opacity-0"
      }`}
      id="step1"
    >
      <div className="flex flex-col gap-3">
        <p className="text-sm text-[#2660B6] font-medium">
          Add RTSP Link<span className="text-[#DC362E]">*</span>
        </p>
        <Input
          width={"fit-content"}
          name="rtsp"
          defaultValue={userData.rtsp}
          ref={rtspRef}
          isDisabled={disable}
          placeholder=""
        />
      </div>
      <div className="flex gap-2 items-center">
        <PrimaryButton
          text={"Save"}
          width={"fit-content"}
          onClick={handleSave}
          disable={disable}
        />
      </div>
    </div>
  );
};

export default CamLink;
