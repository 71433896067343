import React from "react";
import {
  Skeleton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { MoodBad } from "@mui/icons-material";

// No
export const NoData = () => {
  return (
    <div className="flex flex-row justify-center items-center border rounded-md w-full h-full">
      <div className="flex flex-row items-center gap-2 text-gray-400">
        <MoodBad fontSize={"large"} />
        <h2 className="text-2xl">No Data!</h2>
      </div>
    </div>
  );
};
export const TableSkeleton = ({
  headers = 5,
  rows = 3,
  cellsPerRow = 3,
  padding = null,
  backgroundColor = "#CCEAFF",
  disableHeader = false,
}) => {
  return (
    <TableContainer
      overflowX={"scroll"}
      className="border rounded-md"
    >
      <Table variant="simple">
        {disableHeader === false && (
          <Thead>
            <Tr backgroundColor={backgroundColor}>
              {[...Array(headers)].map((header, index) => (
                <Th
                  key={index}
                  minW={"150px"}
                  padding={padding}
                >
                  <Skeleton height="20px" />
                </Th>
              ))}
            </Tr>
          </Thead>
        )}
        <Tbody>
          {[...Array(rows)].map((_, rowIndex) => (
            <Tr key={rowIndex}>
              {[...Array(cellsPerRow)].map((_, cellIndex) => (
                <Td
                  key={cellIndex}
                  padding={padding}
                >
                  <Skeleton height="20px" />
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
