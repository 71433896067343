import React, { useState, useEffect } from "react";
import {
  Input,
  Radio,
  RadioGroup,
  useToast,
  Textarea,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  Text,
} from "@chakra-ui/react";

const DiscardModal = ({isModalOpen, handleCloseModal, handleConfirmDiscard}) => {
  return (
    <Modal size={'sm'} isOpen={isModalOpen} onClose={handleCloseModal} >
          <ModalOverlay />
          <ModalContent>

            <ModalHeader className=" flex justify-center rounded-t-md bg-red-400 text-white ">
              Discard Changes?
            </ModalHeader>
            {/* <ModalCloseButton /> */}
            <ModalBody m={"auto"} fontSize={"15px"}>
            <div className="flex flex-col items-center">
                <img src={require("../discard.png")} className="w-[60px] h-[60px]" />
                <p className="font-bold">Are you sure you want to discard?</p>
                Your changes will not be saved
              </div>
            </ModalBody>

            <div className="mt-4 flex flex-row justify-center">
              <Button colorScheme="blue" mr={3} onClick={handleCloseModal}>
                Go Back
              </Button>
              <Button onClick={handleConfirmDiscard}>Discard</Button>
            </div>

            <ModalFooter>
              {/* <div className="mt-4 flex flex-row justify-center">
              <Button colorScheme="blue" mr={3} onClick={handleCloseModal}>
                No
              </Button>
              <Button onClick={handleConfirmDiscard}>Yes, Discard</Button>
            </div> */}
            </ModalFooter>

          </ModalContent>
        </Modal>
  )
}

export default DiscardModal