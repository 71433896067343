import React from "react";
import {
  ChakraProvider,
  FormLabel,
  FormControl,
  Input,
  extendTheme,
} from "@chakra-ui/react";

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-24px)",
};
export const theme = extendTheme({
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles,
              },
            },
            "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
              {
                ...activeLabelStyles,
              },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: "absolute",
              backgroundColor: "white",
              pointerEvents: "none",
              mx: 3,
              px: 1,
              my: 2,
              transformOrigin: "left top",
            },
          },
        },
      },
    },
  },
});

const FloatingInput = (props) => {
  return (
    <ChakraProvider theme={theme}>
      <FormControl variant="floating" id="Dates">
        <Input
          placeholder=""
          size={props.size ? props.size : "md" }
          type={props.type}
          value={props.value}
          min={props?.min || null}
          max={props?.max || null}
          disabled={props?.disabled || null}
          onChange={(e) => props?.setDateTime(e.target.value)}
          style={{
            borderColor: props.borderColor ? props.borderColor : "#79767D"
          }}
          className="!border-1 !rounded-[5px] !text-[#AEA9B1] !text-base pl-30"
        />
        {/* It is important that the Label comes after the Control due to css selectors */}
        <FormLabel className="!font-normal" style={{color: props.textColor ? props.textColor : "#0c0b0b", fontSize: props.textSize ? props.textSize : "16px"}}>
          {props.text}
        </FormLabel>
      </FormControl>
    </ChakraProvider>
  );
};

export default FloatingInput;
