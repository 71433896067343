// variables naming kept in camelCase to align with intelliverse
// time stamp are kept in epoch can be changes
export const dummyUpcomingBiasRecommenderData = {
  columns: [
    { value: "TOC", label: "TOC" },
    { value: "TOM", label: "TOM" },
    { value: "plate_id", label: "Plate Id" },
    { value: "MEID", label: "MEID" },
    { value: "steel_grade", label: "Steel Grade" },
    { value: "thickness", label: "Thickness" },
    { value: "width", label: "Width" },
    { value: "length", label: "Length" },
    { value: "measured_actual_FRT", label: "Actual FRT" },
    { value: "target_FRT", label: "FRT" },
    { value: "target_SCT", label: "SCT" },
    { value: "target_FCT", label: "FCT" },
  ],
  tableData: [
    {
      id: "1", //unqiue identifier in each row
      recordId: "12322343", // recordId to fetch historical data of that particular row
      TOC: 1710260670,
      TOM: 1710260670,
      plate_id: 370993,
      MEID: 232323,
      steel_grade: "GradeA",
      thickness: 5,
      width: 10,
      length: 100,
      measured_actual_FRT: 213213,
      target_FRT: 12132,
      target_SCT: 12132,
      target_FCT: 12132,
    },
    {
      id: "2",
      recordId: "121343545",
      TOC: 1710260670,
      TOM: 1710260670,
      plate_id: 370993,
      MEID: 232323,
      steel_grade: "GradeA",
      thickness: 5,
      width: 10,
      length: 100,
      measured_actual_FRT: 213213,
      target_FRT: 12132,
      target_SCT: 12132,
      target_FCT: 12132,
    },
    {
      id: "3",
      recordId: "121343545",
      TOC: 1710260670,
      TOM: 1710260670,
      plate_id: 370993,
      MEID: 232323,
      steel_grade: "GradeA",
      thickness: 5,
      width: 10,
      length: 100,
      measured_actual_FRT: 213213,
      target_FRT: 12132,
      target_SCT: 12132,
      target_FCT: 12132,
    },
    {
      id: "4",
      TOC: 1710260670,
      TOM: 1710260670,
      plate_id: 370993,
      MEID: 232323,
      steel_grade: "GradeA",
      thickness: 5,
      width: 10,
      length: 100,
      measured_actual_FRT: 213213,
      target_FRT: 12132,
      target_SCT: 12132,
      target_FCT: 12132,
    },
    {
      id: "5",
      recordId: "121343545",
      TOC: 1710260670,
      TOM: 1710260670,
      plate_id: 370993,
      MEID: 232323,
      steel_grade: "GradeA",
      thickness: 5,
      width: 10,
      length: 100,
      measured_actual_FRT: 213213,
      target_FRT: 12132,
      target_SCT: 12132,
      target_FCT: 12132,
    },
  ],
};

export const dummyRecommendedBiasRecommenderData = {
  columns: [
    {
      value: "TOC",
      label: "TOC",
    },
    {
      value: "primary_data_plate_id",
      label: "Plate Id",
    },
    {
      value: "primary_data_steel_grade",
      label: "Steel Grade",
    },
    {
      value: "primary_data_thickness",
      label: "Thickness",
    },
    {
      value: "primary_data_width",
      label: "Width",
    },
    {
      value: "primary_data_length",
      label: "Length",
    },
    {
      value: "measured_data_actual_frt",
      label: "Actual FRT",
    },
    {
      value: "measured_data_FCT_error",
      label: "FCT Error",
    },
    {
      value: "measured_data_water_temp",
      label: "Water Temp",
    },
    {
      value: "PRT_indices_total_flow",
      label: "Total Flow",
    },
    {
      value: "PRT_indices_speed_curve",
      label: "Speed Curve",
    },
    {
      value: "PRT_indices_WFR",
      label: "WFR",
    },
    {
      value: "PRT_indices_edge_mask",
      label: "Edge Mask",
    },
    {
      value: "PRT_indices_H-T_mask",
      label: "H-T Mask",
    },
    {
      value: "PRT_speed_bias_initial_speed",
      label: "Initial Speed",
    },
    {
      value: "PRT_speed_bias_final_speed",
      label: "Final Speed",
    },
    {
      value: "PRT_speed_bias_curve_shift",
      label: "Curve Shift",
    },
    {
      value: "PRT_speed_bias_max_speed",
      label: "Max Speed",
    },
    {
      value: "water_flow_rate_coefficients_a0",
      label: "A0",
    },
    {
      value: "water_flow_rate_coefficients_a1",
      label: "A1",
    },
    {
      value: "water_flow_rate_coefficients_a2",
      label: "A2",
    },
    {
      value: "water_flow_rate_coefficients_a7",
      label: "A7",
    },
    {
      value: "intermediate_results_eff1",
      label: "Eff1",
    },
    {
      value: "intermediate_results_eff2",
      label: "Eff2",
    },
    {
      value: "edge_masking_position_worker_side",
      label: "Worker Side",
    },
    {
      value: "edge_masking_position_driver_side",
      label: "Driver Side",
    },
    {
      value: "edge_masking_length_worker_side",
      label: "Worker Side",
    },
    {
      value: "edge_masking_length_driver_side",
      label: "Driver Side",
    },
    {
      value: "head_masking_bank_a_ramp_h1",
      label: "H1",
    },
    {
      value: "head_masking_bank_a_ramp_h2",
      label: "H2",
    },
    {
      value: "head_masking_bank_a_ramp_h3",
      label: "H3",
    },
    {
      value: "head_masking_bank_a_ramp_h4",
      label: "H4",
    },
    {
      value: "head_masking_bank_a_ramp_h5",
      label: "H5",
    },
    {
      value: "head_masking_bank_a_ramp_h6",
      label: "H6",
    },
    {
      value: "head_masking_bank_a_length_h1",
      label: "H1",
    },
    {
      value: "head_masking_bank_a_length_h2",
      label: "H2",
    },
    {
      value: "head_masking_bank_a_length_h3",
      label: "H3",
    },
    {
      value: "head_masking_bank_a_length_h4",
      label: "H4",
    },
    {
      value: "head_masking_bank_a_length_h5",
      label: "H5",
    },
    {
      value: "head_masking_bank_a_length_h6",
      label: "H6",
    },
    { value: "head_masking_bank_a_offset_h1", label: "H1" },
    { value: "head_masking_bank_a_offset_h2", label: "H2" },
    { value: "head_masking_bank_a_offset_h3", label: "H3" },
    { value: "head_masking_bank_a_offset_h4", label: "H4" },
    { value: "head_masking_bank_a_offset_h5", label: "H5" },
    { value: "head_masking_bank_a_offset_h6", label: "H6" },
    { value: "head_masking_a_botm_ramp_h1", label: "H1" },
    { value: "head_masking_a_botm_ramp_h2", label: "H2" },
    { value: "head_masking_a_botm_ramp_h3", label: "H3" },
    { value: "head_masking_a_botm_ramp_h4", label: "H4" },
    { value: "head_masking_a_botm_ramp_h5", label: "H5" },
    { value: "head_masking_a_botm_ramp_h6", label: "H6" },
    { value: "head_masking_a_botm_length_h1", label: "H1" },
    { value: "head_masking_a_botm_length_h2", label: "H2" },
    { value: "head_masking_a_botm_length_h3", label: "H3" },
    { value: "head_masking_a_botm_length_h4", label: "H4" },
    { value: "head_masking_a_botm_length_h5", label: "H5" },
    { value: "head_masking_a_botm_length_h6", label: "H6" },
    { value: "head_masking_a_botm_offset_h1", label: "H1" },
    { value: "head_masking_a_botm_offset_h2", label: "H2" },
    { value: "head_masking_a_botm_offset_h3", label: "H3" },
    { value: "head_masking_a_botm_offset_h4", label: "H4" },
    { value: "head_masking_a_botm_offset_h5", label: "H5" },
    { value: "head_masking_a_botm_offset_h6", label: "H6" },
    { value: "tail_masking_bank_a_ramp_h1", label: "H1" },
    { value: "tail_masking_bank_a_ramp_h2", label: "H2" },
    { value: "tail_masking_bank_a_ramp_h3", label: "H3" },
    { value: "tail_masking_bank_a_ramp_h4", label: "H4" },
    { value: "tail_masking_bank_a_ramp_h5", label: "H5" },
    { value: "tail_masking_bank_a_ramp_h6", label: "H6" },
    { value: "tail_masking_bank_a_length_h1", label: "H1" },
    { value: "tail_masking_bank_a_length_h2", label: "H2" },
    { value: "tail_masking_bank_a_length_h3", label: "H3" },
    { value: "tail_masking_bank_a_length_h4", label: "H4" },
    { value: "tail_masking_bank_a_length_h5", label: "H5" },
    { value: "tail_masking_bank_a_length_h6", label: "H6" },
    { value: "tail_masking_bank_a_offset_h1", label: "H1" },
    { value: "tail_masking_bank_a_offset_h2", label: "H2" },
    { value: "tail_masking_bank_a_offset_h3", label: "H3" },
    { value: "tail_masking_bank_a_offset_h4", label: "H4" },
    { value: "tail_masking_bank_a_offset_h5", label: "H5" },
    { value: "tail_masking_bank_a_offset_h6", label: "H6" },
    { value: "tail_masking_a_botm_ramp_h1", label: "H1" },
    { value: "tail_masking_a_botm_ramp_h2", label: "H2" },
    { value: "tail_masking_a_botm_ramp_h3", label: "H3" },
    { value: "tail_masking_a_botm_ramp_h4", label: "H4" },
    { value: "tail_masking_a_botm_ramp_h5", label: "H5" },
    { value: "tail_masking_a_botm_ramp_h6", label: "H6" },
    { value: "tail_masking_a_botm_length_h1", label: "H1" },
    { value: "tail_masking_a_botm_length_h2", label: "H2" },
    { value: "tail_masking_a_botm_length_h3", label: "H3" },
    { value: "tail_masking_a_botm_length_h4", label: "H4" },
    { value: "tail_masking_a_botm_length_h5", label: "H5" },
    { value: "tail_masking_a_botm_length_h6", label: "H6" },
    { value: "tail_masking_a_botm_offset_h1", label: "H1" },
    { value: "tail_masking_a_botm_offset_h2", label: "H2" },
    { value: "tail_masking_a_botm_offset_h3", label: "H3" },
    { value: "tail_masking_a_botm_offset_h4", label: "H4" },
    { value: "tail_masking_a_botm_offset_h5", label: "H5" },
    { value: "tail_masking_a_botm_offset_h6", label: "H6" },
  ],
  tableData: [
    {
      id: "1", //unique identifier in each row
      TOC: 1710260670,
      primary_data_plate_id: "ABC123",
      primary_data_steel_grade: "GradeA",
      primary_data_thickness: 10,
      primary_data_width: 150,
      primary_data_length: 3000,
      measured_data_actual_frt: 25.5,
      measured_data_FCT_error: -0.2,
      measured_data_water_temp: 30,
      PRT_indices_total_flow: 100,
      PRT_indices_speed_curve: "Smooth",
      PRT_indices_WFR: 0.5,
      PRT_indices_edge_mask: 23.4,
      "PRT_indices_H-T_mask": 233.33,
      PRT_speed_bias_initial_speed: 20,
      PRT_speed_bias_final_speed: 25,
      PRT_speed_bias_curve_shift: 0.05,
      PRT_speed_bias_max_speed: 30,
      water_flow_rate_coefficients_a0: 1.0,
      water_flow_rate_coefficients_a1: 0.8,
      water_flow_rate_coefficients_a2: 0.5,
      water_flow_rate_coefficients_a7: 0.3,
      intermediate_results_eff1: 0.85,
      intermediate_results_eff2: 0.9,
      edge_masking_position_worker_side: 140,
      edge_masking_position_driver_side: 140,
      edge_masking_length_worker_side: 140,
      edge_masking_length_driver_side: 140,
      head_masking_bank_a_ramp_h1: 5,
      head_masking_bank_a_ramp_h2: 5,
      head_masking_bank_a_ramp_h3: 5,
      head_masking_bank_a_ramp_h4: 5,
      head_masking_bank_a_ramp_h5: 5,
      head_masking_bank_a_ramp_h6: 5,
      head_masking_bank_a_length_h1: 5,
      head_masking_bank_a_length_h2: 5,
      head_masking_bank_a_length_h3: 5,
      head_masking_bank_a_length_h4: 5,
      head_masking_bank_a_length_h5: 5,
      head_masking_bank_a_length_h6: 5,
      head_masking_bank_a_offset_h1: 5,
      head_masking_bank_a_offset_h2: 5,
      head_masking_bank_a_offset_h3: 5,
      head_masking_bank_a_offset_h4: 5,
      head_masking_bank_a_offset_h5: 5,
      head_masking_bank_a_offset_h6: 5,
      head_masking_a_botm_ramp_h1: 5,
      head_masking_a_botm_ramp_h2: 5,
      head_masking_a_botm_ramp_h3: 5,
      head_masking_a_botm_ramp_h4: 5,
      head_masking_a_botm_ramp_h5: 5,
      head_masking_a_botm_ramp_h6: 5,
      head_masking_a_botm_length_h1: 5,
      head_masking_a_botm_length_h2: 5,
      head_masking_a_botm_length_h3: 5,
      head_masking_a_botm_length_h4: 5,
      head_masking_a_botm_length_h5: 5,
      head_masking_a_botm_length_h6: 5,
      head_masking_a_botm_offset_h1: 5,
      head_masking_a_botm_offset_h2: 5,
      head_masking_a_botm_offset_h3: 5,
      head_masking_a_botm_offset_h4: 5,
      head_masking_a_botm_offset_h5: 5,
      head_masking_a_botm_offset_h6: 5,
      tail_masking_bank_a_ramp_h1: 5,
      tail_masking_bank_a_ramp_h2: 5,
      tail_masking_bank_a_ramp_h3: 5,
      tail_masking_bank_a_ramp_h4: 5,
      tail_masking_bank_a_ramp_h5: 5,
      tail_masking_bank_a_ramp_h6: 5,
      tail_masking_bank_a_length_h1: 5,
      tail_masking_bank_a_length_h2: 5,
      tail_masking_bank_a_length_h3: 5,
      tail_masking_bank_a_length_h4: 5,
      tail_masking_bank_a_length_h5: 5,
      tail_masking_bank_a_length_h6: 5,
      tail_masking_bank_a_offset_h1: 5,
      tail_masking_bank_a_offset_h2: 5,
      tail_masking_bank_a_offset_h3: 5,
      tail_masking_bank_a_offset_h4: 5,
      tail_masking_bank_a_offset_h5: 5,
      tail_masking_bank_a_offset_h6: 5,
      tail_masking_a_botm_ramp_h1: 5,
      tail_masking_a_botm_ramp_h2: 5,
      tail_masking_a_botm_ramp_h3: 5,
      tail_masking_a_botm_ramp_h4: 5,
      tail_masking_a_botm_ramp_h5: 5,
      tail_masking_a_botm_ramp_h6: 5,
      tail_masking_a_botm_length_h1: 5,
      tail_masking_a_botm_length_h2: 5,
      tail_masking_a_botm_length_h3: 5,
      tail_masking_a_botm_length_h4: 5,
      tail_masking_a_botm_length_h5: 5,
      tail_masking_a_botm_length_h6: 5,
      tail_masking_a_botm_offset_h1: 5,
      tail_masking_a_botm_offset_h2: 5,
      tail_masking_a_botm_offset_h3: 5,
      tail_masking_a_botm_offset_h4: 5,
      tail_masking_a_botm_offset_h5: 5,
      tail_masking_a_botm_offset_h6: 5,
    },
    {
      id: "2", //unique identifier in each row
      TOC: 1710260670,
      primary_data_plate_id: "ABC123",
      primary_data_steel_grade: "GradeA",
      primary_data_thickness: 10,
      primary_data_width: 150,
      primary_data_length: 3000,
      measured_data_actual_frt: 25.5,
      measured_data_FCT_error: -0.2,
      measured_data_water_temp: 30,
      PRT_indices_total_flow: 100,
      PRT_indices_speed_curve: "Smooth",
      PRT_indices_WFR: 0.5,
      PRT_indices_edge_mask: 23.4,
      "PRT_indices_H-T_mask": 233.33,
      PRT_speed_bias_initial_speed: 20,
      PRT_speed_bias_final_speed: 25,
      PRT_speed_bias_curve_shift: 0.05,
      PRT_speed_bias_max_speed: 30,
      water_flow_rate_coefficients_a0: 1.0,
      water_flow_rate_coefficients_a1: 0.8,
      water_flow_rate_coefficients_a2: 0.5,
      water_flow_rate_coefficients_a7: 0.3,
      intermediate_results_eff1: 0.85,
      intermediate_results_eff2: 0.9,
      edge_masking_position_worker_side: 140,
      edge_masking_position_driver_side: 140,
      edge_masking_length_worker_side: 140,
      edge_masking_length_driver_side: 140,
      head_masking_bank_a_ramp_h1: 5,
      head_masking_bank_a_ramp_h2: 5,
      head_masking_bank_a_ramp_h3: 5,
      head_masking_bank_a_ramp_h4: 5,
      head_masking_bank_a_ramp_h5: 5,
      head_masking_bank_a_ramp_h6: 5,
      head_masking_bank_a_length_h1: 5,
      head_masking_bank_a_length_h2: 5,
      head_masking_bank_a_length_h3: 5,
      head_masking_bank_a_length_h4: 5,
      head_masking_bank_a_length_h5: 5,
      head_masking_bank_a_length_h6: 5,
      head_masking_bank_a_offset_h1: 5,
      head_masking_bank_a_offset_h2: 5,
      head_masking_bank_a_offset_h3: 5,
      head_masking_bank_a_offset_h4: 5,
      head_masking_bank_a_offset_h5: 5,
      head_masking_bank_a_offset_h6: 5,
      head_masking_a_botm_ramp_h1: 5,
      head_masking_a_botm_ramp_h2: 5,
      head_masking_a_botm_ramp_h3: 5,
      head_masking_a_botm_ramp_h4: 5,
      head_masking_a_botm_ramp_h5: 5,
      head_masking_a_botm_ramp_h6: 5,
      head_masking_a_botm_length_h1: 5,
      head_masking_a_botm_length_h2: 5,
      head_masking_a_botm_length_h3: 5,
      head_masking_a_botm_length_h4: 5,
      head_masking_a_botm_length_h5: 5,
      head_masking_a_botm_length_h6: 5,
      head_masking_a_botm_offset_h1: 5,
      head_masking_a_botm_offset_h2: 5,
      head_masking_a_botm_offset_h3: 5,
      head_masking_a_botm_offset_h4: 5,
      head_masking_a_botm_offset_h5: 5,
      head_masking_a_botm_offset_h6: 5,
      tail_masking_bank_a_ramp_h1: 5,
      tail_masking_bank_a_ramp_h2: 5,
      tail_masking_bank_a_ramp_h3: 5,
      tail_masking_bank_a_ramp_h4: 5,
      tail_masking_bank_a_ramp_h5: 5,
      tail_masking_bank_a_ramp_h6: 5,
      tail_masking_bank_a_length_h1: 5,
      tail_masking_bank_a_length_h2: 5,
      tail_masking_bank_a_length_h3: 5,
      tail_masking_bank_a_length_h4: 5,
      tail_masking_bank_a_length_h5: 5,
      tail_masking_bank_a_length_h6: 5,
      tail_masking_bank_a_offset_h1: 5,
      tail_masking_bank_a_offset_h2: 5,
      tail_masking_bank_a_offset_h3: 5,
      tail_masking_bank_a_offset_h4: 5,
      tail_masking_bank_a_offset_h5: 5,
      tail_masking_bank_a_offset_h6: 5,
      tail_masking_a_botm_ramp_h1: 5,
      tail_masking_a_botm_ramp_h2: 5,
      tail_masking_a_botm_ramp_h3: 5,
      tail_masking_a_botm_ramp_h4: 5,
      tail_masking_a_botm_ramp_h5: 5,
      tail_masking_a_botm_ramp_h6: 5,
      tail_masking_a_botm_length_h1: 5,
      tail_masking_a_botm_length_h2: 5,
      tail_masking_a_botm_length_h3: 5,
      tail_masking_a_botm_length_h4: 5,
      tail_masking_a_botm_length_h5: 5,
      tail_masking_a_botm_length_h6: 5,
      tail_masking_a_botm_offset_h1: 5,
      tail_masking_a_botm_offset_h2: 5,
      tail_masking_a_botm_offset_h3: 5,
      tail_masking_a_botm_offset_h4: 5,
      tail_masking_a_botm_offset_h5: 5,
      tail_masking_a_botm_offset_h6: 5,
    },
    {
      id: "3", //unique identifier in each row
      TOC: 1710260670,
      primary_data_plate_id: "ABC123",
      primary_data_steel_grade: "GradeA",
      primary_data_thickness: 10,
      primary_data_width: 150,
      primary_data_length: 3000,
      measured_data_actual_frt: 25.5,
      measured_data_FCT_error: -0.2,
      measured_data_water_temp: 30,
      PRT_indices_total_flow: 100,
      PRT_indices_speed_curve: "Smooth",
      PRT_indices_WFR: 0.5,
      PRT_indices_edge_mask: 23.4,
      "PRT_indices_H-T_mask": 233.33,
      PRT_speed_bias_initial_speed: 20,
      PRT_speed_bias_final_speed: 25,
      PRT_speed_bias_curve_shift: 0.05,
      PRT_speed_bias_max_speed: 30,
      water_flow_rate_coefficients_a0: 1.0,
      water_flow_rate_coefficients_a1: 0.8,
      water_flow_rate_coefficients_a2: 0.5,
      water_flow_rate_coefficients_a7: 0.3,
      intermediate_results_eff1: 0.85,
      intermediate_results_eff2: 0.9,
      edge_masking_position_worker_side: 140,
      edge_masking_position_driver_side: 140,
      edge_masking_length_worker_side: 140,
      edge_masking_length_driver_side: 140,
      head_masking_bank_a_ramp_h1: 5,
      head_masking_bank_a_ramp_h2: 5,
      head_masking_bank_a_ramp_h3: 5,
      head_masking_bank_a_ramp_h4: 5,
      head_masking_bank_a_ramp_h5: 5,
      head_masking_bank_a_ramp_h6: 5,
      head_masking_bank_a_length_h1: 5,
      head_masking_bank_a_length_h2: 5,
      head_masking_bank_a_length_h3: 5,
      head_masking_bank_a_length_h4: 5,
      head_masking_bank_a_length_h5: 5,
      head_masking_bank_a_length_h6: 5,
      head_masking_bank_a_offset_h1: 5,
      head_masking_bank_a_offset_h2: 5,
      head_masking_bank_a_offset_h3: 5,
      head_masking_bank_a_offset_h4: 5,
      head_masking_bank_a_offset_h5: 5,
      head_masking_bank_a_offset_h6: 5,
      head_masking_a_botm_ramp_h1: 5,
      head_masking_a_botm_ramp_h2: 5,
      head_masking_a_botm_ramp_h3: 5,
      head_masking_a_botm_ramp_h4: 5,
      head_masking_a_botm_ramp_h5: 5,
      head_masking_a_botm_ramp_h6: 5,
      head_masking_a_botm_length_h1: 5,
      head_masking_a_botm_length_h2: 5,
      head_masking_a_botm_length_h3: 5,
      head_masking_a_botm_length_h4: 5,
      head_masking_a_botm_length_h5: 5,
      head_masking_a_botm_length_h6: 5,
      head_masking_a_botm_offset_h1: 5,
      head_masking_a_botm_offset_h2: 5,
      head_masking_a_botm_offset_h3: 5,
      head_masking_a_botm_offset_h4: 5,
      head_masking_a_botm_offset_h5: 5,
      head_masking_a_botm_offset_h6: 5,
      tail_masking_bank_a_ramp_h1: 5,
      tail_masking_bank_a_ramp_h2: 5,
      tail_masking_bank_a_ramp_h3: 5,
      tail_masking_bank_a_ramp_h4: 5,
      tail_masking_bank_a_ramp_h5: 5,
      tail_masking_bank_a_ramp_h6: 5,
      tail_masking_bank_a_length_h1: 5,
      tail_masking_bank_a_length_h2: 5,
      tail_masking_bank_a_length_h3: 5,
      tail_masking_bank_a_length_h4: 5,
      tail_masking_bank_a_length_h5: 5,
      tail_masking_bank_a_length_h6: 5,
      tail_masking_bank_a_offset_h1: 5,
      tail_masking_bank_a_offset_h2: 5,
      tail_masking_bank_a_offset_h3: 5,
      tail_masking_bank_a_offset_h4: 5,
      tail_masking_bank_a_offset_h5: 5,
      tail_masking_bank_a_offset_h6: 5,
      tail_masking_a_botm_ramp_h1: 5,
      tail_masking_a_botm_ramp_h2: 5,
      tail_masking_a_botm_ramp_h3: 5,
      tail_masking_a_botm_ramp_h4: 5,
      tail_masking_a_botm_ramp_h5: 5,
      tail_masking_a_botm_ramp_h6: 5,
      tail_masking_a_botm_length_h1: 5,
      tail_masking_a_botm_length_h2: 5,
      tail_masking_a_botm_length_h3: 5,
      tail_masking_a_botm_length_h4: 5,
      tail_masking_a_botm_length_h5: 5,
      tail_masking_a_botm_length_h6: 5,
      tail_masking_a_botm_offset_h1: 5,
      tail_masking_a_botm_offset_h2: 5,
      tail_masking_a_botm_offset_h3: 5,
      tail_masking_a_botm_offset_h4: 5,
      tail_masking_a_botm_offset_h5: 5,
      tail_masking_a_botm_offset_h6: 5,
    },
  ],
};

export const dummyReportCardData = {
  totalPlatesRun: { value: 20 },
  platesOutsideRecommendedRange: { value: 80 },
  platesWithinRecommendedRange: { value: 100 },
};

export const dummyReportTableData = {
  columns: [
    {
      value: "TOC",
      label: "TOC",
    },
    {
      value: "plate_id",
      label: "Plate Id",
    },

  ],
  tableData: [
    {
      id: "1", //unique identifier in each row
      TOC: 1710260670,
      plate_id: "ABMH123",
    },
  ]
};

export const dummySingleConfigData = {
  current_config: {
    bias: {
      initial_speed: 12,
      final_speed: 12,
      curve_shift: 12,
      max_speed: 12,
    },
    wfr: {
      a0: 1,
      a1: 2,
      a2: 3,
      a7: 5,
    },
    flow_related_bias: {
      total: 12,
      top: 13,
      bottom: 15,
    },
    edge_masking: {
      columns: ["bank", "ws", "ds"],
      table_data: [
        { bank: "1", ws: 232342, ds: 22343 },
        { bank: "2", ws: 232342, ds: 22343 },
        { bank: "3", ws: 232342, ds: 22343 },
        { bank: "4", ws: 232342, ds: 22343 },
      ],
    },
    head_masking: {
      top: {
        ramp: {
          h1: { value: 12, flag: true },
          h2: 10,
          h3: 11,
          h4: 15,
          h5: 16,
          h6: 17,
        },
        length: { h1: 12, h2: 10, h3: 11, h4: 15, h5: 16, h6: 17 },
        offset: { h1: 12, h2: 10, h3: 11, h4: 15, h5: 16, h6: 17 },
      },
      bottom: {
        ramp: {
          h1: 12,
          h2: 10,
          h3: 11,
          h4: 15,
          h5: 16,
          h6: 17,
        },
        length: { h1: 12, h2: 10, h3: 11, h4: 15, h5: 16, h6: 17 },
        offset: { h1: 12, h2: 10, h3: 11, h4: 15, h5: 16, h6: 17 },
      },
    },
    tail_masking: {
      top: {
        ramp: {
          h1: 1,
          h2: 2,
          h3: 3,
          h4: 4,
          h5: 5,
          h6: 6,
        },
        length: { h1: 7, h2: 8, h3: 9, h4: 10, h5: 11, h6: 12 },
        offset: { h1: 13, h2: 14, h3: 15, h4: 16, h5: 17, h6: 18 },
      },
      bottom: {
        ramp: {
          h1: 1,
          h2: 2,
          h3: 3,
          h4: 4,
          h5: 5,
          h6: 6,
        },
        length: { h1: 7, h2: 8, h3: 9, h4: 10, h5: 11, h6: 12 },
        offset: { h1: 13, h2: 14, h3: 15, h4: 16, h5: 17, h6: 18 },
      },
    },
  },
  recommended_config: {
    bias: {
      initial_speed: 12,
      final_speed: 12,
      curve_shift: 12,
      max_speed: 12,
    },
    wfr: {
      a0: 1,
      a1: 2,
      a2: 3,
      a7: 5,
    },
    flow_related_bias: {
      total: 12,
      top: 13,
      bottom: 15,
    },
    edge_masking: {
      columns: ["bank", "ws", "ds"],
      table_data: [
        { bank: "1", ws: 232342, ds: 22343 },
        { bank: "2", ws: 232342, ds: 22343 },
        { bank: "3", ws: 232342, ds: 22343 },
        { bank: "4", ws: 232342, ds: 22343 },
      ],
    },
    head_masking: {
      top: {
        ramp: {
          h1: 12,
          h2: 10,
          h3: 11,
          h4: 15,
          h5: 16,
          h6: 17,
        },
        length: { h1: 12, h2: 10, h3: 11, h4: 15, h5: 16, h6: 17 },
        offset: { h1: 12, h2: 10, h3: 11, h4: 15, h5: 16, h6: 17 },
      },
      bottom: {
        ramp: {
          h1: 12,
          h2: 10,
          h3: 11,
          h4: 15,
          h5: 16,
          h6: 17,
        },
        length: { h1: 12, h2: 10, h3: 11, h4: 15, h5: 16, h6: 17 },
        offset: { h1: 12, h2: 10, h3: 11, h4: 15, h5: 16, h6: 17 },
      },
    },
    tail_masking: {
      bank_a: {
        ramp: {
          h1: 1,
          h2: 2,
          h3: 3,
          h4: 4,
          h5: 5,
          h6: 6,
        },
        length: { h1: 7, h2: 8, h3: 9, h4: 10, h5: 11, h6: 12 },
        offset: { h1: 13, h2: 14, h3: 15, h4: 16, h5: 17, h6: 18 },
      },
      a_botm: {
        ramp: {
          h1: 1,
          h2: 2,
          h3: 3,
          h4: 4,
          h5: 5,
          h6: 6,
        },
        length: { h1: 7, h2: 8, h3: 9, h4: 10, h5: 11, h6: 12 },
        offset: { h1: 13, h2: 14, h3: 15, h4: 16, h5: 17, h6: 18 },
      },
    },
  },
};

export const dummyMatrixQualityData = {
  columns: [
    { label: "Grade", value: "STEELGRADE" },
    { label: "Thickness Min", value: "MIN_THK" },
    { label: "Thickness Max", value: "MAX_THK" },
    { label: "FCT Min", value: "FCT_MIN" },
    { label: "FCT Max", value: "FCT_MAX" },
  ],
  tableData: [
    {
      id: 1, //unique id in each row
      grade: "ABCGSGGS",
      thickness_min: 2.5,
      thickness_max: 3.0,
      fct_min: 450,
      fct_max: 500,
    },
    {
      id: 2,
      grade: "BDHHDHD",
      thickness_min: 3.0,
      thickness_max: 3.5,
      fct_min: 400,
      fct_max: 450,
    },
    {
      id: 3,
      grade: "CSHHSS",
      thickness_min: 2.0,
      thickness_max: 2.5,
      fct_min: 500,
      fct_max: 550,
    },
    {
      id: 4,
      grade: "ABSBBSB",
      thickness_min: 2.0,
      thickness_max: 2.5,
      fct_min: 420,
      fct_max: 470,
    },
    {
      id: 5,
      grade: "BSHSHHSH",
      thickness_min: 2.5,
      thickness_max: 3.0,
      fct_min: 480,
      fct_max: 530,
    },
  ],
};

export const dummyWeightsData = {
  columns: [
    { label: "Parameter", value: "parameter" },
    { label: "Weight", value: "weight" },

  ],
  tableData: [
    {
      id: 1, //unique id in each row
      parameter: "JA25OPTM01",
      weight: 2,

    },
    {
      id: 2,
      parameter: "JA25OPTM02",
      weight: 3,

    },
    {
      id: 3,
      parameter: "JA41MLMN02",
      weight: 2,

    },
    {
      id: 4,
      parameter: "JA50WTMP03",
      weight: 2,

    },
    {
      id: 5,
      parameter: "JA50WTMP03",
      weight: 4,

    },
  ],
}

export const dummyProductionReportData = {
  pieData: [100, 200, 300],
  lineData: {
    data: [{
      name: "Plate Count",
      data: [75, 12],
    }],
    timeStamps: [1707040892000, 1712224591912],
  },
  stackBarData: [
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      timeStamp: 1707040892000
    },
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      timeStamp: 1712224591912
    }
  ]
}

export const dummyTargetTemperatureData = {
  frt: {
    Overall: [
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
    ],
    Head: [
      {
        "In Range": 20,
        "Low": 40,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
    ],
    Body: [
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
    ],
    Tail: [
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
    ],
    Length: [
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
    ]
  },
  sct: {
    Overall: [
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 20,
        "Low": 40,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
    ],
    Head: [
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
    ],
    Body: [
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
    ],
    Tail: [
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
    ],
    Length: [
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
    ]
  }
}

export const dummyHighMovingGradesData = {
  Overall: [
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      grade: "J2H1011111"
    },
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      grade: "J2H1011112"
    },
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      grade: "J2H1011113"
    },
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      grade: "J2H1011114"
    },
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      grade: "J2H1011115"
    }
  ],
  Head: [
    {
      data: {
        "In Range": 20,
        "Low": 40,
        "High": 30
      },
      grade: "J2H1011111"
    },
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      grade: "J2H1011112"
    },
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      grade: "J2H1011113"
    },
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      grade: "J2H1011114"
    },
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      grade: "J2H1011115"
    }
  ],
  Body: [
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      grade: "J2H1011111"
    },
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      grade: "J2H1011112"
    },
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      grade: "J2H1011113"
    },
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      grade: "J2H1011114"
    },
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      grade: "J2H1011115"
    }
  ],
  Tail: [
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      grade: "J2H1011111"
    },
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      grade: "J2H1011112"
    },
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      grade: "J2H1011113"
    },
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      grade: "J2H1011114"
    },
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      grade: "J2H1011115"
    }
  ],
  Length: [
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      grade: "J2H1011111"
    },
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      grade: "J2H1011112"
    },
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      grade: "J2H1011113"
    },
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      grade: "J2H1011114"
    },
    {
      data: {
        "In Range": 10,
        "Low": 20,
        "High": 30
      },
      grade: "J2H1011115"
    }
  ],
}

export const dummyInsufficientData = {
  pieData: [100, 200],
  barData: [
    {
      data: {
        "Insufficient": 10,
        "Sufficient": 20,
      },
      grade: "J2H1011111"
    },
    {
      data: {
        "Insufficient": 10,
        "Sufficient": 20,
      },
      grade: "J2H1011111"
    },
    {
      data: {
        "Insufficient": 10,
        "Sufficient": 20,
      },
      grade: "J2H1011111"
    },
    {
      data: {
        "Insufficient": 10,
        "Sufficient": 20,
      },
      grade: "J2H1011111"
    },
    {
      data: {
        "Insufficient": 10,
        "Sufficient": 20,
      },
      grade: "J2H1011111"
    },
  ],
}

export const dummyAnalysisData = {
  opt1: {
    "Grade": 1234,
    "Thickness": 234,
    "Width": 12,
    "Length": 45,
    "Temp Equal(TGT_FCT)": 25,
    "Temper P1 (TGTFRT)": 12,
    "Water Temperature": 20,
  },
  temperatureBars: {
    sec1: {
      "FRT": {
        overall: [-25, 25],
        optimal: [-10, 10],
        current: 670,
        temp: 650
      },
      "SCT": {
        overall: [-25, 25],
        optimal: [-10, 10],
        current: 670,
        temp: 650
      },
      "FCT": {
        overall: [-25, 25],
        optimal: [-10, 10],
        current: 670,
        temp: 650
      }
    },
    sec2: {
      "Head FCT": {
        overall: [-25, 25],
        optimal: [-10, 10],
        current: 670,
        temp: 650
      },
      "Body FCT": {
        overall: [-25, 25],
        optimal: [-10, 10],
        current: 670,
        temp: 650
      },
      "Tail FCT": {
        overall: [-25, 25],
        optimal: [-10, 10],
        current: 670,
        temp: 650
      },
      "Length FCT": {
        overall: [-25, 25],
        optimal: [-10, 10],
        current: 670,
        temp: 650
      }
    }
  },
  opt2: {
    "T_tunnel_in (Act_SCT)": 123,
    "temper_pX": 123,
    "FCT_Error": 12,
    "AFRT_TSCT_Error": 12,
    "ASCT_TSCT_Error": 123,
    "GradeShift": 12,
    "ThickShift": 45,
    "LenShift": 46,
    "WidthShift": 78,
    "Cooling Speed": 12,
    "ACR": 78,
    "ACTUALCOOLTIME": 78,
    "total_flow": 45,
    "A_AVG_WFD": 78,
    "A_BTMWFD": 78,
    "A_TOPWFD": 89,
    "BCD_BTMWFD": 45,
    "BCD_TOPWFD": 32,
    "B_AVG_WFD": 42,
    "initial_spd": 89,
    "final_spd": 56
  }
}

export const ibaData = {
  lineData: {
    data: [{
      name: "Plate Count",
      data: [75, 12],
    }],
    timeStamps: [1707040892000, 1712224591912],
  },
}

export const leftIbaData = {
  temperature: {
    xAxisData: [0, 20, 35, 40, 50, 60],
    series: [
      {
        name: "A1 Btm Actual",
        data: [0, 10, 20, 40, 60, 100]
      },
      {
        name: "A1 Btm Reference",
        data: [0, 20, 40, 30, 50, 80]
      },
      {
        name: "A2 Btm Actual",
        data: [10, 10, 20, 40, 60, 100]
      },
      {
        name: "A2 Btm Reference",
        data: [10, 20, 40, 30, 50, 80]
      },
    ]
  },
  flowRate: {
    xAxisData: [0, 20, 35, 40, 50, 60],
    series: [
      {
        name: "A1 Btm Actual",
        data: [{
          x: 65765767868,
          y: 0
        }, 10, 20, 40, 60, 100],
        type: 0,
      },
      {
        name: "A1 Btm Reference",
        data: [0, 20, 40, 30, 50, 80]
      },
      {
        name: "A2 Btm Actual",
        data: [0, 10, 20, 40, 60, 100]
      },
      {
        name: "A2 Btm Reference",
        data: [0, 20, 40, 30, 50, 80]
      },
    ]
  },
  speed: {
    xAxisData: [0, 20, 35, 40, 50, 60],
    series: [
      {
        name: "A1 Btm Actual",
        data: [0, 10, 20, 40, 60, 100]
      },
      {
        name: "A1 Btm Reference",
        data: [0, 20, 40, 30, 50, 80]
      },
      {
        name: "A2 Btm Actual",
        data: [0, 10, 20, 40, 60, 100]
      },
      {
        name: "A2 Btm Reference",
        data: [0, 20, 40, 30, 50, 80]
      },
    ]
  },
}

export const rightIbaData = {
  overlayData: [
    {
      xAxisData: [0, 20, 35, 40, 50, 60],
      series: [
        {
          name: "Head Pos",
          data: [0, 10, 20, 40, 60, 100]
        },
        {
          name: "Tail Pos",
          data: [0, 20, 40, 30, 50, 80]
        },
        {
          name: "Temperature",
          data: [8, 10, 5, 6, 9, 20]
        }
      ]
    },
    {
      xAxisData: [0, 20, 35, 40, 50, 60],
      series: [
        {
          name: "Head Pos",
          data: [0, 10, 20, 40, 60, 100]
        },
        {
          name: "Tail Pos",
          data: [0, 20, 40, 30, 50, 80]
        },
        {
          name: "Temperature",
          data: [8, 10, 5, 6, 9, 20]
        }
      ]
    }
  ],
  singleAxisData: [
    {
      series: [
        {
          name: "Head Pos",
          data: [75, 12, 23, 46],
        },
        {
          name: "Tail Pos",
          data: [12, 75, 46, 23],
        },
      ],
      xAxisData: [12, 24, 50, 60]
    },
    {
      series: [
        {
          name: "Head Pos",
          data: [75, 12, 23, 46],
        },
        {
          name: "Tail Pos",
          data: [12, 75, 46, 23],
        },
      ],
      xAxisData: [12, 24, 50, 60]
    }

  ]
}

export const ibaMaskingData = {
  columns: [
    {
      value: "TOC",
      label: "TOC",
    },
    {
      value: "primary_data_plate_id",
      label: "Plate Id",
    },
  ],
  tableData: [
    {
      id: "1", //unique identifier in each row
      TOC: 1710260670,
      primary_data_plate_id: "ABMH123",
    },
    {
      id: "2", //unique identifier in each row
      TOC: 1710260670,
      primary_data_plate_id: "ABX123",
    },
    {
      id: "3", //unique identifier in each row
      TOC: 1710260670,
      primary_data_plate_id: "ABY123",
    },
    {
      id: "4", //unique identifier in each row
      TOC: 1710260670,
      primary_data_plate_id: "ABH123",
    },
    {
      id: "5", //unique identifier in each row
      TOC: 1710260670,
      primary_data_plate_id: "ABS123",
      primary_data_steel_grade: "GradeA",
    },
    {
      id: "6", //unique identifier in each row
      TOC: 1710260670,
      primary_data_plate_id: "ABM123",
      primary_data_steel_grade: "GradeA",
    },
    {
      id: "7", //unique identifier in each row
      TOC: 1710260670,
      primary_data_plate_id: "ABD123",
    },
    {
      id: "8", //unique identifier in each row
      TOC: 1710260670,
      primary_data_plate_id: "ABK123",
    },
  ]
}

export const dummyPlateIdData = {
  "F13F574JGR": [
    {
      parameter: "Actual FRT",
      checked: false,
      type: 0,
    },
    {
      parameter: "Recommended FRT",
      checked: false,
      type: 0,
    }
  ],
  "F2ABV1763JY": [
    {
      parameter: "Actual SCT",
      checked: false,
      type: 0,
    },
    {
      parameter: "Recommended SCT",
      checked: false,
      type: 0,
    }
  ]
}

export const plateIdData = [
  {
    plate_id: "1",
    parameter: "Actual FRT",
    checked: false,
    type: 0,
  },
  {
    plate_id: "2",
    parameter: "Recommended FRT",
    checked: false,
    type: 0,
  },
  {
    plate_id: "3",
    parameter: "Actual SCT",
    checked: false,
    type: 0,
  },
  {
    plate_id: "4",
    parameter: "Recommended SCT",
    checked: false,
    type: 0,
  },
  {
    plate_id: "1",
    parameter: "Actual Temperature",
    checked: false,
    type: 0,
  }
]

export const dummyChartsData = [
  {
    name: "Series A",
    data: [
      {
        x: 1716375611719,
        y: 1000,
      },
      {
        x: 1716385611719,
        y: 2000,
      }
    ],
    type: 0,
  },
  {
    name: "Series C",
    data: [
      {
        x: 1716375611719,
        y: 10,
      },
      {
        x: 1716385611719,
        y: 20,
      }
    ],
    type: 0,
  },
  {
    name: "Series B",
    data: [
      {
        x: 1716375611719,
        y: 40,
      },
      {
        x: 1716385611719,
        y: 20,
      },
      {
        x: 1716485611719,
        y: 30,
      }
    ],
    type: 1,
  }
]

export const boxPlotDummyData = [
  {
    x: 'Jan 2015',
    y: [54, 66, 69, 75, 88]
  },
  {
    x: 'Jan 2016',
    y: [43, 65, 69, 76, 81]
  },
  {
    x: 'Jan 2017',
    y: [31, 39, 45, 51, 59]
  },
  {
    x: 'Jan 2018',
    y: [39, 46, 55, 65, 71]
  },
  {
    x: 'Jan 2019',
    y: [29, 31, 35, 39, 44]
  },
  {
    x: 'Jan 2020',
    y: [41, 49, 58, 61, 67]
  },
  {
    x: 'Jan 2021',
    y: [54, 59, 66, 71, 88]
  }
]

export const dummyAdditionalChartsData = {
  plate1: [
    {
      name: "Series A",
      data: [
        {
          x: 1716375611719,
          y: 1000,
        },
        {
          x: 1716385611719,
          y: 2000,
        }
      ],
      type:0,
    },
    {
      name: "Series C",
      data: [
        {
          x: 1716375611719,
          y: 10,
        },
        {
          x: 1716385611719,
          y: 20,
        }
      ],
      type:0,
    },
    {
      name: "Series B",
      data: [
        {
          x: 1716375611719,
          y: 40,
        },
        {
          x: 1716385611719,
          y: 20,
        },
        {
          x: 1716485611719,
          y: 30,
        }
      ],
      type:1,
    }
  ],
  // plate2: [
  //   {
  //     name: "Series A",
  //     data: [
  //       {
  //         x: 1716375611719,
  //         y: 1000,
  //       },
  //       {
  //         x: 1716385611719,
  //         y: 2000,
  //       }
  //     ],
  //     type:0,
  //   },
  //   {
  //     name: "Series C",
  //     data: [
  //       {
  //         x: 1716375611719,
  //         y: 10,
  //       },
  //       {
  //         x: 1716385611719,
  //         y: 20,
  //       }
  //     ],
  //     type:0,
  //   },
  //   {
  //     name: "Series B",
  //     data: [
  //       {
  //         x: 1716375611719,
  //         y: 40,
  //       },
  //       {
  //         x: 1716385611719,
  //         y: 20,
  //       },
  //       {
  //         x: 1716485611719,
  //         y: 30,
  //       }
  //     ],
  //     type:1,
  //   }
  // ],
  // plate3: [
  //   {
  //     name: "Series A",
  //     data: [
  //       {
  //         x: 1716375611719,
  //         y: 1000,
  //       },
  //       {
  //         x: 1716385611719,
  //         y: 2000,
  //       }
  //     ],
  //     type:0,
  //   },
  //   {
  //     name: "Series C",
  //     data: [
  //       {
  //         x: 1716375611719,
  //         y: 10,
  //       },
  //       {
  //         x: 1716385611719,
  //         y: 20,
  //       }
  //     ],
  //     type:0,
  //   },
  //   {
  //     name: "Series B",
  //     data: [
  //       {
  //         x: 1716375611719,
  //         y: 40,
  //       },
  //       {
  //         x: 1716385611719,
  //         y: 20,
  //       },
  //       {
  //         x: 1716485611719,
  //         y: 30,
  //       }
  //     ],
  //     type:1,
  //   }
  // ],
  // plate4: [
  //   {
  //     name: "Series A",
  //     data: [
  //       {
  //         x: 1716375611719,
  //         y: 1000,
  //       },
  //       {
  //         x: 1716385611719,
  //         y: 2000,
  //       }
  //     ],
  //     type:0,
  //   },
  //   {
  //     name: "Series C",
  //     data: [
  //       {
  //         x: 1716375611719,
  //         y: 10,
  //       },
  //       {
  //         x: 1716385611719,
  //         y: 20,
  //       }
  //     ],
  //     type:0,
  //   },
  //   {
  //     name: "Series B",
  //     data: [
  //       {
  //         x: 1716375611719,
  //         y: 40,
  //       },
  //       {
  //         x: 1716385611719,
  //         y: 20,
  //       },
  //       {
  //         x: 1716485611719,
  //         y: 30,
  //       }
  //     ],
  //     type:1,
  //   }
  // ],
}