import ReactApexCharts from "react-apexcharts";
import { useState, useEffect } from "react";
import { capitalizeFirstLetter } from "../../../util/sentenceCase";
const HourlyLineChart = ({ data, type, color }) => {
  //manging x axis and y axis values
  const [times, setTimes] = useState([]);
  const [series, setSeries] = useState([]);
  const updateSeries = () => {
    setSeries((prevSeries) => {
      let newData = [];
      let uniqueKeys;
      if (type !== "moisture") {
        uniqueKeys = Object.keys(data[0]?.[type] || {});
        uniqueKeys.forEach((item) => {
          newData.push({
            name: item,
            data: [],
          });
        });
      } else {
        newData.push({
          name: "moisture",
          data: [],
        });
      }
      let times = [];
      data.forEach((item) => {
        times.push(item?.timestamp);
        if (type === "moisture") {
          newData[0]?.data?.push(item[type]);
        } else {
          uniqueKeys.forEach((x) => {
            let idx = newData.findIndex((val) => val.name === x);
            newData[idx]?.data?.push(item[type][x]);
          });
        }
      });
      setTimes(times);
      return newData;
    });
  };
  useEffect(() => {
    updateSeries();
  }, [data, type]);
  //chart options
  const options = {
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          return y + "%";
        },
      },
      theme: "dark",
      fillSeriesColor: true,
      style: {
        fontSize: "16px",
      },
    },
    chart: {
      type: "line",
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: true,
        },
      },
      animations: {
        enabled: false,
      },
    },
    xaxis: {
      categories: times,
      type: "datetime",
      labels: {
        show: true,
        formatter: function (value) {
          const date = new Date(value);
          const hours = date.getHours();
          const minutes = date.getMinutes().toString().padStart(2, "0");
          const ampm = hours >= 12 ? "PM" : "AM";
          const formattedHours = hours === 0 || hours === 12 ? 12 : hours % 12;
          return `${formattedHours}:${minutes} ${ampm}`;
        },
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      title: {
        text: "",
        style: {
          fontSize: "16px",
          color: "#000000",
          fontWeight: 500,
          fontFamily: "Poppins",
        },
      },
      labels: {
        style: {
          fontSize: "12px",
        },
        formatter: function (value) {
          return value?.toFixed(2) + "%";
        },
      },
    },
    colors: color,
    stroke: {
      curve: "smooth",
      width: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
    },
    grid: {
      borderColor: "#E7E7E7",
      row: {
        colors: ["#FAFAFA", "transparent"],
        opacity: 1,
      },
    },
    legend: {
      show: true,
      offsetY: 15,
      offsetX: 0,
      position: "top",
      horizontalAlign: "left",
      fontSize: "12px",
      formatter: function (val, opts) {
        return capitalizeFirstLetter(val); // Capitalizing legend labels
      },
      align: "center",
    },
  };
  return (
    <ReactApexCharts
      options={options}
      series={series}
      type="line"
      height="100%"
      width="100%"
    />
  );
};
export default HourlyLineChart;
