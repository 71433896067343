import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  Skeleton,
} from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { baseURL } from "../../..";
import axios from "axios";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";

const AlertImagesModal = ({ openModal, closeModal, row, checked }) => {
  const [selectedImage, setSelectedImage] = useState(1);
  const [videoLink, setVideoLink] = useState({
    url: "",
    success: null,
    message: "",
    createdAt: null,
    lastUpdatedAt: null,
    loading: false,
    loaded: false,
  });
  const cameraImageToVideoApi = async () => {
    setVideoLink((prevVideoLink) => ({
      ...prevVideoLink,
      loading: true,
    }));
    const requestData = {
      clientId: "asianpaints",
      useCase: "WORKFORCESAFETY",
      plantName: "khandala",
      entityId: row.entityId,
      event: row.event,
      subEvent: row.subEvent,
      // entityId: "bay1_1705390226",
      // event: "sideSampling",

      // subEvent: "sampleCollection",
    };
    console.log("123 called", requestData);
    const config = {
      method: "post",
      url: baseURL + `vision/v1/workforceMonitoring/feed/video/`,
      headers: {
        "Content-Type": "application/json",
      },
      data: requestData,
    };

    const res = await axios(config)
      .then((response) => {
        console.log("API Response:", response.data);
        setVideoLink((prevVideoLink) => ({
          ...prevVideoLink,
          url: response?.data?.url,
          success: response?.data?.success,
          message: response?.data?.message,
          createdAt: response?.data?.createdAt,
          lastUpdatedAt: response?.data?.lastUpdatedAt,
          loading: false,
          loaded: true,
        }));
      })
      .catch((error) => {
        console.error("API Error:", error);
        setVideoLink((prevVideoLink) => ({
          ...prevVideoLink,
          url: "",
          success: null,
          message: "",
          createdAt: null,
          lastUpdatedAt: null,
          loading: false,
          loaded: false,
        }));
      });
  };
  useEffect(() => {
    setSelectedImage(Object.keys(row?.annotatedImage || {})[0]);
    cameraImageToVideoApi();
    return () => {
      setVideoLink({
        url: "",
        success: null,
        message: "",
        createdAt: null,
        lastUpdatedAt: null,
        loading: false,
        loaded: false,
      });
    };
  }, []);

  console.log("alertmodal", row);
  return (
    <ChakraProvider>
      <Modal isOpen={openModal} onClose={closeModal} isCentered="true">
        <ModalOverlay />
        <ModalContent style={{ borderRadius: "12px" }} maxW="933px">
          <ModalHeader padding="0px">
            <div className="pl-6 py-3 pr-2 bg-[#F5F5F5] items-center flex justify-between text-sm whitespace-nowrap rounded-t-xl">
              <div className="flex gap-3 items-center text-black font-medium">
                <AccessAlarmIcon className="text-[#024D87]" />
                <p>{new Date(row.startTs * 1000).toLocaleDateString()}</p>
              </div>
              <img
                src="/WorkforceSafetyIcons/cross.svg"
                className="cursor-pointer"
                onClick={() => closeModal()}
              />
            </div>
          </ModalHeader>
          <ModalBody p="16px" rounded="12px">
            <div className="flex gap-4">
              <div className="flex flex-col gap-2 w-full">
                <p className="text-xs text-[#605D64] font-medium">
                  {checked == "Pass" ? "Compliance" : "Violation"}
                </p>
                <div
                  className={`flex flex-col gap-2 py-3 pl-4 pr-6 ${
                    checked == "Pass" ? "bg-[#D0F0C0]" : "bg-[#FCEEEE]"
                  }   text-[#3E3C42] text-sm font-medium rounded-xl h-full`}
                >
                  <p className="capitalize font-bold">
                    {row?.subEvent || row?.check}
                  </p>
                  <p className="capitalize ">{row?.msg}</p>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2 max-w-[42vw] overflow-x-auto  ">
                  {row?.annotatedImage != null &&
                    Object.keys(row.annotatedImage).map((val) => {
                      return (
                        <Button
                          colorScheme="gray"
                          variant="link"
                          fontSize="12px"
                          isDisabled={selectedImage == val}
                          onClick={() => setSelectedImage(val)}
                        >
                          {val}
                        </Button>
                      );
                    })}
                </div>

                <div className="relative w-[660px] h-[500px] bg-black rounded-xl flex justify-center items-center">
                  {videoLink?.success == null && videoLink?.loading == true ? (
                    <Skeleton height={"100%"} width={"100%"} />
                  ) : videoLink?.success == true &&
                    videoLink?.loading == false ? (
                    <video
                      muted
                      autoPlay
                      loop
                      crossOrigin="anonymous"
                      className="w-full h-full rounded-xl"
                      src={videoLink.url}
                      type="video/mp4"
                    ></video>
                  ) : (
                    <img
                      src={
                        row.annotatedImage != null
                          ? row.annotatedImage[selectedImage]
                          : "/imgnotfound.jpg"
                      }
                      crossOrigin="anonymous"
                      className="w-full h-full "
                    />
                  )}

                  <div className="absolute top-2 left-2 bg-black rounded-md opacity-70 p-[2px]">
                    <p className="text-white text-xs font-semibold bg-black rounded-lg">
                      {new Date(row.startTs * 1000).toLocaleTimeString(
                        "en-US",
                        {
                          timeZone: "UTC",
                        }
                      )}
                    </p>
                  </div>
                  <div className="absolute top-2 right-2 bg-black rounded-md opacity-70 p-[2px]">
                    {videoLink?.success == true &&
                    videoLink?.loading == false ? (
                      <p className="text-white text-xs font-semibold bg-black rounded-lg">
                        {new Date(row.endTs * 1000).toLocaleTimeString(
                          "en-US",
                          {
                            timeZone: "UTC",
                          }
                        )}
                      </p>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default AlertImagesModal;
