import { Linechart } from "../../../Charts/IsfOptimizerCharts/LineChart";

export const ParametersLineChart = ({
  title = "Actual",
  chartData,
  colors = ["#1C56AC", "#CDEEBF"],
}) => {
  const updatedChartData = {
    series: [
      {
        name: title,
        type: "line",
        data: chartData?.["data"],
      },
    ],
    options: {
      chart: {
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: true,
            zoomin: false,
            zoomout: false,
            pan: false,
          },
        },
        animations: {
          enabled: false,
        },
        zoom: {
          enabled: true,
        },
      },
      grid: {
        borderColor: "#e7e7e7",
        strokeDashArray: 6,
        padding: {
          top: 0,
          right: 5,
          bottom: 0,
          left: 5,
        },
      },
      markers: {
        size: 0,
      },
      xaxis: {
        categories: chartData?.["times"],
        type: "datetime",
        labels: {
          show: true,
          datetimeUTC: false,
          // format: "hh tt",
          style: {
            fontSize: "12px",
          },
        },
      },
      yaxis: {
        // min max can be int or float
        min: chartData?.min || 0,
        max: chartData?.max || 100,
        type: "numeric",
      },
      colors: colors,
      tooltip: {
        x: {
          format: "dd MMM yyyy HH:mm",
        },
      },
      dataLabel: {
        enabled: false,
      },

      stroke: {
        curve: "straight",
        width: [2, 0],
        dashArray: [0, 6],
        colors: colors,
      },
      // fill: {
      //   type: "solid",
      //   opacity: [1, 1],
      // },
      legend: {
        show: true,
        showForSingleSeries: true,
        customLegendItems: ["Actual"],
        position: "top",
        horizontalAlign: "left",
        fontSize: "12px",
      },

      //   annotation to show optimal range
      // annotations: {
      //   yaxis: [
      //     {
      //       y: chartData?.optimalRange?.split("-")[0],
      //       y2: chartData?.optimalRange?.split("-")[1],
      //       borderColor: "#000",
      //       fillColor: "#CDEEBF",
      //     },
      //   ],
      // },
    },
  };

  return (
    <div className={`w-[100%] h-[250px] bg-white`}>
      <Linechart chart={updatedChartData} />
    </div>
  );
};
