import React from "react";
import { Skeleton, Checkbox } from "@chakra-ui/react";

const LibraryGridSkeleton = () => {
  return (
    <div className="flex flex-col gap-3">
      <div className="flex justify-between items-baseline">
        <div className="flex items-baseline gap-2 w-[30%] md:w-[10%]">
          <Skeleton
            height={"20px"}
            width={"100%"}
          />
        </div>
        <div className="flex sm:flex-row flex-col items-center gap-1 w-[50%] md:w-[20%]">
          <Skeleton
            height={"35px"}
            width={"100%"}
          />
        </div>
      </div>
      <div className="gap-1 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4 h-[80vh] overflow-y-scroll">
        {[...Array(18)].map((_, id) => (
          <div
            key={id}
            className="relative w-full text-center object-cover"
          >
            <div className="top-2 right-2 absolute flex gap-2">
              <Skeleton
                height="20px"
                width="70px"
              />
              <Skeleton
                height="20px"
                width="70px"
              />
            </div>
            <Skeleton
              height="150px"
              rounded={"md"}
            />
            <div className="top-[40px] right-2 absolute flex flex-col items-end gap-2 opacity-50 hover:opacity-100">
              <Skeleton
                height="20px"
                width="70px"
              />
              <Skeleton
                height="20px"
                width="70px"
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default LibraryGridSkeleton;
