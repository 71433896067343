import EditIcon from "@mui/icons-material/Edit";
import { useRef, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { baseURL } from "../../..";
import axios from "axios";
import { Toast, useToast } from "@chakra-ui/react";

const CustomCell = ({ rowIndex, truckName }) => {
  const inputRef = useRef();
  const [editedText, setEditedText] = useState();
  const [editCell, setEditCell] = useState(false);
  const toast = useToast();
  console.log("rowIndex", rowIndex.row.mergedEntityIds);
  const uniqueId =
    rowIndex?.row?.mergedEntityIds?.length > 0
      ? rowIndex?.row?.mergedEntityIds
      : [];

  const handleChangeTruckName = async () => {
    let data = JSON.stringify({
      clientId: "asianpaints",
      useCase: "WORKFORCESAFETY",
      mergedEntityIds: uniqueId,
      keyToUpdate: "vehicleNo",
      valueToUpdate: inputRef.current.value,
    });

    if (inputRef.current.value == "") {
      toast({
        title: `You can't set empty vehicle number`,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      setEditCell(false);
      return;
    }

    if (inputRef.current.value.length >= 26) {
      toast({
        title: `Vehicle number can't be more than 25 characters`,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      setEditCell(false);
      return;
    }

    try {
      const response = await axios.post(
        baseURL + "vision/v1/workforceMonitoring/updateVehicleNo/",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data == "vehicleNo Updated") {
        toast({
          title: `Vehicle number changed`,
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        setEditedText(inputRef.current.value);
      }
    } catch (error) {
      console.log("error", error);
      toast({
        title: `Something went wrong`,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      setEditCell(false);
      return;
    }

    setEditCell(false);
  };

  console.log("edit", inputRef);
  return (
    <div className="w-full flex justify-start gap-2">
      {editCell ? (
        <div className="flex justify-between items-center gap-2">
          <input
            type="text"
            className="border w-full h-8 text-[#605D64] pl-2 pr-5 rounded-sm"
            defaultValue={truckName}
            ref={inputRef}
          />

          <SaveIcon
            onClick={() => {
              handleChangeTruckName(truckName);
            }}
            sx={{
              cursor: "pointer",
              fontSize: "16px",
              color: "#605D64",
            }}
          />
        </div>
      ) : (
        <div className="flex justify-between items-center gap-0 w-full">
          <p className="">{editedText ? editedText : truckName}</p>

          <EditIcon
            onClick={() => setEditCell(true)}
            sx={{
              cursor: "pointer",
              fontSize: "16px",
              color: "#605D64",
            }}
          />
        </div>
      )}
      {/* <input
          type="text"
          className="border w-full h-8 text-[#605D64] pl-2 pr-5 rounded-sm"
          defaultValue={truckName}
          ref={inputRef}
          style={{ width: "70px" }}
        />
        <button
          className="!text-[#3A74CA] !font-semibold text-[14px]"
          // onClick={() => {
          //   handleAddToken(
          //     rowIndex,
          //     Number(inputRef.current.value),
          //     user,
          //     userName
          //   );
          // }}
        >
          Add
        </button> */}
    </div>
  );
};

export default CustomCell;
