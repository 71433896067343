import { useState } from "react";
import { Link } from "react-router-dom";

const SodaAshAndLimeOptimizerClientSelect = () => {
  const [clients, setClients] = useState(["hindustanzinc"]);

  return (
    <div className="h-full">
      <div className="bg-white shadow-md mt-5 p-4 border rounded-xl">
        <div className="flex flex-col gap-5">
          <div className="flex justify-start items-center gap-2 mt-3 mr-3 mb-3 ml-3 text-2xl text-semibold">
            <Link
              to="/home"
              style={{
                textDecoration: "none",
              }}
            >
              <img
                src="/backtick.svg"
                alt="go-back"
                className="border"
              />
            </Link>
            Please choose a client
            <p></p>
          </div>
          <div className="flex flex-wrap gap-4 mb-5 ml-3 w-full">
            {clients?.map((item, index) => {
              return (
                <Link
                  to={`./${item}?tabIndex=0`}
                  style={{ textDecoration: "none" }}
                >
                  <div
                    key={index}
                    className="flex justify-center items-center hover:bg-gray-100 shadow-md p-1 border rounded-md w-40 h-40 font-bold text-[#024D87] text-center uppercase hover:transition duration-200 cursor-pointer"
                  >
                    {item}
                  </div>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SodaAshAndLimeOptimizerClientSelect;
