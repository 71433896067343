import { Select, Input, Spinner } from "@chakra-ui/react";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import { useState, useContext } from "react";
import NavContext from "../../NavContext";
import { useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";

import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { mixpanelTrackButton } from "../../../util/mixpanelFunctions";
import { capitalizeFirstLetter } from "../../../util/sentenceCase";
import axios from "axios";
import { baseURL } from "../../..";

export const getVideoDataApi = async (auth, requestData, setLoading) => {
  try {
    setLoading(true);
    const res = await axios.post(
      `${baseURL}vision/v2/processMonitoring/feedLibrary/video/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );

    const data = res.data;
    if (data) {
      if (data?.success) {
        return {
          success: data?.success,
          url: data?.url,
          message: data?.message,
        };
      } else {
        return {
          success: data?.success,
          url: data?.url,
          message: data?.message,
        };
      }
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      url: null,
      message: "No video in range",
    };
  } finally {
    setLoading(false);
  }
};

const VideoInputForm = ({
  setIsVideo,
  plantId,
  disable,
  plantCamMap,
  camMap,
  setCamMap,
  material,
}) => {
  const param = useParams();
  const { auth } = useContext(NavContext);
  const [selectedCamera, setSelectedCamera] = useState(camMap?.cameraKeys?.[0]);
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [toTime, setToTime] = useState("12:00");
  const [fromTime, setFromTime] = useState("00:00");
  const toast = useToast();

  const handleSubmit = async () => {
    setIsVideo((prev) => "");
    try {
      const requestBody = JSON.stringify({
        plantName: "salem",
        startDate: new Date(date + "T" + fromTime).getTime(),
        endDate: new Date(date + "T" + toTime).getTime(),
        clientId: param?.clientId?.toLowerCase(),
        useCase: material,
        cameraId: selectedCamera,
      });
      const res = await getVideoDataApi(auth, requestBody, setLoading);
      if (res.success) {
        setIsVideo((prev) => res.url);
        toast({
          position: "top-right",
          title: "Video loaded",
          description: res.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        toast({
          position: "top-right",
          title: "Failed to load",
          description: res.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        position: "top-right",
        title: "Connection Error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsVideo((prev) => "");
    }
    mixpanelTrackButton(
      "video gallery",
      "Sinter Belt Monitoring",
      "Generate Video"
    );
  };
  return (
    <div className="flex flex-col gap-8 w-[24vw] min-w-[245px] sm:min-w-[375px] h-full">
      <div className="flex flex-col items-center gap-6">
        <div className="flex flex-col items-start gap-3 w-full">
          <p className="font-medium text-[#938F96] text-sm">Select Camera</p>
          <div className="w-full">
            <Select
              borderColor="#CAC5CD"
              color="#605D64"
              placeholder={
                disable && capitalizeFirstLetter(camMap?.cameraKeys?.[0])
              }
              variant="outline"
              isDisabled={disable}
              className="!rounded-md !font-medium !text-sm text-[#605D64]"
              onChange={(e) => setSelectedCamera(e.target.value)}
              value={selectedCamera}
            >
              {!disable &&
                camMap?.cameraKeys?.map((cameraName) => {
                  return (
                    <option key={cameraName} value={cameraName}>
                      {capitalizeFirstLetter(cameraName)}
                    </option>
                  );
                })}
            </Select>
          </div>
        </div>
        <div className="flex flex-col items-start gap-3 w-full">
          <p className="font-medium text-[#938F96] text-sm">Enter date</p>
          <div className="w-full">
            <Input
              borderColor="#79767D"
              color="#605D64"
              type="date"
              placeholder="Select Date"
              variant="outline"
              className="!rounded-[5px] !text-[#AEA9B1] !text-base"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              max={new Date().toISOString().slice(0, 10)}
            />
          </div>
        </div>
        <div className="flex flex-col items-start gap-3 w-full">
          <p className="font-medium text-[#938F96] text-sm">Enter time range</p>
          <div className="flex flex-col sm:flex-row gap-4 w-full">
            <FloatingInput
              text="Start Time"
              type="time"
              setDateTime={setFromTime}
              value={fromTime}
            />
            <FloatingInput
              text="End Time"
              type="time"
              setDateTime={setToTime}
              value={toTime}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <PrimaryButton
          onClick={handleSubmit}
          text={loading ? <Spinner /> : "Generate video"}
          height={"100%"}
          width={"140px"}
          disable={
            selectedCamera === "select plant" ||
            !date ||
            fromTime === toTime ||
            loading
          }
        />
      </div>
    </div>
  );
};

export default VideoInputForm;
