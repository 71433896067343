import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
  createTheme,
} from "@mui/material";

export const CommanSingleSelect = ({
  value,
  handleChange,
  options,
  disable = false,
  label = "Select",
}) => {
  const MuiTheme = createTheme();
  return (
    <ThemeProvider theme={MuiTheme}>
      <FormControl
        fullWidth
        size="small"
      >
        <InputLabel id={`simple-select-${label}`}>{label}</InputLabel>
        <Select
          labelId={`simple-select-${label}`}
          id={`${label}-simple-select`}
          value={value}
          label={label}
          onChange={handleChange}
          size={"small"}
          sx={{ fontSize: "14px", height: "40px" }}
        >
          {options?.map((el) => (
            <MenuItem
              value={el?.value}
              sx={{ fontSize: "14px" }}
            >
              {el?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
};
