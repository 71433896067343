import axios from "axios";
import { baseURL } from "../../..";
import {
  dummyAlertsData,
  dummyAnalyticsData,
  dummyDetailModalData,
  dummyFeedData,
  dummyInitializeData,
  dummyPhotoGalleryData,
  dummyReportData,
} from "../utilis/dummyData";
import { formatDateTimeLocale } from "../utilis/utilityFunctions";

export const getInitialDataApi = async (auth, requestData) => {
  try {
    // const res = await axios.post(
    //   `${baseURL}vision/v2/ProcessMonitoring/info/initialize/`,
    //   requestData,
    //   {
    //     credentials: "same-origin",
    //     headers: {
    //       "Content-Type": "application/json",
    //       "X-Auth-Token": auth,
    //     },
    //   }
    // );
    // const data = res?.data;
    const data = dummyInitializeData?.location?.[0]?.cameraGpIds;
    if (data) {
      return data;
    } else {
      throw new Error("Error while fetching data!");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error while fetching data!");
  }
};

export const getFeedDataApi = async (auth, requestData) => {
  try {
    // const res = await axios.post(
    //   `${baseURL}vision/v2/ProcessMonitoring/info/initialize/`,
    //   requestData,
    //   {
    //     credentials: "same-origin",
    //     headers: {
    //       "Content-Type": "application/json",
    //       "X-Auth-Token": auth,
    //     },
    //   }
    // );
    // const data = res?.data;
    const data = dummyFeedData;
    if (data) {
      return data;
    } else {
      throw new Error("Error while fetching data!");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error while fetching data!");
  }
};

export const getAlertsDataApi = async (auth, requestData, setLoading) => {
  try {
    // const res = await axios.post(
    //   `${baseURL}vision/v2/ProcessMonitoring/info/initialize/`,
    //   requestData,
    //   {
    //     credentials: "same-origin",
    //     headers: {
    //       "Content-Type": "application/json",
    //       "X-Auth-Token": auth,
    //     },
    //   }
    // );
    // const data = res?.data;
    // const { columns, data } = dummyAlertsData;
    // const tempTableData = data?.map((el) => ({
    //   ...el,
    //   timeStamp: el?.timeStamp && formatDateTimeLocale(el.timeStamp),
    // }));

    // if (data) {
    //   return {
    //     columns,
    //     data: tempTableData,
    //   };
    // } else {
    //   throw new Error("Error while fetching data!");
    // }

    // mock api for staged data
    return new Promise((resolve, reject) => {
      setLoading(true);
      setTimeout(() => {
        const { columns, data } = dummyAlertsData;
        const tempTableData = data?.map((el) => ({
          ...el,
          startTime: el?.startTime && formatDateTimeLocale(el.startTime),
          endTime: el?.endTime && formatDateTimeLocale(el.endTime),
        }));
        resolve({ columns, data: tempTableData });
        setLoading(false);
      }, 500);
    });
  } catch (error) {
    console.log(error);
    throw new Error("Error while fetching data!");
  }
};

export const getDetailModalDataApi = async (auth, requestData, setLoading) => {
  try {
    // const res = await axios.post(
    //   `${baseURL}vision/v2/ProcessMonitoring/info/initialize/`,
    //   requestData,
    //   {
    //     credentials: "same-origin",
    //     headers: {
    //       "Content-Type": "application/json",
    //       "X-Auth-Token": auth,
    //     },
    //   }
    // );
    // const data = res?.data;
    // if(data) {
    //  return data
    // } else {
    //   throw new Error("Error while fetching data!");
    // }

    // mock api for staged data
    return new Promise((resolve, reject) => {
      setLoading(true);
      setTimeout(() => {
        const mockResponseData = dummyDetailModalData;
        resolve(mockResponseData);
        setLoading(false);
      }, 500);
    });
  } catch (error) {
    console.error(error);
    throw new Error("Error while fetching data!");
  }
};

export const getPhotoGalleryDataApi = async (auth, requestData, setLoading) => {
  try {
    // const res = await axios.post(
    //   `${baseURL}vision/v2/ProcessMonitoring/info/initialize/`,
    //   requestData,
    //   {
    //     credentials: "same-origin",
    //     headers: {
    //       "Content-Type": "application/json",
    //       "X-Auth-Token": auth,
    //     },
    //   }
    // );
    // const data = res?.data;
    // if(data) {
    //  return data
    // } else {
    //   throw new Error("Error while fetching data!");
    // }

    // mock api for staged data
    return new Promise((resolve, reject) => {
      setLoading(true);
      setTimeout(() => {
        const mockResponseData = dummyPhotoGalleryData;
        resolve(mockResponseData);
        setLoading(false);
      }, 500);
    });
  } catch (error) {
    console.error(error);
    throw new Error("Error while fetching data!");
  }
};

export const getReportDataApi = async (auth, requestData, setLoading) => {
  try {
    // const res = await axios.post(
    //   `${baseURL}vision/v2/ProcessMonitoring/info/initialize/`,
    //   requestData,
    //   {
    //     credentials: "same-origin",
    //     headers: {
    //       "Content-Type": "application/json",
    //       "X-Auth-Token": auth,
    //     },
    //   }
    // );
    // const data = res?.data;
    // if(data) {
    //  return data
    // } else {
    //   throw new Error("Error while fetching data!");
    // }

    // mock api for staged data
    return new Promise((resolve, reject) => {
      setLoading((prev) => ({ ...prev, report: true }));
      setTimeout(() => {
        const { columns, data } = dummyReportData;
        const tempTableData = data?.map((el) => ({
          ...el,
          startTime: el?.startTime && formatDateTimeLocale(el.startTime),
          endTime: el?.endTime && formatDateTimeLocale(el.endTime),
        }));
        resolve({ columns, data: tempTableData });
        setLoading((prev) => ({ ...prev, report: false }));
      }, 500);
    });
  } catch (error) {
    console.error(error);
    throw new Error("Error while fetching data!");
  }
};

export const getAnalyticsDataApi = async (auth, requestData, setLoading) => {
  try {
    // const res = await axios.post(
    //   `${baseURL}vision/v2/ProcessMonitoring/info/initialize/`,
    //   requestData,
    //   {
    //     credentials: "same-origin",
    //     headers: {
    //       "Content-Type": "application/json",
    //       "X-Auth-Token": auth,
    //     },
    //   }
    // );
    // const data = res?.data;
    // const { columns, data } = dummyAlertsData;
    // const tempTableData = data?.map((el) => ({
    //   ...el,
    //   timeStamp: el?.timeStamp && formatDateTimeLocale(el.timeStamp),
    // }));

    // if (data) {
    //   return {
    //     columns,
    //     data: tempTableData,
    //   };
    // } else {
    //   throw new Error("Error while fetching data!");
    // }

    // mock api for staged data
    return new Promise((resolve, reject) => {
      setLoading(true);
      setTimeout(() => {
        const { tableData, donutChartData, flareData, alertsData } =
          dummyAnalyticsData;
        const tempTableData = tableData?.data?.map((el) => ({
          ...el,
          startTime: el?.startTime && formatDateTimeLocale(el.startTime),
          endTime: el?.endTime && formatDateTimeLocale(el.endTime),
        }));
        resolve({
          columns: tableData?.columns,
          tableData: tempTableData,
          donutChartData,
          flareData,
          alertsData,
        });
        setLoading(false);
      }, 500);
    });
  } catch (error) {
    console.log(error);
    throw new Error("Error while fetching data!");
  }
};
