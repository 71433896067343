import { useContext, useState } from "react";
import saveAs from "file-saver";
import { DownloadForOfflineOutlined } from "@mui/icons-material";
import { IconButton } from "@chakra-ui/react";
import { Input, Spinner } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import NavContext from "../../NavContext";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { FlareStackContext } from "../context/FlareStackContext";
import { getCameraOptions } from "../utilis/utilityFunctions";

const CustomToggleButton = ({ value, onChange, options }) => {
  return (
    <div className="gap-4 grid grid-cols-2 w-full">
      {options?.map((el) => (
        <button
          key={el?.value}
          onClick={() => onChange(el?.value)}
          className={`px-4 py-2 border rounded transition-colors duration-300 hover:shadow-lg  ${
            value === el.value
              ? "bg-[#F1F7FF] text-[#084298] border-[#6CA6FC]"
              : "bg-white text-[#605D64] border-[#EBEBEB]"
          }`}
          aria-label={el?.value}
        >
          {el?.name}
        </button>
      ))}
    </div>
  );
};

const VideoInputForm = ({ setIsVideo }) => {
  const param = useParams();
  const { auth } = useContext(NavContext);
  const { initialData } = useContext(FlareStackContext);
  const toast = useToast();

  //   const [selectedPlant, setSelectedPlant] = useState(
  //     disable ? plantId : "select plant"
  //   );
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState({
    date: new Date().toISOString().slice(0, 10),
    toTime: "12:00",
    fromTime: "00:00",
    flareStack: initialData?.[0]?.cameraGpId,
    cameraType: initialData?.[0]?.cameraInfo?.[0]?.cameraId,
  });

  const handleSubmit = async () => {
    setIsVideo((prev) => "");
    try {
      const requestBody = JSON.stringify({
        // plantName: selectedPlant,
        startDate:
          new Date(filters.date + "T" + filters.fromTime).getTime() +
          5.5 * 60 * 60 * 1000,
        endDate:
          new Date(filters.date + "T" + filters.toTime).getTime() +
          5.5 * 60 * 60 * 1000,
        clientId: param.clientId.toLowerCase(),
        useCase: "SINTERBELT",
        flareStack: "",
        cameraType: "",
      });
      //   const res = await getVideoDataApi(auth, requestBody, setLoading);
      //   if (res.success) {
      //     setIsVideo((prev) => res.url);
      //     toast({
      //       position: "top-right",
      //       title: "Video loaded",
      //       description: res.message,
      //       status: "success",
      //       duration: 3000,
      //       isClosable: true,
      //     });
      //   } else {
      //     toast({
      //       position: "top-right",
      //       title: "Failed to load",
      //       description: res.message,
      //       status: "error",
      //       duration: 3000,
      //       isClosable: true,
      //     });
      //   }
    } catch (error) {
      toast({
        position: "top-right",
        title: "Connection Error",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsVideo((prev) => "");
    }
  };

  return (
    <div className="flex flex-col gap-8 w-full xl:w-[35%] h-full">
      <div className="flex flex-col items-center gap-6">
        {/* <div className="flex flex-col items-start gap-3 w-full">
          <p className="font-medium text-[#938F96] text-sm">Select a plant</p>
          <div className="w-full">
            <Select
              borderColor="#CAC5CD"
              color="#605D64"
              placeholder={disable && capitalizeFirstLetter(selectedPlant)}
              variant="outline"
              isDisabled={disable}
              className="!rounded-md !font-medium !text-sm text-[#605D64]"
              onChange={(e) => setSelectedPlant(e.target.value)}
              value={selectedPlant}
            >
              <option value="select plant">Select Plant</option>
              {!disable &&
                Object.keys(plantCamMap).map((plant) => {
                  return (
                    <option
                      key={plant}
                      value={plant}
                    >
                      {capitalizeFirstLetter(plant)}
                    </option>
                  );
                })}
            </Select>
          </div>
        </div> */}
        <div className="flex flex-col gap-2 w-full h-full">
          <p className="font-semibold text-[#605D64]">Flare Stack</p>
          <CustomToggleButton
            value={filters.flareStack}
            options={initialData?.map((el) => ({
              name: el.cameraGpName,
              value: el.cameraGpId,
            }))}
            onChange={(value) => {
              if (value !== filters.flareStack) {
                setFilters((prev) => ({ ...prev, flareStack: value }));
              }
            }}
          />
        </div>

        <div className="flex flex-col gap-2 w-full h-full">
          <p className="font-semibold text-[#605D64]">Select Camera</p>
          <CustomToggleButton
            value={filters.cameraType}
            options={getCameraOptions(filters.flareStack, initialData)}
            onChange={(value) => {
              if (value !== filters.cameraType) {
                setFilters((prev) => ({ ...prev, cameraType: value }));
              }
            }}
          />
        </div>
        <div className="flex flex-col items-start gap-3 w-full">
          <p className="font-medium text-[#938F96] text-sm">Enter date</p>
          <div className="w-full">
            <Input
              borderColor="#79767D"
              color="#605D64"
              type="date"
              placeholder="Select Date"
              variant="outline"
              className="!rounded-[5px] !text-[#AEA9B1] !text-base"
              value={filters?.date}
              onChange={(e) => {
                setFilters((prev) => ({ ...prev, date: e.target.value }));
              }}
              max={new Date().toISOString().slice(0, 10)}
            />
          </div>
        </div>
        <div className="flex flex-col items-start gap-3 w-full">
          <p className="font-medium text-[#938F96] text-sm">Enter time range</p>
          <div className="flex gap-2 w-full">
            <FloatingInput
              text="Start Time"
              type="time"
              value={filters.fromTime}
              setDateTime={(value) => {
                setFilters((prev) => ({ ...prev, fromTime: value }));
              }}
            />
            <FloatingInput
              text="End Time"
              type="time"
              value={filters.toTime}
              setDateTime={(value) => {
                setFilters((prev) => ({ ...prev, toTime: value }));
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <PrimaryButton
          onClick={handleSubmit}
          text={loading ? <Spinner /> : "Generate video"}
          height={"100%"}
          width={"140px"}
          disable={
            !filters.date || filters.fromTime === filters.toTime || loading
          }
        />
      </div>
    </div>
  );
};

export const VideoGallery = ({ plantId, disable, plantCamMap }) => {
  const [isVideo, setIsVideo] = useState("");
  const VidDownload = async (url, idx) => {
    const response = await fetch(url);
    const videoData = await response.blob();
    saveAs(videoData, `video-${idx}.mp4`);
  };
  return (
    <div className="relative flex xl:flex-row flex-col justify-between items-center gap-8 bg-white pt-5 rounded-xl w-full">
      {isVideo == "" ? (
        <div className="flex flex-col items-center gap-6 h-full">
          <img
            src="/Common/VideoPlayer.svg"
            alt="No support"
          />
          <div className="flex flex-col items-center gap-2">
            <p className="font-medium text-[#1C56AC] text-2xl">Video gallery</p>
            <p className="text-[#AEA9B1] text-base text-center">
              The Video gallery will create a continuous video frame of the
              captured pictures from your selected date and time range.
            </p>
          </div>
        </div>
      ) : (
        <div className="relative flex items-center">
          <video
            crossOrigin="anonymous"
            controls
            muted
            autoPlay
            className="rounded-lg w-[45vw]"
          >
            <source
              src={isVideo}
              type="video/mp4"
            />
          </video>
          <div className="top-2 right-2 absolute flex gap-4 opacity-40 hover:opacity-90">
            <IconButton
              size={"xs"}
              rounded={"full"}
              icon={<DownloadForOfflineOutlined className="text-gray-400" />}
              onClick={() => VidDownload(isVideo, "sample")}
            />
          </div>
        </div>
      )}
      <VideoInputForm
        setIsVideo={setIsVideo}
        plantId={plantId}
        disable={disable}
        plantCamMap={plantCamMap}
      />
    </div>
  );
};
