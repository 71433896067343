import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import AlertImagesModal from "../Components/AlertImagesModal";

const MuiTheme = createTheme();

const DetailClick = (param) => {
  const [openModal, setOpenModal] = useState(false);
  let { colDef, row } = param;
  let { field } = colDef;
  return (
    <>
      <p
        className="text-[#084298] font-medium text-sm cursor-pointer"
        onClick={() => setOpenModal(true)}
      >
        View images
      </p>
      {openModal && (
        <AlertImagesModal
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          row={row}
        />
      )}
    </>
  );
};

const columns = [
  {
    field: "id",
    headerName: "SR No.",
    flex: 1,
  },
  {
    field: "plantName",
    headerName: "Plant",
    flex: 1,
  },
  {
    field: "cameraGpId",
    headerName: "Area",
    flex: 1,
  },
  {
    field: "vehicleNo",
    headerName: "Truck number",
    flex: 1,
  },
  // {
  //   field: "date",
  //   headerName: "Date",
  //   type: "time",
  //   flex: 1,
  //   valueGetter: ({ row }) => {
  //     const date = new Date((row.startTs - 5.5 * 3600) * 1000);
  //     const day = date.getDate();
  //     const month = date.getMonth() + 1; // Months are zero-based
  //     const year = date.getFullYear();
  //     const hours = date.getHours().toString().padStart(2, "0");
  //     const minutes = date.getMinutes().toString().padStart(2, "0");

  //     // Use template literals to format the date and time string
  //     return `${day}/${month}/${year} ${hours}:${minutes}`;
  //   },
  // },
  {
    field: "date",
    headerName: "Date",
    flex: 1,
    valueGetter: ({ row }) => {
      // return new Date(
      //   (row.startTs - 5.5 * 60 * 60) * 1000
      // ).toLocaleDateString();
      const date = new Date((row.startTs - 5.5 * 60 * 60) * 1000);
      const day = date.getDate().toString().padStart(2, "0"); // Get the day with leading zero if needed
      const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get the month with leading zero if needed (Note: month index starts from 0)
      const year = date.getFullYear(); // Get the full year

      return `${day}/${month}/${year}`;
    },
  },
  // {
  //   field: "startTs",
  //   headerName: "Violation start time",
  //   type: "time",
  //   flex: 1,
  //   valueGetter: ({ value }) => {
  //     return new Date(value * 1000).toLocaleTimeString("en-US", {
  //       timeZone: "UTC",
  //     });
  //   },
  // },
  {
    field: "startTs",
    headerName: "Violation start time",

    flex: 1,
    valueFormatter: ({ value }) => {
      return new Date(value * 1000).toLocaleTimeString("en-US", {
        timeZone: "UTC",
      });
    },
  },
  {
    field: "endTs",
    headerName: "violation end time",
    type: "time",
    flex: 1,
    valueGetter: ({ value }) => {
      return new Date(value * 1000).toLocaleTimeString("en-US", {
        timeZone: "UTC",
      });
    },
  },
  {
    field: "duration",
    headerName: "Duration",
    valueGetter: ({ row }) => {
      const timeDifferenceInSeconds = Math.abs(row.startTs - row.endTs);
      const timeDifferenceDate = new Date(timeDifferenceInSeconds * 1000);
      const hours = timeDifferenceDate.getUTCHours();
      const minutes = timeDifferenceDate.getUTCMinutes();
      const seconds = timeDifferenceDate.getUTCSeconds();

      const timeString = `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

      return timeString;
    },
    flex: 1,
  },
  {
    field: "event",
    headerName: "Event",
    flex: 1,
  },
  {
    field: "msg",
    headerName: "Violation",
    flex: 2,
  },
  {
    field: "annotatedImage",
    headerName: "images",
    flex: 1,
    type: "actions",
    renderCell: DetailClick,
  },
];

function capitalizeWords(str) {
  return str.replace(/\b\w/g, (match) => match.toUpperCase());
}

const AlertTable = ({ rowData, filterData, setFilterModel }) => {
  console.log("parameter", filterData);

  const [rows, setRows] = useState([]);
  useEffect(() => {
    setRows((prev) => {
      let newData = rowData.map((item, idx) => {
        item["id"] = idx + 1;
        item.msg = capitalizeWords(item.msg);
        return item;
      });
      return newData;
    });
  }, [rowData]);
  const headerClass =
    "text-xs font-medium text-[#525056] bg-[#ddeeff] uppercase";
  const cellClass = "text-sm font-medium text-[#3E3C42]";
  columns.map((val) => {
    val["headerClassName"] = headerClass;
    val["cellClassName"] = cellClass;
  });

  console.log("alerts row", rowData);
  return (
    <div className="overflow-x-auto">
      <ThemeProvider theme={MuiTheme}>
        <DataGrid
          rows={rows}
          columns={columns}
          columnVisibilityModel={{
            id: false,
            event: false,
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
            sorting: {
              sortModel: [{ field: "startTs", sort: "desc" }],
            },
          }}
          filterModel={filterData}
          onFilterModelChange={(newFilterModel) =>
            setFilterModel(newFilterModel)
          }
          pageSizeOptions={[5, 10, 25]}
          sx={{ minWidth: "1000px" }}
        />
      </ThemeProvider>
    </div>
  );
};

export default AlertTable;
