import { Link } from "react-router-dom";
import DeviceCard from "../Devices/Components/DeviceCard";
import DeviceGroupsCard from "../Devices/Components/DeviceGroupsCards";
import { useContext, useEffect, useState } from "react";
import { Spinner } from "@chakra-ui/react";
import AddIcon from '@mui/icons-material/Add';

const ManageDevices = ({
  setShowDevicesTab,
  deviceType,
  devices,
  setSelectedDevice,
  getDeviceApi,
  deviceGroups,
  getDeviceGroupApi
}) => {
  const [loading, setLoading] = useState(false);

  const handleAddDevice = () => {
    setShowDevicesTab((prev) => "addNewDevice");
  };

  const handleAddDeviceCategory = () => {
    setShowDevicesTab((prev) => "addNewDeviceGroup");
  };

  const handleViewDevice = () => {
    setShowDevicesTab((prev) => "viewDevice");
  };

  const handleUpdateDevice = () => {
    setShowDevicesTab((prev) => "updateDevice");
  };

  return (
    <div className="flex rounded-lg bg-[#fafafa] p-4">
      {deviceType == "Groups" && (
        <div className="grid grid-cols-1 min-[560px]:grid-cols-2 lg:grid-cols-3 min-[1330px]:grid-cols-5 min-[2200px]:grid-cols-6 w-full gap-4 h-fit">
          <div
            className="rounded p-3 w-full flex flex-col justify-center gap-3 items-center bg-white h-[250px]"
            style={{
              boxShadow:
                "-4px -4px 24px 0px rgba(0, 0, 0, 0.07), 4px 4px 24px 0px rgba(0, 0, 0, 0.07)",
            }}
          >
            <div
              className="p-[10px] bg-[#CDEEBF] text-center rounded cursor-pointer hover:scale-105"
              onClick={handleAddDeviceCategory}
            >
              <img src="/superadmin/addNewProduct.svg" alt="add" />
            </div>

            <p className="font-bold">Add new Device group</p>
          </div>
          {loading ? (
            <div className="flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            deviceGroups &&
            deviceGroups.length != 0 &&
            deviceGroups?.map((items) => {
              return <DeviceGroupsCard data={items} setSelectedDevice={setSelectedDevice} setShowDevicesTab={setShowDevicesTab} getDeviceGroupApi={getDeviceGroupApi} />;
            })
          )}
        </div>
      )}
      {deviceType == "Devices" && (
        <div className="grid grid-cols-1 min-[560px]:grid-cols-2 lg:grid-cols-3 min-[1330px]:grid-cols-5 min-[2200px]:grid-cols-6 w-full gap-4 h-fit">
          <div
            className="rounded p-3 w-full flex flex-col justify-center gap-3 items-center bg-white h-[250px]"
            style={{
              boxShadow:
                "-4px -4px 24px 0px rgba(0, 0, 0, 0.07), 4px 4px 24px 0px rgba(0, 0, 0, 0.07)",
            }}
          >
            <div
              className="p-[10px] bg-[#DEF] text-center rounded cursor-pointer hover:scale-105 "
              onClick={handleAddDevice}
            >
              <AddIcon sx={{color: "#3A74CA"}} />
            </div>

            <p className="font-bold">Add new Device</p>
          </div>
          {loading ? (
            <div className="flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            devices &&
            devices.length != 0 &&
            devices?.map((items) => {
              return (
                <DeviceCard
                  data={items}
                  setSelectedDevice={setSelectedDevice}
                  setShowDevicesTab={setShowDevicesTab}
                  getDeviceApi={getDeviceApi}
                />
              );
            })
          )}
        </div>
      )}
    </div>
  );
};

export default ManageDevices;
