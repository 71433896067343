import React from 'react'
import PieChart from '../../../Charts/MulpicCharts/PieChart';
import HighMovingStackBarChart from '../../../Charts/MulpicCharts/HighMovingStackBarChart';

const InsufficientData = ({ data }) => {
    return (
        <div className="bg-white rounded-xl flex flex-col w-full h-full overflow-x-auto" id="#production">
            <div className="flex flex-row gap-4 py-[8px] bg-white rounded-xl justify-between items-center px-3">
                <p className="rounded-e-xl rounded-s-xl text-xl font-medium">
                    Insufficient Data Analysis
                </p>
            </div>
            <div className="flex flex-col md:flex-row gap-8 py-[2px] bg-white justify-between items-center px-3 w-full overflow-x-auto overflow-y-hidden h-full min-h-[320px]">
                <PieChart labels={["Insufficient", "Sufficient"]} series={data?.pieData} colors={["#E46962",
                    "#5193f6"]} />
                <div className='py-[2px] bg-white w-full min-w-[400px] !h-full'>
                    {/* <p className='text-base font-medium'>Grades vs </p> */}
                    <HighMovingStackBarChart data={data?.barData} type={"data"} labels={["Insufficient", "Sufficient"]} yAxisText={"Grade"} xAxisText={"Count"} colors={["#E46962",
                        "#5193f6"]} />
                </div>
            </div>
        </div>
    )
}

export default InsufficientData