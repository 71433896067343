import { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import CamName from "./CamName";
import CamLink from "./CamLink";
import CamModelAssign from "./CamModelAssign";

export const initState = {
  name: "",
  rtsp: "",
  assignedProject: "",
  minProb: null,
};

const CreateCam = ({ data = {}, viewMode = false }) => {
  const [userState, setUserState] = useState(initState);
  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (!viewMode) {
      const elem = document.getElementById("step" + activeStep);
      elem.scrollIntoView({
        behavior: "smooth", // You can use 'auto' instead of 'smooth' for instant scrolling
        block: "center", // You can use 'center' or 'end' instead of 'start'
      });
    }
  }, [activeStep]);

  useEffect(() => {
    if (Object?.entries(data)?.length > 0) {
      setUserState(data);
      setActiveStep(2);
    }
  }, [data]);

  console.log(userState, "state");
  return (
    <div
      className="flex flex-col gap-2 h-screen font-roboto"
      style={{ marginTop: viewMode ? "0px" : "24px" }}
    >
      {!viewMode && (
        <div className="flex gap-2 items-center">
          <Link
            to={`/Sandbox?selectedGroup=data+feed`}
            style={{
              textDecoration: "none",
            }}
          >
            <img src="/backtick.svg" />
          </Link>
          <p className="text-xl font-medium text-[#084298] capitalize">
            Add new camera
          </p>
        </div>
      )}
      <div className="flex flex-col gap-3 h-full">
        <CamName
          userData={userState}
          setUserData={setUserState}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          viewMode={viewMode}
        />
        <CamLink
          userData={userState}
          setUserData={setUserState}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          show={activeStep >= 1}
          viewMode={viewMode}
        />
        <CamModelAssign
          userData={userState}
          setUserData={setUserState}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          show={activeStep >= 2}
          viewMode={viewMode}
        />
      </div>
    </div>
  );
};

export default CreateCam;
