import {
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Close } from "@mui/icons-material";
import React, { useState } from "react";

export const ZoomSingleImage = ({
  imageUrl,
  closeModal,
  openModal,
  height = "50vh",
}) => {
  return (
    <Modal
      isOpen={openModal}
      onClose={closeModal}
      isCentered="true"
      returnFocusOnClose={false}
      closeOnOverlayClick={true}
    >
      <ModalOverlay />
      <ModalContent
        style={{
          borderRadius: "12px",
          transformOrigin: "center",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
        maxW={["100vh", "100vh", "80vw", "70vw", "60vw"]}
        maxH={["510px", "100vw", "90vh", "90vh", "90vh"]}
        width={["300px", "100vh", "80vw", "70vw", "60vw"]}
        height={["500px", "100vw", "auto", "auto", "auto"]}
      >
        <IconButton
          position={"absolute"}
          rounded={"full"}
          bg={"white"}
          cursor={"pointer"}
          top={"0px"} // Adjust the positioning as needed
          right={"0px"} // Add right positioning to place it in the top-right corner
          icon={<Close sx={{ color: "gray" }} />}
          onClick={closeModal}
          boxShadow={"md"}
          style={{ zIndex: 1000 }} // Ensure it has a high z-index
        />
        {/* <ModalHeader
          padding="0px"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          position={"relative"}
        >
    
            <IconButton
              position={"absolute"}
              rounded={"full"}
              bg={"white"}
              cursor={"pointer"}
              top={-5}
              icon={<Close sx={{ color: "gray" }} />}
              onClick={closeModal}
              boxShadow={"md"}
              style={{ zIndex: 1000 }} // Increase the z-index here
            />
       
        </ModalHeader> */}
        <div
          style={{
            transform: `rotate(${window.innerWidth < 768 ? 90 : 0}deg)`,
            width: window.innerWidth < 768 ? "430px" : "100%", // Use parent's height as width if screen is small
            height: window.innerWidth < 768 ? "250px" : "100%", // Use parent's width as height if screen is small
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ModalBody
            padding={[1, 1, 6, 6, 6, 6]}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {imageUrl || imageUrl != "" ? (
              <img src={imageUrl} alt="" className="w-full" />
            ) : (
              <img src="" alt="" />
            )}
          </ModalBody>
        </div>
      </ModalContent>
    </Modal>
  );
};
