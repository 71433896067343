import { useContext, useState } from "react";
import NavContext from "../../../NavContext";
import {
  Button,
  Checkbox,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import PrimaryButton from "../../../../util/Buttons/PrimaryButton";
import TonalButton from "../../../../util/Buttons/TonalButton";
import AddNewExpertModal from "../AddNewExpertModal";
import { baseURL } from "../../../..";
import axios from "axios";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const ExpertCard = ({ data, getData }) => {
  const { auth } = useContext(NavContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false);
  const [isOpenAddExpertModal, setIsOpenAddExpertModal] = useState(false);
  const [isOpenViewExpertModal, setIsOpenViewExpertModal] = useState(false);
  const toast = useToast();
  const [isExpertDeactivated, setDeactivateExpert] = useState(data?.isDeleted);
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleDeactivate = (e) => {
    const isChecked = e.target.checked;
    console.log(isChecked);
    if (isChecked) {
      //   setDeactivateExpert(true);
      setIsModalOpen(true);
    } else {
      patchExpert(1, false);
      setIsMenuOpen(false);
    }
  };

  const handleActivationChange = (value) => {
    if (data?.status === value) {
      // no need to send a request when status and value is same
      return;
    }

    // const newStatus = expertStatus === "ACTIVE" ? 1 : 2;
    // const isDeletedValue = expertStatus === "INACTIVE" ? false : true;

    // Call the function to activate/deactivate the expert.

    if (value === "ACTIVE") {
      patchExpert(1, false);
    } else {
      setIsModalOpen(true);
    }
    // setIsMenuOpen(false);
  };

  const handleConfirmDiscard = async () => {
    await patchExpert(2, true);

    // fetchClientsFun();

    setIsModalOpen(false);
    setTimeout(() => {
      setIsMenuOpen(false);
    }, 1000);
  };

  const patchExpert = async (status, flag) => {
    const payload = {
      expertId: data?.expertId,
      status: status,
      isDeleted: flag,
    };

    // if (formData.isDeleted) {
    //   data2.isDeleted = true;
    // } else {
    //   data2.isDeleted = false;
    // }

    console.log(payload);

    const json = JSON.stringify(payload);

    const blob = new Blob([json], {
      type: "application/json",
    });
    const FormData = require("form-data");
    let body = new FormData();
    body.append("data", blob);

    let configUpdate = {
      method: "patch",
      maxBodyLength: Infinity,
      url: baseURL + "experts/v1/update/experts",
      //   "product/v1/update/productGroup"
      //params: {productIdUuid: selectedProductGroup.productIdUuid},
      headers: {
        "X-Auth-Token": auth,
      },
      data: body,
    };

    axios
      .request(configUpdate)
      .then(
        (response) => {
          toast({
            title: `${data.expertName} updated successfully`,
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top",
          });
          setIsMenuOpen(false);
          getData();
        }

        // onClose();
      )
      .catch((error) => {
        console.log(error);
        toast({
          title: `${error?.response?.data?.data}`,
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
      });
  };

  return (
    <div
      className="px-4 py-5 justify-between rounded flex flex-col gap-3 relative bg-white h-[250px]"
      style={{
        boxShadow:
          "-4px -4px 24px 0px rgba(0, 0, 0, 0.07), 4px 4px 24px 0px rgba(0, 0, 0, 0.07)",
      }}
    >
      <div className="flex flex-col h-full gap-2 ">
        <div className="flex items-center gap-0">
          <div className="w-8 h-8">
            {data?.imageUrl ? (
              <img src={data?.imageUrl} alt="logo" className="w-full h-full" />
            ) : (
              <img
                src="/Common/exp_placeholder.jpg"
                alt=""
                className="w-full h-full"
              />
            )}
          </div>
          <div className="ml-2 w-[75%] h-18">
            <Tooltip label={data?.expertName} placement="top">
              <p className="text-[#3E3C42] text-[16px] font-medium whitespace-nowrap overflow-hidden overflow-ellipsis">
                {data?.expertName}
              </p>
            </Tooltip>
          </div>
          <div className="ml-3 mb-5">
            <Menu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<MoreVertIcon />}
                variant="simple"
                position={"absolute"}
                top={"20px"}
                right={"1px"}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              />
              <MenuList
                px={"12px"}
                py={"16px"}
                rounded={"8px"}
                display={"flex"}
                flexDirection={"column"}
                gap={"16px"}
                minWidth={"fit-content"}
                w={"180px"}
                position={"absolute"}
                right={"-50px"}
                top={"-15px"}
              >
                {/* <div className="flex ml-3">
                  <Checkbox
                    isChecked={data?.isDeleted}
                    color={data?.isDeleted ? "#E46962" : ""}
                    fontWeight={500}
                    fontSize={"14px"}
                    onChange={handleDeactivate}
                  >
                    {data?.isDeleted ? "Deactivated" : "Deactivate"}
                  </Checkbox>
                </div> */}
                <div className="flex ml-3">
                  <RadioGroup
                    value={data?.status}
                    onChange={(value) => {
                      handleActivationChange(value);
                    }}
                  >
                    {/* <HStack spacing="8px"> */}
                    <Radio
                      value="ACTIVE"
                      color="#2C7A7B"
                    // isDisabled={data?.isDeleted}
                    >
                      {data?.status === "ACTIVE" ? "Activated" : "Activate"}
                    </Radio>
                    <Radio
                      value="INACTIVE"
                      // color={data?.status === "INACTIVE" ? "#E46962" : ""}
                      // isChecked={!data?.isDeleted}
                      isDisabled={data?.status === "DRAFT"}
                    >
                      <span
                        className={
                          data?.status === "INACTIVE" ? "text-[#E46962]" : ""
                        }
                      >
                        {data?.status === "INACTIVE"
                          ? "Deactivated"
                          : "Deactivate"}
                      </span>
                    </Radio>
                    {/* </HStack> */}
                  </RadioGroup>
                </div>
              </MenuList>
            </Menu>
            {isModalOpen && (
              <Modal
                size={"sm"}
                isOpen={isModalOpen}
                onClose={handleCloseModal}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader className=" flex justify-center rounded-t-md bg-red-400 text-white ">
                    Confirm deactivate ?
                  </ModalHeader>
                  {/* <ModalCloseButton /> */}
                  <ModalBody m={"auto"} fontSize={"15px"}>
                    <div className="flex flex-col items-center justify-center w-full">
                      <img
                        src={require("../../../SuperAdmin/AddClients/Components/disable.png")}
                        className="w-[100px] h-[100px]"
                        alt=""
                      />

                      <p className="ml-4 font-medium">
                        Are you sure you would like to deactivate the expert?
                      </p>
                    </div>
                  </ModalBody>

                  <div className="mt-4 flex flex-row justify-center">
                    <Button
                      colorScheme="blue"
                      mr={4}
                      onClick={handleConfirmDiscard}
                    >
                      Confirm
                    </Button>
                    <Button onClick={handleCloseModal}>Go Back</Button>
                  </div>
                  <ModalFooter></ModalFooter>
                </ModalContent>
              </Modal>
            )}
          </div>
        </div>
        <div className=" flex flex-col justify-between h-full ">
          <div className="w-[100%] h-[48px]">
            <div>
              <p className="text-[#938F96] text-sm">
                Current / Past Designation
              </p>
              <Tooltip label={data?.designation} placement="top">
                <p
                  className={`  whitespace-nowrap overflow-hidden overflow-ellipsis ${data?.designation ? "" : "text-[#938F96] text-sm"
                    }`}
                >
                  {data?.designation
                    ? data?.designation
                    : "No designation entered"}
                </p>
              </Tooltip>
            </div>

            <Tooltip label={data?.currPastOrganization} placement="top">
              <div>
                <p
                  className={`whitespace-nowrap overflow-hidden overflow-ellipsis ${data?.currPastOrganization ? "" : "text-[#938F96] text-sm"
                    }`}
                >
                  {data?.currPastOrganization}
                </p>
              </div>
            </Tooltip>
          </div>
          <div className="w-[100%] h-[48px]">
            <div>
              <p className="text-[#938F96] text-sm ">Industry</p>
              <Tooltip label={data?.industry} placement="top">
                <p
                  className={`whitespace-nowrap overflow-hidden overflow-ellipsis ${data?.industry ? "" : "text-[#938F96] text-sm"
                    }`}
                >
                  {data?.industry ? data?.industry : "No industry entered"}
                </p>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-2 items-center ">
        <PrimaryButton
          text={"View"}
          onClick={() => {
            //   setExpert((prev) => expert);
            // setIsOpen(true);
            setIsOpenViewExpertModal(true);
          }}
          disable={""}
        />
        <TonalButton
          text={"Update"}
          onClick={() => {
            // setExpert((prev) => expert);
            setIsOpenUpdateModal(true);
          }}
          disable={data?.status === "INACTIVE"}
        />
      </div>
      <Tooltip
        label={
          data?.status === "ACTIVE"
            ? "active"
            : data?.status === "INACTIVE"
              ? "deactivated"
              : "onboard"
        }
        hasArrow
      >
        {data?.status === "DRAFT" ?
          <img
            src={`/Common/draft.svg`}
            className="w-8 h-8 absolute -top-2 -right-2 rounded-full bg-white p-1 shadow-md"
            alt="status"
          /> :
          data?.status === "ACTIVE" ? <CheckCircleIcon sx={{
            position: 'absolute',
            top: "-8px",
            right: "-8px",
            backgroundColor: "white",
            padding: "4px",
            width: "32px",
            height: "32px",
            borderRadius: "9999px",
            color: "green",
            boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
          }} /> :
            <CancelIcon sx={{
              position: 'absolute',
              top: "-8px",
              right: "-8px",
              backgroundColor: "white",
              padding: "4px",
              borderRadius: "9999px",
              width: "32px",
              height: "32px",
              color: "#E46962",
              boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
            }} />}
      </Tooltip>

      {isOpenAddExpertModal && (
        <AddNewExpertModal
          isOpen={isOpenAddExpertModal}
          onClose={() => {
            setIsOpenAddExpertModal(false);
            getData();
          }}
          mode={"add"}
        //getData={getData}
        />
      )}
      {data && isOpenUpdateModal && (
        <AddNewExpertModal
          isOpen={isOpenUpdateModal}
          onClose={() => {
            setIsOpenUpdateModal(false);
            getData();
          }}
          data={data}
          mode={"update"}
        //getData={getData}
        />
      )}
      {data && isOpenViewExpertModal && (
        <AddNewExpertModal
          isOpen={isOpenViewExpertModal}
          onClose={() => {
            setIsOpenViewExpertModal(false);
          }}
          data={data}
          mode={"view"}
        //getData={getData}
        />
      )}
    </div>
  );
};

export default ExpertCard;
