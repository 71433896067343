import { useToast } from "@chakra-ui/react";

const UploadImage = ({
  logo,
  setLogo,
  setLogoPreview,
  desiredRatio,
  info,
  buttontext,
}) => {
  const toast = useToast();

  const handleImageUpload = (files) => {
    const imageFile = files[0];
    if (imageFile) {
      const image = new Image();
      image.src = URL.createObjectURL(imageFile);

      image.onload = () => {
        const ratio = info === "1" || info == "3" ? 6 / 1 : 3 / 2; // 6:1 ratio

        const currentRatio = image.width / image.height;

        if (info) {
          if (currentRatio === ratio) {
            setLogo(imageFile);
            setLogoPreview(URL.createObjectURL(imageFile));
          } else {
            console.log("logo", imageFile, "curr", currentRatio);

            toast({
              title: "Invalid Image Dimensions",
              description: `Please upload an image with ratio ${desiredRatio}.`,
              status: "error",
              duration: 4000,
              isClosable: true,
              position: "top",
            });
            setLogo(null);
          }
        } else {
          setLogo(imageFile);
          setLogoPreview(URL.createObjectURL(imageFile));
        }
      };
    }
  };

  return (
    <div className="mt-5">
      <div className="flex items-center gap-2">
        <div
          className={`w-[140px] rounded-md text-[white] border bg-[#6CA6FC] h-8`}
        >
          <label htmlFor={`${info}-image-uploader`} className="cursor-pointer">
            <div className="flex justify-evenly py-1">
              <img src="/superadmin/upload.png" alt="upload" />
              <p>{buttontext}</p>
            </div>
          </label>
          <input
            type="file"
            accept="image/*"
            name={`${info}-image-uploader`}
            id={`${info}-image-uploader`}
            style={{ display: "none" }}
            onChange={(e) => handleImageUpload(e.target.files)}
          />
        </div>
      </div>
    </div>
  );
};

export default UploadImage;
