import { useWindowSize } from "@uidotdev/usehooks";
import { useEffect, useState } from "react";
import { Progress, Skeleton } from "@chakra-ui/react";
import {
  Select,
  Table,
  Td,
  Tr,
  Thead,
  Tbody,
  TableContainer,
  Th,
  Flex,
  Image,
  Spinner,
  Alert,
  AlertIcon,
  useToast,
} from "@chakra-ui/react";
import { ArrowDownIcon, ArrowUpIcon, CloseIcon } from "@chakra-ui/icons";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import EditSlabId from "./EditSlabId";
import Paginator from "../../../util/VisionUtils/Paginator";

function DynamicTable({ tableData, stateForStandard, setEditingSLabId }) {
  const size = useWindowSize();
  const toast = useToast();
  const [displayData, setDisplayData] = useState([]);

  // const [selectAll, setSelectAll] = useState(false);

  // const handleSelectAll = () => {
  //   if (modifyTableData) {
  //     setSelectAll(!selectAll);
  //   }
  // };

  // const handleRowSelect = (id) => {
  //   if (modifyTableData) {
  //     const updatedTableData = tableData.map((item) =>
  //       item.id === id ? { ...item, selected: !item.selected } : item
  //     );
  //     setTableData(updatedTableData);
  //   } else {
  //     toast({
  //       title: "Enable Editing",
  //       description: "",
  //       status: "error",
  //       duration: 2000,
  //       isClosable: true,
  //     });
  //   }
  // };

  // useEffect(() => {
  //   const storedData = localStorage.getItem("tableData");
  //   if (storedData) {
  //     setTableData(JSON.parse(storedData));
  //   }
  // }, []);
  // const [modifyTableData, setModifyTableData] = useState(false);

  // const moveRow = (currentIndex, direction) => {
  //   if (modifyTableData) {
  //     const newIndex = currentIndex + direction;
  //     if (newIndex >= 0 && newIndex < tableData.length) {
  //       const updatedTableData = [...tableData];
  //       const movedRow = updatedTableData.splice(currentIndex, 1)[0];
  //       updatedTableData.splice(newIndex, 0, movedRow);

  //       setTableData(updatedTableData);
  //     }
  //   } else {
  //     toast({
  //       title: "Enable Editing",
  //       description: "",
  //       status: "error",
  //       duration: 2000,
  //       isClosable: true,
  //     });
  //   }
  // };

  // const saveToLocalStorage = (data) => {
  //   localStorage.setItem("tableData", JSON.stringify(data));
  // };

  // const handleSubmit = () => {
  //   console.log("krishna", tableData);
  //   saveToLocalStorage(tableData);
  // };

  // const enableEditing = (sts) => {
  //   setModifyTableData(sts);

  //   if (sts == false) {
  //     handleSubmit();
  //   }
  // };

  // const deleteRow = (id) => {
  //   if (modifyTableData) {
  //     const updatedTableData = tableData.filter((item) => item.id !== id);
  //     setTableData(updatedTableData);
  //   } else {
  //     toast({
  //       title: "Enable Editing",
  //       description: "",
  //       status: "error",
  //       duration: 2000,
  //       isClosable: true,
  //     });
  //   }
  // };

  return (
    <div className="w-full h-full flex flex-col gap-3">
      {/* <div className="flex justify-start">
        {modifyTableData ? (
          <PrimaryButton
            text={"Save"}
            width={"fit-content"}
            onClick={() => enableEditing(false)}
          />
        ) : (
        
          <PrimaryButton
            text={"Enable Editing"}
            width={"fit-content"}
            onClick={() => enableEditing(true)}
          />
        )}
      </div> */}
      <div className="w-fill flex justify-end">
        {tableData != null && tableData.length != 0 && (
          <Paginator
            data={tableData != null && tableData.length != 0 ? tableData : []}
            limit={5}
            setDisplayData={setDisplayData}
          />
        )}
      </div>

      <TableContainer className="!max-h-[50vh] !overflow-y-auto !w-full !mb-5">
        <Table variant="simple">
          <Thead
            className={`  bg-[#DDEEFF]    !font-semibold  !text-xs !sticky !top-0`}
          >
            <Tr>
              {/* <Th color="#302F32" fontWeight={400}>
                SEQUENCE. NO.
              </Th> */}
              <Th color="black" fontWeight={600}>
                SLAB ID.
              </Th>
              <Th color="black" fontWeight={600}>
                PLANNED LENGTH <p className=" lowercase text-center">(mm)</p>
              </Th>
              <Th color="black" fontWeight={600}>
                PLANNED WIDTH <p className=" lowercase text-center">(mm)</p>
              </Th>
              <Th color="black" fontWeight={600}>
                PLANNED THICKNESS <p className=" lowercase text-center">(mm)</p>
              </Th>
              <Th color="black" fontWeight={600}>
                ACTUAL LENGTH <p className=" lowercase text-center">(mm)</p>
              </Th>
              <Th color="black" fontWeight={600}>
                ACTUAL WIDTH <p className=" lowercase text-center">(mm)</p>
              </Th>
              <Th color="black" fontWeight={600}>
                ACTUAL THICKNESS <p className=" lowercase text-center">(mm)</p>
              </Th>
              {/* <Th color="#302F32" fontWeight={400}>
                SORT
              </Th> */}
              {/* <Th color="#302F32" fontWeight={400}>
                <div className="flex gap-3">
                  <p>Select All</p>
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    disabled={!modifyTableData}
                    className="w-4 h-4"
                  />
                </div>
              </Th> */}
            </Tr>
          </Thead>
          {tableData == null && (
            <>
              <tr>
                <td colSpan="7">
                  <Skeleton height="50px" width="100%" />
                </td>
              </tr>
              <tr>
                <td colSpan="7">
                  <Skeleton height="50px" width="100%" />
                </td>
              </tr>
              <tr>
                <td colSpan="7">
                  <Skeleton height="50px" width="100%" />
                </td>
              </tr>
              <tr>
                <td colSpan="7">
                  <Skeleton height="50px" width="100%" />
                </td>
              </tr>
            </>
          )}
          <Tbody>
            {displayData != null &&
              displayData.length != 0 &&
              displayData.map((item, index) => {
                return (
                  <Tr
                    key={index}
                    className={`!text-sm !text-[#3E3C42] text-center !font-medium  ${
                      item.slab_found !== undefined
                        ? item?.slab_found
                          ? "even:bg-[#FAFAFA] odd:bg-white"
                          : "border-2 border-red-300 bg-red-50"
                        : "even:bg-[#FAFAFA] odd:bg-white"
                    }  border-[2px]`}
                  >
                    {/* <Td className="!text-center">
                      {" "}
                      <div className="flex gap-2 justify-center items-center">
                        <CloseIcon
                          onClick={() => deleteRow(item.id)}
                          className={` !font-bold text-[15px] ${
                            modifyTableData
                              ? "cursor-pointer  hover:scale-125 transition-transform hover:shadow-lg !text-[red] "
                              : "cursor-not-allowed !text-[grey]"
                          } `}
                        />
                        <p>{index + 1}</p>
                      </div>
                    </Td> */}
                    <Td className="!text-center">
                      <EditSlabId
                        item={item}
                        setEditingSLabId={setEditingSLabId}
                      />
                    </Td>
                    <Td className="!text-center">
                      {item?.standard_length == null
                        ? "..."
                        : item?.standard_length?.toFixed(0)}
                    </Td>
                    <Td className="!text-center">
                      {" "}
                      {item?.standard_breadth == null
                        ? "..."
                        : item?.standard_breadth?.toFixed(0)}
                    </Td>
                    <Td className="!text-center">
                      {" "}
                      {item?.standard_depth == null
                        ? "..."
                        : item?.standard_depth?.toFixed(0)}
                    </Td>
                    <Td className="!text-center">
                      {item?.length == null ? "..." : item.length.toFixed(0)}
                    </Td>
                    <Td className="!text-center">
                      {item?.breadth == null ? "..." : item.breadth.toFixed(0)}
                    </Td>
                    <Td className="!text-center">
                      {item?.depth == null ? "..." : item.depth.toFixed(0)}
                    </Td>
                    {/* <Td className="!text-center">
                      <div className=" flex flex-col justify-center items-center gap-2 ">
                        <ArrowUpIcon
                          onClick={() => moveRow(index, -1)}
                          className={`text-[18px] cursor-${
                            modifyTableData
                              ? "pointer  hover:scale-125 transition-transform hover:shadow-lg font-bold hover:text-[#024D87]"
                              : "not-allowed  font-normal"
                          } `}
                        />
                        <ArrowDownIcon
                          onClick={() => moveRow(index, 1)}
                          className={`text-[18px] cursor-${
                            modifyTableData
                              ? "pointer hover:scale-125 transition-transform hover:shadow-lg hover:text-[#024D87]"
                              : "not-allowed "
                          } `}
                        />
                      </div>
                    </Td> */}
                    {/* <td>
                      <input
                        type="checkbox"
                        checked={selectAll || item.selected}
                        onChange={() => handleRowSelect(item.id)}
                        disabled={!modifyTableData}
                        className="w-4 h-4"
                      />
                    </td> */}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default DynamicTable;
