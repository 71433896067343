import { capitalizeFirstLetter } from "../../../util/sentenceCase";
import { formatColumnName } from "./formatColumnName";
import { formatDateTime } from "./formatDateTime";

export const generateDynamicColumns = async (
  columnNames,
  filterable = false,
  sortable = false,
  disableColumnMenu = true,
  flexMap,
  minWidthMap
) => {
  const dynamicColumns = columnNames.map((columnName, index) => {
    console.log(minWidthMap?.columnName, columnName, minWidthMap);
    let columnConfig = {
      field: columnName,
      headerName: capitalizeFirstLetter(formatColumnName(columnName)),

      // checking for array and index is not out bound value both needs to be of same length as that of columnNames
      flex: flexMap && flexMap?.[columnName] ? flexMap[columnName] : 1,
      minWidth:
        minWidthMap && minWidthMap?.[columnName]
          ? minWidthMap[columnName]
          : 150,
      sortable: sortable,
      disableColumnMenu: disableColumnMenu,
      filterable: filterable,
    };
    switch (columnName) {
      case "startTime":
      case "endTime":
      case "timestamp":
      case "lastAnalysisTime":
        columnConfig.valueGetter = (params) => {
          return params.value ? formatDateTime(params.value) : "";
        };
        break;
      case "averageFlameDistance(px)":
      case "areaColdspots(%)":
      case "areaHotspots(%)":
      case "last1Hour":
      case "last24Hours":
        columnConfig.valueFormatter = (params) => {
          if (typeof params.value === "boolean") {
            return params.value;
          }
          if (
            typeof params.value === "number" &&
            isNaN(params.value) === false
          ) {
            return params?.value?.toFixed(2) || params?.value;
          }
          return params?.value;
        };
        break;

      case "plantName":
      case "cameraId":
        columnConfig.valueFormatter = (params) => {
          return params.value ? capitalizeFirstLetter(params.value) : "";
        };
        break;
      default:
        break;
    }

    return columnConfig;
  });
  return dynamicColumns;
};
