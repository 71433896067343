import { useContext, useEffect, useState } from "react";
import {
  dummyRecommendedBiasRecommenderData,
  dummyUpcomingBiasRecommenderData,
} from "../services/data";
import {
  generateDynamicColumns,
  generateGroupedColumns,
  upcomingBiasReommenderColumnGroupingModel,
  upcomingSlabReommenderColumnGroupingModel,
  nearestNeighbourColumnGroupingModel,
  dummySimilarPlatesColumns,
  upcomingRolledPlatesColumnGroupingModel
} from "../utilis/dataGridColumns";
import { BiasRecommenderTable } from "../components/BiasRecommenderTable";
import {
  RadioButtonChecked,
  RadioButtonUncheckedRounded,
} from "@mui/icons-material";
import {
  getCurrentConfigDataApi,
  getRecommendedConfigDataApi,
  getSimilarPlatesDataApi,
  getSlabsDataApi,
  getUpcomingBiasRecommenderDataApi,
  getPrtDataApi,
  getRollingPlatesDataApi,
  getRecommendedConfigRolledPlatesDataApi,
  getCurrentConfigRolledPlatesDataApi,
  postIsRollingApi,
  getIsRollingApi
} from "../services/biasRecommenderServices";
import { Config } from "../components/Config";
import NavContext from "../../NavContext";
import TableSkeleton from "../components/LoadingState/TableSkeleton";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import ExlCsvDownload from "../../../util/VisionUtils/ExlCsvDownload";
import ExlcButton from "../utilis/ExlcButton";
import { Switch } from "@chakra-ui/react";

const CustomRadioRenderCell = ({ value }) => {
  if (value) {
    return (
      <button className="w-full cursor-pointer">
        <RadioButtonChecked
          fontSize={"medium"}
          style={{
            color: "#6CA6FC",
          }}
        />
      </button>
    );
  } else {
    return (
      <button className="w-full cursor-pointer">
        <RadioButtonUncheckedRounded
          fontSize={"medium"}
          style={{
            color: "#6CA6FC",
          }}
        />
      </button>
    );
  }
};
export const BiasRecommender = () => {
  // refs and context
  const { auth } = useContext(NavContext);
  // state
  const [loading, setLoading] = useState({
    upcomingBiasRecommender: false,
    similarPlates: false,
    currentConfig: false,
    recommendedConfig: false,
    slabs: false,
    rolledPlates: false,
  });

  const [firstTimeLoaded, setFirstTimeLoaded] = useState({
    upcomingBiasRecommender: false,
    similarPlates: false,
    slabs: false,
    rolledPlates: false,
  });

  // data states
  const [upcomingBiasRecommenderData, setUpcomingBiasRecommenderData] =
    useState(dummyUpcomingBiasRecommenderData);
  const [upcomingBiasRecommenderColumns, setUpcomingBiasRecommenderColumns] =
    useState(null);
  const [currentConfigData, setCurrentConfigData] = useState(null);
  const [recommendedConfigData, setRecommendedConfigData] = useState(null);

  const [similarPlatesColumns, setSimilarPlatesColumns] = useState(null);
  const [similarPlatesData, setSimilarPlatesData] = useState(
    dummyRecommendedBiasRecommenderData
  );
  const [inSufDataFlag, setInsufDataFlag] = useState({ plate: false, slab: false, rolledPlates: false });
  const [slabsColumns, setSlabsColumns] = useState(null);
  const [slabsData, setSlabsData] = useState(null);
  const [rolledPlatesColumns, setRolledPlatesColumns] = useState(null);
  const [rolledPlatesData, setRolledPlatesData] = useState(null);
  const [prtData, setPrtData] = useState(null);
  // to store checkbox
  const [rowSelectionUpcomingPlatesModel, setRowSelectionUpcomingPlatesModel] =
    useState([]);
  const [rowSelectionRolledPlatesModel, setRowSelectionRolledPlatesModel] =
    useState([]);
  const [rowSelectionSlabsModel, setRowSelectionSlabsModel] = useState([]);
  const [isLiveFeed, setIsLiveFeed] = useState(true);
  const [isRollingAdjustments, setIsRollingAdjustments] = useState(false);
  const [intervalIds, setIntervalIds] = useState({
    plates: '',
    slabs: "",
    rolledPlates: '',
  })
  const [isSlab, setIsSlab] = useState(true);

  // functions to update data
  const fetchUpcomingData = async (requestData) => {
    try {
      const response = await getUpcomingBiasRecommenderDataApi(
        auth,
        requestData,
        setLoading
      );
      if (response?.data) {
        // to generate incoming columns only on the first render
        if (firstTimeLoaded?.upcomingBiasRecommender === false) {
          const tempIncomingBiasRecommenderColumns =
            await generateGroupedColumns(
              response?.data?.columns,
              true,
              true,
              false
            );
          // adding bias recommender specific column
          setUpcomingBiasRecommenderColumns((prev) => [
            // checkbox to select box commented as not needed as of now
            {
              ...GRID_CHECKBOX_SELECTION_COL_DEF,
              renderHeader: (params) => null,
              renderCell: (params) => {
                const { value } = params;
                return <CustomRadioRenderCell value={value} />;
              },
            },
            ...tempIncomingBiasRecommenderColumns,
          ]);
        }
        setUpcomingBiasRecommenderData((prev) => response?.data);
        // if (
        //   response?.data?.tableData?.length > 0 &&
        //   response?.data?.tableData[0]?.id
        // ) {
        //   setRowSelectionUpcomingPlatesModel((prev) => {
        //     return [response?.data?.tableData[0]?.id];
        //   });
        //   setRowSelectionSlabsModel((prev) => []);
        //   if (response?.data?.tableData[0]?.insf_data == 1) setInsufDataFlag((prev) => ({ slab: false, plate: true }));
        //   else setInsufDataFlag((prev) => ({ slab: false, plate: false }));
        //   const requestData = JSON.stringify({
        //     plate_id: response?.data?.tableData[0]?.plate_id,
        //   });
        //   await fetchCurrentConfig(requestData);
        //   await fetchPRT(requestData);
        //   await fetchRecommendedConfig(requestData);
        //   await fetchSimilarPlates(requestData);
        // }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFirstTimeLoaded((prev) => ({
        ...prev,
        upcomingBiasRecommender: true,
      }));
    }
  };

  const fetchRolledPlatesData = async (requestData) => {
    try {
      const response = await getRollingPlatesDataApi(
        auth,
        requestData,
        setLoading
      );
      if (response?.data) {
        // to generate incoming columns only on the first render
        if (firstTimeLoaded?.rolledPlates === false) {
          const tempColumns =
            await generateGroupedColumns(
              response?.data?.columns,
              true,
              true,
              false
            );
          // adding bias recommender specific column
          setRolledPlatesColumns((prev) => [
            // checkbox to select box commented as not needed as of now
            {
              ...GRID_CHECKBOX_SELECTION_COL_DEF,
              renderHeader: (params) => null,
              renderCell: (params) => {
                const { value } = params;
                return <CustomRadioRenderCell value={value} />;
              },
            },
            ...tempColumns,
          ]);
        }
        setRolledPlatesData((prev) => response?.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFirstTimeLoaded((prev) => ({
        ...prev,
        rolledPlates: true,
      }));
    }
  };

  const fetchCurrentConfig = async (requestData) => {
    try {
      const response = await getCurrentConfigDataApi(
        auth,
        requestData,
        setLoading
      );
      if (response.success) {
        setCurrentConfigData((prev) => response.data);
      } else {
        setCurrentConfigData((prev) => null);
      }
    } catch (error) {
      console.log(error);
      setCurrentConfigData((prev) => null);
    }
  };

  const fetchPRT = async (requestData) => {
    try {
      const response = await getPrtDataApi(
        auth,
        requestData,
        setLoading
      );
      if (response.success) {
        setPrtData((prev) => response.data);
      } else {
        setPrtData((prev) => null);
      }
    } catch (error) {
      console.log(error);
      setPrtData((prev) => null);
    }
  };

  const fetchRecommendedConfig = async (requestData) => {
    try {
      const response = await getRecommendedConfigDataApi(
        auth,
        requestData,
        setLoading
      );
      if (response.success) {
        setRecommendedConfigData((prev) => response.data);
      } else {
        setRecommendedConfigData((prev) => null);
      }
    } catch (error) {
      console.log(error);
      setRecommendedConfigData((prev) => null);
    }
  };

  const fetchSimilarPlates = async (requestData) => {
    try {
      const response = await getSimilarPlatesDataApi(
        auth,
        requestData,
        setLoading
      );
      if (response?.success && response?.data) {
        // to generate incoming columns only on the first render
        if (firstTimeLoaded?.similarPlates === false) {
          const tempColumns = Object.keys(response?.data[0]);
          const tempSimilarPlatesColumns = await generateDynamicColumns(
            tempColumns,
            true,
            true,
            false
          );
          // adding bias recommender specific column
          setSimilarPlatesColumns((prev) => [...tempSimilarPlatesColumns]);
        }
        setSimilarPlatesData((prev) => response?.data);
      } else {
        setSimilarPlatesData((prev) => null);
      }
    } catch (error) {
      console.log(error);
      setSimilarPlatesData((prev) => null);
    }
  };

  const fetchSlabs = async () => {
    try {
      const response = await getSlabsDataApi(auth, null, setLoading);
      if (response?.data) {
        // to generate incoming columns only on the first render
        if (firstTimeLoaded?.slabs === false) {
          const tempSlabsColumns = await generateGroupedColumns(
            response?.data?.columns,
            true,
            true,
            false
          );
          // adding bias recommender specific column
          setSlabsColumns((prev) => [
            // checkbox to select box commented as not needed as of now
            {
              ...GRID_CHECKBOX_SELECTION_COL_DEF,
              renderHeader: (params) => null,
              renderCell: (params) => {
                const { value } = params;
                return <CustomRadioRenderCell value={value} />;
              },
            },
            ...tempSlabsColumns,
          ]);
        }
        setSlabsData((prev) => response?.data);
        if (
          response?.data?.tableData?.length > 0 &&
          response?.data?.tableData[0]?.id
        ) {
          setIsSlab((prev) => true);
          setRowSelectionUpcomingPlatesModel((prev) => []);
          setRowSelectionRolledPlatesModel((prev) => []);
          setRowSelectionSlabsModel((prev) => {
            return [response?.data?.tableData[0]?.id];
          });
          if (response?.data?.tableData[0]?.insf_data == 1) setInsufDataFlag((prev) => ({ slab: true, plate: false, rolledPlates: false }));
          else setInsufDataFlag((prev) => ({ slab: false, plate: false, rolledPlates: false }));
          const requestData = JSON.stringify({
            plate_id: response?.data?.tableData[0]?.plate_id,
            flag: 1,
          });
          await fetchCurrentConfig(requestData);
          await fetchPRT(requestData);
          await fetchRecommendedConfig(requestData);
          await fetchSimilarPlates(requestData);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setFirstTimeLoaded((prev) => ({
        ...prev,
        slabs: true,
      }));
    }
  };

  // call all three function when user clicks on upcoming plates radio button
  const handleViewConfigAndSimilarPlatesFromUpcoming = async (params) => {
    try {
      const { field, id, row } = params;
      if (field === "__check__" && row?.plate_id) {
        setIsSlab((prev) => false);
        setRowSelectionSlabsModel((prev) => []);
        setRowSelectionRolledPlatesModel((prev) => []);
        setRowSelectionUpcomingPlatesModel((prev) => {
          return [id];
        });
        const requestData = JSON.stringify({
          plate_id: row?.plate_id,
        });
        if (row?.insf_data == 1) setInsufDataFlag((prev) => ({ slab: false, plate: true, rolledPlates: false }));
        else setInsufDataFlag((prev) => ({ slab: false, plate: false, rolledPlates: false }));

        await fetchCurrentConfig(requestData);
        await fetchPRT(requestData);
        await fetchRecommendedConfig(requestData);
        await fetchSimilarPlates(requestData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // to change config and similar plates when switching slabs
  const handleViewConfigAndSimilarPlatesFromSlabs = async (params) => {
    try {
      const { field, id, row } = params;
      if (field === "__check__" && row?.plate_id) {
        setRowSelectionUpcomingPlatesModel((prev) => []);
        setRowSelectionRolledPlatesModel((prev) => []);
        setRowSelectionSlabsModel((prev) => {
          return [id];
        });
        setIsSlab((prev) => true);
        const requestData = JSON.stringify({
          plate_id: row?.plate_id,
          flag: 1,
        });
        if (row?.insf_data == 1) setInsufDataFlag((prev) => ({ slab: true, plate: false, rolledPlates: false }));
        else setInsufDataFlag((prev) => ({ slab: false, plate: false, rolledPlates: false }));

        await fetchCurrentConfig(requestData);
        await fetchPRT(requestData);
        await fetchRecommendedConfig(requestData);
        await fetchSimilarPlates(requestData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRecommendedConfigRolledPlates = async (requestData) => {

    console.log("Hello");
    try {
      const response = await getRecommendedConfigRolledPlatesDataApi(
        auth,
        requestData,
        setLoading
      );
      if (response.success) {
        setRecommendedConfigData((prev) => response?.data);
      } else {
        setRecommendedConfigData((prev) => null);
      }
    } catch (error) {
      console.log(error);
      setRecommendedConfigData((prev) => null);
    }
  }

  const fetchCurrentConfigRolledPlates = async (requestData) => {
    try {
      const response = await getCurrentConfigRolledPlatesDataApi(
        auth,
        requestData,
        setLoading
      );
      if (response.success) {
        setCurrentConfigData((prev) => response.data);
      } else {
        setCurrentConfigData((prev) => null);
      }
    } catch (error) {
      console.log(error);
      setCurrentConfigData((prev) => null);
    }
  };

  const handleViewConfigAndSimilarPlatesFromRolledPlates = async (params) => {
    try {
      const { field, id, row } = params;
      if (field === "__check__" && row?.plate_id) {
        setIsSlab((prev) => false);
        setRowSelectionUpcomingPlatesModel((prev) => []);
        setRowSelectionSlabsModel((prev) => []);
        setRowSelectionRolledPlatesModel((prev) => {
          return [id];
        });
        const requestData = JSON.stringify({
          plate_id: row?.plate_id,
        });
        if (row?.insf_data == 1) setInsufDataFlag((prev) => ({ rolledPlates: true, slab: false, plate: false }));
        else setInsufDataFlag((prev) => ({ slab: false, plate: false, rolledPlates: false }));

        await fetchCurrentConfigRolledPlates(requestData);
        await fetchRecommendedConfigRolledPlates(requestData);
        // await fetchPRT(requestData);
        await fetchSimilarPlates(requestData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {

    if (isLiveFeed) {
      fetchUpcomingData();
      fetchSlabs();
      fetchRolledPlatesData();
      let id = setInterval(() => {
        fetchUpcomingData();
      }, 30000);

      let id2 = setInterval(() => {
        fetchSlabs();
      }, 60000);

      let id3 = setInterval(() => {
        fetchRolledPlatesData();
      }, 60000);

      if (intervalIds?.plates) clearInterval(intervalIds?.plates);
      if (intervalIds?.slabs) clearInterval(intervalIds?.slabs);
      if (intervalIds?.rolledPlates) clearInterval(intervalIds?.rolledPlates);

      setIntervalIds((prev) => ({
        ...prev,
        plates: id,
        slabs: id2,
        rolledPlates: id3,
      }))
    }
    else {
      if (intervalIds?.plates) clearInterval(intervalIds?.plates);
      if (intervalIds?.slabs) clearInterval(intervalIds?.slabs);
      if (intervalIds?.rolledPlates) clearInterval(intervalIds?.rolledPlates);

      setIntervalIds((prev) => ({
        ...prev,
        plates: "",
        slabs: "",
        rolledPlates: "",
      }))
    }

    return () => {
      if (intervalIds?.plates) clearInterval(intervalIds?.plates);
      if (intervalIds?.slabs) clearInterval(intervalIds?.slabs);
      if (intervalIds?.rolledPlates) clearInterval(intervalIds?.rolledPlates);
    }
  }, [isLiveFeed]);

  const getIsRollingStatus = async () => {
    try {
      const response = await getIsRollingApi();
      if (response.success) {
        setIsRollingAdjustments((prev) => response?.data?.flag);
      } else {
        // setPrtData((prev) => null);
      }
    } catch (error) {
      console.log(error);
      // setPrtData((prev) => null);
    }
  }

  useEffect(() => {
    getIsRollingStatus();
  }, [])

  useEffect(() => {
    const requestData = {
      flag: isRollingAdjustments,
    }
    postIsRollingApi(requestData);
  }, [isRollingAdjustments])

  const getOrder = (columns) => {

    // console.log(columns, "columns");
    let newArray = [];

    columns?.forEach((column) => {
      newArray.push(column?.value ? column?.value : column?.field);
    })

    return newArray;
  }

  // console.log(similarPlatesData);

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="flex flex-col md:flex-row justify-between w-full">
        <div className="flex flex-row gap-1 items-center px-2">
          <p>{isLiveFeed ? "Live" : "Static"}</p>
          <Switch isChecked={isLiveFeed} onChange={(e) => setIsLiveFeed((prev) => !prev)} />
        </div>
        <div className="flex flex-row gap-1 items-center px-2">
          <p>{isRollingAdjustments ? "Rolling Adjustments On" : "Rolling Adjustments Off"}</p>
          <Switch isChecked={isRollingAdjustments} onChange={(e) => setIsRollingAdjustments((prev) => !prev)} />
        </div>
      </div>

      <div className="w-full rounded-xl bg-white">
        {/* Upcoming Slabs Data */}
        <div className="flex flex-row justify-between">
          <p className="rounded-e-xl rounded-s-xl px-2 py-3 text-xl font-medium">
            Upcoming Slabs
          </p>

          <div className="flex flex-row gap-1 px-2 py-3">
            {
              inSufDataFlag?.slab && (
                <p className="rounded-e-xl rounded-s-xl px-2 py-1 text-base font-medium text-[red]">
                  ⋆ Insufficient data
                </p>
              )
            }
            {slabsData && <ExlcButton order={getOrder(slabsData?.columns)} data={slabsData?.tableData} name={"Upcoming_slabs"} />}
          </div>
        </div>
        <div className="w-full">
          {loading?.slabs && firstTimeLoaded?.slabs === false ? (
            <TableSkeleton
              headers={[
                "Header 1",
                "Header 2",
                "Header 3",
                "Header 4",
                "Header 5",
                "Header 6",
                "Header 7",
                "Header 8",
              ]}
              rows={5}
              cellsPerRow={8}
            />
          ) : (
            slabsData &&
            slabsColumns && (
              <BiasRecommenderTable
                columns={slabsColumns || []}
                rowData={slabsData?.tableData || []}
                rowSelectionModel={rowSelectionSlabsModel}
                columnGroupingModel={upcomingSlabReommenderColumnGroupingModel}
                handleCellClick={handleViewConfigAndSimilarPlatesFromSlabs}
              />
            )
          )}
        </div>
      </div>
      <div className="w-full rounded-xl bg-white">
        {/* Upcoming Plates Data */}
        <div className="flex flex-row justify-between">
          <p className="rounded-e-xl rounded-s-xl px-2 py-3 text-xl font-medium">
            Upcoming Plates
          </p>
          <div className="flex flex-row gap-1 px-2 py-3">
            {
              inSufDataFlag?.plate && (
                <p className="rounded-e-xl rounded-s-xl px-2 py-1 text-base font-medium text-[red]">
                  ⋆ Insufficient data
                </p>
              )
            }
            {upcomingBiasRecommenderData && <ExlcButton order={getOrder(upcomingBiasRecommenderData?.columns)} data={upcomingBiasRecommenderData?.tableData} name={"Upcoming_plates"} />}
          </div>

        </div>

        <div className="w-full">
          {loading?.upcomingBiasRecommender &&
            firstTimeLoaded?.upcomingBiasRecommender === false ? (
            <TableSkeleton
              headers={[
                "Header 1",
                "Header 2",
                "Header 3",
                "Header 4",
                "Header 5",
                "Header 6",
                "Header 7",
                "Header 8",
              ]}
              rows={5}
              cellsPerRow={8}
            />
          ) : (
            upcomingBiasRecommenderData &&
            upcomingBiasRecommenderColumns && (
              <BiasRecommenderTable
                columns={upcomingBiasRecommenderColumns || []}
                rowData={upcomingBiasRecommenderData?.tableData || []}
                rowSelectionModel={rowSelectionUpcomingPlatesModel}
                columnGroupingModel={upcomingBiasReommenderColumnGroupingModel}
                handleCellClick={handleViewConfigAndSimilarPlatesFromUpcoming}
              />
            )
          )}
        </div>
      </div>
      <div className="w-full rounded-xl bg-white">
        {/* Upcoming Plates Data */}
        <div className="flex flex-row justify-between">
          <p className="rounded-e-xl rounded-s-xl px-2 py-3 text-xl font-medium">
            Rolled Plates
          </p>
          <div className="flex flex-row gap-1 px-2 py-3">
            {
              inSufDataFlag?.rolledPlates && (
                <p className="rounded-e-xl rounded-s-xl px-2 py-1 text-base font-medium text-[red]">
                  ⋆ Insufficient data
                </p>
              )
            }
            {rolledPlatesData && <ExlcButton order={getOrder(rolledPlatesData?.columns)} data={rolledPlatesData?.tableData} name={"Rolled_plates"} />}
          </div>

        </div>

        <div className="w-full">
          {loading?.rolledPlates &&
            firstTimeLoaded?.rolledPlates === false ? (
            <TableSkeleton
              headers={[
                "Header 1",
                "Header 2",
                "Header 3",
                "Header 4",
                "Header 5",
                "Header 6",
                "Header 7",
                "Header 8",
              ]}
              rows={5}
              cellsPerRow={8}
            />
          ) : (
            rolledPlatesData &&
            rolledPlatesColumns && (
              <BiasRecommenderTable
                columns={rolledPlatesColumns || []}
                rowData={rolledPlatesData?.tableData || []}
                rowSelectionModel={rowSelectionRolledPlatesModel}
                columnGroupingModel={upcomingRolledPlatesColumnGroupingModel}
                handleCellClick={handleViewConfigAndSimilarPlatesFromRolledPlates}
              />
            )
          )}
        </div>
      </div>
      <div>
        {/* Configs  */}
        {/* {rowSelectionModel?.length > 0 && ( */}
        <Config
          currentConfigData={currentConfigData}
          recommendedConfigData={recommendedConfigData}
          currentConfigLoading={loading.currentConfig}
          recommendedConfigLoading={loading.recommendedConfig}
          prtData={prtData}
          isSlab={isSlab}
        />
        {/* )} */}
      </div>
      <div className="w-full rounded-xl bg-white">
        {/* Historical Data for a particular row */}

        <div className="flex flex-row w-full justify-between px-2 py-2">

          {similarPlatesData && similarPlatesColumns && (
            <p className="rounded-e-xl rounded-s-xl px-2 py-3 text-xl font-medium">
              Nearest Neighbour
            </p>
          )}
          {similarPlatesData && similarPlatesColumns && <ExlcButton order={getOrder(similarPlatesColumns)} data={similarPlatesData} name={"Nearest_Neighbour"} />}
        </div>

        <div className="max-h-[60vh] w-full">
          {loading?.similarPlates ? (
            <TableSkeleton
              headers={[
                "Header 1",
                "Header 2",
                "Header 3",
                "Header 4",
                "Header 5",
                "Header 6",
                "Header 7",
                "Header 8",
              ]}
              rows={7}
              cellsPerRow={8}
            />
          ) : (
            similarPlatesData &&
            similarPlatesColumns && nearestNeighbourColumnGroupingModel && (
              <BiasRecommenderTable
                columns={similarPlatesColumns || []}
                rowData={similarPlatesData || []}
                columnGroupingModel={nearestNeighbourColumnGroupingModel}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};
