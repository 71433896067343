import Lottie from "lottie-react";
import AiAnimation from "../lottieFiles/AiAnimation.json";
const ModelStatusFallback = ({ userData, modelInfo }) => {
  return (
    <div className="flex justify-center items-center w-full h-[80vh]">
      <div className="flex flex-col gap-3 items-center">
        <div className="flex flex-col gap-1 capitalize">
          <div className="flex gap-2 items-center text-lg">
            <p className="text-[#938F96] w-[200px]">Status:</p>
            <p className="text-[#3E3C42] font-medium">{modelInfo?.status}</p>
          </div>
          <div className="flex gap-2 items-center text-lg">
            <p className="text-[#938F96] w-[200px]">No. of Files:</p>
            <p className="text-[#3E3C42] font-medium">
              {Object.entries(userData?.uploadedFiles || {}).length}
            </p>
          </div>
          <div className="flex gap-2 items-center text-lg">
            <p className="text-[#938F96] w-[200px]">No. of files Annotated:</p>
            <p className="text-[#3E3C42] font-medium">
              {userData?.annotatedData?.length}
            </p>
          </div>
          {["FAILED", "TERMINATED"].includes(modelInfo?.status) && (
            <div className="flex gap-2 items-center text-lg">
              <p className="text-red-600 font-medium">{modelInfo?.remarks}</p>
            </div>
          )}
        </div>
        <div className="w-[300px] h-[300px]">
          {["FAILED", "TERMINATED"].includes(modelInfo?.status) ? (
            <img
              src="/selfServiceIcons/modelFail.svg"
              className="w-full h-full"
              alt="failed"
            />
          ) : (
            <Lottie animationData={AiAnimation} loop={true} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ModelStatusFallback;
