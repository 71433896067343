import { Input, Radio, RadioGroup } from "@chakra-ui/react";

const CompanySizeDetails = ({
  setCompanySize,
  companySize,
  review,
  formData,
  validationErrors,
  handleChange,
  setFieledsChange,
}) => {
  return (
    <div className="p-6 rounded-lg flex flex-col gap-3 bg-white">
      <p className="text-[#3E3C42] text-lg font-medium ">Company size</p>

      <div className="flex flex-col gap-3">
        <p className="text-[#79767D] text-sm font-medium mb-1">Company Size</p>
        <RadioGroup
          onChange={(value) => {
            setCompanySize(value);
            setFieledsChange(true);
          }}
          value={companySize}
          isDisabled={review}
        >
          <div
            className="flex flex-col lg:grid lg:grid-cols-4 gap-1 lg:gap-2"
            style={{ width: "fit-content" }}
          >
            {["Large", "Medium", "Small"].map((x) => (
              <div key={x} className="flex items-center">
                <div
                  style={{
                    backgroundColor: companySize === x ? "#DDEEFF80" : "#FFF",
                    borderRadius: "8px",
                    mb: "12px",
                  }}
                >
                  <Radio
                    value={x}
                    py={"8px"}
                    pl={"8px"}
                    pr={"12px"}
                    fontSize={"14px"}
                    fontWeight={500}
                    color={"#3E3C42"}
                    _checked={{
                      bg: "#6CA6FC",
                      borderColor: "#6CA6FC",
                    }}
                    _hover={{
                      borderColor: "#6CA6FC",
                    }}
                    borderColor={"gray.300"}
                    disabled={review}
                  >
                    {x}
                  </Radio>
                </div>
                <span className="text-[#79767D] text-sm font-medium ml-2">
                  {x === "Large"
                    ? "(500+ employees)"
                    : x === "Medium"
                    ? "(50-100 employees)"
                    : "(<50 employees)"}
                </span>
              </div>
            ))}
          </div>
        </RadioGroup>
      </div>

      <div>
        <p className="text-[#79767D] text-sm font-medium mb-3">
          Number of users
        </p>
        <div style={{ width: "fit-content" }}>
          <Input
            type="number"
            name="numberOfUsers"
            value={formData?.numberOfUsers}
            borderColor={
              validationErrors?.numberOfUsers ? "red.500" : "gray.300"
            }
            borderWidth={validationErrors?.numberOfUsers ? "2px" : "2px"}
            disabled={review}
            border={review ? "none" : ""}
            onChange={handleChange}
          />
        </div>
        {validationErrors?.numberOfUsers && (
          <p className="!mb-1 !text-[red] !text-sm">
            {validationErrors?.numberOfUsers}
          </p>
        )}
      </div>
    </div>
  );
};

export default CompanySizeDetails;
