import axios from "axios";
import { useState, useEffect, useContext } from "react";
import NavContext from "../../NavContext";
import { getLiverAlerts } from "../SizingComponents/CamCard";
import DonutChart from "../../Charts/SizingCharts/DonutChart";
import LiquidGauge from "../../Charts/SizingCharts/LiquidGauge";
import { Skeleton, SkeletonCircle, Spinner, useToast } from "@chakra-ui/react";
import { useWindowSize } from "@uidotdev/usehooks";
import { baseURL } from "../../../index";
import HourlyLineChart from "../../Charts/SizingCharts/HourlyLineChart";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import ReactPlayer from "react-player";
import TonalButton from "../../../util/Buttons/TonalButton";

const theme = createTheme();

let color = {
  size: [
    "#ffc107",
    "#5193f6",
    "#ef6f12",
    "#1c56ac",
    "#e91e63",
    "#00bcd4",
    "#8bc34a",
    "#9c27b0",
    "#673ab7",
    "#ff9800",
    "#4caf50",
    "#795548",
  ],
  color: ["#79767D", "#000000"],
  moisture: ["#084298"],
};

const CamFeed = ({
  material,
  cameraId,
  clientId,
  plantId,
  callApi,
  initialRender,
  setShowCrusherTab,
}) => {
  const toast = useToast();
  const { auth } = useContext(NavContext);
  const [camData, setCamData] = useState("");
  const [hourlyChart, setHourlChart] = useState([]);
  const [loaded, setLoaded] = useState(true);
  const [currentHour, setCurrentHour] = useState(new Date().getHours());
  const [selectTrendType, setSelectTrendType] = useState(0);
  const [wtAvgValue, setWtAvgValue] = useState(null);
  const [hlsLink, setHlsLink] = useState("");
  const [calorificValue, setCalorificValue] = useState({
    msw: null,
    agro: null,
    plastic: null,
  });
  const size = useWindowSize();
  const apiCall = async () => {
    const requestData = JSON.stringify({
      clientId: clientId,
      material: material,
      cameraId: cameraId,
    });
    const response = await axios
      .post(baseURL + "vision/v2/sizing/analysis/detail/", requestData, {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      })
      .then((response) => {
        setCamData(response.data);
        if (response.data?.showCrusher) {
          setShowCrusherTab(response.data?.showCrusher);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const hourlyApi = async () => {
    try {
      const requestData = JSON.stringify({
        clientId: clientId,
        material: material,
        startDate: new Date().getTime() - 24 * 60 * 60 * 1000,
        endDate: new Date().getTime(),
        cameraId: cameraId,
        plantName: plantId,
        basis: "2",
        flatten: false,
      });
      const response = await axios.post(
        baseURL + "vision/v2/sizing/report/overview/",
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.data?.data) {
        console.log("vision/v2/sizing/report/overview/", response.data);
        setHourlChart(response.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlRtspAdd = async () => {
    try {
      const requestData = JSON.stringify({
        rtsp: "rtsp://admin:Ripik.ai@115.247.181.88/Streaming/channels/101",
        uuid: "7f725fa8-6cac-482c-ac31-5d484ac8bba3",
      });
      console.log("calling... play");

      const response = await axios.post(baseURL + "/create", requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response?.status === 200) {
        let data = response?.data;
        let hlsUrl = baseURL + data?.replace(/^\./, "");
        toast({
          title: "Processing",
          description: "This can take upto a minute",
          status: "info",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        setTimeout(() => setHlsLink(hlsUrl), 20 * 1000);
      }
    } catch (error) {
      toast({
        title: error?.response?.status + " Error",
        description: error?.response?.data?.message,
        status: "error",
        duration: 6000,
        isClosable: true,
        position: "top-right",
      });

      console.log(error);
    }
  };

  const killHlsStream = async ({ uuid = "", toast = () => {}, rtsp = "" }) => {
    try {
      const requestData = JSON.stringify({
        uuid: uuid,
        rtsp: rtsp?.trim(),
      });
      console.log("calling... kill");
      const response = await axios?.post(baseURL + "/delete", requestData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
    } catch (error) {
      toast({
        title: error?.response?.status + " Error",
        description: error?.response?.data?.message,
        status: "error",
        duration: 6000,
        isClosable: true,
        position: "top-right",
      });
      console.log(error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const newHour = new Date().getHours();
      if (newHour !== currentHour) {
        setCurrentHour(newHour);
        hourlyApi();
      }
    }, 1000 * 60);
    return () => clearInterval(interval);
  }, [currentHour]);

  useEffect(() => {
    if (!initialRender) {
      apiCall();
      getLiverAlerts(auth, toast, plantId, cameraId, material, clientId, true);
    } else {
      apiCall();
      getLiverAlerts(auth, toast, plantId, cameraId, material, clientId, true);
      hourlyApi();
    }
  }, [callApi]);

  const fetchCalorificValue = async () => {
    try {
      const requestData = JSON.stringify({
        clientId: clientId,
        material: material,
        cameraId: cameraId,
      });
      const response = await axios.post(
        baseURL + "vision/v2/sizing/fetch/calorificValue/",
        requestData,
        {
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      setCalorificValue((prev) => {
        return {
          ...prev,
          msw: response?.data?.msw || null,
          agro: response?.data?.agro || null,
          plastic: response?.data?.plastic || null,
        };
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveCalorificValue = async () => {
    try {
      const requestData = JSON.stringify({
        clientId: clientId,
        material: material,
        cameraId: cameraId,

        msw: Number(calorificValue?.msw) || 0,
        agro: Number(calorificValue?.agro) || 0,
        plastic: Number(calorificValue?.plastic) || 0,
      });
      const response = await axios.post(
        baseURL + "vision/v2/sizing/store/calorificValue/",
        requestData,
        {
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      toast({
        title: "Calorific Value Updated",
        description: response?.data?.message || "Success",
        status: "success",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      fetchCalorificValue();
    } catch (error) {
      console.log(error);
      toast({
        title: "Calorific Value Not Updated",
        description: error?.response?.data?.message || "",
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right",
      });
      fetchCalorificValue();
    }
  };

  const handleChangeCalorificValue = (e) => {
    const { name, value } = e.target;
    setCalorificValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const calculateCalory = (calVal, currPercent) => {
    return calVal && currPercent ? (calVal * currPercent) / 100 : 0;
  };

  const calculateAvgCalories = () => {
    let sum = 0; // Use 'let' to allow modification
    if (camData.hasOwnProperty("composition")) {
      Object.keys(camData.composition).forEach((key) => {
        console.log(
          key,
          calorificValue,
          calorificValue[key],
          camData.composition[key],
          "keu"
        );
        sum += calculateCalory(calorificValue[key], camData.composition[key]);
      });
    }
    sum = sum?.toFixed(2) || sum; // Round to 2 decimal places
    console.log(sum, "sum of all 3");
    setWtAvgValue((prev) => sum);
  };

  useEffect(() => {
    calculateAvgCalories();
  }, [calorificValue, camData]);

  useEffect(() => {
    if (material == "afr") {
      // handlRtspAdd();
      fetchCalorificValue();

      // const handleUnload = () => {
      //   killHlsStream({
      //     uuid: "7f725fa8-6cac-482c-ac31-5d484ac8bba3",
      //     rtsp: "rtsp://admin:Ripik.ai@115.247.181.88/Streaming/channels/101",
      //     toast: toast,
      //   });
      // };
      // window.addEventListener("unload", handleUnload);
      return () => {
        // handleUnload();
        // window.removeEventListener("unload", handleUnload);
      };
    }
  }, [material]);
  const coalCheck =
    material === "coal" && (camData?.showColor || camData?.showMoisture);
  const afrCheck = material == "afr";

  return (
    <>
      {camData && (
        <div
          className={`flex flex-col gap-2 transition ease-in duration-1000 ${
            loaded ? "opacity-100" : "opacity-0"
          }`}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 rounded-xl p-4 pr-6 pl-6 bg-white">
            <div className="flex flex-col gap-2 items-center w-full">
              <p className="self-start text-[#3E3C42] text-md">
                Original image
              </p>
              <div className="w-full h-full bg-black rounded-xl flex justify-center">
                {
                  <img
                    className="rounded-lg h-[25vh]"
                    src={camData?.originalImage}
                  />
                }
              </div>
            </div>
            {camData?.noCoal === 0 ? (
              <div className="flex flex-col gap-2 items-center w-full">
                <p className="self-start text-[#3E3C42] text-md">
                  Perspective image
                </p>
                <div className="w-full h-full bg-black rounded-xl flex justify-center">
                  <img
                    className="rounded-lg h-[25vh]"
                    src={camData?.perspectiveImage}
                  />
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-8 items-center justify-center">
                <img src="/Common/noCoal.svg" className="h-[10vh]" />
                <p>No {material} on belt</p>
              </div>
            )}
            {camData?.noCoal === 0 ? (
              <div className="flex flex-col gap-2 items-center w-full">
                <p className="text-md self-start text-[#3E3C42] ">
                  Particle analysis
                </p>
                <div className="w-full h-full bg-black rounded-xl flex justify-center">
                  <img
                    className="rounded-lg h-[25vh]"
                    src={camData?.particleImage}
                  />
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-8 items-center justify-center">
                <img src="/Common/noCoal.svg" className="h-[10vh]" />
                <p>No {material} on belt</p>
              </div>
            )}
          </div>
          <div
            className={
              coalCheck
                ? "flex flex-col gap-2"
                : `flex flex-col min-[1150px]:flex-row gap-2`
            }
          >
            <div className="flex flex-col gap-4 rounded-xl p-6 pt-4 bg-white ">
              <div className="flex justify-between items-baseline">
                <p className="text-[#3E3C42] text-xl font-medium">
                  Current analysis
                </p>
                <p className="text-[#938F96] text-sm">
                  Last updated{" "}
                  {new Date(camData.createdAt * 1000).toLocaleTimeString(
                    "en-GB"
                  )}
                </p>
              </div>
              {camData?.noCoal === 0 ? (
                <div
                  className={
                    coalCheck
                      ? "flex gap-[64px] justify-between flex-col min-[1440px]:flex-row"
                      : "flex flex-col-reverse gap-4"
                  }
                >
                  <div className="w-full flex  flex-col min-[900px]:flex-row justify-between gap-[25px]  md:gap-[64px]">
                    <div className="flex flex-col gap-4 w-full">
                      <p className="text-md font-medium text-[#605D64]">
                        Size distribution{" "}
                        {camData?.bigParticlePresent ? (
                          <span className="text-xs p-1 rounded border border-red-700 bg-red-100 font-semibold text-red-700">
                            Big particle detected
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      <div
                        className={
                          coalCheck
                            ? "w-full md:w-[50vw] lg:w-[40vw] xl:w-[30vw] h-[30vh] xl:h-[30vh] "
                            : `w-full min-[1150px]:w-[20vw] h-[50vh]`
                        }
                      >
                        <DonutChart
                          data={Object.values(camData?.size ?? {})}
                          labels={Object.keys(camData?.size ?? {})}
                          position={
                            coalCheck && size.width >= 425 ? "right" : "bottom"
                          }
                          thresholds={
                            "thresholds" in camData ? camData?.thresholds : null
                          }
                        />
                      </div>
                    </div>
                    {camData?.showColor && (
                      <div className="w-full  flex ">
                        <div className="flex flex-col gap-4 w-full md:min-w-[150px] min-h-[200px] max-h-[250px] ">
                          <p className="text-md font-medium text-[#605D64] text-center">
                            Color distribution
                          </p>
                          <div className="w-full flex justify-center  h-full">
                            <div className="h-full w-[16vw] sm:w-[6vw] flex flex-col gap-1 rounded-lg border">
                              <div
                                style={{ height: `${camData?.color?.gray}%` }}
                                className="flex justify-center items-center bg-[#79767D] rounded-tr-lg rounded-tl-lg text-white text-center text-sm lg:text-sm xl:text-sm font-medium"
                              >
                                {camData?.color?.gray?.toFixed(2)}%
                              </div>
                              <div
                                style={{ height: `${camData?.color?.black}%` }}
                                className="flex justify-center items-center bg-black rounded-br-lg rounded-bl-lg text-white text-center text-sm lg:text-sm xl:text-sm font-medium"
                              >
                                {camData?.color?.black?.toFixed(2)}%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="w-full flex flex-col min-[900px]:flex-row  justify-between gap-[25px] md:gap-[64px]  ">
                    {camData?.showMoisture && (
                      <div className="flex flex-col gap-4 w-[100%]  lg:w-[58%] ">
                        <p className="text-md font-medium text-[#605D64]">
                          Moisture content
                        </p>
                        <div className="w-full h-full flex gap-4 justify-center">
                          <LiquidGauge
                            moisture={camData?.moisture ?? 0}
                            // moisture={0}
                            r={size.width < 1440 ? 80 : 100}
                          />
                          {size.width < 1440 && (
                            <div className="w-[0%] lg:w-[25%]"></div>
                          )}
                        </div>
                      </div>
                    )}
                    <div className=" w-[100%]  flex ">
                      <div className="w-full flex flex-row lg:flex-col gap-2  h-full whitespace-nowrap  ">
                        <div className="w-full flex flex-col gap-2  ">
                          <p className="text-md font-medium text-[#605D64]">
                            MPS
                          </p>
                          <div className="min-w-[150px] flex w-full gap-2 rounded-lg bg-[#f6faff] text-center py-[25px] pl-3 pr-7 text-[#1C56AC] text-md">
                            {camData?.mps?.toFixed(2)} mm
                          </div>
                        </div>

                        {camData?.hasOwnProperty("gcv") && (
                          <div className="w-full flex flex-col gap-2">
                            <p className="text-md font-medium text-[#605D64]">
                              GCV
                            </p>
                            <div className="min-w-[150px] flex w-full gap-2 rounded-lg bg-[#f6faff] text-center py-[25px] pl-3 pr-7 text-[#1C56AC] text-md">
                              {camData?.gcv} Kcal/Kg
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {afrCheck && (
                    <div className="flex flex-col w-[100%] gap-4 ">
                      <div className=" w-[100%]  flex ">
                        <div className="w-full flex flex-row lg:flex-col gap-2  h-full whitespace-nowrap  ">
                          <div className="w-full flex flex-col gap-2  ">
                            <p className="text-md font-medium text-[#605D64]">
                              Weighted Avg Calorific Value
                            </p>
                            <div className="min-w-[150px] flex w-full gap-2 rounded-lg bg-[#f6faff] text-center py-[25px] pl-3 pr-7 text-[#1C56AC] text-md">
                              {wtAvgValue}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="w-full flex  flex-col min-[900px]:flex-row justify-between gap-[25px]  md:gap-[64px]  ">
                        <div className="flex flex-col gap-4 w-full">
                          <p className="text-md font-medium text-[#605D64]">
                            Composition analysis{" "}
                            {camData?.bigParticlePresent ? (
                              <span className="text-xs p-1 rounded border border-red-700 bg-red-100 font-semibold text-red-700">
                                Big particle detected
                              </span>
                            ) : (
                              ""
                            )}
                          </p>
                          <div
                            className={
                              coalCheck
                                ? "w-full md:w-[50vw] lg:w-[40vw] xl:w-[30vw] h-[30vh] xl:h-[30vh] "
                                : `w-full min-[1150px]:w-[20vw] h-[50vh] `
                            }
                          >
                            {camData?.composition ? (
                              <DonutChart
                                data={Object.values(camData.composition || {})}
                                labels={Object.keys(camData.composition || {})}
                                position={
                                  coalCheck && size.width >= 425
                                    ? "right"
                                    : "bottom"
                                }
                                thresholds={
                                  "thresholds" in camData
                                    ? camData?.thresholds
                                    : null
                                }
                              />
                            ) : (
                              <div className="flex justify-center items-center  h-full pb-4">
                                <SkeletonCircle size="220" />
                              </div>
                            )}
                          </div>
                        </div>
                        {camData?.showColor && (
                          <div className="w-full  flex ">
                            <div className="flex flex-col gap-4 w-full md:min-w-[150px] min-h-[200px] max-h-[250px] ">
                              <p className="text-md font-medium text-[#605D64] text-center">
                                Color distribution
                              </p>
                              <div className="w-full flex justify-center  h-full">
                                <div className="h-full w-[16vw] sm:w-[6vw] flex flex-col gap-1 rounded-lg border">
                                  <div
                                    style={{
                                      height: `${camData?.color?.gray}%`,
                                    }}
                                    className="flex justify-center items-center bg-[#79767D] rounded-tr-lg rounded-tl-lg text-white text-center text-sm lg:text-sm xl:text-sm font-medium"
                                  >
                                    {camData?.color?.gray.toFixed(2)}%
                                  </div>
                                  <div
                                    style={{
                                      height: `${camData?.color?.black}%`,
                                    }}
                                    className="flex justify-center items-center bg-black rounded-br-lg rounded-bl-lg text-white text-center text-sm lg:text-sm xl:text-sm font-medium"
                                  >
                                    {camData?.color?.black?.toFixed(2)}%
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {afrCheck && (
                    <div className="w-full flex flex-col gap-2  px-0 py-1 border rounded-lg px-2">
                      <p className="font-semibold"> Update calorific Value</p>
                      <div className="flex flex-col gap-2">
                        <form className="flex flex-col gap-3">
                          <div className="flex justify-between">
                            <label className="text-sm">MSW:</label>
                            <input
                              type="number"
                              name="msw"
                              className="border rounded-md"
                              value={calorificValue.msw}
                              onChange={handleChangeCalorificValue}
                            />
                          </div>
                          <div className="flex justify-between">
                            <label>Agro Waste:</label>
                            <input
                              type="number"
                              name="agro"
                              className="border rounded-md"
                              value={calorificValue.agro}
                              onChange={handleChangeCalorificValue}
                            />
                          </div>
                          <div className="flex justify-between">
                            <label>Plastic:</label>
                            <input
                              type="number"
                              name="plastic"
                              className="border rounded-md"
                              value={calorificValue.plastic}
                              onChange={handleChangeCalorificValue}
                            />
                          </div>
                        </form>
                        <div className="flex justify-end">
                          <TonalButton
                            text={"save"}
                            width={"80px"}
                            height={"40px"}
                            onClick={handleSaveCalorificValue}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex flex-col gap-8 items-center justify-center h-full">
                  <img src="/Common/noCoal.svg" className="h-[10vh] w-[30vw]" />
                  <p>No {material} on belt</p>
                </div>
              )}
            </div>
            <div className="flex flex-col gap-6 rounded-xl p-6 pt-4 bg-white w-full">
              <div className="flex flex-col xl:flex-row gap-6">
                <p className="text-[#3E3C42] text-xl font-medium">
                  Trend analysis
                </p>
                <div className="px-4 py-1 flex gap-4 rounded-lg bg-[#f6faff] text-md font-medium overflow-x-auto">
                  <div className="flex gap-2 px-3 py-[2px] items-baseline">
                    <p className="text-[#605D64]">MPS</p>
                    <p> </p>
                  </div>
                  {camData.hasOwnProperty("mpsAvg") &&
                    Object.keys(camData.mpsAvg).map((i) => {
                      return (
                        <div className="flex gap-2 px-3 py-[2px] items-baseline min-w-[150px]">
                          <p className="text-[#1C56AC]">
                            {camData.mpsAvg[i]} mm
                          </p>
                          <p className="text-[#605D64] font-normal">{i}</p>
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="rounded-xl bg-white flex flex-col lg:flex-row gap-4 h-full">
                <div className="flex flex-col flex-1 gap-4 ">
                  <div className="flex justify-between pr-2">
                    <p className="text-[#605D64] font-medium text-md">
                      Size trend
                    </p>
                    {afrCheck && (
                      <div className="w-[200px]">
                        <ThemeProvider theme={theme}>
                          <FormControl
                            sx={{ m: 1, minWidth: 200 }}
                            size="small"
                          >
                            <InputLabel id="demo-select-small-label">
                              Select trend type
                            </InputLabel>
                            <Select
                              labelId="demo-select-small-label"
                              id="demo-select-small"
                              value={selectTrendType}
                              label="Select trend type"
                              onChange={(e) => {
                                setSelectTrendType(e.target.value);
                              }}
                            >
                              <MenuItem value={0}>Sizing</MenuItem>
                              <MenuItem value={1}>Composition</MenuItem>
                            </Select>
                          </FormControl>
                        </ThemeProvider>
                      </div>
                    )}
                  </div>

                  <div
                    className={
                      coalCheck ? "h-[40vh]" : "h-[40vh] min-[1150px]:h-[60vh]"
                    }
                  >
                    <HourlyLineChart
                      data={hourlyChart}
                      type={selectTrendType === 1 ? "composition" : "size"}
                      color={color["size"]}
                      material={material}
                    />
                  </div>
                </div>
                {camData?.showColor && (
                  <div className="flex flex-col flex-1 gap-4">
                    <p className="text-[#605D64] font-medium text-md">
                      Color trend
                    </p>
                    <div className="h-[40vh]">
                      <HourlyLineChart
                        data={hourlyChart}
                        type={"color"}
                        color={color["color"]}
                      />
                    </div>
                  </div>
                )}
                {camData?.showMoisture && (
                  <div className="flex flex-col flex-1 gap-4">
                    <p className="text-[#605D64] font-medium text-md">
                      Moisture trend
                    </p>
                    <div className="h-[40vh]">
                      <HourlyLineChart
                        data={hourlyChart}
                        type={"moisture"}
                        color={color["moisture"]}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {!loaded && (
        <div
          style={{ top: camData ? "30%" : "100%" }}
          className="flex absolute right-[50%] opacity-100"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </div>
      )}
    </>
  );
};

export default CamFeed;
