// import CamCard from "./CamCard";
// import DetailModal from "./DetailModal";
import React, { useState } from "react";
import CameraCard from "./CameraCard";
// import CamCard from "./CamCard";

const Capitalize = (str) => {
  const arr = str?.split(" ");
  for (var i = 0; i < arr?.length; i++) {
    arr[i] = arr[i]?.charAt(0)?.toUpperCase() + arr[i]?.slice(1);
  }
  const str2 = arr?.join(" ");
  return str2;
};

const PlantCard = ({
  PlantName,
  CamData,
  thresholds,
  loading,
  sizeDataChanging = true,
  selectedCategoryBar,
  setSelectedBasis,
  selectedBasis,
  fromTime,
  toTime,
}) => {
  return (
    <div
      className={`flex flex-col bg-white rounded-xl pt-4 gap-1  
      
      `}
    >
      <div className="flex justify-between pl-4 pr-4">
        <p className="text-xl font-medium">{Capitalize(PlantName)}</p>
      </div>
      <div className={`flex flex-col `}>
        {CamData ? (
          Object?.keys(CamData).length > 0 &&
          Object?.keys(CamData)?.map((cam, idx) => {
            return (
              <CameraCard
                plantId={PlantName}
                cameraName={cam}
                data={CamData[cam][0]}
                thresholds={thresholds}
                loading={loading}
                sizeDataChanging={sizeDataChanging}
                selectedCategoryBar={selectedCategoryBar}
                setSelectedBasis={setSelectedBasis}
                selectedBasis={selectedBasis}
                fromTime={fromTime}
                toTime={toTime}
              />
              // <CamCard
              //   plantId={PlantName}
              //   cameraName={cam}
              //   data={CamData[cam][0]}
              //   thresholds={thresholds}
              // />
            );
          })
        ) : (
          <CameraCard />
        )}
      </div>
    </div>
  );
};

export default React.memo(PlantCard);
