import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { forwardRef, useEffect, useRef, useState } from "react";
import { createTheme, ThemeProvider, Tooltip } from "@mui/material";
import { Flex, Image } from "@chakra-ui/react";
import { CustomStyledDataGrid } from "../../../util/MaterialDataGrid/CustomStyledDatagrid";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import LoopIcon from "@mui/icons-material/Loop";
import VisibilityIcon from "@mui/icons-material/Visibility";

import {
  gridPaginatedVisibleSortedGridRowEntriesSelector,
  useGridApiRef,
} from "@mui/x-data-grid";
import { capitalizeFirstLetter } from "../../../util/sentenceCase";
import { useWindowSize } from "@uidotdev/usehooks";
import { generateDynamicColumns } from "./dataGridColumns";

const MuiTheme = createTheme();

export const stringDecimalFix = (str) => {
  const regex = /-?\d+\.\d+/;
  const match = str.match(regex);
  if (match) {
    const floatingNumber = parseFloat(match[0]);
    const fixedNumber = floatingNumber.toFixed(2);
    return str.replace(regex, fixedNumber);
  }
  return str;
};

const additionnalColumnsForGrid = [
  {
    groupId: "Kappa",
    headerAlign: "center",
    children: [
      { field: "kappa_current", headerAlign: "center" },
      { field: "kappa_expected", headerAlign: "center" },
    ],
  },
  {
    groupId: "Pentosan",
    headerAlign: "center",
    children: [
      { field: "pentosan_current", headerAlign: "center" },
      { field: "pentosan_expected", headerAlign: "center" },
    ],
  },
  {
    groupId: "Viscosity",
    headerAlign: "center",
    children: [
      { field: "viscosity_current", headerAlign: "center" },
      { field: "viscosity_expected", headerAlign: "center" },
    ],
  },
];

const getDynamicObjForColumns = {
  plantName: {
    field: "plantName",
    type: "string",
    headerName: "Plant name",
    valueFormatter: (params) => {
      return capitalizeFirstLetter(params.value); // Convert value to sentence case
    },
    minWidth: 106,
  },
  cameraId: {
    field: "cameraId",
    type: "string",

    headerName: "Camera",
    valueFormatter: (params) => {
      return capitalizeFirstLetter(params.value); // Convert value to sentence case
    },
    minWidth: 106,
  },
  startTime: {
    field: "startTime",
    headerName: "Start time",
    valueGetter: ({ value }) => new Date(value),
    type: "dateTime",
    minWidth: 155,
  },
  endTime: {
    field: "endTime",
    headerName: "End time",
    valueGetter: ({ value }) => new Date(value),
    type: "dateTime",
    minWidth: 155,
  },
  timestamp: {
    field: "timestamp",
    headerName: "Timestamp",
    valueGetter: ({ value }) => new Date(value),
    type: "dateTime",
    minWidth: 147,
  },
  "0-2mm": {
    field: "0-2mm",
    headerName: "0-2 mm",
    type: "string",
    minWidth: 109,
  },
  "0-6mm": {
    field: "0-6mm",
    headerName: "0-6 mm",
    type: "string",
    minWidth: 110,
  },
  "0-10mm": {
    field: "0-10mm",
    headerName: "0-10 mm",
    type: "string",
    minWidth: 120,
  },
  "0-25mm": {
    field: "0-25mm",
    headerName: "0-25 mm",
    type: "string",
    minWidth: 120,
  },
  "2-6mm": {
    field: "2-6mm",
    headerName: "2-6 mm",
    type: "string",
    minWidth: 108,
  },
  "6-8mm": {
    field: "6-8mm",
    headerName: "6-8 mm",
    type: "string",
    minWidth: 99,
  },
  "8+mm": { field: "8+mm", headerName: "8+ mm", type: "string", minWidth: 94 },
  "8-10mm": {
    field: "8-10mm",
    headerName: "8-10 mm",
    type: "string",
    minWidth: 120,
  },
  "10+mm": {
    field: "10+mm",
    headerName: "10+ mm",
    type: "string",
    minWidth: 120,
  },
  "10-20mm": {
    field: "10-20mm",
    headerName: "10-20 mm",
    type: "string",
    minWidth: 120,
  },
  "20-25mm": {
    field: "20-25mm",
    headerName: "20-25 mm",
    type: "string",
    minWidth: 120,
  },
  "20-30mm": {
    field: "20-30mm",
    headerName: "20-30 mm",
    type: "string",
    minWidth: 120,
  },
  "25-30mm": {
    field: "25-30mm",
    headerName: "25-30 mm",
    type: "string",
    minWidth: 120,
  },
  "25-50mm": {
    field: "25-50mm",
    headerName: "25-50 mm",
    type: "string",
    minWidth: 120,
  },
  "30-40mm": {
    field: "30-40mm",
    headerName: "30-40 mm",
    type: "string",
    minWidth: 120,
  },
  "40-50mm": {
    field: "40-50mm",
    headerName: "40-50 mm",
    type: "string",
    minWidth: 120,
  },
  "50+mm": {
    field: "50+mm",
    headerName: "50+ mm",
    type: "string",
    minWidth: 120,
  },
  "50-60mm": {
    field: "50-60mm",
    headerName: "50-60 mm",
    type: "string",
    minWidth: 120,
  },
  "50-100mm": {
    field: "50-100mm",
    headerName: "50-100 mm",
    type: "string",
    minWidth: 120,
  },
  "60-70mm": {
    field: "60-70mm",
    headerName: "60-70 mm",
    type: "string",
    minWidth: 120,
  },
  "70-80mm": {
    field: "70-80mm",
    headerName: "70-80 mm",
    type: "string",
    minWidth: 120,
  },
  "80+mm": {
    field: "80+mm",
    headerName: "80+ mm",
    type: "string",
    minWidth: 120,
  },
  "100-200mm": {
    field: "100-200mm",
    headerName: "100-200 mm",
    type: "string",
    minWidth: 120,
  },
  "200+mm": {
    field: "200+mm",
    headerName: "200+ mm",
    type: "string",
    minWidth: 120,
  },
  "undersize(0-7mm)": {
    field: "undersize(0-7mm)",
    headerName: "Undersize (0-7 mm)",
    type: "string",
    minWidth: 120,
  },
  "accepted(7-45mm)": {
    field: "accepted(7-45mm)",
    headerName: "Accepted (7-45 mm)",
    type: "string",
    minWidth: 120,
  },
  "oversize(45+mm)": {
    field: "oversize(45+mm)",
    headerName: "Oversize (45+ mm)",
    type: "string",
    minWidth: 120,
  },

  mps: { field: "mps", headerName: "MPS", type: "string", minWidth: 120 },
  moisture: {
    field: "moisture",
    headerName: "Moisture",
    type: "string",
    minWidth: 100,
  },
  black: {
    field: "black",
    headerName: "Black",
    type: "string",
    minWidth: 100,
  },
  gray: {
    field: "gray",
    headerName: "Gray",
    type: "string",
    minWidth: 95,
  },
  gcv: {
    field: "gcv",
    headerName: "GCV",
    type: "string",
    minWidth: 93,
  },
};

const HistoryTable = ({
  rowData,
  order,
  setDisplayData,
  setOpenModal,
  indexRef,
}) => {
  const gridApiRef = useGridApiRef();
  const size = useWindowSize();
  const [columns, setColumns] = useState([]);
  // order?.map((ele, idx) => {
  //   const objectToPush = getDynamicObjForColumns?.[ele];
  //   if (objectToPush) columns.push(objectToPush);
  // });

  // columns.push({
  //   field: "",
  //   headerName: "View details",
  //   minWidth: 103,
  //   type: "actions",
  //   headerAlign: "center",

  //   renderCell: (params) => (
  //     <button
  //       onClick={() => handleViewDetails(params)}
  //       className="cursor-pointer w-full text-[#084298] font-semibold text-normal hover:scale-105 flex justify-center"
  //     >
  //       <VisibilityIcon />
  //     </button>
  //   ),
  // });

  const [rows, setRows] = useState([]);
  const headerClass = "text-xs font-medium text-[#525056] bg-[#ddeeff] ";
  const cellClass = "text-sm font-medium text-[#3E3C42]  p-4";
  // const flexMap = {
  //   id: 0,
  //   plantName: 0.5,
  //   cameraId: 0.5,
  //   startTime: 1,
  //   timestamp: 1,
  //   endTime: 1,
  //   "0-2mm": 0.5,
  //   "0-6mm": 0.5,
  //   "0-10mm": 0.5,
  //   "0-25mm": 0.5,
  //   "2-6mm": 0.5,
  //   "6-8mm": 0.5,
  //   "8+mm": 0.5,
  //   "8-10mm": 0.5,
  //   "10+mm": 0.5,
  //   "10-20mm": 0.5,
  //   "20-25mm": 0.5,
  //   "20-30mm": 0.5,
  //   "25-30mm": 0.5,
  //   "25-50mm": 0.5,
  //   "30-40mm": 0.5,
  //   "40-50mm": 0.5,
  //   "50+mm": 0.5,
  //   "50-60mm": 0.5,
  //   "50-100mm": 0.5,
  //   "60-70mm": 0.5,
  //   "70-80mm": 0.5,
  //   "80+mm": 0.5,
  //   "100-200mm": 0.5,
  //   "200+mm": 0.5,
  //   "undersize(0-7mm)": 1,
  //   "accepted(7-45mm)": 1,
  //   "oversize(45+mm)": 1,
  //   mps: 0.5,
  //   moisture: 0.5,
  //   black: 0.5,
  //   gray: 0.5,
  //   gcv: 0.5,
  // };

  columns.map((val, idx) => {
    val["headerClassName"] = headerClass;
    val["cellClassName"] = cellClass;
    // val["flex"] = flexMap[val.field];
    return val;
  });

  const handleViewDetails = (params) => {
    const tempDisplayData =
      gridPaginatedVisibleSortedGridRowEntriesSelector(gridApiRef);
    const updatedData = tempDisplayData.map((el, id) => el.model);
    setDisplayData((prev) => updatedData);
    const index = params.api.getRowIndexRelativeToVisibleRows(params.row.id);
    indexRef.current = index;
    setOpenModal(true);
  };

  useEffect(() => {
    const dynamicTableColumns = async () => {
      const columnsToUSe = await generateDynamicColumns(
        order,
        true,
        true,
        false
      );
      let col = [
        {
          field: "id",
          headerName: "Sr No.",
        },
      ];
      if (columnsToUSe && columnsToUSe.length > 0) {
        columnsToUSe.map((ele, i) => {
          col.push(ele);
        });
      }
      col.push({
        field: "",
        headerName: "View details",
        minWidth: 103,
        type: "actions",
        headerAlign: "center",

        renderCell: (params) => (
          <button
            onClick={() => handleViewDetails(params)}
            className="cursor-pointer w-full text-[#084298] font-semibold text-normal hover:scale-105 flex justify-center"
          >
            <VisibilityIcon />
          </button>
        ),
      });
      setColumns(col);
    };

    dynamicTableColumns();
  }, [order]);

  useEffect(() => {
    setRows((prev) => {
      let newData = rowData.map((item, idx) => {
        if (!item.hasOwnProperty("id")) {
          return {
            ...item,
            id: idx,
          };
        } else {
          return {
            ...item,
          };
        }
      });
      return newData;
    });
  }, [rowData]);

  return (
    <div className=" w-full h-[69vh] ">
      <CustomStyledDataGrid
        apiRef={gridApiRef}
        rows={rows}
        columns={columns}
        columnVisibilityModel={{
          id: false,
        }}
        columnHeaderHeight={45}
        rowHeight={80}
        disableColumnSelector={true}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
          sorting: {
            sortModel: [{ field: "time", sort: "desc" }],
          },
        }}
        pagination
        // slots={{
        //   toolbar: CustomToolbar,
        // }}
        pageSizeOptions={[5, 10, 25]}
        sx={{
          "& .MuiDataGrid-columnHeader": {
            border: "0.9px solid #e0e0e0",
            textTransform: "none",
            fontSize: size.width < 768 ? "13px" : "14px",
          },
        }}
        columnGroupingModel={additionnalColumnsForGrid}
        experimentalFeatures={{ columnGrouping: true }}
        slotProps={{
          panel: {
            sx: {
              maxWidth: "98vw",
              alignSelf: "center",
              ".css-154sxbz-MuiPaper-root-MuiDataGrid-paper": {
                minWidth: 0,
              },
              // margin: 2,
            },
          },
          filterPanel: {
            filterFormProps: {
              // input box for operator selection(for multi-columns => and, or)
              logicOperatorInputProps: {
                sx: { display: "none" },
              },
              // input box for column selection
              columnInputProps: {
                variant: "outlined",
                size: "small",
              },
              // input box for operator selection(for a particular columns => contains, start with, is empty ....)
              operatorInputProps: {
                sx: { display: "none" },
              },
              // input box to write the value on the basis of which data get's filtered
              valueInputProps: {
                InputComponentProps: {
                  variant: "outlined",
                  size: "small",
                },
              },
              // delete icon
              deleteIconProps: {
                sx: {
                  "& .MuiSvgIcon-root": { color: "#D32F2F" },
                },
              },
            },
            sx: {
              overflowX: "auto",
              "& .MuiDataGrid-filterForm": { p: 2 },
              "& .MuiDataGrid-filterFormLogicOperatorInput": {
                mr: 2,
                minWidth: 80,
              },
              "& .MuiDataGrid-filterFormColumnInput": {
                mr: 2,
                minWidth: 150,
              },
              "& .MuiDataGrid-filterFormValueInput": { minWidth: 150 },
            },
          },
        }}
      />
    </div>
  );
};
export default HistoryTable;
