import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useToast,
  Tooltip,
  Spinner,
} from "@chakra-ui/react";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import TonalButton from "../../../util/Buttons/TonalButton";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../..";
import NavContext from "../../NavContext";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import ShareModal from "../Components/ShareModal";

export const status = (data) => {
  switch (data) {
    case "FAILED":
    case "TERMINATED":
      return "fail";
    case "DRAFT":
      return "draft";
    case "COMPLETED":
      return "live";
    case "PUBLISHED":
      return "published";
    default:
      return "run";
  }
};

export const deleteApi = async (data, toast, auth, fetchData) => {
  try {
    const param = {
      projectId: data?.projectId,
    };
    const response = await axios.post(
      baseURL + "selfserve/v1/project/v1/delete/",
      null,
      {
        params: param,
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    if (response.status == 200) {
      toast({
        title: "Successfully deleted",
        description: `Project ${data?.name} deleted`,
        status: "success",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
      fetchData();
    }
  } catch (error) {
    console.log(error);
    toast({
      title: "Error",
      description: error.response?.data?.message,
      status: "error",
      position: "top-right",
      duration: 2000,
      isClosable: true,
    });
  }
};

export const removeShareApi = async (data, toast, auth, fetchData) => {
  try {
    const param = {
      projectId: data?.projectId,
    };
    const response = await axios.delete(
      baseURL + "selfserve/v1/project/v1/sharingUsers/",
      {
        params: param,
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    if (response.status == 200) {
      toast({
        title: "Successfully removed",
        description: `Project ${data?.name} has been removed from shared`,
        status: "success",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
      fetchData();
    }
  } catch (error) {
    console.log(error);
    toast({
      title: "Error",
      description: `Remove share api error`,
      status: "error",
      position: "top-right",
      duration: 2000,
      isClosable: true,
    });
  }
};

export const options = [
  {
    type: "Share access",
    icon: "/selfServiceIcons/share.svg",
  },
  {
    type: "Delete",
    icon: "/delete.svg",
  },
  {
    type: "Remove share",
    icon: "/delete.svg",
  },
];

const CameraCard = ({ data, fetchData }) => {
  const { auth, email } = useContext(NavContext);
  const [shareModal, setShareModal] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const handleClick = () => {
    if (data?.status != "DRAFt")
      navigate(`/Sandbox/Cam/View/${data?.projectId}`);
    // else if (data?.status == "DRAFT")
    //   navigate(`/Sandbox/Draft/${data?.projectId}`);
  };

  const cloneApi = async () => {
    try {
      const param = {
        projectId: data?.projectId,
      };
      const response = await axios.post(
        baseURL + "selfserve/v1/project/v1/clone/",
        null,
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.status == 200) {
        toast({
          title: "Successfully clone",
          description: `Project ${data?.name} cloned`,
          status: "success",
          position: "top-right",
          duration: 2000,
          isClosable: true,
        });
        fetchData();
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 400) {
        toast({
          title: "Warning",
          description: error.response?.data?.message,
          status: "warning",
          position: "top-right",
          duration: 2000,
          isClosable: true,
        });
      }
    }
  };

  const handleMenuClick = (val) => {
    switch (val) {
      case "Delete":
        // deleteApi(data, toast, auth, fetchData);
        break;
      case "Share access":
        // setShareModal(true);
        break;
      case "Remove share":
        // removeShareApi(data, toast, auth, fetchData);
        break;
      default:
        console.log("error");
    }
  };

  const statusFinder = () => {
    return data.status;
  };

  return (
    <div
      className="h-[210px] px-4 py-5 rounded flex flex-col gap-2 relative bg-white"
      style={{
        boxShadow:
          "-4px -4px 24px 0px rgba(0, 0, 0, 0.07), 4px 4px 24px 0px rgba(0, 0, 0, 0.07)",
        border: data?.owner != email ? "2px dashed #9FC5E8" : "",
      }}
    >
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-0">
          <Tooltip label={data?.name} hasArrow placement="top">
            <p className="text-[#3E3C42] text-base font-medium whitespace-nowrap w-[150px] overflow-hidden text-ellipsis">
              {data?.name}
            </p>
          </Tooltip>
          <p className="text-[#79767D] text-xs font-medium">
            {new Date(data?.createdAt)?.toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </p>
        </div>
        <div className="flex flex-col gap-0">
          <p className="text-[#938F96] text-xs">Linked Project</p>
          <p className="text-[#525056] text-sm">{data?.projectName}</p>
        </div>
        <div className="flex flex-col gap-0">
          <p className="text-[#938F96] text-xs">Owner</p>
          <p className="text-[#525056] text-sm">{data?.owner}</p>
        </div>
      </div>
      <div className="flex gap-2 items-center">
        <PrimaryButton
          text={"View"}
          onClick={() => handleClick()}
          color="green"
        />
        <TonalButton
          text={"Clone"}
          onClick={null}
          disable={data?.status == "DRAFT"}
          color="green"
        />
      </div>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<img src="/selfServiceIcons/menu.svg" alt="menu" />}
          variant="simple"
          position={"absolute"}
          top={"20px"}
          right={"8px"}
        />
        <MenuList
          px={"12px"}
          py={"16px"}
          rounded={"8px"}
          display={"flex"}
          flexDirection={"column"}
          gap={"16px"}
          minWidth={"fit-content"}
          w={"180px"}
          position={"absolute"}
          right={"-50px"}
          top={"-15px"}
        >
          {options
            .filter((item) => {
              if (data.owner != email)
                return ["Download", "Remove share"]?.includes(item.type);
              else if (
                data?.status == "DRAFT" ||
                status(statusFinder()) != "live"
              )
                return !["Download", "Remove share"]?.includes(item.type);
              else return item.type != "Remove share";
            })
            .map((item) => {
              return (
                <MenuItem
                  icon={
                    <img
                      src={item.icon}
                      alt={item.type}
                      height={"15px"}
                      width={"15px"}
                    />
                  }
                  textColor={"#605D64"}
                  fontSize={"14px"}
                  fontWeight={500}
                  p={"2px"}
                  _hover={{ bg: "#DDEEFF80", borderRadius: "4px" }}
                  _focus={{ bg: "#DDEEFF80", borderRadius: "4px" }}
                  onClick={() => handleMenuClick(item.type)}
                >
                  {item.type}
                </MenuItem>
              );
            })}
        </MenuList>
      </Menu>
      {shareModal && (
        <ShareModal
          data={data}
          closeModal={() => setShareModal(false)}
          openModal={shareModal}
        />
      )}
      <Tooltip label={status(statusFinder())} hasArrow>
        <div className="w-8 h-8 absolute -top-2 -right-2 rounded-full bg-white p-1 shadow-md">
          {status(statusFinder()) != "run" ? (
            <img
              src={`/selfServiceIcons/${status(statusFinder())}.svg`}
              className="w-full h-full"
              alt="status"
            />
          ) : (
            <Spinner color="#69b04b" thickness="4px" />
          )}
        </div>
      </Tooltip>
    </div>
  );
};

export default CameraCard;
