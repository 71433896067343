import React from "react";
import { capitalizeCamelCaseString } from "./capitalizeCamelCaseString";
import { formatDateTime } from "./dateTimeFormatter";
import { getGridStringOperators } from "@mui/x-data-grid";
import { CustomCell, CustomEditCell } from "../Tabs/WhatIfAnalysis";

export const generateAlertColumns = async (columnNames, handleViewDetails) => {
  // static column
  const staticViewDetailColumn = {
    field: "view_details",
    headerName: "View Details",
    minWidth: 150,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      // console.log(data);
      return (
        <button
          onClick={() => handleViewDetails(params.row.driverId)}
          className="text-blue-800 cursor-pointer hover:text-blue-200 font-semibold min-w-[80px]"
        >
          View Details
        </button>
      );
    },
  };
  //   dynamic columns
  const dynamicColumns = columnNames.map((columnName) => {
    let columnConfig = {
      field: columnName,
      headerName: capitalizeCamelCaseString(columnName),
      flex: 1,
      minWidth: 170,
      sortable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "contains"
      ),
    };
    // add column specific props
    switch (columnName) {
      case "startTime":
        columnConfig.renderCell = (params) => {
          return params.value ? formatDateTime(params.value) : "";
        };
        break;
      case "endTime":
        columnConfig.renderCell = (params) => {
          return params.value ? formatDateTime(params.value) : "";
        };
        break;
      case "recommendedRange":
        columnConfig.sortable = false;
        break;
      default:
        break;
    }
    return columnConfig;
  });
  return [...(dynamicColumns || []), staticViewDetailColumn];
};

export const generateParamertersColumns = async (columnNames, isEdit) => {
  //   dynamic columns
  const dynamicColumns = columnNames.map((columnName) => {
    let columnConfig = {
      field: columnName,
      headerName: capitalizeCamelCaseString(columnName),
      flex: 1,
      minWidth: 170,
      sortable: false,
      disableColumnMenu: true,
      editable: false,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "contains"
      ),
    };
    // add column specific props
    switch (columnName) {
      case isEdit && "valueUsedForLastPrediction":
        columnConfig.renderCell = (params) => {
          return <CustomEditCell {...params} />;
        };
        break;
      default:
        break;
    }
    return columnConfig;
  });
  return [...(dynamicColumns || [])];
};
export const generateReportColumns = async (columnNames, handleViewDetails) => {
  const staticViewDetailColumn = {
    field: "view_details",
    headerName: "View Details",
    minWidth: 150,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: (params) => {
      //   const getRowIndex = params.api.getSortedRowIds().indexOf(params.row.id);
      return (
        <button
          onClick={() => handleViewDetails(params)}
          className="text-blue-800 cursor-pointer hover:text-blue-200 font-semibold min-w-[80px]"
        >
          View Details
        </button>
      );
    },
  };
  //   dynamic columns
  const dynamicColumns = columnNames.map((columnName) => {
    let columnConfig = {
      field: columnName,
      headerName: capitalizeCamelCaseString(columnName),
      flex: 1,
      minWidth: 170,
      sortable: true,
      filterable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "contains"
      ),
    };
    // add column specific props
    switch (columnName) {
      case "timeStamp":
        columnConfig.valueFormatter = (params) => {
          const { value } = params;
          return value ? formatDateTime(value) : "";
        };
        break;
      case "predictionOfZn%InSlag":
        columnConfig.sortable = false;
        columnConfig.filterable = false;
        columnConfig.renderCell = (params) => {
          const { value } = params;
          return (
            <div>
              <p>{value?.value}%</p>
              <p className="text-[#938F96]">{`Made at ${
                value?.timeStamp ? formatDateTime(value?.timeStamp) : ""
              }`}</p>
            </div>
          );
        };
        break;
      case "remarks":
        columnConfig.sortable = false;
        columnConfig.filterable = false;
        columnConfig.renderCell = (params) => {
          const { value } = params;
          return (
            <div>
              <p>{value?.issue}</p>
              <p className="text-[#5892E8]">{value?.action}</p>
            </div>
          );
        };
        break;
      default:
        break;
    }
    return columnConfig;
  });
  return [...(dynamicColumns || []), staticViewDetailColumn];
};
