import {
  Select,
  Table,
  Td,
  Tr,
  Thead,
  Tbody,
  TableContainer,
  Th,
  Flex,
  Image,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import { useWindowSize } from "@uidotdev/usehooks";
import Viewdetails from "./Viewdetails";
import Feedback from "./Feedback";
import { useState } from "react";
import UserModals from "./UserModals";

const ReportTable = ({
  tableData,
  stateForStandard,
  order,
  setOrder,
  setHistory,
}) => {
  const size = useWindowSize();
  const [textToEdit, setTextToEdit] = useState("");

  const editDates = (TimeStamp) => {
    const timestamp = TimeStamp * 1000; // Multiply by 1000 to convert from seconds to milliseconds
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };

  return (
    <TableContainer
      className={` ${
        size.width < 768 ? "h-[56vh] mb-[50px]" : "h-[50vh]"
      }  w-[91vw]  `}
    >
      <Table variant="simple">
        <Thead className="bg-[#DDEEFF] !text-xs !sticky !top-0">
          <Tr>
            <Th
              color="#79767D"
              fontWeight={400}
              style={{ width: "50px", textAlign: "left" }}
            >
              SR. NO.
            </Th>
            <Th
              color="#79767D"
              fontWeight={400}
              style={{ width: "100px", textAlign: "center" }}
            >
              SLAB ID
            </Th>
            <Th
              color="#79767D"
              fontWeight={400}
              style={{ width: "200px", textAlign: "center" }}
            >
              SHIFT
            </Th>
            <Th
              color="#79767D"
              fontWeight={400}
              style={{ width: "200px", textAlign: "center" }}
            >
              <div className="flex flex-col w-[150px] gap-2">
                <div>TIME</div>
                <div className="w-full flex justify-center items-center text-[#938F96] text-[16px] h-[25px] ">
                  <select
                    name=""
                    id=""
                    style={{ width: "100%", height: "100%" }}
                    value={order}
                    onChange={(e) => setOrder(e.target.value)}
                  >
                    <option value="desc" className="text-[#938F96]">
                      Latest
                    </option>
                    <option value="asc">Oldest</option>
                  </select>
                </div>
              </div>
            </Th>
            <Th
              color="#79767D"
              fontWeight={400}
              style={{ width: "200px", textAlign: "center" }}
              className=""
            >
              <div className="flex flex-col gap-3">
                <div> Length</div>
                <div className="flex gap-4 w-full justify-between">
                  <p>Act.</p>
                  <p>Std.</p>
                </div>
              </div>
            </Th>
            <Th
              color="#79767D"
              fontWeight={400}
              style={{ width: "200px", textAlign: "center" }}
              className=""
            >
              <div className="flex flex-col gap-3">
                <div> Breadth</div>

                <div className="flex gap-4 w-full justify-between">
                  <p>Act.</p>
                  <p>Std.</p>
                </div>
              </div>
            </Th>
            <Th
              color="#79767D"
              fontWeight={400}
              style={{ width: "200px", textAlign: "center" }}
              className=""
            >
              <div className="flex flex-col gap-3">
                <div> Heigth</div>

                <div className="flex gap-4 w-full justify-between">
                  <p>Act.</p>
                  <p>Std.</p>
                </div>
              </div>
            </Th>
            <Th
              color="#79767D"
              fontWeight={400}
              style={{ width: "300px", textAlign: "center" }}
            >
              VARIANCE
            </Th>
            <Th
              color="#79767D"
              fontWeight={400}
              style={{ width: "100px", textAlign: "center" }}
            >
              PASS / FAIL
            </Th>
            <Th
              color="#79767D"
              fontWeight={400}
              style={{ width: "100px", textAlign: "center" }}
            >
              OPERATOR's COMMENT
            </Th>
            <Th
              color="#79767D"
              fontWeight={400}
              style={{ width: "200px", textAlign: "center" }}
            >
              DETAIL
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {tableData &&
            tableData.length > 0 &&
            tableData.map((item, index) => {
              return (
                <Tr
                  key={index}
                  className="!text-sm !text-[#3E3C42] text-center !font-medium even:bg-[#FAFAFA] odd:bg-white"
                >
                  {/* sl */}
                  <Td style={{ width: "50px", textAlign: "left" }}>
                    {String(item["idx"]).padStart(2, "0")}
                  </Td>
                  {/* slab id */}
                  <Td style={{ width: "100px", textAlign: "center" }}>
                    {item?.slab_id}
                  </Td>
                  {/* shift */}
                  <Td style={{ width: "100px", textAlign: "center" }}>
                    {item?.shift}
                  </Td>
                  {/* timestamp */}
                  <Td style={{ width: "200px", textAlign: "center" }}>
                    {editDates(item?.timestamp - 5.5 * 60 * 60)}
                  </Td>
                  {/* length */}
                  <Td style={{ width: "200px", textAlign: "center" }}>
                    <div className="flex gap-4 justify-between ">
                      <p>
                        {" "}
                        {item?.length == null || item?.length == undefined
                          ? "..."
                          : item?.length?.toFixed(0) + " mm"}
                      </p>
                      <p>
                        {" "}
                        {item?.standard_length == null ||
                        item?.standard_length == undefined
                          ? "..."
                          : item?.standard_length?.toFixed(0) + " mm"}
                      </p>
                    </div>
                  </Td>
                  {/* width */}
                  <Td style={{ width: "200px", textAlign: "center" }}>
                    <div className="flex gap-4 justify-between ">
                      <p>
                        {" "}
                        {item?.breadth == null || item?.breadth == undefined
                          ? "..."
                          : item?.breadth?.toFixed(0) + " mm"}
                      </p>
                      <p>
                        {" "}
                        {item?.standard_breadth == null ||
                        item?.standard_breadth == undefined
                          ? "..."
                          : item?.standard_breadth?.toFixed(0) + " mm"}
                      </p>
                    </div>
                  </Td>
                  {/* height */}
                  <Td style={{ width: "200px", textAlign: "center" }}>
                    <div className="flex gap-4 justify-between ">
                      <p>
                        {" "}
                        {item.depth == null || item.depth == undefined
                          ? "..."
                          : item.depth?.toFixed(0) + " mm"}
                      </p>
                      <p>
                        {" "}
                        {item.standard_depth == null ||
                        item.standard_depth == undefined
                          ? "..."
                          : item.standard_depth?.toFixed(0) + " mm"}
                      </p>
                    </div>
                  </Td>
                  {/* variance */}
                  <Td className="flex gap-2 p-2  w-full !justify-between ">
                    {/* length */}
                    <div className="w-[80px] flex gap-1">
                      <p>L :</p>{" "}
                      {item?.alert?.length != 0 ? (
                        <p className="text-[#E46962]">
                          {item?.alert?.length == null ||
                          item?.alert?.length == undefined
                            ? "..."
                            : item?.alert?.length?.toFixed(0) + " mm"}
                        </p>
                      ) : (
                        <img src="/slabsizing/greentick.svg" alt="" />
                      )}{" "}
                    </div>
                    {/* breadth */}
                    <div className="w-[80px] flex gap-1">
                      <p>B :</p>{" "}
                      {item?.alert?.breadth != 0 ? (
                        <p className="text-[#E46962]">
                          {item?.alert?.breadth == null ||
                          item?.alert?.breadth == undefined
                            ? "..."
                            : item?.alert?.breadth.toFixed(0) + " mm"}
                        </p>
                      ) : (
                        <img src="/slabsizing/greentick.svg" alt="" />
                      )}{" "}
                    </div>
                    {/* height */}
                    <div className="w-[80px] flex gap-1">
                      <p>H :</p>{" "}
                      {item?.alert?.depth != 0 ? (
                        <p className="text-[#E46962]">
                          {item?.alert?.depth == null ||
                          item?.alert?.depth == undefined
                            ? "..."
                            : item?.alert?.depth.toFixed(0) + " mm"}
                        </p>
                      ) : (
                        <img src="/slabsizing/greentick.svg" alt="" />
                      )}{" "}
                    </div>
                  </Td>
                  <Td className=" gap-2  w-[100px]">
                    <div className="w-full text-center uppercase">
                      {item.status == null || item?.status == undefined
                        ? "..."
                        : item?.status}
                    </div>
                  </Td>
                  <UserModals item={item} setHistory={setHistory} />
                  {/* <Td className=" gap-2  w-[100px]">
                    <div className="flex w-full justify-center cursor-pointer gap-2">
                      <Feedback
                        slab={item.slab_id}
                        idx={item._id}
                        comment={item?.operators_comment}
                        setTextToEdit={setTextToEdit}
                      />
                    </div>
                  </Td>

                  <Td className=" gap-2 justify-between w-[200px]">
                    <Viewdetails
                      slab={item.slab_id}
                      idx={item._id}
                      item={item}
                      textToEdit={textToEdit}
                      setTextToEdit={setTextToEdit}
                    />
                  </Td> */}
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ReportTable;
