import React, { useState, useEffect, useContext } from 'react'
import axios from "axios";
import { baseURL } from "../../../index";
import NavContext from "../../NavContext";

import {
    Input,
    Radio,
    RadioGroup,
    useToast,
    Textarea,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    Button,
    Text,
    Checkbox,
    ChakraProvider,
} from "@chakra-ui/react";

import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import TonalButton from "../../../util/Buttons/TonalButton";
import AddIcon from "./Components/AddIcon";
import {
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';


const AddNewProduct = ({ setShowAddNewProductCategoryForm, setShowProductTab }) => {

    const [productGroup, setProductGroup] = useState("")
    const [submitClicked, setSubmitClicked] = useState(false);
    const [productName, setProductName] = useState("");
    const [productParentGroup, setProductParentGroup] = useState();
    const [description, setDescription] = useState("");
    const [useCase, setUseCase] = useState("");
    const [userPersona, setUserPersona] = useState("");
    const [selectedRegulatoryRequirementsCheckbox, setSelectedRegulatoryRequirementsCheckbox] = useState([]);
    const [selectedIndustriesCheckbox, setSelectedIndustriesCheckbox] = useState([]);
    const [otherSelectedIndustriesCheckbox, setOtherSelectedIndustriesCheckbox] = useState("");
    const [selectedClientsCheckbox, setSelectedClientsCheckbox] = useState("");
    const [geographies, setGeographies] = useState("");
    const [useDemo, setUseDemo] = useState("");
    const [prototypeLink, setPrototypeLink] = useState("");
    const [mvpTime, setMvpTime] = useState("");
    //const [developmentResources, setDevelopmentResources] = useState("");
    const [featureList, setFeatureList] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState(null);
    const [searchSuggestionsGroups, setSearchSuggestionsGroups] = useState([]);
    const [searchSuggestionsProducts, setSearchSuggestionsProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [productGroupsList, setProductGroupsList] = useState();
    const [productsList, setProductsList] = useState([]);
    const [cardLogoPreview, setCardLogoPreview] = useState(null);
    const [cardLogo, setCardLogo] = useState(null);
    const [showDiscard, setShowDiscard] = useState(false);
    const [showDraft, setShowDraft] = useState(false);
    const [requirement, setRequirement] = useState([]);
    const [aiModel, setAiModel] = useState([]);
    const [databases, setDatabases] = useState(null);
    const [storage, setStorage] = useState(null);
    const [server, setServer] = useState(null);
    const [cloud, setCloud] = useState([]);
    const [closeLooping, setCloseLooping] = useState(null);
    const [deployment, setDeployment] = useState(null);
    const [dataSize, setDataSize] = useState(null);
    const [videoFps, setVideoFps] = useState(null);
    const [formToBeShown, setFormToBeShown] = useState(1);
    const [productId, setProductId] = useState(null);
    const [icon, setIcon] = useState("Select default icons");
    const [logoList, setLogoList] = useState();
    const [defaultIcon, setDefaultIcon] = useState(null);
    const [firstFormDisable, setFirstFormDisable] = useState(false);
    const [secondFormDisable, setSecondFormDisable] = useState(false);
    const [thirdFormDisable, setThirdFormDisable] = useState(false);
    const [review, setReview] = useState(false);
    // const [activeStep, setActiveStep] = useState("step-0")
    // const [productId, setProductId] = useState(null);


    const { auth, getSubscriptionsNew, getProductGroupsNew } = useContext(NavContext);

    const getProductGroups = async () => {
        //setLoading(true);
        try {
            const response = await axios.get(
                baseURL + "product/v1/fetch/productGroup",
                {
                    headers: {
                        "Content-Type": "application/json",
                        "X-auth-Token": auth,
                    },
                }
            );
            const data = response?.data;
            setProductGroupsList(data.filter((item) => item.status === "ACTIVE"));
            //setLoading(false);
            console.log(data);
        } catch (error) {
            //setLoading(false);
        }
    };

    const getProducts = async () => {
        try {
            const response = await axios.get(
                baseURL + "product/v1/fetch/product",
                {
                    headers: {
                        "Content-Type": "application/json",
                        "X-auth-Token": auth,
                    },
                }
            );
            const data = response?.data;
            setProductsList(data);
        } catch (error) {
        }
    }

    const getLogos = async () => {
        try {
            const response = await axios.get(
                baseURL + "draft/v1/fetch/defaultLogo",
                {
                    headers: {
                        "Content-Type": "application/json",
                        "X-auth-Token": auth,
                    },
                }
            );
            const data = response?.data;
            setLogoList(data.filter((item) => item.defaultLogoType === "PRODUCT"));
        } catch (error) {
        }
    }

    useEffect(() => {
        getProductGroups();
        getProducts();
        getLogos();
    }, []);

    useEffect(() => {
        const delay = 300;
        const timeoutId = setTimeout(() => {
            if (searchTerm) {
                fetchSearchSuggestions(searchTerm);
            } else {
                setSearchSuggestionsGroups([]);
                setSearchSuggestionsProducts([]);
            }
        }, delay);

        return () => clearTimeout(timeoutId);
    }, [searchTerm]);

    const toast = useToast();

    const dataOptions = {
        regulatoryRequirements: {
            type: ["GDPR", "ISO9001", "SOC-1", "SOC-2", "GMP", "Others"],
        },
        industries: {
            type: ["Metal", "Pharmaceutical", "Construction", "Automotive", "Consumer goods", "Energy", "Healthcare", "Aerospace", "Chemical", "Food & Beverage", "Artificial Intelligence", "Software Development", "Glass", "Defence", " Information Technology", "Others",]
        },
        clients: {
            type: ["Current", "Future"]
        },
        demo: {
            type: ["Yes", "No"]
        },
        geographies: {
            type: ["North America", "Asia", "Europe", "Central America", "Africa", "Middle East", "Caribbean", "Oceania"]
        },
        mvp: {
            type: ["<1 Week", "1 Week", "2 Weeks", "1 Month", "2 Months", "1 Quarter", "2 Quarters", "1 Year", "1+ Year"]
        },
        userPersona: {
            type: ["CXO", "Senior Director", "Associate Director", "Senior Vice President", "Director", "Associate Vice President", "Vice President", "Manager", "Senior Manager", "Plant Head", "Shift Manager", "Plant Operator", "Business Analyst", "Consultant", "Corporate Staff", "IT Analyst", "IT Developer", "IT Manager", "Plant Manager", "Mill Operator", "Automation Team", "Automation Staff", "Equipment Operator", "Device Operator", "Process Engineer", "Other"]
        },
        requirements: {
            type: ["Frontend", "Backend", "Data Engineer"]
        },
        aiModel: {
            type: ["Existing AI model", "Custom AI model", "New Custom AI", "Cloud AI models"]
        },
        databases: {
            type: ["Mongo DB", "Dynamo DB", "PostgreSQL", "SQL"]
        },
        storage: {
            type: ["AWS S3", "AWS Glacier", "AWS EBS", "Azure Blob storage", "Google Standard storage"],
        },
        server: {
            type: ["On-prem", "Cloud", "Hybrid"]
        },
        cloud: {
            type: ["Azure", "AWS", "GCP", "Oracle", "Others", "Not Applicable"]
        },
        closeLooping: {
            type: ["Possible", "Required", "Not Applicable"]
        },
        deployment: {
            type: ["On-prem", "Cloud", "Hybrid"]
        },
        icon: {
            type: ["Select default icons", "Upload your own icon"]
        },
        imageIcon: {
            type: ["image 123", "image 124", "image 125", "image 126", "image 127", "image 128", "image 129", "image 130", "image 131", "image 132", "image 133", "image 134"]
        }
    };

    const fetchSearchSuggestions = (term) => {
        const groups = productGroupsList?.filter((item) => item.productGroupName.toLowerCase().includes(term.trim().toLowerCase()));
        const products = productsList?.filter((item) => item.productName.toLowerCase().includes(term.trim().toLowerCase()));
        setSearchSuggestionsGroups(groups);
        setSearchSuggestionsProducts(products);
    };

    const handleSearchTermChange = (e) => {
        const term = e.target.value;
        setSearchTerm(term);
    };

    const handleParentProductDropdownChange = (value) => {
        setShowDiscard(true);
        setProductGroup(value);

        for (let x of productGroupsList) {
            if (x.productGroupName === value) {
                console.log("Yaaa");
                console.log(x.productGroupParent);
                setProductParentGroup(x.productGroupParent);
            }
        }

    }

    const handleRegulatoryRequirementsCheckboxChange = (value) => {
        setShowDiscard(true);
        const updatedReqs = [...selectedRegulatoryRequirementsCheckbox];
        if (updatedReqs.includes(value)) {
            const index = updatedReqs.indexOf(value);
            updatedReqs?.splice(index, 1);
            setSelectedRegulatoryRequirementsCheckbox(updatedReqs);
        }
        else {
            updatedReqs.push(value);
            setSelectedRegulatoryRequirementsCheckbox(updatedReqs);
        }
    };

    const handleIndustriesCheckboxChange = (value) => {
        setShowDiscard(true);
        const updatedIndustries = [...selectedIndustriesCheckbox];
        if (updatedIndustries.includes(value)) {
            const index = updatedIndustries.indexOf(value);
            updatedIndustries?.splice(index, 1);
            setSelectedIndustriesCheckbox(updatedIndustries);
        }
        else {
            updatedIndustries.push(value);
            setSelectedIndustriesCheckbox(updatedIndustries);
        }
    }

    const handleRequirementsCheckboxChange = (value) => {
        setShowDiscard(true);
        const updatedReqs = [...requirement];
        if (updatedReqs.includes(value)) {
            const index = updatedReqs.indexOf(value);
            updatedReqs?.splice(index, 1);
            setRequirement(updatedReqs);
        }
        else {
            updatedReqs.push(value);
            setRequirement(updatedReqs);
        }
    }

    const handleAiCheckboxChange = (value) => {
        setShowDiscard(true);
        const updatedai = [...aiModel];
        if (updatedai.includes(value)) {
            const index = updatedai.indexOf(value);
            updatedai?.splice(index, 1);
            setAiModel(updatedai);
        }
        else {
            updatedai.push(value);
            setAiModel(updatedai);
        }
    }

    const handleCloudCheckboxChange = (value) => {
        setShowDiscard(true);
        const updatedCloud = [...cloud];
        if (updatedCloud?.includes(value)) {
            const index = cloud.indexOf(value);
            updatedCloud?.splice(index, 1);
            setCloud(updatedCloud);
        }
        else {
            updatedCloud.push(value);
            setCloud(updatedCloud);
        }
    }

    const handleClientsCheckboxChange = (value) => {
        setShowDiscard(true);
        setSelectedClientsCheckbox(value === selectedClientsCheckbox ? null : value);
    }

    const handleMvpDropdownChange = (event) => {
        setShowDiscard(true);
        setMvpTime(event.target.value);
    }

    const handleConfirmDraftDiscard = () => {
        setShowDraft(false);
        setShowProductTab((prev) => "products");
    }

    const handleBackButton = () => {

        if (showDiscard) {
            setShowDraft(true);
            return;
        }
        setShowProductTab((prev) => "products");
    };

    useEffect(() => {
        const elem = document.getElementById("step" + formToBeShown);
        elem.scrollIntoView({
            behavior: "smooth", // You can use 'auto' instead of 'smooth' for instant scrolling
            block: "start", // You can use 'center' or 'end' instead of 'start'
        });
    }, [formToBeShown])

    const handleUserPersonaDropdownChange = (event) => {
        setShowDiscard(true);
        setUserPersona(event.target.value);
    }

    const handleSubmit = (status) => {

        setSubmitClicked(true);
        if (!productGroup || !productName || productName?.length > 50 || description?.length > 1000) {
            toast({
                title: "Incomplete Details",
                description: "Please fill in all required details.",
                status: "error",
                duration: 4000,
                isClosable: true,
                position: "top",
            });
            return;
        }

        let flag = false;
        for (let x of productsList) {
            if (x.productName.toLowerCase() === productName.trim().toLowerCase()) {
                flag = true;
                break;
            }
        }

        if (flag) {
            toast({
                title: "",
                description: "This product already exists. Please add a new one.",
                status: "error",
                duration: 4000,
                isClosable: true,
                position: "top",
            });
            return;
        }

        let productGroupId = "";

        for (let x of productGroupsList) {
            if (x.productGroupName == productGroup) {
                productGroupId = x.productIdUuid;
            }
        }

        let data1 = {
            productName: productName?.trim(),
            productDescription: description?.trim(),
            productGroupId: productGroupId,
            productCategory: productParentGroup,
            status: status,
            isdeleted: false,
            isDraft: true,

        }
        if (icon === "Select default icons") data1.productLogoUrl = defaultIcon;
        if (productId) data1.productId = productId;

        const json = JSON.stringify(data1);
        const blob = new Blob([json], {
            type: "application/json",
        });
        const FormData = require("form-data");
        let data = new FormData();
        data.append("data", blob);
        if (icon === "Upload your own icon") data.append("file", cardLogo)
        console.log(cardLogo);

        let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: baseURL + "product/v1/addProduct",
            headers: {
                "X-Auth-Token": auth,
            },
            data: data,
        };
        axios
            .request(config)
            .then((response) => {
                setShowDiscard(false);
                setFormToBeShown(2);
                toast({
                    title: `Product details saved successfully`,
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                    position: "top",
                });

                console.log(response);
                console.log(response.data.productId);
                setFirstFormDisable(true);
                setProductId(response.data.productId);
            })
            .catch((error) => {
                console.log(error);
                toast({
                    title: "Something went wrong",
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                    position: "top",
                });
            });

    };

    const handleSubmit2 = (status) => {

        setSubmitClicked(true);

        if (!productGroup || !productName || productName?.length > 50 || description?.length > 1000 || useCase?.length > 50) {
            toast({
                title: "Incomplete Details",
                description: "Please fill in all required details.",
                status: "error",
                duration: 4000,
                isClosable: true,
                position: "top",
            });
            return;
        }

        let productGroupId = "";

        for (let x of productGroupsList) {
            if (x.productGroupName == productGroup) {
                productGroupId = x.productIdUuid;
            }
        }

        const industries = selectedIndustriesCheckbox?.filter((item) => item != "Others");
        if (otherSelectedIndustriesCheckbox) industries.push(otherSelectedIndustriesCheckbox);

        const data1 = {
            productName: productName?.trim(),
            productDescription: description?.trim(),
            productGroupId: productGroupId,
            usecaseType: useCase?.trim(),
            productCategory: productParentGroup,
            regulatoryRequirement: selectedRegulatoryRequirementsCheckbox?.join(","),
            userPersona: userPersona,
            prospectiveIndustries: industries?.join(","),
            prospectiveClient: selectedClientsCheckbox,
            status: status,
            isdeleted: false,
            isDraft: true,
            productId: productId
        }

        console.log(data1);

        //console.log(data1);


        const json = JSON.stringify(data1);
        const blob = new Blob([json], {
            type: "application/json",
        });
        const FormData = require("form-data");
        let data = new FormData();
        data.append("data", blob);

        let config = {
            method: "patch",
            maxBodyLength: Infinity,
            url: baseURL + "product/v1/update/product",
            headers: {
                "X-Auth-Token": auth,
            },
            data: data,
        };
        axios
            .request(config)
            .then((response) => {
                setShowDiscard(false);
                setFormToBeShown(3);
                setSecondFormDisable(true);
                toast({
                    title: `Use Case details saved successfully`,
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                    position: "top",
                });
            })
            .catch((error) => {
                console.log(error);
                toast({
                    title: "Something went wrong",
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                    position: "top",
                });
            });

    };

    const handleSubmit3 = (status) => {

        setSubmitClicked(true);

        if (!productGroup || !productName || productName?.length > 50 || description?.length > 1000 || useCase?.length > 50 || dataSize?.length > 50 || videoFps?.length > 50 || featureList?.length > 350) {
            toast({
                title: "Please fill in all the required details.",
                status: "error",
                duration: 4000,
                isClosable: true,
                position: "top",
            });
            return;
        }

        let productGroupId = "";

        for (let x of productGroupsList) {
            if (x.productGroupName == productGroup) {
                productGroupId = x.productIdUuid;
            }
        }

        const industries = selectedIndustriesCheckbox?.filter((item) => item != "Others");
        if (otherSelectedIndustriesCheckbox) industries.push(otherSelectedIndustriesCheckbox);
        console.log(industries);

        const data1 = {
            productName: productName?.trim(),
            productDescription: description?.trim(),
            productGroupId: productGroupId,
            usecaseType: useCase?.trim(),
            productCategory: productParentGroup,
            regulatoryRequirement: selectedRegulatoryRequirementsCheckbox?.join(","),
            userPersona: userPersona,
            prospectiveIndustries: industries?.join(","),
            prospectiveClient: selectedClientsCheckbox,
            demoLink: prototypeLink,
            mvpTime: mvpTime,
            status: status,
            isdeleted: false,
            featureList: featureList?.trim(),
            requirement: requirement?.join(","),
            aiModel: aiModel?.join(","),
            databases: databases,
            storage: storage,
            server: server,
            cloud: cloud?.join(","),
            closeLooping: closeLooping,
            deployment: deployment,
            dataSize: dataSize?.trim(),
            videoFps: videoFps?.trim(),
            isDraft: false,
            productId: productId
        }

        const json = JSON.stringify(data1);
        const blob = new Blob([json], {
            type: "application/json",
        });
        const FormData = require("form-data");
        let data = new FormData();
        data.append("data", blob);

        let config = {
            method: "patch",
            maxBodyLength: Infinity,
            url: baseURL + "product/v1/update/product",
            headers: {
                "X-Auth-Token": auth,
            },
            data: data,
        };
        axios
            .request(config)
            .then((response) => {
                setShowDiscard(false);
                if (status === 1) setThirdFormDisable(true);
                if (status === 1) {
                    getSubscriptionsNew();
                    getProductGroupsNew();
                }
                toast({
                    title: `${productName} Added successfully`,
                    status: "success",
                    duration: 4000,
                    isClosable: true,
                    position: "top",
                });

                if (status === 1) {
                    setTimeout(() => {
                        setShowProductTab("products");
                    }, 1500);
                }
            })
            .catch((error) => {
                console.log(error);
                toast({
                    title: "Something went wrong",
                    status: "error",
                    duration: 4000,
                    isClosable: true,
                    position: "top",
                });
            });

    };

    return (
        <div className="font-roboto flex flex-col gap-2 mt-0">

            <div className="flex items-center">
                <div className="cursor-pointer w-8" onClick={handleBackButton}>
                    <KeyboardBackspaceIcon sx={{ color: "#084298" }} />
                </div>
                <p className="text-[#084298] font-medium text-xl ml-2">
                    Add new product
                </p>
            </div>

            <div className="font-roboto flex flex-col gap-2 p-4 pt-2 m-2 bg-white rounded-lg border" id="step1">

                <p className='text-[#3E3C42] text-xl font-medium font-semibold'>Product Details</p>

                <div className='flex flex-row gap-3'>
                    <div className="w-[50%] flex flex-col gap-3 mt-6">
                        <p className="text-[#3E3C42] text-lg font-medium">
                            Select Product group<span className="text-red-500">*</span>
                        </p>
                        <div className="w-full lg:w-[400px]">

                            <Menu>
                                <MenuButton isDisabled={firstFormDisable} as={Button} rightIcon={<ChevronDownIcon />} className='w-full lg:w-[400px] font-normal'>
                                    {productGroup ? productGroup : "Select one"}
                                </MenuButton>
                                <MenuList className='w-full lg:w-[400px] font-normal max-h-[400px] overflow-y-auto'>
                                    {productGroupsList?.map((item, index) => (
                                        <MenuItem onClick={() => { handleParentProductDropdownChange(item.productGroupName); }}>
                                            {item.productGroupUrl ? <img className='h-[20px] w-[20px]' src={item.productGroupUrl} alt="Hello" /> : <img className='h-[20px] w-[20px]' src="/ProductGroupDefaultIcon.svg" alt="Hello" />}
                                            {item.productGroupName}
                                        </MenuItem>
                                    ))}
                                    {/* <MenuItem>Download</MenuItem>
                                    <MenuItem>Create a Copy</MenuItem>
                                    <MenuItem>Mark as Draft</MenuItem>
                                    <MenuItem>Delete</MenuItem>
                                    <MenuItem>Attend a Workshop</MenuItem> */}
                                </MenuList>
                            </Menu>
                            {submitClicked && !productGroup && (
                                <Text color="red.500" fontSize="sm" mt="1">
                                    Please select product group
                                </Text>
                            )}
                        </div>
                    </div>
                    <div className="w-[50%] flex flex-col gap-3 mt-6">
                        <p className="text-[#3E3C42] text-lg font-medium">
                            Product parent group
                        </p>
                        <div className="w-full lg:w-[400px]">
                            <Input
                                type="text"
                                value={productParentGroup}
                                placeholder={productParentGroup}
                                required
                                borderColor={
                                    submitClicked && !productParentGroup ? "red.500" : "gray.300"
                                }
                                disabled={true}
                                borderWidth={"2px"}
                            />
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-3 mt-6">
                    <p className="text-[#3E3C42] text-lg font-medium">
                        Product name<span className="text-red-500">*</span>
                    </p>
                    <div className="md:w-[400px]">
                        <Input
                            type="text"
                            value={productName}
                            placeholder="Enter product name"
                            required
                            borderColor={
                                submitClicked && !productName ? "red.500" : "gray.300"
                            }
                            disabled={firstFormDisable}
                            borderWidth={"2px"}
                            onChange={(e) => { setProductName(e.target.value); handleSearchTermChange(e); setShowDiscard(true); }}
                        />

                        {(searchSuggestionsProducts.length > 0) && (

                            <div >
                                <div className="search-suggestions rounded-md mt-2 w-[400px]">
                                    <p className="text-red-500">Similar product(s) already exist. Please check if your product is duplicate.</p>
                                </div>
                                <div className="search-suggestions border bg-white rounded-md shadow-lg shadow-gray-200 mt-2 w-[500px] z-10">
                                    <div className="p-2">
                                        <p className="text-gray-400">
                                            Products
                                        </p>
                                        <div className="grid grid-cols-2 gap-2" >
                                            {searchSuggestionsProducts.map((item) => (
                                                <div className="flex flex-row" >
                                                    <img className="w-[30px] h-[30px]" src="/superadmin/toolsGray.svg"></img>
                                                    <p className="font-medium">{item.productName}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        )}

                        {submitClicked && !productName && (
                            <Text color="red.500" fontSize="sm" mt="1">
                                Please enter the product name
                            </Text>
                        )}

                        {
                            productName?.length > 50 && <div>
                                <Text color="red.500" fontSize="sm" mt="1">
                                    You have exceed the character limit of 50 by {productName.length - 50}
                                </Text>
                            </div>
                        }
                    </div>
                </div>

                <div className="flex items-center gap-4 mt-6">
                    <div>
                        <p className="text-[#3E3C42] text-lg font-medium">
                            Description
                        </p>
                        <div className="mt-6 md:w-[400px]">
                            <Textarea
                                placeholder="A brief description of the product group"
                                borderWidth={"2px"}
                                value={description}
                                disabled={firstFormDisable}
                                onChange={(e) => { setDescription(e.target.value); setShowDiscard(true); }}
                            />
                            {
                                description?.length > 1000 && <div>
                                    <Text color="red.500" fontSize="sm" mt="1">
                                        You have exceed the character limit of 1000 by {description.length - 1000}
                                    </Text>
                                </div>
                            }
                        </div>
                    </div>
                </div>

                <div className="flex flex-col gap-3 mt-6" >
                    <p className="text-[#3E3C42] text-lg font-medium">
                        Select logo for the product
                    </p>

                    <div className="mt-6">
                        <RadioGroup
                            onChange={(e) => { setIcon(e); setShowDiscard(true); }}
                            value={icon}
                            isDisabled={firstFormDisable}
                        >
                            <div
                                className="flex flex-col md:grid md:grid-cols-3 gap-1 md:gap-2"
                                style={{ width: "fit-content" }}
                            >
                                {dataOptions.icon.type.map((x) => {
                                    return (
                                        <div
                                            style={{
                                                backgroundColor:
                                                    icon == x ? "#DDEEFF80" : "#FFF",
                                                borderRadius: "8px",
                                                mb: "12px",
                                                // border
                                            }}
                                        >
                                            <Radio
                                                value={x}
                                                py={"8px"}
                                                pl={"8px"}
                                                pr={"12px"}
                                                fontSize={"14px"}
                                                fontWeight={500}
                                                color={"#3E3C42"}
                                                _checked={{
                                                    bg: "#6CA6FC",
                                                    borderColor: "#6CA6FC",
                                                }}
                                                _hover={{
                                                    borderColor: "#6CA6FC",
                                                }}
                                                borderColor={
                                                    // submitClicked && !useDemo
                                                    //     ? "red.500" :
                                                    "gray.300"
                                                    //  {/*submitClicked &&*/}
                                                }
                                                isDisabled={firstFormDisable}
                                            >
                                                {x === "Select default icons" ? "Select a pre-loaded logo" : "Upload your own logo"}
                                            </Radio>
                                        </div>
                                    );
                                })}
                            </div>
                        </RadioGroup>
                    </div>

                    {icon === "Select default icons" && <div className="w-full lg:w-[400px]">

                        <Menu>
                            <MenuButton isDisabled={firstFormDisable} as={Button} rightIcon={<ChevronDownIcon />} className='w-full lg:w-[220px] font-normal bg-none'>
                                {defaultIcon ? <img className='h-[20px] w-[20px]' src={defaultIcon} alt="image" /> : "Select one"}
                            </MenuButton>
                            <MenuList className='w-full lg:w-[400px] grid grid-cols-4 font-normal max-h-[250px] overflow-y-auto'>
                                {logoList?.map((item, index) => (
                                    <MenuItem onClick={() => { setDefaultIcon(item.defaultLogoUrl); setCardLogo(null); setShowDiscard(true); }}>
                                        {<img className='h-[40px] w-[40px]' src={item.defaultLogoUrl} alt="image" />}
                                        {/* {item} */}
                                    </MenuItem>
                                ))}
                            </MenuList>
                        </Menu>

                    </div>}

                    {icon === "Upload your own icon" && <AddIcon
                        logo={cardLogo}
                        setLogo={setCardLogo}
                        toast={toast}
                        logoPreview={cardLogoPreview}
                        setLogoPreview={setCardLogoPreview}
                        submitClicked={submitClicked}
                        text={"Supported formats: .png, .svg, .jpeg"}
                        info={"2"}
                        labelText={"Upload icon for the product group"}
                        setShowDiscard={setShowDiscard}
                    />}
                </div>

                <div className="flex gap-[10px] items-center ml-6 md:ml-0 mt-6 mb-20 md:mb-0">

                    {firstFormDisable ? <TonalButton
                        text={"Back to Editing"}
                        width={"130px"}
                        // disable={""}
                        onClick={() => setFirstFormDisable(false)}
                    // disable={firstFormDisable}
                    /> :
                        <PrimaryButton
                            text={"Save"}
                            width={"130px"}
                            // disable={""}
                            onClick={() => handleSubmit(0)}
                        // disable={firstFormDisable}
                        />}

                </div>
            </div>

            {formToBeShown >= 2 &&
                <div className="font-roboto flex flex-col gap-2 p-4 pt-2 m-2 bg-white rounded-lg border" id="step2">

                    <p className='text-[#3E3C42] text-xl font-medium font-semibold'>Use Case</p>
                    <div className="flex flex-col gap-3 mt-6">
                        <p className="text-[#3E3C42] text-lg font-medium">
                            Usecase type
                        </p>
                        <div className="md:w-[400px]">
                            <Input
                                type="text"
                                value={useCase}
                                placeholder="ex. Raw material quality assurance"
                                required
                                borderColor={
                                    // submitClicked && !useCase ? "red.500" : 
                                    "gray.300"
                                }
                                disabled={secondFormDisable}
                                borderWidth={"2px"}
                                onChange={(e) => { setUseCase(e.target.value); setShowDiscard(true); }}
                            />

                            {/* {submitClicked && !useCase && (
                            <Text color="red.500" fontSize="sm" mt="1">
                                Please enter the Usecase type
                            </Text>
                        )} */}

                            {
                                useCase?.length > 50 && <div>
                                    <Text color="red.500" fontSize="sm" mt="1">
                                        You have exceed the character limit of 50 by {useCase.length - 50}
                                    </Text>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="flex flex-col gap-3 mt-6">
                        <p className="text-[#3E3C42] text-lg font-medium">
                            Regulatory requirements
                        </p>

                        <ChakraProvider >
                            {/* <VStack spacing={4}> */}
                            <div className='flex flex-row'>

                                {dataOptions.regulatoryRequirements.type.map((x, index) => {
                                    return (
                                        <div className='py-[8px] pl-[8px] pr-[12px]'>
                                            <Checkbox
                                                disabled={secondFormDisable}
                                                isChecked={selectedRegulatoryRequirementsCheckbox?.includes(x)}
                                                onChange={() => handleRegulatoryRequirementsCheckboxChange(x)}
                                            >
                                                {x}
                                            </Checkbox>
                                        </div>
                                    )
                                })}
                            </div>
                        </ChakraProvider>

                    </div>

                    <div className="flex flex-col gap-3 mt-6">
                        <p className="text-[#3E3C42] text-lg font-medium">
                            Prospective industries
                        </p>

                        <ChakraProvider >
                            <div className='flex flex-row items-start flex-wrap'>

                                {dataOptions.industries.type.map((x, index) => {
                                    return (
                                        <div className='py-[8px] pl-[8px] pr-[12px]'>
                                            <Checkbox
                                                disabled={secondFormDisable}
                                                isChecked={selectedIndustriesCheckbox?.includes(x)}
                                                onChange={() => handleIndustriesCheckboxChange(x)}
                                            >
                                                {x}
                                            </Checkbox>
                                        </div>
                                    )
                                })}
                            </div>
                        </ChakraProvider>

                        {selectedIndustriesCheckbox?.includes("Others") && <div className="md:w-[400px]">
                            <Input
                                type="text"
                                value={otherSelectedIndustriesCheckbox}
                                required
                                borderColor={
                                    submitClicked && !otherSelectedIndustriesCheckbox ? "red.500" : "gray.300"
                                }
                                disabled={secondFormDisable}
                                borderWidth={"2px"}
                                onChange={(e) => { setOtherSelectedIndustriesCheckbox(e.target.value); }}
                            />
                        </div>}




                    </div>

                    <div className="flex flex-col gap-3 mt-6">
                        <p className="text-[#3E3C42] text-lg font-medium">
                            Prospective clients
                        </p>

                        <RadioGroup
                            onChange={(e) => { handleClientsCheckboxChange(e) }}
                            value={selectedClientsCheckbox}
                            isDisabled={secondFormDisable}
                        >
                            <div
                                className="flex flex-col md:grid md:grid-cols-3 gap-1 md:gap-2"
                                style={{ width: "fit-content" }}
                            >
                                {dataOptions.clients.type.map((x) => {
                                    return (
                                        <div
                                            style={{
                                                backgroundColor:
                                                    selectedClientsCheckbox == x ? "#DDEEFF80" : "#FFF",
                                                borderRadius: "8px",
                                                mb: "12px",
                                                // border
                                            }}
                                        >
                                            <Radio
                                                value={x}
                                                py={"8px"}
                                                pl={"8px"}
                                                pr={"12px"}
                                                fontSize={"14px"}
                                                fontWeight={500}
                                                color={"#3E3C42"}
                                                _checked={{
                                                    bg: "#6CA6FC",
                                                    borderColor: "#6CA6FC",
                                                }}
                                                _hover={{
                                                    borderColor: "#6CA6FC",
                                                }}
                                                borderColor={
                                                    // submitClicked && !useDemo
                                                    //     ? "red.500" :
                                                    "gray.300"
                                                    //  {/*submitClicked &&*/}
                                                }
                                                isDisabled={secondFormDisable}
                                            >
                                                {x}
                                            </Radio>
                                        </div>
                                    );
                                })}
                            </div>
                        </RadioGroup>

                    </div>

                    <div className="flex flex-col gap-3 mt-6">
                        <p className="text-[#3E3C42] text-lg font-medium">
                            User persona
                        </p>
                        <div className="md:w-[400px]">

                            <select disabled={secondFormDisable} value={userPersona} onChange={handleUserPersonaDropdownChange} borderColor={
                                // submitClicked && !mvpTime ? "red.500" : 
                                "gray.300"
                            } className='md:w-[400px] p-2 border-[2px] rounded-lg max-h-[300px] overflow-y-auto'>
                                <option value="" disabled selected>Select one</option>
                                {
                                    dataOptions.userPersona.type.map((value) => (
                                        <option value={value}>{value}</option>
                                    ))
                                }
                            </select>

                            {
                                userPersona?.length > 50 && <div>
                                    <Text color="red.500" fontSize="sm" mt="1">
                                        You have exceed the character limit of 50 by {userPersona.length - 50}
                                    </Text>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="flex gap-[10px] items-center ml-6 md:ml-0 mt-6 mb-20 md:mb-0">
                        {secondFormDisable ? <TonalButton
                            text={"Back to Editing"}
                            width={"130px"}
                            // disable={""}
                            onClick={() => setSecondFormDisable(false)}
                        // disable={firstFormDisable}
                        /> :
                            <PrimaryButton
                                text={"Save"}
                                width={"130px"}
                                // disable={""}
                                onClick={() => handleSubmit2(0)}
                            // disable={firstFormDisable}
                            />}
                    </div>
                </div>}

            {formToBeShown >= 3 &&

                <div className="font-roboto flex flex-col gap-2 p-4 pt-2 m-2 bg-white rounded-lg border" id="step3">

                    <p className='text-[#3E3C42] text-xl font-medium font-semibold' >Technology</p>
                    <div className="flex flex-col gap-3 mt-6">
                        <p className="text-[#3E3C42] text-lg font-medium">
                            Existing demo/ Prototype link
                        </p>

                        <RadioGroup
                            onChange={(e) => { setUseDemo(e); setShowDiscard(true); }}
                            value={useDemo}
                            isDisabled={thirdFormDisable}
                        >
                            <div
                                className="flex flex-col md:grid md:grid-cols-3 gap-1 md:gap-2"
                                style={{ width: "fit-content" }}
                            >
                                {dataOptions.demo.type.map((x) => {
                                    return (
                                        <div
                                            style={{
                                                backgroundColor:
                                                    useDemo == x ? "#DDEEFF80" : "#FFF",
                                                borderRadius: "8px",
                                                mb: "12px",
                                                // border
                                            }}
                                        >
                                            <Radio
                                                value={x}
                                                py={"8px"}
                                                pl={"8px"}
                                                pr={"12px"}
                                                fontSize={"14px"}
                                                fontWeight={500}
                                                color={"#3E3C42"}
                                                _checked={{
                                                    bg: "#6CA6FC",
                                                    borderColor: "#6CA6FC",
                                                }}
                                                _hover={{
                                                    borderColor: "#6CA6FC",
                                                }}
                                                borderColor={
                                                    // submitClicked && !useDemo
                                                    //     ? "red.500" :
                                                    "gray.300"
                                                    //  {/*submitClicked &&*/}
                                                }
                                                isDisabled={thirdFormDisable}
                                            >
                                                {x}
                                            </Radio>
                                        </div>
                                    );
                                })}
                            </div>
                        </RadioGroup>

                        {useDemo == "Yes" && <div className="md:w-[400px]">
                            {/* {console.log(useDemo + "heheheheh")} */}
                            <Input
                                disabled={thirdFormDisable}
                                type="text"
                                value={prototypeLink}
                                placeholder="Prototype Link"
                                //required
                                borderColor={
                                    submitClicked && !prototypeLink ? "red.500" : "gray.300"
                                }
                                borderWidth={"2px"}
                                onChange={(e) => { setPrototypeLink(e.target.value); setShowDiscard(true); }}
                            />
                        </div>}

                    </div>

                    <div className="flex flex-col gap-3 mt-6">
                        <p className="text-[#3E3C42] text-lg font-medium">
                            Time for MVP/full product (estimate)
                        </p>
                        <div className="md:w-[400px]">
                            <select value={mvpTime} disabled={thirdFormDisable} onChange={handleMvpDropdownChange} borderColor={
                                // submitClicked && !mvpTime ? "red.500" : 
                                "gray.300"
                            } className='md:w-[400px] p-2 border-[2px] rounded-lg max-h-[300px] overflow-y-auto'>
                                <option value="" disabled selected>Select one</option>
                                {
                                    dataOptions.mvp.type.map((value) => (
                                        <option value={value}>{value}</option>
                                    ))
                                }
                            </select>
                        </div>

                        {/* {submitClicked && !mvpTime && (
                        <Text color="red.500" fontSize="sm" mt="1">
                            Please enter the Time estimate
                        </Text>
                    )} */}
                    </div>

                    <div className="flex flex-col gap-3 mt-6">
                        <p className="text-[#3E3C42] text-lg font-medium">
                            Requirements
                        </p>

                        <ChakraProvider >
                            <div className='flex flex-row items-start'>

                                {dataOptions.requirements.type.map((x, index) => {
                                    return (
                                        <div className='py-[8px] pl-[8px] pr-[12px]'>
                                            <Checkbox
                                                isChecked={requirement?.includes(x)}
                                                disabled={thirdFormDisable}
                                                onChange={() => handleRequirementsCheckboxChange(x)}
                                            >
                                                {x}
                                            </Checkbox>
                                        </div>
                                    )
                                })}
                            </div>
                        </ChakraProvider>

                    </div>

                    <div className="flex flex-col gap-3 mt-6">
                        <p className="text-[#3E3C42] text-lg font-medium">
                            AI model
                        </p>

                        <ChakraProvider >
                            <div className='flex flex-row items-start'>

                                {dataOptions.aiModel.type.map((x, index) => {
                                    return (
                                        <div className='py-[8px] pl-[8px] pr-[12px]'>
                                            <Checkbox
                                                disabled={thirdFormDisable}
                                                isChecked={aiModel?.includes(x)}
                                                onChange={() => {
                                                    handleAiCheckboxChange(x);
                                                }}
                                            >
                                                {x}
                                            </Checkbox>
                                        </div>
                                    )
                                })}
                            </div>
                        </ChakraProvider>

                    </div>

                    <div className="flex flex-col gap-3 mt-6">
                        <p className="text-[#3E3C42] text-lg font-medium">
                            Databases
                        </p>

                        <RadioGroup
                            onChange={(e) => { setShowDiscard(true); setDatabases(e === databases ? null : e); }}
                            value={databases}
                            isDisabled={thirdFormDisable}
                        >
                            <div
                                className="flex flex-col md:grid md:grid-cols-3 gap-1 md:gap-2"
                                style={{ width: "fit-content" }}
                            >
                                {dataOptions.databases.type.map((x) => {
                                    return (
                                        <div
                                            style={{
                                                backgroundColor:
                                                    databases == x ? "#DDEEFF80" : "#FFF",
                                                borderRadius: "8px",
                                                mb: "12px",
                                                // border
                                            }}
                                        >
                                            <Radio
                                                value={x}
                                                py={"8px"}
                                                pl={"8px"}
                                                pr={"12px"}
                                                fontSize={"14px"}
                                                fontWeight={500}
                                                color={"#3E3C42"}
                                                _checked={{
                                                    bg: "#6CA6FC",
                                                    borderColor: "#6CA6FC",
                                                }}
                                                _hover={{
                                                    borderColor: "#6CA6FC",
                                                }}
                                                borderColor={
                                                    // submitClicked && !useDemo
                                                    //     ? "red.500" :
                                                    "gray.300"
                                                    //  {/*submitClicked &&*/}
                                                }
                                                isDisabled={thirdFormDisable}
                                            >
                                                {x}
                                            </Radio>
                                        </div>
                                    );
                                })}
                            </div>
                        </RadioGroup>

                    </div>

                    <div className="flex flex-col gap-3 mt-6">
                        <p className="text-[#3E3C42] text-lg font-medium">
                            Storage
                        </p>

                        <RadioGroup
                            onChange={(e) => { setShowDiscard(true); setStorage(e === storage ? null : e); }}
                            value={storage}
                            isDisabled={thirdFormDisable}
                        >
                            <div
                                className="flex flex-col md:grid md:grid-cols-3 gap-1 md:gap-2"
                                style={{ width: "fit-content" }}
                            >
                                {dataOptions.storage.type.map((x) => {
                                    return (
                                        <div
                                            style={{
                                                backgroundColor:
                                                    storage == x ? "#DDEEFF80" : "#FFF",
                                                borderRadius: "8px",
                                                mb: "12px",
                                                // border
                                            }}
                                        >
                                            <Radio
                                                value={x}
                                                py={"8px"}
                                                pl={"8px"}
                                                pr={"12px"}
                                                fontSize={"14px"}
                                                fontWeight={500}
                                                color={"#3E3C42"}
                                                _checked={{
                                                    bg: "#6CA6FC",
                                                    borderColor: "#6CA6FC",
                                                }}
                                                _hover={{
                                                    borderColor: "#6CA6FC",
                                                }}
                                                borderColor={
                                                    // submitClicked && !useDemo
                                                    //     ? "red.500" :
                                                    "gray.300"
                                                    //  {/*submitClicked &&*/}
                                                }
                                                isDisabled={thirdFormDisable}
                                            >
                                                {x}
                                            </Radio>
                                        </div>
                                    );
                                })}
                            </div>
                        </RadioGroup>

                    </div>

                    <div className="flex flex-col gap-3 mt-6">
                        <p className="text-[#3E3C42] text-lg font-medium">
                            Server
                        </p>

                        <RadioGroup
                            isDisabled={thirdFormDisable}
                            onChange={(e) => { setServer(e); setShowDiscard(true); }}
                            value={server}
                        >
                            <div
                                className="flex flex-col md:grid md:grid-cols-4 gap-1 md:gap-2"
                                style={{ width: "fit-content" }}
                            >
                                {dataOptions.server.type.map((x) => {
                                    return (
                                        <div
                                            style={{
                                                backgroundColor:
                                                    server == x ? "#DDEEFF80" : "#FFF",
                                                borderRadius: "8px",
                                                mb: "12px",
                                                // border
                                            }}
                                        >
                                            <Radio
                                                value={x}
                                                py={"8px"}
                                                pl={"8px"}
                                                pr={"12px"}
                                                fontSize={"14px"}
                                                fontWeight={500}
                                                color={"#3E3C42"}
                                                _checked={{
                                                    bg: "#6CA6FC",
                                                    borderColor: "#6CA6FC",
                                                }}
                                                _hover={{
                                                    borderColor: "#6CA6FC",
                                                }}
                                                borderColor={
                                                    // submitClicked && !useDemo
                                                    //     ? "red.500" :
                                                    "gray.300"
                                                    //  {/*submitClicked &&*/}
                                                }
                                                isDisabled={thirdFormDisable}
                                            >
                                                {x}
                                            </Radio>
                                        </div>
                                    );
                                })}
                            </div>
                        </RadioGroup>

                    </div>

                    <div className="flex flex-col gap-3 mt-6">
                        <p className="text-[#3E3C42] text-lg font-medium">
                            Cloud
                        </p>

                        <ChakraProvider >
                            <div className='flex flex-row items-start flex-wrap'>

                                {dataOptions.cloud.type.map((x, index) => {
                                    return (
                                        <div className='py-[8px] pl-[8px] pr-[12px]'>
                                            <Checkbox
                                                disabled={thirdFormDisable}
                                                isChecked={cloud?.includes(x)}
                                                onChange={() => handleCloudCheckboxChange(x)}
                                            >
                                                {x}
                                            </Checkbox>
                                        </div>
                                    )
                                })}
                            </div>
                        </ChakraProvider>

                    </div>

                    <div className="flex flex-col gap-3 mt-6">
                        <p className="text-[#3E3C42] text-lg font-medium">
                            Close Looping
                        </p>

                        <RadioGroup
                            isDisabled={thirdFormDisable}
                            onChange={(e) => { setCloseLooping(e); setShowDiscard(true); }}
                            value={closeLooping}
                        >
                            <div
                                className="flex flex-col md:grid md:grid-cols-4 gap-1 md:gap-2"
                                style={{ width: "fit-content" }}
                            >
                                {dataOptions.closeLooping.type.map((x) => {
                                    return (
                                        <div
                                            style={{
                                                backgroundColor:
                                                    closeLooping === x ? "#DDEEFF80" : "#FFF",
                                                borderRadius: "8px",
                                                mb: "12px",
                                                // border
                                            }}
                                        >
                                            <Radio
                                                value={x}
                                                py={"8px"}
                                                pl={"8px"}
                                                pr={"12px"}
                                                fontSize={"14px"}
                                                fontWeight={500}
                                                color={"#3E3C42"}
                                                _checked={{
                                                    bg: "#6CA6FC",
                                                    borderColor: "#6CA6FC",
                                                }}
                                                _hover={{
                                                    borderColor: "#6CA6FC",
                                                }}
                                                borderColor={
                                                    // submitClicked && !useDemo
                                                    //     ? "red.500" :
                                                    "gray.300"
                                                    //  {/*submitClicked &&*/}
                                                }
                                                isDisabled={thirdFormDisable}
                                            >
                                                {x}
                                            </Radio>
                                        </div>
                                    );
                                })}
                            </div>
                        </RadioGroup>

                    </div>

                    <div className="flex flex-col gap-3 mt-6">
                        <p className="text-[#3E3C42] text-lg font-medium">
                            Deployment
                        </p>

                        <RadioGroup
                            isDisabled={thirdFormDisable}
                            onChange={(e) => { setDeployment(e); setShowDiscard(true); }}
                            value={deployment}
                        >
                            <div
                                className="flex flex-col md:grid md:grid-cols-4 gap-1 md:gap-2"
                                style={{ width: "fit-content" }}
                            >
                                {dataOptions.deployment.type.map((x) => {
                                    return (
                                        <div
                                            style={{
                                                backgroundColor:
                                                    deployment == x ? "#DDEEFF80" : "#FFF",
                                                borderRadius: "8px",
                                                mb: "12px",
                                                // border
                                            }}
                                        >
                                            <Radio
                                                // disabled={thirdFormDisable}
                                                value={x}
                                                py={"8px"}
                                                pl={"8px"}
                                                pr={"12px"}
                                                fontSize={"14px"}
                                                fontWeight={500}
                                                color={"#3E3C42"}
                                                _checked={{
                                                    bg: "#6CA6FC",
                                                    borderColor: "#6CA6FC",
                                                }}
                                                _hover={{
                                                    borderColor: "#6CA6FC",
                                                }}
                                                borderColor={
                                                    // submitClicked && !useDemo
                                                    //     ? "red.500" :
                                                    "gray.300"
                                                    //  {/*submitClicked &&*/}
                                                }
                                                isDisabled={thirdFormDisable}
                                            >
                                                {x}
                                            </Radio>
                                        </div>
                                    );
                                })}
                            </div>
                        </RadioGroup>

                    </div>

                    <div className="flex flex-col gap-3 mt-6">
                        <p className="text-[#3E3C42] text-lg font-medium">
                            Data size estimate
                        </p>
                        <div className="md:w-[400px]">
                            <Input
                                type="text"
                                value={dataSize}
                                placeholder="Link"
                                required
                                borderColor={
                                    // submitClicked && !featureList ? "red.500" : 
                                    "gray.300"
                                }
                                disabled={thirdFormDisable}
                                borderWidth={"2px"}
                                onChange={(e) => { setDataSize(e.target.value); setShowDiscard(true); }}
                            />
                            {
                                dataSize?.length > 50 && <div>
                                    <Text color="red.500" fontSize="sm" mt="1">
                                        You have exceed the character limit of 50 by {dataSize.length - 50}
                                    </Text>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="flex flex-col gap-3 mt-6">
                        <p className="text-[#3E3C42] text-lg font-medium">
                            Video FPS
                        </p>
                        <div className="md:w-[400px]">
                            <Input
                                type="text"
                                value={videoFps}
                                placeholder="Link"
                                required
                                borderColor={
                                    // submitClicked && !featureList ? "red.500" : 
                                    "gray.300"
                                }
                                disabled={thirdFormDisable}
                                borderWidth={"2px"}
                                onChange={(e) => { setVideoFps(e.target.value); setShowDiscard(true); }}
                            />
                            {
                                videoFps?.length > 50 && <div>
                                    <Text color="red.500" fontSize="sm" mt="1">
                                        You have exceed the character limit of 50 by {videoFps.length - 50}
                                    </Text>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="flex flex-col gap-3 mt-6">
                        <p className="text-[#3E3C42] text-lg font-medium">
                            Feature List/PRD
                        </p>
                        <div className="md:w-[400px]">
                            <Input
                                type="text"
                                value={featureList}
                                placeholder="Link"
                                required
                                disabled={thirdFormDisable}
                                borderColor={
                                    // submitClicked && !featureList ? "red.500" : 
                                    "gray.300"
                                }
                                borderWidth={"2px"}
                                onChange={(e) => { setFeatureList(e.target.value); setShowDiscard(true); }}
                            />
                            {
                                featureList?.length > 350 && <div>
                                    <Text color="red.500" fontSize="sm" mt="1">
                                        You have exceed the character limit of 350 by {featureList.length - 350}
                                    </Text>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="flex gap-[10px] items-center ml-6 md:ml-0 mt-6 mb-20 md:mb-0">



                        {
                            !review ? (
                                <>
                                    <PrimaryButton
                                        text={"Save Draft"}
                                        width={"130px"}
                                        //disable={showDisable}
                                        onClick={() => handleSubmit3(0)}
                                    />
                                    <TonalButton
                                        //disable={!showDiscard}
                                        text={"Review"}
                                        width={"130px"}
                                        onClick={() => {
                                            setReview(true);
                                            setThirdFormDisable(true);
                                        }}
                                    />
                                </>
                            ) :
                                (
                                    <>
                                        <TonalButton
                                            //disable={!showDiscard}
                                            text={"Back to Editing"}
                                            width={"130px"}
                                            onClick={() => {
                                                setReview(false);
                                                setThirdFormDisable(false);
                                            }}
                                        />
                                        <PrimaryButton
                                            text={"Submit"}
                                            width={"130px"}
                                            //disable={showDisable}
                                            onClick={() => handleSubmit3(1)}
                                            color="green"
                                        />

                                    </>
                                )
                        }


                    </div>
                </div>
            }

            {showDraft && <ProductDraftModal isOpen={showDraft} handleSubmit={handleSubmit3} handleConfirmDiscard={handleConfirmDraftDiscard} setShowDraft={setShowDraft} />}

        </div>
    )
}


const ProductDraftModal = ({ isOpen, setShowDraft, handleSubmit, handleConfirmDiscard }) => {
    return (
        <Modal isOpen={isOpen} onClose={() => setShowDraft(false)} isCentered size={"sm"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader
                    className=" !flex !justify-center !rounded-t-md !bg-red-400 !text-white "
                    fontSize={"18px"}
                >
                    Save changes ?
                </ModalHeader>
                {/* <div className="text-white w-full flex bg-[#034D86] font-semibold justify-center items-center rounded-t-md">
            hi
          </div> */}
                <div className="flex flex-col justify-center items-center mt-4">
                    <div>
                        <img src="/images/img.svg" alt="" />
                    </div>
                    <div className="font-bold">You have unsaved changes</div>
                    <div >
                        Would you like to save those changes?
                    </div>
                </div>
                <ModalCloseButton className="mt-2" color={"white"} />
                <ModalBody>
                    <div className="flex justify-center items-center gap-3 mt-5">
                        <button
                            onClick={() => {
                                handleSubmit(0);
                                setShowDraft(false);
                            }}
                            className="bg-[#084298] text-white px-7 py-2 rounded-md mb-5 w-[120px]"
                            mr={3}
                        >
                            Save
                        </button>
                        <button
                            onClick={() => handleConfirmDiscard()}
                            className="bg-[#DDEEFF] text-[#3A74CA] px-7 py-2 rounded-md mb-5 w-[120px]"
                            mr={3}
                        >
                            Discard
                        </button>
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default AddNewProduct