import {
  TableContainer,
  Table,
  Th,
  Td,
  Tr,
  Thead,
  Tbody,
} from "@chakra-ui/react";

export const CalAnnotations = (
  label,
  annotatedImages,
  userData,
  total = false
) => {
  let count = 0;
  if (total) {
    if (label == "all") {
      count = annotatedImages.reduce((a, b) => {
        let sum = 0;
        if (userData.annotationType == "Classify") {
          sum = 1;
        } else {
          sum = b.regions.length;
        }
        return a + sum;
      }, 0);
    } else {
      count = annotatedImages.reduce((a, b) => {
        let sum = 0;
        if (userData.annotationType == "Classify") {
          sum = b.label == label;
        } else {
          sum = b.regions.reduce((ac, bc) => {
            let i = bc.cls == label ? 1 : 0;
            return ac + i;
          }, 0);
        }
        return a + sum;
      }, 0);
    }
  } else {
    if (userData.annotationType == "Classify") {
      count = annotatedImages.filter((item) => item.label == label).length;
    } else {
      count = annotatedImages.filter(
        (item) => item.regions[0].cls == label
      ).length;
    }
  }
  return count;
};

const AnnotationCount = ({ labels, annotatedImages, userData }) => {
  const cols = ["", "No. Files", "No. Annotations"];
  return (
    <TableContainer className="w-full lg:w-[15vw]">
      <Table variant="simple" size={"sm"}>
        <Thead>
          {cols.map((item) => {
            return <Th>{item}</Th>;
          })}
        </Thead>
        <Tbody>
          <Tr>
            <Td>Total Annotation</Td>
            <Td>{annotatedImages.length}</Td>
            <Td>{CalAnnotations("all", annotatedImages, userData, true)}</Td>
          </Tr>
          {labels.map((item) => {
            return (
              <Tr>
                <Td>{item}</Td>
                <Td>{CalAnnotations(item, annotatedImages, userData)}</Td>
                <Td>{CalAnnotations(item, annotatedImages, userData, true)}</Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default AnnotationCount;
