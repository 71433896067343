import { useWindowSize } from "@uidotdev/usehooks";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NavContext from "../../NavContext";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { Spinner } from "@chakra-ui/react";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import { getReportDataApi } from "../services/flarestack.api";
import { dataGridSlotProps, generateAlertsColumns } from "../utilis/dataGrid";
import {
  CustomFooter,
  CustomStyledDataGrid,
} from "../../../util/MaterialDataGrid/CustomStyledDatagrid";
import { NoData, TableSkeleton } from "../components/Loading";
import {
  CommanMultipleSelect,
  CommanSingleSelect,
} from "../components/CommanSelectBox";
import { FlareStackContext } from "../context/FlareStackContext";

const Report = ({ plantId }) => {
  // auth params and refs
  //  refs, params
  const { width } = useWindowSize();
  let param = useParams();
  const { auth } = useContext(NavContext);
  const { initialData } = useContext(FlareStackContext);

  //state
  const [loading, setLoading] = useState({
    report: false,
    download: false,
  });
  const [reportData, setReportData] = useState({
    columns: null,
    tableData: null,
  });
  const [filters, setFilters] = useState({
    selectedPlant: plantId,
    fromTime: new Date(
      new Date().getTime() - 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000
    )
      .toISOString()
      .slice(0, 16),
    toTime: new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16),
    flareStack: [initialData?.[0]?.cameraGpId] || [],
    basis: "hourly",
  });

  const downloadReportData = async () => {
    try {
      const requestData = JSON.stringify({
        clientId: param.clientId.toLowerCase(),
        useCase: "SINTERBELT",
        cameraId: "all",
        plantName: filters.selectedPlant,
        basis: filters.selectedBasis,
        startDate: new Date(filters.fromTime).getTime() + 5.5 * 60 * 60 * 1000,
        endDate: new Date(filters.toTime).getTime() + 5.5 * 60 * 60 * 1000,
      });
      // const response = await downloadReportDataApi(
      //   auth,
      //   requestData,
      //   setLoading
      // );
      // const blob = new Blob([response.data], {
      //   type: "application/octet-stream",
      // });
      // const fileName = `Report-${filters?.fromTime.substring(
      //   0,
      //   10
      // )}-to-${filters?.toTime.substring(0, 10)}.xlsx`;
      // saveAs(blob, fileName);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchReportData = async () => {
    try {
      const requestData = JSON.stringify({
        clientId: param.clientId.toLowerCase(),
        useCase: "SINTERBELT",
        cameraId: "all",
        plantName: filters.selectedPlant,
        basis: filters.basis,
        startDate: new Date(filters.fromTime).getTime() + 5.5 * 60 * 60 * 1000,
        endDate: new Date(filters.toTime).getTime() + 5.5 * 60 * 60 * 1000,
      });
      const reportResponse = await getReportDataApi(
        auth,
        requestData,
        setLoading
      );

      // report data
      const tempColumns = await generateAlertsColumns(reportResponse?.columns);
      setReportData((prev) => ({
        ...prev,
        columns: tempColumns,
        tableData: reportResponse?.data,
      }));
    } catch (error) {
      console.log(error);
      setReportData((prev) => ({ ...prev, columns: [], tableData: [] }));
    }
  };

  useEffect(() => {
    fetchReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative flex flex-col mt-5 md:mt-0 mb-1">
      <div className="right-0 left-0 absolute flex justify-center">
        <div className="flex md:flex-row flex-col items-center gap-2 bg-white shadow-md p-5 pr-6 pl-6 rounded-xl">
          <div>
            <FloatingInput
              text="From"
              type="datetime-local"
              setDateTime={(value) =>
                setFilters((prev) => ({ ...prev, fromTime: value }))
              }
              value={filters.fromTime}
              min={"2021-01-01T00:00"}
              max={new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
                .toISOString()
                .slice(0, 16)}
            />
          </div>
          <div>
            <FloatingInput
              text="To"
              type="datetime-local"
              setDateTime={(value) =>
                setFilters((prev) => ({ ...prev, toTime: value }))
              }
              value={filters.toTime}
              min={filters.fromTime}
              max={new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
                .toISOString()
                .slice(0, 16)}
            />
          </div>
          <PrimaryButton
            onClick={fetchReportData}
            text={loading.report ? <Spinner /> : "Show report"}
            height={"100%"}
            width={"120px"}
            disable={!filters.fromTime || !filters.toTime || loading.report}
          />
        </div>
      </div>
      <div className="bg-white mt-[160px] md:mt-11 px-4 pt-[57px] pb-4 rounded-xl w-full h-full">
        <div className="flex md:flex-row flex-col md:justify-between items-end md:items-center gap-2 mb-2">
          <div className="flex flex-row gap-4 py-3 w-full overflow-auto">
            <div className="w-[200px] min-w-[200px]">
              <CommanMultipleSelect
                label={"Flare Stack"}
                options={initialData?.map((el) => ({
                  name: el.cameraGpName,
                  value: el.cameraGpId,
                }))}
                value={filters.flareStack}
                handleChange={(event, obj) => {
                  const {
                    target: { value },
                  } = event;
                  if (typeof value === "string") {
                    setFilters((prev) => ({
                      ...prev,
                      flareStack: value.split(","),
                    }));
                  } else {
                    if (value?.length > 0) {
                      setFilters((prev) => ({ ...prev, flareStack: value }));
                    }
                  }
                }}
              />
            </div>
            <div className="w-[200px] min-w-[200px]">
              <CommanSingleSelect
                label={"Basis"}
                options={[
                  { value: "hourly", name: "Hourly" },
                  { value: "daily", name: "Daily" },
                  { value: "weekly", name: "Weekly" },
                ]}
                value={filters.basis}
                handleChange={(event) => {
                  const {
                    target: { value },
                  } = event;
                  setFilters((prev) => ({
                    ...prev,
                    basis: value,
                  }));
                }}
              />
            </div>
            <SecondaryButton
              onClick={fetchReportData}
              text={loading.report ? <Spinner /> : "Apply"}
              height={"40px"}
              width={"120px"}
              disable={!filters.fromTime || !filters.toTime || loading.report}
            />
          </div>

          <div className="flex items-center gap-3">
            <SecondaryButton
              text={loading?.download ? <Spinner /> : "Download"}
              height={"40px"}
              onClick={downloadReportData}
              disable={loading.download}
              width={"100px"}
            />
          </div>
        </div>

        {loading.report ? (
          <TableSkeleton
            headers={[
              "Header 1",
              "Header 2",
              "Header 3",
              "Header 4",
              "Header 5",
            ]}
            rows={10}
            cellsPerRow={5}
          />
        ) : (
          <>
            {reportData?.columns && reportData?.tableData ? (
              <div
                className="border rounded-md w-full"
                style={{
                  height:
                    90 +
                    Math.min(10, reportData?.tableData?.length || 0) * 45 +
                    "px",
                }}
              >
                <CustomStyledDataGrid
                  rows={reportData?.tableData || []}
                  columns={reportData?.columns || []}
                  rowHeight={45}
                  columnHeaderHeight={45}
                  disableColumnSelector={true}
                  disableRowSelectionOnClick={true}
                  pageSizeOptions={[10, 20, 50]}
                  pagination={true}
                  disableColumnReorder={true}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: 10, page: 0 },
                    },
                    pinnedColumns: {
                      right: width > 768 ? ["view_details"] : null,
                    },
                  }}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0
                      ? "bg-[#FAFAFA]"
                      : "bg-white"
                  }
                  slots={{
                    footer: CustomFooter,
                  }}
                  slotProps={dataGridSlotProps && dataGridSlotProps()}
                  sx={{
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal",
                    },
                  }}
                  showColumnVerticalBorder
                />
              </div>
            ) : (
              <div className="w-full h-[350px]">
                <NoData />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Report;
