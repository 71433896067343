import { DataGrid } from "@mui/x-data-grid";
import {
  createTheme,
  FormControlLabel,
  Switch,
  ThemeProvider,
  Popover,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../..";
import NavContext from "../../NavContext";
import { useParams } from "react-router-dom";
import { IconButton } from "@mui/material";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import InfoIcon from "@mui/icons-material/Info";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { CalF1 } from "../pages/DetailView";
import { useToast } from "@chakra-ui/react";
import InfoDetailModal from "../Components/InfoDetailModal";
import RefreshIcon from "@mui/icons-material/Refresh";
import TextButton from "../../../util/Buttons/TextButton";
import TonalButton from "../../../util/Buttons/TonalButton";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import ReRunModal from "../Components/ReRunModal";

const MuiTheme = createTheme();

const Starred = ({ param, auth, toast }) => {
  let { row, col } = param;
  const [starred, setStarred] = useState(row?.starred);
  const starApi = async (val) => {
    try {
      const data = {
        projectId: row?.projectId,
        trainingId: row?.trainingId,
      };
      const requestBody = JSON.stringify({
        starred: val,
      });
      const response = await axios.post(
        baseURL + "selfserve/v1/project/v1/training/iteration/",
        requestBody,
        {
          params: data,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.status == 200) {
        toast({
          title: "Success",
          description: "Added as favourite",
          status: "success",
          position: "top-right",
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      setStarred((prev) => !prev);
    }
  };

  return (
    <IconButton
      onClick={() => {
        setStarred((prev) => {
          starApi(!prev);
          return !prev;
        });
      }}
    >
      {starred ? (
        <StarIcon sx={{ color: "#FFD700" }} />
      ) : (
        <StarOutlineIcon sx={{ color: "#FFD700" }} />
      )}
    </IconButton>
  );
};

const Info = ({ param }) => {
  const [openModal, setOpenModal] = useState(false);
  let { row } = param;
  return (
    <>
      <IconButton onClick={() => setOpenModal(true)}>
        <InfoIcon sx={{ color: "#CCCCCC" }} />
      </IconButton>
      {openModal && (
        <InfoDetailModal
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          row={row}
        />
      )}
    </>
  );
};

const RerunDeploy = ({ deployed, getStatus, param, auth, toast, type }) => {
  let { row } = param;
  const [checked, setChecked] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const startTrainApi = async (param) => {
    try {
      const response = await axios.post(
        baseURL + "selfserve/v1/project/v1/training/start/",
        null,
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.status == 200) {
        let eta = response.data?.etaTotalSeconds;
        toast({
          title: "Success",
          description: (
            <div>
              <p>Training started for </p>
              <p>Version: {row?.version}</p>
              {eta && eta > 1 && <p>ETA: {(eta / 60).toFixed(1)} min</p>}
            </div>
          ),
          status: "success",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
        setTimeout(() => window.location.reload(), 2000);
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Error",
        description: "Start train api failed",
        status: "error",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
    }
  };
  const unpublishApi = async (param) => {
    try {
      const response = await axios.delete(
        baseURL + "selfserve/v1/project/v1/training/publish/",
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response?.status == 200) {
        toast({
          title: "Success",
          description: "Unpublished the instance",
          status: "success",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
        getStatus();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleSubmit = () => {
    let param = {
      projectId: row?.projectId,
    };
    switch (type) {
      case "run":
        setOpenModal(true);
        setAnchorEl(null);
        break;
      case "deploy":
        if (checked) {
          param["trainingId"] = row?.trainingId;
          unpublishApi(param);
        } else {
          param["toPublish"] = true;
          startTrainApi(param);
        }
        break;
      default:
        console.log("invalid type");
        break;
    }
  };

  useEffect(() => {
    setChecked(row?.publishStatus == "COMPLETED" ? true : false);
  }, [row]);

  return (
    <>
      {type == "run" ? (
        <SecondaryButton
          text={"Re-Run"}
          width={"fit-content"}
          Icon={<AutorenewIcon />}
          disable={
            (row?.latestStatus != "COMPLETED" &&
              row?.latestStatus != "FAILED" &&
              row?.latestStatus != "TERMINATED") ||
            !checked
          }
          onClick={(e) => handleClick(e)}
        />
      ) : (
        <FormControlLabel
          control={<IOSSwitch sx={{ m: 1 }} />}
          label=""
          checked={checked}
          onClick={(e) => handleClick(e)}
          disabled={
            (deployed && !checked) ||
            (row?.latestStatus != "COMPLETED" &&
              row?.latestStatus != "FAILED" &&
              row?.latestStatus != "TERMINATED")
          }
        />
      )}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="text-sm text-[#605D64] flex flex-col gap-2 p-2 rounded-lg w-[200px]">
          <p>
            {type == "run"
              ? "Are you sure you want to run the project"
              : `Are you sure you want to ${
                  row.publishStatus == "NONE" ? "publish" : "unpublish"
                } this project`}
          </p>
          <div className="flex w-full items-center gap-1">
            <TonalButton text={"Cancel"} onClick={() => setAnchorEl(null)} />
            <PrimaryButton text={"Confirm"} onClick={() => handleSubmit()} />
          </div>
        </div>
      </Popover>
      {openModal && (
        <ReRunModal
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          userData={row}
        />
      )}
    </>
  );
};

const HistoryTable = ({
  annotationType,
  setSelectedIteration,
  selectedIteration,
}) => {
  const [rowData, setRowData] = useState([]);
  const [deployed, setDeployed] = useState(false);
  const { projectId } = useParams();
  const { auth } = useContext(NavContext);
  const toast = useToast();
  const columns = [
    {
      field: "id",
      headerName: "SR No.",
    },
    {
      field: "starred",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (param) => (
        <Starred param={param} auth={auth} toast={toast} />
      ),
    },
    {
      field: "modelType",
      headerName: "Model group",
      valueGetter: ({ row }) => {
        return row?.projectDetail?.modelService;
      },
    },
    {
      field: "annotationType",
      headerName: "Model type",
    },
    {
      field: "version",
      headerName: "Version",
    },
    {
      field: "lastUpdatedAt",
      headerName: "Last updated time",
      type: "datetime",
      valueFormatter: ({ value }) => new Date(value).toLocaleString(),
    },
    {
      field: "status",
      headerName: "Model status",
      valueGetter: ({ row }) => {
        let data = row?.publishStatus ?? "NONE";
        if (data != "NONE") {
          return data == "QUEUED"
            ? "PUBLISHING"
            : data === "COMPLETED"
            ? "PUBLISHED"
            : data;
        } else return row?.status;
      },
    },
    {
      field: "numImages",
      headerName: "Image count",
      valueGetter: ({ row }) => {
        return row?.projectDetail?.numDataset;
      },
    },
    {
      field: "numAnnotations",
      headerName: "Annotation count",
      valueGetter: ({ row }) => {
        return row?.projectDetail?.numAnnotations;
      },
    },
    {
      field: "predictions",
      headerName: "Prediction count",
      valueFormatter: ({ value }) => (value ? value?.length : "No predictions"),
    },
    {
      field: "performance",
      headerName: "Model Accuracy",
      valueGetter: ({ row }) => {
        return {
          precision: row?.performance?.precision,
          recall: row?.performance?.recall,
        };
      },
      valueFormatter: ({ value }) => CalF1(value.precision, value.recall),
    },
    {
      field: "info",
      headerName: "Details",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (param) => <Info param={param} />,
    },
    {
      field: "rerun",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (param) => (
        <RerunDeploy
          deployed={deployed}
          getStatus={getStatus}
          setDeployed={setDeployed}
          param={param}
          auth={auth}
          toast={toast}
          type={"run"}
        />
      ),
    },
    {
      field: "publishStatus",
      headerName: "Deploy",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (param) => (
        <RerunDeploy
          deployed={deployed}
          getStatus={getStatus}
          setDeployed={setDeployed}
          param={param}
          auth={auth}
          toast={toast}
          type={"deploy"}
        />
      ),
    },
  ];

  const getStatus = async () => {
    const param = {
      projectId: projectId,
    };
    try {
      const response = await axios.get(
        baseURL + "selfserve/v1/project/v1/training/status/",
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.status == 200 && response.data) {
        let latestStatus = response.data[0]?.status;
        setRowData((prev) => {
          let newData = response.data.map((item, idx) => {
            item["annotationType"] = annotationType;
            item["sr"] = idx;
            item["latestStatus"] = latestStatus;
            return item;
          });
          return newData;
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const headerClass =
    "text-sm font-medium text-[#525056] bg-[#ddeeff] capitalize";
  const cellClass = "text-sm font-medium text-[#3E3C42]";
  let flexmap = {
    id: 0,
    star: 0.5,
    modelType: 1,
    annotationType: 1,
    version: 0.5,
    lastUpdatedAt: 2,
    status: 1,
    numImages: 1,
    numAnnotations: 1,
    predictions: 1,
    performance: 1,
    info: 1,
    rerun: 1,
    publishStatus: 1,
  };
  columns.map((val, idx) => {
    val["headerClassName"] = headerClass;
    val["cellClassName"] = cellClass;
    val["flex"] = flexmap[val.field];
    val["headerAlign"] = "center";
    val["align"] = "center";
  });

  useEffect(() => {
    getStatus();
  }, []);

  useEffect(() => {
    for (let i = 0; i < rowData.length; i++) {
      if (rowData[i].publishStatus == "COMPLETED") {
        setDeployed(true);
      }
    }
  }, [rowData]);

  return (
    <div className="w-full flex flex-col gap-2 items-start">
      <TextButton
        text={"Update"}
        width={"fit-content"}
        onClick={getStatus}
        Icon={<RefreshIcon />}
      />
      <div className="overflow-x-auto w-full">
        <ThemeProvider theme={MuiTheme}>
          {rowData.length > 0 && (
            <DataGrid
              rows={rowData}
              columns={columns}
              columnVisibilityModel={{
                id: false,
              }}
              getRowId={(row) => row?.trainingId}
              onRowClick={(param) => {
                if (
                  selectedIteration?.index != param.row.sr ||
                  selectedIteration?.version != param.row.version
                )
                  setSelectedIteration((prev) => ({
                    ...prev,
                    index: param.row.sr,
                    version: param.row.version,
                  }));
              }}
              getRowClassName={({ row }) =>
                `${
                  row.sr == rowData.length - 1
                    ? "bg-yellow-100"
                    : row.sr == selectedIteration?.index
                    ? "bg-[#E9F2F9]"
                    : "bg-white"
                }`
              }
              getCellClassName={({ field, value }) => {
                if (field == "status") {
                  let colorMap = {
                    COMPLETED: "text-green-400",
                    TERMINATED: "text-red-400",
                    FAILED: "text-red-400",
                    PUBLISHING: "text-blue-400",
                    PUBLISHED: "text-purple-400",
                  };
                  return value in colorMap
                    ? colorMap[value]
                    : "text-yellow-400";
                }
              }}
              pageSizeOptions={[]}
              sx={{
                minWidth: "1500px",
                borderStartStartRadius: "8px",
              }}
            />
          )}
        </ThemeProvider>
      </div>
    </div>
  );
};

export default HistoryTable;
