import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import axios from "axios";
import { baseURL } from "../../..";
import NavContext from "../../NavContext";
import { useToast } from "@chakra-ui/react";
import { initState } from "./CreateForm";
import { draftPostApi } from "./DraftForm";

const Capitalize = (str) => {
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1).toLowerCase();
  }
  const str2 = arr.join(" ");
  return str2;
};

const UploadNewForm = () => {
  const [userData, setUserData] = useState(initState);
  const { auth } = useContext(NavContext);
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const fileInputRef = useRef();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const param = {
    projectId: projectId,
  };
  const format = {
    Image: ["jpg", "jpeg", "png"],
    Video: ["mp4"],
  };
  const [activeStep, setActiveStep] = useState(2);

  const getSingle = async () => {
    try {
      const resposne = await axios.get(
        baseURL + "selfserve/v1/project/v1/getSingle/",
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      setUserData((prev) => {
        let newData = { ...prev };
        let data = resposne.data;
        let val = Object.keys(data?.details[0]?.dataset || {})[0];
        newData["name"] = data?.name || "";
        newData["dataType"] =
          Capitalize(data?.details[0]?.datasetType || "") || "Image";
        newData["whatToDetect"] = data?.remarks || "";
        newData["isAnnotated"] = data?.isAnnotated ? "Yes" : "No";
        newData["annotationType"] =
          Capitalize(data?.details[0]?.modelType || "") || "Classify";
        newData["savedFiles"] = null;
        newData["modelService"] =
          data?.details[0]?.modelService || "RIPIK_VISION";
        newData["bucketLink"] = data?.details[0]?.bucketLink || "";
        newData["uploadedFiles"] = {};
        newData["annotatedData"] = null;
        newData["projectId"] = projectId;
        return newData;
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (name, value) => {
    setUserData((prev) => {
      let newData = { ...prev };
      newData[name] = value;
      return newData;
    });
  };

  const UploadFiles = async (data) => {
    let commonParams = {
      projectId: projectId,
    };

    const promise = new Promise(async (resolve, reject) => {
      let chunkSize = 90;
      const totalChunks = Math.ceil(data.length / chunkSize);
      let completedChunks = 0;
      for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSize;
        const end = (i + 1) * chunkSize;
        const filesChunk = data.slice(start, end);
        let requestData = new FormData();
        filesChunk.map((x) => {
          requestData.append("files", x);
        });
        const axiosParams = {
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Auth-Token": auth,
          },
          params:
            i === 0 ? { ...commonParams, partitionType: "NEW" } : commonParams,
        };
        await axios
          .post(
            baseURL + "selfserve/v1/project/v1/file/upload/",
            requestData,
            axiosParams
          )
          .then((response) => {
            completedChunks++;
            if (completedChunks == totalChunks) {
              resolve(200);
              setLoading(false);
              // handleChange("uploadedFiles", response.data?.dataset);
              setUserData((prev) => {
                let newData = { ...prev };
                newData["uploadedFiles"] = response.data?.dataset;
                draftPostApi(newData, 3, toast, auth);
                setTimeout(() => {
                  navigate(`/Sandbox/Draft/${projectId}`);
                }, 3000);
                return newData;
              });
              if (activeStep < 3) setActiveStep((prev) => prev + 1);
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
            setLoading(false);
          });
      }
    });

    toast.promise(promise, {
      success: {
        title: "Upload complete",
        description: "Click on save to annotate",
        position: "top-right",
      },
      error: {
        title: "Upload failed",
        description: "Something went wrong",
        position: "top-right",
      },
      loading: {
        title: "Uploading files",
        description: "Please wait",
        position: "top-right",
      },
    });
  };

  const handleFileChange = (event) => {
    const selectedFile = Array.from(event.target.files);
    if (selectedFile) {
      const acceptedTypes = format[userData.dataType].map((ext) => {
        return userData.dataType.toLowerCase() + "/" + ext;
      });
      let goodToGo = true;
      selectedFile.map((file) => {
        if (!acceptedTypes.includes(file.type) || file.size > 2 * 1024 * 1024) {
          toast({
            title: "Error",
            description: (
              <div>
                <p>{`Image name: ${file.name}`}</p>
                <p>{`Accepted formats: [${acceptedTypes.join(", ")}]`}</p>
                <p>{`Uploaded file: ${file.type}`}</p>
                <p>{`Max size: 2MB, Uploaded size: ${(
                  file.size /
                  (1024 * 1024)
                ).toFixed(1)}MB`}</p>
              </div>
            ),
            status: "error",
            position: "top-right",
            duration: 6000,
            isClosable: true,
          });
          goodToGo = false;
          return;
        }
      });
      if (goodToGo) {
        setLoading(true);
        UploadFiles(selectedFile);
        handleChange("savedFiles", selectedFile);
      }
    }
  };

  useEffect(() => {
    getSingle();
  }, []);

  return (
    <div className="flex flex-col gap-3 h-full w-full mt-6">
      <p className="text-[#084298] font-medium text-xl">Update model data</p>
      <div className="p-6 flex flex-col gap-8 rounded-lg bg-white" id="step2">
        <p className="text-[#3E3C42] text-2xl font-medium">Upload files</p>
        <input
          ref={fileInputRef}
          type="file"
          style={{ display: "none" }}
          onChange={handleFileChange}
          multiple
        />
        <SecondaryButton
          Icon={<img src="/selfServiceIcons/upload.svg" alt="upload" />}
          text={"Upload files"}
          width={"fit-content"}
          onClick={() => {
            fileInputRef.current.click();
          }}
          disable={loading || activeStep > 2}
        />
      </div>
    </div>
  );
};

export default UploadNewForm;
