// api's request for status page
import axios from "axios";
import { baseURL } from "../../..";
// import { sequenceSetModalData } from "./data";

export const getStatusDataApi = async (auth, requestData, setLoading) => {
  setLoading(true);
  try {
    let res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/analysis/overview/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    const data = res?.data;
    if (data) {
      const tempStatusData = data?.plants?.chanderia?.sinterbelt;
      return {
        success: true,
        data: tempStatusData,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      error: error,
      data: null,
    };
  } finally {
    setLoading(false);
  }
};

export const getSequenceSetModalDataApi = async (
  auth,
  requestData,
  setLoading
) => {
  try {
    setLoading(true);
    let res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/boardSequence/get/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    // console.log(res);
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data: data,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      error: error,
      data: null,
    };
  } finally {
    setLoading(false);
  }
};

export const initializePalletBoardApi = async (auth, requestData) => {
  try {
    // console.log(auth, requestData);
    const res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/boardSequence/initialize/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    // console.log(res);
    if (res?.status === 200 && res?.data?.message === "Success") {
      return {
        success: true,
        message: "Pallet Board Initialized",
      };
    } else {
      return {
        success: false,
        message: res?.data?.message || "Error while Updating",
      };
    }
    // return {
    //   success: true,
    //   message: "Pallet Board Initialized",
    // };
  } catch (error) {
    console.log(error);
    return {
      success: false,
      message: "Error while Updating",
    };
  }
};
export const updateBoardNumberApi = async (auth, requestData, setLoading) => {
  try {
    setLoading(true);
    console.log(auth, requestData);
    const res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/boardSequence/update/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    if (res?.status === 200 && res?.data?.message === "Success") {
      return {
        success: true,
        message: "Board Number Updated",
      };
    } else {
      return {
        success: false,
        message: "Error while updating",
      };
    }
  } catch (error) {
    console.log(error);
    throw error;
  } finally {
    setLoading(false);
  }
};
