import { getGridStringOperators } from "@mui/x-data-grid";
import { capitalizeCamelCaseString } from "./utilityFunctions";

export const generateColumns = async (columnNames) => {
  //   dynamic columns
  const dynamicColumns = columnNames.map((columnName) => {
    let columnConfig = {
      field: columnName,
      headerName: capitalizeCamelCaseString(columnName),
      flex: 1,
      minWidth: 200,
      sortable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "startsWith"
      ),
    };

    // add column specific props
    switch (columnName) {
      default:
        break;
    }

    return columnConfig;
  });
  return [...(dynamicColumns || [])];
};

export const dataGridSlotProps = () => {
  return {
    // column menu
    columnMenu: {
      sx: {
        minWidth: 170,
        padding: 0,
        ".MuiTypography-root": {
          fontSize: "13px",
        },
        "& .css-1asgcec-MuiButtonBase-root-MuiMenuItem-root": {
          paddingY: 0,
          minHeight: "35px",
        },
      },
    },
    // the panel which contains filtering menu (top wrapper)
    panel: {
      sx: {
        maxWidth: "85vw",
        ".css-154sxbz-MuiPaper-root-MuiDataGrid-paper": {
          minWidth: 0,
        },
      },
    },
    // actual filter panel in which all elements are stored
    filterPanel: {
      filterFormProps: {
        // input box for operator selection(for multi-columns => and, or)
        logicOperatorInputProps: {
          sx: { display: "none" },
        },
        // input box for column selection
        columnInputProps: {
          variant: "outlined",
          size: "small",
        },
        // input box for operator selection(for a particular columns => contains, start with, is empty ....)
        operatorInputProps: {
          sx: { display: "none" },
        },
        // input box to write the value on the basis of which data get's filtered
        valueInputProps: {
          InputComponentProps: {
            variant: "outlined",
            size: "small",
          },
        },
        // delete icon
        deleteIconProps: {
          sx: {
            "& .MuiSvgIcon-root": { color: "#d32f2f" },
          },
        },
      },
      sx: {
        overflowX: "auto",
        "& .MuiDataGrid-filterForm": { p: 2 },
        "& .MuiDataGrid-filterFormLogicOperatorInput": {
          mr: 2,
          minWidth: 80,
        },
        "& .MuiDataGrid-filterFormColumnInput": {
          mr: 2,
          minWidth: 150,
        },
        "& .MuiDataGrid-filterFormValueInput": { minWidth: 200 },
      },
    },
  };
};
