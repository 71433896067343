import { Navigate } from "react-router-dom";
import EmailActivation from "./components/Admin/EmailActivation";
import AdminHome from "./components/Admin/Home";
import TransactionHistory from "./components/Admin/TransactionHistory/TransactionHistory";
import BillingHome from "./components/Billing/Home";
import BF_Dashboard from "./components/BlastFurnace/BF_Components/BF_Dashboard";
import BfClientSelect from "./components/BlastFurnace/BF_Components/BfClientSelect";
import BlendComplianceTracking from "./components/BlendComplianceTracking/BlendComplianceTracking";
import ConatctUs from "./components/ContactUs/ConatctUs";
import Setting from "./components/ContactUs/Setting";
import UserProfile from "./components/ContactUs/UserProfile";
import Fabric from "./components/FabricTracking/Fabric";
import FabricSingleCam from "./components/FabricTracking/FabricSingleCam";
import Kiln from "./components/Kiln/Kiln";
import KilnSingleCam from "./components/Kiln/KilnSingleCam";
import AiAdvisor from "./components/Main/AIAdvisor";
import AskAnExpert from "./components/Main/AskAnExpert";
import ClientSelect from "./components/Main/ClientSelect";
import CreditBuy from "./components/Main/CreditBuy";
import Demo from "./components/Main/Demo";
import Expert from "./components/Main/Expert";
import Home from "./components/Main/Home";
import Messages from "./components/Main/Messages/Messages";
import SingleMessage from "./components/Main/Messages/SingleMessage";
import Redirect from "./components/Main/Redirect";
import Manpower from "./components/Manpower/Manpower";
import SearchResults from "./components/SearchResults";
import CreateForm from "./components/SelfService/pages/CreateForm";
import Dashboard from "./components/SelfService/pages/Dashboard";
import DetailView from "./components/SelfService/pages/DetailView";
import DraftForm from "./components/SelfService/pages/DraftForm";
import UploadNewForm from "./components/SelfService/pages/UploadNewForm";
import SinterBelt from "./components/SinterBelt/SinterBelt";
import Sinterflame from "./components/SinterFlame/Sinterflame";
import SinterflameSingle from "./components/SinterFlame/SinterflameSingle";
import SingleCam from "./components/Sizing/SingleCam";
import Sizing from "./components/Sizing/Sizing";
import SlabSizing from "./components/SlabSizing/SlabSizing";
import AddClients from "./components/SuperAdmin/AddClients/AddClients";
import CreateClient from "./components/SuperAdmin/AddClients/CreateClient";
import SuperAdminDashboard from "./components/SuperAdmin/SuperAdminDashboard";
import UpdateTabs from "./components/SuperAdmin/Tabs/UpdateTabs";
import ViewTabs from "./components/SuperAdmin/Tabs/ViewTabs";
import WorkforceSafety from "./components/WorkforceSafety/WorkforceSafety";
import AiAdvisorHistory from "./components/community/AiAdvisorHistory";
import AskAnExpertHistory from "./components/community/AskAnExpertHistory";
import AskYourQuestion from "./components/community/AskYourQuestion/AskYourQuestion";
import DraftQuestion from "./components/community/AskYourQuestion/DraftQuestion";
import MaterialSelect from "./util/MaterialSelect";
import IsfOptimizerClientSelect from "./components/IsfOptimizer/IsfOptimizerClientSelect";
import IsfOptimizer from "./components/IsfOptimizer/IsfOptimizer";
import CreateCam from "./components/SelfService/CamComponents/CreateCam";
import CamViewDetail from "./components/SelfService/CamComponents/CamView/CamViewDetail";
import CreateAlert from "./components/SelfService/CamComponents/CamAlerts/CreateAlert";
import AlertSetting from "./components/SelfService/CamComponents/CamAlerts/AlertSetting";
import Mulpic from "./components/Mulpic/Mulpic";
import MulpicClientSelect from "./components/Mulpic/MulpicClientSelect";
import SinterBed from "./components/SinterBed/SinterBed";
import RecipeCompliance from "./components/RecipeCompliance/RecipeCompliance";
import SinterFlameProgress from "./components/SinterFlameProgression/SinterFlameProgress";
import { Suspense, lazy } from "react";
import FlareStack from "./components/FlareStack/FlareStack";
import SodaAshAndLimeOptimizerClientSelect from "./components/SodaAshAndLimeOptimizer/SodaAshAndLimeOptimizerClientSelect";
import SodaAshAndLimeOptimizer from "./components/SodaAshAndLimeOptimizer/SodaAshAndLimeOptimizer";

// import YieldAppRoute from "./components/YieldOptimizer/src/YieldAppRoute";
// import YieldApp from "./components/YieldOptimizer/src/YieldApp";

const YieldAppRoute = lazy(() =>
  import("./components/YieldOptimizer/src/YieldAppRoute")
);

const YieldHistoryRoute = lazy(() =>
  import("./components/YieldOptimizer/src/YieldHistoryRoute")
);

const SizingAndMoistureRoute = lazy(() =>
  import("./components/SizingAndMoisture/SizingAndMoisture")
);

const SizingAndMoistureSingleCamRoute = lazy(() =>
  import("./components/SizingAndMoisture/SizingAndMoistureSingleCam")
);

export const routes = [
  {
    path: "/search", //search results
    element: <SearchResults />,
    role: ["SUPERADMIN", "ADMIN", "USER", "EXPERT", "GUEST"],
  },
  {
    path: "/",
    element: <Navigate to="/home" />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
  {
    path: "/",
    element: <Navigate to="community/askanexpert" />,
    role: ["EXPERT"],
  },
  {
    path: "/home",
    element: <Home state={"home"} />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
    hasHomeRef: true, // Indicate that this route needs homeRef
  },
  {
    path: "/vision",
    element: <Home state={"vision"} />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
    hasHomeRef: true, // Indicate that this route needs homeRef
  },
  {
    path: "/optimus",
    element: <Home state={"optimus"} />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
    hasHomeRef: true, // Indicate that this route needs homeRef
  },
  // Community pages

  {
    path: "/community",
    element: <Home state={"community"} />,
    role: ["SUPERADMIN", "ADMIN", "USER", "EXPERT", "GUEST"],
    hasHomeRef: true, // Indicate that this route needs homeRef
  },
  {
    path: "/community/advisor/:chatId",
    element: <AiAdvisor />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
  {
    path: "/community/advisor/buycredits/:org/:name",
    element: <CreditBuy />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
  {
    path: "/community/askanexpert/question",
    element: <AskAnExpert />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
  {
    path: "/community/askanexpert/updatequestion/:questionId",
    element: <DraftQuestion />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
  {
    path: "/community/expert/:questionId",
    element: <Expert />,
    role: ["SUPERADMIN", "ADMIN", "USER", "EXPERT", "GUEST"],
  },
  {
    path: "/community/advisor/history",
    element: <AiAdvisorHistory />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
  {
    path: "/community/askanexpert",
    element: <AskAnExpertHistory />,
    role: ["SUPERADMIN", "ADMIN", "USER", "EXPERT", "GUEST"],
  },
  // {
  //   path: "/community/expert/:questionId",
  //   element: <Expert />,
  //   role: ["SUPERADMIN", "ADMIN", "USER", "EXPERT"],
  // },
  {
    path: "/user/transactionhistory",
    element: <TransactionHistory />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
  {
    path: "/community/askanexpert/askquestion/:expertId",
    element: <AskYourQuestion />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
  {
    path: "/client_select",
    element: <ClientSelect />, //Client Select Page
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  // Optimus Pages
  {
    path: "/optimus/blastfurnace",
    element: <Demo />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  {
    path: "/optimus/blastfurnace/:clientId",
    element: <BF_Dashboard />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },

  {
    path: "/optimus/blastfurnace/:clientId/:material",
    element: (
      <BF_Dashboard>
        <Sizing />
      </BF_Dashboard>
    ),
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  {
    path: "/optimus/blastfurnace/:clientId/:material/:plantId/:cameraId",
    element: (
      <BF_Dashboard>
        <SingleCam />
      </BF_Dashboard>
    ),
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  // ISF Optimizer Pages
  {
    path: "/optimus/isfoptimizer/",
    element: <IsfOptimizerClientSelect />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  {
    path: "/optimus/isfoptimizer/:clientId",
    element: <IsfOptimizer />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },

  // Mulpic Pages
  {
    path: "/optimus/mulpic",
    element: <MulpicClientSelect />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  {
    path: "/optimus/mulpic/:clientId",
    element: <Mulpic />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  // Soda Ash and Lime Optimizer Pages
  {
    path: "/optimus/sodaashandlimeoptimizer",
    element: <SodaAshAndLimeOptimizerClientSelect />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  {
    path: "/optimus/sodaashandlimeoptimizer/:clientId",
    element: <SodaAshAndLimeOptimizer />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  // Vision Pages
  // {
  //   path: "/vision/:category",
  //   element: <MaterialSelect />,
  //   role: ["SUPERADMIN", "ADMIN", "USER"],
  // },
  {
    path: "/vision/:category",
    element: <MaterialSelect />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  {
    path: "/vision/:category/:material",
    element: <ClientSelect />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  // Sizing pages0000
  {
    path: "/vision/Sizing/:material/:clientId",
    element: <Sizing />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  {
    path: "/vision/Sizing/:material/:clientId/:plantId/:cameraId",
    element: <SingleCam />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  // Sizing and Moisture
  {
    path: "/vision/rawmaterialanalysis/:material/:clientId",
    element: (
      <Suspense fallback={<h1 className="mt-10">Loading...</h1>}>
        <SizingAndMoistureRoute />
      </Suspense>
    ),
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  {
    path: "/vision/rawmaterialanalysis/:material/:clientId/:plantId/:cameraId",
    element: (
      <Suspense fallback={<h1 className="mt-10">Loading...</h1>}>
        <SizingAndMoistureSingleCamRoute />
      </Suspense>
    ),
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },

  // Process and Kiln Pages
  {
    path: "/vision/ProcessMonitoring/kilnhealth/:clientId",
    element: <Kiln />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  {
    path: "/vision/ProcessMonitoring/kilnhealth/:clientId/:plantId/:cameraId",
    element: <KilnSingleCam />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  // Sinter Flame Analysis
  {
    path: "/vision/ProcessMonitoring/sinterflame/:clientId",
    element: <Sinterflame />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  {
    path: "/vision/ProcessMonitoring/sinterflame/:clientId/:plantId/:cameraId",
    element: <SinterflameSingle />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  // jsw saleem
  {
    path: "/vision/ProcessMonitoring/sinterflameprogression/:clientId",
    element: <SinterFlameProgress />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  // Sinter Belt Monitoring
  {
    path: "/vision/equipmentmonitoring/sinterbelt/:clientId",
    element: <SinterBelt />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  // Sinter Bed Monitoring
  {
    path: "/vision/qualityTracking/sinterbed/:clientId",
    element: <SinterBed />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  {
    path: "/vision/ProcessMonitoring/flarestackmonitoring/:clientId",
    element: <FlareStack />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  //Quality Tracking pages
  {
    path: "/vision/qualityTracking/fabric/:clientId",
    element: <Fabric />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  {
    path: "/vision/qualityTracking/fabric/:clientId/:plantId/:cameraId",
    element: <FabricSingleCam />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  // slab sizing
  {
    path: "/vision/qualityTracking/steelslab/:clientId",
    element: <SlabSizing />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  // Manpower pages
  {
    path: "/Optimus/Manpower",
    element: <Manpower />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  // Workforcesafety
  {
    path: "/vision/workforceMonitoring/workforcesafety/:clientId",
    element: <WorkforceSafety />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  {
    path: "/Optimus/:category",
    element: <Demo />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  {
    path: "/vision/workforceMonitoring/blendcompliance/:clientId",
    element: <BlendComplianceTracking />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  {
    path: "/vision/workforceMonitoring/recipecompliance/:clientId",
    element: <RecipeCompliance />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  {
    path: "/Optimus/:category",
    element: <Demo />,
    role: ["SUPERADMIN", "ADMIN", "USER"],
  },
  // Profile Pages
  {
    path: "/contactus",
    element: <ConatctUs />,
    role: ["SUPERADMIN", "ADMIN", "USER", "EXPERT", "GUEST"],
  },
  {
    path: "/settings",
    element: <Setting />,
    role: ["SUPERADMIN", "ADMIN", "USER", "EXPERT", "GUEST"],
  },
  {
    path: "/profile",
    element: <UserProfile />,
    role: ["SUPERADMIN", "ADMIN", "USER", "EXPERT", "GUEST"],
  },
  {
    path: "/bookdemo/:product",
    element: <Demo />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
  // Notification Pages
  {
    path: "/notifications",
    element: <Messages />,
    role: ["SUPERADMIN", "ADMIN", "USER", "EXPERT", "GUEST"],
  },
  {
    path: "/notifications/singleMessage",
    element: <SingleMessage />,
    role: ["SUPERADMIN", "ADMIN", "USER", "EXPERT", "GUEST"],
  },
  {
    path: "/community/sso",
    element: <Redirect />,
    role: ["SUPERADMIN", "ADMIN", "USER", "EXPERT", "GUEST"],
  },

  // Admin Pages
  {
    path: "/admin/usermanagement",
    element: <AdminHome />,
    role: ["SUPERADMIN", "ADMIN"],
  },
  {
    path: "/admin/billing",
    element: <BillingHome />,
    role: ["SUPERADMIN", "ADMIN"],
  },
  {
    path: "/admin/activatesubscription",
    element: <EmailActivation />,
    role: ["SUPERADMIN", "ADMIN"],
  },
  // Super admin pages

  {
    path: "/superadmin",
    element: <SuperAdminDashboard />,
    role: ["SUPERADMIN"],
  },

  {
    path: "/superadmin/viewDetails/:clientOrg/:clientId/:mode",
    element: <ViewTabs />,
    role: ["SUPERADMIN"],
  },
  {
    path: "/superadmin/update/:clientOrg/:clientId/:mode",
    element: <UpdateTabs />,
    role: ["SUPERADMIN"],
  },
  // Self service pages

  {
    path: "/Sandbox",
    element: <Dashboard />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },

  {
    path: "/Sandbox/Create",
    element: <CreateForm />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
  {
    path: "/Sandbox/Cam/Create",
    element: <CreateCam />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
  {
    path: "/Sandbox/Cam/View/:camId",
    element: <CamViewDetail />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
  {
    path: "/Sandbox/Alert/Create/:camId",
    element: <CreateAlert />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
  {
    path: "/Sandbox/Alert/:camId",
    element: <AlertSetting />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
  {
    path: "/Sandbox/Draft/:projectId",
    element: <DraftForm />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
  {
    path: "/Sandbox/View/:projectId",
    element: <DetailView />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
  {
    path: "/Sandbox/New/:projectId",
    element: <UploadNewForm />,
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
  // Optimus Paths
  {
    path: "/Optimus/yieldoptimiser",
    element: (
      <Suspense fallback={<h1 className="mt-10">Loading</h1>}>
        <YieldHistoryRoute />
      </Suspense>
    ),
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
  {
    path: "/Optimus/yieldoptimiser/app",
    element: (
      <Suspense fallback={<h1 className="mt-10">Loading</h1>}>
        <YieldAppRoute />
      </Suspense>
    ),
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
  {
    path: "/Optimus/yieldoptimiser/app/:stage",
    element: (
      <Suspense fallback={<h1 className="mt-10">Loading</h1>}>
        <YieldAppRoute />
      </Suspense>
    ),
    role: ["SUPERADMIN", "ADMIN", "USER", "GUEST"],
  },
];
