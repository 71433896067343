import React from "react";
import { Tooltip } from "@chakra-ui/react";
import { TriangleDownIcon } from "@chakra-ui/icons";

const TemperatureBar = ({
  overallRange = [-25, 25],
  optimalRange = [-10, 10],
  currentValue = 655,
  tempValue = 650,
}) => {
  const calculateOptimalRangeWidth = () => {
    let total = overallRange[1] - overallRange[0];
    let optimalTotal = optimalRange[1] - optimalRange[0];
    return { width: `${(optimalTotal / total) * 100}%` };
  };

  const currentValuePosFinder = () => {
    let total = overallRange[1] - overallRange[0];
    let currVal =
      (tempValue + overallRange[1]) <= currentValue
        ? total
        : (tempValue - overallRange[1]) >= currentValue
          ? 0
          : currentValue - tempValue + (overallRange[1] - overallRange[0]) / 2;
    return `${((currVal / total) * 100) - 7.5}%`;
  };
  let currentValuePercent = currentValuePosFinder();

  console.log(currentValuePercent);
  return (
    <div className="w-full h-full flex items-center justify-center p-3 px-4 text-sm">
      <div className="h-[60%] bg-[#F5F5F5] z-0 w-full flex justify-center relative shadow-inner">
        <div className="absolute -bottom-5 flex w-full justify-between items-center">
          <p
            style={{
              marginLeft: overallRange[0] < 0 ? "-12px" : "-4px",
            }}
          >
            {overallRange[0]}
          </p>
          <p className="-mr-1">{overallRange[1]}</p>
        </div>
        <div
          className="h-full bg-[#CDEEBF] z-10 flex justify-center relative"
          style={calculateOptimalRangeWidth()}
        >
          <div
            className="h-full bg-[#447ED4] relative"
            style={{ width: "2px" }}
          >
            <p className="absolute -bottom-5 flex w-full justify-center items-center">
              {tempValue}
            </p>
          </div>
          <div className="absolute -bottom-5 flex w-full justify-between items-center">
            <p
              style={{
                marginLeft: optimalRange[0] < 0 ? "-12px" : "-4px",
              }}
            >
              {optimalRange[0]}
            </p>
            <p className="-mr-1">{optimalRange[1]}</p>
          </div>
        </div>
        <div className="flex flex-col gap-0 absolute top-[-35px] items-center w-[30px]" style={{left: currentValuePercent}}>
          <p
            className="px-2 py-1 text-[black] font-bold text-xs rounded-md z-0 whitespace-nowrap bg-gray-200 shadow-inner"
            style={{
              boxShadow:
                "4px 4px 4px 0px rgba(226, 240, 220, 0.51), -4px -4px 18px 0px rgba(226, 240, 220, 0.38)",
              // color: index == 0 ? "#FFC107" : "#DC362E",
              
            }}
          >
            {currentValue}
          </p>
          <TriangleDownIcon
            style={{
              color: "black",
              // marginTop: "-5px",
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default TemperatureBar;