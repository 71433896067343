import React, { useRef } from 'react'
import { useEffect, useState, useContext } from "react";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
// import {} from "../services/data";
import { generateGroupedColumns } from "../utilis/dataGridColumns";
import { CustomFooter } from "../../../util/MaterialDataGrid/CustomStyledDatagrid";
import { Input, Select, Spinner } from "@chakra-ui/react";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import { IbaTable } from '../components/IbaTable';
import { getIbaData, getParametersDataApi, getGraphsDataApi, getAdditionalGraphsDataApi } from "../services/ibaServices";
import NavContext from "../../NavContext";
import { Menu, MenuButton, MenuList, Stack, Button } from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import LineChartOverlay from '../../Charts/MulpicCharts/LineChartOverlay';
import { ThemeProvider, createTheme } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useWindowSize } from '@uidotdev/usehooks';
import Tooltip from '@mui/material/Tooltip';
import MetaDataComponent from '../components/MetaDataComponent';
import ExlcButton from '../utilis/ExlcButton';
import { getBoxPlotGraphsDataApi } from '../services/ibaServices';
import { boxPlotDummyData } from '../services/data';
import BoxPlotChart from '../../Charts/MulpicCharts/BoxPlotChart';
import { dummyAdditionalChartsData } from '../services/data';


const MuiTheme = createTheme();

const CustomInput = ({ row, rowData, setRowData, index, plateId, cnt, isTwoAxis }) => {

    return (
        <div className={`grid grid-cols-1 md:${isTwoAxis ? "grid-cols-3" : "grid-cols-1"} gap-2 border p-1 w-full rounded-sm`}>
            <div className='flex flex-row gap-1'>
                <input type="checkbox" disabled={(cnt >= 10 && !rowData[plateId][index].checked)} onChange={(e) => {
                    if (e.target.checked) {
                        if (!rowData?.[plateId]?.[index]?.checked) {
                            setRowData((prev) => {
                                let newData = structuredClone(prev);
                                newData[plateId][index].checked = true;
                                return newData;
                            })
                        }
                    }
                    else {
                        if (rowData?.[plateId]?.[index]?.checked) {
                            setRowData((prev) => {
                                let newData = structuredClone(prev);
                                newData[plateId][index].checked = false;
                                return newData;
                            })
                        }
                    }
                }}
                    checked={rowData?.[plateId]?.[index]?.checked}
                />
                <Tooltip title={row?.parameter} placement="top">
                    <p className='truncate'>{row?.parameter}</p>
                </Tooltip>

            </div>
            {isTwoAxis && <div className='flex flex-row gap-1 md:justify-center items-center'>
                <input type="radio" onChange={(e) => {
                    if (e.target.checked) {
                        setRowData((prev) => {
                            let newData = structuredClone(prev);
                            newData[plateId][index].type = 0;
                            return newData;
                        })
                    }
                    else {
                        setRowData((prev) => {
                            let newData = structuredClone(prev);
                            newData[plateId][index].type = 1;
                            return newData;
                        })
                    }
                }}
                    checked={rowData?.[plateId]?.[index]?.type == 0}
                />
                <p>Primary Y-Axis</p>
            </div>}
            {isTwoAxis && <div className='flex flex-row gap-1 md:justify-center items-center'>
                <input type="radio" onChange={(e) => {
                    if (e.target.checked) {
                        setRowData((prev) => {
                            let newData = structuredClone(prev);
                            newData[plateId][index].type = 1;
                            return newData;
                        })
                    }
                    else {
                        setRowData((prev) => {
                            let newData = structuredClone(prev);
                            newData[plateId][index].type = 0;
                            return newData;
                        })
                    }
                }}
                    checked={rowData?.[plateId]?.[index]?.type == 1}
                />
                <p>Secondary Y-Axis</p>
            </div>}
        </div>
    )
}

// const CustomInput = ({ row, rowData, setRowData, index }) => {
//     return (
//         <div className='grid grid-cols-4 gap-4 border p-2'>
//             <div className='flex flex-row gap-1'>
//                 <input type="checkbox" onChange={(e) => {
//                     if (e.target.checked) {
//                         if (!rowData?.[index]?.checked) {
//                             setRowData((prev) => {
//                                 let newData = structuredClone(prev);
//                                 newData[index].checked = true;
//                                 return newData;
//                             })
//                         }
//                     }
//                     else {
//                         if (rowData?.[index]?.checked) {
//                             setRowData((prev) => {
//                                 let newData = structuredClone(prev);
//                                 newData[index].checked = false;
//                                 return newData;
//                             })
//                         }
//                     }
//                 }}
//                     checked={rowData?.[index]?.checked}
//                 />
//                 <p>{row?.plate_id}</p>
//             </div>

//             <p className=''>{row?.parameter}</p>
//             <div className='flex flex-row gap-1 justify-center'>
//                 <input type="radio" onChange={(e) => {
//                     if (e.target.checked) {
//                         setRowData((prev) => {
//                             let newData = structuredClone(prev);
//                             newData[index].type = 0;
//                             return newData;
//                         })
//                     }
//                     else {
//                         setRowData((prev) => {
//                             let newData = structuredClone(prev);
//                             newData[index].type = 1;
//                             return newData;
//                         })
//                     }
//                 }}
//                     checked={rowData?.[index]?.type == 0}
//                 />
//                 <p>Primary</p>
//             </div>
//             <div className='flex flex-row gap-1 justify-center'>
//                 <input type="radio" onChange={(e) => {
//                     if (e.target.checked) {
//                         setRowData((prev) => {
//                             let newData = structuredClone(prev);
//                             newData[index].type = 1;
//                             return newData;
//                         })
//                     }
//                     else {
//                         setRowData((prev) => {
//                             let newData = structuredClone(prev);
//                             newData[index].type = 0;
//                             return newData;
//                         })
//                     }
//                 }}
//                     checked={rowData?.[index]?.type == 1}
//                 />
//                 <p>Secondary</p>
//             </div>
//         </div>
//     )
// }

const CustomInput2 = ({ plateId, rowData, setRowData, isTwoAxis = true }) => {

    const size = useWindowSize();

    let cnt = 0;

    Object.keys(rowData).forEach((key) => {
        rowData[key]?.forEach((value) => {
            if (value?.checked) cnt++;
        })
    })

    const newData = rowData?.[plateId]?.map((item, index) => {
        return (
            {
                ...item,
                index: index,
            }
        )
    });

    return (
        <div className='flex flex-col md:flex-row gap-2 border p-2 justify-between md:items-center w-full'>
            <p className='font-medium'>{plateId} - </p>
            <ThemeProvider theme={MuiTheme}>
                <Autocomplete
                    disablePortal
                    //   value={selectedValue}
                    id="combo-box-demo"
                    options={newData}
                    getOptionLabel={(option) => `${option?.parameter}`}
                    // onChange={handleAutocompleteChange}
                    sx={{
                        width: size?.width >= 768 ? "500px" : "200px",
                        "& .MuiInputBase-root": {
                            height: "40px", // Adjust the height here
                        },
                    }}
                    renderOption={(props, option) => {
                        console.log(props);
                        return <CustomInput row={option} rowData={rowData} plateId={plateId} setRowData={setRowData} index={option?.index} cnt={cnt} isTwoAxis={isTwoAxis} />
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Select Parameters"
                            sx={{
                                width: "100%",
                                height: "100%",

                                "& .MuiInputLabel-root": {
                                    transform: "translate(14px, 10px) scale(1)", // Adjust the label position
                                    fontSize: "14px", // Adjust the label font size
                                },
                                "& .MuiInputLabel-shrink": {
                                    transform: "translate(14px, -6px) scale(0.75)", // Adjust the shrunk label position
                                },
                            }}
                        />
                    )}
                />
            </ThemeProvider>
        </div>
    )
}

const Iba = ({ plantId, disable, plantMap }) => {

    const primaryRef = useRef();
    const leftRef = useRef();
    const rightRef = useRef();

    const { auth } = useContext(NavContext);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedTimeStamps, setSelectedTimeStamps] = useState([]);
    const [loading, setLoading] = useState(false);
    const [reportTableData, setReportTableData] = useState([]);
    const [filteredRows, setFilteredRows] = useState([]);
    const [columns, setColumns] = useState(null);
    const [selectedRange, setSelectedRange] = useState(0);
    const [parametersData, setParametersData] = useState([]);
    const [leftParametersData, setLeftParametersData] = useState([]);
    const [rightParametersData, setRightParametersData] = useState([]);
    const [allParametersData, setAllParametersData] = useState([]);
    const [boxPlotParametersData, setBoxPlotParametersData] = useState([]);
    const [boxPlotChartsData, setBoxPlotChartsData] = useState([]);
    const [leftChartsData, setLeftChartsData] = useState(null);
    const [rightChartsData, setRightChartsData] = useState(null);
    const [chartsData, setChartsData] = useState(null);
    const [selectedPlateType, setSelectedPlateType] = useState("mulpic")
    const [referencePoint, setReferencePoint] = useState({
        "primary": [],
        "left": [],
        "right": [],
    })
    const [additionalChartsData, setAdditionalChartsData] = useState(null);
    const [fromTime, setFromTime] = useState(
        new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000)
            .toISOString()
            .slice(0, 10)
    );
    const [toTime, setToTime] = useState(
        new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
            .toISOString()
            .slice(0, 10)
    );
    const [loadingCharts, setLoadingCharts] = useState({
        "primary": false,
        "left": false,
        "right": false,
        "boxPlot": false,
    });
    const [loadingAdditionalCharts, setLoadingAdditionalCharts] = useState(false);


    const fetchIbaData = async () => {
        try {
            const response = await getIbaData(
                auth,
                {
                    start_date: fromTime?.split("T")?.join(" "),
                    end_date: toTime?.split("T")?.join(" ")
                },
            );
            const tempColumns = await generateGroupedColumns(
                response?.data?.columns,
                true,
                true,
                false,
            );
            setColumns((prev) => [
                ...tempColumns,
            ]);
            setFilteredRows((prev) => response?.data?.tableData?.filter((item) => item?.type == selectedPlateType));
            setReportTableData((prev) => response?.data?.tableData);
        } catch (error) {
            console.log(error);
        }
    };

    const handleRangeSelect = (e) => {
        setSelectedRange(e.target.value);
        if (e.target.value == 0) {
            setFromTime(
                new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000)
                    .toISOString()
                    .slice(0, 10)
            );
            setToTime(new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().slice(0, 10));
        }
        if (e.target.value == 1) {
            setFromTime(
                new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000)
                    .toISOString()
                    .slice(0, 10)
            );
            setToTime(new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().slice(0, 10));
        }
        else if (e.target.value == 3) {
            setFromTime(
                new Date(new Date().getTime() - 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000)
                    .toISOString()
                    .slice(0, 10)
            );
            setToTime(new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().slice(0, 10));
        }
    };

    const fetchData = async () => {
        setLoading(true);
        await fetchIbaData();
        setLoading(false);
    }

    const findParametersData = async (requestData) => {

        try {
            const response = await getParametersDataApi(
                auth,
                requestData,
            );
            if (response?.success) {
                setParametersData((prev) => response?.data);
                setLeftParametersData((prev) => response?.data);
                setRightParametersData((prev) => response?.data);
                setBoxPlotParametersData((prev) => response?.data);
                setAllParametersData((prev) => response?.data);
            } else {
                setParametersData((prev) => null);
                setLeftParametersData((prev) => null);
                setRightParametersData((prev) => null);
                setBoxPlotParametersData((prev) => null);
                setAllParametersData((prev) => null);
            }
        } catch (error) {
            console.log(error);
            setParametersData((prev) => null);
            setLeftParametersData((prev) => null);
            setRightParametersData((prev) => null);
            setBoxPlotParametersData((prev) => null);
            setAllParametersData((prev) => null);
        }
    }

    const getGraphsData = async (type) => {

        const requestData = type == "primary" ? parametersData : (type == "left" ? leftParametersData : rightParametersData);

        setLoadingCharts((prev) => ({
            ...prev,
            [type]: true,
        }))

        try {
            const response = await getGraphsDataApi(
                auth,
                requestData,
            );
            if (response?.success) {
                type == "primary" && setChartsData((prev) => response?.data);
                type == "left" && setLeftChartsData((prev) => response?.data);
                type == "right" && setRightChartsData((prev) => response?.data);

            } else {
                type == "primary" && setChartsData((prev) => null);
                type == "left" && setLeftChartsData((prev) => null);
                type == "right" && setRightChartsData((prev) => null);
            }
        } catch (error) {
            console.log(error);
            type == "primary" && setChartsData((prev) => null);
            type == "left" && setLeftChartsData((prev) => null);
            type == "right" && setRightChartsData((prev) => null);
        }

        setLoadingCharts((prev) => ({
            ...prev,
            [type]: false,
        }))
    }

    const getBoxPlotGraphsData = async (type) => {

        setLoadingCharts((prev) => ({
            ...prev,
            [type]: true,
        }))

        try {
            const response = await getBoxPlotGraphsDataApi(
                auth,
                boxPlotParametersData,
            );
            if (response?.success) {
                setBoxPlotChartsData((prev) => response?.data);

            } else {
                setBoxPlotChartsData((prev) => null);
            }
        } catch (error) {
            console.log(error);
            setBoxPlotChartsData((prev) => null);
        }

        setLoadingCharts((prev) => ({
            ...prev,
            [type]: false,
        }))
    }

    const getAdditionalGraphsData = async () => {

        // setAdditionalChartsData((prev) => dummyAdditionalChartsData);
        // return;
        setLoadingAdditionalCharts((prev) => true);
        let requestData = {
            plate_ids: selectedRows,
        };
        try {
            const response = await getAdditionalGraphsDataApi(
                auth,
                requestData,
            );
            if (response?.success) {
                setAdditionalChartsData((prev) => response?.data)

            } else {
                setAdditionalChartsData((prev) => null);
            }
        } catch (error) {
            setAdditionalChartsData((prev) => null);
        }
        setLoadingAdditionalCharts((prev) => false);
    }

    const handleResetParameters = async (type) => {
        type == "primary" && setParametersData((prev) => allParametersData);
        type == "left" && setLeftParametersData((prev) => allParametersData);
        type == "right" && setRightParametersData((prev) => allParametersData);
        type == "boxPlot" && setBoxPlotParametersData((prev) => allParametersData);

        type == "primary" && setChartsData((prev) => null);
        type == "left" && setLeftChartsData((prev) => null);
        type == "right" && setRightChartsData((prev) => null);
        type == "boxPlot" && setBoxPlotChartsData((prev) => null);
    }

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        const getPlateParamatersData = async () => {
            let requestData = {
                plate_ids: selectedRows,
                // timeStamps: selectedTimeStamps
            };
            await findParametersData(requestData);
        }

        getPlateParamatersData();

    }, [selectedRows]);

    const handleGetGraphsDataClick = async (type) => {
        type == "primary" && await getGraphsData("primary");
        type == "left" && await getGraphsData("left");
        type == "right" && await getGraphsData("right");
        type == "boxPlot" && await getBoxPlotGraphsData("boxPlot");
    }

    const handleChangeReference = (type, value) => {

        console.log(value, typeof value)

        let val = isNaN(value) ? 0 : Number(value);

        console.log(val, typeof val)

        setReferencePoint((prev) => {
            let index = prev[type]?.indexOf(val);
            let newArray = [...prev[type]];
            if (index == -1) newArray.push(val);
            return {
                ...prev,
                [type]: newArray,
            }
        })
    }

    const handleResetReferencePoint = (type) => {
        setReferencePoint((prev) => ({
            ...prev,
            [type]: []
        }))
    }

    const getOrder = (columns) => {

        console.log(columns, "columns");
        let newArray = [];

        columns?.forEach((column) => {
            newArray.push(column?.value ? column?.value : column?.field);
        })

        return newArray;
    }

    useEffect(() => {
        setFilteredRows((prev) => {
            let newData = reportTableData?.filter((item) => item?.type == selectedPlateType);
            return newData;
        })
    }, [selectedPlateType])

    useEffect(() => {

        console.log("aaaaa")
        if (additionalChartsData) {

            console.log("jbahkh");
            const elem = document.getElementById("additionalGraphs");
            setTimeout(() => {
                elem.scrollIntoView({
                    behavior: "smooth",
                    block: "center",
                });
            }, 0)

        }
    }, [additionalChartsData])

    console.log(referencePoint, "rowsIba");

    return (
        <div className="relative flex flex-col gap-4 h-full">
            <div className="flex flex-row gap-3 pt-2 overflow-x-auto">
                <div className="min-w-[110px]">
                    <Select
                        size='sm'
                        borderColor="#CAC5CD"
                        color="#605D64"
                        value={selectedRange}
                        variant="outline"
                        className="!rounded-md !text-xs !font-medium !text-[#6b6475]"
                        onChange={(e) => handleRangeSelect(e)}
                    >
                        <option key="Last 7 days" value={0}>
                            Last 7 days
                        </option>
                        <option key="Last 1 month" value={1}>
                            Last 1 month
                        </option>
                        <option key="Last 24 hours" value={3}>
                            Last 24 hours
                        </option>
                        <option key="custom" value={2}>
                            Custom
                        </option>

                    </Select>
                </div>
                {selectedRange == 2 && (
                    <div className="min-w-[110px]">
                        <FloatingInput
                            size="sm"
                            borderColor="#CAC5CD"
                            text="from"
                            type="date"
                            setDateTime={setFromTime}
                            value={fromTime}
                            textColor="#6b6475"
                            textSize="14px"
                        />
                    </div>
                )}
                {selectedRange == 2 && (
                    <div className="min-w-[110px]">
                        <FloatingInput
                            size="sm"
                            borderColor="#CAC5CD"
                            text="to"
                            type="date"
                            setDateTime={setToTime}
                            value={toTime}
                            textColor="#6b6475"
                            textSize="14px"
                        />
                    </div>
                )}
                <SecondaryButton
                    height={"32px"}
                    width={"80px"}
                    text={loading ? <Spinner /> : "Apply"}
                    disable={loading}
                    onClick={() => fetchData()}
                />
            </div>

            <div className="flex flex-col gap-4 py-[8px] px-3 bg-white rounded-xl justify-start pb-40">
                <div className='flex flex-row justify-between w-full overflow-x-auto'>
                    <p className="rounded-e-xl rounded-s-xl text-xl font-medium">
                        IBA Dashboard
                    </p>

                    {
                        filteredRows && columns && (
                            <ExlcButton order={getOrder(columns)} data={filteredRows} name={"IBA_Data"} />
                        )
                    }
                </div>

                <div className='flex flex-row justify-between overflow-x-auto'>
                    <div className="flex flex-row gap-2 w-full">
                        <div className='rounded-md p-1 w-[100px] text-center border border-gray-300 hover:bg-slate-100 cursor-pointer' style={{ backgroundColor: selectedPlateType == "mulpic" ? "rgb(226 232 240)" : "white" }} onClick={() => setSelectedPlateType("mulpic")}>
                            Mulpic
                        </div>
                        <div className='rounded-md p-1 w-[100px] text-center border border-gray-300 hover:bg-slate-100 cursor-pointer' style={{ backgroundColor: selectedPlateType == "non-mulpic" ? "rgb(226 232 240)" : "white" }} onClick={() => setSelectedPlateType("non-mulpic")}>

                            Non-Mulpic
                        </div>
                    </div>

                </div>



                {columns && (
                    <div className="w-full">
                        <IbaTable
                            columns={columns || []}
                            rowData={filteredRows || []}
                            selectedRows={selectedRows}
                            setSelectedRows={setSelectedRows}
                            selectedTimeStamps={selectedTimeStamps}
                            setSelectedTimeStamps={setSelectedTimeStamps}
                            hideFooter={false}
                            pageSizeOptions={[10, 30, 50]}
                            pagination={true}
                            initialState={{
                                pagination: { paginationModel: { pageSize: 10, page: 0 } },
                            }}
                            slots={{
                                footer: CustomFooter
                            }}
                        />
                    </div>
                )}

                <div className='flex flex-row w-full justify-start'>
                    <SecondaryButton
                        height={"32px"}
                        width={"80px"}
                        text={loading || loadingAdditionalCharts ? <Spinner /> : "Analyze"}
                        disable={loading || loadingAdditionalCharts}
                        onClick={() => getAdditionalGraphsData()}
                    />
                </div>


                {(additionalChartsData) && <div className='flex rounded-md flex-col gap-2 p-4 bg-slate-100' id="additionalGraphs">
                    <p className='text-lg font-medium'>Additional Graphs</p>
                    <div className='grid grid-cols-1 lg:grid-cols-2 rounded-md gap-4 bg-slate-100 overflow-x-scroll w-full overflow-y-hidden'>
                        {
                            Object.keys(additionalChartsData)?.map((key) => {
                                return (
                                    <div className='flex flex-col gap-2'>
                                        <p className='text-lg font-medium capitalize'>{key}</p>
                                        {/* <div className='flex flex-row gap-2'>
                                            <Menu size='sm'>
                                                <MenuButton size={'sm'} as={Button} rightIcon={<ChevronDownIcon />} width={'250px'} variant='outline' fontWeight={'normal'} textAlign={'left'} borderColor={'gray.300'} borderWidth={'2px'} backgroundColor={'white'}>
                                                    Select Parameters
                                                </MenuButton>
                                                <MenuList maxHeight={'200px'} overflowY={'scroll'} gap={2} padding={2}>
                                                    <Stack>
                                                        {
                                                            Object?.keys(leftParametersData)?.map((item, idx) => (
                                                                <CustomInput2 plateId={item} rowData={leftParametersData} setRowData={setLeftParametersData} />
                                                            ))
                                                        }
                                                    </Stack>
                                                </MenuList>
                                            </Menu>
                                            <SecondaryButton
                                                height={"31px"}
                                                width={"80px"}
                                                text={loadingCharts?.["left"] ? <Spinner /> : "Apply"}
                                                disable={loadingCharts?.["left"]}
                                                onClick={() => handleGetGraphsDataClick("left")}
                                            />
                                            <SecondaryButton
                                                height={"31px"}
                                                width={"80px"}
                                                text={loadingCharts?.["left"] ? <Spinner /> : "Reset"}
                                                disable={loadingCharts?.["left"]}
                                                onClick={() => handleResetParameters("left")}
                                            />
                                        </div> */}
                                        <div className='border rounded-md p-1 bg-white overflow-x-scroll w-full overflow-y-hidden flex flex-col gap-2'>
                                            <LineChartOverlay series={additionalChartsData?.[key]} />
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>}

                {(parametersData) && <div className='flex rounded-md flex-col gap-2 p-4 bg-slate-100'>
                    <div className='flex flex-col gap-2 rounded-md bg-slate-100 overflow-x-scroll w-full overflow-y-hidden'>
                        <p className='text-lg font-medium'>Select parameters for visualization</p>
                        <div className='flex flex-col lg:flex-row gap-2 w-full justify-between'>
                            <div className='flex flex-row gap-2'>
                                <Menu size='sm'>
                                    <MenuButton size={'sm'} as={Button} rightIcon={<ChevronDownIcon />} width={'250px'} variant='outline' fontWeight={'normal'} textAlign={'left'} borderColor={'gray.300'} borderWidth={'2px'} backgroundColor={'white'}>
                                        Select Parameters
                                    </MenuButton>
                                    <MenuList maxHeight={'200px'} overflowY={'scroll'} gap={2} padding={2}>
                                        <Stack>
                                            {
                                                Object?.keys(parametersData)?.map((item, idx) => (
                                                    <CustomInput2 plateId={item} rowData={parametersData} setRowData={setParametersData} />
                                                ))
                                            }
                                        </Stack>
                                    </MenuList>
                                </Menu>
                                <SecondaryButton
                                    height={"31px"}
                                    width={"80px"}
                                    text={loadingCharts?.["primary"] ? <Spinner /> : "Apply"}
                                    disable={loadingCharts?.["primary"]}
                                    onClick={() => handleGetGraphsDataClick("primary")}
                                />
                                <SecondaryButton
                                    height={"31px"}
                                    width={"80px"}
                                    text={loadingCharts?.["primary"] ? <Spinner /> : "Reset"}
                                    disable={loadingCharts?.["primary"]}
                                    onClick={() => handleResetParameters("primary")}
                                />
                            </div>
                            <div className='flex flex-row gap-2'>
                                <Input
                                    borderColor="#CAC5CD"
                                    color="black"
                                    size='sm'
                                    placeholder='Add Reference'
                                    variant="outline"
                                    className="!rounded-md !text-xs !font-medium !text-black !bg-white"
                                    ref={primaryRef}
                                    maxWidth={250}
                                    // defaultValue={primaryRef.current.value}
                                    type='number'
                                // onChange={(e) => handleChangeReference("primary", e.target.value)}
                                />
                                <SecondaryButton
                                    height={"31px"}
                                    width={"80px"}
                                    text={loadingCharts?.["primary"] ? <Spinner /> : "Add"}
                                    disable={loadingCharts?.["primary"]}
                                    onClick={() => handleChangeReference("primary", primaryRef.current.value)}
                                />
                                <SecondaryButton
                                    height={"31px"}
                                    width={"80px"}
                                    text={loadingCharts?.["primary"] ? <Spinner /> : "Reset"}
                                    disable={loadingCharts?.["primary"]}
                                    onClick={() => handleResetReferencePoint("primary")}
                                />
                            </div>
                        </div>


                        <div className='border rounded-md p-1 bg-white overflow-x-scroll w-full overflow-y-hidden flex flex-col gap-2'>
                            <LineChartOverlay series={chartsData} referencePoint={referencePoint["primary"]} />

                        </div>
                        <div className='p-1 border rounded-md bg-white overflow-x-scroll w-full overflow-y-hidden flex flex-col gap-2'>
                            <p>Meta Data</p>
                            <hr />
                            <div className='flex flex-wrap gap-4'>
                                {
                                    chartsData?.map((chartItem, index) => (
                                        <MetaDataComponent data={chartItem} index={index} />
                                    ))
                                }
                            </div>

                        </div>
                    </div>
                </div>}

                {(parametersData) && <div className='flex rounded-md flex-col gap-2 p-4 bg-slate-100'>
                    <div className='grid grid-cols-1 lg:grid-cols-2 rounded-md gap-4 bg-slate-100 overflow-x-scroll w-full overflow-y-hidden'>
                        <div className='flex flex-col gap-2'>
                            <p className='text-lg font-medium'>Select parameters for visualization</p>
                            <div className='flex flex-col gap-2 w-full justify-between'>
                                <div className='flex flex-row gap-2'>
                                    <Menu size='sm'>
                                        <MenuButton size={'sm'} as={Button} rightIcon={<ChevronDownIcon />} width={'250px'} variant='outline' fontWeight={'normal'} textAlign={'left'} borderColor={'gray.300'} borderWidth={'2px'} backgroundColor={'white'}>
                                            Select Parameters
                                        </MenuButton>
                                        <MenuList maxHeight={'200px'} overflowY={'scroll'} gap={2} padding={2}>
                                            <Stack>
                                                {
                                                    Object?.keys(leftParametersData)?.map((item, idx) => (
                                                        <CustomInput2 plateId={item} rowData={leftParametersData} setRowData={setLeftParametersData} />
                                                    ))
                                                }
                                            </Stack>
                                        </MenuList>
                                    </Menu>
                                    <SecondaryButton
                                        height={"31px"}
                                        width={"80px"}
                                        text={loadingCharts?.["left"] ? <Spinner /> : "Apply"}
                                        disable={loadingCharts?.["left"]}
                                        onClick={() => handleGetGraphsDataClick("left")}
                                    />
                                    <SecondaryButton
                                        height={"31px"}
                                        width={"80px"}
                                        text={loadingCharts?.["left"] ? <Spinner /> : "Reset"}
                                        disable={loadingCharts?.["left"]}
                                        onClick={() => handleResetParameters("left")}
                                    />
                                </div>
                                <div className='flex flex-row gap-2'>
                                    <Input
                                        borderColor="#CAC5CD"
                                        color="black"
                                        size='sm'
                                        placeholder='Add Reference'
                                        variant="outline"
                                        className="!rounded-md !text-xs !font-medium !text-black !bg-white"
                                        ref={leftRef}
                                        maxWidth={250}
                                        // defaultValue={primaryRef.current.value}
                                        type='number'
                                    // onChange={(e) => handleChangeReference("primary", e.target.value)}
                                    />
                                    <SecondaryButton
                                        height={"31px"}
                                        width={"80px"}
                                        text={loadingCharts?.["left"] ? <Spinner /> : "Add"}
                                        disable={loadingCharts?.["left"]}
                                        onClick={() => handleChangeReference("left", leftRef.current.value)}
                                    />
                                    <SecondaryButton
                                        height={"31px"}
                                        width={"80px"}
                                        text={loadingCharts?.["left"] ? <Spinner /> : "Reset"}
                                        disable={loadingCharts?.["left"]}
                                        onClick={() => handleResetReferencePoint("left")}
                                    />

                                </div>
                            </div>
                            <div className='border rounded-md p-1 bg-white overflow-x-scroll w-full overflow-y-hidden flex flex-col gap-2'>
                                <LineChartOverlay series={leftChartsData} referencePoint={referencePoint["left"]} />
                            </div>
                            <div className='p-1 border rounded-md bg-white overflow-x-scroll w-full overflow-y-hidden flex flex-col gap-2'>
                                <p>Meta Data</p>
                                <hr />
                                <div className='flex flex-wrap gap-4'>
                                    {
                                        leftChartsData?.map((chartItem, index) => (
                                            <MetaDataComponent data={chartItem} index={index} />
                                        ))
                                    }
                                </div>

                            </div>
                        </div>
                        <div className='flex flex-col gap-2'>
                            <p className='text-lg font-medium'>Select parameters for visualization</p>
                            <div className='flex flex-col gap-2 w-full justify-between'>
                                <div className='flex flex-row gap-2'>
                                    <Menu size='sm'>
                                        <MenuButton size={'sm'} as={Button} rightIcon={<ChevronDownIcon />} width={'250px'} variant='outline' fontWeight={'normal'} textAlign={'left'} borderColor={'gray.300'} borderWidth={'2px'} backgroundColor={'white'}>
                                            Select Parameters
                                        </MenuButton>
                                        <MenuList maxHeight={'200px'} overflowY={'scroll'} gap={2} padding={2}>
                                            <Stack>
                                                {
                                                    Object?.keys(rightParametersData)?.map((item, idx) => (
                                                        <CustomInput2 plateId={item} rowData={rightParametersData} setRowData={setRightParametersData} />
                                                    ))
                                                }
                                            </Stack>
                                        </MenuList>
                                    </Menu>
                                    <SecondaryButton
                                        height={"31px"}
                                        width={"80px"}
                                        text={loadingCharts?.["right"] ? <Spinner /> : "Apply"}
                                        disable={loadingCharts?.["right"]}
                                        onClick={() => handleGetGraphsDataClick("right")}
                                    />
                                    <SecondaryButton
                                        height={"31px"}
                                        width={"80px"}
                                        text={loadingCharts?.["right"] ? <Spinner /> : "Reset"}
                                        disable={loadingCharts?.["right"]}
                                        onClick={() => handleResetParameters("right")}
                                    />
                                </div>
                                <div className='flex flex-row gap-2'>
                                    <Input
                                        borderColor="#CAC5CD"
                                        color="black"
                                        size='sm'
                                        placeholder='Add Reference'
                                        variant="outline"
                                        className="!rounded-md !text-xs !font-medium !text-black !bg-white"
                                        ref={rightRef}
                                        maxWidth={250}
                                        // defaultValue={primaryRef.current.value}
                                        type='number'
                                    // onChange={(e) => handleChangeReference("primary", e.target.value)}
                                    />
                                    <SecondaryButton
                                        height={"31px"}
                                        width={"80px"}
                                        text={loadingCharts?.["right"] ? <Spinner /> : "Add"}
                                        disable={loadingCharts?.["right"]}
                                        onClick={() => handleChangeReference("right", rightRef.current.value)}
                                    />
                                    <SecondaryButton
                                        height={"31px"}
                                        width={"80px"}
                                        text={loadingCharts?.["right"] ? <Spinner /> : "Reset"}
                                        disable={loadingCharts?.["right"]}
                                        onClick={() => handleResetReferencePoint("right")}
                                    />

                                </div>
                            </div>
                            <div className='border rounded-md p-1 bg-white overflow-x-scroll w-full overflow-y-hidden flex flex-col gap-2'>
                                <LineChartOverlay series={rightChartsData} referencePoint={referencePoint["right"]} />
                            </div>
                            <div className='p-1 border rounded-md bg-white overflow-x-scroll w-full overflow-y-hidden flex flex-col gap-2'>
                                <p>Meta Data</p>
                                <hr />
                                <div className='flex flex-wrap gap-4'>
                                    {
                                        rightChartsData?.map((chartItem, index) => (
                                            <MetaDataComponent data={chartItem} index={index} />
                                        ))
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                </div>}

                <p className='mt-10 text-xl font-medium'>Box Plot Analysis</p>

                {(boxPlotParametersData) && <div className='flex rounded-md flex-col gap-2 p-4 bg-slate-100'>
                    <div className='flex flex-col gap-2 rounded-md bg-slate-100 overflow-x-scroll w-full overflow-y-hidden'>
                        <p className='text-lg font-medium'>Select parameters for visualization</p>
                        <div className='flex flex-row w-full justify-between'>
                            <div className='flex flex-row gap-2'>
                                <Menu size='sm'>
                                    <MenuButton size={'sm'} as={Button} rightIcon={<ChevronDownIcon />} width={'250px'} variant='outline' fontWeight={'normal'} textAlign={'left'} borderColor={'gray.300'} borderWidth={'2px'} backgroundColor={'white'}>
                                        Select Parameters
                                    </MenuButton>
                                    <MenuList maxHeight={'200px'} overflowY={'scroll'} gap={2} padding={2}>
                                        <Stack>
                                            {
                                                Object?.keys(boxPlotParametersData)?.map((item, idx) => (
                                                    <CustomInput2 plateId={item} rowData={boxPlotParametersData} setRowData={setBoxPlotParametersData} isTwoAxis={false} />
                                                ))
                                            }
                                        </Stack>
                                    </MenuList>
                                </Menu>
                                <SecondaryButton
                                    height={"31px"}
                                    width={"80px"}
                                    text={loadingCharts?.["boxPlot"] ? <Spinner /> : "Apply"}
                                    disable={loadingCharts?.["boxPlot"]}
                                    onClick={() => handleGetGraphsDataClick("boxPlot")}
                                />
                                <SecondaryButton
                                    height={"31px"}
                                    width={"80px"}
                                    text={loadingCharts?.["boxPlot"] ? <Spinner /> : "Reset"}
                                    disable={loadingCharts?.["boxPlot"]}
                                    onClick={() => handleResetParameters("boxPlot")}
                                />
                            </div>

                        </div>

                        <div className='border rounded-md p-1 bg-white overflow-x-scroll w-full overflow-y-hidden flex flex-col gap-2'>
                            <BoxPlotChart series={boxPlotChartsData} />

                        </div>

                    </div>
                </div>}

            </div>

        </div>
    )
}

export default Iba;

