import { useNavigate } from "react-router-dom";
import TonalButton from "../../../../util/Buttons/TonalButton";
import PrimaryButton from "../../../../util/Buttons/PrimaryButton";
import FloatingInput from "../../../../util/VisionUtils/FloatingInput";
import SecondaryButton from "../../../../util/Buttons/SecondaryButton";
import { useState } from "react";
import { Select } from "@chakra-ui/react";
import AlertTable from "../Tables/AlertTable";

const CamAlerts = () => {
  const navigate = useNavigate();
  const [alerts, setAlerts] = useState([]);
  return (
    <div className="flex flex-col gap-4 p-6 w-full">
      <p className="text-[#3E3C42] text-lg font-medium">Camera 1 Alerts</p>
      {alerts.length > 0 ? (
        <>
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center w-full gap-2">
            <div className="overflow-x-auto h-fit max-w-full">
              <div className="flex gap-3 items-center py-2">
                <Select w={"150px"} minW={"150px"}>
                  {["Custom", "Last 7 days"]?.map((item) => {
                    return (
                      <option value={item} key={item}>
                        {item}
                      </option>
                    );
                  })}
                </Select>
                <div className="w-fit">
                  <FloatingInput text="From" type="date" />
                </div>
                <div className="w-fit">
                  <FloatingInput text="To" type="date" />
                </div>
                <SecondaryButton
                  text={"Apply"}
                  width={"fit-content"}
                  height={"40px"}
                />
              </div>
            </div>
            <TonalButton
              Icon={<img src="/selfServiceIcons/settings.svg" alt="settings" />}
              text={"Alert settings"}
              width={"fit-content"}
              height={"40px"}
              onClick={() => navigate("/Sandbox/Alert/1234")}
            />
          </div>
          <AlertTable />
        </>
      ) : (
        <div className="w-full h-[50vh] border-2 border-dashed border-[#3A74CA] rounded-lg flex justify-center items-center">
          <div className="flex flex-col gap-4 items-center">
            <img src="/selfServiceIcons/noAlert.svg" className="h-auto w-[150px] xs:w-auto" alt="noAlert" />
            <p className="text-[#084298] text-xl font-medium text-center">
              No Alert Created yet ! Click on the below button to create an
              alert
            </p>
            <PrimaryButton
              text={"Create Alert"}
              width={"fit-content"}
              onClick={() => navigate("/Sandbox/Alert/Create/1234")}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default CamAlerts;
