import { InfoOutlineIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  ModalHeader,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  IconButton,
} from "@chakra-ui/react";
import { Close } from "@mui/icons-material";
import { useWindowSize } from "@uidotdev/usehooks";

export const InfoModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const size = useWindowSize();

  return (
    <>
      <IconButton
        size={"xs"}
        isRound={true}
        icon={
          <InfoOutlineIcon
            fontSize={"20px"}
            style={{ cursor: "pointer" }}
          />
        }
        onClick={onOpen}
      />

      {size.width >= 768 ? (
        <Modal
          isOpen={isOpen}
          onClose={onClose}
          isCentered="true"
          returnFocusOnClose={false}
        >
          <ModalOverlay />
          <ModalContent
            maxH={"90vh"}
            maxW={"80vw"}
          >
            <ModalHeader
              padding="0px"
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              position={"relative"}
            >
              <IconButton
                position={"absolute"}
                top={-5}
                rounded={"full"}
                bg={"white"}
                cursor={"pointer"}
                icon={<Close sx={{ color: "gray" }} />}
                onClick={onClose}
                boxShadow={"md"}
              />
            </ModalHeader>
            <ModalBody
              overflowY="auto"
              display={"flex"}
              flexDirection={"column"}
              gap={3}
              padding={4}
            >
              {" "}
              <div className="flex flex-col gap-2 shadow-md px-4 py-2 border rounded-md">
                <h2 className="font-semibold text-xl">
                  Permeability threshold
                </h2>
                <ul className="ml-3 text-base list-disc list-outside">
                  <li>
                    <strong>Excellent : </strong> 17 to 20
                  </li>
                  <li>
                    <strong>Good : </strong> 15 to 17 & 20 to 26
                  </li>
                  <li>
                    <strong>Average : </strong> 13 to 15 & 26 to 30
                  </li>
                  <li>
                    <strong>Poor : </strong> &lt;13 &amp; &gt;30
                  </li>
                  <li>
                    Notches with width greater than 60px will be considered as
                    V-notches and greater than 100px will be considered as a
                    case of missing grate bars.
                  </li>
                  <li>
                    Email alerts are triggered for V-notches/Grate bar missing
                    with width greater than 100px.
                  </li>
                </ul>
              </div>
              <div className="shadow-md py-2 border rounded-md">
                <h2 className="px-4 font-semibold text-xl">
                  Sample pallet board permeability calculation
                </h2>
                <img
                  src="/sinterbelt/permeability_calc.png"
                  alt="permeability_calc"
                />
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        <Drawer
          isOpen={isOpen}
          onClose={onClose}
          size="full"
        >
          <DrawerOverlay />
          <DrawerContent width={"full"}>
            <DrawerHeader
              padding="0px"
              width={"full"}
            >
              <div className="flex flex-row justify-center items-center w-full">
                <IconButton
                  cursor={"pointer"}
                  icon={<Close sx={{ color: "gray" }} />}
                  onClick={onClose}
                  isRound={true}
                />
              </div>
            </DrawerHeader>
            <DrawerBody
              overflowY="auto"
              padding={1}
              width={"full"}
              display={"flex"}
              flexDirection={"column"}
              gap={4}
            >
              <div className="flex flex-col gap-1 shadow-md px-4 py-2 border rounded-md w-full">
                <h2 className="font-semibold text-lg">
                  Permeability threshold
                </h2>
                <ul className="ml-4 text-sm list-disc list-outside">
                  <li>
                    <strong>Excellent : </strong> 17 to 20
                  </li>
                  <li>
                    <strong>Good : </strong> 15 to 17 & 20 to 26
                  </li>
                  <li>
                    <strong>Average : </strong> 13 to 15 & 26 to 30
                  </li>
                  <li>
                    <strong>Poor : </strong> &lt;13 &amp; &gt;30
                  </li>
                  <li>
                    Notches with width greater than 60px will be considered as
                    V-notches and greater than 110px will be considered as a
                    case of missing grate bars.
                  </li>
                  <li>
                    Email alerts are triggered for V-notches/Grate bar missing
                    with width greater than 100px.
                  </li>
                </ul>
              </div>
              <div className="shadow-md py-2 border rounded-md">
                <h2 className="px-4 font-semibold text-base">
                  Sample pallet board permeability calculation
                </h2>
                <img
                  src="/sinterbelt/permeability_calc_mobile.png"
                  alt="permeability_calc"
                  className="w-full h-full"
                />
              </div>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};
