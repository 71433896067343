import { capitalizeFirstLetter } from "../../../util/sentenceCase";
import { formatDateTime } from "./DateTimeFormatter";

function formatColumnName(columnName) {
  if (!columnName) return "";

  // First, replace underscores with spaces
  let formattedName = columnName.split("_").join(" ");

  // Then, add a space before each uppercase letter and capitalize each word
  formattedName = formattedName.replace(/([a-z])([A-Z])/g, "$1 $2");

  // Capitalize the first letter of each word
  formattedName = formattedName
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedName;
}

export const generateDynamicColumns = async (
  columnNames,
  filterable = false,
  sortable = false,
  disableColumnMenu = true,
  flexMap,
  minWidthMap
) => {
  //   dynamic columns
  const dynamicColumns = columnNames.map((columnName, index) => {
    let columnConfig = {
      field: columnName,
      headerName: capitalizeFirstLetter(formatColumnName(columnName)),

      // checking for array and index is not out bound value both needs to be of same length as that of columnNames
      flex: flexMap && flexMap?.[columnName] ? flexMap[columnName] : 1,
      minWidth:
        minWidthMap && minWidthMap?.[columnName]
          ? minWidthMap[columnName]
          : 110,
      sortable: sortable,
      disableColumnMenu: disableColumnMenu,
      filterable: filterable,
    };
    switch (columnName) {
      // case "TOC":
      case "TOM":
      case "startTime":
      case "endTime":
        
        columnConfig.valueFormatter = (params) => {
          return params.value ? formatDateTime(params.value) : "";
        };
        columnConfig.valueGetter = (params) => {
          const value = params.value
          const isMilliseconds = value >= 1000000000000; // checking if time stamp is in miliseconds or not after 2001

          const date = new Date(
            isMilliseconds ? value : value * 1000
          );
          return date
        };
        columnConfig.type = "dateTime";
        break;
      case "timestamp":
        
        columnConfig.valueFormatter = (params) => {
          console.log(params,"dt");
          return params.value ? formatDateTime(params.value) : "";
        };
        columnConfig.valueGetter = (params) => {
          const value = params?.row?.createdAt
          const isMilliseconds = value >= 1000000000000; // checking if time stamp is in miliseconds or not after 2001

          const date = new Date(
            isMilliseconds ? value : value * 1000
          );
          return date
        };
        columnConfig.type = "dateTime";
        break;
      case "plantName":
      case "cameraId":
        columnConfig.valueFormatter = (params) => {
          return params.value ? capitalizeFirstLetter(params.value) : "";
        };
        break;
      case "prevkeys.mps":
        columnConfig.headerName = "Previous belt mps";
        columnConfig.valueFormatter = (params) => {
          if (typeof params.value === "number") {
            return params.value.toFixed(2);
          } else {
            return params.value ? params.value : "";
          }
        };
        break;
      case "BeltMps-PrevKeysMps":
        columnConfig.headerName = "Delta (Mps - Prev belt Mps)";
        columnConfig.valueGetter = ({row}) => {
         return (row?.mps - row?.["prevkeys.mps"])?.toFixed(2)
        };
        break
      default:
        columnConfig.valueFormatter = (params) => {
          if (typeof params.value === "number") {
            return params.value.toFixed(2);
          } else {
            return params.value ? params.value : "";
          }
        };
        break;
    }

    return columnConfig;
  });
  return dynamicColumns;
};
