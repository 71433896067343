const SearchSuggestions = ({ searchSuggestions }) => {
  return (
    <div>
      <div className="search-suggestions rounded-md mt-2 w-[400px]">
        <p className="text-[red]">
          Similar group(s) already exist. Please check if your group is
          duplicate
        </p>
      </div>
      <div className="search-suggestions border bg-white rounded-md shadow-lg shadow-gray-200 mt-2 w-[500px] z-10">
        <div className="p-2">
          <p className="text-gray-400">Groups</p>
          <div className="grid grid-cols-2 gap-2">
            {searchSuggestions?.map((item) => (
              <div className="flex flex-row">
                <img
                  className="w-[30px] h-[30px]"
                  src="/superadmin/Bell.svg"
                  alt=""
                />

                <p className="font-medium text-lg ml-1">
                  {item?.alertGroupName}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchSuggestions;
