import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
} from "@chakra-ui/react";
import { ChakraProvider } from "@chakra-ui/react";
import { useState } from "react";
import { stringDecimalFix } from "../Tables/AlertTable";
import ClearIcon from "@mui/icons-material/Clear";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";

const AlertImagesModal = ({ openModal, closeModal, row }) => {
  const [type, setType] = useState("");
  let types = [
    {
      name: "Prev dumping",
      value: "prev",
    },
    // {
    //   name: "Prev picking",
    //   value: "pick",
    // },
    {
      name: "Present",
      value: "",
    },
  ];
  return (
    <ChakraProvider>
      <Modal isOpen={openModal} onClose={closeModal} isCentered="true">
        <ModalOverlay />
        <ModalContent style={{ borderRadius: "12px" }} maxW="700px">
          <ModalHeader padding="0px">
            <div className="pl-6 py-3 pr-2 bg-[#F5F5F5] items-center flex justify-between text-sm whitespace-nowrap rounded-t-xl">
              <div className="flex gap-3 items-center text-black font-medium">
                <AccessAlarmIcon sx={{ color: "#024d87" }} />
                <p>{new Date(row.timestamp * 1000).toLocaleString()}</p>
              </div>
              <ClearIcon
                className="cursor-pointer"
                onClick={() => closeModal()}
              />
            </div>
          </ModalHeader>
          <ModalBody p="16px" rounded="12px">
            <div className="flex flex-col md:flex-row gap-4">
              <div className="flex flex-col gap-2 w-full">
                <p className="text-xs text-[#605D64] font-medium">Violations</p>
                <div className="flex flex-col gap-2 py-3 pl-4 pr-6 bg-[#FCEEEE] text-[#3E3C42] text-sm font-medium rounded-xl h-full">
                  <p>{stringDecimalFix(row?.alertMessages)}</p>
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex gap-2 items-center justify-end">
                  {types.map((item) => {
                    return (
                      <div
                        className="py-[6px] px-4 rounded-[32px] capitalize border text-sm"
                        style={{
                          backgroundColor:
                            type == item.value ? "#6CA6FC33" : "white",
                          borderColor:
                            type == item.value ? "#6CA6FC" : "#EBEBEB",
                          color: type == item.value ? "#3E3C42" : "#605D64",
                          fontWeight: type == item.value ? 500 : 400,
                          cursor: type == item.value ? "" : "pointer",
                        }}
                        onClick={() => setType(item.value)}
                      >
                        {item.name}
                      </div>
                    );
                  })}
                </div>
                <div className="relative w-full md:w-[400px] h-[150px] bg-black rounded-xl flex justify-center items-center">
                  <img
                    src={
                      row?.[`${type ? type + "_" : ""}` + "loader_image"] ??
                      "https://clarionhealthcare.com/wp-content/uploads/2020/12/default-fallback-image.png"
                    }
                    alt="loaderImg"
                    className="max-h-full w-auto"
                  />
                </div>
                <div className="relative w-full md:w-[400px] h-[150px] bg-black rounded-xl flex justify-center items-center">
                  <img
                    src={
                      row?.[`${type ? type + "_" : ""}` + "ocr_image"] ??
                      "https://clarionhealthcare.com/wp-content/uploads/2020/12/default-fallback-image.png"
                    }
                    alt="ocrImg"
                    className="max-h-full w-auto"
                  />
                </div>
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </ChakraProvider>
  );
};

export default AlertImagesModal;
