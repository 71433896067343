import { useState, useEffect } from "react";

import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import PhotoGallery from "../FeedComponent/PhotoGallery";
import TonalButton from "../../../util/Buttons/TonalButton";
import VideoGallery from "../FeedComponent/VideoGallery";

const FeedLibrary = ({ plantId, cameraId, disable, plantCamMap, material }) => {
  const param = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const [camMap, setCamMap] = useState({
    cameraKeys: ["IR camera", "Vision camera"],
    cameraMapping: {
      "Vision camera": ["originalImage", "processedImage"],
      "IR camera": ["irImage", "processedIrImage"],
    },
  });
  const handleTabChange = (index) => {
    setTabIndex((prev) => index);
  };

  const tabs = [
    {
      label: "Photo Gallery",
      index: 0,
      component: (
        <PhotoGallery
          plantId={disable ? plantId : Object.keys(plantCamMap)?.[0]}
          cameraId={
            disable ? cameraId : plantCamMap[Object.keys(plantCamMap)?.[0]]?.[0]
          }
          disable={disable}
          material={material}
          plantCamMap={plantCamMap}
          setCamMap={setCamMap}
          camMap={camMap}
        />
      ),
    },

    {
      label: "Video Gallery",
      index: 1,
      component: (
        <VideoGallery
          plantId={plantId}
          cameraId={cameraId}
          disable={disable}
          plantCamMap={plantCamMap}
          setCamMap={setCamMap}
          camMap={camMap}
          material={material}
        />
      ),
    },
  ];

  //   useEffect(() => {
  //     let tool = capitalize(param.material.toLowerCase()) + " Sizing";
  //     mixpanelFeedLibTrack(page.toLowerCase(), tool);
  //   }, [page]);
  return (
    <div className="flex flex-col gap-0 bg-white p-5 rounded-xl w-full">
      <Tabs index={tabIndex} onChange={handleTabChange}>
        <TabList
          display={"flex"}
          gap={0}
          width={"full"}
          border={"1px solid #EBEBEB"}
          rounded={"8px"}
        >
          {tabs?.map((element, index) => (
            <Tab
              flex={1}
              padding={0}
              key={`${element.label}-${index}-tab`}
              px={"6px"}
              py={"8px"}
              border={0}
            >
              <div
                className={`flex items-center justify-center w-full p-2 cursor-pointer rounded-[4px] ${
                  tabIndex === element.index && "bg-[#E2EDFE]"
                }`}
              >
                <p
                  className={
                    tabIndex === element.index
                      ? "text-[#447ED4] text-base font-semibold"
                      : "text-[#79767D] text-base font-semibold"
                  }
                >
                  {element.label}
                </p>
              </div>
            </Tab>
          ))}
        </TabList>

        <TabPanels p={0}>
          {tabs?.map((element, index) => (
            <TabPanel key={`${element.label}-${index}-tabpanel`} padding={0}>
              {element?.component}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default FeedLibrary;
