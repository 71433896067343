import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { baseURL } from "../../..";
import NavContext from "../../NavContext";
import ManageAlerts from "./ManageAlerts";
import AlertTablePage from "./AlertsTablepage";

export const deactiveAlertApi = async (
  data,
  toast,
  isDelete,
  auth,
  getAlertApi,
  getAlertGroupApi
) => {

  const data1 = {
    alertId: data?.alertId,
  };
  try {
    if (isDelete) {
      data1["isdeleted"] = true;
      data1["status"] = 2;
    } else {
      data1["isdeleted"] = false;
      data1["status"] = 1;
    }
    const requestData = JSON.stringify(data1);
    const response = await axios.patch(
      baseURL + "alert/v1/addAlert",
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    if (response.status == 200) {
      getAlertApi();
      getAlertGroupApi();
      // toast({
      //   title: "Deleted",
      //   description: `The ${data?.deviceInfo?.deviceName} is deactivated`,
      //   status: "success",
      //   duration: 2000,
      //   isClosable: true,
      //   position: "top",
      // });
      //getDeviceApi();
    }
  } catch (error) {
    console.log(error);
  }
};

const AlertsPage = ({
  setShowAlertsTab,
  view,
  setView,
  alertType,
  setAlertType,
  setSelectedAlert,
}) => {
  const { auth } = useContext(NavContext);
  const [alerts, setAlerts] = useState([]);
  const [alertGroups, setAlertGroups] = useState([]);

  const getAlertApi = async () => {
    try {
      //TODO: api after BE done.
      const response = await axios.get(baseURL + "alert/v1/fetch/alerts", {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      if (response.data) {
        let res = structuredClone(response.data);
        let formattedData = res?.map((alert) => {
          let newAlert = structuredClone(alert);
          let newDetails = alert?.alertsDetail?.map((item) => {
            let newItem = structuredClone(item);
            let x = JSON.parse(item?.contacts);
            console.log(typeof x);
            newItem.contacts = x;
            let y = JSON.parse(item?.camera);
            newItem.camera = y;
            let z = JSON.parse(item?.extraParameters);
            newItem.extraParameters = z;
            return newItem;
          });
          newAlert.alertsDetail = newDetails;
          return newAlert;
        })
        setAlerts(formattedData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAlertGroupApi = async () => {
    try {
      const response = await axios.get(baseURL + "alert/v1/fetch/alertGroup", {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });

      if (response.data) {
        setAlertGroups(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  useEffect(() => {
    getAlertApi();
    getAlertGroupApi();
  }, []);

  return (
    <div className="flex flex-col">
      <div className="w-full flex flex-col xs:flex-row justify-between gap-4 items-start xs:items-center">
        <div className="flex gap-0 whitespace-nowrap">
          {["Groups", "Alerts"].map((i, idx) => {
            return (
              <div
                key={idx}
                className={`py-[6px] px-3 capitalize border text-sm w-[125px] flex justify-center`}
                style={{
                  backgroundColor: alertType == i ? "#FFFFED" : "white",
                  borderColor: alertType == i ? "#FFC107" : "#EBEBEB",
                  color: alertType == i ? "#3E3C42" : "#605D64",
                  fontWeight: alertType == i ? 500 : 400,
                  cursor: alertType == i ? "" : "pointer",
                }}
                onClick={() => setAlertType(i)}
              >
                {i === "Groups" ? "Alert Groups" : i}
              </div>
            );
          })}
        </div>
        <div className="flex gap-0">
          {["card", "table"].map((i, idx) => {
            return (
              <div
                key={idx}
                className={`py-[6px] flex gap-1 items-center px-3 capitalize border text-sm $`}
                style={{
                  backgroundColor: view == i ? "#FFFFED" : "white",
                  borderColor: view == i ? "#FFC107" : "#EBEBEB",
                  color: view == i ? "#3E3C42" : "#605D64",
                  fontWeight: view == i ? 500 : 400,
                  cursor: view == i ? "" : "pointer",
                }}
                onClick={() => setView(i)}
              >
                <img
                  src={`/${i == "card" ? "cardIcon" : "tableIcon"}.svg`}
                  alt={i}
                  className="w-[20px] h-[20px] mr-1"
                />{" "}
                {i}
              </div>
            );
          })}
        </div>
      </div>
      {view === "card" && (
        <ManageAlerts
          setShowAlertsTab={setShowAlertsTab}
          alertType={alertType}
          setSelectedAlert={setSelectedAlert}
          alerts={alerts}
          getAlertApi={getAlertApi}
          getAlertGroupApi={getAlertGroupApi}
          alertGroups={alertGroups}
        />
      )}
      {view === "table" && (
        <AlertTablePage
          setShowAlertsTab={setShowAlertsTab}
          alertType={alertType}
          setSelectedAlert={setSelectedAlert}
          alerts={alerts}
          getAlertApi={getAlertApi}
          getAlertGroupApi={getAlertGroupApi}
          alertGroups={alertGroups}
        />
      )}
    </div>
  );
};

export default AlertsPage;
