import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  CircularProgress,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import PrimaryButton from "../../util/Buttons/PrimaryButton";
import OTPInput from "react-otp-input";
import { useLocation, useNavigate } from "react-router-dom";
import { baseURL } from "../..";
import NavContext from ".././NavContext";
import axios from "axios";
import TonalButton from "../../util/Buttons/TonalButton";

const TwoFactorAuthModal = ({
  isOpen,
  onOpen,
  onClose,
  size,
  isExpired,
  setIsExpired,
}) => {
  const { auth, setTwoFactorAuthInfo } = useContext(NavContext);
  const [submitted, setSubmitted] = useState(false);
  const [otp, setOtp] = useState("");
  const [qrCodeImageUrl, setQrCodeImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [fullName, setFullName] = useState(localStorage.getItem("fullname"));
  const toast = useToast();

  const handleClose = () => {
    navigate("/");
    // Set the key in local storage to true
    localStorage.setItem("isAuthenticated", "false");

    // Close the modal
    onClose();
  };
  const fetchQRCode = async () => {
    // setLoading(true);
    try {
      const response = await axios.get(
        baseURL + `googleauth/ga/v1/generateQR/`,
        {
          credentials: "same-origin",
          headers: {
            Accept: "application/json, text/plain, */*",
            "X-Auth-Token": auth,
          },
          responseType: "arraybuffer",
        }
      );
      const imageData = response.data;
      const blob = new Blob([imageData]);
      // Create a data URL from the Blob
      const imageUrl = URL.createObjectURL(blob);
      setQrCodeImageUrl(imageUrl);
    } catch (error) {
      console.log(error);
    } finally {
      // setLoading(false);
    }
  };

  const validateCode = async () => {
    setLoading(true);
    const code = {
      code: +otp,
    };
    try {
      const response = await axios.post(
        baseURL + `googleauth/ga/v1/validate/key/`,
        code,
        {
          headers: {
            "Content-Type": "application/json",
            "X-auth-Token": auth,
          },
        }
      );

      // if (response.data.valid) {
      //   setIsExpired(false);
      //   // Set the key and expiration time in local storage
      //   const expirationTime = Date.now() + 2 * 60 * 60 * 1000; // 2 hrs
      //   localStorage.setItem("is2FAuthenticated", "true");
      //   localStorage.setItem("authExpiration", expirationTime.toString());
      //   onClose(); // Close the modal on successful validation
      // }
      if (response.data.valid) {
        const expirationTimeAdmin = Date.now() + 30 * 60 * 1000; // 30 mins
        const expirationTimeSuperAdmin = Date.now() + 30 * 60 * 1000; // 30 mins

        // const expirationTimeAdmin = Date.now() + 15 * 60 * 1000; // 30 mins
        // const expirationTimeSuperAdmin = Date.now() + 15 * 60 * 1000; // 30 mins

        const authInfo = JSON.parse(localStorage.getItem("authInfo")) || {};

        // Check the route and set the appropriate keys
        const isAdminRoute = location.pathname.includes("/admin");
        const isSuperAdminRoute = location.pathname.includes("/superadmin");

        if (isAdminRoute) {
          authInfo.isAdminAuthenticated = true;

          setTwoFactorAuthInfo((prev) => ({
            //setting the value true to render the tabs in admin
            ...prev,
            is2FAuthenticatedAdmin: true,
          }));

          authInfo.adminAuthExpiration = expirationTimeAdmin;
        }

        if (isSuperAdminRoute) {
          authInfo.isSuperAdminAuthenticated = true;
          setTwoFactorAuthInfo((prev) => ({
            //setting the value true to render the tabs in super admin
            ...prev,
            is2FAuthenticatedSuperAdmin: true,
          }));
          authInfo.superAdminAuthExpiration = expirationTimeSuperAdmin;
        }

        localStorage.setItem("authInfo", JSON.stringify(authInfo));
        onClose();
      } else {
        toast({
          title: "Incorrect code.",
          // description: "Please fill in all required details.",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
      }
      setSubmitted(true);
    } catch (error) {
      console.log(error);
    } finally {
      setOtp("");
      setLoading(false);
    }
  };
  useEffect(() => {
    setSubmitted(false);
  }, [onClose]);

  const handleSubmit = () => {
    validateCode();
  };

  return (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={handleClose}
        isCentered
        size={size}
      >
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(10px)" />
        <ModalContent mx={{ base: 4, sm: 8 }}>
          <div
            className={`text-white w-full h-12 flex ${
              isExpired ? "bg-[#E46962]" : "bg-[#2660B6]"
            } font-semibold justify-center items-center rounded-t-md `}
          >
            {isExpired
              ? "Your session has expired."
              : "Two Factor Authentication"}
          </div>
          <ModalCloseButton className="" color={"white"} />
          <ModalBody>
            {/* {submitted === false ? ( */}
            {/* {loading ? (
              <div className="w-full flex justify-center items-center">
                <Spinner size={"xl"} />
              </div>
            ) : ( */}
            <div>
              <div className="mt-2 flex items-center m-auto mb-4">
                <p className=" font-semibold m-auto">
                  {isExpired
                    ? `Please follow the steps below for continued access.`
                    : `Please follow the steps below to access ${
                        location.pathname.includes("/admin")
                          ? "Admin"
                          : "Super Admin"
                      } Panel`}
                </p>
              </div>
              <p>
                Enter the code on
                <span className="ml-1 mr-1 ">
                  <img src="/google.png" alt="" className="inline-block" />
                </span>
                Google Authenticator below
              </p>
              {/* <p className="mt-2 mb-1">code</p> */}
              <div className="flex items-center w-full justify-center mt-4">
                <OTPInput
                  // className="w-[200px]"
                  value={otp}
                  onChange={setOtp}
                  type="number"
                  numInputs={6}
                  renderSeparator={<span>{"  "}</span>}
                  renderInput={(props) => <input {...props} />}
                  inputStyle={{
                    border: "0.5px solid #79767D",
                    padding: "8px",
                    width: "25%",
                    borderRadius: "5px",
                    // backgroundColor: '#f5f5f5',
                  }}
                  containerStyle={{
                    width: "220px",
                    gap: "5px",
                    marginBottom: "32px",
                    justifySelf: "center",
                  }}
                />
              </div>
              <div className="mt-2 flex justify-center">
                <PrimaryButton
                  onClick={handleSubmit}
                  className="bg-[#084298] text-white px-7 py-2 rounded-md mb-5 "
                  text={loading ? <Spinner /> : "Submit"}
                  width={"fit-content"}
                  disable={otp?.length === 6 ? false : true}
                />
              </div>
              <div className="mt-2 flex">
                {/* <p className="font-semibold">Step 1:</p> */}
                <div className="flex flex-col ml-2">
                  {/* <p>
                    Use
                    <span className="ml-1 mr-1 ">
                      <img src="/google.png" alt="" className="inline-block" />
                    </span>
                    <span className="font-semibold">Google Authenticator</span>{" "}
                    on your mobile phone
                  </p>
                  <p>to scan the QR code</p> */}
                  <p>For new access, generate a QR code</p>
                </div>
              </div>
              {qrCodeImageUrl ? (
                <div className="m-auto">
                  <img src={qrCodeImageUrl} alt="text" className="m-auto" />
                  <div className="flex justify-center">
                    <p className="mb-3">Scan the QR and enter code above</p>
                  </div>
                </div>
              ) : (
                <div className="flex w-full justify-center my-4">
                  <div>
                    <PrimaryButton
                      onClick={fetchQRCode}
                      text={"Generate QR Code"}
                      width={"fit-content"}
                    />
                  </div>
                </div>
              )}
              {/* <p>
                <span className="font-semibold mr-1">Step 2:</span>Enter the
                code given by the app
              </p> */}

              {/* <OTPInput renderInput={(props) => <input {...props} />} /> */}
            </div>
            {/* )} */}
            {/* // ) : ( //{" "}
            <div>
              //{" "}
              <div className="mt-5 w-full flex justify-center">
                // <img src="addusecase_submitted.svg" alt="submitted" />
                //{" "}
              </div>
              //{" "}
              <div className="mt-5 w-full flex justify-center font-bold text-[#034D86] text-lg">
                // Request Submitted //{" "}
              </div>
              //{" "}
              <div className="text-[#141619] font-light text-sm flex justify-center w-full px-2  mt-4">
                // Thank you for submitting your request to add a new use case
                to // our platform. Your input is valuable to us as we continue
                to // enhance our services. We appreciate the effort you've
                taken to // provide the details. //{" "}
              </div>
              //{" "}
            </div>
            // )} */}
          </ModalBody>
          {/* <ModalFooter>
            <PrimaryButton
              onClick={handleSubmit}
              className="bg-[#084298] text-white px-7 py-2 rounded-md mb-5 "
              text={loading ? <Spinner /> : "Submit"}
              width={"fit-content"}
              disable={otp?.length === 6 ? false : true}
            />
          </ModalFooter> */}
        </ModalContent>
      </Modal>
    </>
  );
};

export default TwoFactorAuthModal;
