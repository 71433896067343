import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  IconButton,
} from "@chakra-ui/react";
import { useWindowSize } from "@uidotdev/usehooks";
import { useState } from "react";
import ReactCardFlip from "react-card-flip";
import {
  AddCircle,
  Cameraswitch,
  Close,
  ThumbDown,
  ThumbUp,
} from "@mui/icons-material";
import OverallIndexChart from "../../Charts/SinterBeltCharts/OverallIndexChart";
import { formatDateTime } from "../utils/formatDateTime";
import CameraFeed from "./CameraFeed";
import Feedback from "./Feedback";

const CommanCardsWithFlipper = ({
  data,
  handleDelete,
  flip,
  handleImageFlip,
  closeModal,
  handleThumbsUpClick,
  handleThumbsDownClick,
  camMap,
}) => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-6 items-start">
      {[...Array(4)].map((i, idx) => {
        return data.length >= idx + 1 ? (
          <div
            className="px-6 pb-4 pt-2  md:pt-4 rounded-lg flex flex-col items-center gap-2 min-h-[300px]"
            style={{
              boxShadow:
                "4px 4px 7px 0px rgba(0, 0, 0, 0.05), -4px -4px 7px 0px rgba(0, 0, 0, 0.05)",
            }}
            key={`image-card-flip-${idx}`}
          >
            <div className="flex flex-col sm:flex-row justify-between items-center w-full ">
              <div className="flex flex-row gap-3 items-center">
                <p className="text-sm text-[#525056] font-bold capitalize">
                  {data[idx]?.idx}. Board No: {data[idx]?.boardNo}
                </p>
                <p className="text-xs md:text-xs text-[#938F96]">
                  {data[idx]?.timestamp && formatDateTime(data[idx]?.timestamp)}
                </p>
              </div>
              <img
                src="/delete.svg"
                alt="Not supported"
                className="w-[12px] h-[14px] cursor-pointer"
                onClick={() => handleDelete(data[idx]?.id)}
                style={{
                  filter: data.length > 2 ? "" : "grayscale(100%)",
                }}
              />
            </div>
            <div className="flex flex-col gap-4 md:gap-3 items-center w-full ">
              {/* board no camera  */}
              {camMap?.cameraKeys?.length > 0 && camMap?.cameraMapping ? (
                camMap.cameraKeys.map((cameraKey, i) => {
                  const mappedValues = camMap.cameraMapping[cameraKey];
                  return (
                    <div key={i} className="w-full flex flex-col gap-1">
                      <p className="w-full justify-center border rounded-md font-semibold text-xs h-[30px] flex items-center">
                        {cameraKey}
                      </p>
                      {data[idx]?.[mappedValues?.[1]] ? (
                        <ReactCardFlip
                          isFlipped={flip[data[idx].id + idx + i] || false}
                          flipDirection="vertical"
                          containerClassName="w-full h-full"
                        >
                          {/* first view original image */}
                          <div className="w-full h-full  ">
                            <IconButton
                              aria-label="flip-image-front"
                              position={"absolute"}
                              bg={"#616161"}
                              size={"xs"}
                              color={"white"}
                              _hover={{ bg: "#616161" }}
                              onClick={() =>
                                handleImageFlip(data[idx].id + idx + i)
                              }
                              className="absolute right-0 bottom-0 text-white"
                              icon={
                                <Cameraswitch
                                  fontSize={"medium"}
                                  className="p-[2px]"
                                />
                              }
                            />
                            <img
                              src={data[idx]?.[mappedValues?.[0]]}
                              alt="not supported"
                              className="w-full h-[150px] rounded-lg "
                            />
                          </div>
                          {/* fliping to show annotated image */}
                          <div className="w-full h-full relative">
                            {/* overlay over image to show zone and image icon */}

                            <img
                              src={data[idx]?.[mappedValues?.[1]]}
                              alt="not supported"
                              className="w-full h-[150px] rounded-lg "
                            />
                            <IconButton
                              aria-label="flip-image-back"
                              bg="#616161"
                              color={"white"}
                              size={"xs"}
                              _hover={{ bg: "#616161" }}
                              onClick={() =>
                                handleImageFlip(data[idx].id + idx + i)
                              }
                              position={"absolute"}
                              bottom={0}
                              right={0}
                              icon={
                                <Cameraswitch
                                  fontSize={"medium"}
                                  className="p-[2px]"
                                />
                              }
                            />
                          </div>
                        </ReactCardFlip>
                      ) : (
                        <CameraFeed
                          key={i}
                          cameraName={mappedValues?.[0]}
                          data={data[idx]}
                        />
                      )}
                    </div>
                  );
                })
              ) : (
                <>
                  {[1, 2].map((e, i) => {
                    return (
                      <div
                        key={i}
                        className="h-[200px] w-full flex justify-center items-center border font-semibold rounded-md"
                      >
                        No Data
                      </div>
                    );
                  })}
                </>
              )}
              <div className="grid grid-cols-4 gap-1 p-1 border rounded-md w-full">
                <div className=" text-[#3E3C42] text-xs text-start col-span-3 font-medium  p-2 bg-[#DEF] border">
                  Average flame distance {"(px)"}
                </div>
                <div className="text-sm text-[#3E3C42] text-center p-2 border">
                  {data?.[idx]?.avgFlameDistance?.toFixed(2) || "-"}
                </div>
                <div className="text-xs text-[#3E3C42] col-span-3 font-medium  p-2 bg-[#DEF] border">
                  Area coldspots %
                </div>
                <div className="text-sm text-[#3E3C42] text-center p-2 border">
                  {data?.[idx]?.coldSpotPercentage?.toFixed(2) || "-"}
                </div>
                <div className="text-xs text-[#3E3C42] col-span-3 font-medium  p-2 bg-[#DEF] border">
                  Area hotspots %
                </div>

                <div className="text-sm text-[#3E3C42] text-center p-2 border">
                  {data?.[idx]?.hotSpotPercentage?.toFixed(2) || "-"}
                </div>
              </div>

              <div className="w-full flex flex-row items-center justify-end">
                <p className="text-sm">Feedback : </p>
                <IconButton
                  size={"xs"}
                  padding={0}
                  backgroundColor={"white"}
                  icon={<ThumbUp sx={{ fontSize: "18px", color: "#024d87" }} />}
                  onClick={() => handleThumbsUpClick(data[idx]?.id)}
                />
                <IconButton
                  size={"xs"}
                  padding={0}
                  backgroundColor={"white"}
                  icon={
                    <ThumbDown sx={{ fontSize: "18px", color: "#024d87" }} />
                  }
                  onClick={() => handleThumbsDownClick(data[idx]?.id)}
                />
              </div>
            </div>
          </div>
        ) : (
          <div
            className="px-6 pb-4 pt-4 rounded-lg flex flex-col items-center gap-2 h-[300px] md:h-full w-full"
            style={{
              boxShadow:
                "4px 4px 7px 0px rgba(0, 0, 0, 0.05), -4px -4px 7px 0px rgba(0, 0, 0, 0.05)",
            }}
          >
            <div className="rounded-2xl border-2 border-[#79767D] border-dashed w-full h-full flex justify-center items-center">
              <div className="flex gap-3 items-center">
                <IconButton
                  icon={
                    <AddCircle sx={{ color: "#9E9E9E", fontSize: "40px" }} />
                  }
                  rounded={"full"}
                  padding={0}
                  onClick={closeModal}
                  backgroundColor={"transparent"}
                />
                <p className="text-[#79767D] text-base">Add Board</p>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
const CompareModal = ({
  openModal,
  closeModal,
  data,
  setData,
  clientId,
  plantName,
  filterForCam,
  camMap,
}) => {
  const [flip, setFlip] = useState({});
  const [openFeedback, setopenFeedback] = useState(false);
  const [feedBackData, setFeedBackData] = useState({
    id: null,
    feedbackStatus: "",
  });
  const size = useWindowSize();
  const handleImageFlip = (recordId) => {
    setFlip((prevState) => ({
      ...prevState,
      [recordId]: !prevState[recordId],
    }));
  };
  const handleDelete = (recordId) => {
    if (data.length > 2) {
      setData((prev) => {
        let updatedData = [...prev];
        let idx = updatedData.findIndex((item) => item.id === recordId);
        updatedData.splice(idx, 1);
        return updatedData;
      });
    }
  };
  const handleThumbsUpClick = (id) => {
    setFeedBackData((prev) => ({ ...prev, id: id, feedbackStatus: "GOOD" }));
    setopenFeedback((prev) => true);
  };
  const handleThumbsDownClick = (id) => {
    setFeedBackData((prev) => ({ ...prev, id: id, feedbackStatus: "BAD" }));
    setopenFeedback((prev) => true);
  };

  return (
    <>
      {size.width >= 768 ? (
        <Modal isOpen={openModal} onClose={closeModal} isCentered="true">
          <ModalOverlay />
          <ModalContent
            style={{ borderRadius: "12px" }}
            maxW={"95vw"}
            maxH={"95vh"}
          >
            <ModalHeader
              padding="0px"
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              position={"relative"}
            >
              <IconButton
                position={"absolute"}
                top={-5}
                rounded={"full"}
                bg={"white"}
                cursor={"pointer"}
                icon={<Close sx={{ color: "gray" }} />}
                onClick={closeModal}
                boxShadow={"md"}
              />
            </ModalHeader>
            <ModalBody p="24px" overflowY={"auto"}>
              <CommanCardsWithFlipper
                data={data}
                handleDelete={handleDelete}
                flip={flip}
                handleImageFlip={handleImageFlip}
                closeModal={closeModal}
                handleThumbsUpClick={handleThumbsUpClick}
                handleThumbsDownClick={handleThumbsDownClick}
                filterForCam={filterForCam}
                camMap={camMap}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        <Drawer onClose={closeModal} isOpen={openModal} size="full">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader padding="0px">
              <div className="flex justify-center items-center">
                <IconButton
                  rounded={"full"}
                  bg={"white"}
                  cursor={"pointer"}
                  icon={<Close sx={{ color: "gray" }} />}
                  onClick={closeModal}
                  boxShadow={"md"}
                />
              </div>
            </DrawerHeader>
            <DrawerBody padding="24px" w={"full"}>
              <CommanCardsWithFlipper
                data={data}
                handleDelete={handleDelete}
                flip={flip}
                handleImageFlip={handleImageFlip}
                closeModal={closeModal}
                handleThumbsUpClick={handleThumbsUpClick}
                handleThumbsDownClick={handleThumbsDownClick}
                filterForCam={filterForCam}
                camMap={camMap}
              />
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
      {feedBackData && openFeedback && feedBackData?.id && (
        <Feedback
          openModal={openFeedback}
          closeModal={() => setopenFeedback((prev) => false)}
          clientId={clientId}
          material={"sinterflameprogression"}
          // cameraId={cameraRef.current}
          plantName={"salem"}
          id={feedBackData?.id}
          {...(feedBackData?.feedbackStatus && {
            feedbackStatus: feedBackData.feedbackStatus,
          })}
        />
      )}
    </>
  );
};

export default CompareModal;
