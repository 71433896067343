import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  IconButton,
  Badge,
  useToast,
  ModalCloseButton
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";

import PrimaryButton from "../../../../util/Buttons/PrimaryButton";
import TonalButton from "../../../../util/Buttons/TonalButton";
import DownloadIcon from "@mui/icons-material/Download";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../../../index";
import NavContext from "../../../NavContext";
import axios from "axios";
import { blue } from "@mui/material/colors";
import { Tooltip } from "@chakra-ui/react";
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import { utils, writeFile } from "xlsx";
import { useWindowSize } from "@uidotdev/usehooks";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const status = (val) => {
  switch (val) {
    case "DRAFT":
      return "draft";
    case "ACTIVE":
      return "live";
    case "INACTIVE":
      return "fail";
    default:
      break;
  }
};

const DeviceGroupsCard = ({ data, setSelectedDevice, setShowDevicesTab, getDeviceGroupApi }) => {

  console.log("Hello");
  console.log(data);
  const { auth } = useContext(NavContext);
  const toast = useToast();
  const navigate = useNavigate();
  const [isSwitchOn, setIsSwitchOn] = useState(data?.deviceGroup?.status === "INACTIVE");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [enabledModalOpen, setEnabledModalOpen] = useState(false);

  useEffect(() => { }, []);

  const patchDeviceGroupApi = async (flag, status) => {

    let data1 = { ...data?.deviceGroup }
    data1["isdeleted"] = flag;
    data1["status"] = status;
    //data["deviceGroupId"] = 

    const requestData = JSON.stringify(data1);
    const blob = new Blob([requestData], {
      type: "application/json",
    });
    const FormData = require("form-data");
    let datax = new FormData();
    datax.append("data", blob);
    //if (data.logoType === "Upload your own logo") datax.append("file", cardLogo);

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: baseURL + "device/group/v1/update/device",
      headers: {
        "X-Auth-Token": auth,
      },
      data: datax,
    };

    axios
      .request(config)
      .then((response) => {
        getDeviceGroupApi();
      }).catch((error) => {

      });
  }

  const handleEnableModal = async () => {
    setIsSwitchOn(!isSwitchOn);
    await patchDeviceGroupApi(false, 1);
    setEnabledModalOpen(false);
    setTimeout(() => {
      setIsMenuOpen(false);
    }, 1000);
  }

  const handleSwitchChange = async () => {
    if (!isSwitchOn) {
      setIsModalOpen(true);
      return;
    }
    else setEnabledModalOpen(true);

  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmDiscard = async () => {
    setIsSwitchOn(!isSwitchOn);
    await patchDeviceGroupApi(true, 2);
    setIsModalOpen(false);
    setTimeout(() => {
      setIsMenuOpen(false);
    }, 1000);
  };

  const exportAsExcel = (data, sheetName) => {
    let wb = utils.book_new();


    let fileColumns = Object.keys(data[0]);
    fileColumns = fileColumns.filter((value) => (value !== "deviceGroupId" && value !== "createdBy" && value !== "updatedBy"))
    let newData = [fileColumns];

    if (data.length != 0) {
      data?.forEach((item) => {
        const newArray = fileColumns?.map((i) => {
          return i.toLowerCase().includes("time")
            ? new Date(item[i]).toLocaleString()
            : item[i];
        });
        newData.push(newArray);
      });
    }

    let ws = utils.aoa_to_sheet(newData);
    utils.book_append_sheet(wb, ws, `${sheetName}`);
    writeFile(wb, `report_data.xlsx`);
  };

  const handleDownloadClick = () => {
    if (Array.isArray(data.deviceGroup)) exportAsExcel(data.product, "Summary");
    else exportAsExcel([data.deviceGroup], "Summary");
  }

  const switchLabelText = isSwitchOn ? "Disabled" : "Disable";

  const handleClick = () => {
    setShowDevicesTab((prev) => "viewDeviceGroup");
    setSelectedDevice(data);
  };

  const handleUpdate = () => {
    setShowDevicesTab((prev) => "updateDeviceGroup");
    setSelectedDevice(data);
  };

  const size = useWindowSize();
  const productString = data?.deviceList?.map((value, index) => value?.deviceName);
  const products = productString;
  let maxProductsToShow = 0;
  let productsLengthExceeds = false;
  let totalLength = 0;

  const calculateProduct = () => {
    for (let i = 0; i < products?.length; i++) {
      // Assuming each location contributes 10 characters on average

      // Calculate the total length with the new location
      totalLength += products[i]?.length;

      // Increment maxLocationsToShow if the total length is within the limit
      if (totalLength <= (size.width < 1536 ? 14 : 25)) {
        maxProductsToShow++;
      } else {
        maxProductsToShow++;
        productsLengthExceeds = true;
        // Stop incrementing if the limit is exceeded
        break;
      }
    }
  };

  products && calculateProduct();

  const toolsString =
    "Infrared Camera, Image Camera, Infrared Camera, Image Camera";
  const allTools = data?.deviceList?.map((value, index) => value?.deviceName);
  console.log(allTools, "hello")
  const maxToolsToShow = 2;

  return (
    <div
      className="px-4 py-5 rounded flex flex-col justify-between gap-4 relative bg-white h-[250px] w-full"
      style={{
        boxShadow:
          "-4px -4px 24px 0px rgba(0, 0, 0, 0.07), 4px 4px 24px 0px rgba(0, 0, 0, 0.07)",
      }}
    >
      <div className="flex flex-col gap-2 w-full">
        <div className="flex gap-0 h-[45px] w-full">
          <div className="flex justify-start">
            {data?.deviceGroup?.deviceGroupUrl ? (
              <img
                src={data?.deviceGroup?.deviceGroupUrl}
                alt="logo"
                className="w-full max-w-[30px] h-[30px]"
              />
            ) : (
              <img src="/deviceGroupDefaultIcon.png" alt="" className="w-full max-w-[30px] h-[30px]" />
            )}
          </div>

          <div className="ml-2 h-[45px] w-[70%] overflow-hidden">
            <Tooltip label={data?.deviceGroup?.deviceGroupName} placement="top">
              <p className="text-[#3E3C42] text-[16px] font-medium whitespace-pre-line line-clamp-2 overflow-hidden overflow-ellipsis">
                {data?.deviceGroup?.deviceGroupName}
              </p>
            </Tooltip>
          </div>


          <div className="w-[10%]">
            <Menu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<MoreVertIcon />}
                variant="simple"
                position={"absolute"}
                top={"15px"}
                right={"0px"}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              />
              <MenuList
                px={"12px"}
                py={"16px"}
                rounded={"8px"}
                display={"flex"}
                flexDirection={"column"}
                gap={"16px"}
                minWidth={"fit-content"}
                w={"180px"}
                position={"absolute"}
                right={"-50px"}
                top={"-15px"}
              >
                <MenuItem
                  icon={<DownloadIcon />}
                  textColor={"#605D64"}
                  fontSize={"14px"}
                  fontWeight={500}
                  p={"8px"}
                  _hover={{ bg: "#DDEEFF80", borderRadius: "4px" }}
                  _focus={{ bg: "#DDEEFF80", borderRadius: "4px" }}
                  onClick={handleDownloadClick}
                >
                  {"Download"}
                </MenuItem>
                <Checkbox
                  //iconColor='red'
                  colorScheme="red"
                  p={"8px"}
                  isChecked={isSwitchOn}
                  disabled={data?.deviceGroup?.status === "DRAFT"}
                  //isIndeterminate={isIndeterminate}
                  onChange={handleSwitchChange}
                >
                  {switchLabelText}
                </Checkbox>
              </MenuList>
            </Menu>
            <Modal size={"sm"} isOpen={isModalOpen} onClose={handleCloseModal}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader className=" flex justify-center rounded-t-md bg-red-400 text-white ">
                  Confirm Disable ?
                </ModalHeader>
                {/* <ModalCloseButton /> */}
                <ModalBody m={"auto"} fontSize={"15px"}>
                  <div className="flex flex-col items-center justify-center w-full">
                    <img
                      src="/disableIcon.png"
                      className="w-[100px] h-[100px]"
                    />

                    <p className="ml-4 font-medium">
                      Are you sure you would like to disable the device group?
                    </p>
                  </div>
                </ModalBody>

                <div className="mt-4 flex flex-row justify-center">
                  <Button
                    colorScheme="blue"
                    mr={4}
                    onClick={handleConfirmDiscard}
                  >
                    Confirm
                  </Button>
                  <Button onClick={handleCloseModal}>Go Back</Button>
                </div>

                <ModalFooter></ModalFooter>
              </ModalContent>
            </Modal>
            <Modal size={"md"} isOpen={enabledModalOpen} onClose={() => setEnabledModalOpen(false)}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader className=" flex justify-center rounded-t-md bg-blue-700 text-white ">
                  Product group enabled
                </ModalHeader>
                <ModalCloseButton className="mt-2" color={"white"} />
                <ModalBody fontSize={"15px"}>
                  <div className="flex flex-col items-center justify-center w-full">
                    <div>
                      <img
                        src="/enabled.svg"
                        className="w-[80px] h-[80px]"
                      />
                    </div>

                    <div className="flex flex-col items-center">
                      <p className="font-bold">
                        Device group has been enabled successfully
                      </p>
                      <div className="flex justify-center items-center">
                        {/* <p className=" font-normal"> */}
                          Please make sure you also enable all the devices individually
                        {/* </p> */}
                      </div>

                    </div>

                  </div>
                </ModalBody>

                <div className="mt-4 flex flex-row justify-center">
                  <Button
                    colorScheme={"blue"}
                    // mr={"#084298"}
                    onClick={handleEnableModal}
                  >
                    Okay
                  </Button>
                  {/* <Button onClick={handleCloseModal}>Go Back</Button> */}
                </div>

                <ModalFooter></ModalFooter>
              </ModalContent>
            </Modal>
          </div>
          {/* <div></div> */}
        </div>
        <div className="flex  items-center justify-between">
          <div className="flex items-center justify-center">
            <div className="flex flex-col justify-center">
              <p className="text-[#938F96] text-sm">Devices</p>
              <div className="flex items-center justify-start">
                <div >
                  <img className="w-[20px] h-[20px]" src="/DeviceIconCard.svg" alt="" />
                </div>
                <div className="ml-2">
                  <p>{data?.deviceCount}</p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex-col justify-center">
              <p className="text-[#938F96] text-sm">Clients</p>
              <div className="flex items-center justify-start">
                <div className="">
                  <img className="w-[20px] h-[20px]" src="/clients.png" alt="" />
                </div>
                <div className="ml-2">
                  <p>{data?.clientCount}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <div className="flex flex-col justify-center">
              <p className="text-[#938F96] text-sm">Locations</p>
              <div className="flex items-center justify-start">
                {/* <div class Name=""> */}
                <img className="w-[20px] h-[20px]" src="/location.png" alt="" />
                {/* </div> */}
                <div className="ml-2">
                  <p>{data?.deviceDeployCount}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <Tooltip label={allTools && allTools?.join(", ")} placement="top">
            <div>
              <p className="text-[#938F96] text-sm">Devices</p>
              <div className="flex mt-2 justify-between">

                <div className="flex w-[180px] 2xl:w-[90%]">
                  <img className="w-[20px] h-[20px] mr-1" src="/deviceTableIcon.svg" alt="" />
                  <p className="text-[#938F96] text-sm font-medium truncate">{allTools.join(", ")}</p>
                  {/* {allTools && allTools?.slice(0, maxToolsToShow).map((el, index) => (
                    <div key={index} className="flex items-center overflow-hidden overflow-ellipsis whitespace-nowrap">

                      <p className="text-[#938F96] text-sm font-medium whitespace-pre-line line-clamp-1">{el+ " "}</p>

                    </div>
                  ))} */}
                </div>
                {products?.length > maxProductsToShow && (
                  <Badge
                    ml={1}
                    color={"#447ED4"}
                    bg={"white"}
                    fontWeight={600}
                    fontSize="14px"
                  >
                    +{products?.length - maxProductsToShow}
                  </Badge>
                )}
              </div>
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="flex gap-2 items-center">
        <button
          className={
            ""
              ? "flex items-center gap-1 justify-center text-[#AEA9B1] text-sm font-medium rounded bg-[#EBEBEB]"
              : "flex items-center gap-1 justify-center text-[#FFF] text-sm font-medium rounded bg-[#378805] hover:bg-[#CDEEBF] hover:shadow-[0px_2px_6px_2px_rgba(0,0,0,0.15),0px_1px_2px_0px_rgba(0,0,0,0.30)] focus:bg-[#CDEEBF] focus:shadow-[0px_2px_6px_2px_rgba(0,0,0,0.15),0px_1px_2px_0px_rgba(0,0,0,0.30)] focus:outline-none active:bg-[#CDEEBF]"
          }
          style={{
            width: "100%",
            height: "auto",
            padding: '8px 16px 8px 16px'
          }}
          onClick={() => handleClick()}
          disabled={""}
        >
          View
        </button>

        <button
          className={
            data?.deviceGroup?.status === "INACTIVE"
              ? "flex items-center justify-center gap-1 text-[#AEA9B1] text-sm rounded bg-[#EBEBEB]"
              : "flex items-center justify-center gap-1 text-[#378805] text-sm rounded bg-[#CDEEBF] hover:bg-[#CDEEBF] hover:shadow-[0px_2px_6px_2px_rgba(0,0,0,0.10),0px_1px_2px_0px_rgba(0,0,0,0.10)] focus:bg-[#CDEEBF] focus:shadow-[0px_2px_6px_2px_rgba(0,0,0,0.10),0px_1px_2px_0px_rgba(0,0,0,0.10)] focus:outline-none active:bg-[#CDEEBF]"
          }
          style={{
            width: "100%",
            height: "auto",
            padding: "8px 16px 8px 16px",
          }}
          onClick={() => handleUpdate()}
          disabled={data?.deviceGroup?.status === "INACTIVE"}
        >
          Update
        </button>
      </div>
      <Tooltip label={data?.deviceGroup?.status?.toLowerCase()} hasArrow>
      {
          data?.deviceGroup?.status == "DRAFT" ? <img
            src={`/Common/draft.svg`}
            className="w-8 h-8 absolute -top-2 -right-2 rounded-full bg-white p-1 shadow-md"
            alt="status"
          /> : (
            data?.deviceGroup?.status == "ACTIVE" ? <CheckCircleIcon sx={{
              position: 'absolute',
              top: "-8px",
              right: "-8px",
              backgroundColor: "white",
              padding: "4px",
              width: "32px",
              height: "32px",
              borderRadius: "9999px",
              color: "green",
              boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
            }} /> : <CancelIcon sx={{
              position: 'absolute',
              top: "-8px",
              right: "-8px",
              backgroundColor: "white",
              padding: "4px",
              borderRadius: "9999px",
              width: "32px",
              height: "32px",
              color: "#E46962",
              boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
            }} />
          )
        }
      </Tooltip>
    </div>
  );
};

export default DeviceGroupsCard;
