import EditIcon from "@mui/icons-material/Edit";
import { useRef, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import { baseURL } from "../../..";
import axios from "axios";
import { Toast, useToast } from "@chakra-ui/react";
import { slabSizingJsplBaseURl } from "../SlabSizing";

const EditSlabId = ({ item, setEditingSLabId }) => {
  const inputRef = useRef();
  const [editedText, setEditedText] = useState();
  const [editCell, setEditCell] = useState(false);
  const toast = useToast();

  const handleChangeTruckName = async () => {
    let data = JSON.stringify({
      _id: item._id,
      slab_id: inputRef.current.value,
    });

    if (inputRef.current.value == "") {
      toast({
        title: `You can't set empty slab id`,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      setEditCell(false);
      return;
    }

    if (inputRef.current.value.length >= 26) {
      toast({
        title: `Slab ID  can't be more than 25 characters`,
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      setEditCell(false);
      return;
    }

    try {
      const response = await axios.post(
        slabSizingJsplBaseURl + "update/slab_id/",
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data == "slab updated") {
        toast({
          title: `Slab ID Updated`,
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
        setEditedText(inputRef.current.value);
      }
    } catch (error) {
      console.log("error", error);
      toast({
        title: error?.response?.data
          ? error?.response?.data
          : "Something went wrong",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top-right",
      });
      setEditCell(false);
      return;
    }

    setEditCell(false);
  };

  return (
    <div className="min-w-[90px] max-w-[100px] flex justify-start gap-2">
      {editCell ? (
        <div className="flex justify-between items-center gap-0 w-full">
          <input
            type="text"
            className="border w-full h-6 text-[#605D64]  rounded-sm"
            defaultValue={item.slab_id}
            ref={inputRef}
          />

          <SaveIcon
            onClick={() => {
              handleChangeTruckName(item.slab_id);
              setEditingSLabId(false);
            }}
            sx={{
              cursor: "pointer",
              fontSize: "16px",
              color: "#605D64",
            }}
          />
        </div>
      ) : (
        <div className="flex justify-between items-center gap-0 w-full">
          <p className=" h-6 w-full flex items-center">
            {editedText ? editedText : item.slab_id}
          </p>

          <EditIcon
            onClick={() => {
              setEditCell(true);
              setEditingSLabId(true);
            }}
            sx={{
              cursor: "pointer",
              fontSize: "16px",
              color: "#605D64",
            }}
          />
        </div>
      )}
    </div>
  );
};

export default EditSlabId;
