import { useWindowSize } from "@uidotdev/usehooks";
import ReactApexChart from "react-apexcharts";


const RangedBarChart = ({ data, labels, xAxisText, yAxisText, stackType = false }) => {

    const size = useWindowSize();
    if(!data){
        return;
    }
    let graphData = {};
    labels?.map((i) => {
        graphData[i] = [];
    });

    data?.map((i, idx) => {
        Object.keys(i)?.map((j) => {
            graphData[j].push({x:2.5+(idx*5), y:i[j]});
        });
    });

    const series = [];
    Object.keys(graphData)?.map((i) => {
        series.push({
            name: i,
            data: graphData[i]
        });
    });

    const options = {
        chart: {
            toolbar: {
                show: false,
            },
            type: "bar",
            stacked: true,
            stackType: stackType == true ? "100%" : "normal",
        },
        // series: graphData,
        // title: {
        //     text: 'No. of Defects',
        //     align: 'center',
        //     style: {
        //         fontSize: '16px',
        //         fontWeight: 'bold',
        //         fontFamily: 'Arial, sans-serif',
        //     },
        // },
        dataLabels: {
            formatter: function (val, opts) {
                return stackType ? val?.toFixed(1) + "%" :  val
            },
        },
        plotOptions: {
            bar: {
                columnWidth: '40px',
            }
        },
        colors:  [
            "#4caf50",
            "#5193f6",
            "#E46962",
            "#1c56ac",
            "#e91e63",
            "#00bcd4",
            "#8bc34a",
            "#9c27b0",
            "#673ab7",
            "#ff9800",
            "#4caf50",
            "#795548",
        ],
        xaxis: {
            type: "numeric",
            min: 0,
            stepSize: 5,
            max: 35,
            tickAmount: 7,
            axisTicks: {
                show: true,
                borderType: 'solid',
                color: '#707070',
                height: 6,
                offsetX: 0,
                offsetY: 0
              },
            labels: {formatter: x => ( x == 35 ? "" : (x == 30 ? "30+" : x) ) /*Math.round(x)*/},
            title: {
                text: xAxisText,
                align: 'center',
                style: {
                    fontSize: '12px',
                    fontWeight: 'light',
                    fontFamily: 'Arial, sans-serif',
                },
            },
            axisBorder: {
                color: "#000000"
            }
        },
        yaxis: {
            title: {
                text: yAxisText,
                align: 'center',
                style: {
                    fontSize: '12px',
                    fontWeight: 'light',
                    fontFamily: 'Arial, sans-serif',
                },
            },
        },
        fill: {
            opacity: 1,
        },
        legend: {
            show: true,
            position: "top",
            offsetX: -30,
            offsetY: 0,
            fontSize: "12px",
            labels: {
                colors: "#79767D",
            },
            markers: {
                width: 12,
                height: 12,
                radius: 10,
            },
            alignLabels: true,
            itemMargin: {
                horizontal: 2,
                vertical: 5,
            },
        },
        // legend: {
        //     show: true,
        //     position: "top",
        //     fontSize: "12px",
        // },
    };
    return (
        // <div className={`h-full w-full ${size.width < 700 ? "overflow-x-scroll" : "overflow-hidden"
        //     }`}>
            <ReactApexChart
                // width={size.width < 1024 ? "700px" : "100%"}
                options={options}
                series={series}
                type="bar"
                height={300}
            />
        // </div>
    );
};

export default RangedBarChart;