import TextButton from "../../../util/Buttons/TextButton";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import { useContext, useEffect, useRef, useState } from "react";
import {
  Input,
  InputGroup,
  InputRightElement,
  RadioGroup,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import AddLabel from "./AddLabel";
import ImageSelector from "./ImageSelector";
import DetectSegment from "./DetectSegment";
import axios from "axios";
import { baseURL } from "../../..";
import NavContext from "../../NavContext";
import TonalButton from "../../../util/Buttons/TonalButton";
import { draftPostApi } from "../pages/DraftForm";
import AnnotationCount from "./AnnotationCount";
import { CalAnnotations } from "./AnnotationCount";
import HelpModal from "./HelpModal";

const AnnotateData = ({
  userData,
  setUSerData,
  activeStep,
  setActiveStep,
  show,
  draft = false,
}) => {
  const { auth } = useContext(NavContext);
  const [labels, setLabels] = useState([]);
  const [page, setPage] = useState("Unannotated");
  const [selectedImages, setSelectedImages] = useState([]);
  const [annotatedImages, setAnnotatedImages] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [allImages, setAllImages] = useState([]);
  const [ImageSet, setImageSet] = useState([]);
  const [assign, setAssign] = useState("");
  const [add, setAdd] = useState(false);
  const [helpModal, setHelpModal] = useState(false);
  const addLabelRef = useRef();
  const toast = useToast();

  const handleAdd = (e) => {
    if (e.code == "Enter" && /\S/.test(addLabelRef.current.value)) {
      setLabels((prev) => {
        let newData = [...prev];
        newData.push(addLabelRef.current.value);
        return newData;
      });
      setAdd(false);
    }
  };

  const newLabelAdd = (e) => {
    let okay = true;
    let fileCount = Object.entries(userData?.uploadedFiles || {})?.length;
    okay = Math.floor(fileCount / 20) > labels.length;
    if (okay) {
      setAdd(true);
    } else {
      toast({
        title: "Error",
        description: "You don't have enough images for one more label",
        status: "error",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleAssign = () => {
    setAnnotatedImages((prev) => {
      let newData = [...prev];
      selectedImages.forEach((item) => {
        newData.push({
          img: item.img,
          id: item.id,
          label: assign,
        });
      });
      return newData;
    });
    setAllImages((prev) => {
      let newData = [...prev];
      selectedImages.forEach((item) => {
        let idx = newData.findIndex((x) => x.id == item.id);
        newData.splice(idx, 1);
      });
      return newData;
    });
    setAssign("");
    setSelectedImages([]);
  };

  const startTrainApi = async () => {
    try {
      const param = {
        projectId: userData.projectId,
      };
      const response = await axios.post(
        baseURL + "selfserve/v1/project/v1/training/start/",
        null,
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.status == 200) {
        let eta = response.data?.etaTotalSeconds;
        toast({
          title: "Success",
          description: (
            <div>
              <p>Training started</p>
              {eta && eta > 1 && <p>ETA: {(eta / 60).toFixed(1)} min</p>}
            </div>
          ),
          status: "success",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
        setActiveStep((prev) => prev + 1);
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Error",
        description: "Start train api failed",
        status: "error",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const AnnotateApi = async () => {
    try {
      const param = {
        projectId: userData.projectId,
      };
      const annotations = annotatedImages.map((item) => {
        return {
          fileId: item.id,
          label:
            userData.annotationType == "Classify" ? item.label : item.regions,
        };
      });
      let predictionData;
      predictionData = allImages
        .filter((val) => {
          return !annotatedImages.some((item) => item.id == val.id);
        })
        .map((item) => {
          return {
            fileId: item.id,
            fileUrl: item.img,
          };
        });
      setUSerData((prev) => {
        let newData = { ...prev };
        newData["annotatedData"] = annotations;
        return newData;
      });
      const requestBody = JSON.stringify(
        userData?.dataType == "Image"
          ? {
              annotations: annotations,
              predictionDataset: predictionData,
            }
          : {
              annotations: annotations,
            }
      );
      const response = await axios.post(
        baseURL +
          `selfserve/v1/project/v1/annotations/${
            userData?.mode == "Retraining" ? "update" : "set"
          }/`,
        requestBody,
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.status == 200) {
        toast({
          title: "Success",
          description: "Annotated data received",
          status: "success",
          position: "top-right",
          duration: 2000,
          isClosable: true,
        });
        startTrainApi();
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Error",
        description: "Set annotate api failed",
        status: "error",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const checkAnnotation = () => {
    let type = userData.annotationType;
    let limit = type == "Classify" ? 15 : 20;
    for (let l in labels) {
      let count = CalAnnotations(labels[l], annotatedImages, userData);
      if (count < limit) {
        toast({
          title: "Less images annotated",
          description:
            "label : " +
            labels[l] +
            " & annotations: " +
            count +
            `, Provide min ${limit - count} more to submit`,
          status: "error",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
        return false;
      }
    }
    return true;
  };

  const handleDraft = () => {
    let newData = userData;
    newData["annotatedImages"] = annotatedImages;
    newData["labels"] = labels;
    newData["allImages"] = allImages;
    newData["draft"] = true;
    if (userData?.annotationType != "Classify") {
      newData["confirm"] = confirm;
      newData["ImageSet"] = ImageSet;
    }
    draftPostApi(newData, activeStep, toast, auth);
  };

  const handleSubmit = async () => {
    if (checkAnnotation()) AnnotateApi();
  };

  useEffect(() => {
    if (
      userData.uploadedFiles &&
      Object.entries(userData.uploadedFiles).length > 0
    ) {
      if (draft) {
        setAllImages(userData?.allImages);
        setAnnotatedImages(userData?.annotatedImages);
        setSelectedImages([]);
        setAssign("");
        setLabels(userData?.labels);
        if (userData?.annotationType != "Classify") {
          setConfirm(userData?.confirm);
          setImageSet((prev) => {
            let newData = userData?.ImageSet;
            userData?.annotatedImages?.forEach((item) => {
              let idx = newData.findIndex((val) => val.id == item.id);
              if (idx !== -1) {
                newData[idx] = {
                  ...newData[idx],
                  regions: item.regions,
                };
              }
            });
            return newData;
          });
        }
      } else {
        setAllImages(
          Object.entries(userData.uploadedFiles).map((item) => {
            return {
              img: item[1],
              id: item[0],
            };
          })
        );
        setAnnotatedImages([]);
        setSelectedImages([]);
        setAssign("");
        setLabels([]);
      }
    }
  }, [userData.uploadedFiles]);

  return (
    <div
      className={`p-6 flex flex-col gap-6 bg-white transition-all duration-700 ease-in ${
        show ? "opacity-100" : "opacity-0"
      }`}
      id="step3"
    >
      <div className="flex gap-2 items-center">
        <p className="text-[#3E3C42] text-2xl font-medium">{`Assign labels (Files saved: ${
          Object.entries(userData.uploadedFiles)?.length
        })`}</p>
        <TextButton
          text={"Know how?"}
          width={"fit-content"}
          onClick={() => setHelpModal(true)}
        />
      </div>
      <div className="flex flex-col lg:flex-row gap-[47px] relative">
        {/* Label selection and additon */}
        <div className="flex flex-col gap-3 whitespace-nowrap">
          <div className="self-start lg:self-end">
            <TextButton
              text={"Add labels"}
              width={"fit-content"}
              disable={add || confirm || labels.length == 5}
              onClick={() => newLabelAdd()}
            />
          </div>
          <RadioGroup onChange={setAssign} value={assign}>
            <div className="flex flex-col gap-3 max-w-[250px]">
              {labels.map((x) => {
                return (
                  <AddLabel
                    x={x}
                    assign={assign}
                    setLabels={setLabels}
                    setAssign={setAssign}
                    setAnnotatedImages={setAnnotatedImages}
                    setAllImages={setAllImages}
                    userData={userData}
                    confirm={confirm}
                  />
                );
              })}
            </div>
            {add && (
              <InputGroup>
                <Input
                  py={"4px"}
                  pl={"6px"}
                  ref={addLabelRef}
                  border={"1px"}
                  borderColor={"#EBEBEB"}
                  borderRadius={"2px"}
                  width={"fit-content"}
                  onKeyDown={handleAdd}
                />
                <InputRightElement>
                  <img src="/selfServiceIcons/enter.svg" alt="enter" />
                </InputRightElement>
              </InputGroup>
            )}
          </RadioGroup>
          {userData.annotationType == "Classify" ? (
            <SecondaryButton
              text={"Assign label"}
              width={"fit-content"}
              disable={assign == ""}
              onClick={handleAssign}
            />
          ) : (
            <Tooltip
              label={"You can't add a label after confirmation."}
              hasArrow
              placement="right"
            >
              <div className="w-fit">
                <SecondaryButton
                  text={"Confirm"}
                  width={"fit-content"}
                  disable={labels.length == 0 || confirm || add}
                  onClick={() => setConfirm(true)}
                />
              </div>
            </Tooltip>
          )}
          <AnnotationCount
            labels={labels}
            annotatedImages={annotatedImages}
            userData={userData}
          />
        </div>
        {/* Image selection area */}
        {userData.annotationType == "Classify" ? (
          <ImageSelector
            selectedImages={selectedImages}
            setSelectedImages={setSelectedImages}
            annotatedImages={annotatedImages}
            setAnnotatedImages={setAnnotatedImages}
            labels={labels}
            images={allImages}
            setImages={setAllImages}
            page={page}
            setPage={setPage}
          />
        ) : (
          <DetectSegment
            labels={labels}
            setAnnotatedImages={setAnnotatedImages}
            annotatedImages={annotatedImages}
            allImages={allImages}
            options={
              userData.annotationType == "Detect"
                ? ["select", "create-box"]
                : ["select", "create-polygon"]
            }
            ImageSet={ImageSet}
            setImageSet={setImageSet}
            draft={draft}
            confirm={confirm}
          />
        )}
      </div>
      <div className="flex gap-2 items-center">
        <TonalButton
          text={"Save as Draft"}
          width={"fit-content"}
          disable={activeStep > 3}
          onClick={() => handleDraft()}
        />
        <PrimaryButton
          text={"Submit"}
          width={"fit-content"}
          disable={annotatedImages.length == 0 || activeStep > 3}
          onClick={handleSubmit}
          color="green"
        />
      </div>
      {helpModal && (
        <HelpModal
          openModal={helpModal}
          closeModal={() => setHelpModal(false)}
          type={userData?.annotationType}
        />
      )}
    </div>
  );
};

export default AnnotateData;
