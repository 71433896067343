import { useContext, useEffect, useState } from "react";
import TopBanner from "../Components/TopBanner";
import AllProjects from "./AllProjects";
import AllCams from "./AllCams";
import { useNavigate, useSearchParams } from "react-router-dom";

const dummy = [
  {
    projectId: 325,
    status: "DRAFT",
    name: "JXiwhaZYit",
    createdAt: 1629191002421,
    projectName: "fVZRxwCy",
    owner: "gitansh@ripik.ai",
  },
  {
    projectId: 548,
    status: "DRAFT",
    name: "PvOeAIQgGf",
    createdAt: 1629191002846,
    projectName: "KJQvSHJX",
    owner: "gitansh@ripik.ai",
  },
  {
    projectId: 786,
    status: "COMPLETED",
    name: "irjKdqPOVo",
    createdAt: 1629191003161,
    projectName: "qTkEeuzH",
    owner: "gitansh@ripik.ai",
  },
  {
    projectId: 538,
    status: "COMPLETED",
    name: "FkmUQDezYO",
    createdAt: 1629191004007,
    projectName: "CwqUkPEh",
    owner: "aman@ripik.ai",
  },
];

const Dashboard = () => {
  const [projects, setProjects] = useState([]);
  const [fetching, setFetching] = useState(true);
  const [cams, setCams] = useState(dummy);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedGroup, setSelectedGroup] = useState(
    searchParams.get("selectedGroup")
      ? searchParams.get("selectedGroup")
      : "project"
  );
  const handleGroupChange = (val) => {
    if (val !== selectedGroup) {
      setSearchParams({ selectedGroup: val }, { replace: true });
      setSelectedGroup(val);
    }
  };

  return (
    <div className="flex flex-col gap-4 my-6 font-roboto">
      <div className="flex gap-2 items-center">
        <img
          src="/backtick.svg"
          alt="back"
          className="hover:scale-105 cursor-pointer"
          onClick={() => navigate("/")}
        />
        <p className="text-xl font-medium text-[#084298]">AI Sandbox</p>
      </div>
      <div className="flex gap-2 items-center">
        {["project", "data feed"]?.map((item) => {
          return (
            <div
              className="py-[6px] px-4 rounded-[32px] capitalize border text-sm"
              style={{
                backgroundColor: selectedGroup == item ? "#6CA6FC33" : "white",
                borderColor: selectedGroup == item ? "#6CA6FC" : "#EBEBEB",
                color: selectedGroup == item ? "#3E3C42" : "#605D64",
                fontWeight: selectedGroup == item ? 500 : 400,
                cursor: selectedGroup == item ? "" : "pointer",
              }}
              onClick={() => handleGroupChange(item)}
            >
              {item}
            </div>
          );
        })}
      </div>
      <div className="flex flex-col gap-6 bg-white rounded-lg">
        {!fetching && <TopBanner firstTime={projects.length == 0} />}
        {selectedGroup == "project" && (
          <AllProjects
            projects={projects}
            setProjects={setProjects}
            setFetching={setFetching}
          />
        )}
        {selectedGroup == "data feed" && (
          <AllCams cams={cams} setCams={setCams} />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
