import { useState } from "react";
import saveAs from "file-saver";
import VideoInputForm from "./VideoInputForm";
import { DownloadForOfflineOutlined } from "@mui/icons-material";
import { IconButton } from "@chakra-ui/react";

const VideoGallery = ({
  plantId,
  disable,
  plantCamMap,
  camMap,
  setCamMap,
  material,
}) => {
  const [isVideo, setIsVideo] = useState("");
  const VidDownload = async (url, idx) => {
    const response = await fetch(url);
    const videoData = await response.blob();
    saveAs(videoData, `video-${idx}.mp4`);
  };
  console.log(isVideo, "isVideo");
  return (
    <div className="relative flex xl:flex-row flex-col justify-between items-center gap-8 bg-white p-10 rounded-xl">
      {isVideo == "" ? (
        <div className="flex flex-col items-center gap-6 h-full">
          <img src="/Common/VideoPlayer.svg" alt="No support" />
          <div className="flex flex-col items-center gap-2">
            <p className="font-medium text-[#1C56AC] text-2xl">Video gallery</p>
            <p className="text-[#AEA9B1] text-base text-center">
              The Video gallery will create a continuous video frame of the
              captured pictures from your selected date and time range.
            </p>
          </div>
        </div>
      ) : (
        <div className="relative flex items-center">
          <video
            crossOrigin="anonymous"
            controls
            className="rounded-lg w-[45vw]"
            muted="muted"
            defaultMuted
            playsInline
            loop="loop"
            autoPlay="autoPlay"
          >
            {isVideo ? (
              <source src={isVideo} type="video/mp4" />
            ) : (
              <p>Your browser does not support HTML5 video.</p>
            )}
          </video>
          {/* <div className="top-2 right-2 absolute flex gap-4 opacity-40 hover:opacity-90">
            <IconButton
              size={"xs"}
              rounded={"full"}
              icon={<DownloadForOfflineOutlined className="text-gray-400" />}
              onClick={() => VidDownload(isVideo, "sample")}
            />
          </div> */}
        </div>
      )}
      <VideoInputForm
        setIsVideo={setIsVideo}
        plantId={plantId}
        disable={disable}
        plantCamMap={plantCamMap}
        setCamMap={setCamMap}
        camMap={camMap}
        material={material}
      />
    </div>
  );
};

export default VideoGallery;
