import { Textarea, Input, Radio, RadioGroup, useToast } from "@chakra-ui/react";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import axios from "axios";
import { baseURL } from "../../../index";
import { useContext, useEffect, useRef, useState } from "react";
import NavContext from "../../NavContext";

const UseCaseDetail = ({
  userData,
  setUSerData,
  activeStep,
  setActiveStep,
  show,
  viewMode = false,
}) => {
  const descRef = useRef();
  const toast = useToast();
  const { auth } = useContext(NavContext);
  const [disable, setDisable] = useState(viewMode);
  const handleChange = (name, value) => {
    setUSerData((prev) => {
      const updatedData = { ...prev };
      updatedData[name] = value;
      return updatedData;
    });
  };
  const handleSave = async () => {
    if (descRef.current.value == "" || userData.dataType == "") {
      toast({
        title: "Error",
        description: (
          <div>
            {userData.dataType == "" && <p>The dataType cannot be empty.</p>}
            {descRef.current.value == "" && (
              <p>The description cannot be empty.</p>
            )}
          </div>
        ),
        status: "error",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
      return;
    } else {
      try {
        const requestBody = JSON.stringify({
          remarks: descRef.current.value,
          datasetType: userData.dataType.toUpperCase(),
        });
        const param = {
          projectId: userData.projectId,
        };
        const response = await axios.post(
          baseURL + "selfserve/v1/project/v1/update/",
          requestBody,
          {
            params: param,
            headers: {
              "Content-Type": "application/json",
              "X-Auth-Token": auth,
            },
          }
        );
        if (response.status == 200) {
          setUSerData((prev) => {
            let newData = { ...prev };
            newData["whatToDetect"] = descRef.current.value;
            return newData;
          });
          if (activeStep < 2) setActiveStep(50);
          setDisable(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (activeStep == 1 && !viewMode) setDisable(false);
  }, [activeStep]);
  const dataTypes = ["Image", "Video", "Text"];
  return (
    <div
      className={`p-6 flex flex-col gap-8 rounded-lg bg-white transition-all ease-in duration-500 ${
        show ? "opacity-100" : "opacity-0"
      }`}
      id="step1"
    >
      <p className="text-[#3E3C42] text-xl font-medium">UseCase details</p>
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-3">
          <p className="text-sm text-[#3E3C42] font-medium">
            Select data type<span className="text-[#DC362E]">*</span>
          </p>
          <RadioGroup
            name="dataType"
            onChange={(e) => handleChange("dataType", e)}
            value={userData.dataType}
          >
            <div className="flex gap-1 items-center">
              {dataTypes.map((x) => {
                return (
                  <div
                    style={{
                      backgroundColor:
                        x == userData.dataType ? "#DDEEFF80" : "#FFF",
                      borderRadius: "8px",
                    }}
                  >
                    <Radio
                      value={x}
                      py={"8px"}
                      pl={"8px"}
                      pr={"12px"}
                      fontSize={"14px"}
                      fontWeight={500}
                      color={"#3E3C42"}
                      _checked={{
                        bg: "#6CA6FC",
                        borderColor: "#6CA6FC",
                      }}
                      _hover={{
                        borderColor: "#6CA6FC",
                      }}
                      isDisabled={x == "Text" || disable}
                    >
                      {x}
                    </Radio>
                  </div>
                );
              })}
            </div>
          </RadioGroup>
        </div>
        <div className="flex flex-col gap-3">
          <p className="text-sm text-[#3E3C42] font-medium">
            What do you want to detect?<span className="text-[#DC362E]">*</span>
          </p>
          <Textarea
            placeholder="Ex. I want to detect the size of coal"
            width={"300px"}
            name="whatToDetect"
            defaultValue={userData.whatToDetect}
            ref={descRef}
            isDisabled={disable}
          />
        </div>
      </div>
      <div className="flex items-center gap-2 mt-2">
        <PrimaryButton
          text={"Save"}
          width={"fit-content"}
          onClick={handleSave}
          disable={disable}
        />
        {/* {activeStep > 1 && (
          <TonalButton
            width={"fit-content"}
            text={"Edit"}
            onClick={() => {
              setDisable(false);
            }}
            disable={!disable}
          />
        )} */}
      </div>
    </div>
  );
};

export default UseCaseDetail;
