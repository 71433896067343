import ReactApexCharts from "react-apexcharts";
import { useState, useEffect } from "react";
import { indexWordMap } from "../../SinterFlame/Sinterflame";

const seriesNameFinder = (val) => {
  if (val.length > 2) return [val[1], val[2]];
  else return [val[1]];
};

const minMaxFinder = (data, val) => {
  switch (val) {
    case "min":
      if (data?.length > 2) {
        return Math.min(...data[1]?.data, ...data[2]?.data);
      } else if (data?.length > 1) return Math.min(...data[1]?.data);
      else return 0;
    case "max":
      if (data?.length > 2) {
        return Math.max(...data[1]?.data, ...data[2]?.data);
      } else if (data?.length > 1) return Math.max(...data[1]?.data);
      else return 0;
    default:
      break;
  }
};

const LineChart = ({ data, timeStamps, labels, color, curve }) => {
  //manging x axis and y axis values
  const [times, setTimes] = useState([]);
  const [series, setSeries] = useState([]);
  // update the series each time new points come
  const updateSeries = (newNames, newData) => {
    setSeries((prevSeries) => {
      const updatedSeries = [...prevSeries];

      newNames.forEach((newName, index) => {
        const existingIndex = updatedSeries.findIndex(
          (item) => item.name === newName
        );

        if (existingIndex !== -1) {
          updatedSeries[existingIndex].data.push(newData[index]?.toFixed(2));
        } else {
          updatedSeries.push({
            name: newName,
            data: [newData[index]?.toFixed(2)],
          });
        }
      });

      return updatedSeries;
    });
  };

  useEffect(() => {
    updateSeries(labels, data);
    setTimes((prev) => [...prev, timeStamps]);
  }, [timeStamps, data, labels]);

  //chart options
  const options = {
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y, { seriesIndex }) {
          return seriesIndex != 0 ? y : indexWordMap[y];
        },
      },
      theme: "dark",
      fillSeriesColor: true,
      style: {
        fontSize: "16px",
      },
    },
    chart: {
      type: "line",
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: true,
        },
      },
      animations: {
        enabled: false,
      },
    },
    xaxis: {
      categories: times,
      type: "datetime",
      labels: {
        show: true,
        formatter: function (value) {
          const date = new Date(value);
          const hours = date.getHours();
          const minutes = date.getMinutes().toString().padStart(2, "0");
          const ampm = hours >= 12 ? "PM" : "AM";
          const formattedHours = hours === 0 || hours === 12 ? 12 : hours % 12;

          return `${formattedHours}:${minutes} ${ampm}`;
        },
        style: {
          fontSize: "14px",
        },
      },
    },
    yaxis: [
      {
        opposite: true,
        title: {
          text: "",
          style: {
            fontSize: "12px",
            color: "#000000",
            fontWeight: 500,
            fontFamily: "Poppins",
          },
        },
        tickAmount: 3,
        labels: {
          style: {
            fontSize: "14px",
          },
          formatter: function (value) {
            return indexWordMap[value]?.split(" ");
          },
          offsetY: -8,
          minWidth: 60,
        },
        min: 0,
        max: 3,
      },
      {
        title: {
          text: "Burner Temperatures",
          style: {
            fontSize: "12px",
            color: "#3E3C42",
            fontWeight: 500,
            fontFamily: "Poppins",
          },
        },
        min: minMaxFinder(series, "min"),
        max: minMaxFinder(series, "max"),
        seriesName: seriesNameFinder(labels),
        show: false,
      },
      {
        title: {
          text: "Burner Temperatures",
          style: {
            fontSize: "12px",
            color: "#3E3C42",
            fontWeight: 500,
            fontFamily: "Poppins",
          },
        },
        min: minMaxFinder(series, "min"),
        max: minMaxFinder(series, "max"),
        seriesName: seriesNameFinder(labels),
      },
    ],
    colors: color,
    stroke: {
      curve: curve,
      width: [3, 3, 3, 3, 3, 3, 3, 3, 3, 3],
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#FAFAFA", "transparent"],
        opacity: 1,
      },
    },
    annotations: {
      yaxis: [
        {
          y: 1.5,
          y2: 2.5,
          borderColor: "#69B04B",
          fillColor: "#B2D8B2",
          label: {
            text: "Optimal range",
          },
        },
      ],
    },
    legend: {
      show: true,
      offsetY: 15,
      offsetX: 0,
      position: "top",
      fontSize: "12px",
    },
  };

  return (
    <ReactApexCharts
      options={options}
      series={series}
      type="line"
      height="100%"
      width="100%"
    />
  );
};

export default LineChart;
