import axios from "axios";
import { mulpicJsplBaseUrl } from "../Mulpic";

export const getTemperatureGraphsDataApi = async (
    auth,
    requestData,
) => {
    try {
        const res = await axios.post(
            `${mulpicJsplBaseUrl}mulpic/iba/plots/temp_related`,
            requestData,
            {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const data = res?.data;
        if (data) {
            return {
                success: true,
                data: data,
            };
        } else {
            return {
                success: false,
                data: null,
            };
        }
    } catch (error) {
        console.log(error);
        return {
            success: false,
            data: null,
            error: error,
        };
    }
};

export const getGraphsDataApi = async (
    auth,
    requestData,
) => {
    try {
        const res = await axios.post(
            `${mulpicJsplBaseUrl}mulpic/iba/plot_data`,
            requestData,
            {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const data = res?.data;
        if (data) {
            return {
                success: true,
                data: data,
            };
        } else {
            return {
                success: false,
                data: null,
            };
        }
    } catch (error) {
        console.log(error);
        return {
            success: false,
            data: null,
            error: error,
        };
    }
};

export const getBoxPlotGraphsDataApi = async (
    auth,
    requestData,
) => {
    try {
        const res = await axios.post(
            `${mulpicJsplBaseUrl}mulpic/iba/boxplot/`,
            requestData,
            {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const data = res?.data;
        if (data) {
            return {
                success: true,
                data: data,
            };
        } else {
            return {
                success: false,
                data: null,
            };
        }
    } catch (error) {
        console.log(error);
        return {
            success: false,
            data: null,
            error: error,
        };
    }
};

export const getParametersDataApi = async (
    auth,
    requestData,
) => {
    try {
        const res = await axios.post(
            `${mulpicJsplBaseUrl}mulpic/iba/fetch_params`,
            requestData,
            {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const data = res?.data;
        if (data) {
            return {
                success: true,
                data: data,
            };
        } else {
            return {
                success: false,
                data: null,
            };
        }
    } catch (error) {
        console.log(error);
        return {
            success: false,
            data: null,
            error: error,
        };
    }
};

export const getFlowRateGraphsDataApi = async (
    auth,
    requestData,
) => {
    try {
        const res = await axios.post(
            `${mulpicJsplBaseUrl}mulpic/iba/plots/flow_related`,
            requestData,
            {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const data = res?.data;
        if (data) {
            return {
                success: true,
                data: data,
            };
        } else {
            return {
                success: false,
                data: null,
            };
        }
    } catch (error) {
        console.log(error);
        return {
            success: false,
            data: null,
            error: error,
        };
    }
};

export const getSpeedGraphsDataApi = async (
    auth,
    requestData,
) => {
    try {
        const res = await axios.post(
            `${mulpicJsplBaseUrl}mulpic/iba/plots/speed_related`,
            requestData,
            {
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const data = res?.data;
        if (data) {
            return {
                success: true,
                data: data,
            };
        } else {
            return {
                success: false,
                data: null,
            };
        }
    } catch (error) {
        console.log(error);
        return {
            success: false,
            data: null,
            error: error,
        };
    }
};

export const getAdditionalGraphsDataApi = async (
    auth,
    requestData,
) => {
    try {
        const res = await axios.post(
            `${mulpicJsplBaseUrl}mulpic/iba/default_graphs/`,
            requestData,
            {
                credentials: "same-origin",
                // params: ,
                headers: {
                    "Content-Type": "application/json",
                    // "X-Auth-Token": auth,
                },
            }
        );
        const data = res?.data;
        if (data) {
            return {
                success: true,
                data: data,
            };
        } else {
            return {
                success: false,
                data: null,
            };
        }
    } catch (error) {
        console.log(error);
        return {
            success: false,
            data: null,
            error: error,
        };
    }
};

export const getIbaData = async (
    auth,
    requestData,
) => {
    try {
        const res = await axios.get(
            `${mulpicJsplBaseUrl}mulpic/iba/plates`,
            {
                credentials: "same-origin",
                params: requestData,
                headers: {
                    "Content-Type": "application/json",
                    // "X-Auth-Token": auth,
                },
            }
        );
        const data = res?.data;
        if (data) {
            const { columns, tableData } = data;
            const sortedData = tableData?.sort((a, b) => b?.["TOC"] - a?.["TOC"]);
            return {
                success: true,
                data: { columns, tableData: sortedData },
            };
        } else {
            return {
                success: false,
                data: null,
            };
        }
    } catch (error) {
        console.log(error);
        return {
            success: false,
            data: null,
            error: error,
        };
        // } finally {
        //   setLoading((prev) => {
        //     return { ...prev, upcomingBiasRecommender: false };
        //   });
    }
};

export const getIbaStaticData = async (
    auth,
    requestData,
) => {
    try {
        const res = await axios.get(
            `${mulpicJsplBaseUrl}mulpic/iba/edge_masking`,
            {
                credentials: "same-origin",
                params: requestData,
                headers: {
                    "Content-Type": "application/json",
                    // "X-Auth-Token": auth,
                },
            }
        );
        const data = res?.data;
        if (data) {
            const { columns, tableData } = data;
            const sortedData = tableData?.sort((a, b) => a?.["TOC"] - b?.["TOC"]);
            return {
                success: true,
                data: { columns, tableData: sortedData },
            };
        } else {
            return {
                success: false,
                data: null,
            };
        }
    } catch (error) {
        console.log(error);
        return {
            success: false,
            data: null,
            error: error,
        };
        // } finally {
        //   setLoading((prev) => {
        //     return { ...prev, upcomingBiasRecommender: false };
        //   });
    }
};