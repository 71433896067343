import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
  IconButton,
  ModalCloseButton,
  Badge,
  Switch,
  useToast,
} from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { Checkbox, CheckboxGroup } from '@chakra-ui/react'
import { useWindowSize } from "@uidotdev/usehooks";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import PrimaryButton from "../../../../util/Buttons/PrimaryButton";
import TonalButton from "../../../../util/Buttons/TonalButton";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import { baseURL } from "../../../../index";
import NavContext from "../../../NavContext";
import axios from "axios";
import { blue } from "@mui/material/colors";
import { Tooltip } from "@chakra-ui/react";
import { utils, writeFile } from "xlsx";

const ProductGroupCard = ({
  data,
  getProductGroups,
  setSelectedProductGroup,
  setShowProductTab,
  getProducts
}) => {
  const { auth } = useContext(NavContext);
  const toast = useToast();
  const navigate = useNavigate();
  const [isSwitchOn, setIsSwitchOn] = useState(data?.status === "INACTIVE");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [vision, setVision] = useState(0);
  const [userCount, setUserCount] = useState(0);
  const [optimus, setOptimus] = useState(0);
  const [productCategory, setProductCategory] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [enabledModalOpen, setEnabledModalOpen] = useState(false);



  const updateProductGroups = async (flag, status) => {

    const data1 = {
      productIdUuid: data.productIdUuid,
      isdeleted: flag,
      status: status,
    }
    const json = JSON.stringify(data1);
    const blob = new Blob([json], {
      type: "application/json",
    });
    const FormData = require("form-data");
    let datax = new FormData();
    datax.append("data", blob);

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: baseURL + "product/v1/update/productGroup",
      headers: {
        "X-Auth-Token": auth,
      },
      data: datax,
    };
    axios
      .request(config)
      .then((response) => {
        getProductGroups();
        getProducts();
      })
      .catch((error) => {
        //console.log(error);
        toast({
          title: "Something went wrong",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
      });

  }

  const handleEnableModal = async () => {
    await updateProductGroups(false, 1);
    // setTimeout(() => {
    //   getProductGroups();
    // })
    setEnabledModalOpen(false);

    setIsSwitchOn(!isSwitchOn);
    setTimeout(() => {
      setIsMenuOpen(false);
    }, 1500);
  }

  const handleSwitchChange = async () => {
    if (!isSwitchOn) {
      setIsModalOpen(true);
      return;
    }

    else setEnabledModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmDiscard = async () => {
    setIsSwitchOn(!isSwitchOn);
    await updateProductGroups(true, 2);
    // setTimeout(() => {
    //   getProductGroups();
    // }, 1500)
    setIsModalOpen(false);
    setTimeout(() => {
      setIsMenuOpen(false);
    }, 1000);
  };

  const getProductCategory = async () => {
    try {
      const response = await axios.get(baseURL + "service/getallServ", {
        headers: {
          "Content-Type": "application/json",
          "X-auth-Token": auth,
        },
      });

      const res = response?.data;
      const filteredProductCategory = res?.filter(
        (el) => el.servCategory === data.productGroupName
      );
      setProductCategory("filtered", filteredProductCategory);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = () => {
    setSelectedProductGroup(data);
    setShowProductTab((prev) => "viewProductGroup");
    //   navigate(
    //     `/superadmin/viewDetails/${data.organisation}/${data.clientId}/view`
    //   );
  };

  const exportAsExcel = (data, sheetName) => {
    let wb = utils.book_new();


    let fileColumns = Object.keys(data[0]);
    fileColumns = fileColumns.filter((value) => (value != "productIdUuid" && value != "createdAt" && value != "updatedAt" && value != "productList"));
    let newData = [fileColumns];

    if (data.length != 0) {
      data?.forEach((item) => {
        const newArray = fileColumns?.map((i) => {
          return i.toLowerCase().includes("time")
            ? new Date(item[i]).toLocaleString()
            : item[i];
        });
        newData.push(newArray);
      });
    }

    let ws = utils.aoa_to_sheet(newData);
    utils.book_append_sheet(wb, ws, `${sheetName}`);
    writeFile(wb, `report_data.xlsx`);
  };

  const handleDownloadClick = async () => {
    if (Array.isArray(data)) exportAsExcel(data, "Summary");
    else exportAsExcel([data], "Summary");
  };

  const handleUpdate = () => {
    //   navigate(`/superadmin/update/${data.organisation}/${data.clientId}/update`);
    setSelectedProductGroup(data);
    setShowProductTab((prev) => "updateProductGroup");
  };

  const switchLabelText = isSwitchOn ? "Disabled" : "Disable";
  const size = useWindowSize();
  const productString = data?.productList?.map((value, index) => value.productName)
  const products = productString;
  let maxProductsToShow = 0;
  let productsLengthExceeds = false;
  let totalLength = 0;

  const calculateProduct = () => {
    for (let i = 0; i < products?.length; i++) {
      // Assuming each location contributes 10 characters on average

      // Calculate the total length with the new location
      totalLength += products[i]?.length;

      // Increment maxLocationsToShow if the total length is within the limit
      if (totalLength <= (size.width < 1536 ? 14 : 25)) {
        maxProductsToShow++;
      } else {
        maxProductsToShow++;
        productsLengthExceeds = true;
        // Stop incrementing if the limit is exceeded
        break;
      }
    }
  };

  products && calculateProduct();

  let allTools = data?.productList?.map((value, index) => value.productName)
  const maxToolsToShow = 2;

  useEffect(() => {
    // getProductCategory();
  }, []);

  return (
    <div
      className="px-4 py-5 rounded flex flex-col justify-between h-[250px] gap-4 relative bg-white w-full"
      style={{
        boxShadow:
          "-4px -4px 24px 0px rgba(0, 0, 0, 0.07), 4px 4px 24px 0px rgba(0, 0, 0, 0.07)",
      }}
    >

      <div className="flex flex-col gap-2 w-full">
        <div className="flex h-[45px] gap-0 w-full">
          <div className="flex justify-start">
            {data?.productGroupUrl ? (
              <img
                src={data?.productGroupUrl}
                alt="logo"
                className="w-full max-w-[30px] h-[30px]"
              />
            ) : (
              <img src="/ProductGroupDefaultIcon.svg" alt="" className="w-full max-w-[30px] h-[30px]" />
            )}
          </div>

          <div className="ml-2 h-[45px] w-[70%] overflow-hidden">
            <Tooltip label={data?.productGroupName} placement="top">
              <p className="text-[#3E3C42] text-[16px] font-medium whitespace-pre-line line-clamp-2 overflow-hidden overflow-ellipsis">
                {data?.productGroupName}
              </p>
            </Tooltip>
          </div>

          <div className=" w-[10%]">
            <Menu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<MoreVertIcon />}
                variant="simple"
                position={"absolute"}
                top={"15px"}
                right={"0px"}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              />
              <MenuList
                px={"12px"}
                py={"16px"}
                rounded={"8px"}
                display={"flex"}
                flexDirection={"column"}
                gap={"16px"}
                minWidth={"fit-content"}
                w={"180px"}
                position={"absolute"}
                right={"-50px"}
                top={"-15px"}
              >
                <MenuItem
                  icon={<DownloadIcon />}
                  textColor={"#605D64"}
                  fontSize={"14px"}
                  fontWeight={500}
                  p={"8px"}
                  _hover={{ bg: "#DDEEFF80", borderRadius: "4px" }}
                  _focus={{ bg: "#DDEEFF80", borderRadius: "4px" }}
                  onClick={handleDownloadClick}
                >
                  {"Download"}
                </MenuItem>
                {/* <MenuItem
                  icon={<DeleteIcon />}
                  textColor={"#605D64"}
                  fontSize={"14px"}
                  fontWeight={500}
                  p={"8px"}
                  _hover={{ bg: "#DDEEFF80", borderRadius: "4px" }}
                  _focus={{ bg: "#DDEEFF80", borderRadius: "4px" }}
                >
                  {"Delete"}
                </MenuItem> */}
                <Checkbox
                  //iconColor='red'
                  colorScheme="red"
                  p={"8px"}
                  isChecked={isSwitchOn}
                  disabled={data?.status === "DRAFT"}
                  //isIndeterminate={isIndeterminate}
                  onChange={handleSwitchChange}
                >
                  {switchLabelText}
                </Checkbox>
              </MenuList>
            </Menu>
            <Modal size={"sm"} isOpen={isModalOpen} onClose={handleCloseModal}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader className=" flex justify-center rounded-t-md bg-red-400 text-white ">
                  Confirm Disable ?
                </ModalHeader>
                {/* <ModalCloseButton /> */}
                <ModalBody m={"auto"} fontSize={"15px"}>
                  <div className="flex flex-col items-center justify-center w-full">
                    <img
                      src={require("./disable.png")}
                      className="w-[100px] h-[100px]"
                    />

                    <p className="ml-4 font-medium">
                      Are you sure you would like to disable the product group?
                    </p>
                  </div>
                </ModalBody>

                <div className="mt-4 flex flex-row justify-center">
                  <Button
                    colorScheme="blue"
                    mr={4}
                    onClick={handleConfirmDiscard}
                  >
                    Confirm
                  </Button>
                  <Button onClick={handleCloseModal}>Go Back</Button>
                </div>

                <ModalFooter></ModalFooter>
              </ModalContent>
            </Modal>
            <Modal size={"md"} isOpen={enabledModalOpen} onClose={() => setEnabledModalOpen(false)}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader className=" flex justify-center rounded-t-md bg-blue-700 text-white ">
                  Product group enabled
                </ModalHeader>
                <ModalCloseButton className="mt-2" color={"white"} />
                <ModalBody fontSize={"15px"}>
                  <div className="flex flex-col items-center justify-center w-full">
                    <div>
                      <img
                        src="/enabled.svg"
                        className="w-[80px] h-[80px]"
                      />
                    </div>

                    <div className="flex flex-col items-center">
                      <p className="font-bold">
                        Device group has been enabled successfully
                      </p>
                      <div className="flex justify-center items-center">
                        {/* <p className=" font-normal"> */}
                        Please make sure you also enable all the devices individually
                        {/* </p> */}
                      </div>

                    </div>

                  </div>
                </ModalBody>

                <div className="mt-4 flex flex-row justify-center">
                  <Button
                    colorScheme={"blue"}
                    // mr={"#084298"}
                    onClick={handleEnableModal}
                  >
                    Okay
                  </Button>
                  {/* <Button onClick={handleCloseModal}>Go Back</Button> */}
                </div>

                <ModalFooter></ModalFooter>
              </ModalContent>
            </Modal>
          </div>
          {/* <div></div> */}
        </div>
        <div className="grid grid-cols-2 gap-2">
          <div className="flex items-center justify-start">
            <div className="flex flex-col items-start justify-start">
              <p className="text-[#938F96] text-sm">Parent</p>
              <div className="flex items-center justify-center">
                <div className="">
                  {
                    data?.productGroupParent.toLowerCase() === "community" && <img className="w-[20px] h-[20px]" src="/CommunityIcon.svg" alt="" />
                  }
                  {
                    data?.productGroupParent.toLowerCase() === "vision" && <img className="w-[20px] h-[20px]" src="/VisionIcon.svg" alt="" />
                  }
                  {
                    data?.productGroupParent.toLowerCase() === "optimus" && <img className="w-[20px] h-[20px]" src="/OptimusIcon.svg" alt="" />
                  }
                  {
                    data?.productGroupParent.toLowerCase() === "others" && <img className="w-[20px] h-[20px]" src="/casino.svg" alt="" />
                  }
                </div>
                <div className="ml-2 overflow-hidden">
                  <Tooltip label={data?.productGroupParent} placement="top">
                    <p className="whitespace-pre-line line-clamp-1 overflow-hidden overflow-ellipsis">{data?.productGroupParent}</p>
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-start">
            <div className="flex flex-col items-start justify-start">
              <p className="text-[#938F96] text-sm">Products</p>
              <div className="flex items-center justify-center">
                <div className="">
                  <img className="w-[20px] h-[20px]" src="/tools.png" alt="" />
                </div>
                <div className="ml-2">
                  <p>{data?.productCount}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="">
          <Tooltip label={allTools?.join(", ")} placement="top">
            <div>
              <p className="text-[#938F96] text-sm">Product names</p>
              <div className="flex mt-2 justify-between">

                <div className="flex w-[180px] 2xl:w-[90%] items-center">
                  <img src="/superadmin/toolsGray.svg" alt="" />
                  <p className="text-[#938F96] text-sm font-medium truncate">{products.join(", ")}</p>
                  {/* {allTools?.slice(0, maxToolsToShow)?.map((el, index) => (
                    <div key={index} className="flex items-center overflow-hidden overflow-ellipsis whitespace-nowrap">
                      <p className="text-[#938F96] text-sm font-medium whitespace-pre-line line-clamp-1">{el + " "}</p>

                    </div>
                  ))} */}
                </div>

                {products?.length > maxProductsToShow && (
                  <Badge
                    ml={1}
                    color={"#447ED4"}
                    bg={"white"}
                    fontWeight={600}
                    fontSize="14px"
                  >
                    +{products?.length - maxProductsToShow}
                  </Badge>
                )}
              </div>
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="flex gap-2 items-center">

        <button
          className={
            // data?.status === "INACTIVE"
            //   ? "flex items-center gap-1 justify-center text-[#AEA9B1] text-sm font-medium rounded bg-[#EBEBEB]"
            //   : 
            "flex items-center gap-1 justify-center text-[#FFF] text-sm font-medium rounded bg-[#378805] hover:bg-[#CDEEBF] hover:shadow-[0px_2px_6px_2px_rgba(0,0,0,0.15),0px_1px_2px_0px_rgba(0,0,0,0.30)] focus:bg-[#CDEEBF] focus:shadow-[0px_2px_6px_2px_rgba(0,0,0,0.15),0px_1px_2px_0px_rgba(0,0,0,0.30)] focus:outline-none active:bg-[#CDEEBF]"
          }
          style={{
            width: "100%",
            height: "auto",
            padding: '8px 16px 8px 16px'
          }}
          onClick={() => handleClick()}
        // disabled={!data.isactive}
        >
          View
        </button>

        <button
          className={
            data?.status === "INACTIVE"
              ? "flex items-center justify-center gap-1 text-[#AEA9B1] text-sm rounded bg-[#EBEBEB]"
              : "flex items-center justify-center gap-1 text-[#378805] text-sm rounded bg-[#CDEEBF] hover:bg-[#CDEEBF] hover:shadow-[0px_2px_6px_2px_rgba(0,0,0,0.10),0px_1px_2px_0px_rgba(0,0,0,0.10)] focus:bg-[#CDEEBF] focus:shadow-[0px_2px_6px_2px_rgba(0,0,0,0.10),0px_1px_2px_0px_rgba(0,0,0,0.10)] focus:outline-none active:bg-[#CDEEBF]"
          }
          style={{
            width: "100%",
            height: "auto",
            padding: "8px 16px 8px 16px",
          }}
          onClick={() => handleUpdate()}
          disabled={data?.status === "INACTIVE"}
        >
          Update
        </button>
        {/* <TonalButton
          color="#CDEEBF"
          text={"Update"}
          onClick={() => handleUpdate()}
          disable={""}
          textColor="#378805"
        /> */}
      </div>

      <Tooltip
        label={data?.status?.toLowerCase()}
        hasArrow
      >

        {data?.status === "DRAFT" ?
          <img
            src={`/Common/draft.svg`}
            className="w-8 h-8 absolute -top-2 -right-2 rounded-full bg-white p-1 shadow-md"
            alt="status"
          /> :
          data?.status === "ACTIVE" ? <CheckCircleIcon sx={{
            position: 'absolute',
            top: "-8px",
            right: "-8px",
            backgroundColor: "white",
            padding: "4px",
            width: "32px",
            height: "32px",
            borderRadius: "9999px",
            color: "green",
            boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
          }} /> :
            <CancelIcon sx={{
              position: 'absolute',
              top: "-8px",
              right: "-8px",
              backgroundColor: "white",
              padding: "4px",
              borderRadius: "9999px",
              width: "32px",
              height: "32px",
              color: "#E46962",
              boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
            }} />}
        {/* {
            data?.status 
          } */}

      </Tooltip>

    </div>
  );
};

export default ProductGroupCard;
