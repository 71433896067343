import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useState, useEffect } from "react";
import {
  useLocation,
  useParams,
  Link,
  useSearchParams,
} from "react-router-dom";
import Feed from "./Tabs/Feed";
import FeedLibrary from "./Tabs/FeedLibrary";
import Alerts from "./Tabs/Alerts";
import Report from "./Tabs/Report";
import Analytics from "./Tabs/Analytics";
import { useWindowSize } from "@uidotdev/usehooks";
import { mixpanelTrackTabs } from "../../util/mixpanelFunctions";
import { capitalizeFirstLetter } from "../../util/sentenceCase";

const Capitalize = (str) => {
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
};

export const indexWordMap = {
  0: "View Obstructed",
  // 1: "Healthy flame",
  1: "Low flame",
  2: "Healthy flame",
  3: "Flame out",
};

export const recommendations = {
  0: <p>Clean camera lens/Inspection Window/Remove any obstacle in between</p>,
  // 1: <p>Keep up the good work!</p>,
  1: <p>Increase Fuel Rate &uarr;</p>,
  2: <p>Keep up the good work!</p>,
  3: <p>Reduce Fuel Rate &darr;</p>,
};

const Sinterflame = () => {
  const size = useWindowSize();
  const [searchParam, setSearchParam] = useSearchParams();
  const [plantCamMap, setPlantCamMap] = useState({});
  const page = !isNaN(searchParam?.get("tabIndex"))
    ? Number(searchParam?.get("tabIndex"))
    : 0;
  const param = useParams();

  const handleTabChange = (val) => {
    if (val !== page) {
      setSearchParam({ tabIndex: val }, { replace: true });
    }
  };

  useEffect(() => {
    handleTabChange(page);
    let tool = "Sinter Flame Monitoring";
    mixpanelTrackTabs(tabList[page]?.tabName?.toLowerCase(), tool);
  }, [page]);

  const tabList = [
    {
      index: 0,
      tabName: "Feed",
      element: (
        <Feed
          material={"sinterflame"}
          clientId={param.clientId.toLowerCase()}
          setPlantCamMap={setPlantCamMap}
        />
      ),
    },
    {
      index: 1,
      tabName: "Alerts",
      element: (
        <Alerts
          plantId="All Plants"
          cameraId=""
          disable={false}
          plantCamMap={plantCamMap}
        />
      ),
    },
    {
      index: 2,
      tabName: "Feed library",
      element: (
        <FeedLibrary
          plantId="All Plants"
          cameraId=""
          disable={false}
          plantCamMap={plantCamMap}
        />
      ),
    },
    {
      index: 3,
      tabName: "Analytics",
      element: Object.keys(plantCamMap).length != 0 && (
        <Analytics
          plantId={Object.keys(plantCamMap)[0]}
          cameraId={plantCamMap[Object.keys(plantCamMap)[0]][0]}
          disable={false}
          plantCamMap={plantCamMap}
        />
      ),
    },
    {
      index: 4,
      tabName: "Report",
      element: (
        <Report
          plantId="All Plants"
          cameraId=""
          disable={false}
          plantCamMap={plantCamMap}
        />
      ),
    },
  ];

  return (
    <div
      className="font-poppins flex flex-col rounded-lg"
      style={{ width: size.width >= 768 ? "calc(100vw - 168px)" : "100vw" }}
    >
      <div
        className="fixed top-5 right-0 h-[30px] w-[180px] bg-white"
        style={{ zIndex: 1000 }}
      >
        <img
          className="absolute h-[50px] w-[120px] top-[-20px] right-2"
          src="/clientLogos/hzl.png"
        />
      </div>
      <div className="flex justify-start gap-2 items-center mb-3 mt-6">
        <Link
          to={`/vision/ProcessMonitoring/sinterflame`}
          style={{
            textDecoration: "none",
          }}
        >
          <img src="/backtick.svg" />
        </Link>
        <p className="text-lg sm:text-2xl font-semibold text-[#024D87]">
          {capitalizeFirstLetter("Sinter Flame Monitoring")}
        </p>
      </div>

      <Tabs index={page} onChange={handleTabChange}>
        <TabList className="!flex !border-0">
          <div className="flex items-center gap-4 overflow-x-auto h-14 md:h-10">
            {tabList?.map((item, idx) => {
              return (
                <Tab
                  key={idx}
                  className={
                    item?.index === page
                      ? "!text-black !text-xs sm:!text-sm !bg-white rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#79767D]"
                      : "!text-xs sm:!text-sm !text-[#938F96] !border-0"
                  }
                  onClick={() => handleTabChange(item?.index)}
                >
                  {item?.tabName}
                </Tab>
              );
            })}
          </div>
        </TabList>

        <TabPanels>
          {tabList?.map((item, idx) => (
            <TabPanel key={idx} className="!pl-0 !pr-0">
              {item.element}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default Sinterflame;
