import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { forwardRef, useEffect, useRef, useState } from "react";
import { createTheme, ThemeProvider, Tooltip } from "@mui/material";
import { Flex, Image } from "@chakra-ui/react";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import LoopIcon from "@mui/icons-material/Loop";
import {
  gridPaginatedVisibleSortedGridRowEntriesSelector,
  useGridApiRef,
} from "@mui/x-data-grid";
import { CustomStyledDataGrid } from "../../../util/MaterialDataGrid/CustomStyledDatagrid";
import { useWindowSize } from "@uidotdev/usehooks";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { capitalizeFirstLetter } from "../../../util/sentenceCase";

const MuiTheme = createTheme();

const ActionButtons = ({ actionUpdate }) => {
  const [actionPerRow, setActionPerRow] = useState(actionUpdate);
  return (
    <div className="flex w-full gap-2 justify-center">
      {actionPerRow.actionTaken === false ? (
        <div className="w-full flex justify-center gap-6">
          <div
            className={`bg-transparent rounded-md cursor-pointer transform hover:scale-110 transition-transform text-blue-800
        
        `}
            fontSize={"20px"}
            onClick={() => {
              setActionPerRow((prevState) => ({
                ...prevState,
                actionStatus: true,
                actionTaken: true,
              }));
            }}
          >
            <img src="/actionTick.svg" alt="yes" />
          </div>

          <div
            className="bg-transparent rounded-md  cursor-pointer transform hover:scale-110 transition-transform text-[red]"
            fontSize={"20px"}
            onClick={() => {
              setActionPerRow((prevState) => ({
                ...prevState,
                actionStatus: false,
                actionTaken: true,
              }));
            }}
          >
            <img src="/actionCross.svg" alt="yes" />
          </div>
        </div>
      ) : (
        <div className="w-full flex justify-center gap-6">
          {actionPerRow.actionStatus === true ? (
            <div
              className={` ${
                actionPerRow.actionStatus === true
                  ? "cursor-not-allowed  transform  transition-transform text-blue-800"
                  : "cursor-not-allowed text-grey-600"
              } bg-transparent rounded-md 

`}
              fontSize={"20px"}
            >
              <img src="/actionTick.svg" alt="yes" />
            </div>
          ) : (
            <div
              className={`${
                actionPerRow.actionStatus === false
                  ? "cursor-not-allowed  transform  transition-transform text-[red]"
                  : "cursor-not-allowed text-grey-600"
              }
          bg-transparent rounded-md  `}
              fontSize={"20px"}
            >
              <img src="/actionCross.svg" alt="yes" />
            </div>
          )}
          <div
            className="text-blue-600 bg-transparent rounded-md cursor-pointer transform hover:scale-110 transition-transform"
            onClick={() => {
              setActionPerRow((prevState) => ({
                ...prevState,
                actionTaken: false,
              }));
            }}
          >
            <LoopIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export const stringDecimalFix = (str) => {
  const regex = /-?\d+\.\d+/;
  const match = str.match(regex);
  if (match) {
    const floatingNumber = parseFloat(match[0]);
    const fixedNumber = floatingNumber.toFixed(2);
    return str.replace(regex, fixedNumber);
  }
  return str;
};

const getDynamicObjForColumns = {
  plantName: {
    field: "plantName",
    type: "string",
    headerName: "Plant name",
    minWidth: 110,
    valueFormatter: (params) => {
      return capitalizeFirstLetter(params.value); // Convert value to sentence case
    },
  },
  cameraId: {
    field: "cameraId",
    type: "string",
    minWidth: 110,

    headerName: "Camera",
    valueFormatter: (params) => {
      return capitalizeFirstLetter(params.value); // Convert value to sentence case
    },
  },
  startTime: {
    field: "startTime",
    headerName: "Start time",
    valueFormatter: ({ value }) => {
      if (value.toString().length === 13) {
        return new Date(value).toLocaleString("en-GB", {
          timeZone: "IST",
        });
      }

      return new Date(value * 1000).toLocaleString("en-GB", {
        timeZone: "UTC",
      });
    },
    type: "dateTime",
    minWidth: 150,
  },
  endTime: {
    field: "endTime",
    headerName: "End time",
    valueFormatter: ({ value }) => {
      if (value.toString().length === 13) {
        return new Date(value).toLocaleString("en-GB", {
          timeZone: "IST",
        });
      }

      return new Date(value * 1000).toLocaleString("en-GB", {
        timeZone: "UTC",
      });
    },
    type: "dateTime",
    minWidth: 150,
  },
  timestamp: {
    field: "createdAt",
    headerName: "Time stamp",
    valueFormatter: ({ value }) => {
      return new Date(value * 1000).toLocaleString("en-GB", {
        timeZone: "UTC",
      });
    },
    type: "dateTime",
    minWidth: 170,
  },
  type: {
    field: "type",
    type: "string",
    minWidth: 100,

    headerName: "Type",
    valueFormatter: (params) => {
      return capitalizeFirstLetter(params.value); // Convert value to sentence case
    },
  },
  mgw: {
    field: "mgw",
    type: "string",
    minWidth: 100,

    headerName: "MGW",
  },
  avgGapWidths: {
    field: "avgGapWidths",
    type: "actions",

    headerName: "Avg gap widths",
    minWidth: 150,
    renderCell: ({ row }) => (
      <p>
        {row?.avgGapWidths?.[0]?.map((e, i) => {
          return i != row?.avgGapWidths?.[0].length - 1 ? e + ", " : e;
        })}
      </p>
    ),
  },
  alertsCount: {
    field: "alertsCount",
    type: "string",
    minWidth: 120,

    headerName: "Alerts count",
    valueFormatter: (params) => {
      return capitalizeFirstLetter(params.value); // Convert value to sentence case
    },
  },

  actionUpdate: {
    field: "actionUpdate",
    headerName: "Action taken",
    headerAlign: "center",
    minWidth: 120,

    renderCell: ({ row }) => <ActionButtons actionUpdate={row.actionUpdate} />,
  },
};

const DataGridTable = ({
  rowData,
  handleDetail,
  order,
  setDisplayData,
  setOpenModal,
  indexRef,
  showViewDetail,
  showActionButton,
  showDetailModalByRowData,
  showHealthIndex,
}) => {
  const size = useWindowSize();
  const gridApiRef = useGridApiRef();
  console.log("order", order);
  console.log("data", rowData);

  let columns = [
    {
      field: "id",
      headerName: "Sr No.",
    },
  ];
  order?.map((ele, idx) => {
    const objectToPush = getDynamicObjForColumns?.[ele];
    if (objectToPush) columns.push(objectToPush);
  });

  if (showViewDetail) {
    columns.push({
      field: "",
      headerName: "View details",
      minWidth: 120,
      headerAlign: "center",
      renderCell: (params) => {
        console.log("param", params);
        return (
          <button
            onClick={() => {
              if (showDetailModalByRowData) {
                console.log("indexRef--1", indexRef, setDisplayData);

                handleDetail(params.row);
              } else {
                console.log("indexRef--2", indexRef, setDisplayData);

                handleViewDetails(params);
              }
            }}
            className="cursor-pointer w-full text-[#084298] font-semibold text-normal hover:scale-105 flex justify-center"
          >
            <VisibilityIcon />
          </button>
        );
      },
    });
  }
  if (showActionButton) {
    columns.push({
      field: "actionUpdate",
      headerName: "Action taken",
      headerAlign: "center",
      minWidth: 120,

      renderCell: ({ row }) => (
        <ActionButtons actionUpdate={row.actionUpdate} />
      ),
    });
  }

  const handleViewDetails = (params) => {
    const rowID = params?.row?.id
      ? params?.row.id
      : params?.row?.startTime +
        params?.row?.endTime +
        params?.row?.cameraId +
        params?.row?.createdAt;
    const tempDisplayData =
      gridPaginatedVisibleSortedGridRowEntriesSelector(gridApiRef);
    const updatedData = tempDisplayData.map((el, id) => el.model);
    setDisplayData((prev) => updatedData);

    const index = params.api.getRowIndexRelativeToVisibleRows(rowID);
    console.log(rowID);
    indexRef.current = index;
    setOpenModal(true);
  };

  const [rows, setRows] = useState([]);
  const headerClass = "text-xs font-medium text-[#525056] bg-[#ddeeff] ";
  const cellClass = "text-sm font-medium text-[#3E3C42]  p-4";
  const flexMap = {
    id: 0,
    plantName: 1,
    cameraId: 1,
    timestamp: 2,
    startTime: 1,
    createdAt: 1,
    endTime: 1,
    type: 0.8,
    mgw: 0.7,
    avgGapWidths: 1.5,
    alertsCount: 0.7,
  };
  columns.map((val, idx) => {
    val["headerClassName"] = headerClass;
    val["cellClassName"] = cellClass;
    val["flex"] = flexMap[val.field];
    return val;
  });

  useEffect(() => {
    setRows((prev) => {
      let newData = rowData.map((item, idx) => {
        if (!item.hasOwnProperty("id")) {
          return {
            ...item,
            id: idx,
            actionUpdate: {
              actionTaken: false,
              actionStatus: true,
            },
          };
        } else {
          // If 'id' already exists, do nothing
          return {
            ...item,
            actionUpdate: {
              actionTaken: false,
              actionStatus: true,
            },
          };
        }
      });
      return newData;
    });
  }, [rowData]);
  console.log("rows", rows);
  return (
    <div className=" w-full h-[70vh]">
      <CustomStyledDataGrid
        apiRef={gridApiRef}
        rows={rows}
        columns={columns}
        columnVisibilityModel={{
          id: false,
        }}
        disableColumnSelector={true}
        getRowId={(row) => {
          if (row?.id) {
            return row.id;
          }
          return row?.startTime + row?.endTime + row?.cameraId + row?.createdAt;
        }}
        columnHeaderHeight={45}
        rowHeight={65}
        initialState={{
          pagination: { paginationModel: { pageSize: 5 } },
          sorting: {
            sortModel: [
              // { field: "timestamp", sort: "desc" },
              { field: "startTime", sort: "desc" },
            ],
          },
        }}
        pagination
        // slots={{
        //   toolbar: CustomToolbar,
        // }}
        pageSizeOptions={[5, 10, 25]}
        sx={{
          "& .MuiDataGrid-columnHeader": {
            border: "0.9px solid #e0e0e0",
            textTransform: "none",
            fontSize: size.width < 768 ? "13px" : "14px",
          },
        }}
        experimentalFeatures={{ columnGrouping: true }}
      />
    </div>
  );
};
export default DataGridTable;
