import {
  Button,
  Checkbox,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tooltip,
} from "@chakra-ui/react";
import DownloadIcon from "@mui/icons-material/Download";
import { useContext, useState } from "react";
import axios from "axios";
import { baseURL } from "../../../..";
import NavContext from "../../../NavContext";
import { utils, writeFile } from "xlsx";
import DisableModal from "../../DisableModal/DisableModal";
import { formatDate } from "../../utils/timeFormatter";
import { exportAsExcel, status } from "../../utils/common";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// const status = (val) => {
//   switch (val) {
//     case "DRAFT":
//       return "draft";
//     case "ACTIVE":
//       return "live";
//     case "INACTIVE":
//       return "fail";
//     default:
//       break;
//   }
// };
const AlertGroupCard = ({
  data,
  setSelectedAlert,
  setShowAlertsTab,
  getAlertGroupApi,
}) => {
  const { auth } = useContext(NavContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  let wb = utils.book_new();

  const patchAlertGroup = async (status, isDeleted) => {
    const alertGroupId = data?.alertGroupId;

    const payload = {
      alertGroupId,
      status,
      isDeleted,
    };

    const json = JSON.stringify(payload);
    const blob = new Blob([json], {
      type: "application/json",
    });
    const FormData = require("form-data");
    let data1 = new FormData();
    data1.append("data", blob);

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: baseURL + "alert/v1/update/alertGroup",
      headers: {
        "X-Auth-Token": auth,
      },
      data: data1,
    };
    axios
      .request(config)
      .then((response) => {
        getAlertGroupApi();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleUpdate = () => {
    setShowAlertsTab((prev) => "updateAlertGroup");
    setSelectedAlert(data);
  };

  const handleView = () => {
    setShowAlertsTab((prev) => "viewAlertGroup");
    setSelectedAlert(data);
  };

  const handleSwitchChange = (e) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setTimeout(() => {
        setIsModalOpen(true);
      }, 300);
    } else {
      patchAlertGroup(1, false);
      setTimeout(() => {
        setIsMenuOpen(false);
      }, 1000);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmDisable = async () => {
    await patchAlertGroup(2, true);

    // getAlertGroupApi();

    setIsModalOpen(false);
    setTimeout(() => {
      setIsMenuOpen(false);
    }, 1000);
  };

  // const exportAsExcel = (data, sheetName) => {
  //   let newData = [];

  //   if (data?.length > 0 && data[0]) {
  //     const fileColumns = Object.keys(data[0]);

  //     if (fileColumns && fileColumns?.length > 0) {
  //       newData.push(fileColumns.map((column) => column?.toLowerCase()));

  //       data.forEach((item) => {
  //         if (item) {
  //           const newArray = fileColumns?.map((column) => {
  //             return column.toLowerCase()?.includes("time")
  //               ? new Date(item[column])?.toLocaleString()
  //               : item[column];
  //           });
  //           newData.push(newArray);
  //         }
  //       });

  //       let ws = utils.aoa_to_sheet(newData);
  //       utils.book_append_sheet(wb, ws, sheetName);
  //     } else {
  //       console.error("fileColumns is undefined or empty");
  //     }
  //   } else {
  //     console.error(
  //       "data array is empty or does not have the expected structure"
  //     );
  //   }
  // };

  const handleDownloadClick = async () => {
    if (data?.length != 0) {
      if (Array.isArray(data)) exportAsExcel(data, "alert_group_data", wb);
      else exportAsExcel([data], "alert_group_data", wb);
    }

    writeFile(wb, `report_data.xlsx`);
  };

  return (
    <div
      className="px-4 py-5 rounded flex flex-col justify-between gap-4 relative bg-white h-[250px] w-full"
      style={{
        boxShadow:
          "-4px -4px 24px 0px rgba(0, 0, 0, 0.07), 4px 4px 24px 0px rgba(0, 0, 0, 0.07)",
      }}
    >
      <div className="flex flex-col gap-2 w-full">
        <div className="flex h-[45px] gap-1 w-full">
          <div className="flex justify-start">
            <img
              src={"/superadmin/Bell.svg"}
              alt="logo"
              className="w-full max-w-[30px] h-[30px]"
            />
          </div>

          <div className="ml-2 h-[45px] w-[70%] overflow-hidden">
            <Tooltip label={data?.alertGroupName} placement="top-start">
              <p className="text-[#3E3C42] text-[16px] font-medium whitespace-pre-line line-clamp-2 overflow-hidden overflow-ellipsis">
                {data?.alertGroupName}
              </p>
            </Tooltip>
            <Tooltip label={formatDate(data?.createdAt)} placement="top-start">
              <p className=" text-sm font-medium whitespace-nowrap overflow-hidden overflow-ellipsis">
                {formatDate(data?.createdAt)}
              </p>
            </Tooltip>
          </div>

          <div className="w-[10%]">
            <Menu isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
              <MenuButton
                as={IconButton}
                aria-label="Options"
                icon={<MoreVertIcon />}
                variant="simple"
                position={"absolute"}
                top={"15px"}
                right={"0px"}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              />
              <MenuList
                px={"12px"}
                py={"16px"}
                rounded={"8px"}
                display={"flex"}
                flexDirection={"column"}
                gap={"16px"}
                minWidth={"fit-content"}
                w={"180px"}
                position={"absolute"}
                right={"-50px"}
                top={"-15px"}
              >
                <MenuItem
                  icon={<DownloadIcon />}
                  textColor={"#605D64"}
                  fontSize={"14px"}
                  fontWeight={500}
                  p={"8px"}
                  _hover={{ bg: "#DDEEFF80", borderRadius: "4px" }}
                  _focus={{ bg: "#DDEEFF80", borderRadius: "4px" }}
                  onClick={handleDownloadClick}
                >
                  {"Download"}
                </MenuItem>
                <div className="flex ml-3">
                  <Checkbox
                    isChecked={data?.isDeleted}
                    // color={data?.isDeleted ? "#E46962" : ""}
                    colorScheme={data?.isDeleted ? "red" : ""}
                    fontWeight={500}
                    fontSize={"14px"}
                    onChange={handleSwitchChange}
                    isDisabled={data?.status === "DRAFT"}
                  >
                    {data?.isDeleted ? "Disabled" : "Disable"}
                  </Checkbox>
                </div>
              </MenuList>
            </Menu>
            {/* disable modal */}
            {isModalOpen && (
              <DisableModal
                isOpen={isModalOpen}
                text={
                  "Are you sure you would like to disable the alert group ?"
                }
                onClose={handleCloseModal}
                handleConfirmDisable={handleConfirmDisable}
              />
            )}
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className=" flex">
            <div className="w-1/2">
              <p className="text-[#938F96] text-sm ">Type</p>
              <p className="mt-1 capitalize text-sm text-[#525056] font-normal truncate">
                {data?.alertType}
              </p>
            </div>
            <div className="w-1/2 ">
              <p className="text-[#938F96] text-sm capitalize">
                {data?.alertType}
              </p>
              <Tooltip label={data?.alertTypeName} placement="top-start">
                <p className="mt-1 capitalize text-sm text-[#525056] font-medium truncate">
                  {data?.alertTypeName}
                </p>
              </Tooltip>
            </div>
          </div>

          <div>
            <p className="text-[#938F96] text-sm capitalize">
              {`${data?.alertType} Group`}
            </p>
            <Tooltip label={data?.alertTypeGroup} placement="top-start">
              <p className="mt-2 capitalize text-sm text-[#525056] font-medium truncate">
                {data?.alertTypeGroup}
              </p>
            </Tooltip>
          </div>
        </div>
      </div>

      <div className="flex gap-2 items-center">
        <button
          className={
            "flex items-center gap-1 justify-center text-[#FFF] text-sm font-medium rounded bg-[#378805] hover:text-[#378805] hover:bg-[#CDEEBF] hover:shadow-[0px_2px_6px_2px_rgba(0,0,0,0.15),0px_1px_2px_0px_rgba(0,0,0,0.30)] focus:bg-[#CDEEBF] focus:shadow-[0px_2px_6px_2px_rgba(0,0,0,0.15),0px_1px_2px_0px_rgba(0,0,0,0.30)] focus:outline-none active:bg-[#CDEEBF]"
          }
          style={{
            width: "100%",
            height: "auto",
            padding: "8px 16px 8px 16px",
          }}
          onClick={() => handleView()}
        >
          View
        </button>

        <button
          className={
            data?.status === "INACTIVE"
              ? "flex items-center justify-center gap-1 text-[#AEA9B1] text-sm rounded bg-[#EBEBEB]"
              : "flex items-center justify-center gap-1 text-[#378805] text-sm rounded bg-[#CDEEBF] hover:bg-[#CDEEBF] hover:shadow-[0px_2px_6px_2px_rgba(0,0,0,0.10),0px_1px_2px_0px_rgba(0,0,0,0.10)] focus:bg-[#CDEEBF] focus:shadow-[0px_2px_6px_2px_rgba(0,0,0,0.10),0px_1px_2px_0px_rgba(0,0,0,0.10)] focus:outline-none active:bg-[#CDEEBF]"
          }
          style={{
            width: "100%",
            height: "auto",
            padding: "8px 16px 8px 16px",
          }}
          onClick={() => handleUpdate()}
          disabled={data?.status === "INACTIVE"}
        >
          Update
        </button>
      </div>

      <Tooltip label={data?.status?.toLowerCase()} hasArrow>
        {data?.status === "DRAFT" ?
          <img
            src={`/Common/draft.svg`}
            className="w-8 h-8 absolute -top-2 -right-2 rounded-full bg-white p-1 shadow-md"
            alt="status"
          /> :
          data?.status === "ACTIVE" ? <CheckCircleIcon sx={{
            position: 'absolute',
            top: "-8px",
            right: "-8px",
            backgroundColor: "white",
            padding: "4px",
            width: "32px",
            height: "32px",
            borderRadius: "9999px",
            color: "green",
            boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
          }} /> :
            <CancelIcon sx={{
              position: 'absolute',
              top: "-8px",
              right: "-8px",
              backgroundColor: "white",
              padding: "4px",
              borderRadius: "9999px",
              width: "32px",
              height: "32px",
              color: "#E46962",
              boxShadow: "0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)"
            }} />}
      </Tooltip>
    </div>
  );
};

export default AlertGroupCard;
