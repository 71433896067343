import ReactApexChart from "react-apexcharts";
import { useState, useEffect } from "react";

const DonutChart = ({ data, labels, position, thresholds = null }) => {
  const [series, setSeries] = useState([]);
  const customLabels = labels;
  const [customLegendItems, setCustomLegendItems] = useState([]);

  useEffect(() => {
    const newData = data.map((x) => {
      return parseFloat(x.toFixed(2));
    });

    let maxLength = 0;
    labels.forEach((items) => {
      if (maxLength < items.length) maxLength = items.length;
    });

    const maxLengthLabel = maxLength;

    const newlegends = labels.map((item, idx) => {
      let val =
        thresholds &&
        thresholds?.["size"]?.[item]?.max <= newData[idx].toFixed(2)
          ? ' <span class="text-red-700 text-base">&#9888;</span>'
          : "";
      return `<span style="display: inline-flex; padding: 1px;">
      <p style="width: ${
        maxLengthLabel > 8 ? maxLengthLabel * 8 : maxLengthLabel * 10
      }px; display: inline-block; font-size: 12px; ">${item}</p>
      <p style="min-width: ${
        maxLengthLabel > 8 ? maxLengthLabel * 1 : maxLengthLabel * 10
      }px; display: inline-block; font-size: 12px;">
        <strong>${newData[idx].toFixed(2)}</strong>%
      </p>
      <span>${val}</span>
    </span>`;
      //  (
      //   item +
      //   new Array(maxLengthLabel - item.length).fill("&nbsp;&nbsp;").join("") +
      //   "&nbsp;&nbsp;" +
      //   newData[idx].toFixed(2).toString().padStart(4, "0").bold() +
      //   "%" +
      //   val
      // );

      // (
      //   item.padEnd(maxLengthLabel, " ") +
      //   " " +
      //   newData[idx].toFixed(2).bold() +
      //   "%" +
      //   val
      // );
    });

    setSeries(newData);
    setCustomLegendItems(newlegends);
  }, [data]);

  const options = {
    chart: {
      type: "donut",
    },
    colors: [
      "#ffc107",
      "#5193f6",
      "#ef6f12",
      "#1c56ac",
      "#e91e63",
      "#00bcd4",
      "#8bc34a",
      "#9c27b0",
      "#673ab7",
      "#ff9800",
      "#4caf50",
      "#795548",
    ],
    labels: customLabels,
    legend: {
      position: position,
      fontSize: "12px",
      customLegendItems: customLegendItems,
      offsetY: 0,
    },
    dataLabels: {
      enabled: true,
      style: {
        fontSize: "12px",
      },
      background: {
        enabled: true,
        foreColor: "#000",
      },
      dropShadow: {
        enabled: false,
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      height="100%"
      width="100%"
    />
  );
};

export default DonutChart;
