import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const DevelopmentInProgress = ({ setDeployClicked, isModelsPage }) => {
  const handleBackButton = () => {
    setDeployClicked(false);
  };
  return (
    <>
      <div className="flex">
        {!isModelsPage && (
          <div className="cursor-pointer w-8" onClick={handleBackButton}>
            <KeyboardBackspaceIcon sx={{ color: "#084298" }} />
          </div>
        )}
      </div>
      <div className="flex flex-col justify-center items-center mb-36">
        <div>
          <img src="/Common/building-blocks.png" alt="building-blocks" />
        </div>
        <div className="flex flex-col justify-center items-center">
          <p className="text-[24px] font-semibold">Development in progress</p>
          <p className="text-[16px] text-[#605D64]">
            Our team is working hard to bring you an exciting new feature. We
            apologize for any inconvenience.
          </p>
          <p className="text-[16px] text-[#605D64]">
            Thank you for your patience.
          </p>
        </div>
        <div className="flex items-center gap-2 mt-2">
          {/* <PrimaryButton
          text={"Go to homepage"}
          width={"fit-content"}
          onClick={() => navigate("/")}
        /> */}
        </div>
      </div>
    </>
  );
};

export default DevelopmentInProgress;
