import React from "react";
import {
  Box,
  Select,
  Skeleton,
  SkeletonText,
  Spinner,
  Stack,
} from "@chakra-ui/react";
const Barchartloader = () => {
  const BarSkeleton = ({ height }) => <Skeleton height={height} width="30px" />;
  return (
    <div className="border w-full h-[300px] flex flex-col gap-2 p-2 justify-center item-center rounded-md">
      <div className="!flex flex-row h-[100%] w-[100%] !justify-between items-end">
        <BarSkeleton height="60%" />
        <BarSkeleton height="90%" />
        <BarSkeleton height="40%" />
        <BarSkeleton height="100%" />
        <BarSkeleton height="80%" />
        <BarSkeleton height="70%" />
        <BarSkeleton height="90%" />
        <BarSkeleton height="40%" />
        <BarSkeleton height="70%" />
        <BarSkeleton height="80%" />
        <BarSkeleton height="25%" />
        <BarSkeleton height="80%" />
        <BarSkeleton height="50%" />
        <BarSkeleton height="90%" />
      </div>
      <Skeleton w={"100%"} h={4} />
    </div>
  );
};

export default Barchartloader;
