import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import NavContext from "../../NavContext";
import { IconButton, Skeleton } from "@chakra-ui/react";
import { ChevronLeft, ChevronRight, MoodBad } from "@mui/icons-material";
import { useWindowSize } from "@uidotdev/usehooks";
import {
  AllBurnersAndThermocoupleChart,
  CommanMultipleYAxisLineChart,
  SingleBurnerChart,
  TotalSulphurAndOverallBeltTemperatureChart,
} from "../components/CommanCharts";
import {
  getImageAndAllBurnersDataApi,
  getIndividualBurnersDataApi,
  getThermocoupleTemperatureReportDataApi,
  getTotalSulphurAndOverallBeltTemperatureApi,
} from "../services/sinterbed.api";
import { useParams } from "react-router-dom";
import { SinterBedContext } from "../components/SinterBedContext";
import { formatDateTimeLocale } from "../utilis/utilityFunctions";

const NoData = () => {
  return (
    <div className="flex flex-row justify-center items-center border rounded-md w-full h-full min-h-[30vh]">
      <div className="flex flex-row items-center gap-2 text-gray-400">
        <MoodBad fontSize={"large"} />
        <h2 className="text-2xl">No Data!</h2>
      </div>
    </div>
  );
};

const TotalSulphurAndOverallBeltTemperature = React.memo(() => {
  const { clientId } = useParams();
  const { initialData } = useContext(SinterBedContext);
  const { auth } = useContext(NavContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  const fetchData = async () => {
    try {
      const requestData = {
        clientId: clientId,
        useCase: "SINTERBED",
        plantName: "chanderia",
        startDate: new Date(
          new Date().getTime() - 8 * 60 * 60 * 1000
        ).getTime(),
        endDate: new Date(new Date().getTime()).getTime(),
        cameraId: initialData?.cameraGpId,
        interval: 60000, // 1 minute in milisecond
      };
      const response = await getTotalSulphurAndOverallBeltTemperatureApi(
        auth,
        requestData
      );
      setData((prev) => response?.data);
    } catch (error) {
      console.log(error);
    } finally {
      if (loading) setLoading((prev) => false);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 1000 * 60);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <Skeleton
      height="340px"
      width={"100%"}
    />
  ) : data ? (
    <TotalSulphurAndOverallBeltTemperatureChart chartData={data} />
  ) : (
    <NoData />
  );
});

const ImageAndAllBurners = () => {
  const { auth } = useContext(NavContext);
  const { width } = useWindowSize();
  const { clientId } = useParams();
  const { initialData } = useContext(SinterBedContext);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const requestData = {
        clientId: clientId,
        useCase: "SINTERBED",
        plantName: "chanderia",
      };
      const response = await getImageAndAllBurnersDataApi(auth, requestData);
      setData((prev) => response?.data);
    } catch (error) {
      console.log(error);
    } finally {
      if (loading) setLoading((prev) => false);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollContainerRef = useRef(null);
  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -250, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 250, behavior: "smooth" });
    }
  };

  return (
    <div className="flex flex-col gap-2 bg-white p-2 rounded-xl w-full">
      {loading ? (
        <>
          <div className="flex md:flex-row flex-col justify-between items-start md:items-center px-1">
            <div className="flex flex-row items-center gap-3">
              <Skeleton
                height="20px"
                width="150px"
              />
              <Skeleton
                height="20px"
                width="100px"
              />
            </div>
            <div>
              <Skeleton
                height="20px"
                width="200px"
              />
            </div>
          </div>
          <div className="relative w-full h-full">
            <Skeleton
              height="130px"
              className="border rounded w-full h-full overflow-auto lg:overflow-hidden"
            />
          </div>
        </>
      ) : data ? (
        <>
          <div className="flex md:flex-row flex-col justify-between items-start md:items-center px-1">
            <div className="flex flex-row items-center gap-3">
              <p className="min-w-fit font-bold text-lg">
                {initialData?.cameraGpName}
              </p>
              <p className="text-[#79767D] text-sm md:text-base">
                {data?.timeStamp && formatDateTimeLocale(data?.timeStamp)}
              </p>
            </div>
            <p>
              {`Overall Bed Temperature :  ${data?.overallBedTemperature?.toFixed(
                2
              )}\u00B0C`}
            </p>
          </div>
          <div className="relative w-full h-full">
            <div
              className="px-1 py-1 border rounded w-full h-full overflow-auto lg:overflow-hidden"
              ref={scrollContainerRef}
            >
              <div className="flex flex-row justify-between items-center gap-1 w-[400%] lg:w-full">
                {initialData?.cameraInfo?.map((element) => (
                  <div
                    className="border rounded w-full h-full"
                    key={`${element?.name}`}
                  >
                    <p className="bg-gray-300 text-center text-xs md:text-sm">
                      {`${element?.name}`}
                    </p>
                  </div>
                ))}
              </div>
              <div className="relative w-[400%] lg:w-full h-full">
                <img
                  src={data?.annotatedImage}
                  alt="thermal_camera_image"
                  className="w-full h-full"
                />
              </div>
            </div>
            {width <= 768 && (
              <IconButton
                position={"absolute"}
                top={"50%"}
                left={0}
                onClick={scrollLeft}
                icon={<ChevronLeft />}
                size={"sm"}
                isRound={true}
                transform={"translateY(-50%)"}
                backgroundColor={"gray.200"}
              />
            )}
            {width <= 768 && (
              <IconButton
                position={"absolute"}
                top={"50%"}
                right={0}
                onClick={scrollRight}
                icon={<ChevronRight />}
                size={"sm"}
                isRound={true}
                transform={"translateY(-50%)"}
                backgroundColor={"gray.200"}
              />
            )}
          </div>
        </>
      ) : (
        <NoData />
      )}

      <div className="flex xl:flex-row flex-col gap-4 xl:gap-2 w-full">
        <div className="w-full xl:w-[50%]">
          {loading ? (
            <Skeleton height="365px" />
          ) : data ? (
            <AllBurnersAndThermocoupleChart
              xAxis={data?.xAxis}
              maximumTemperature={data.maximumTemperature}
              averageTemperature={data?.averageTemperature}
              minimumTemperature={data?.minimumTemperature}
              thermocoupleTemperature={data?.thermocoupleTemperature}
              thermocoupleTime={data?.thermocoupleTime}
            />
          ) : (
            <NoData />
          )}
        </div>
        <div className="pt-6 w-full xl:w-[50%]">
          <TotalSulphurAndOverallBeltTemperature title={"Some text"} />
        </div>
      </div>
    </div>
  );
};

const IndividualBurner = () => {
  const { clientId } = useParams();
  const { auth } = useContext(NavContext);
  const { initialData } = useContext(SinterBedContext);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedBasis, setSelectedBasis] = useState("maxTemp");

  const options = useMemo(
    () => [
      {
        value: "maxTemp",
        name: "Maximum Temperature",
      },
      {
        value: "avgTemp",
        name: "Average Temperature",
      },
      {
        value: "minTemp",
        name: "Minimum Temperature",
      },
    ],
    []
  );

  const fetchData = async (paramSelectedBasis, showLoading) => {
    if (showLoading && loading === false) setLoading((prev) => true);
    try {
      const requestData = {
        clientId: clientId,
        useCase: "SINTERBED",
        plantName: "chanderia",
        startDate: new Date(new Date().getTime() - 60 * 60 * 1000).getTime(),
        endDate: new Date(new Date().getTime()).getTime(),
        cameraId: initialData?.cameraGpId,
        type: paramSelectedBasis,
        interval: 10000, // 10 second in milisecond
      };
      const response = await getIndividualBurnersDataApi(
        auth,
        requestData,
        true
      );
      setData((prev) => response?.data);
    } catch (error) {
      console.log(error);
    } finally {
      if (showLoading) setLoading((prev) => false);
    }
  };

  useEffect(() => {
    fetchData(selectedBasis, true);
    const intervalId = setInterval(() => {
      fetchData(selectedBasis, false);
    }, 1000 * 60);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col gap-4 bg-white p-2 rounded-xl">
      <div className="flex justify-between items-center gap-6 px-1 w-full text-[#49454F] overflow-auto">
        <p className="min-w-fit font-bold text-lg">
          Individual Burner Temperature (From IR Camera)
        </p>
        <div className="flex items-center gap-5 text-[#49454F] text-xs lg:text-sm">
          {options?.map((el) => (
            <label
              className={`p-2 flex items-center gap-1 cursor-pointer rounded-md min-w-fit ${
                selectedBasis === el.value ? "bg-[#e7effb]" : "bg-white"
              }`}
              key={`radio-box-${el.value}`}
            >
              <input
                value={el.value}
                checked={selectedBasis === el.value}
                onChange={(e) => {
                  setSelectedBasis(e.target.value);
                  fetchData(e.target.value, true);
                }}
                disabled={loading}
                type="radio"
                name="freq"
                className="w-[18px] h-[18px] cursor-pointer accent-[#3A74CA]"
              />
              <span>{el.name}</span>
            </label>
          ))}
        </div>
      </div>
      {loading ? (
        <div className="gap-3 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 bg-white p-1 rounded-xl w-full h-full">
          {Array.from({ length: 8 }).map((_, idx) => (
            <div key={idx}>
              <Skeleton
                height="270px"
                rounded={"md"}
              />
            </div>
          ))}
        </div>
      ) : data ? (
        <div className="gap-3 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 w-full h-full">
          {data &&
            initialData?.cameraInfo?.map((el, idx) => (
              <SingleBurnerChart
                title={el.name}
                data={data?.[el.cameraId].data}
                times={data?.[el.cameraId].times}
                alert={data?.[el.cameraId].alert}
              />
            ))}
        </div>
      ) : (
        <NoData />
      )}
    </div>
  );
};
const ThermocoupleTemperature = () => {
  const { clientId } = useParams();
  const { auth } = useContext(NavContext);
  const { initialData } = useContext(SinterBedContext);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const requestData = {
        clientId: clientId,
        useCase: "SINTERBED",
        plantName: "chanderia",
        startDate: new Date(new Date().getTime() - 60 * 60 * 1000).getTime(),
        endDate: new Date(new Date().getTime()).getTime(),
        cameraId: initialData?.cameraGpId,
      };
      const response = await getThermocoupleTemperatureReportDataApi(
        auth,
        requestData
      );
      setData((prev) => response?.data);
    } catch (error) {
      console.log(error);
    } finally {
      if (loading) setLoading((prev) => false);
    }
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 1000 * 60 * 5);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="flex flex-col gap-2 bg-white p-2 rounded-xl w-full h-full">
      <p className="px-2 min-w-fit font-bold text-lg">
        Thermocouple temperature
      </p>
      {loading ? (
        <Skeleton
          height={"350px"}
          width={"100%"}
        />
      ) : data ? (
        <CommanMultipleYAxisLineChart
          colors={[
            "#ffc107",
            "#5193f6",
            "#ef6f12",
            "#1c56ac",
            "#e91e63",
            "#00bcd4",
            "#8bc34a",
          ]}
          chartData={data}
        />
      ) : (
        <NoData />
      )}
    </div>
  );
};
export const Status = () => {
  return (
    <div className="flex flex-col gap-5 w-full h-full">
      <ImageAndAllBurners />
      {/* Single burners charts */}
      <IndividualBurner />
      {/* Temeperature couple chart */}
      <ThermocoupleTemperature />
    </div>
  );
};
