import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider, IconButton } from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useState } from "react";
import Switch from '@mui/material/Switch';
import { alpha, styled } from '@mui/material/styles';
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';


const MuiTheme = createTheme();


const View = ({ row, setShowDevicesTab, setSelectedDevice }) => {

    const handleView = () => {
        setShowDevicesTab((prev) => "viewDeviceGroup");
        setSelectedDevice(row);
    }

    return (
        <IconButton
            aria-label="edit"
            sx={{ color: "#1E88E5" }}
            onClick={() => {
                handleView();
            }}
        //disabled={row?.status === "INACTIVE"}
        >
            <VisibilityIcon />
        </IconButton>
    );
}

const Download = ({ row, setShowDevicesTab, setSelectedDevice }) => {

    const handleDownload = () => {
        //console.log(products);
        // setSelectedProductGroup(row);
        // setShowProductTab((prev) => "updateProductGroup");
    }
    return (
        <div onClick={handleDownload}>
            <img src="/download.svg" />
        </div>
    );
}

const Update = ({ row, setShowDevicesTab, setSelectedDevice }) => {

    const handleUpdate = () => {
        setShowDevicesTab((prev) => "updateDeviceGroup");
        setSelectedDevice(row);
    }
    return (
        <IconButton
            aria-label="edit"
            sx={{ color: "#1E88E5" }}
            onClick={() => {
                handleUpdate();
            }}
            disabled={row?.deviceGroup?.status === "INACTIVE"}
        >
            <EditNoteIcon />
        </IconButton>
    );
}

const ToggleSwitch = ({ row, setShowDevicesTab }) => {

    const [checked, setChecked] = useState(true);

    const handleChange = (event) => {
        setChecked(event.target.check);
    };

    const GreenSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: "#378805",
            '&:hover': {
                backgroundColor: alpha("#378805", theme.palette.action.hoverOpacity),
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: "#378805",
        },
    }));

    return (
        <div>
            {/* Switch */}
            <GreenSwitch
                checked={checked}
                onChange={handleChange}
                // color="#568203"
                inputProps={{ 'aria-label': 'controlled' }}
            />
        </div>

    );
}

const DeviceGroupsTable = ({ deviceGroups, setShowDevicesTab, setSelectedDevice }) => {


    const rowData = deviceGroups;

    const columns = [
        {
            field: "product",
            headerName: <div className="flex">
                <img src="/deviceGroupTableIcon.svg" />
                Device Group
            </div>,
            valueGetter: ({ row }) => row?.deviceGroup?.deviceGroupName,
            renderCell: ({ row }) => {
                return (
                    <div className="w-full flex gap-1 ">
                        <img src="/camera.svg" className="w-[20px] h-[20px]" alt="coins" />
                        {row.deviceGroup.deviceGroupName}

                    </div>
                );
            },
        },
        {
            field: "devices",
            headerName: <div className="flex">
                <img src="/deviceTableIcon.svg" />
                Devices
            </div>,
            valueGetter: ({ row }) => row?.deviceList?.map((value, index) => value.deviceName).join(", "),
            renderCell: ({ row }) => {
                let allTools = row?.deviceList?.map((value, index) => value.deviceName)
                return (
                    <Tooltip title={allTools?.join(", ")} placement="top">
                        <div className="whitespace-nowrap overflow-hidden text-ellipsis flex flex-row gap-1">
                            <p className="font-bold">{row.deviceCount}</p>
                            {allTools?.join(", ")}

                        </div>
                    </Tooltip>
                );
            },
        },
        {
            field: "clients",
            headerName: <div className="flex">
                <img src="/clientsIcon.svg" />
                Clients Count
            </div>,
            valueGetter: ({ row }) => row?.clientCount,
            renderCell: ({ row }) => {
                return (
                    <div className="w-full flex item-center gap-2 cursor-pointer ">
                        <p className="font-bold">{row.clientCount}</p>

                    </div>
                );
            },
        },
        {
            field: "locations",
            headerName: <div className="flex">
                <img src="/location.svg" />
                Locations
            </div>,
            valueGetter: ({ row }) => row?.deviceDeployCount,
            renderCell: ({ row }) => {
                return (
                    <div className="w-full flex item-center gap-2 cursor-pointer ">
                        <p className="font-bold">{row.deviceDeployCount}</p>

                    </div>
                );
            },
        },
        {
            field: "createdAt",
            headerName: <div className="flex">
                <img src="/date.svg" />
                Created on
            </div>,
            valueGetter: ({ row }) => row?.deviceGroup?.createdAt,
            renderCell: ({ row }) => {
                function padZero(number) {
                    return (number < 10 ? '0' : '') + number;
                }

                const date = new Date(row.deviceGroup.createdAt);
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so add 1
                const day = date.getDate().toString().padStart(2, '0');
                const hours = date.getHours();
                const minutes = date.getMinutes();
                const seconds = date.getSeconds();
                const formattedTime = padZero(hours) + ":" + padZero(minutes) + ":" + padZero(seconds);
                const dateString = `${day}/${month}/${year}`

                const formattedDate = new Date(row.deviceGroup.createdAt).toDateString().split(" ")[2] +
                    " " +
                    new Date(row.deviceGroup.createdAt).toDateString().split(" ")[1] +
                    " '" +
                    new Date(row.deviceGroup.createdAt).toDateString().split(" ")[3].slice(2, 4)
                return (
                    <Tooltip title={formattedDate + " " + formattedTime} placement="top">
                        <div className="w-full flex gap-2 whitespace-nowrap overflow-hidden text-ellipsis">
                            {formattedDate + " " + formattedTime}
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            field: "updatedAt",
            headerName: <div className="flex">
                <img src="/date.svg" />
                Updated on
            </div>,
            valueGetter: ({ row }) => row?.deviceGroup?.updatedAt,
            renderCell: ({ row }) => {
                function padZero(number) {
                    return (number < 10 ? '0' : '') + number;
                }
                const date = new Date(row.deviceGroup.updatedAt);
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so add 1
                const day = date.getDate().toString().padStart(2, '0');
                const hours = date.getHours();
                const minutes = date.getMinutes();
                const seconds = date.getSeconds();
                const formattedTime = padZero(hours) + ":" + padZero(minutes) + ":" + padZero(seconds);
                const dateString = `${day}/${month}/${year}`

                const formattedDate = new Date(row.deviceGroup.updatedAt).toDateString().split(" ")[2] +
                    " " +
                    new Date(row.deviceGroup.updatedAt).toDateString().split(" ")[1] +
                    " '" +
                    new Date(row.deviceGroup.updatedAt).toDateString().split(" ")[3].slice(2, 4)
                return (
                    <Tooltip title={formattedDate + " " + formattedTime} placement="top">
                        <div className="w-full flex gap-2 whitespace-nowrap overflow-hidden text-ellipsis">
                            {formattedDate + " " + formattedTime}
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            field: "status",
            headerName: <div className="flex">
                <img src="/superadmin/clients/timer.svg" alt="" />
                Status
            </div>,
            valueGetter: ({ row }) => row?.deviceGroup?.status,
            renderCell: ({ row }) => {
                return (
                    <div className="w-full flex item-center gap-2 cursor-pointer ">
                        {row?.deviceGroup?.status === "DRAFT" ? (
                            <img
                                src={`/Common/draft.svg`}
                                className="w-5 h-5 rounded-full"
                                alt="status"
                            />
                        ) : row?.deviceGroup?.status === "ACTIVE" ? (
                            <CheckCircleIcon sx={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "9999px",
                                color: "green",
                            }} />
                        ) : (
                            <CancelIcon sx={{
                                width: "20px",
                                height: "20px",
                                borderRadius: "9999px",
                                color: "#E46962",
                            }} />
                        )}
                        <p className="capitalize">{row?.deviceGroup.status.toLowerCase()}</p>
                    </div>
                );
            },
        },
        {
            field: "links",
            headerName: "",
            sortable: false,
            disableColumnMenu: true,
            renderCell: ({ row }) => <View row={row} setShowDevicesTab={setShowDevicesTab} setSelectedDevice={setSelectedDevice} />,
        },
        {
            field: "links2",
            headerName: "",
            sortable: false,
            disableColumnMenu: true,
            renderCell: ({ row }) => <Update row={row} setShowDevicesTab={setShowDevicesTab} setSelectedDevice={setSelectedDevice} />,
        },
        // {
        //     field: "links3",
        //     headerName: "",
        //     renderCell: ({ row }) => <Download row={row} setShowDevicesTab={setShowDevicesTab} setSelectedDevice={setSelectedDevice} />,
        // },
        // {
        //     field: "links4",
        //     headerName: "",
        //     renderCell: ({ row }) => <ToggleSwitch row={row} setShowDevicesTab={setShowDevicesTab} />,
        // },

    ];
    const headerClass =
        "text-sm font-normal text-[#79767D] bg-[#CDEEBF]";
    const cellClass = "text-sm font-normal text-[#3E3C42] whitespace-nowrap";
    const flexMap = [1, 3, 0.8, 0.8, 0.8, 0.8, 0.8, 0.1, 0.1];
    columns.map((val, idx) => {
        val["headerClassName"] = headerClass;
        val["cellClassName"] = cellClass;
        val["flex"] = flexMap[idx];
    });

    return (
        <div className="overflow-x-auto mt-2">
            <ThemeProvider theme={MuiTheme}>
                <DataGrid
                    rows={rowData}
                    columns={columns}
                    getRowId={(row) => row.deviceGroup.deviceGroupId}
                    //   columnVisibilityModel={{
                    //     chatId: false,
                    //     username: false,
                    //   }}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "updatedAt", sort: "desc" }],
                        },
                        // pagination: { paginationModel: { pageSize: 5 } },
                    }}
                    //   hideFooter={true}
                    sx={{ minWidth: "1000px" }}
                />
            </ThemeProvider>
        </div>
    );
};

export default DeviceGroupsTable;
