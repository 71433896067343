import { useEffect, useState, useContext, type, useRef } from "react";
import NavContext from "../../NavContext";
import { baseURL } from "../../../index";
import { useParams } from "react-router-dom";
import PieChart from "../../Charts/SizingCharts/PieChart";
import StackBarChart from "../../Charts/SizingCharts/StackBarChart";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import HistoryAnalytics from "../SizingComponents/HistoryAnalytics";
import { Select, Spinner } from "@chakra-ui/react";
import BoxPlotAnalysis from "../SizingComponents/BoxPlotAnalysis";
import axios from "axios";
import LiquidGauge from "../../Charts/SizingCharts/LiquidGauge";
import MoistureChart from "../../Charts/SizingCharts/MoistureChart";
import { useWindowSize } from "@uidotdev/usehooks";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import { capitalizeFirstLetter } from "../../../util/sentenceCase";

const analysisType = {
  0: "Size distribution",
  1: "Color analysis",
  2: "Moisture analysis",
};

const Analytics = ({ plantId, cameraId, disable, plantCamMap }) => {
  let param = useParams();
  const { auth } = useContext(NavContext);
  const size = useWindowSize();
  let material = param.material.toLowerCase();
  const [sizeData, setSizeData] = useState([]);
  const [sizeDataChanging, setSizeDataChanging] = useState(false);
  const [checkData, setCheckData] = useState({});
  const [selectedBasis, setSelectedBasis] = useState(0);
  const typeRef = useRef();
  const [avgMoisture, setAvgMoisture] = useState(0);
  const [selectedRange, setSelectedRange] = useState(0);
  const [selectedPlant, setSelectedPlant] = useState(plantId);
  const [selectedCam, setSelectedCam] = useState(cameraId);
  const [fromTime, setFromTime] = useState(
    new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10)
  );
  const [toTime, setToTime] = useState(
    new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10)
  );

  const handleRangeSelect = (e) => {
    setSelectedRange(e.target.value);
    if (e.target.value == 0) {
      setFromTime(
        new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 10)
      );
      setToTime(
        new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 10)
      );
    }
  };

  const apiCall = async () => {
    const requestData = JSON.stringify({
      clientId: param.clientId.toLowerCase(),
      material: param.material.toLowerCase(),
      cameraId:
        selectedCam === "All Cams" || selectedPlant === "All Plants"
          ? "all"
          : selectedCam,
      plantName: selectedPlant === "All Plants" ? "all" : selectedPlant,
      startDate: new Date(new Date(fromTime).setHours(0, 0, 0, 0)).getTime(),
      endDate: new Date(new Date(toTime).setHours(23, 59, 59, 999)).getTime(),
      distType: typeRef.current,
    });
    const response = await axios
      .post(baseURL + "vision/v2/sizing/analytics/distribution/", requestData, {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      })
      .then((response) => {
        setSizeData(response.data);
        setSizeDataChanging(false);
      })
      .catch((error) => {
        console.log(error);
        setSizeDataChanging(false);
      });
  };

  const checkApi = async () => {
    try {
      let plant =
        selectedPlant === "All Plants"
          ? Object?.keys(plantCamMap)[0]
          : selectedPlant;
      let cam = selectedCam === "" ? plantCamMap[plant][0] : selectedCam;
      const requestData = JSON.stringify({
        clientId: param?.clientId?.toLowerCase(),
        material: material,
        cameraId: cam,
      });
      const response = await axios.post(
        baseURL + "vision/v2/sizing/analysis/detail/",
        requestData,
        {
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response?.status === 200) setCheckData(response?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = () => {
    setSizeDataChanging(true);
    apiCall();
  };

  useEffect(() => {
    typeRef.current = "SIZE";
    checkApi();
    setSelectedBasis(0);
    setSizeDataChanging(true);
    apiCall();
  }, []);

  useEffect(() => {
    if (selectedBasis == 0) typeRef.current = "SIZE";
    else if (selectedBasis == 1) typeRef.current = "COLOR";
    else if (selectedBasis == 4) typeRef.current = "COMPOSITION";
    else typeRef.current = "MOISTURE";
    handleClick();
  }, [selectedBasis]);

  useEffect(() => {
    if (typeRef.current == "MOISTURE") {
      let sum = 0;
      let count = 0;
      sizeData.map((i) => {
        if (i.moisture != 0) {
          sum += i.moisture;
          count++;
        }
      });
      let avgSum = count == 0 ? 0 : sum / count;
      setAvgMoisture(avgSum);
    }
  }, [typeRef.current, sizeData]);

  useEffect(() => {
    if (!disable && selectedPlant != "All Plants") {
      setSelectedCam("All Cams");
    }
  }, [selectedPlant]);
  const coalCheck =
    material === "coal" && (checkData?.showColor || checkData?.showMoisture);
  const afrCheck = material === "afr";
  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col p-6 pt-4 bg-white rounded-xl">
        <div className="w-full">
          {" "}
          <p className="text-[#3E3C42] font-medium text-xl py-2">
            {analysisType[selectedBasis]}
          </p>
        </div>
        <div
          className={`flex justify-between gap-2 items-center overflow-x-auto h-fit`}
        >
          {coalCheck && (
            <div className="flex gap-6 text-[#49454F] text-xs lg:text-base min-w-[445px]">
              <div
                className={`p-3 flex items-center gap-1 ${
                  selectedBasis == 0 ? "bg-[#e7effb] rounded-xl" : "bg-white"
                }`}
              >
                <input
                  value={0}
                  checked={selectedBasis == 0}
                  onChange={(e) => setSelectedBasis(e.target.value)}
                  type="radio"
                  name="freq"
                  className="cursor-pointer accent-[#3A74CA] h-[18px] w-[18px]"
                />
                <p>Size distribution</p>
              </div>
              {checkData?.showColor && (
                <div
                  className={`p-3 flex items-center gap-1 ${
                    selectedBasis == 1 ? "bg-[#e7effb] rounded-xl" : "bg-white"
                  }`}
                >
                  <input
                    value={1}
                    checked={selectedBasis == 1}
                    onChange={(e) => setSelectedBasis(e.target.value)}
                    type="radio"
                    name="freq"
                    className="cursor-pointer accent-[#3A74CA] h-[18px] w-[18px]"
                  />
                  <p>Colour analysis</p>
                </div>
              )}
              {checkData?.showMoisture && (
                <div
                  className={`p-3 flex items-center gap-1 ${
                    selectedBasis == 2 ? "bg-[#e7effb] rounded-xl" : "bg-white"
                  }`}
                >
                  <input
                    value={2}
                    checked={selectedBasis == 2}
                    onChange={(e) => setSelectedBasis(e.target.value)}
                    type="radio"
                    name="freq"
                    className="cursor-pointer accent-[#3A74CA] h-[18px] w-[18px]"
                  />
                  <p>Moisture analysis</p>
                </div>
              )}
            </div>
          )}
          {afrCheck && (
            <div className="flex gap-6 text-[#49454F] text-xs lg:text-base min-w-[445px]">
              <div
                className={`p-3 flex items-center gap-1 ${
                  selectedBasis == 0 ? "bg-[#e7effb] rounded-xl" : "bg-white"
                }`}
              >
                <input
                  value={0}
                  checked={selectedBasis == 0}
                  onChange={(e) => setSelectedBasis(e.target.value)}
                  type="radio"
                  name="freq"
                  className="cursor-pointer accent-[#3A74CA] h-[18px] w-[18px]"
                />
                <p>Size distribution</p>
              </div>
              {sizeData[0]?.composition && (
                <div
                  className={`p-3 flex items-center gap-1 ${
                    selectedBasis == 4 ? "bg-[#e7effb] rounded-xl" : "bg-white"
                  }`}
                >
                  <input
                    value={4}
                    checked={selectedBasis == 4}
                    onChange={(e) => setSelectedBasis(e.target.value)}
                    type="radio"
                    name="freq"
                    className="cursor-pointer accent-[#3A74CA] h-[18px] w-[18px]"
                  />
                  <p>Composition</p>
                </div>
              )}
            </div>
          )}
        </div>
        <div
          className={`flex justify-end gap-2 items-center overflow-x-auto h-fit`}
        >
          <div className="flex items-center gap-2 overflow-x-auto p-2">
            <div className="min-w-[110px]">
              <Select
                borderColor="#CAC5CD"
                color="#605D64"
                placeholder={disable && capitalizeFirstLetter(plantId)}
                variant="outline"
                isDisabled={disable}
                rounded={"base"}
                className="!text-sm !font-medium text-[#605D64] "
                onChange={(e) => setSelectedPlant(e.target.value)}
                value={selectedPlant}
              >
                <option key="All Plants" value="All Plants">
                  {capitalizeFirstLetter("All plants")}
                </option>
                {!disable &&
                  Object.keys(plantCamMap).map((plant) => {
                    return (
                      <option key={plant} value={plant}>
                        {capitalizeFirstLetter(plant)}
                      </option>
                    );
                  })}
              </Select>
            </div>
            {selectedPlant !== "All Plants" && (
              <div className="min-w-[110px]">
                <Select
                  borderColor="#CAC5CD"
                  color="#605D64"
                  placeholder={disable && capitalizeFirstLetter(cameraId)}
                  variant="outline"
                  isDisabled={disable}
                  rounded={"base"}
                  className="!text-sm !font-medium text-[#605D64]"
                  onChange={(e) => setSelectedCam(e.target.value)}
                  value={selectedCam}
                >
                  {" "}
                  <option key="All Cams" value="All Cams">
                    {capitalizeFirstLetter("All cams")}
                  </option>
                  {!disable &&
                    plantCamMap[selectedPlant].map((cam) => {
                      return (
                        <option key={cam} value={cam}>
                          {capitalizeFirstLetter(cam)}
                        </option>
                      );
                    })}
                </Select>
              </div>
            )}
            <div className="min-w-[110px]">
              <Select
                borderColor="#CAC5CD"
                color="#605D64"
                value={selectedRange}
                variant="outline"
                rounded={"base"}
                className="!text-sm !font-medium !text-[#605D64]"
                onChange={(e) => handleRangeSelect(e)}
              >
                <option key="Last 7 days" value={0}>
                  Last 7 days
                </option>
                <option key="custom" value={1}>
                  Custom
                </option>
              </Select>
            </div>
            {selectedRange == 1 && (
              <div className="min-w-[110px]">
                <FloatingInput
                  text="From"
                  type="date"
                  setDateTime={setFromTime}
                  value={fromTime}
                  max={toTime}
                />
              </div>
            )}
            {selectedRange == 1 && (
              <div className="min-w-[110px]">
                <FloatingInput
                  text="To"
                  type="date"
                  setDateTime={setToTime}
                  value={toTime}
                  min={fromTime}
                  max={new Date().toISOString().slice(0, 10)}
                />
              </div>
            )}
            {/* <button className="text-center py-2 px-4 text-white text-xs md:text-base font-medium bg-[#6CA6FC] rounded-full min-w-[80px]">
              {}
            </button> */}
            <SecondaryButton
              width={"80px"}
              height={"40px"}
              text={sizeDataChanging ? <Spinner /> : "Apply"}
              onClick={handleClick}
              disable={!fromTime || !toTime}
            />
          </div>
        </div>

        {sizeData.length != 0 && (
          <div className="flex gap-1 sm:gap-[40px] items-center overflow-x-auto overflow-y-hidden  min-h-[280px]">
            <div className="ml-[-40px] sm:ml-0 min-w-[270px] w-[23vw]">
              {typeRef.current == "MOISTURE" ? (
                <LiquidGauge
                  moisture={avgMoisture}
                  r={Math.max(Math.ceil((size.width * 20) / 200), 80)}
                />
              ) : (
                sizeData?.[0]?.[typeRef.current.toLowerCase()] &&
                Object.keys(sizeData[0][typeRef.current.toLowerCase()]).length >
                  0 && (
                  <PieChart
                    data={sizeData}
                    type={typeRef.current.toLowerCase()}
                  />
                )
              )}
            </div>
            <div className="ml-[-40px] sm:ml-0 h-[35vh] min-w-[680px] flex-grow">
              {typeRef.current == "MOISTURE" ? (
                <MoistureChart data={sizeData} />
              ) : (
                sizeData?.[0]?.[typeRef.current.toLowerCase()] &&
                Object.keys(sizeData[0][typeRef.current.toLowerCase()]).length >
                  0 && (
                  <StackBarChart
                    data={sizeData}
                    type={typeRef.current.toLowerCase()}
                  />
                )
              )}
            </div>
          </div>
        )}
      </div>
      {(disable || Object.keys(plantCamMap).length != 0) && (
        <BoxPlotAnalysis
          plantId={disable ? plantId : Object.keys(plantCamMap)[0]}
          cameraId={
            disable ? cameraId : plantCamMap[Object.keys(plantCamMap)[0]][0]
          }
          disable={disable}
          plantCamMap={plantCamMap}
        />
      )}
      {(disable || Object.keys(plantCamMap).length != 0) && (
        <HistoryAnalytics
          plantId={disable ? plantId : Object.keys(plantCamMap)[0]}
          cameraId={
            disable ? cameraId : plantCamMap[Object.keys(plantCamMap)[0]][0]
          }
          disable={disable}
          plantCamMap={plantCamMap}
        />
      )}
    </div>
  );
};

export default Analytics;
