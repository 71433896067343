import { useContext, useState } from "react";
import { Radio, RadioGroup, Tooltip, useToast } from "@chakra-ui/react";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import TonalButton from "../../../util/Buttons/TonalButton";
import axios from "axios";
import { baseURL } from "../../../index";
import NavContext from "../../NavContext";
import VideoSelect from "./VideoSelect";
import UploadHandler from "./UploadHandler";

const UploadDetails = ({
  userData,
  setUSerData,
  setActiveStep,
  activeStep,
  show,
  viewMode = false,
}) => {
  const { auth, organisation } = useContext(NavContext);
  const [disable, setDisable] = useState(viewMode);
  const [loading, setLoading] = useState(false);
  const [videoSelection, setVideoSelection] = useState(false);
  const toast = useToast();
  const models = {
    Classify: ["AZURE_CUSTOM_VISION", "RIPIK_VISION"],
    Detect: ["AZURE_CUSTOM_VISION", "RIPIK_VISION"],
    Segment: ["RIPIK_VISION"],
  };

  const handleChange = (name, value) => {
    setUSerData((prev) => {
      let updateData = { ...prev };
      updateData[name] = value;
      return updateData;
    });
  };

  const startTrainApi = async () => {
    try {
      const param = {
        projectId: userData.projectId,
        toTrain: false,
      };
      const response = await axios.post(
        baseURL + "selfserve/v1/project/v1/training/start/",
        null,
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.status == 200) {
        let eta = response.data?.etaTotalSeconds;
        toast({
          title: "Success",
          description: (
            <div>
              <p>Training started</p>
              {eta && eta > 1 && <p>ETA: {(eta / 60).toFixed(1)} min</p>}
            </div>
          ),
          status: "success",
          position: "top-right",
          duration: 5000,
          isClosable: true,
        });
        setActiveStep(4);
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Error",
        description: "Start train api failed",
        status: "error",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const AnnotateApi = async (data) => {
    try {
      const param = {
        projectId: userData.projectId,
      };
      let predictionData;
      predictionData = Object.entries(userData?.uploadedFiles)?.map((item) => {
        return {
          fileId: item[0],
          fileUrl: item[1],
        };
      });
      const requestBody = JSON.stringify({
        annotations: null,
        predictionDataset: predictionData,
      });
      const response = await axios.post(
        baseURL + "selfserve/v1/project/v1/annotations/set/",
        requestBody,
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.status == 200) {
        toast({
          title: "Success",
          description: "Annotated data received",
          status: "success",
          position: "top-right",
          duration: 2000,
          isClosable: true,
        });
        startTrainApi();
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Error",
        description: "Set annotate api failed",
        status: "error",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const handleSave = async () => {
    if (
      userData.annotationType == "" ||
      Object.entries(userData.uploadedFiles).length == 0
    ) {
      toast({
        title: "Error",
        description: (
          <div>
            {userData.annotationType == "" && (
              <p>Please select annotationType.</p>
            )}
            {Object.entries(userData.uploadedFiles).length == 0 && (
              <p>Please upload files/folder or Link rtsp/bucket.</p>
            )}
          </div>
        ),
        status: "error",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
      return;
    } else {
      try {
        const requestBody = JSON.stringify({
          remarks: userData.whatToDetect,
          datasetType: userData.dataType.toUpperCase(),
          isAnnotated: userData.isAnnotated == "No" ? false : true,
          modelType: userData.annotationType.toUpperCase(),
          modelService: userData.modelService.toUpperCase(),
        });
        const param = {
          projectId: userData.projectId,
        };
        const response = await axios.post(
          baseURL + "selfserve/v1/project/v1/update/",
          requestBody,
          {
            params: param,
            headers: {
              "Content-Type": "application/json",
              "X-Auth-Token": auth,
            },
          }
        );
        if (response.status == 200) {
          if (userData?.pickleFile && userData?.isAnnotated == "Yes") {
            AnnotateApi(userData?.uploadedFiles);
          } else if (activeStep < 3) setActiveStep((prev) => prev + 1);
          setDisable(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div
      className={`flex flex-col lg:flex-row items-start lg:items-center justify-between gap-3 p-6 bg-white rounded-lg transition-all ease-in duration-700 ${
        show ? "opacity-100" : "opacity-0"
      }`}
      id="step2"
    >
      <div className="flex flex-col gap-8">
        <p className="text-[#3E3C42] text-xl font-medium">
          Upload data {"mode" in userData ? "For " + userData?.mode : ""}
        </p>
        <div className="flex flex-col gap-6">
          {/*annotation type */}
          <div className="flex flex-col gap-3">
            <p className="text-[#3E3C42] text-sm font-medium">
              Select model type
              <span className="text-[#DC362E]">*</span>
            </p>
            <RadioGroup
              onChange={(e) => {
                handleChange("annotationType", e);
                if (!userData?.pickleFile)
                  handleChange("modelService", models[e][0]);
              }}
              value={userData.annotationType}
              isDisabled={disable}
            >
              <div className="flex gap-1 items-center">
                {["Classify", "Detect", "Segment"].map((x) => {
                  return (
                    <div
                      style={{
                        backgroundColor:
                          x == userData.annotationType ? "#DDEEFF80" : "#FFF",
                        borderRadius: "8px",
                      }}
                    >
                      <Radio
                        value={x}
                        py={"8px"}
                        pl={"8px"}
                        pr={"12px"}
                        fontSize={"14px"}
                        fontWeight={500}
                        color={"#3E3C42"}
                        _checked={{
                          bg: "#6CA6FC",
                          borderColor: "#6CA6FC",
                        }}
                        _hover={{
                          borderColor: "#6CA6FC",
                        }}
                        isDisabled={disable}
                      >
                        {x}
                      </Radio>
                    </div>
                  );
                })}
              </div>
            </RadioGroup>
          </div>
          {/*Model select */}
          {userData.annotationType != "" &&
            organisation?.includes("Ripik") &&
            !userData.pickleFile &&
            userData.modelService != "CUSTOM_UPLOAD" && (
              <div className="flex flex-col gap-3">
                <p className="text-[#3E3C42] text-sm font-medium">
                  Select model provider<span className="text-[#DC362E]">*</span>
                </p>
                <RadioGroup
                  onChange={(e) => handleChange("modelService", e)}
                  value={userData.modelService}
                  isDisabled={disable}
                >
                  <div className="flex gap-1 items-center">
                    {models[userData.annotationType].map((x) => {
                      return (
                        <div
                          style={{
                            backgroundColor:
                              x == userData.modelService ? "#DDEEFF80" : "#FFF",
                            borderRadius: "8px",
                          }}
                          className="flex gap-[6px] items-center py-2 pl-2 pr-3"
                        >
                          <Radio
                            value={x}
                            fontSize={"14px"}
                            fontWeight={500}
                            color={"#3E3C42"}
                            _checked={{
                              bg: "#6CA6FC",
                              borderColor: "#6CA6FC",
                            }}
                            _hover={{
                              borderColor: "#6CA6FC",
                            }}
                            isDisabled={disable}
                          />
                          <img
                            src={
                              x.includes("AZURE")
                                ? "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a8/Microsoft_Azure_Logo.svg/2560px-Microsoft_Azure_Logo.svg.png"
                                : "/logo.svg"
                            }
                            className="h-[20px] w-auto"
                            alt="model image"
                          />
                        </div>
                      );
                    })}
                  </div>
                </RadioGroup>
              </div>
            )}
          <UploadHandler
            userData={userData}
            setUSerData={setUSerData}
            activeStep={activeStep}
            handleChange={handleChange}
            loading={loading}
            disable={disable}
            setLoading={setLoading}
            setVideoSelection={setVideoSelection}
          />
        </div>
        <div className="flex gap-2 items-center mt-2">
          <Tooltip label={"Confirm project details"} hasArrow placement="top">
            <div>
              <PrimaryButton
                text={userData?.isAnnotated == "Yes" ? "Start" : "Confirm"}
                onClick={handleSave}
                width={"fit-content"}
                disable={disable || loading}
              />
            </div>
          </Tooltip>
          <Tooltip
            label={"Cannot go back once files are uploaded"}
            hasArrow
            placement="top"
            isDisabled={
              !(
                disable ||
                Object.entries(userData.uploadedFiles).length > 0 ||
                loading
              )
            }
          >
            <div>
              <TonalButton
                text={"Back"}
                width={"fit-content"}
                disable={
                  disable ||
                  Object.entries(userData.uploadedFiles).length > 0 ||
                  loading
                }
                onClick={() => setActiveStep(50)}
              />
            </div>
          </Tooltip>
        </div>
      </div>
      {/*classify,detect,segment info images */}
      {userData.annotationType != "" &&
        Object.entries(userData.uploadedFiles).length == 0 &&
        userData.bucketLink == "" && (
          <div className="rounded-lg h-full flex justify-center items-center transition-all duration-500 ease-linear">
            <img
              src={`/selfServiceIcons/images/${userData.annotationType.toLowerCase()}_final.png`}
              alt="info image"
              className="rounded-lg h-auto xl:h-[420px] w-full lg:w-[35vw] xl:w-auto"
            />
          </div>
        )}
      {/*uploaded images */}
      {userData.uploadedFiles &&
        Object.entries(userData.uploadedFiles).length > 0 && (
          <div className="grid grid-cols-2 h-full w-fit gap-3">
            {Object.entries(userData.uploadedFiles)
              .slice(0, 5)
              .map((item) => {
                return (
                  <div className="flex justify-center items-center bg-black w-full rounded-lg">
                    <img
                      src={item[1]}
                      alt="image"
                      className="h-auto xl:h-[120px] w-[50vw] lg:w-[15vw] xl:w-auto rounded-lg"
                      crossOrigin="anonymous"
                      loading="lazy"
                    />
                  </div>
                );
              })}
            <div className="text-[#3E3C42] text-lg font-medium flex justify-center items-center text-center">
              {Object.entries(userData.uploadedFiles).length} Images uploaded
            </div>
          </div>
        )}
      {/*Video selection modal */}
      {videoSelection && userData?.savedFiles && (
        <VideoSelect
          openModal={videoSelection}
          closeModal={() => setVideoSelection(false)}
          selectedFiles={userData?.savedFiles}
          userData={userData}
          setUSerData={setUSerData}
          setLoading={setLoading}
          activeStep={activeStep}
        />
      )}
    </div>
  );
};

export default UploadDetails;
