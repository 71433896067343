import ProductCard from "../AddClients/Components/ProductCards";
import React, { useContext, useEffect, useState } from "react";
import NavContext from "../../NavContext";
import axios from "axios";
import { baseURL } from "../../..";
import { Spinner } from "@chakra-ui/react";
import ProductGroupCard from "../AddClients/Components/ProductGroupCards";
import ProductsGroupsTable from "../Products/Components/ProductsGroupsTable";
import ProductsTable from "../Products/Components/ProductsTable";
import AddIcon from '@mui/icons-material/Add';

const ManageProducts = ({
  setShowProductTab,
  setSelectedProductGroup,
  view,
  setView,
  format,
  setFormat,
}) => {
  const { auth } = useContext(NavContext);
  const [loading, setLoading] = useState(false);
  const [productGroup, setProductGroup] = useState(false);
  const [products, setProducts] = useState(false);

  const getProductGroups = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        baseURL + "product/v1/fetch/productGroup/detail",
        {
          headers: {
            "Content-Type": "application/json",
            "X-auth-Token": auth,
          },
        }
      );
      let data = response?.data;
      //console.log(data);
      data.sort((a, b) => {
        // let x = a.productGroupName.toLowerCase();
        // let y = b.productGroupName.toLowerCase();

        let x = a.updatedAt;
        let y = b.updatedAt;
        if (a.isactive && !b.isactive) return -1;
        if (!a.isactive && b.isactive) return 1;
        if (x < y) return 1;
        if (x > y) return -1;
        return 0;
      });
      //console.log(data1);
      setProductGroup(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      //console.log(error);
    }
  };

  const getProducts = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        baseURL + "product/v1/fetch/product/detail",
        {
          headers: {
            "Content-Type": "application/json",
            "X-auth-Token": auth,
          },
        }
      );
      let data = response?.data;
      //console.log(data);
      data.sort((a, b) => {
        // let x = a.productName.toLowerCase();
        // let y = b.productName.toLowerCase();

        let x = a.product.updatedAt;
        let y = b.product.updatedAt;
        if (a.product.isactive && !b.product.isactive) return -1;
        if (!a.product.isactive && b.product.isactive) return 1;
        if (x < y) return 1;
        if (x > y) return -1;
        return 0;
      });
      //console.log(data1);
      setProducts(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      //console.log(error);
    }
  };

  //product/v1/fetch/product
  useEffect(() => {
    getProductGroups();
    getProducts();
  }, []);

  const handleAddProductCategory = () => {
    setShowProductTab((prev) => "addNewProductCategory");
  };

  const handleAddProduct = () => {
    setShowProductTab((prev) => "addNewProduct");
  };
  console.log(view);

  //grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 min-[1440px]:grid-cols-5 min-[1750px]:grid-cols-5 min-[2150px]:grid-cols-6

  return (
    <div className="flex flex-col rounded-lg bg-white ">
      <div className="flex flex-col sm:flex-row justify-between">
        <div className="flex flex-row gap-0">
          <div className='py-[6px] px-3 capitalize border text-sm w-[125px] flex justify-center' style={{ backgroundColor: view === "productGroups" ? "#FFFFED" : "white", borderColor: view === "productGroups" ? "#FFC107" : "#EBEBEB", color: view === "productGroups" ? "#3E3C42" : "#605D64", fontWeight: view === "productGroups" ? 500 : 400, cursor: view === "productGroups" ? "" : "pointer",}} onClick={() => setView("productGroups")}>
            {/* <img className='w-[20px] h-[20px] mr-1' style={{ backgroundColor: view === "productGroups" ? "#CDEEBF" : "white", }} src="/cardIcon.svg" ></img> */}
            Product Groups
          </div>
          <div className='py-[6px] px-3 capitalize border text-sm w-[125px] flex justify-center' style={{ backgroundColor: view === "products" ? "#FFFFED" : "white", borderColor: view === "products" ? "#FFC107" : "#EBEBEB", color: view === "products" ? "#3E3C42" : "#605D64", fontWeight: view === "products" ? 500 : 400, cursor: view === "products" ? "" : "pointer",}} onClick={() => setView("products")}>
            {/* <img className='w-[20px] h-[20px] mr-1' style={{ backgroundColor: view === "products" ? "s" : "white", }} src="/tableIcon.svg" ></img> */}
            Products
          </div>
        </div>

        <div className='flex flex-row gap-0 pl-3 pt-2 sm:pl-0 sm:pt-0'>
          <div className='py-[6px] flex gap-1 items-center px-3 capitalize border text-sm' style={{ backgroundColor: format === "card" ? "#FFFFED" : "white", borderColor: format === "card" ? "#FFC107" : "#EBEBEB", color: format === "card" ? "#3E3C42" : "#605D64", fontWeight: format === "card" ? 500 : 400, cursor: format === "card" ? "" : "pointer", }} onClick={() => setFormat("card")}>
            <img className='w-[20px] h-[20px] mr-1' src="/cardIcon.svg" ></img>
            Card
          </div>
          <div className='py-[6px] flex gap-1 items-center px-3 capitalize border text-sm' style={{ backgroundColor: format === "table" ? "#FFFFED" : "white", borderColor: format === "table" ? "#FFC107" : "#EBEBEB", color: format === "table" ? "#3E3C42" : "#605D64", fontWeight: format === "table" ? 500 : 400, cursor: format === "table" ? "" : "pointer", }} onClick={() => setFormat("table")}>
            <img className='w-[20px] h-[20px] mr-1' src="/tableIcon.svg" ></img>
            Table
          </div>
        </div>
      </div>

      <div className="bg-[#fafafa]  p-4 w-full overflow-y-auto sm:h-full gap-3 text-base font-medium text-[#3E3C42] flex xl:flex-row flex-col">
        {view === "productGroups" && format === "card" && (
          <div
            className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 min-[1440px]:grid-cols-5 lg:grid-cols-3 gap-3 w-full  h-fit"
            style={{
              paddingRight:
                products.length < productGroup.length ? "2px" : "0px",
              borderRight:
                products.length < productGroup.length ? "4px" : "0px",
              borderColor:
                products.length < productGroup.length ? "4px" : "0px",
            }}
          >
            <div
              className="rounded p-3 w-full flex flex-col justify-center gap-3 items-center bg-white h-[250px]"
              style={{
                boxShadow:
                  "-4px -4px 24px 0px rgba(0, 0, 0, 0.07), 4px 4px 24px 0px rgba(0, 0, 0, 0.07)",
              }}
              // onClick = {() => setShowAddNewProductCategoryForm(true)}
            >
              <div
                className="p-[10px] bg-[#CDEEBF] text-center rounded cursor-pointer hover:scale-105"
                onClick={handleAddProductCategory}
              >
                <img src="/superadmin/addNewProduct.svg" alt="add" />
              </div>

              <p className="font-bold">Add new product group</p>
            </div>
            {loading ? (
              <div className="flex items-center justify-center">
                <Spinner />
              </div>
            ) : (
              productGroup &&
              productGroup.length != 0 &&
              productGroup?.map((items) => {
                return (
                  <ProductGroupCard
                    setSelectedProductGroup={setSelectedProductGroup}
                    setShowProductTab={setShowProductTab}
                    data={items}
                    getProductGroups={getProductGroups}
                    getProducts={getProducts}
                  />
                );
              })
            )}
          </div>
        )}
        {view === "products" && format === "card" && (
          <div
            className=" grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 min-[1440px]:grid-cols-5 lg:grid-cols-3 gap-3 w-full  h-fit"
            style={{
              paddingLeft:
                products.length > productGroup.length ? "2px" : "0px",
              borderLeft: products.length > productGroup.length ? "2px" : "0px",
            }}
          >
            <div
              className="rounded p-3 w-full flex flex-col justify-center gap-3 items-center bg-white h-[250px]"
              style={{
                boxShadow:
                  "-4px -4px 24px 0px rgba(0, 0, 0, 0.07), 4px 4px 24px 0px rgba(0, 0, 0, 0.07)",
              }}
            >
              <div
                className="p-[10px] bg-[#DEF] text-center rounded cursor-pointer hover:scale-105"
                onClick={handleAddProduct}
              >
                <AddIcon sx={{color: "#3A74CA"}} />
              </div>

              <p className="font-bold">Add new product</p>
            </div>
            {loading ? (
              <div className="flex items-center justify-center">
                <Spinner speed="0.65s" size={"sm"} />
              </div>
            ) : (
              products &&
              products.length != 0 &&
              products?.map((items) => {
                return (
                  <ProductCard
                    setSelectedProductGroup={setSelectedProductGroup}
                    setShowProductTab={setShowProductTab}
                    data={items}
                    getProducts={getProducts}
                    getProductGroups={getProductGroups}
                  />
                );
              })
            )}
          </div>)}
        {(view === "productGroups" && format === "table") && <React.Fragment>
          {productGroup && productGroup.length !== 0 ? (
            <ProductsGroupsTable productGroups={productGroup} setSelectedProductGroup={setSelectedProductGroup}
              setShowProductTab={setShowProductTab} getProductGroups={getProductGroups} />
          ) : (
            <p className="ml-[45%]">No data available!</p>
          )}
        </React.Fragment>}
        {(view === "products" && format === "table") && <React.Fragment>
          {products && products.length !== 0 ? (
            <ProductsTable products={products} setSelectedProductGroup={setSelectedProductGroup}
              setShowProductTab={setShowProductTab} getProducts={getProducts} />
          ) : (
            <p className="ml-[45%]">No data available!</p>
          )}
        </React.Fragment>}
      </div>
    </div>
  );
};

export default ManageProducts;
