import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  useLocation,
  useParams,
  Link,
  useSearchParams,
} from "react-router-dom";
import Feed from "./Tabs/Feed";
import Alerts from "./Tabs/Alerts";
import Report from "./Tabs/Report";
import { useWindowSize } from "@uidotdev/usehooks";
import mixpanel from "mixpanel-browser";
import { mixpanelTrackTabs } from "../../util/mixpanelFunctions";

const Capitalize = (str) => {
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
};

const WorkforceSafety = () => {
  const size = useWindowSize();
  const location = useLocation();
  const [plantCamMap, setPlantCamMap] = useState({});
  const [page, setPage] = useState("feed");
  const param = useParams();
  useEffect(() => {
    mixpanelTrackTabs(page, "WorkforceSafety Monitoring");
  }, [page]);

  const [searchParams, setSearchParams] = useSearchParams();
  const urlParamTabIndex = !isNaN(searchParams.get("tabIndex"))
    ? Number(searchParams.get("tabIndex"))
    : 0;

  // handling tab change
  const handleTabsChange = (index) => {
    if (index !== urlParamTabIndex) {
      setSearchParams({ tabIndex: index }, { replace: true });
    }
  };

  const tabs = [
    {
      label: "Feed",
      index: 0,
      component: (
        <Feed
          material={"workforcesafety"}
          clientId={param.clientId.toLowerCase()}
          setPlantCamMap={setPlantCamMap}
        />
      ),
    },
    {
      label: "Alerts",
      index: 1,
      component: (
        <Alerts
          plantId="All Plants"
          cameraId=""
          disable={false}
          plantCamMap={plantCamMap}
        />
      ),
    },
    {
      label: "Report",
      index: 2,
      component: (
        <Report
          plantId="All Plants"
          cameraId=""
          disable={false}
          plantCamMap={plantCamMap}
        />
      ),
    },
  ];
  useEffect(() => {
    handleTabsChange(urlParamTabIndex);
  }, [urlParamTabIndex]);
  return (
    <div
      className="  font-poppins flex flex-col rounded-lg"
      style={{ width: size.width >= 768 ? "calc(100vw - 168px)" : "100vw" }}
    >
      <div
        className="fixed top-3 right-2 h-[30px] w-[180px] bg-white"
        style={{ zIndex: 1000 }}
      >
        <img
          className="absolute h-full w-full"
          src="/clientLogos/asianpaints.png"
        />
      </div>
      <div className="flex justify-start gap-2 items-center mb-3 mt-6">
        <Link
          to={`/vision/workforceMonitoring/workforcesafety`}
          style={{
            textDecoration: "none",
          }}
        >
          <img src="/backtick.svg" />
        </Link>
        <p className="text-lg sm:text-2xl font-semibold text-[#024D87]">
          {Capitalize("raw material sampling")}
        </p>
      </div>
      <Tabs
        isLazy={true}
        index={urlParamTabIndex}
        onChange={handleTabsChange}
        paddingRight={{ base: "8px", md: "0px", lg: "0px" }}
      >
        <TabList className="!flex !border-0">
          <div className="flex items-center gap-4 overflow-x-auto h-14 md:h-10">
            {tabs &&
              tabs.map((tab, index) => (
                <Tab
                  key={`${tab.label}-${index}`}
                  minW={"fit-content"}
                  className={`${
                    urlParamTabIndex === tab.index
                      ? "!text-black !text-xs sm:!text-sm !bg-white rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#79767D]"
                      : "!text-xs sm:!text-sm !text-[#938F96] !border-0"
                  }`}
                  onClick={() => handleTabsChange(tab.index)}
                >
                  {tab.label}
                </Tab>
              ))}
          </div>
        </TabList>
        <TabPanels>
          {tabs &&
            tabs.map((tab, index) => (
              <TabPanel
                key={`${tab.label}-${index}`}
                className="!pl-0 !pr-0 h-full"
              >
                {tab.component}
              </TabPanel>
            ))}
        </TabPanels>
      </Tabs>

      {/* <Tabs isLazy={true}>
        <TabList className="!flex !border-0">
          <div className="flex items-center gap-4 overflow-x-auto h-14 md:h-10">
            <Tab
              className={
                page === "feed"
                  ? "!text-black !text-xs sm:!text-sm !bg-white rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#79767D]"
                  : "!text-xs sm:!text-sm !text-[#938F96] !border-0"
              }
              onClick={() => setPage("feed")}
            >
              Feed
            </Tab>
            <Tab
              className={
                page === "alerts"
                  ? "!text-black !text-xs sm:!text-sm !bg-white rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#79767D]"
                  : "!text-xs sm:!text-sm !text-[#938F96] !border-0"
              }
              onClick={() => setPage("alerts")}
            >
              Alerts
            </Tab>
            <Tab
              className={
                page === "report"
                  ? "!text-black !text-xs sm:!text-sm !bg-white rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#79767D]"
                  : "!text-xs sm:!text-sm !text-[#938F96] !border-0"
              }
              onClick={() => setPage("report")}
            >
              Report
            </Tab>
          </div>
        </TabList>

        <TabPanels>
          <TabPanel className="!pl-0 !pr-0">
            <Feed
              material={"workforcesafety"}
              clientId={param.clientId.toLowerCase()}
              setPlantCamMap={setPlantCamMap}
            />
          </TabPanel>
          <TabPanel className="!pl-0 !pr-0">
            <Alerts
              plantId="All Plants"
              cameraId=""
              disable={false}
              plantCamMap={plantCamMap}
            />
          </TabPanel>
          <TabPanel className="!pl-0 !pr-0">
            <Report
              plantId="All Plants"
              cameraId=""
              disable={false}
              plantCamMap={plantCamMap}
            />
          </TabPanel>
        </TabPanels>
      </Tabs> */}
    </div>
  );
};

export default WorkforceSafety;
