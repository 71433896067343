import React, { useState, useEffect, useContext, useRef } from "react";
import { Input, useToast, Textarea, Text } from "@chakra-ui/react";
import TonalButton from "../../../../util/Buttons/TonalButton";
import PrimaryButton from "../../../../util/Buttons/PrimaryButton";
import DiscardModal from "../../DiscardModal";
import NavContext from "../../../NavContext";
import axios from "axios";
import { baseURL } from "../../../..";

const DeviceInfoForm = ({
  userForm,
  setUserForm,
  page,
  activeStep,
  setActiveStep,
  view=false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { auth } = useContext(NavContext);
  const toast = useToast();
  const [categories, setCategories] = useState([]);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [disable, setDisable] = useState(view);
  const [review, setReview] = useState(false);

  const fetchCategory = async () => {
    try {
      const response = await axios.get(
        baseURL + "device/group/v1/fetch/device",
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response?.data) {
        console.log(response?.data);
        setCategories(response?.data.filter((item) => item?.status === "ACTIVE"));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (name, value) => {
    setUserForm((prev) => ({
      ...prev,
      [page]: {
        ...prev[page],
        [name]: value,
      },
    }));
  };

  const checkOk = () => {
    setSubmitClicked(true);
    if (
      !userForm[page]?.deviceGroupId ||
      !userForm[page]?.deviceName ||
      !userForm[page]?.deviceMake ||
      userForm[page]?.deviceName?.length > 100 ||
      userForm[page]?.deviceMake?.length > 100 ||
      userForm[page]?.referanceNo?.length > 100 ||
      userForm[page]?.secReferanceNo?.length > 100 ||
      userForm[page]?.deviceDetail?.length > 1000 ||
      userForm[page]?.deviceNotes?.length > 1000
    ) {
      toast({
        title: "Incomplete Details",
        description: "Please fill in all required details.",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
      return false;
    } else {
      
      setSubmitClicked(false);
      return true;
    }
  };

  const handleSubmit = () => {
    console.log(userForm)
    if (checkOk()) {
      setDisable(true);
      setActiveStep((prev) => prev + 1);
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmDiscard = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    fetchCategory();
  }, []);

  return (
    <div
      className="font-roboto flex flex-col gap-2 p-4 pt-2 m-2 bg-white rounded-lg border"
      id="step0"
    >
      <div className="flex flex-col sm:flex-row justify-between">
        <p className="text-[#3E3C42] text-xl font-medium">Device information</p>
      </div>

      <div className="lg:w-[50%] flex flex-col xs:flex-row gap-2">
        <div className="xs:w-[50%] flex flex-col gap-3 mt-6">
          <p className="text-[#3E3C42] text-lg font-medium">
            Category<span className="text-red-500">*</span>
          </p>
          <div className="w-full">
            {view === true ? <p className="text-[#525056] text-sm font-medium">{userForm[page]?.deviceCategory}</p> : 
            <select
              value={userForm[page]?.deviceGroupId}
              onChange={(e) => handleChange("deviceGroupId", e.target.value)}
              borderColor={
                submitClicked && !userForm[page]?.deviceGroupId
                  ? "red.500"
                  : "gray.300"
              }
              className="w-[100%] p-2 border-[2px] rounded-lg max-h-[300px] overflow-y-auto"
              disabled={disable}
            >
              <option value="" disabled selected>
                Select one
              </option>
              {categories?.map((item, index) => (
                <option value={item.deviceGroupId}>
                  {item.deviceGroupName}
                </option>
              ))}
            </select>}

            {submitClicked && !userForm[page]?.deviceGroupId && (
              <Text color="red.500" fontSize="sm" mt="1">
                Please select the Category.
              </Text>
            )}
          </div>
        </div>
        <div className="xs:w-[50%] flex flex-col gap-3 mt-6">
          <p className="text-[#3E3C42] text-lg font-medium">
            Name<span className="text-red-500">*</span>
          </p>
          {view === true ? <p className="text-[#525056] text-sm font-medium">{userForm[page]?.deviceName}</p> : 
          <div className="w-full">
            <Input
              type="text"
              value={userForm[page]?.deviceName}
              required
              borderColor={
                submitClicked && !userForm[page]?.deviceName
                  ? "red.500"
                  : "gray.300"
              }
              borderWidth={"2px"}
              onChange={(e) => handleChange("deviceName", e.target.value)}
              isDisabled={disable}
            />

            {submitClicked && !userForm[page]?.deviceName && (
              <Text color="red.500" fontSize="sm" mt="1">
                Please enter the name
              </Text>
            )}

            {userForm[page]?.deviceName?.length > 100 && (
              <div>
                <Text color="red.500" fontSize="sm" mt="1">
                  You have exceed the character limit of 100 by{" "}
                  {userForm[page]?.deviceName?.length - 100}
                </Text>
              </div>
            )}
          </div>}
        </div>
      </div>

      {view === true && <hr className="mt-3"></hr>}
      
      <div className="sm:w-[100%] flex flex-col sm:flex-row gap-2">
        <div className="sm:w-[50%] flex flex-row gap-2">
          <div className="w-[50%] flex flex-col gap-3 mt-6">
            <p className="text-[#3E3C42] text-lg font-medium">
              Make<span className="text-red-500">*</span>(Enter N/A if not
              available)
            </p>
            {view === true ? <p className="text-[#525056] text-sm font-medium">{userForm[page]?.deviceMake}</p> :
            <div className="w-full">
              <Input
                type="text"
                value={userForm[page]?.deviceMake}
                onChange={(e) => handleChange("deviceMake", e.target.value)}
                required
                borderColor={
                  submitClicked && !userForm[page]?.deviceMake
                    ? "red.500"
                    : "gray.300"
                }
                borderWidth={"2px"}
                isDisabled={disable}
              />

              {submitClicked && !userForm[page]?.deviceMake && (
                <Text color="red.500" fontSize="sm" mt="1">
                  Please enter the Make
                </Text>
              )}

              {userForm[page]?.deviceMake?.length > 100 && (
                <div>
                  <Text color="red.500" fontSize="sm" mt="1">
                    You have exceed the character limit of 100 by{" "}
                    {userForm[page]?.deviceMake?.length - 100}
                  </Text>
                </div>
              )}
            </div>}
          </div>
          <div className="w-[50%] flex flex-col gap-3 mt-6">
            <p className="text-[#3E3C42] text-lg font-medium">
              Reference Number
            </p>
            {view === true ? <p className="text-[#525056] text-sm font-medium">{userForm[page]?.referanceNo}</p> :
            <div className="w-full">
              <Input
                type="text"
                value={userForm[page]?.referanceNo}
                onChange={(e) => handleChange("referanceNo", e.target.value)}
                required
                borderColor={
                  // submitClicked && !userForm[page]?.referanceNo
                  //   ? "red.500"
                   "gray.300"
                }
                borderWidth={"2px"}
                isDisabled={disable}
              />

              {/* {submitClicked && userForm[page]?.referanceNo?.length === 0 && (
                <Text color="red.500" fontSize="sm" mt="1">
                  Please enter the Reference Number
                </Text>
              )} */}

              {userForm[page]?.referanceNo?.length > 100 && (
                <div>
                  <Text color="red.500" fontSize="sm" mt="1">
                    You have exceed the character limit of 100 by{" "}
                    {userForm[page]?.referanceNo?.length - 100}
                  </Text>
                </div>
              )}
            </div>}
          </div>
          {/* <div className="w-[50%] flex flex-col justify-between gap-3 mt-6">
            <p className="text-[#3E3C42] text-lg font-medium">
              Serial Number<span className="text-red-500">*</span>
            </p>
            {view === true ? <p>{userForm[page]?.serialNumber}</p> :
            <div className="w-full">
              <Input
                type="text"
                value={userForm[page]?.serialNumber}
                onChange={(e) => handleChange("serialNumber", e.target.value)}
                required
                borderColor={
                  submitClicked && !userForm[page]?.serialNumber
                    ? "red.500"
                    : "gray.300"
                }
                borderWidth={"2px"}
                isDisabled={disable}
              />

              {submitClicked && userForm[page]?.serialNumber?.length === 0 && (
                <Text color="red.500" fontSize="sm" mt="1">
                  Please enter the Serial Number
                </Text>
              )}

              {userForm[page]?.serialNumber?.length > 100 && (
                <div>
                  <Text color="red.500" fontSize="sm" mt="1">
                    You have exceed the character limit of 100 by{" "}
                    {userForm[page]?.serialNumber?.length - 100}
                  </Text>
                </div>
              )}
            </div>}
          </div> */}
        </div>

        <div className="sm:w-[50%] flex flex-col gap-3 mt-6">
          <p className="text-[#3E3C42] text-lg font-medium">
            Secondary Reference Number
          </p>
          {view === true ? <p className="text-[#525056] text-sm font-medium">{userForm[page]?.secReferanceNo}</p> :
          <div className="w-[50%]">
            <Input
              type="text"
              value={userForm[page]?.secReferanceNo}
              onChange={(e) => handleChange("secReferanceNo", e.target.value)}
              required
              borderColor={"gray.300"}
              borderWidth={"2px"}
              isDisabled={disable}
            />
            {userForm[page]?.secReferanceNo?.length > 100 && (
              <div>
                <Text color="red.500" fontSize="sm" mt="1">
                  You have exceed the character limit of 100 by{" "}
                  {userForm[page]?.secReferanceNo?.length - 100}
                </Text>
              </div>
            )}
          </div>}
        </div>
      </div>

      {view === true && <hr className="mt-3"></hr>}

      <div className="flex flex-col sm:flex-row gap-2">
        <div className="sm:w-[50%] flex flex-col gap-3 mt-6">
          <p className="text-[#3E3C42] text-lg font-medium">Details</p>
          {view === true ? <p className="text-[#525056] text-sm font-medium">{userForm[page]?.deviceDetail}</p> :
          <div className="w-full">
            <Textarea
              placeholder="Details"
              borderWidth={"2px"}
              value={userForm[page]?.deviceDetail}
              onChange={(e) => handleChange("deviceDetail", e.target.value)}
              isDisabled={disable}
            />

            {userForm[page]?.deviceDetail?.length > 1000 && (
              <div>
                <Text color="red.500" fontSize="sm" mt="1">
                  You have exceed the character limit of 1000 by{" "}
                  {userForm[page]?.deviceDetail?.length - 1000}
                </Text>
              </div>
            )}
          </div>}
        </div>
        <div className="sm:w-[50%] flex flex-col gap-3 mt-6">
          <p className="text-[#3E3C42] text-lg font-medium">Notes</p>
          {view === true ? <p className="text-[#525056] text-sm font-medium">{userForm[page]?.deviceNotes}</p> :
          <div className="w-full">
            <Textarea
              placeholder="Notes"
              borderWidth={"2px"}
              value={userForm[page]?.deviceNotes}
              onChange={(e) => handleChange("deviceNotes", e.target.value)}
              isDisabled={disable}
            />

            {userForm[page]?.deviceNotes?.length > 1000 && (
              <div>
                <Text color="red.500" fontSize="sm" mt="1">
                  You have exceed the character limit of 1000 by{" "}
                  {userForm[page]?.deviceNotes?.length - 1000}
                </Text>
              </div>
            )}
          </div>}
        </div>
      </div>
      {view === false && 
      <div className="flex gap-[10px] items-center ml-6 md:ml-0 mt-6 mb-20 md:mb-0">
            
            { disable ? 
            <TonalButton
              text={"Back to editing"}
              width={"130px"}
              onClick={() => {
                setReview(false);
                setDisable(false);
              }}
              //disable={activeStep > 0 || view}
            /> :
            <PrimaryButton
              text={"Save"}
              width={"130px"}
              onClick={handleSubmit}
              //disable={activeStep > 0 || view}
              //color="green"
            />}
          
      </div>}

      <div className="flex flex-row items-center gap-1">
      <p className="text-xs text-[#D3D3D3]">Device UUID</p>
        <p>{userForm[page].deviceId}</p>
        
        
      </div>

      <DiscardModal
        isModalOpen={isModalOpen}
        handleCloseModal={handleCloseModal}
        handleConfirmDiscard={handleConfirmDiscard}
      />
    </div>
  );
};

export default DeviceInfoForm;
