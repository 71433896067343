import axios from "axios";
import React, { useEffect, useState } from "react";
import { baseURL } from "../../../..";
import { IconButton, Skeleton } from "@chakra-ui/react";
import FeedDown from "./FeedDown";
import { AspectRatio } from "@mui/icons-material";
import { ZoomedImageModal } from "./ZoomedImageModal";

const handleReadableTimeStamp = (time) => {
  if (time && time != "") {
    if (time.toString().length === 13) {
      return new Date(time).toLocaleString("en-GB", {
        timeZone: "IST",
      });
    }

    return new Date(time * 1000).toLocaleString("en-GB", {
      timeZone: "UTC",
    });
  } else {
    return "";
  }
};

const VideoByCamId = ({ item, timestamp }) => {
  // const apiCall = async () => {
  //   try {
  //     const requestData = JSON.stringify({
  //       clientId: clientId,
  //       useCase: material,
  //       cameraId: camId,
  //     });
  //     const response = await axios.post(
  //       baseURL + "vision/v1/workforce/recipeCompliance/live/feed/",
  //       requestData,
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     );

  //     let data = response.data;

  //     setCamFeed({
  //       imageUrl: data?.image_url ? data.image_url : "",

  //       loading: false,
  //     });
  //   } catch (error) {
  //     console.error("Error fetching data:", error.response.status);
  //     if (error.response.status === 500) {
  //       setCamFeed({
  //         imageUrl: null,

  //         loading: false,
  //       });
  //     }
  //     if (error.response.status === 404) {
  //       setCamFeed({
  //         imageUrl: "",

  //         loading: false,
  //       });
  //     }
  //   }
  // };
  // useEffect(() => {
  //   apiCall();
  //   const interval = setInterval(apiCall, 15000);

  //   return () => clearInterval(interval);
  // }, [camId]);
  const [selectedImage, setSelectedImage] = useState(item?.cameraUrl);
  const [openZoomModal, setOpenZoomModal] = useState(false);
  const handlePreviewImage = (imageUrl) => {
    setSelectedImage((prev) => imageUrl);
    setOpenZoomModal((prev) => true);
  };

  return (
    <div className="h-full ">
      {item.cameraUrl === null ||
      item?.cameraUrl === "" ||
      item?.cameraUrl === "noimage" ? (
        <div className="h-[180px]">
          <FeedDown />
        </div>
      ) : (
        <div className="h-[180px] relative ">
          <img
            src={item?.cameraUrl}
            alt=""
            className="w-full  object-contain h-full bg-black"
          />
          <div className="flex flex-row  absolute bottom-2 right-2 items-center gap-1">
            <IconButton
              onClick={() => handlePreviewImage(item?.cameraUrl || "")}
              size={"xs"}
              backgroundColor={"transparent"}
              _hover={{ backgroundColor: "transparent" }}
              _active={{ backgroundColor: "transparent" }}
              icon={
                <AspectRatio
                  className="preview-image text-white hover:cursor-pointer transform transition-transform duration-300 ease-in-out hover:scale-110"
                  data-index={item?.cameraUrl}
                  fontSize={"medium"}
                />
              }
            />
            {/* <p className="text-white text-xs font-semibold bg-black rounded-md p-[2px]">
                    Board No- {capitalizeFirstLetter(x.boardNo)}
                  </p> */}
          </div>
          {openZoomModal && (
            <ZoomedImageModal
              imageUrl={selectedImage}
              openModal={openZoomModal}
              closeModal={() => {
                setOpenZoomModal((prev) => false);
                setSelectedImage(null);
              }}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default VideoByCamId;
