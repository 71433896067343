import axios from "axios";
import { mulpicJsplBaseUrl } from "../Mulpic";

export const getPastRunData = async (
  auth,
  requestData,
) => {

  console.log(requestData);
  try {
    // setLoading((prev) => {
    //   return { ...prev, upcomingBiasRecommender: true };
    // });
    const res = await axios.get(
      `${mulpicJsplBaseUrl}mulpic/get_plates_history`,
      {
        credentials: "same-origin",
        params: requestData,
        headers: {
          "Content-Type": "application/json",
          // "X-Auth-Token": auth,
        },
      }
    );
    const data = res?.data;
    if (data) {
      const { columns, tableData } = data;
      const sortedData = tableData?.sort((a, b) => b?.["TOC"] - a?.["TOC"]);
      return {
        success: true,
        data: { columns, tableData: sortedData },
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      error: error,
    };
    // } finally {
    //   setLoading((prev) => {
    //     return { ...prev, upcomingBiasRecommender: false };
    //   });
  }
};

export const getProductionData = async (
  auth,
  requestData,
) => {
  try {
    const res = await axios.get(
      `${mulpicJsplBaseUrl}mulpic/get_production_report`,
      {
        credentials: "same-origin",
        params: requestData,
        headers: {
          "Content-Type": "application/json",
          // "X-Auth-Token": auth,
        },
      }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data: data,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      error: error,
    };
  }
};

export const getInsufficientData = async (
  auth,
  requestData,
) => {
  try {
    const res = await axios.get(
      `${mulpicJsplBaseUrl}mulpic/insf_data/`,
      {
        credentials: "same-origin",
        params: requestData,
        headers: {
          "Content-Type": "application/json",
          // "X-Auth-Token": auth,
        },
      }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data: data,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      error: error,
    };
  }
};

export const downLoadData = async (
  startDate,
  endDate,
  auth,
  requestData,
) => {
  try {
    const res = await axios.get(
      `${mulpicJsplBaseUrl}mulpic/excel_download`,
      {
        credentials: "same-origin",
        params: requestData,
        headers: {
          "Content-Type": "application/json",
          // "X-Auth-Token": auth,
        },
        responseType: "blob"
      }
    );

    if (res?.status == 200) {
      const data = await res?.data;
      const url = URL.createObjectURL(data);
      const node = document.createElement("a");
      node.href = url;
      node.setAttribute("download", `Mulpic_report_${startDate}_to_${endDate}.xlsx`);
      node.click();
      node.remove();
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      error: error,
    };
  }
};

export const getHighMovingGradesData = async (
  auth,
  requestData,
) => {
  try {
    const res = await axios.get(
      `${mulpicJsplBaseUrl}mulpic/high_moving_grades`,
      {
        credentials: "same-origin",
        params: requestData,
        headers: {
          "Content-Type": "application/json",
          // "X-Auth-Token": auth,
        },
      }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data: data,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      error: error,
    };
  }
};

export const getTargetTemperatureData = async (
  auth,
  requestData,
) => {
  try {
    const res = await axios.get(
      `${mulpicJsplBaseUrl}mulpic/temperature_data`,
      {
        credentials: "same-origin",
        params: requestData,
        headers: {
          "Content-Type": "application/json",
          // "X-Auth-Token": auth,
        },
      }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data: data,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      error: error,
    };
  }
};

export const getRecommendedConfigDataApi = async (
  auth,
  requestData,
  setLoadingConfig
) => {
  try {
    setLoadingConfig((prev) => ({ ...prev, recommendedConfig: true }));
    const res = await axios.get(
      `${mulpicJsplBaseUrl}mulpic/get_recommended_config`,
      {
        params: requestData,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      error: error,
    };
  } finally {
    setLoadingConfig((prev) => ({ ...prev, recommendedConfig: false }));
  }
};

export const getActualConfigDataApi = async (
  auth,
  requestData,
  setLoadingConfig
) => {
  console.log(requestData)
  try {
    setLoadingConfig((prev) => ({ ...prev, actualConfig: true }));
    const res = await axios.get(
      `${mulpicJsplBaseUrl}mulpic/get_actual_config`,
      {
        params: requestData,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      error: error,
    };
  } finally {
    setLoadingConfig((prev) => ({ ...prev, actualConfig: false }));
  }
};

export const getAnalysisData = async (
  auth,
  requestData,
  setLoadingConfig
) => {
  console.log(requestData)
  try {
    setLoadingConfig((prev) => ({ ...prev, actualConfig: true }));
    const res = await axios.get(
      `${mulpicJsplBaseUrl}mulpic/analysis_data`,
      {
        params: requestData,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      data: null,
      error: error,
    };
  } finally {
    setLoadingConfig((prev) => ({ ...prev, actualConfig: false }));
  }
};