import { useContext, useEffect } from "react";
import { useState } from "react";
import { baseURL } from "../../..";
import axios from "axios";
import NavContext from "../../NavContext";

const ClientAdminCards = ({
  noOfActiveSubs,
  noOflocations,
  organisation,
  commonLogo,
}) => {
  const [imageUrl, setImageUrl] = useState(null);
  const { auth } = useContext(NavContext);

  return (
    <div className="flex items-center xl:w-1/2 gap-3">
      {commonLogo && (
        <div className="flex shadow-md rounded-lg w-[100px] h-[67px]">
          <img src={commonLogo} alt="logo" className="w-full" />
        </div>
      )}
      <div className="flex shadow-md rounded-lg">
        <div className="w-[10px] border rounded-l-lg bg-[#7AC958]"></div>
        <div className="p-2">
          <p className="text-[#3E3C42] font-medium text-[20px]">
            {noOfActiveSubs}
          </p>
          <p className="text-[#605D64] text-[11px] sm:text-[14px]">
            Active subscriptions
          </p>
        </div>
      </div>
      <div className="flex shadow-md rounded-lg">
        <div className="w-[10px] border rounded-l-lg bg-[#FFC107]"></div>
        <div className="p-2">
          <p className="text-[#3E3C42] font-medium text-[20px]">
            {noOflocations}
          </p>
          <p className="text-[#605D64] text-[11px] sm:text-[14px]">
            All locations
          </p>
        </div>
      </div>
    </div>
  );
};

export default ClientAdminCards;
