import { Skeleton } from "@chakra-ui/react";
import ReactPlayer from "react-player";

const LiveFeed = ({ show, cameraAngles }) => {
  const imgLink =
    show == 0
      ? cameraAngles?.TopView
      : show == 1
      ? cameraAngles?.RightView
      : cameraAngles?.LeftView;
  return (
    <div className={`flex flex-col w-full  h-[33vh]  gap-1 `}>
      <div className="w-full h-6vh  flex justify-between  items-center  ">
        <p className="text-[#605D64] text-[13px]">
          {" "}
          {show == 0
            ? "Live feed - Top View "
            : show == 1
            ? "Live feed - Right side view"
            : "Live feed -  Left side view"}
        </p>
      </div>
      <div className={`w-full h-full`}>
        {imgLink === "" ? (
          <Skeleton width="100%" height="100%" />
        ) : (
          <img src={imgLink} alt="" style={{ width: "100%", height: "27vh" }} />
        )}
      </div>
    </div>
  );
};

export default LiveFeed;
