import axios from "axios";
import { baseURL } from "../../../";
import NavContext from "../../NavContext";
import { useContext, useEffect, useState } from "react";
import { Avatar, Tooltip } from "@chakra-ui/react";
import AutorenewIcon from "@mui/icons-material/Autorenew";

const TopBanner = ({ firstTime = false }) => {
  const { auth } = useContext(NavContext);
  const [projectCount, setProjectCount] = useState({});
  const [user, setUser] = useState({});
  const eleMap = {
    requiresAction: (
      <div className="w-fit">
        <img src="/selfServiceIcons/fail.svg" alt="fail" className="w-6 h-6" />
      </div>
    ),
    inProgress: (
      <div className="w-fit">
        <AutorenewIcon
          sx={{
            color: "#FFC107",
            width: "24px",
            height: "24px",
          }}
        />
      </div>
    ),
    ready: (
      <div className="w-fit">
        <img src="/selfServiceIcons/live.svg" alt="live" className="w-6 h-6" />
      </div>
    ),
  };
  const headingMap = {
    requiresAction: "Action required",
    inProgress: "In progress",
    ready: "Ready",
  };
  const intro = [
    {
      title: "Upload data set",
      desc: "Upload image, video, text",
      icon: "/selfServiceIcons/uploadData.svg",
    },
    {
      title: "Train and Test",
      desc: "Train and Test model on the data set",
      icon: "/selfServiceIcons/trainModel.svg",
    },
    {
      title: "Insights",
      desc: "View model insights",
      icon: "/selfServiceIcons/testModel.svg",
    },
  ];

  const getUserApi = async () => {
    try {
      const response = await axios.get(baseURL + "user", {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      if (response?.data) {
        setUser(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getProjectCount = async () => {
    try {
      const response = await axios.get(
        baseURL + "selfserve/v1/analytics/v1/project/counts/",
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      setProjectCount(response?.data?.counts);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProjectCount();
    getUserApi();
  }, []);

  const bannerType = {
    true: (
      <div
        className="flex flex-col gap-4 px-6 pt-6 pb-8 rounded-lg bg-[#084298] relative"
        style={{
          boxShadow:
            "-4px -4px 24px 0px rgba(0, 0, 0, 0.07), 4px 4px 24px 0px rgba(0, 0, 0, 0.07)",
        }}
      >
        <p className="text-[#FAFAFA] font-bold text-2xl flex flex-col md:flex-row gap-2">
          Welcome to <p className="text-[#C0FFFF]">AI Project Sandbox !</p>
        </p>
        <div className="flex flex-col gap-4 whitespace-nowrap">
          <p className="text-[#FAFAFA] text-base whitespace-normal w-[80%]">
            Maximize your AI projects with Ripik's platform. Bring your data,
            use our models, or test industry standards for quick, tailored
            insights. Empowering innovation and quick decision-making.
          </p>
          <div className="flex flex-col lg:flex-row gap-5 lg:gap-[54px] items-start lg:items-center">
            {intro.map((item) => {
              return (
                <div className="flex gap-3 items-center">
                  <img src={item.icon} alt={item.title} />
                  <div className="flex flex-col gap-0">
                    <p className="text-[#C0FFFF] text-base font-bold">
                      {item.title}
                    </p>
                    <p className="text-sm text-[#FAFACCCC]">{item.desc}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="absolute top-1 sm:top-5 right-1 sm:right-5 flex flex-col gap-2">
          <p className="px-3 py-[2px] rounded bg-[#FFFFD8] flex gap-4 items-center text-[#605D64] text-sm">
            Current Balance{" "}
            <span className="flex gap-1 items-center text-[#3E3C42] text-base font-medium">
              2000 <img src="/token.svg" alt="token" />
            </span>
          </p>
          <p className="self-end flex gap-4 px-3 items-center text-[#EBEBEB] text-sm">
            Total Spent{" "}
            <span className="flex gap-1 items-center text-[#FAFAFA] text-base font-medium">
              1000 <img src="/token.svg" alt="token" />
            </span>
          </p>
        </div>
      </div>
    ),
    false: (
      <div
        className="flex p-4 rounded-xl bg-[#084298] gap-8 sm:gap-[50px] h-fit"
        style={{
          boxShadow:
            "-4px -4px 24px 0px rgba(0, 0, 0, 0.07), 4px 4px 24px 0px rgba(0, 0, 0, 0.07)",
        }}
      >
        {/*profile*/}
        <div className="flex flex-col gap-3 justify-between h-full whitespace-nowrap">
          <p className="text-white text-base font-medium">
            Welcome {user?.username}
          </p>
          <Avatar
            name={user?.username}
            src={user?.imageurl}
            width={"70px"}
            height={"70px"}
          />
        </div>
        {/*summary*/}
        <div className="flex flex-col gap-3 h-full">
          <p className="text-[#FFF] text-base font-medium">Your summary</p>
          <div className="flex flex-wrap sm:flex-nowrap gap-3 items-center whitespace-nowrap">
            {Object.entries(projectCount)?.map((item) => {
              return (
                <div className="flex h-full gap-4 p-2 w-fit rounded">
                  {eleMap[item[0]]}
                  <div className="flex flex-col gap-[2px]">
                    <p className="text-white text-2xl font-medium">{item[1]}</p>
                    <p className="text-white text-sm">{headingMap[item[0]]}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    ),
  };

  return bannerType[firstTime];
};

export default TopBanner;
