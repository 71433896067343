import React from "react";
import { Skeleton } from "@chakra-ui/react";
import TableSkeleton from "./TableSkeleton";

const LiveViewSkeleton = () => {
  return (
    <div className="flex flex-col lg:flex-row bg-white px-6 py-0  gap-6 rounded-xl">
      <div className="my-4 items-center overflow-x-auto rounded-md bg-[#FAFAFA]  w-[100%] lg:w-[45%] xl:w-[30%]">
        <div className="flex flex-col gap-4 py-3 px-6 w-full">
          <div className="flex flex-col sm:flex-row gap-6 items-center">
            <div className="flex flex-col h-full w-full items-center gap-2">
              <div className="w-full">
                <Skeleton height="20px" width="100%" />
              </div>
              <div className="w-full h-full flex flex-col gap-1">
                {/* Board Number Live Image */}
                <div className="h-full w-full flex justify-center items-center rounded-md">
                  <Skeleton height={["150px", "130px", "110px"]} width="100%" />
                </div>
                {/* Pallet Belt Live Image  */}
                <div className="h-full w-full flex justify-center items-center rounded-md">
                  <Skeleton height="180px" width="100%" />
                </div>
                <div className="flex flex-row gap-1 items-center justify-end">
                  <Skeleton height="12px" width="30%" />
                  <Skeleton height="12px" width="30%" />
                </div>
              </div>
              <div className="w-full">
                <Skeleton height="20px" width="100%" />
              </div>
              <div className="flex flex-col gap-4 h-full w-full">
                <div className="flex flex-row gap-4 items-center">
                  <Skeleton height="12px" width="30%" />
                  <Skeleton height="20px" width="100%" />
                </div>
                <div className="flex flex-row gap-4 items-center">
                  <Skeleton height="12px" width="30%" />
                  <Skeleton height="20px" width="100%" />
                </div>
                <div className="flex flex-row gap-4 items-center">
                  <Skeleton height="12px" width="30%" />
                  <Skeleton height="20px" width="100%" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5 md:pt-0 gap-4 my-0 items-center rounded-md w-[100%] lg:w-[55%] xl:w-[70%] mb-6">
        <div className="overflow-hidden">
          <div className="w-full h-full flex flex-col gap-5 mt-2">
            <div className="flex flex-col lg:flex-row items-center w-full justify-between gap-4 lg:gap-0 lg:h-[65px]">
              <div className="flex flex-row w-[100%] lg:w-[40%] justify-start">
                <Skeleton height="30px" width="70%" />
              </div>
              <div className="flex flex-row gap-4 items-center justify-end w-[100%] lg:w-[60%]">
                <Skeleton height="30px" width="20%" />
                <Skeleton height="50px" width="100%" />
              </div>
            </div>
            {/* Summary Table */}
            <TableSkeleton
              headers={[
                "Header 1",
                "Header 2",
                "Header 3",
                "Header 4",
                "Header 5",
              ]}
              rows={3}
              cellsPerRow={5}
            />
            {/* Overall Table */}
            <TableSkeleton
              headers={[
                "Header 1",
                "Header 2",
                "Header 3",
                "Header 4",
                "Header 5",
              ]}
              rows={5}
              cellsPerRow={5}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LiveViewSkeleton;
