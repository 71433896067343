import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const MuiTheme = createTheme();

const AdminTransactionsTable = ({ rowData }) => {
  const columns = [
    {
      field: "transactionId",
      headerName: "SR No.",
    },
    {
      field: "userName",
      headerName: "user name",
    },
    {
      field: "transactionDate",
      headerName: "date time",
      sortComparator: (v1, v2, row1, row2) => {
        // Custom sorting logic for date strings
        const date1 = new Date(v1);
        const date2 = new Date(v2);

        if (date1 < date2) {
          return -1;
        }
        if (date1 > date2) {
          return 1;
        }
        return 0;
      },

      valueGetter: (params) =>
        params.row.transactionDate
          ? new Date(params.row.transactionDate).toDateString().split(" ")[2] +
            " " +
            new Date(params.row.transactionDate).toDateString().split(" ")[1] +
            " '" +
            new Date(params.row.transactionDate)
              .toDateString()
              .split(" ")[3]
              .slice(2, 4) +
            " " +
            new Date(params.row.transactionDate).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            })
          : "",
    },
    {
      field: "description",
      headerName: "description",
    },
    {
      field: "amount",
      headerName: "amount",
      // renderCell: ({ row }) => {
      //   return (
      //     <div className="w-full flex gap-1 ">
      //       <p>₹</p>
      //       {row.amount}
      //       {/* <img src="/token.svg" alt="coins" /> */}
      //     </div>
      //   );
      // },
    },
    {
      field: "token",
      headerName: "ripik tokens",
      renderCell: ({ row }) => {
        const isPurchase = row.transactionType === "SPENT_TOKEN";
        const tokenColor = isPurchase ? "text-black" : "text-[#7AC958]";

        const tokenSymbol = isPurchase ? "-" : "+";

        return (
          <div className={`w-full flex gap-1 ${tokenColor}`}>
            <span className="text-sm">{tokenSymbol}</span>
            {row.token}
            <img src="/token.svg" alt="coins" />
          </div>
        );
      },
    },
    {
      field: "afterBalance",
      headerName: "balance after transaction",
      renderCell: ({ row }) => {
        return (
          <div className="w-full flex gap-1 ">
            {row.afterBalance}
            <img src="/token.svg" alt="coins" />
          </div>
        );
      },
    },
  ];
  const headerClass =
    "text-sm font-normal text-[#79767D] bg-[#DDEEFF] capitalize";
  const cellClass = "text-sm font-normal text-[#3E3C42] whitespace-nowrap";
  const flexMap = [0, 2, 2, 3, 1, 1, 2];
  columns.map((val, idx) => {
    val["headerClassName"] = headerClass;
    val["cellClassName"] = cellClass;
    val["flex"] = flexMap[idx];
  });

  return (
    <div className="overflow-x-auto mt-2">
      <ThemeProvider theme={MuiTheme}>
        <DataGrid
          rows={rowData}
          columns={columns}
          getRowId={(row) => row.transactionId}
          columnVisibilityModel={{
            transactionId: false,
          }}
          initialState={{
            sorting: {
              sortModel: [{ field: "transactionDate", sort: "desc" }],
            },
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          //   hideFooter={true}
          sx={{ minWidth: "1000px" }}
        />
      </ThemeProvider>
    </div>
  );
};

export default AdminTransactionsTable;
