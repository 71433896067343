export const capitalizeCamelCaseString = (input) => {
  if (typeof input !== "string") {
    throw new Error("Input should be a string");
  }

  const words = input.split(/(?=[A-Z])/).join(" ");

  const capitalizedWords = words
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return capitalizedWords;
};

export const convertMapToArray = (map1) => {
  const resultArray = [];
  if (map1.size > 0) {
    resultArray.push(
      ...Array.from(map1.values()).map((obj) => ({
        ...obj,
      }))
    );
  }
  return resultArray;
};
