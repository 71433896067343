import { useWindowSize } from "@uidotdev/usehooks";
import ReactApexChart from "react-apexcharts";

export const Linechart = ({ chart }) => {
  const size = useWindowSize();
  const width = size.width < 768 ? 700 : "100%";
  return (
    <div
      id="chart"
      className={`h-full w-full ${
        width === 700 ? "overflow-x-scroll" : "overflow-hidden"
      }`}
    >
      <ReactApexChart
        options={chart.options}
        series={chart.series}
        height={"100%"}
        width={width}
      />
    </div>
  );
};
