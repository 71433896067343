import { AddIcon } from "@chakra-ui/icons";
import { Button, useToast } from "@chakra-ui/react";
import { useContext, useState } from "react";
import { AddSubscriptionsModal } from "../Modals/AddSubscriptionsModal";
import { AddBulkUsersModal } from "../Modals/AddBulkUsersModal";
import axios from "axios";
import { baseURL } from "../../..";
import NavContext from "../../NavContext";

const ActiveSubsFilters = ({
  organisation,
  handleStatus,
  setChangingbutton,
  changingbutton,
  fetchTableData,
  setSubHistoryChange,
  issubscriptionHistoryChanged,
  mode,
  clientOrg,
}) => {
  const { auth } = useContext(NavContext);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isOpenB, setIsOpenB] = useState(false);
  const [send, setSend] = useState([]);
  const toast = useToast();

  const onCloseB = () => {
    setIsOpenB(false);
  };
  const onClose = () => {
    setIsAddModalOpen(false);
  };

  const [downloadBulk, setDownloadBulk] = useState([
    {
      "CLIENT NAME": "Steel Compnay",
      LOCATION: "Pune",
      PRODUCT: "Sinter Sizing",
      "PRODUCT CATEGORY": "Vision",
      "SUBSCRIPTION END DATE": "22-Dec-25",
    },
  ]);
  const [order, setOrder] = useState([
    "CLIENT NAME",
    "LOCATION",
    "PRODUCT",
    "PRODUCT CATEGORY",
    "SUBSCRIPTION END DATE",
  ]);

  const postBulkUpload = async () => {
    // console.log("File details:", send[0]);

    // const param = { organisation: clientOrg || "" };

    try {
      let requestBody = new FormData();
      requestBody.append("file", send[0]);
      const response = await axios.post(
        baseURL + "subscription/bulkUpload",
        requestBody,

        {
          // params: param,
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Auth-Token": auth,
          },
        }
      );
      fetchTableData();
      toast({
        title: `File has been uploaded`,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    } catch (error) {
      const errMessage = error?.response?.data?.data;
      toast({
        title: [errMessage],
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      console.log(error?.response?.data?.data);
    }
  };
  return (
    <div className="flex justify-center xl:justify-end xl:w-1/2 gap-3">
      <div className="mt-2 xl:mt-0">
        {((organisation === "Ripik.Ai" && mode != "view") ||
          (organisation !== "Ripik.Ai" && mode === "update")) && (
          <div className="ml-2 sm:ml-0">
            <Button
              onClick={() => setIsAddModalOpen(true)}
              className="!border-0 !text-[#1C56AC] !text-sm gap-1 !bg-white "
            >
              <AddIcon />
              <span>Add subscription</span>
            </Button>
            <Button
              onClick={() => setIsOpenB(true)}
              className="!border-0 !text-[#1C56AC] !text-sm gap-1 !bg-white"
            >
              <AddIcon />
              <span>Bulk add subscriptions</span>
            </Button>
          </div>
        )}

        <div className="xl:w-full flex justify-center xl:justify-between mt-2">
          {/* filter button */}
          <div className="flex justify-start rounded-l-md ">
            <button
              className={`w-[50px] xl:w-[80px] flex justify-center items-center rounded-l-md ${
                changingbutton == "ACTIVE"
                  ? " text-[#605D64] border-[#FFC107] bg-[#FFFFED]"
                  : "border-[#EBEBEB]"
              } border xl:px-[16px] `}
              onClick={() => handleStatus("ACTIVE")}
            >
              <p className="text-[#605D64]">Active</p>
            </button>
            <button
              className={`w-[70px] xl:w-[100px] flex justify-center items-center  ${
                changingbutton == "MODIFIED"
                  ? " text-[#605D64] border-[#FFC107] bg-[#FFFFED]"
                  : "border-[#EBEBEB]"
              } border  px-[16px]`}
              onClick={() => handleStatus("MODIFIED")}
            >
              <p className="text-[#605D64]">Modified</p>
            </button>
            <button
              className={`w-[70px] xl:w-[100px] flex justify-center items-center ${
                changingbutton == "INACTIVE"
                  ? " text-[#605D64] border-[#FFC107] bg-[#FFFFED]"
                  : "border-[#EBEBEB]"
              } border   xl:px-[16px]`}
              onClick={() => handleStatus("INACTIVE")}
            >
              <p className="text-[#605D64]">Inactive</p>
            </button>
            <button
              className={`w-[70px] xl:w-[80px] flex justify-center items-center${
                changingbutton == "starred"
                  ? " text-[#605D64] border-[#FFC107] bg-[#FFFFED]"
                  : "border-[#EBEBEB]"
              } border  `}
              onClick={() => handleStatus("starred")}
            >
              <p className="text-[#605D64]">Starred</p>
            </button>
            <button
              className={`w-[40px] xl:w-[70px] flex justify-center items-center rounded-r-md ${
                changingbutton == "all"
                  ? " text-[#605D64] border-[#FFC107] bg-[#FFFFED]"
                  : "border-[#EBEBEB]"
              } border px-[10px] `}
              onClick={() => handleStatus("all")}
            >
              <p className="text-[#605D64]">All</p>
            </button>
          </div>
        </div>
      </div>
      {isAddModalOpen && organisation === "Ripik.Ai" && (
        <AddSubscriptionsModal
          isOpen={isAddModalOpen}
          onClose={() => {
            setIsAddModalOpen(false);
          }}
          fetchTableData={fetchTableData}
          setSubHistoryChange={setSubHistoryChange}
          issubscriptionHistoryChanged={issubscriptionHistoryChanged}
          clientOrg={clientOrg}
        />
      )}
      {organisation === "Ripik.Ai" && (
        <>
          <AddBulkUsersModal
            isOpen={isOpenB}
            onClose={onCloseB}
            size={"3xl"}
            setSend={setSend}
            send={send}
            postBulkUpload={postBulkUpload}
            data={downloadBulk}
            order={order}
            mode={"subscriptions"}
            headertext="Subscriptions"
            buttontext="Add"
          />
        </>
      )}
    </div>
  );
};

export default ActiveSubsFilters;
