import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
  Skeleton,
  InputGroup,
  InputLeftAddon,
  Input,
} from "@chakra-ui/react";
import StarIcon from "@mui/icons-material/Star";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useEffect, useRef, useState } from "react";
import ReactCardFlip from "react-card-flip";
import DisplayImage from "../../utils/DisplayImage";
import LabelBar from "../../../Charts/SelfService/LabelBar";
import Paginator from "../../../../util/VisionUtils/Paginator";
import LabelScatterPlot from "../../../Charts/SelfService/LabelScatterPlot";
import ClearIcon from "@mui/icons-material/Clear";

export const CalMax = (data) => {
  let values = data?.map((item) => {
    return item?.precision;
  });
  return Math.max(...values);
};

const FlipCard = ({ data }) => {
  const [flip, setFlip] = useState(
    data.hasOwnProperty("annotationData") ? true : false
  );
  return (
    <div className="w-full relative">
      <ReactCardFlip isFlipped={flip} flipDirection="horizontal">
        <div className="w-full relative h-[128px] flex justify-center items-center bg-black rounded">
          <img
            src={data.img}
            alt="inputImage"
            className="w-auto max-h-full rounded"
            loading="lazy"
          />
          {!data.hasOwnProperty("annotatedData") && (
            <p className="absolute top-2 right-2 text-white text-xs p-1 rounded-lg bg-black bg-opacity-50">
              {data?.tag_name}
            </p>
          )}
        </div>
        {data.hasOwnProperty("annotationData") && (
          <div className="w-full h-[128px] flex justify-center items-center bg-black rounded">
            <DisplayImage
              width={128}
              height={128}
              url={data.img}
              annotationData={data.annotationData}
            />
          </div>
        )}
      </ReactCardFlip>
      <div className="absolute bottom-0 py-1 pl-2 pr-[10px] rounded bg-black bg-opacity-50 flex justify-between w-full text-xs text-[#FAFAFA]">
        {data.hasOwnProperty("annotationData") && (
          <img
            src="/selfServiceIcons/flip.svg"
            alt="flip"
            className="z-10 cursor-pointer hover:scale-105"
            onClick={() => setFlip((prev) => !prev)}
          />
        )}
        <div className="flex gap-1 items-center">
          <img
            alt="brain"
            src="/selfServiceIcons/brain.svg"
            className="w-4 h-5"
          />
          {data.hasOwnProperty("annotationData") ? (
            <p>{(CalMax(data?.annotationData) * 100)?.toFixed(2)}%</p>
          ) : (
            <p>{(data.precision * 100)?.toFixed(2)}%</p>
          )}
        </div>
      </div>
    </div>
  );
};

const ExpandModal = ({
  isOpen,
  closeModal,
  label,
  predictionData,
  userData,
  starred,
}) => {
  const sortVal = ["High to Low", "Low to High"];
  const [selectedSort, setSelectedSort] = useState("");
  const [data, setData] = useState({});
  const [minConf, setMinConf] = useState(0);
  const [displayData, setDisplayData] = useState([]);

  useEffect(() => {
    switch (selectedSort) {
      case "High to Low":
        setData((prev) => ({
          ...prev,
          [label]: {
            ...prev[label],
            img: [...prev[label]?.img]?.sort((a, b) => {
              if (a.hasOwnProperty("annotationData"))
                return CalMax(b.annotationData) - CalMax(a.annotationData);
              return b.precision - a.precision;
            }),
          },
        }));
        break;
      case "Low to High":
        setData((prev) => ({
          ...prev,
          [label]: {
            ...prev[label],
            img: [...prev[label]?.img]?.sort((a, b) => {
              if (a.hasOwnProperty("annotationData"))
                return CalMax(a.annotationData) - CalMax(b.annotationData);
              return a.precision - b.precision;
            }),
          },
        }));
        break;
      default:
        setData(predictionData);
        setMinConf(0);
        break;
    }
  }, [selectedSort]);

  useEffect(() => {
    if ([label] in data) {
      setData((prev) => ({
        ...prev,
        [label]: {
          ...prev[label],
          img: predictionData[label]?.img?.filter((item) => {
            let conf = item.hasOwnProperty("annotationData")
              ? (CalMax(item?.annotationData) * 100)?.toFixed(2)
              : (item.precision * 100)?.toFixed(2);
            return parseFloat(conf) >= parseFloat(minConf);
          }),
        },
      }));
    }
  }, [minConf, predictionData]);

  useEffect(() => {
    setData({ ...predictionData });
  }, [predictionData]);

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={closeModal}
      isCentered="true"
    >
      <ModalOverlay />
      <ModalContent style={{ borderRadius: "12px" }} maxW="1160px">
        <ModalBody
          px={"16px"}
          py={"20px"}
          display={"flex"}
          flexDirection={"column"}
          gap={"16px"}
        >
          <div className="flex items-center justify-between gap-2">
            <div className="flex gap-[6px] flex-col">
              <div className="flex gap-2 items-center">
                {starred && <StarIcon sx={{ color: "#FFC107" }} />}
                <p className="text-[#3E3C42] font-medium text-base">{label}</p>
              </div>
              <p className="text-[#79767D] text-sm">
                Total :{" "}
                <span className="text-[#3E3C42]">
                  {data[label]?.img?.length} Files
                </span>
              </p>
            </div>
            <div className="flex gap-1 flex-col sm:flex-row items-center">
              {selectedSort == "" ? (
                <Menu closeOnSelect={true}>
                  <MenuButton
                    as={Button}
                    rightIcon={<ArrowDropDownIcon />}
                    px={"4px"}
                    pl={"12px"}
                    pr={"8px"}
                    bg={"#EBEBEB4D"}
                    border={"1px solid #E0E0E0"}
                    color={"#938F96"}
                    fontSize={"14px"}
                    fontWeight={500}
                    _hover={{
                      backgroundColor: "#EBEBEB4D",
                    }}
                  >
                    Sort by
                  </MenuButton>
                  <MenuList minWidth="240px">
                    <MenuOptionGroup
                      defaultValue=""
                      title="Sort by"
                      type="radio"
                      color={"#AEA9B1"}
                      fontSize={"14px"}
                      fontWeight={500}
                      value={selectedSort}
                      onChange={setSelectedSort}
                    >
                      {sortVal.map((x) => {
                        return (
                          <MenuItemOption
                            value={x}
                            color={"#605D64"}
                            fontSize={"14px"}
                            p={"8px"}
                          >
                            Confidence: {x}
                          </MenuItemOption>
                        );
                      })}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              ) : (
                <div className="flex gap-2 px-1 pl-3 pr-2 bg-[#EEF5FF] border border-[#6CA6FC] rounded">
                  <p className="text-[#605D64] text-sm">
                    Confidence:{" "}
                    <span className="font-medium">{selectedSort}</span>
                  </p>
                  <ClearIcon
                    className="cursor-pointer hover:scale-105"
                    onClick={() => setSelectedSort("")}
                  />
                </div>
              )}
              <InputGroup width={"fit-content"}>
                <InputLeftAddon
                  bg={"#EBEBEB4D"}
                  color={"#938F96"}
                  fontSize={"14px"}
                  fontWeight={500}
                >
                  {"Confidence >="}
                </InputLeftAddon>
                <Input
                  type="number"
                  color={"#938F96"}
                  fontSize={"14px"}
                  fontWeight={500}
                  width={"50px"}
                  value={minConf}
                  onChange={(e) =>
                    setMinConf(
                      e.target.value > 100
                        ? 100
                        : e.target.value < 0
                        ? 0
                        : e.target.value
                    )
                  }
                />
              </InputGroup>
            </div>
            <img
              src="/selfServiceIcons/expanded.svg"
              alt="expanded"
              className="cursor-pointer hover:scale-105"
              onClick={closeModal}
            />
          </div>
          <div className="flex flex-col xl:flex-row gap-4 w-full">
            {data[label]?.img?.length > 0 ? (
              <>
                <div className="flex flex-col gap-1 items-end w-full xl:w-[65%]">
                  <Paginator
                    data={data[label]?.img}
                    limit={10}
                    setDisplayData={setDisplayData}
                  />
                  <div className="w-full grid grid-cols-2 xs:grid-cols-3 sm:grid-cols-4 md:grid-cols-5 max-h-[400px] overflow-y-auto h-fit gap-4">
                    {displayData?.map((item) => {
                      return <FlipCard data={item} />;
                    })}
                  </div>
                </div>
                <div className="flex flex-col gap-4 w-full xl:w-[35%]">
                  {/* <LabelBoxPlot data={dummy} />
              <Skeleton height={"200px"} width={"full"} /> */}
                  {data.hasOwnProperty(label) && (
                    <LabelBar data={data[label]?.img} />
                  )}
                  {data.hasOwnProperty(label) && (
                    <LabelScatterPlot data={data[label]?.img} />
                  )}
                </div>
              </>
            ) : (
              <p className="text-xl w-full flex justify-center items-center">
                No {label} with confidence {">= " + minConf + "%"} were detected
                kindly provide better annotations, better image quality or try
                lowering the confidence
              </p>
            )}
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ExpandModal;
