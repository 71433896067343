import {
  Select,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Input,
  useToast,
} from "@chakra-ui/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import NavContext from "../../NavContext";
import { CloseIcon } from "@chakra-ui/icons";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import BctContext from "../BctContext";
import { postConcApi } from "../BlendComplianceTracking";
import {
  Autocomplete,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";

const NewConcAddModal = ({ openModal, closeModal, func }) => {
  const newMaterialRef = useRef();
  const { conc, setConc } = useContext(BctContext);
  const toast = useToast();
  const { auth } = useContext(NavContext);
  const handleAdd = () => {
    let val = newMaterialRef?.current?.value;
    if (val?.trim() === "" || val?.length > 200) {
      toast({
        title: "Error",
        description: "Concentrate cannot be empty or greater than 200 chars",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (val) {
      let e = {
        target: {
          value: val,
        },
      };
      postConcApi(auth, [...conc, val], toast, setConc);
      func(e);
      closeModal();
    }
  };
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={openModal}
      onClose={closeModal}
      isCentered="true"
    >
      <ModalOverlay />
      <ModalContent style={{ borderRadius: "8px" }} maxW="360px">
        <ModalHeader
          py={"8px"}
          px={"16px"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          borderBottom={"1px solid #EBE2F0"}
        >
          <p className="text-[#605D64] text-sm font-medium">Add concentrate</p>
          <CloseIcon
            className="cursor-pointer hover:scale-105"
            onClick={closeModal}
            sx={{ color: "#605D64" }}
          />
        </ModalHeader>
        <ModalBody
          px={"24px"}
          py={"16px"}
          display={"flex"}
          flexDirection={"column"}
          gap={"12px"}
        >
          <Input ref={newMaterialRef} />
          <div className="self-end">
            <PrimaryButton
              text={"Save"}
              width={"fit-content"}
              onClick={handleAdd}
            />
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const ConcentrateSelect = ({ value, func }) => {
  const { conc, setConc } = useContext(BctContext);
  const uniqueEntries = Array.from(new Set(conc));
  const [addModal, setAddModal] = useState(false);
  const ref = useRef();
  const MuiTheme = createTheme();

  const handleChange = (e, val) => {
    if (val != "Others")
      func({
        target: {
          value: val ?? "",
        },
      });
    else {
      ref?.current?.querySelector("input")?.blur();
      setAddModal(true);
    }
  };

  return (
    <>
      {/* <Select
        value={value}
        border={"1px solid #CAC5CD"}
        onChange={handleChange}
      >
        <option value={""} key={"N/A"}>
          N/A
        </option>
        {conc.map((item) => {
          return (
            <option value={item} key={item}>
              {item}
            </option>
          );
        })}
        <option value={"Others"} key={"Others"}>
          Others
        </option>
      </Select> */}
      <ThemeProvider theme={MuiTheme}>
        <Autocomplete
          onChange={handleChange}
          disablePortal
          id="combo-box-demo"
          ref={ref}
          options={[...uniqueEntries, "Others"]}
          value={value === "" ? null : value}
          size="small"
          sx={{
            width: "auto",
            minWidth: "150px",
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              border: "1px solid #CAC5CD",
            },
            "& .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input": {
              fontSize: "15px",
            },
          }}
          ListboxProps={{
            style: {
              maxHeight: "250px",
              fontSize: "14px",
            },
          }}
          renderInput={(params) => <TextField {...params} label={""} />}
        />
      </ThemeProvider>
      {addModal && (
        <NewConcAddModal
          openModal={addModal}
          closeModal={() => setAddModal(false)}
          conc={conc}
          setConc={setConc}
          func={func}
        />
      )}
    </>
  );
};

export default ConcentrateSelect;
