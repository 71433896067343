// four case of gas flow rate
// 1. Very high
// 2. High
// 3. Acceptable
// 4. No

export const dummyInitializeData = {
  location: [
    {
      locationId: "vijaynagar",
      locationName: "VijayNagar",
      cameraGpIds: [
        {
          cameraGpId: "flareStack3", // id of camera
          cameraGpName: "Flare Stack 3",
          cameraInfo: [
            {
              cameraId: "opticalCam1",
              name: "Optical Camera-1",
            },
            {
              cameraId: "opticalCam2",
              name: "Optical Camera-2",
            },
            {
              cameraId: "thermalCam1",
              name: "Thermal Camera-1",
            },
            {
              cameraId: "thermalCam2",
              name: "Thermal Camera-2",
            },
          ],
        },
        {
          cameraGpId: "flareStack4", // id of camera
          cameraGpName: "Flare Stack 4",
          cameraInfo: [
            {
              cameraId: "opticalCam1",
              name: "Optical Camera-1",
            },
            {
              cameraId: "opticalCam2",
              name: "Optical Camera-2",
            },
            {
              cameraId: "thermalCam1",
              name: "Thermal Camera-1",
            },
            {
              cameraId: "thermalCam2",
              name: "Thermal Camera-2",
            },
          ],
        },
      ],
    },
  ],
};

export const dummyFeedData = {
  flareStack3: {
    opticalCam1: {
      image: "/flarestack/opticalimage.png",
      lastUpdated: 1707338022029,
    },
    opticalCam2: {
      image: "/flarestack/opticalimage.png",
      lastUpdated: 1707338022029,
    },
    thermalCam1: {
      image: "/flarestack/thermalimage.png",
      lastUpdated: 1707338022029,
    },
    thermalCam2: {
      image: "/flarestack/thermalimage.png",
      lastUpdated: 1707338022029,
    },
    gasFlowRate: "High", // low = 0, accepatable = 1, High = 2,
    lineChartData: {
      data: [720, 750, 730, 780, 800, 790, 750],
      times: [
        1707084000000, 1707087600000, 1707091200000, 1707094800000,
        1707098400000, 1707102000000, 1707105600000,
      ],
    },
  },
  flareStack4: {
    opticalCam1: {
      image: "/flarestack/opticalimage.png",
      lastUpdated: 1707338022029,
    },
    opticalCam2: {
      image: "/flarestack/opticalimage.png",
      lastUpdated: 1707338022029,
    },
    thermalCam1: {
      image: "/flarestack/thermalimage.png",
      lastUpdated: 1707338022029,
    },
    thermalCam2: {
      image: "/flarestack/thermalimage.png",
      lastUpdated: 1707338022029,
    },
    gasFlowRate: "Acceptable", // low = 0, accepatable = 1, High = 2,
    lineChartData: {
      data: [720, 750, 730, 780, 800, 790, 750],
      times: [
        1707084000000, 1707087600000, 1707091200000, 1707094800000,
        1707098400000, 1707102000000, 1707105600000,
      ],
    },
  },
};

export const dummyAlertsData = {
  columns: [
    "startTime",
    "endTime",
    "flareNumber",
    "flareVolume",
    "averageVolume",
  ],

  data: [
    {
      id: "99c8ba96-dff0-40f1-9b34-6db251eb503b",
      startTime: 1720612607858,
      endTime: 1720612607858,
      flareNumber: 3,
      flareVolume: "High",
      averageVolume: 200,
    },
    {
      id: "5f6ad02d-e06c-40ad-9fb9-71719c09f245",
      startTime: 1720612607923,
      endTime: 1720612607923,
      flareNumber: 4,
      flareVolume: "Low",
      averageVolume: 200,
    },
    {
      id: "a1233dd4-49fa-453f-a616-c0e58ea6cf33",
      startTime: 1720612608707,
      endTime: 1720612608707,
      flareNumber: 3,
      flareVolume: "No",
      averageVolume: 200,
    },

    {
      id: "b1f9a814-fdd1-4538-8e85-ab6870c4cf9c",
      startTime: 1720612608603,
      endTime: 1720612608603,
      flareNumber: 3,
      flareVolume: "Acceptable",
      averageVolume: 200,
    },
  ],
};

export const dummyDetailModalData = {
  cameraGpId: "flareStack3",
  startTime: 1707338022029,
  endTime: 1707338022029,
  gasFlowRate: "High",
  opticalCam1: {
    video:
      "https://intelliverse-public-videos.s3.ap-south-1.amazonaws.com/videos/flarestackVideo.mp4",
  },
  opticalCam2: {
    video:
      "https://intelliverse-public-videos.s3.ap-south-1.amazonaws.com/videos/flarestackVideo.mp4",
  },
  thermalCam1: {
    video:
      "https://intelliverse-public-videos.s3.ap-south-1.amazonaws.com/videos/flarestackVideo.mp4",
  },
  thermalCam2: {
    video:
      "https://intelliverse-public-videos.s3.ap-south-1.amazonaws.com/videos/flarestackVideo.mp4",
  },
  lineChartData: dummyFeedData.flareStack3.lineChartData,
};

export const dummyPhotoGalleryData = [
  {
    cameraName: "Optical Camera 1",
    timeStamp: 1707084000000,
    flareVolume: "Low Flame",
    image: "/flarestack/opticalimage.png",
  },
  {
    cameraName: "Optical Camera 2",
    timeStamp: 1707084000000,
    flareVolume: "Low Flame",
    image: "/flarestack/opticalimage.png",
  },
  {
    cameraName: "Thermal Camera 1",
    timeStamp: 1707084000000,
    flareVolume: "Acceptable Flame",
    image: "/flarestack/thermalimage.png",
  },
  {
    cameraName: "Thermal Camera 2",
    timeStamp: 1707084000000,
    flareVolume: "High Flame",
    image: "/flarestack/thermalimage.png",
  },
  {
    cameraName: "Optical Camera 1",
    timeStamp: 1707084000000,
    flareVolume: "Low Flame",
    image: "/flarestack/opticalimage.png",
  },
];

export const dummyReportData = {
  columns: [
    "startTime",
    "endTime",
    "flareStack",
    "percentageHighFlare",
    "alertsCount",
    "averageVolume",
  ],
  data: [
    {
      id: 1,
      startTime: 1720607417,
      endTime: 1720607933,
      flareStack: 3,
      percentageHighFlare: 93.72,
      alertsCount: 18,
      averageVolume: 500,
    },
    {
      id: 2,
      startTime: 1720657529,
      endTime: 1720663191,
      flareStack: 4,
      percentageHighFlare: 89.96,
      alertsCount: 18,
      averageVolume: 500,
    },
    {
      id: 3,
      startTime: 1720645359,
      endTime: 1720646486,
      flareStack: 4,
      percentageHighFlare: 69.52,
      alertsCount: 4,
      averageVolume: 500,
    },
    {
      id: 4,
      startTime: 1720628260,
      endTime: 1720632893,
      flareStack: 4,
      percentageHighFlare: 40.25,
      alertsCount: 19,
      averageVolume: 500,
    },
    {
      id: 5,
      startTime: 1720620842,
      endTime: 1720625699,
      flareStack: 3,
      percentageHighFlare: 40.67,
      alertsCount: 14,
      averageVolume: 500,
    },
  ],
};

export const dummyAnalyticsData = {
  donutChartData: [
    { name: "Very High Flare", data: 10 },
    { name: "High Flare", data: 15 },
    { name: "Acceptable Flare", data: 40 },
    { name: "Low Flare", data: 35 },
  ],
  flareData: {
    times: [
      1706745600000, 1709424000000, 1712016000000, 1714694400000, 1717286400000,
      1719964800000,
    ],
    data: [
      // for stack column chart data
      {
        name: "Very High Flare",
        data: [32, 5, 24, 10, 44, 16],
      },
      {
        name: "High Flare",
        data: [44, 55, 41, 67, 22, 43],
        times: [],
      },
      {
        name: "Acceptable Flare",
        data: [13, 23, 20, 8, 13, 27],
      },
      {
        name: "Low Flare",
        data: [11, 17, 15, 15, 21, 14],
      },
    ],
  },
  alertsData: {
    times: [
      1706745600000, 1709424000000, 1712016000000, 1714694400000, 1717286400000,
      1719964800000, 1722556800000, 1725235200000, 1727827200000,
    ],

    data: [
      {
        name: "High Alert Count",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
      },
    ],
  },
  tableData: {
    columns: [
      "startTime",
      "endTime",
      "flareNumber",
      "flareVolume",
      "averageVolume",
    ],
    data: [
      {
        id: "99c8ba96-dff0-40f1-9b34-6db251eb503b",
        startTime: 1720612607858,
        endTime: 1720612607858,
        flareNumber: 3,
        flareVolume: "High",
        averageVolume: 300,
      },
      {
        id: "5f6ad02d-e06c-40ad-9fb9-71719c09f245",
        startTime: 1720612607923,
        endTime: 1720612607923,
        flareNumber: 4,
        flareVolume: "Low",
        averageVolume: 300,
      },
      {
        id: "a1233dd4-49fa-453f-a616-c0e58ea6cf33",
        startTime: 1720612608707,
        endTime: 1720612608707,
        flareNumber: 3,
        flareVolume: "High",
        averageVolume: 300,
      },
      {
        id: "79daa417-0901-4059-a039-136a149431ad",
        startTime: 1720612608671,
        endTime: 1720612608671,
        flareNumber: 4,
        flareVolume: "High",
        averageVolume: 300,
      },
      {
        id: "b1f9a814-fdd1-4538-8e85-ab6870c4cf9c",
        startTime: 1720612608603,
        endTime: 1720612608603,
        flareNumber: 3,
        flareVolume: "Acceptable",
        averageVolume: 300,
      },
    ],
  },
};
