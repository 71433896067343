import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";
import { BiasRecommender } from "./Tabs/BiasRecommender";
import { Report } from "./Tabs/Report";
import { QualityMatrix } from "./Tabs/QualityMatrix";
import { Advanced } from "./Tabs/Advanced";
import Iba from "./Tabs/Iba";
import { mixpanelTrackTabs } from "../../util/mixpanelFunctions";

/*------------------------ BASE URL FOR Mulpic -----------------------*/

export const mulpicJsplBaseUrl = process.env.REACT_APP_MULPIC_JSPL_BASE_URL;

/*------------------------ BASE URL FOR Mulpic  -----------------------*/

const Mulpic = () => {
  const size = useWindowSize();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlParamTabIndex = !isNaN(searchParams.get("tabIndex"))
    ? Number(searchParams.get("tabIndex"))
    : 0;
  const [plantMap, setPlantMap] = useState(["angul"]);

  // handling tab change
  const handleTabsChange = (index) => {
    if (index !== urlParamTabIndex) {
      setSearchParams({ tabIndex: index }, { replace: true });
    }
  };

  // tablist
  const tabs = [
    {
      label: "MULPIC Recommender",
      index: 0,
      component: (
        <BiasRecommender
          plantMap={plantMap}
          plantId="All Plants"
        />
      ),
    },
    {
      label: "Quality Matrix",
      index: 1,
      component: (
        <QualityMatrix
          plantMap={plantMap}
          plantId="All Plants"
        />
      ),
    },
    {
      label: "Advanced",
      index: 2,
      component: (
        <Advanced
          plantMap={plantMap}
          plantId="All Plants"
        />
      ),
    },
    {
      label: "Report",
      index: 3,
      component: (
        <Report
          plantMap={plantMap}
          plantId="All Plants"
          disable={false}
        />
      ),
    },
    {
      label: "IBA",
      index: 4,
      component: (
        <Iba
          plantMap={plantMap}
          plantId="All Plants"
          disable={false}
        />
      ),
    },
  ];
  useEffect(() => {
    const originalTitle = document.title;
    document.title = "Mulpic | IntelliVerse";
    return () => {
      document.title = originalTitle;
    };
  }, []);
  // use effect to look for change in tab-index
  useEffect(() => {
    handleTabsChange(urlParamTabIndex);
    const tabName = tabs?.find(item => item.index == urlParamTabIndex)
    mixpanelTrackTabs(tabName?.label?.toLowerCase(), "Mulpic"); // track tabs
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlParamTabIndex]);

  return (
    <div
      className="px-2 md:px-5 font-poppins flex flex-col rounded-lg h-full"
      style={{ width: size.width >= 768 ? "calc(100vw - 168px)" : "100vw" }}
    >
      <div
        className="fixed top-5 right-0 h-[30px] w-[180px] bg-white"
        style={{ zIndex: 1000 }}
      >
        {/* <img
          className="absolute h-[50px] w-[120px] top-[-20px] right-2"
          src="/clientLogos/.png"
          alt="clientLogo"
        /> */}
      </div>
      <div className="flex justify-start gap-2 items-center mb-3 mt-6">
        <Link
          to={`/optimus/mulpic`}
          style={{
            textDecoration: "none",
          }}
        >
          <img
            src="/backtick.svg"
            alt="backtick"
          />
        </Link>
        <p className="text-lg sm:text-2xl font-semibold text-[#024D87]">
          {"MULPIC"}
        </p>
      </div>
      <Tabs
        // isLazy={true}
        index={urlParamTabIndex}
        onChange={handleTabsChange}
        paddingRight={{ base: "8px", md: "0px", lg: "0px" }}
      >
        <TabList className="!flex !border-0">
          <div className="flex items-center gap-4 overflow-x-auto h-14 md:h-10">
            {tabs &&
              tabs.map((tab, index) => (
                <Tab
                  key={`${tab.label}-${index}`}
                  minW={"fit-content"}
                  className={`${urlParamTabIndex === tab.index
                      ? "!text-black !text-xs sm:!text-sm !bg-white rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#79767D]"
                      : "!text-xs sm:!text-sm !text-[#938F96] !border-0"
                    }`}
                  onClick={() => handleTabsChange(tab.index)}
                >
                  {tab.label}
                </Tab>
              ))}
          </div>
        </TabList>
        <TabPanels>
          {tabs &&
            tabs.map((tab, index) => (
              <TabPanel
                key={`${tab.label}-${index}`}
                className="!pl-0 !pr-0 h-full"
              >
                {tab.component}
              </TabPanel>
            ))}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default Mulpic;
