import * as React from "react";
import EditIcon from "@mui/icons-material/Edit";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowModes,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from "@mui/x-data-grid";
import {
  CustomStyledDataGrid,
  CustomToolbar,
} from "../../../util/MaterialDataGrid/CustomStyledDatagrid";
import { updateBoardNumberApi } from "../services/statusServices";
import { useToast } from "@chakra-ui/react";
import { capitalizeCamelCaseString } from "../utilis/dateTimeFormatter";

export const CustomEditableDataGrid = ({ auth, rows, setRows, clientId }) => {
  const toast = useToast();
  const [loading, setLoading] = React.useState(false);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const getRowId = (row) => {
    return row.recordId;
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow?.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = async (newRow, originalRow) => {
    try {
      const keysToAvoid = ["recordId", "sNo", "isNew"];
      for (let key in newRow) {
        if (!keysToAvoid.includes(key) && newRow[key]?.trim() === "") {
          throw new Error(
            `${capitalizeCamelCaseString(key)} field can't be empty`
          );
        }
      }
      const requestBody = JSON.stringify({
        clientId: clientId,
        plantName: "chanderia",
        useCase: "SINTERBELT",
        sNo: newRow?.sNo,
        id: newRow?.recordId,
        board: newRow?.board?.trim(),
        secondRowDivider: newRow?.secondRowDivider?.trim(),
      });
      const updatedRow = { ...newRow, isNew: false };
      const apiResponse = await updateBoardNumberApi(
        auth,
        requestBody,
        setLoading
      );
      if (apiResponse?.success) {
        setRows(
          rows.map((row) =>
            row.recordId === newRow.recordId ? updatedRow : row
          )
        );
        toast({
          title: apiResponse?.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        return updatedRow;
      } else {
        throw new Error("Please try again...");
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Error while updating",
        description: error?.message || "",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return originalRow;
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns = [
    {
      field: "sNo",
      headerName: "S. No.",
      flex: 1,
      minWidth: 80,
    },
    {
      field: "board",
      headerName: "Board Number",
      editable: true,
      flex: 2,
      minWidth: 150,
    },
    {
      field: "secondRowDivider",
      headerName: "Second Row Divider",
      editable: true,
      flex: 2,
      minWidth: 150,
      type: "singleSelect",
      valueOptions: ["L", "R"],
      valueGetter: ({ value }) => {
        if (!value) {
          return "";
        }
        return value;
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 80,
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  return (
    <div className="w-full">
      <div id="set-sequence-table-filter-panel"></div>
      <div className="w-full h-[60vh] border border-gray-200 rounded-md">
        <CustomStyledDataGrid
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          getRowId={getRowId}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          disableColumnMenu={true}
          disableRowSelectionOnClick={true}
          rowHeight={45}
          columnHeaderHeight={45}
          loading={loading}
          hideFooter={true}
          pageSizeOptions={[60]}
          onCellDoubleClick={(params, event) => {
            event.defaultMuiPrevented = true;
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: { toolBarId: "set-sequence-table-filter-panel" },
          }}
        />
      </div>
    </div>
  );
};
