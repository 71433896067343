import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";

const checkColoredBias = (data) => {
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const element = data[key];
      if (element.flag !== undefined && element.flag) {
        return true;
      }
    }
  }
  return false;
};
const SpeedCurveBias = ({ speedCurveBiasData }) => {
  const columns = ["initial_speed", "final_speed", "curve_shift", "max_speed"];
  let isColored = speedCurveBiasData && checkColoredBias(speedCurveBiasData);
  // console.log(isColored);
  return (
    <div
      className="flex w-full flex-col gap-1 rounded-md border pt-1 shadow-md"
      style={{
        border: isColored ? "1px solid #9ed8ff" : "1px solid #E2E8F0",
        backgroundColor: isColored ? "#FAFAFA" : "white",
      }}
    >
      <p className="text-left px-4 text-base font-medium">Speed Curve Bias</p>
      <div className="overflow-x-scroll">
        <Table size={"sm"} >
          <Tbody>
            {columns?.map((element, index) => (
              <Tr
                key={`bias-${element}-${index}`}
              // backgroundColor={
              //   speedCurveBiasData?.[element]?.flag && "#F9DEDC"
              // }
              >
                <Td
                  textAlign={"left"}
                  w={"70%"}
                  textTransform={"capitalize"}
                  // fontWeight={"semibold"}
                  fontSize={"13px"}
                >
                  {element?.split("_")?.join(" ")}
                </Td>
                <Td textAlign={"left"} className={speedCurveBiasData?.[element]?.flag ? "text-[#3E3C42] font-semibold" : "text-[#1A202C]"}  >
                  {speedCurveBiasData?.[element]?.value}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
    </div>
  );
};

//className= { speedCurveBiasData?.[element]?.flag ? "text-[#3E3C42]" : "text-[#1A202C]" }

const Wfr = ({ wfrData }) => {
  const columns = ["a0", "a1", "a2", "a7"];
  let isColored = wfrData && checkColoredBias(wfrData);
  return (
    <div
      className="flex w-full flex-col gap-1  rounded-md pt-1 color-[#3E3C42] shadow-md"
      style={{
        border: isColored ? "1px solid #9ed8ff" : "1px solid #E2E8F0",
        backgroundColor: isColored ? "#FAFAFA" : "white",
      }}
    >
      <p className="text-left px-4 text-base font-medium">WF Adaptors</p>
      <div className="overflow-x-scroll">
        <Table size={"sm"}>
          <Tbody>
            {columns?.map((element, index) => (
              <Tr
                key={`wfr-${element}-${index}`}
              //backgroundColor={wfrData?.[element]?.flag && "#F9DEDC"}
              >
                <Td
                  textAlign={"left"}
                  w={"70%"}
                  textTransform={"capitalize"}
                  //fontWeight={"semibold"}
                  fontSize={"13px"}
                >
                  {element?.split("_")?.join(" ")}
                </Td>
                <Td textAlign={"left"} className={wfrData?.[element]?.flag ? "text-[#3E3C42] font-semibold" : "text-[#1A202C]"}>{wfrData?.[element]?.value}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
    </div>
  );
};
const FlowRelatedBias = ({ flowRelatedBiasData }) => {
  const columns = ["total", "top", "bottom"];
  let isColored = flowRelatedBiasData && checkColoredBias(flowRelatedBiasData);
  return (
    <div
      className="flex w-full flex-col gap-2  rounded-md border pt-1 shadow-md"
      style={{
        border: isColored ? "1px solid #9ed8ff" : "1px solid #E2E8F0",
        backgroundColor: isColored ? "#FAFAFA" : "white",
      }}
    >
      <p className="text-left px-4 text-base  font-medium">Flow Related Bias</p>
      <div className="overflow-x-scroll">
        <Table size={"sm"}>
          <Tbody>
            {columns?.map((element, index) => (
              <Tr
                key={`flow-related-bias-${element}-${index}`}
              // backgroundColor={
              //   flowRelatedBiasData?.[element]?.flag && "#F9DEDC"
              // }
              >
                <Td
                  textAlign={"left"}
                  w={"70%"}
                  textTransform={"capitalize"}
                  //fontWeight={"semibold"}
                  fontSize={"13px"}
                >
                  {element?.split("_")?.join(" ")}
                </Td>
                <Td textAlign={"left"} className={flowRelatedBiasData?.[element]?.flag ? "text-[#3E3C42] font-semibold" : "text-[#1A202C]"}>
                  {flowRelatedBiasData?.[element]?.value}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </div>
    </div>
  );
};

const SpeedContainer = ({ speedData }) => {

  let isColored = false;

  speedData && checkColoredBias(speedData);

  return (
    <div
      className="flex md:w-8/12 flex-row gap-2 rounded-md shadow-md "
      style={{
        border: isColored ? "1px solid #9ed8ff" : "1px solid #E2E8F0",
        backgroundColor: isColored ? "#FAFAFA" : "white",
      }}
    >
      <Table className="grid grid-cols-2 w-full justify-evenly">
        {
          Object.keys(speedData)?.map((item, index) => (
            <Tr key={index} className="flex flex-row gap-2">
              <Td
                // key={index}
                className={(speedData[item]?.flag) ? "text-[#3E3C42] capitalize self-center " : "text-[#1A202C] capitalize self-center "}
              >
                {item?.split("_")?.join(" ")}
              </Td>
              <Td
                // key={index}
                className={(speedData[item]?.flag) ? "text-[#3E3C42] capitalize self-center " : "text-[#1A202C] capitalize self-center "}
              >
                {speedData[item]?.value}
              </Td>
            </Tr>

          ))
        }
      </Table>
    </div >
  )
}

const EdgeMasking = ({ edgeMaskingData, isRecommended }) => {
  const columns = ["bank", "ws", "ds"];
  let isColored = false;

  function checkEdgeMaskingColored(data) {
    for (const item of data) {
      for (const key in item) {
        if (item.hasOwnProperty(key) && item[key].flag) {
          isColored = true;
          return;
        }
      }
    }
  }

  edgeMaskingData && checkEdgeMaskingColored(edgeMaskingData);

  return (
    <div
      className="flex w-full flex-col gap-2  rounded-md pt-1 shadow-md"
      style={{
        border: isColored ? "1px solid #9ed8ff" : "1px solid #E2E8F0",
        backgroundColor: isColored ? "#FAFAFA" : "white",
      }}
    >
      <p className="text-left px-4 text-base font-medium">Edge Masking {isRecommended ? "(Position)" : "(Bias)"}</p>
      <div>
        <Table size={"sm"}>
          <Thead className={isColored ? "bg-[#9ed8ff]" : "bg-[#DDEEFF]"}>
            <Tr>
              {columns?.map((item, idx) => {
                return (
                  <Th
                    color={"black"}
                    fontWeight={"medium"}
                    key={`head-${item}-${idx}`}
                    textTransform={
                      item === "ws" || item === "ds"
                        ? "uppercase"
                        : "capitalize"
                    }
                    fontSize={"13px"}
                  >
                    {item}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {edgeMaskingData?.map((element, index) => {
              return (
                <Tr key={`wfr-${index}-row`}>
                  {columns?.map((item, idx) => {
                    return (
                      <Td
                        key={`${item}-tabledata-${idx}`}
                        className={(element[item]?.flag && item != "bank") ? "text-[#3E3C42] font-semibold" : "text-[#1A202C]"}
                      //fontWeight={item === "bank" && "bold"}
                      //backgroundColor={element[item]?.flag && "#F9DEDC"}
                      >
                        {element[item]?.value}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </div>
    </div>
  );
};

const HeadTailMasking = ({ data, title }) => {
  const tableHeader = ["", "", "h1", "h2", "h3", "h4", "h5", "h6"];
  const outerColumns = ["ramp", "length", "offset"];
  const innerColumns = ["h1", "h2", "h3", "h4", "h5", "h6"];
  let isColored = false;

  (function checkMaskingColored() {
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
        const subObj = data[key];
        for (const subKey in subObj) {
          if (Object.hasOwnProperty.call(subObj, subKey)) {
            const innerObj = subObj[subKey];
            for (const innerKey in innerObj) {
              if (Object.hasOwnProperty.call(innerObj, innerKey)) {
                const element = innerObj[innerKey];
                if (element.flag !== undefined && element.flag) {
                  isColored = true;
                  break;
                }
              }
            }
          }
        }
      }
    }
  })();

  return (
    <div
      className="w-full flex flex-col gap-2 rounded-md pt-1 shadow-md"
      style={{
        border: isColored ? "1px solid #9ed8ff" : "1px solid #E2E8F0",
        backgroundColor: isColored ? "#FAFAFA" : "white",
      }}
    >
      <p className="text-base text-left font-medium px-4">
        {title ? title : ""}
      </p>
      <div className="overflow-x-scroll">
        <Table
          size={"sm"}
          minWidth={"100%"}
        >
          <Thead className={isColored ? "bg-[#9ed8ff]" : "bg-[#DDEEFF]"}>
            <Tr>
              {tableHeader?.map((element, index) => (
                <Th
                  key={`${element}-${index}-table-header`}
                  fontWeight={"semibold"}
                  textTransform={"capitalize"}
                  fontSize={"13px"}
                  color={"black"}
                >
                  {element}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {data &&
              Object.keys(data)?.map((element, index) => {
                return (
                  <Tr
                    key={`${index}-row-${element}`}
                    aria-rowspan={3}
                  >
                    <Td
                      textTransform={"capitalize"}
                      //fontWeight={"semibold"}
                      fontSize={"sm"}
                      color={"black"}
                    >
                      {element?.split("_")?.join(" ")}
                    </Td>
                    <Td>
                      {outerColumns &&
                        outerColumns?.map((el, idx) => (
                          <p
                            key={`${el}-${idx}-second-row-header`}
                            className="text-xs capitalize"
                          >
                            {el}
                          </p>
                        ))}
                    </Td>
                    {innerColumns?.map((item, ind) => (
                      <Td key={`inner-row-${ind}-item`}>
                        {outerColumns?.map((el, idx) => {
                          return (
                            <p
                              className={data?.[element]?.[el]?.[item]?.flag ? "text-[#3E3C42] font-semibold" : "text-[#1A202C]"}
                              // style={{
                              //   backgroundColor:
                              //     data?.[element]?.[el]?.[item]?.flag &&
                              //     "#F9DEDC",
                              // }}
                              key={`${ind}-${el}-${item}-cell`}
                            >
                              {data?.[element]?.[el]?.[item]?.value}
                            </p>
                          );
                        })}
                      </Td>
                    ))}
                  </Tr>
                );
              })}
          </Tbody>
        </Table>
      </div>
    </div>
  );
};

const WaterTemperature = ({ waterData }) => {
  let isColored = waterData?.flag
  return (
    <div
      className="flex md:w-1/3 flex-row gap-2 rounded-md shadow-md h-full"
      style={{
        border: isColored ? "1px solid #9ed8ff" : "1px solid #E2E8F0",
        backgroundColor: isColored ? "#FAFAFA" : "white",
      }}
    >
      <div className="grid grid-cols-1 w-full justify-evenly">
        <div className="flex flex-row gap-2 px-4 items-center justify-evenly">
          <p
            className={isColored ? "text-[#3E3C42] capitalize self-center " : "text-[#1A202C] capitalize self-center "}
          >
            Water Temperature
          </p>
          <p
            className={isColored ? "text-[#3E3C42] capitalize self-center " : "text-[#1A202C] capitalize self-center "}
          >
            {waterData?.value}
          </p>
        </div>

      </div>
    </div >
  )
}

export const SingleConfig = ({ data, isSingle, isRecommended }) => {
  return (
    <>
      <div className="flex w-full flex-row flex-wrap gap-5 px-1">
        {isSingle && <div className="flex flex-col md:flex-row items-center justify-between gap-2 md:w-[50%]">
          <SpeedContainer speedData={data?.speed} />
          <WaterTemperature waterData={data?.water_temperature} />
        </div>}
        <div
          className={`flex  w-full ${isSingle ? "flex-row gap-2" : "flex-col gap-5"
            }`}
        >
          {!isSingle && <div className="flex flex-col md:flex-row items-center justify-between gap-4">
            <SpeedContainer speedData={data?.speed} />
            <WaterTemperature waterData={data?.water_temperature} />
          </div>}
          <div className="flex w-full flex-auto flex-col items-start justify-evenly gap-2 md:flex-row ">
            {/* bias table */}
            {data?.bias && <SpeedCurveBias speedCurveBiasData={data?.bias} />}
            {/* wfr table  */}
            {data?.wfr && <Wfr wfrData={data?.wfr} />}
            {/* Flow Related Bias */}
            {data?.flow_related_bias && (
              <FlowRelatedBias flowRelatedBiasData={data?.flow_related_bias} />
            )}
          </div>

          {/* Edge Masking */}
          {data?.edge_masking?.data && (
            <EdgeMasking edgeMaskingData={data?.edge_masking?.data} isRecommended={isRecommended} />
          )}
        </div>

        {/* Head Masking  */}
        <div
          className={`w-full flex ${isSingle ? "flex-row gap-2" : "flex-col gap-5"
            }`}
        >
          {data?.head_masking && (
            <HeadTailMasking
              data={data?.head_masking}
              title={"Head Masking"}
            />
          )}

          {/* Tail Masking */}
          {data?.tail_masking && (
            <HeadTailMasking
              data={data?.tail_masking}
              title={"Tail Masking"}
            />
          )}
        </div>
      </div>
    </>
  );
};
