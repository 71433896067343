import { useWindowSize } from "@uidotdev/usehooks";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "@chakra-ui/react";
import NavContext from "../../NavContext";
import {
  CustomFooter,
  CustomStyledDataGrid,
} from "../../../util/MaterialDataGrid/CustomStyledDatagrid";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { NoData, TableSkeleton } from "../components/Loading";
import { dataGridSlotProps, generateColumns } from "../utilis/dataGrid";
import { getReportDataApi } from "../services/sodaashandlime.api";
import { DownloadButton } from "../components/DownloadButton";
import { formatForDateTimePickerLocale } from "../utilis/utilityFunctions";

const Report = () => {
  // auth params and refs
  const { width } = useWindowSize();
  let param = useParams();
  const { auth } = useContext(NavContext);

  //state
  const [loading, setLoading] = useState(false);
  const [reportData, setReportData] = useState({
    columns: [],
    tableData: [],
    order: [],
  });

  const getInitialState = (hours = 0, days = 0) => {
    const currentDate = new Date();
    const adjustedDate = new Date(
      currentDate.getTime() -
        hours * 60 * 60 * 1000 -
        days * 24 * 60 * 60 * 1000
    );
    return {
      fromTime: formatForDateTimePickerLocale(adjustedDate),
      toTime: formatForDateTimePickerLocale(currentDate),
      basis: "sodaash",
    };
  };

  const initialState = getInitialState(0, 7); // days

  const [filters, setFilters] = useState(initialState);

  const fetchReportData = async () => {
    try {
      const requestData = JSON.stringify({
        clientId: param.clientId.toLowerCase(),
        useCase: "sodaash",
        // basis: filters.basis,
        startDate: new Date(filters.fromTime).getTime(),
        endDate: new Date(filters.toTime).getTime(),
      });
      const reportResponse = await getReportDataApi(
        auth,
        requestData,
        setLoading
      );

      // report data
      const tempColumns = await generateColumns(reportResponse?.columns);
      setReportData((prev) => ({
        ...prev,
        columns: tempColumns,
        tableData: reportResponse?.tempTableData,
        order: reportResponse.columns,
      }));
    } catch (error) {
      console.log(error);
      setReportData((prev) => ({
        ...prev,
        columns: null,
        tableData: null,
        order: null,
      }));
    }
  };

  useEffect(() => {
    fetchReportData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative flex flex-col mt-5 md:mt-0 mb-1">
      <div className="right-0 left-0 absolute flex justify-center">
        <div className="flex md:flex-row flex-col items-center gap-2 bg-white shadow-md p-5 pr-6 pl-6 rounded-xl">
          <div>
            <FloatingInput
              text="From"
              type="datetime-local"
              setDateTime={(value) =>
                setFilters((prev) => ({ ...prev, fromTime: value }))
              }
              value={filters.fromTime}
              min={"2021-01-01T00:00"}
              max={filters.toTime || formatForDateTimePickerLocale(new Date())}
            />
          </div>
          <div>
            <FloatingInput
              text="To"
              type="datetime-local"
              setDateTime={(value) =>
                setFilters((prev) => ({ ...prev, toTime: value }))
              }
              value={filters.toTime}
              min={filters.fromTime}
              max={filters.toTime || formatForDateTimePickerLocale(new Date())}
            />
          </div>
          <PrimaryButton
            onClick={fetchReportData}
            text={loading ? <Spinner /> : "Show report"}
            height={"100%"}
            width={"120px"}
            disable={!filters.fromTime || !filters.toTime || loading}
          />
        </div>
      </div>
      <div className="bg-white mt-[160px] md:mt-11 px-4 pt-[57px] pb-4 rounded-xl w-full h-full">
        <div className="flex md:flex-row flex-col md:justify-end items-end md:items-center gap-2 mb-2">
          <div className="flex items-center gap-3">
            {reportData?.order && reportData?.tableData && (
              <DownloadButton
                order={reportData.order}
                data={reportData.tableData}
              />
            )}
          </div>
        </div>

        {loading ? (
          <TableSkeleton
            headers={6}
            rows={10}
            cellsPerRow={6}
          />
        ) : (
          <>
            {reportData?.columns && reportData?.tableData ? (
              <div
                className="border rounded-md w-full"
                style={{
                  height:
                    95 +
                    Math.min(10, reportData?.tableData?.length || 0) * 45 +
                    "px",
                }}
              >
                <CustomStyledDataGrid
                  rows={reportData?.tableData || []}
                  columns={reportData?.columns || []}
                  rowHeight={45}
                  columnHeaderHeight={50}
                  disableColumnSelector={true}
                  disableRowSelectionOnClick={true}
                  pageSizeOptions={[10, 20, 50]}
                  pagination={true}
                  disableColumnReorder={true}
                  initialState={{
                    pagination: {
                      paginationModel: { pageSize: 10, page: 0 },
                    },
                    pinnedColumns: {
                      right: width > 768 ? ["view_details"] : null,
                    },
                  }}
                  getRowClassName={(params) =>
                    params.indexRelativeToCurrentPage % 2 === 0
                      ? "bg-[#FAFAFA]"
                      : "bg-white"
                  }
                  slots={{
                    footer: CustomFooter,
                  }}
                  slotProps={dataGridSlotProps && dataGridSlotProps()}
                  sx={{
                    "& .MuiDataGrid-columnHeaderTitle": {
                      whiteSpace: "normal",
                      lineHeight: "normal",
                    },
                    "& .MuiDataGrid-columnHeader": {
                      textTransform: "none",
                    },
                  }}
                  showColumnVerticalBorder
                />
              </div>
            ) : (
              <div className="w-full h-[350px]">
                <NoData />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Report;
