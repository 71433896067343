import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useEffect, useState, useContext, useRef } from "react";
import NavContext from "../../NavContext";
import { baseURL } from "../../../index";
import { Spinner } from "@chakra-ui/react";
import AlertTable from "../Tables/AlertTable";
import AlertCard from "../Components/AlertCard";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";

const Alerts = ({ plantId, cameraId, disable, plantCamMap }) => {
  const param = useParams();
  const { auth } = useContext(NavContext);
  const [alerts, setAlerts] = useState([]);
  const [alertCards, setAlertCards] = useState({});
  const [alertsChanging, setAlertsChanging] = useState(false);
  const [filterData, setFilterData] = useState({ items: [] });
  const [fromTime, setFromTime] = useState(
    new Date(new Date().getTime() - 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16)
  );
  const [toTime, setToTime] = useState(
    new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16)
  );

  const AlertCardApi = async (val, time) => {
    try {
      const param = {
        starttime: new Date(time ? time : fromTime).getTime(),
        endtime: new Date(toTime).getTime(),
        loader: val,
      };
      const response = await axios.get(
        baseURL + "vision/v1/workforceMonitoring/cb/getLoaderAccuracy",
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.data) {
        let data = response.data;
        setAlertCards((prev) => ({
          ...prev,
          [val]: {
            total: data?.total_rounds,
            passed: data?.total_rounds - data?.total_alerts,
          },
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const AlertApi = async (time = null) => {
    const param = {
      starttime: new Date(time ? time : fromTime).getTime(),
      endtime: new Date(toTime).getTime(),
    };
    const response = await axios
      .get(baseURL + "vision/v1/workforceMonitoring/cb/getAlerts", {
        params: param,
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      })
      .then((response) => {
        ["loader-4", "loader-5"]?.forEach((item) => {
          AlertCardApi(item, time);
        });
        setAlerts(response.data);
        setAlertsChanging(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getStatusApi = async () => {
    try {
      const response = await axios.get(
        baseURL + "vision/v1/workforceMonitoring/concentrateStatus",
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response?.data?.length > 0) {
        let data = response?.data[0];
        setFromTime((prev) => {
          let newData = new Date(data?.starttime * 1000)
            ?.toISOString()
            ?.slice(0, 16);
          setAlertsChanging(true);
          AlertApi(newData);
          return newData;
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = () => {
    setAlertsChanging(true);
    AlertApi();
  };

  useEffect(() => {
    getStatusApi();
  }, []);

  return (
    <div className="relative flex flex-col">
      <div className="absolute left-0 right-0 flex justify-center">
        <div className="p-5 pl-6 pr-6 gap-2 flex flex-col md:flex-row items-center bg-white rounded-xl shadow-md">
          <div>
            <FloatingInput
              text="From"
              type="datetime-local"
              setDateTime={setFromTime}
              value={fromTime}
              max={toTime}
            />
          </div>
          <div>
            <FloatingInput
              text="To"
              type="datetime-local"
              setDateTime={setToTime}
              value={toTime}
              max={new Date().toISOString().slice(0, 16)}
              min={fromTime}
            />
          </div>
          <PrimaryButton
            disable={!fromTime || !toTime}
            onClick={handleClick}
            width={"110px"}
            height={"40px"}
            text={alertsChanging ? <Spinner /> : "Show alerts"}
          />
        </div>
      </div>
      <div className="flex flex-col gap-8 mt-[160px] md:mt-11 pt-[57px] bg-white rounded-xl justify-start">
        {/* <div className="flex flex-col gap-4">
          <p className="pl-6 text-xl font-medium text-[#3E3C42]">
            How you are doing
          </p>
          <div className="px-4 flex gap-4 justify-start flex-wrap">
            {Object.keys(alertCards)?.map((val, idx) => {
              return (
                <AlertCard
                  parameter={val}
                  count={alertCards[val].passed}
                  total={alertCards[val].total}
                  setFilterData={setFilterData}
                />
              );
            })}
          </div>
          <div className="pl-6 flex items-center gap-1">
            <img src="/BlendComplianceIcons/info.svg" alt="no support" />
            <p className="text-[#938F96] text-xs">
              No. of non-compliant rounds / Total no. of rounds{" "}
            </p>
          </div>
        </div> */}
        {alerts.length > 0 && (
          <AlertTable
            rowData={alerts}
            filterData={filterData}
            setFilterModel={setFilterData}
          />
        )}
      </div>
    </div>
  );
};

export default Alerts;
