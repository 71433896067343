export const formatDateTime = (epochTimestamp) => {
  const isMilliseconds = epochTimestamp >= 1000000000000; // checking if time stamp is in miliseconds or not after 2001

  const date = new Date(
    isMilliseconds ? epochTimestamp : epochTimestamp * 1000
  );

  const formattedDate = date.toLocaleDateString("en-IN", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour12: false,
    timeZone: "UTC",
  });

  const formattedTime = date.toLocaleTimeString("en-IN", {
    hour12: false,
    timeZone: "UTC",
  });

  return `${formattedDate} ${formattedTime}`;
};

export const formatDateTimeIST = (epochTimestamp) => {
  const isMilliseconds = epochTimestamp >= 1000000000000; // checking if time stamp is in miliseconds or not after 2001

  const date = new Date(
    isMilliseconds ? epochTimestamp : epochTimestamp * 1000
  );
  const formattedDate = date.toLocaleDateString("en-IN", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour12: false,
  });
  const formattedTime = date.toLocaleTimeString("en-IN", {
    hour12: false,
  });
  return `${formattedDate} ${formattedTime}`;
};

export const capitalizeCamelCaseString = (input) => {
  if (typeof input !== "string") {
    throw new Error("Input should be a string");
  }

  const words = input.split(/(?=[A-Z])/).join(" ");

  const capitalizedWords = words
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return capitalizedWords;
};

export const convertMapToArray = (map1) => {
  const resultArray = [];
  if (map1.size > 0) {
    resultArray.push(
      ...Array.from(map1.values()).map((obj) => ({
        ...obj,
      }))
    );
  }
  return resultArray;
};
