import { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import NavContext from "../../NavContext";
import { Select, Spinner } from "@chakra-ui/react";
import { baseURL } from "../../../index";
import axios from "axios";
import { mixpanelTrackButton } from "../../../util/mixpanelFunctions";
import { capitalize } from "@mui/material";
import { capitalizeFirstLetter } from "../../../util/sentenceCase";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import LibraryGrid from "./LibraryGrid";

const PhotoGallery = ({
  plantId,
  cameraId,
  disable,
  plantCamMap,
  camMap,
  setCamMap,
  material,
}) => {
  const { auth } = useContext(NavContext);
  let param = useParams();
  const [imgData, setImgData] = useState([]);
  const [filterForCam, setFilterForCam] = useState([]);
  const [imgType, setImgType] = useState(camMap?.cameraKeys?.[0] || "");
  const [childImgType, setChildImgType] = useState(
    camMap?.cameraKeys?.[0] || ""
  );
  const [imgDataChanging, setImgDataChanging] = useState(false);
  const [hasBigParticle, setHasBigParticle] = useState(0);
  const [showType, setShowType] = useState(0);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [currect_page, setCurrect_page] = useState({
    active: false,
    page: null,
  });
  const [fromTime, setFromTime] = useState(
    new Date(new Date().getTime() - 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16)
  );
  const [toTime, setToTime] = useState(
    new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16)
  );
  const [selectedPlant, setSelectedPlant] = useState(plantId);
  const [selectedCam, setSelectedCam] = useState(cameraId);
  const [need_to_update, set_need_to_update] = useState(true);
  const handleSelect = (e) => {
    let val = e.target.value;
    setShowType(val);
    set_need_to_update(true);
    if (val == 0) {
      setFromTime(
        new Date(
          new Date().getTime() - 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000
        )
          .toISOString()
          .slice(0, 16)
      );
      setToTime(
        new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 16)
      );
    } else if (val == 7) {
      setFromTime(
        new Date(
          new Date().getTime() - 7 * 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000
        )
          .toISOString()
          .slice(0, 16)
      );
      setToTime(
        new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 16)
      );
    }
  };
  const response = {
    filtersForCam: [
      { camera: "camID2", displayName: "IR camera" },
      { camera: "camID1", displayName: "Vision camera" },
    ],
    data: [
      {
        clientId: "jsw",
        camID1: "/JSWSalem/real.jpg",
        camID2: "/JSWSalem/annoted.jpg",
        timestamp: 1717604736,

        material: "sinterflameprogression",
        id: "666044f7f104a5cac8e3f89a",
      },
      {
        clientId: "jsw",
        camID1: "/JSWSalem/real.jpg",
        camID2: "/JSWSalem/annoted.jpg",
        timestamp: 1717604737,

        material: "sinterflameprogression",
        id: "666044f7f104a5cac8e3f89a",
      },
    ],
  };
  const apiCall = async (isInitial) => {
    try {
      const requestData = JSON.stringify({
        clientId: param.clientId.toLowerCase(),
        useCase: material,
        plantName: "salem",
        cameraId: "cam101",
        startDate: new Date(fromTime).getTime(),
        endDate: new Date(toTime).getTime(),
      });
      const response = await axios.post(
        baseURL + "vision/v2/processMonitoring/feedLibrary/images/",
        requestData,
        {
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );

      setImgData(response?.data?.[0]?.data);
      setCamMap((prev) => ({
        ...prev,
        cameraKeys: response?.data?.[0]?.cameraKeys,
        cameraMapping: response?.data?.[0]?.cameraMapping,
      }));
      // Set imgType only on initial render
      if (isInitial) {
        setImgType(response?.data?.[0]?.cameraKeys?.[0] || "");
      }

      setFilterForCam(response?.data?.[0]?.filtersForCam);
      setCurrect_page((prev) => {
        return {
          ...prev,
          active: false,
        };
      });
      setImgDataChanging(false);
    } catch (error) {
      console.log(error);
      setImgData([]);
      setFilterForCam([]);
      setImgDataChanging(false);
      setCurrect_page((prev) => {
        return {
          ...prev,
          active: false,
        };
      });
    } finally {
      set_need_to_update(true);
    }
    // setImgData(response?.data);
    // setFilterForCam(response?.filtersForCam);
  };

  const handleClick = async (isInitial) => {
    try {
      setChildImgType(imgType);
      setImgDataChanging(true);
      if (need_to_update) {
        await apiCall(isInitial);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setImgDataChanging(false);
      set_need_to_update(true);
    }
  };

  useEffect(() => {
    handleClick(isInitialRender);
    setIsInitialRender(false); // Set to false after the first render
  }, []);

  useEffect(() => {
    if (!disable) {
      setSelectedCam(plantCamMap?.[selectedPlant]?.[0]);
    }
  }, [selectedPlant]);

  useEffect(() => {
    set_need_to_update(true);
  }, [fromTime, toTime]);

  return (
    <div className="bg-white  flex flex-col gap-6">
      <div className="flex pt-5 gap-2 py-[2px] items-center overflow-x-auto">
        {camMap?.cameraKeys && (
          <div className="min-w-[110px]">
            <Select
              borderColor="#CAC5CD"
              color="#605D64"
              variant="outline"
              rounded={"base"}
              onChange={(e) => {
                setImgType(e.target.value);
                set_need_to_update(false);
                setCurrect_page((prev) => {
                  return {
                    ...prev,
                    active: true,
                  };
                });
              }}
              value={imgType ? imgType : camMap?.cameraKeys?.[0]}
            >
              {camMap?.cameraKeys?.map((e) => {
                return <option value={e}>{e}</option>;
              })}
            </Select>
          </div>
        )}
        <div className="min-w-[100px]">
          <Select
            borderColor="#CAC5CD"
            color="#605D64"
            variant="outline"
            rounded={"base"}
            onChange={(e) => handleSelect(e)}
            value={showType}
          >
            <option value={0}>Today</option>
            <option value={7}>Past 7 days</option>
            <option value={2}>Custom</option>
          </Select>
        </div>
        {/* {(imgData?.some((item) => item?.hasOwnProperty("bigParticlePresent")) ||
          hasBigParticle === 1) && (
          <div className="min-w-[110px]">
            <Select
              borderColor="#CAC5CD"
              color="#605D64"
              variant="outline"
              rounded={"base"}
              onChange={(e) => setHasBigParticle(Number(e.target.value))}
              value={hasBigParticle}
            >
              <option value={0}>All</option>
              <option value={1}>Big particle</option>
            </Select>
          </div>
        )} */}
        {showType == 2 && (
          <div className="flex gap-2">
            <div>
              <FloatingInput
                text="From"
                type="datetime-local"
                setDateTime={setFromTime}
                value={fromTime}
                max={toTime}
              />
            </div>
            <div>
              <FloatingInput
                text="To"
                type="datetime-local"
                setDateTime={setToTime}
                value={toTime}
                min={fromTime}
                max={new Date().toISOString().slice(0, 16)}
              />
            </div>
          </div>
        )}
        {/* <button
          className="text-center py-2 px-4 text-white text-xs md:text-base font-medium bg-[#6CA6FC] rounded-full min-w-[80px]"
          onClick={handleClick}
        >
          {imgDataChanging ? <Spinner /> : "Apply"}
        </button> */}
        <SecondaryButton
          height={"40px"}
          width={"80px"}
          onClick={() => handleClick(false)}
          text={imgDataChanging ? <Spinner /> : "Apply"}
          disable={imgDataChanging || !date}
        />
      </div>
      <LibraryGrid
        plantName={selectedPlant}
        img={imgData}
        imgType={camMap?.cameraMapping?.[childImgType]?.[0]}
        filterForCam={filterForCam}
        setImgType={setImgType}
        setCamMap={setCamMap}
        camMap={camMap}
        imgDataChanging={imgDataChanging}
        currect_page={currect_page}
        setCurrect_page={setCurrect_page}
      />
    </div>
  );
};

export default PhotoGallery;
