import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useContext, useEffect, useRef, useState } from "react";
import {
  useLocation,
  useParams,
  Link,
  useSearchParams,
} from "react-router-dom";
import { baseURL } from "../..";
import { useWindowSize } from "@uidotdev/usehooks";
import NavContext from "../NavContext";
import axios from "axios";
import Feed from "./Tabs/Feed";
import Alerts from "./Tabs/Alerts";
import FeedLibrary from "./Tabs/FeedLibrary";
import Report from "./Tabs/Report";
import Analytics from "./Tabs/Analytics";

const SinterFlameProgress = () => {
  const size = useWindowSize();
  const [plantCamMap, setPlantCamMap] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const params = useParams();
  const material = "SINTERFLAMEPROGRESSION";
  const clientId = params.clientId;
  const urlParamTabIndex = !isNaN(searchParams.get("tabIndex"))
    ? Number(searchParams.get("tabIndex"))
    : 0;

  // handling tab change
  const handleTabsChange = (index) => {
    if (index !== urlParamTabIndex) {
      setSearchParams({ tabIndex: index }, { replace: true });
    }
  };

  // tablist
  const tabs = [
    {
      label: "Feed",
      index: 0,
      component: (
        <Feed
          material={material?.toUpperCase()}
          clientId={clientId}
          setPlantCamMap={setPlantCamMap}
        />
      ),
    },
    {
      label: "Alerts",
      index: 1,
      component: (
        <Alerts
          material={material?.toUpperCase()}
          clientId={clientId}
          plantId="All Plants"
          cameraId=""
          disable={false}
          plantCamMap={plantCamMap}
        />
      ),
    },
    {
      label: "Feed Library",
      index: 2,
      component: (
        <FeedLibrary
          plantId="All Plants"
          cameraId=""
          disable={false}
          plantCamMap={plantCamMap}
          material={material?.toUpperCase()}
        />
      ),
    },
    {
      label: "Analytics",
      index: 3,
      component: (
        <Analytics
          material={material?.toUpperCase()}
          plantId={Object.keys(plantCamMap)?.[0]}
          cameraId={plantCamMap?.[Object.keys(plantCamMap)?.[0]]?.[0]}
          disable={false}
          plantCamMap={plantCamMap}
        />
      ),
    },
    {
      label: "Report",
      index: 4,
      component: (
        <Report
          material={material?.toUpperCase()}
          plantId="All Plants"
          cameraId=""
          disable={false}
          plantCamMap={plantCamMap}
        />
      ),
    },
  ];

  useEffect(() => {
    handleTabsChange(urlParamTabIndex);
  }, [urlParamTabIndex]);
  return (
    <div
      className=" font-poppins flex flex-col rounded-lg  "
      style={{ width: size.width >= 768 ? "calc(100vw - 168px)" : "100vw" }}
    >
      <div className="flex justify-start items-center gap-2 mb-3 mt-0 md:mt-6">
        <Link
          to={`/vision/ProcessMonitoring/sinterflameprogression`}
          style={{
            textDecoration: "none",
          }}
        >
          <img src="/backtick.svg" />
        </Link>
        <p className="text-lg sm:text-2xl font-semibold text-[#024D87] capitalize">
          {"Sinter Flame progression"}
        </p>
      </div>

      <Tabs
        index={urlParamTabIndex}
        onChange={handleTabsChange}
        paddingRight={{ base: "0px", md: "0px", lg: "0px" }}
        isLazy
      >
        <TabList className="!flex !border-0">
          <div className="flex items-center gap-4 overflow-x-auto h-14 md:h-10">
            {tabs &&
              tabs.map((tab, index) => (
                <Tab
                  key={`${tab.label}-${index}`}
                  minW={"fit-content"}
                  className={`${
                    urlParamTabIndex === tab.index
                      ? "!text-black !text-xs sm:!text-sm !bg-white rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#79767D]"
                      : "!text-xs sm:!text-sm !text-[#938F96] !border-0"
                  }`}
                  onClick={() => handleTabsChange(tab.index)}
                >
                  {tab.label}
                </Tab>
              ))}
          </div>
        </TabList>
        <TabPanels>
          {tabs &&
            tabs.map((tab, index) => (
              <TabPanel
                key={`${tab.label}-${index}`}
                className="!pl-0 !pr-0 h-full mb-0 pb-0"
              >
                {tab.component}
              </TabPanel>
            ))}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default SinterFlameProgress;
