import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider, IconButton } from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useState, useContext } from "react";
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Tooltip from '@mui/material/Tooltip';
import { utils, writeFile } from "xlsx";
import { alpha, styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import axios from "axios";
import NavContext from "../../../NavContext";
import { baseURL } from "../../../..";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const MuiTheme = createTheme();

const View = ({ row, setSelectedProductGroup, setShowProductTab }) => {

    const handleView = () => {
        //console.log(products);
        setSelectedProductGroup(row);
        setShowProductTab((prev) => "viewProductGroup");
    }

    return (
        <IconButton
            aria-label="edit"
            sx={{ color: "#1E88E5" }}
            onClick={() => {
                handleView();
            }}
            //disabled={row?.status === "INACTIVE"}
        >
            <VisibilityIcon />
        </IconButton>
    );
}

const Update = ({ row, setSelectedProductGroup, setShowProductTab }) => {

    const handleUpdate = () => {
        //console.log(products);
        setSelectedProductGroup(row);
        setShowProductTab((prev) => "updateProductGroup");
    }
    return (
        <IconButton
            aria-label="edit"
            sx={{ color: "#1E88E5" }}
            onClick={() => {
                handleUpdate();
            }}
            disabled={row?.status === "INACTIVE"}
        >
            <EditNoteIcon />
        </IconButton>
    );
}

const Download = ({ row, setShowDevicesTab }) => {

    const exportAsExcel = (data, sheetName) => {
        let wb = utils.book_new();

        let newData = [Object.keys(data[0])];
        const fileColumns = Object.keys(data[0]);

        if (data.length != 0) {
            data?.forEach((item) => {
                const newArray = fileColumns?.map((i) => {
                    return i.toLowerCase().includes("time")
                        ? new Date(item[i]).toLocaleString()
                        : item[i];
                });
                newData.push(newArray);
            });
        }

        let ws = utils.aoa_to_sheet(newData);
        utils.book_append_sheet(wb, ws, `${sheetName}`);
        writeFile(wb, `report_data.xlsx`);
    };

    const handleDownloadClick = async (data) => {
        if (Array.isArray(data)) exportAsExcel(data, "Summary");
        else exportAsExcel([data], "Summary");
    };

    return (
        <div className="cursor-pointer" onClick={() => handleDownloadClick(row)}>
            <img src="/download.svg" />
        </div>
    );
}

const ToggleSwitch = ({ row, setShowDevicesTab, getProductGroups }) => {

    const [checked, setChecked] = useState(row?.status !== "INACTIVE");
    const { auth } = useContext(NavContext);

    const updateProductGroups = async (flag, status) => {

        const data1 = {
            productIdUuid: row.productIdUuid,
            isdeleted: flag,
            status: status,
        }
        const json = JSON.stringify(data1);
        const blob = new Blob([json], {
            type: "application/json",
        });
        const FormData = require("form-data");
        let datax = new FormData();
        datax.append("data", blob);

        let config = {
            method: "patch",
            maxBodyLength: Infinity,
            url: baseURL + "product/v1/update/productGroup",
            headers: {
                "X-Auth-Token": auth,
            },
            data: datax,
        };
        axios
            .request(config)
            .then((response) => {
                getProductGroups();
            })
            .catch((error) => {
                //console.log(error);
                // toast({
                //     title: "Something went wrong",
                //     status: "error",
                //     duration: 4000,
                //     isClosable: true,
                //     position: "top",
                // });
            });

    }

    const handleChange = async (event) => {

        setChecked(event.target.checked);
        if (event.target.checked === true) await updateProductGroups(false, 1)
        else await updateProductGroups(true, 2);
        console.log(event.target.checked)

    };

    const GreenSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
            color: "#378805",
            '&:hover': {
                backgroundColor: alpha("#378805", theme.palette.action.hoverOpacity),
            },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
            backgroundColor: "#378805",
        },
    }));

    return (
        <div>
            {/* Switch */}
            <GreenSwitch
                checked={checked}
                onChange={handleChange}
                // color="#568203"
                inputProps={{ 'aria-label': 'controlled' }}
            />
        </div>

    );
}

const ProductsGroupsTable = ({ productGroups, setSelectedProductGroup, setShowProductTab, getProductGroups }) => {

    console.log(productGroups)

    const rowData = productGroups;

    // const obj = {
    //     clients: "A, B, C, D",
    //     deployments: "Ahmedabad, Surat, Kerela, Jamshedpur, Lucknow, Rajasthan",
    //     products: "Camera, Chips",
    // }

    // for (let x of productGroups) {
    //     const newObj = { ...x };
    //     newObj.productIdUuid = x.productIdUuid;
    //     newObj.productGroup = x.productGroupName;
    //     newObj.clients = "A, B, C, D";
    //     newObj.deployments = "Ahmedabad, Surat, Kerela, Jamshedpur, Lucknow, Rajasthan";
    //     newObj.products = "Camera, Chips";
    //     rowData.push(newObj);
    // }

    // const rowData = [
    //     {
    //         productIdUuid: "1",
    //         productGroup: "Raw material sizing",

    //     },
    //     {
    //         productIdUuid: "2",
    //         productGroup: "Raw material sizing",
    //         clients: "Raw material sizing",
    //         deployments: "Ahmedabad, Surat, Kerela, Jamshedpur, Lucknow, Rajasthan",
    //         products: "Camera, Chips",
    //     },
    //     {
    //         productIdUuid: "3",
    //         productGroup: "Raw material sizing",
    //         clients: "A, B, C, D",
    //         deployments: "Ahmedabad, Surat, Kerela, Jamshedpur, Lucknow, Rajasthan",
    //         products: "Camera, Chips",
    //     },
    //     {
    //         productIdUuid: "4",
    //         productGroup: "Raw material sizing",
    //         clients: "A, B, C, D",
    //         deployments: "Ahmedabad, Surat, Kerela, Jamshedpur, Lucknow, Rajasthan",
    //         products: "Camera, Chips",
    //     },
    //     {
    //         productIdUuid: "5",
    //         productGroup: "Coal String",
    //         clients: "A, B, C, D",
    //         deployments: "Ahmedabad, Surat, Kerela, Jamshedpur, Lucknow, Rajasthan",
    //         products: "Camera, Chips"
    //     }
    // ]

    console.log(rowData);

    const columns = [
        {
            field: "productGroup",
            headerName: <div className="flex">
                <img src="/productGroup.svg" />
                Product Group
            </div>,
            valueGetter: ({ row }) => row?.productGroupName,
            renderCell: ({ row }) => {
                return (
                    <div className="w-full flex gap-1 ">
                        {row.productGroupUrl ? <img src={row.productGroupUrl} className="w-[20px] h-[20px]" alt="coins" /> : <img src="/ProductGroupDefaultIcon.svg" alt="" className=" w-[20px] h-[20px]" />}
                        {row.productGroupName}

                    </div>
                );
            },
        },

        {
            field: "products",
            headerName: <div className="flex">
                <img src="/toolsIcon.svg" />
                Products
            </div>,
            valueGetter: ({ row }) => row?.productList?.map((value, index) => value.productName).join(", "),
            renderCell: ({ row }) => {
                let allTools = row?.productList?.map((value, index) => value.productName)
                return (
                    <Tooltip title={allTools?.join(", ")} placement="top">
                        <div className="whitespace-nowrap overflow-hidden text-ellipsis flex flex-row gap-1">
                            <p className="font-bold">{row.productCount}</p>

                            {allTools.join(", ")}

                        </div>
                    </Tooltip>
                );
            },
        },
        {
            field: "productGroupParent",
            headerName: <div className="flex">
                <img src="/devicesIcon.svg" />
                Parent Group
            </div>,
            valueGetter: ({ row }) => row?.productGroupParent,
            renderCell: ({ row }) => {
                return (
                    <div className="w-full flex gap-1 ">
                        {/* <img src="/vision_selected.svg" className="w-[20px] h-[20px]" alt="coins" /> */}
                        {row.productGroupParent}

                    </div>
                );
            },
        },
        {
            field: "createdAt",
            headerName: <div className="flex">
                <img src="/date.svg" />
                Created on
            </div>,
            valueGetter: ({ row }) => row?.createdAt,
            renderCell: ({ row }) => {
                function padZero(number) {
                    return (number < 10 ? '0' : '') + number;
                }

                const date = new Date(row.createdAt);
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so add 1
                const day = date.getDate().toString().padStart(2, '0');
                const hours = date.getUTCHours();
                const minutes = date.getUTCMinutes();
                const seconds = date.getUTCSeconds();
                const formattedTime = padZero(hours) + ":" + padZero(minutes) + ":" + padZero(seconds);
                const dateString = `${day}/${month}/${year}`

                const formattedDate = new Date(row.createdAt).toDateString().split(" ")[2] +
                    " " +
                    new Date(row.createdAt).toDateString().split(" ")[1] +
                    " '" +
                    new Date(row.createdAt).toDateString().split(" ")[3].slice(2, 4)
                return (
                    <Tooltip title={formattedDate + " " + formattedTime} placement="top">
                        <div className="w-full flex gap-2 whitespace-nowrap overflow-hidden text-ellipsis">
                            {formattedDate + " " + formattedTime}
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            field: "updatedAt",
            headerName: <div className="flex">
                <img src="/date.svg" />
                Updated on
            </div>,
            valueGetter: ({ row }) => row?.updatedAt,
            renderCell: ({ row }) => {
                function padZero(number) {
                    return (number < 10 ? '0' : '') + number;
                }
                const date = new Date(row.updatedAt);
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so add 1
                const day = date.getDate().toString().padStart(2, '0');
                const hours = date.getUTCHours();
                const minutes = date.getUTCMinutes();
                const seconds = date.getUTCSeconds();
                const formattedTime = padZero(hours) + ":" + padZero(minutes) + ":" + padZero(seconds);
                const dateString = `${day}/${month}/${year}`

                const formattedDate = new Date(row.updatedAt).toDateString().split(" ")[2] +
                    " " +
                    new Date(row.updatedAt).toDateString().split(" ")[1] +
                    " '" +
                    new Date(row.updatedAt).toDateString().split(" ")[3].slice(2, 4)
                return (
                    <Tooltip title={formattedDate + " " + formattedTime} placement="top">
                        <div className="w-full flex gap-2 whitespace-nowrap overflow-hidden text-ellipsis">
                            {formattedDate + " " + formattedTime}
                        </div>
                    </Tooltip>
                );
            },
        },
        // {
        //     field: "clients",
        //     headerName: <div className="flex">
        //         <img src="/clientsIcon.svg" />
        //         Clients
        //     </div>,
        //     renderCell: ({ row }) => {
        //         return (
        //             <div className="w-full flex item-center gap-2 cursor-pointer ">
        //                 <p className="font-bold">14</p>
        //                 {row.clients}
        //                 {/* <Link to={`/community/advisor/${row.chatId}`}>
        //         <p>Link to Q/A</p>
        //       </Link>
        //       <div className="flex items-center justify-center">
        //         <ExternalLinkIcon />
        //       </div> */}
        //             </div>
        //         );
        //     },
        // },
        // {
        //     field: "deployments",
        //     headerName: <div className="flex">
        //         <img src="/deplIcon.svg" />
        //         Deployments
        //     </div>,
        //     renderCell: ({ row }) => {
        //         return (
        //             <div className="w-full flex gap-2 ">
        //                 <p className="font-bold">14</p>
        //                 {row.deployments}
        //                 {/* <img src="/token.svg" alt="coins" /> */}
        //             </div>
        //         );
        //     },
        // },
        {
            field: "status",
            headerName: <div className="flex">
                <img src="/superadmin/clients/timer.svg" alt="" />
                Status
            </div>,
            valueGetter: ({ row }) => row?.status,
            renderCell: ({ row }) => {
                return (
                    <div className="w-full flex item-center gap-2 cursor-pointer ">
                        {row?.status === "DRAFT" ? (
                                <img
                                    src={`/Common/draft.svg`}
                                    className="w-5 h-5 rounded-full"
                                    alt="status"
                                />
                            ) : row.status === "ACTIVE" ? (
                                <CheckCircleIcon sx={{
                                    width: "20px",
                                    height: "20px",
                                    borderRadius: "9999px",
                                    color: "green",
                                }} />
                            ) : (
                                <CancelIcon sx={{
                                    width: "20px",
                                    height: "20px",
                                    borderRadius: "9999px",
                                    color: "#E46962",
                                }} />
                            )}
                        <p className="capitalize">{row.status.toLowerCase()}</p>
                    </div>
                );
            },
        },
        {
            field: "links",
            headerName: "",
            sortable: false,
            disableColumnMenu: true,
            renderCell: ({ row }) => <View row={row} setSelectedProductGroup={setSelectedProductGroup} setShowProductTab={setShowProductTab} />,
        },
        {
            field: "links2",
            headerName: "",
            sortable: false,
            disableColumnMenu: true,
            renderCell: ({ row }) => <Update row={row} setSelectedProductGroup={setSelectedProductGroup} setShowProductTab={setShowProductTab} />,
        },
        // {
        //     field: "links3",
        //     headerName: "",
        //     renderCell: ({ row }) => <Download row={row} setSelectedProductGroup={setSelectedProductGroup} setShowProductTab={setShowProductTab} />,
        // },
        // {
        //     field: "links4",
        //     headerName: "",
        //     renderCell: ({ row }) => <ToggleSwitch row={row} setShowProductTab={setShowProductTab} getProductGroups={getProductGroups} />,
        // }

    ];
    const headerClass =
        "text-sm font-normal text-[#79767D] bg-[#CDEEBF]";
    const cellClass = "text-sm font-normal text-[#3E3C42] whitespace-nowrap";
    const flexMap = [1.3, 2.5, 1, 0.8, 0.8, 0.8, 0.1, 0.1];
    columns.map((val, idx) => {
        val["headerClassName"] = headerClass;
        val["cellClassName"] = cellClass;
        val["flex"] = flexMap[idx];
    });

    return (
        <div className="overflow-x-auto mt-2 w-full">
            <ThemeProvider theme={MuiTheme}>
                <DataGrid
                    rows={rowData}
                    columns={columns}
                    getRowId={(row) => row.productIdUuid}
                    //   columnVisibilityModel={{
                    //     chatId: false,
                    //     username: false,
                    //   }}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "updatedAt", sort: "desc" }],
                        },
                        pagination: { paginationModel: { pageSize: 8 } },
                    }}
                    //   hideFooter={true}
                    sx={{ minWidth: "1000px" }}
                />
            </ThemeProvider>
        </div>
    );
};

export default ProductsGroupsTable;
