import { useContext, useEffect, useRef, useState } from "react";
import { Input, useToast } from "@chakra-ui/react";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import TextButton from "../../../util/Buttons/TextButton";
import axios from "axios";
import { baseURL } from "../../../index";
import { draftPostApi } from "../pages/DraftForm";
import NavContext from "../../NavContext";
import CommonProgressBar from "./CommonProgressBar";

const UploadHandler = ({
  userData,
  setUSerData,
  activeStep,
  handleChange,
  loading,
  disable,
  setLoading,
  setVideoSelection,
}) => {
  const fileInputRef = useRef();
  const folderInputRef = useRef();
  const bucketRef = useRef();
  const rtspRef = useRef();
  const toast = useToast();
  const [progressState, setProgressState] = useState({
    open: false,
    state: null,
  });
  const { auth } = useContext(NavContext);
  const format = {
    Image: ["jpg", "jpeg", "png"],
    Video: ["mp4"],
  };

  const LinkBucket = async (data) => {
    const param = {
      projectId: userData.projectId,
    };

    const requestData = JSON.stringify({
      link: data.replace(/\/+$/, ""),
      linkType: "S3",
    });
    setProgressState((prev) => ({ ...prev, open: true, state: 1 }));
    const promise = new Promise(async (resolve, reject) => {
      await axios
        .post(baseURL + "selfserve/v1/project/v1/link/upload/", requestData, {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        })
        .then((response) => {
          resolve(200);
          setProgressState((prev) => ({ ...prev, state: 2 }));
          setLoading(false);
          handleChange("uploadedFiles", response.data?.dataset);
        })
        .catch((error) => {
          console.log(error);
          reject(error);
          setProgressState((prev) => ({ ...prev, state: 0 }));
          setLoading(false);
        });
    });
  };

  const LinkRtsp = async () => {
    let regex = /(rtsp):\/\/([^\s@/]+)([^\s/:]+)(?::([0-9]+))?(\/.*)/;
    if (!regex.test(rtspRef?.current?.value)) {
      toast({
        title: "Error",
        description: "Invalid rtsp link",
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    try {
      const requestData = JSON.stringify({
        link: rtspRef?.current?.value,
        linkType: "RTSP",
        config: {
          trainCaptureSeconds: 0,
          predictCaptureSeconds: 0,
          targetFps: 1,
        },
      });
      const param = {
        projectId: userData?.projectId,
      };
      const response = await axios.post(
        baseURL + "selfserve/v1/project/v1/feed/upload/",
        requestData,
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response?.status == 200) {
        setUSerData((prev) => {
          let updateData = { ...prev };
          updateData["videoUploaded"] = true;
          draftPostApi(updateData, activeStep, toast, auth);
          return updateData;
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = async (event) => {
    const selectedFile = Array.from(event.target.files);
    if (selectedFile) {
      const acceptedTypes = format[userData.dataType].map((ext) => {
        return userData.dataType.toLowerCase() + "/" + ext;
      });
      let goodToGo = true;
      if (userData?.dataType == "Image") {
        selectedFile.map((file) => {
          if (
            !acceptedTypes.includes(file.type) ||
            file.size > 2 * 1024 * 1024
          ) {
            toast({
              title: "Error",
              description: (
                <div>
                  <p>{`Image name: ${file.name}`}</p>
                  <p>{`Accepted formats: [${acceptedTypes.join(", ")}]`}</p>
                  <p>{`Uploaded file: ${file.type}`}</p>
                  <p>{`Max size: 2MB, Uploaded size: ${(
                    file.size /
                    (1024 * 1024)
                  ).toFixed(1)}MB`}</p>
                </div>
              ),
              status: "error",
              position: "top-right",
              duration: 6000,
              isClosable: true,
            });
            goodToGo = false;
            return;
          }
        });
      } else if (userData?.dataType == "Video") {
        if (selectedFile.length < 2 || selectedFile.length > 10) {
          toast({
            title: "Error",
            description: "Please upload minimum 2 and maximum 10 files",
            status: "error",
            position: "top-right",
            duration: 6000,
            isClosable: true,
          });
          goodToGo = false;
          return;
        }
        await Promise.all(
          selectedFile.map(async (file) => {
            if (file.type.startsWith("video/")) {
              const video = document.createElement("video");
              video.preload = "metadata";
              const loadMetadata = new Promise((resolve, reject) => {
                video.onloadedmetadata = resolve;
                video.onerror = reject;
              });
              video.src = URL.createObjectURL(file);

              try {
                await loadMetadata;
                window.URL.revokeObjectURL(video.src);

                if (
                  video.duration > 15 * 60 ||
                  video.videoWidth > 1080 ||
                  video.videoHeight > 720 ||
                  file.size > 200 * 1024 * 1024
                ) {
                  toast({
                    title: "Error",
                    description: (
                      <div>
                        <p>{`Image name: ${file.name}`}</p>
                        {video.duration > 15 * 60 && (
                          <p>{`Video duration ${video.duration} exceeds the allowed duration of 15 mins`}</p>
                        )}
                        {(video.videoWidth > 1080 ||
                          video.videoHeight > 720) && (
                          <p>{`Video dimension ${
                            video.videoWidth + "x" + video.videoHeight
                          } exceeds the required dimensions of 1080x720`}</p>
                        )}
                        {file.size > 200 * 1024 * 1024 && (
                          <p>{`Uploaded size: ${(
                            file.size /
                            (1024 * 1024)
                          ).toFixed(1)}MB exceeds the max size of 200MB`}</p>
                        )}
                      </div>
                    ),
                    status: "error",
                    position: "top-right",
                    duration: 6000,
                    isClosable: true,
                  });
                  goodToGo = false;
                  return;
                }
              } catch (error) {
                console.error("Error loading video metadata:", error);
                goodToGo = false;
                return;
              }
            } else {
              toast({
                title: "Error",
                description: (
                  <div>
                    <p>{`File name: ${file.name}`}</p>
                    <p>{`Accepted formats: [${acceptedTypes.join(", ")}]`}</p>
                    <p>{`Uploaded file: ${file.type}`}</p>
                  </div>
                ),
                status: "error",
                position: "top-right",
                duration: 6000,
                isClosable: true,
              });
              goodToGo = false;
              return;
            }
          })
        );
      }
      if (goodToGo) {
        setLoading(true);
        if (userData?.dataType == "Image") UploadFiles(selectedFile);
        else if (userData?.dataType == "Video") setVideoSelection(true);
        handleChange("savedFiles", selectedFile);
      } else {
        fileInputRef.current.value = null;
        folderInputRef.current.value = null;
      }
      console.log(goodToGo);
    }
  };

  const UploadFiles = async (data) => {
    const param = {
      projectId: userData.projectId,
    };
    setProgressState((prev) => ({ ...prev, open: true, state: 1 }));
    const promise = new Promise(async (resolve, reject) => {
      let chunkSize = userData?.chunkSize;
      const totalChunks = Math.ceil(data.length / chunkSize);
      let completedChunks = 0;
      for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSize;
        const end = (i + 1) * chunkSize;
        const filesChunk = data.slice(start, end);
        let requestData = new FormData();
        filesChunk.map((x) => {
          requestData.append("files", x);
        });
        await axios
          .post(baseURL + "selfserve/v1/project/v1/file/upload/", requestData, {
            params: param,
            headers: {
              "Content-Type": "multipart/form-data",
              "X-Auth-Token": auth,
            },
          })
          .then((response) => {
            completedChunks++;
            if (completedChunks == totalChunks) {
              resolve(200);
              setProgressState((prev) => ({ ...prev, state: 2 }));
              setLoading(false);
              setUSerData((prev) => {
                let updateData = { ...prev };
                updateData["uploadedFiles"] = response.data?.dataset;
                draftPostApi(updateData, activeStep, toast, auth);
                return updateData;
              });
            }
          })
          .catch((error) => {
            console.log(error);
            reject(error);
            setProgressState((prev) => ({ ...prev, state: 0 }));
            setLoading(false);
          });
      }
    });
  };

  const getVideoFrames = async (resolve, reject, clear) => {
    const param = {
      projectId: userData?.projectId,
    };
    try {
      const response = await axios.get(
        baseURL + "selfserve/v1/project/v1/video/status/",
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.status == 200) {
        if (Object.keys(response?.data?.dataset || {})?.length > 0) {
          handleChange("uploadedFiles", response?.data?.dataset);
          resolve();
          setProgressState((prev) => ({ ...prev, state: 2 }));
          setLoading(false);
          if (clear) clear();
        }
      }
    } catch (error) {
      console.log(error);
      reject();
      setProgressState((prev) => ({ ...prev, state: 0 }));
      setLoading(false);
      if (clear) clear();
    }
  };

  useEffect(() => {
    if (activeStep == 2 && userData?.videoUploaded) {
      let intervalId;
      setLoading(true);
      setProgressState((prev) => ({ ...prev, open: true, state: 1 }));
      const promise = new Promise((resolve, reject) => {
        getVideoFrames(resolve, reject);
        intervalId = setInterval(() => {
          getVideoFrames(resolve, reject, () => clearInterval(intervalId));
        }, 10 * 1000);
      });
      return () => clearInterval(intervalId);
    }
  }, [userData?.videoUploaded]);

  return (
    <>
      {" "}
      {/*Pointers */}
      <div className="text-[#AEA9B1] text-sm flex gap-3 items-start whitespace-normal">
        <ul style={{ listStyleType: "disc", marginLeft: "1.5em" }}>
          <li>Ensures non-upload of duplicate images</li>
          <li>Supports up to 1000 images</li>
          <li>Provides 5 free labels</li>
        </ul>
        <ul style={{ listStyleType: "disc", marginLeft: "1.5em" }}>
          <li>
            For Classification ensure minimum 15 annotation per label, 30 files
            per label
          </li>
          <li>
            For Segment/Detect ensure minimum 20 annotation per label, 30 files
            per label
          </li>
        </ul>
      </div>
      {/*Upload data*/}
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1">
          <p className="text-[#79767D] text-sm font-medium">
            Upload input data
            <span className="text-[#DC362E]">*</span>
          </p>
          <p className="text-[#AEA9B1] text-sm">
            Supported formats: {format[userData.dataType]?.join(", ")}
          </p>
        </div>
        <div className="flex flex-col sm:flex-row gap-4 text-[#AEA9B1] text-sm items-start sm:items-center whitespace-nowrap">
          <input
            ref={fileInputRef}
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange}
            multiple
          />
          <input
            ref={folderInputRef}
            type="file"
            style={{ display: "none" }}
            onChange={handleFileChange}
            directory=""
            webkitdirectory=""
            multiple
          />
          <div className="flex flex-col gap-1">
            <SecondaryButton
              Icon={<img src="/selfServiceIcons/upload.svg" alt="upload" />}
              text={"Upload files"}
              width={"130px"}
              onClick={() => {
                fileInputRef.current.click();
              }}
              disable={disable || loading}
            />
            <SecondaryButton
              Icon={<img src="/selfServiceIcons/upload.svg" alt="upload" />}
              text={"Upload folder"}
              width={"130px"}
              onClick={() => {
                folderInputRef.current.click();
              }}
              disable={disable || loading}
            />
          </div>
          <p>or</p>
          <div className="flex flex-col gap-1">
            <div className="flex items-center gap-0">
              <TextButton
                text={"Link Bucket"}
                width={"90px"}
                disable={disable || loading}
                onClick={() => {
                  handleChange("bucketLink", bucketRef?.current?.value);
                  LinkBucket(bucketRef?.current?.value);
                }}
              />
              <div style={{ width: "fit-content" }}>
                <Input
                  type="text"
                  placeholder="Supported Aws"
                  isReadOnly={disable}
                  ref={bucketRef}
                  defaultValue={userData?.bucketLink}
                />
              </div>
            </div>
            {userData?.dataType == "Video" && (
              <div className="flex items-center gap-0">
                <TextButton
                  text={"Link RTSP"}
                  width={"90px"}
                  disable={disable || loading}
                  onClick={() => {
                    handleChange("rtspLink", rtspRef?.current?.value);
                    LinkRtsp();
                  }}
                />
                <div style={{ width: "fit-content" }}>
                  <Input
                    type="text"
                    ref={rtspRef}
                    defaultValue={userData?.rtspLink}
                    placeholder="Supported rtsp"
                    isReadOnly={disable}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {progressState?.open && progressState?.state && (
        <CommonProgressBar
          openModal={progressState?.open}
          closeModal={() =>
            setProgressState((prev) => ({
              ...prev,
              open: false,
              state: null,
            }))
          }
          state={progressState?.state}
        />
      )}
    </>
  );
};

export default UploadHandler;
