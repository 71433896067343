import {
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Textarea,
  useToast,
  Checkbox,
  CheckboxGroup,
} from "@chakra-ui/react";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import TonalButton from "../../../util/Buttons/TonalButton";
import { useEffect, useState, useContext, useRef } from "react";
import UploadImage from "../../../util/UploadImage/UploadImage";
import axios from "axios";
import { baseURL } from "../../..";
import NavContext from "../../NavContext";

const AddNewExpertModal = ({ isOpen, onClose, data, mode }) => {
  const { auth } = useContext(NavContext);
  const expertNameRef = useRef(null);
  const phoneRef = useRef(null);
  const expertEmailRef = useRef(null);
  const altPhoneRef = useRef(null);
  const expertSecEmailRef = useRef(null);
  const dataExpertId = data?.expertId;
  const [validationErrors, setValidationErrors] = useState({
    expertName: "",
    expertEmail: "",
    phone: "",
    altPhone: "",
    designation: "",
    experience: "",
    industry: "",
    org: "",
    education: "",
  });
  const [logo, setLogo] = useState(null);
  const [phoneCodes, setPhoneCodes] = useState({
    phone: data?.phoneCode || "+91",
    alternate: data?.altPhoneCode || "+91",
  });

  const [logoPreview, setLogoPreview] = useState(null);
  const toast = useToast();
  const today = new Date().toISOString().slice(0, 10);
  const oneYearFromNow = new Date(today);
  oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() + 1);
  const [formData, setFormData] = useState({
    expertName: data?.expertName || "",
    expertEmail: data?.expertEmail || "",
    designation: data?.designation || "",
    speciality: data?.speciality || "",
    industry: data?.industry || "",
    experience: data?.experience || "",
    education: data?.education || "",
    phone: data?.phoneNumber || "",
    altEmail: data?.alternateEmail || "",
    altPhone: data?.altPhoneNumber || "",
    org: data?.currPastOrganization || "",
    checked: data?.aggreement || false,
    isDeleted: data?.isDeleted || false,
    // isAgreementChecked:false,
    startDate: data?.contractStartDate
      ? new Date(data?.contractStartDate).toISOString().slice(0, 10)
      : today,
    endDate: data?.contractEndDate
      ? new Date(data?.contractEndDate).toISOString().slice(0, 10)
      : oneYearFromNow.toISOString().slice(0, 10),
  });

  // const [startDate, setStartDate] = useState(
  //   (data?.startDate && new Date(data?.startDate).toISOString().slice(0, 10)) ||
  //   null
  // );
  // const [endDate, setEndDate] = useState(
  //   (data?.endDate && new Date(data?.endDate).toISOString().slice(0, 10)) ||
  //   null
  // );

  const countryCodes = ["+91", "+1", "+44", "+81"];

  const dataOptions = {
    industry: {
      type: [
        "Metal",
        "Pharmaceutical",
        "Construction",
        "Automotive",
        "Consumer goods",
        "Energy",
        "Healthcare",
        "Aerospace",
        "Chemical",
        "Food & Beverage",
        "Others",
      ],
    },
  };
  const handleDiscard = () => {
    onClose();
    // setFormData({});
  };

  function convertToEpoch(dateString) {
    // Convert the date string to a JavaScript Date object
    const dateObject = new Date(dateString);

    // Set the time to the end of the day
    dateObject.setHours(23, 59, 59, 999);

    // Get the epoch time (Unix timestamp) in milliseconds
    const epochTimeMilliseconds = dateObject.getTime();

    // Convert milliseconds to seconds (as Unix timestamps are usually in seconds)
    // const epochTimeSeconds = Math.floor(epochTimeMilliseconds / 1000);

    return epochTimeMilliseconds;
  }

  const handleAutoPopulateEndDate = (startDateValue) => {
    const startDate = new Date(startDateValue);
    const endDate = new Date(startDate);

    // Set the end date to one year ahead
    endDate.setFullYear(startDate.getFullYear() + 1);

    // Format the end date as "YYYY-MM-DD"
    return endDate.toISOString().slice(0, 10);
  };

  const handleCheckBoxChange = (e) => {
    const isCkecked = e.target.checked;
    setFormData((prevData) => ({
      ...prevData,

      checked: isCkecked,
    }));
  };
  const isValidEmail = (email) => {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email.trim());
  };

  const isValidName = (name) => {
    // Regular expression for a simple name validation
    const nameRegex = /^[a-zA-Z ]{2,50}$/;
    return nameRegex.test(name.trim());
  };

  const handleSave = async () => {
    // Validate each input field and update validationErrors state
    const newValidationErrors = {};
    if (!formData.expertName || !isValidName(formData.expertName)) {
      newValidationErrors.expertName = "Please enter a valid name";
    }
    if (!formData.expertEmail || !isValidEmail(formData.expertEmail)) {
      newValidationErrors.expertEmail = "Please enter a valid email";
    }
    // Validation for phone number
    if (!formData.phone || !/^\d+$/.test(formData.phone.trim())) {
      newValidationErrors.phone = "Enter a valid numeric phone number";
    } else {
      const phoneNumber = formData.phone.trim(); // Remove leading/trailing whitespaces
      const minPhoneLength = 8; // Minimum allowed phone number length
      const maxPhoneLength = 13; // Maximum allowed phone number length

      if (
        phoneNumber.length < minPhoneLength ||
        phoneNumber.length > maxPhoneLength
      ) {
        newValidationErrors.phone = `Phone must be ${minPhoneLength}-${maxPhoneLength} digits`;
      }
    }

    // Check if alternate email is the same as email
    if (formData.expertEmail.trim() != "") {
      if (
        formData.altEmail.trim().toLowerCase() ===
        formData.expertEmail.trim().toLowerCase()
      ) {
        newValidationErrors.altEmail =
          "Alt email should be different from the primary email";
      }
    }

    // Check if alternate phone is the same as phone, including country codes
    if (formData.phone.trim() != "") {
      if (
        formData.altPhone.trim() === formData.phone.trim() &&
        phoneCodes.alternate === phoneCodes.phone
      ) {
        newValidationErrors.altPhone =
          "Alt phone should be different from the primary phone";
      }
    }

    // Check for length in data?.currPastOrganization
    if (formData.org.length > 100) {
      newValidationErrors.org =
        "Organization should be less than 100 characters";
    }

    // Validation for alternate email
    if (formData.altEmail.trim() !== "") {
      if (!isValidEmail(formData.altEmail.trim())) {
        newValidationErrors.altEmail = "Please enter a valid email";
      }
    }

    // Validation for alternate phone number
    if (formData.altPhone.trim() !== "") {
      if (!/^\d+$/.test(formData.altPhone.trim())) {
        newValidationErrors.altPhone =
          "Please enter a valid numeric phone number";
      } else {
        const altPhoneNumber = formData.altPhone.trim();

        if (altPhoneNumber.length < 8 || altPhoneNumber.length > 15) {
          newValidationErrors.altPhone = `Phone must be between 8 and 15 digits`;
        }
      }
    }

    // Check for length
    if (formData.experience.length > 200) {
      newValidationErrors.experience =
        "Experience should be less than 200 characters";
    }

    // Check for length in education
    if (formData.education.length > 100) {
      newValidationErrors.education =
        "Education should be less than 100 characters";
    }

    setValidationErrors(newValidationErrors);

    // If there are any validation errors, prevent form submission
    if (Object.keys(newValidationErrors).length > 0) {
      // Check for specific field errors
      if (newValidationErrors.expertName) {
        expertNameRef.current.focus();
        return;
      }

      if (newValidationErrors.expertEmail) {
        expertEmailRef.current.focus();
        return;
      }

      if (newValidationErrors.phone) {
        phoneRef.current.focus();
        return;
      }

      if (newValidationErrors.altEmail) {
        expertSecEmailRef.current.focus();
        return;
      }

      if (newValidationErrors.altPhone) {
        altPhoneRef.current.focus();
        return;
      }

      return;
    }

    const data1 = {
      expertName: formData.expertName.trim(),
      expertEmail: formData.expertEmail.trim(),
      designation: formData.designation,
      speciality: formData.industry,
      industry: formData.industry,
      experience: formData.experience,
      education: formData.education,
      phoneNumber: formData.phone,
      phoneCode: phoneCodes.phone,
      alternateEmail: formData.altEmail,
      altPhoneNumber: formData.altPhone,
      altPhoneCode: phoneCodes.alternate,
      currPastOrganization: formData.org,
      contractStartDate: convertToEpoch(formData.startDate),
      contractEndDate: convertToEpoch(formData.endDate),
      aggreement: formData.checked,
      status: 0,
      isDeleted: false,
    };
    // console.log("data1", data1);

    const data2 = {
      expertId: dataExpertId,
      expertName: formData.expertName?.trim(),
      expertEmail: formData.expertEmail?.trim(),
      designation: formData.designation,
      speciality: formData.industry,
      industry: formData.industry,
      experience: formData.experience,
      education: formData.education,
      phoneNumber: formData.phone,
      phoneCode: phoneCodes.phone,
      alternateEmail: formData.altEmail,
      altPhoneNumber: formData.altPhone,
      altPhoneCode: phoneCodes.alternate,
      currPastOrganization: formData.org,
      contractStartDate: convertToEpoch(formData.startDate),
      contractEndDate: convertToEpoch(formData.endDate),
      aggreement: formData.checked,
    };

    if (formData.isDeleted) {
      data2.isDeleted = true;
    } else {
      data2.isDeleted = false;
    }

    // console.log("data2", data2);

    let json;
    if (mode === "add") {
      json = JSON.stringify(data1);
    } else json = JSON.stringify(data2);
    const blob = new Blob([json], {
      type: "application/json",
    });
    const FormData = require("form-data");
    let data = new FormData();
    data.append("data", blob);
    // data.append("file1", logo);
    data.append("file", logo);

    let configAdd = {
      method: "post",
      maxBodyLength: Infinity,
      url: baseURL + "experts/v1/add/experts",
      headers: {
        "X-Auth-Token": auth,
      },
      data: data,
    };

    let configUpdate = {
      method: "patch",
      maxBodyLength: Infinity,
      url: baseURL + "experts/v1/update/experts",
      //   "product/v1/update/productGroup"
      //params: {productIdUuid: selectedProductGroup.productIdUuid},
      headers: {
        "X-Auth-Token": auth,
      },
      data: data,
    };

    let config;
    if (mode === "update") config = configUpdate;
    else config = configAdd;

    axios
      .request(config)
      .then((response) => {
        if (mode === "add") {
          toast({
            title: `${formData.expertName} added successfully`,
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top",
          });
        } else {
          toast({
            title: `${formData.expertName} updated successfully`,
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top",
          });
        }

        onClose();
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: `${error?.response?.data?.data}`,
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
      });
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    // Clear the error for the corresponding field
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    // Handle auto-populating end date logic
    if (name === "startDate") {
      const endDateValue = handleAutoPopulateEndDate(value);

      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
        endDate: endDateValue,
      }));
    } else {
      // Update the form data for other fields
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  // const handleDeactivateToggle = (e) => {
  //   const isChecked = e.target.checked;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     isDeleted: isChecked,
  //   }));

  //   // console.log(e.target);
  // };

  useEffect(() => {
    setLogoPreview(data?.imageUrl);
    setLogo(data?.imageUrl);
  }, [data]);

  console.log(formData);
  console.log(phoneCodes);
  // console.log(data?.aggreement);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered size={"sm"} width={740}>
      <ModalOverlay />
      <ModalContent>
        <div className="text-white w-full h-10 flex bg-[#2660B6] font-semibold justify-center items-center rounded-t-md">
          {
            mode === "add"
              ? "Add Expert Details"
              : mode === "update"
              ? "Update Expert Details"
              : mode === "view"
              ? "View Expert Details"
              : null /* Handle other modes as needed */
          }
        </div>
        <ModalCloseButton className="text-white" />
        <ModalBody
          className="mt-6 pb-0 mb-0"
          mb={mode === "view" ? "20px" : ""}
        >
          <Flex flexDirection={"column"} gap={"10px"}>
            <FormControl className="" isRequired={mode != "view"}>
              <FormLabel className="!text-xs !text-[#2660B6] !mb-2 !font-semibold">
                Expert Name
              </FormLabel>
              <input
                value={formData?.expertName}
                name="expertName"
                ref={expertNameRef}
                placeholder="Enter Name"
                className="w-full border rounded text-sm border-[#938F96] py-2 px-5"
                onChange={handleChange}
                disabled={mode === "view" || formData.isDeleted}
              />

              {validationErrors?.expertName && (
                <p className=" !text-[red] !text-sm">
                  {validationErrors?.expertName}
                </p>
              )}
            </FormControl>
            <FormControl className="" isRequired={mode != "view"}>
              <FormLabel className="!text-xs !text-[#2660B6] !mb-2 !font-semibold">
                Email ID
              </FormLabel>
              <input
                value={formData?.expertEmail}
                name="expertEmail"
                ref={expertEmailRef}
                placeholder="Enter Email ID"
                className="w-full border rounded text-sm border-[#938F96] py-2 px-5"
                onChange={handleChange}
                disabled={mode === "view" || formData.isDeleted}
              />
              {validationErrors?.expertEmail && (
                <p className=" !text-[red] !text-sm">
                  {validationErrors?.expertEmail}
                </p>
              )}
            </FormControl>
            <FormControl className="" isRequired={mode != "view"}>
              <FormLabel className="!text-xs !text-[#2660B6] !mb-2 !font-semibold">
                Phone number
              </FormLabel>

              <div className="flex">
                <select
                  // border={"none"}

                  value={phoneCodes.phone}
                  onChange={(e) => {
                    setPhoneCodes((prev) => ({
                      ...prev,
                      phone: e.target.value,
                    }));
                  }}
                  className=" p-2 rounded-md border border-gray-400"
                  disabled={mode === "view" || formData.isDeleted}
                >
                  {countryCodes.map((code) => (
                    <option key={code} value={code}>
                      {code}
                    </option>
                  ))}
                </select>
                <input
                  value={formData?.phone}
                  name="phone"
                  type="number"
                  ref={phoneRef}
                  placeholder="Enter Phone no."
                  className="w-full border rounded text-sm border-[#938F96] py-2 px-5"
                  onChange={handleChange}
                  disabled={mode === "view" || formData.isDeleted}
                />
              </div>
              {validationErrors?.phone && (
                <p className=" !text-[red] !text-sm">
                  {validationErrors?.phone}
                </p>
              )}
            </FormControl>
            <FormControl className="">
              <FormLabel className="!text-xs !text-[#2660B6] !mb-2 !font-semibold">
                Alternate Email ID
              </FormLabel>
              <input
                value={formData?.altEmail}
                name="altEmail"
                ref={expertSecEmailRef}
                placeholder="Enter Alternate Email ID."
                className="w-full border rounded text-sm border-[#938F96] py-2 px-5"
                onChange={handleChange}
                disabled={mode === "view" || formData.isDeleted}
              />
              {validationErrors?.altEmail && (
                <p className=" !text-[red] !text-sm">
                  {validationErrors?.altEmail}
                </p>
              )}
            </FormControl>
            <FormControl className="">
              <FormLabel className="!text-xs !text-[#2660B6] !mb-2 !font-semibold">
                Alternate phone number
              </FormLabel>

              <div className="flex">
                <select
                  // border={"none"}

                  value={phoneCodes.alternate}
                  onChange={(e) => {
                    setPhoneCodes((prev) => ({
                      ...prev,
                      alternate: e.target.value,
                    }));
                  }}
                  className=" p-2 rounded-md border border-gray-400"
                  disabled={mode === "view" || formData.isDeleted}
                >
                  {countryCodes.map((code) => (
                    <option key={code} value={code}>
                      {code}
                    </option>
                  ))}
                </select>
                <input
                  value={formData?.altPhone}
                  name="altPhone"
                  type="number"
                  ref={altPhoneRef}
                  placeholder="Enter Alternate Phone no."
                  className="w-full border rounded text-sm border-[#938F96] py-2 px-5"
                  onChange={handleChange}
                  disabled={mode === "view" || formData.isDeleted}
                />
              </div>
              {validationErrors?.altPhone && (
                <p className=" !text-[red] !text-sm">
                  {validationErrors?.altPhone}
                </p>
              )}
            </FormControl>
            <div>
              <p className="!text-xs !text-[#2660B6] !mb-2 !font-semibold">
                {mode === "view"
                  ? "Experts's Profile Image"
                  : "Upload Experts's Profile Image"}
              </p>
              <div className="flex items-center">
                {(mode === "update" || mode === "add") &&
                  formData.isDeleted != true && (
                    <UploadImage
                      logo={logo}
                      setLogo={setLogo}
                      setLogoPreview={setLogoPreview}
                      buttontext={"Upload image"}
                    />
                  )}
                {logoPreview ? (
                  <div className="w-[50px] h-[50px] ml-2">
                    <img
                      src={logoPreview}
                      alt="Logo Preview"
                      className="w-full"
                    />
                  </div>
                ) : (
                  mode == "view" && (
                    <div className="w-[50px] h-[50px] ml-2">
                      <img
                        src="/Common/exp_placeholder.jpg"
                        alt="Logo Preview"
                        className="w-full"
                      />
                    </div>
                  )
                )}
                {/* {data?.imageUrl } */}
              </div>
            </div>

            <FormControl className="">
              <FormLabel className="!text-xs !text-[#2660B6] !mb-2 !font-semibold">
                Current / Past Designation
              </FormLabel>

              <select
                onChange={handleChange}
                name="designation"
                value={formData.designation}
                className="w-full overflow-auto border rounded text-sm border-[#938F96] py-2 px-1"
                disabled={mode === "view" || formData.isDeleted}
                // style={{ height: '150px', overflowY: 'auto' }}
              >
                <option value={""}>Select one</option>
                <option value={"CXO"}>CXO</option>

                <option value={"Senior Director"}>Senior Director</option>
                <option value={"Associate Director"}>Associate Director</option>
                <option value={"Director"}>Director</option>
                <option value={"President"}>President</option>

                <option value={"Senior Vice President"}>
                  Senior Vice President
                </option>
                <option value={"Vice President"}>Vice President</option>
                <option value={"Associate Vice President"}>
                  Associate Vice President
                </option>
                <option value={"Senior Partner"}>Senior Partner</option>

                <option value={"Associate Partner"}>Associate Partner</option>
                <option value={"Partner"}>Partner</option>

                <option value={"Manager"}>Manager</option>
                <option value={"Senior Manager"}>Senior Manager</option>
                <option value={"Plant Head"}>Plant Head</option>
                <option value={"Shift Manager"}>Shift Manager</option>
                <option value={"Plant Operator"}>Plant Operator</option>
                <option value={"Business Analyst"}>Business Analyst</option>
                <option value={"Consultant"}>Consultant</option>
                <option value={"Corporate Staff"}>Corporate Staff</option>
                <option value={"IT Analyst"}>IT Analyst</option>
                <option value={"IT Developer"}>IT Developer</option>
                <option value={"IT Manager"}>IT Manager</option>
                <option value={"Plant Manager"}>Plant Manager</option>
                <option value={"Mill Operator"}>Mill Operator</option>
                <option value={"Automation Team"}>Automation Team</option>
                <option value={"Automation Staff"}>Automation Staff</option>
                <option value={"Equipment Operator"}>Equipment Operator</option>
                <option value={"Device Operator"}>Device Operator</option>
                <option value={"Other"}>Other</option>
              </select>
            </FormControl>
            <FormControl className="">
              <FormLabel className="!text-xs !text-[#2660B6] !mb-2 !font-semibold">
                Current / Past Organization
              </FormLabel>
              <input
                value={formData?.org}
                name="org"
                placeholder="Enter Current/Past Organization"
                className="w-full border rounded text-sm border-[#938F96] py-2 px-5"
                onChange={handleChange}
                disabled={mode === "view" || formData.isDeleted}
              />
              {validationErrors?.org && (
                <p className=" !text-[red] !text-sm">{validationErrors?.org}</p>
              )}
            </FormControl>
            <FormControl className="!mb-0" mb={0}>
              <FormLabel className="!text-xs !text-[#2660B6] !font-semibold">
                Experience
              </FormLabel>

              <textarea
                value={formData?.experience}
                name="experience"
                placeholder="Enter Experience"
                className="w-full border rounded text-sm border-[#938F96] py-2 px-2 m-0 h-20"
                onChange={handleChange}
                disabled={mode === "view" || formData.isDeleted}
              />
              {validationErrors?.experience && (
                <p className=" !text-[red] !text-sm">
                  {validationErrors?.experience}
                </p>
              )}
            </FormControl>
            <FormControl className=" !mt-0" mt={0}>
              <FormLabel className="!text-xs !text-[#2660B6] !mt-0 !mb-2 !font-semibold">
                Industry / Speciality
              </FormLabel>

              <select
                value={formData?.industry}
                name="industry"
                onChange={handleChange}
                className="w-full p-2 rounded-md border border-gray-400 overflow-y-auto"
                disabled={mode === "view" || formData.isDeleted}
              >
                <option value="" disabled selected>
                  Select one
                </option>
                {dataOptions.industry.type?.map((value) => (
                  <option value={value}>{value}</option>
                ))}
              </select>
            </FormControl>

            <FormControl className="">
              <FormLabel className="!text-xs !text-[#2660B6] !mb-2 !font-semibold">
                Education
              </FormLabel>

              <input
                value={formData?.education}
                name="education"
                placeholder="Enter Education"
                className="w-full border rounded text-sm border-[#938F96] py-2 px-5"
                onChange={handleChange}
                disabled={mode === "view" || formData.isDeleted}
              />
              {validationErrors?.education && (
                <p className=" !text-[red] !text-sm">
                  {validationErrors?.education}
                </p>
              )}
            </FormControl>

            {((mode === "view" && formData?.checked) ||
              mode === "update" ||
              mode === "add") && (
              <Checkbox
                isChecked={formData?.checked}
                onChange={handleCheckBoxChange}
                isDisabled={mode === "view" || formData.isDeleted}
              >
                Signed agreement with Ripik
              </Checkbox>
            )}

            {formData.checked && (
              <div className="flex gap-2">
                <div>
                  <p className="!text-xs !text-[#2660B6] !mb-2 !font-semibold">
                    Contract Start date
                  </p>
                  <input
                    text=""
                    type="date"
                    name="startDate"
                    value={formData?.startDate}
                    onChange={handleChange}
                    className={` ${"w-full"} border rounded text-sm border-[#938F96] py-2 px-1 $
                  // prodCategory.length > 0 ? "border-none border-[#AEA9B1]" : ""
                `}
                    disabled={mode === "view" || formData.isDeleted}
                  />
                </div>

                <div>
                  <p className="!text-xs !text-[#2660B6] !mb-2 !font-semibold">
                    Contract End date
                  </p>
                  <input
                    text=""
                    name="endDate"
                    type="date"
                    value={formData?.endDate}
                    onChange={handleChange}
                    className={` ${"w-full"} border rounded text-sm border-[#938F96] py-2 px-1 $
                  // prodCategory.length > 0 ? "border-none border-[#AEA9B1]" : ""
                `}
                    disabled={mode === "view" || formData.isDeleted}
                  />
                </div>
              </div>
            )}

            {/* {mode == "update" && (
              <>
                <Checkbox
                  isChecked={formData?.isDeleted}
                  onChange={handleDeactivateToggle}
                  // onClick={handleCheckboxClick}
                  isDisabled={formData.checked && formData.isDeleted != true}
                  mr={"4px"}
                >
                  Deactivate
                </Checkbox>
                {formData.checked && (
                  <p className="text-sm text-[#938F96]">
                    Please uncheck signed agreement to deactivate
                  </p>
                )}
              </>
            )} */}
          </Flex>
        </ModalBody>

        <ModalFooter
          className="!w-full !flex !flex-row !items-start !justify-start !gap-2"
          paddingY={0}
        >
          {mode != "view" && (
            <div className="flex justify-center items-center gap-3 mt-5">
              <button
                onClick={handleSave}
                className="bg-[#084298] text-white px-7 py-2 rounded-md mb-5 w-[120px]"
                mr={3}
              >
                Save
              </button>
              <button
                onClick={handleDiscard}
                className="bg-[#DDEEFF] text-[#3A74CA] px-7 py-2 rounded-md mb-5 w-[120px]"
                mr={3}
              >
                Discard
              </button>
            </div>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddNewExpertModal;
