import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Slider,
  SliderMark,
  SliderFilledTrack,
  SliderTrack,
  SliderThumb,
  useToast,
  Spinner,
  IconButton,
} from "@chakra-ui/react";
import { useState, useContext, useEffect } from "react";
import NavContext from "../../NavContext";
import { overallPermeabilityIndex } from "../SinterBelt";
import {
  getAlertParamsValueApi,
  updateAlertParamsApi,
} from "../services/alertsServices";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { Close } from "@mui/icons-material";

const ConstraintModal = ({
  openModal,
  closeModal,
  plantName,
  material,
  clientId,
}) => {
  const [alertValue, setAlertValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const { auth } = useContext(NavContext);
  const toast = useToast();
  const getData = () => {
    const requestBody = JSON.stringify({
      clientId: clientId,
      useCase: material.toUpperCase(),
      plantName: plantName,
      cameraId: "all",
    });
    getAlertParamsValueApi(auth, requestBody, setLoading)
      .then((res) => {
        console.log(res);
        if (res.success) {
          setAlertValue((prev) => res.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleConfirmClick = () => {
    const requestData = JSON.stringify({
      clientId: clientId,
      useCase: material.toUpperCase(),
      plantName: "chanderia",
      alertParam: alertValue,
      id: "65a7bb0eaa9c76b454056c13",
    });

    updateAlertParamsApi(auth, requestData)
      .then((res) => {
        // console.log(res);
        if (res.success) {
          toast({
            title: res?.message,
            description: "Alert limit changed to " + alertValue,
            status: "success",
            duration: 3000,
            isClosable: true,
          });
          closeModal();
        } else {
          toast({
            title: res?.message,
            status: "error",
            duration: 3000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: "Error while Updating",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      isOpen={openModal}
      onClose={closeModal}
      isCentered="true"
    >
      <ModalOverlay />
      <ModalContent
        style={{ borderRadius: "12px" }}
        maxW="300px"
      >
        <ModalHeader
          padding="0px"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          position={"relative"}
        >
          <IconButton
            position={"absolute"}
            top={-5}
            rounded={"full"}
            bg={"white"}
            cursor={"pointer"}
            icon={<Close sx={{ color: "gray" }} />}
            onClick={closeModal}
            boxShadow={"md"}
          />
        </ModalHeader>
        <ModalBody px="24px">
          <div className="flex flex-col gap-4">
            <p className="text-sm font-semibold">Alert Limit</p>
            <Slider
              aria-label="slider-ex-6"
              w={"full"}
              min={1}
              max={5}
              step={1}
              mt={"20px"}
              mb={"20px"}
              onChange={(val) => setAlertValue(val)}
              value={alertValue}
            >
              {[...Array(5)].map((i, b) => {
                var idx = b + 1;
                return (
                  <SliderMark
                    value={idx}
                    mt={1}
                    // ml={-0.5}
                    fontSize={"sm"}
                    // hidden={idx == alertVal}
                  >
                    {idx}
                  </SliderMark>
                );
              })}
              <SliderMark
                value={alertValue}
                textAlign="center"
                bg="#084298"
                color="white"
                mt="-9"
                px={1}
                rounded={3}
              >
                {overallPermeabilityIndex[alertValue]}
              </SliderMark>
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb />
            </Slider>
            <div className="self-end">
              <PrimaryButton
                onClick={handleConfirmClick}
                text={loading ? <Spinner /> : "Confirm"}
              />
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConstraintModal;
