import { Select, Spinner } from "@chakra-ui/react";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import React, { useContext, useEffect, useRef, useState } from "react";
import TableSkeleton from "./LoadingState/TableSkeleton";
import {
  CustomFooter,
  CustomStyledDataGrid,
} from "../../../util/MaterialDataGrid/CustomStyledDatagrid";
import NavContext from "../../NavContext";
import { gridPaginatedVisibleSortedGridRowEntriesSelector } from "@mui/x-data-grid";
import { useParams } from "react-router-dom";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import Lottie from "lottie-react";
import NoReportAnimationData from "../utilis/noReportLottie.json";
import {
  downloadReportDataApi,
  getPalletBoardReplacementLogDataApi,
  getReportDataApi,
} from "../services/reportServices";
import DetailModal from "./DetailModal";
import { saveAs } from "file-saver";
import { useWindowSize } from "@uidotdev/usehooks";
import {
  dataGridSlotProps,
  generateAlertReportColumns,
  generatePalletBoardReplacementLogColumns,
} from "../utilis/dataGridColumns";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";

const PalletBoardReplacementLogTableComponent = ({ plantId }) => {
  // refs and params
  const { auth } = useContext(NavContext);
  const param = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedRange, setSelectedRange] = useState("last7Days");
  const [filters, setFilters] = useState({
    selectedPlant: plantId,
    fromTime: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16),
    toTime: new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16),
  });
  const [palletBoardReplacementLogData, setPalletBoardReplacementLogData] =
    useState(null);
  const [
    palletBoardReplacementLogColumns,
    setPalletBoardReplacementLogColumns,
  ] = useState(null);
  const handleRangeSelect = (e) => {
    setSelectedRange(e.target.value);
    if (e.target.value === "last7Days") {
      setFilters((prev) => ({
        ...prev,
        fromTime: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 16),
        toTime: new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 16),
      }));
    }
  };
  const getData = async () => {
    try {
      const requestData = JSON.stringify({
        clientId: param.clientId.toLowerCase(),
        useCase: "SINTERBELT",
        cameraId: "all",
        plantName: filters.selectedPlant,
        startDate: new Date(filters.fromTime).getTime() + 5.5 * 60 * 60 * 1000,
        endDate: new Date(filters.toTime).getTime() + 5.5 * 60 * 60 * 1000,
      });

      const palletBoardReplacementLogResponse =
        await getPalletBoardReplacementLogDataApi(
          auth,
          requestData,
          setLoading
        );
      // pallet board sequecne data
      if (palletBoardReplacementLogResponse?.data) {
        const tempColumns = await generatePalletBoardReplacementLogColumns(
          palletBoardReplacementLogResponse.data.columns
        );
        setPalletBoardReplacementLogColumns((prev) => tempColumns);
        setPalletBoardReplacementLogData(
          (prev) => palletBoardReplacementLogResponse.data
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col gap-3 bg-white pt-6 rounded-xl">
      <div className="flex flex-row justify-between items-center gap-5 px-2 py-2 w-full overflow-auto">
        <p className="font-bold text-[16px] whitespace-nowrap">
          Pallet Board Replacement Log
        </p>
        <div className="flex flex-row items-center gap-2">
          <div className="min-w-[110px]">
            <Select
              borderColor="#CAC5CD"
              color="#605D64"
              value={selectedRange}
              variant="outline"
              rounded={"base"}
              className="!font-medium !text-[#605D64] !text-sm"
              onChange={(e) => handleRangeSelect(e)}
            >
              <option
                key="Last 7 days"
                value={"last7Days"}
              >
                Last 7 days
              </option>
              <option
                key="custom"
                value={"custom"}
              >
                Custom
              </option>
            </Select>
          </div>
          {selectedRange === "custom" && (
            <div className="min-w-[110px]">
              <FloatingInput
                text="From"
                type="datetime-local"
                setDateTime={(value) =>
                  setFilters((prev) => ({ ...prev, fromTime: value }))
                }
                value={filters.fromTime}
                min={"2021-01-01T00:00"}
                max={new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
                  .toISOString()
                  .slice(0, 16)}
              />
            </div>
          )}
          {selectedRange === "custom" && (
            <div className="min-w-[110px]">
              <FloatingInput
                text="To"
                type="datetime-local"
                setDateTime={(value) =>
                  setFilters((prev) => ({ ...prev, toTime: value }))
                }
                value={filters.toTime}
                min={filters.fromTime}
                max={new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
                  .toISOString()
                  .slice(0, 16)}
              />
            </div>
          )}

          <SecondaryButton
            height={"40px"}
            width={"80px"}
            text={loading.report ? <Spinner /> : "Apply"}
            onClick={getData}
            disable={!filters.fromTime || !filters.toTime || loading}
          />
        </div>
      </div>
      {loading ? (
        <TableSkeleton
          headers={[
            "Header 1",
            "Header 2",
            "Header 3",
            "Header 4",
            "Header 5",
            "Header 6",
            "Header 7",
            "Header 8",
          ]}
          rows={8}
          cellsPerRow={8}
        />
      ) : (
        palletBoardReplacementLogData?.table_data && (
          <div
            className="border rounded-md w-full"
            style={{
              height:
                108 +
                Math.min(
                  10,
                  palletBoardReplacementLogData?.table_data?.length || 0
                ) *
                  50 +
                "px",
            }}
          >
            <CustomStyledDataGrid
              rows={
                palletBoardReplacementLogData &&
                palletBoardReplacementLogData.table_data
              }
              columns={
                palletBoardReplacementLogColumns &&
                palletBoardReplacementLogColumns
              }
              rowHeight={50}
              columnHeaderHeight={40}
              disableColumnSelector={true}
              disableRowSelectionOnClick={true}
              pageSizeOptions={[10, 20, 50]}
              pagination={true}
              disableColumnReorder={true}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 10, page: 0 },
                },
              }}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0
                  ? "bg-[#FAFAFA]"
                  : "bg-white"
              }
              slots={{
                footer: CustomFooter,
              }}
              slotProps={dataGridSlotProps && dataGridSlotProps()}
              showColumnVerticalBorder
            />
          </div>
        )
      )}
    </div>
  );
};

const ReportsTableComponent = ({ plantId }) => {
  // auth params and refs
  //  refs, params
  const { width } = useWindowSize();
  let param = useParams();
  const { auth } = useContext(NavContext);
  const indexRef = useRef();
  const gridApiRef = useGridApiRef();
  //state
  const [loading, setLoading] = useState({
    report: false,
    download: false,
  });
  const [openModal, setOpenModal] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [displayData, setDisplayData] = useState([]);
  const [reportColumns, setReportColumns] = useState(null);
  const [filters, setFilters] = useState({
    selectedPlant: plantId,
    selectedBasis: 2,
    fromTime: new Date(
      new Date().getTime() - 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000
    )
      .toISOString()
      .slice(0, 16),
    toTime: new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16),
  });
  // checking date range less than four
  const dateRangeLessThanFourDays =
    (new Date(filters.toTime) - new Date(filters.fromTime)) /
      (1000 * 60 * 60 * 24) <
    4
      ? true
      : false;
  const downloadReportData = async () => {
    try {
      const requestData = JSON.stringify({
        clientId: param.clientId.toLowerCase(),
        useCase: "SINTERBELT",
        cameraId: "all",
        plantName: filters.selectedPlant,
        basis: filters.selectedBasis,
        startDate: new Date(filters.fromTime).getTime() + 5.5 * 60 * 60 * 1000,
        endDate: new Date(filters.toTime).getTime() + 5.5 * 60 * 60 * 1000,
      });
      const response = await downloadReportDataApi(
        auth,
        requestData,
        setLoading
      );
      const blob = new Blob([response.data], {
        type: "application/octet-stream",
      });
      const fileName = `Report-${filters?.fromTime.substring(
        0,
        10
      )}-to-${filters?.toTime.substring(0, 10)}.xlsx`;
      saveAs(blob, fileName);
    } catch (error) {
      console.log(error);
    }
  };

  const getReportData = async () => {
    try {
      const requestData = JSON.stringify({
        clientId: param.clientId.toLowerCase(),
        useCase: "SINTERBELT",
        cameraId: "all",
        plantName: filters.selectedPlant,
        basis: filters.selectedBasis,
        startDate: new Date(filters.fromTime).getTime() + 5.5 * 60 * 60 * 1000,
        endDate: new Date(filters.toTime).getTime() + 5.5 * 60 * 60 * 1000,
      });
      const reportResponse = await getReportDataApi(
        auth,
        requestData,
        setLoading
      );

      // report data
      if (reportResponse?.data) {
        const tempColumns = await generateAlertReportColumns(
          reportResponse.data.columns,
          handleDetail,
          true
        );
        setReportColumns((prev) => tempColumns);
        setReportData((prev) => reportResponse.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDetail = (params) => {
    const tempDisplayData =
      gridPaginatedVisibleSortedGridRowEntriesSelector(gridApiRef);
    setDisplayData((prev) => tempDisplayData);
    const index = params.api.getRowIndexRelativeToVisibleRows(params.row.id);
    indexRef.current = index;
    setOpenModal(true);
  };

  useEffect(() => {
    if (dateRangeLessThanFourDays) {
      getReportData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative">
      <div className="right-0 left-0 absolute flex justify-center">
        <div className="flex md:flex-row flex-col items-center gap-2 bg-white shadow-md p-5 pr-6 pl-6 rounded-xl">
          <div>
            <FloatingInput
              text="From"
              type="datetime-local"
              setDateTime={(value) =>
                setFilters((prev) => ({ ...prev, fromTime: value }))
              }
              value={filters.fromTime}
              min={"2021-01-01T00:00"}
              max={new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
                .toISOString()
                .slice(0, 16)}
            />
          </div>
          <div>
            <FloatingInput
              text="To"
              type="datetime-local"
              setDateTime={(value) =>
                setFilters((prev) => ({ ...prev, toTime: value }))
              }
              value={filters.toTime}
              min={filters.fromTime}
              max={new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
                .toISOString()
                .slice(0, 16)}
            />
          </div>
          <PrimaryButton
            onClick={getReportData}
            text={loading.report ? <Spinner /> : "Show report"}
            height={"100%"}
            width={"120px"}
            disable={
              dateRangeLessThanFourDays === false ||
              !filters.fromTime ||
              !filters.toTime ||
              loading.report
            }
          />
        </div>
      </div>
      <div className="bg-white mt-[160px] md:mt-11 pt-[57px] rounded-xl w-full h-full">
        <div className="flex md:flex-row flex-col md:justify-end items-end md:items-center gap-2">
          {dateRangeLessThanFourDays ? (
            <div className="flex items-center gap-3 px-2 py-1">
              <SecondaryButton
                text={loading?.download ? <Spinner /> : "Download"}
                height={"40px"}
                onClick={downloadReportData}
                disable={loading.download}
                width={"100px"}
              />
            </div>
          ) : null}
        </div>
        {dateRangeLessThanFourDays ? (
          <>
            {loading.report ? (
              <TableSkeleton
                headers={[
                  "Header 1",
                  "Header 2",
                  "Header 3",
                  "Header 4",
                  "Header 5",
                  "Header 6",
                  "Header 7",
                  "Header 8",
                ]}
                rows={10}
                cellsPerRow={8}
              />
            ) : (
              <>
                {reportData && reportData?.table_data && (
                  <div
                    className="border rounded-md w-full"
                    style={{
                      height:
                        108 +
                        Math.min(10, reportData?.table_data?.length || 0) * 50 +
                        "px",
                    }}
                  >
                    <CustomStyledDataGrid
                      apiRef={gridApiRef}
                      rows={reportData && reportData.table_data}
                      columns={reportColumns && reportColumns}
                      rowHeight={50}
                      columnHeaderHeight={50}
                      disableColumnSelector={true}
                      disableRowSelectionOnClick={true}
                      pageSizeOptions={[10, 20, 50]}
                      pagination={true}
                      disableColumnReorder={true}
                      initialState={{
                        pagination: {
                          paginationModel: { pageSize: 10, page: 0 },
                        },
                        pinnedColumns: {
                          right: width > 768 ? ["view_details"] : null,
                        },
                      }}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0
                          ? "bg-[#FAFAFA]"
                          : "bg-white"
                      }
                      slots={{
                        footer: CustomFooter,
                      }}
                      slotProps={dataGridSlotProps && dataGridSlotProps()}
                      sx={{
                        "& .MuiDataGrid-columnHeaderTitle": {
                          whiteSpace: "normal",
                          lineHeight: "normal",
                        },
                      }}
                      showColumnVerticalBorder
                    />
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <div className="flex flex-col items-center gap-0 border-[#084298] bg-[#F5F5F5] m-3 pb-5 border border-dashed rounded-md">
            <div className="w-[200px] h-[200px]">
              <Lottie
                animationData={NoReportAnimationData}
                loop={true}
              />
            </div>
            <p className="mb-5 font-bold text-[#084298]">
              Cannot display data for selected time period. Kindly download the
              report or select shorter time period.
            </p>
            <SecondaryButton
              text={loading?.download ? <Spinner /> : "Download Report"}
              disable={
                !filters.fromTime || !filters.toTime || loading?.download
              }
              width={"180px"}
              height={"40px"}
              onClick={downloadReportData}
            />
          </div>
        )}
      </div>

      {openModal && (
        <DetailModal
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          data={displayData}
          index={indexRef.current}
          plantName={filters.selectedPlant}
        />
      )}
    </div>
  );
};

const PalletBoardReplacementLogTable = React.memo(
  PalletBoardReplacementLogTableComponent
);
const ReportsTable = React.memo(ReportsTableComponent);

export { PalletBoardReplacementLogTable, ReportsTable };
