import { useContext, useState } from "react";
import NavContext from "../../NavContext";
import TonalButton from "../../../util/Buttons/TonalButton";
import { AddIcon } from "@chakra-ui/icons";
import {
  CancelOutlined,
  DeleteOutline,
  EditNote,
  Restore,
  Save,
} from "@mui/icons-material";
import {
  GridPagination,
  GridRowEditStopReasons,
  GridRowModes,
  GridToolbarContainer,
} from "@mui/x-data-grid";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { CustomStyledDataGrid } from "../../../util/MaterialDataGrid/CustomStyledDatagrid";
import { useToast } from "@chakra-ui/react";
import {
  Autocomplete,
  FormControl,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import {
  deleteParameterWeightApi,
  resetToDefaultParameterWeightApi,
  saveWeightsDataApi,
  updateParameterWeightApi,
} from "../services/advancedServices";
import ConfirmationModal from "../components/ConfirmationModal";
import { mixpanelTrackButton } from "../../../util/mixpanelFunctions";

export const CustomAutoComplete = ({ options, value, handleChange }) => {
  const MuiTheme = createTheme();
  return (
    <ThemeProvider theme={MuiTheme}>
      <FormControl
        fullWidth
        size={"small"}
      >
        <Autocomplete
          forcePopupIcon
          freeSolo={true}
          size={"small"}
          value={value || ""}
          onChange={handleChange}
          inputValue={value || ""}
          onInputChange={handleChange}
          options={options.map((item) => item)}
          isOptionEqualToValue={(option, value) => option == value}
          sx={{
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
              {
                border: "none",
              },
            "& .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input": {
              fontSize: "13px",
            },
            "& .css-rkd4jw-MuiAutocomplete-roott": {
              padding: "0px",
            },
            "& .MuiOutlinedInput-root": {},
            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
              border: "none",
            },
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant={"outlined"}
              sx={{
                ".css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input": "0px",
              }}
            />
          )}
          ListboxProps={{ style: { maxHeight: "300px", fontSize: "13px" } }}
        />
      </FormControl>
    </ThemeProvider>
  );
};

const CustomFooter = (props) => {
  const { auth } = useContext(NavContext);
  const toast = useToast();
  const {
    rows,
    setRows,
    setRowModesModel,
    setLoading,
    fetchData,
  } = props;
  const getIdForNewRow = async (data = {}) => {
    try {
      const requestData = null;
      const response = await updateParameterWeightApi(
        auth,
        requestData,
        setLoading
      );
      if (response.success) {
        return response.id;
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickAddNewRow = async () => {
    try {
      const id = await getIdForNewRow();
      if (id) {
        const lastRowIndex = rows.length - 1;
        const lastRow = rows[lastRowIndex];

        const newFields = {};
        Object.keys(lastRow).forEach((key) => {
          newFields[key] = "";
        });

        setRows((oldRows) => [ { ...newFields, id, isNew: true },...oldRows]);

        setRowModesModel((oldModel) => ({
          ...oldModel,
          [id]: {
            mode: GridRowModes.Edit,
            fieldToFocus: "weight",
          },
        }));
        // const lastPage = Math.ceil((rows.length + 1) / 10);
        // setPaginationModel((prev) => ({ ...prev, page: lastPage }));
      }
      mixpanelTrackButton("Advanced","Mulpic","Add") // Track button
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickResetToDefault = async () => {
    try {
      const response = await resetToDefaultParameterWeightApi(auth, setLoading);
      fetchData();
      console.log(response);
      mixpanelTrackButton("Advanced","Mulpic","Reset to Default") // Track button
    } catch (error) {
      console.log(error);
    }
  };
  const handleClickSaveWeights = async () => {
    try {
      const response = await saveWeightsDataApi(auth, setLoading);
      console.log(response);
      toast({
        title: "Weights data saved successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      mixpanelTrackButton("Advanced","Mulpic","Save") // Track button
    } catch (error) {
      toast({
        title: "Error while saving weights data",
        description: "Please try again...",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      console.log(error);
    }
  };

  return (
    <>
      <div className="flex flex-row gap-0 items-center min-h-[45px] justify-between border-t border-gray-200 overflow-x-scroll md:overflow-hidden">
        <div className="flex flex-row items-center gap-4">
          <GridToolbarContainer
            sx={{
              height: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              padding: "0px 0px 0px 8px",
            }}
          >
            <TonalButton
              text={"Add"}
              height={"30px"}
              onClick={handleClickAddNewRow}
              Icon={<AddIcon />}
            />
          </GridToolbarContainer>
          <div className="flex flex-row gap-4">
            <TonalButton
              text={"Reset to Default"}
              height={"30px"}
              width={"160px"}
              onClick={handleClickResetToDefault}
              Icon={<Restore />}
            />
            <TonalButton
              text={"Save"}
              height={"30px"}
              width={"80px"}
              onClick={handleClickSaveWeights}
              Icon={<Save />}
            />
          </div>
        </div>
        <div>
          <GridPagination />
        </div>
      </div>
    </>
  );
};

export const WeightsTable = ({ rows, columns, setRows, fetchData }) => {
  const { auth } = useContext(NavContext);
  const toast = useToast();
  const [rowModesModel, setRowModesModel] = useState({});
  const [loading, setLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [rowToBeDeletedId, setRowToBeDeletedId] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 8,
    page: 0,
  });

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => async () => {
    try {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      const editedRow = rows.find((row) => row.id === id);
      if (editedRow?.isNew) {
        await deleteParameterWeightApi(auth, { id }, setLoading);
        setRows(rows.filter((row) => row.id !== id));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteClick = (id) => async () => {
    setRowToBeDeletedId((prev) => id);
    setConfirmationModal((prev) => true);
  };

  const handleDeleteModalConfirmation = async () => {
    // delete the selected row
    try {
      if (rowToBeDeletedId) {
        const response = await deleteParameterWeightApi(
          auth,
          { id: rowToBeDeletedId },
          setLoading
        );
        if (response.success) {
          setRows(rows.filter((row) => row.id !== rowToBeDeletedId));
          toast({
            title: "Parameter deleted successfully",
            status: "success",
            duration: 3000,
            isClosable: true,
            position: "top-right",
          });
        }
      }
    } catch (error) {
      toast({
        title: "Error while deleting parameter",
        description: "Please try again...",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      console.log(error);
    }
  };
  const processRowUpdate = async (newRow, originalRow) => {
    try {
      let flag = true;
      for (let key in newRow){
        if(key !== "id" && key !== "isNew"){
          if(newRow[key]){
            flag = false;
            break;
          }
        }
      }
      if(flag===true){
        throw new Error("Invalid data")
      }
      const requestData = {
        ...newRow
      };
      console.log(requestData);
      const updatedRow = { ...newRow, isNew: false };
      if (requestData?.isNew) {
        delete requestData?.isNew;
      }
      const apiResponse = await updateParameterWeightApi(
        auth,
        requestData,
        setLoading
      );
      if (apiResponse?.success) {
        setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        toast({
          title: "Parameter updated successfully",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
        return updatedRow;
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Invalid data",
        description: "Please ensure that data entered is valid",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
      // return originalRow;
    }
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  // columns with action cell
  const updatedColumnsWithActionCell = [
    ...columns,
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: "150",
      cellClassName: "actions",
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<Save sx={{ color: "#3A74CA", fontSize: "24px" }} />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={
                <CancelOutlined sx={{ color: "#616161", fontSize: "24px" }} />
              }
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditNote sx={{ color: "#3A74CA", fontSize: "28px" }} />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteOutline sx={{ color: "#FF7043", fontSize: "24px" }} />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
  return (
    <div className="w-full bg-white">
      <div className="w-full border border-gray-200 rounded-md overflow-auto">
        <CustomStyledDataGrid
          rows={rows || []}
          columns={updatedColumnsWithActionCell || []}
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          rowHeight={45}
          columnHeaderHeight={45}
          disableColumnSelector={true}
          editMode={"row"}
          pagination={true}
          loading={loading}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          pageSizeOptions={[30]}
          onCellDoubleClick={(params, event) => {
            event.defaultMuiPrevented = true;
          }}
          sx={{ maxHeight: "65vh", overflow: "hidden" }}
          slots={{
            footer: CustomFooter,
          }}
          slotProps={{
            footer: {
              rows,
              setRows,
              setRowModesModel,
              setLoading,
              fetchData,
            },
          }}
        />
      </div>
      {confirmationModal && (
        <ConfirmationModal
          isOpen={confirmationModal}
          onClose={() => setConfirmationModal(false)}
          onConfirm={handleDeleteModalConfirmation}
        />
      )}
    </div>
  );
};
