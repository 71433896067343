import { EditIcon, SmallCloseIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Textarea,
  useToast,
  list,
  useDisclosure,
  ModalCloseButton,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Skeleton,
} from "@chakra-ui/react";
import { useWindowSize } from "@uidotdev/usehooks";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
const Viewdetails = ({ slab, idx, item, textToEdit, setTextToEdit }) => {
  const size = useWindowSize();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const editDates = (TimeStamp) => {
    const timestamp = TimeStamp * 1000; // Multiply by 1000 to convert from seconds to milliseconds
    const date = new Date(timestamp);

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is zero-based
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  };

  return (
    <>
      <p
        className="text-center text-[#084298]  cursor-pointer"
        onClick={onOpen}
      >
        View Details
      </p>
      {size.width >= 768 ? (
        <Modal isOpen={isOpen} onClose={onClose} isCentered="true">
          <ModalOverlay />
          <ModalContent
            style={{ borderRadius: "12px" }}
            maxW={`${size.width < 768 ? "400px" : "60%"}`}
          >
            <div className=" flex justify-end p-1 text-[#79767D]">
              {" "}
              <SmallCloseIcon onClick={onClose} className="cursor-pointer" />
            </div>
            <ModalBody pos="relative" p="1px" rounded="12px">
              <div className="flex flex-col gap-1">
                <div className="flex relative gap-2 justify-between bg-slate-100 p-2 ">
                  <p className="text-[#79767D] text-[12px] text-base">
                    Details of Slab ID-{" "}
                    <span className="text-[#000] ">{slab}</span>
                  </p>
                  <p className="flex text-[#79767D] text-[12px] text-base gap-2">
                    Date
                    <span className="text-[#000] ">
                      {editDates(item?.top_view_timestamp)}
                    </span>
                  </p>
                  <p className="flex text-[#79767D] text-[12px] text-base gap-2">
                    Shift<span className="text-[#000] ">{item?.shift}</span>
                  </p>
                </div>
                <div className="flex justify-between gap-2 p-2">
                  <Tabs width="45%">
                    <TabList>
                      <Tab>Top</Tab>
                      <Tab>Right</Tab>
                      <Tab>Left</Tab>
                    </TabList>

                    <TabPanels>
                      <TabPanel>
                        <div className="w-full border-2 h-full">
                          {item?.top_view === "" ? (
                            <Skeleton width="100%" height="200px" />
                          ) : (
                            <img src={item?.top_view} alt="top" />
                          )}
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="w-full border-2 h-full">
                          {item?.right_side_view === "" ? (
                            <Skeleton width="100%" height="200px" />
                          ) : (
                            <img src={item?.right_side_view} alt="right" />
                          )}
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="w-full border-2 h-full">
                          {item?.left_side_view === "" ? (
                            <Skeleton width="100%" height="200px" />
                          ) : (
                            <img src={item?.left_side_view} alt="left" />
                          )}
                        </div>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>

                  <div className="w-[55%] flex flex-col gap-2">
                    <p className=" text-[#79767D] text-[12px] text-base text-center  w-full font-semibold gap-2 bg-slate-200">
                      Variance
                    </p>
                    <div className="flex justify-between p-3 border-2 border-slate-200 ">
                      <p className="flex flex-col text-[#79767D] text-[12px] text-base gap-1">
                        Length :
                        <span className="text-[#000] ">
                          {item?.alert?.length == null ||
                          item?.alert?.length == undefined
                            ? "..."
                            : item?.alert?.length?.toFixed(2)}
                        </span>
                      </p>
                      <p className="flex flex-col text-[#79767D] text-[12px] text-base gap-1">
                        Width :
                        <span className="text-[#000] ">
                          {item?.alert?.breadth == null ||
                          item?.alert?.breadth == undefined
                            ? "..."
                            : item?.alert?.breadth?.toFixed(2)}
                        </span>
                      </p>
                      <p className="flex flex-col text-[#79767D] text-[12px] text-base gap-1">
                        Height :
                        <span className="text-[#000] ">
                          {item?.alert?.depth == null ||
                          item?.alert?.depth == undefined
                            ? "..."
                            : item?.alert?.depth?.toFixed(2)}
                        </span>
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="flex text-[#79767D] text-[12px] text-base gap-1 w-[100px]">
                        Comments :
                      </p>
                      <textarea
                        className="flex text-sm  gap-1 h-[114px] w-full border-2 p-3"
                        value={item?.operators_comment}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        <Drawer isOpen={isOpen} onClose={onClose} size="full">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader padding="0px">
              <div className="py-3 pr-2 pl-2 flex justify-between items-center bg-[#F5F5F5] rounded-tr-xl rounded-tl-xl">
                <div className="flex gap-3 items-center justify-between w-[90%]">
                  <p className="text-black font-semibold text-sm">
                    Slab ID- {slab}
                  </p>
                  <p className="text-black font-semibold text-sm">
                    {editDates(item.timestamp - 5.5 * 60 * 60)}
                  </p>
                </div>
                <SmallCloseIcon onClick={onClose} className="cursor-pointer" />
              </div>
            </DrawerHeader>
            <DrawerBody padding="0px">
              <div className="flex flex-col gap-1">
                <div className="flex flex-col justify-between gap-2 p-2">
                  <Tabs width="full">
                    <TabList>
                      <Tab>Top</Tab>
                      <Tab>Right</Tab>
                      <Tab>Left</Tab>
                    </TabList>

                    <TabPanels>
                      <TabPanel>
                        <div className="w-full border-2 h-full">
                          {item?.top_view === "" ? (
                            <Skeleton width="100%" height="200px" />
                          ) : (
                            <img src={item?.top_view} alt="top" />
                          )}
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="w-full border-2 h-full">
                          {item?.right_side_view === "" ? (
                            <Skeleton width="100%" height="200px" />
                          ) : (
                            <img src={item?.right_side_view} alt="right" />
                          )}
                        </div>
                      </TabPanel>
                      <TabPanel>
                        <div className="w-full border-2 h-full">
                          {item?.left_side_view === "" ? (
                            <Skeleton width="100%" height="200px" />
                          ) : (
                            <img src={item?.left_side_view} alt="left" />
                          )}
                        </div>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>

                  <div className="w-full flex flex-col gap-2 ">
                    <p className=" text-[#79767D] text-[12px] text-base text-center  w-full font-semibold gap-2 bg-slate-200">
                      Variance
                    </p>
                    <div className="flex justify-between p-3 w-full  border-2 border-slate-200">
                      <p className="flex flex-col text-[#79767D] text-[12px] text-center text-base gap-1 w-full">
                        Length
                        <span className="text-[#000] ">
                          {item?.alert?.length == null ||
                          item?.alert?.length == undefined
                            ? "..."
                            : item?.alert?.length?.toFixed(2)}
                        </span>
                      </p>
                      <p className="flex flex-col text-[#79767D] text-[12px] text-center text-base gap-1 w-full">
                        Breadth
                        <span className="text-[#000] ">
                          {item?.alert?.breadth == null ||
                          item?.alert?.breadth == undefined
                            ? "..."
                            : item?.alert?.breadth?.toFixed(2)}
                        </span>
                      </p>
                      <p className="flex flex-col text-[#79767D] text-[12px] text-center text-base gap-1 w-full">
                        Height
                        <span className="text-[#000] ">
                          {item?.alert?.depth == null ||
                          item?.alert?.depth == undefined
                            ? "..."
                            : item?.alert?.depth.toFixed(2)}
                        </span>
                      </p>
                    </div>
                    <div className="flex flex-col">
                      <p className="flex text-[#79767D] text-[12px] text-base gap-1 w-[100px]">
                        Comments
                      </p>
                      <textarea
                        className="flex text-[8px] text-base gap-1 h-[110px] w-full border-2 p-3"
                        value={item?.operators_comment}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};

export default Viewdetails;
