import FlipCard from "../Components/FlipCard";
import axios from "axios";
import { baseURL } from "../../../index";
import NavContext from "../../NavContext";
import { useContext, useEffect, useState } from "react";
import ExlCsvDownload from "../../../util/VisionUtils/ExlCsvDownload";
import ConcentrateDelete from "../Components/ConcentrateDelete";
import DeleteIcon from "@mui/icons-material/Delete";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";

const Feed = () => {
  const { auth } = useContext(NavContext);
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [downloadData, setDownloadData] = useState([]);
  const getData = async () => {
    try {
      const response = await axios.get(
        baseURL + "vision/v1/workforceMonitoring/concentrate",
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.data) {
        setData((prev) => {
          let newData = response.data;
          delete newData["_id"];
          return newData;
        });
        setDownloadData((prev) => {
          let newData = [];
          let apiData = response.data;
          delete apiData["_id"];
          for (let bay in apiData) {
            for (let section in apiData[bay]) {
              let obj = {};
              obj["Bay"] = bay;
              obj["Section"] = section;
              obj["Concentrate"] = apiData[bay][section]?.concentrate;
              obj["Amount"] = apiData[bay][section]?.current;
              newData.push(obj);
            }
          }
          return newData;
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="flex flex-col gap-2 w-full">
      <div className="w-fit self-end">
        {/* <ExlCsvDownload
          order={["Bay", "Section", "Concentrate", "Amount"]}
          data={downloadData}
        /> */}
        <PrimaryButton
          width={"fit-content"}
          text={"Concentrates"}
          Icon={<DeleteIcon />}
          onClick={() => setOpenModal(true)}
        />
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 min-[1100px]:grid-cols-4 gap-3">
        {data &&
          Object.keys(data).length > 0 &&
          ["B1", "B2", "B3", "B4", "B8", "B7", "B6", "B5"]?.map((val) => {
            return (
              <FlipCard
                title={"Bay " + val[1]}
                sections={data[val]}
                data={data}
                bay={val}
                fetchData={getData}
              />
            );
          })}
      </div>
      {openModal && (
        <ConcentrateDelete
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
        />
      )}
    </div>
  );
};

export default Feed;
