import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  useLocation,
  useParams,
  Link,
  useSearchParams,
} from "react-router-dom";
import Feed from "./Tabs/Feed";
import FeedLibrary from "./Tabs/FeedLibrary";
import Alerts from "./Tabs/Alerts";
import Report from "./Tabs/Report";
import Analytics from "./Tabs/Analytics";
import { useWindowSize } from "@uidotdev/usehooks";
import { mixpanelTrackTabs } from "../../util/mixpanelFunctions";
import RetrainModal from "../../util/RetrainContact/RetrainModal";
import ContactUsModal from "../../util/RetrainContact/ContactUsModal";
import RetrainContact from "../../util/RetrainContact/RetrainContact";
import { capitalizeFirstLetter } from "../../util/sentenceCase";
import CrusherEffectiveness from "../CrusherEffectiveness/CrusherEffectiveness";

const Capitalize = (str) => {
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
};

const Sizing = () => {
  const size = useWindowSize();
  const location = useLocation();
  const [plantCamMap, setPlantCamMap] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const page = !isNaN(searchParams?.get("tabIndex"))
    ? Number(searchParams?.get("tabIndex"))
    : 0;
  const param = useParams();
  const [showCrusherTab, setShowCrusherTab] = useState(false);

  const handlePageChange = (val) => {
    if (val !== page) {
      setSearchParams({ tabIndex: val }, { replace: true });
    }
  };
  console.log("landed");
  useEffect(() => {
    handlePageChange(page);
    let tool = Capitalize(param.material.toLowerCase()) + " Sizing";
    mixpanelTrackTabs(tabList[page]?.tabName.toLowerCase(), tool);
  }, [page]);

  const tabList = [
    {
      index: 0,
      tabName: "Feed",
      element: (
        <Feed
          material={param.material.toLowerCase()}
          clientId={param.clientId.toLowerCase()}
          setPlantCamMap={setPlantCamMap}
          setShowCrusherTab={setShowCrusherTab}
        />
      ),
    },
    {
      index: 1,
      tabName: "Alerts",
      element: (
        <Alerts
          plantId="All Plants"
          cameraId=""
          disable={false}
          plantCamMap={plantCamMap}
        />
      ),
    },
    {
      index: 2,
      tabName: "Feed library",
      element: (
        <FeedLibrary
          plantId="All Plants"
          cameraId=""
          disable={false}
          plantCamMap={plantCamMap}
        />
      ),
    },
    {
      index: 3,
      tabName: "Analytics",
      element: Object.keys(plantCamMap).length != 0 && (
        <Analytics
          plantId={Object.keys(plantCamMap)[0]}
          cameraId={plantCamMap[Object.keys(plantCamMap)[0]][0]}
          disable={false}
          plantCamMap={plantCamMap}
        />
      ),
    },
    {
      index: 4,
      tabName: "Report",
      element: (
        <Report
          plantId="All Plants"
          cameraId=""
          disable={false}
          plantCamMap={plantCamMap}
        />
      ),
    },
    {
      index: 5,
      tabName: "Crusher Effectiveness",
      element: (
        <CrusherEffectiveness
          material={param?.material?.toLowerCase()}
          clientId={param?.clientId?.toLowerCase()}
          handlePageChange={handlePageChange}
          page={page}
        />
      ),
    },
  ].filter((tab) => {
    if (tab.tabName === "Crusher Effectiveness") {
      return showCrusherTab;
    }
    return true;
  });

  return (
    <div
      className="  font-poppins flex flex-col rounded-lg"
      style={{ width: size.width >= 768 ? "calc(100vw - 168px)" : "100vw" }}
    >
      {location.pathname.includes("blastfurnace") ? (
        <></>
      ) : (
        <div className="flex justify-start items-center gap-2 mb-3 mt-6">
          <Link
            to={`/vision/Sizing/${param.material}`}
            style={{
              textDecoration: "none",
            }}
          >
            <img src="/backtick.svg" />
          </Link>
          <p className="text-lg sm:text-2xl font-semibold text-[#024D87]">
            {capitalizeFirstLetter(param.material.toLowerCase() + " Sizing")}
          </p>
        </div>
      )}

      <Tabs index={page} onChange={handlePageChange}>
        <TabList className="!flex !border-0">
          <div className="flex md:flex-row flex-col justify-between  w-full gap-3">
            <div className="flex items-center gap-4 overflow-x-auto h-14 md:h-10">
              {tabList?.map((item, id) => {
                return (
                  <Tab
                    key={id}
                    className={
                      page === item?.index
                        ? "!text-black !text-xs sm:!text-sm !bg-white rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#79767D]"
                        : "!text-xs sm:!text-sm !text-[#938F96] !border-0"
                    }
                    onClick={() => handlePageChange(item?.index)}
                  >
                    {item?.tabName}
                  </Tab>
                );
              })}
            </div>
          </div>
        </TabList>

        <TabPanels>
          {tabList?.map((item) => {
            return <TabPanel className="!pl-0 !pr-0">{item?.element}</TabPanel>;
          })}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default Sizing;
