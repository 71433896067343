import React, { useState, useEffect, useContext } from "react";
import ManageDevices from "./ManageDevices";
import DevicesTablePage from "./DevicesTablePage";
import axios from "axios";
import { baseURL } from "../../..";
import NavContext from "../../NavContext";

export const deactiveDeviceApi = async (
  data,
  toast,
  isDelete,
  auth,
  getDeviceApi
) => {

  const data1 = {...data?.deviceInfo};
  try {
    if (isDelete) {
      data1["isdeleted"] = true;
      data1["status"] = 2;
    } else {
      data1["isdeleted"] = false;
      data1["status"] = 1;
    }
    const requestData = JSON.stringify(data1);
    const response = await axios.patch(
      baseURL + "device/v1/update/device",
      requestData,
      {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    if (response.status == 200) {
      // toast({
      //   title: "Deleted",
      //   description: `The ${data?.deviceInfo?.deviceName} is deactivated`,
      //   status: "success",
      //   duration: 2000,
      //   isClosable: true,
      //   position: "top",
      // });
      getDeviceApi();
    }
  } catch (error) {
    console.log(error);
  }
};

const DevicesPage = ({
  setShowDevicesTab,
  view,
  setView,
  deviceType,
  setDeviceType,
  setSelectedDevice,
}) => {
  const { auth } = useContext(NavContext);
  const [devices, setDevices] = useState([]);
  const [deviceGroups, setDeviceGroups] = useState([]);

  const getDeviceApi = async () => {
    try {
      const response = await axios.get(baseURL + "device/v1/fetch/device", {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      if (response.data) {
        setDevices(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDeviceGroupApi = async () => {
    try {
      const response = await axios.get(baseURL + "device/group/v1/fetch/device/detail", {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      if (response.data) {
        console.log("Devicehello");
        console.log(response.data);
        setDeviceGroups(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDeviceApi();
    getDeviceGroupApi();
  }, []);

  return (
    <div className="flex flex-col">
      <div className="w-full flex flex-col xs:flex-row justify-between gap-4 items-start xs:items-center">
        <div className="flex gap-0 whitespace-nowrap">
          {["Groups", "Devices"].map((i, idx) => {
            return (
              <div
                className={`py-[6px] px-3 capitalize border text-sm w-[125px] flex justify-center`}
                style={{
                  backgroundColor: deviceType == i ? "#FFFFED" : "white",
                  borderColor: deviceType == i ? "#FFC107" : "#EBEBEB",
                  color: deviceType == i ? "#3E3C42" : "#605D64",
                  fontWeight: deviceType == i ? 500 : 400,
                  cursor: deviceType == i ? "" : "pointer",
                }}
                onClick={() => setDeviceType(i)}
              >
                {i === "Groups" ? "Device Groups" : i}
              </div>
            );
          })}
        </div>
        <div className="flex gap-0">
          {["card", "table"].map((i, idx) => {
            return (
              <div
                className={`py-[6px] flex gap-1 items-center px-3 capitalize border text-sm $`}
                style={{
                  backgroundColor: view == i ? "#FFFFED" : "white",
                  borderColor: view == i ? "#FFC107" : "#EBEBEB",
                  color: view == i ? "#3E3C42" : "#605D64",
                  fontWeight: view == i ? 500 : 400,
                  cursor: view == i ? "" : "pointer",
                }}
                onClick={() => setView(i)}
              >
                <img
                  src={`/${i == "card" ? "cardIcon" : "tableIcon"}.svg`}
                  alt={i}
                  className="w-[20px] h-[20px] mr-1"
                />{" "}
                {i}
              </div>
            );
          })}
        </div>
      </div>
      {view === "card" && (
        <ManageDevices
          setShowDevicesTab={setShowDevicesTab}
          deviceType={deviceType}
          setSelectedDevice={setSelectedDevice}
          devices={devices}
          getDeviceApi={getDeviceApi}
          getDeviceGroupApi={getDeviceGroupApi}
          deviceGroups={deviceGroups}
        />
      )}
      {view === "table" && (
        <DevicesTablePage
          setShowDevicesTab={setShowDevicesTab}
          deviceType={deviceType}
          setSelectedDevice={setSelectedDevice}
          devices={devices}
          getDeviceApi={getDeviceApi}
          getDeviceGroupApi={getDeviceGroupApi}
          deviceGroups={deviceGroups}
        />
      )}
    </div>
  );
};

export default DevicesPage;
