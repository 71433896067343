import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Progress,
  ModalBody,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

const stateInfo = {
  1: {
    name: "Processing",
    icon: null,
    color: "blue",
  },
  2: {
    name: "Complete",
    icon: "/selfServiceIcons/live.svg",
    color: "green",
  },
  0: {
    name: "Failed",
    icon: "/selfServiceIcons/fail.svg",
    color: "red",
  },
};

const CommonProgressBar = ({ openModal, closeModal, state }) => {
  const [progress, setProgress] = useState(0);
  let intervalId;

  useEffect(() => {
    intervalId = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 99) {
          clearInterval(intervalId);
          return prev;
        } else {
          return prev + 1;
        }
      });
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (state == 2) {
      setProgress(100);
      setTimeout(() => closeModal(), 2000);
    } else if (state == 0) {
      clearInterval(intervalId);
      setTimeout(() => closeModal(), 2000);
    }
  }, [state]);

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={openModal}
      onClose={closeModal}
      isCentered="true"
    >
      <ModalOverlay />
      <ModalContent style={{ borderRadius: "8px" }} maxW="360px">
        <ModalBody p={"16px"}>
          <div className="flex flex-col gap-2">
            <p className="text-lg font-medium">{stateInfo[state]?.name}</p>
            <div className="flex gap-1 items-center text-sm font-medium whitespace-nowrap">
              <Progress
                hasStripe
                value={progress}
                width={"full"}
                colorScheme={stateInfo[state]?.color}
              />
              {stateInfo[state]?.icon ? (
                <img src={stateInfo[state]?.icon} alt="icons" />
              ) : (
                progress + " %"
              )}
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CommonProgressBar;
