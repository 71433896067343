import { useContext, useEffect, useState } from "react";
import UseCaseDetail from "../Components/UseCaseDetail";
import UploadDetails from "../Components/UploadDetails";
import AnnotateData from "../Components/AnnotateData";
import ProjectDetails from "../Components/ProjectDetails";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { useNavigate, Link } from "react-router-dom";
import ModelUpload from "../Components/ModelUpload";
import { draftPostApi } from "./DraftForm";
import { useToast } from "@chakra-ui/react";
import NavContext from "../../NavContext";
import TonalButton from "../../../util/Buttons/TonalButton";

const TimeLengthMap = (val) => {
  if (val > 1000) return "30 min";
  else if (val > 500) return "15 min";
  else return "a few min";
};

export const initState = {
  projectId: "",
  name: "",
  chunkSize: 90,
  dataType: "",
  whatToDetect: "",
  uploadedFiles: {},
  bucketLink: "",
  rtspLink: "",
  savedFiles: null,
  isAnnotated: "No",
  annotationType: "",
  modelType: "Use existing",
  modelService: "RIPIK_VISION",
  annotatedData: null,
  pickleFile: null,
  uploadModelRemarks: "",
  modelArch: "",
  videoUploaded: false,
};

const CreateForm = () => {
  const [userState, setUserState] = useState(initState);
  const [activeStep, setActiveStep] = useState(0);
  const toast = useToast();
  const { auth } = useContext(NavContext);
  const navigate = useNavigate();

  useEffect(() => {
    const elem = document.getElementById("step" + activeStep);
    elem.scrollIntoView({
      behavior: "smooth", // You can use 'auto' instead of 'smooth' for instant scrolling
      block: "center", // You can use 'center' or 'end' instead of 'start'
    });
    if (activeStep != 0) {
      draftPostApi(userState, activeStep, toast, auth);
    }
  }, [activeStep]);
  console.log(userState, "state");
  return (
    <div className="flex flex-col gap-2 h-screen mt-6 font-roboto">
      <div className="flex gap-2 items-center">
        <Link
          to={`/Sandbox`}
          style={{
            textDecoration: "none",
          }}
        >
          <img src="/backtick.svg" />
        </Link>
        <p className="text-xl font-medium text-[#084298] capitalize">
          Create new project
        </p>
      </div>
      <div className="flex flex-col gap-3 h-full">
        <ProjectDetails
          userData={userState}
          setUSerData={setUserState}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          disable={activeStep > 0}
        />
        <UseCaseDetail
          userData={userState}
          setUSerData={setUserState}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          show={activeStep >= 1}
        />
        <ModelUpload
          userData={userState}
          setUSerData={setUserState}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          show={activeStep >= 2 || activeStep == 50}
        />
        <UploadDetails
          userData={userState}
          setUSerData={setUserState}
          setActiveStep={setActiveStep}
          activeStep={activeStep}
          show={activeStep >= 2 && activeStep != 50}
        />
        {userState.isAnnotated == "No" && (
          <AnnotateData
            userData={userState}
            setUSerData={setUserState}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            show={activeStep >= 3 && activeStep != 50}
          />
        )}
        {activeStep == 4 && (
          <div
            className={`p-6 flex flex-col gap-8 rounded-lg bg-white transition-all ease-in duration-500 ${
              activeStep == 4 ? "opacity-100" : "opacity-0"
            }`}
            id="step4"
          >
            <p className="text-[#3E3C42] text-xl font-medium">
              Traning request Submitted
            </p>
            <p className="text-base font-medium text-[#3E3C42] whitespace-normal">
              You saved {Object.entries(userState.uploadedFiles)?.length} files
              and annotated {userState.annotatedData?.length} Files. Based on
              the information provided the model will take{" "}
              {TimeLengthMap(userState.annotatedData?.length)} approx. Please
              click on close to move to home page
            </p>
            <div className="flex items-center gap-2 mt-2">
              <TonalButton
                text={"Close"}
                width={"fit-content"}
                onClick={() => navigate(`/Sandbox`)}
              />
              <PrimaryButton
                text={"View"}
                width={"fit-content"}
                onClick={() =>
                  navigate(`/Sandbox/View/${userState?.projectId}`)
                }
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CreateForm;
