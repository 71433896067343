import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useWindowSize } from "@uidotdev/usehooks";
import VideoCard from "../CommonComponents/VideoCard";
import PurchaseAndAlert from "../FeedComponents/PurchaseAndAlert";
import AlertCardDetail from "../CommonComponents/AlertCardDetail";
import PurchaseOrder from "../FeedComponents/PurchaseOrder";
import ReportPointers from "./ReportPointers";
import DataGridTable from "../../Tables/CommonTable";
import FeedHealth from "./FeedHealth";
import { useEffect, useRef, useState } from "react";
import AlertDetailModal from "../AlertComponents/AlertDetailModal";
import CommonTable from "../../Tables/CommonTable";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import ClipModal from "./ClipModal";

const ReportDetailModal = ({
  openModal,
  closeModal,
  data,
  index,
  order,
  initialData,
}) => {
  const size = useWindowSize();
  const [openclipModal, setOpenClipModal] = useState(false);
  const [displayData, setDisplayData] = useState([]);
  const [currIndex, setCurrIndex] = useState(index);
  const [dataToDisplay, setDataToDisplay] = useState(data[currIndex]);
  const toggleMove = (val) => {
    //index -1
    let index = null;
    if (val === 1) {
      setCurrIndex((prev) => {
        index = prev > 0 ? prev - 1 : data.length - 1;
        return index;
      });
    }
    //index +1
    else if (val === 2) {
      setCurrIndex((prev) => {
        index = prev < data.length - 1 ? prev + 1 : 0;
        return index;
      });
    }
    setDataToDisplay((prev) => data[index]);
  };
  const indexRef = useRef();
  const reportPointers = [
    {
      header: "PO number",
      content: dataToDisplay?.purchaseOrder || "",
      type: "string",
    },
    {
      header: "Start time",
      content: dataToDisplay?.poStart || null,
      type: "dateTime",
    },
    {
      header: "End time",
      content: dataToDisplay?.poEnd || null,
      type: "dateTime",
    },
  ];

  useEffect(() => {
    if (index !== null || index !== undefined) {
      setCurrIndex((prev) => index);
    }
  }, [data, index]);

  const cameraIds = initialData?.cameraGpIds?.reduce((acc, group) => {
    const ids = group?.cameraInfo?.map((info) => ({
      cameraId: info?.cameraId,
      cameraName: info?.name,
      url: "",
      cameraGpId: group?.cameraGpId, // Adding the cameraGpId to each camera object
    }));
    return acc.concat(ids);
  }, []);

  console.log({ cameraIds });

  return (
    <>
      {size.width >= 1000 ? (
        <Modal isOpen={openModal} onClose={closeModal} isCentered="true">
          <ModalOverlay zIndex={1200} />
          <ModalContent
            containerProps={{ zIndex: 1200 }}
            style={{ borderRadius: "12px" }}
            maxW="1000px"
          >
            <ModalHeader padding="0px">
              <div className="px-5 py-3 flex justify-between items-center gap-3  rounded-tr-xl rounded-tl-xl bg-slate-100 ">
                <div className="flex gap-3 items-center text-base font-medium w-full">
                  {data?.length > 1 && <p> {currIndex + 1}.</p>}
                  Report details
                </div>
                <img
                  src="/recipeCompliance/ClosemodalButton.svg"
                  className="cursor-pointer"
                  onClick={closeModal}
                />
              </div>
            </ModalHeader>
            <ModalBody paddingX={"15px"}>
              <div className="flex flex-row items-center gap-2 h-full pb-1 relative max-w-full">
                {data.length > 1 && (
                  <IconButton
                    bg={"transparent"}
                    rounded={"full"}
                    size={"sm"}
                    boxShadow={"md"}
                    icon={<ChevronLeftIcon />}
                    onClick={() => toggleMove(1)}
                  />
                )}

                <div
                  className=" gap-[18px] flex flex-col "
                  style={{
                    width: data.length > 1 ? "92%" : "100%",
                  }}
                >
                  <div className="w-full flex gap-4">
                    {reportPointers.map((item, id) => {
                      return (
                        <ReportPointers
                          header={item?.header}
                          content={item?.content}
                          type={item.type}
                          key={id}
                        />
                      );
                    })}

                    <div className="w-full  flex items-center">
                      <ClipModal
                        cameraIds={cameraIds}
                        poId={dataToDisplay?.purchaseOrder}
                      />
                    </div>

                    {dataToDisplay?.feedDown && (
                      <div className="w-full">
                        <FeedHealth data={dataToDisplay?.feedDown} />
                      </div>
                    )}
                  </div>
                  <div className="w-full">
                    <CommonTable
                      rowData={dataToDisplay?.pellet || []}
                      order={order || []}
                      // showClips={true}
                      setDisplayData={setDisplayData}
                      setOpenModal={setOpenClipModal}
                      indexRef={indexRef}
                    />
                  </div>
                  {/* {openModal && (
                    <AlertDetailModal
                      openModal={openclipModal}
                      closeModal={() => setOpenClipModal(false)}
                      data={displayData}
                      openingFrom={"report"}
                      initialData={initialData}
                      index={indexRef.current}
                    />
                  )} */}
                </div>

                {data.length > 1 && (
                  <IconButton
                    bg={"transparent"}
                    rounded={"full"}
                    size={"sm"}
                    boxShadow={"md"}
                    icon={<ChevronRightIcon />}
                    onClick={() => toggleMove(2)}
                  />
                )}
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        <Drawer onClose={closeModal} isOpen={openModal} size="full">
          <DrawerOverlay zIndex={1200} />
          <DrawerContent containerProps={{ zIndex: 1200 }} gap={0}>
            <DrawerHeader padding="5px">
              <div className="px-6 py-2 flex flex-row justify-between border rounded-tr-xl rounded-tl-xl bg-slate-100 items-end w-full mt-2">
                <div className="flex gap-3 items-center text-base font-medium w-full ">
                  {data?.length > 1 && <p> {currIndex + 1}.</p>}
                  Report details
                </div>
                <img
                  src="/recipeCompliance/ClosemodalButton.svg"
                  className="cursor-pointer "
                  onClick={closeModal}
                />
              </div>
            </DrawerHeader>
            <DrawerBody padding="5px">
              <div className="flex flex-row items-center gap-1 relative h-full   ">
                {data.length > 1 && (
                  <IconButton
                    bg={"transparent"}
                    rounded={"full"}
                    size={"sm"}
                    boxShadow={"md"}
                    icon={<ChevronLeftIcon />}
                    onClick={() => toggleMove(1)}
                  />
                )}
                <div className="h-[89vh]  overflow-y-auto w-full">
                  <div className="w-full px-0 py-0 gap-[18px] flex flex-col">
                    <div className="w-full flex flex-col gap-2">
                      {reportPointers.map((item, id) => {
                        return (
                          <ReportPointers
                            header={item?.header}
                            content={item?.content}
                            type={item.type}
                            key={id}
                          />
                        );
                      })}
                      <div className="w-full  flex justify-center items-center">
                        <ClipModal
                          cameraIds={cameraIds}
                          poId={dataToDisplay?.purchaseOrder}
                        />
                      </div>
                      {dataToDisplay?.feedDown && (
                        <div className="w-full">
                          <FeedHealth data={dataToDisplay?.feedDown} />
                        </div>
                      )}
                    </div>
                    <div>
                      <CommonTable
                        rowData={dataToDisplay?.pellet || []}
                        order={order || []}
                        // showClips={true}
                        setDisplayData={setDisplayData}
                        setOpenModal={setOpenClipModal}
                        indexRef={indexRef}
                      />
                    </div>
                    {openModal && (
                      <AlertDetailModal
                        openModal={openclipModal}
                        closeModal={() => setOpenClipModal(false)}
                        data={displayData}
                        openingFrom={"report"}
                        initialData={initialData}
                        index={indexRef.current}
                      />
                    )}
                  </div>
                </div>
                {data.length > 1 && (
                  <IconButton
                    bg={"transparent"}
                    rounded={"full"}
                    size={"sm"}
                    boxShadow={"md"}
                    icon={<ChevronRightIcon />}
                    onClick={() => toggleMove(2)}
                  />
                )}
              </div>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};

export default ReportDetailModal;
