import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useToast,
} from "@chakra-ui/react";
import {
  Autocomplete,
  TextField,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import { baseURL } from "../../..";
import NavContext from "../../NavContext";
import TonalButton from "../../../util/Buttons/TonalButton";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import CloseIcon from "@mui/icons-material/Close";

const ShareModal = ({ data, openModal, closeModal }) => {
  const [shareMails, setShareMails] = useState([]);
  const { auth, email } = useContext(NavContext);
  const [allMails, setAllMails] = useState([]);
  const toast = useToast();
  const MuiTheme = createTheme();

  const shareApi = async () => {
    const param = {
      projectId: data?.projectId,
    };
    const requestBody = JSON.stringify({
      sharing: shareMails,
    });
    try {
      const response = await axios.post(
        baseURL + "selfserve/v1/project/v1/sharingUsers/",
        requestBody,
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      if (response.status == 200) {
        toast({
          title: "Successfully shared",
          description: `Project ${data?.name} shared`,
          status: "success",
          position: "top-right",
          duration: 2000,
          isClosable: true,
        });
        closeModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAllEmailApi = async () => {
    try {
      const response = await axios.get(baseURL + "iam/users", {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      if (response.data)
        setAllMails((prev) => {
          let newData = response.data
            .filter((item) => item.email != email)
            .map((item) => item.email);
          return newData;
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemove = (val) => {
    setShareMails((prev) => {
      let newData = [...prev];
      let idx = newData.indexOf(val);
      newData.splice(idx, 1);
      return newData;
    });
  };

  useEffect(() => {
    getAllEmailApi();
  }, []);

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={openModal}
      onClose={closeModal}
      isCentered="true"
    >
      <ModalOverlay />
      <ModalContent style={{ borderRadius: "8px" }} maxW="300px">
        <ModalHeader
          py={"8px"}
          px={"16px"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          borderBottom={"1px solid #EBE2F0"}
        >
          <p className="text-[#605D64] text-sm font-medium">Share project</p>
        </ModalHeader>
        <ModalBody
          px={"24px"}
          py={"16px"}
          display={"flex"}
          flexDirection={"column"}
          gap={"32px"}
        >
          <div className="flex flex-col gap-2">
            <div className="flex flex-wrap gap-2">
              {shareMails?.map((item) => {
                return (
                  <div className="p-1 text-white text-xs rounded-lg bg-black bg-opacity-50 flex gap-1 items-center">
                    {item}{" "}
                    <CloseIcon
                      sx={{ color: "white" }}
                      className="cursor-pointer hover:scale-105"
                      onClick={() => handleRemove(item)}
                    />
                  </div>
                );
              })}
            </div>
            <ThemeProvider theme={MuiTheme}>
              <Autocomplete
                onChange={(event, newValue) => {
                  if (newValue) setShareMails((prev) => [...prev, newValue]);
                }}
                disablePortal
                id="combo-box-demo"
                options={allMails}
                size="small"
                sx={{ width: "auto" }}
                renderInput={(params) => (
                  <TextField {...params} label="Select mails to be used" />
                )}
              />
            </ThemeProvider>
          </div>
        </ModalBody>
        <ModalFooter
          display={"flex"}
          flexDirection={"row"}
          gap={"8px"}
          alignItems={"center"}
          justifyContent={"end"}
        >
          <PrimaryButton
            text={"Save"}
            width={"fit-content"}
            onClick={shareApi}
            disable={!shareMails.length}
          />
          <TonalButton
            text={"Close"}
            width={"fit-content"}
            onClick={closeModal}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ShareModal;
