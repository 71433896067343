// Function to check if a shift can be selected
export function canSelectShift(selectedShift, timestamp) {
  // Define the shifts and their time ranges in hours (local time)
  const shifts = {
    shiftA: { start: 6, end: 14 },
    shiftB: { start: 14, end: 22 },
    shiftC: { start: 22, end: 6 }, // Note: shiftC ends at 6 AM the next day
  };

  const selectedDate = new Date(timestamp);
  const currentDate = new Date();

  // Extract the shift details
  const shift = shifts[selectedShift];
  if (!shift) return false; // Invalid shift

  const currentHours = currentDate.getHours();
  const selectedDay = selectedDate.toDateString();
  const today = currentDate.toDateString();

  // Check if the selected date is today
  if (selectedDay === today) {
    // Check if the selected shift is in the future relative to the current time
    if (selectedShift === "shiftC") {
      // Shift C spans over two days

      // if (currentHours >= shift.start || currentHours < shift.end) {
      //   return false; // Shift C is active today, hence future shift cannot be selected
      // }
      // Additional check to see if the selected shift is for later in the day
      if (currentHours < shift.start) {
        return false; // Shift is in the future
      }
    } else {
      // For other shifts
      // if (currentHours >= shift.start && currentHours < shift.end) {
      //   return false; // The shift is currently active
      // }
      // Additional check to see if the selected shift is for later in the day
      if (currentHours < shift.start) {
        return false; // Shift is in the future
      }
    }
  }

  // Check if the selected date is yesterday
  const yesterday = new Date(currentDate);
  yesterday.setDate(yesterday.getDate() - 1);
  const yesterdayString = yesterday.toDateString();

  if (selectedDay === yesterdayString && selectedShift === "shiftC") {
    // Check if the current time is within Shift C's active period (yesterday 10 PM to today 6 AM)
    if (currentHours < shift.end) {
      return false; // Shift C is active today based on yesterday's date, hence future shift cannot be selected
    }
  }

  // If it is not today or yesterday during Shift C's period, or the shift is not in the future, it can be selected
  return true;
}

export function flattenKeys(obj, cameraOptions) {
  const res = { ...obj };

  if (res?.keys && typeof res?.keys === "object") {
    // If there is a 'keys' property and it's an object, flatten it
    for (let key in res.keys) {
      if (res.keys?.hasOwnProperty(key) && !res?.hasOwnProperty(key)) {
        // avoid conflicts of keys with parent object
        res[key] = res.keys[key];
      }
    }
    delete res.keys; // Remove the nested 'keys' object
  }

  // Determine the value for the 'belt' key based on cameraOptions
  if (res?.camera) {
    const beltKey = cameraOptions?.find((cam) => cam?.value === res?.camera);
    res.belt = beltKey.name;
  }

  return res;
}

export function transformChartData(data) {
  // Initialize the transformed data with camera information
  const transformedData = {
    camera: data.camera.map((cam) => ({
      name: cam.name,
      value: cam.value,
      mps: cam.mps,
      prev_mps: cam.prev_mps,
    })),
  };

  // Iterate over each camera entry to process its data
  transformedData?.camera?.forEach((cam) => {
    const camKey = cam?.value;
    const camName = cam?.name;
    const camData = data?.[camKey] || [];

    // console.log(camData?.map((item) => item?.mps["prevkeys.mps"],"cam"))

    if (camData?.length > 0) {
      transformedData[camKey] = {
        mps: [
          {
            name: cam?.mps,
            data: camData?.map((item) => item?.mps?.mps),
          },
          {
            name: cam?.prev_mps,
            data: camData?.map((item) => item?.mps?.["prevkeys.mps"]),
          },
        ],
        bucketWise: Object.keys(camData?.[0]?.size)
          ?.filter((size) => !size?.startsWith("prevkeys"))
          ?.map((size) => ({
            name: size || "",
            data: camData?.map((item) => item?.size[size]),
          })),
        combination: camData?.map((item) => item?.combination),
      };
    }
  });

  return transformedData;
}

export function checkDuplicateEntry(allRows) {
  const seenEntries = new Set();

  for (const row of allRows) {
    let { timestamp, shift, camera } = row;

    // Convert timestamp to Date object
    const dateObj = new Date(timestamp);

    // Extract local date in 'YYYY-MM-DD' format
    const year = dateObj.getFullYear();
    const month = (dateObj.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
    const day = dateObj.getDate().toString().padStart(2, "0");

    // Format date to 'YYYY-MM-DD'
    const localDateStr = `${year}-${month}-${day}`;

    // Create a unique key based on date, shift, and camera
    const key = `${localDateStr}-${shift}-${camera}`;

    // Check for duplicate entries
    if (seenEntries.has(key)) {
      return true; // Duplicate found
    }

    // Add the key to the set
    seenEntries.add(key);
  }

  return false; // No duplicates found
}
