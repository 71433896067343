import React, { useState, useEffect, useRef, useContext } from "react";
import { Spinner } from "@chakra-ui/react";
import TransactionTable from "./TransactionTable";
import ReadMore from "./ReadMore";
import { useWindowSize } from "@uidotdev/usehooks";
import FloatingInput from "../../util/VisionUtils/FloatingInput";
import Paginator from "../../util/VisionUtils/Paginator";
import { useNavigate } from "react-router-dom";

import { SpinnerIcon } from "@chakra-ui/icons";
import axios from "axios";
import { baseURL } from "../..";
import NavContext from ".././NavContext";
import ExlCsvDownload from "../../util/VisionUtils/ExlCsvDownload";
import AiAdvisorHistoryTable from "./AiAdvisorHistoryTable";

const AiAdvisorHistory = () => {
  const size = useWindowSize();
  const { auth } = useContext(NavContext);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [totalTokens, setTotalTokens] = useState(0);
  const [currentBalance, SetCurrentBalance] = useState(0);
  const [userBadge, setUserBadge] = useState([]);
  const [homebadge, setHomebadge] = useState({
    badgeLogo: "/advisor/newbie.svg",
    tag: "Newbie Explorer",
    recieved: false,
  });
  const [stateChanging, setStateChanging] = useState(false);
  const [fromTime, setFromTime] = useState(
    new Date(new Date().getTime() - 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10)
  );
  const [toTime, setToTime] = useState(
    new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10)
  );
  const [fromTimeInMs, setFromTimeInMs] = useState("");
  const [toTimeInMs, setToTimeInMs] = useState("");

  const [modifiedData, setModifiedData] = useState([]);

  const summaryFields = ["amount", "chatAnswer", "chatQuestion", "summary"];
  const detailsFields = [
    "chatId",
    "questionId",
    "userId",
    "amount",
    "chatAnswer",
    "chatQuestion",
    "summary",
  ];

  const setEndOfDay = (dateString) => {
    const parsedDate = new Date(dateString);
    // Set time to the end of the day (23:59:59.999)
    parsedDate.setHours(23, 59, 59, 999);
    return parsedDate;
  };
  const setStartOfDay = (dateString) => {
    const parsedDate = new Date(dateString);
    // Set time to the start of the day (00:00:00.000)
    parsedDate.setHours(0, 0, 0, 0);
    return parsedDate;
  };

  useEffect(() => {
    setFromTimeInMs(setStartOfDay(fromTime).getTime());
    setToTimeInMs(setEndOfDay(toTime).getTime());
  }, [fromTime, toTime]);

  useEffect(() => {
    fetchAdvisorHistory();
    fetchCurrentBalance();
  }, []);

  const handleClick = () => {
    setStateChanging(false);
    fetchAdvisorHistory();
  };

  const series = [
    {
      name: "Passed",
      type: "column",
      data: [10, 20, 10, 11, 12, 15, 16],
    },
  ];

  const options = {
    chart: {
      type: "bar",
      stacked: false,
      // stackType: "100%",

      toolbar: {
        show: false,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          // reset: true | '<img src="/static/icons/reset.png" width="20">',
          reset: false,
        },

        // autoSelected: "zoom",
      },
    },
    stroke: {
      width: [0, 0, 0],
      curve: "smooth",
    },
    plotOptions: {
      bar: {
        columnWidth: "30%",
      },
    },
    colors: ["#CDEEBF", "#EF6F12", "#6CA6FC"],

    // fill: {
    //   opacity: [0.8, 0.8, 0.8],
    //   gradient: {
    //     inverseColors: false,
    //     shade: "light",
    //     type: "vertical",
    //     opacityFrom: 0.85,
    //     opacityTo: 0.55,
    //     stops: [0, 100, 100, 100],
    //   },
    // },

    markers: {
      size: 0,
    },
    xaxis: {
      type: "category",
      categories: [
        "01/01/2003",
        "02/01/2003",
        "03/01/2003",
        "04/01/2003",
        "05/01/2003",
        "06/01/2003",
        "07/01/2003",
      ],
      labels: {
        show: true,
        hideOverlappingLabels: true,
        showDuplicates: false,
        trim: true,
        style: {
          fontSize: "12px",
          fontWeight: 300,
          cssClass: "apexcharts-xaxis-label",
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    grid: {
      strokeDashArray: 5,
    },
    yaxis: {
      tickAmount: 4,
      // labels: {
      //   formatter: function (y) {
      //     return y + "%";
      //   },
      // },
    },
  };
  const [displayData, setDisplayData] = useState([]);

  // const totalTokenUsed = tableData?.reduce(
  //   (total, item) => total + item.tokenUsed,
  //   0
  // );

  const fetchCurrentBalance = async () => {
    try {
      const response = await axios.get(baseURL + "token-wallet/v1/balance", {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });

      SetCurrentBalance(response?.data.User.balance);
    } catch (e) {
      console.error(e);
    }
  };

  const fetchAdvisorHistory = async () => {
    setLoading(true);
    const param = {
      startDate: fromTimeInMs,
      endDate: toTimeInMs,
    };
    try {
      const response = await axios.get(baseURL + "chat/history", {
        params: param,
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });

      // console.log(response?.data);

      setTableData(response?.data?.history);

      let arr = [];

      for (let x of response?.data?.history) {
        let obj = {};
        obj.amount = x.amount;
        obj.summary = x.summary;
        obj.chatId = x.chatId;
        for (let y of x["tchatQuestionAnswers"]) {
          let newObj = { ...obj };
          newObj.questionId = y.questionId;
          newObj.chatAnswer = y.chatAnswer;
          newObj.chatQuestion = y.chatQuestion;
          newObj.createdAt = y.createdAt;
          newObj.userId = y.userId;

          arr.push(newObj);
        }
      }

      setModifiedData(arr);

      const total = response?.data?.history;

      const totalTokenUsed = total?.reduce(
        (total, item) => total + item.amount,
        0
      );

      setTotalTokens(totalTokenUsed);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching experts:", error);
    }
  };

  const handleBackButton = () => {
    navigate("/user/transactionhistory");
  };

  return (
    <div
      className="mt-[3vh] flex flex-col gap-1 "
      style={{ width: size.width >= 768 ? "calc(100vw - 168px)" : "100vw" }}
    >
      <div className="w-full flex gap-[8px]">
        <div
          className="flex items-center cursor-pointer"
          onClick={handleBackButton}
        >
          <img src="/backtick.svg" alt="" />
        </div>
        <p className="text-[20px] sm:text-[20px] font-semibold text-[#024D87]">
          Question history
        </p>
      </div>
      {/* bottom white background part */}
      <div className="flex flex-col gap-[8px]  bg-white p-4 rounded-xl">
        {/* batch */}
        <div
          className={`flex  ${
            size.width < 798 ? "flex-col" : "flex-row"
          } f justify-between w-full rounded-sm`}
          style={{
            boxShadow:
              "4px 4px 12px 0px rgba(0, 0, 0, 0.10), -4px -4px 12px 0px rgba(0, 0, 0, 0.10)",
          }}
        >
          {/* batch pic */}
          <div className={`flex gap-2 ${size.width < 425 ? "flex-col" : ""}`}>
            <div
              className={`flex p-5 ${
                size.width < 768
                  ? "h-full w-full justify-center"
                  : "h-[150px] w-[180px]"
              } bg-[#00cefe] bg-opacity-10`}
            >
              <img src={homebadge?.badgeLogo} alt="Badge" />
            </div>
            <div className="flex flex-col p-2 w-full">
              {homebadge && homebadge.recieved ? (
                <p
                  className={`text-[#605D64] ${
                    size.width < 768 ? " text-[14px]" : " text-[20px]"
                  }`}
                >
                  Congratulations! You are now a
                  <span className="font-semibold ml-2">{homebadge?.tag}</span>
                </p>
              ) : (
                <p
                  className={`text-[#605D64] ${
                    size.width < 768 ? " text-[14px]" : " text-[20px]"
                  }`}
                >
                  Ask a question, to earn your first badge!
                </p>
              )}
              <ReadMore
                setHomebadge={setHomebadge}
                userBadge={userBadge}
                setUserBadge={setUserBadge}
              />
              {size.width < 798 && (
                <div className=" p-2 flex justify-end mt-[15px]">
                  <div className="flex justify-center gap-2 w-[230px] p-[8px] bg-[#FFFFD8] text-[16px]">
                    <p className="text-[#605D64]">Current Balance</p>
                    <div className="flex gap-1">
                      <p className="text-[#3E3C42] font-semibold ">
                        {currentBalance}
                      </p>
                      {/* coin icons */}
                      <img src="/token.svg" alt="coins" />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* current balance */}
          {size.width >= 798 && (
            <div className=" p-4">
              <div className="flex justify-center gap-2 w-[230px] p-[8px] bg-[#FFFFD8] text-[16px]">
                <p className="text-[#605D64]">Current Balance</p>
                <div className="flex gap-1">
                  <p className="text-[#3E3C42] font-semibold ">
                    {currentBalance}
                  </p>
                  {/* coin icons */}
                  <img src="/token.svg" alt="coins" />
                </div>
              </div>
            </div>
          )}
        </div>

        {/* date picker */}

        <div className="py-2  gap-3 flex flex-col md:flex-row items-center justify-center md:justify-start">
          <div className="py-2  gap-3 flex flex-col md:flex-row items-center justify-center md:justify-start">
            <div
              className={` w-full md:w-[350px] flex gap-2 p-4 justify-center ${
                size.width < 375 ? "flex-col" : ""
              } `}
            >
              <div className="">
                <FloatingInput
                  text="From"
                  type="date"
                  setDateTime={setFromTime}
                  value={fromTime}
                />
              </div>
              <div>
                <FloatingInput
                  text="To"
                  type="date"
                  setDateTime={setToTime}
                  value={toTime}
                />
              </div>
            </div>
            <button
              className="text-center px-[16px] py-[10px] w-[80px] h-[40px] text-white text-xs md:text-base font-medium bg-[#084298] rounded-lg"
              onClick={handleClick}
            >
              {loading ? <Spinner /> : "Show"}
            </button>
          </div>

          <div className="flex justify-end w-full mt-2 md:mt-0">
            <ExlCsvDownload
              data={modifiedData}
              order={summaryFields}
              orderDetail={detailsFields}
              enable={true}
            />
          </div>
        </div>

        <div className="w-full flex gap-[25px] ">
          {/* report */}
          <div
            className={`flex ${
              size.width < 768 ? "flex-col" : "flex-row"
            }  w-full gap-[20px] h-full`}
          >
            <div
              className={` ${
                size.width < 768 ? "w-full" : "w-[80%]"
              } py-2 h-full`}
            >
              <p className="text-[#79767D] bg-[#f2f5f9] px-[16px] py-[8px] flex justify-center text-[14px] font-medium ">
                Queries resolved using AI advisor
              </p>
              <p className="flex justify-center bg-[#FAFAFA] text-[#3E3C42] text-[18px] px-[16px] py-[8px]">
                {tableData.length}
              </p>
            </div>
            <div
              className={` ${
                size.width < 768 ? "w-full" : "w-[80%]"
              } py-2 h-full`}
            >
              <p className="text-[#79767D] bg-[#f2f5f9] px-[16px] py-[8px] flex justify-center text-[14px] font-medium ">
                Tokens used
              </p>

              <p className="flex items-center gap-1 justify-center bg-[#FAFAFA] text-[#3E3C42] text-[18px] px-[16px] py-[8px] text-center">
                {totalTokens}
                <span className="">
                  {" "}
                  <img src="/token.svg" alt="coins" />
                </span>
              </p>
            </div>
          </div>
        </div>

        <div>
          {loading ? (
            <div className="ml-[50%]">
              <Spinner />
            </div>
          ) : tableData && tableData?.length !== 0 ? (
            <AiAdvisorHistoryTable rowData={tableData} />
          ) : (
            <p className="ml-[38%] md:ml-[45%] mb-5 md:mb-0">No data available!</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default AiAdvisorHistory;
