import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

const DisableModal = ({ isOpen, onClose, handleConfirmDisable, text }) => {
  return (
    <>
      <Modal size={"sm"} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader className="flex justify-center rounded-t-md bg-red-400 text-white">
            Confirm Disable ?
          </ModalHeader>

          <ModalBody m={"auto"} fontSize={"15px"}>
            <div className="flex flex-col items-center justify-center w-full">
              <img
                src="/disableIcon.png"
                className="w-[100px] h-[100px]"
                alt=""
              />

              <p className="ml-4 font-medium">{text}</p>
            </div>
          </ModalBody>

          <div className="mt-4 flex flex-row justify-center">
            <Button colorScheme="blue" mr={4} onClick={handleConfirmDisable}>
              Confirm
            </Button>
            <Button onClick={onClose}>Go Back</Button> {/* set isOpen false */}
          </div>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DisableModal;
