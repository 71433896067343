import React, { useState, useEffect, useContext } from "react";
import { Input, useToast, Text, Select } from "@chakra-ui/react";
import TonalButton from "../../../../util/Buttons/TonalButton";
import PrimaryButton from "../../../../util/Buttons/PrimaryButton";
import DiscardModal from "../../DiscardModal";
import NavContext from "../../../NavContext";
import axios from "axios";
import { baseURL } from "../../../..";

const AlertsInfoForm = ({
    userForm,
    setUserForm,
    activeStep,
    setActiveStep,
    view = false,
    update = false
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { auth } = useContext(NavContext);
    const toast = useToast();
    const [categories, setCategories] = useState([]);
    const [submitClicked, setSubmitClicked] = useState(false);
    const [disable, setDisable] = useState(view);
    const [review, setReview] = useState(false);
    const [alertGroup, setAlertGroup] = useState(null);

    const fetchCategory = async () => {
        try {
            const response = await axios.get(
                baseURL + "alert/v1/fetch/alertGroup",
                {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Auth-Token": auth,
                    },
                }
            );
            if (response?.data) {
                console.log(response?.data);
                setCategories(response?.data.filter((item) => item?.status === "ACTIVE"));
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if ((update || view) && userForm?.alertGroupId && categories) {
            for (let x of categories) {
                if (x?.alertGroupId === userForm?.alertGroupId) {
                    setAlertGroup(x);
                }
            }
        }
    }, [categories])

    const handleChange = (name, value) => {

        if (name === "alertGroupId") {
            for (let x of categories) {
                if (x?.alertGroupId === value) {
                    setAlertGroup(x);
                    setUserForm((prev) => ({
                        ...prev,
                        [name]: value,
                        ["alertGroupName"]: x?.alertGroupName,
                        ["alertType"]: x?.alertType,
                    }));
                    break;
                }
            }
        }
        else {
            setUserForm((prev) => ({
                ...prev,
                [name]: value,
            }));
        }

    };

    const checkOk = () => {
        setSubmitClicked(true);
        if (
            !userForm?.alertGroupId ||
            !userForm?.alertName
        ) {
            toast({
                title: "Incomplete Details",
                description: "Please fill in all required details.",
                status: "error",
                duration: 4000,
                isClosable: true,
                position: "top",
            });
            return false;
        } else {

            setSubmitClicked(false);
            return true;
        }
    };

    const handleSubmit = () => {
        if (checkOk()) {
            setDisable(true);
            setActiveStep(1);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleConfirmDiscard = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        fetchCategory();
    }, []);

    return (
        <div
            className="font-roboto flex flex-col gap-2 p-4 pt-2 m-2 bg-white rounded-lg border"
            id="step0"
        >
            <div className="flex flex-col sm:flex-row justify-between">
                <p className="text-[#3E3C42] text-xl font-medium">Alert information</p>
            </div>

            <div className="lg:w-[50%] flex flex-col xs:flex-row gap-2">
                <div className="xs:w-[50%] flex flex-col gap-3 mt-6">
                    <p className="text-[#3E3C42] text-lg font-medium">
                        Select Alert Group<span className="text-red-500">*</span>
                    </p>
                    <div className="w-full">
                        {view === true ? <p className="text-[#525056] text-sm font-medium">{userForm?.alertGroupName}</p> :
                            <Select
                                value={userForm?.alertGroupId}
                                onChange={(e) => handleChange("alertGroupId", e.target.value)}
                                borderColor={
                                    submitClicked && userForm?.alertGroupId
                                        ? "red.500"
                                        : "gray.300"
                                }
                                borderWidth={'2px'}
                                className=" w-[100%] border-[2px] rounded-lg max-h-[300px] overflow-y-auto"
                                disabled={disable}
                            >
                                <option value="" disabled selected>
                                    Select one
                                </option>
                                {categories?.map((item, index) => (
                                    <option value={item.alertGroupId}>
                                        {item.alertGroupName}
                                    </option>
                                ))}
                            </Select>}

                        {submitClicked && !userForm?.alertGroupId && (
                            <Text color="red.500" fontSize="sm" mt="1">
                                Please select the Alert Group.
                            </Text>
                        )}
                    </div>
                </div>
            </div>

            <div className="lg:w-[50%] flex flex-col xs:flex-row gap-2">
                <div className="xs:w-[50%] flex flex-col gap-3 mt-6">
                    <p className="text-[#3E3C42] text-lg font-medium">
                        Alert name<span className="text-red-500">*</span>
                    </p>
                    {view === true ? (
                        <p className="text-[#525056] text-sm font-medium">
                            {userForm.alertName}
                        </p>
                    ) : (
                        <div className="w-full">
                            <Input
                                type="text"
                                defaultValue={userForm?.alertName}
                                onChange={(e) =>
                                    handleChange("alertName", e.target.value)
                                }
                                borderColor={"gray.300"}
                                borderWidth={"2px"}
                                isDisabled={disable}
                            />
                            {submitClicked && !userForm?.alertName && (
                                <Text color="red.500" fontSize="sm" mt="1">
                                    Please enter the alert name.
                                </Text>
                            )}
                            {userForm?.alertName?.length > 100 && (
                                <div>
                                    <Text color="red.500" fontSize="sm" mt="1">
                                        You have exceed the character limit of 100 by{" "}
                                        {userForm?.alertName?.length - 100}
                                    </Text>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </div>




            {view === true && <hr className="mt-3"></hr>}

            {(userForm?.alertGroupId && alertGroup?.alertType === "product") &&
                <div className="sm:w-[100%] flex flex-col sm:flex-row gap-2">
                    <div className="sm:w-[50%] flex flex-row gap-2">
                        <div className="w-[50%] flex flex-col gap-3 mt-6">
                            <p className="text-[#3E3C42] text-lg font-medium">
                                {alertGroup?.alertType === "product" && "Product"}
                                {alertGroup?.alertType === "system" && "System"}
                                {alertGroup?.alertType === "device" && "Device"}
                            </p>
                            {<p className="text-[#525056] text-sm font-medium">{alertGroup?.alertTypeName}</p>}
                        </div>

                        <div className="w-[50%] flex flex-col gap-3 mt-6">
                            <p className="text-[#3E3C42] text-lg font-medium">
                                {alertGroup?.alertType === "product" && "Product Group"}
                                {alertGroup?.alertType === "system" && "System Group"}
                                {alertGroup?.alertType === "device" && "Device Group"}
                            </p>
                            {<p className="text-[#525056] text-sm font-medium">{alertGroup?.alertTypeGroup}</p>}

                        </div>
                    </div>
                </div>
            }

            <div className="flex flex-row items-center gap-1 mt-6">
                <p className="text-xs text-[#3E3C42]">Alert UUID</p>
                <p>{userForm?.alertId}</p>
            </div>

            {view === false &&
                <div className="flex gap-[10px] items-center ml-0 mt-6 mb-20 md:mb-0">

                    {disable ?
                        <TonalButton
                            text={"Back to editing"}
                            width={"130px"}
                            onClick={() => {
                                setActiveStep(0);
                                setReview(false);
                                setDisable(false);
                            }}
                        //disable={activeStep > 0 || view}
                        /> :
                        <PrimaryButton
                            text={"Save"}
                            width={"130px"}
                            onClick={handleSubmit}
                        //disable={activeStep > 0 || view}
                        //color="green"
                        />}

                </div>}

            <DiscardModal
                isModalOpen={isModalOpen}
                handleCloseModal={handleCloseModal}
                handleConfirmDiscard={handleConfirmDiscard}
            />
        </div>
    );
};

export default AlertsInfoForm;
