export const formatDateTime = (epochTimestamp) => {
  const isMilliseconds = epochTimestamp >= 1000000000000; // checking if time stamp is in miliseconds or not after 2001

  const date = new Date(
    isMilliseconds ? epochTimestamp : epochTimestamp * 1000
  );

  const formattedDate = date.toLocaleDateString("en-IN", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour12: false,
    timeZone: "UTC",
  });

  const formattedTime = date.toLocaleTimeString("en-IN", {
    hour12: false,
    timeZone: "UTC",
  });

  return `${formattedDate} ${formattedTime}`;
};
