import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useWindowSize } from "@uidotdev/usehooks";
import React, { useState, useEffect, useRef, useContext } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
const MuiTheme = createTheme();

const TokenAllocationTable = ({ tableData }) => {
  const size = useWindowSize();
  function convertTime(inputTime) {
    const inputDate = new Date(inputTime);

    // Get components of the date
    const day = inputDate.getDate();
    const month = inputDate.toLocaleString("default", { month: "short" });
    const year = inputDate.getFullYear().toString().substr(-2);
    let hours = inputDate.getHours();
    let minutes = inputDate.getMinutes();

    // Add leading zero if necessary
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;

    // Format the result in 24-hour format
    const result = `${day} ${month} '${year} ${hours}:${minutes}`;

    return result;
  }

  const columns = [
    {
      field: "id",
      headerName: "user id",
      hide: true,
    },
    {
      field: "userName",
      headerName: "User Name",
    },

    {
      field: "transactionDate",
      headerName: "Date Time",
      renderCell: (params) => (
        <div>{convertTime(params.row.transactionDate)}</div>
      ),
    },
    {
      field: "userEmail",
      headerName: "Email",
    },
    {
      field: "token",
      headerName: "Token",
      renderCell: (params) => (
        <div
          className={`w-full flex justify-start gap-1 ${
            params.row.status === "SUCCESS"
              ? params.row.token > 0
                ? "text-[#7AC958]"
                : ""
              : "text-[#DC362E]"
          }`}
        >
          {params.row.status === "SUCCESS"
            ? params.row.token > 0
              ? "+"
              : ""
            : ""}
          {params.row.token}
          <img src="/token.svg" alt="coins" />
        </div>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: (params) => (
        <div
          className={`w-full ${
            params.value === "FAILURE" ? "text-[#E46962]" : "text-[#7AC958]"
          }`}
        >
          {params.value === "FAILURE" ? "Failed" : "Success"}
        </div>
      ),
    },
  ];
  const headerClass =
    "text-sm font-normal text-[#79767D] bg-[#DDEEFF] capitalize";
  const cellClass = "text-sm font-normal text-[#3E3C42] whitespace-normal";
  const flexMap = [0, 1, 1, 2, 1, 1];

  columns.map((val, idx) => {
    val["headerClassName"] = headerClass;
    val["cellClassName"] = cellClass;
    val["flex"] = flexMap[idx];
  });

  return (
    <div className="mt-2 overflow-auto">
      <ThemeProvider theme={MuiTheme}>
        <DataGrid
          // scro
          rows={tableData}
          columns={columns}
          getRowId={(row) => row.id}
          getRowHeight={() => {
            return "37px";
          }}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 5 },
            },
          }}
          columnVisibilityModel={{
            id: false,
          }}
          hideFooter={false}
          rowsPerPageOptions={[5, 10, 20]}
          sx={{ minWidth: "1000px", margin: 0, padding: 0 }}
        />
      </ThemeProvider>
    </div>
  );
};

export default TokenAllocationTable;
