import { DataGrid } from "@mui/x-data-grid";
import { useRef, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material";
import ReportModal from "../Components/ReportModal";

import CustomCell from "./CustomCell";

const MuiTheme = createTheme();

const DetailClick = (param) => {
  const [openModal, setOpenModal] = useState(false);
  let { colDef, row } = param;
  let { field } = colDef;
  return (
    <>
      <p
        className="text-[#084298] font-medium text-sm cursor-pointer"
        onClick={() => setOpenModal(true)}
      >
        View detail
      </p>
      {openModal && (
        <ReportModal
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          row={row}
        />
      )}
    </>
  );
};

const columns = [
  {
    field: "id",
    headerName: "SR No.",
    flex: 1,
  },
  {
    field: "cameraGpId",
    headerName: "Area",
    flex: 1,
  },
  {
    field: "vehicleNo",
    headerName: "Truck Id",
    flex: 1,
    renderCell: (params) => (
      <CustomCell
        rowIndex={params}
        truckName={params.row.vehicleNo}
        uniqueId={params}
        // user={params.row.userId}
        // userName={params.row.userName}
      />
    ),
  },
  {
    field: "date",
    headerName: "Date",
    flex: 1,
    valueGetter: ({ row }) => {
      if (row?.truckInTime != null) {
        const date = new Date((row?.truckInTime - 5.5 * 60 * 60) * 1000);
        const day = date.getDate().toString().padStart(2, "0"); // Get the day with leading zero if needed
        const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Get the month with leading zero if needed (Note: month index starts from 0)
        const year = date.getFullYear(); // Get the full year

        return `${day}/${month}/${year}`;
      }
      return "...";
    },
  },
  {
    field: "truckInTime",
    headerName: "In Time",

    flex: 1,
    valueFormatter: ({ value }) => {
      if (value != null) {
        return new Date(value * 1000).toLocaleTimeString("en-US", {
          timeZone: "UTC",
        });
      }
      return "...";
    },
  },
  {
    field: "truckOutTime",
    headerName: "Out Time",
    type: "time",
    flex: 1,
    valueGetter: ({ value }) => {
      if (value == null) {
        return "...";
      } else {
        return new Date(value * 1000).toLocaleTimeString("en-US", {
          timeZone: "UTC",
        });
      }
    },
  },
  {
    field: "tat",
    headerName: "Tat",
    flex: 1,
    valueGetter: ({ row }) => {
      if (row.truckOutTime == null) {
        return "...";
      } else {
        const timeDifferenceInSeconds = Math.abs(
          row.createdAt - row.truckOutTime
        );
        const timeDifferenceDate = new Date(timeDifferenceInSeconds * 1000);
        const hours = timeDifferenceDate.getUTCHours();
        const minutes = timeDifferenceDate.getUTCMinutes();
        const seconds = timeDifferenceDate.getUTCSeconds();

        const timeString = `${hours.toString().padStart(2, "0")}:${minutes
          .toString()
          .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

        return timeString;
      }
    },
  },
  // {
  //   field: "hasAlert",
  //   headerName: "Violation",
  //   flex: 1,
  //   valueGetter: ({ value }) => {
  //     if (value) return "Violations detected";
  //     return "All checks passed";
  //   },
  // },
  {
    field: "hasAlert",
    headerName: "Compliance",
    flex: 1,
    valueGetter: ({ row }) => {
      const { score, cameraGpId } = row;

      // if (
      //   cameraGpId == "weighBridge1-left" ||
      //   cameraGpId == "weighBridge1-right"
      // ) {
      //   score.total = 1;
      // }
      // if (
      //   cameraGpId == "bay1" ||
      //   cameraGpId == "bay2" ||
      //   cameraGpId == "bay3" ||
      //   cameraGpId == "bay4"
      // ) {
      //   score.total = 16;
      // }

      if (score.null != score.total) {
        return `(${score.pass}/${score.total})`;
      } else {
        return "NA";
      }
    },
  },
  {
    field: "report",
    headerName: "Reports",
    type: "actions",
    flex: 1,
    renderCell: DetailClick,
  },
];

const ReportTable = ({ rowData }) => {
  for (let i = 0; i < rowData.length; i++) {
    // rowData[i]["id"] = i + 1;
    rowData[i]["score"] = {
      total: 0,
      fail: 0,
      pass: 0,
      null: 0,
    };
  }

  let index = 1;
  let data = rowData.map((ele, idx) => {
    let events = ele.events;

    for (const category in events) {
      for (const check in events[category]) {
        events[category][check].map((item) => {
          const complianceCheck =
            item.passed == null ? "Null" : item.passed ? "Pass" : "Fail";

          index++;
          rowData[idx]["score"].total++;
          if (complianceCheck === "Fail") {
            rowData[idx]["score"].fail++;
          }
          if (complianceCheck === "Pass" || complianceCheck === "Null") {
            rowData[idx]["score"].pass++;
          }
          if (complianceCheck === "Null") {
            rowData[idx]["score"].null++;
          }
        });
      }
    }
  });

  const headerClass =
    "text-xs font-medium text-[#525056] bg-[#ddeeff] uppercase";
  const cellClass = "text-sm font-medium text-[#3E3C42]";
  columns.map((val) => {
    val["headerClassName"] = headerClass;
    val["cellClassName"] = cellClass;
  });
  // for (let i = 0; i < rowData.length; i++) {
  //   rowData[i]["id"] = i + 1;
  // }

  const filteredRows = rowData.filter(
    (row) => row.score.null != row.score.total
  );

  console.log("filtert", rowData);

  return (
    <div className="overflow-x-auto">
      <ThemeProvider theme={MuiTheme}>
        <DataGrid
          rows={filteredRows}
          columns={columns}
          columnVisibilityModel={{
            id: false,
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
          pageSizeOptions={[5, 10, 25]}
          sx={{ minWidth: "1000px" }}
        />
      </ThemeProvider>
    </div>
  );
};

export default ReportTable;
