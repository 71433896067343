import ReactApexChart from "react-apexcharts";

const SpiderChart = ({ points, labels }) => {
  const series = [
    {
      name: "Gap width",
      data: points,
    },
  ];
  const options = {
    chart: {
      type: "radar",
    },
    markers: {
      size: 4,
      hover: {
        size: 7,
      },
      colors: "#F57C00",
    },
    tooltip: {
      x: {
        show: true,
        formatter: function (value) {
          return "Partition " + value;
        },
      },
      theme: "dark",
      fillSeriesColor: true,
      style: {
        fontSize: "16px",
      },
    },
    xaxis: {
      categories: labels,
      labels: {
        show: true,
        style: {
          colors: Array.from({ length: labels?.length }, () => "#607D8B"),
          fontSize: "12px",
          fontWeight: "bold",
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        style: {
          colors: Array.from({ length: points?.length }, () => "#263238"),
          fontSize: "13px",

          fontWeight: 700,
        },
      },
    },
    plotOptions: {
      radar: {
        polygons: {
          strokeColor: "#1976D2",
          fill: {
            colors: ["#e6f7ff", "#f2f9fe"],
          },
        },
      },
    },
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="radar"
      height="100%"
      width="100%"
    />
  );
};

export default SpiderChart;
