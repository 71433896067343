// api's for feed

import axios from "axios";
import { baseURL } from "../../..";

// get images
export const getFeedDataApi = async (auth, requestData, setLoading) => {
  try {
    setLoading(true);
    let res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/feedLibrary/images/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data,
      };
    } else {
      return {
        success: false,
        data: null,
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      error: error,
      data: null,
    };
  } finally {
    setLoading(false);
  }
};

// generate video

export const getVideoDataApi = async (auth, requestData, setLoading) => {
  try {
    setLoading(true);
    const res = await axios.post(
      `${baseURL}vision/v2/qualityTracking/feedLibrary/video/`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    console.log(res);
    const data = res.data;
    if (data) {
      if (data?.success) {
        return {
          success: data?.success,
          url: data?.url,
          message: data?.message,
        };
      } else {
        return {
          success: data?.success,
          url: data?.url,
          message: data?.message,
        };
      }
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      url: null,
      message: "No video in range",
    };
  } finally {
    setLoading(false);
  }
};
