import React, { useState, useEffect } from 'react'
import { Select, Spinner } from "@chakra-ui/react";
import SecondaryButton from '../../../../util/Buttons/SecondaryButton';
import FloatingInput from '../../../../util/VisionUtils/FloatingInput';
import PieChart from '../../../Charts/MulpicCharts/PieChart';
import LineTrendChart from '../../../Charts/MulpicCharts/LineTrendChart';
import StackBarChart from '../../../Charts/MulpicCharts/StackBarChart';
import BarChart from '../../../Charts/MulpicCharts/BarChart';
import HighMovingStackBarChart from '../../../Charts/MulpicCharts/HighMovingStackBarChart';
import RangedBarChart from '../../../Charts/MulpicCharts/RangedBarChart';

const tempData = [
    {
        "In Range": 10,
        "Low": 20,
        "High": 30
    },
    {
        "In Range": 10,
        "Low": 20,
        "High": 30
    },
    {
        "In Range": 10,
        "Low": 20,
        "High": 30
    },
    {
        "In Range": 10,
        "Low": 20,
        "High": 30
    },
    {
        "In Range": 10,
        "Low": 20,
        "High": 30
    },
    {
        "In Range": 10,
        "Low": 20,
        "High": 30
    },
]

const TargetTemperature = ({data}) => {

    const [selectedOption, setSelectedOption] = useState("Overall");

    const dataOptions = {
        qc: ["Overall", "Head", "Body", "Tail", "Length"],
    }

    return (
        <div className=" flex flex-col w-full h-full bg-white rounded-xl" id="#targetTemperatureData">
            <div className="flex flex-row gap-4 py-[2px]  justify-between items-center px-3 ">
                <p className="rounded-e-xl rounded-s-xl text-xl font-medium">
                    Target Temperature Analysis
                </p>
                <div className="w-[140px] px-3 py-1">
                    <Select
                        borderColor="#CAC5CD"
                        color="#605D64"
                        value={selectedOption}
                        variant="outline"
                        className="!rounded-md !text-sm !font-medium !text-[#525154]"
                        onChange={(e) => {setSelectedOption(e.target.value)}}
                    >
                        {
                            dataOptions?.qc?.map((item) =>
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            )
                        }
                    </Select>
                </div>
            </div>

            <div className="flex flex-col min-[900px]:flex-row py-[2px] justify-between items-center px-3 w-full overflow-x-auto h-full min-h-[320px]">
                <div className='py-[2px] bg-white w-full min-w-[400px] overflow-x-auto !h-full'>
                    <p className='text-base font-medium'>TGT_FRT Frequency</p>
                    <RangedBarChart data={data?.frt[selectedOption]} labels={["In Range", "Low", "High"]} yAxisText={"Count"} xAxisText={"Absolute difference from target temperature"}  />
                </div>
                <div className='py-[2px] bg-white w-full min-w-[400px] overflow-x-auto !h-full'>
                    <p className='text-base font-medium'>TGT_FRT</p>
                    <RangedBarChart data={data?.frt[selectedOption]} labels={["In Range", "Low", "High"]} yAxisText={"Count"} xAxisText={"Absolute difference from target temperature"} stackType={true}/>
                </div>
            </div>
            
            <div className="flex flex-col min-[900px]:flex-row py-[2px] justify-between items-center px-3 w-full overflow-x-auto h-full min-h-[320px]">
                <div className='py-[2px] bg-white w-full min-w-[400px] overflow-x-auto !h-full'>
                    <p className='text-base font-medium'>TGT_SCT Frequency</p>
                    <RangedBarChart data={data?.sct[selectedOption]} labels={["In Range", "Low", "High"]} yAxisText={"Count"} xAxisText={"Absolute difference from target temperature"} />
                </div>
                <div className='py-[2px] bg-white w-full min-w-[400px] overflow-x-auto !h-full'>
                    <p className='text-base font-medium'>TGT_SCT</p>
                    <RangedBarChart data={data?.sct[selectedOption]} labels={["In Range", "Low", "High"]} yAxisText={"Count"} xAxisText={"Absolute difference from target temperature"} stackType={true}/>
                </div>
            </div>
        </div>
    )
}

export default TargetTemperature;