import { useEffect, useState } from "react";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import { Spinner } from "@chakra-ui/react";
import ImageLibrary from "../components/ImageLibrary";
import axios from "axios";
import { slabSizingJsplBaseURl } from "../SlabSizing";
import { mixpanelTrackButton } from "../../../util/mixpanelFunctions";
const FeedLib = () => {
  const [alertsChanging, setAlertsChanging] = useState(false);
  const [serverd, setServerd] = useState(true);
  const [imageData, setImageData] = useState([]);

  const [fromTime, setFromTime] = useState(
    new Date(new Date().getTime() - 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10)
  );
  const [toTime, setToTime] = useState(
    new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10)
  );

  const callFeedLibraryData = async () => {
    try {
      const selectedFromDate = new Date(fromTime);
      const formattedFromDate = selectedFromDate.toISOString().split("T")[0];
      const formattedTime = "00:00:00";
      const formattedendTime = "23:59:59";

      const startTime = `${formattedFromDate} ${formattedTime}`;
      const encodedStartTime = encodeURIComponent(startTime);

      const selectedToDate = new Date(toTime);
      const formattedToDate = selectedToDate.toISOString().split("T")[0];

      const endTime = `${formattedToDate} ${formattedendTime}`;
      const encodedToTime = encodeURIComponent(endTime);

      console.log("time", encodedStartTime, "-", encodedToTime);
      const response = await axios.get(
        `${slabSizingJsplBaseURl}feed_library/?start_time=${encodedStartTime}&end_time=${encodedToTime}`
      );

      let data = response.data;
      // modify data by making 3 objects out of 1 because we want to show 3 cameras from single object for top,right ,left view if we dont do this paginator will not work
      const updatedDataForThreeViews = data.reduce((a, b) => {
        let { top_view, right_side_view, left_side_view } = b;
        a.push(
          ...[
            { ...b, showImageKey: left_side_view },
            { ...b, showImageKey: right_side_view },
            { ...b, showImageKey: top_view },
          ]
        );
        return a;
      }, []);
      setImageData(updatedDataForThreeViews.reverse());
      console.log("feed123", updatedDataForThreeViews);
      setAlertsChanging(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setAlertsChanging(false);
    }
  };

  const handleClick = () => {
    callFeedLibraryData();
    mixpanelTrackButton("Photo gallery", "Slab Sizing", "View");
  };

  useEffect(() => {
    setAlertsChanging(true);
    handleClick();
  }, []);

  return (
    <div className="relative flex flex-col p-1">
      <div className={`absolute left-0 right-0 flex justify-center z-50 `}>
        <div className="p-5 pl-6 pr-6 gap-2 flex flex-col md:flex-row items-center bg-white rounded-xl shadow-md">
          <div>
            <FloatingInput
              text="From"
              type="date"
              setDateTime={setFromTime}
              value={fromTime}
              max={toTime}
            />
          </div>
          <div>
            <FloatingInput
              text="To"
              type="date"
              setDateTime={setToTime}
              value={toTime}
              max={new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
                .toISOString()
                .slice(0, 10)}
              min={fromTime}
            />
          </div>

          {alertsChanging ? (
            <Spinner />
          ) : (
            <PrimaryButton
              text={"Apply"}
              width={"fit-content"}
              onClick={handleClick}
              height={"40px"}
            />
          )}
        </div>
      </div>
      {alertsChanging ? (
        <div className="w-full h-full  mt-[200px] flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col gap-4 mt-[160px] md:mt-8 pt-[57px] bg-white rounded-xl justify-start  ">
          <ImageLibrary img={imageData} />
        </div>
      )}
    </div>
  );
};

export default FeedLib;
