import { Select } from "@chakra-ui/react";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import { useEffect, useState } from "react";
import { baseURL } from "../../..";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { filter } from "lodash";

const Header = ({
  customEndDate,
  setCustomToTime,
  selectedExpert,
  selectedExpertId,
  setSelectedExpert,
  setSelectedExpertId,
  expertList,
  organisation,
  fullName,
  auth,
  enquirerDetails,
}) => {
  const [tokenBalance, setTokenBalance] = useState(0);
  const [questionCost, setQuestionCost] = useState(0);
  const [currentDate, setCurrentDate] = useState(
    new Date().toISOString().slice(0, 10)
  );
  const getBalance = async () => {
    try {
      const data = await fetch(baseURL + "token-wallet/v1/balance", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      const res = await data.json();
      if (data.status !== 400) {
        setTokenBalance(res.User.balance);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getQuestionCost = async () => {
    try {
      const data = await fetch(baseURL + "token-wallet/v1/token-cost", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      const res = await data.json();
      if (data.status !== 400) {
        const filteredItems = res.find(
          (el) => el.productDescription === "Ask an expert"
        );
        const cost = filteredItems?.productCost;
        setQuestionCost(cost);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getBalance();
    getQuestionCost();
  }, []);

  return (
    <>
      <div className="flex flex-col gap-2 md:flex-row md:justify-between">
        <div>
          <p className="text-[16px] md:text-[20px]" style={{ fontWeight: 500 }}>
            Hello, {enquirerDetails?.enqName || "Enquirer"}
          </p>
        </div>
        <div className="flex justify-end">
          <div className="inline-block">
            <div className="flex  md:justify-end items-center my-2 w-full">
              <div className=" flex items-center  justify-between lg:gap-4 sm:gap-2 px-1 py-1 rounded-sm bg-[#FFFFD8] w-full">
                <p className="text-[14px] text-[#605D64] font-normal p-1">
                  Question cost
                </p>

                <div className="flex items-center gap-1">
                  <p className="text-[#3E3C42] font-semibold text-[16px]">
                    {questionCost}
                  </p>
                  <img src="/token.svg" className="w-full h-full" alt="token" />
                </div>
              </div>
            </div>
            <div className="flex  md:justify-end items-center my-2 w-full">
              <div className="flex items-center justify-between lg:gap-4 sm:gap-2 px-1 py-1 rounded-sm bg-[#FFFFD8]">
                <p className="text-[14px] text-[#605D64] font-normal p-1">
                  Current balance
                </p>

                <div className="flex items-center gap-1">
                  <p className="text-[#3E3C42] font-semibold text-[16px]">
                    {tokenBalance}
                  </p>
                  <img src="/token.svg" className="w-full h-full" alt="token" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex  md:justify-end items-center my-2">
        <div className="flex items-center justify-between lg:gap-4 sm:gap-2 px-1 py-1 rounded-sm bg-[#FFFFD8] w-[200px]">
          <div>
            <p className="text-[14px] text-[#605D64] font-normal p-1">
              Current balance
            </p>
          </div>
          <div className="flex items-center gap-1">
            <p className="text-[#3E3C42] font-semibold text-[16px]">
              {tokenBalance}
            </p>
            <img src="/token.svg" className="w-full h-full" alt="token" />
          </div>
        </div>
      </div> */}
      <div className="flex flex-col md:flex-row items-start gap-4 md:gap-16 text-[14px] pt-2">
        <div className="flex xs:flex-row flex-col">
          <div className="md:mr-2 mt-2 md:mt-0">
            <p className=" font-semibold">Expert</p>
            <div className="flex md:min-w-[110px]  items-center mt-1">
              <Select
                borderColor="#CAC5CD"
                color="#605D64"
                variant="outline"
                width={"168px"}
                className="!text-sm !font-medium text-[#605D64]"
                _focus={{ borderColor: "blue.500" }}
                value={selectedExpertId || ""}
                onChange={(e) => {
                  const newSelectedId = parseInt(e.target.value, 10);
                  const newSelectedExpert = expertList.find(
                    (expert) => expert.expertId === selectedExpertId
                  );
                  setSelectedExpert(newSelectedExpert);
                  setSelectedExpertId(newSelectedId);
                }}
              >
                {expertList.map((expert) => (
                  <option key={expert.expertId} value={expert.expertId}>
                    {expert.expertName}
                  </option>
                ))}
              </Select>
            </div>
          </div>
          <div className="xs:ml-2 mt-2 md:mt-0">
            <p className=" font-semibold">Need by</p>
            <div className="flex sm:gap-1 items-center">
              <div className="mt-1 w-[0px] h-[0px] sm:min-w-[30px] sm:min-h-[30px]">
                <img src="/expert/date.svg" alt="" />
              </div>
              <div className="flex justify-center mt-1">
                <div className="md:min-w-[110px]">
                  <FloatingInput
                    text=""
                    type="date"
                    setDateTime={setCustomToTime}
                    value={customEndDate}
                    min={currentDate}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="xs:mt-2 md:mt-0">
          <p className=" font-semibold">Enquirer</p>
          <div className="flex flex-row gap-1 items-center">
            <div className="w-[30px] h-[30px]">
              {enquirerDetails?.enqImgUrl ? (
                <img
                  src={enquirerDetails?.enqImgUrl + `?${performance.now()}`}
                  alt=""
                  className="max-h-full w-full"
                />
              ) : (
                <AccountCircleIcon />
              )}
            </div>
            <div>
              <p>{enquirerDetails?.enqName || "Name not specified"}</p>
              <p className="text-[#AEA9B1]">
                {enquirerDetails?.enqOrg || "Unaffiliated"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
