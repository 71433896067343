// export const dummyRoZldClarifierData = {
//   columns: ["parameter", "value"],
//   tableData: [
//     {
//       id: 1, // unique id in each row
//       parameter: "Total Hardness Inlet",
//       value: 35,
//       min: 2000,
//       max: 3340,
//       unit: "PPM",
//     },
//     {
//       id: 2, // unique id in each row
//       parameter: "Calcium Hardness inlet",
//       value: 961,
//       min: 1600,
//       max: 2900,
//       unit: "PPM",
//     },
//     {
//       id: 3, // unique id in each row
//       parameter: "Total Hardness Outlet",
//       value: 700,
//       min: 172,
//       max: 212,
//       unit: "PPM",
//     },
//     {
//       id: 4, // unique id in each row
//       parameter: "Effluent Feed Flow",
//       value: 700,
//       min: 109.84,
//       max: 146.43,
//       unit: "m3/hr",
//     },
//   ],
// };

// export const dummyReportData = {
//   columns: [
//     "timeStamp",
//     "totalHardnessInlet(ppm)",
//     "calciumHardnessInlet(ppm)",
//     "totalHardnessOutlet(ppm)",
//     "effluentFeedFlow(m3/hr)",
//     "optimalDosingRate(ppm)",
//   ],
//   data: [
//     {
//       id: 1,
//       timeStamp: 1678861440000,
//       "totalHardnessInlet(ppm)": 150,
//       "calciumHardnessInlet(ppm)": 50,
//       "totalHardnessOutlet(ppm)": 100,
//       "effluentFeedFlow(m3/hr)": 500,
//       "optimalDosingRate(ppm)": 1.5,
//     },
//     {
//       id: 2,
//       timeStamp: 1678861440000,
//       "totalHardnessInlet(ppm)": 160,
//       "calciumHardnessInlet(ppm)": 55,
//       "totalHardnessOutlet(ppm)": 105,
//       "effluentFeedFlow(m3/hr)": 510,
//       "optimalDosingRate(ppm)": 1.6,
//     },
//     {
//       id: 3,
//       timeStamp: 1678861440000,
//       "totalHardnessInlet(ppm)": 170,
//       "calciumHardnessInlet(ppm)": 60,
//       "totalHardnessOutlet(ppm)": 110,
//       "effluentFeedFlow(m3/hr)": 520,
//       "optimalDosingRate(ppm)": 1.7,
//     },
//     {
//       id: 4,
//       timeStamp: 1678861440000,
//       "totalHardnessInlet(ppm)": 180,
//       "calciumHardnessInlet(ppm)": 65,
//       "totalHardnessOutlet(ppm)": 115,
//       "effluentFeedFlow(m3/hr)": 530,
//       "optimalDosingRate(ppm)": 1.8,
//     },
//     {
//       id: 5,
//       timeStamp: 1678861440000,
//       "totalHardnessInlet(ppm)": 190,
//       "calciumHardnessInlet(ppm)": 70,
//       "totalHardnessOutlet(ppm)": 120,
//       "effluentFeedFlow(m3/hr)": 540,
//       "optimalDosingRate(ppm)": 1.9,
//     },
//   ],
// };

export const dummyParamterChartData = {
  data: [720, 750, 730, 780, 800, 790, 750],
  times: [
    1707084000000, 1707087600000, 1707091200000, 1707094800000, 1707098400000,
    1707102000000, 1707105600000,
  ],
};
