import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarExport,
} from "@mui/x-data-grid-pro";
import { useEffect, useState } from "react";
import { createTheme, ThemeProvider, Tooltip } from "@mui/material";
import AlertImagesModal from "../Components/AlertImagesModal";

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
};

const MuiTheme = createTheme();

const ViewDetail = ({ row }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <p
        className="cursor-pointer text-blue-800 font-medium text-base hover:scale-105"
        onClick={() => setOpenModal(true)}
      >
        View image
      </p>
      {openModal && (
        <AlertImagesModal
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          row={row}
        />
      )}
    </>
  );
};

export const stringDecimalFix = (str) => {
  const regex = /-?\d+\.\d+/;
  const match = str.match(regex);
  if (match) {
    const floatingNumber = parseFloat(match[0]);
    const fixedNumber = floatingNumber.toFixed(2);
    return str.replace(regex, fixedNumber);
  }
  return str;
};

const columns = [
  {
    field: "id",
    headerName: "SR No.",
  },
  {
    field: "loader_id",
    headerName: "Loader Id",
    type: "string",
  },
  {
    field: "date",
    headerName: "Violation date",
    type: "date",
    valueGetter: ({ row }) => row.timestamp * 1000,
    valueFormatter: ({ value }) => {
      return new Date(value).toLocaleDateString("en-GB");
    },
  },
  {
    field: "time",
    headerName: "Violation time",
    type: "dateTime",
    valueGetter: ({ row }) => new Date(row.timestamp * 1000),
    valueFormatter: ({ value }) => {
      return new Date(value).toLocaleTimeString();
    },
  },
  {
    field: "concentrate",
    headerName: "Concentrate",
    type: "string",
  },
  // {
  //   field: "smelter",
  //   headerName: "Smelter",
  //   type: "string",
  // },
  // {
  //   field: "round",
  //   headerName: "Round",
  //   type: "string",
  // },
  // {
  //   field: "concentrate_position",
  //   headerName: "Position",
  //   type: "string",
  //   renderCell: ({ value }) => {
  //     let baySectionMap = {};
  //     value?.split(",")?.map((item) => {
  //       if (item[1] && item[3]) {
  //         if (item[1] in baySectionMap) {
  //           baySectionMap[item[1]].push(item[3]);
  //         } else baySectionMap[item[1]] = [item[3]];
  //       }
  //     });
  //     return (
  //       <div className="flex flex-col gap-1 w-full">
  //         {Object.keys(baySectionMap)?.map((item) => {
  //           return (
  //             <div className="flex items-center justify-between gap-2 w-full">
  //               <p>B {item}</p>
  //               <p>S {baySectionMap[item]}</p>
  //             </div>
  //           );
  //         })}
  //       </div>
  //     );
  //   },
  // },
  {
    field: "violation_type",
    headerName: "Violation Type",
    type: "singleSelect",
    valueOptions: [
      "Meter not reset",
      "Exceeded tonnage",
      "Incorrect concentrate order",
    ],
  },
  {
    field: "alertMessages",
    headerName: "Violation",
    type: "string",
    renderCell: ({ value }) => {
      return (
        <div className="w-full">
          <p className="whitespace-normal">{stringDecimalFix(value)}</p>
        </div>
      );
    },
  },
  {
    field: "loader_image",
    type: "action",
    headerName: "",
    renderCell: ({ row }) => <ViewDetail row={row} />,
  },
];

const AlertTable = ({ rowData, filterData, setFilterModel }) => {
  const [rows, setRows] = useState([]);
  const headerClass =
    "text-xs font-medium text-[#525056] bg-[#ddeeff] uppercase";
  const cellClass = "text-sm font-medium text-[#3E3C42]";
  const flexMap = {
    id: 0,
    loader_id: 1,
    date: 1,
    time: 1,
    concentrate: 1,
    smelter: 0.75,
    round: 0.75,
    violation_type: 1,
    alertMessages: 3,
    loader_image: 1,
  };
  columns.map((val, idx) => {
    val["headerClassName"] = headerClass;
    val["cellClassName"] = cellClass;
    val["flex"] = flexMap[val.field];
    return val;
  });

  useEffect(() => {
    setRows((prev) => {
      let newData = rowData.map((item, idx) => {
        item["id"] = idx + 1;
        return item;
      });
      return newData;
    });
  }, [rowData]);

  return (
    <div className="overflow-x-auto w-full">
      <ThemeProvider theme={MuiTheme}>
        <DataGridPro
          rows={rows}
          columns={columns}
          columnVisibilityModel={{
            id: false,
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
            sorting: {
              sortModel: [{ field: "time", sort: "desc" }],
            },
          }}
          pagination
          getRowHeight={() => "auto"}
          filterModel={filterData}
          onFilterModelChange={(newFilterModel) =>
            setFilterModel(newFilterModel)
          }
          slots={{
            toolbar: CustomToolbar,
          }}
          pageSizeOptions={[5, 10, 25]}
          sx={{ minWidth: "1000px" }}
        />
      </ThemeProvider>
    </div>
  );
};

export default AlertTable;
