import LabelPie from "../../../Charts/SelfService/LabelPie";

const colors = [
  "#ffc107",
  "#5193f6",
  "#ef6f12",
  "#1c56ac",
  "#e91e63",
  "#00bcd4",
  "#8bc34a",
  "#9c27b0",
  "#673ab7",
  "#ff9800",
  "#4caf50",
  "#795548",
];

const LabelStats = ({ userData, predictionData }) => {
  return (
    <div className="px-5 py-4 flex gap-2 min-[430px]:gap-9 items-center rounded-md border border-[#EBEBEB] shadow-md h-[180px] w-full">
      <LabelPie predictionData={predictionData} />
      <div className="w-full">
        <div className="flex gap-4 items-center max-w-[110px] sm:max-w-[50vw] md:max-w-[40vw] xl:max-w-[30vw] 2xl:max-w-max overflow-x-auto">
          {predictionData &&
            Object.keys(predictionData).length > 0 &&
            Object.keys(predictionData).map((item, idx) => {
              let sum = Object.keys(predictionData).reduce((a, b) => {
                return a + predictionData[b]?.img?.length;
              }, 0);
              let percentage = (
                (predictionData[item]?.img?.length / sum) *
                100
              ).toFixed(1);
              return (
                <div className="p-2 rounded-md flex flex-col gap-2 bg-[#f8f8fa] min-w-[120px] whitespace-nowrap">
                  <div className="flex items-center gap-1">
                    <div
                      className="h-2 w-2 rounded-full"
                      style={{ backgroundColor: colors[idx] }}
                    />
                    <p className="text-[#3E3C42] text-sm">{item}</p>
                  </div>
                  <div className="rounded bg-white p-1 flex gap-3 items-center">
                    <div className="flex justify-center items-center h-[42px] w-[42px] bg-black rounded">
                      <img
                        src={predictionData[item]?.img[0]?.img}
                        alt="img"
                        className="max-h-full w-auto"
                      />
                    </div>
                    <div className="flex flex-col gap-0">
                      <p className="text-[#525056] text-sm font-medium">
                        {predictionData[item]?.img?.length} Files
                      </p>
                      <p
                        className="text-sm font-medium"
                        style={{ color: colors[idx] }}
                      >
                        {percentage}%
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default LabelStats;
