import mixpanel from 'mixpanel-browser';

const mixpanelToken = process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN ?? null;

export const mixpanelInit = () => {
  if (mixpanelToken) {
    mixpanel.init(mixpanelToken, {
      api_host: 'https://drdun9bya6vw5.cloudfront.net',
      persistence: 'localStorage',
      track_pageview: true,
    });
  }
};

export const mixpanelAppRegister = () => {
  if (mixpanelToken) {
    mixpanel.identify(localStorage.getItem('email'));
    mixpanel.people.set('$email', localStorage.getItem('email'));
    mixpanel.people.set('$name', localStorage.getItem('fullname'));
    mixpanel.people.set('organisation', localStorage.getItem('organisation')) &&
      console.log(
        'Mixpanel organisation',
        localStorage.getItem('organisation')
      );
    mixpanel.people.set('role', localStorage.getItem('user_type'));
    mixpanel.people.set('$region', localStorage.getItem('location'));
    mixpanel.people.set('$phone', localStorage.getItem('phone'));
    mixpanel.register({
      email: localStorage.getItem('email'),
      role: localStorage.getItem('user_type'),
      organisation: localStorage.getItem('organisation'),
    });
  }
};

export const mixpanelPathnameEvent = (pathname) => {
  if (mixpanelToken) {
    if (pathname === '/vision/Sizing/woodchips/grasim-local') {
      mixpanel.track('Grasim woodchips page visited');
    } else if (
      pathname === '/vision/ProcessMonitoring/sinterflame/hindustanzinc'
    ) {
      mixpanel.track('Hindustanzinc sinterflame page visited');
    } else if (
      pathname === '/vision/workforceMonitoring/workforcesafety/asianpaints'
    ) {
      mixpanel.track('Asianpaints workforcesafety page visited');
    } else if (pathname) {
      let str = pathname.split('/').slice(1).reverse().join(' ');
      mixpanel.track(`${str} page visited`);
    }
  }
};

export const mixpanelLoginFunction = (res, email) => {
  if (mixpanelToken) {
    mixpanel.identify(email);
    mixpanel.people.set('$email', email);
    mixpanel.people.set('$name', res?.data?.fullname);
    mixpanel.people.set('organisation', res?.data?.organisation);
    mixpanel.people.set('role', res?.data?.role);
    mixpanel.people.set('$region', res?.data?.location);
    mixpanel.people.set(
      '$phone',
      res?.data?.phoneNumber ? res?.data?.phoneNumber : ''
    );
    mixpanel.register({
      email: email,
      role: res?.data?.role,
      organisation: res?.data?.organisation,
    });
    mixpanel.track('User login', {
      location: res?.data?.location,
    });
  }
};

export const mixpanelTrackClientSelect = (client, material) => {
  if (mixpanelToken) {
    if (client) {
      mixpanel.track(`${client} ${material} selected`);
    } else mixpanel.track(`${material} selected`);
  }
};

export const mixpanelWorkforceBayTrack = (selectedBay, client) => {
  if (mixpanelToken) {
    mixpanel.track(`Workforce view`, {
      bay: selectedBay,
      client: client,
    });
  }
};

export const mixpanelTrackTabs = (tab, tool) => {
  if (mixpanelToken) {
    let organization = localStorage.getItem('organisation');
    mixpanel.track('Tab switch', {
      tool: tool,
      tab: tab,
      organisation: organization,
    });
  }
};

export const mixpanelFeedLibTrack = (tab, tool) => {
  if (mixpanelToken) {
    let organization = localStorage.getItem('organisation');
    mixpanel.track('Feed library switch', {
      tool: tool,
      tab: tab,
      organisation: organization,
    });
  }
};

export const mixpanelTrackButton = (tab, tool, buttonClicked) => {
  if (mixpanelToken) {
    let organization = localStorage.getItem('organisation');
    mixpanel.track('Button clicked', {
      tab: tab,
      tool: tool,
      organisation: organization,
      buttonClicked: buttonClicked,
    });
  }
};
