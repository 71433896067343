import axios from "axios";
import { sodaAshAndLimeOptimizerBaseUrl } from "../SodaAshAndLimeOptimizer";
import { dummyParamterChartData } from "../utilis/dummyData";
import { formatDateTimeLocale } from "../utilis/utilityFunctions";

export const getroZLdClarifierDataApi = async (auth, requestData) => {
  try {
    const res = await axios.post(
      `${sodaAshAndLimeOptimizerBaseUrl}getParamInfo`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    const data = res?.data;
    if (data) {
      return data;
    } else {
      throw new Error("Error while fetching data");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error while fetching data");
  }
};

export const postRoZldClarifierDataApi = async (
  auth,
  requestData,
  setLoading
) => {
  try {
    setLoading(true);
    const res = await axios.post(
      `${sodaAshAndLimeOptimizerBaseUrl}predict`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    const data = res?.data;
    if (data?.["prediction"]) {
      return data?.["prediction"];
    } else {
      throw new Error("Error while fetching data");
    }
  } catch (error) {
    throw new Error("Error while fetching data");
  } finally {
    setLoading(false);
  }
};

export const getParamterChartDataApi = async (
  auth,
  requestData,
  setLoading
) => {
  try {
    console.log(auth, requestData);
    //   setLoading(true);
    //   const res = await axios.post(
    //     `${baseURL}endpoint`,
    //     requestData,
    //     {
    //       credentials: "same-origin",
    //       headers: {
    //         "Content-Type": "application/json",
    //         "X-Auth-Token": auth,
    //       },
    //     }
    //   );
    //   const data = res?.data;
    // console.log(data);
    //   if (data) {
    //     return {
    //       success: true,
    //       data,
    //     };
    //   } else {
    //   throw new Error("Error while fetching data")
    //   }

    // mock api for staged data
    return new Promise((resolve, reject) => {
      setLoading((prev) => true);
      setTimeout(() => {
        resolve(dummyParamterChartData);
        setLoading((prev) => false);
      }, 400);
    });
  } catch (error) {
    console.log(error);
    throw new Error("Error while fetching data");
  } finally {
    // setLoading(false);
  }
};
export const getReportDataApi = async (auth, requestData, setLoading) => {
  try {
    // console.log(auth, requestData);
    setLoading(true);
    const res = await axios.post(
      `${sodaAshAndLimeOptimizerBaseUrl}report`,
      requestData,
      {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      }
    );
    const resData = res?.data;
    if (resData) {
      const { columns, data } = resData;
      const tempTableData = data?.map((el) => ({
        ...el,
        timeStamp: el?.timestamp && formatDateTimeLocale(el.timestamp),
        id: el?.["_id"]?.["$oid"],
        "optimalDosingRate(m3/hr)": el?.["optimalDosingRate(ppm)"],
      }));
      return {
        columns,
        tempTableData,
      };
    } else {
      throw new Error("Error while fetching data");
    }
  } catch (error) {
    console.log(error);
    throw new Error("Error while fetching data");
  } finally {
    setLoading(false);
  }
};
