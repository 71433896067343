import { useParams } from "react-router-dom";
import { useEffect, useState, useContext, useRef } from "react";
import NavContext from "../../NavContext";
import { SettingsIcon } from "@chakra-ui/icons";
import { Spinner, IconButton } from "@chakra-ui/react";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import DetailModal from "../components/DetailModal";
import ConstraintModal from "../components/ConstraintModal";
import { getAlertsDataApi } from "../services/alertsServices";
import TableSkeleton from "../components/LoadingState/TableSkeleton";
// import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import {
  CustomFooter,
  CustomStyledDataGrid,
} from "../../../util/MaterialDataGrid/CustomStyledDatagrid";
import {
  dataGridSlotProps,
  generateAlertReportColumns,
} from "../utilis/dataGridColumns";
import {
  gridPaginatedVisibleSortedGridRowEntriesSelector,
  useGridApiRef,
} from "@mui/x-data-grid";
import { AlertsCountChart } from "../../Charts/SinterBeltCharts/AlertsCountChart";
import { AlertCountGridSkeleton } from "../components/LoadingState/AlertCountGridSkeleton";
import { MoodBad } from "@mui/icons-material";

const Alerts = ({ plantId, disable, plantCamMap }) => {
  // refs, params, variables
  const gridApiRef = useGridApiRef();
  const param = useParams();
  const { auth } = useContext(NavContext);
  let material = "sinterbelt";
  let clientId = param.clientId.toLowerCase();
  const indexRef = useRef();

  // state
  const [openModal, setOpenModal] = useState(false);
  const [openConstraintModal, setOpenConstraintModal] = useState(false);
  const [alerts, setAlerts] = useState(null);
  const [alertsCountData, setAlertsCountData] = useState(null);
  const [columns, setColumns] = useState(null);
  const [displayData, setDisplayData] = useState([]);
  const [filterData, setFilterData] = useState({ items: [] });
  const [loading, setLoading] = useState(false);
  const [fromTime, setFromTime] = useState(
    new Date(new Date().getTime() - 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16)
  );
  const [toTime, setToTime] = useState(
    new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16)
  );
  const [selectedPlant, setSelectedPlant] = useState(disable ? plantId : "all");

  const getAlertsData = async () => {
    try {
      const requestData = JSON.stringify({
        clientId: clientId,
        useCase: material.toUpperCase(),
        startDate: new Date(fromTime).getTime() + 5.5 * 60 * 60 * 1000,
        endDate: new Date(toTime).getTime() + 5.5 * 60 * 60 * 1000,
        // plantName: selectedPlant === "All Plants" ? "all" : selectedPlant,
        plantName: selectedPlant,
        maxLimit: 8000,
      });
      const response = await getAlertsDataApi(auth, requestData, setLoading);
      if (response?.data) {
        const tempColumns = await generateAlertReportColumns(
          response.data.columns,
          handleDetail
        );
        setAlertsCountData((prev) => response?.data?.alertGridData);
        setColumns((prev) => tempColumns);
        setAlerts((prev) => response.data.table_data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  // console.log(alertsCountData);
  const handleDetail = (params) => {
    const tempDisplayData =
      gridPaginatedVisibleSortedGridRowEntriesSelector(gridApiRef);
    setDisplayData((prev) => tempDisplayData);
    const index = params.api.getRowIndexRelativeToVisibleRows(params.row.id);
    indexRef.current = index;
    setOpenModal(true);
  };

  // useEffect(() => {
  //   if (fromTime) {
  //     setToTime(
  //       new Date(new Date(fromTime).getTime() + 7 * 24 * 60 * 60 * 1000)
  //         .toISOString()
  //         .slice(0, 16)
  //     );
  //   }
  // }, [fromTime]);

  useEffect(() => {
    getAlertsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative flex flex-col mt-5 md:mt-0 mb-10">
      <IconButton
        position={"absolute"}
        top={"-30px"}
        right={0}
        colorScheme="gray"
        size={"lg"}
        rounded={"100px"}
        color={"#79767D"}
        zIndex={10}
        onClick={() => setOpenConstraintModal(true)}
        icon={<SettingsIcon />}
      >
        Update Constraint
      </IconButton>
      <div className="absolute left-0 right-0 flex justify-center">
        <div className="p-5 pl-6 pr-6 gap-2 flex flex-col md:flex-row items-center bg-white rounded-xl shadow-md">
          <div>
            <FloatingInput
              text="From"
              type="datetime-local"
              setDateTime={setFromTime}
              value={fromTime}
              min={"2021-01-01T00:00"}
              max={new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
                .toISOString()
                .slice(0, 16)}
            />
          </div>
          <div>
            <FloatingInput
              text="To"
              type="datetime-local"
              setDateTime={setToTime}
              value={toTime}
              min={fromTime}
              max={new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
                .toISOString()
                .slice(0, 16)}
            />
          </div>
          <PrimaryButton
            onClick={getAlertsData}
            text={loading ? <Spinner /> : "Show alerts"}
            height={"100%"}
            width={"110px"}
            disable={!fromTime || !toTime || loading}
          />
        </div>
      </div>
      <div className="flex flex-col gap-2 mt-[160px] md:mt-11 pt-[57px] bg-white rounded-xl justify-start">
        {/* <div className="flex justify-between gap-3">
          <div className="flex gap-2 overflow-x-auto py-[2px] px-2">
            <div className="min-w-[110px]">
              <Select
                borderColor={"#CAC5CD"}
                placeholder={disable && plantId}
                variant="outline"
                isDisabled={disable}
                fontSize={"sm"}
                fontWeight={"medium"}
                textColor={"#605D64"}
                rounded={"base"}
                onChange={(e) => setSelectedPlant(e.target.value)}
                value={selectedPlant}
              >
                <option
                  key="all"
                  value="all"
                >
                  All Plants
                </option>
                {!disable &&
                  Object.keys(plantCamMap).map((plant) => {
                    return (
                      <option
                        key={plant}
                        value={plant}
                      >
                        {plant}
                      </option>
                    );
                  })}
              </Select>
            </div>

            <SecondaryButton
              height={"40px"}
              width={"80px"}
              text={loading ? <Spinner /> : "Apply"}
              disable={loading}
              onClick={getAlertsData}
            />
          </div>
        </div> */}

        {loading ? (
          <AlertCountGridSkeleton />
        ) : alertsCountData && alertsCountData?.length ? (
          <AlertsCountChart
            data={alertsCountData}
            filterData={filterData}
            setFilterData={setFilterData}
          />
        ) : (
          <div className="h-[30vh] w-full flex flex-row items-center justify-center border rounded-md">
            <div className="flex flex-row items-center gap-2 text-gray-400">
              <MoodBad fontSize={"large"} />
              <h2 className="text-2xl">No Data!</h2>
            </div>
          </div>
        )}
        {loading ? (
          <TableSkeleton
            headers={[
              "Header 1",
              "Header 2",
              "Header 3",
              "Header 4",
              "Header 5",
              "Header 6",
              "Header 7",
              "Header 8",
            ]}
            rows={10}
            cellsPerRow={8}
          />
        ) : // for data grid
        alerts ? (
          <div
            className="w-full border rounded-md"
            style={{
              height: 108 + 10 * 50 + "px",
            }}
            id="tableContainer"
          >
            <CustomStyledDataGrid
              apiRef={gridApiRef}
              rows={alerts && alerts}
              columns={columns && columns}
              rowHeight={50}
              columnHeaderHeight={50}
              disableColumnSelector={true}
              disableRowSelectionOnClick={true}
              pageSizeOptions={[10, 20, 50]}
              pagination={true}
              disableColumnReorder={true}
              initialState={{
                pagination: { paginationModel: { pageSize: 10, page: 0 } },
              }}
              filterModel={filterData}
              onFilterModelChange={(newFilterModel) =>
                setFilterData(newFilterModel)
              }
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0
                  ? "bg-[#FAFAFA]"
                  : "bg-white"
              }
              slots={{
                footer: CustomFooter,
              }}
              slotProps={dataGridSlotProps && dataGridSlotProps()}
              sx={{
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "normal",
                },
              }}
              showColumnVerticalBorder
            />
          </div>
        ) : (
          <div
            className="w-full flex flex-row items-center justify-center border rounded-md"
            style={{
              height: 108 + 10 * 50 + "px",
            }}
          >
            <div className="flex flex-row items-center gap-2 text-gray-400">
              <MoodBad fontSize={"large"} />
              <h2 className="text-2xl">No Data!</h2>
            </div>
          </div>
        )}
      </div>
      {openModal && (
        <DetailModal
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          data={displayData}
          index={indexRef.current}
          plantName={selectedPlant}
        />
      )}
      {openConstraintModal && (
        <ConstraintModal
          openModal={openConstraintModal}
          closeModal={() => setOpenConstraintModal(false)}
          plantName={selectedPlant}
          material={material}
          clientId={clientId}
        />
      )}
    </div>
  );
};

export default Alerts;
