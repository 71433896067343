import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { baseURL } from "../../..";
import NavContext from "../../NavContext";
import TonalButton from "../../../util/Buttons/TonalButton";
import DraftModal from "../DraftModal";
import { fetchDevices } from "../utils/alertGroupsApi";
import SearchSuggestions from "./components/SearchSuggestions";
import { alertGroupValidation } from "./components/alertGroupValidation";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const initialState = {
  alertGroup: "",
  // selectedTypeName: "",
  // selectedTypeGrp: "",
  alertTypeName: "",
  alertTypeGroup: "",
  alertGroupDesc: "",
  alertGroupId: "",
  alertType: "camera",
  alertTypeId: "",
};
const AddNewAlertGroup = ({
  setShowAlertsTab,
  update,
  selectedAlert,
  view,
}) => {
  const { auth } = useContext(NavContext);
  const [formData, setFormData] = useState({ ...initialState });
  const [validationErrors, setValidationErrors] = useState({
    alertGroup: "",
    // selectedTypeName: "",
    // selectedTypeGrp: "",
    alertTypeName: "",
    alertTypeGroup: "",
    alertGroupDesc: "",
    // alertGroupId: "",
    alertType: "",
    alertGroupMatch: "",
  });

  const [review, setReview] = useState(false);
  const [showDraft, setShowDraft] = useState(false);
  const [isFieldsChanged, setFieldsChanged] = useState(false);
  const toast = useToast();
  const [searchTerm, setSearchTerm] = useState(null);
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const [alertGroups, setAlertGroups] = useState([]);
  const [optionData, setOptionData] = useState([]);
  const getAlertGroupApi = async () => {
    try {
      const response = await axios.get(baseURL + "alert/v1/fetch/alertGroup", {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });

      if (response.data) {
        setAlertGroups(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        baseURL + "product/v1/fetch/product/detail",
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );
      const products = response?.data.filter(
        (item) => item.status === "ACTIVE"
      );

      const optnData = products?.map((product) => ({
        id: product?.product?.productId,
        name: product?.product?.productName,
        group: product?.productGroup,
      }));
      setOptionData(optnData);
    } catch (error) {
      console.log(error);
    }
  };

  // const isValidAlertGrpName = (name) => {
  //   // Regular expression for name validation (allowing only letters, numbers, and underscores, with no numbers at the start and a minimum length of 2 characters)
  //   const regex = /^[a-zA-Z][a-zA-Z0-9_\s]{1,}$/;
  //   return regex.test(name.trim());
  // };
  const handleBackButton = () => {
    if (isFieldsChanged) {
      // show draft if any of the fields changed
      setShowDraft((prev) => true);
      return;
    }
    setShowAlertsTab((prev) => "alerts");
  };

  const handleUpdate = async () => {
    if (selectedAlert?.alertType === "device") {
      const res = await fetchDevices(auth);
      if (res) {
        const optnData = res?.map((device) => ({
          id: device?.deviceInfo?.deviceId,
          name: device?.deviceInfo?.deviceName,
          group: device?.deviceInfo?.deviceCategory,
        }));
        setOptionData(optnData);
      }
    } else if (selectedAlert?.alertType === "system") {
      setOptionData([]);
    } else {
      fetchProducts();
    }

    setFormData({
      ...formData,
      // selectedTypeName: selectedAlert?.alertTypeName,
      // selectedTypeGrp: selectedAlert?.alertTypeGroup,
      alertTypeName: selectedAlert?.alertTypeName,
      alertTypeGroup: selectedAlert?.alertTypeGroup,
      alertGroup: selectedAlert?.alertGroupName,
      alertGroupDesc: selectedAlert?.description,
      alertGroupId: selectedAlert?.alertGroupId,
      alertType: selectedAlert?.alertType,
      alertTypeId: selectedAlert?.alertTypeId,
    });
  };

  const handleChange = async (e) => {
    const { name, value } = e.target;

    setFieldsChanged((prev) => true);
    // If the selected field is 'alertTypeName', find the corresponding product group
    if (name === "alertTypeName") {
      const selectedInfo = optionData?.find((el) => el?.name === value);

      const selectedGrp = selectedInfo ? selectedInfo?.group : "";
      setFormData({
        ...formData,
        // selectedTypeName: value,
        // selectedTypeGrp: selectedGrp,
        alertTypeName: value,
        alertTypeGroup: selectedGrp,
        alertTypeId: selectedInfo?.id,
      });
    } else if (name === "alertType") {
      // Make the API call when alertType changes
      try {
        if (value === "device") {
          const res = await fetchDevices(auth);
          if (res) {
            const optnData = res?.map((device) => ({
              id: device?.deviceInfo?.deviceId,
              name: device?.deviceInfo?.deviceName,
              group: device?.deviceInfo?.deviceCategory,
            }));
            setOptionData(optnData);
          }
        } else if (value === "product") {
          await fetchProducts();
        } else {
          setOptionData([]);
        }

        setFormData({
          ...formData,
          alertType: value,
          // selectedTypeName: "",
          // selectedTypeGrp: "",
          alertTypeName: "",
          alertTypeGroup: "",
          alertTypeId: "",
        });
      } catch (error) {
        console.error(error);
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }

    if (name === "alertGroupDesc" && value?.trim()?.length > 200) {
      setValidationErrors((prev) => ({
        ...prev,
        [name]: `You have exceeded the character limit of 200 by ${value?.trim()?.length - 200
          }`,
      }));
    } else if (name !== "alertGroupDesc" && value?.trim()?.length > 50) {
      setValidationErrors((prev) => ({
        ...prev,
        [name]: `You have exceeded the character limit of 50 by ${value?.trim()?.length - 50
          }`,
      }));
    } else if (name === "alertType" && value === "system") {
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        alertTypeName: "", // set the error empty for system as system is not mandatory
      }));
    } else {
      setValidationErrors({
        ...validationErrors,
        [name]: "",
      });
    }
  };

  const handleSubmit = async (status) => {
    const newValidationErrors = alertGroupValidation(
      formData,
      validationErrors,
      setValidationErrors,
      toast
    );
    // const newValidationErrors = {};
    // if (!formData?.alertGroup || !isValidAlertGrpName(formData?.alertGroup)) {
    //   if (!formData?.alertGroup) {
    //     newValidationErrors.alertGroup = "Please enter alert group name";
    //   } else if (!isValidAlertGrpName(formData?.alertGroup)) {
    //     newValidationErrors.alertGroup =
    //       "Please enter a valid alert group name";
    //   }
    // }
    // if (validationErrors?.alertGroup) {
    //   // set the error in fetchSearchSuggestions
    //   // check for similar alert group name exists or not
    //   newValidationErrors.alertGroup = "Alert group already exists";
    //   toast({
    //     // showing toast if alert group name exists
    //     title: "Alert group already exists",
    //     status: "error",
    //     duration: 4000,
    //     isClosable: true,
    //     position: "top",
    //   });
    // }

    // if (!formData?.alertType) {
    //   newValidationErrors.alertType = "Please select alert type";
    // }

    // if (!formData?.alertTypeName && formData?.alertType != "system") {
    //   newValidationErrors.alertTypeName = "Please select an option";
    // }
    // if (formData?.alertGroup?.trim()?.length > 50) {
    //   newValidationErrors.alertGroup = `You have exceeded the character limit of 50 by ${
    //     formData?.alertGroup.length - 50
    //   }`;
    // }
    // if (formData?.alertGroupDesc?.trim()?.length > 200) {
    //   newValidationErrors.alertGroupDesc = `You have exceeded the character limit of 200 by ${
    //     formData?.alertGroupDesc?.length - 200
    //   }`;
    // }
    // setValidationErrors(newValidationErrors);
    if (Object.keys(newValidationErrors).length > 0) {

      const requiredKeys = ["alertGroup", "alertType"];
      // If alertType is not "system," check for the "selectedTypeName" key
      if (formData.alertType == "product") {
        requiredKeys.push("alertTypeName");
      }
      // Check if any required field is empty
      const isAnyKeyEmpty = requiredKeys.some((key) => !formData[key]);

      if (isAnyKeyEmpty) {
        toast({
          title: "Incomplete Details",
          description: "Please fill the mandatory fields before submitting",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
        return;
      }

      console.log(newValidationErrors)

      return;
    }

    const requestBody = {
      alertGroupName: formData?.alertGroup,
      description: formData?.alertGroupDesc,
      status: status,
      alertType: formData?.alertType,
      alertTypeGroup: formData?.alertTypeGroup,
      alertTypeName: formData?.alertTypeName,
      alertTypeId: formData?.alertTypeId,
    };

    if (formData?.alertGroupId) {
      // add alert grp id after saving
      requestBody.alertGroupId = formData?.alertGroupId;
    }

    const json = JSON.stringify(requestBody);
    const blob = new Blob([json], {
      type: "application/json",
    });
    const FormData = require("form-data");
    let data = new FormData();
    data.append("data", blob);

    let config;
    if (update) {
      config = {
        method: "patch",
        maxBodyLength: Infinity,
        url: baseURL + "alert/v1/update/alertGroup",
        headers: {
          "X-Auth-Token": auth,
        },
        data: data,
      };
    } else {
      config = {
        method: "post",
        maxBodyLength: Infinity,
        url: baseURL + "alert/v1/addGroup",
        headers: {
          "X-Auth-Token": auth,
        },
        data: data,
      };
    }
    axios
      .request(config)
      .then((response) => {
        if (status === 1) {
          toast({
            title: "Alert group Added successfully",
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top",
          });

          setTimeout(() => {
            setShowAlertsTab((prev) => "alerts");
          }, 1500);
        } else {
          toast({
            title: "Alert group saved successfully",
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top",
          });

          setFormData({
            ...formData,
            alertGroupId: response?.data?.alertGroupId,
          });
        }
        setFieldsChanged((prev) => false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleConfirmDiscard = () => {
    setShowDraft((prev) => false);
    setShowAlertsTab((prev) => "alerts");
  };

  const fetchSearchSuggestions = async (term) => {
    if (term?.trim()) {
      const groups = alertGroups?.filter((item) => {
        const trimmedAlertGroupName = item?.alertGroupName.replace(/\s/g, ""); // Remove spaces from alertGroupName
        const trimmedTerm = term?.trim()?.replace(/\s/g, ""); // Remove spaces from the search term
        const includesCheck = item?.alertGroupName
          .toLowerCase()
          .includes(term?.trim()?.toLowerCase());

        let exactMatchCheck = false;
        if (selectedAlert?.alertGroupName) {
          exactMatchCheck =
            selectedAlert?.alertGroupName?.toLowerCase()?.replace(/\s/g, "") ===
              trimmedTerm?.toLowerCase()
              ? false
              : trimmedAlertGroupName.toLowerCase() ===
              trimmedTerm?.toLowerCase();
        } else {
          exactMatchCheck =
            trimmedAlertGroupName.toLowerCase() === trimmedTerm?.toLowerCase();
        }

        // Use exactMatchCheck for another purpose
        if (exactMatchCheck) {
          setValidationErrors({
            // setting alert group duplication error
            ...validationErrors,
            alertGroup: "Alert group already exists",
          });
        }

        return includesCheck;
      });
      setSearchSuggestions(groups);
    }
  };

  useEffect(() => {
    const delay = 300; // Adjust the delay as needed
    const timeoutId = setTimeout(() => {
      if (searchTerm) {
        fetchSearchSuggestions(searchTerm);
      } else {
        setSearchSuggestions([]);
      }
    }, delay);

    return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount or searchTerm change
  }, [searchTerm]);

  // useEffect(() => {
  //   getAlertGroupApi();
  // }, []);

  useEffect(() => {
    getAlertGroupApi();
    if (selectedAlert) {
      handleUpdate();
    } else {
      fetchProducts();
    }
  }, [selectedAlert]);

  return (
    <div className="font-roboto flex flex-col gap-2 mt-0">
      <div className="flex items-center">
        <div className="cursor-pointer w-8" onClick={handleBackButton}>
          <KeyboardBackspaceIcon sx={{ color: "#084298" }} />
        </div>
        <p className="text-[#084298] font-medium text-xl ml-2">
          {view
            ? "View Alert Group"
            : update
              ? "Update Alert Group"
              : "Add New Alert Group"}
        </p>
      </div>
      <div className={`flex flex-col gap-3 mt-6 ${view ? "pl-0" : "pl-6"}`}>
        {view ? (
          <div>
            <p className="text-[#48464C] text-md font-medium mb-3">
              Type of Alert
            </p>
            <div className="text-[#525056] text-sm font-medium mb-5">
              {selectedAlert?.alertType || ""}
            </div>
            <hr />
          </div>
        ) : (
          <div className="md:w-[370px]">
            <p className="text-[#3E3C42] text-[14px] font-medium mb-3">
              Type of Alert<span className="text-red-500 ml-1">*</span>
            </p>
            <Select
              name="alertType"
              value={formData?.alertType}
              onChange={handleChange}
              isDisabled={review}
            >
              {/* <option value="">Select type</option> */}
              <option value="product">Product</option>
              <option value="camera">Camera</option>
              <option value="system">System</option>
              <option value="Usecase">Usecase</option>
              <option value="Other">Other</option>
            </Select>
            {validationErrors?.alertType && (
              <p className="!mb-1 !text-[red] !text-sm">
                {validationErrors?.alertType}
              </p>
            )}
          </div>
        )}
        {view ? (
          <div>
            <p className="text-[#48464C] text-md font-medium">
              Alert Group Name
            </p>
            <div className="text-[#525056] text-sm font-medium mb-5">
              {selectedAlert?.alertGroupName || ""}
            </div>
            <hr />
          </div>
        ) : (
          <FormControl isRequired w={{ md: "370px" }}  font>
            <FormLabel fontSize={"14px"} fontWeight={500} color={"#3E3C42"}>
              Alert Group Name
            </FormLabel>
            <Input
              name="alertGroup"
              placeholder="Enter group name"
              value={formData?.alertGroup}
              onChange={(e) => {
                handleChange(e);
                setSearchTerm(e.target.value);
              }}
              borderColor={
                validationErrors?.alertGroup ? "red.500" : "gray.300"
              }
              borderWidth={validationErrors?.alertGroup ? "2px" : "2px"}
              disabled={review}
              border={review ? "none" : ""}
            />
            {validationErrors?.alertGroup && (
              <p className="!mb-1 !text-[red] !text-sm">
                {validationErrors?.alertGroup}
              </p>
            )}
            {searchSuggestions && searchSuggestions?.length > 0 && (
              <SearchSuggestions searchSuggestions={searchSuggestions} />
            )}
          </FormControl>
        )}

        {
          formData?.alertType == 'product' && (
              view ? (
                <>
                  <div className="flex  flex-col md:flex-row  gap-6 w-full ">
                    <div>
                      <p className="text-[#48464C] text-md font-medium mb-3 capitalize">
                        {formData?.alertType}
                      </p>
                      <div className="text-[#525056] text-sm font-medium ">
                        {selectedAlert?.alertTypeName || ""}
                      </div>
                    </div>
                    <div>
                      <p className="text-[#48464C] text-md font-medium mb-3 capitalize">
                        {formData?.alertType + " Group"}
                      </p>
                      <div className="text-[#525056] text-sm font-medium">
                        {selectedAlert?.alertTypeGroup || ""}
                      </div>
                    </div>
                  </div>
                  <hr />
                </>
              ) : (
                <div className="flex  flex-col md:flex-row  gap-6 ">
                  <div className="md:w-[370px]">
                    <p className="text-[#3E3C42] text-[14px] font-medium mb-3">
                      Select{" "}
                      {formData?.alertType === "product"
                        ? "Product"
                        : formData?.alertType === "device"
                          ? "Device"
                          : "System"}
                      {formData?.alertType !== "system" && (
                        <span className="text-red-500 ml-1">*</span>
                      )}
                    </p>
                    <Select
                      name="alertTypeName"
                      value={formData?.alertTypeName}
                      onChange={handleChange}
                      isDisabled={review}
                    >
                      <option value="">
                        {formData?.alertType === "product"
                          ? "Select Product"
                          : formData?.alertType === "device"
                            ? "Select Device"
                            : "Select System"}
                      </option>
                      {optionData &&
                        optionData?.length > 0 &&
                        optionData?.map((el) => (
                          <option key={el?.id} value={el?.name}>
                            {el?.name}
                          </option>
                        ))}
                    </Select>
                    {validationErrors?.alertTypeName && (
                      <p className="!mb-1 !text-[red] !text-sm">
                        {validationErrors?.alertTypeName}
                      </p>
                    )}
                  </div>
                  {formData?.alertTypeGroup && (
                    <div>
                      <FormControl w={{ md: "370px" }}>
                        <FormLabel
                          fontSize={"14px"}
                          fontWeight={500}
                          color={"#3E3C42"}
                          mb={"12px"}
                        >
                          {formData?.alertType === "product"
                            ? "Product"
                            : formData?.alertType === "device"
                              ? "Device"
                              : "System"}{" "}
                          Group
                        </FormLabel>
                        <Input
                          name="alertTypeGroup"
                          isReadOnly
                          value={formData?.alertTypeGroup}
                          onChange={handleChange}
                          placeholder=""
                          disabled={review}
                          border={"none"}
                          color={"#AEA9B1"}
                          p={0}
                        />
                      </FormControl>
                    </div>
                  )}
                </div>
              )
          )
        }


        {view ? (
          <div className="mb-10">
            <p className="text-[#48464C] text-md font-medium mb-3">
              Alert Group description
            </p>
            <div className="text-[#525056] text-sm font-medium">
              {selectedAlert?.description || ""}
            </div>
          </div>
        ) : (
          <div className="md:w-[370px]">
            <p className="text-[#3E3C42] text-[14px] font-medium mb-3">
              Alert Group description
            </p>
            <Textarea
              name="alertGroupDesc"
              value={formData?.alertGroupDesc}
              onChange={handleChange}
              placeholder="A brief description of the alert group"
              borderColor={
                validationErrors?.alertGroupDesc ? "red.500" : "gray.300"
              }
              borderWidth={validationErrors?.alertGroupDesc ? "2px" : "2px"}
              disabled={review}
              border={review ? "none" : ""}
            />
            {validationErrors?.alertGroupDesc && (
              <p className="!mb-1 !text-[red] !text-sm">
                {validationErrors?.alertGroupDesc}
              </p>
            )}
          </div>
        )}
        {!view && (
          <div className="flex gap-[10px] md:items-center  md:ml-0 mt-2 mb-20 md:mb-0">
            <div className="flex md:items-center gap-2 mb-5">
              {!review ? (
                <>
                  <PrimaryButton
                    text={"Save Draft"}
                    width={"130px"}
                    disable={!isFieldsChanged}
                    onClick={() => handleSubmit(0)}
                  />
                  <TonalButton
                    text={"Review"}
                    width={"130px"}
                    onClick={() => {
                      setReview(true);
                    }}
                  />
                </>
              ) : (
                <>
                  <TonalButton
                    text={"Back to Editing"}
                    width={"130px"}
                    onClick={() => {
                      setReview(false);
                    }}
                  />
                  <PrimaryButton
                    text={"Submit"}
                    width={"130px"}
                    onClick={() => handleSubmit(1)}
                    color="green"
                  />
                </>
              )}
            </div>
            {showDraft && (
              <DraftModal
                isOpen={showDraft}
                handleSubmit={handleSubmit}
                handleConfirmDiscard={handleConfirmDiscard}
                setShowDraft={setShowDraft}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default AddNewAlertGroup;
