import {
  Table,
  Td,
  Tr,
  Thead,
  Tbody,
  TableContainer,
  Th,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { aggregationLogic } from "../Tabs/Status";
import Paginator from "../../../util/VisionUtils/Paginator";

const ReportTableRows = ({ rowData, index, headers }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [expandedData, setExpandedData] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    let data = rowData?.data;
    aggregationLogic(data, setExpandedData, setColumns);
  }, [rowData]);

  return (
    <>
      <Tr color={"#3E3C42"} fontWeight={500} fontSize={"14px"}>
        {headers?.map((x) => {
          return x == "SR No." ? (
            <Td p={"8px"} textAlign={"center"}>
              {rowData["idx"]}
            </Td>
          ) : (
            <Td p={"8px"} textAlign={"center"}>
              {rowData[x]}
            </Td>
          );
        })}
        <Td p={"8px"}>
          <div className="flex w-full justify-start xl:justify-end">
            <img
              src={`/BlendComplianceIcons/${isExpanded ? "up" : "down"}.svg`}
              alt="no support"
              className="cursor-pointer"
              onClick={() => setIsExpanded((prev) => !prev)}
            />
          </div>
        </Td>
      </Tr>
      {isExpanded && (
        <Tr>
          <Td colSpan={"100%"} px={"12px"} py={"16px"}>
            <div className="flex flex-col gap-1 items-end">
              <Paginator
                data={expandedData}
                limit={5}
                setDisplayData={setDisplayData}
              />
              <TableContainer className="w-full" rounded={"8px"}>
                <Table variant="simple">
                  <Thead className="bg-[#FFFFED] !text-xs !sticky !top-0">
                    <Tr h={"44px"}>
                      {columns.map((id, idx) => {
                        return (
                          <Th
                            key={idx}
                            color="#79767D"
                            fontWeight={400}
                            p={"8px"}
                            textAlign={idx == 0 ? "left" : "center"}
                          >
                            {/^R\d+$/.test(id)
                              ? "Round " + id.slice(1)
                              : id.toUpperCase()}
                          </Th>
                        );
                      })}
                    </Tr>
                  </Thead>
                  <Tbody>
                    {displayData.map((item, index) => {
                      return (
                        <Tr
                          className="!bg-white"
                          color={"#3E3C42"}
                          fontWeight={600}
                          fontSize={"14px"}
                        >
                          {columns.map((val,idx) => {
                            return (
                              <Td p={'8px'} textAlign={idx == 0 ? "left" : "center"}>
                                {(val == "Bay" || val == "Section") &&
                                item["idx"] != expandedData.length ? (
                                  <div>
                                    {item[val].map((x) => {
                                      return (
                                        <p>
                                          {val == "Bay" ? "B" : "S"} {x}
                                        </p>
                                      );
                                    })}
                                  </div>
                                ) : (
                                  item[val]
                                )}
                              </Td>
                            );
                          })}
                        </Tr>
                      );
                    })}
                  </Tbody>
                </Table>
              </TableContainer>
            </div>
          </Td>
        </Tr>
      )}
    </>
  );
};

export default ReportTableRows;
