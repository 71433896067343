import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
// import NavContext from "../../NavContext";
import { IconButton, Select, Spinner, useToast } from "@chakra-ui/react";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { saveAs } from "file-saver";
import { DownloadForOfflineOutlined } from "@mui/icons-material";
import { getVideoDataApi } from "../services/sinterbed.api";
import NavContext from "../../NavContext";
import { SinterBedContext } from "../components/SinterBedContext";

const VideoInputForm = ({ setIsVideo }) => {
  const plants = ["chanderia"];
  const { auth } = useContext(NavContext);
  const { clientId } = useParams();
  const { initialData } = useContext(SinterBedContext);
  const [selectedPlant, setSelectedPlant] = useState("");
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [toTime, setToTime] = useState("12:00");
  const [fromTime, setFromTime] = useState("00:00");
  const toast = useToast();

  const handleSubmit = async () => {
    setIsVideo((prev) => "");
    try {
      const requestBody = JSON.stringify({
        plantName: selectedPlant,
        startDate: new Date(date + "T" + fromTime).getTime(),
        endDate: new Date(date + "T" + toTime).getTime(),
        clientId: clientId,
        useCase: "SINTERBED",
        cameraId: initialData?.cameraGpId,
      });
      const res = await getVideoDataApi(auth, requestBody, setLoading);
      if (res.success) {
        setIsVideo((prev) => res.url);
        toast({
          position: "top-right",
          title: "Video loaded",
          description: res.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        position: "top-right",
        title: "Error while generating video!",
        description: "Please try again",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsVideo((prev) => "");
    }
  };

  return (
    <div className="flex lg:flex-row flex-col justify-between items-center gap-8 w-full h-full">
      <Select
        borderColor="#CAC5CD"
        color="#605D64"
        variant="outline"
        // isDisabled={disable}
        className="!rounded-md !font-medium !text-sm text-[#605D64]"
        onChange={(e) => setSelectedPlant(e.target.value)}
        value={selectedPlant}
        textTransform={"capitalize"}
      >
        <option value="">Select Plant</option>
        {plants?.map((plant) => {
          return (
            <option
              key={plant}
              value={plant}
            >
              {plant}
            </option>
          );
        })}
      </Select>
      <FloatingInput
        text="Select Date"
        type="date"
        value={date}
        setDateTime={setDate}
        max={new Date().toISOString().slice(0, 10)}
      />
      <FloatingInput
        text="Start Time"
        type="time"
        setDateTime={setFromTime}
        value={fromTime}
      />
      <FloatingInput
        text="End Time"
        type="time"
        setDateTime={setToTime}
        value={toTime}
      />
      <div>
        <PrimaryButton
          onClick={handleSubmit}
          text={loading ? <Spinner /> : "Generate video"}
          height={"40px"}
          width={"160px"}
          disable={!selectedPlant || !date || fromTime === toTime || loading}
        />
      </div>
    </div>
  );
};

export const FeedLibrary = () => {
  const [isVideo, setIsVideo] = useState("");

  const handleDownloadVideo = async (url, idx) => {
    const response = await fetch(url);
    const videoData = await response.blob();
    saveAs(videoData, `sinterbed-video.mp4`);
  };

  return (
    <div className="relative flex flex-col lg:flex-col-reverse justify-between items-center gap-10 bg-white shadow-sm px-2 py-4 rounded-xl w-full">
      {!isVideo ? (
        <div className="flex flex-col items-center gap-6 h-full">
          <img
            src="/Common/VideoPlayer.svg"
            alt="No support"
          />
          <div className="flex flex-col items-center gap-2">
            <p className="font-medium text-[#6CA6FC] text-2xl">Video gallery</p>
            <p className="text-[#AEA9B1] text-base text-center">
              The Video gallery will create a continuous video frame of the
              captured pictures from your selected date and time range.
            </p>
          </div>
        </div>
      ) : (
        <div className="relative flex items-center w-full h-full">
          <video
            crossOrigin="anonymous"
            controls
            muted
            autoPlay
            className="rounded-lg w-[100%] h-[200px]"
          >
            <source
              src={isVideo}
              type="video/mp4"
            />
          </video>
          <div className="top-2 right-2 absolute flex gap-4">
            <IconButton
              size={"sm"}
              rounded={"full"}
              icon={<DownloadForOfflineOutlined className="text-black" />}
              onClick={() => handleDownloadVideo(isVideo, "sample")}
            />
          </div>
        </div>
      )}
      <VideoInputForm setIsVideo={setIsVideo} />
    </div>
  );
};
