import { useContext, useEffect, useRef, useState } from "react";
import {
  Radio,
  RadioGroup,
  Select,
  useToast,
  Textarea,
} from "@chakra-ui/react";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import TonalButton from "../../../util/Buttons/TonalButton";
import { baseURL } from "../../..";
import axios from "axios";
import NavContext from "../../NavContext";
import CommonProgressBar from "./CommonProgressBar";

const invert = {
  Yes: "No",
  No: "Yes",
};

const ModelUpload = ({
  userData,
  setUSerData,
  setActiveStep,
  activeStep,
  show,
  viewMode = false,
}) => {
  const fileInputRef = useRef();
  const [disable, setDisable] = useState(viewMode);
  const { auth } = useContext(NavContext);
  const [progressState, setProgressState] = useState({
    open: false,
    state: null,
  });
  const archOptions = ["YOLO"];
  const remarkRef = useRef();
  const toast = useToast();
  const handleChange = (name, value) => {
    setUSerData((prev) => {
      let newData = { ...prev };
      newData[name] = value;
      return newData;
    });
  };
  const handleSave = () => {
    if (userData?.modelType != "Bring your own") {
      setActiveStep(2);
      setDisable(true);
    } else {
      if (
        !userData?.pickleFile ||
        userData?.modelArch == "" ||
        remarkRef.current.value == ""
      ) {
        toast({
          title: "Error",
          description:
            "Please upload model ,select architecture and enter remarks",
          status: "error",
          position: "top-right",
          duration: 6000,
          isClosable: true,
        });
      } else {
        uploadModelApi();
      }
    }
  };

  const uploadModelApi = async () => {
    setProgressState((prev) => ({ ...prev, open: true, state: 1 }));
    const promise = new Promise(async (resolve, reject) => {
      try {
        const param = {
          projectId: userData?.projectId,
          modelArch: userData?.modelArch,
          remarks: remarkRef.current.value,
          fileSize: fileInputRef.current.files[0].size,
        };
        const formData = new FormData();
        formData.append("file", userData?.pickleFile);
        const response = await axios.post(
          baseURL + "selfserve/v1/project/v1/model/upload/",
          formData,
          {
            params: param,
            headers: {
              "Content-Type": "form-data",
              "X-Auth-Token": auth,
            },
          }
        );
        if (response.status == 200) {
          handleChange("uploadModelRemarks", remarkRef.current.value);
          setActiveStep(2);
          setDisable(true);
          resolve(200);
          setProgressState((prev) => ({ ...prev, state: 2 }));
        }
      } catch (error) {
        reject(error);
        setProgressState((prev) => ({ ...prev, state: 0 }));
        console.log(error);
      }
    });
  };

  useEffect(() => {
    if (activeStep == 50 && !viewMode) {
      setDisable(false);
      handleChange("pickleFile", null);
      handleChange("modelService", "RIPIK_VISION");
    }
  }, [activeStep]);

  return (
    <div
      className={`p-6 flex flex-col gap-8 rounded-lg bg-white transition-all ease-in duration-500 ${
        show ? "opacity-100" : "opacity-0"
      }`}
      id="step50"
    >
      <div className="flex gap-2 items-center">
        <p className="text-[#3E3C42] text-xl font-medium">Select model</p>
      </div>
      <RadioGroup
        onChange={(e) => handleChange("modelType", e)}
        value={userData?.modelType}
      >
        <div className="flex gap-1 items-center">
          {["Use existing", "Bring your own"].map((x) => {
            return (
              <div
                style={{
                  backgroundColor:
                    x == userData?.modelType ? "#DDEEFF80" : "#FFF",
                  borderRadius: "8px",
                }}
              >
                <Radio
                  value={x}
                  py={"8px"}
                  pl={"8px"}
                  pr={"12px"}
                  fontSize={"14px"}
                  fontWeight={500}
                  color={"#3E3C42"}
                  _checked={{
                    bg: "#6CA6FC",
                    borderColor: "#6CA6FC",
                  }}
                  _hover={{
                    borderColor: "#6CA6FC",
                  }}
                  isDisabled={disable}
                >
                  {x}
                </Radio>
              </div>
            );
          })}
        </div>
      </RadioGroup>
      {userData?.modelType == "Bring your own" && (
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-3">
            <p className="text-[#3E3C42] text-sm font-medium">
              Do you wish to annotate<span className="text-[#DC362E]">*</span>
            </p>
            <RadioGroup
              onChange={(e) => handleChange("isAnnotated", invert[e])}
              value={invert[userData.isAnnotated]}
            >
              <div className="flex gap-1 items-center">
                {["Yes", "No"].map((x) => {
                  return (
                    <div
                      style={{
                        backgroundColor:
                          x == invert[userData.isAnnotated]
                            ? "#DDEEFF80"
                            : "#FFF",
                        borderRadius: "8px",
                      }}
                    >
                      <Radio
                        value={x}
                        py={"8px"}
                        pl={"8px"}
                        pr={"12px"}
                        fontSize={"14px"}
                        fontWeight={500}
                        color={"#3E3C42"}
                        _checked={{
                          bg: "#6CA6FC",
                          borderColor: "#6CA6FC",
                        }}
                        _hover={{
                          borderColor: "#6CA6FC",
                        }}
                        isDisabled={disable}
                      >
                        {x}
                      </Radio>
                    </div>
                  );
                })}
              </div>
            </RadioGroup>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-[#3E3C42] text-sm font-medium">
              Select Model Architecture<span className="text-[#DC362E]">*</span>
            </p>
            <Select
              onChange={(e) => handleChange("modelArch", e.target.value)}
              value={userData?.modelArch}
              isDisabled={disable}
              width={"300px"}
            >
              <option key={"1"} value={""}>
                Select arch
              </option>
              {archOptions.map((item) => {
                return (
                  <option key={item} value={item}>
                    {item}
                  </option>
                );
              })}
            </Select>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-[#3E3C42] text-sm font-medium">Remarks<span className="text-[#DC362E]">*</span></p>
            <Textarea
              placeholder="Please enter remarks"
              width={"300px"}
              name="uploadModelRemarks"
              defaultValue={userData.uploadModelRemarks}
              ref={remarkRef}
              isDisabled={disable}
            />
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-sm text-[#3E3C42] font-medium">
              Upload your model pickle file<span className="text-[#DC362E]">*</span>
            </p>
            <input
              ref={fileInputRef}
              type="file"
              accept=".pkl, .pickle, .pt, .hdf5"
              style={{ display: "none" }}
              onChange={() => {
                handleChange("pickleFile", fileInputRef.current.files[0]);
                handleChange("modelService", "CUSTOM_UPLOAD");
              }}
            />
            <div className="flex gap-1 items-center">
              <SecondaryButton
                Icon={<img src="/selfServiceIcons/upload.svg" alt="upload" />}
                text={"Upload files"}
                width={"fit-content"}
                onClick={() => {
                  fileInputRef.current.click();
                }}
                disable={disable}
              />
              {userData?.pickleFile && (
                <p className="w-[200px] truncate">
                  {userData?.pickleFile?.name}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="flex items-center gap-2 mt-2">
        <PrimaryButton
          text={"Save"}
          width={"fit-content"}
          onClick={handleSave}
          disable={disable}
        />
        <TonalButton
          text={"Back"}
          width={"fit-content"}
          onClick={() => setActiveStep(1)}
          disable={disable}
        />
      </div>
      {progressState?.open && progressState?.state && (
        <CommonProgressBar
          openModal={progressState?.open}
          closeModal={() =>
            setProgressState((prev) => ({
              ...prev,
              open: false,
              state: null,
            }))
          }
          state={progressState?.state}
        />
      )}
    </div>
  );
};

export default ModelUpload;
