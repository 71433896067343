import React from "react";

function PlantCardNoData() {
  return (
    <div className="w-full h-[300px] justify-center items-center flex  bg-white rounded-md">
      <p className="text-xl text-[#024D87] font-semibold">No Data</p>
    </div>
  );
}

export default PlantCardNoData;
