import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material";
import AlertImagesModal from "../Components/AlertImagesModal";
import { useState } from "react";

const MuiTheme = createTheme();

const DetailClick = (param) => {
  console.log("121", param);
  const [openModal, setOpenModal] = useState(false);
  let { colDef, row } = param;
  let { field } = colDef;
  return (
    <>
      {row?.compliance == "Null" ? (
        <></>
      ) : (
        <>
          <p
            className="text-[#084298] font-medium text-sm cursor-pointer"
            onClick={() => setOpenModal(true)}
          >
            View images
          </p>
          {openModal && (
            <AlertImagesModal
              openModal={openModal}
              closeModal={() => setOpenModal(false)}
              row={row}
              checked={param.row.compliance}
            />
          )}
        </>
      )}
    </>
  );
};

const StatusCell = (param) => {
  const { row, colDef } = param;
  let { field } = colDef;
  let status = row[field];

  return (
    <p className={`${status == "Pass" ? "text-green-500" : "text-red-500"}`}>
      {status}
    </p>
  );
};

const columns = [
  {
    field: "id",
    headerName: "SR No.",
    headerClassName: "bg-[#ddeeff]",
    flex: 1,
  },
  {
    field: "event",
    headerName: "Event",
    headerClassName: "bg-[#ddeeff]",
    flex: 1,
  },
  {
    field: "subEvent",
    headerName: "Check",
    headerClassName: "bg-[#ddeeff]",
    flex: 1,
  },
  {
    field: "compliance",
    headerName: "Compliance",
    headerClassName: "bg-[#ddeeff]",
    flex: 1,
    renderCell: StatusCell,
  },
  {
    field: "image",
    headerName: "Image",
    headerClassName: "bg-[#ddeeff]",
    flex: 1,
    renderCell: DetailClick,
  },
];

const ReportModalTable = ({ rowData }) => {
  for (let i = 0; i < rowData.length; i++) {
    rowData[i]["id"] = i + 1;
  }

  const modRows = [];
  let id = 1;
  let data = rowData.events;
  for (const category in data) {
    for (const subEvent in data[category]) {
      data[category][subEvent].map((item) => {
        console.log("item", item);
        const compliance =
          item.passed == null ? "Null" : item.passed ? "Pass" : "Fail";
        const annotatedImage = item?.annotatedImage;
        const startTs = item?.startTs;
        const endTs = item?.endTs;
        const event = category;
        const entityId = rowData.entityId;
        const msg = item.msg;
        const complianceObject = {
          id,
          event,
          subEvent,
          compliance,
          annotatedImage,
          startTs,
          endTs,
          msg,
          entityId,
        };
        modRows.push(complianceObject);
        id++;
      });
    }
  }
  // modRows.push({
  //   id: 100,
  //   event: "flushing",
  //   subEvent: "executive",
  //   compliance: "Null",
  //   annotatedImage: null,
  //   startTs: null,
  //   endTs: null,
  //   entityId: null,
  // });

  const customSortingOrder = [
    "chock - wheelChock",
    "clamp - earthingClamp",
    "safety - helmet",
    "safety - harness",
    "safety - rope",
    "dipRod - executive",
    "dipRod - security",
    "dipRod - rodDip",
    "flushing - flush",
    "flushing - executive",
    "topSampling - sampleCollection",
    "topSampling - executive",
    "sideValveDrainage - drainage",
    "sideSampling - sampleCollection",
    "sampleInspection - inspection",
    "lidInspection - compartment",
  ];

  const sortedArray = modRows.sort((a, b) => {
    const eventA = customSortingOrder.indexOf(`${a.event} - ${a.subEvent}`);
    const eventB = customSortingOrder.indexOf(`${b.event} - ${b.subEvent}`);

    return eventA - eventB;
  });

  return (
    <div className="overflow-auto">
      <ThemeProvider theme={MuiTheme}>
        <DataGrid
          rows={sortedArray}
          columns={columns}
          disableColumnMenu={true}
          hideFooter={false}
          columnVisibilityModel={{
            id: false,
          }}
          initialState={{
            pagination: { paginationModel: { pageSize: 8 } },
          }}
          pageSizeOptions={[]}
          sx={{ maxHeight: "540px" }}
        />
      </ThemeProvider>
    </div>
  );
};

export default ReportModalTable;
