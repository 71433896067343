import { Radio, RadioGroup, Text } from "@chakra-ui/react";

const CompanyType = ({
  setCompanyType,
  setFieledsChange,
  companyType,
  review,
  dataOptions,
  submitClicked,
}) => {
  return (
    <div className="flex flex-col gap-3">
      <p className="text-[#3E3C42] text-lg font-medium">
        Company type<span className="text-[red] ml-1">*</span>
      </p>

      <RadioGroup
        onChange={(value) => {
          setCompanyType(value);
          setFieledsChange(true);
        }}
        value={companyType}
        isDisabled={review}
      >
        <div
          className="flex flex-col md:grid md:grid-cols-4 gap-1 md:gap-2"
          style={{ width: "fit-content" }}
        >
          {dataOptions.companyType.type.map((x) => {
            return (
              <div
                key={x}
                style={{
                  backgroundColor: companyType == x ? "#DDEEFF80" : "#FFF",
                  borderRadius: "8px",
                  mb: "12px",
                  // border
                }}
              >
                <Radio
                  value={x}
                  py={"8px"}
                  pl={"8px"}
                  pr={"12px"}
                  fontSize={"14px"}
                  fontWeight={500}
                  color={"#3E3C42"}
                  _checked={{
                    bg: "#6CA6FC",
                    borderColor: "#6CA6FC",
                  }}
                  _hover={{
                    borderColor: "#6CA6FC",
                  }}
                  borderColor={
                    submitClicked && !companyType ? "red.500" : "gray.300"
                  }
                  disabled={review}
                >
                  {x}
                </Radio>
              </div>
            );
          })}
        </div>
      </RadioGroup>
      {submitClicked && !companyType && (
        <Text fontSize="sm" mt="1" className="!text-[red]">
          Please select the company type
        </Text>
      )}
    </div>
  );
};

export default CompanyType;
