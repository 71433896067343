import { useEffect, useState } from "react";

import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import PhotoGallery from "../components/PhotoGallery";
import VideoGallery from "../components/VideoGallery";
import { mixpanelFeedLibTrack } from "../../../util/mixpanelFunctions";

const FeedLibrary = ({ plantId, cameraId, disable, plantCamMap }) => {
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (index) => {
    setTabIndex((prev) => index);
  };

  const tabs = [
    {
      label: "Photo gallery",
      index: 0,
      component: (
        <PhotoGallery
          plantId={disable ? plantId : Object.keys(plantCamMap)[0]}
          cameraId={
            disable ? cameraId : plantCamMap[Object.keys(plantCamMap)[0]][0]
          }
          disable={disable}
          plantCamMap={plantCamMap}
        />
      ),
    },

    {
      label: "Video gallery",
      index: 1,
      component: (
        <VideoGallery
          plantId={plantId}
          cameraId={cameraId}
          disable={disable}
          plantCamMap={plantCamMap}
        />
      ),
    },
  ];

  useEffect(() => {
    let tool = "Sinter Belt Monitoring";
    mixpanelFeedLibTrack(tabs[tabIndex].label.toLowerCase(), tool);
  }, [tabIndex]);
  return (
    <div className="flex flex-col gap-0 w-full">
      <Tabs index={tabIndex} onChange={handleTabChange}>
        <TabList display={"flex"} gap={0} width={"full"} border={0}>
          {tabs?.map((element, index) => (
            <Tab
              flex={1}
              padding={0}
              border={0}
              key={`${element.label}-${index}-tab`}
            >
              <div
                className={`flex items-center justify-center w-full p-2 cursor-pointer ${
                  tabIndex === element.index &&
                  "bg-white rounded-tr-xl rounded-tl-xl"
                }`}
              >
                <p
                  className={
                    tabIndex === element.index
                      ? "text-[#2660B6] text-base font-medium"
                      : "text-[#605D64] text-base"
                  }
                >
                  {element.label}
                </p>
              </div>
            </Tab>
          ))}
        </TabList>

        <TabPanels p={0}>
          {tabs?.map((element, index) => (
            <TabPanel key={`${element.label}-${index}-tabpanel`} padding={0}>
              {element?.component}
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default FeedLibrary;
