import { useEffect, useState, useContext, useRef } from "react";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import { dummyRecommendedBiasRecommenderData, dummyInsufficientData } from "../services/data";
import { generateDynamicColumns, generateGroupedColumns, generateReportsGroupedColumns, nearestNeighbourColumnGroupingModel } from "../utilis/dataGridColumns";
import { CustomFooter } from "../../../util/MaterialDataGrid/CustomStyledDatagrid";
import { Select, Spinner, TableContainer } from "@chakra-ui/react";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import { Switch } from '@chakra-ui/react'
import { ReportsTable } from "../components/ReportsTable";
import { getPastRunData, getProductionData, getHighMovingGradesData, getTargetTemperatureData, getRecommendedConfigDataApi, getActualConfigDataApi, getAnalysisData, downLoadData, getInsufficientData } from "../services/reportServices";
import NavContext from "../../NavContext";
import { reportsColumnGroupingModel } from "../utilis/dataGridColumns";
import {
  RadioButtonChecked,
  RadioButtonUncheckedRounded,
} from "@mui/icons-material";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
import Production from "../components/Reports/Production";
import HighMovingGrades from "../components/Reports/HighMovingGrades";
import TargetTemperature from "../components/Reports/TargetTemperature";
import ExlCsvDownload from "../../../util/VisionUtils/ExlCsvDownload";
import { Config } from "../components/Config";
import TemperatureBar from "../components/Reports/TemperatureBar";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { mixpanelTrackButton } from "../../../util/mixpanelFunctions";
import { getSimilarPlatesDataApi } from "../services/biasRecommenderServices";
import TableSkeleton from "../components/LoadingState/TableSkeleton";
import { BiasRecommenderTable } from "../components/BiasRecommenderTable";
import ExlcButton from "../utilis/ExlcButton";
import InsufficientData from "../components/Reports/InsufficientData";

export const Report = ({ plantId, disable, plantMap }) => {

  const { auth } = useContext(NavContext);

  const flexMap = {
    timeStamp: 1,
    grade: 0.8,
    width: 0.8,
    thickness: 1,
    length: 0.8,
    platesWithinRecommendedRange: 2,
    platesOutsideRecommendedRange: 2,
  };
  const minWidthMap = {
    timeStamp: 130,
    grade: 110,
    width: 110,
    thickness: 130,
    length: 110,
    platesWithinRecommendedRange: 230,
    platesOutsideRecommendedRange: 230,
  };
  const reportCardColorMap = {
    totalPlatesRun: "#FAFAFA",
    platesOutsideRecommendedRange: "#FCEEEE",
    platesWithinRecommendedRange: "#E6F6DF",
  };
  // state
  const [loading, setLoading] = useState(false);
  const [reportTableData, setReportTableData] = useState([]);
  const [initTableData, setInitTableData] = useState([]);
  const [productionData, setProductionData] = useState(null);
  const [highMovingGradesData, setHighMovingGradesData] = useState(null);
  const [targetTemperatureData, setTargetTemperatureData] = useState(null);
  const [filters, setFilters] = useState(["low", "pass", "high"]);
  const [columns, setColumns] = useState(null);
  const [selectedRange, setSelectedRange] = useState(3);
  const [actualConfigData, setActualConfigData] = useState(null);
  const [recommendedConfigData, setRecommendedConfigData] = useState(null);
  const [similarPlatesColumns, setSimilarPlatesColumns] = useState(null);
  const [insufficientData, setInsufficientData] = useState(null);
  const [similarPlatesData, setSimilarPlatesData] = useState(
    dummyRecommendedBiasRecommenderData
  );
  const [loadingConfig, setLoadingConfig] = useState({
    actualConfig: false,
    recommendedConfig: false,
  });
  const [isModalOpen, setIsModalOpen] = useState({ value: false });
  const [fromTime, setFromTime] = useState(
    new Date(new Date().getTime() - 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10)
  );
  const [toTime, setToTime] = useState(
    new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10)
  );
  const [rowSelectionModel, setRowSelectionModel] = useState([]);
  const [analysisData, setAnalysisData] = useState(null);

  const CustomRadioRenderCell = ({ value }) => {
    if (value) {
      return (
        <button className="w-full cursor-pointer">
          <RadioButtonChecked
            fontSize={"medium"}
            style={{
              color: "#6CA6FC",
            }}
          />
        </button>
      );
    } else {
      return (
        <button className="w-full cursor-pointer">
          <RadioButtonUncheckedRounded
            fontSize={"medium"}
            style={{
              color: "#6CA6FC",
            }}
          />
        </button>
      );
    }
  };

  const fetchProductionData = async (from = null, to = null) => {
    try {
      const response = await getProductionData(
        auth,
        {
          start_date: from ? from?.split("T")?.join("") : fromTime?.split("T")?.join(" "),
          end_date: to ? to?.split("T")?.join("") : toTime?.split("T")?.join(" ")
        },
      );

      if (response?.data) {
        setProductionData(response?.data);
      }

    } catch (error) {
      console.log(error);
    }
  }

  const fetchInsufficientData = async (from = null, to = null) => {

    try {
      const response = await getInsufficientData(
        auth,
        {
          start_date: from ? from?.split("T")?.join("") : fromTime?.split("T")?.join(" "),
          end_date: to ? to?.split("T")?.join("") : toTime?.split("T")?.join(" ")
        },
      );

      if (response?.data) {
        setInsufficientData(response?.data);
      }

    } catch (error) {
      console.log(error);
    }
  }

  const fetchHighMovingGradesData = async (from = null, to = null) => {
    try {
      const response = await getHighMovingGradesData(
        auth,
        {
          start_date: from ? from?.split("T")?.join("") : fromTime?.split("T")?.join(" "),
          end_date: to ? to?.split("T")?.join("") : toTime?.split("T")?.join(" ")
        },
      );

      if (response?.data) {
        setHighMovingGradesData(response?.data);
      }

    } catch (error) {
      console.log(error);
    }
  }

  const fetchTargetTemperatureData = async (from = null, to = null) => {
    try {
      const response = await getTargetTemperatureData(
        auth,
        {
          start_date: from ? from?.split("T")?.join("") : fromTime?.split("T")?.join(" "),
          end_date: to ? to?.split("T")?.join("") : toTime?.split("T")?.join(" ")
        },
      );

      if (response?.data) {
        setTargetTemperatureData(response?.data);
      }

    } catch (error) {
      console.log(error);
    }
  }



  const fetchPastRunData = async (from = null, to = null) => {
    try {
      const response = await getPastRunData(
        auth,
        {
          start_date: from ? from?.split("T")?.join("") : fromTime?.split("T")?.join(" "),
          end_date: to ? to?.split("T")?.join("") : toTime?.split("T")?.join(" ")
        },
      );

      if (response?.data) {
        const tempColumns = await generateReportsGroupedColumns(
          response?.data?.columns,
          true,
          true,
          false,
        );
        setColumns((prev) => [
          // checkbox to select box commented as not needed as of now
          {
            ...GRID_CHECKBOX_SELECTION_COL_DEF,
            renderHeader: (params) => null,
            renderCell: (params) => {
              const { value } = params;
              return <CustomRadioRenderCell value={value} />;
            },
          },
          ...tempColumns,
        ]);

        console.log(response.data);
        setInitTableData((prev) => response?.data);
        setReportTableData((prev) => response?.data);

        if (
          response?.data?.tableData?.length > 0 &&
          response?.data?.tableData[0]?.id
        ) {
          setRowSelectionModel((prev) => {
            return [response?.data?.tableData[0]?.id];
          });
          const requestData = {
            plate_id: response?.data?.tableData[0]?.primary_data_plate_id,
          };


          await fetchActualConfig(requestData);
          await fetchRecommendedConfig(requestData);
          await fetchAnalysisData(requestData);
          await fetchSimilarPlates(requestData);
          // await fetchSimilarPlates(requestData);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRecommendedConfig = async (requestData) => {
    try {
      const response = await getRecommendedConfigDataApi(
        auth,
        requestData,
        setLoadingConfig
      );
      if (response.success) {
        setRecommendedConfigData((prev) => response.data);
      } else {
        setRecommendedConfigData((prev) => null);
      }
    } catch (error) {
      console.log(error);
      setRecommendedConfigData((prev) => null);
    }
  };

  const fetchAnalysisData = async (requestData) => {
    try {
      const response = await getAnalysisData(
        auth,
        requestData,
        setLoadingConfig
      );
      if (response?.success) {
        setAnalysisData((prev) => response?.data);
      } else {
        setAnalysisData((prev) => null);
      }
    } catch (error) {
      console.log(error);
      setAnalysisData((prev) => null);
    }
  };

  const fetchActualConfig = async (requestData) => {
    try {
      const response = await getActualConfigDataApi(
        auth,
        requestData,
        setLoadingConfig
      );
      if (response.success) {
        setActualConfigData((prev) => response.data);
      } else {
        setActualConfigData((prev) => null);
      }
    } catch (error) {
      console.log(error);
      setActualConfigData((prev) => null);
    }
  };

  const handleViewRowDetails = async (params) => {
    try {
      const { field, id, row } = params;
      if (field === "__check__" && row?.primary_data_plate_id) {
        setRowSelectionModel((prev) => {
          return [id];
        });

        // console.log(row);
        const requestData = {
          plate_id: row?.primary_data_plate_id,
        };
        await fetchActualConfig(requestData);
        await fetchRecommendedConfig(requestData);
        await fetchAnalysisData(requestData);
        await fetchSimilarPlates(requestData)
        // await fetchSimilarPlates(requestData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRangeSelect = (e) => {
    setSelectedRange(e.target.value);
    if (e.target.value == 0) {
      setFromTime(
        new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 10)
      );
      setToTime(new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().slice(0, 10));
    }
    if (e.target.value == 1) {
      setFromTime(
        new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 10)
      );
      setToTime(new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().slice(0, 10));
    }
    else if (e.target.value == 3) {
      setFromTime(
        new Date(new Date().getTime() - 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000)
          .toISOString()
          .slice(0, 10)
      );
      setToTime(new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().slice(0, 10));
    }
  };

  const handleFilters = (e, key) => {
    if (e.target.checked) {
      setFilters((prev) => {
        let newArr = [...prev];
        newArr.push(key);
        return newArr;
      }
      )
    }
    else {
      const idx = filters?.indexOf(key);
      if (idx > -1) { // only splice array when item is found
        setFilters((prev) => {
          let newArr = [...prev];
          newArr.splice(idx, 1);
          return newArr;
        }
        ) // 2nd parameter means remove one item only
      }
    }
  }

  const fetchData = async (flag = false) => {
    setLoading(true);
    await fetchProductionData();
    await fetchHighMovingGradesData();
    await fetchTargetTemperatureData();
    await fetchPastRunData();
    await fetchInsufficientData();
    setLoading(false);

    if (flag) {
      let from = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000)
        .toISOString()
        .slice(0, 10);
      let to = new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000).toISOString().slice(0, 10);
      setLoading(true);
      handleRangeSelect({
        target: {
          value: 0
        }
      });
      await fetchProductionData(from, to);
      await fetchHighMovingGradesData(from, to);
      await fetchTargetTemperatureData(from, to);
      await fetchPastRunData(from, to);
      await fetchInsufficientData(from, to);
      setLoading(false);
    }

  }

  const modalRef = useRef();
  modalRef.current = isModalOpen;

  const handleDownload = async () => {

    // setIsModalOpen((prev) => ({ ...prev, value: true }));
    let w = document.getElementById("#graphs").offsetWidth;
    let h = 2500;
    // if (columns) {
    //   let count = reportTableData?.tableData?.length;
    //   h = (count * 50);
    // }
    if (h <= 2500) h = 2500;
    let doc = new jsPDF('p', 'px', [w, h]);
    let width = doc.internal.pageSize.getWidth();
    let height = doc.internal.pageSize.getHeight();


    let elem = document.getElementById("#graphs");
    let divWidth = document.getElementById("#graphs").offsetWidth;
    let divHeight = document.getElementById("#graphs").offsetHeight;
    let ratio = divHeight / divWidth;
    let canvas = await html2canvas(elem);
    let url = canvas.toDataURL("image/png");
    doc.addImage(url, 0, 0, width - 10, (ratio * width) - 10);
    // doc.addPage();
    // console.log(modalRef.current)
    // let x = height;
    // if (columns) {
    //   elem = document.getElementById("#reportsTableHidden");
    //   canvas = await html2canvas(elem,
    //     {
    //       onclone: function (clonedDoc) {
    //         clonedDoc.getElementById('#reportsTableHidden').style.display = 'block';
    //         divWidth = clonedDoc.getElementById("#reportsTableHidden").offsetWidth;
    //         divHeight = clonedDoc.getElementById("#reportsTableHidden").offsetHeight;
    //         ratio = divHeight / divWidth;
    //       },
    //     },
    //   );

    //   url = canvas.toDataURL();
    //   doc.addImage(url, 0, 0, width - 10, divHeight);
    //   doc.addPage();
    // }

    // if (reportTableData?.tableData) {
    //   for (let x of reportTableData?.tableData) {

    //     if (!modalRef?.current?.value) {
    //       console.log(modalRef?.current?.value, "khafshslhlHello");
    //       return;
    //     }

    //     const requestData = {
    //       plate_id: x?.primary_data_plate_id,
    //     };
    //     await fetchActualConfig(requestData);
    //     await fetchAnalysisData(requestData);
    //     await fetchRecommendedConfig(requestData);
    //     await fetchSimilarPlates(requestData);

    //     await delay(3000);

    //     elem = document.getElementById("#report");
    //     canvas = await html2canvas(elem);
    //     divWidth = document.getElementById("#report").offsetWidth;
    //     divHeight = document.getElementById("#report").offsetHeight;
    //     ratio = divHeight / divWidth;
    //     url = canvas.toDataURL();
    //     doc.addImage(url, 0, 0, width - 10, (ratio * width) - 10);
    //     doc.addPage();

    //   }
    // }

    // if (!modalRef?.current?.value) {
    //   console.log(modalRef?.current?.value, "khafshslhlHello");
    //   return;
    // }

    let startDate = new Date(fromTime)?.toLocaleDateString();
    let endDate = new Date(toTime)?.toLocaleDateString();

    doc.save(`Mulpic_report_${startDate}_to_${endDate}.pdf`);
    // setIsModalOpen((prev) => ({ ...prev, value: false }));
  }

  useEffect(() => {
    setReportTableData((prev) => {
      let updated = initTableData?.tableData?.filter((item) => filters?.includes(item?.measured_data_FCT_QC));
      return { columns: prev?.columns, tableData: updated };
    })
  }, [filters]);

  const fetchSimilarPlates = async (requestData) => {
    try {
      const response = await getSimilarPlatesDataApi(
        auth,
        requestData,
        setLoading
      );
      if (response?.success && response?.data) {
        // to generate incoming columns only on the first render
        // if (firstTimeLoaded?.similarPlates === false) {
        const tempColumns = Object.keys(response?.data[0]);
        const tempSimilarPlatesColumns = await generateDynamicColumns(
          tempColumns,
          true,
          true,
          false
        );
        // adding bias recommender specific column
        setSimilarPlatesColumns((prev) => [...tempSimilarPlatesColumns]);
        // }
        setSimilarPlatesData((prev) => response?.data);
      } else {
        setSimilarPlatesData((prev) => null);
      }
    } catch (error) {
      console.log(error);
      setSimilarPlatesData((prev) => null);
    }
  };

  console.log(fromTime, toTime, "reportTableData")
  console.log(reportTableData, "reportTableData");

  useEffect(() => {
    fetchData(true);
  }, []);

  const delay = (delayInms) => {
    return new Promise(resolve => setTimeout(resolve, delayInms));
  };

  const handleClickDownloadExcel = async (isTrue = false) => {
    let startDate = new Date(fromTime)?.toLocaleDateString();
    let endDate = new Date(toTime)?.toLocaleDateString();

    setIsModalOpen((prev) => ({ ...prev, value: true }));
    try {
      await downLoadData(
        startDate,
        endDate,
        auth,
        {
          start_date: fromTime?.split("T")?.join(" "),
          end_date: toTime?.split("T")?.join(" ")
        },
      );
    } catch (error) {
      console.log(error);
    }
    if (isTrue) handleDownload();
    // window.print();
    setIsModalOpen((prev) => ({ ...prev, value: false }));
  }

  const getOrder = (columns) => {

    console.log(columns, "columns");
    let newArray = [];

    columns?.forEach((column) => {
      newArray.push(column?.value ? column?.value : column?.field);
    })

    return newArray;
  }

  return (
    <div className="relative flex flex-col gap-4 h-full">
      <div className="flex flex-row gap-3 pt-2 overflow-x-auto justify-between overflow-y-hidden">
        <div className="flex flex-row gap-3">
          <div className="min-w-[110px]">
            <Select
              size='sm'
              borderColor="#CAC5CD"
              color="#605D64"
              value={selectedRange}
              variant="outline"
              className="!rounded-md !text-xs !font-medium !text-[#6b6475]"
              isDisabled={loading}
              onChange={(e) => handleRangeSelect(e)}
            >
              <option key="Last 7 days" value={0}>
                Last 7 days
              </option>
              <option key="Last 1 month" value={1}>
                Last 1 month
              </option>
              <option key="Last 24 hours" value={3}>
                Last 24 hours
              </option>
              <option key="custom" value={2}>
                Custom
              </option>

            </Select>
          </div>
          {selectedRange == 2 && (
            <div className="min-w-[110px]">
              <FloatingInput
                size="sm"
                borderColor="#CAC5CD"
                text="from"
                type="date"
                setDateTime={setFromTime}
                value={fromTime}
                textColor="#6b6475"
                textSize="14px"
              />
            </div>
          )}
          {selectedRange == 2 && (
            <div className="min-w-[110px]">
              <FloatingInput
                size="sm"
                borderColor="#CAC5CD"
                text="to"
                type="date"
                setDateTime={setToTime}
                value={toTime}
                textColor="#6b6475"
                textSize="14px"
              />
            </div>
          )}
          <SecondaryButton
            height={"32px"}
            width={"80px"}
            text={loading ? <Spinner /> : "Apply"}
            disable={loading}
            onClick={() => {
              fetchData()
              mixpanelTrackButton("Report", "Mulpic", "Apply") // Track button
            }}
          />
        </div>

        <button
          className={"flex items-center gap-1 justify-center text-[#FFF] bg-[#89b7fc] text-sm rounded hover:bg-[#447ED4] hover:shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] focus:bg-[#447ED4] focus:outline-none focus:shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] active:bg-[#447ED4]"
          }
          style={{
            width: "80px",
            height: "32px",
            padding: "2px 12px 2px 12px",
          }}
          onClick={() => handleClickDownloadExcel(true)}
          disabled={disable}
        >
          Download
        </button>
        {/* <SecondaryButton
          height={"32px"}
          width={"80px"}
          text={"Download"}
          disable={loading}
          onClick={() => handleClickDownloadExcel()}
        // onClick={() => fetchData()}
        /> */}
      </div>
      <div className="flex flex-col gap-4 h-full" id="#graphs">
        <Production data={productionData} />
        <HighMovingGrades data={highMovingGradesData} />
        <TargetTemperature data={targetTemperatureData} />
        <InsufficientData data={insufficientData} />
      </div>

      <div className="flex flex-col gap-4 py-[8px] bg-white rounded-xl justify-start pb-20 " >
        <p className="rounded-e-xl rounded-s-xl px-3 text-xl font-medium">
          Rolled plates
        </p>
        <div className="flex flex-row justify-between px-0 md:px-2">
          <div className="flex overflow-x-auto py-[2px] px-1 items-center justify-between w-full">

            <div className="flex flex-row gap-5 items-center">
              <div className="flex flex-row gap-1 items-center">
                <p>In Range</p>
                <Switch isChecked={filters?.includes("pass")} onChange={(e) => handleFilters(e, "pass")} />
              </div>
              <div className="flex flex-row gap-1 items-center">
                <p>Low</p>
                <Switch isChecked={filters?.includes("low")} onChange={(e) => handleFilters(e, "low")} />
              </div>
              <div className="flex flex-row gap-1 items-center">
                <p>High</p>
                <Switch isChecked={filters?.includes("high")} onChange={(e) => handleFilters(e, "high")} />
              </div>
            </div>
            <button
              className={"flex items-center gap-1 justify-center text-[#FFF] bg-[#89b7fc] text-sm rounded hover:bg-[#447ED4] hover:shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] focus:bg-[#447ED4] focus:outline-none focus:shadow-[0px_4px_4px_0px_rgba(0,0,0,0.25)] active:bg-[#447ED4]"
              }
              style={{
                width: "80px",
                height: "32px",
                padding: "2px 12px 2px 12px",
              }}
              onClick={() => handleClickDownloadExcel()}
              disabled={disable}
            >
              Download
            </button>
          </div>
        </div>
        {columns && (
          <div className="w-full px-3 gap-2" id="#reportsTable">

            <ReportsTable
              columns={columns || []}
              rowData={reportTableData?.tableData || []}
              rowSelectionModel={rowSelectionModel}
              handleCellClick={handleViewRowDetails}
              hideFooter={false}
              pageSizeOptions={[10, 30, 50]}
              pagination={true}
              initialState={{
                pagination: { paginationModel: { pageSize: 10, page: 0 } },
              }}
              slots={{
                footer: CustomFooter
              }}
              columnGroupingModel={reportsColumnGroupingModel}
            />
          </div>
        )}

        {
          columns &&
          <div className="w-full px-3 overflow-x-scroll hidden" id="#reportsTableHidden">
            <TableContainer whiteSpace={'normal'} border={'1px'} fontSize={'10px'}>
              <Table __css={{ 'table-layout': 'fixed', width: 'full' }} variant="simple" size={'sm'} >
                <Thead className="">
                  <Tr className="h-[40px]">
                    <Th >TimeStamp</Th>
                    <Th colSpan={7}>Primary Data</Th>
                    <Th colSpan={6}>Compliance</Th>
                  </Tr>
                  <Tr className="h-[40px]">
                    <Th>TOC</Th>
                    <Th>Plate Id</Th>
                    <Th>Steel Grade</Th>
                    <Th>Thickness</Th>
                    <Th>Width</Th>
                    <Th>Length</Th>
                    <Th>FCT QC</Th>
                    <Th>FCT Error</Th>
                    <Th>Speed Curve Bias</Th>
                    <Th>WF Adaptors</Th>
                    <Th>Flow Related Bias</Th>
                    <Th>Edge Masking</Th>
                    <Th>Head Masking</Th>
                    <Th>Tail Masking</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {
                    reportTableData?.tableData?.map((data) => {
                      return (
                        <Tr className="h-[40px]">
                          {
                            Object.keys(data).filter((key) => (key != "insf_data" && key != "id"))?.map((key) => {
                              return (
                                <Td>
                                  {
                                    key == "TOC" ? (`${new Date(data[key] * 1000).toLocaleString('en-US')}`) :
                                      typeof data[key] == 'boolean' ? (data[key] ? "true" : "false") : (
                                        typeof data[key] == 'number' ? data[key]?.toFixed(2) : data[key]
                                      )
                                  }
                                </Td>
                              )
                            })
                          }
                        </Tr>
                      )
                    })
                  }
                </Tbody>
              </Table>
            </TableContainer>

          </div>
        }

        <div id="#report" className="w-full">
          <p className="font-semibold m-2">Primary data</p>

          <div className="flex flex-wrap gap-2 border border-[#e0e0e0] mx-3 rounded-sm" >
            {
              analysisData?.opt1 && Object.keys(analysisData?.opt1)?.map((item) => <div className="flex flex-row gap-2 items-center p-2 text-sm w-auto">
                <p>{item}</p>
                <div className="rounded-md bg-[#F5F5F5] p-1 px-4 text-center text-sm w-auto shadow-inner">
                  <p className="text-black slate-900" style={{
                    textShadow: "1px 1px #fff"
                  }}>{analysisData?.opt1[item]}</p>
                </div>

              </div>)
            }
          </div>

          <p className="font-semibold m-2">Temperature Bars</p>

          <div className="flex flex-wrap gap-4 px-3">
            {analysisData &&
              Object.keys(analysisData?.temperatureBars?.sec1)?.map((item) => <div className="w-[250px] p-2 rounded-sm flex flex-col justify-between gap-4 border">
                <p>{item}</p>
                <div className="h-[72px]">
                  <TemperatureBar overallRange={analysisData?.temperatureBars?.sec1[item]?.overall} optimalRange={analysisData?.temperatureBars?.sec1[item]?.optimal} currentValue={analysisData?.temperatureBars?.sec1[item]?.current} tempValue={analysisData?.temperatureBars?.sec1[item]?.temp} />
                </div>

              </div>)
            }
          </div>

          <div className=" flex flex-wrap gap-4 px-3 mt-4">
            {
              analysisData &&
              Object.keys(analysisData?.temperatureBars?.sec2)?.map((item) => <div className="justify-between w-[250px] p-2 rounded-sm flex flex-col gap-4 border">
                <p>{item}</p>
                <div className="h-[72px]">
                  <TemperatureBar overallRange={analysisData?.temperatureBars?.sec2[item]?.overall} optimalRange={analysisData?.temperatureBars?.sec2[item]?.optimal} currentValue={analysisData?.temperatureBars?.sec2[item]?.current} tempValue={analysisData?.temperatureBars?.sec2[item]?.temp} />
                </div>
              </div>)
            }
          </div>

          <p className="font-semibold m-2">Additional Parameters</p>

          <div className="flex flex-wrap gap-2 border border-[#e0e0e0] mx-3 rounded-sm" >
            {
              analysisData?.opt2 && Object.keys(analysisData?.opt2)?.map((item) => <div className="flex flex-row gap-2 items-center p-2 text-sm w-auto">
                <p>{item}</p>
                <div className="rounded-md bg-[#F5F5F5] p-1 px-4 text-center text-sm w-auto shadow-inner">
                  <p className="text-black slate-900" style={{
                    textShadow: "1px 1px #fff"
                  }}>{analysisData?.opt2[item]}</p>
                </div>
              </div>)
            }
          </div>

          <div>
            <Config
              currentConfigData={actualConfigData}
              recommendedConfigData={recommendedConfigData}
              currentConfigLoading={loadingConfig.actualConfig}
              recommendedConfigLoading={loadingConfig.recommendedConfig}
              currentConfigText={"Actual Config"}
              recommendedConfigText={"Recommended Config"}
            />
          </div>

          <div className="w-full rounded-xl bg-white">
            {/* Historical Data for a particular row */}

            <div className="flex flex-row w-full justify-between px-2 py-2">

              {similarPlatesData && similarPlatesColumns && (
                <p className="rounded-e-xl rounded-s-xl px-2 text-xl font-medium">
                  Nearest Neighbour
                </p>
              )}
              {similarPlatesData && similarPlatesColumns && <ExlcButton order={getOrder(similarPlatesColumns)} data={similarPlatesData} name={"Nearest_Neighbour"} />}

            </div>

            <div className="max-h-[60vh] w-full px-2">
              {similarPlatesData &&
                similarPlatesColumns && nearestNeighbourColumnGroupingModel && (
                  <BiasRecommenderTable
                    columns={similarPlatesColumns || []}
                    rowData={similarPlatesData || []}
                    columnGroupingModel={nearestNeighbourColumnGroupingModel}
                  />
                )}
            </div>
          </div>
        </div>


      </div>

      {isModalOpen?.value &&
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen((prev) => ({ ...prev, value: false }))} size='xs'>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton className=" w-1 h-1" />
            <ModalBody m={'auto'} fontSize={'15px'}>
              <div className="flex flex-col items-center text-lg font-semibold justify-center w-full mt-4">
                <p>Processing Download ...</p>
                {/* <p>ETA: 10 mins</p> */}
                <p className="text-sm font-normal">Please do not close this tab</p>
              </div>
            </ModalBody>
            <ModalFooter></ModalFooter>
          </ModalContent>
        </Modal>
      }

    </div>
  );
};
