import React from "react";
import {
  FormControl,
  ThemeProvider,
  createTheme,
  TextField,
  Select,
  OutlinedInput,
  Checkbox,
  ListItemText,
  MenuItem,
  Badge,
  InputLabel,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";

export const CustomMaterialSingleSelect = ({
  rows,
  selectedBoard,
  setSelectedBoard,
}) => {
  const MuiTheme = createTheme();

  return (
    <ThemeProvider theme={MuiTheme}>
      <FormControl
        fullWidth
        size={"small"}
      >
        <Autocomplete
          freeSolo
          forcePopupIcon
          size={"small"}
          value={selectedBoard || null}
          onChange={(event, newValue) => {
            setSelectedBoard(newValue);
          }}
          inputValue={selectedBoard || ""}
          onInputChange={(event, newInputValue) => {
            setSelectedBoard(newInputValue);
          }}
          options={rows.map((item) => item.board)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Select Board"
            />
          )}
          ListboxProps={{ style: { maxHeight: "200px" } }}
        />
      </FormControl>
    </ThemeProvider>
  );
};

export const CustomMaterialMultipleSelect = ({
  value,
  handleChange,
  selectedItems,
  options,
  disable = false,
  label = "Select",
}) => {
  const MuiTheme = createTheme();
  return (
    <ThemeProvider theme={MuiTheme}>
      <Badge
        badgeContent={selectedItems && selectedItems?.length}
        color="primary"
        sx={{ width: "100%" }}
      >
        <FormControl fullWidth>
          {label && (
            <InputLabel
              id="multiple-checkbox-label"
              size={"small"}
              sx={{ fontSize: "14px" }}
            >
              {label}
            </InputLabel>
          )}
          <Select
            value={value}
            onChange={handleChange}
            multiple={true}
            input={
              <OutlinedInput
                size={"small"}
                {...(label && {
                  label: label,
                })}
              />
            }
            renderValue={(selected) => selected.join(", ")}
            size={"small"}
            sx={{ color: "#605D64", fontSize: "14px", height: "40px" }}
          >
            {options.map((option) => (
              <MenuItem
                key={option}
                value={option}
                sx={{
                  height: "40px",
                  fontSize: "14px",
                  paddingX: "10px",
                  gap: "4px",
                }}
              >
                <Checkbox
                  size={"small"}
                  checked={selectedItems?.indexOf(option) > -1}
                  sx={{ padding: 0 }}
                />
                <ListItemText
                  primary={option}
                  primaryTypographyProps={{
                    sx: { fontSize: "14px", textTransform: "capitalize" },
                  }}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Badge>
    </ThemeProvider>
  );
};
