import { useState } from "react";
import ReactCardFlip from "react-card-flip";
import DisplayImage from "../utils/DisplayImage";
import { CalMax } from "../Home/HomeComponents/ExpandModal";

const PredictionCard = ({ data, time }) => {
  const [flip, setFlip] = useState(
    data.hasOwnProperty("annotationData") ? true : false
  );
  return (
    <ReactCardFlip isFlipped={flip} flipDirection="horizontal">
      <div
        className="w-full h-[250px] flex justify-center items-center bg-black rounded relative cursor-pointer"
        onClick={() => setFlip((prev) => !prev)}
      >
        <img
          src={data.img}
          alt="inputImage"
          className="w-auto max-h-full rounded"
          loading="lazy"
        />
        <p className="absolute text-white bottom-2 right-2 bg-black bg-opacity-60">
          {new Date(time)?.toLocaleString("en-GB")}
        </p>
      </div>
      <div
        className="w-full h-[250px] flex justify-center items-center bg-black rounded relative cursor-pointer"
        onClick={() => setFlip((prev) => !prev)}
      >
        {data.hasOwnProperty("annotationData") ? (
          <>
            <DisplayImage
              width={250}
              height={250}
              url={data.img}
              annotationData={data.annotationData}
            />
            <p className="absolute bottom-2 right-2 bg-black p-1 rounded-lg bg-opacity-50 text-white">
              Confidence:{" "}
              <span className="text-green-400">
                {Math.round(CalMax(data.annotationData) * 100)}%
              </span>{" "}
              <br />
              Captured: {new Date(time)?.toLocaleString("en-GB")}
            </p>
          </>
        ) : (
          <div className="flex flex-col gap-2 text-base">
            <p className="text-white">
              Prediction: <span className="font-bold">{data.tag_name}</span>
            </p>
            <p className="text-white">
              Confidence:{" "}
              <span className="text-green-400">
                {Math.round(data.precision * 100)}%
              </span>
            </p>
          </div>
        )}
      </div>
    </ReactCardFlip>
  );
};

export default PredictionCard;
