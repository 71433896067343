import React, {
  useState,
  useRef,
  useEffect,
  useContext,
  useLayoutEffect,
} from "react";
import { Link, useNavigate } from "react-router-dom";

import NavContext from "../../NavContext";
import AddIcon from '@mui/icons-material/Add';

import { Button, Spinner } from "@chakra-ui/react";
import axios from "axios";
import { useWindowSize } from "@uidotdev/usehooks";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import ExpertReadMore from "../../Main/ExpertReadMore";
import { baseURL } from "../../..";
import TonalButton from "../../../util/Buttons/TonalButton";
import ExpertUpdateModal from "./ExpertUpdateModal";
import AddNewExpertModal from "./AddNewExpertModal";
import { Tooltip } from "@chakra-ui/react";
import ExpertCard from "./components/ExpertCard";

const ExpertsTab = ({ setShowExpertsTab }) => {
  const { auth } = useContext(NavContext);

  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState(false);
  const [isOpenAddExpertModal, setIsOpenAddExpertModal] = useState(false);
  const [isOpenViewExpertModal, setIsOpenViewExpertModal] = useState(false);

  const [expert, setExpert] = useState(null);
  const [loading, setLoader] = useState(false);

  const [expertDetails, setExpertDetails] = useState([]);

  const [expertId, setExpertId] = useState(""); // expert id
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getData = async () => {
    setLoader(true);
    try {
      const response = await axios.get(baseURL + "experts/v1/fetch/experts", {
        headers: {
          "Content-Type": "application/json",
          "X-auth-Token": auth,
        },
      });

      //setting order for downloading data
      setExpertDetails(response?.data);

      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };

  const truncateStyle = {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    maxWidth: "200px", // Adjust the max-width as needed
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="mt-8">
      <div className="w-full bg-white rounded-md mb-5 ">
        <div className="bg-[#fafafa]  p-4 w-[100%] overflow-y-auto sm:h-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 min-[1440px]:grid-cols-5 min-[1750px]:grid-cols-5 min-[2150px]:grid-cols-7 gap-3 text-base font-medium text-[#3E3C42]">
          <div
            className="rounded p-3 w-full flex flex-col justify-center gap-3 items-center bg-white h-[250px]"
            style={{
              boxShadow:
                "-4px -4px 24px 0px rgba(0, 0, 0, 0.07), 4px 4px 24px 0px rgba(0, 0, 0, 0.07)",
            }}
          >
            <div
              className="p-[10px] bg-[#DEF] text-center rounded cursor-pointer hover:scale-105"
              onClick={() => {
                setIsOpenAddExpertModal(true);
              }}
            >
              <AddIcon sx={{color: "#3A74CA"}} />
            </div>

            <p className="font-bold">Add new expert</p>
          </div>
          {expertDetails &&
            expertDetails.length != 0 &&
            expertDetails?.map((items, index) => {
              return <ExpertCard key={index} data={items} getData={getData} />;
            })}
        </div>
      </div>

      {/* {expert && isOpen && (
        <ExpertReadMore
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          expertDetails={expert}
        />
      )} */}
      {isOpenAddExpertModal && (
        <AddNewExpertModal
          isOpen={isOpenAddExpertModal}
          onClose={() => {
            setIsOpenAddExpertModal(false);
            getData();
          }}
          mode={"add"}
          //getData={getData}
        />
      )}
      {/* {expert && isOpenUpdateModal && (
        <AddNewExpertModal
          isOpen={isOpenUpdateModal}
          onClose={() => {
            setIsOpenUpdateModal(false);
            getData();
          }}
          data={expert}
          mode={"update"}
          //getData={getData}
        />
      )}
      {expert && isOpenViewExpertModal && (
        <AddNewExpertModal
          isOpen={isOpenViewExpertModal}
          onClose={() => {
            setIsOpenViewExpertModal(false);
          }}
          data={expert}
          mode={"view"}
          //getData={getData}
        />
      )} */}
    </div>
  );
};

export default ExpertsTab;
