import { Td, CircularProgress, CircularProgressLabel } from "@chakra-ui/react";
import StatusTooltip from "../Components/StatusTooltip";
import { useEffect, useState } from "react";
import StatusEdit from "../Components/StatusEdit";

const TableCell = ({
  data,
  val,
  item,
  index,
  percentage,
  diff,
  fixColumns,
  colIdx,
}) => {
  const [hover, setHover] = useState(false);
  const [edit, setEdit] = useState(false);

  const handleClick = () => {
    if (/^R\d+$/.test(val)) {
      setEdit(true);
      setHover(false);
    }
  };
  return /^R\d+$/.test(val) && index != data.length - 1 ? (
    <Td
      p={"8px"}
      textAlign={"center"}
      position={"relative"}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handleClick}
      cursor={"pointer"}
    >
      <CircularProgress
        value={percentage}
        color={
          diff <= -2.5 || diff == 2.5 || item["200 MT"] == 0
            ? "#FF7F7F"
            : "#6CA6FC"
        }
      >
        <CircularProgressLabel color="gray">
          {percentage < 100
            ? // <div className="flex justify-center items-center">
              //   <img src="/BlendComplianceIcons/info.svg" alt="no support" />
              // </div>
              item[val]
            : "+ " + Math.abs(diff).toFixed(2)}
        </CircularProgressLabel>
      </CircularProgress>
      {hover && (
        <StatusTooltip
          bay={[1, Object.keys(item.baySectionMap)?.join("-")]}
          section={[1, Object.values(item.baySectionMap).join(",")]}
          data={{
            concentrate: item.Concentrate,
            current: item[val],
            lastUpdated: item[`T${val.slice(1)}`]
              ? new Date(item[`T${val.slice(1)}`] * 1000).toLocaleString()
              : "-",
          }}
        />
      )}
      {edit && (
        <StatusEdit
          openModal={edit}
          closeModal={() => setEdit(false)}
          data={{
            value: item[val],
            round: val,
            required: item["200 MT"],
            smelter: item?.smelter,
            batchId: item?.batchId,
            concentrate: item?.Concentrate,
          }}
        />
      )}
    </Td>
  ) : (
    <Td
      p={"12px"}
      textAlign={colIdx == 0 ? "left" : "center"}
      position={Object.keys(fixColumns).includes(val) ? "sticky" : ""}
      left={Object.keys(fixColumns).includes(val) ? fixColumns[val] : ""}
      zIndex={Object.keys(fixColumns).includes(val) ? "docked" : ""}
      bg={Object.keys(fixColumns).includes(val) ? "#fff" : ""}
    >
      {(val == "Bay" || val == "Section") && index != data.length - 1 ? (
        <div>
          {item[val].map((x) => {
            return (
              <p>
                {val == "Bay" ? "B" : "S"} {x}
              </p>
            );
          })}
        </div>
      ) : val == "Blend Ratio" ? (
        item[val] + " %"
      ) : val == "200 MT" ? (
        item[val] + " MT"
      ) : (
        item[val]
      )}
    </Td>
  );
};

export default TableCell;
