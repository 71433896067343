import StarIcon from "@mui/icons-material/Star";
import { useEffect, useState } from "react";
import ExpandModal from "./ExpandModal";

const CalConfidenceParams = (data, type) => {
  let precisionValues,
    minPrecision,
    maxPrecision,
    sortedPrecisionValues,
    midIndex,
    medianPrecision;
  switch (type) {
    case "Classify":
      precisionValues = data?.img?.map((obj) => obj.precision);
      minPrecision = Math.min(...precisionValues);
      maxPrecision = Math.max(...precisionValues);
      sortedPrecisionValues = precisionValues.sort((a, b) => a - b);
      midIndex = Math.floor(sortedPrecisionValues.length / 2);
      medianPrecision =
        sortedPrecisionValues.length % 2 === 0
          ? (sortedPrecisionValues[midIndex - 1] +
              sortedPrecisionValues[midIndex]) /
            2
          : sortedPrecisionValues[midIndex];
      return {
        min: (minPrecision * 100)?.toFixed(1),
        max: (maxPrecision * 100)?.toFixed(1),
        median: (medianPrecision * 100)?.toFixed(1),
      };
    case "Detect":
    case "Segment":
      precisionValues = data?.img?.reduce((acc, currentObject) => {
        let arr = currentObject?.annotationData?.map((obj) => obj.precision);
        return acc.concat(arr);
      }, []);
      minPrecision = Math.min(...precisionValues);
      maxPrecision = Math.max(...precisionValues);
      sortedPrecisionValues = precisionValues.sort((a, b) => a - b);
      midIndex = Math.floor(sortedPrecisionValues.length / 2);
      medianPrecision =
        sortedPrecisionValues.length % 2 === 0
          ? (sortedPrecisionValues[midIndex - 1] +
              sortedPrecisionValues[midIndex]) /
            2
          : sortedPrecisionValues[midIndex];
      console.log(
        "here",
        precisionValues,
        minPrecision,
        maxPrecision,
        medianPrecision,
        "values"
      );
      return {
        min: (minPrecision * 100)?.toFixed(1),
        max: (maxPrecision * 100)?.toFixed(1),
        median: (medianPrecision * 100)?.toFixed(1),
      };
    default:
      return "Whoops";
  }
};

const LabelCard = ({ label, predictionData, userData, starred }) => {
  const [confidence, setConfidence] = useState({
    min: 0,
    max: 0,
    median: 0,
  });
  const [expand, setExpand] = useState(false);
  useEffect(() => {
    if (predictionData[label]?.img?.length > 0) {
      setConfidence((prev) => {
        return CalConfidenceParams(
          predictionData[label],
          userData?.annotationType
        );
      });
    }
  }, [label, predictionData]);

  return (
    <div
      className="py-5 px-4 flex flex-col gap-4 items-center rounded-xl"
      style={{
        boxShadow:
          "-4px -4px 24px 0px rgba(0, 0, 0, 0.07), 4px 4px 24px 0px rgba(0, 0, 0, 0.07)",
      }}
    >
      <div className="flex justify-between w-full items-start">
        <div className="flex gap-[6px] flex-col">
          <div className="flex gap-2 items-center">
            {starred && <StarIcon sx={{ color: "#FFC107" }} />}
            <p className="text-[#3E3C42] font-medium text-base">{label}</p>
          </div>
          <p className="text-[#79767D] text-sm">
            Total :{" "}
            <span className="text-[#3E3C42]">
              {predictionData[label]?.img?.length} Files
            </span>
          </p>
        </div>
        <img
          src="/selfServiceIcons/expand.svg"
          alt="expand"
          className="cursor-pointer hover:scale-105"
          onClick={() => setExpand(true)}
        />
      </div>
      <div className="flex flex-col gap-9 w-full">
        <div className="flex flex-col gap-3 w-full">
          <div className="flex justify-between w-full">
            <p className="flex gap-1 items-center text-[#525056] text-sm">
              <img
                src={
                  userData?.modelService?.includes("CUSTOM")
                    ? "/selfServiceIcons/azure.svg"
                    : "/logo192.png"
                }
                alt="model"
                className="w-5 h-5"
              />
              Minimum confidence
            </p>
            <p className="text-[#93B472] text-lg font-semibold">
              {confidence.min}%
            </p>
          </div>
          <div className="flex justify-between w-full">
            <p className="flex gap-1 items-center text-[#525056] text-sm">
              <img
                src={
                  userData?.modelService?.includes("CUSTOM")
                    ? "/selfServiceIcons/azure.svg"
                    : "/logo192.png"
                }
                alt="model"
                className="w-5 h-5"
              />
              Maximum confidence
            </p>
            <p className="text-[#93B472] text-lg font-semibold">
              {confidence.max}%
            </p>
          </div>
          <div className="flex justify-between w-full">
            <p className="flex gap-1 items-center text-[#525056] text-sm">
              <img
                src={
                  userData?.modelService?.includes("CUSTOM")
                    ? "/selfServiceIcons/azure.svg"
                    : "/logo192.png"
                }
                alt="model"
                className="w-5 h-5"
              />
              Median confidence
            </p>
            <p className="text-[#93B472] text-lg font-semibold">
              {confidence.median}%
            </p>
          </div>
        </div>
        <div className="flex items-center justify-between">
          {predictionData[label]?.img?.slice(0, 5).map((item, idx) => {
            let len = predictionData[label]?.img?.slice(0, 5)?.length;
            return (
              <div className="flex justify-center items-center h-[48px] w-[48px] bg-black rounded relative">
                <img src={item?.img} alt="img" className="max-h-full w-auto" />
                {idx == len - 1 && (
                  <p className="absolute flex w-full h-full justify-center rounded text-center items-center text-[#FAFAFA] text-sm font-medium bg-black bg-opacity-60">
                    + {predictionData[label]?.img?.length - len}
                  </p>
                )}
              </div>
            );
          })}
        </div>
      </div>
      {expand && (
        <ExpandModal
          starred={starred}
          userData={userData}
          predictionData={predictionData}
          label={label}
          isOpen={expand}
          closeModal={() => setExpand(false)}
        />
      )}
    </div>
  );
};

export default LabelCard;
