import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";

import AddClients from "./AddClients/AddClients";
import ManageProducts from "./Tabs/ManageProducts";
import CreateClient from "./AddClients/CreateClient";
import ViewClient from "./AddClients/ViewClient";
import ViewTabs from "./Tabs/ViewTabs";
import UpdateTabs from "./Tabs/UpdateTabs";
import AddNewProductCategory from "./Products/AddNewProductCategory";
import UserMgmt from "../Admin/Tabs/UserMgmt";
import AddNewProduct from "./Products/AddNewProduct";
import ManageDevices from "./Tabs/ManageDevices";
import ExpertsTab from "./Experts/ExpertsTab";
import DevelopmentInProgress from "./Deploy/DevelopmentInProgress";
import ViewProductGroup from "./Products/ViewProductGroup";
import UpdateProductGroup from "./Products/UpdateProductGroup";
import ViewProduct from "./Products/ViewProduct";
import UpdateProduct from "./Products/UpdateProduct";
import ProductsPage from "./Tabs/ProductsPage";
import AddNewDevice from "./Devices/AddNewDevice";
import AddNewDeviceGroup from "./Devices/AddNewDeviceGroup";
import DevicesPage from "./Tabs/DevicesPage";
import AlertsPage from "./Tabs/AlertsPage";
import NavContext from "../NavContext";
import AddNewAlertGroup from "./Alerts/AddNewAlertGroup";
import AddNewAlert from "./Alerts/AddNewAlert";

const SuperAdminDashboard = () => {
  const { twoFactorAuthoInfo } = useContext(NavContext);

  const [isDeployClicked, setDeployClicked] = useState(false);
  const [plantCamMap, setPlantCamMap] = useState({});
  const [showClientTab, setShowClientTab] = useState("addclient");
  const [showProductsTab, setShowProductTab] = useState("products");
  const [showDevicesTab, setShowDevicesTab] = useState("devices");
  const [showExpertsTab, setShowExpertsTab] = useState("experts");
  const [showAlertsTab, setShowAlertsTab] = useState("alerts");
  const [showModelsTab, setShowModelsTab] = useState("models");
  const [view, setView] = useState("products");
  const [format, setFormat] = useState("card");

  const [viewDevice, setViewDevice] = useState("card");
  const [deviceType, setDeviceType] = useState("Devices");
  const [selectedDevice, setSelectedDevice] = useState({});
  const [selectedDeviceGroup, setSelectedDeviceGroup] = useState({});
  const [clientView, setClientView] = useState("card");

  const [selectedClient, setSelectedClient] = useState({});
  const [selectedProductGroup, setSelectedProductGroup] = useState({});
  const [page, setPage] = useState("clients");

  const [viewAlert, setViewAlert] = useState("card");
  const [alertType, setAlertType] = useState("Alerts");
  const [selectedAlert, setSelectedAlert] = useState({});
  const [searchParams, setSearchParams] = useSearchParams();
  const tabIndex = searchParams.get("tabIndex")
    ? parseInt(searchParams.get("tabIndex"), 10)
    : 0;

  const tabsMapping = {
    0: "clients",
    1: "products",
    2: "experts",
    3: "devices",
    4: "alerts",
  };
  const clientsTabMapping = {
    createclient: <CreateClient setShowClientTab={setShowClientTab} />,
    addclient: (
      <AddClients
        setShowClientTab={setShowClientTab}
        setSelectedClient={setSelectedClient}
        clientView={clientView}
        setClientView={setClientView}
      />
    ),
    viewclient: (
      <ViewTabs
        setShowClientTab={setShowClientTab}
        selectedClient={selectedClient}
      />
    ),
    updateClient: (
      <UpdateTabs
        setShowClientTab={setShowClientTab}
        selectedClient={selectedClient}
      />
    ),
  };
  const productsTabMapping = {
    // <ManageProducts
    //     setSelectedProductGroup={setSelectedProductGroup}
    //     setShowProductTab={setShowProductTab}
    //   />
    products: (
      <ManageProducts
        view={view}
        setView={setView}
        format={format}
        setFormat={setFormat}
        setSelectedProductGroup={setSelectedProductGroup}
        setShowProductTab={setShowProductTab}
      />
    ),
    addNewProductCategory: (
      <AddNewProductCategory setShowProductTab={setShowProductTab} />
    ),
    addNewProduct: <AddNewProduct setShowProductTab={setShowProductTab} />,
    viewProductGroup: (
      <ViewProductGroup
        selectedProductGroup={selectedProductGroup}
        setShowProductTab={setShowProductTab}
      />
    ),
    updateProductGroup: (
      <UpdateProductGroup
        selectedProductGroup={selectedProductGroup}
        setShowProductTab={setShowProductTab}
      />
    ),
    viewProduct: (
      <ViewProduct
        selectedProductGroup={selectedProductGroup}
        setShowProductTab={setShowProductTab}
      />
    ),
    updateProduct: (
      <UpdateProduct
        selectedProductGroup={selectedProductGroup}
        setShowProductTab={setShowProductTab}
      />
    ),
  };
  const devicesTabMapping = {
    devices: (
      <DevicesPage
        setShowDevicesTab={setShowDevicesTab}
        view={viewDevice}
        setDeviceType={setDeviceType}
        deviceType={deviceType}
        setView={setViewDevice}
        setSelectedDevice={setSelectedDevice}
      />
    ),
    addNewDevice: <AddNewDevice setShowDevicesTab={setShowDevicesTab} />,
    viewDevice: (
      <AddNewDevice
        setShowDevicesTab={setShowDevicesTab}
        selectedDevice={selectedDevice}
        view={true}
      />
    ),
    updateDevice: (
      <AddNewDevice
        setShowDevicesTab={setShowDevicesTab}
        selectedDevice={selectedDevice}
        update={true}
      />
    ),
    addNewDeviceGroup: (
      <AddNewDeviceGroup setShowDevicesTab={setShowDevicesTab} />
    ),
    viewDeviceGroup: (
      <AddNewDeviceGroup
        setShowDevicesTab={setShowDevicesTab}
        selectedDevice={selectedDevice}
        view={true}
      />
    ),
    updateDeviceGroup: (
      <AddNewDeviceGroup
        setShowDevicesTab={setShowDevicesTab}
        selectedDevice={selectedDevice}
        update={true}
      />
    ),
  };

  const expertsTabMapping = {
    experts: <ExpertsTab setShowExpertsTab={setShowExpertsTab} />,
  };

  const alertsTabMapping = {
    alerts: (
      <AlertsPage
        setShowAlertsTab={setShowAlertsTab}
        view={viewAlert}
        setAlertType={setAlertType}
        alertType={alertType}
        setView={setViewAlert}
        setSelectedAlert={setSelectedAlert}
      />
    ),
    addNewAlertGroup: <AddNewAlertGroup setShowAlertsTab={setShowAlertsTab} />,
    updateAlertGroup: (
      <AddNewAlertGroup
        setShowAlertsTab={setShowAlertsTab}
        selectedAlert={selectedAlert}
        update={true}
      />
    ),
    viewAlertGroup: (
      <AddNewAlertGroup
        setShowAlertsTab={setShowAlertsTab}
        selectedAlert={selectedAlert}
        view={true}
      />
    ),

    addNewAlert: <AddNewAlert setShowAlertsTab={setShowAlertsTab} />,
    viewAlert: (
      <AddNewAlert
        setShowAlertsTab={setShowAlertsTab}
        selectedAlert={selectedAlert}
        view={true}
      />
    ),
    updateAlert: (
      <AddNewAlert
        setShowAlertsTab={setShowAlertsTab}
        selectedAlert={selectedAlert}
        update={true}
      />
    ),
  };


  const modelsTabMapping = {
    models: (
      <DevelopmentInProgress
        setShowModelsTab={setShowModelsTab}
        setShowDevicesTab={setShowDevicesTab}
        isModelsPage={true}
      />
    ),
  };

  const handleTabIndexChange = (index) => {
    const tabName = tabsMapping[index];
    setSearchParams({ tabIndex: index });
    setPage(tabName);
  };
  useEffect(() => {
    handleTabIndexChange(tabIndex);
  }, [tabIndex]);

  return (
    <>
      <div
        className="pl-0 mt-[3vh]  font-roboto flex flex-col rounded-lg"
        //   style={{ width: size.width >= 768 ? "calc(100vw - 168px)" : "100vw" }}
      >
        {twoFactorAuthoInfo?.is2FAuthenticatedSuperAdmin && ( // rendering the tabs based on 2FA
          <Tabs
            className="bg-white rounded-md px-6 border-2 pt-4"
            width={"full"}
            isLazy={true}
            index={tabIndex}
            onChange={handleTabIndexChange}
          >
            <TabList
              className="!flex !border-0 !justify-between !items-center"
              width={"full"}
            >
              <div className="flex items-center gap-4 overflow-x-auto h-14 md:h-10">
                <Tab
                  className={
                    page === "clients"
                      ? "!text-[#605D64] !text-xs sm:!text-sm !bg-[#6CABFC] !bg-opacity-20 rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#6CA6FC]"
                      : "!text-xs sm:!text-sm !text-[#605D64] !border !border-[#EBEBEB] !rounded-full"
                  }
                  onClick={() => setPage("clients")}
                >
                  Clients
                </Tab>
                <Tab
                  className={
                    page === "products"
                      ? "!text-[#605D64] !text-xs sm:!text-sm !bg-[#6CABFC] !bg-opacity-20 rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#6CA6FC]"
                      : "!text-xs sm:!text-sm !text-[#605D64] !border !border-[#EBEBEB] !rounded-full"
                  }
                  onClick={() => setPage("products")}
                >
                  Products
                </Tab>
                <Tab
                  className={
                    page === "experts"
                      ? "!text-[#605D64] !text-xs sm:!text-sm !bg-[#6CABFC] !bg-opacity-20 rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#6CA6FC]"
                      : "!text-xs sm:!text-sm !text-[#605D64] !border !border-[#EBEBEB] !rounded-full"
                  }
                  onClick={() => setPage("experts")}
                >
                  Experts
                </Tab>
                <Tab
                  className={
                    page === "devices"
                      ? "!text-[#605D64] !text-xs sm:!text-sm !bg-[#6CABFC] !bg-opacity-20 rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#6CA6FC]"
                      : "!text-xs sm:!text-sm !text-[#605D64] !border !border-[#EBEBEB] !rounded-full"
                  }
                  onClick={() => setPage("devices")}
                >
                  Devices
                </Tab>
                <Tab
                  className={
                    page === "alerts"
                      ? "!text-[#605D64] !text-xs sm:!text-sm !bg-[#6CABFC] !bg-opacity-20 rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#6CA6FC]"
                      : "!text-xs sm:!text-sm !text-[#605D64] !border !border-[#EBEBEB] !rounded-full"
                  }
                  onClick={() => setPage("alerts")}
                >
                  Alerts
                </Tab>

                {/* <Tab
                className={
                  page === "models"
                    ? "!text-[#605D64] !text-xs sm:!text-sm !bg-[#6CABFC] !bg-opacity-20 rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#6CA6FC]"
                    : "!text-xs sm:!text-sm !text-[#605D64] !border !border-[#EBEBEB] !rounded-full"
                }
                onClick={() => setPage("models")}
              >
                Models
              </Tab> */}
              </div>
            </TabList>
            <div className="flex">
              <TabPanels className="!pt-4">
                <TabPanel className="!pl-0 !pr-0">
                  {clientsTabMapping[showClientTab] ? (
                    clientsTabMapping[showClientTab]
                  ) : (
                    <>No such Tab Exist</>
                  )}
                </TabPanel>
                <TabPanel className="!pl-0 !pr-0">
                  {productsTabMapping[showProductsTab] ? (
                    productsTabMapping[showProductsTab]
                  ) : (
                    <>No such Tab Exist</>
                  )}
                </TabPanel>

                <TabPanel className="!pl-0 !pr-0">
                  {expertsTabMapping[showExpertsTab] ? (
                    expertsTabMapping[showExpertsTab]
                  ) : (
                    <>No such Tab Exist</>
                  )}
                </TabPanel>
                <TabPanel className="!pl-0 !pr-0">
                  {devicesTabMapping[showDevicesTab] ? (
                    devicesTabMapping[showDevicesTab]
                  ) : (
                    <>No such Tab Exist</>
                  )}
                </TabPanel>
                <TabPanel className="!pl-0 !pr-0">
                  {alertsTabMapping[showAlertsTab] ? (
                    alertsTabMapping[showAlertsTab]
                  ) : (
                    <>No such Tab Exist</>
                  )}
                </TabPanel>
                {/* <TabPanel className="!pl-0 !pr-0">
                {modelsTabMapping[showModelsTab] ? (
                  modelsTabMapping[showModelsTab]
                ) : (
                  <>No such Tab Exist</>
                )}
              </TabPanel> */}
              </TabPanels>
            </div>
          </Tabs>
        )}
      </div>
    </>
  );
};

export default SuperAdminDashboard;
