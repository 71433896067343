function OverallDimensions({ overallDimentions, stateForStandard }) {
  return (
    <div className="flex flex-col gap-2">
      <div className="w-full h-full overflow-x-auto ">
        <div className="gap-1 grid grid-cols-12 min-w-[1150px] ">
          {/* header */}
          <div className="flex flex-col  gap-3 text-start    h-full  col-span-2">
            <p className="text-[#000] text-base">Overall dimensions</p>
            <p className="text-[#79767D] text-sm">
              A summary of the dimensions
            </p>
          </div>
          {/* dimentions */}
          <div className=" col-span-3">
            <table className="w-full h-full">
              <thead className="bg-[#DDEEFF] h-[5vh]  text-sm font-semibold p-2 text-left  ">
                <tr>
                  <th className="p-2">Dimension</th>{" "}
                  <th className="p-2">Actual</th>{" "}
                  <th className="p-2">Standard</th>
                </tr>
              </thead>
              <tbody className="text-[#79767D] text-sm  text-left ">
                <tr>
                  <td className="px-2">Length</td>{" "}
                  <td className="font-medium text-black px-2">
                    {" "}
                    {overallDimentions.dimentions.actual.length == null
                      ? "..."
                      : overallDimentions.dimentions.actual.length + " mm"}
                  </td>{" "}
                  <td className=" px-2">
                    {" "}
                    {overallDimentions.standard_length == null
                      ? "..."
                      : overallDimentions.standard_length + " mm"}
                  </td>
                </tr>
                <tr>
                  <td className="px-2">Breadth</td>{" "}
                  <td className="font-medium text-black px-2">
                    {" "}
                    {overallDimentions.dimentions.actual.breadth == null
                      ? "..."
                      : overallDimentions.dimentions.actual.breadth + " mm"}
                  </td>{" "}
                  <td className="px-2">
                    {" "}
                    {overallDimentions.standard_breadth == null
                      ? "..."
                      : overallDimentions.standard_breadth + " mm"}
                  </td>
                </tr>

                <tr>
                  <td className="px-2">Depth</td>{" "}
                  <td className="font-semibold text-black  px-2">
                    {" "}
                    {overallDimentions.dimentions.actual.depth == null
                      ? "..."
                      : overallDimentions.dimentions.actual.depth + " mm"}
                  </td>{" "}
                  <td className=" px-2">
                    {" "}
                    {overallDimentions.standard_depth == null
                      ? "..."
                      : overallDimentions.standard_depth + " mm"}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <div className="grid grid-cols-3 grid-rows-4 gap-1 shadow-md      h-full">
            <div className="bg-[#FFFFED]   text-[14px] text-[#4F4E4E] font-semibold p-2 text-left  flex justify-start items-center">
              Dimensions
            </div>
            <div className="bg-[#FFFFED]   text-[14px] text-[#4F4E4E] font-semibold  p-2 text-left  flex justify-start items-center">
              Actual
            </div>
            <div className="bg-[#FFFFED] text-[14px] text-[#4F4E4E] font-semibold p-2 text-left  flex justify-start items-center">
              Standard
            </div>

            <div className="text-[#929292] text-[14px] p-2 text-left  flex justify-start items-center">
              Length
            </div>
            <div className=" text-[14px]  font-semibold   p-2 text-left  flex justify-start items-center">
              {overallDimentions.dimentions.actual.length == null
                ? "..."
                : overallDimentions.dimentions.actual.length}
            </div>
            <div className="text-[#929292] text-[14px] p-2 text-left  flex justify-start items-center">
              {overallDimentions.standard_length == null
                ? "..."
                : overallDimentions.standard_length}
            </div>

            <div className="text-[#929292] text-[14px] p-2 text-left  flex justify-start items-center">
              Breadth
            </div>
            <div className="p-2 text-[14px] font-semibold flex justify-start items-center">
              {overallDimentions.dimentions.actual.breadth == null
                ? "..."
                : overallDimentions.dimentions.actual.breadth}
            </div>
            <div className="text-[#929292] text-[14px] p-2 text-left  flex justify-start items-center">
              {overallDimentions.standard_breadth == null
                ? "..."
                : overallDimentions.standard_breadth}
            </div>

            <div className="text-[#929292] text-[14px] p-2 text-left  flex justify-start items-center">
              Depth
            </div>
            <div className="p-2 text-[14px] font-semibold flex justify-start items-center">
              {overallDimentions.dimentions.actual.depth == null
                ? "..."
                : overallDimentions.dimentions.actual.depth}
            </div>
            <div className=" text-[#929292] text-[14px] p-2 text-left  flex justify-start items-center">
              {overallDimentions.standard_depth == null
                ? "..."
                : overallDimentions.standard_depth}
            </div>
          </div> */}

          {/* diagonal dimentions */}
          <div className="shadow-md  h-full col-span-3">
            <div className="bg-[#DDEEFF]  p-2  text-sm font-semibold  text-left  flex justify-start items-center">
              Diagonal dimensions
            </div>
            <div className="w-full h-[17vh]">
              <div className="flex gap-2 w-full h-[50%]">
                <p
                  className="text-[#79767D]  text-sm p-2 w-full text-left  flex justify-start items-center "
                  style={{ whiteSpace: "nowrap" }}
                >
                  Left side diagonal -
                  <span className=" text-sm text-black font-medium p-2   w-full text-left flex justify-start items-center">
                    {overallDimentions.diagonal_dimentions.leftSide == null
                      ? "..."
                      : overallDimentions.diagonal_dimentions.leftSide + " mm"}
                  </span>
                </p>
              </div>
              <div className="flex gap-2 w-full h-[50%]">
                <p
                  className="text-[#79767D] text-[14px] p-2 w-full text-left flex justify-start items-center"
                  style={{ whiteSpace: "nowrap" }}
                >
                  Right side diagonal -
                  <span className=" text-[14px] text-black font-medium  p-2  w-full text-left flex justify-start items-center">
                    {overallDimentions.diagonal_dimentions.rightSide == null
                      ? "..."
                      : overallDimentions.diagonal_dimentions.rightSide + " mm"}
                  </span>
                </p>
              </div>
            </div>
          </div>

          {/* edge counter */}
          <div className="shadow-md  h-full col-span-2 ">
            <div className="bg-[#DDEEFF]  p-2  text-sm font-semibold  text-left  flex justify-start items-center">
              Edge contours
            </div>
            <div className="relative  h-[17vh]">
              {Object.entries(overallDimentions?.edge_contours).length > 0 &&
                Object.entries(overallDimentions?.edge_contours).map(
                  ([key, value], index) => (
                    <div
                      key={index}
                      className={`text-[14px] font-medium p-1  border-dashed border-gray-300 w-[80px] ${
                        index === 0
                          ? "rounded-tl-[25%] border-t-2 border-l-2 absolute top-2 left-2"
                          : index === 1
                          ? "rounded-tr-[25%] border-t-2 border-r-2 text-right absolute top-2 right-2"
                          : index === 2
                          ? "rounded-bl-[25%] border-b-2 border-l-2 absolute bottom-2 left-2"
                          : "rounded-br-[25%] border-b-2 border-r-2 text-right absolute bottom-2 right-2"
                      }`}
                    >
                      {value == null ? "" : value}
                    </div>
                  )
                )}
            </div>
          </div>

          {/* Surface area */}

          <div className="shadow-md h-full col-span-2">
            <div className="bg-[#DDEEFF]  p-2  text-sm font-semibold  text-left  flex justify-start items-center">
              Surface area
            </div>

            <div className="grid grid-cols-2 grid-rows-1 gap-1 p-2 h-[17vh]">
              <div className="w-full h-full p-3">
                <img
                  src="/slabsizing/surface.svg"
                  alt=""
                  className="w-full h-full"
                />
              </div>

              <div className="flex flex-col p-2 text-sm font-medium justify-center items-center">
                {overallDimentions?.surfaceArea == null
                  ? "..."
                  : overallDimentions?.surfaceArea}
                {overallDimentions?.surfaceArea != null && (
                  <p>
                    mm<sup>2</sup>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OverallDimensions;
