import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Td,
  Tbody,
  IconButton,
  Skeleton,
} from "@chakra-ui/react";
import Feedback from "./Feedback";
import { useParams } from "react-router-dom";
import NavContext from "../../NavContext";
import { useState, useContext, useRef, useEffect } from "react";
import { useWindowSize } from "@uidotdev/usehooks";
import ReactCardFlip from "react-card-flip";
import {
  Cameraswitch,
  ChevronLeft,
  ChevronRight,
  Close,
} from "@mui/icons-material";
import { indexWordMap } from "../SinterBelt";
import TableSkeleton from "./LoadingState/TableSkeleton";
import { getDetailModalDataApi } from "../services/detailModalServices";
import { formatDateTime } from "../utilis/dateTimeFormatter";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

const ZoneWiseTable = ({ columns, tableData }) => {
  return (
    <TableContainer className="border rounded-md ">
      <Table variant="simple">
        <Thead className="bg-[#CCEAFF]">
          <Tr>
            {columns?.map((columnName, columnIndex) => (
              <Th
                color="#3E3C42"
                fontWeight={"semibold"}
                key={`${columnName}-${columnIndex}`}
                padding={1}
                textAlign={"left"}
                textTransform={"capitalize"}
              >
                {columnName?.split("_").join(" ")}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {tableData?.map((rowData, rowIndex) => {
            return (
              <Tr
                key={`row-${rowIndex}`}
                className="!text-sm !text-[#3E3C42] !font-medium even:bg-[#FAFAFA] odd:bg-white"
              >
                {columns?.map((columnName, columnIndex) => {
                  let content;
                  switch (columnName) {
                    case "permeability_index":
                      content = indexWordMap[rowData[columnName]];
                      break;
                    case "grate_bars":
                      content = rowData["grate_bars"] || "-";
                      break;
                    case "value_gap":
                      const [value, gap] = (rowData["value_gap"] ?? "")
                        ?.split("_")
                        ?.map(Number);
                      content =
                        rowData?.permeability_index === 3 ||
                        rowData?.permeability_index === 4
                          ? `${gap}px`
                          : `${value?.toFixed(2)}%`;
                      break;
                    default:
                      content = rowData[columnName];
                      break;
                  }
                  return (
                    <Td
                      key={`${columnName}-${columnIndex}`}
                      padding={1}
                      textAlign={"left"}
                    >
                      {content}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

const ImageFlipCard = ({
  flip,
  setFlip,
  originalImage,
  annotatedImage,
  cam2OriginalImage,
}) => {
  return (
    <div className="flex flex-col gap-3 rounded-md p-0 md:p-3 w-full border">
      {/* Pallet Number Live Image */}
      <div className="w-full h-full">
        <img
          src={cam2OriginalImage}
          alt="not supported"
          className="w-full h-[150px] object-cover rounded-md"
        />
      </div>
      <ReactCardFlip
        isFlipped={flip}
        flipDirection="vertical"
        containerClassName="w-full h-full"
      >
        {/* annotated image on front view */}
        <div className="w-full h-full">
          <div className=" absolute w-full h-full flex flex-row">
            <div className="flex flex-col justify-between items-start w-full h-full">
              <p className="bg-[#616161] rounded-sm text-white px-[2px]">N1</p>
              <p className="bg-[#616161] rounded-sm text-white px-[2px]">N2</p>
              <div className="flex flex-col items-start">
                <p className="bg-[#616161] rounded-sm text-white px-[2px]">
                  N3
                </p>
                <p className="h-10"></p>
              </div>
            </div>
            <div className="flex flex-col justify-between items-end  w-full h-full">
              <p className="bg-[#616161] rounded-sm text-white px-[2px]">S1</p>
              <p className="bg-[#616161] rounded-sm text-white px-[2px]">S2</p>
              <div className="flex flex-col items-end gap-1">
                <p className="bg-[#616161] rounded-sm text-white px-[2px]">
                  S3
                </p>
                <IconButton
                  aria-label="flip-image-front"
                  bg="#616161"
                  size={"sm"}
                  color={"white"}
                  _hover={{ bg: "#616161" }}
                  onClick={() => setFlip((prev) => !prev)}
                  icon={
                    <Cameraswitch
                      fontSize="large"
                      className="p-1"
                    />
                  }
                />
              </div>
            </div>
          </div>

          <img
            src={annotatedImage}
            alt="image_alt"
            className="w-full h-[200px] md:h-[250px]  rounded-md"
          />
        </div>
        {/* original image on back view */}
        <div className="w-full h-full">
          <TransformWrapper
            initialScale={1}
            wheel={{ disabled: true }}
            doubleClick={{ disabled: true }}
            pinch={{ disabled: true }}
          >
            {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
              <div className="w-full h-full relative">
                <div className="absolute bg-[#616161] text-white flex flex-col top-0 z-50 right-0 text-[18px] gap-0 rounded-md">
                  <button
                    onClick={() => zoomIn()}
                    className="w-full h-full py-0 px-[6px]"
                  >
                    +
                  </button>
                  <button
                    onClick={() => zoomOut()}
                    className="w-full h-full py-0 px-[6px]"
                  >
                    -
                  </button>
                  <button
                    onClick={() => resetTransform()}
                    className="w-full h-full py-0 px-[6px]"
                  >
                    x
                  </button>
                </div>
                <IconButton
                  aria-label="flip-image-front"
                  position="absolute"
                  bg="#616161"
                  _hover={{ bg: "#616161" }}
                  color={"white"}
                  size={"sm"}
                  onClick={() => setFlip((prev) => !prev)}
                  className="absolute right-0 bottom-0 text-white z-50"
                  icon={
                    <Cameraswitch
                      fontSize="large"
                      className="p-1"
                    />
                  }
                />
                <TransformComponent
                  wrapperStyle={{
                    width: "100%",
                    zIndex: 0,
                    borderRadius: "6px",
                  }}
                  contentStyle={{
                    width: "100%",
                    borderRadius: "6px",
                  }}
                >
                  <img
                    src={originalImage}
                    alt="image_alt"
                    className="w-full h-[200px] md:h-[250px]  object-center rounded-md"
                  />
                </TransformComponent>
              </div>
            )}
          </TransformWrapper>
        </div>
      </ReactCardFlip>
    </div>
  );
};

const Footer = ({ setopenFeedback }) => {
  return (
    <div className="flex w-full justify-end pb-2 md:pb-1">
      <div className="flex flex-col gap-1 md:gap-0 items-end">
        <p className="text-[#605D64] text-base">Noticed incorrect data?</p>
        <p
          className="text-[#084298] text-base font-semibold cursor-pointer hover:opacity-60"
          onClick={() => setopenFeedback(true)}
        >
          Give us feedback
        </p>
      </div>
    </div>
  );
};
const DetailModal = ({ openModal, closeModal, data, index }) => {
  const columns = [
    "row",
    "grate_bar_zone",
    "grate_bars",
    "permeability_index",
    "value_gap",
  ];
  let param = useParams();
  const size = useWindowSize();
  const { auth } = useContext(NavContext);
  let material = "sinterbelt";
  let clientId = param.clientId.toLowerCase();
  const indexRef = useRef();
  const idRef = useRef();
  const plantRef = useRef();
  const [modalData, setModalData] = useState(null);
  const [openFeedback, setopenFeedback] = useState(false);
  const [flip, setFlip] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    try {
      const requestData = JSON.stringify({
        clientId: clientId,
        useCase: material.toUpperCase(),
        id: idRef.current,
      });
      const response = await getDetailModalDataApi(
        auth,
        requestData,
        setLoading
      );
      // console.log(response);
      setModalData((prev) => response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const toggleMove = (val) => {
    //index -1
    let x = indexRef.current;
    if (val === 1) {
      indexRef.current = x > 0 ? x - 1 : data.length - 1;
    }
    //index +1
    else if (val === 2) {
      indexRef.current = x < data.length - 1 ? x + 1 : 0;
    }

    let idx = indexRef.current;
    idRef.current = data[idx]?.model?.recordId;
    fetchData();
  };

  useEffect(() => {
    if (idRef.current !== data[index]?.model?.recordId) {
      indexRef.current = index;
      idRef.current = data[index]?.model?.recordId;
      plantRef.current = data[index]?.model?.plantName;
      fetchData();
    }
  }, [data]);

  return (
    <>
      {size.width >= 768 ? (
        <Modal
          isOpen={openModal}
          onClose={closeModal}
          isCentered="true"
        >
          <ModalOverlay />
          <ModalContent
            style={{ borderRadius: "12px" }}
            maxW={["80vw", "80vw", "80vw", "70vw", "50vw"]}
            maxHeight={"90vh"}
          >
            <ModalHeader padding="0px">
              <div className="py-3 pr-2 pl-6 flex justify-between items-center bg-[#F5F5F5] rounded-tr-xl rounded-tl-xl">
                <div className="flex flex-row items-end ">
                  <p className="text-[#AEA9B1] text-xl font-bold">
                    {modalData?.sequenceNo}.
                  </p>
                </div>
                <div className="flex flex-row justify-between items-end w-full px-2">
                  <p className="text-base font-medium">
                    Pallet Board {modalData?.board}
                  </p>
                  <p className="text-[#79767D] text-sm font-medium">
                    Last Updated{" "}
                    <span className="text-black text-sm font-medium">
                      {modalData?.last_updated &&
                        formatDateTime(modalData?.last_updated)}
                    </span>
                  </p>
                </div>
                <IconButton
                  backgroundColor={"transparent"}
                  cursor={"pointer"}
                  icon={<Close sx={{ color: "gray" }} />}
                  onClick={closeModal}
                />
              </div>
            </ModalHeader>
            <ModalBody
              px="0px"
              maxH={"85vh"}
              overflow={"auto"}
            >
              <div className="flex flex-row items-center gap-2 relative h-full">
                {data.length > 1 && (
                  <IconButton
                    bg={"transparent"}
                    rounded={"full"}
                    size={"sm"}
                    boxShadow={"md"}
                    icon={<ChevronLeft />}
                    onClick={() => toggleMove(1)}
                  />
                )}

                <div className="w-full">
                  {loading ? (
                    <Skeleton
                      height={"400px"}
                      w={"full"}
                    />
                  ) : (
                    <ImageFlipCard
                      flip={flip}
                      setFlip={setFlip}
                      originalImage={modalData?.original_image}
                      annotatedImage={modalData?.annotated_image}
                      cam2OriginalImage={modalData?.cam2OriginalImage}
                    />
                  )}
                  <div className="flex flex-col gap-3 rounded-md p-3 w-full">
                    <div className="flex flex-row w-full justify-between items-center">
                      <p className="font-semibold">Permeability</p>
                    </div>
                    <div className="w-full">
                      {loading ? (
                        <TableSkeleton
                          headers={[
                            "Header 1",
                            "Header 2",
                            "Header 3",
                            "Header 4",
                          ]}
                          rows={5}
                          cellsPerRow={4}
                          padding={1}
                        />
                      ) : (
                        modalData?.table_data && (
                          <ZoneWiseTable
                            columns={columns}
                            tableData={modalData?.table_data}
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>
                {data.length > 1 && (
                  <IconButton
                    bg={"transparent"}
                    rounded={"full"}
                    size={"sm"}
                    boxShadow={"md"}
                    icon={<ChevronRight />}
                    onClick={() => toggleMove(2)}
                  />
                )}
              </div>
            </ModalBody>

            <ModalFooter paddingY={0}>
              {loading ? (
                <Skeleton
                  height={"30"}
                  w={"full"}
                />
              ) : (
                <Footer setopenFeedback={setopenFeedback} />
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : (
        <Drawer
          onClose={closeModal}
          isOpen={openModal}
          size="full"
          isFullHeight={true}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader
              backgroundColor={"#F5F5F5"}
              paddingX={0}
              paddingY={1}
            >
              <div className="flex justify-between items-center px-1">
                <div className="flex gap-3 items-end">
                  <p className="text-[#AEA9B1] font-semibold text-base">
                    {modalData?.sequenceNo}.
                  </p>
                </div>
                <div className="flex flex-row justify-between items-end w-full px-2">
                  <p className="text-base font-medium">
                    Pallet Board {modalData?.board}
                  </p>
                </div>
                <IconButton
                  backgroundColor={"transparent"}
                  cursor={"pointer"}
                  icon={<Close sx={{ color: "gray" }} />}
                  onClick={closeModal}
                />
              </div>
            </DrawerHeader>
            <DrawerBody padding="0px">
              <div className="flex justify-center">
                {data.length > 1 && (
                  <IconButton
                    bg={"white"}
                    position={"absolute"}
                    top={"45%"}
                    left={0}
                    rounded={"full"}
                    size={"sm"}
                    boxShadow={"md"}
                    icon={<ChevronLeft />}
                    onClick={() => toggleMove(1)}
                    zIndex={1}
                  />
                )}

                <div className="w-full">
                  {loading ? (
                    <Skeleton
                      h={"40"}
                      w={"full"}
                    />
                  ) : (
                    <div className="flex flex-col gap-3 border rounded-md p-3 w-full">
                      <div className="flex flex-row w-full justify-end items-center">
                        <p className="text-[#79767D] text-xs">
                          Last Updated{" "}
                          <span className="text-black text-xs">
                            {modalData?.last_updated &&
                              formatDateTime(modalData?.last_updated)}
                          </span>
                        </p>
                      </div>
                      {loading ? (
                        <Skeleton height={"350px"} />
                      ) : (
                        <ImageFlipCard
                          flip={flip}
                          setFlip={setFlip}
                          originalImage={modalData?.original_image}
                          annotatedImage={modalData?.annotated_image}
                          cam2OriginalImage={modalData?.cam2OriginalImage}
                        />
                      )}
                    </div>
                  )}
                  <div className="flex flex-col gap-3 rounded-md p-3 w-full">
                    <div className="flex flex-row w-full justify-center items-center">
                      <p className="font-semibold">Permeability</p>
                    </div>
                    <div className="w-full">
                      {loading ? (
                        <TableSkeleton
                          headers={[
                            "Header 1",
                            "Header 2",
                            "Header 3",
                            "Header 4",
                          ]}
                          rows={5}
                          cellsPerRow={4}
                          padding={1}
                        />
                      ) : (
                        modalData?.table_data && (
                          <ZoneWiseTable
                            columns={columns}
                            tableData={modalData?.table_data}
                          />
                        )
                      )}
                    </div>
                  </div>
                </div>
                {data.length > 1 && (
                  <IconButton
                    bg={"white"}
                    position={"absolute"}
                    top={"45%"}
                    right={0}
                    rounded={"full"}
                    size={"sm"}
                    boxShadow={"md"}
                    icon={<ChevronRight />}
                    onClick={() => toggleMove(2)}
                  />
                )}
              </div>
            </DrawerBody>
            <DrawerFooter paddingY={0}>
              {loading ? (
                <Skeleton
                  height={"30"}
                  w={"full"}
                />
              ) : (
                <Footer setopenFeedback={setopenFeedback} />
              )}
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
      {openFeedback && (
        <Feedback
          openModal={openFeedback}
          closeModal={() => setopenFeedback(false)}
          clientId={clientId}
          material={material}
          // cameraId={cameraRef.current}
          plantName={plantRef.current}
          id={idRef.current}
        />
      )}
    </>
  );
};

export default DetailModal;
