import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/react";
import { capitalizeCamelCaseString } from "../../utilis/capitalizeCamelCaseString";
import { ParametersLineChart } from "./ParametersLineChart";
import { CustomStyledDataGrid } from "../../../../util/MaterialDataGrid/CustomStyledDatagrid";

export const ParametersChangeTrend = ({ data }) => {
  return (
    <Accordion
      defaultIndex={[0]}
      allowToggle={false}
      backgroundColor={"white"}
      rounded={"xl"}
      paddingX={0}
      paddingBottom={4}
    >
      <AccordionItem border={"none"}>
        <AccordionButton
          borderTopRadius={"xl"}
          _hover={{
            backgroundColor: "white",
          }}
          padding={0}
        >
          <div className="flex flex-row justify-between w-full items-center px-2 md:px-5 py-2">
            <p className="text-sm text-left  md:text-base lg:text-lg  font-bold ">
              Parameter change trend since Last prediction
            </p>
          </div>
        </AccordionButton>
        <AccordionPanel
          py={4}
          borderBottomRadius={"xl"}
          padding={0}
        >
          <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-x-4 gap-y-6 w-full h-full px-2 md:px-5">
            {data &&
              Object.keys(data)
                ?.sort()
                ?.map((element, index) => (
                  <div
                    key={`${element}-${index}`}
                    className={`w-full h-full rounded-xl bg-white shadow-sm p-1 border`}
                  >
                    <p
                      className={`text-[12px]  md:text-[14px] lg:text-[16px]  font-semibold px-4 mb-2`}
                    >
                      {element && capitalizeCamelCaseString(element)}
                    </p>
                    <ParametersLineChart
                      chartData={data?.[element]?.lineChartData}
                      title={element && capitalizeCamelCaseString(element)}
                    />
                  </div>
                ))}
          </div>
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};

export const ParametersAccordian = ({ title, columns, rowData }) => {
  return (
    <Accordion
      defaultIndex={[0]}
      allowToggle={true}
      backgroundColor={"white"}
      rounded={"md"}
      paddingY={2}
      boxShadow={"sm"}
      border={"none"}
    >
      <AccordionItem
        padding={0}
        border={"none"}
      >
        {({ isExpanded }) => (
          <>
            <AccordionButton
              borderTopRadius={"xl"}
              _hover={{
                backgroundColor: "white",
              }}
              padding={0}
            >
              <div className="flex flex-row justify-between w-full items-center px-1 md:px-3">
                <p className="text-sm  md:text-base lg:text-lg font-bold text-left">
                  {title}
                </p>
                <AccordionIcon
                  rounded={"3xl"}
                  boxSize={"8"}
                  boxShadow={"md"}
                />
              </div>
            </AccordionButton>
            <AccordionPanel
              py={1}
              borderBottomRadius={"xl"}
              paddingX={0}
            >
              {isExpanded && (
                <div className="w-full h-full px-1 md:px-3">
                  <div className="w-full border rounded-md h-full">
                    <CustomStyledDataGrid
                      rows={rowData || []}
                      columns={columns || []}
                      autoHeight={true}
                      rowHeight={35}
                      hideFooter={true}
                      columnHeaderHeight={35}
                      disableColumnSelector={true}
                      disableRowSelectionOnClick={true}
                      getRowClassName={(params) =>
                        params.indexRelativeToCurrentPage % 2 === 0
                          ? "bg-[#FAFAFA]"
                          : "bg-white"
                      }
                      showColumnVerticalBorder
                    />
                  </div>
                </div>
              )}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  );
};
