import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { InfoIcon, InfoOutlineIcon } from "@chakra-ui/icons";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import NavContext from ".././NavContext";
import { baseURL } from "../../index";
import StarIcon from "@mui/icons-material/Star";
const MuiTheme = createTheme();

const AskAnExpertHistoryTable = ({
  rowData,
  fetchQueries,
  role,
  handleQuestionsExceptexpert,
}) => {
  const { auth } = useContext(NavContext);

  const navigate = useNavigate();

  const handleStarClick = async (queryId, event) => {
    // Prevent the click from triggering when clicking on a button inside the row
    if (event.target.tagName.toLowerCase() === "button") {
      return;
    }

    try {
      const response = await axios.patch(
        baseURL + `questions/starred/${queryId}`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );

      if (response.status == 200) {
        if (role === "EXPERT") {
          fetchQueries();
        } else {
          handleQuestionsExceptexpert();
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const columns = [
    {
      field: "questionId",
      headerName: "ID",
    },
    {
      field: "star",
      headerName: "",
      filterable:false,
      sortable:false,
      renderCell: ({ row }) => (
        <IconButton onClick={(e) => handleStarClick(row.questionId, e)}>
          {row.starred ? (
            <StarIcon style={{ color: "#FFC107" }} />
          ) : (
            <StarOutlineIcon />
          )}
        </IconButton>
      ),
    },
    {
      field: "subject",
      headerName: "Questions",
    },
    {
      field: "organisation",
      headerName: "Organisation",
      renderCell: (params) => (
        <div className="flex items-center w-[28px]">
          {/* <img
            src="/asianpaints.png"
            alt="Company Logo"
            className="mr-2 w-full" // Adjust margin as needed
          /> */}
          <span>{params.value}</span>
        </div>
      ),
    },
    {
      field: role === "EXPERT" ? "enquirer" : "expert",
      headerName: role === "EXPERT" ? "Enquirer" : "Expert",
    },
    {
      field: "dateTime",
      headerName: "Time of Enquiry",
      type:"date",
      valueGetter:({value}) => new Date(value),
      renderCell: (params) => <div>{formatTime(params.row.dateTime)}</div>,
    },
    {
      field: "deadline",
      headerName: "Deadline In",
      renderCell: (params) => <div>{formatDeadline(params.row.deadline)}</div>,
    },
    {
      field: "status",
      headerName: "Status",
      renderCell: ({ value }) => (
        <div className={`w-full flex gap-1 ${getStatusStyles(value)}`}>
          {value === "In Progress"
            ? "In Progress"
            : value === "Pending"
            ? "New Question"
            : value}
        </div>
      ),
    },

    {
      field: "viewAnswer",
      headerName: "View details",
      filterable:false,
      sortable:false,
      renderCell: ({ row }) => (
        <IconButton
          onClick={(e) => handleViewAnswerClick(row.questionId, e, row.status)}
          style={{ color: "#2196F3" }}
        >
          {/* <InfoOutlineIcon /> */}
          <VisibilityIcon />
        </IconButton>
      ),
    },
  ];

  const getStatusStyles = (status) => {
    switch (status) {
      case "Pending":
        return "text-[#FFC107] text-[14px] font-[700]";
      case "In Progress":
        return "text-[#69B04B] text-[14px] font-[500]";
      case "Answered":
        return "text-[#6CA6FC] text-[14px] font-[500]";
      case "Draft":
        return "text-[#FF0000] text-[14px] font-[500]";
      default:
        return "";
    }
  };
  const [selectedRowId, setSelectedRowId] = useState(null);

  const headerClass =
    //  capitalize
    "text-sm font-normal text-[#79767D] bg-[#DDEEFF] ";
  const cellClass = "text-sm text-[#3E3C42] whitespace-nowrap";
  const flexMap =
    role === "EXPERT"
      ? [0, 0.5, 3, 1, 1, 1, 1, 1, 1]
      : [0, 0.5, 3, 0, 1, 1, 1, 1, 1];
  columns.map((val, idx) => {
    val["headerClassName"] = headerClass;
    val["cellClassName"] = cellClass;
    val["flex"] = flexMap[idx];
  });

  const getRowClassName = (params) => {
    const isPendingRow = params?.row?.status === "Pending";

    return isPendingRow ? "font-bold" : "";
  };
  const handleRowClick = (params, event) => {
    // Prevent the row click from triggering when clicking on a button inside the row
    if (event.target.tagName.toLowerCase() === "button") {
      return;
    }

    // Navigate to a specific route when any row is clicked
    // navigate(`/community/expert/af933136-6f05-4f83-8e5b-f9c0d5384ced`);
  };

  const handleViewAnswerClick = async (questionId, event, status) => {
    if (event.target.tagName.toLowerCase() === "button") {
      return;
    }
    try {
      const response = await axios.patch(
        baseURL + `questions/status/${questionId}?status=0`,
        null,
        {
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );

      // Navigate to the desired route (if needed)
      if (status === "Draft")
        navigate(`/community/askanexpert/updatequestion/${questionId}`);
      else navigate(`/community/expert/${questionId}`);
    } catch (error) {
      console.error(error);
    }
  };

  const isTodayOrYesterday = (timestamp) => {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds

    return (
      date.getDate() === today.getDate() ||
      date.getDate() === yesterday.getDate()
    );
  };

  const formatTime = (dateTime) => {
    const date = new Date(dateTime);

    if (isTodayOrYesterday(date.getTime() / 1000)) {
      if (date.getDate() === new Date().getDate()) {
        // If today, display "Today"
        return (
          "Today " +
          date.toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          })
        );
      } else {
        // If yesterday, display "Yesterday" and month and time
        return date.toLocaleString("en-US", {
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        });
      }
    } else {
      // For other days, display month and time
      return date.toLocaleString("en-US", {
        month: "short",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    }
  };

  const formatDeadline = (deadline) => {
    if (deadline >= 0) {
      return `${deadline} Hours`;
    } else {
      return "Time Over";
    }
  };

  return (
    <div className="w-full overflow-x-auto mt-2 h-[65vh]">
      <ThemeProvider theme={MuiTheme}>
        <DataGrid
          rows={rowData}
          columns={columns}
          getRowId={(row) => row.questionId}
          getRowClassName={getRowClassName}
          columnVisibilityModel={{
            questionId: false,
            organisation: role === "EXPERT" ? true : false,
          }}
          onRowClick={handleRowClick}
          // hideFooter={true}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
            sorting: {
              sortModel: [{ field: "dateTime", sort: "desc" }],
            },
          }}
          slotProps={{
            // column menu
            columnMenu: {
              sx: {
                minWidth: 170,
                padding: 0,
                ".MuiTypography-root": {
                  fontSize: "13px",
                },
                "& .css-1asgcec-MuiButtonBase-root-MuiMenuItem-root": {
                  paddingY: 0,
                  minHeight: "35px",
                },
              },
            },
            // the panel which contains filtering menu (top wrapper)
            panel: {
              sx: {
                maxWidth: "85vw",
                ".css-154sxbz-MuiPaper-root-MuiDataGrid-paper": {
                  minWidth: 0,
                },
              },
            },
            // actual filter panel in which all elements are stored
            filterPanel: {
              filterFormProps: {
                // input box for operator selection(for multi-columns => and, or)
                logicOperatorInputProps: {
                  sx: { display: "none" },
                },
                // input box for column selection
                columnInputProps: {
                  variant: "outlined",
                  size: "small",
                },
                // input box for operator selection(for a particular columns => contains, start with, is empty ....)
                operatorInputProps: {
                  sx: { display: "none" },
                },
                // input box to write the value on the basis of which data get's filtered
                valueInputProps: {
                  InputComponentProps: {
                    variant: "outlined",
                    size: "small",
                  },
                },
                // delete icon
                deleteIconProps: {
                  sx: {
                    "& .MuiSvgIcon-root": { color: "#D32F2F" },
                  },
                },
              },
              sx: {
                overflowX: "auto",
                "& .MuiDataGrid-filterForm": { p: 2 },
                "& .MuiDataGrid-filterFormLogicOperatorInput": {
                  mr: 2,
                  minWidth: 80,
                },
                "& .MuiDataGrid-filterFormColumnInput": {
                  mr: 2,
                  minWidth: 150,
                },
                "& .MuiDataGrid-filterFormValueInput": { minWidth: 200 },
              },
            },
          }}
          sx={{ minWidth: "1000px" }}
        />
      </ThemeProvider>
    </div>
  );
};

export default AskAnExpertHistoryTable;
