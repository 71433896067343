import IndexChart from "../../Charts/SinterFlameCharts/IndexChart";
import { useEffect, useState, useContext } from "react";
import { baseURL } from "../../../index";
import { Spinner } from "@chakra-ui/react";
import axios from "axios";
import NavContext from "../../NavContext";
import LineChart from "../../Charts/SinterFlameCharts/LineChart";
import GaugeMeter from "../../Charts/SinterFlameCharts/GaugeMeter";
import { recommendations } from "../Sinterflame";

const CamFeed = ({
  material,
  cameraId,
  clientId,
  callApi,
  initialRender,
  plantName,
}) => {
  const { auth } = useContext(NavContext);
  const [camData, setCamData] = useState("");
  const [bulkData, setBulkData] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [num, setNum] = useState(1);

  const apiCall = async () => {
    const requestData = JSON.stringify({
      clientId: clientId,
      useCase: material.toUpperCase(),
      cameraId: cameraId,
      // plantName: plantName,
    });
    const response = await axios
      .post(
        baseURL + "vision/v2/processMonitoring/analysis/detail/",
        requestData,
        {
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      )
      .then((response) => {
        setCamData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const apiCallPopulate = async () => {
    const requestData = JSON.stringify({
      clientId: clientId,
      useCase: material.toUpperCase(),
      cameraId: cameraId,
      // plantName: plantName,
    });
    const response = await axios
      .post(
        baseURL + "vision/v2/processMonitoring/analysis/list/",
        requestData,
        {
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      )
      .then((response) => {
        setBulkData(response.data.reverse());
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (bulkData.length !== 0) {
      let currentIndex = 0;
      const intervalId = setInterval(() => {
        if (currentIndex < bulkData.length) {
          setCamData((prevCamData) => {
            return bulkData[currentIndex];
          });
          currentIndex++;
        } else {
          // All items processed, clear the interval
          clearInterval(intervalId);
          setLoaded(true);
        }
      }, 100);
      return () => {
        clearInterval(intervalId); // Clear the interval on component unmount
      };
    }
  }, [bulkData]);

  useEffect(() => {
    apiCallPopulate();
  }, []);

  useEffect(() => {
    if (!initialRender) {
      console.log("calling...");
      apiCall();
    }
  }, [callApi]);

  useEffect(() => {
    setNum(parseInt(camData?.cameraId?.replace("burner", "")));
  }, [camData]);

  return (
    <>
      {camData && (
        <div
          className={`flex flex-col gap-2 transition ease-in duration-1000 ${
            loaded ? "opacity-100" : "opacity-0"
          }`}
        >
          <div className="flex flex-col xl:flex-row gap-10 bg-white rounded-xl py-4 px-6">
            <div className="flex flex-col gap-8 flex-1">
              <div className="flex justify-between items-center w-full">
                <p className="self-start text-[#3E3C42] text-xl font-medium">
                  Current analysis
                </p>
                <p className="text-sm text-[#79767D]">
                  {new Date(camData.timestamp).toLocaleDateString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "2-digit",
                  })}
                  &nbsp;&nbsp;&nbsp;
                  {new Date(camData.timestamp).toLocaleTimeString()}
                </p>
              </div>
              <div className="flex flex-col sm:flex-row gap-4 w-full h-full sm:h-[30vh]">
                <div className="flex flex-col gap-2 w-full h-full">
                  <div
                    className={`cursor-pointer hover:bg-opacity-50 text-[#525056] text-sm font-medium flex ${
                      num - 1 == 0 ? "justify-end" : "justify-between"
                    } items-center w-full px-1 py-2 bg-[#F5F5F5] rounded-lg`}
                  >
                    {num - 1 != 0 && (
                      <p>
                        T{num - 1}: {camData["T" + (num - 1)]?.toFixed(2)}{" "}
                        &deg;C
                      </p>
                    )}
                    {num != 8 && (
                      <p>
                        T{num}: {camData["T" + num]?.toFixed(2)} &deg;C
                      </p>
                    )}
                  </div>
                  <div className="w-full h-full bg-black flex justify-center items-center rounded-lg">
                    {loaded && (
                      <img
                        className="rounded-xl w-auto h-[30vh] sm:h-auto max-h-[30vh]"
                        src={camData.annotatedImage}
                        crossOrigin="anonymous"
                      />
                    )}
                  </div>
                </div>
                <div className="border rounded-lg p-4 border-[#EBEBEB] bg-[#FAFAFA] h-full w-full sm:w-[215px] flex flex-col gap-2">
                  {!(camData.healthIndex == 0) ? (
                    <GaugeMeter
                      index={camData?.healthIndex}
                      accuracy={camData?.conf?.toFixed(0)}
                    />
                  ) : (
                    <div className="h-full gap-2 flex flex-col w-full items-center justify-center text-black font-bold text-center text-md">
                      <img
                        src="/SinterflameIcons/viewObstruct.svg"
                        className="h-[50px]"
                      />
                      <p>View obstructed</p>
                    </div>
                  )}
                  <div className="flex flex-col gap-[6px]">
                    <p className="flex gap-1 items-center text-[#605D64] text-sm font-medium">
                      <img
                        src="/logo192.png"
                        className="w-[18px] h-5"
                        alt="logo"
                      />
                      Recommendation
                    </p>
                    <div
                      className="w-full border rounded-lg p-2 text-center whitespace-normal text-[#605D64] text-xs"
                      style={{
                        backgroundColor:
                          camData?.healthIndex == 2 ? "#EFFCEE" : "#FCEEEE",
                        borderColor:
                          camData?.healthIndex == 2 ? "#6CE462" : "#E46962",
                      }}
                    >
                      {camData?.hasOwnProperty("remarks")
                        ? camData?.remarks
                        : recommendations[camData?.healthIndex]}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-8 flex-1">
              <p className="self-start text-[#3E3C42] text-xl font-medium">
                Trend analysis
              </p>
              <div className="h-[30vh]">
                {Number.isInteger(num) && (
                  <LineChart
                    data={
                      num - 1 == 0
                        ? [camData.healthIndex, camData["T" + num]]
                        : num == 8
                        ? [camData.healthIndex, camData["T" + (num - 1)]]
                        : [
                            camData.healthIndex,
                            camData["T" + (num - 1)],
                            camData["T" + num],
                          ]
                    }
                    timeStamps={new Date(camData?.timestamp).getTime()}
                    labels={
                      num - 1 == 0
                        ? ["Flame health", "T" + num]
                        : num == 8
                        ? ["Flame health", "T" + (num - 1)]
                        : ["Flame health", "T" + (num - 1), "T" + num]
                    }
                    color={["#084298", "#6CA6FC", "#5CFFFF"]}
                    curve={["stepline", "smooth", "smooth"]}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {!loaded && (
        <div
          style={{ top: camData ? "30%" : "100%" }}
          className="flex absolute right-[50%] opacity-100"
        >
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </div>
      )}
    </>
  );
};

export default CamFeed;
