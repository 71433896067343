import { useEffect, useState } from "react";
import SubDailyOutput from "./SubDailyOutput";
import axios from "axios";
function DailyOutput({ dailyOutput }) {
  const subDailyoutput = [0, 1, 2, 3];

  return (
    <div className="flex flex-col w-full  h-full gap-2">
      <div className="flex text-start w-full h-[22px]   items-center gap-3">
        <p className="text-[#79767D] text-base">Daily output</p>
      </div>

      <div className="grid grid-cols-2 grid-rows-2 gap-2">
        {/* You can create cells here */}
        {subDailyoutput.map((e) => {
          return <SubDailyOutput show={e} state={dailyOutput} />;
        })}
      </div>
    </div>
  );
}

export default DailyOutput;
