import React, { useContext, useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Input,
  Checkbox,
  Stack,
  ListItem,
  UnorderedList,
  Select,
  ModalHeader,
  useToast,
  Text,
} from "@chakra-ui/react";
import { InfoOutlineIcon } from "@chakra-ui/icons";
import PrimaryButton from "../../util/Buttons/PrimaryButton";
import TonalButton from "../../util/Buttons/TonalButton";
import ConfirmationModal from "./ConfirmationModal";
import { baseURL } from "../.././index";
import axios from "axios";
import NavContext from ".././NavContext";
import { useNavigate } from "react-router-dom";

const RequestUseCaseModal = ({ isOpen, onOpen, onClose, size }) => {
  const { auth } = useContext(NavContext);

  const [submitted, setSubmitted] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [briefDescription, setBriefDescription] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [otherCategory, setOtherCategory] = useState("");
  const [detailedDescription, setDetailedDescription] = useState("");
  const [selectedCurrency, setSelectedCurrency] = useState("₹");
  const [selectedNonFinancialBenefits, setSelectedNonFinancialBenefits] =
    useState("");
  const [financialValue, setFinancialValue] = useState("");
  const [submitClicked, setSubmitClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const productGroups = [
    {
      name: "Raw material sizing",
      value: "Raw material sizing",
    },
    {
      name: "Flare/Flame monitoring",
      value: "Flare/Flame monitoring",
    },
    {
      name: "Quality tracking/monitoring",
      value: "Quality tracking/monitoring",
    },
    {
      name: "Workforce monitoring",
      value: "Workforce monitoring",
    },
    {
      name: "Equipment monitoring",
      value: "Equipment monitoring",
    },
  ];

  useEffect(() => {
    setSubmitted(false);
  }, [onClose]);

  const handleDiscard = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleConfirmDiscard = () => {
    // Handle discard logic
    setIsModalOpen(false);
    onClose();
    navigate("/");
  };

  const handleCheckboxChange = (category) => {
    setSelectedCategories((prevCategories) => {
      if (category === "Others") {
        // If "Others" is checked, clear other selected categories and set "Others" value
        return prevCategories.includes("Others") ? [] : ["Others"];
      } else {
        // If any other category is checked, remove "Others" from selectedCategories
        return prevCategories.includes("Others")
          ? [category]
          : prevCategories.includes(category)
          ? prevCategories.filter((cat) => cat !== category)
          : [...prevCategories, category];
      }
    });
  };

  const handleOtherInputChange = (e) => {
    setOtherCategory(e.target.value);
  };

  const handleSubmit = async () => {
    // Set submitClicked to true
    setSubmitClicked(true);


    // for minimum character check
    if (
      briefDescription?.trim()?.length < 10 ||
      (selectedCategories.includes("Others") &&
        otherCategory?.trim()?.length < 2) ||
      detailedDescription?.trim()?.length < 50
    ) {
      toast({
        title: "Incomplete Details",
        description: "Please fill in all required details.",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });

      return;
    }

    // for maximum character check
    if (
      briefDescription?.trim()?.length > 200 ||
      detailedDescription?.trim()?.length > 4000 ||
      otherCategory?.trim()?.length > 200 ||
      selectedNonFinancialBenefits?.trim()?.length > 3500
    ) {
      toast({
        title: "You have exceeded the character limit",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });

      return;
    }

    const data = {
      description: briefDescription?.trim(),
      category: `${
        selectedCategories.includes("Others")
          ? otherCategory?.trim()
          : selectedCategories?.join(", ")
      }`,
      detailDescription: detailedDescription?.trim(),
      financialValue: `${selectedCurrency} ${financialValue}`,
      nonFinancialValue: selectedNonFinancialBenefits?.trim(),
    };
    setSubmitted(true);
    setIsLoading(true);
    try {
      const response = await axios.post(baseURL + "iam/addUseCase", data, {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });
      setIsLoading(false);
      setSubmitted(true);
      setBriefDescription("");
      setFinancialValue("");
      setSelectedCategories([]);
      setOtherCategory("");
      setSelectedNonFinancialBenefits("");
      setDetailedDescription("");
    } catch (error) {
      toast({
        title: "Something went wrong",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
      setIsLoading(false);
      console.log(error);
    }
    // console.log(data);
  };
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered size={size}>
        <ModalOverlay />
        <ModalContent>
          <div className="text-white w-full h-16 flex bg-[#2660B6] font-semibold justify-center items-center rounded-t-md">
            Request Use Case
          </div>
          <ModalCloseButton className="mt-2" color={"white"} />
          <ModalBody maxHeight={"80vh"} overflowY={"auto"}>
            {submitted === false ? (
              <div className="overflow-y-auto">
                <div className="mt-2">
                  <p className="font-semibold">
                    Brief description<span className=" text-red-500">*</span>
                  </p>
                  <Input
                    placeholder="Be concise and include the most important benefit"
                    mt={2}
                    value={briefDescription}
                    borderColor={
                      submitClicked && !briefDescription
                        ? "red.500"
                        : "gray.300"
                    }
                    borderWidth={"2px"}
                    onChange={(e) => setBriefDescription(e.target.value)}
                    // className="h-18 w-full border rounded-md mt-3 px-2 py-2 md:text-sm text-[10px]"
                  />
                  {submitClicked && (
                    <p className="text-red-500 text-sm mt-1">
                      {briefDescription?.trim()?.length === 0
                        ? "Please provide a brief description"
                        : briefDescription?.trim()?.length < 10
                        ? "Minimum 10 characters required"
                        : briefDescription?.trim()?.length > 200
                        ? "You have exceeded the character limit of 200"
                        : ""}
                    </p>
                  )}
                </div>
                <div className="mt-3">
                  <p className="font-semibold ">
                    Select category{" "}
                    <span className="text-[#AEA9B1]">(optional)</span>
                  </p>
                  <div className="grid gap-5 mt-5 grid-cols-1 sm:grid-cols-2 md:grid-cols-3">
                    {productGroups?.map((item) => (
                      <div>
                        <Checkbox
                          isDisabled={selectedCategories?.includes("Others")}
                          onChange={() => handleCheckboxChange(item?.value)}
                          whiteSpace={"nowrap"}
                        >
                          {item?.name}
                        </Checkbox>
                      </div>
                    ))}

                    <div>
                      <Checkbox onChange={() => handleCheckboxChange("Others")}>
                        Others
                      </Checkbox>
                    </div>
                    <div>
                      {selectedCategories?.includes("Others") && (
                        <Input
                          placeholder="Specify other category"
                          value={otherCategory}
                          onChange={handleOtherInputChange}
                        />
                      )}
                      {submitClicked &&
                        selectedCategories?.includes("Others") && (
                          <p className="text-red-500 text-sm mt-1">
                            {otherCategory?.trim()?.length === 0
                              ? "Please specify other category"
                              : otherCategory?.trim()?.length < 2
                              ? "Minimum 2 characters required"
                              : otherCategory?.trim()?.length > 200
                              ? "You have exceeded the character limit of 200"
                              : ""}
                          </p>
                        )}
                    </div>
                  </div>
                </div>
                <div className="mt-2">
                  <p className=" font-semibold mt-5">
                    Detailed description<span className=" text-red-500">*</span>
                  </p>
                  <p className="text-[14px] text-[#AEA9B1] mt-2">
                    Please Include
                  </p>
                  <UnorderedList
                    color={"#AEA9B1"}
                    ml={7}
                    fontSize={"14px"}
                    lineHeight={7}
                  >
                    <ListItem>A clear description of the new use case</ListItem>
                    <ListItem>
                      Intended objectives, benefits, and usage
                    </ListItem>
                    <ListItem>
                      Any specific requirements or unique aspects of the use
                      case that you believe are important for us to consider
                    </ListItem>
                  </UnorderedList>
                  <textarea
                    className={`h-[150px] w-full border-solid border-2 rounded-md mt-3 px-2 py-2 md:text-sm text-[10px] ${
                      submitClicked && !detailedDescription
                        ? "border-red-500"
                        : "border-gray-300"
                    }`}
                    value={detailedDescription}
                    onChange={(e) => setDetailedDescription(e.target.value)}
                  />
                  {submitClicked && (
                    <p className="text-red-500 text-sm mt-1">
                      {detailedDescription?.trim()?.length === 0
                        ? "Please provide a detailed description"
                        : detailedDescription?.trim()?.length < 50
                        ? "Minimum 50 characters required"
                        : detailedDescription?.trim()?.length > 4000
                        ? "You have exceeded the character limit of 4000"
                        : ""}
                    </p>
                  )}
                </div>
                <div className="flex w-[75%] gap-3">
                  <div>
                    <InfoOutlineIcon color={"#AEA9B1"} />
                  </div>
                  <p className="text-[#AEA9B1] text-[14px]">
                    The more information you provide, the better we can assess
                    the feasibility and potential impact of adding this use case
                    to our plattorm.
                  </p>
                </div>
                <div>
                  <p className="font-semibold mt-5 mb-3">
                    Financial value (appoximate)
                    <span className="text-[#AEA9B1] ml-1">(optional)</span>
                  </p>

                  <div className="flex ">
                    <Select
                      w="80px"
                      value={selectedCurrency}
                      color={"black"}
                      onChange={(e) => setSelectedCurrency(e.target.value)}
                    >
                      <option value="₹">₹</option>
                      <option value="$">$</option>
                      <option value="€">€</option>
                    </Select>

                    <Input
                      w="300px"
                      type="number"
                      value={financialValue}
                      onChange={(e) => setFinancialValue(e.target.value)}
                    />
                  </div>
                  <div className="mt-5">
                    <p className="font-semibold ">
                      Non-financial benefits
                      <span className="text-[#AEA9B1] ml-1">(optional)</span>
                    </p>
                    <Input
                      mt={3}
                      value={selectedNonFinancialBenefits}
                      onChange={(e) =>
                        setSelectedNonFinancialBenefits(e.target.value)
                      }
                    />
                    {submitClicked && (
                      <p className="text-red-500 text-sm mt-1">
                        {selectedNonFinancialBenefits?.trim()?.length > 3500
                          ? "You have exceeded the character limit of 3500"
                          : ""}
                      </p>
                    )}
                  </div>

                  <div className="flex mt-5 gap-3">
                    <div className="mt-1">
                      <InfoOutlineIcon color={"#AEA9B1"} />
                    </div>
                    <p className="text-[#AEA9B1] mt-1">Disclaimer</p>
                  </div>
                  <p className="text-[#AEA9B1] mt-3 text-[14px]">
                    While we appreciate your suggestions, inclusion is not
                    guaranteed. We evaluate each for technical feasibility,
                    financial viability, and demand. We reserve the right to
                    assess the impact of each proposal in enhancing the Ripik
                    platfrom. Your input is valuable, and we thank you for
                    contributing.
                  </p>
                </div>
              </div>
            ) : (
              <div>
                <div className="mt-5 w-full flex justify-center">
                  <img src="addusecase_submitted.svg" alt="submitted" />
                </div>
                <div className="mt-5 w-full flex justify-center font-bold text-[#034D86] text-lg">
                  Request Submitted
                </div>
                <div className="text-[#141619] font-light text-sm flex justify-center w-full px-2  mt-4">
                  Thank you for submitting your request to add a new use case to
                  our platform. Your input is valuable to us as we continue to
                  enhance our services. We appreciate the effort you've taken to
                  provide the details.
                </div>
              </div>
            )}
          </ModalBody>
          <ModalFooter justifyContent={"flex-start"}>
            {submitted === false ? (
              <div className="flex gap-[10px] items-center mt-2">
                <PrimaryButton
                  text={isLoading ? "Submitting..." : "Submit"}
                  width={"fit-content"}
                  onClick={handleSubmit}
                />
                <TonalButton
                  text={"Discard"}
                  width={"fit-content"}
                  onClick={handleDiscard}
                />
                <Modal
                  isOpen={isModalOpen}
                  onClose={handleCloseModal}
                  isCentered
                  size="sm"
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader
                      textAlign="center"
                      fontSize="lg"
                      fontWeight="bold"
                    >
                      Discard Information?
                    </ModalHeader>
                    <ModalBody textAlign="center" fontSize="sm">
                      Are you sure you want to discard the entered information
                      and go to homescreen?
                    </ModalBody>

                    <ModalFooter justifyContent="center">
                      <Button
                        colorScheme="blue"
                        mr={3}
                        onClick={handleCloseModal}
                      >
                        No
                      </Button>
                      <Button onClick={handleConfirmDiscard}>Yes</Button>
                    </ModalFooter>
                  </ModalContent>
                </Modal>
              </div>
            ) : (
              <button
                onClick={onClose}
                className="bg-[#084298] text-white px-7 py-2 rounded-md mb-5 ml-[45%]"
                mr={3}
              >
                Close
              </button>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RequestUseCaseModal;
