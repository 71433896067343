import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Textarea,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { useContext, useState } from "react";
import NavContext from "../../NavContext";
import { postFeedbackApi } from "../services/detailModalServices";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import TonalButton from "../../../util/Buttons/TonalButton";

let wordLimit = 100;

const Feedback = ({
  openModal,
  closeModal,
  clientId,
  material = "SINTERBELT",
  cameraId,
  plantName,
  id,
  feedbackStatus = "BAD",
}) => {
  const { auth } = useContext(NavContext);
  const toast = useToast();
  // const [issue, setIssue] = useState([]);
  const [text, setText] = useState("");
  const [exceedLimit, setExceedLimit] = useState(false);
  const [loading, setLoading] = useState(false);

  // const changeHandler = (val) => {
  //   setIssue((prev) => {
  //     const list = prev.slice();
  //     const idx = list.indexOf(val);

  //     if (idx !== -1) {
  //       list.splice(idx, 1);
  //     } else {
  //       list.push(val);
  //     }

  //     return list;
  //   });
  // };

  const textChangeHandler = (e) => {
    const inputValue = e.target.value;
    const words = inputValue.split(/[\s.!,;?]+/).filter(Boolean);
    if (words.length <= wordLimit) {
      setText(inputValue);
      if (exceedLimit) setExceedLimit(false);
    } else {
      setExceedLimit(true);
    }
  };

  const handleSubmit = async () => {
    if (exceedLimit || text.length == 0) {
      toast({
        title: "Invalid data",
        description: "Please ensure that data entered is valid",
        status: "warning",
        position: "top-right",
        duration: 1000,
        isClosable: true,
      });
      return;
    }
    try {
      const requestData = JSON.stringify({
        clientId: clientId,
        useCase: material.toUpperCase(),
        plantName: plantName,
        cameraId: "all",
        recordId: id,
        feedbackStatus: feedbackStatus || "BAD",
        feedbackMessage: text,
      });
      const res = await postFeedbackApi(auth, requestData, setLoading);
      // console.log(res.success);
      if (res.success) {
        toast({
          title: res?.message,
          description: "Your feedback has been recieved",
          status: "success",
          position: "top-right",
          duration: 2000,
          isClosable: true,
        });
        closeModal();
      } else {
        console.log("ex block");
        toast({
          title: "Error",
          description: "Please try again",
          status: "error",
          position: "top-right",
          duration: 1000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: "Error",
        description: "Please try again",
        status: "error",
        position: "top-right",
        duration: 1000,
        isClosable: true,
      });
    }
  };
  return (
    <Modal
      isOpen={openModal}
      onClose={closeModal}
      isCentered="true"
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent
        style={{ borderRadius: "12px" }}
        maxW="400px"
      >
        <ModalBody
          pos="relative"
          p="24px"
          rounded="12px"
        >
          <div className="flex flex-col gap-6">
            {/* <div className="flex flex-col gap-4">
              <p className="text-base text-[#141619]">
                Select the parameter(s) you found incorrect{" "}
              </p>
              <div className="flex flex-col gap-3">
                <div className="flex gap-3 items-center">
                  <input
                    type="checkbox"
                    name="size"
                    className="h-4 w-4"
                    onChange={(e) => changeHandler(e.target.name)}
                  />
                  <p className="text-[#605D64] text-base">Permeability Index</p>
                </div>
              </div>
            </div> */}
            <div className="flex flex-col relative">
              <p className="text-[#141619] text-base">Your feedback</p>
              <Textarea
                placeholder="Please explain your concern"
                mt="4px"
                mb="4px"
                isInvalid={exceedLimit}
                onChange={textChangeHandler}
              />
              <p className="absolute top-0 right-0 text-xs text-gray-600">
                Words: {text.split(/[\s.!,;?]+/).filter(Boolean).length}{" "}
                {`(Limit ${wordLimit})`}
              </p>
            </div>
            <div className="flex flex-row items-center gap-5">
              <TonalButton
                width={"150px"}
                height={"40px"}
                text={"Discard Changes"}
                onClick={closeModal}
              />

              <PrimaryButton
                width={"150px"}
                height={"40px"}
                text={loading ? <Spinner /> : "Submit"}
                onClick={() => handleSubmit()}
              />
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default Feedback;
