import FuelSuctionChart from "../../Charts/SinterFlameCharts/FuelSuctionMap";
import { useEffect, useState } from "react";

const OverviewGraphs = ({ CamData, plantName }) => {
  const [tempData, setTempData] = useState({});
  useEffect(() => {
    setTempData((prev) => {
      let newData = prev ? { ...prev } : {};
      for (let cam in CamData) {
        const pattern = /^T\d+$/;
        let keys = Object.keys(CamData[cam][0]).filter((key) =>
          pattern.test(key)
        );
        keys.forEach((item) => {
          if (!(item in newData) && CamData[cam][0][item]) {
            newData[item] = CamData[cam][0][item];
          }
        });
      }
      return newData;
    });
  }, [plantName, CamData]);
  return (
    <div className="w-full">
      <FuelSuctionChart
        series={[
          {
            name: "Temperature",
            data: Object.values(tempData || {}),
          },
        ]}
        yTitle={"Temperature in .C"}
        xAxis={{
          categories: Object.keys(tempData || {}),
          type: "string",
        }}
      />
    </div>
  );
};

export default OverviewGraphs;
