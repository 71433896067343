import React from "react";
import { getGridStringOperators } from "@mui/x-data-grid";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { capitalizeCamelCaseString } from "./utilityFunctions";

export const generateAlertsColumns = async (columnNames, handleViewDetails) => {
  const staticColumns = [
    {
      field: "view_details",
      headerName: "View Details",
      minWidth: 100,
      flex: 0.3,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,

      renderCell: (params) => {
        return (
          <button
            onClick={() => handleViewDetails(params)}
            className="text-blue-800 cursor-pointer font-semibold w-fit m-auto hover:bg-gray-200 p-1 rounded-full hover:scale-110"
          >
            <VisibilityIcon />
          </button>
        );
      },
    },
    // {
    //   field: "actionUpdate",
    //   headerName: "Action taken",
    //   type: "actions",
    //   headerAlign: "center",
    //   renderCell: ({ row }) => (
    //     <ActionButtons actionUpdate={row?.actionUpdate} />
    //   ),
    // },
  ];
  //   dynamic columns
  const dynamicColumns = columnNames.map((columnName) => {
    let columnConfig = {
      field: columnName,
      headerName: capitalizeCamelCaseString(columnName),
      flex: 1,
      minWidth: 200,
      sortable: true,
      filterOperators: getGridStringOperators().filter(
        (operator) => operator.value === "startsWith"
      ),
    };

    // add column specific props
    switch (columnName) {
      default:
        break;
    }

    return columnConfig;
  });
  return [
    ...(dynamicColumns || []),
    ...(handleViewDetails ? staticColumns : []),
  ];
};

export const dataGridSlotProps = () => {
  return {
    // column menu
    columnMenu: {
      sx: {
        minWidth: 170,
        padding: 0,
        ".MuiTypography-root": {
          fontSize: "13px",
        },
        "& .css-1asgcec-MuiButtonBase-root-MuiMenuItem-root": {
          paddingY: 0,
          minHeight: "35px",
        },
      },
    },
    // the panel which contains filtering menu (top wrapper)
    panel: {
      sx: {
        maxWidth: "85vw",
        ".css-154sxbz-MuiPaper-root-MuiDataGrid-paper": {
          minWidth: 0,
        },
      },
    },
    // actual filter panel in which all elements are stored
    filterPanel: {
      filterFormProps: {
        // input box for operator selection(for multi-columns => and, or)
        logicOperatorInputProps: {
          sx: { display: "none" },
        },
        // input box for column selection
        columnInputProps: {
          variant: "outlined",
          size: "small",
        },
        // input box for operator selection(for a particular columns => contains, start with, is empty ....)
        operatorInputProps: {
          sx: { display: "none" },
        },
        // input box to write the value on the basis of which data get's filtered
        valueInputProps: {
          InputComponentProps: {
            variant: "outlined",
            size: "small",
          },
        },
        // delete icon
        deleteIconProps: {
          sx: {
            "& .MuiSvgIcon-root": { color: "#d32f2f" },
          },
        },
      },
      sx: {
        overflowX: "auto",
        "& .MuiDataGrid-filterForm": { p: 2 },
        "& .MuiDataGrid-filterFormLogicOperatorInput": {
          mr: 2,
          minWidth: 80,
        },
        "& .MuiDataGrid-filterFormColumnInput": {
          mr: 2,
          minWidth: 150,
        },
        "& .MuiDataGrid-filterFormValueInput": { minWidth: 200 },
      },
    },
  };
};
