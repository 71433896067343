import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Input,
  useToast,
} from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import {
  Autocomplete,
  Checkbox,
  TextField,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { useContext, useState } from "react";
import BctContext from "../BctContext";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import NavContext from "../../NavContext";
import { postConcApi } from "../BlendComplianceTracking";

const ConcentrateDelete = ({ openModal = false, closeModal = () => {} }) => {
  const [selectedConcs, setSelectedConc] = useState([]);
  const { conc, setConc, currBayMap } = useContext(BctContext);
  const { auth } = useContext(NavContext);
  const toast = useToast();
  const MuiTheme = createTheme();
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleDelete = () => {
    let existInBay = selectedConcs?.filter((item) =>
      currBayMap?.includes(item)
    );
    if (selectedConcs?.length === 0 || existInBay?.length !== 0) {
      toast({
        title: "Error",
        description: (
          <div>
            {selectedConcs?.length === 0 && (
              <p>Please select concentrates to delete</p>
            )}
            {existInBay?.map((item) => (
              <p>
                {item} is in yard map, kindly remove from there then proceed to
                delete
              </p>
            ))}
          </div>
        ),
        duration: 6000,
        status: "error",
        isClosable: true,
        position: "top-right",
      });
      return;
    }
    let newConcs = conc?.filter(
      (item) => !selectedConcs?.some((val) => val === item)
    );
    postConcApi(auth, newConcs, toast, setConc);
    closeModal();
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={openModal}
      onClose={closeModal}
      isCentered="true"
    >
      <ModalOverlay />
      <ModalContent style={{ borderRadius: "8px" }} maxW="360px">
        <ModalHeader
          py={"8px"}
          px={"16px"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          borderBottom={"1px solid #EBE2F0"}
        >
          <p className="text-[#605D64] text-sm font-medium">
            Delete concentrate
          </p>
          <CloseIcon
            className="cursor-pointer hover:scale-105"
            onClick={closeModal}
            sx={{ color: "#605D64" }}
          />
        </ModalHeader>
        <ModalBody
          px={"24px"}
          py={"16px"}
          display={"flex"}
          flexDirection={"column"}
          gap={"12px"}
        >
          <ThemeProvider theme={MuiTheme}>
            <Autocomplete
              onChange={(val, newVal) => setSelectedConc(newVal)}
              disablePortal
              id="combo-box-demo"
              options={Array.from(new Set(conc))}
              value={selectedConcs}
              multiple
              disableCloseOnSelect
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              size="small"
              sx={{
                width: "auto",
                minWidth: "150px",
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #CAC5CD",
                },
                "& .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input": {
                  fontSize: "15px",
                },
              }}
              ListboxProps={{
                style: {
                  maxHeight: "250px",
                  fontSize: "14px",
                },
              }}
              renderInput={(params) => (
                <TextField {...params} label={"Select concentrates"} />
              )}
            />
          </ThemeProvider>
          <div className="self-end">
            <PrimaryButton
              text={"Save"}
              width={"fit-content"}
              onClick={handleDelete}
            />
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ConcentrateDelete;
