import { Link } from "react-router-dom";
import { useEffect } from "react";

import { baseURL } from "../../../index";
import NavContext from "../../NavContext";
import { useContext } from "react";
import { useState } from "react";
import axios from "axios";
import CreateClient from "./CreateClient";
import ClientsTable from "./Tables/ClientsTable";
import { Spinner } from "@chakra-ui/react";
import ClientCard from "./Components/ClientCards";
import AddIcon from '@mui/icons-material/Add';

const AddClients = ({
  setShowClientTab,
  setSelectedClient,
  clientView,
  setClientView,
}) => {
  const { auth } = useContext(NavContext);
  const [clients, setClients] = useState([]);
  const [clientsList, setClientList] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchClients = async () => {
    try {
      const response = await axios.get(baseURL + "iam/fetchClient", {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });

      // setClients(response?.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchClientsDetails = async () => {
    setLoading(true);
    try {
      const response = await axios.get(baseURL + "iam/fetchClient/detail", {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });

      setClientList(response?.data);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClientsDetails();
  }, []);
  return (
    <div className=" rounded-lg bg-white ">
      <div className="flex flex-row justify-end pl-3 pt-2 sm:pl-0 sm:pt-0">
        <div
          className="py-[6px] flex gap-1 items-center px-3 capitalize border text-sm"
          style={{
            backgroundColor: clientView === "card" ? "#FFFFED" : "white",
            borderColor: clientView === "card" ? "#FFC107" : "#EBEBEB",
            color: clientView === "card" ? "#3E3C42" : "#605D64",
            fontWeight: clientView === "card" ? 500 : 400,
            cursor: clientView === "card" ? "" : "pointer",
          }}
          onClick={() => setClientView("card")}
        >
          <img
            className="w-[20px] h-[20px] mr-1"
            src="/cardIcon.svg"
            alt=""
          ></img>
          Card
        </div>
        <div
          className="py-[6px] flex gap-1 items-center px-3 capitalize border text-sm"
          style={{
            backgroundColor: clientView === "table" ? "#FFFFED" : "white",
            borderColor: clientView === "table" ? "#FFC107" : "#EBEBEB",
            color: clientView === "table" ? "#3E3C42" : "#605D64",
            fontWeight: clientView === "table" ? 500 : 400,
            cursor: clientView === "table" ? "" : "pointer",
          }}
          onClick={() => setClientView("table")}
        >
          <img
            className="w-[20px] h-[20px] mr-1"
            src="/tableIcon.svg"
            alt=""
          ></img>
          Table
        </div>
      </div>
      {loading ? (
        <div className="flex justify-center mt-5">
          <Spinner speed="0.99s" size={"sm"} />
        </div>
      ) : (
        <>
          {clientView === "card" ? (
            <div className="bg-[#fafafa]  p-4 w-[100%] overflow-y-auto sm:h-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 min-[1440px]:grid-cols-5 min-[1750px]:grid-cols-5 min-[2150px]:grid-cols-7 gap-3 text-base font-medium text-[#3E3C42]">
              <div
                className="rounded p-3 w-full flex flex-col justify-center gap-3 items-center bg-white h-[250px]"
                style={{
                  boxShadow:
                    "-4px -4px 24px 0px rgba(0, 0, 0, 0.07), 4px 4px 24px 0px rgba(0, 0, 0, 0.07)",
                }}
              >
                <div
                  className="p-[10px] bg-[#DEF] text-center rounded cursor-pointer hover:scale-105"
                  onClick={() => setShowClientTab((prev) => "createclient")}
                >
                  <AddIcon sx={{color: "#3A74CA"}} />
                </div>
                <p className="font-bold">Add new client</p>
              </div>
              {clientsList &&
                clientsList.length != 0 &&
                clientsList?.map((items, index) => {
                  return (
                    <ClientCard
                      key={index}
                      data={items}
                      fetchClientsFun={fetchClientsDetails}
                      setShowClientTab={setShowClientTab}
                      setSelectedClient={setSelectedClient}
                    />
                  );
                })}
            </div>
          ) : (
            <div className="bg-[#fafafa]  p-4 w-full overflow-y-auto sm:h-full gap-3 text-base font-medium text-[#3E3C42] flex xl:flex-row flex-col">
              {clientsList && clientsList.length !== 0 ? (
                <ClientsTable
                  data={clientsList}
                  setShowClientTab={setShowClientTab}
                  setSelectedClient={setSelectedClient}
                />
              ) : (
                <div className="w-full flex justify-center text-gray-500 mt-5">
                  No data available
                </div>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AddClients;
