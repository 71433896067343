import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useWindowSize } from "@uidotdev/usehooks";

import ViewClient from "../AddClients/ViewClient";
import AllotToken from "../../Admin/Tabs/AllotToken";
import TokenTransaction from "../../Admin/Tabs/TokenTransaction";
import SessionLogs from "../../Admin/Tabs/SessionLogs";
import UserMgmt from "../../Admin/Tabs/UserMgmt";
import ActiveSubs from "../../Admin/Tabs/ActiveSubs";
import UpdateClient from "../AddClients/UpdateClient";
import DevelopmentInProgress from "../Deploy/DevelopmentInProgress";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const UpdateTabs = ({ setShowClientTab, selectedClient }) => {
  const [isDeployClicked, setDeployClicked] = useState(false);
  const [isFetchTranChanged, setTranTableChange] = useState(false);

  const [page, setPage] = useState("edit");
  const [tabIndex, setTabIndex] = useState(0);
  const [isfieldsChanged, setFieledsChange] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleTabsChange = (index) => {
    setTabIndex(index);
  };
  const handleBackButton = () => {
    if (isfieldsChanged) {
      setIsModalOpen(true);
    } else {
      setShowClientTab((prev) => "addclient");
    }
  };

  return (
    <>
      {" "}
      {!isDeployClicked ? (
        <div
          className="font-roboto flex flex-col rounded-lg"
        //   style={{ width: size.width >= 768 ? "calc(100vw - 168px)" : "100vw" }}
        >
          <div className="flex items-between mb-3">
            <div className="cursor-pointer w-8" onClick={handleBackButton}>
              <KeyboardBackspaceIcon sx={{ color: "#084298" }} />
            </div>
            <p className="text-lg sm:text-2xl font-semibold text-[#024D87]">
              Update Client Details
            </p>
          </div>

          <Tabs
            className="bg-white rounded-md px-6 pt-4"
            index={tabIndex}
            onChange={handleTabsChange}
            isLazy={true}
          >
            <TabList
              className="!flex !border-0 !justify-between !items-center"
              width={"full"}
            >
              <div className="flex sm:w-[75%] xl:w-[100%] items-center gap-4 overflow-x-auto h-14 xl:h-10">
                <Tab
                  className={
                    tabIndex == 0
                      ? "!text-[#605D64] !text-xs xl:!text-sm !bg-[#6CABFC] !bg-opacity-20 rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#6CA6FC]"
                      : "!text-xs xl:!text-sm !text-[#605D64] !border !border-[#EBEBEB] !rounded-full"
                  }
                // onClick={() => setPage("edit")}
                >
                  Edit
                </Tab>
                <Tab
                  className={
                    tabIndex == 1
                      ? "!text-[#605D64] !text-xs xl:!text-sm !bg-[#6CABFC] !bg-opacity-20 rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#6CA6FC]"
                      : "!text-xs xl:!text-sm !text-[#605D64] !border !border-[#EBEBEB] !rounded-full"
                  }
                // onClick={() => setPage("activesubs")}
                >
                  Subscriptions
                </Tab>
                <Tab
                  className={
                    tabIndex == 2
                      ? "!text-[#605D64] !text-xs xl:!text-sm !bg-[#6CABFC] !bg-opacity-20 rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#6CA6FC]"
                      : "!text-xs xl:!text-sm !text-[#605D64] !border !border-[#EBEBEB] !rounded-full"
                  }
                  onClick={() => setPage("user_management")}
                >
                  User Management
                </Tab>
                <Tab
                  className={
                    tabIndex == 3
                      ? "!text-[#605D64] !text-xs xl:!text-sm !bg-[#6CABFC] !bg-opacity-20 rounded-full pl-4 pr-4 pt-1 pb-1 !border !border-[#6CA6FC]"
                      : "!text-xs xl:!text-sm !text-[#605D64] !border !border-[#EBEBEB] !rounded-full"
                  }
                  onClick={() => setPage("allot_token")}
                >
                  Allocate Tokens
                </Tab>
              </div>
              {/* <PrimaryButton
                text={"Deploy"}
                width={"fit-content"}
                onClick={() => setDeployClicked(true)}
              /> */}
            </TabList>

            <TabPanels className="!pt-4">
              <TabPanel className="!pl-0 !pr-0">
                {selectedClient && (
                  <UpdateClient
                    setDeployClicked={setDeployClicked}
                    selectedClient={selectedClient}
                    setShowClientTab={setShowClientTab}
                    isfieldsChanged={isfieldsChanged}
                    setFieledsChange={setFieledsChange}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                  />
                )}
              </TabPanel>
              <TabPanel className="!pl-0 !pr-0">
                {selectedClient && (
                  <ActiveSubs
                    clientId={selectedClient.clientId}
                    clientOrg={selectedClient.organisation}
                    mode="update"
                    isSuperAdminClient={
                      selectedClient.organisation !== "Ripik.Ai" ? true : false
                    }
                  />
                )}
              </TabPanel>
              <TabPanel className="!pl-0 !pr-0">
                {selectedClient && (
                  <UserMgmt
                    clientId={selectedClient.clientId}
                    clientOrg={selectedClient.organisation}
                    mode="update"
                  />
                )}
              </TabPanel>
              <TabPanel className="!pl-0 !pr-0">
                {selectedClient && (
                  <AllotToken
                    clientId={selectedClient.clientId}
                    setTranTableChange={setTranTableChange}
                    isFetchTranChanged={isFetchTranChanged}
                    clientOrg={selectedClient.organisation}
                    mode="update"
                  />
                )}
              </TabPanel>
            </TabPanels>
          </Tabs>
        </div>
      ) : (
        <DevelopmentInProgress setDeployClicked={setDeployClicked} />
      )}
    </>
  );
};

export default UpdateTabs;
