import { useNavigate } from "react-router-dom";
import TextButton from "../../../../util/Buttons/TextButton";
import AddIcon from "@mui/icons-material/Add";
import {
  Breadcrumb,
  BreadcrumbItem,
  Table,
  Td,
  Tr,
  Thead,
  Tbody,
  TableContainer,
  Th,
} from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";

const AlertSetting = () => {
  const navigate = useNavigate();
  const columns = [
    "Alert name",
    "Alert medium",
    "created on",
    "Alert condition",
    "Action",
  ];
  return (
    <div className="flex flex-col gap-2 mt-6">
      <div className="flex gap-2 items-center text-[#084298] text-xl font-medium">
        <img
          src="/backtick.svg"
          alt="back"
          className="hover:scale-105 cursor-pointer"
          onClick={() => navigate(`/Sandbox/Cam/View/1234?page=2`)}
        />
        <Breadcrumb
          spacing="8px"
          separator={<ChevronRightIcon color="#084298" />}
        >
          <BreadcrumbItem>
            <p>Camera 1</p>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <p>Alerts settings</p>
          </BreadcrumbItem>
        </Breadcrumb>
      </div>
      <div className="bg-white py-6 flex flex-col gap-4 rounded-xl">
        <p className="pl-4 text-[#3E3C42] text-lg font-medium">
          Created alerts
        </p>
        <TableContainer className="!max-h-[80vh] !overflow-y-auto">
          <Table variant="simple">
            <Thead className="bg-[#FAFAFA] !text-xs !sticky !top-0">
              <Tr>
                {columns.map((id, idx) => {
                  return (
                    <Th key={idx} color="#79767D" fontWeight={400}>
                      {id.toUpperCase()}
                    </Th>
                  );
                })}
                <Th key={Math.random()} color="#79767D" fontWeight={400}>
                  {" "}
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {[...Array(3)].map((item, index) => {
                return (
                  <Tr
                    key={index}
                    className="!text-sm !text-[#3E3C42] !font-medium even:bg-[#FAFAFA] odd:bg-white"
                  >
                    <Td>Alert-1</Td>
                    <Td>Email, Whatsapp</Td>
                    <Td>{new Date().toLocaleString()}</Td>
                    <Td>
                      <div className="flex flex-col gap-2">
                        <p>When Human is detected</p>
                        <p className="text-[#AEA9B1]">And</p>
                        <p>When monkey is detected</p>
                      </div>
                    </Td>
                    <Td>
                      <TextButton
                        text={"Edit alert"}
                        width={"fit-content"}
                        onClick={() =>
                          navigate("/Sandbox/Alert/Create/1234?existing=true")
                        }
                      />
                    </Td>
                    <Td>
                      <TextButton
                        text={
                          <img src="/delete.svg" className="w-5" alt="delete" />
                        }
                        width={"fit-content"}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
        <TextButton
          Icon={<AddIcon sx={{ color: "#447ED4" }} />}
          text={"Add new alert"}
          width={"fit-content"}
          onClick={() => navigate("/Sandbox/Alert/Create/1234")}
        />
      </div>
    </div>
  );
};

export default AlertSetting;
