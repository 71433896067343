import {
  Input,
  Radio,
  RadioGroup,
  Checkbox,
  CheckboxGroup,
  useToast,
  Select,
  Stack,
  Textarea,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
  Button,
  Text,
  CloseButton,
  IconButton,
} from "@chakra-ui/react";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useState, useRef, useEffect, useContext } from "react";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import TextButton from "../../../util/Buttons/TextButton";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import TonalButton from "../../../util/Buttons/TonalButton";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../index";
import NavContext from "../../NavContext";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
//import AdminTabs from "../Tabs/TabsView";
import AddLogo from "./Components/AddLogo";
import DiscardModal from "../DiscardModal";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DraftModal from "../DraftModal";
import ClientSelectLogo from "./Components/ClientSelectLogo";
import { PhotoSizeSelectActualRounded } from "@mui/icons-material";

const UpdateClient = ({
  setDeployClicked,
  selectedClient,
  setShowClientTab,
  isfieldsChanged,
  setFieledsChange,
  isModalOpen,
  setIsModalOpen,
}) => {
  const { auth } = useContext(NavContext);
  const { clientId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [companyType, setCompanyType] = useState();
  const [industryValue, setIndustryValue] = useState("");
  const [subIndustryValue, setSubIndustryValue] = useState("");
  const [companySize, setCompanySize] = useState("");
  const [numberOfUsers, setNumberOfUsers] = useState("");
  const [parentCompany, setParentCompany] = useState("");
  const [clientName, setClientName] = useState("");
  const [clientHqLocation, setClientHqLocation] = useState("");
  const [hqLocationAddress, setHqLocationAddress] = useState("");
  const [allLocations, setAllLocations] = useState("");
  const [totalClientLocations, setTotalClientLocations] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientPrimaryContactName, setClientPrimaryContactName] = useState("");
  const [clientSecContactName, setClientSecContactName] = useState("");
  const [clientSecContactEmail, setClientSecContactEmail] = useState("");
  const [clientSecContactNumber, setClientSecContactNumber] = useState("");
  const [clientPhoneNumber, setClientPhoneNumber] = useState("");
  const [ripikPhoneNumber, setRipikPhoneNumber] = useState("");
  const [ripikEmail, setRipikEmail] = useState("");
  const [ripikSecContactName, setRipikSecContactName] = useState("");
  const [ripikSecContactEmail, setRipikSecContactEmail] = useState("");
  const [ripikSecContactNumber, setRipikSecContactNumber] = useState("");
  const [ripikPrimaryContactName, setRipikPrimaryContactName] = useState("");
  const [dealValue, setDealValue] = useState("");
  const [purchaseOrderCode, setPurchaseOrderCode] = useState("");
  const [remarks, setRemarks] = useState("");
  const [submitClicked, setSubmitClicked] = useState(false);
  const [clientOrg, setClientOrg] = useState("");

  const [cardLogo, setCardLogo] = useState(null);
  const [cardLogoPreview, setCardLogoPreview] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(
    selectedClient.dealCurrency || "₹"
  );
  const [review, setReview] = useState(false);
  const [isSaved, setSaved] = useState(false);

  const [selectedCountryCodeClient, setSelectedCountryCodeClient] = useState(
    selectedClient.clientContactCode || "+91"
  );
  const [selectedCountryCodeRipik, setSelectedCountryCodeRipik] = useState(
    selectedClient.ripikContactCode || "+91"
  );
  const [secSelectedCountryCodeClient, setSecSelectedCountryCodeClient] =
    useState(selectedClient.clientSecContactCode || "+91");
  const [secSelectedCountryCodeRipik, setSecSelectedCountryCodeRipik] =
    useState(selectedClient.ripikContactSecCode || "+91");
  const [validationErrors, setValidationErrors] = useState({
    clientName: "",
    parentCompany: "",
    companyType: "",
    industry: "",
    subIndustryValue: "",
    companySize: "",
    numberOfUsers: "",
    clientHqLocation: "",
    hqLocationAddress: "",
    totalClientLocation: "",
    allLocations: "",
    clientPrimaryContactName: "",
    clientEmail: "",
    clientPhoneNumber: "",
    clientSecContactName: "",
    clientSecContactEmail: "",
    clientSecContactNumber: "",
    ripikPrimaryContactName: "",
    ripikEmail: "",
    ripikContactNumber: "",
    ripikContactSecName: "",
    ripikContactSecEmail: "",
    ripikSecContactNumber: "",
    // creationAt: new Date().getTime(),
    // lastUpdatedDate: new Date().getTime(),
    clientRelStartDate: "",
    ripikProductUseDate: "",
    dealValue: "",
    remarks: "",
    purchaseOrderCode: "",
  });
  const toast = useToast();
  const [relationDate, setRelationDate] = useState(null);

  const [productDate, setProductDate] = useState(null);
  const countryCodes = ["+91", "+1", "+44", "+81"];
  const [locations, setLocations] = useState(
    (selectedClient?.clientAllLocation || "")
      .split(",")
      .filter((location) => location.trim() !== "") || []
  );
  const [existingClientNames, setExistingClientNames] = useState([]);

  const [isAddCompanyLocation, setAddCompanyLocation] = useState(false);
  const [errorAllLocations, setErrorAllLocations] = useState("");
  const [editableIndex, setEditableIndex] = useState(null);
  const [searchSuggestions, setSearchSuggestions] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [icon, setIcon] = useState("Select default logo");
  const [defaultIcon, setDefaultIcon] = useState(null);
  const [logoList, setLogoList] = useState(null);

  const handleAddLocation = () => {
    if (allLocations.trim() !== "") {
      // Handle adding locations logic
      const newLocations = allLocations.split(",").map((loc) => loc.trim());
      const allLocs = [...locations, ...newLocations];
      const duplicate = allLocs.map((loc) => loc.toLowerCase());

      // Check for duplicates
      if (new Set(duplicate).size !== duplicate?.length) {
        setErrorAllLocations("Duplicate locations are not allowed");
      }

      // Check for length
      if (duplicate?.length > 50) {
        setErrorAllLocations(
          `You have exceeded the character limit of 50 by ${
            duplicate?.length - 50
          }`
        );
      }

      setLocations((prevLocations) => [...prevLocations, ...newLocations]);
      // Reset allLocations to an empty string after adding
      setAllLocations("");
      setAddCompanyLocation(false);
    }
    setValidationErrors((prev) => ({
      ...prev,
      allLocations: "",
    }));
    // setAddCompanyLocation(false);
  };

  const handleEditLocation = (index) => {
    setEditableIndex(index);
    // Set the current location being edited to allLocations
    // setAllLocations(locations[index]);
    setErrorAllLocations(""); // Clear any previous errors
  };

  const handleSaveLocation = (index) => {
    // Implement any additional logic needed when saving an edited location

    setEditableIndex(null);
    setAllLocations(""); //added to avaoid duplication when editing and saving as draft
    setValidationErrors((prev) => ({
      ...prev,
      allLocations: "",
    }));
  };

  const handleLocationChange = (e, index) => {
    const updatedLocations = [...locations];
    updatedLocations[index] = e.target.value;

    setLocations(updatedLocations);
  };

  const handleAddCompanyLocation = () => {
    setAddCompanyLocation(true);
  };

  const fetchSearchSuggestions = async (term) => {
    const clients = existingClientNames.filter((item) =>
      item.name.toLowerCase().includes(term.toLowerCase())
    );

    setSearchSuggestions(clients);
  };
  const handleSearchTermChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
  };

  const dataOptions = {
    companyType: {
      type: ["Public", "Private", "Others"],
    },
    industry: {
      type: [
        "Aerospace",
        "Artificial Intelligence",
        "Automotive",
        "Chemical",
        "Construction",
        "Consumer goods",
        "Defence",
        "Energy",
        "Food & Beverage",
        "Glass",
        "Healthcare",
        "Information Technology",
        "Metal",
        "Pharmaceutical",
        "Software Development",
        "Others",
      ],
    },
  };

  const handleConfirmDiscard = () => {
    // Handle discard logic
    setIsModalOpen(false);
    setShowClientTab((prev) => "addclient");
  };

  const isValidEmail = (email) => {
    // Regular expression for a simple email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isContactNameValid = (name) => {
    // Regular expression for a simple email validation
    const nameRegex = /^[a-zA-Z ]{2,50}$/;
    return nameRegex.test(name);
  };

  const isValidName = (name) => {
    // Regular expression for a simple name validation
    const nameRegex = /^(?!-)(?!.*-$)[\w\d\s\S]{2,50}$/;

    return nameRegex.test(name);
  };
  const isValidClientPhoneNumber = (phoneNumber) => {
    // Regular expression for validating phone number (assuming digits only)
    const phoneNumberRegex = /^\d+$/;

    return (
      phoneNumber &&
      phoneNumberRegex.test(phoneNumber) &&
      phoneNumber?.length >= 8 &&
      phoneNumber?.length <= 15
    );
  };
  const handleSubmit = async (statusCode) => {
    setSubmitClicked(true);

    const newValidationErrors = {};

    // if (!clientName || !isValidName(clientName)) {
    if (!clientName || !isValidName(clientName)) {
      newValidationErrors.clientName = "Please enter a valid name";
    } else if (clientName?.length < 2 || clientName?.length > 50) {
      if (clientName?.length < 2) {
        newValidationErrors.clientName = "Minimum 2 characters required";
      } else if (clientName?.length > 50) {
        newValidationErrors.clientName = `Exceeded the character limit of 50 `;
      }
    }
    // }
    if (!companyType) {
      newValidationErrors.companyType = "Please add company type";
    }

    // if (locations.length == 0) {
    //   //checking location
    //   newValidationErrors.allLocations = "Please add location";
    // }
    if (parentCompany?.length > 50) {
      newValidationErrors.parentCompany = `You have exceeded the character limit of 50 by ${
        parentCompany.length - 50
      }`;
    }

    if (subIndustryValue?.length > 50) {
      newValidationErrors.subIndustryValue = `You have exceeded the character limit of 50 by ${
        subIndustryValue?.length - 50
      }`;
    }
    if (numberOfUsers?.length > 50) {
      newValidationErrors.numberOfUsers = `You have exceeded the character limit of 50 by ${
        numberOfUsers?.length - 50
      }`;
    }

    if (hqLocationAddress?.length > 200) {
      newValidationErrors.hqLocationAddress = `You have exceeded the character limit of 200 by ${
        hqLocationAddress.length - 200
      }`;
    }
    if (clientHqLocation?.length > 50) {
      newValidationErrors.clientHqLocation = `You have exceeded the character limit of 50 by ${
        clientHqLocation?.length - 50
      }`;
    }
    if (allLocations?.length > 50) {
      newValidationErrors.allLocations = `You have exceeded the character limit of 50 by ${
        allLocations?.length - 50
      }`;
    }
    if (
      clientPrimaryContactName &&
      !isContactNameValid(clientPrimaryContactName)
    ) {
      if (!isContactNameValid(clientPrimaryContactName)) {
        newValidationErrors.clientPrimContactName = "Please enter a valid name";
      } else {
        if (clientPrimaryContactName?.length > 50) {
          newValidationErrors.clientPrimaryContactName = `You have exceeded the character limit of 50 by ${
            clientPrimaryContactName?.length - 50
          }`;
        }
      }
    }
    if (clientEmail && !isValidEmail(clientEmail)) {
      if (!isValidEmail(clientEmail)) {
        newValidationErrors.clientEmail = "Please enter a valid email";
      }
    }

    if (clientPhoneNumber && !isValidClientPhoneNumber(clientPhoneNumber)) {
      newValidationErrors.clientPhoneNumber = "Please add a valid phone number";
    }

    if (clientSecContactName && !isContactNameValid(clientSecContactName)) {
      if (!isContactNameValid(clientSecContactName)) {
        newValidationErrors.clientSecContactName = "Please enter a valid name";
      } else {
        if (clientSecContactName?.length > 50) {
          newValidationErrors.clientSecContactName = `You have exceeded the character limit of 50 by ${
            clientSecContactName?.length - 50
          }`;
        }
      }
    }
    if (clientSecContactEmail && !isValidEmail(clientSecContactEmail)) {
      if (!isValidEmail(clientSecContactEmail)) {
        newValidationErrors.clientSecContactEmail =
          "Please enter a valid email";
      }
    }

    if (
      clientSecContactNumber &&
      !isValidClientPhoneNumber(clientSecContactNumber)
    ) {
      newValidationErrors.clientSecContactNumber =
        "Please add a valid phone number";
    }

    if (
      ripikPrimaryContactName &&
      !isContactNameValid(ripikPrimaryContactName)
    ) {
      if (!isContactNameValid(ripikPrimaryContactName)) {
        newValidationErrors.ripikPrimaryContactName =
          "Please enter a valid name";
      } else {
        if (ripikPrimaryContactName?.length > 50) {
          newValidationErrors.ripikPrimaryContactName = `You have exceeded the character limit of 50 by ${
            ripikPrimaryContactName?.length - 50
          }`;
        }
      }
    }
    if (ripikEmail && !isValidEmail(ripikEmail)) {
      if (!isValidEmail(ripikEmail)) {
        newValidationErrors.ripikEmail = "Please enter a valid email";
      }
    }

    if (ripikPhoneNumber && !isValidClientPhoneNumber(ripikPhoneNumber)) {
      newValidationErrors.ripikPhoneNumber = "Please add a valid phone number";
    }

    // if (ripikSecContactName && !isContactNameValid(ripikSecContactName)) {
    if (ripikSecContactName && !isContactNameValid(ripikSecContactName)) {
      newValidationErrors.ripikSecContactName = "Please enter a valid name";
    } else {
      if (ripikSecContactName?.length > 50) {
        newValidationErrors.ripikSecContactName = `You have exceeded the character limit of 50 by ${
          ripikSecContactName?.length - 50
        }`;
      }
    }
    // }
    if (ripikSecContactEmail && !isValidEmail(ripikSecContactEmail)) {
      if (!isValidEmail(ripikSecContactEmail)) {
        newValidationErrors.ripikSecContactEmail = "Please enter a valid email";
      }
    }

    if (
      ripikSecContactNumber &&
      !isValidClientPhoneNumber(ripikSecContactNumber)
    ) {
      newValidationErrors.ripikSecContactNumber =
        "Please add a valid phone number";
    }

    if (dealValue?.length > 50) {
      newValidationErrors.dealValue = `You have exceeded the character limit of 50 by ${
        dealValue?.length - 50
      }`;
    }

    if (purchaseOrderCode?.length > 50) {
      newValidationErrors.purchaseOrderCode = `You have exceeded the character limit of 50 by ${
        purchaseOrderCode?.length - 50
      }`;
    }
    if (remarks?.length > 200) {
      newValidationErrors.remarks = `You have exceeded the character limit of 200 by ${
        remarks?.length - 200
      }`;
    }

    // console.log(newValidationErrors);

    // let allLocs = locations?.join(",");
    let allLocs = locations.filter(Boolean).join(",");

    // if (allLocations.trim() !== "" && statusCode != 1) {
    //   const newLocations = allLocations.split(",").map((loc) => loc.trim());
    //   const locationsCopy = [...locations, ...newLocations];
    //   allLocs = locationsCopy.join(",");
    //   setAddCompanyLocation(false);
    //   console.log(1);
    //   // setLocations(locationsCopy);
    // }
    if (allLocations.trim() !== "") {
      // Handle adding locations logic
      const newLocations = allLocations.split(",").map((loc) => loc.trim());
      console.log("newLocations", newLocations);
      const locCopy = [...locations, ...newLocations];

      console.log("locCopy", locCopy);

      allLocs = locCopy?.join(",");

      setLocations((prevLocations) => [...prevLocations, ...newLocations]);
      // Reset allLocations to an empty string after adding
      setAllLocations("");
      setAddCompanyLocation(false);
    }

    if (!allLocs) {
      //checking location again when trying to submit empty

      newValidationErrors.allLocations = "Please add location";
    }
    console.log(newValidationErrors);
    setValidationErrors(newValidationErrors);
    if (!clientName || !companyType || !allLocs) {
      // Show a toast if any required field is empty
      toast({
        title: "Incomplete Details",
        description: "Please fill the mandatory fields before submitting",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
      setIsLoading(false);
      return;
    }

    if (Object.keys(newValidationErrors)?.length > 0) {
      return;
    }

    // setAllLocations(allLocs);

    // Check if any required field is empty

    // if (!clientName || !companyType || !allLocs) {
    //   // Show a toast if any required field is empty
    //   toast({
    //     title: "Incomplete Details",
    //     description: "Please fill the mandatory fields before submitting",
    //     status: "error",
    //     duration: 4000,
    //     isClosable: true,
    //     position: "top",
    //   });
    //   setIsLoading(false);
    //   return;
    // }

    const newLocations = allLocs
      ?.split(",")
      ?.map((loc) => loc?.trim()?.toLowerCase());

    // Check for duplicates
    if (new Set(newLocations)?.size !== newLocations?.length) {
      setIsLoading(false);
      setErrorAllLocations("Duplicate locations are not allowed");
      return;
    }

    // // Check for length
    // if (newLocations.length > 50) {
    //   setIsLoading(false);

    //   setErrorAllLocations(`Maximum allowed locations is ${50}`);
    //   return;
    // }

    const data1 = {
      clientName: clientName,
      parentCompany: parentCompany,
      companyType: companyType,
      industry: industryValue,
      subIndustry: subIndustryValue,
      companySize: companySize,
      numberOfUsers: numberOfUsers,
      clientHqLocation: clientHqLocation,
      hqLocationAddress: hqLocationAddress,
      totalClientLocation: newLocations.length,
      // enterAllLocation: allLocs,
      clientAllLocation: allLocs,
      clientPrimContactName: clientPrimaryContactName,
      clientPrimEmail: clientEmail,
      clientContactNumber: clientPhoneNumber,
      clientSecContactName: clientSecContactName,
      ripikContactPrimName: ripikPrimaryContactName,
      ripikContactEmail: ripikEmail,
      ripikContactNumber: ripikPhoneNumber,
      ripikContactSecName: ripikSecContactName,
      ripikContactSecEmail: ripikSecContactEmail,
      ripikContactSecNumber: ripikSecContactNumber,
      clientSecEmail: clientSecContactEmail,
      clientSecContactNumber: clientSecContactNumber,
      // creationAt: new Date(relationDate).getTime(),
      lastUpdatedDate: new Date().getTime(),
      clientRelStartDate: relationDate,
      ripikProductUseDate: productDate,
      dealValue: dealValue,
      remarks: remarks,
      purchaseOrderCode: purchaseOrderCode,
      clientId: selectedClient.clientId,
      clientContactCode: selectedCountryCodeClient,
      clientSecContactCode: secSelectedCountryCodeClient,
      ripikContactCode: selectedCountryCodeRipik,
      ripikContactSecCode: secSelectedCountryCodeRipik,
      dealCurrency: selectedCurrency,
      status: statusCode,
    };
    if (icon === "Select default logo") {
      data1.logo32url = defaultIcon;
    }
    setIsLoading(true);

    // Append other data to FormData
    // return;
    const json = JSON.stringify(data1);
    const blob = new Blob([json], {
      type: "application/json",
    });
    const FormData = require("form-data");
    let data = new FormData();
    data.append("data", blob);
    // if (logo) {
    //   data.append("file1", logo);
    // }
    if (icon === "Upload your own logo") {
      data.append("file", cardLogo);
    }

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: baseURL + "iam/updateClient",
      headers: {
        "X-Auth-Token": auth,
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        setIsLoading(false);
        if (statusCode == 1) {
          toast({
            title: "Client updated successfully",
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top",
          });

          setTimeout(() => {
            setShowClientTab("addclient");
          }, 1500);
        } else {
          toast({
            title: "Draft saved successfully",
            status: "success",
            duration: 4000,
            isClosable: true,
            position: "top",
          });
          setLocations(allLocs?.split(",")); //updating state to avoid empty locations after editing.
          setEditableIndex(null);
          setAddCompanyLocation(false);

          // fetchClientDetails();

          setFieledsChange(false);

          // setTimeout(() => {
          //   setShowClientTab("addclient");
          // }, 1500);
        }
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: error?.response?.data?.data,
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
        setIsLoading(false);
      });
  };

  const fetchClientDetails = async () => {
    // Check if any required field is empty

    const data = selectedClient;
    setCompanyType(data.companyType);
    setIndustryValue(data.industry);
    setSubIndustryValue(data.industry);
    setCompanySize(data.companySize);
    setNumberOfUsers(data.numberOfUsers);
    setParentCompany(data.parentCompany);
    setClientName(data.clientName);
    setSubIndustryValue(data.subIndustry);
    setClientHqLocation(data.clientHqLocation);
    setHqLocationAddress(data.hqLocationAddress);
    // setAllLocations(data.enterAllLocation);
    setTotalClientLocations(data.totalClientLocation);
    setClientEmail(data.clientPrimEmail);
    setClientPrimaryContactName(data.clientPrimContactName);
    setClientSecContactName(data.clientSecContactName);
    setClientPhoneNumber(data.clientContactNumber);
    setRipikPhoneNumber(data.ripikContactNumber);
    setRipikEmail(data.ripikContactEmail);
    setRipikSecContactName(data.ripikContactSecName);
    setRipikPrimaryContactName(data.ripikContactPrimName);
    setDealValue(data.dealValue);
    setPurchaseOrderCode(data.purchaseOrderCode);
    setRemarks(data.remarks);
    setProductDate(data.ripikProductUseDate);
    setRelationDate(data.clientRelStartDate);
    setClientOrg(data.organisation);
    setCardLogoPreview(data.logo32url);
    setDefaultIcon(data.logo32url);
    setCardLogo(data.logo32url);
    setRipikSecContactEmail(data.ripikContactSecEmail);
    setRipikSecContactNumber(data.ripikContactSecNumber);
    setClientSecContactEmail(data.clientSecEmail);
    setClientSecContactNumber(data.clientSecContactNumber);
  };

  const fetchClients = async () => {
    try {
      const response = await axios.get(baseURL + "iam/fetchClient", {
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });

      const clients = response?.data?.data;
      const clientData = clients?.map((client) => ({
        name: client.clientName,
        image: client.logo32url,
      }));
      setExistingClientNames(clientData);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDatePicker = (e, key) => {
    // console.log(e);
    setFieledsChange(true);
    if (key == "relationDate") {
      setRelationDate(e);
    } else {
      setProductDate(e);
    }
  };

  useEffect(() => {
    fetchClientDetails();
    fetchClients();
  }, [selectedClient]);

  useEffect(() => {
    const delay = 300; // Adjust the delay as needed
    const timeoutId = setTimeout(() => {
      if (searchTerm) {
        fetchSearchSuggestions(searchTerm);
      } else {
        setSearchSuggestions([]);
      }
    }, delay);

    return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount or searchTerm change
  }, [searchTerm]);

  // console.log("locations", locations);
  // console.log("alllocations", allLocations);

  // console.log(secSelectedCountryCodeClient);

  return (
    <div className="font-roboto flex flex-col gap-2">
      <div className="flex flex-col gap-3">
        <div className="rounded-lg flex flex-col gap-3 bg-white">
          <div className="flex justify-between mt-2">
            <p className="text-[#3E3C42] text-lg font-medium ">
              Company information
            </p>
            {/* <PrimaryButton text={"Deploy"} width={"fit-content"} onClick={()=>setDeployClicked(true)}/> */}
          </div>

          <div className="flex flex-col md:flex-row items-start md:items-center gap-4">
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Client name<span className="text-[red] ml-1">*</span>
              </p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="text"
                  value={clientName}
                  required
                  borderColor={
                    validationErrors?.clientName ? "red.500" : "gray.300"
                  }
                  borderWidth={validationErrors?.clientName ? "2px" : "2px"}
                  // borderWidth={"2px"}
                  disabled={review}
                  border={review ? "none" : ""}
                  onChange={(e) => {
                    setClientName(e.target.value);
                    setFieledsChange(true);
                    handleSearchTermChange(e);
                    setValidationErrors((prev) => ({
                      ...prev,
                      clientName: "",
                    }));
                  }}
                />
              </div>
              {validationErrors?.clientName && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.clientName}
                </p>
              )}
              {searchSuggestions?.length > 0 && (
                <div>
                  <div className="search-suggestions rounded-md mt-2 w-[400px]">
                    <p className="text-red-500 text-sm">
                      Similar client(s) already exist. Please check if your
                      client is duplicate
                    </p>
                  </div>
                  <div className="search-suggestions border bg-white rounded-md shadow-lg shadow-gray-200 mt-2 w-[500px] z-10">
                    <div className="p-2">
                      <p className="text-gray-400 mb-1">Existing clients</p>
                      <div className="grid grid-cols-2 gap-2">
                        {searchSuggestions?.map((item) => (
                          <div className="flex flex-row">
                            <img
                              className="w-[30px] h-[30px]"
                              src={
                                item.image
                                  ? item.image
                                  : "/superadmin/clients/companyLogo.png"
                              }
                              alt=""
                            />
                            <p className="font-medium text-lg ml-1">
                              {item.name}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Parent company{" "}
                {/* <span className="text-[#AEA9B1]">(if applicable)</span> */}
              </p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="text"
                  value={parentCompany}
                  borderColor={
                    validationErrors?.parentCompany ? "red.500" : "gray.300"
                  }
                  borderWidth={validationErrors?.parentCompany ? "2px" : "2px"}
                  disabled={review}
                  border={review ? "none" : ""}
                  onChange={(e) => {
                    setParentCompany(e.target.value);
                    setFieledsChange(true);
                  }}
                />
              </div>
              {validationErrors?.parentCompany && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.parentCompany}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col gap-3">
            <p className="text-[#3E3C42] text-lg font-medium">
              Company Type<span className="text-[red] ml-1">*</span>
            </p>

            <RadioGroup
              onChange={(e) => {
                setCompanyType(e);
                setFieledsChange(true);
              }}
              value={companyType}
            >
              <div
                className="flex flex-col md:flex-row items-start md:items-center gap-4"
                style={{ width: "fit-content" }}
              >
                {dataOptions?.companyType.type.map((x) => {
                  return (
                    <div
                      style={{
                        backgroundColor:
                          companyType == x ? "#DDEEFF80" : "#FFF",
                        borderRadius: "8px",
                        mb: "12px",
                      }}
                    >
                      <Radio
                        value={x}
                        py={"8px"}
                        pl={"8px"}
                        pr={"12px"}
                        fontSize={"14px"}
                        fontWeight={500}
                        color={"#3E3C42"}
                        _checked={{
                          bg: "#6CA6FC",
                          borderColor: "#6CA6FC",
                        }}
                        _hover={{
                          borderColor: "#6CA6FC",
                        }}
                        isDisabled={review}
                      >
                        {x}
                      </Radio>
                    </div>
                  );
                })}
              </div>
            </RadioGroup>
          </div>
          <div className="flex flex-col md:flex-row items-start md:items-center gap-4 mt-5">
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Industry
              </p>
              <div style={{ width: "fit-content", width: "225px" }}>
                {/* <Input
                  type="text"
                  value={industryValue}
                  required
                  borderColor={
                    "gray.300"
                  }
                  borderWidth={"2px"}
                  onChange={(e) => setIndustryValue(e.target.value)}
                /> */}

                <select
                  value={industryValue}
                  onChange={(e) => {
                    setIndustryValue(e.target.value);
                    setFieledsChange(true);
                  }}
                  borderColor={"gray.300"}
                  className="w-full p-2 border-[2px] rounded-lg max-h-[300px] overflow-y-auto"
                  disabled={review}
                >
                  <option value="" disabled selected>
                    Select one
                  </option>
                  {dataOptions.industry.type.map((value) => (
                    <option value={value}>{value}</option>
                  ))}
                </select>
              </div>
            </div>
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Sub-Industry
              </p>
              <div style={{ width: "fit-content", width: "225px" }}>
                <Input
                  type="text"
                  value={subIndustryValue}
                  borderColor={
                    validationErrors?.subIndustryValue ? "red.500" : "gray.300"
                  }
                  borderWidth={
                    validationErrors?.subIndustryValue ? "2px" : "2px"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                  onChange={(e) => {
                    setSubIndustryValue(e.target.value);
                    setFieledsChange(true);
                  }}
                />
              </div>
              {validationErrors?.subIndustryValue && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.subIndustryValue}
                </p>
              )}
            </div>
          </div>

          {/* <AddLogo
            logo={cardLogo}
            setLogo={setCardLogo}
            toast={toast}
            logoPreview={cardLogoPreview}
            setLogoPreview={setCardLogoPreview}
            submitClicked={submitClicked}
            desiredRatio={"2 : 2"}
            // text={"3 : 2 ratio , E.g: 1800px. x 1200px"}
            info={"1"}
            disable={review}
            setFieledsChange={setFieledsChange}
          /> */}
          <div className="mt-5">
            <p className="text-[#3E3C42] text-lg font-medium">
              Select Company logo
            </p>
            <ClientSelectLogo
              icon={icon}
              setIcon={setIcon}
              setCardLogo={setCardLogo}
              defaultIcon={defaultIcon}
              auth={auth}
              setLogoList={setLogoList}
              logoList={logoList}
              cardLogo={cardLogo}
              toast={toast}
              cardLogoPreview={cardLogoPreview}
              setCardLogoPreview={setCardLogoPreview}
              submitClicked={submitClicked}
              setFieledsChange={setFieledsChange}
              setDefaultIcon={setDefaultIcon}
            />
          </div>
        </div>
        <div className=" rounded-lg flex flex-col gap-3 bg-white">
          <p className="text-[#3E3C42] text-lg font-medium ">Company size</p>

          <div className="flex flex-col gap-3">
            <p className="text-[#79767D] text-sm font-medium mb-1">
              Company Size
            </p>
            <RadioGroup
              onChange={(e) => {
                setCompanySize(e);
                setFieledsChange(true);
              }}
              value={companySize}
            >
              <div
                className="flex flex-col lg:flex-row items-start lg:items-center gap-4"
                style={{ width: "fit-content" }}
              >
                {["Large", "Medium", "Small"].map((x) => (
                  <div key={x} className="flex items-center">
                    <div
                      style={{
                        backgroundColor:
                          companySize === x ? "#DDEEFF80" : "#FFF",
                        borderRadius: "8px",
                        mb: "12px",
                      }}
                    >
                      <Radio
                        value={x}
                        py={"8px"}
                        pl={"8px"}
                        pr={"12px"}
                        fontSize={"14px"}
                        fontWeight={500}
                        color={"#3E3C42"}
                        _checked={{
                          bg: "#6CA6FC",
                          borderColor: "#6CA6FC",
                        }}
                        _hover={{
                          borderColor: "#6CA6FC",
                        }}
                        isDisabled={review}
                      >
                        {x}
                      </Radio>
                    </div>
                    <span className="text-[#79767D] text-sm font-medium ml-2">
                      {x === "Large"
                        ? "(500+ employees)"
                        : x === "Medium"
                        ? "(50-100 employees)"
                        : "(<50 employees)"}
                    </span>
                  </div>
                ))}
              </div>
            </RadioGroup>
          </div>

          <div>
            <p className="text-[#79767D] text-sm font-medium mb-3">
              Number of users
            </p>
            <div style={{ width: "fit-content" }}>
              <Input
                type="number"
                value={numberOfUsers}
                borderColor={
                  validationErrors?.numberOfUsers ? "red.500" : "gray.300"
                }
                borderWidth={validationErrors?.numberOfUsers ? "2px" : "2px"}
                disabled={review}
                border={review ? "none" : ""}
                onChange={(e) => {
                  setNumberOfUsers(e.target.value);
                  setFieledsChange(true);
                }}
              />
            </div>
            {validationErrors?.numberOfUsers && (
              <p className="!mb-1 !text-[red] !text-sm">
                {validationErrors?.numberOfUsers}
              </p>
            )}
          </div>
        </div>
        <div className=" rounded-lg flex flex-col gap-3 bg-white">
          <p className="text-[#3E3C42] text-lg font-medium ">
            Company’s location
          </p>

          <div className="flex flex-col md:flex-row items-start md:items-center gap-4">
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Client HQ location
              </p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="text"
                  value={clientHqLocation}
                  borderColor={
                    validationErrors?.clientHqLocation ? "red.500" : "gray.300"
                  }
                  borderWidth={
                    validationErrors?.clientHqLocation ? "2px" : "2px"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                  onChange={(e) => {
                    setClientHqLocation(e.target.value);
                    setFieledsChange(true);
                  }}
                />
              </div>
              {validationErrors?.clientHqLocation && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.clientHqLocation}
                </p>
              )}
            </div>
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                HQ location address
                {/* <span className="text-[#AEA9B1]">(if applicable)</span> */}
              </p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="text"
                  value={hqLocationAddress}
                  width={{ base: "", lg: "516px" }}
                  borderColor={
                    validationErrors?.hqLocationAddress ? "red.500" : "gray.300"
                  }
                  borderWidth={
                    validationErrors?.hqLocationAddress ? "2px" : "2px"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                  onChange={(e) => {
                    setHqLocationAddress(e.target.value);
                    setFieledsChange(true);
                  }}
                />
              </div>
              {validationErrors?.hqLocationAddress && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.hqLocationAddress}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-start gap-4">
            <div>
              <div>
                {locations &&
                  locations?.map((location, index) => (
                    <>
                      <p className="text-[#79767D] text-sm font-medium mb-3">
                        Location {index + 1}
                      </p>
                      <div className="flex flex-col lg:flex-row lg:items-center">
                        <div
                          key={index}
                          className="flex flex-row items-center justify-between gap-1 mb-4 h-10"
                        >
                          <Input
                            type="text"
                            name={`location-${index}`}
                            value={location}
                            // border={"none"}
                            isReadOnly={editableIndex !== index}
                            onChange={(e) => {
                              handleLocationChange(e, index);
                              setFieledsChange(true);
                            }}
                            w={{ base: "", lg: "475px" }}
                            borderColor={"gray.300"}
                            bgColor={
                              editableIndex == index ? "none" : "gray.100"
                            }
                            borderWidth={"2px"}
                            color={editableIndex == index ? "none" : "#AEA9B1"}
                          />

                          {editableIndex !== index && review === false && (
                            <IconButton
                              aria-label="delete"
                              color="red.600"
                              bg={"none"}
                              _hover={{ bg: "none" }}
                              minW={10}
                              onClick={() => {
                                const updatedLocations = [...locations];
                                updatedLocations.splice(index, 1);
                                setLocations(updatedLocations);
                                setErrorAllLocations("");
                                setFieledsChange(true);
                              }}
                            >
                              {/* <DeleteIcon /> */}
                              <DeleteOutlineIcon sx={{color: "#E46962"}} />
                            </IconButton>
                          )}
                        </div>
                        <div className="flex items-center">
                          {editableIndex !== index && review === false && (
                            <IconButton
                              aria-label="update"
                              color="#3A74CA"
                              bg={"none"}
                              _hover={{ bg: "none" }}
                              mb="16px"
                              onClick={() => handleEditLocation(index)}
                            >
                              <EditNoteIcon />
                            </IconButton>
                          )}
                          {editableIndex === index ? (
                            <div className="ml-1 mb-4">
                              <TonalButton
                                text={"Save"}
                                width={"fit-content"}
                                onClick={() => {
                                  if (locations[index] != "") {
                                    //if field is not empty call the function
                                    handleSaveLocation(index);
                                  }
                                }}
                                disable={review}
                              />
                            </div>
                          ) : (
                            <p className="text-[#7AC958] text-sm ml-3 font-semibold mb-5">
                              Saved
                            </p>
                          )}
                        </div>
                      </div>
                    </>
                  ))}
                {(locations?.length == 0 || isAddCompanyLocation) && (
                  <>
                    <p className="text-[#79767D] text-sm font-medium mb-3">
                      Enter location{" "}
                      {locations?.length ? locations?.length + 1 : 1}
                      {locations?.length < 1 && (
                        <span className="text-[red] mx-1">*</span>
                      )}
                    </p>
                    <div className="flex flex-col md:flex-row md:items-center gap-4">
                      <Input
                        type="text"
                        // name="enterAllLocation"
                        // value={formData?.enterAllLocation}
                        onChange={(e) => {
                          setAllLocations(e.target.value);
                          setFieledsChange(true);

                          setErrorAllLocations("");
                          // setValidationErrors((prev) => ({
                          //   ...prev,
                          //   allLocations: "",
                          // }));
                        }}
                        // placeholder="loc1,loc2,..."
                        w={{ base: "", sm: "", lg: "524px" }}
                        borderColor={
                          // submitClicked && !allLocations
                          //   ? "red.500"
                          //   :
                          "gray.300"
                        }
                        borderWidth={"2px"}
                        disabled={review}
                        border={review ? "none" : ""}
                      />

                      <TonalButton
                        text={"Save"}
                        width={"fit-content"}
                        onClick={handleAddLocation}
                        disable={review}
                      />
                    </div>
                  </>
                )}
              </div>
              {validationErrors?.allLocations && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.allLocations}
                </p>
              )}
              {locations?.length > 0 && errorAllLocations && (
                <Text className="!mb-1 !text-[red] !text-sm">
                  {errorAllLocations}
                </Text>
              )}
            </div>
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3 ml-1">
                Total client locations{" "}
                <span className="text-[#AEA9B1] ml-1">(count) </span>{" "}
              </p>
              <div className="mt-5 ml-3">
                {locations.filter((location) => location !== "").length}
              </div>
            </div>
          </div>
          <PrimaryButton
            text={"Add another location"}
            width={"fit-content"}
            disable={review}
            onClick={handleAddCompanyLocation}
          />
        </div>
        <div className=" rounded-lg flex flex-col gap-3 bg-white">
          <p className="text-[#3E3C42] text-lg font-medium ">
            Client’s contact details
          </p>

          <div className="flex flex-col md:flex-row items-start md:items-center gap-4">
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Primary contact name
              </p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="text"
                  value={clientPrimaryContactName}
                  borderColor={
                    validationErrors?.clientPrimContactName
                      ? "red.500"
                      : "gray.300"
                  }
                  borderWidth={
                    validationErrors?.clientPrimContactName ? "2px" : "2px"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                  onChange={(e) => {
                    setClientPrimaryContactName(e.target.value);
                    setFieledsChange(true);
                  }}
                />
              </div>
              {(validationErrors?.clientPrimContactName ||
                !isContactNameValid(clientPrimaryContactName)) && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.clientPrimContactName}
                </p>
              )}
            </div>
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">E-mail</p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="email"
                  value={clientEmail}
                  borderColor={
                    validationErrors?.clientEmail ? "red.500" : "gray.300"
                  }
                  borderWidth={validationErrors?.clientEmail ? "2px" : "2px"}
                  disabled={review}
                  border={review ? "none" : ""}
                  onChange={(e) => {
                    setClientEmail(e.target.value);
                    setFieledsChange(true);
                  }}
                />
              </div>
              {(validationErrors?.clientEmail ||
                !isValidEmail(clientEmail)) && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.clientEmail}
                </p>
              )}
            </div>
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Phone number
              </p>
              <div className="">
                <div className="flex ">
                  <Select
                    // border={"none"}
                    w={"110px"}
                    value={selectedCountryCodeClient}
                    onChange={(e) => {
                      setSelectedCountryCodeClient(e.target.value);
                      setFieledsChange(true);
                    }}
                    isDisabled={review}
                  >
                    {countryCodes?.map((code) => (
                      <option key={code} value={code}>
                        {code}
                      </option>
                    ))}
                  </Select>

                  <Input
                    type="number"
                    value={clientPhoneNumber}
                    borderColor={
                      validationErrors?.clientPhoneNumber
                        ? "red.500"
                        : "gray.300"
                    }
                    borderWidth={
                      validationErrors?.clientPhoneNumber ? "2px" : "2px"
                    }
                    disabled={review}
                    border={review ? "none" : ""}
                    onChange={(e) => {
                      setClientPhoneNumber(e.target.value);
                      setFieledsChange(true);
                    }}
                  />
                </div>
              </div>
              {validationErrors?.clientPhoneNumber && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.clientPhoneNumber}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-start md:items-center gap-4">
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Secondary contact name{" "}
              </p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="text"
                  value={clientSecContactName}
                  borderColor={
                    validationErrors?.clientSecContactName
                      ? "red.500"
                      : "gray.300"
                  }
                  borderWidth={
                    validationErrors?.clientSecContactName ? "2px" : "2px"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                  onChange={(e) => {
                    setClientSecContactName(e.target.value);
                    setFieledsChange(true);
                  }}
                />
              </div>
              {(validationErrors?.clientSecContactName ||
                !isContactNameValid(clientSecContactName)) && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.clientSecContactName}
                </p>
              )}
            </div>
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Secondary contact E-mail{" "}
              </p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="email"
                  value={clientSecContactEmail}
                  borderColor={
                    validationErrors?.clientSecContactEmail
                      ? "red.500"
                      : "gray.300"
                  }
                  borderWidth={
                    validationErrors?.clientSecContactEmail ? "2px" : "2px"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                  onChange={(e) => {
                    setClientSecContactEmail(e.target.value);
                    setFieledsChange(true);
                  }}
                />
              </div>
              {(validationErrors?.clientSecContactEmail ||
                !isValidEmail(clientSecContactEmail)) && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.clientSecContactEmail}
                </p>
              )}
            </div>
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Secondary contact Phone number{" "}
              </p>
              <div style={{ width: "fit-content" }}>
                <div className="flex">
                  <Select
                    // border={"none"}
                    w={"110px"}
                    value={secSelectedCountryCodeClient}
                    onChange={(e) => {
                      setSecSelectedCountryCodeClient(e.target.value);
                      setFieledsChange(true);
                    }}
                    isDisabled={review}
                  >
                    {countryCodes?.map((code) => (
                      <option key={code} value={code}>
                        {code}
                      </option>
                    ))}
                  </Select>
                  <Input
                    type="number"
                    value={clientSecContactNumber}
                    borderColor={
                      validationErrors?.clientSecContactNumber
                        ? "red.500"
                        : "gray.300"
                    }
                    borderWidth={
                      validationErrors?.clientSecContactNumber ? "2px" : "2px"
                    }
                    disabled={review}
                    border={review ? "none" : ""}
                    onChange={(e) => {
                      setClientSecContactNumber(e.target.value);
                      setFieledsChange(true);
                    }}
                  />
                </div>
                {validationErrors?.clientSecContactNumber && (
                  <p className="!mb-1 !text-[red] !text-sm">
                    {validationErrors?.clientSecContactNumber}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className=" rounded-lg flex flex-col gap-3 bg-white">
          <p className="text-[#3E3C42] text-lg font-medium ">
            Ripik's contact details
          </p>

          <div className="flex flex-col md:flex-row items-start md:items-center gap-4">
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Primary contact name
              </p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="text"
                  value={ripikPrimaryContactName}
                  borderColor={
                    validationErrors?.ripikPrimaryContactName
                      ? "red.500"
                      : "gray.300"
                  }
                  borderWidth={
                    validationErrors?.ripikPrimaryContactName ? "2px" : "2px"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                  onChange={(e) => {
                    setRipikPrimaryContactName(e.target.value);
                    setFieledsChange(true);
                  }}
                />
              </div>
              {(validationErrors?.ripikPrimaryContactName ||
                !isContactNameValid(ripikPrimaryContactName)) && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.ripikPrimaryContactName}
                </p>
              )}
            </div>
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">E-mail</p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="text"
                  value={ripikEmail}
                  borderColor={
                    validationErrors?.ripikEmail ? "red.500" : "gray.300"
                  }
                  borderWidth={validationErrors?.ripikEmail ? "2px" : "2px"}
                  disabled={review}
                  border={review ? "none" : ""}
                  onChange={(e) => {
                    setRipikEmail(e.target.value);
                    setFieledsChange(true);
                  }}
                />
              </div>
              {(validationErrors?.ripikEmail || !isValidEmail(ripikEmail)) && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.ripikEmail}
                </p>
              )}
            </div>
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Phone number
              </p>

              <div className="flex ">
                <Select
                  w={"110px"}
                  value={selectedCountryCodeRipik}
                  onChange={(e) => {
                    setSelectedCountryCodeRipik(e.target.value);
                    setFieledsChange(true);
                  }}
                  isDisabled={review}
                >
                  {countryCodes.map((code) => (
                    <option key={code} value={code}>
                      {code}
                    </option>
                  ))}
                </Select>

                <Input
                  type="number"
                  value={ripikPhoneNumber}
                  borderColor={
                    validationErrors?.ripikPhoneNumber ? "red.500" : "gray.300"
                  }
                  borderWidth={
                    validationErrors?.ripikPhoneNumber ? "2px" : "2px"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                  onChange={(e) => {
                    setRipikPhoneNumber(e.target.value);
                    setFieledsChange(true);
                  }}
                />
              </div>
              {validationErrors?.ripikPhoneNumber && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.ripikPhoneNumber}
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-start md:items-center gap-4">
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Secondary contact name
              </p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="text"
                  value={ripikSecContactName}
                  borderColor={
                    validationErrors?.ripikSecContactName
                      ? "red.500"
                      : "gray.300"
                  }
                  borderWidth={
                    validationErrors?.ripikSecContactName ? "2px" : "2px"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                  onChange={(e) => {
                    setRipikSecContactName(e.target.value);
                    setFieledsChange(true);
                  }}
                />
              </div>
              {validationErrors?.ripikSecContactName && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.ripikSecContactName}
                </p>
              )}
            </div>
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Secondary contact E-mail
              </p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="email"
                  value={ripikSecContactEmail}
                  borderColor={
                    validationErrors?.ripikSecContactEmail
                      ? "red.500"
                      : "gray.300"
                  }
                  borderWidth={
                    validationErrors?.ripikSecContactEmail ? "2px" : "2px"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                  onChange={(e) => {
                    setRipikSecContactEmail(e.target.value);
                    setFieledsChange(true);
                  }}
                />
              </div>
              {(validationErrors?.ripikSecContactEmail ||
                !isValidEmail(ripikSecContactEmail)) && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.ripikSecContactEmail}
                </p>
              )}
            </div>
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Secondary contact Phone number
              </p>
              <div style={{ width: "fit-content" }}>
                <div className="flex">
                  <Select
                    // border={"none"}
                    w={"110px"}
                    value={secSelectedCountryCodeRipik}
                    onChange={(e) => {
                      setSecSelectedCountryCodeRipik(e.target.value);
                      setFieledsChange(true);
                    }}
                    isDisabled={review}
                  >
                    {countryCodes.map((code) => (
                      <option key={code} value={code}>
                        {code}
                      </option>
                    ))}
                  </Select>
                  <Input
                    type="number"
                    value={ripikSecContactNumber}
                    borderColor={
                      validationErrors?.ripikSecContactNumber
                        ? "red.500"
                        : "gray.300"
                    }
                    borderWidth={
                      validationErrors?.ripikSecContactNumber ? "2px" : "2px"
                    }
                    disabled={review}
                    border={review ? "none" : ""}
                    onChange={(e) => {
                      setRipikSecContactNumber(e.target.value);
                      setFieledsChange(true);
                    }}
                  />
                </div>

                {validationErrors?.ripikSecContactNumber && (
                  <p className="!mb-1 !text-[red] !text-sm">
                    {validationErrors?.ripikSecContactNumber}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className=" rounded-lg flex flex-col gap-3 bg-white">
          <p className="text-[#3E3C42] text-lg font-medium ">
            Client relation details
          </p>
          <div className="flex flex-col md:flex-row items-start md:items-center gap-4">
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Relation start date
              </p>
              <div>
                <FloatingInput
                  text=""
                  type="date"
                  setDateTime={(e) => handleDatePicker(e, "relationDate")}
                  value={relationDate}
                  disabled={review}
                />
              </div>
            </div>
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                First Ripik product use date
              </p>
              <div>
                <FloatingInput
                  text=""
                  type="date"
                  setDateTime={(e) => handleDatePicker(e, "productDate")}
                  value={productDate}
                  disabled={review}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Deal value
                {/* <span className="text-[#AEA9B1]">(optional)</span> */}
              </p>

              <div className="flex items-center">
                <Select
                  value={selectedCurrency}
                  onChange={(e) => {
                    setSelectedCurrency(e.target.value);
                    setFieledsChange(true);
                  }}
                  isDisabled={review}
                >
                  <option value="₹">₹ (In Lakhs, Optional)</option>
                  <option value="€">€ (In Thousands, Optional)</option>
                  <option value="$">$ (In Thousands, Optional)</option>
                </Select>
                <Input
                  type="number"
                  name="dealValue"
                  placeholder=""
                  value={dealValue}
                  // onChange={(e) => setDealValue(e.target.value)}
                  onChange={(e) => {
                    setDealValue(e.target.value);
                    setFieledsChange(true);
                  }}
                  borderColor={
                    validationErrors?.dealValue ? "red.500" : "gray.300"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                  borderWidth={validationErrors?.dealValue ? "2px" : "2px"}
                />
              </div>
              {validationErrors?.dealValue && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.dealValue}
                </p>
              )}
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                First purchase order code
              </p>
              <div style={{ width: "fit-content" }}>
                <Input
                  type="text"
                  value={purchaseOrderCode}
                  borderColor={
                    validationErrors?.purchaseOrderCode ? "red.500" : "gray.300"
                  }
                  disabled={review}
                  border={review ? "none" : ""}
                  borderWidth={
                    validationErrors?.purchaseOrderCode ? "2px" : "2px"
                  }
                  onChange={(e) => {
                    setPurchaseOrderCode(e.target.value);
                    setFieledsChange(true);
                  }}
                />
              </div>
              {validationErrors?.purchaseOrderCode && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.purchaseOrderCode}
                </p>
              )}
            </div>
          </div>
          <div className="flex items-center gap-4">
            <div>
              <p className="text-[#79767D] text-sm font-medium mb-3">
                Remarks / additional information
                {/* <span className="text-[#AEA9B1]">(optional)</span> */}
              </p>
              <div style={{ width: "" }}>
                <Textarea
                  style={{ width: "60vw" }}
                  value={remarks}
                  borderColor={
                    validationErrors?.remarks ? "red.500" : "gray.300"
                  }
                  borderWidth={validationErrors?.remarks ? "2px" : "2px"}
                  disabled={review}
                  border={review ? "none" : ""}
                  onChange={(e) => {
                    setRemarks(e.target.value);
                    setFieledsChange(true);
                  }}
                />
              </div>
              {validationErrors?.remarks && (
                <p className="!mb-1 !text-[red] !text-sm">
                  {validationErrors?.remarks}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex gap-[10px] items-center md:ml-0 mt-2 mb-20 md:mb-0">
        <div className="flex items-center gap-2 mb-5">
          {review === false ? (
            <TonalButton
              //disable={!showDiscard}
              text={"Save draft"}
              width={"130px"}
              onClick={() => {
                handleSubmit(0);
              }}
              disable={!isfieldsChanged}
            />
          ) : (
            <TonalButton
              //disable={!showDiscard}
              text={"Back to editing"}
              width={"130px"}
              onClick={() => {
                setReview(false);
                // handleInputsCheck();
              }}
              disable={""}
            />
          )}
          {review === false ? (
            <button
              className="bg-[#084298] rounded text-white text-sm"
              onClick={() => {
                setReview(true);
                // handleInputsCheck();
              }}
              style={{
                width: "125px",
                // height: "auto",
                padding: "8px 0px 8px 0px",
              }}
            >
              Review
            </button>
          ) : (
            <button
              className="bg-[#69B04B] rounded text-white text-sm"
              onClick={() => handleSubmit(1)}
              style={{
                width: "125px",
                // height: "auto",
                padding: "8px 0px 8px 0px",
              }}
            >
              {isLoading ? "Submitting..." : "Submit"}
            </button>
          )}
        </div>
        {isModalOpen && (
          <DraftModal
            isOpen={isModalOpen}
            handleSubmit={handleSubmit}
            handleConfirmDiscard={handleConfirmDiscard}
            setShowDraft={setIsModalOpen}
          />
        )}
      </div>
    </div>
  );
};

export default UpdateClient;
