import { SingleConfig } from "./SingleConfig";
// import { dummySingleConfigData } from "../services/data";
import { useState } from "react";
import { ConfigSkeleton } from "./LoadingState/ConfigSkeleton";
import { IconButton, Textarea, Tooltip, useToast } from "@chakra-ui/react";
import { Table, Tbody, Td, Th, Thead, Tr, Button } from "@chakra-ui/react";
import { InfoOutlined } from "@mui/icons-material";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import { postCommentApi } from "../services/biasRecommenderServices";
const data = {
  a: 124455,
  b: 18644,
  c: 1967567,
  d: 197971,
  e: 13127083
};

export const Config = ({
  currentConfigData,
  recommendedConfigData,
  currentConfigLoading,
  recommendedConfigLoading,
  prtData = null,
  currentConfigText = "Current Config",
  recommendedConfigText = "Recommended Config",
  isSlab
}) => {

  const toast = useToast();

  const [hover, setHover] = useState(false);
  const [addComment, setAddComment] = useState(false);
  const [commentValue, setCommentValue] = useState("");

  // console.log(currentConfigData);

  const postComment = async () => {
    console.log(currentConfigData?.plate_id, commentValue);
    const requestData = {
      plate_id: currentConfigData?.plate_id,
      upcoming_plate_comment: commentValue,
    }

    const response = await postCommentApi(requestData);

    if (response?.success) {
      toast({
        title: "Comment added Successfully",
        status: "success",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
    }

  }

  return (
    <div className="flex flex-col xl:flex-row w-full bg-white justify-evenly gap-4 rounded-xl pb-2">
      {currentConfigLoading ? (
        <ConfigSkeleton />
      ) : (
        <div className="w-full ">
          {/* Current Config */}
          <div className="flex flex-row items-center relative">
            <p className="font-medium text-xl px-2 py-3">{currentConfigText}</p>
            {prtData &&
              <IconButton size={"xs"} icon={<InfoOutlined fontSize={"small"} />} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)} />
            }

            {
              (hover && prtData) && (
                <div className="flex min-w-[200px] flex-col gap-1 rounded-md border pt-1 shadow-md absolute left-[200px] top-0"
                  style={{
                    border: "1px solid #E2E8F0",
                    backgroundColor: "white",
                  }}
                >
                  <p className="text-left px-4 text-base font-medium">PRT Indices</p>
                  <div className="overflow-x-scroll">
                    <Table size={"sm"} >
                      <Tbody>
                        {Object.keys(prtData)?.map((item, index) => (
                          <Tr
                            key={index}
                          // backgroundColor={
                          //   speedCurveBiasData?.[element]?.flag && "#F9DEDC"
                          // }
                          >
                            <Td
                              textAlign={"left"}
                              w={"70%"}
                              textTransform={"capitalize"}
                              // fontWeight={"semibold"}
                              fontSize={"13px"}
                            >
                              {item}
                            </Td>
                            <Td textAlign={"left"} className="text-[#1A202C]"  >
                              {prtData[item]}
                            </Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </div>
                </div>
              )
            }
          </div>

          {currentConfigData && currentConfigData !== -1 ? (
            <SingleConfig data={currentConfigData} isRecommended={false} />
          ) : (
            <div>
              {" "}
              {/* <p className="text-md text-gray-400 text-left px-2">
          No Current Config Found
        </p> */}
            </div>
          )}
        </div>
      )
      }
      {
        recommendedConfigLoading ? (
          <ConfigSkeleton />
        ) : (
          <div className="w-full">
            {/* Recommended Config */}
            <div className="flex flex-row items-center justify-between px-1 relative">
              <p className="font-medium text-xl px-2 py-3">{recommendedConfigText}</p>

              {/* //TODO: Commented for now as Needed */}

              {(currentConfigData?.plate_id != "-") && <SecondaryButton
                height={"32px"}
                width={"120px"}
                text={addComment ? "Close" : "Add Comment"}
                onClick={() => setAddComment(!addComment)}
              />}
              {
                (addComment && currentConfigData?.plate_id != "-") && <div className="flex min-w-[300px] p-2 flex-col gap-1 rounded-md border pt-1 shadow-md absolute top-[50px] right-[0px]"
                  style={{
                    border: "1px solid gray",
                    backgroundColor: "white",
                  }}
                >
                  <div className="flex flex-row justify-between w-full">
                    <p className="text-left px-2 text-base font-medium">Add Comment - {currentConfigData?.plate_id}</p>
                    <p className="cursor-pointer" onClick={() => setAddComment(false)}>x</p>
                  </div>

                  <hr />
                  <div className="min-w-[200px] flex flex-col gap-2 mt-1">
                    <div className="flex flex-row gap-2 w-full">
                      <div className=" bg-green-400 p-1 text-[white] rounded-md w-full text-center cursor-pointer hover:bg-green-500"  onClick={() => setCommentValue("Accept")}>
                        Accept
                      </div>
                      <div className="p-1 bg-red-400 rounded-md text-[white] w-full text-center cursor-pointer hover:bg-red-500" onClick={() => setCommentValue("Reject")}>
                        Reject
                      </div>
                    </div>
                    <Textarea
                      width={"full"}
                      placeholder="Add comment"
                      defaultValue={commentValue}
                      value={commentValue}
                      onChange={(e) => setCommentValue(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-row justify-end gap-1">
                    <Button colorScheme='blue' size='xs' onClick={() => postComment()}>
                      Submit
                    </Button>
                    {/* <Button colorScheme='red' size='xs' onClick = {() => setCommentValue("")}>
                      Reject
                    </Button> */}
                  </div>
                </div>
              }
            </div>
            {recommendedConfigData && recommendedConfigData !== -1 ? (
              <SingleConfig data={recommendedConfigData} isRecommended={true} />
            ) : (
              <div>
                {/* <p className="text-md text-gray-400 text-left px-2">
                No Recommendation Config Found
              </p> */}
              </div>
            )}
          </div>
        )
      }
    </div >
  );
};

// <div className="w-full flex items-center justify-center h-[50px] rounded-lg bg-white">
//       <p className="font-bold text-lg text-gray-400">No Data Found</p>
//     </div>
