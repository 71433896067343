import { Spinner } from "@chakra-ui/react";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  gridPaginatedVisibleSortedGridRowEntriesSelector,
  useGridApiRef,
} from "@mui/x-data-grid";
import NavContext from "../../NavContext";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { getAlertsDataApi } from "../services/flarestack.api";
import { dataGridSlotProps, generateAlertsColumns } from "../utilis/dataGrid";
import {
  CustomFooter,
  CustomStyledDataGrid,
} from "../../../util/MaterialDataGrid/CustomStyledDatagrid";
import { DetailModal } from "../components/DetailModal";
import { NoData, TableSkeleton } from "../components/Loading";

const Alerts = () => {
  // refs, params
  const { clientId } = useParams();
  const { auth } = useContext(NavContext);
  const gridApiRef = useGridApiRef();

  // state
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({ columns: null, tableData: null });
  const [modalData, setModalData] = useState({
    open: false,
    index: null,
    data: null,
  });
  const [filters, setFilters] = useState({
    fromTime: new Date(
      new Date().getTime() - 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000
    )
      .toISOString()
      .slice(0, 16),
    toTime: new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16),
  });

  const fetchAlertsData = async () => {
    try {
      const requestData = JSON.stringify({
        clientId: clientId,
        useCase: "FLARESTACK",
        startDate: new Date(filters.fromTime).getTime(),
        endDate: new Date(filters.toTime).getTime(),
        plantName: "chanderia",
        maxLimit: 8000,
      });
      const response = await getAlertsDataApi(auth, requestData, setLoading);
      const tempColumns = await generateAlertsColumns(
        response?.columns,
        handleDetail
      );
      setData((prev) => ({
        ...prev,
        columns: [...tempColumns],
        tableData: response?.data,
      }));
    } catch (error) {
      console.log(error);
      setData((prev) => ({
        ...prev,
        columns: null,
        tableData: null,
      }));
    }
  };

  const handleDetail = (params) => {
    const tempDisplayData =
      gridPaginatedVisibleSortedGridRowEntriesSelector(gridApiRef);
    const index = params.api.getRowIndexRelativeToVisibleRows(params.row.id);
    setModalData((prev) => ({
      ...prev,
      open: true,
      index,
      data: tempDisplayData,
    }));
  };

  useEffect(() => {
    fetchAlertsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // variables
  const dateLimits = {
    max: new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 16),
    minFromTime: "2023-01-01T00:00",
  };

  return (
    <div className="relative flex flex-col mt-5 md:mt-0 mb-10">
      <div className="right-0 left-0 absolute flex justify-center">
        <div className="flex md:flex-row flex-col items-center gap-2 bg-white shadow-md p-5 pr-6 pl-6 rounded-xl">
          <div>
            <FloatingInput
              text="From"
              type="datetime-local"
              value={filters.fromTime}
              setDateTime={(value) => {
                console.log(value, dateLimits.minFromTime);
                if (value > dateLimits.minFromTime) {
                  setFilters((prev) => ({ ...prev, fromTime: value }));
                }
              }}
              min={dateLimits.minFromTime}
              max={dateLimits.max}
            />
          </div>
          <div>
            <FloatingInput
              text="To"
              type="datetime-local"
              value={filters.toTime}
              setDateTime={(value) =>
                setFilters((prev) => ({ ...prev, toTime: value }))
              }
              min={filters.fromTime}
              max={dateLimits.max}
            />
          </div>
          <PrimaryButton
            // onClick={getAlertsData}
            text={loading ? <Spinner /> : "Show alerts"}
            height={"100%"}
            width={"110px"}
            disable={!filters.fromTime || !filters.toTime || loading}
          />
        </div>
      </div>
      <div className="flex flex-col justify-start gap-2 bg-white mt-[160px] md:mt-11 p-4 pt-[57px] rounded-xl">
        {loading ? (
          <TableSkeleton
            headers={["Header 1", "Header 2", "Header 3", "Header 4"]}
            rows={10}
            cellsPerRow={4}
          />
        ) : data?.columns && data?.tableData ? (
          <div
            className="border rounded-md w-full"
            style={{
              height:
                90 + Math.min(10, data?.tableData?.length || 0) * 45 + "px",
            }}
            id="tableContainer"
          >
            <CustomStyledDataGrid
              apiRef={gridApiRef}
              columns={data?.columns || []}
              rows={data?.tableData || []}
              rowHeight={45}
              columnHeaderHeight={45}
              disableColumnSelector={true}
              disableRowSelectionOnClick={true}
              pageSizeOptions={[10, 20, 50]}
              pagination={true}
              disableColumnReorder={true}
              initialState={{
                pagination: { paginationModel: { pageSize: 10, page: 0 } },
              }}
              getRowClassName={(params) =>
                params.indexRelativeToCurrentPage % 2 === 0
                  ? "bg-[#FAFAFA]"
                  : "bg-white"
              }
              slots={{
                footer: CustomFooter,
              }}
              sx={{
                "& .MuiDataGrid-columnHeaderTitle": {
                  whiteSpace: "normal",
                  lineHeight: "normal",
                },
              }}
              slotProps={dataGridSlotProps && dataGridSlotProps()}
              showColumnVerticalBorder
            />
          </div>
        ) : (
          <div className="w-full h-[350px]">
            <NoData />
          </div>
        )}
      </div>
      {modalData?.open && modalData?.index !== null && modalData?.data && (
        <DetailModal
          openModal={modalData.open}
          closeModal={() =>
            setModalData((prev) => ({
              ...prev,
              open: false,
              index: null,
              data: null,
            }))
          }
          data={modalData.data}
          index={modalData.index}
        />
      )}
    </div>
  );
};

export default Alerts;
