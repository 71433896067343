import PlantCard from "../SizingComponents/PlantCard";
import axios from "axios";
import { useState, useEffect, useContext } from "react";
import { baseURL } from "../../../index";
import NavContext from "../../NavContext";

import { Skeleton } from "@chakra-ui/react";
const Feed = ({ material, clientId, setPlantCamMap, setShowCrusherTab }) => {
  const [loading, setLoading] = useState(true);
  const [plantData, setPlantData] = useState("noPlant");
  const [thresholds, setThresholds] = useState(null);
  const { auth } = useContext(NavContext);
  const apiCall = async () => {
    const requestData = JSON.stringify({
      clientId: clientId,
      material: material,
      cameraId: "all",
      plantName: "all",
    });
    const response = await axios
      .post(baseURL + "vision/v2/sizing/analysis/overview/", requestData, {
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      })
      .then((response) => {
        setLoading(false);
        setPlantData(response.data.plants);
        if ("thresholds" in response?.data)
          setThresholds(response?.data?.thresholds);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    if (plantData !== "noPlant") {
      const plantCamMap = {};
      Object.keys(plantData ?? {}).map((plant) => {
        plantCamMap[plant] = Object.keys(plantData[plant][material]);
      });
      const sortedData = Object.entries(plantCamMap)
        .sort((a, b) => b[1].length - a[1].length)
        .reduce((acc, [key, value]) => {
          acc[key] = value;
          return acc;
        }, {});
      setPlantCamMap(sortedData);
    }

    const camData = Object.keys(plantData)?.map(
      (plant) => plantData[plant][material]
    );
    let flag = false;
    for (let key in camData[0]) {
      for (let el of camData?.[0]?.[key]) {
        if (el?.showCrusher) {
          setShowCrusherTab(true);
          flag = true;
          break;
        }
      }
      if (flag) {
        break;
      }
    }
  }, [plantData]);

  useEffect(() => {
    setLoading(true);
    apiCall();
    const intervalId = setInterval(() => {
      apiCall();
    }, 30000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);
  console.log(plantData, "plantData");
  return (
    <div className="grid grid-cols-2 gap-4">
      {loading ? (
        <div className="w-full col-span-2 border h-[83vh] rounded-lg md:h-[53vh] flex md:flex-row flex-col justify-center p-3 gap-3  ">
          {[1, 2].map(() => {
            return (
              <div className="w-full h-full border  rounded-lg">
                <Skeleton w={"100%"} h={"100%"} />
              </div>
            );
          })}
        </div>
      ) : (
        (plantData == undefined || plantData == "noPlant") && (
          <div className="w-full col-span-2 h-full flex justify-center   border-2 border-[#3A74CA] rounded-lg border-dashed">
            <div className="flex flex-col w-[200px] h-[43vh] md:w-[250px] md:h-[63vh] gap-2  justify-center items-center">
              <img src="/SizingIcons/nodata.svg" />
              <p className="text-[#084298] font-semibold">No data Found </p>
            </div>
          </div>
        )
      )}
      {plantData &&
        plantData !== "noPlant" &&
        Object.keys(plantData).map((plant) => {
          return (
            <PlantCard
              PlantName={plant}
              CamData={plantData[plant][material]}
              thresholds={thresholds}
            />
          );
        })}
    </div>
  );
};

export default Feed;
