import { SvgIcon } from "@mui/material";

export const FlameIcon = ({ color = "#E46962", ...props }) => {
  return (
    <SvgIcon {...props}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g clip-path="url(#clip0_8_842)">
          <path
            d="M8.47137 23.9685C8.70581 24.0931 8.9531 23.8232 8.80957 23.5999C8.03051 22.3874 7.29278 20.347 8.46816 17.7194C10.4282 13.3375 11.6209 11.068 11.6209 11.068C11.6209 11.068 12.2553 13.7165 13.975 16.0714C15.6301 18.3378 16.5361 21.1882 15.0756 23.5627C14.9385 23.7856 15.1802 24.0485 15.4131 23.9294C17.221 23.0046 19.2487 21.1496 19.4774 17.4581C19.5618 16.3301 19.4353 14.7509 18.8027 12.758C17.9893 10.2323 16.9894 9.05377 16.4108 8.54765C16.2378 8.39624 15.9686 8.53043 15.9828 8.75992C16.1514 11.4852 15.1262 12.1768 14.5426 10.6182C14.3095 9.99569 14.1736 8.91898 14.1736 7.60776C14.1736 5.42482 13.5403 3.17768 12.144 1.35164C11.7809 0.876772 11.356 0.433046 10.8686 0.0546528C10.6921 -0.0823671 10.4363 0.0553586 10.4525 0.278183C10.5597 1.75803 10.4626 5.99917 6.74172 11.066C3.36803 15.7661 4.67533 19.3757 5.13921 20.3533C6.02611 22.2262 7.26322 23.3261 8.47137 23.9685Z"
            fill={color}
          />
        </g>
        <defs>
          <clipPath id="clip0_8_842">
            <rect
              width="24"
              height="24"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export const NoFlameIcon = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="44"
        height="44"
        viewBox="0 0 44 44"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_197_1038)">
          <path
            d="M19.4714 31.9685C19.7058 32.0931 19.9531 31.8232 19.8096 31.5998C19.0305 30.3873 18.2928 28.347 19.4682 25.7194C21.4282 21.3375 22.6209 19.068 22.6209 19.068C22.6209 19.068 23.2553 21.7165 24.975 24.0714C26.6301 26.3378 27.5361 29.1882 26.0756 31.5627C25.9385 31.7856 26.1802 32.0485 26.4131 31.9293C28.221 31.0045 30.2487 29.1495 30.4774 25.4581C30.5618 24.3301 30.4353 22.7508 29.8027 20.758C28.9893 18.2323 27.9894 17.0537 27.4108 16.5476C27.2378 16.3962 26.9686 16.5304 26.9828 16.7599C27.1514 19.4852 26.1262 20.1767 25.5426 18.6182C25.3095 17.9957 25.1736 16.919 25.1736 15.6077C25.1736 13.4248 24.5403 11.1776 23.144 9.3516C22.7809 8.87674 22.356 8.43302 21.8686 8.05462C21.6921 7.9176 21.4363 8.05533 21.4525 8.27815C21.5597 9.758 21.4626 13.9991 17.7417 19.066C14.368 23.766 15.6753 27.3756 16.1392 28.3533C17.0261 30.2262 18.2632 31.3261 19.4714 31.9685Z"
            fill="#79767D"
          />
        </g>
        <mask
          id="mask0_197_1038"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="44"
          height="44"
        >
          <rect
            width="44"
            height="44"
            fill="#D9D9D9"
          />
        </mask>
        <g mask="url(#mask0_197_1038)">
          <path
            d="M22 40.3333C19.4639 40.3333 17.0806 39.8521 14.85 38.8896C12.6195 37.9271 10.6792 36.6208 9.02919 34.9708C7.37919 33.3208 6.07294 31.3806 5.11044 29.15C4.14794 26.9195 3.66669 24.5361 3.66669 22C3.66669 19.4639 4.14794 17.0806 5.11044 14.85C6.07294 12.6194 7.37919 10.6792 9.02919 9.02917C10.6792 7.37917 12.6195 6.07292 14.85 5.11042C17.0806 4.14792 19.4639 3.66667 22 3.66667C24.5361 3.66667 26.9195 4.14792 29.15 5.11042C31.3806 6.07292 33.3209 7.37917 34.9709 9.02917C36.6209 10.6792 37.9271 12.6194 38.8896 14.85C39.8521 17.0806 40.3334 19.4639 40.3334 22C40.3334 24.5361 39.8521 26.9195 38.8896 29.15C37.9271 31.3806 36.6209 33.3208 34.9709 34.9708C33.3209 36.6208 31.3806 37.9271 29.15 38.8896C26.9195 39.8521 24.5361 40.3333 22 40.3333ZM22 36.6667C23.65 36.6667 25.2389 36.3993 26.7667 35.8646C28.2945 35.3299 29.7 34.5583 30.9834 33.55L10.45 13.0167C9.44169 14.3 8.67016 15.7056 8.13544 17.2333C7.60071 18.7611 7.33335 20.35 7.33335 22C7.33335 26.0945 8.75419 29.5625 11.5959 32.4042C14.4375 35.2458 17.9056 36.6667 22 36.6667ZM33.55 30.9833C34.5584 29.7 35.3299 28.2945 35.8646 26.7667C36.3993 25.2389 36.6667 23.65 36.6667 22C36.6667 17.9056 35.2459 14.4375 32.4042 11.5958C29.5625 8.75417 26.0945 7.33334 22 7.33334C20.35 7.33334 18.7611 7.6007 17.2334 8.13542C15.7056 8.67014 14.3 9.44167 13.0167 10.45L33.55 30.9833Z"
            fill="#AEA9B1"
          />
        </g>
        <defs>
          <clipPath id="clip0_197_1038">
            <rect
              width="24"
              height="24"
              fill="white"
              transform="translate(11 8)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};
