export const formatDateTimeUTC = (epochTimestamp) => {
  const isMilliseconds = epochTimestamp >= 1000000000000;

  const date = new Date(
    isMilliseconds ? epochTimestamp : epochTimestamp * 1000
  );

  const formattedDate = date.toLocaleDateString("en-IN", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour12: false,
    timeZone: "UTC",
  });

  const formattedTime = date.toLocaleTimeString("en-IN", {
    hour12: false,
    timeZone: "UTC",
  });

  return `${formattedDate} ${formattedTime}`;
};

export const formatDateTimeLocale = (epochTimestamp) => {
  const isMilliseconds = epochTimestamp >= 1000000000000;

  const date = new Date(
    isMilliseconds ? epochTimestamp : epochTimestamp * 1000
  );
  const formattedDate = date.toLocaleDateString(undefined, {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour12: false,
  });
  const formattedTime = date.toLocaleTimeString(undefined, {
    hour12: false,
  });
  return `${formattedDate} ${formattedTime}`;
};

export const formatForDateTimePickerLocale = (date) => {
  // multiply by 60000 cos getTimezoneOffset returns the difference in minutes
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, 19);
};

export const capitalizeCamelCaseString = (input) => {
  if (typeof input !== "string") {
    throw new Error("Input should be a string");
  }

  const words = input.split(/(?=[A-Z])|(\(.+?\))/).filter(Boolean);

  const capitalizedWords = words
    .map((word) => {
      if (word.startsWith("(") && word.endsWith(")")) {
        return word;
      } else {
        return word.charAt(0).toUpperCase() + word.slice(1);
      }
    })
    .join(" ");

  return capitalizedWords;
};

export const convertToCamelCase = (str) => {
  let words = str.split(/[^a-zA-Z0-9]+/);
  words = words.map((word, index) =>
    index === 0
      ? word.toLowerCase()
      : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );
  return words.join("");
};
