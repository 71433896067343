import ReactApexCharts from "react-apexcharts";

const FuelSuctionChart = ({
  series,
  colors = ["#084298"],
  annotations = {},
  yTitle,
  xAxis,
  height = "200px",
}) => {
  //chart options
  const options = {
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: function (y) {
          return y?.toFixed(2);
        },
      },
      theme: "dark",
      fillSeriesColor: true,
      style: {
        fontSize: "16px",
      },
    },
    chart: {
      type: "line",
      toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: false,
          selection: false,
          zoom: true,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: true,
        },
      },
      animations: {
        enabled: false,
      },
    },
    annotations: annotations,
    xaxis: xAxis,
    yaxis: {
      title: {
        text: yTitle,
        style: {
          fontSize: "14px",
          color: "#3E3C42",
          fontWeight: 500,
          fontFamily: "Poppins",
        },
      },
      labels: {
        formatter: function (value) {
          return value?.toFixed(2);
        },
      },
    },
    colors: colors,
    stroke: {
      curve: "straight",
      width: 3,
    },
    grid: {
      borderColor: "#e7e7e7",
      row: {
        colors: ["#FAFAFA", "transparent"],
        opacity: 1,
      },
    },
    legend: {
      show: true,
      offsetY: 15,
      offsetX: 0,
      position: "right",
      fontSize: "12px",
    },
  };

  return (
    <ReactApexCharts
      options={options}
      series={series}
      type="line"
      height={height}
      width="100%"
    />
  );
};

export default FuelSuctionChart;
