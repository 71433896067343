import { useToast, Input, Skeleton } from "@chakra-ui/react";
import React, { useEffect, useRef, useState, forwardRef } from "react";
import {
  Autocomplete,
  TextField,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import ReactPlayer from "react-player";

const AutoComp = forwardRef(
  ({ setInvalidModel, invalidModel, userData, disable }, ref) => {
    const MuiTheme = createTheme();
    const [value, setValue] = useState(userData?.assignedProject);
    useEffect(() => {
      setValue(userData?.assignedProject);
    }, [userData]);
    return (
      <ThemeProvider theme={MuiTheme}>
        <Autocomplete
          onChange={(event, newValue) => {
            setValue(newValue);
            ref.current = newValue;
            if (invalidModel) setInvalidModel(false);
          }}
          value={value}
          disablePortal
          disabled={disable}
          id="combo-box-demo"
          options={["", "Dummy project1", "Dummy project2", "Dummy project3"]}
          size="small"
          sx={{ width: "300px" }}
          renderInput={(params) => (
            <TextField {...params} label="Select project to be assigned" />
          )}
        />
      </ThemeProvider>
    );
  }
);

const MemoizedReactPlayer = React.memo(({ userData }) => {
  const playerWrapper = ({ children }) => (
    <div className="max-w-full sm:max-w-[60%] rounded-lg relative">
      {children}
      <p
        className="absolute top-4 left-4 text-base font-medium text-white px-2 py-1 rounded-[32px] bg-[#AEA9B1]"
        style={{ boxShadow: " 0px 4px 4px 0px rgba(0, 0, 0, 0.10)" }}
      >
        Live feed from {userData?.name}
      </p>
    </div>
  );

  return (
    <ReactPlayer
      url={
        "https://intelliverse-demo-new.s3.ap-south-1.amazonaws.com/Self+service.mp4"
      }
      volume={0}
      muted
      controls
      height={"auto"}
      wrapper={playerWrapper}
    />
  );
});

const CamModelAssign = ({
  userData,
  setUserData,
  activeStep,
  setActiveStep,
  viewMode = false,
  show,
}) => {
  const [disable, setDisable] = useState(viewMode);
  const [validated, setValidated] = useState(viewMode);
  const [invalidModel, setInvalidModel] = useState(false);
  const projectRef = useRef();
  const navigate = useNavigate();
  const probRef = useRef();
  const toast = useToast();

  const handleSave = () => {
    if (!probRef?.current?.value) {
      toast({
        title: "Error",
        description: "Please select minimum probability",
        status: "error",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
      return;
    }
    setUserData((prev) => ({
      ...prev,
      assignedProject: projectRef?.current,
      minProb: probRef?.current?.value,
    }));
    setDisable(true);
    navigate(`/Sandbox/Cam/View/1234?page=1`);
  };

  const handleValidate = () => {
    if (!projectRef?.current) {
      toast({
        title: "Error",
        description: "Please select project to validate",
        status: "error",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
      setInvalidModel(true);
      return;
    } else {
      toast({
        title: "Validated",
        description: "Your camera has been validated",
        status: "success",
        position: "top-right",
        duration: 2000,
        isClosable: true,
      });
      setValidated(true);
    }
  };

  return (
    <div
      className={`flex flex-col items-start justify-between gap-8 p-6 pb-[80px] md:pb-6 bg-white rounded-lg transition-all ease-in duration-700 ${
        show ? "opacity-100" : "opacity-0"
      }`}
      id="step2"
    >
      <p className="text-[#3E3C42] text-lg font-medium">Live Feed</p>
      <div className="flex flex-col gap-6">
        <MemoizedReactPlayer userData={userData} />
        {/*select project */}
        <div className="flex flex-col gap-3">
          <p className="text-sm text-[#2660B6] font-medium">
            Select Project to link Model with Above Camera Feed
            <span className="text-[#DC362E]">*</span>
          </p>
          <AutoComp
            invalidModel={invalidModel}
            setInvalidModel={setInvalidModel}
            ref={projectRef}
            userData={userData}
            disable={disable}
          />
          {invalidModel && (
            <p className="text-[#AEA9B1] text-sm">
              Please select the compatible Project and Validate again to check
            </p>
          )}
        </div>
        {/*select probability */}
        {validated && (
          <div className="flex flex-col gap-3">
            <p className="text-sm text-[#2660B6] font-medium">
              Enter Minimum Probability
              <span className="text-[#DC362E]">*</span>
            </p>
            <Input
              width={"300px"}
              name="minProb"
              defaultValue={userData?.minProb}
              ref={probRef}
              onChange={(e) => {
                probRef.current.value =
                  e.target.value > 1
                    ? 1
                    : e.target.value < 0
                    ? 0
                    : e.target.value;
              }}
              min="0"
              max="1"
              type="number"
              isDisabled={disable}
              placeholder="Enter between 1 and 0"
            />
          </div>
        )}
      </div>
      <div className="flex gap-2 items-center">
        {!validated ? (
          <PrimaryButton
            text={"Validate Project"}
            width={"fit-content"}
            onClick={handleValidate}
            disable={disable || invalidModel}
          />
        ) : (
          <PrimaryButton
            text={"Start Inference"}
            width={"fit-content"}
            onClick={handleSave}
            disable={disable}
          />
        )}
      </div>
    </div>
  );
};

export default CamModelAssign;
