import {
  Table,
  Td,
  Tr,
  Thead,
  Tbody,
  TableContainer,
  Th,
} from "@chakra-ui/react";
import ReportTableRows from "./ReportTableRows";

const ReportTable = ({ rowData }) => {
  const columns = [
    "SR No.",
    "Start date",
    "End date",
    "Smelter",
    "Batch No.",
    "Total Weight (MT)",
    "Alerts",
  ];
  return (
    <TableContainer
      className="w-full !overflow-y-auto max-h-[45vh]"
      rounded={"8px"}
    >
      <Table variant="simple">
        <Thead
          className="bg-[#DEF] !text-xs !sticky !top-0"
          style={{ zIndex: 10 }}
        >
          <Tr>
            {columns.map((id, idx) => {
              return (
                <Th
                  key={idx}
                  color="#79767D"
                  fontWeight={400}
                  p={"12px"}
                  textAlign={"center"}
                  w={150}
                >
                  {id.toUpperCase()}
                </Th>
              );
            })}
            <Th
              color="#79767D"
              fontWeight={400}
              p={0}
              px={"12px"}
              flex={1}
              minW={100}
              textAlign={"center"}
            >
              {" "}
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {rowData.map((item, idx) => {
            return (
              <ReportTableRows rowData={item} index={idx} headers={columns} />
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ReportTable;
