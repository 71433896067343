import ReactApexChart from "react-apexcharts";
import { useState, useEffect } from "react";

const CalMax = (data) => {
  let values = data?.map((item) => {
    return item?.precision;
  });
  return Math.max(...values);
};

const LabelScatterPlot = ({ data }) => {
  const [series, setSeries] = useState([]);
  useEffect(() => {
    setSeries((prev) => {
      let d = [];
      data.forEach((item) => {
        let val;
        if (!item.hasOwnProperty("annotationData"))
          val = (item.precision * 100)?.toFixed(2);
        else val = (CalMax(item.annotationData) * 100)?.toFixed(2);
        let idx = Math.ceil(val / 10) - 1;
        d.push([parseFloat(val), parseFloat(val)]);
      });
      let newData = [
        {
          name: "points",
          data: d,
        },
      ];
      return newData;
    });
  }, [data]);

  const options = {
    chart: {
      type: "scatter",
      zoom: {
        type: "xy",
      },
      toolbar: {
        tools: {
          download: true,
          selection: false,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: [],
        },
      },
    },
    xaxis: {
      labels: {
        formatter: function (val) {
          return parseFloat(val).toFixed(2) + "%";
        },
        maxHeight: 60
      },
      tickAmount: 10,
      title:{
        text: 'Confidence score range'
      }
    },
    yaxis: {
      labels: {
        formatter: function (val) {
          return parseFloat(val).toFixed(0) + "%";
        },
      },
      title: {
        text: 'Confidence'
      },
      min: 0,
      max: 100,
    },
  };

  return (
    <ReactApexChart
      series={series}
      options={options}
      type="scatter"
      height="200px"
      width="100%"
    />
  );
};

export default LabelScatterPlot;
