import { useWindowSize } from "@uidotdev/usehooks";
import { useContext, useEffect, useRef, useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  SkeletonText,
} from "@chakra-ui/react";
import { ChevronLeft, ChevronRight, Close } from "@mui/icons-material";
import { formatDateTimeLocale } from "../utilis/utilityFunctions";
import { FlareStackContext } from "../context/FlareStackContext";
import { StatusCard } from "../tabs/Feed";
import { getDetailModalDataApi } from "../services/flarestack.api";
import NavContext from "../../NavContext";
import { DetailModalSkeleton } from "./Loading";
import { FlareStackLineChart } from "./CommanCharts";
import { FeedbackModal } from "./FeedbackModal";

const VideoComponent = ({ videoUrl, cameraName }) => {
  return (
    <div className="flex flex-col w-full h-full">
      <div className="relative w-full h-full">
        <video
          crossOrigin="anonymous"
          controls
          muted
          className="rounded-lg h-full w-full"
        >
          <source
            src={videoUrl}
            type="video/mp4"
          />
        </video>
        <div className="top-0 left-0 absolute flex flex-row justify-start items-center px-1 py-1 w-full text-sm">
          <p className="bg-white px-1 rounded">{cameraName}</p>
        </div>
      </div>
    </div>
  );
};

const Footer = ({ setopenFeedback }) => {
  return (
    <div className="flex justify-end pb-2 md:pb-1 w-full">
      <div className="flex flex-col items-end gap-1 md:gap-0">
        <p className="text-[#605D64] text-base">Noticed incorrect data?</p>
        <p
          className="hover:opacity-60 font-semibold text-[#084298] text-base cursor-pointer"
          onClick={() => setopenFeedback(true)}
        >
          Give us feedback
        </p>
      </div>
    </div>
  );
};

export const DetailModal = ({ openModal, closeModal, data, index }) => {
  const indexRef = useRef();
  const idRef = useRef();
  const { initialData } = useContext(FlareStackContext);
  const { auth } = useContext(NavContext);
  const [modalData, setModalData] = useState(null);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [loading, setLoading] = useState(true);
  const [openFeedback, setopenFeedback] = useState(false);
  const size = useWindowSize();

  const fetchData = async () => {
    try {
      const requestData = {};
      const response = await getDetailModalDataApi(
        auth,
        requestData,
        setLoading
      );
      let tempSelectedCamera = initialData?.find(
        (el) => el?.cameraGpId === response?.cameraGpId
      );
      if (tempSelectedCamera) {
        setSelectedCamera((prev) => tempSelectedCamera);
        setModalData((prev) => response);
      } else {
        throw new Error("Incorrect Camera GP Id");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const toggleMove = (val) => {
    //index -1
    let x = indexRef.current;
    if (val === 1) {
      indexRef.current = x > 0 ? x - 1 : data.length - 1;
    }
    //index +1
    else if (val === 2) {
      indexRef.current = x < data.length - 1 ? x + 1 : 0;
    }

    let idx = indexRef.current;
    idRef.current = data[idx]?.model?.id;
    fetchData();
  };

  useEffect(() => {
    if (idRef.current !== data[index]?.model?.id) {
      indexRef.current = index;
      idRef.current = data[index]?.model?.id;
      fetchData();
    }
  }, [data, index]);

  return (
    <>
      {size.width >= 768 ? (
        <Modal
          isOpen={openModal}
          onClose={closeModal}
          isCentered="true"
        >
          <ModalOverlay />
          <ModalContent
            style={{ borderRadius: "12px" }}
            maxW={["90vw", "90vw", "95vw", "95vw", "95vw"]}
            height={"75vh"}
          >
            <ModalHeader padding="0px">
              <div className="flex justify-between items-center bg-[#F5F5F5] py-2 pr-2 pl-6 rounded-tl-xl rounded-tr-xl">
                <div className="flex flex-row items-center gap-5 px-2 w-full">
                  {loading ? (
                    <SkeletonText
                      noOfLines={1}
                      width={"200px"}
                    />
                  ) : (
                    <p className="font-medium text-base">
                      {selectedCamera?.cameraGpName}
                    </p>
                  )}
                  {loading ? (
                    <SkeletonText
                      noOfLines={1}
                      width={"200px"}
                    />
                  ) : (
                    <p className="font-medium text-[#79767D] text-sm">
                      Start Time{" "}
                      <span className="font-medium text-black text-sm">
                        {modalData?.startTime &&
                          formatDateTimeLocale(modalData?.startTime)}
                      </span>
                    </p>
                  )}
                  {loading ? (
                    <SkeletonText
                      noOfLines={1}
                      width={"200px"}
                    />
                  ) : (
                    <p className="font-medium text-[#79767D] text-sm">
                      End Time{" "}
                      <span className="font-medium text-black text-sm">
                        {modalData?.endTime &&
                          formatDateTimeLocale(modalData?.endTime)}
                      </span>
                    </p>
                  )}
                </div>
                <IconButton
                  backgroundColor={"transparent"}
                  cursor={"pointer"}
                  icon={<Close sx={{ color: "gray" }} />}
                  onClick={closeModal}
                />
              </div>
            </ModalHeader>
            <ModalBody
              px="0px"
              w="full"
              h="full"
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              gap={2}
              overflow={"hidden"}
            >
              {data.length > 1 && (
                <IconButton
                  bg="transparent"
                  rounded="full"
                  size="sm"
                  boxShadow="md"
                  icon={<ChevronLeft />}
                  onClick={() => toggleMove(1)}
                  className="z-10"
                />
              )}

              {loading ? (
                <DetailModalSkeleton />
              ) : (
                <div className="flex flex-col gap-2 w-full h-full overflow-auto">
                  <div className="flex flex-row gap-5 w-full h-[60%]">
                    <div className="w-[30%] h-full">
                      <StatusCard gasFlowRate={modalData?.gasFlowRate} />
                    </div>
                    <div
                      className={`w-[70%] h-full  rounded-md bg-white border`}
                    >
                      <div className="flex flex-row justify-between items-center px-2 pt-1 pb-0 w-full h-10">
                        <p className="font-medium text-[#525056]">{`Trend Graph`}</p>
                      </div>
                      <FlareStackLineChart
                        data={modalData?.lineChartData?.data}
                        times={modalData?.lineChartData?.times}
                      />
                    </div>
                  </div>
                  <div className="gap-2 grid grid-cols-1 md:grid-cols-4 w-full h-[35%]">
                    {selectedCamera?.cameraInfo?.map((el) => (
                      <VideoComponent
                        key={el.cameraId}
                        cameraName={el.name}
                        videoUrl={modalData?.[el.cameraId]?.video}
                      />
                    ))}
                  </div>
                </div>
              )}

              {data.length > 1 && (
                <IconButton
                  bg="transparent"
                  rounded="full"
                  size="sm"
                  boxShadow="md"
                  icon={<ChevronRight />}
                  onClick={() => toggleMove(2)}
                  className="z-10"
                />
              )}
            </ModalBody>

            <ModalFooter paddingY={0}>
              {loading ? (
                <Skeleton
                  height={"30"}
                  w={"full"}
                />
              ) : (
                <Footer setopenFeedback={setopenFeedback} />
              )}
            </ModalFooter>
          </ModalContent>
        </Modal>
      ) : (
        <Drawer
          onClose={closeModal}
          isOpen={openModal}
          size="full"
          isFullHeight={true}
        >
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader
              backgroundColor={"#F5F5F5"}
              paddingX={0}
              paddingY={1}
            >
              <div className="flex justify-between items-center px-1">
                <div className="flex flex-col items-start px-2 w-full">
                  {loading ? (
                    <SkeletonText
                      noOfLines={1}
                      width={"200px"}
                    />
                  ) : (
                    <>
                      {" "}
                      <p className="font-medium text-base">
                        {selectedCamera?.cameraGpName}
                      </p>
                      <div className="">
                        <div className="flex flex-row gap-2">
                          <p className="font-medium text-[#79767D] text-sm">
                            Start Time
                          </p>
                          <p className="font-medium text-black text-sm">
                            {modalData?.startTime &&
                              formatDateTimeLocale(modalData?.startTime)}
                          </p>
                        </div>
                        <div className="flex flex-row gap-2">
                          <p className="font-medium text-[#79767D] text-sm">
                            End Time
                          </p>
                          <p className="font-medium text-black text-sm">
                            {modalData?.endTime &&
                              formatDateTimeLocale(modalData?.endTime)}
                          </p>
                        </div>
                      </div>
                      {/* <p className="font-medium text-[#79767D] text-sm">
                        <span className="font-medium text-black text-sm">
                          {modalData?.timeStamp &&
                            formatDateTimeLocale(modalData?.timeStamp)}
                        </span>
                      </p> */}
                    </>
                  )}
                </div>
                <IconButton
                  backgroundColor={"transparent"}
                  cursor={"pointer"}
                  icon={<Close sx={{ color: "gray" }} />}
                  onClick={closeModal}
                />
              </div>
            </DrawerHeader>
            <DrawerBody padding="0px">
              <div className="flex justify-center">
                {data.length > 1 && (
                  <IconButton
                    bg={"white"}
                    position={"absolute"}
                    top={"45%"}
                    left={0}
                    rounded={"full"}
                    size={"sm"}
                    boxShadow={"md"}
                    icon={<ChevronLeft />}
                    onClick={() => toggleMove(1)}
                    zIndex={1}
                  />
                )}
                {loading ? (
                  <DetailModalSkeleton />
                ) : (
                  <div className="px-2 w-full h-full">
                    <div className="flex flex-col-reverse gap-3 w-full">
                      <div className="gap-2 grid grid-cols-1 w-full">
                        {selectedCamera?.cameraInfo?.map((el) => (
                          <VideoComponent
                            cameraName={el?.name}
                            videoUrl={modalData?.[el?.cameraId]?.video}
                          />
                        ))}
                      </div>
                      <div className={`w-full flex flex-col gap-3`}>
                        <StatusCard gasFlowRate={modalData?.gasFlowRate} />
                        <div
                          className={`w-full h-full  rounded-md bg-white border`}
                        >
                          <div className="flex flex-row justify-between items-center px-2 pt-1 pb-0 w-full h-10">
                            <p className="font-medium text-[#525056]">{`Trend Graph`}</p>
                          </div>
                          <FlareStackLineChart
                            data={modalData?.lineChartData?.data}
                            times={modalData?.lineChartData?.times}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {data.length > 1 && (
                  <IconButton
                    bg={"white"}
                    position={"absolute"}
                    top={"45%"}
                    right={0}
                    rounded={"full"}
                    size={"sm"}
                    boxShadow={"md"}
                    icon={<ChevronRight />}
                    onClick={() => toggleMove(2)}
                  />
                )}
              </div>
            </DrawerBody>
            <DrawerFooter paddingY={0}>
              {loading ? (
                <Skeleton
                  height={"30"}
                  w={"full"}
                />
              ) : (
                <Footer setopenFeedback={setopenFeedback} />
              )}
            </DrawerFooter>
          </DrawerContent>
        </Drawer>
      )}
      {openFeedback && (
        <FeedbackModal
          openModal={openFeedback}
          closeModal={() => setopenFeedback(false)}
          // cameraId={cameraRef.current}
          // plantName={plantRef.current}
          id={idRef.current}
        />
      )}
    </>
  );
};
