import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Radio,
  RadioGroup,
  useToast,
  ChakraProvider,
  Tooltip,
  Select,
} from "@chakra-ui/react";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const CamAddModal = ({ openModal, closeModal }) => {
  const navigate = useNavigate();
  const options = ["Add New", "Use Existing"];
  const [existingFeeds, setExistingFeeds] = useState(["1", "1", "1", "1"]);
  const [selectedFeed, setSelectedFeed] = useState("Add New");
  const handleNext = () => {
    switch (selectedFeed) {
      case options[0]:
        navigate(`/Sandbox/Cam/Create`);
        closeModal();
        break;
      case options[1]:
        closeModal();
        break;
      default:
        break;
    }
  };

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={openModal}
      onClose={closeModal}
      isCentered="true"
    >
      <ModalOverlay />
      <ModalContent style={{ borderRadius: "8px" }} maxW="420px">
        <ModalHeader
          py={"12px"}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          bg={"#2660B6"}
          color={"#FFFBF9"}
          fontSize={"18px"}
          fontWeight={500}
          borderTopRadius={"8px"}
        >
          Select Feed type
        </ModalHeader>
        <ModalBody
          p={"24px"}
          borderBottomRadius={"8px"}
          display={"flex"}
          flexDirection={"column"}
          gap={"24px"}
        >
          <div className="flex flex-col gap-3">
            <p className="text-[#2660B6] text-sm font-medium">
              Select data feed type
            </p>
            <RadioGroup onChange={setSelectedFeed} value={selectedFeed}>
              <div className="flex gap-6 items-center">
                {options.map((x) => {
                  return (
                    <div
                      style={{
                        backgroundColor:
                          x == selectedFeed ? "#DDEEFF80" : "#FFF",
                        borderRadius: "8px",
                      }}
                    >
                      <Radio
                        value={x}
                        py={"8px"}
                        pl={"8px"}
                        pr={"12px"}
                        fontSize={"14px"}
                        fontWeight={500}
                        color={"#3E3C42"}
                        _checked={{
                          bg: "#6CA6FC",
                          borderColor: "#6CA6FC",
                        }}
                        _hover={{
                          borderColor: "#6CA6FC",
                        }}
                      >
                        {x}
                      </Radio>
                    </div>
                  );
                })}
              </div>
            </RadioGroup>
          </div>
          {selectedFeed == options[1] && (
            <div className="flex flex-col gap-3">
              <p className="text-[#2660B6] text-sm font-medium">
                Select existing feed device
              </p>
              <Select
                width={"300px"}
                color={"#605D64"}
                fontSize={"16px"}
                fontWeight={400}
              >
                {existingFeeds?.map((item) => {
                  return (
                    <option value={item} key={item}>
                      {item}
                    </option>
                  );
                })}
              </Select>
            </div>
          )}
          <PrimaryButton
            text={"Next"}
            width={"fit-content"}
            onClick={handleNext}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CamAddModal;
