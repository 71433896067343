import React, { useState } from "react";
import {
    Input,
    InputGroup,
    InputRightElement,
    Text,
} from "@chakra-ui/react";
import TonalButton from "../../../../util/Buttons/TonalButton";
import PrimaryButton from "../../../../util/Buttons/PrimaryButton";
import {
    Modal,
    ModalBody,
    ModalFooter,
    ModalOverlay,
    ModalCloseButton,
    ModalContent,
    Radio,
    RadioGroup,
    Textarea,
} from "@chakra-ui/react";


const UpdateModal = ({
    isOpen,
    handleCloseEditModal,
    deviceId,
    view,
    device,
    handleChange,
    index,
    handleSubmit,
}) => {
    const [disable, setDisable] = useState(view ? true : false);
    const [showPassword, setShowPassword] = useState(false);

    const dataOptions = {
        type: {
            type: ["Planned", "Procured", "Installed"],
        },
        dataFeedReady: {
            type: ["Yes", "No"],
        },
        dataFeedType: {
            type: ["Realtime", "SKU", "MKDCAL", "PP"],
        },
        commType: {
            type: ["HTTP", "HTTPS", "UDP", "TCP", "RTSP", "RCBL", "Others"],
        },
    };

    return (
        <Modal
            isOpen={isOpen >= 0}
            onClose={() => handleCloseEditModal()}
            isCentered
            size={"xl"}
            width={1200}
        >
            <ModalOverlay />
            <ModalContent>
                <div className="text-white w-full h-12 flex bg-[#2660B6] font-semibold justify-center items-center rounded-t-md">
                    {/* <p>{"LHBSAFKJHJLKJAOSHLJH"}</p> */}
                    Add/Edit Device instance
                </div>
                <ModalCloseButton className="" color={"white"} />
                <ModalBody className="w-full">
                    <div className="flex flex-col gap-2">
                        <div className="flex flex-row items-center gap-1">
                            <p className="text-xs text-[#D3D3D3]">Device Instance Id</p>
                            <p>{device?.deviceInstanceId}</p>
                        </div>
                        {/* <p className="text-[#2660B6]">{device.relativeDeviceId}</p> */}

                        <div className="sm:w-[50%] flex flex-col gap-3 mt-6">
                            <p className="text-[#2660B6] text-lg font-medium">
                                Device Instance name
                            </p>
                            {view === true ? (
                                <p className="text-[#525056] text-sm font-medium">
                                    {device?.relativeDeviceName}
                                </p>
                            ) : (
                                <div className="w-full">
                                    <Input
                                        type="text"
                                        defaultValue={device?.relativeDeviceName}
                                        onChange={(e) =>
                                            handleChange("relativeDeviceName", e.target.value, index)
                                        }
                                        borderColor={"gray.300"}
                                        borderWidth={"2px"}
                                        isDisabled={disable}
                                    />
                                    {device?.relativeDeviceName?.length > 100 && (
                                        <div>
                                            <Text color="red.500" fontSize="sm" mt="1">
                                                You have exceed the character limit of 100 by{" "}
                                                {device?.relativeDeviceName?.length - 100}
                                            </Text>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                        <div className="flex flex-row gap-3 mt-3">
                            <RadioGroup
                                isDisabled={disable}
                                onChange={(e) => {
                                    handleChange("devicePhase", e, index);
                                }}
                                value={device?.devicePhase}
                                className="flex flex-row"
                            >
                                {dataOptions.type.type.map((x) => {
                                    return (
                                        <div
                                            style={{
                                                backgroundColor:
                                                    device?.devicePhase === x ? "#DDEEFF80" : "#FFF",
                                                borderRadius: "8px",
                                                mb: "12px",
                                                // border
                                            }}
                                        >
                                            <Radio
                                                value={x}
                                                py={"8px"}
                                                pl={"8px"}
                                                pr={"12px"}
                                                fontSize={"14px"}
                                                fontWeight={500}
                                                color={"#3E3C42"}
                                                _checked={{
                                                    bg: "#6CA6FC",
                                                    borderColor: "#6CA6FC",
                                                }}
                                                _hover={{
                                                    borderColor: "#6CA6FC",
                                                }}
                                                borderColor={"gray.300"}
                                                isDisabled={disable}
                                            >
                                                {x}
                                            </Radio>
                                        </div>
                                    );
                                })}
                            </RadioGroup>
                            {/* <Checkbox
                  disabled={disable}
                  isChecked={device?.isDeleted === true}
                  onChange={() => handleChange("isDeleted", device?.isDeleted === true ? false : true, index)}
                >
                  {"Deactivated"}
                </Checkbox> */}
                        </div>

                        <div className="lg:w-[100%] flex flex-row gap-2">
                            <div className="w-[50%] flex flex-col gap-3 mt-6">
                                <p className="text-[#2660B6] text-lg font-medium">
                                    Serial Number
                                </p>
                                {view === true ? (
                                    <p className="text-[#525056] text-sm font-medium">
                                        {device?.serialNumber}
                                    </p>
                                ) : (
                                    <div className="w-full">
                                        <Input
                                            type="text"
                                            value={device?.serialNumber}
                                            onChange={(e) => {
                                                handleChange("serialNumber", e.target.value, index);
                                            }}
                                            borderColor={"gray.300"}
                                            borderWidth={"2px"}
                                            isDisabled={disable}
                                        />

                                        {device?.serialNumber?.length > 100 && (
                                            <div>
                                                <Text color="red.500" fontSize="sm" mt="1">
                                                    You have exceed the character limit of 100 by{" "}
                                                    {device?.serialNumber?.length - 100}
                                                </Text>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="w-[50%] flex flex-col gap-3 mt-6">
                                <p className="text-[#2660B6] text-lg font-medium">
                                    Additional Reference number
                                </p>
                                {view === true ? (
                                    <p className="text-[#525056] text-sm font-medium">
                                        {device?.addReferanceNotes}
                                    </p>
                                ) : (
                                    <div className="w-full">
                                        <Input
                                            type="text"
                                            defaultValue={device?.addReferanceNotes}
                                            onChange={(e) => {
                                                handleChange(
                                                    "addReferanceNotes",
                                                    e.target.value,
                                                    index
                                                );
                                            }}
                                            borderColor={"gray.300"}
                                            borderWidth={"2px"}
                                            isDisabled={disable}
                                        />

                                        {device?.addReferanceNotes?.length > 100 && (
                                            <div>
                                                <Text color="red.500" fontSize="sm" mt="1">
                                                    You have exceed the character limit of 100 by{" "}
                                                    {device?.addReferanceNotes?.length - 100}
                                                </Text>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="flex flex-col sm:flex-row gap-2">
                            <div className="sm:w-[50%] flex flex-col gap-3 mt-6">
                                <p className="text-[#2660B6] text-lg font-medium">
                                    Device Instance Notes
                                </p>
                                {view === true ? (
                                    <p className="text-[#525056] text-sm font-medium">
                                        {device?.instanceNotes}
                                    </p>
                                ) : (
                                    <div className="w-full">
                                        <Textarea
                                            placeholder="Details"
                                            borderWidth={"2px"}
                                            value={device?.instanceNotes}
                                            onChange={(e) =>
                                                handleChange("instanceNotes", e.target.value, index)
                                            }
                                            isDisabled={disable}
                                        />

                                        {device?.instanceNotes?.length > 1000 && (
                                            <div>
                                                <Text color="red.500" fontSize="sm" mt="1">
                                                    You have exceed the character limit of 1000 by{" "}
                                                    {device?.instanceNotes?.length - 1000}
                                                </Text>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="sm:w-[50%] flex flex-col gap-3 mt-6">
                                <p className="text-[#2660B6] text-lg font-medium">
                                    Device Installation Notes
                                </p>
                                {view === true ? (
                                    <p className="text-[#525056] text-sm font-medium">
                                        {device?.installationNotes}
                                    </p>
                                ) : (
                                    <div className="w-full">
                                        <Textarea
                                            placeholder="Notes"
                                            borderWidth={"2px"}
                                            value={device?.installationNotes}
                                            onChange={(e) =>
                                                handleChange("installationNotes", e.target.value, index)
                                            }
                                            isDisabled={disable}
                                        />

                                        {device?.installationNotes?.length > 1000 && (
                                            <div>
                                                <Text color="red.500" fontSize="sm" mt="1">
                                                    You have exceed the character limit of 1000 by{" "}
                                                    {device?.installationNotes?.length - 1000}
                                                </Text>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>

                        <div className="flex flex-col gap-3 mt-6">
                            <p className="text-[#2660B6] text-lg font-medium">
                                Data Feed Ready?
                            </p>
                            <RadioGroup
                                isDisabled={disable}
                                onChange={(e) => {
                                    handleChange("feedStatus", e === "Yes" ? true : false, index);
                                }}
                                value={device?.feedStatus === true ? "Yes" : "No"}
                                className="flex flex-row"
                            >
                                {dataOptions.dataFeedReady.type.map((x) => {
                                    return (
                                        <div
                                            style={{
                                                backgroundColor:
                                                    (device?.feedStatus === true && x === "Yes") ||
                                                        (device?.feedStatus === false && x === "No")
                                                        ? "#DDEEFF80"
                                                        : "#FFF",
                                                borderRadius: "8px",
                                                mb: "12px",
                                                // border
                                            }}
                                        >
                                            <Radio
                                                value={x}
                                                py={"8px"}
                                                pl={"8px"}
                                                pr={"12px"}
                                                fontSize={"14px"}
                                                fontWeight={500}
                                                color={"#3E3C42"}
                                                _checked={{
                                                    bg: "#6CA6FC",
                                                    borderColor: "#6CA6FC",
                                                }}
                                                _hover={{
                                                    borderColor: "#6CA6FC",
                                                }}
                                                borderColor={"gray.300"}
                                                isDisabled={disable}
                                            >
                                                {x}
                                            </Radio>
                                        </div>
                                    );
                                })}
                            </RadioGroup>
                        </div>

                        {device?.feedStatus === true && (
                            <div>
                                <div className="lg:w-[100%] flex flex-row gap-2">
                                    <div className="sm:w-[50%] flex flex-col justify-between gap-3 mt-6">
                                        <p className="text-[#2660B6] text-lg font-medium">
                                            Data Feed Type
                                        </p>
                                        {view === true ? (
                                            <p className="text-[#525056] text-sm font-medium">
                                                {device?.feedType}
                                            </p>
                                        ) : (
                                            <div className="w-full">
                                                <select
                                                    value={device?.feedType}
                                                    onChange={(e) =>
                                                        handleChange("feedType", e.target.value, index)
                                                    }
                                                    borderColor={"gray.300"}
                                                    className="w-[100%] p-2 border-[2px] rounded-lg max-h-[300px] overflow-y-auto"
                                                    disabled={disable}
                                                >
                                                    <option value="" disabled selected>
                                                        Select one
                                                    </option>
                                                    {dataOptions.dataFeedType.type?.map((item, index) => (
                                                        <option value={item}>{item}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        )}
                                    </div>
                                    <div className="sm:w-[50%] flex flex-col justify-between gap-3 mt-6">
                                        <p className="text-[#2660B6] text-lg font-medium">
                                            Communication type
                                        </p>
                                        {view === true ? (
                                            <p className="text-[#525056] text-sm font-medium">
                                                {device?.connectionType}
                                            </p>
                                        ) : (
                                            <div className="w-full">
                                                <select
                                                    value={device?.connectionType}
                                                    onChange={(e) =>
                                                        handleChange(
                                                            "connectionType",
                                                            e.target.value,
                                                            index
                                                        )
                                                    }
                                                    borderColor={"gray.300"}
                                                    className="w-[100%] p-2 border-[2px] rounded-lg max-h-[300px] overflow-y-auto"
                                                    disabled={disable}
                                                >
                                                    <option value="" disabled selected>
                                                        Select one
                                                    </option>
                                                    {dataOptions.commType.type?.map((item, index) => (
                                                        <option value={item}>{item}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="lg:w-[100%] flex sm:flex-row flex-col gap-2">
                                    <div className="sm:w-[50%] flex flex-col gap-3 mt-6">
                                        <p className="text-[#2660B6] text-lg font-medium">
                                            Data Feed IP or RTSP url
                                        </p>
                                        {view === true ? (
                                            <p className="text-[#525056] text-sm font-medium">
                                                {device?.dataFeed}
                                            </p>
                                        ) : (
                                            <div className="w-full">
                                                <Input
                                                    type="text"
                                                    defaultValue={device?.dataFeed}
                                                    onChange={(e) =>
                                                        handleChange("dataFeed", e.target.value, index)
                                                    }
                                                    borderColor={"gray.300"}
                                                    borderWidth={"2px"}
                                                    isDisabled={disable}
                                                />

                                                {device?.dataFeed?.length > 100 && (
                                                    <div>
                                                        <Text color="red.500" fontSize="sm" mt="1">
                                                            You have exceed the character limit of 100 by{" "}
                                                            {device?.dataFeed?.length - 100}
                                                        </Text>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div className="sm:w-[50%] flex flex-col gap-3 mt-6">
                                        <p className="text-[#2660B6] text-lg font-medium">Port</p>
                                        {view === true ? (
                                            <p className="text-[#525056] text-sm font-medium">
                                                {device?.port}
                                            </p>
                                        ) : (
                                            <div className="w-full">
                                                <Input
                                                    type="text"
                                                    defaultValue={device?.port}
                                                    onChange={(e) =>
                                                        handleChange("port", e.target.value, index)
                                                    }
                                                    borderColor={"gray.300"}
                                                    borderWidth={"2px"}
                                                    isDisabled={disable}
                                                />

                                                {device?.port?.length > 100 && (
                                                    <div>
                                                        <Text color="red.500" fontSize="sm" mt="1">
                                                            You have exceed the character limit of 100 by{" "}
                                                            {device?.port?.length - 100}
                                                        </Text>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className="lg:w-[100%] flex sm:flex-row flex-col gap-2">
                                    <div className="sm:w-[50%] flex flex-col gap-3 mt-6">
                                        <p className="text-[#2660B6] text-lg font-medium">
                                            Username
                                        </p>
                                        {view === true ? (
                                            <p className="text-[#525056] text-sm font-medium">
                                                {device?.userName}
                                            </p>
                                        ) : (
                                            <div className="w-full">
                                                <Input
                                                    type="text"
                                                    defaultValue={device?.userName}
                                                    onChange={(e) =>
                                                        handleChange("userName", e.target.value, index)
                                                    }
                                                    borderColor={"gray.300"}
                                                    borderWidth={"2px"}
                                                    isDisabled={disable}
                                                />

                                                {device?.userName?.length > 100 && (
                                                    <div>
                                                        <Text color="red.500" fontSize="sm" mt="1">
                                                            You have exceed the character limit of 100 by{" "}
                                                            {device?.userName?.length - 100}
                                                        </Text>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div className="sm:w-[50%] flex flex-col gap-3 mt-6">
                                        <p className="text-[#2660B6] text-lg font-medium">
                                            Password
                                        </p>
                                        {view === true ? (
                                            <p className="text-[#525056] text-sm font-medium">
                                                {device?.password}
                                            </p>
                                        ) : (
                                            <div className="w-full">
                                                <InputGroup size="md">
                                                    <Input
                                                        defaultValue={device?.password}
                                                        pr="4.5rem"
                                                        borderColor={"gray.300"}
                                                        borderWidth={"2px"}
                                                        onChange={(e) =>
                                                            handleChange("password", e.target.value, index)
                                                        }
                                                        type={showPassword ? "text" : "password"}
                                                        placeholder="Enter password"
                                                    />
                                                    <InputRightElement
                                                        width="4.5rem"
                                                        onClick={() => setShowPassword(!showPassword)}
                                                    >
                                                        {showPassword ? (
                                                            <img src="/visibility_off.svg" />
                                                        ) : (
                                                            <img src="/visibility.svg" />
                                                        )}
                                                    </InputRightElement>
                                                </InputGroup>
                                                {device?.password?.length > 100 && (
                                                    <div>
                                                        <Text color="red.500" fontSize="sm" mt="1">
                                                            You have exceed the character limit of 100 by{" "}
                                                            {device?.password?.length - 100}
                                                        </Text>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="flex flex-row items-center gap-1">
                            <p className="text-xs text-[#D3D3D3]">Device UUID</p>
                            <p>{deviceId}</p>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className="!w-full !flex !flex-row !items-center !justify-start !gap-2">
                    {view === false && (
                        <div className="flex gap-[10px] items-center ml-6 md:ml-0 mt-6 mb-20 md:mb-0">
                            {disable ? (
                                <TonalButton
                                    text={"Back to editing"}
                                    width={"130px"}
                                    onClick={() => {
                                        //setReview(false);
                                        setDisable(false);
                                    }}
                                    disable={view}
                                />
                            ) : (
                                <PrimaryButton
                                    text={"Save"}
                                    width={"130px"}
                                    onClick={() => {
                                        setDisable(true);
                                        handleSubmit(true);
                                    }}
                                    disable={view}
                                //color="green"
                                />
                            )}
                        </div>
                    )}
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default UpdateModal;