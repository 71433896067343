import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { createTheme, ThemeProvider, Tooltip } from "@mui/material";
import TextButton from "../../../../util/Buttons/TextButton";

const MuiTheme = createTheme();

const dummy = [
  {
    alertName: "Dummy string",
    alertMedium: "Dummy string",
    timestamp: "2024-03-08T12:00:00.000Z",
    alertTrigger: "Dummy string",
    details: "https://example.com/dummy-image.jpg",
  },
  {
    alertName: "Dummy string",
    alertMedium: "Dummy string",
    timestamp: "2024-03-08T12:00:00.000Z",
    alertTrigger: "Dummy string",
    details: "https://example.com/dummy-image.jpg",
  },
  {
    alertName: "Dummy string",
    alertMedium: "Dummy string",
    timestamp: "2024-03-08T12:00:00.000Z",
    alertTrigger: "Dummy string",
    details: "https://example.com/dummy-image.jpg",
  },
  {
    alertName: "Dummy string",
    alertMedium: "Dummy string",
    timestamp: "2024-03-08T12:00:00.000Z",
    alertTrigger: "Dummy string",
    details: "https://example.com/dummy-image.jpg",
  },
  {
    alertName: "Dummy string",
    alertMedium: "Dummy string",
    timestamp: "2024-03-08T12:00:00.000Z",
    alertTrigger: "Dummy string",
    details: "https://example.com/dummy-image.jpg",
  },
  {
    alertName: "Dummy string",
    alertMedium: "Dummy string",
    timestamp: "2024-03-08T12:00:00.000Z",
    alertTrigger: "Dummy string",
    details: "https://example.com/dummy-image.jpg",
  },
  {
    alertName: "Dummy string",
    alertMedium: "Dummy string",
    timestamp: "2024-03-08T12:00:00.000Z",
    alertTrigger: "Dummy string",
    details: "https://example.com/dummy-image.jpg",
  },
  {
    alertName: "Dummy string",
    alertMedium: "Dummy string",
    timestamp: "2024-03-08T12:00:00.000Z",
    alertTrigger: "Dummy string",
    details: "https://example.com/dummy-image.jpg",
  },
  {
    alertName: "Dummy string",
    alertMedium: "Dummy string",
    timestamp: "2024-03-08T12:00:00.000Z",
    alertTrigger: "Dummy string",
    details: "https://example.com/dummy-image.jpg",
  },
  {
    alertName: "Dummy string",
    alertMedium: "Dummy string",
    timestamp: "2024-03-08T12:00:00.000Z",
    alertTrigger: "Dummy string",
    details: "https://example.com/dummy-image.jpg",
  },
];

const ViewDetail = ({ row }) => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <TextButton text={"View details"} width={"fit-content"} />
    </>
  );
};

const columns = [
  {
    field: "alertName",
    headerName: "Alert name",
  },
  {
    field: "alertMedium",
    headerName: "Alert medium",
  },
  {
    field: "timestamp",
    headerName: "Timestamp",
  },
  {
    field: "alertTrigger",
    headerName: "Alert trigger",
  },
  {
    field: "details",
    headerName: "Details",
    renderCell: ({ row }) => <ViewDetail row={row} />,
  },
];

const AlertTable = ({ rowData = dummy }) => {
  const [rows, setRows] = useState([]);
  const headerClass =
    "text-xs font-medium text-[#525056] bg-[#ddeeff] uppercase";
  const cellClass = "text-sm font-medium text-[#3E3C42]";
  const flexMap = {
    alertName: 1,
    alertMedium: 1,
    timestamp: 1,
    alertTrigger: 1,
    details: 1,
  };
  columns.map((val, idx) => {
    val["headerClassName"] = headerClass;
    val["cellClassName"] = cellClass;
    val["flex"] = flexMap[val.field];
    val["align"] = idx == 0 ? "left" : "center";
    val["headerAlign"] = idx == 0 ? "left" : "center";
    return val;
  });

  useEffect(() => {
    setRows((prev) => {
      let newData = rowData.map((item, idx) => {
        item["id"] = idx + 1;
        return item;
      });
      return newData;
    });
  }, [rowData]);

  return (
    <div className="overflow-x-auto max-w-full">
      <ThemeProvider theme={MuiTheme}>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: { paginationModel: { pageSize: 5 } },
          }}
          pagination
          pageSizeOptions={[5, 10, 25]}
          sx={{ minWidth: "1000px" }}
        />
      </ThemeProvider>
    </div>
  );
};

export default AlertTable;
