import { useNavigate, useSearchParams } from "react-router-dom";
import PrimaryButton from "../../../../util/Buttons/PrimaryButton";
import TonalButton from "../../../../util/Buttons/TonalButton";
import { Checkbox, CheckboxGroup, Input, Select } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";

const dummy = {
  labels: ["Human", "Monkey"],
  conditions: [
    {
      label: "Human",
      state: "Detected",
    },
    {
      operation: "And",
    },
    {
      label: "Monkey",
      state: "Detected",
    },
  ],
  medium: ["Whatsapp", "Email"],
  name: "Alert 1",
};

const Condition = ({
  idx,
  data,
  labels,
  found,
  handleChange,
  handleRemove,
}) => (
  <div className="flex flex-col gap-4 p-4 w-fit bg-[#FAFAFA] rounded-lg">
    <p className="text-[#3E3C42] text-lg font-medium flex justify-between w-full">
      Condition {idx / 2 + 1}{" "}
      {idx != 0 && (
        <img
          src="/delete.svg"
          className="hover:scale-105 cursor-pointer w-4 h-auto"
          alt="delete"
          onClick={() => handleRemove(idx)}
        />
      )}
    </p>
    <div className="flex flex-col md:flex-row gap-2 items-start md:items-end">
      <p className="text-[#79767D] text-lg font-medium">When</p>
      <div className="flex flex-col gap-3">
        <p className="text-[#2660B6] text-sm font-medium">Select label</p>
        <Select
          width={"250px"}
          value={data[idx]?.label}
          onChange={(e) => handleChange(idx, "label", e.target.value)}
        >
          <option value={""} key={"select"}>
            Select label
          </option>
          {labels?.map((item) => {
            return (
              <option value={item} key={item}>
                {item}
              </option>
            );
          })}
        </Select>
      </div>
      <p className="text-[#79767D] text-lg font-medium">Is</p>
      <div className="flex flex-col gap-3">
        <p className="text-[#2660B6] text-sm font-medium">Select State</p>
        <Select
          width={"250px"}
          value={data[idx]?.state}
          onChange={(e) => handleChange(idx, "state", e.target.value)}
        >
          <option value={""} key={"select"}>
            Select state
          </option>
          {found?.map((item) => {
            return (
              <option value={item} key={item}>
                {item}
              </option>
            );
          })}
        </Select>
      </div>
    </div>
  </div>
);

const Operation = ({ id, data, handleChange }) => (
  <div className="flex items-center gap-0">
    {["And", "Or"]?.map((item, idx) => {
      return (
        <div
          className={`text-sm border text-center w-14 py-2 ${
            idx == 0 ? "rounded-l-lg" : "rounded-r-lg"
          } ${idx == 0 ? "pl-2 pr-3" : "pl-3 pr-2"}`}
          style={{
            color: item == data[id]?.operation ? "#5892E8" : "#605D64",
            fontWeight: item == data[id]?.operation ? 500 : 400,
            backgroundColor:
              item == data[id]?.operation ? "#DDEEFF80" : "#F5F5F5",
            borderColor: item == data[id]?.operation ? "#5892E8" : "#CAC5CD",
            cursor: item == data[id]?.operation ? "" : "pointer",
          }}
          onClick={() => handleChange(id, "operation", item)}
        >
          {item}
        </div>
      );
    })}
  </div>
);

const CreateAlert = () => {
  const navigate = useNavigate();
  const nameRef = useRef();
  const [searchParams, setSearchParams] = useSearchParams();
  const [alertMedium, setAlertMedium] = useState([]);
  const [conditions, setConditions] = useState([
    {
      label: "",
      state: "",
    },
  ]);
  const allowedMediums = ["Whatsapp", "Email"];
  const [labels, setLabels] = useState(["Cat", "Dog", "Mouse"]);
  const found = ["Detected", "Not Detected"];

  const handleChange = (idx, name, value) => {
    setConditions((prev) => {
      let newData = JSON.parse(JSON.stringify(prev));
      newData[idx][name] = value;
      return newData;
    });
  };

  const handleAdd = () => {
    setConditions((prev) => {
      let newData = JSON.parse(JSON.stringify(prev));
      newData.push({
        operation: "",
      });
      newData.push({
        label: "",
        state: "",
      });
      return newData;
    });
  };

  const handleRemove = (idx) => {
    setConditions((prev) => {
      let newData = JSON.parse(JSON.stringify(prev));
      newData.splice(idx, 1);
      newData.splice(idx - 1, 1);
      return newData;
    });
  };

  useEffect(() => {
    let val = searchParams.get("existing");
    if (val && Boolean(val)) {
      setConditions(dummy.conditions);
      setLabels(dummy.labels);
      setAlertMedium(dummy.medium);
      nameRef.current.value = dummy.name;
    }
  }, [searchParams]);

  console.log(conditions, "conditions alerts");

  return (
    <div className="flex flex-col gap-2 mt-6">
      <div className="flex gap-2 items-center">
        <img
          src="/backtick.svg"
          alt="back"
          className="hover:scale-105 cursor-pointer"
          onClick={() => navigate(`/Sandbox/Alert/1234`)}
        />
        <p className="text-[#084298] text-xl font-medium">
          Create new Camera Alert
        </p>
      </div>
      <div className="bg-white p-6 rounded-lg flex flex-col gap-6">
        {/*name */}
        <div className="flex flex-col gap-3">
          <p className="text-sm font-medium text-[#2660B6]">Enter alert name</p>
          <Input width={"300px"} ref={nameRef} placeholder="Enter alert name" />
        </div>
        {/*Conditions */}
        <div className="flex flex-col gap-6 items-center p-2 rounded-lg border border-[#CAC5CD] w-fit">
          {conditions?.map((item, idx) => {
            return idx % 2 == 0 ? (
              <Condition
                key={"conditon" + Math.random()}
                idx={idx}
                data={conditions}
                labels={labels}
                found={found}
                handleChange={handleChange}
                handleRemove={handleRemove}
              />
            ) : (
              <Operation
                key={"operation" + Math.random()}
                id={idx}
                data={conditions}
                handleChange={handleChange}
              />
            );
          })}
        </div>
        <TonalButton
          Icon={<AddIcon sx={{ color: "#447ED4" }} />}
          text={"Add Condition"}
          width={"fit-content"}
          onClick={handleAdd}
        />
        {/*Medium */}
        <div className="flex flex-col gap-3">
          <p className="text-sm font-medium text-[#2660B6]">
            Select alert medium
          </p>
          <CheckboxGroup onChange={setAlertMedium} value={alertMedium}>
            <div className="flex gap-3 items-center">
              {allowedMediums.map((x) => {
                return (
                  <div
                    style={{
                      backgroundColor: alertMedium.includes(x)
                        ? "#DDEEFF80"
                        : "#FFF",
                      borderRadius: "8px",
                    }}
                  >
                    <Checkbox
                      value={x}
                      py={"8px"}
                      pl={"8px"}
                      pr={"12px"}
                      fontSize={"14px"}
                      fontWeight={500}
                      color={"#3E3C42"}
                      _hover={{ borderColor: "#6CA6FC" }}
                    >
                      {x}
                    </Checkbox>
                  </div>
                );
              })}
            </div>
          </CheckboxGroup>
        </div>
        <PrimaryButton
          text={"Create Alert"}
          width={"fit-content"}
          onClick={() => navigate("/Sandbox/Alert/1234")}
        />
      </div>
    </div>
  );
};

export default CreateAlert;
