import UseCaseDetail from "../Components/UseCaseDetail";
import UploadDetails from "../Components/UploadDetails";
import ProjectDetails from "../Components/ProjectDetails";
import DisplayImage from "../utils/DisplayImage";
import { useEffect, useState } from "react";
import Paginator from "../../../util/VisionUtils/Paginator";
import ModelUpload from "../Components/ModelUpload";

const dataTransformer = (val, type) => {
  let data = [];
  switch (type) {
    case "Classify":
      return "";
    case "Segment":
      val.map((item) => {
        data.push({
          points: item.points,
          label: item.cls,
        });
      });
      return data;
    case "Detect":
      val.map((item) => {
        let x = [
          [item.x, item.y],
          [item.x + item.w, item.y],
          [item.x + item.w, item.y + item.h],
          [item.x, item.y + item.h],
        ];
        data.push({
          points: x,
          label: item.cls,
        });
      });
      return data;
    default:
      break;
  }
};

const ViewForm = ({ userState }) => {
  const [displayData, setDisplayData] = useState([]);
  useEffect(() => {
    if (userState.annotatedData == null) setDisplayData([]);
  }, [userState.annotatedData]);
  return (
    <div className="flex flex-col gap-3 h-full w-full">
      <ProjectDetails userData={userState} viewMode={true} />
      <UseCaseDetail userData={userState} viewMode={true} show={true} />
      {/* <ModelUpload userData={userState} viewMode={true} show={true} /> */}
      <UploadDetails userData={userState} viewMode={true} show={true} />
      {userState?.isAnnotated == "No" && (
        <div className="flex flex-col gap-3 p-6">
          <p className="text-[#3E3C42] text-xl font-medium">Annotated data</p>
          <div className="self-end">
            {userState.annotatedData != null && (
              <Paginator
                data={userState?.annotatedData}
                limit={8}
                setDisplayData={setDisplayData}
              />
            )}
          </div>
          <div className="w-full h-fit grid-cols-1 grid min-[430px]:grid-cols-2 xl:grid-cols-4 gap-5">
            {displayData.map((item, idx) => {
              let annotationData = dataTransformer(
                item.label,
                userState.annotationType
              );
              return userState.annotationType == "Classify" ? (
                <div className="w-full h-[250px] bg-black relative rounded flex items-center justify-center">
                  <img
                    src={userState?.uploadedFiles[item.fileId]}
                    alt="image"
                    className="w-auto max-h-full rounded"
                    loading="lazy"
                  />
                  <p className="flex items-center gap-1 p-1 rounded-full bg-black absolute top-2 right-2 text-white font-medium text-sm bg-opacity-50">
                    {item.label}
                  </p>
                </div>
              ) : (
                <div className="flex bg-black justify-center items-center w-full h-auto">
                  <DisplayImage
                    width={250}
                    height={250}
                    url={userState?.uploadedFiles[item.fileId]}
                    annotationData={annotationData}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ViewForm;
