import {
  Table,
  Td,
  Tr,
  Thead,
  Tbody,
  TableContainer,
  Th,
  CircularProgress,
  CircularProgressLabel,
} from "@chakra-ui/react";
import TableCell from "./StatusTableCell";
import React, { useEffect, useState } from "react";
import { useWindowSize } from "@uidotdev/usehooks";

const StatusTable = ({ rowData, cols }) => {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const size = useWindowSize();
  const fixColumns =
    size.width >= 1280
      ? {
          Concentrate: 0,
          "Blend Ratio": 128,
          Bay: 236,
          Section: 285,
          "200 MT": 376,
        }
      : size.width > 768
      ? {
          Concentrate: 0,
          Bay: 120,
          Section: 165,
        }
      : {
          Concentrate: 0,
        };
  useEffect(() => {
    setData(rowData);
    setColumns(cols);
  }, [rowData, columns]);

  return (
    columns?.length > 0 &&
    data?.length > 0 && (
      <TableContainer
        className=" w-full"
        rounded={"8px"}
        paddingBottom={"30px"}
      >
        <Table variant="simple">
          <Thead className="bg-[#DEF] !text-xs !sticky !top-0">
            <Tr h={"44px"}>
              {columns.map((id, idx) => {
                let regex = /^R\d+$/;

                return (
                  <Th
                    key={idx}
                    color="#79767D"
                    fontWeight={400}
                    p={0}
                    px={"12px"}
                    textAlign={idx == 0 ? "left" : "center"}
                    position={
                      Object.keys(fixColumns).includes(id) ? "sticky" : ""
                    }
                    left={
                      Object.keys(fixColumns).includes(id) ? fixColumns[id] : ""
                    }
                    zIndex={
                      Object.keys(fixColumns).includes(id) ? "docked" : ""
                    }
                    bg={Object.keys(fixColumns).includes(id) ? "#DEF" : ""}
                  >
                    {regex.test(id) ? "Round " + id.slice(1) : id.toUpperCase()}
                  </Th>
                );
              })}
            </Tr>
          </Thead>
          <Tbody>
            {["Start time", "End time"]?.map((val, timeIdx) => {
              let regex = /^R\d+$/;
              return (
                <Tr
                  className="!bg-[#F2F2F2]"
                  color={"#3E3C42"}
                  fontWeight={600}
                  fontSize={"14px"}
                >
                  {columns?.map((id, idx) => {
                    let startTime = regex.test(id)
                      ? Math.min(
                          ...data
                            ?.map((item) => item?.["TS" + id.slice(1)] ?? null)
                            ?.filter((item) => item !== null)
                        )
                      : 0;
                    let endTime = regex.test(id)
                      ? Math.max(
                          ...data
                            ?.map((item) => item?.["T" + id.slice(1)] ?? null)
                            ?.filter((item) => item !== null)
                        )
                      : 0;
                    return (
                      <Td
                        p={"12px"}
                        textAlign={idx == 0 ? "left" : "center"}
                        position={
                          Object.keys(fixColumns).includes(id) ? "sticky" : ""
                        }
                        left={
                          Object.keys(fixColumns).includes(id)
                            ? fixColumns[id]
                            : ""
                        }
                        zIndex={
                          Object.keys(fixColumns).includes(id) ? "docked" : ""
                        }
                        bg={"#F2F2F2"}
                      >
                        {startTime === 0
                          ? idx === 0
                            ? val
                            : "-"
                          : timeIdx === 0
                          ? new Date(startTime * 1000)?.toLocaleString()
                          : new Date(endTime * 1000)?.toLocaleString()}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
            {data.map((item, index) => {
              return (
                <Tr
                  className="!bg-white"
                  color={"#3E3C42"}
                  fontWeight={600}
                  fontSize={"14px"}
                >
                  {columns.length > 0 &&
                    columns.map((x, idx) => {
                      var val = x.includes("R") ? parseFloat(item[x]) : 0;
                      let diff = parseFloat(item["200 MT"]) - val;
                      var percentage =
                        diff <= 0
                          ? item["200 MT"] == 0 && val == 0
                            ? 0
                            : 100
                          : (val / parseFloat(item["200 MT"])) * 100;
                      return (
                        <TableCell
                          key={x + "_" + idx + Math.random()}
                          data={data}
                          val={x}
                          item={item}
                          index={index}
                          percentage={percentage}
                          diff={diff}
                          fixColumns={fixColumns}
                          colIdx={idx}
                        />
                      );
                    })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    )
  );
};

export default React.memo(StatusTable);
