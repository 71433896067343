import React, { useState, useContext, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableCaption,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tfoot,
  Link,
  Button,
  Spinner,
} from "@chakra-ui/react";
import axios from "axios";
import NavContext from "../../NavContext";
import { baseURL } from "../../..";
import Paginator from "../../../util/VisionUtils/Paginator";
import { useToast } from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import ExlCsvDownload from "../../../util/VisionUtils/ExlCsvDownload";
import { CSVLink } from "react-csv";
import ActiveSubsTable from "../Tables/ActiveSubsTable";
import RipikAdminCards from "../components/RipikAdminCards";
import ActiveSubsFilters from "../components/ActiveSubsFilters";
import ClientAdminCards from "../components/ClientAdminCards";
import SubscriptionHistoryTable from "../Tables/SubscriptionHistoryTable";
import SubscriptionHistoryCards from "../components/SubscriptionHistoryCards";

const SubscriptionHistory = ({ issubscriptionHistoryChanged }) => {
  const activeSubsDataList = [];
  const [activeSubs, setActiveSubs] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const { auth, organisation } = useContext(NavContext);
  const toast = useToast();
  // const [downloadData, setDownloadData] = useState({});
  // const [downloadProp, setDownloadProp] = useState([]);
  const [loading, setLoading] = useState(false);
  const [noOfActiveSubs, setNoOfActiveSubs] = useState(0);
  const [allSubcriptions, setAllSubcriptions] = useState(0);
  const [modifiedSubs, setNoOfModifiedSubs] = useState(0);
  const [noOfInActiveSubs, setNoOfInActiveSubs] = useState(0);
  const [downloadData, setDownloadData] = useState([]);
  const [order, setOrder] = useState({});

  const fetchSubsHistory = async () => {
    const param = {
      organisation: organisation,
    };
    try {
      const response = await axios.get(baseURL + "subscription/fetchSubsLog", {
        params: param,
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      });

      console.log("sub hist", response.data);
      setActiveSubs(response?.data?.subscriptions);
      setNoOfActiveSubs(response?.data?.totalActive);
      setAllSubcriptions(response?.data?.totalSubscriptions);
      setNoOfModifiedSubs(response?.data?.totalModified);
      setNoOfInActiveSubs(response?.data?.totalInactive);

      setLoading(false);

      if (organisation === "Ripik.Ai") {
        setDownloadData(response?.data?.dataList);
        setOrder(response?.data?.adminSummary);
      } else {
        setDownloadData(response?.data?.dataList);
        setOrder(response?.data?.clientSummary);
      }
    } catch (e) {
      setLoading(false);
      console.error(e);
    }
  };

  useEffect(() => {
    if (auth) {
      setLoading(true);
      {
        organisation && fetchSubsHistory();
      }
      // fetchDownloadApi();
    }
  }, [auth, organisation, issubscriptionHistoryChanged]);

  return (
    <div className="w-full px-2 mt-3 !font-roboto">
      <div className="flex flex-col lg:flex-row lg:justify-between">
        <SubscriptionHistoryCards
          allSubcriptions={allSubcriptions}
          noOfActiveSubs={noOfActiveSubs}
          noOfInActiveSubs={noOfInActiveSubs}
          modifiedSubs={modifiedSubs}
        />
        <div className="flex lg:mt-0 items-end justify-end lg:justify-end">
          {activeSubs && activeSubs.length > 0 && (
            <ExlCsvDownload
              data={activeSubs}
              order={order}
              orderDetail={downloadData}
              enable={true}
            />
          )}
        </div>
      </div>

      {loading ? (
        <div className="ml-[50%]">
          <Spinner speed="0.65s" />
        </div>
      ) : (
        <React.Fragment>
          {activeSubs && activeSubs.length !== 0 ? (
            <SubscriptionHistoryTable
              activeSubs={activeSubs}
              organisation={organisation}
            />
          ) : (
            <p className="ml-[45%]">No data available</p>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default SubscriptionHistory;
