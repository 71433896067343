import {
  Select,
  Table,
  Td,
  Tr,
  Thead,
  Tbody,
  TableContainer,
  Th,
  Flex,
  Image,
  Spinner,
  Tooltip,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
// import ReactSearchBox from "react-search-box";
import { EditIcon, SmallCloseIcon, SpinnerIcon } from "@chakra-ui/icons";
import { useWindowSize } from "@uidotdev/usehooks";
// import DownloadExcel from "../utils/DownloadExcel";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import Paginator from "../../../util/VisionUtils/Paginator";
import HistoryCharts from "../components/HistoryCharts";
import Feedback from "../components/Feedback";
import Viewdetails from "../components/Viewdetails";
import DownloadExcel from "../components/DownloadExcel";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import axios from "axios";
import ReportTable from "../components/ReportTable";
import { slabSizingJsplBaseURl } from "../SlabSizing";
const MuiTheme = createTheme();

const Report = () => {
  const size = useWindowSize();
  const [serverd, setServerd] = useState(true);
  const [order, setOrder] = useState("desc");
  const [displayData, setDisplayData] = useState([]);
  const [historyChanging, setHistoryChanging] = useState(false);
  const [historyChangingForTable, setHistoryChangingForTable] = useState(false);
  const [stateForBarChart, setStateForBarChart] = useState(null);

  const [fromTime, setFromTime] = useState(
    new Date(
      new Date().getTime() - 6 * 24 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000
    )
      .toISOString()
      .slice(0, 10)
  );
  const [toTime, setToTime] = useState(
    new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10)
  );
  const [history, setHistory] = useState([]);
  const [searchStatus, setSearchstatus] = useState(false);
  const [searchinput, setSearchInput] = useState(0);
  const [filterhistory, setFilterHistory] = useState([]);
  const [stateForSequenceTable, setStateForSequenceTable] = useState(null);
  const [stateForStandard, setStateForStandard] = useState({
    standard: {
      length: null,
      breadth: null,
      depth: null,
    },
  });

  const WeeklyOutput = async () => {
    try {
      const response = await axios.get(
        `${slabSizingJsplBaseURl}weekly_output/?start_date=${fromTime}&end_date=${toTime}`
      );

      let data = response.data;

      setStateForBarChart(data);
      setHistoryChanging(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setHistoryChanging(false);
    }
  };

  const fetchStandardData = async () => {
    try {
      const response = await axios.get(`${slabSizingJsplBaseURl}standard`);

      let data = response.data;
      setStateForStandard((prev) => ({
        ...prev,
        standard: {
          length: data?.length,
          breadth: data?.breadth,
          depth: data?.depth,
        },
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const sequenceTable = async () => {
    try {
      const response = await axios.get(
        `${slabSizingJsplBaseURl}report/?start_date=${fromTime}&end_date=${toTime}`
      );

      let data = response.data;
      setStateForSequenceTable(data);
      setHistoryChangingForTable(false);
      setHistory(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setHistoryChangingForTable(false);
    }
  };

  const handleClick = () => {
    setHistoryChanging(true);
    setHistoryChangingForTable(true);
    fetchStandardData();
    WeeklyOutput();
    sequenceTable();
    //   apiCall();
    // handleSortChange("desc");
  };

  useEffect(() => {
    handleClick();
  }, []);

  //   search bar

  useEffect(() => {
    console.log("order", order);

    const sortedData =
      filterhistory.length > 0 ? [...filterhistory] : [...history];
    if (order === "desc") {
      sortedData.sort((a, b) => b.timestamp - a.timestamp);
    } else if (order === "asc") {
      sortedData.sort((a, b) => a.timestamp - b.timestamp);
    }

    if (filterhistory.length > 0) {
      setFilterHistory(sortedData);
    } else {
      setHistory(sortedData);
    }
  }, [order, historyChangingForTable]);

  const historyData = history;

  const searchData = historyData.map((item) => {
    if (item?.slab_id && item?.slab_id?.toString()) {
      return { label: item.slab_id.toString() };
    } else {
      return { label: "" };
    }
  });
  const uniqueSearchData = Array.from(
    new Set(searchData.map((item) => item.label))
  ).map((label) => {
    return {
      label,
    };
  });
  uniqueSearchData.sort((a, b) => {
    const labelA = a.label.toUpperCase(); // Convert labels to uppercase for case-insensitive comparison
    const labelB = b.label.toUpperCase();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  });

  const handleAutocompleteChange = (event, selectedValue) => {
    if (selectedValue && selectedValue.label) {
      const filteredAlerts = history.filter(
        (history) => history.slab_id == selectedValue.label
      );
      setFilterHistory(filteredAlerts);
    } else {
      setFilterHistory([]);
      setOrder("desc");
    }
  };

  return (
    <div className="relative flex flex-col">
      <div className={`absolute left-0 right-0 flex justify-center z-50 `}>
        <div className="flex md:flex-row flex-col items-center gap-2 bg-white shadow-md p-5 pr-6 pl-6 rounded-xl">
          <div>
            <FloatingInput
              text="From"
              type="date"
              setDateTime={setFromTime}
              value={fromTime}
              max={toTime}
            />
          </div>
          <div>
            <FloatingInput
              text="To"
              type="date"
              setDateTime={setToTime}
              value={toTime}
              max={new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
                .toISOString()
                .slice(0, 10)}
              min={fromTime}
            />
          </div>

          {serverd ? (
            historyChanging ? (
              <SpinnerIcon />
            ) : (
              <PrimaryButton
                text={"Apply"}
                width={"fit-content"}
                onClick={handleClick}
                height={"40px"}
              />
            )
          ) : (
            <PrimaryButton
              text={"Apply"}
              width={"fit-content"}
              onClick={handleClick}
            />
          )}
        </div>
      </div>
      {serverd ? (
        historyChanging ? (
          <div className="flex justify-center items-center mt-[200px] w-full h-full">
            <Spinner />
          </div>
        ) : (
          <div className="flex flex-col justify-start gap-2 bg-white mt-[160px] md:mt-6 pt-[57px] rounded-xl">
            <div className="w-full h-250px">
              <HistoryCharts
                fromTime={fromTime}
                toTime={toTime}
                stateForBarChart={stateForBarChart}
              />
            </div>

            <div className="relative flex flex-col gap-2 w-full h-full">
              {history && history.length > 0 && (
                <div className="flex justify-between px-4 w-full">
                  <ThemeProvider theme={MuiTheme}>
                    <Autocomplete
                      disablePortal
                      //   value={selectedValue}
                      id="combo-box-demo"
                      options={uniqueSearchData}
                      onChange={handleAutocompleteChange}
                      sx={{
                        width: "200px",
                        "& .MuiInputBase-root": {
                          height: "40px", // Adjust the height here
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Slab ID"
                          sx={{
                            width: "100%",
                            height: "100%",

                            "& .MuiInputLabel-root": {
                              transform: "translate(14px, 10px) scale(1)", // Adjust the label position
                              fontSize: "14px", // Adjust the label font size
                            },
                            "& .MuiInputLabel-shrink": {
                              transform: "translate(14px, -6px) scale(0.75)", // Adjust the shrunk label position
                            },
                          }}
                        />
                      )}
                    />
                  </ThemeProvider>
                  <div
                    className={`flex gap-2 ${
                      size.width < 768 ? "flex-col" : ""
                    }`}
                  >
                    <Paginator
                      data={filterhistory.length != 0 ? filterhistory : history}
                      limit={5}
                      setDisplayData={setDisplayData}
                    />
                  </div>
                </div>
              )}

              {filterhistory.length != 0 ? (
                <ReportTable
                  tableData={displayData}
                  stateForStandard={stateForStandard}
                  order={order}
                  setOrder={setOrder}
                  setHistory={setDisplayData}
                />
              ) : history.length != 0 ? (
                <ReportTable
                  tableData={displayData}
                  stateForStandard={stateForStandard}
                  order={order}
                  setOrder={setOrder}
                  setHistory={setDisplayData}
                />
              ) : (
                <ReportTable
                  tableData={[]}
                  stateForStandard={stateForStandard}
                  order={order}
                  setOrder={setOrder}
                />
              )}
            </div>
          </div>
        )
      ) : (
        <div className="mt-[70px] w-full">No Data Found</div>
      )}
    </div>
  );
};

export default Report;
