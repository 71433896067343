import { useState, useEffect, useContext } from "react";
import { Skeleton, useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import NavContext from "../../NavContext";
import { baseURL } from "../../../index";
import FeedCard from "../Components/FeedCard";
import axios from "axios";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import WeighmentCard from "../Components/WeighmentCard";
import mixpanel from "mixpanel-browser";
import ReactPlayer from "react-player";
import { LoginSharp } from "@mui/icons-material";
import { mixpanelWorkforceBayTrack } from "../../../util/mixpanelFunctions";

const Capitalize = (str) => {
  const arr = str.split(" ");
  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
  }
  const str2 = arr.join(" ");
  return str2;
};

const RtspToHslConverter = async (url, camId) => {
  try {
    const request = JSON.stringify({
      uri: url,
      alias: camId,
    });
    const response = await axios.post(
      "https://rtsp.backend-ripik.com/start",
      request,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const data = await response.data;
    return data.running ? "https://rtsp.backend-ripik.com" + data.uri : "";
  } catch (error) {
    console.log(error);
    return "";
  }
};

const playNotificationSound = (toast, check, title, msg, section) => {
  const audio = new Audio(
    "https://intelliverse-demo-new.s3.ap-south-1.amazonaws.com/cullingham.mp3"
  );

  const titleText = section ? section + " > " + title + " > " + check : title;
  const playPromise = audio.play();
  audio.volume = 0.02;
  if (playPromise !== undefined) {
    playPromise
      .then(() => {
        toast({
          title: titleText,
          description: Capitalize(msg),
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top-right",
        });
      })
      .catch((error) => {
        console.error("Error starting audio playback:", error);
      });
  }
};

const Feed = () => {
  const [selectedBay, setSelectedBay] = useState();
  const param = useParams();
  const [bays, setBays] = useState([]);
  const [truckNo, setTruckNo] = useState("");
  const [editing, setEditing] = useState(false);
  const [currentCams, setCurrentCams] = useState({});
  const [trucInfo, setTruckInfo] = useState({});
  const [feedMap, setFeedMap] = useState({});
  const [ShowCamera, setShowCamera] = useState([]);
  const [weightBridgeData, setWeightBridgeData] = useState({
    "weighBridge1-right": {
      reason: -1,
      truckNo: "",
    },
    "weighBridge1-left": {
      reason: -1,
      truckNo: "",
    },
  });
  const [weightBridgeDate, setwightBridgeDate] = useState();
  const { auth } = useContext(NavContext);
  const toast = useToast();

  const LiveAlertsApi = async () => {
    const requestData = JSON.stringify({
      clientId: param.clientId.toLowerCase(),
      useCase: "WORKFORCESAFETY",
      plantName: "khandala",
      cameraGpId: selectedBay,
      endDate: new Date().getTime() + 5.5 * 60 * 60 * 1000,
    });
    const response = await axios
      .post(
        baseURL + "vision/v1/workforceMonitoring/alerts/live/",
        requestData,
        {
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      )
      .then((response) => {
        let data = response.data.alerts;
        let dummyAlertData = {
          flushing: {
            flush: [
              {
                passed: false,
                startTs: 1706183008,
                endTs: 1706183008,
                cameraId: "cam_bay1_top",
                msg: "Flushing not conducted",
                annotatedImage: null,
                originalImage: null,
                failedCount: 0,
                passedCount: 0,
              },
            ],
          },
        };
        // if (dummyAlertData && Object.keys(dummyAlertData).length > 0) {
        //   Object.keys(dummyAlertData).map((title) => {
        //     Object.keys(dummyAlertData[title]).map((check, idx) => {
        //       playNotificationSound(
        //         toast,
        //         check,
        //         title,
        //         dummyAlertData[title][check][0].msg
        //       );
        //     });
        //   });
        // }
        if (data && Object.keys(data).length > 0) {
          Object.keys(data).map((title) => {
            Object.keys(data[title]).map((check, idx) => {
              playNotificationSound(
                toast,
                check,
                title,
                data[title][check][0].msg
              );
            });
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const LiveAlertsApiWeigh = async () => {
    const requestData = JSON.stringify({
      clientId: param.clientId.toLowerCase(),
      useCase: "WORKFORCESAFETY",
      plantName: "khandala",
      cameraGpId: selectedBay,
      endDate: new Date().getTime() + 5.5 * 60 * 60 * 1000,
    });
    const response = await axios
      .post(
        baseURL + "vision/v1/workforceMonitoring/alerts/live/multiple/",
        requestData,
        {
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      )
      .then((response) => {
        let data = response.data;

        let dummyAlertData = {
          "weighBridge1-left": {
            alerts: {
              dipRod: {
                security: [
                  {
                    passed: false,
                    startTs: 1706609510,
                    endTs: 1706611132,
                    cameraId: "cam_bay1_top",
                    msg: "Security guard not present during dip rod test",
                    confidence: 0,
                    annotatedImage: {
                      cam_bay1_top:
                        "https://asianpaints-data-files.s3.ap-south-1.amazonaws.com/asianpaints/workforcesafety/annotatedImage/bay1/cam_bay1_top/30-01-2024/10/bay1_17066095031706611132.jpg",
                    },
                    originalImage: {
                      cam_bay1_top:
                        "https://asianpaints-data-files.s3.ap-south-1.amazonaws.com/asianpaints/workforcesafety/originalImage/bay1/cam_bay1_top/30-01-2024/10/bay1_17066095031706611132.jpg",
                    },
                    failedCount: 1,
                    passedCount: 0,
                  },
                ],
              },
            },
            createdAt: 1706611135,
            lastUpdatedAt: 1706611135,
          },
          "weighBridge1-right": {
            alerts: {
              dipRod: {
                security: [
                  {
                    passed: false,
                    startTs: 1706609510,
                    endTs: 1706611132,
                    cameraId: "cam_bay1_top",
                    msg: "Security guard not present during dip rod test",
                    confidence: 0,
                    annotatedImage: {
                      cam_bay1_top:
                        "https://asianpaints-data-files.s3.ap-south-1.amazonaws.com/asianpaints/workforcesafety/annotatedImage/bay1/cam_bay1_top/30-01-2024/10/bay1_17066095031706611132.jpg",
                    },
                    originalImage: {
                      cam_bay1_top:
                        "https://asianpaints-data-files.s3.ap-south-1.amazonaws.com/asianpaints/workforcesafety/originalImage/bay1/cam_bay1_top/30-01-2024/10/bay1_17066095031706611132.jpg",
                    },
                    failedCount: 1,
                    passedCount: 0,
                  },
                ],
              },
            },
            createdAt: 1706611135,
            lastUpdatedAt: 1706611135,
          },
        };

        // if (dummyAlertData && Object.keys(dummyAlertData).length > 0) {
        //   Object.keys(dummyAlertData).map((section) => {
        //     Object.keys(dummyAlertData[section].alerts).map((events) => {
        //       Object.keys(dummyAlertData[section].alerts[events]).map(
        //         (subevents) => {
        //           if (
        //             dummyAlertData[section].alerts[events][subevents][0]
        //               .passed === false
        //           ) {
        //             playNotificationSound(
        //               toast,
        //               subevents,
        //               events,
        //               dummyAlertData[section].alerts[events][subevents][0].msg,
        //               section
        //             );
        //           }
        //         }
        //       );
        //     });
        //   });

        // }

        if (data && Object.keys(data).length > 0) {
          Object.keys(data).map((section) => {
            if (
              data[section].alerts &&
              Object.keys(data[section].alerts).length > 0
            ) {
              Object.keys(data[section].alerts).map((events) => {
                if (
                  data[section].alerts[events] &&
                  Object.keys(data[section].alerts[events]).length
                ) {
                  Object.keys(data[section].alerts[events]).map((subevents) => {
                    if (data[section].alerts[events][subevents].length > 0) {
                      if (
                        data[section].alerts[events][subevents][0]?.passed ===
                        false
                      ) {
                        playNotificationSound(
                          toast,
                          subevents,
                          events,
                          data[section].alerts[events][subevents][0].msg,
                          section
                        );
                      }
                    }
                  });
                }
              });
            }
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const BaysApiCall = async () => {
    const requestData = JSON.stringify({
      clientId: param.clientId.toLowerCase(),

      useCase: "WORKFORCESAFETY",
      plantName: "khandala",
      cameraGpId: "all",
      excludeCameraGpIds: ["weighBridge1-left", "weighBridge1-right"],
      excludeEvents: ["weighment"],
    });

    const response = await axios
      .post(
        baseURL + "vision/v1/workforceMonitoring/info/initialize/",
        requestData,
        {
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      )
      .then((response) => {
        let data = response.data.cameraGpIds;
        let totalbays = [];
        let bayCamMap = {};
        data.map((item) => {
          totalbays.push(item.cameraGpId);
          item.cameraInfo.map(async (cam) => {
            cam["hsl"] = await RtspToHslConverter(
              "rtsp://admin:Admin@123@172.16.54.54:554/Streaming/Channels/102/?transportmode=unicast",
              cam.cameraId
            );
            return cam;
          });
          bayCamMap[item.cameraGpId] = item.cameraInfo;
        });
        setBays(totalbays);
        setCurrentCams(bayCamMap);
        let map = {};
        totalbays.map((x) => {
          map[x] = {};
        });
        let feedData = response.data.events;
        console.log("111", feedData);

        totalbays.map((x) => {
          Object.keys(feedData).map((val) => {
            map[x][val] = feedData[val].reduce((acc, s, index) => {
              acc[s] = -1;
              return acc;
            }, {});
          });
        });
        setFeedMap(map);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const LiveSummaryApi = async () => {
    const requestData = JSON.stringify({
      clientId: param.clientId.toLowerCase(),
      useCase: "WORKFORCESAFETY",
      plantName: "khandala",
      cameraGpId: selectedBay,
      endDate: new Date().getTime() + 5.5 * 60 * 60 * 1000,
    });
    const response = await axios
      .post(
        baseURL + "vision/v1/workforceMonitoring/summary/live/",
        requestData,
        {
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      )
      .then((response) => {
        if (
          response.data.summary &&
          Object.keys(response.data.summary).length > 0
        ) {
          let data = response.data.summary;
          if (data && Object.keys(data).length > 0) {
            setFeedMap((prev) => {
              const updatedMap = { ...prev };
              for (const key in updatedMap[selectedBay]) {
                for (const subKey in updatedMap[selectedBay][key]) {
                  updatedMap[selectedBay][key][subKey] = -1;
                }
              }
              for (const key in data) {
                for (const subKey in data[key]) {
                  const { passed, total } = data[key][subKey];
                  if (
                    updatedMap[selectedBay].hasOwnProperty(key) &&
                    updatedMap[selectedBay][key].hasOwnProperty(subKey) //handling inconsistency b/w init and live data
                  ) {
                    updatedMap[selectedBay][key][subKey] =
                      passed == null ? -1 : passed === total ? 0 : 1;
                  }
                }
              }
              return updatedMap;
            });
          }
        } else {
          setFeedMap((prev) => {
            const updatedMap = { ...prev };
            for (const key in updatedMap[selectedBay]) {
              for (const subKey in updatedMap[selectedBay][key]) {
                updatedMap[selectedBay][key][subKey] = -1;
              }
            }
            return updatedMap;
          });
        }
        setTruckInfo({
          truckNumber: response?.data?.vehicleNo,
          timestamp: response?.data?.createdAt,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const weightBridge1Api = async () => {
    const requestData = JSON.stringify({
      clientId: param.clientId.toLowerCase(),
      useCase: "WORKFORCESAFETY",
      plantName: "khandala",
      cameraGpId: selectedBay,
      endDate: new Date().getTime() + 5.5 * 60 * 60 * 1000,
    });
    const response = await axios
      .post(
        baseURL + "vision/v1/workforceMonitoring/summary/live/multiple/",
        requestData,
        {
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      )
      .then((response) => {
        let data = response.data;
        setWeightBridgeData((prev) => {
          let newData = { ...prev };
          for (let key in data) {
            if (Object.entries(data[key]?.summary || {}).length) {
              let { passed, total } = data[key]?.summary?.weighment?.clearance;
              newData[key] = {
                reason: passed == null ? -1 : passed === total ? 0 : 1, // Use === for strict equality check
                truckNo: data[key]?.vehicleNo || "",
                createdAt: data[key]?.createdAt,
              };
            }
          }
          return newData;
        });
        const firstKey = Object.keys(response.data)[0];
        if (firstKey) {
          const createdAtTimestamp =
            (response.data[firstKey].createdAt - 5.5 * 60 * 60) * 1000; // Convert seconds to milliseconds
          // const formattedDateTime =
          //   new Date(createdAtTimestamp).toLocaleDateString() +
          //   " " +
          //   new Date(createdAtTimestamp).toLocaleTimeString();

          setwightBridgeDate(createdAtTimestamp);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setTruckInfo({});
    console.log("tabs", selectedBay);
    if (selectedBay === "weighBridge1") {
      const intervalId1 = setInterval(() => {
        weightBridge1Api();
        LiveAlertsApiWeigh();
      }, 10 * 1000);
      mixpanelWorkforceBayTrack(selectedBay, param.clientId);
      return () => {
        clearInterval(intervalId1);
      };
    } else {
      const intervalId1 = setInterval(() => {
        LiveAlertsApi();
      }, 7 * 1000);

      const intervalId2 = setInterval(() => {
        LiveSummaryApi();
      }, 10 * 1000);
      mixpanelWorkforceBayTrack(selectedBay, param.clientId);
      return () => {
        clearInterval(intervalId2);
        clearInterval(intervalId1);
      };
    }
  }, [selectedBay]);

  useEffect(() => {
    BaysApiCall();
  }, []);

  useEffect(() => {
    if (bays.length > 0) {
      setSelectedBay(bays[0]);
    }
  }, [bays]);

  useEffect(() => {
    if (trucInfo && Object.keys(trucInfo).length > 0) {
      setTruckNo(trucInfo.truckNumber ? trucInfo.truckNumber : "UNKNOWN");
    }
  }, [trucInfo]);

  useEffect(() => {
    setShowCamera([]);

    const fetchData = async () => {
      const requestData = JSON.stringify({
        clientId: "asianpaints",
        useCase: "WORKFORCESAFETY",
        plantName: "khandala",
        cameraGpId: selectedBay,
      });

      const response = await axios
        .post(
          baseURL + "vision/v1/workforceMonitoring/feed/images/",
          requestData,
          {
            credentials: "same-origin",
            headers: {
              "Content-Type": "application/json",
              "X-Auth-Token": auth,
            },
          }
        )
        .then((response) => {
          const data = response.data;

          setShowCamera(data.reverse());
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    };

    const intervalId = setInterval(() => {
      fetchData();
    }, 5000);

    return () => clearInterval(intervalId);
  }, [selectedBay]);

  const imgs = ["3.png", "2.png", "1.png", "3.png", "2.png", "1.png"];

  console.log("ShowCamera", ShowCamera);

  return (
    <div className="px-6 py-4 rounded-xl bg-white flex flex-col gap-5">
      {/* <button onClick={() => handleAudioClick()} className="hidden">
        Play
      </button> */}
      <div className="flex flex-col xl:flex-row gap-3 justify-between">
        <div>
          <div className="flex items-center">
            <p className="text-xl font-medium text-[#3E3C42]">Khandala</p>
          </div>

          <div className="flex gap-4 items-center overflow-x-auto">
            {bays.map((val) => {
              return (
                <div
                  className={`rounded-[32px] px-4 py-[6px] text-[#605D64] text-base cursor-pointer whitespace-nowrap ${
                    selectedBay != val
                      ? "bg-white border border-gray-300"
                      : "bg-[#e2edfe] border border-[#6CA6FC]"
                  }`}
                  onClick={() => setSelectedBay(val)}
                >
                  {"Bay " + val[val.length - 1]}
                </div>
              );
            })}
            <div
              className={`rounded-[32px] px-4 py-[6px] text-[#605D64] text-base whitespace-nowrap cursor-pointer ${
                selectedBay != "weighBridge1"
                  ? "bg-white border border-gray-300"
                  : "bg-[#e2edfe] border border-[#6CA6FC]"
              }`}
              onClick={() => setSelectedBay("weighBridge1")}
            >
              {"Weigh Bridge1"}
            </div>
          </div>
        </div>
        {/*  */}
        {selectedBay != "weighBridge1" ? (
          <div className=" w-full xl:w-fit justify-between px-3 xl:px-6 py-3 h-full self-end  flex gap-3 items-center bg-[#FAFAFA] rounded-[6px] max-w-[80vw] border border-[#EBEBEB] overflow-x-auto">
            <div className="flex gap-2 items-center w-full lg:min-w-[160px] ">
              <p className="text-sm text-[#605D64]">Truck no.</p>
              {!editing ? (
                <p className="text-base font-medium  text-[#3E3C42]">
                  {truckNo}
                </p>
              ) : (
                <div className="w-[150px]">
                  <FloatingInput
                    text="TruckNo"
                    type="text"
                    setDateTime={setTruckNo}
                    value={truckNo}
                  />
                </div>
              )}
            </div>
            <div className="flex gap-2 items-center w-full lg:min-w-[160px] ">
              <p className="text-sm text-[#605D64]">In Time</p>
              <p className="text-base font-medium text-[#3E3C42]">
                {trucInfo?.timestamp
                  ? new Date(
                      (trucInfo?.timestamp - 5.5 * 60 * 60) * 1000
                    ).toLocaleTimeString()
                  : ""}
              </p>
            </div>
            <div className="flex gap-2 items-center w-full lg:min-w-[160px] ">
              <p className="text-sm text-[#605D64]">Date</p>
              <p className="text-base font-medium text-[#3E3C42]">
                {trucInfo?.timestamp
                  ? new Date(trucInfo?.timestamp * 1000).toLocaleDateString()
                  : ""}
              </p>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {selectedBay != "weighBridge1" ? (
        <>
          {" "}
          {/* <div className="self-start px-6 py-3  flex gap-7 items-center bg-[#FAFAFA] rounded-[6px] max-w-[80vw] border border-[#EBEBEB] overflow-x-auto">
            <div className="flex gap-2 items-center min-w-[160px]">
              <p className="text-sm text-[#605D64]">Truck no.</p>
              {!editing ? (
                <p className="text-base font-medium text-[#3E3C42]">
                  {truckNo}
                </p>
              ) : (
                <div className="w-[150px]">
                  <FloatingInput
                    text="TruckNo"
                    type="text"
                    setDateTime={setTruckNo}
                    value={truckNo}
                  />
                </div>
              )}
            </div>
            <div className="flex gap-2 items-center min-w-[160px]">
              <p className="text-sm text-[#605D64]">In Time</p>
              <p className="text-base font-medium text-[#3E3C42]">
                {trucInfo?.timestamp
                  ? new Date(
                      (trucInfo?.timestamp - 5.5 * 60 * 60) * 1000
                    ).toLocaleTimeString()
                  : ""}
              </p>
            </div>
            <div className="flex gap-2 items-center min-w-[160px]">
              <p className="text-sm text-[#605D64]">Date</p>
              <p className="text-base font-medium text-[#3E3C42]">
                {trucInfo?.timestamp
                  ? new Date(trucInfo?.timestamp * 1000).toLocaleDateString()
                  : ""}
              </p>
            </div>
          </div> */}
          <div className="flex flex-col-reverse items-center  min-[900px]:items-start min-[900px]:flex-row gap-4 ">
            {/* <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 min-[1800px]:grid-cols-4 gap-4 h-[250px] sm:h-[60%] max-h-[100vh]  w-full"> */}
            <div className="grid   grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 min-[1800px]:grid-cols-4 gap-4 h-full  w-full">
              {feedMap.hasOwnProperty(selectedBay) &&
                Object.keys(feedMap[selectedBay]).map((val, idx) => {
                  return (
                    <FeedCard
                      parameter={val}
                      reasons={feedMap[selectedBay][val]}
                    />
                  );
                })}
            </div>
            {/* <div className="flex flex-col gap-4 py-4 pr-6 pl-4 rounded-lg bg-[#F5F5F5] h-[250px] sm:h-[100vh] w-[85vw] sm:w-[70vw] min-[900px]:w-[100vw] lg:w-[45vw]  border-2 border-red-500"> */}
            <div className="flex b flex-col gap-4 py-4 pr-6 pl-4 rounded-lg bg-[#F5F5F5] h-full w-[85vw] sm:w-[70vw] min-[900px]:w-[100vw] lg:w-[35vw]  ">
              {ShowCamera.length > 0 ? (
                ShowCamera.map((item, idx) => {
                  const cameraName = Object.keys(item)[0]; // Get the camera name
                  const imageUrl = item[cameraName]; // Get the image URL
                  const timestamp = item.timestamp;
                  return (
                    <div className="flex-1 flex flex-col gap-2 w-full ">
                      <p className="text-[#605D64] text-sm font-medium whitespace-nowrap">
                        {cameraName}
                      </p>
                      <div className="relative bg-black h-full w-full flex justify-center items-center rounded-xl">
                        <img
                          src={imageUrl}
                          alt=""
                          className="max-w-full max-h-full h-[260px] flex justify-center items-center"
                          crossOrigin="anonymous"
                        />

                        <div className="absolute top-2 right-2 bg-black rounded-md opacity-70 p-[2px]">
                          <p className="text-white text-xs font-semibold bg-black rounded-lg">
                            {new Date(
                              (timestamp - 5.5 * 60 * 60) * 1000
                            ).toLocaleDateString() +
                              " " +
                              new Date(
                                (timestamp - 5.5 * 60 * 60) * 1000
                              ).toLocaleTimeString()}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="flex-1 flex flex-col gap-2 w-full">
                  <p className="text-[#605D64] text-sm font-medium whitespace-nowrap">
                    {/* {val.name} */}
                  </p>
                  <div className="relative bg-black h-full w-full flex justify-center items-center rounded-xl">
                    <Skeleton height={"250px"} width={"300px"} />
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : (
        <div className="flex flex-col gap-6">
          <div className="flex flex-col min-[815px]:flex-row items-start min-[815px]:items-center gap-[40px]">
            <p className="text-[#605D64] text-[18px]  font-medium">
              Weigh Bridge1 Inspection Protocol
            </p>
            {/* <div className="px-4 py-1 rounded-md bg-[#FAFAFA] border border-[#EBEBEB] flex gap-7 items-center whitespace-nowrap">
              <p className="text-[#605D64] text-sm flex gap-2 items-center">
                Date{" "}
                <p className="text-[#3E3C42] text-base font-medium">
                  {new Date(weightBridgeDate).toLocaleDateString()}

               
                </p>
              </p>
              <p className="text-[#605D64] text-sm flex gap-2 items-center">
                Time{" "}
                <p className="text-[#3E3C42] text-base font-medium">
                  {" "}
                  {new Date(weightBridgeDate).toLocaleTimeString()}
                </p>
              </p>
            </div> */}
          </div>
          <div className="flex flex-col-reverse lg:flex-row gap-8 items-start h-auto">
            <div className="flex flex-col sm:flex-row lg:flex-col justify-between h-full gap-4">
              <WeighmentCard
                createdAt={weightBridgeData?.["weighBridge1-left"]?.createdAt}
                reason={weightBridgeData?.["weighBridge1-left"]?.reason}
                num={"Left Bridge"}
                truckNo={weightBridgeData?.["weighBridge1-left"]?.truckNo}
              />
              <WeighmentCard
                createdAt={weightBridgeData?.["weighBridge1-right"]?.createdAt}
                reason={weightBridgeData?.["weighBridge1-right"]?.reason}
                num={"Right Bridge"}
                truckNo={weightBridgeData?.["weighBridge1-right"]?.truckNo}
              />
            </div>
            <div className="p-4 rounded-lg flex flex-col gap-2 bg-[#F5F5F5] w-[30vw] ">
              <p className="text-[#605D64] font-medium text-sm">
                RMG Weigh Bridge Out
              </p>
              <div className="rounded-lg w-full h-auto ">
                {ShowCamera.length > 0 ? (
                  ShowCamera.map((item, idx) => {
                    const cameraName = Object.keys(item)[0]; // Get the camera name
                    const imageUrl = item[cameraName]; // Get the image URL
                    return (
                      <div className="flex-1 flex flex-col gap-2 w-full">
                        <p className="text-[#605D64] text-sm font-medium whitespace-nowrap"></p>
                        <div className="relative bg-black h-full w-full flex justify-center items-center rounded-xl">
                          {/* <img
                  className="w-[60vw] lg:w-[40vw] rounded-xl"
                  src={`/WorkforceSafetyIcons/images/${imgs[idx]}`}
                /> */}
                          <img
                            src={imageUrl}
                            alt=""
                            className="max-w-full max-h-full h-[280px] flex justify-center items-center"
                          />
                          {/* <video
                      muted
                      autoPlay
                      loop
                      crossOrigin="anonymous"
                      className="w-[60vw] lg:w-[40vw] rounded-xl"
                    >
                      <source src={val.rtsp} type="video/mp4" />
                    </video> */}
                          {/* <div className="max-w-full max-h-full h-auto flex justify-center items-center">
                      {val?.hls ? (
                        <ReactPlayer
                          url={val?.hsl}
                          playing={true}
                          muted={true}
                          height="70%"
                          width="70%"
                        />
                      ) : (
                        <Skeleton height={"250px"} width={"300px"} />
                      )}
                    </div> */}

                          {/* <div className="absolute bottom-2 left-2 bg-black rounded-md opacity-70 p-[2px]">
                      <p className="text-white text-xs font-semibold bg-black rounded-lg">
                        {new Date().toLocaleTimeString()}
                      </p>
                    </div> */}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="flex-1 flex flex-col gap-2 w-full">
                    <p className="text-[#605D64] text-sm font-medium whitespace-nowrap">
                      {/* {val.name} */}
                    </p>
                    <div className="relative bg-black h-full w-full flex justify-center items-center rounded-xl">
                      <Skeleton height={"250px"} width={"300px"} />
                    </div>
                  </div>
                )}
                {/* <video
                  muted
                  autoPlay
                  loop
                  crossOrigin="anonymous"
                  className="w-full lg:w-[40vw] rounded-xl"
                >
                  <source
                    src={
                      "https://asianpaintsvideos1.s3.ap-south-1.amazonaws.com/weighment_light+(1).mp4"
                    }
                    type="video/mp4"
                  />
                
                </video> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Feed;
