import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider, IconButton, Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { deactiveDeviceApi } from "../../Tabs/DevicesPage";
import { useToast } from "@chakra-ui/react";
import NavContext from "../../../NavContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const MuiTheme = createTheme();

const View = ({ row, setShowDevicesTab, setSelectedDevice }) => {
  const handleView = () => {
    setShowDevicesTab((prev) => "viewDevice");
    setSelectedDevice(row);
  };

  return (
    <IconButton
      aria-label="edit"
      sx={{ color: "#1E88E5" }}
      onClick={() => {
        handleView();
      }}
    >
      <VisibilityIcon />
    </IconButton>
  );
};

const Update = ({ row, setShowDevicesTab, setSelectedDevice }) => {
  const handleUpdate = () => {
    setShowDevicesTab((prev) => "updateDevice");
    setSelectedDevice(row);
  };
  return (
    <IconButton
      aria-label="edit"
      sx={{ color: "#1E88E5", cursor: "pointer" }}
      onClick={() => {
        handleUpdate();
      }}
      disabled={row?.deviceInfo?.status === "INACTIVE"}
    >
      <EditNoteIcon />
    </IconButton>
  );
};

const Download = ({ row, setShowDevicesTab }) => {
  const handleDownload = () => { };
  return (
    <div onClick={handleDownload}>
      <img src="/download.svg" />
    </div>
  );
};

const ToggleSwitch = ({ row, toast, auth, getDeviceApi }) => {
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    setChecked(row.status != "INACTIVE");
  }, [row]);

  const handleChange = (event) => {
    if (event.target.checked) {
      deactiveDeviceApi(row, toast, false, auth, getDeviceApi);
    } else {
      deactiveDeviceApi(row, toast, true, auth, getDeviceApi);
    }
  };

  const GreenSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
      color: "#378805",
      "&:hover": {
        backgroundColor: alpha("#378805", theme.palette.action.hoverOpacity),
      },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#378805",
    },
  }));

  return (
    <div>
      {/* Switch */}
      <GreenSwitch
        checked={checked}
        onChange={handleChange}
        inputProps={{ "aria-label": "controlled" }}
      />
    </div>
  );
};

const DevicesTable = ({
  devices,
  setShowDevicesTab,
  setSelectedDevice,
  getDeviceApi,
}) => {
  const toast = useToast();
  const { auth } = useContext(NavContext);

  console.log(devices);
  const rowData = devices;

  const columns = [
    {
      field: "product",
      headerName: <div className="flex">
        <img src="/deviceTableIcon.svg" />
        Devices
      </div>,
      valueGetter: ({ row }) => row?.deviceInfo?.deviceName,
      renderCell: ({ value }) => {
        return (
          <div className="w-full flex gap-1 ">
            <img
              className=" w-[20px] h-[20px] "
              src="/camera.svg"
              alt="coins"
            />
            {value}
          </div>
        );
      },
    },
    {
      field: "deviceInstanceName",
      headerName: <div className="flex">
        <img src="/deviceInstanceIcon.svg" />
        Device Instance name
      </div>,
      valueGetter: ({ row }) => row?.deviceNameList?.join(", "),
      // valueGetter: ({ row }) => {
      //   row.clientsPlantInfo.map((x) => x).toString();
      // },


      renderCell: ({ row }) => {
        let x = row?.deviceNameList?.join(", ")

        return (
          <Tooltip title={x} placement="top">
            <div className="w-full flex gap-1 ">
              <p className="font-bold">{row?.deviceNameList?.length}</p>
              {x}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "client",
      headerName: <div className="flex">
        <img src="/clientsIcon.svg" />
        Clients count
      </div>,
      valueGetter: ({ row }) => row?.clientList?.length,
      // valueGetter: ({ row }) => {
      //   row.clientsPlantInfo.map((x) => x).toString();
      // },


      renderCell: ({ row }) => {
        let x = row?.clientList?.join(", ");

        return (
          // <Tooltip title={x} placement="top">  
          <div className="w-full flex gap-1 ">
            <p className="font-bold">{row?.clientList?.length}</p>
            {/* {x} */}
          </div>
          // </Tooltip>
        );
      },
    },
    {
      field: "location",
      headerName: <div className="flex">
        <img src="/location.svg" />
        Locations
      </div>,
      valueGetter: ({ row }) => row?.locationList?.length,
      // valueGetter: ({ row }) => {
      //   row.clientsPlantInfo.map((x) => x).toString();
      // },


      renderCell: ({ row }) => {
        let x = row?.locationList?.join(", ");

        return (
          // <Tooltip title={x} placement="top">
          <div className="w-full flex gap-1 ">
            <p className="font-bold">{row?.locationList?.length}</p>
            {/* {x} */}
          </div>
          // </Tooltip>
        );
      },
    },
    {
      field: "deviceGroup",
      headerName: <div className="flex">
        <img src="/deviceGroupTableIcon.svg" />
        Device Group
      </div>,
      valueGetter: ({ row }) => row?.deviceInfo?.deviceCategory,
      renderCell: ({ value }) => {
        return (
          <Tooltip title={value} placement="top">
            <div className="w-full flex gap-1 ">
              <img
                className=" w-[20px] h-[20px] "
                src="/camera.svg"
                alt="coins"
              />
              {value}
            </div>
          </Tooltip>

        );
      },
    },
    {
      field: "createdAt",
      headerName: <div className="flex">
        <img src="/date.svg" />
        Created on
      </div>,
      valueGetter: ({ row }) => row?.deviceInfo?.createdAt,
      // valueFormatter: ({ row }) => {
      //   return new Date(row?.deviceInfo?.creationDate)?.toLocaleString();
      // },
      renderCell: ({ row }) => {
        function padZero(number) {
          return (number < 10 ? '0' : '') + number;
        }

        const date = new Date(row?.deviceInfo?.createdAt);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so add 1
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const formattedTime = padZero(hours) + ":" + padZero(minutes) + ":" + padZero(seconds);
        const dateString = `${day}/${month}/${year}`

        const formattedDate = new Date(row.deviceInfo.createdAt).toDateString().split(" ")[2] +
          " " +
          new Date(row.deviceInfo.createdAt).toDateString().split(" ")[1] +
          " '" +
          new Date(row.deviceInfo.createdAt).toDateString().split(" ")[3].slice(2, 4)
        return (
          <Tooltip title={formattedDate + " " + formattedTime} placement="top">
            <div className="w-full flex gap-2 whitespace-nowrap overflow-hidden text-ellipsis">
              {formattedDate + " " + formattedTime}
            </div>
          </Tooltip>
        );
      }
    },
    {
      field: "updatedAt",
      headerName: <div className="flex">
        <img src="/date.svg" />
        Updated on
      </div>,
      valueGetter: ({ row }) => row?.deviceInfo?.updatedAt,
      renderCell: ({ row }) => {
        function padZero(number) {
          return (number < 10 ? '0' : '') + number;
        }
        const date = new Date(row.deviceInfo.updatedAt);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based, so add 1
        const day = date.getDate().toString().padStart(2, '0');
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const formattedTime = padZero(hours) + ":" + padZero(minutes) + ":" + padZero(seconds);
        const dateString = `${day}/${month}/${year}`

        const formattedDate = new Date(row.deviceInfo.updatedAt).toDateString().split(" ")[2] +
          " " +
          new Date(row.deviceInfo.updatedAt).toDateString().split(" ")[1] +
          " '" +
          new Date(row.deviceInfo.updatedAt).toDateString().split(" ")[3].slice(2, 4)
        return (
          <Tooltip title={formattedDate + " " + formattedTime} placement="top">
            <div className="w-full flex gap-2 whitespace-nowrap overflow-hidden text-ellipsis">
              {formattedDate + " " + formattedTime}
            </div>
          </Tooltip>
        );
      },

    },
    {
      field: "status",
      headerName: <div className="flex">
        <img src="/superadmin/clients/timer.svg" alt="" />
        Status
      </div>,
      valueGetter: ({ row }) => row?.deviceInfo?.status,
      renderCell: ({ row }) => {
        return (
          <Tooltip title={row?.deviceInfo?.status.toLowerCase()} placement="top">
            <div className="w-full flex item-center gap-2 cursor-pointer ">
              {row?.deviceInfo?.status === "DRAFT" ? (
                <img
                  src={`/Common/draft.svg`}
                  className="w-5 h-5 rounded-full"
                  alt="status"
                />
              ) : row?.deviceInfo?.status === "ACTIVE" ? (
                <CheckCircleIcon sx={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "9999px",
                  color: "green",
                }} />
              ) : (
                <CancelIcon sx={{
                  width: "20px",
                  height: "20px",
                  borderRadius: "9999px",
                  color: "#E46962",
                }} />
              )}
              <p className="capitalize">{row?.deviceInfo?.status?.toLowerCase()}</p>
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "links",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <View
          row={row}
          setShowDevicesTab={setShowDevicesTab}
          setSelectedDevice={setSelectedDevice}
        />
      ),
    },
    {
      field: "links2",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Update
          row={row}
          setShowDevicesTab={setShowDevicesTab}
          setSelectedDevice={setSelectedDevice}
        />
      ),
    },
    // {
    //   field: "links3",
    //   headerName: "",
    //   renderCell: ({ row }) => (
    //     <Download row={row} setShowDevicesTab={setShowDevicesTab} />
    //   ),
    // },
    // {
    //   field: "links4",
    //   headerName: "",
    //   renderCell: ({ row }) => (
    //     <ToggleSwitch
    //       row={row}
    //       toast={toast}
    //       auth={auth}
    //       getDeviceApi={getDeviceApi}
    //     />
    //   ),
    // },
  ];

  const headerClass = "text-sm font-normal text-[#79767D] bg-[#DEF]";
  const cellClass = "text-sm font-normal text-[#3E3C42] whitespace-nowrap";
  const flexMap = [1.3, 2.3, 1, 1, 1.3, 1.3, 1.3, 1.2, 0.1, 0.1];
  columns.map((val, idx) => {
    val["headerClassName"] = headerClass;
    val["cellClassName"] = cellClass;
    val["flex"] = flexMap[idx];
  });

  return (
    <div className="overflow-x-auto mt-2">
      <ThemeProvider theme={MuiTheme}>
        <DataGrid
          rows={rowData}
          columns={columns}
          getRowId={(row) => row.deviceInfo.deviceId}
          initialState={{
            sorting: {
              sortModel: [{ field: "updatedAt", sort: "desc" }],
            },
          }}
          sx={{ minWidth: "1000px" }}
        />
      </ThemeProvider>
    </div>
  );
};

export default DevicesTable;
