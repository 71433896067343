import FloatingInput from "../../../../util/VisionUtils/FloatingInput";
import SecondaryButton from "../../../../util/Buttons/SecondaryButton";
import HistoryTable from "../Tables/HistoryTable";

const CamHistory = () => {
  return (
    <div className="p-6 flex flex-col gap-6">
      <p>Camera 1 Feed History</p>
      <div className="flex gap-3 items-center overflow-x-auto max-w-full pt-2">
        <div className="w-fit">
          <FloatingInput text="date" type="date" />
        </div>
        <div className="w-fit">
          <FloatingInput text="start time" type="time" />
        </div>
        <div className="w-fit">
          <FloatingInput text="end time" type="time" />
        </div>
        <SecondaryButton text={"Apply"} width={"fit-content"} height={"40px"} />
      </div>
      <HistoryTable />
    </div>
  );
};

export default CamHistory;
