import { EditIcon, SmallCloseIcon } from "@chakra-ui/icons";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Textarea,
  useToast,
  list,
  useDisclosure,
  ModalCloseButton,
  Tooltip,
} from "@chakra-ui/react";
import axios from "axios";
import { useContext, useEffect, useRef, useState } from "react";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import { slabSizingJsplBaseURl } from "../SlabSizing";

let wordLimit = 500;

const Feedback = ({ slab, idx, comment, setHistory }) => {
  const toast = useToast();
  const [exceedLimit, setExceedLimit] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const clientIdbf = "sesa";
  const BASE_URL_FOR_BF = "123";
  const textRef = useRef();
  const [loader, setLoader] = useState(false);
  const updateParentTableData = () => {
    setHistory((prev) => {
      let updatedData = prev?.map((element) => {
        if (element?._id === idx) {
          return {
            ...element,
            operators_comment: textRef?.current?.value,
          };
        } else {
          return element;
        }
      });
      // console.log(updatedData, idx);
      return updatedData;
    });
  };

  const apiCall = async () => {
    const requestData = {
      _id: idx,
      operators_comment: textRef?.current?.value,
    };

    try {
      if (exceedLimit || textRef?.current?.value?.length === 0) {
        throw new Error("Invalid data");
      }

      const response = await axios.post(
        `${slabSizingJsplBaseURl}report/`,
        requestData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response.data.success === "Record updated successfully") {
        toast({
          title: "Feedback Submitted",
          description: "Your feedback has been received",
          status: "success",
          position: "top-right",
          duration: 3000,
          isClosable: true,
        });
        updateParentTableData();
        onClose();
      } else {
        throw new Error("Feedback submission failed");
      }
      setLoader(false);
    } catch (error) {
      console.error("Error:", error);

      toast({
        title: "Error",
        description: error.message || "An error occurred",
        status: "error",
        position: "top-right",
        duration: 3000,
        isClosable: true,
      });
      setLoader(false);
    }
  };

  const textChangeHandler = (e) => {
    const inputValue = e.target.value;
    // Split based on spaces, full stops, and common special characters
    const words = inputValue.split(/[\s.!,;?]+/).filter(Boolean);

    if (words.length <= wordLimit) {
      if (exceedLimit) setExceedLimit(false);
    } else {
      setExceedLimit(true);
    }
  };

  return (
    <>
      <Tooltip label={comment} placement="top">
        <div
          className="w-full max-w-[150px]  flex justify-center items-center gap-2"
          onClick={() => {
            onOpen();
          }}
        >
          <p className="overflow-clip text-ellipsis">{comment}</p>

          <EditIcon />
        </div>
      </Tooltip>

      <Modal isOpen={isOpen} onClose={onClose} isCentered="true">
        <ModalOverlay />
        <ModalContent style={{ borderRadius: "12px" }} maxW="400px">
          <div className=" flex justify-end p-2 text-[#79767D]">
            {" "}
            <SmallCloseIcon onClick={onClose} className="cursor-pointer" />
          </div>
          <ModalBody pos="relative" p="12px" rounded="12px">
            <div className="flex flex-col gap-6">
              <div className="flex flex-col relative gap-2">
                <p className="text-[#79767D] text-[14px]">
                  Operator's feedback
                </p>
                <p className="text-[#79767D] text-[12px] text-base">
                  Slab ID- <span className="text-[#000] ">{slab}</span>
                </p>
                <Textarea
                  placeholder="Please explain... "
                  mt="4px"
                  mb="4px"
                  isInvalid={exceedLimit}
                  onChange={textChangeHandler}
                  defaultValue={comment}
                  ref={textRef}
                />
                <p className="absolute top-0 right-0 text-xs text-gray-600">
                  Words:{" "}
                  {
                    textRef?.current?.value.split(/[\s.!,;?]+/).filter(Boolean)
                      .length
                  }{" "}
                  {`(Limit ${wordLimit})`}
                </p>
              </div>
              <div className="flex justify-end">
                <PrimaryButton
                  text={loader ? "Loading" : "Submit"}
                  width={"fit-content"}
                  disable={loader}
                  onClick={() => {
                    setLoader(true);
                    apiCall();
                  }}
                />
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Feedback;
