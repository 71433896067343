const colors = {
  dusty: "#FFE082",
  healthy: "#9CFF88",
  hot: "#FFCDD2",
  hot_Dusty: "#FFEDCC",
  negative: "#E0E0E0",
};

const tagColor = {
  dusty: "#FFB300",
  healthy: "#4CAF50",
  hot: "#ff6460",
  hot_Dusty: "#ef6f12",
  negative: "#000000",
};

const tagName = {
  dusty: "Dusty",
  healthy: "Healthy",
  hot: "Hot",
  hot_Dusty: "Hot & Dusty",
  negative: "Negative",
};

// fffcf2

const ModalCard = ({ dusty, hot, health }) => {
  return (
    <div
      className="flex flex-col sm:flex-row justify-evenly gap-6 p-4 py-8 rounded w-full"
      style={{ backgroundColor: colors[health] }}
    >
      <div className="relative flex flex-col gap-1">
        <p className="text-sm font-medium text-[#3E3C42]">Health:</p>
        <div className="flex gap-1 items-center">
          <div
            className="w-[5px] h-full"
            style={{ backgroundColor: tagColor[health] }}
          ></div>
          <p className="text-[#605D64] font-medium text-sm">
            {tagName[health]}
          </p>
        </div>
        {health != "healthy" && (
          <p className="absolute left-0 top-[-26px] px-2 border border-[#DC362E]  py-1  h-fit rounded-[3px] bg-[#FFCDD2] text-[#DC362E] text-xs font-medium">
            Critical
          </p>
        )}
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex gap-2 items-center">
          <p className="text-[#3E3C42] text-sm font-medium whitespace-nowrap">
            Dusty index
          </p>
        </div>
        <div className="flex gap-2 items-center">
          <p className="text-[#605D64] text-sm font-medium">{dusty}</p>
        </div>
      </div>
      <div className="flex flex-col gap-1">
        <div className="flex gap-2 items-center">
          <p className="text-[#3E3C42]  text-sm font-medium whitespace-nowrap">
            Hot index
          </p>
        </div>
        <div className="flex gap-2 items-center">
          <p className="text-[#605D64] text-sm font-medium">{hot}</p>
        </div>
      </div>
    </div>
  );
};

export default ModalCard;
