export const formatDateTimeUTC = (epochTimestamp) => {
  const isMilliseconds = epochTimestamp >= 1000000000000;

  const date = new Date(
    isMilliseconds ? epochTimestamp : epochTimestamp * 1000
  );

  const formattedDate = date.toLocaleDateString("en-IN", {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour12: false,
    timeZone: "UTC",
  });

  const formattedTime = date.toLocaleTimeString("en-IN", {
    hour12: false,
    timeZone: "UTC",
  });

  return `${formattedDate} ${formattedTime}`;
};

export const formatDateTimeLocale = (epochTimestamp) => {
  const isMilliseconds = epochTimestamp >= 1000000000000;

  const date = new Date(
    isMilliseconds ? epochTimestamp : epochTimestamp * 1000
  );
  const formattedDate = date.toLocaleDateString(undefined, {
    year: "2-digit",
    month: "2-digit",
    day: "2-digit",
    hour12: false,
  });
  const formattedTime = date.toLocaleTimeString(undefined, {
    hour12: false,
  });
  return `${formattedDate} ${formattedTime}`;
};

export const formatForDateTimePickerLocale = (date) => {
  // multiply by 60000 cos getTimezoneOffset returns the difference in minutes
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, 16);
};

export const capitalizeCamelCaseString = (input) => {
  if (typeof input !== "string") {
    throw new Error("Input should be a string");
  }

  const words = input.split(/(?=[A-Z])/).join(" ");

  const capitalizedWords = words
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return capitalizedWords;
};

export const getCameraOptions = (flareStackIds, initialData) => {
  const uniqueCameras = new Map();
  initialData
    .filter((el) => flareStackIds.includes(el.cameraGpId))
    .flatMap((el) => el.cameraInfo)
    .forEach((camera) => {
      if (!uniqueCameras.has(camera.name)) {
        uniqueCameras.set(camera.name, camera.cameraId);
      }
    });

  return Array.from(uniqueCameras, ([name, value]) => ({ name, value }));
};

export const getFlareTypeOptions = () => {
  return [
    { name: "Very High Flare", value: "veryHighFlare" },
    { name: "High Flare", value: "highFlare" },
    { name: "Acceptable Flare", value: "acceptableFlare" },
    { name: "Low Flare", value: "lowFlare" },
  ];
};
