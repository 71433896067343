import { useWindowSize } from "@uidotdev/usehooks";
import ReactApexChart from "react-apexcharts";

let color = {
    size: [
        "#E46962",
        "#FFD51B",
    ],
    color: ["#79767D", "#000000"],
};

const StackBarChart = ({ data, type }) => {

    const size = useWindowSize();
    if(!data)return;
    let graphData = {};
    let times = [];
    data?.map((i) => {
        times.push(i?.timeStamp);
    });
    const labels = Object.keys(data[0][type]);
    labels?.map((i) => {
        graphData[i] = [];
    });

    data?.map((i) => {
        Object.keys(i[type])?.map((j) => {
            graphData[j].push(i[type][j]);
        });
    });

    const series = [];
    Object.keys(graphData)?.map((i) => {
        series.push({
            name: i,
            data: graphData[i],
        });
    });

    let newTimes = [];

    let prev = -1;

    // times?.forEach((i) => {
    //     const date = new Date(i);
    //     const dayOfMonth = String(date.getDate()).padStart(2, "0");
    //     const monthsAbbreviated = [
    //         "Jan",
    //         "Feb",
    //         "Mar",
    //         "Apr",
    //         "May",
    //         "Jun",
    //         "Jul",
    //         "Aug",
    //         "Sep",
    //         "Oct",
    //         "Nov",
    //         "Dec",
    //     ];
    //     const monthAbbreviated = monthsAbbreviated[date.getMonth()];

    //     if (prev == -1) {
    //         prev = monthAbbreviated;
    //         newTimes.push(`${dayOfMonth} ${monthAbbreviated}`);
    //         //return `${dayOfMonth} ${monthAbbreviated}`;
    //     }
    //     else {
    //         if(monthAbbreviated == prev){
    //             newTimes.push(`${dayOfMonth}`);
    //         }
    //         else {
    //             prev = monthAbbreviated;
    //             newTimes.push(`${dayOfMonth} ${monthAbbreviated}`);
    //             // return `${dayOfMonth} ${monthAbbreviated}`;
    //         }
    //     }
    // })

    const options = {
        chart: {
            toolbar: {
                show: false,
            },
            type: "bar",
            stacked: true,
            stackType: "100%",
        },
        title: {
            text: 'No. of Defects',
            align: 'center',
            style: {
                fontSize: '16px',
                fontWeight: 'medium',
                fontFamily: 'Arial, sans-serif',
            },
        },
        plotOptions: {
            bar: {
                // columnWidth: '40px',
            }
        },
        colors: [
            "#4caf50",
            "#5193f6",
            "#E46962",
            "#1c56ac",
            "#e91e63",
            "#00bcd4",
            "#8bc34a",
            "#9c27b0",
            "#673ab7",
            "#ff9800",
            "#4caf50",
            "#795548",
        ],
        
        xaxis: {
            categories: times,
            type: "datetime",
            // axisTicks: {
            //     show: true,
            //     borderType: 'solid',
            //     color: '#707070',
            //     height: 6,
            //     offsetX: 0,
            //     offsetY: 0
            // },
            labels: {
                show: true,
                // formatter: function (value) {
                //     const date = new Date(value);

                //     // Get the day of the month with leading zero
                //     const dayOfMonth = String(date.getDate()).padStart(2, "0");

                //     // Get the abbreviated month name
                //     const monthsAbbreviated = [
                //         "Jan",
                //         "Feb",
                //         "Mar",
                //         "Apr",
                //         "May",
                //         "Jun",
                //         "Jul",
                //         "Aug",
                //         "Sep",
                //         "Oct",
                //         "Nov",
                //         "Dec",
                //     ];
                //     const monthAbbreviated = monthsAbbreviated[date.getMonth()];

                //     return `${dayOfMonth} ${monthAbbreviated}`;
                // },
                // style: {
                //     fontSize: "14px",
                // },
            },
        },
        fill: {
            opacity: 1,
        },
        legend: {
            show: true,
            position: "top",
            offsetX: -30,
            offsetY: 0,
            fontSize: "12px",
            labels: {
                colors: "#79767D",
            },
            markers: {
                width: 12,
                height: 12,
                radius: 10,
            },
            alignLabels: true,
            itemMargin: {
                horizontal: 2,
                vertical: 5,
            },
        },
        // legend: {
        //     show: true,
        //     position: "top",
        //     fontSize: "12px",
        // },
    };
    return (
        // <div className={`h-full w-full ${size.width < 700 ? "overflow-x-scroll" : "overflow-hidden"
        //     }`}>
        <div className="min-w-[400px] w-full">
            <ReactApexChart
                // width={size.width < 1024 ? "700px" : "100%"}
                options={options}
                series={series}
                type="bar"
                height={300}
            />
        </div>
    );
};

export default StackBarChart;