// api's for specific element page

import axios from "axios";
import { isfOptimizerHzlBaseUrl } from "../IsfOptimizer";
import { apiErrorHandler } from "../utilis/errorHandler";
// import { dummyZincPercentInSlagData } from "./dummyData";

// to get specific element data
export const getSpecificElementDataApi = async (
  auth = null,
  requestData = null,
  setLoading,
  toast,
  elementName = null,
  elementController
) => {
  try {
    setLoading(true);
    const res = await axios.get(
      `${isfOptimizerHzlBaseUrl}apis/${elementName}/`,
      // requestData,
      {
        // credentials: "same-origin",
        // headers: {
        //   "Content-Type": "application/json",
        //   "X-Auth-Token": auth,
        // },
        // signal: elementController?.signal,
      }
    );
    const data = res?.data;
    if (data) {
      return {
        success: true,
        data,
      };
    } else {
      throw new Error("Error fetching data");
    }
  } catch (error) {
    const errorMessage = apiErrorHandler(error);
    if (errorMessage !== "Cancelled by user") {
      toast({
        title: errorMessage || "Server Error",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    }
    throw new Error(errorMessage);
  } finally {
    setLoading(false);
  }
};
