import {
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { Close } from "@mui/icons-material";
import React, { useState } from "react";

export const ZoomedImageModal = ({
  imageUrl,
  closeModal,
  openModal,
  height = "50vh",
}) => {
  return (
    <Modal
      isOpen={openModal}
      onClose={closeModal}
      isCentered="true"
      returnFocusOnClose={false}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent
        style={{ borderRadius: "12px" }}
        maxW={["100vw", "100vw", "90vw", "90vw", "60vw"]}
        width={["100vw", "100vw", "90vw", "90vw", "60vw"]}
        maxH={["100vh", "100vh", "90vh", "90vh", "90vh"]}
      >
        <ModalHeader
          padding="0px"
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          position={"relative"}
        >
          <IconButton
            position={"absolute"}
            rounded={"full"}
            bg={"white"}
            cursor={"pointer"}
            top={-5}
            icon={<Close sx={{ color: "gray" }} />}
            onClick={closeModal}
            boxShadow={"md"}
          />
        </ModalHeader>
        <ModalBody padding={[3, 3, 6, 6, 6, 6]}>
          {imageUrl || imageUrl != "" ? (
            <img src={imageUrl} alt="" className="w-full" />
          ) : (
            <img src="" alt="" />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
