import { useContext, useEffect, useMemo, useState } from "react";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import { Select, Skeleton, Spinner } from "@chakra-ui/react";
import {
  downloadReportDataApi,
  getIndividualBurnersDataApi,
  getThermocoupleTemperatureReportDataApi,
  getTotalSulphurAndOverallBeltTemperatureApi,
} from "../services/sinterbed.api";
import {
  CommanMultipleYAxisLineChart,
  TotalSulphurAndOverallBeltTemperatureChart,
} from "../components/CommanCharts";
import { FileDownloadOutlined, MoodBad } from "@mui/icons-material";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import useFetchData from "../hooks/useFetchData";
import { useParams } from "react-router-dom";
import { SinterBedContext } from "../components/SinterBedContext";
import NavContext from "../../NavContext";
import { saveAs } from "file-saver";
import {
  formatForDateTimePickerLocale,
  getAggregationInterval,
} from "../utilis/utilityFunctions";

const FilterPanel = ({
  loading = false,
  buttonText = "Apply",
  fetchData,
  basis = "",
}) => {
  // variable, params and refs
  const { clientId } = useParams();
  const { auth } = useContext(NavContext);
  const { initialData } = useContext(SinterBedContext);
  const [downloading, setDownloading] = useState(false);

  const options = useMemo(
    () => [
      {
        value: "maxTemp",
        name: "Maximum Temperature",
      },
      {
        value: "avgTemp",
        name: "Average Temperature",
      },
      {
        value: "minTemp",
        name: "Minimum Temperature",
      },
    ],
    []
  );

  const getInitialState = (hours = 0, days = 0) => {
    const currentDate = new Date();
    const adjustedDate = new Date(
      currentDate.getTime() -
        hours * 60 * 60 * 1000 -
        days * 24 * 60 * 60 * 1000
    );
    return {
      fromTime: formatForDateTimePickerLocale(adjustedDate),
      toTime: formatForDateTimePickerLocale(currentDate),
      ...(basis === "individualburner" && { type: "maxTemp" }),
    };
  };

  // const initialState = getInitialState(1); // hrs
  const initialState = getInitialState(0, 1); // days

  // state
  const [filters, setFilters] = useState(initialState);

  // utility functions
  const getRequestData = (isDownload = false) => {
    const interval = getAggregationInterval(filters.fromTime, filters.toTime);
    const startDate = new Date(filters.fromTime).getTime();
    const endDate = new Date(filters.toTime).getTime();

    const tempRequestData = {
      clientId,
      useCase: "SINTERBED",
      plantName: "chanderia",
      startDate,
      endDate,
      cameraId: initialData?.cameraGpId,
      interval,
    };

    if (isDownload) {
      switch (basis) {
        case "individualburner":
          tempRequestData.type = filters.type;
          tempRequestData.basis = 1;
          break;
        case "thermocouple":
          tempRequestData.basis = 2;
          break;
        case "overallbelt":
          tempRequestData.basis = 3;
          break;
        default:
          break;
      }
    } else {
      if (basis === "individualburner") {
        tempRequestData.type = filters.type;
      }
    }

    return tempRequestData;
  };

  const handleApplyClick = async () => {
    try {
      const requestData = getRequestData && getRequestData();
      await fetchData(requestData);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadReport = async () => {
    try {
      setDownloading((prev) => true);
      const requestData = getRequestData && getRequestData(true);
      const response = await downloadReportDataApi(auth, requestData);
      const blob = new Blob([response.data], {
        type: "application/octet-stream",
      });
      const fileName = `${basis}_reportdata_${filters?.fromTime.substring(
        0,
        10
      )}_to_${filters?.toTime.substring(0, 10)}.xlsx`;
      saveAs(blob, fileName);
    } catch (error) {
      console.error(error);
    } finally {
      setDownloading((prev) => false);
    }
  };

  useEffect(() => {
    handleApplyClick();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-row items-center gap-2">
      {basis === "individualburner" && (
        <div className="min-w-[130px]">
          <Select
            borderColor={"#CAC5CD"}
            variant="outline"
            fontSize={"sm"}
            fontWeight={"medium"}
            textColor={"#605D64"}
            rounded={"base"}
            name="type"
            onChange={(event) =>
              setFilters((prev) => ({ ...prev, type: event.target.value }))
            }
            value={filters.type}
          >
            {options?.map((el) => {
              return (
                <option
                  key={el.value}
                  value={el.value}
                >
                  {el.name}
                </option>
              );
            })}
          </Select>
        </div>
      )}
      <div>
        <FloatingInput
          text="From"
          type="datetime-local"
          value={filters.fromTime}
          setDateTime={(value) =>
            setFilters((prev) => ({ ...prev, fromTime: value }))
          }
          min={"2021-01-01T00:00"}
          max={filters.toTime || formatForDateTimePickerLocale(new Date())}
        />
      </div>
      <div>
        <FloatingInput
          text="To"
          type="datetime-local"
          value={filters.toTime}
          setDateTime={(value) =>
            setFilters((prev) => ({ ...prev, toTime: value }))
          }
          min={filters.fromTime}
          max={filters.toTime || formatForDateTimePickerLocale(new Date())}
        />
      </div>
      <SecondaryButton
        onClick={handleApplyClick}
        text={loading ? <Spinner /> : `${buttonText}`}
        height={"40px"}
        width={"80px"}
        disable={!filters.fromTime || !filters.toTime || loading}
      />

      <SecondaryButton
        onClick={handleDownloadReport}
        text={downloading ? <Spinner /> : <FileDownloadOutlined />}
        height={"40px"}
        width={"fit-content"}
        disable={!filters.fromTime || !filters.toTime || downloading}
      />
    </div>
  );
};

const NoData = () => {
  return (
    <div className="flex flex-row justify-center items-center border rounded-md w-full h-[50vh]">
      <div className="flex flex-row items-center gap-2 text-gray-400">
        <MoodBad fontSize={"large"} />
        <h2 className="text-2xl">No Data!</h2>
      </div>
    </div>
  );
};

const BurnerTemperature = () => {
  const { loading, data, fetchData } = useFetchData(
    getIndividualBurnersDataApi
  );
  return (
    <div className="bg-white shadow-sm p-4 rounded-xl w-full">
      <div className="flex flex-row justify-between items-center px-2 py-2 w-full overflow-x-auto">
        <p className="px-2 min-w-fit font-bold text-lg">
          Burner Temperature (From IR Camera){" "}
        </p>
        <div>
          <FilterPanel
            fetchData={fetchData}
            loading={loading}
            basis={"individualburner"}
          />
        </div>
      </div>
      {loading ? (
        <div className="p-2">
          <Skeleton
            height={"330px"}
            width={"100%"}
            rounded={"md"}
          />
        </div>
      ) : (
        <>
          {data ? (
            <CommanMultipleYAxisLineChart chartData={data} />
          ) : (
            <NoData />
          )}
        </>
      )}
    </div>
  );
};

const ThermocoupleTemperature = () => {
  const { loading, data, fetchData } = useFetchData(
    getThermocoupleTemperatureReportDataApi
  );

  return (
    <div className="bg-white shadow-sm p-4 rounded-xl">
      <div className="flex flex-row justify-between items-center px-2 py-2 w-full overflow-x-auto">
        <p className="px-2 min-w-fit font-bold text-lg">
          Thermocouple Temperature
        </p>
        <FilterPanel
          fetchData={fetchData}
          loading={loading}
          basis={"thermocouple"}
        />
      </div>
      {loading ? (
        <div className="p-2">
          <Skeleton
            height={"340px"}
            width={"100%"}
            rounded={"md"}
          />
        </div>
      ) : (
        <>
          {data ? (
            <CommanMultipleYAxisLineChart chartData={data} />
          ) : (
            <NoData />
          )}
        </>
      )}
    </div>
  );
};

const TotalSulphurAndOverallBeltTemperature = () => {
  const { loading, data, fetchData } = useFetchData(
    getTotalSulphurAndOverallBeltTemperatureApi
  );
  return (
    <div className="bg-white shadow-sm p-4 rounded-xl">
      <div className="flex flex-row justify-between items-center px-2 py-2 w-full overflow-x-auto">
        <p className="px-2 min-w-fit font-bold text-lg">
          Overall belt Temperature
        </p>{" "}
        <FilterPanel
          fetchData={fetchData}
          loading={loading}
          basis={"overallbelt"}
        />
      </div>
      {loading ? (
        <div className="p-2">
          <Skeleton
            height={"350px"}
            width={"100%"}
            rounded={"md"}
          />
        </div>
      ) : (
        <>
          {data ? (
            <TotalSulphurAndOverallBeltTemperatureChart chartData={data} />
          ) : (
            <NoData />
          )}
        </>
      )}
    </div>
  );
};

export const Report = () => (
  <div className="flex flex-col gap-6 w-full h-full">
    <BurnerTemperature />
    <ThermocoupleTemperature />
    <TotalSulphurAndOverallBeltTemperature />
  </div>
);
