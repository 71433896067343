import { useWindowSize } from "@uidotdev/usehooks";

import Barchart from "./Barchart";
import ReactApexChart from "react-apexcharts";

const CompareBarChart = ({ series, categories, setFilterData }) => {
  // const series = [
  //   {
  //     name: "Cold spots",
  //     data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
  //   },
  //   {
  //     name: "Hot spots",
  //     data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
  //   },
  // ];

  const options = {
    chart: {
      type: "bar",
      height: 350,
      animations: {
        enabled: true,
      },
      events: {
        click: function (event, chartContext, opts) {
          // Your custom code here
          if (
            typeof opts.dataPointIndex !== "undefined" &&
            opts.dataPointIndex !== -1
          ) {
            const clickedBoard = categories[opts.dataPointIndex].toString();
            setFilterData((prev) => {
              // Check if the value already exists in the filter data
              const existingIndex = prev.items.findIndex(
                (item) =>
                  item.field === "boardNo" && item.value === clickedBoard
              );
              if (existingIndex !== -1) {
                // If the value already exists, remove it from the filter data
                // return { items: [] };
                return {
                  items: prev.items.filter(
                    (item, index) => index !== existingIndex
                  ),
                };
              } else {
                // If the value doesn't exist, add it to the filter data
                return {
                  items: [
                    {
                      field: "boardNo",
                      operator: "equals",
                      value: clickedBoard,
                    },
                  ],
                };
              }
            });
          }
        },
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "50%",
        endingShape: "rounded",
        borderRadius: 3,
        // minWidth: `150px`,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    xaxis: {
      title: { text: "Boards" },
      categories: categories,
    },
    colors: [
      series?.[0]?.name?.toLowerCase()?.includes("distance")
        ? "#E46962"
        : "#6CA6FC",
      "#FFC107",
    ],

    yaxis: {
      title: {
        text: series?.[0]?.name?.toLowerCase()?.includes("distance")
          ? "Distance (px)"
          : "Average % of spots",
      },
      min: series?.[0]?.name?.toLowerCase()?.includes("distance")
        ? undefined
        : 0,
      max: series?.[0]?.name?.toLowerCase()?.includes("distance")
        ? undefined
        : 100,
      tickAmount: 5,
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: true,
      position: "top",
      horizontalAlign: "left",
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return series?.[0]?.name?.toLowerCase()?.includes("distance")
            ? val + " px"
            : val + "%";
        },
      },
    },
  };

  const size = useWindowSize();
  return (
    <div
      style={{
        minWidth:
          series?.[0]?.data?.length < 30
            ? "100%"
            : `${Math.max(series?.[0]?.data?.length * 30, 1000)}px`,
      }}
      className=" h-full"
    >
      <div className="flex gap-1 sm:gap-[40px] items-center min-h-[280px] ">
        <div
          className={`sm:ml-0 min-h-[280px]x ${
            size.width < 1024 ? "w-full" : " w-[50%]"
          } flex-grow `}
        >
          <div id="chart">
            <ReactApexChart
              options={options}
              series={series}
              type="bar"
              height={350}
            />
          </div>
          <div id="html-dist"></div>
        </div>
      </div>
    </div>
  );
};

export default CompareBarChart;
