import axios from "axios";
import { useParams } from "react-router-dom";
import { useEffect, useState, useContext, useRef } from "react";
import NavContext from "../../NavContext";
import { baseURL } from "../../../index";

import {
  Select,
  Table,
  Td,
  Tr,
  Thead,
  Tbody,
  TableContainer,
  Th,
  Flex,
  Image,
  Spinner,
} from "@chakra-ui/react";
import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import SecondaryButton from "../../../util/Buttons/SecondaryButton";
import { capitalizeFirstLetter } from "../../../util/sentenceCase";
import DownloadExcel from "../../community/DownloadExcel";
import CommonTable from "../CommonTable/CommonTable";
import { useWindowSize } from "@uidotdev/usehooks";
import DetailModal from "../FeedComponent/DetailModal";
import TableSkeleton from "../CommonTable/TableSkeleton";
import ExlCsvDownload from "../utils/ExlCsvDownload";
import { formatColumnName } from "../utils/formatColumnName";
import Feedback from "../FeedComponent/Feedback";

const Alerts = ({
  plantId,
  cameraId,
  disable,
  plantCamMap,
  material,
  clientId,
}) => {
  const param = useParams();
  const size = useWindowSize();
  const { auth } = useContext(NavContext);
  const [openFeedback, setopenFeedback] = useState(false);
  const [feedBackData, setFeedBackData] = useState({
    id: null,
    feedbackStatus: "",
  });
  const indexRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [alerts, setAlerts] = useState({});
  const [displayData, setDisplayData] = useState([]);
  const [alertsChanging, setAlertsChanging] = useState(false);
  const [camMap, setCamMap] = useState({
    cameraKeys: null,
    cameraMapping: null,
  });
  const [fromTime, setFromTime] = useState(
    new Date(
      new Date().getTime() -
        24 * 60 * 60 * 1000 -
        new Date().getTimezoneOffset() * 60 * 1000
    )
      .toISOString()
      .slice(0, 16)
  );
  const [toTime, setToTime] = useState(
    new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60 * 1000)
      .toISOString()
      .slice(0, 16)
  );

  const [selectedPlant, setSelectedPlant] = useState(
    disable ? plantId : "All Plants"
  );
  const [selectedCam, setSelectedCam] = useState(
    disable ? cameraId : "All Cams"
  );

  const response = {
    data: {
      order: [
        "serialNo",
        "lastAnalysisTime",
        "averageFlameDistance",
        "areaColdspots",
        "areaHotspots",
      ],
      data: [
        {
          id: "668ced9e9d764842e0125a3a",
          lastAnalysisTime: 1720511900,
          serialNo: 2,
          areaHotspots: 71,
          areaColdspots: 29,
          averageFlameDistance: 410,
          cameraKeys: ["cam101", "cam102"],
          cam101: "dummy.jpg",
          cam102: "dummy.jpg",
        },
        {
          id: "668cf2187428353fa2a70faf",
          lastAnalysisTime: 1720511900,
          serialNo: 2,
          areaHotspots: 71,
          areaColdspots: 29,
          averageFlameDistance: 410,
          cameraKeys: ["cam101", "cam102"],
          cam101: "dummy.jpg",
          cam102: "dummy.jpg",
        },
        {
          id: "668cf21b7428353fa2a70fb0",
          lastAnalysisTime: 1720511900,
          serialNo: 2,
          areaHotspots: 71,
          areaColdspots: 29,
          averageFlameDistance: 410,
          cameraKeys: ["cam101", "cam102"],
          cam101: "dummy.jpg",
          cam102: "dummy.jpg",
        },
      ],
    },
  };
  const apiCall = async () => {
    try {
      const requestData = JSON.stringify({
        clientId: clientId,
        useCase: material,
        startDate: new Date(fromTime).getTime(),
        endDate: new Date(toTime).getTime(),
        cameraId: "cam101",
        plantName: "salem",
      });
      const response = await axios.post(
        baseURL + `vision/v2/processMonitoring/alerts/overview/`,
        requestData,
        {
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );

      setAlerts(response?.data);
      setCamMap((prev) => ({
        ...prev,
        cameraKeys: response?.data?.cameraKeys,
        cameraMapping: response?.data?.cameraMapping,
      }));
    } catch (error) {
      console.log(error);
      setAlerts({ order: [], data: [] });
    } finally {
      setAlertsChanging(false);
    }

    // setAlerts(response.data);
    // setAlertsChanging(false);
  };

  const handleClick = () => {
    setAlertsChanging(true);

    apiCall();
  };

  const handleDetail = (index) => {
    indexRef.current = index;
    setOpenModal(true);
  };

  useEffect(() => {
    if (!disable & (selectedPlant != "All Plants")) {
      setSelectedCam("All Cams");
    }
  }, [selectedPlant]);
  useEffect(() => {
    handleClick();
  }, []);

  return (
    <div className="relative flex flex-col">
      <div className="absolute left-0 right-0 flex justify-center">
        <div className="p-5   gap-2 flex flex-col md:flex-row items-center bg-white rounded-xl shadow-md">
          <div>
            <FloatingInput
              text="From"
              type="datetime-local"
              setDateTime={setFromTime}
              value={fromTime}
              max={toTime}
            />
          </div>
          <div>
            <FloatingInput
              text="To"
              type="datetime-local"
              setDateTime={setToTime}
              value={toTime}
              min={fromTime}
              max={new Date().toISOString().slice(0, 16)}
            />
          </div>
          {/* <button
            className="text-center p-[10px] pl-4 pr-4 text-white text-xs md:text-base font-medium bg-[#084298] rounded-full"
            onClick={handleClick}
          >
            {alertsChanging ? <Spinner /> : "Show Alerts"}
          </button> */}
          <PrimaryButton
            height={"100%"}
            width={"110px"}
            text={alertsChanging ? <Spinner /> : "Show alerts"}
            disable={alertsChanging || !fromTime || !toTime}
            onClick={handleClick}
          />
        </div>
      </div>
      <div className="flex flex-col pl-3 pr-3 gap-4 mt-[160px] md:mt-8 pt-[57px] bg-white rounded-xl justify-start  pb-2 md-3 md:mb-0">
        {/* <div className="flex justify-between gap-3">
          <div className="flex gap-2 ml-6 overflow-x-auto">
            <div className="min-w-[110px]">
              <Select
                borderColor="#CAC5CD"
                color="#605D64"
                placeholder={disable && capitalizeFirstLetter(plantId)}
                variant="outline"
                isDisabled={disable}
                className="!text-sm !font-medium text-[#605D64] "
                onChange={(e) => setSelectedPlant(e.target.value)}
                value={selectedPlant}
              >
                <option key="All Plants" value="All Plants">
                  {capitalizeFirstLetter("All Plants")}
                </option>
                {!disable &&
                  Object.keys(plantCamMap).map((plant) => {
                    return (
                      <option key={plant} value={plant}>
                        {capitalizeFirstLetter(plant)}
                      </option>
                    );
                  })}
              </Select>
            </div>
            {selectedPlant !== "All Plants" && (
              <div className="min-w-[110px]">
                <Select
                  borderColor="#CAC5CD"
                  color="#605D64"
                  placeholder={disable && capitalizeFirstLetter(cameraId)}
                  variant="outline"
                  isDisabled={disable}
                  className="!text-sm !font-medium text-[#605D64] "
                  onChange={(e) => setSelectedCam(e.target.value)}
                  value={selectedCam}
                >
                  {" "}
                  <option key="All Cams" value="All Cams">
                    {capitalizeFirstLetter("All Cams")}
                  </option>
                  {!disable &&
                    plantCamMap[selectedPlant].map((cam) => {
                      return (
                        <option key={cam} value={cam}>
                          {capitalizeFirstLetter(cam)}
                        </option>
                      );
                    })}
                </Select>
              </div>
            )}

            <SecondaryButton
              height={"40px"}
              width={"80px"}
              text={alertsChanging ? <Spinner /> : "Apply"}
              disable={alertsChanging}
              onClick={handleClick}
            />
          </div>
        </div> */}
        <div className="w-full justify-between flex px-2 ">
          <div className="flex items-center text-base font-medium"></div>
          <div className="w-[110px]">
            {alerts.hasOwnProperty("order") && (
              <ExlCsvDownload
                order={alerts.order}
                data={alerts.data}
                name="Alert"
              />
            )}
          </div>
        </div>
        {alertsChanging ? (
          <TableSkeleton
            rows={7}
            cellsPerRow={7}
            headers={["1", "2", "3", "4", "5", "6", "7"]}
          />
        ) : (
          alerts.hasOwnProperty("data") &&
          alerts.hasOwnProperty("order") && (
            <CommonTable
              rowData={alerts.data || []}
              order={alerts.order || []}
              showViewDetail={true}
              showFeedBack={true}
              setDisplayData={setDisplayData}
              setOpenModal={setOpenModal}
              indexRef={indexRef}
              tableHeight={size.width <= 1024 ? "400px" : "560px"}
              rowHeight={45}
              pagesize={50}
              setopenFeedback={setopenFeedback}
              setFeedBackData={setFeedBackData}
            />
          )
        )}
      </div>

      {openModal && (
        <DetailModal
          openModal={openModal}
          closeModal={() => setOpenModal(false)}
          data={displayData}
          index={indexRef.current}
          PlantName={selectedPlant}
          camMap={camMap}
        />
      )}
      {feedBackData && openFeedback && feedBackData?.id && (
        <Feedback
          openModal={openFeedback}
          closeModal={() => setopenFeedback((prev) => false)}
          clientId={clientId}
          material={material}
          // cameraId={cameraRef.current}
          plantName={"salem"}
          id={feedBackData?.id}
          {...(feedBackData?.feedbackStatus && {
            feedbackStatus: feedBackData.feedbackStatus,
          })}
        />
      )}
    </div>
  );
};

export default Alerts;
