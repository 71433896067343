import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
} from "@chakra-ui/react";
import { useWindowSize } from "@uidotdev/usehooks";
import GaugeMeter from "../../Charts/SinterFlameCharts/GaugeMeter";
import { useEffect, useState } from "react";
import Feedback from "./Feedback";

const CompareCard = ({ data, num, allowDelete, handleDelete }) => {
  const [feedback, setFeedback] = useState(false);
  const [feedbackType, setFeedbackType] = useState("");
  const [disableFeedback, setDisableFeedback] = useState(false);

  useEffect(() => {
    setDisableFeedback(data?.hasFeedback);
  }, [data]);

  return (
    <div
      className="px-6 pb-4 pt-4 rounded-lg flex flex-col items-center gap-2"
      style={{
        boxShadow:
          "4px 4px 7px 0px rgba(0, 0, 0, 0.05), -4px -4px 7px 0px rgba(0, 0, 0, 0.05)",
      }}
    >
      <div className="flex justify-between items-center w-full">
        <div className="flex gap-2 items-center">
          <p className="text-base text-[#525056] font-medium capitalize">
            {data?.cameraId}
          </p>
          <p className="text-sm text-[#938F96]">
            {new Date(data?.timestamp).toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "2-digit",
            })}
          </p>
          <p className="text-sm text-[#938F96]">
            {new Date(data?.timestamp).toLocaleTimeString()}
          </p>
        </div>
        <img
          src="/delete.svg"
          alt="Not supported"
          className="w-[12px] h-[14px] cursor-pointer"
          onClick={() => handleDelete(data?.id)}
          style={{
            filter: allowDelete ? "" : "grayscale(100%)",
          }}
        />
      </div>
      <div className="flex flex-col gap-4 items-center w-full">
        <div className="text-white font-medium text-xs relative h-full w-full flex justify-center items-center bg-black rounded-lg">
          <img
            src={data?.annotatedImage}
            alt="not supported"
            className="w-auto h-[140px] rounded-lg"
          />
          {num - 1 != 0 && (
            <p className="bg-black bg-opacity-70 absolute top-2 left-2">
              T{num - 1}: {data["T" + (num - 1)]?.toFixed(2)} &deg;C
            </p>
          )}
          {num != 8 && (
            <p className="bg-black bg-opacity-70 absolute top-2 right-2">
              T{num}: {data["T" + num]?.toFixed(2)} &deg;C
            </p>
          )}
          {!disableFeedback && (
            <div className="flex flex-col gap-0 absolute bottom-1 right-1 bg-white bg-opacity-90 p-1 rounded-lg">
              <p className="text-[#938F96] text-[10px] font-medium">
                Feedback:{" "}
              </p>
              <div className="flex gap-1 items-center">
                <img
                  src="/SinterflameIcons/thumU.svg"
                  alt="thumbs up"
                  className="hover:scale-105 h-4 w-4 cursor-pointer"
                  onClick={() => {
                    setFeedbackType("GOOD");
                    setFeedback(true);
                  }}
                />
                <img
                  src="/SinterflameIcons/thumD.svg"
                  alt="thumbs down"
                  className="hover:scale-105 h-4 w-4 cursor-pointer"
                  onClick={() => {
                    setFeedbackType("BAD");
                    setFeedback(true);
                  }}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex gap-3 self-center w-[80%]">
          {data?.healthIndex != 0 ? (
            <GaugeMeter
              index={data?.healthIndex}
              accuracy={data?.conf?.toFixed(0)}
            />
          ) : (
            <div className="h-full gap-2 flex flex-col w-full items-center justify-center text-black font-bold text-center text-md">
              <img
                src="/SinterflameIcons/viewObstruct.svg"
                className="h-[120px]"
              />
              <p>View Obstructed</p>
            </div>
          )}
        </div>
      </div>
      {feedback && feedbackType != "" && (
        <Feedback
          openModal={feedback}
          closeModal={() => setFeedback(false)}
          clientId={data?.clientId}
          material={"sinterflame"}
          cameraId={data?.cameraId}
          plantName={data?.plantName}
          id={data?.id}
          type={feedbackType}
          setDisableFeedback={setDisableFeedback}
        />
      )}
    </div>
  );
};

const CompareModal = ({ openModal, closeModal, data, setData }) => {
  const size = useWindowSize();
  const handleDelete = (id) => {
    if (data.length > 2) {
      setData((prev) => {
        let updatedData = [...prev];
        let idx = updatedData.findIndex((item) => item.id === id);
        updatedData.splice(idx, 1);

        return updatedData;
      });
    }
  };

  return (
    <>
      {size.width >= 768 ? (
        <Modal isOpen={openModal} onClose={closeModal} isCentered="true">
          <ModalOverlay />
          <ModalContent style={{ borderRadius: "12px" }} maxW="650px">
            <ModalHeader
              padding="0px"
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              position={"relative"}
            >
              <img
                className="absolute top-[-20px] rounded-[39px] p-[10px] bg-white cursor-pointer"
                style={{ boxShadow: "0px 2px 35px 0px rgba(0, 0, 0, 0.06)" }}
                src="/SinterflameIcons/cross.svg"
                alt="Not supported"
                onClick={closeModal}
              />
            </ModalHeader>
            <ModalBody p="24px">
              <div className="grid grid-cols-2 gap-6 items-center">
                {[...Array(4)].map((i, idx) => {
                  let num = parseInt(
                    data[idx]?.cameraId?.replace("burner", "")
                  );
                  return data.length >= idx + 1 ? (
                    <CompareCard
                      data={data[idx]}
                      num={num}
                      allowDelete={data.length > 2}
                      handleDelete={handleDelete}
                    />
                  ) : (
                    <div
                      className="px-6 pb-4 pt-4 rounded-lg flex flex-col items-center gap-2 h-full w-full"
                      style={{
                        boxShadow:
                          "4px 4px 7px 0px rgba(0, 0, 0, 0.05), -4px -4px 7px 0px rgba(0, 0, 0, 0.05)",
                      }}
                    >
                      <div className="rounded-2xl border-2 border-[#79767D] border-dashed w-full h-full flex justify-center items-center">
                        <div className="flex gap-3 items-center">
                          <img
                            src="/SinterflameIcons/addBurner.svg"
                            alt="No support"
                            className="cursor-pointer w-[40px] h-[40px]"
                            onClick={closeModal}
                          />
                          <p className="text-[#79767D] text-base">Add Burner</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        <Drawer onClose={closeModal} isOpen={openModal} size="full">
          <DrawerOverlay />
          <DrawerContent>
            <DrawerHeader padding="0px">
              <div className="flex justify-center items-center">
                <img
                  className="rounded-[39px] p-[10px] bg-white cursor-pointer"
                  style={{ boxShadow: "0px 2px 35px 0px rgba(0, 0, 0, 0.06)" }}
                  src="/SinterflameIcons/cross.svg"
                  alt="Not supported"
                  onClick={closeModal}
                />
              </div>
            </DrawerHeader>
            <DrawerBody padding="24px">
              <div className="grid grid-cols-1 gap-6 items-center">
                {[...Array(4)].map((i, idx) => {
                  let num = parseInt(
                    data[idx]?.cameraId?.replace("burner", "")
                  );
                  return data.length >= idx + 1 ? (
                    <CompareCard
                      data={data[idx]}
                      num={num}
                      allowDelete={data.length > 2}
                      handleDelete={handleDelete}
                    />
                  ) : (
                    <div
                      className="px-6 pb-4 pt-4 rounded-lg flex flex-col items-center gap-2 h-[300px] w-full"
                      style={{
                        boxShadow:
                          "4px 4px 7px 0px rgba(0, 0, 0, 0.05), -4px -4px 7px 0px rgba(0, 0, 0, 0.05)",
                      }}
                    >
                      <div className="rounded-2xl border-2 border-[#79767D] border-dashed w-full h-full flex justify-center items-center">
                        <div className="flex gap-3 items-center">
                          <img
                            src="/SinterflameIcons/addBurner.svg"
                            alt="No support"
                            className="cursor-pointer w-[40px] h-[40px]"
                            onClick={closeModal}
                          />
                          <p className="text-[#79767D] text-base">Add Burner</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>{" "}
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
};

export default CompareModal;
