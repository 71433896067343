import FloatingInput from "../../../util/VisionUtils/FloatingInput";
import { useState, useEffect, useContext } from "react";
import { baseURL } from "../../../index";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Select } from "@chakra-ui/react";
import ExlCsvDownload from "../../../util/VisionUtils/ExlCsvDownload";
import ReportTable from "../Tables/ReportTable";
import NavContext from "../../NavContext";
import { Spinner } from "@chakra-ui/react";
import SummaryReportTable from "../Tables/SummaryReportTable";
import { batches } from "./Status";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import Paginator from "../../../util/VisionUtils/Paginator";
import { shiftCal } from "../Tables/SummaryReportTable";

const Report = ({ plantId, cameraId, disable, plantCamMap }) => {
  const param = useParams();
  const { auth } = useContext(NavContext);
  const [report, setReport] = useState([]);
  const [detailReport, setDetailReport] = useState([]);
  const [displayData, setDisplayData] = useState([]);
  const [sm, setSm] = useState(1);
  const [reportChanging, setReportChanging] = useState(false);
  const [selectedShift, setSelectedShift] = useState("All");
  const [fromTime, setFromTime] = useState(
    new Date(new Date().getTime() - 48 * 60 * 60 * 1000 + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10)
  );
  const [toTime, setToTime] = useState(
    new Date(new Date().getTime() + 5.5 * 60 * 60 * 1000)
      .toISOString()
      .slice(0, 10)
  );

  const ReportApi = async () => {
    const param = {
      starttime: new Date(fromTime).getTime(),
      endtime: new Date(toTime).getTime() + 23.99 * 60 * 60 * 1000,
    };
    const response = await axios
      .get(baseURL + "vision/v1/workforceMonitoring/cb/getReportSummary", {
        params: param,
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": auth,
        },
      })
      .then((response) => {
        let data = response.data
          ?.map((item) => {
            delete item["_id"];
            return item;
          })
          ?.filter((item) => {
            if (selectedShift == "All") return item;
            else return selectedShift == shiftCal(item?.timestamp);
          });
        setReport(data);
        setReportChanging(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const DetailReportApi = async () => {
    try {
      const param = {
        batchNo: 5,
      };
      const response = await axios.get(
        baseURL + "vision/v1/workforceMonitoring/cb/getDetailedReport",
        {
          params: param,
          headers: {
            "Content-Type": "application/json",
            "X-Auth-Token": auth,
          },
        }
      );

      if (Object.keys(response?.data || {})?.length > 0) {
        let rowData = [];
        let data = response?.data;
        for (let batch in data) {
          let val = data[batch];
          let valData = data[batch]?.data?.filter(
            (item) => item.smelter == "SM" + sm
          );
          if (valData?.length > 0) {
            rowData.push({
              "Start date": new Date(
                valData[0]?.starttime * 1000
              )?.toLocaleDateString("en-GB"),
              "End date": new Date(
                valData[valData.length - 1]?.endtime * 1000
              )?.toLocaleDateString("en-GB"),
              Smelter: valData[0]?.smelter,
              "Batch No.": batch,
              "Total Weight (MT)": valData
                ?.reduce((a, b) => {
                  return a + b?.dumped_tonnage;
                }, 0)
                ?.toFixed(2),
              Alerts: val?.alerts,
              data: valData,
            });
          }
        }
        setDetailReport(rowData.reverse());
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = () => {
    setReportChanging(true);
    ReportApi();
    DetailReportApi();
  };

  useEffect(() => {
    handleClick();
  }, [sm]);

  return (
    <div className="relative flex flex-col gap-4   rounded-xl justify-start">
      <div className="absolute left-0 right-0 flex justify-center">
        <div className="p-5 pl-6 pr-6 gap-2 flex flex-col md:flex-row items-center bg-white rounded-xl shadow-md">
          <div className="flex flex-col min-[800px]:flex-row gap-2 items-center self-center">
            <div>
              <FloatingInput
                text="From"
                type="date"
                setDateTime={setFromTime}
                value={fromTime}
                max={toTime}
              />
            </div>
            <div>
              <FloatingInput
                text="To"
                type="date"
                setDateTime={setToTime}
                value={toTime}
                max={new Date().toISOString().slice(0, 10)}
                min={fromTime}
              />
            </div>
            <div className="flex  gap-2 items-center">
              <p className="text-base">Shift</p>
              <Select
                size={"xs"}
                width={"fit-content"}
                height={"40px"}
                rounded={"8px"}
                value={selectedShift}
                onChange={(e) => setSelectedShift(e.target.value)}
              >
                {["All", "A", "B", "C"]?.map((s) => (
                  <option value={s} key={s}>
                    {s}
                  </option>
                ))}
              </Select>
            </div>
            <PrimaryButton
              onClick={handleClick}
              disable={!fromTime || !toTime}
              text={reportChanging ? <Spinner /> : "Show reports"}
              width={"140px"}
              height={"40px"}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2  mt-[160px] md:mt-11 pt-[57px] bg-white rounded-xl justify-start">
        <div className="flex gap-2 flex-col lg:flex-row items-start lg:items-center justify-between px-4">
          <p className="text-xl text-[#3E3C42] font-medium ">Summary</p>
          {report.length > 0 && (
            <div className=" z-50">
              <ExlCsvDownload order={Object.keys(report[0])} data={report} />
            </div>
          )}
        </div>
        <SummaryReportTable rowData={report} fetchData={ReportApi} />
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex gap-2 items-center w-full justify-between">
          <p className="text-xl text-[#3E3C42] font-medium pl-2">Details</p>
          <div className="flex flex-col xs:flex-row gap-2 items-center">
            <div className="flex gap-4">
              {batches.map((i, idx) => {
                return (
                  <div
                    className="py-[6px] px-3 rounded border text-sm"
                    style={{
                      backgroundColor: sm == i ? "#f1f7ff" : "white",
                      color: sm == i ? "#3E3C42" : "#605D64",
                      borderColor: sm == i ? "#6CA6FC" : "#EBEBEB",
                      cursor: sm == i ? "" : "pointer",
                      fontWeight: sm == i ? 500 : 400,
                    }}
                    onClick={() => setSm(i)}
                  >
                    SM {i}
                  </div>
                );
              })}
            </div>
            <Paginator
              data={detailReport}
              limit={5}
              setDisplayData={setDisplayData}
            />
          </div>
        </div>
        <ReportTable rowData={displayData} />
      </div>
    </div>
  );
};

export default Report;
