import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material";

import { Badge, IconButton, Tooltip } from "@chakra-ui/react";
import EditNoteIcon from "@mui/icons-material/EditNote";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const MuiTheme = createTheme();
const ClientsTable = ({ data, setShowClientTab, setSelectedClient }) => {
  const rowData = data?.map((item) => {
    // creating another [{},{}] without any nested objects
    const newObj = { ...item.clientMasterDataEntity, ...item };
    delete newObj["clientMasterDataEntity"]; // Remove the nested object
    newObj.productCount = item.visonProductCount + item.optimusProductCount; // adding new key for product count
    return newObj;
  });

  const columns = [
    {
      field: "clientName",
      headerName: (
        <div className="flex">
          <img src="/superadmin/clients/client.svg" alt="" />
          <p className="ml-1">clients</p>
        </div>
      ),
      renderCell: ({ row }) => {
        return (
          <Tooltip title={row?.clientName} placement="top">
            <div className="flex flex-row whitespace-nowrap overflow-hidden text-ellipsis gap-2">
              {/* <p className="text-[#938F96] text-sm">Description</p> */}
              {row?.logo32url ? (
                <img
                  className=" w-[20px] h-[20px] "
                  src={row?.logo32url}
                  alt="coins"
                />
              ) : (
                <img
                  src="/superadmin/clients/companyLogo.png"
                  alt=""
                  className=" w-[20px] h-[20px]"
                />
              )}
              {row?.clientName}
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "productCount",
      headerName: (
        <div className="flex">
          <img src="/superadmin/clients/prodCount.svg" alt="" />
          <p className="ml-1">Subscriptions</p>
        </div>
      ),
      renderCell: ({ row }) => (
        <p className="font-semibold">{row.productCount}</p>
      ),
    },

    {
      field: "userCount",
      headerName: (
        <div className="flex">
          <img src="/superadmin/clients/users.svg" alt="" />
          <p className="ml-1">users</p>
        </div>
      ),
      renderCell: ({ row }) => <p className="font-semibold">{row.userCount}</p>,
    },
    {
      field: "clientAllLocation",
      headerName: (
        <div className="flex">
          <img src="/superadmin/clients/location.svg" alt="" />
          <p className="ml-1">locations</p>
        </div>
      ),
      renderCell: ({ row }) => {
        const locationsString = row.clientAllLocation || "";
        const locationsArray = locationsString.split(",");
        const numLocations =
          locationsArray[0] === "" ? 0 : locationsArray.length;
        const displayedLocations = locationsArray.slice(0, 3).join(", ");

        return (
          <Tooltip
            label={locationsString}
            placement="top"
            color={"gray.400"}
            bg="black"
            hasArrow
            px={"5px"}
            fontSize={"md"}
            fontWeight={500}
          >
            <div className="flex">
              <p className="font-semibold mr-2">{numLocations}</p>

              <>
                <p className="text-[#3E3C42] font-medium whitespace-nowrap overflow-hidden overflow-ellipsis">
                  {displayedLocations}
                </p>
                <div>
                  {numLocations > 3 && (
                    <>
                      {"..."}

                      <Badge color={"#447ED4"} fontWeight={600} fontSize="14px">
                        +{numLocations - 3}
                      </Badge>
                    </>
                  )}
                </div>
              </>
            </div>
          </Tooltip>
        );
      },
    },
    {
      field: "creationAt",
      headerName: (
        <div className="flex">
          <img src="/superadmin/clients/calender.svg" alt="" />
          <p className="ml-1">Created On</p>
        </div>
      ),
      valueFormatter: (params) => {
        return params.value
          ? new Date(params.value).toDateString().split(" ")[2] +
          " " +
          new Date(params.value).toDateString().split(" ")[1] +
          " '" +
          new Date(params.value).toDateString().split(" ")[3].slice(2, 4) +
          " " +
          new Date(params.value).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          })
          : "";
      },
    },
    {
      field: "lastUpdatedDate",
      headerName: (
        <div className="flex">
          <img src="/superadmin/clients/calender2.svg" alt="" />
          <p className="ml-1">updated on</p>
        </div>
      ),
      valueFormatter: (params) => {
        return params.value
          ? new Date(params.value).toDateString().split(" ")[2] +
          " " +
          new Date(params.value).toDateString().split(" ")[1] +
          " '" +
          new Date(params.value).toDateString().split(" ")[3].slice(2, 4) +
          " " +
          new Date(params.value).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          })
          : "";
      },
    },
    {
      field: "status",
      headerName: (
        <div className="flex">
          <img src="/superadmin/clients/timer.svg" alt="" />
          <p className="ml-1">status</p>
        </div>
      ),
      renderCell: ({ row }) => {
        return (
          <div className="flex items-center gap-1">
            {row?.status === "DRAFT" ? (
              <img
                src={`/Common/draft.svg`}
                className="w-5 h-5 rounded-full"
                alt="status"
              />
            ) : row.status === "ACTIVE" ? (
              <CheckCircleIcon sx={{
                width: "20px",
                height: "20px",
                borderRadius: "9999px",
                color: "green",
              }} />
            ) : (
              <CancelIcon sx={{
                width: "20px",
                height: "20px",
                borderRadius: "9999px",
                color: "#E46962",
              }} />
            )}
            <p className="capitalize">{row.status.toLowerCase()}</p>
          </div>
        );
      },
    },
    {
      field: "view",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,

      renderCell: ({ row }) => (
        <IconButton onClick={() => handleViewClick(row)} color="#2196F3">
          <VisibilityIcon />
        </IconButton>
      ),
    },
    {
      field: "update",
      headerName: "",
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        return (
          <div>
            <IconButton
              aria-label="edit"
              color={row.status === "INACTIVE" ? "gray.400" : "#1E88E5"}
              onClick={() => {
                handleUpdateClick(row);
              }}
              isDisabled={row.status === "INACTIVE"}
            >
              <EditNoteIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const handleViewClick = (row) => {
    setSelectedClient(row);
    setShowClientTab((prev) => "viewclient");
  };

  const handleUpdateClick = (row) => {
    setSelectedClient(row);
    setShowClientTab((prev) => "updateClient");
  };

  const headerClass = "text-sm font-normal text-[#79767D] bg-[#DEF] capitalize";
  const cellClass = "text-sm font-normal text-[#3E3C42] whitespace-nowrap";
  const flexMap = [2, 1, 1, 2.5, 1.5, 1.5, 1, 0.5, 0.5];
  columns.map((val, idx) => {
    val["headerClassName"] = headerClass;
    val["cellClassName"] = cellClass;
    val["flex"] = flexMap[idx];
  });

  return (
    <div className="overflow-x-auto mt-2 w-full">
      <ThemeProvider theme={MuiTheme}>
        <DataGrid
          rows={rowData}
          columns={columns}
          getRowId={(row) => row?.clientId}
          initialState={{
            pagination: { paginationModel: { pageSize: 8 } },
          }}
          sx={{ minWidth: "1000px" }}
        />
      </ThemeProvider>
    </div>
  );
};

export default ClientsTable;
