import { DataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider, IconButton, Tooltip } from "@mui/material";
import EditNoteIcon from "@mui/icons-material/EditNote";
import { useContext, useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import { alpha, styled } from "@mui/material/styles";
import { deactiveAlertApi } from "../../Tabs/AlertsPage";
import { useToast } from "@chakra-ui/react";
import NavContext from "../../../NavContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

const MuiTheme = createTheme();

const View = ({ row, setShowAlertsTab, setSelectedAlert }) => {
    const handleView = () => {
        setShowAlertsTab((prev) => "viewAlert");
        setSelectedAlert(row);
    };

    return (
        <IconButton
            aria-label="edit"
            sx={{ color: "#1E88E5" }}
            onClick={() => {
                handleView();
            }}
        >
            <VisibilityIcon />
        </IconButton>
    );
};

const Update = ({ row, setShowAlertsTab, setSelectedAlert }) => {
    const handleUpdate = () => {
        setShowAlertsTab((prev) => "updateAlert");
        setSelectedAlert(row);
    };
    return (
        <IconButton
            aria-label="edit"
            sx={{ color: "#1E88E5", cursor: "pointer" }}
            onClick={() => {
                handleUpdate();
            }}
            disabled={row?.status === "INACTIVE"}
        >
            <EditNoteIcon />
        </IconButton>
    );
};

const Download = ({ row, setShowDAlertsTab }) => {
    const handleDownload = () => { };
    return (
        <div onClick={handleDownload}>
            <img src="/download.svg" />
        </div>
    );
};

const ToggleSwitch = ({ row, toast, auth, getAlertApi }) => {
    const [checked, setChecked] = useState(true);

    useEffect(() => {
        setChecked(row?.status != "INACTIVE");
    }, [row]);

    const handleChange = (event) => {
        if (event.target.checked) {
            deactiveAlertApi(row, toast, false, auth, getAlertApi);
        } else {
            deactiveAlertApi(row, toast, true, auth, getAlertApi);
        }
    };

    const GreenSwitch = styled(Switch)(({ theme }) => ({
        "& .MuiSwitch-switchBase.Mui-checked": {
            color: "#378805",
            "&:hover": {
                backgroundColor: alpha("#378805", theme.palette.action.hoverOpacity),
            },
        },
        "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
            backgroundColor: "#378805",
        },
    }));

    return (
        <div>
            {/* Switch */}
            <GreenSwitch
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
            />
        </div>
    );
};

const AlertTable = ({
    alerts,
    setShowAlertsTab,
    setSelectedAlert,
    getAlertApi
}) => {

    const toast = useToast();
    const { auth } = useContext(NavContext);

    const rowData = alerts;

    const columns = [
        {
            field: "alert",
            headerName: <div className="flex">
                <img src="/alertBell.svg" />
                Alert Name
            </div>,
            valueGetter: ({ row }) => row?.alertName,
            renderCell: ({ value }) => {
                return (
                    <div className="w-full flex gap-1 ">
                        {value}
                    </div>
                );
            },
        },
        {
            field: "alertGroup",
            headerName: <div className="flex">
                <img src="/alertGroup.svg" />
                Alert Group
            </div>,
            valueGetter: ({ row }) => row?.alertGroupName,
            // valueGetter: ({ row }) => {
            //   row.clientsPlantInfo.map((x) => x).toString();
            // },


            renderCell: ({ row }) => {
                return (
                    <Tooltip title={row?.alertGroupName} placement="top">
                        <div className="w-full flex gap-1 ">
                            {/* <p className="font-bold">{row?.alertGroupName}</p> */}
                            {row?.alertGroupName}
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            field: "instances",
            headerName: <div className="flex">
                <img src="/instances.svg" />
                Instances
            </div>,
            valueGetter: ({ row }) => row?.alertsDetail?.filter((value, index) => value?.isDeleted === false)?.length,

            renderCell: ({ value }) => {
                return (
                    // <Tooltip title={x} placement="top">
                    <div className="w-full flex gap-1 ">
                        <p className="font-bold">{value}</p>
                        {/* {x} */}
                    </div>
                    // </Tooltip>
                );
            },
        },
        {
            field: "clients",
            headerName: <div className="flex">
                <img src="/clientsIcon.svg" />
                Clients
            </div>,
            valueGetter: ({ row }) => {
                let newArr = row?.alertsDetail?.filter((value, index) => value?.isDeleted === false)?.map((value, index) => value.organisation);
                return newArr?.filter((item, idx) => newArr?.indexOf(item) == idx).join(", ");
            },

            renderCell: ({ row }) => {
                //row?.alertsDetail?.filter((value,index) => value?.isDeleted === false)?.map((value, index) => value.organisation)?.join(",");
                let newArr = row?.alertsDetail?.filter((value, index) => value?.isDeleted === false)?.map((value, index) => value.organisation);
                let cnt = 0;
                let x = newArr?.filter((item, idx) => {
                    if (newArr?.indexOf(item) == idx) {
                        cnt++; return true;
                    }
                    else return false;
                }).join(", ");

                return (
                    <Tooltip title={x} placement="top">
                        <div className="whitespace-nowrap overflow-hidden text-ellipsis flex flex-row gap-1">
                            <p className="font-bold">{cnt}</p>
                            {x}
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            field: "createdAt",
            headerName: <div className="flex">
                <img src="/date.svg" />
                Created on
            </div>,
            valueGetter: ({ row }) => row?.createdAt,

            renderCell: ({ row }) => {

                function padZero(number) {
                    return (number < 10 ? '0' : '') + number;
                }

                const date = new Date(row?.createdAt);
                const hours = date.getHours();
                const minutes = date.getMinutes();
                const seconds = date.getSeconds();
                const formattedTime = padZero(hours) + ":" + padZero(minutes) + ":" + padZero(seconds);
                const formattedDate = new Date(row?.createdAt).toDateString().split(" ")[2] +
                    " " +
                    new Date(row?.createdAt).toDateString().split(" ")[1] +
                    " '" +
                    new Date(row?.createdAt).toDateString().split(" ")[3].slice(2, 4)

                return (
                    <Tooltip title={formattedDate + " " + formattedTime} placement="top">
                        <div className="w-full flex gap-2 whitespace-nowrap overflow-hidden text-ellipsis">
                            {formattedDate + " " + formattedTime}
                        </div>
                    </Tooltip>
                );
            }
        },
        {
            field: "updatedAt",
            headerName: <div className="flex">
                <img src="/date.svg" />
                Updated on
            </div>,
            valueGetter: ({ row }) => row?.updatedAt,
            renderCell: ({ row }) => {

                function padZero(number) {
                    return (number < 10 ? '0' : '') + number;
                }

                const date = new Date(row?.updatedAt);
                const hours = date.getHours();
                const minutes = date.getMinutes();
                const seconds = date.getSeconds();
                const formattedTime = padZero(hours) + ":" + padZero(minutes) + ":" + padZero(seconds);
                const formattedDate = new Date(row?.updatedAt).toDateString().split(" ")[2] +
                    " " +
                    new Date(row?.updatedAt).toDateString().split(" ")[1] +
                    " '" +
                    new Date(row?.updatedAt).toDateString().split(" ")[3].slice(2, 4)

                return (
                    <Tooltip title={formattedDate + " " + formattedTime} placement="top">
                        <div className="w-full flex gap-2 whitespace-nowrap overflow-hidden text-ellipsis">
                            {formattedDate + " " + formattedTime}
                        </div>
                    </Tooltip>
                );
            },

        },
        {
            field: "status",
            headerName: <div className="flex">
                <img src="/superadmin/clients/timer.svg" alt="" />
                Status
            </div>,
            valueGetter: ({ row }) => row?.status,
            renderCell: ({ row }) => {
                return (
                    <Tooltip title={row?.status.toLowerCase()} placement="top">
                        <div className="w-full flex item-center gap-2 cursor-pointer ">
                            {row?.status === "DRAFT" ? (
                                <img
                                    src={`/Common/draft.svg`}
                                    className="w-5 h-5 rounded-full"
                                    alt="status"
                                />
                            ) : row.status === "ACTIVE" ? (
                                <CheckCircleIcon sx={{
                                    width: "20px",
                                    height: "20px",
                                    borderRadius: "9999px",
                                    color: "green",
                                }} />
                            ) : (
                                <CancelIcon sx={{
                                    width: "20px",
                                    height: "20px",
                                    borderRadius: "9999px",
                                    color: "#E46962",
                                }} />
                            )}
                            <p className="capitalize">{row?.status?.toLowerCase()}</p>
                        </div>
                    </Tooltip>
                );
            },
        },
        {
            field: "links",
            headerName: "",
            sortable: false,
            disableColumnMenu: true,
            renderCell: ({ row }) => (
                <View
                    row={row}
                    setShowAlertsTab={setShowAlertsTab}
                    setSelectedAlert={setSelectedAlert}
                />
            ),
        },
        {
            field: "links2",
            headerName: "",
            sortable: false,
            disableColumnMenu: true,
            renderCell: ({ row }) => (
                <Update
                    row={row}
                    setShowAlertsTab={setShowAlertsTab}
                    setSelectedAlert={setSelectedAlert}
                />
            ),
        },
        // {
        //   field: "links3",
        //   headerName: "",
        //   renderCell: ({ row }) => (
        //     <Download row={row} setShowDevicesTab={setShowDevicesTab} />
        //   ),
        // },
        // {
        //   field: "links4",
        //   headerName: "",
        //   renderCell: ({ row }) => (
        //     <ToggleSwitch
        //       row={row}
        //       toast={toast}
        //       auth={auth}
        //       getDeviceApi={getDeviceApi}
        //     />
        //   ),
        // },
    ];

    const headerClass = "text-sm font-normal text-[#79767D] bg-[#DEF]";
    const cellClass = "text-sm font-normal text-[#3E3C42] whitespace-nowrap";
    const flexMap = [1.3, 1.3, 1, 3, 1.4, 1.4, 1, 0.1, 0.1];
    columns.map((val, idx) => {
        val["headerClassName"] = headerClass;
        val["cellClassName"] = cellClass;
        val["flex"] = flexMap[idx];
    });

    return (
        <div className="overflow-x-auto mt-2">
            <ThemeProvider theme={MuiTheme}>
                <DataGrid
                    rows={rowData}
                    columns={columns}
                    getRowId={(row) => row.alertId}
                    initialState={{
                        sorting: {
                            sortModel: [{ field: "updatedAt", sort: "desc" }],
                        },
                    }}
                    sx={{ minWidth: "1000px" }}
                />
            </ThemeProvider>
        </div>
    );
};

export default AlertTable;
