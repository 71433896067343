import { memo, useContext, useEffect, useState } from "react";
import { FlareStackContext } from "../context/FlareStackContext";
import { getFeedDataApi } from "../services/flarestack.api";
import { useParams } from "react-router-dom";
import NavContext from "../../NavContext";
import { formatDateTimeLocale } from "../utilis/utilityFunctions";
import { FlameIcon, NoFlameIcon } from "../components/Icons";
import { Warning } from "@mui/icons-material";
import { FlareStackLineChart } from "../components/CommanCharts";
import { DetailModalSkeleton, NoData } from "../components/Loading";

const StatusCard = memo(({ gasFlowRate }) => {
  const gasFlowRateStyles = {
    No: {
      container: "bg-[#F5F5F5] border border-[#938F96]",
      icon: <NoFlameIcon sx={{ fontSize: "44px" }} />,
      textColor: "#79767D",
    },
    Acceptable: {
      container: "border border-[#1E4396] bg-[#F3F8FF]",
      iconColor: "#79767D",
      textColor: "#79767D",
      iconSize: "24px",
    },
    High: {
      container: "bg-[#FCEEEE]",
      iconColor: "#E46962",
      textColor: "#E46962",
      iconSize: "24px",
    },
    "Very High": {
      container: "bg-[#FCEEEE]",
      iconColor: "#E46962",
      textColor: "#E46962",
      iconSize: "24px",
    },
  };
  const styles = gasFlowRateStyles[gasFlowRate] || gasFlowRateStyles.No;

  return (
    <div
      className={`flex flex-col justify-between rounded-md h-[145px] ${styles.container}`}
    >
      {(gasFlowRate === "High" || gasFlowRate === "Very High") && (
        <div className="flex flex-row items-center gap-2 bg-[#DC362E] px-1 py-2 rounded-tl-md rounded-tr-md text-white">
          <Warning />
          <p>Alert</p>
        </div>
      )}
      <div className="flex flex-col justify-center gap-2 px-6 py-4 w-full h-full">
        <div className="flex flex-row items-center gap-0">
          {styles.icon || (
            <FlameIcon
              sx={{ fontSize: styles.iconSize }}
              color={styles.iconColor}
            />
          )}
          <p
            className={`px-1 text-lg font-semibold text-[${styles.textColor}]`}
          >
            {gasFlowRate} Flare
          </p>
        </div>
        {gasFlowRate !== "No" && (
          <p className="px-2 text-[#79767D] text-base">Gas Flow Rate</p>
        )}
      </div>
    </div>
  );
});

export { StatusCard };

const FlareStackCard = ({ cameraInfo, data, cameraName }) => {
  return (
    <div className="flex flex-col gap-2 bg-white p-4 rounded-xl w-full h-full">
      <h2 className="min-w-fit font-bold text-lg">{cameraName}</h2>
      <div className="gap-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 grid-flow-row w-full h-full">
        {cameraInfo?.map((el) => {
          let cameraData = data?.[el?.cameraId] || null;
          return (
            <div className="flex flex-col col-span-1 w-full h-full">
              <div className="relative w-full h-full">
                <img
                  src={cameraData?.image}
                  alt="cam_image"
                  className="w-full h-full"
                />
                <div className="top-0 left-0 absolute flex flex-row justify-start items-center px-1 py-1 w-full text-sm">
                  <p className="bg-white px-1 rounded">{el?.name}</p>
                </div>
              </div>
              <div className="flex flex-col justify-between px-1 py-1 w-full text-sm">
                <p className="text-[#AEA9B1]">Last Updated On</p>
                <p className="font-semibold">
                  {cameraData?.lastUpdated &&
                    formatDateTimeLocale(cameraData.lastUpdated)}
                </p>
              </div>
            </div>
          );
        })}
        <div
          className={`w-full h-full flex flex-col gap-1 col-span-1 md:col-span-2 lg:col-span-2 xl:col-span-1`}
        >
          {/* Status Card */}
          <StatusCard gasFlowRate={data?.gasFlowRate} />
          {/* Line Chart */}
          <div className={`w-full h-full   rounded-md bg-white border`}>
            <div className="flex flex-row justify-between items-center px-2 pt-1 pb-0 w-full h-10">
              <p className="font-medium text-[#525056]">{`Past 1 hour History`}</p>
            </div>
            <FlareStackLineChart
              data={data?.lineChartData?.data}
              times={data?.lineChartData?.times}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Feed = () => {
  const { auth } = useContext(NavContext);
  const { initialData } = useContext(FlareStackContext);
  const { clientId } = useParams;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const requestData = {
        clientId: clientId,
        useCase: "FLARESTACK",
        plantName: "vijaynagar",
      };
      const response = await getFeedDataApi(auth, requestData);
      setData(response);
    } catch (error) {
      setData((prev) => []);
    } finally {
      setTimeout(() => {
        if (loading) setLoading(false);
      }, 500);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return loading ? (
    <div className="flex flex-col gap-10">
      {initialData?.map((el) => (
        <DetailModalSkeleton isFeed={true} />
      ))}
    </div>
  ) : data ? (
    <div className="flex flex-col gap-10">
      {initialData?.map((el) => (
        <FlareStackCard
          cameraInfo={el?.cameraInfo}
          data={data[el?.cameraGpId]}
          cameraName={el?.cameraGpName}
        />
      ))}
    </div>
  ) : (
    <div className="w-full h-[350px]">
      <NoData />
    </div>
  );
};
export default Feed;
