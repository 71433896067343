import {
  Badge,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  ThemeProvider,
  createTheme,
} from "@mui/material";

export const CommanSingleSelect = ({
  value,
  handleChange,
  options,
  disable = false,
  label = "Select",
}) => {
  const MuiTheme = createTheme();
  return (
    <ThemeProvider theme={MuiTheme}>
      <FormControl fullWidth>
        <InputLabel id={`simple-select-${label}`}>{label}</InputLabel>
        <Select
          labelId={`simple-select-${label}`}
          id={`${label}-simple-select`}
          value={value}
          label={label}
          onChange={handleChange}
          size={"small"}
          sx={{ fontSize: "14px", height: "40px" }}
        >
          {options?.map((el) => (
            <MenuItem
              value={el?.value}
              sx={{ fontSize: "14px" }}
            >
              {el?.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
};
export const CommanMultipleSelect = ({
  value,
  handleChange,
  options,
  disable = false,
  label = "Select",
}) => {
  const MuiTheme = createTheme();
  return (
    <ThemeProvider theme={MuiTheme}>
      <Badge
        badgeContent={value && value?.length}
        color="primary"
        sx={{ width: "100%" }}
      >
        <FormControl fullWidth>
          {label && (
            <InputLabel
              id="multiple-checkbox-label"
              size={"small"}
              sx={{ fontSize: "14px" }}
            >
              {label}
            </InputLabel>
          )}
          <Select
            value={value || []}
            onChange={handleChange}
            multiple={true}
            input={
              <OutlinedInput
                size={"small"}
                {...(label && {
                  label: label,
                })}
              />
            }
            renderValue={(selected) => {
              return selected
                .map(
                  (value) =>
                    options.find((option) => option.value === value)?.name || ""
                )
                .join(", ");
            }}
            size={"small"}
            sx={{ color: "#605D64", fontSize: "14px", height: "40px" }}
          >
            {options.map((option) => (
              <MenuItem
                key={option?.value || ""}
                value={option?.value || ""}
                sx={{
                  height: "40px",
                  fontSize: "14px",
                  paddingX: "10px",
                  gap: "4px",
                }}
              >
                <Checkbox
                  size={"small"}
                  checked={value?.indexOf(option?.value) > -1}
                  sx={{ padding: 0 }}
                />
                <ListItemText
                  primary={option?.name || ""}
                  primaryTypographyProps={{
                    sx: { fontSize: "14px", textTransform: "capitalize" },
                  }}
                />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Badge>
    </ThemeProvider>
  );
};
