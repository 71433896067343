import React, { useState} from 'react'
import { Select } from "@chakra-ui/react";
import HighMovingStackBarChart from '../../../Charts/MulpicCharts/HighMovingStackBarChart';

const HighMovingGrades = ({data}) => {

    const [selectedOption, setSelectedOption] = useState("Overall");

    const dataOptions = {
        qc: ["Overall", "Head", "Body", "Tail", "Length"],
    }

    return (
        <div className=" flex flex-col w-full h-full bg-white rounded-xl" id="#highMovingGrades">
            <div className="flex flex-row gap-4 py-[2px] justify-between items-center px-3 ">
                <p className="rounded-e-xl rounded-s-xl text-xl font-medium">
                    High Moving Grades
                </p>
                <div className="min-w-[110px] px-3 py-1">
                    <Select
                        borderColor="#CAC5CD"
                        color="#605D64"
                        value={selectedOption}
                        variant="outline"
                        className="!rounded-md !text-sm !font-medium !text-[#605D64]"
                        onChange={(e) => setSelectedOption(e.target.value)}
                    >
                        {
                            dataOptions?.qc?.map((item) =>
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            )
                        }
                    </Select>
                </div>
            </div>

            <div className="flex flex-col min-[900px]:flex-row py-[2px] justify-between items-center px-3 w-full overflow-x-auto h-full min-h-[320px]">
                <div className='py-[2px] bg-white w-full min-w-[400px] !h-full'>
                    <p className='text-base font-medium'>Grades vs Frequency of Orders</p>
                    <HighMovingStackBarChart data={(data && data?.hasOwnProperty(selectedOption)) ? data[selectedOption] : null} type={"data"} labels={["In Range", "Low", "High"]} yAxisText={"Grade"} xAxisText={"Frequency of Orders"} />
                </div>
                <div className='py-[2px] bg-white w-full min-w-[400px] overflow-x-auto !h-full'>
                    <p className='text-base font-medium'>Grades vs Stacked Bar Plot of QC</p>
                    <HighMovingStackBarChart data={(data && data?.hasOwnProperty(selectedOption)) ? data[selectedOption] : null} type={"data"} labels={["In Range", "Low", "High"]} stackType={true} yAxisText={"Grade"} xAxisText={"Percentage"} />
                </div>
            </div>
        </div>
    )
}

export default HighMovingGrades


{/* <BarChart categories={["JA35LMH01", "JA35LMH02", "JA35LMH03", "JA35LMH04", "JA35LMH05", "JA35LMH07", "JA35LMH08"]} series={[{
                        data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380]
                    }]} /> */}