import React, { useState, useEffect } from "react";
import {
  Input,
  Radio,
  RadioGroup,
  useToast,
  Textarea,
  Button,
  Text,
} from "@chakra-ui/react";
import AddIcon from "./Components/AddIcon";
import PrimaryButton from "../../../util/Buttons/PrimaryButton";
import TonalButton from "../../../util/Buttons/TonalButton";
import axios from "axios";
import { useContext } from "react";
import NavContext from "../../NavContext";
import { baseURL } from "../../../index";
import DraftModal from "../DraftModal";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'

import { ChevronDownIcon } from '@chakra-ui/icons'


const UpdateProductGroup = ({
  selectedProductGroup,
  setShowProductTab,
}) => {
  const [parentProductGroupType, setParentProductGroupType] = useState();
  const [submitClicked, setSubmitClicked] = useState(false);
  const [productGroupName, setProductGroupName] = useState();
  const [cardLogoPreview, setCardLogoPreview] = useState(null);
  const [cardLogo, setCardLogo] = useState(null);
  const [description, setDescription] = useState();
  const [searchTerm, setSearchTerm] = useState(null);
  const [productGroupsList, setProductGroupsList] = useState();
  const [productsList, setProductsList] = useState();
  const [searchSuggestionsGroups, setSearchSuggestionsGroups] = useState([]);
  //const [searchSuggestionsProducts, setSearchSuggestionsProducts] = useState([]);
  const [showDiscard, setShowDiscard] = useState(false);
  const [showDraft, setShowDraft] = useState(false);
  const [showDisable, setShowDisable] = useState(false);
  const [review, setReview] = useState(false);
  const [icon, setIcon] = useState("Select default icons");
  const [logoList, setLogoList] = useState();
  const [defaultIcon, setDefaultIcon] = useState(null);

  useEffect(() => {

    setProductGroupName(selectedProductGroup?.productGroupName);
    setParentProductGroupType(selectedProductGroup?.productGroupParent);
    setDescription(selectedProductGroup?.productGroupDescription);
    setCardLogo(selectedProductGroup?.productGroupUrl);
    setCardLogoPreview(selectedProductGroup?.productGroupUrl)
    setDefaultIcon(selectedProductGroup?.productGroupUrl);
    getProductGroups();
    getProducts();
    getLogos();
  }, []);

  const getLogos = async () => {
    try {
      const response = await axios.get(
        baseURL + "draft/v1/fetch/defaultLogo",
        {
          headers: {
            "Content-Type": "application/json",
            "X-auth-Token": auth,
          },
        }
      );
      const data = response?.data;
      setLogoList(data.filter((item) => item?.defaultLogoType === "PRODUCT"));
    } catch (error) {
    }
  }

  const getProductGroups = async () => {
    //setLoading(true);
    try {
      const response = await axios.get(
        baseURL + "product/v1/fetch/productGroup",
        {
          headers: {
            "Content-Type": "application/json",
            "X-auth-Token": auth,
          },
        }
      );
      const data = response?.data;
      setProductGroupsList(data);
    } catch (error) {

    }
  };

  const getProducts = async () => {
    try {
      const response = await axios.get(
        baseURL + "product/v1/fetch/product",
        {
          headers: {
            "Content-Type": "application/json",
            "X-auth-Token": auth,
          },
        }
      );
      const data = response?.data;
      setProductsList(data);
    } catch (error) {
    }
  }

  useEffect(() => {

    const delay = 300; // Adjust the delay as needed
    const timeoutId = setTimeout(() => {
      if (searchTerm) {
        fetchSearchSuggestions(searchTerm);
      } else {
        setSearchSuggestionsGroups([]);
        //setSearchSuggestionsProducts([]);
      }
    }, delay);

    return () => clearTimeout(timeoutId); // Cleanup the timeout on component unmount or searchTerm change
  }, [searchTerm]);

  const { auth, getProductGroupsNew } = useContext(NavContext);
  const toast = useToast();

  const dataOptions = {
    parentProductGroup: {
      type: ["Vision", "Optimus", "Community", "Platform", "Others"],
    },
    icon: {
      type: ["Select default icons", "Upload your own icon"]
    }
  };

  const handleBackButton = () => {
    if (showDiscard) {
      setShowDraft(true);
      return;
    }
    // setShowAddNewProductCategoryForm(false);
    setShowProductTab((prev) => "products");
  };

  const fetchSearchSuggestions = async (term) => {

    const groups = productGroupsList?.filter((item) => item?.productGroupName?.toLowerCase().includes(term?.toLowerCase()));
    const products = productsList?.filter((item) => item?.productName?.toLowerCase().includes(term?.toLowerCase()));
    console.log(productsList)
    setSearchSuggestionsGroups(groups);
    //setSearchSuggestionsProducts(products);
  };

  const handleSearchTermChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
  };

  const handleSubmit = (isDraft) => {
    setSubmitClicked(true);


    // if (!parentProductGroupType) {
    //   setError("Parent product group type not selected!");
    // }

    // if (!productGroupName) {
    //   setError("Product group name field is empty!");
    // }

    if (!parentProductGroupType || !productGroupName || productGroupName?.length > 50 || description?.length > 1000) {
      toast({
        title: "Incomplete Details",
        description: "Please fill in all required details.",
        status: "error",
        duration: 4000,
        isClosable: true,
        position: "top",
      });
      return;
    }
    let data1 = {
      productGroupName: productGroupName?.trim(),
      productGroupDescription: description?.trim(),
      productGroupParent: parentProductGroupType,
      productIdUuid: selectedProductGroup.productIdUuid,
      status: isDraft,
      isdeleted: false,
    }

    console.log(data1);

    if (icon === "Select default icons") data1.productGroupUrl = defaultIcon;

    const json = JSON.stringify(data1);
    const blob = new Blob([json], {
      type: "application/json",
    });
    const FormData = require("form-data");
    let data = new FormData();
    data.append("data", blob);
    if (icon === "Upload your own icon") {
      data.append("file", cardLogo);
      console.log(cardLogo);
    }

    let config = {
      method: "patch",
      maxBodyLength: Infinity,
      url: baseURL + "product/v1/update/productGroup",
      headers: {
        "X-Auth-Token": auth,
      },
      data: data,
    };
    axios
      .request(config)
      .then((response) => {
        getProductGroupsNew();
        if (isDraft === 1) setShowDisable(true);
        setShowDiscard(false);
        toast({
          title: `${productGroupName} Updated successfully`,
          status: "success",
          duration: 4000,
          isClosable: true,
          position: "top",
        });

        if (isDraft === 1) {
          setTimeout(() => {
            setShowProductTab("products");
          }, 1500);
        }
      })
      .catch((error) => {
        console.log(error);
        toast({
          title: "Something went wrong",
          status: "error",
          duration: 4000,
          isClosable: true,
          position: "top",
        });
      });

  };

  const handleConfirmDiscard = () => {
    setShowProductTab((prev) => "products");
  };

  return (
    <div className="font-roboto flex flex-col gap-2 mt-0">
      <div className="flex items-center">
        <div className="cursor-pointer w-8" onClick={handleBackButton}>
          <KeyboardBackspaceIcon sx={{ color: "#084298" }} />
        </div>
        <p className="text-[#084298] font-medium text-xl ml-2">
          Update product group
        </p>
      </div>
      <div className="flex flex-col gap-3 mt-6">
        <p className="text-[#3E3C42] text-lg font-medium">
          Product group name<span className="text-red-500">*</span>
        </p>
        <div className="md:w-[400px]">
          <Input
            disabled={showDisable}
            type="text"
            value={productGroupName}
            placeholder="Enter group name"
            required
            borderColor={
              submitClicked && !productGroupName ? "red.500" : "gray.300"
            }
            borderWidth={"2px"}
            onChange={(e) => { setProductGroupName(e.target.value); handleSearchTermChange(e); setShowDiscard(true); }}
          />
          {(searchSuggestionsGroups?.length > 0) && (

            <div >
              <div className="search-suggestions rounded-md mt-2 w-[400px]">
                <p className="text-red-500">Similar group(s) already exist. Please check if your group is duplicate.</p>
              </div>
              <div className="search-suggestions border bg-white rounded-md shadow-lg shadow-gray-200 mt-2 w-[500px] z-10">
                <div className="p-2">
                  <p className="text-gray-400">
                    Groups
                  </p>
                  <div className="grid grid-cols-2 gap-2">
                    {searchSuggestionsGroups?.map((item) => (
                      <div className="flex flex-row" >
                        <img className="w-[30px] h-[30px]" src="/groupsCategory.png"></img>
                        <p className="font-medium text-lg">{item.productGroupName}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

          )}
          {submitClicked && !productGroupName && (
            <Text color="red.500" fontSize="sm" mt="1">
              Please enter the product group name.
            </Text>
          )}
          {
            productGroupName?.length > 50 && <div>
              <Text color="red.500" fontSize="sm" mt="1">
                You have exceed the character limit of 50 by {productGroupName?.length - 50}
              </Text>
            </div>
          }
        </div>
      </div>
      <div className="flex flex-col gap-3 mt-6">
        <p className="text-[#3E3C42] text-lg font-medium">
          Parent product group<span className="text-red-500">*</span>
        </p>

        <RadioGroup
          isDisabled={showDisable}
          onChange={(e) => { setParentProductGroupType(e); setShowDiscard(true); }}
          value={parentProductGroupType}
        >
          <div
            className="flex flex-col md:grid md:grid-cols-3 gap-1 md:gap-2"
            style={{ width: "fit-content" }}
          >
            {dataOptions.parentProductGroup.type.map((x) => {
              return (
                <div
                  style={{
                    backgroundColor:
                      parentProductGroupType == x ? "#DDEEFF80" : "#FFF",
                    borderRadius: "8px",
                    mb: "12px",
                    // border
                  }}
                >
                  <Radio
                    value={x}
                    py={"8px"}
                    pl={"8px"}
                    pr={"12px"}
                    fontSize={"14px"}
                    fontWeight={500}
                    color={"#3E3C42"}
                    _checked={{
                      bg: "#6CA6FC",
                      borderColor: "#6CA6FC",
                    }}
                    _hover={{
                      borderColor: "#6CA6FC",
                    }}
                    borderColor={
                      submitClicked && !parentProductGroupType
                        ? "red.500"
                        : "gray.300"
                    }
                    isDisabled={showDisable}
                  >
                    {x}
                  </Radio>
                </div>
              );
            })}
          </div>
        </RadioGroup>
        {submitClicked && !parentProductGroupType && (
          //  {/*submitClicked &&*/}
          <Text color="red.500" fontSize="sm" mt="1">
            Please select the parent product group.
          </Text>
        )}
      </div>

      <div className="flex flex-col gap-3 mt-6" >
        <p className="text-[#3E3C42] text-lg font-medium">
          Select logo for the product group
        </p>

        <div className="mt-6">
          <RadioGroup
            onChange={(e) => { setIcon(e); setShowDiscard(true); }}
            value={icon}
            isDisabled={showDisable}
          >
            <div
              className="flex flex-col md:grid md:grid-cols-3 gap-1 md:gap-2"
              style={{ width: "fit-content" }}
            >
              {dataOptions.icon.type.map((x) => {
                return (
                  <div
                    style={{
                      backgroundColor:
                        icon == x ? "#DDEEFF80" : "#FFF",
                      borderRadius: "8px",
                      mb: "12px",
                      // border
                    }}
                  >
                    <Radio
                      value={x}
                      py={"8px"}
                      pl={"8px"}
                      pr={"12px"}
                      fontSize={"14px"}
                      fontWeight={500}
                      color={"#3E3C42"}
                      _checked={{
                        bg: "#6CA6FC",
                        borderColor: "#6CA6FC",
                      }}
                      _hover={{
                        borderColor: "#6CA6FC",
                      }}
                      borderColor={
                        // submitClicked && !useDemo
                        //     ? "red.500" :
                        "gray.300"
                        //  {/*submitClicked &&*/}
                      }
                      isDisabled={showDisable}
                    >
                      {x === "Select default icons" ? "Select a pre-loaded logo" : "Upload your own logo"}
                    </Radio>
                  </div>
                );
              })}
            </div>
          </RadioGroup>
        </div>

        {icon === "Select default icons" && <div className="w-full lg:w-[400px]">

          <Menu>
            <MenuButton isDisabled={showDisable} as={Button} rightIcon={<ChevronDownIcon />} className='w-full lg:w-[220px] font-normal bg-none'>
              {defaultIcon ? <img className='h-[20px] w-[20px]' src={defaultIcon} alt="image" /> : "Select one"}
            </MenuButton>
            <MenuList className='w-full lg:w-[400px] grid grid-cols-4 font-normal max-h-[250px] overflow-y-auto'>
              {logoList?.map((item, index) => (
                <MenuItem onClick={() => { setDefaultIcon(item.defaultLogoUrl); setCardLogo(null); setShowDiscard(true); }}>
                  {<img className='h-[40px] w-[40px]' src={item.defaultLogoUrl} alt="image" />}
                  {/* {item} */}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>

        </div>}

        {icon === "Upload your own icon" && <AddIcon
          logo={cardLogo}
          setLogo={setCardLogo}
          toast={toast}
          logoPreview={cardLogoPreview}
          setLogoPreview={setCardLogoPreview}
          submitClicked={submitClicked}
          // desiredRatio={"3 : 2"}
          text={"Supported formats: .png, .svg, .jpeg"}
          info={"2"}
          labelText={"Upload icon for the product group"}
          setShowDiscard={setShowDiscard}
        />}
      </div>

      <div className="flex items-center gap-4 mt-6">
        <div>
          <p className="text-[#3E3C42] text-lg font-medium">
            Description
          </p>
          <div className="mt-6 md:w-[400px]">
            <Textarea
              disabled={showDisable}
              placeholder="A brief description of the product group"
              borderWidth={"2px"}
              value={description}
              onChange={(e) => { setDescription(e.target.value); setShowDiscard(true); }}
            />
            {
              description?.length > 1000 && <div>
                <Text color="red.500" fontSize="sm" mt="1">
                  You have exceed the character limit of 1000 by {description.length - 1000}
                </Text>
              </div>
            }
          </div>
        </div>
      </div>


      <div className="flex gap-[10px] items-center ml-6 md:ml-0 mt-6 mb-20 md:mb-0">
        {
          !review ? (
            <>
              <PrimaryButton
                text={"Save Draft"}
                width={"130px"}
                //disable={showDisable}
                onClick={() => handleSubmit(0)}
              />
              <TonalButton
                //disable={!showDiscard}
                text={"Review"}
                width={"130px"}
                onClick={() => {
                  setReview(true);
                  setShowDisable(true);
                }}
              />
            </>
          ) :
            (
              <>
                <TonalButton
                  //disable={!showDiscard}
                  text={"Back to Editing"}
                  width={"130px"}
                  onClick={() => {
                    setReview(false);
                    setShowDisable(false);
                  }}
                />
                <PrimaryButton
                  text={"Submit"}
                  width={"130px"}
                  //disable={showDisable}
                  onClick={() => handleSubmit(1)}
                  color="green"
                />

              </>
            )
        }
        {showDraft && <DraftModal isOpen={showDraft} handleSubmit={handleSubmit} handleConfirmDiscard={handleConfirmDiscard} setShowDraft={setShowDraft} />}
      </div>

    </div>
  );
};

export default UpdateProductGroup;
