const FeedDown = () => {
  return (
    <div className="w-full h-full bg-[#E8E8E8] flex justify-center items-center rounded-lg">
      <div className="w-fit flex flex-col gap-2 justify-center items-center">
        <img
          src="/recipeCompliance/cameraFeedDown.svg"
          alt="Camera"
          className="w-[117px] h-[117px] p-2"
        />

        <p className="text-[#938F96] font-medium text-2xl">Feed Down</p>
      </div>
    </div>
  );
};

export default FeedDown;
